import { takeEvery, all, put } from 'redux-saga/effects';
import { ActionTypes } from '../actionsTypes';
import { ICommonResponse } from '../../Models/Responses/ICommonResponse';
import { ToastMessages } from '../../Enums/Messages';
import { IActivityProfileVotingProcess, IBmProjectMemeber } from '../../Models/Activity/ActivityModels';
import ActivityService from '../../Services/ActivityServices';
import { ActivityAnalysisData, ActivitySurveyData } from '../../Scenes/Tools/ActivityTool/Survey/Models/ActivitySurveyData';
//import { transformActivitySurveyData } from '../../Utils/helperFunctions';

//import { IActivityProfileVersion } from "../../Models/IActivityManagement";


// function* onGetActivityAnalyzeResult(action: any) {

//     try {
//         // yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
//         let _result: ICommonResponse = yield ActivityService.GetActivityAnalyzeResult();

//         if (!_result.isError) {

//             let result = _result.result as IActivityProfileVotingProcess[];
//             yield put({ type: ActionTypes.SetActivityAnalyzeResult, result: result });
//         } else {
//             yield put({ type: ActionTypes.SetCommonResponse, result: _result });
//         }
//         // yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
//     } catch (error) {
//         console.error('ERR in GetActivityAnalyzeResult: ', error);
//         yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
//     }
// }
// function* onGetbmProjectMembers(action: any) {

//     try {
//         // yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
//         let _result: ICommonResponse = yield ActivityService.GetbmProjectMembers();

//         if (!_result.isError) {

//             let result = _result.result as IBmProjectMemeber[];
//             yield put({ type: ActionTypes.SET_BM_PROJECT_MEMBERS, result: result });
//         } else {
//             yield put({ type: ActionTypes.SetCommonResponse, result: _result });
//         }
//     } catch (error) {
//         console.error('ERR in GetbmProjectMembers: ', error);
//         yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
//     }
// }

function* onGetSurveyDataForParticipant(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.GetSurveyDataForParticipant(action.token);
        if (!_result.isError) {
            let result = _result.result as ActivitySurveyData;
            console.log("ActivitySurveyData", result);
            //let activitySurveyData = transformActivitySurveyData(result);
            yield put({ type: ActionTypes.saveActivitySurveyData, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } 
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
       
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}

function* onUploadParticipantAnswer(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true }); 
        let _result: ICommonResponse = yield ActivityService.UploadParticipantAnswer(action.participantAnswer);
        if (!_result.isError) {
            // let result = _result.result as ActivitySurveyData;
            // let activitySurveyData = transformActivitySurveyData(result);
            // yield put({ type: ActionTypes.saveActivitySurveyData, result: activitySurveyData });
            
            yield put({ type: ActionTypes.isSuccessAnonymousVoting, isSuccessAnonymousVoting: true });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } 
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        }); 
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}

// function* onGetProfileVersionTasks(action: any) {

//     try {
//         yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
//         let _result: ICommonResponse = yield ActivityService.GetProfileVersionTasks(action.profileId, action.versionId);

//         if (!_result.isError) {

//             let result = _result.result as IActivityProfileVersion;
//             yield put({ type: ActionTypes.SET_PROFILE_VERSION_TASKS, result: result });
//         } else {
//             yield put({ type: ActionTypes.SetCommonResponse, result: _result });
//         }
//     } catch (error) {
//         console.error('ERR in GetProfileVersionTasks: ', error);
//         yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
//     }
// }
// function* onGetCurrentProfileVersions(action: any) {

//     try {
//         yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
//         let _result: ICommonResponse = yield ActivityService.GetCurrentProfileVersions(action.profileId);

//         if (!_result.isError) {

//             let result = _result.result as IActivityProfileVersion;
//             yield put({ type: ActionTypes.SET_ACTIVITY_PROFILE_VERSIONS, profileId: action.profileId, result: result, });
//         } else {
//             yield put({ type: ActionTypes.SetCommonResponse, result: _result });
//         }
//     } catch (error) {
//         console.error('ERR in GetCurrentProfileVersions: ', error);
//         yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
//     }
// }

function* onGetActivityProjectTasks(action: any) {
    try {
        console.log("onGetActivityProjectTasks: ", action);
        yield put({ type: ActionTypes.GettingActivityProjectTasks, value: true });
        let _result: ICommonResponse = yield ActivityService.GetActivityProjectTasks(action.activityProjectProfileId);

        if (!_result.isError) {
            console.log("activity project tasks: ", _result);

            //let result = _result.result as IActivityProfileVersion;
            // yield put({ type: ActionTypes.SET_ACTIVITY_PROFILE_VERSIONS, profileId: action.profileId, result: result, });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingActivityProjectTasks, value: false });
    } catch (error) {
        console.error('ERR in GetCurrentProfileVersions: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
        yield put({ type: ActionTypes.GettingActivityProjectTasks, value: false });
    }
}

function* onCreateActivityProfileAnalysis(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.CreateActivityProfileAnalysis(action.model);

        if (!_result.isError) {
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in GetCurrentProfileVersions: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}

function* onCancelActivityProfileAnalysis(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.CancelActivityProfileAnalysis(action.model);

        if (!_result.isError) {
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in CancelActivityProfileAnalysis: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}

function* onStartActivityProfileAnalysis(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.StartActivityProfileAnalysis(action.model);

        if (!_result.isError) {
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in StartActivityProfileAnalysis: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}
function* onEndActivityProfileAnalysis(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.EndActivityProfileAnalysis(action.model);

        if (!_result.isError) {
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in StartActivityProfileAnalysis: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}
function* onCompleteActivityProfileAnalysis(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.CompleteActivityProfileAnalysis(action.model);

        if (!_result.isError) {
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in StartActivityProfileAnalysis: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}

function* onReviewActivityAnswer(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.ReviewActivityAnswer(action.model); 
        if (!_result.isError) {
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in StartActivityProfileAnalysis: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}


function* onRemoveActivityAnswer(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.DeleteActivityAnswer(action.answerId); 
        if (!_result.isError) {
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in StartActivityProfileAnalysis: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}


function* onReviewActivityVote(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        let _result: ICommonResponse = yield ActivityService.ReviewActivityVote(action.model); 
        if (!_result.isError) {
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in StartActivityProfileAnalysis: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}
function* onExportActivityProfileAnalysis(action: any) {
    try {
        yield ActivityService.ExportToExcel(action.model);
    } catch (error) {
        console.error("ERR in onGet: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    } 
   
}

function* onGetActivityProgress(action: any) {
    try {
        let _result: ICommonResponse = yield ActivityService.getActivityProgress();
        if (!_result.isError) {
            let result = _result.result as number;
            yield put({ type: ActionTypes.SetActivityProgressValue, result: result });
        }
    } catch (error) {
        console.error('onGetIntergaceProgress', error);
    }
}

function* onGetActivityView(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        yield refreshTheData(action);
    } catch (error) {
        console.error("ERR in onGetIAView: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
    yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
}

function* refreshTheData(action: any) {
    let _resultGet: ICommonResponse = yield ActivityService.GetAll();
    if (!_resultGet.isError) {
        let resultGet = _resultGet.result as ActivityAnalysisData;
        yield put({ type: ActionTypes.setActivityViewResult, result: resultGet });
    } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _resultGet });
    }
}

function* onUpdateActivityToolsTask(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield ActivityService.UpdateActivityToolTask(action.model);
        if (!resultUpdate.isError) {
            yield refreshTheData(action);
            yield put({ type: ActionTypes.SetModalResponse, result: resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateActivityTask: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteActivityToolsTask(action: any) {
   
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultDelete: ICommonResponse = yield ActivityService.DeleteActivityToolTask(action.id);
        if (!resultDelete.isError) {
            yield refreshTheData(action);
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateActivityTask: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onAddActivityToolsTasksToGroup(action: any) {
    try {
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultAdd: ICommonResponse = yield ActivityService.addActivityToolTaskToGroups(action.model);
        if (!resultAdd.isError) {
            yield refreshTheData(action);
            yield put({ type: ActionTypes.SetModalResponse, result: resultAdd });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultAdd });
        }
        yield put({ type: ActionTypes.changeActivityLoader, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in  onAddEditMapTasksToGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}


// function* watchGetActivityAnalyzeResult() {
//     yield takeEvery(ActionTypes.GetActivityAnalyzeResult, onGetActivityAnalyzeResult)
// }

// function* watchGetbmProjectMembers() {
//     yield takeEvery(ActionTypes.GET_BM_PROJECT_MEMBERS, onGetbmProjectMembers)
// }

function* watchGetSurveyDataForParticipant() {
    yield takeEvery(ActionTypes.GetSurveyDataForParticipant, onGetSurveyDataForParticipant)
}

function* watchUploadParticipantAnswer() {
    yield takeEvery(ActionTypes.UploadParticipantAnswer, onUploadParticipantAnswer)
}

// function* watchGetProfileVersionTasks() {
//     yield takeEvery(ActionTypes.GET_PROFILE_VERSION_TASKS, onGetProfileVersionTasks)
// }
// function* watchGetCurrentProfileVersions() {
//     yield takeEvery(ActionTypes.GET_CURRENT_PROFILE_VERSIONS, onGetCurrentProfileVersions)
// }
function* watchGetActivityProjectTasks() {
    yield takeEvery(ActionTypes.GetActivityProjectTasks, onGetActivityProjectTasks)
}
function* watchCreateActivityProfileAnalysis() {
    yield takeEvery(ActionTypes.CreateActivityProfileAnalysis, onCreateActivityProfileAnalysis)
}

function* watchCancelActivityProfileAnalysis() {
    yield takeEvery(ActionTypes.CancelActivityProfileAnalysis, onCancelActivityProfileAnalysis)
}

function* watchStartActivityProfileAnalysis() {
    yield takeEvery(ActionTypes.StartActivityProfileAnalysis, onStartActivityProfileAnalysis)
}
function* watchEndActivityProfileAnalysis() {
    yield takeEvery(ActionTypes.EndActivityProfileAnalysis, onEndActivityProfileAnalysis)
}
function* watchCompleteActivityProfileAnalysis() {
    yield takeEvery(ActionTypes.CompleteActivityProfileAnalysis, onCompleteActivityProfileAnalysis)
}
function* watchExportActivityProfileAnalysis() {
    yield takeEvery(ActionTypes.ExportActivityProfileAnalysis, onExportActivityProfileAnalysis)
}

function* watchReviewActivityAnswer() {
    yield takeEvery(ActionTypes.ReviewActivityVote, onReviewActivityAnswer)
}

function* watchGetActivityProfileAnalysis() {
    yield takeEvery(ActionTypes.GetActivityProfileAnalysis, onGetActivityView)
}

function* watchUpdateActivityToolsTask() {
    yield takeEvery(ActionTypes.UpdateActivityToolsTask, onUpdateActivityToolsTask)
}
function* watchDeleteActivityToolsTask() {
    yield takeEvery(ActionTypes.DeleteActivityToolTask,onDeleteActivityToolsTask)
}
function* watchAddActivityToolsTasksToGroup() {
    yield takeEvery(ActionTypes.AddActivityToolsTasksToGroup, onAddActivityToolsTasksToGroup)
}
function* watchGetActivityProgress() {
    yield takeEvery(ActionTypes.GetActivityProgressValue, onGetActivityProgress);
}
function* watchRemoveActivityAnswer() {
    yield takeEvery(ActionTypes.RemoveActivityVote, onRemoveActivityAnswer)
}
export default function* ActivitySagas() {
    yield all([
        watchRemoveActivityAnswer(),
        watchGetActivityProfileAnalysis(),
        watchCreateActivityProfileAnalysis(),
        watchCancelActivityProfileAnalysis(),
        watchStartActivityProfileAnalysis(),
        watchGetActivityProjectTasks(),
        watchUploadParticipantAnswer(),
        watchGetSurveyDataForParticipant(), 
        watchUpdateActivityToolsTask(),
        watchAddActivityToolsTasksToGroup(),
        watchDeleteActivityToolsTask(),
        watchEndActivityProfileAnalysis(),
        watchCompleteActivityProfileAnalysis(),
        watchExportActivityProfileAnalysis(), 
        watchReviewActivityAnswer(),
        watchGetActivityProgress()
    ]);
}