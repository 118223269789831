import { Avatar, List, Tooltip, Modal, Space, Button, Checkbox, Tabs, Spin, Empty, Typography } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import React from "react";
import { CustomerUserDto, CustomerUsersInQuestionsDto, UsersInDataCollectionRoleDto } from "../../../Models/Requests/CustomerUsers";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import { openNotificationWithIcon } from "../../../Utils/helperFunctions";
import { ToastMessages } from "../../../Enums/Messages";
import { t } from "i18next";
import { IAsupAppConfig } from "../../../Models/Requests/IAsupAppConfig";
import UploadFile from "../EBITTool-V2/CostType/UploadFilesComponent";
import { ProfileResponse } from "../../../Models/Responses/ProfileResponse";
const asupAppConfig = (window as any).globalTS as IAsupAppConfig;
const protocol = window.location.protocol;
const domain = window.location.hostname;
const port = window.location.port;

const full =  asupAppConfig.isTest? asupAppConfig.serverURL : `${protocol}//${domain}:${port ? port : ""}/`
 
const { Text } = Typography;
interface IContactsMngProps {
    usersInDataCollectionRoleDto: UsersInDataCollectionRoleDto;
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    selectedQuestionsIds: number[];
    //leverData: ILever[];
    //profile: ProfileResponse;
    //roles?: number[];
    profileInfo: ProfileResponse;
    getDataCollectionRoles: () => any;
    saveCustomerUsersInQuestion: (model: CustomerUsersInQuestionsDto) => any;
    onImportTable: (frmData: FormData, customerId: number, bmProjectId: number) => any;
}

function ContactsMng(props: IContactsMngProps) {
    const [selectedContactIds, setSelectedContactIds] = useState<number[]>([]);
    const [contacts, setContacts] = useState<CustomerUserDto[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        props.getDataCollectionRoles();
    }, []);

    useEffect(() => {
        if (props.usersInDataCollectionRoleDto?.customerUsers) {
            setContacts(props.usersInDataCollectionRoleDto?.customerUsers);
        }
    }, [props.usersInDataCollectionRoleDto?.customerUsers]);

    useEffect(() => {
        //console.log("props.modalResponse", props.modalResponse);
        if (isModalVisible) {
            if (props.modalResponse?.isError) {
                if (typeof props.modalResponse?.error?.exceptionMessage == 'string') {
                    if (props.modalResponse?.error?.exceptionMessage) {
                        openNotificationWithIcon("error", "Error", props.modalResponse.error?.exceptionMessage as string);
                    } else {
                        openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
                    }
                } else {
                    openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
                }
            } else {
                if (props.modalResponse?.message) {
                    openNotificationWithIcon("success", "Success", props.modalResponse?.message);
                    closeAndReset();
                }
            }
        }
    }, [props.modalResponse]);

    const handleOk = () => {
        props.saveCustomerUsersInQuestion({
            customerUsersIds: selectedContactIds,
            questionListIds: props.selectedQuestionsIds
        });
    };
    const handleCancel = () => {
        closeAndReset();
    };
    const closeAndReset = () => {
        setSelectedContactIds([]);
        setIsModalVisible(false);
    }
    const renderContactsShortcut = () => {
        return <Button type="default" onClick={() => setIsModalVisible(true)}>
            {`${t("Customer Users")}`}
        </Button>;
    }
    const onImport = (formData: FormData) => {
        props.onImportTable(formData, 0, props.profileInfo?.currentProject?.id);
    }

    const onClickContact = (item: CustomerUserDto) => {
        let temp = [...selectedContactIds];
        if (temp.includes(item.customerUserId)) {
            temp = temp.filter(c => c != item.customerUserId);
        } else {
            temp.push(item.customerUserId)
        }
        setSelectedContactIds(temp);
    }

    return (<>
        {renderContactsShortcut()}
        <Modal getContainer={() => document.getElementById('app-layout')} title={`${t("Customer users in roles")}`} open={isModalVisible}

            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    {`${t("Close")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={handleOk}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}
        >
            <Text type="success">{`${t("Please, select users to assign them to the selected questions")}.`}</Text>
            <p>
                <a href={`${full}users.xlsx`}>{`${t("Click to download template with examples to import users")}.`}</a>
                <br/>
                {`${t("Then Edit the file and upload it again")} `}
                <Tooltip placement="top" title={`${t("Daten importieren")}`}>
                    <UploadFile currentProjectId={props.profileInfo?.currentProject.id} onImportCostTable={onImport} isForEbit={false} />
                </Tooltip>
            </p>
            <Spin spinning={props.isLoading}>
                {(props.usersInDataCollectionRoleDto?.dataCollectionRoles && props.usersInDataCollectionRoleDto?.dataCollectionRoles.length > 0) ?
                    <Tabs defaultActiveKey="all_contacts" tabPosition="top" type="line" size='small' >
                        <Tabs.TabPane tab="All" key="all_contacts">
                            <List
                                className="demo-loadmore-list comments-on-levers"
                                itemLayout="vertical"
                                dataSource={contacts as CustomerUserDto[]}
                                renderItem={item => (
                                    <List.Item
                                        key={`user_${item.customerUserId}`}
                                        extra={<label>{item.email}</label>}
                                    >
                                        <List.Item.Meta
                                            avatar={<Checkbox checked={selectedContactIds.includes(item.customerUserId)} onClick={() => { onClickContact(item) }} />}
                                            title={<Text>{`${item.firstName} ${item.lastName}`}</Text>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Tabs.TabPane>
                        {props.usersInDataCollectionRoleDto?.dataCollectionRoles.map(role => {
                            return <Tabs.TabPane tab={role.label} key={`${role.value}_dcr_contact`}>
                                <List
                                    className="demo-loadmore-list comments-on-levers"
                                    itemLayout="vertical"
                                    dataSource={contacts.filter(c => c.dataCollectionRoles.map(dc => { return dc.value }).includes(role.value)) as CustomerUserDto[]}
                                    renderItem={item => (
                                        <List.Item
                                            key={`comment_${item.customerUserId}`}
                                            extra={<label>{item.email}</label>}
                                        >
                                            <List.Item.Meta
                                                title={<Text>{`${item.firstName} ${item.lastName}`}</Text>}
                                                avatar={<Checkbox checked={selectedContactIds.includes(item.customerUserId)} onClick={() => { onClickContact(item) }} />}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Tabs.TabPane>
                        })
                        }
                    </Tabs>
                    : <Empty />}
            </Spin>
        </Modal>
    </>);
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.leverTool.isDCRolesLoading,
        usersInDataCollectionRoleDto: state.leverTool.usersInDataCollectionRoleDto,
        isModalLoading: state.leverTool.isModalLoading,
        modalResponse: state.leverTool.modalResponse,
        selectedQuestionsIds: state.leverTool.selectedQuestions,
        profileInfo: state.generalInfo.profileResponse,
        //leverData: state.leverTool.leverData,
        //profile: state.generalInfo.profileResponse
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getDataCollectionRoles: () => dispatch({ type: ActionTypes.GetDataCollectionRoleWithUsers }),
        saveCustomerUsersInQuestion: (model: CustomerUsersInQuestionsDto) => dispatch({ type: ActionTypes.SaveCustomerUsersInQuestion, model: model }),
        onImportTable: (frmData: FormData, customerId: number, bmProjectId: number) => dispatch({ type: ActionTypes.ImportCustomerUser, frmData: frmData, customerId: customerId, bmProjectId: bmProjectId })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsMng);