
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import { IIndustrySegmentBestPracticeItem } from "../../Models/IIndustrySegmentBestPractice";
import IndustrySegmentBestPracticeService from "../../Services/IndustrySegmentBestPracticeService";
import { ISegmentDisciplineBestPracticeItem } from "../../Models/ISegmentDisciplineBestPractice";
import SegmentBestPracticeDisciplineService from "../../Services/SegmentDisciplineBPService";

function* OnGetAllIndustrySegmentBestPractice(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER, isLoading: true });
        let _result: ICommonResponse = yield IndustrySegmentBestPracticeService.GetIndustrySegmentBestPractice(action.industrySegmentId);
        if (!_result.isError) {
            let result = _result.result as IIndustrySegmentBestPracticeItem;
            yield put({ type: ActionTypes.SAVE_INDUSTRY_SEGMENT_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetIndustrySegmentBestPRactices: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnCreateIndustrySegmentBestPractice(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let _result: ICommonResponse =
            yield IndustrySegmentBestPracticeService.CreateIndustrySegmentBestPractice(action.model);
        if (!_result.isError) {
            let _result: ICommonResponse =
                yield IndustrySegmentBestPracticeService.GetIndustrySegmentBestPractice(action.industrySegmentId);
            let result = _result.result as IIndustrySegmentBestPracticeItem
            yield put({ type: ActionTypes.SAVE_INDUSTRY_SEGMENT_BEST_PRACTICES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        }
        yield put({
            type: ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER,
            isLoading: false,
        });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateIndustrySegmentBestPractice(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield IndustrySegmentBestPracticeService.UpdateIndustrySegmentBestPractice(action.model);
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let resultCus: ICommonResponse = yield IndustrySegmentBestPracticeService.GetIndustrySegmentBestPractice(action.industrySegmentId);
            let result = resultCus.result as IIndustrySegmentBestPracticeItem;
            yield put({ type: ActionTypes.SAVE_INDUSTRY_SEGMENT_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onClearIndustrySegmentBestPractice(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER, isLoading: true });
        let resultClear: ICommonResponse = yield IndustrySegmentBestPracticeService.ClearIndustrySegmentBestPractice(action.industrySegmentId);
        if (!resultClear.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultClear });
            let resultBp: ICommonResponse = yield IndustrySegmentBestPracticeService.GetIndustrySegmentBestPractice(action.industrySegmentId);
            let _resultBp = resultBp.result as IIndustrySegmentBestPracticeItem;
            yield put({ type: ActionTypes.SAVE_INDUSTRY_SEGMENT_BEST_PRACTICES, result: _resultBp });
            let resultDBp: ICommonResponse = yield SegmentBestPracticeDisciplineService.GetSegmentBestPracticeDiscipline(action.industrySegmentId);
            let _resultDBp = resultDBp.result as ISegmentDisciplineBestPracticeItem[];
            yield put({ type: ActionTypes.SAVE_SEGMENT_DISCIPLINE_BEST_PRACTICES, result: _resultDBp });

        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultClear });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onClearQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* watchGetAllIndustrySegmentBestPractice() {
    yield takeEvery(ActionTypes.GET_INDUSTRY_SEGMENT_BEST_PRACTICES, OnGetAllIndustrySegmentBestPractice);
}
function* watchCreateIndustrySegmentBestPractice() {
    yield takeEvery(ActionTypes.CREATE_INDUSTRY_SEGMENT_BEST_PRACTICE, OnCreateIndustrySegmentBestPractice);
}
function* watchUpdateIndustrySegmentBestPractice() {
    yield takeEvery(ActionTypes.UPDATE_INDUSTRY_SEGMENT_BEST_PRACTICE, onUpdateIndustrySegmentBestPractice);
}
function* watchClearIndustrySegmentBestPracticece() {
    yield takeEvery(ActionTypes.CLEAR_INDUSTRY_SEGMENT_BEST_PRACTICE, onClearIndustrySegmentBestPractice);
}
export default function* IndustrySegmentBestPracticeSagas() {
    yield all([
        watchGetAllIndustrySegmentBestPractice(),
        watchCreateIndustrySegmentBestPractice(),
        watchUpdateIndustrySegmentBestPractice(),
        watchClearIndustrySegmentBestPracticece()
    ]);
}
