import { Table } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { tableLocalTranslate } from '../../Utils/helperFunctions';
interface IProps {
    dataSource: any[];
    keyTable?: string;
    className?: string;
    columns: any[];
    scroll?: any;
}
const AdminTable: React.FC<IProps> = (props) => {
    const [totalDataSourceLength, setTotalDataSourceLength] = useState(props.dataSource?.length);
    useEffect(() => {
        setTotalDataSourceLength(props.dataSource?.length)
    }, [props.dataSource])
    return (
        <Table
            getPopupContainer={() => document.getElementById("app-layout")}
            key={props.keyTable}
            className={props.className}
            bordered
            dataSource={props.dataSource}
            columns={props.columns}
            rowClassName="editable-row"
            scroll={props.scroll ? props.scroll : { y: "calc(100vh - 300px)" ,x: true }}
            pagination={{
                defaultPageSize: 50,
                pageSizeOptions: [50, 100, 150],
                total: totalDataSourceLength,
                showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                showSizeChanger: true,
                locale: { items_per_page: `/ ${t("Page")}` },

            }}
            locale={tableLocalTranslate()}
            onChange={function (pagination, filters, sorter, extra: { currentDataSource: [], action: any }) {
                return setTotalDataSourceLength(extra.currentDataSource.length);
            }}

        />
    )
}

export default AdminTable