import "./LeversTool.scss";
import {
  Tabs,
  Space,
  Switch,
  Popover,
  Menu,
  Checkbox,
  Button,
  Tag,
  Spin,
  Empty,
  Badge,
} from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ILever,
  ILeverCell,
  ILeverQuestion,
  ILeversPageConfig,
} from "../../../Models/Levers/Items";
import {
  IListItem,
  ProfileResponse,
} from "../../../Models/Responses/ProfileResponse";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import {
  getLeversCustomerTableHeader,
  getLeversTableHeader,
} from "../../../Services/LeversData";
import { BasicRole } from "../../../Enums/BasicRoles";
import {
  QuestionAnswer,
  QuestionAnswersDto,
} from "../../../Models/Requests/SaveLevers";
import {
  SaveOutlined,
  CheckSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";
import LeversTable from "./LeversTable";
import ContactsMng from "./ContactsMng";
import {
  formatValue,
  openNotificationWithIcon,
} from "../../../Utils/helperFunctions";
import AllCharts from "../../../Components/AllChartsPage/AllCharts";
import {
  CalculateEfDegreeOfFulfillment,
  CalculateKpiDegreeForOneOfFulfillment,
  CalculateKPIDegreeOfFulfillment,
  CalculateTshDegreeOfFulfillment,
  CalculateLeverKPIValue,
} from "./LeversCalculator";
import { t } from "i18next";
import _, { isEqual } from "lodash";
import { usePrompt } from "../EBITTool-V2/BasicCharts/PromptAndBlocker";
import HelpPage from "../../../Layout/HelpPage";
import LeverService from "../../../Services/LeverService";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";

// const { TabPane } = Tabs;
var FormulaParser = require("hot-formula-parser").Parser;
var parser = new FormulaParser();
const MINUTE_MS = 60000;
interface LeversProps {
  isLoading: boolean;
  leverData: ILever[];
  profileInfo: ProfileResponse;
  selectedQuestions: number[];
  selectedCollectionRoles: number[];
  selectedQuestionStatuses: number[];
  getLevers: (projectID: number) => any;
  //leversUnmount: () => any;
  saveLevers: (model: QuestionAnswersDto, bmProjectId: number) => any;
  saveLeversByConsultant: (
    model: QuestionAnswersDto,
    bmProjectId: number
  ) => any;
  changeAllSelectedQuestions: (selectedAll: boolean) => any;
  changeLevers: (model: ILever[]) => any;
  changeCollectionRoles: (model: number[]) => any;
  changeQuestionStatus: (model: number[]) => any;
}
function LeversTool(props: LeversProps) {
  const [allQuestionsSelected, setAllQuestionsSelected] =
    useState<boolean>(false);
  const [listOfEditedQuestions, setListOfEditedQuestions] = useState<number[]>(
    []
  );
  const [config, setConfig] = useState<ILeversPageConfig>({
    wideView: true,
    columns: props.profileInfo?.roles?.includes(BasicRole.Customer)
      ? (getLeversCustomerTableHeader(false) as any[])
      : (getLeversTableHeader(false) as any[]),
    columnsWidth: 120,
  });
  const [collectionRoles, setCollectionRoles] = useState<IListItem[]>([]);
  const [questionStatuses, setQuestionStatuses] = useState<IListItem[]>([]);
  const [filteredColuns, setFilteredColuns] = useState([]);

  /*Save Filters After Reload  */

  let leverQuestionStatuses = JSON.parse(
    localStorage.getItem("leverQuestionStatuses")
  );
  let leverCollectionRoles = JSON.parse(
    localStorage.getItem("leverCollectionRoles")
  );
  let leverFilterColumn = JSON.parse(localStorage.getItem("leverFilterColumn"));
  let leverConfigTemp = JSON.parse(localStorage.getItem("leverConfigTemp"));
  let leverAllQuestionsSelected = localStorage.getItem(
    "leverAllQuestionsSelected"
  );
  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("Logs every minute");
      //check the countOfUnReviewedAnswers
      let _result: ICommonResponse =
        await LeverService.GetCountOfUnreviewedAnswers();
      if (!_result.isError) {
        var count = _result.result as number;
        var currentCount = 0;
        props.leverData.map((ll) => currentCount + ll.countOfUnReviewedAnswers);
        if (count > 0) {
          // eslint-disable-next-line eqeqeq
          if (currentCount != count) {
            onClickSaveLever();
          }
        }
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  useEffect(() => {
    if (leverConfigTemp) {
      setConfig(leverConfigTemp);
    } else {
      if (props.profileInfo?.currentProject?.id) {
        setConfig({
          wideView: true,
          columns: props.profileInfo?.roles?.includes(BasicRole.Customer)
            ? (getLeversCustomerTableHeader(false) as any[])
            : (getLeversTableHeader(false) as any[]),
          columnsWidth: 120,
        });

        props.getLevers(props.profileInfo?.currentProject?.id);
      }
    }
  }, [props.profileInfo]);

  useEffect(() => {
    console.log("First enter to levers");
    if (leverQuestionStatuses) {
      setQuestionStatuses(leverQuestionStatuses);
      props.changeQuestionStatus(
        leverQuestionStatuses.map((c) => {
          return c.value;
        })
      );
    }
    if (leverCollectionRoles) {
      setCollectionRoles(leverCollectionRoles);
      props.changeCollectionRoles(
        leverCollectionRoles.map((c) => {
          return c.value;
        })
      );
    }
    if (leverFilterColumn) {
      setFilteredColuns(leverFilterColumn);
    }
    if (leverAllQuestionsSelected) {
      setAllQuestionsSelected(
        leverAllQuestionsSelected === "true" ? true : false
      );
    }
  }, []);

  const updateListOfEditedQuestions = (questionVId) => {
    let listOfEditedQuestionsOld = [...listOfEditedQuestions];
    if (!listOfEditedQuestionsOld?.includes(questionVId)) {
      listOfEditedQuestionsOld.push(questionVId);
      setListOfEditedQuestions([...listOfEditedQuestionsOld]);
    }
  };

  /*Save Filters After Reload  */
  const onchangeSubquestionTable = (questionV: ILeverQuestion) => {
    var allLevers = [...props.leverData];

    updateListOfEditedQuestions(questionV.id);

    allLevers.map((temp) => {
      temp.subLevers?.map((sublever) => {
        sublever.successFactors.map((successFactor) => {
          successFactor.kpiLevels.map((kpiLevel) => {
            kpiLevel.questions.map((question) => {
              if (question.code == questionV.code) {
                question.kpiValue.value = questionV.kpiValue.value;
                if (question?.subquestions.columnsNumber == 2) {
                  question.subquestions.rates = questionV.subquestions.rates;
                } else {
                  if (question?.subquestions.columnsNumber == 3) {
                    question.subquestions.sales = questionV.subquestions.sales;
                  }
                  if (question?.subquestions.columnsNumber == 4) {
                    question.subquestions.deviationRates =
                      questionV.subquestions.deviationRates;
                  }
                }
              }
              // } else {
              //     if (question.code == questionV.code) {
              //         if (question?.subquestions.columnsNumber == 2) {
              //             question.subquestions.rates = questionV.subquestions.rates;
              //         }
              //         else {
              //             if (question?.subquestions.columnsNumber == 3) {
              //                 question.subquestions.sales = questionV.subquestions.sales;
              //             }
              //             if (question?.subquestions.columnsNumber == 4) {
              //                 question.subquestions.deviationRates = questionV.subquestions.deviationRates;
              //             }
              //         }
              //     }
              // }
            });
          });
        });
      });
    });
    if (questionV.code == "q_2_2_2_1_1") {
      console.log("Edit q_2_2_2_1_1");
      allLevers.map((temp) => {
        temp.subLevers?.map((sublever) => {
          sublever.successFactors.map((successFactor) => {
            successFactor.kpiLevels.map((kpiLevel) => {
              kpiLevel.questions.map((question) => {
                if (question.code == "q_3_4_3_1_1") {
                  console.log("Edit q_3_4_3_1_1 1");
                  question.kpiValue.value = questionV.kpiValue.value;
                  console.log("Edit q_3_4_3_1_1 2");
                  if (question?.subquestions.columnsNumber == 2) {
                    question.subquestions.rates = questionV.subquestions.rates;
                  } else {
                    if (question?.subquestions.columnsNumber == 3) {
                      console.log("Edit q_3_4_3_1_1 3");
                      question.subquestions.sales =
                        questionV.subquestions.sales;
                    }
                    if (question?.subquestions.columnsNumber == 4) {
                      question.subquestions.deviationRates =
                        questionV.subquestions.deviationRates;
                    }
                  }
                }
              });
            });
          });
        });
      });
    }

    allLevers = LeversCalculator(allLevers);
    props.changeLevers(allLevers);
  };

  const onchangeSubquestionInput = (
    value: any,
    questionV: ILeverQuestion,
    cell: ILeverCell
  ) => {
    var allLevers = [...props.leverData];

    updateListOfEditedQuestions(questionV.id);

    allLevers.map((temp) => {
      temp.subLevers?.map((sublever) => {
        sublever.successFactors.map((successFactor) => {
          successFactor.kpiLevels.map((kpiLevel) => {
            kpiLevel.questions.map((question) => {
              if (question.code == cell.code) {
                question.kpiValue.value = value;
              } else {
                if (question.code == questionV.code) {
                  question?.subquestions?.fields?.map((fieldRow) => {
                    fieldRow.map((field) => {
                      if (cell.code == field.code) {
                        field.value = value;
                      }
                    });
                  });
                }
              }
            });
          });
        });
      });
    });
    allLevers = LeversCalculator(allLevers);
    props.changeLevers(allLevers);
  };

  function LeversCalculator(levers: ILever[]) {
    levers.map((lever) => {
      lever.subLevers.map((sublever) => {
        sublever.successFactors.map((successFactor) => {
          successFactor.kpiLevels.map((kpiLevel) => {
            kpiLevel.questions.map((question) => {
              if (question.kpiValue.unit == "%") {
                parser.setVariable(
                  question.kpiValue.code,
                  question.kpiValue.value / 100
                );
              } else {
                parser.setVariable(
                  question.kpiValue.code,
                  question.kpiValue.value
                );
              }
              question?.subquestions?.fields?.map((fieldRows) => {
                fieldRows
                  .filter((c) => c.code != "")
                  .sort((a, b) => (a.code < b.code ? -1 : 1))
                  .map((field) => {
                    if (field.unit == "%") {
                      parser.setVariable(field.code, field.value / 100);
                    } else {
                      parser.setVariable(field.code, field.value);
                    }
                  });
              });
            });
          });
        });
      });
    });
    levers.map((lever) => {
      lever.subLevers.map((sublever) => {
        sublever.successFactors.map((successFactor) => {
          successFactor.kpiLevels.map((kpiLevel) => {
            kpiLevel.questions.map((question) => {
              if (question.kpiValue.unit == "%") {
                parser.setVariable(
                  question.kpiValue.code,
                  question.kpiValue.value / 100
                );
              } else {
                parser.setVariable(
                  question.kpiValue.code,
                  question.kpiValue.value
                );
              }
              question?.subquestions?.fields?.map((fieldRows) => {
                fieldRows
                  .filter((c) => c.code != "")
                  .sort((a, b) => (a.code < b.code ? -1 : 1))
                  .map((field) => {
                    if (field.unit == "%") {
                      parser.setVariable(field.code, field.value / 100);
                    } else {
                      parser.setVariable(field.code, field.value);
                    }
                  });
              });
            });
          });
        });
      });

      lever.subLevers.map((sublever) => {
        sublever.successFactors.map((successFactor) => {
          successFactor.kpiLevels.map((kpiLevel) => {
            kpiLevel.questions.map((question) => {
              question?.subquestions?.fields?.map((fieldRows) => {
                fieldRows
                  .filter((c) => c.formula != "")
                  .sort((a, b) => (a.code < b.code ? -1 : 1))
                  .map((field) => {
                    //console.log('2: ', field.code, field.value);
                    var formulaResult = parser.parse(field.formula) as {
                      error;
                      result;
                    };
                    if (field.unit == "%") {
                      field.value = formulaResult.result * 100;
                      parser.setVariable(field.code, field.value / 100);
                    } else {
                      field.value = formulaResult.result;
                      parser.setVariable(field.code, field.value);
                    }
                  });
              });

              question?.subquestions?.fields?.map((fieldRows) => {
                fieldRows
                  .filter((c) => c.formula != "")
                  .sort((a, b) => (a.code > b.code ? -1 : 1))
                  .map((field) => {
                    //console.log('2: ', field.code, field.value);
                    var formulaResult = parser.parse(field.formula) as {
                      error;
                      result;
                    };
                    if (field.unit == "%") {
                      field.value = formulaResult.result * 100;
                      parser.setVariable(field.code, field.value / 100);
                    } else {
                      field.value = formulaResult.result;
                      parser.setVariable(field.code, field.value);
                    }
                  });
              });

              if (
                question.kpiValue.formula &&
                question.kpiValue.formula != "" &&
                question.kpiValue.formula != "Sum_Planned/Length_Planned" &&
                question.kpiValue.formula != "(Sum_Actual/Sum_Planned)-1" &&
                question.kpiValue.formula !=
                  "(Sum_Actual-Sum_Planned)/Sum_Planned"
              ) {
                var formulaResult2 = parser.parse(
                  question.kpiValue.formula
                ) as {
                  error;
                  result;
                };
                if (formulaResult2.result != null) {
                  if (question.kpiValue.unit == "%") {
                    question.kpiValue.value = formulaResult2.result * 100;
                  } else {
                    question.kpiValue.value = formulaResult2.result;
                  }
                } else {
                }
              } else {
              }
              question.kpiDegreeOfFulfillment =
                CalculateKpiDegreeForOneOfFulfillment(question) * 100;
            });
            kpiLevel.kpiValue = CalculateKPIDegreeOfFulfillment(kpiLevel) * 100;
          });
          successFactor.efDegreeOfFulfillment =
            CalculateEfDegreeOfFulfillment(successFactor) * 100;
          successFactor.kpiValue = successFactor.efDegreeOfFulfillment;
        });
        sublever.tshDegreeOfFulfillment =
          CalculateTshDegreeOfFulfillment(sublever) * 100;
        sublever.kpiValue = sublever.tshDegreeOfFulfillment;
      });
      lever.kpiValue = CalculateLeverKPIValue(lever) * 100;
    });

    return levers;
  }

  // a state where to observe the R&D (inputs) state
  const [formState, setFormState] = useState<
    "unchanged" | "modified" | "saving"
  >("unchanged");

  // Show an exit prompt if the formState is NOT
  // unchanged (modified or being saved) to prevent losing enterd values.
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };
    if (formState !== "unchanged") {
      window.addEventListener("beforeunload", handler);
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    return () => {};
  }, [formState]);

  const [oldResult, setOldResult] = useState([]);

  useEffect(() => {
    console.log("props.leverData", props.leverData);
    if (oldResult == null || oldResult.length == 0) {
      console.log("update oldResult");
      if (props.leverData) {
        setOldResult(
          _.cloneDeep(
            props.leverData.map((c) => {
              return { id: c.id, value: c.kpiValue };
            })
          )
        );
      }
    } else {
      let notChanged: boolean = isEqual(
        oldResult,
        props.leverData.map((c) => {
          return { id: c.id, value: c.kpiValue };
        })
      );
      //console.log("isEqual(oldResult, props.viewState.viewResult) ", notChanged, oldResult, props.leverData.map(c => { return { id: c.id, value: c.kpiValue } }));
      if (!notChanged) {
        setFormState("modified");
      } else {
        setFormState("unchanged");
      }
    }
  }, [props.leverData]);

  const onClickSaveLever = () => {
    var totalOf3521 = 0;
    var list = [];
    console.log(listOfEditedQuestions);
    props.leverData.map((l) => {
      l.subLevers.map((sub) => {
        sub.successFactors.map((sf) => {
          sf.kpiLevels.map((kpi) => {
            kpi.questions.map((qu) => {
              if (
                qu.code == "q_3_5_2_1_1" ||
                qu.code == "q_3_5_2_1_4" ||
                qu.code == "q_3_5_2_1_7"
              ) {
                totalOf3521 = totalOf3521 + qu.kpiValue.value;
              }
              if (listOfEditedQuestions?.includes(qu.id)) {
                var temp: QuestionAnswer = {
                  answerValue: qu.kpiValue.value,
                  kpiDegreeOfFulFillment: qu.kpiDegreeOfFulfillment,
                  questionId: qu.id,
                  customerComment: qu.currentCustomerComment,
                  answerSubquestions: qu.subquestions,
                  questionStatusId: qu.statusId,
                  isEstimated: qu.isEstimated,
                };
                list.push(temp);
              }
            });
          });
        });
      });
    });
    if (totalOf3521 > 100.05) {
      console.log("totalOf3521", totalOf3521);
      openNotificationWithIcon(
        "warning",
        t("Error In Question Value"),
        t("The sum of values in question 3.5.2.1 must be 100% or less")
      );
      return;
    }
    var model: QuestionAnswersDto = {
      bmProjectId: props.profileInfo?.currentProject.id,
      questionAnswers: list,
    };

    props.saveLeversByConsultant(model, props.profileInfo?.currentProject?.id);

    setFormState("unchanged");
    setOldResult(null);
  };

  const changeColumnsWidth = (checked: boolean) => {
    // console.log("changeColumnsWidth , ", checked);
    let configTemp = { ...config };
    if (checked) {
      configTemp.wideView = true;
    } else {
      configTemp.wideView = false;
    }
    configTemp.columns = props.profileInfo?.roles?.includes(BasicRole.Customer)
      ? (getLeversCustomerTableHeader(!configTemp.wideView) as any[])
      : (getLeversTableHeader(!configTemp.wideView) as any[]);
    configTemp.columns = configTemp.columns.filter(
      (c) =>
        filteredColuns.find((obj) => {
          return obj.key == c.key;
        }) == null
    );
    configTemp.columnsWidth = configTemp.wideView ? 120 : 120;
    setConfig(configTemp);
  };

  const columnsFilter = (obj: any) => {
    try {
      //console.log(name);
      let configTemp = { ...config };
      configTemp.columns = configTemp.columns.filter((columns) => {
        // 👇️ remove object that has id equal to 2
        return columns.key !== obj.key;
      });
      setConfig(configTemp);
      localStorage.setItem("leverConfigTemp", JSON.stringify(configTemp));
      let filtered = [...filteredColuns];
      filtered.push(obj);
      localStorage.setItem("leverFilterColumn", JSON.stringify(filtered));
      setFilteredColuns(filtered);
    } catch (e) {
      console.log("columnsFilter", e);
    }
  };

  const columnsFilterAdd = (obj: any) => {
    try {
      // console.log(index);
      let columns = [...config.columns];
      columns.push(obj);
      setConfig({ ...config, columns: columns });
      localStorage.setItem(
        "leverConfigTemp",
        JSON.stringify({ ...config, columns: columns })
      );
      setFilteredColuns((current) =>
        current.filter((columns) => {
          return columns.key !== obj.key;
        })
      );

      localStorage.setItem(
        "leverFilterColumn",
        JSON.stringify(filteredColuns.filter((c) => c.key !== obj.key))
      );
    } catch (e) {
      console.log("columnsFilter", e);
    }
  };

  const columnsFilterOnCollectionRole = (obj: IListItem) => {
    localStorage.removeItem("leverCollectionRoles");
    let temp = [...collectionRoles];
    if (temp.find((c) => c.value == obj.value)) {
      temp = temp.filter((c) => c.value != obj.value);
    } else {
      temp.push(obj);
    }
    setCollectionRoles(temp);
    localStorage.setItem("leverCollectionRoles", JSON.stringify(temp));
    console.log(
      temp.map((c) => {
        return c.value;
      })
    );
    props.changeCollectionRoles(
      temp.map((c) => {
        return c.value;
      })
    );
  };

  const columnsFilterOnQuestionStatus = (obj: IListItem) => {
    localStorage.removeItem("leverQuestionStatuses");
    let temp = [...questionStatuses];
    if (temp.find((c) => c.value == obj.value)) {
      temp = temp.filter((c) => c.value != obj.value);
    } else {
      temp.push(obj);
    }
    setQuestionStatuses(temp);
    localStorage.setItem("leverQuestionStatuses", JSON.stringify(temp));
    console.log(
      temp.map((c) => {
        return c.value;
      })
    );
    props.changeQuestionStatus(
      temp.map((c) => {
        return c.value;
      })
    );
  };
  usePrompt(
    t("Sie haben nicht gespeicherte Änderungen, möchten Sie wirklich gehen?"),
    formState !== "unchanged"
  );

  const menu = (
    <Menu
      getPopupContainer={() => document.getElementById("app-layout")}
      theme="light"
    >
      <p>Shown items </p>
      {config.columns.map((col) => {
        if (
          col.key == "title" ||
          col.key == "tsh" ||
          col.key == "ef" ||
          col.key == "kpi" ||
          col.key == "question" ||
          col.key == "kpiWert"
        ) {
          return;
        }
        return (
          <Menu.Item
            key={col.key}
            className={` ${col.fixed ? "fixed-col" : ""}`}
          >
            <Checkbox key={col.key} onChange={() => columnsFilter(col)}>
              {col.title}
            </Checkbox>
          </Menu.Item>
        );
      })}

      <div className="hidden-items ">
        <p>hiden items </p>
        {filteredColuns.map((col) => {
          return (
            <Menu.Item key={col.key}>
              <Checkbox
                defaultChecked
                key={col.title}
                onChange={() => columnsFilterAdd(col)}
                //onChange={() => columnsFilterAdd(col.key, col.index)}
              >
                {col.title}
              </Checkbox>
            </Menu.Item>
          );
        })}
      </div>
    </Menu>
  );

  const menuCollectionRole = (
    <Menu theme="light">
      {props.profileInfo?.generalSettings?.collectionRoles?.map((col) => {
        return (
          <Menu.Item key={`col_role_${col.value}`}>
            <Checkbox
              key={`ck_cr_key_${col.value}`}
              checked={
                collectionRoles.find((c) => c.value == col.value) != null
              }
              onChange={() => columnsFilterOnCollectionRole(col)}
            >
              {col.label}
            </Checkbox>
          </Menu.Item>
        );
      }) ?? []}
    </Menu>
  );

  const menuQuestionStatus = (
    <Menu
      getPopupContainer={() => document.getElementById("app-layout")}
      theme="light"
    >
      {props.profileInfo?.generalSettings?.questionStatusList?.map((col) => {
        return (
          <Menu.Item key={`col_question_status_${col.value}`}>
            <Checkbox
              key={`ck_qs_key_${col.value}`}
              checked={
                questionStatuses.find((c) => c.value == col.value) != null
              }
              onChange={() => columnsFilterOnQuestionStatus(col)}
            >
              {col.label}
            </Checkbox>
          </Menu.Item>
        );
      }) ?? []}
    </Menu>
  );

  console.log("lever", props.leverData);
  if (
    (props.leverData as ILever[]) &&
    (props.leverData as ILever[]).length <= 0
  ) {
    return (
      <Spin spinning={props.isLoading}>
        {" "}
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No Content"}
        />
      </Spin>
    );
  }

  const operations = (
    <Space size={16} align="center">
      <Button type="default" icon={<SaveOutlined />} onClick={onClickSaveLever}>
        {`${t("Save All")}`}
      </Button>
    </Space>
  );

  const items = props.leverData.map(({ ...lever }, i) => {
    let tabName = !props.profileInfo?.roles?.includes(BasicRole.Customer) ? (
      <Badge
        size="small"
        title={`${t("Need Review")}`}
        count={lever.countOfUnReviewedAnswers}
      >
           <>{lever.number}{" "}{lever.name.toUpperCase()}</>{" "}
        <Tag color="geekblue">
          {formatValue(lever.kpiValue)}&nbsp;{"%"}
        </Tag>
      </Badge>
    ) : (
      <>{lever.number}{" "}{lever.name.toUpperCase()}</>
    );
    return {
      label: tabName,
      key: `tabpane_${i + 1}`,
      children: (
        <>
          <div className="table-actions-filter">
            <Space size={16} align="center" wrap={false}>
              <Switch
                defaultChecked={true}
                checkedChildren={`${t("Narrow")}`}
                unCheckedChildren={`${t("Wide")}`}
                onChange={changeColumnsWidth.bind(this)}
                className="table-mode"
              />
              {!props.profileInfo?.roles?.includes(BasicRole.Customer) ? (
                <Button
                  type="default"
                  icon={
                    allQuestionsSelected ? (
                      <CheckSquareOutlined />
                    ) : (
                      <MinusSquareOutlined />
                    )
                  }
                  onClick={(e) => {
                    props.changeAllSelectedQuestions(!allQuestionsSelected);
                    localStorage.setItem(
                      "leverAllQuestionsSelected",
                      (!allQuestionsSelected).toString()
                    );
                    setAllQuestionsSelected(!allQuestionsSelected);
                  }}
                >
                  {`${
                    allQuestionsSelected ? t("Uncheck all") : t("Check all")
                  }(${props.selectedQuestions?.length}  ${t("selected")})`}
                </Button>
              ) : null}
              {props.selectedQuestions.length == 0 ||
              props.profileInfo?.roles?.includes(BasicRole.Customer) ? null : (
                <ContactsMng />
              )}
              {!props.profileInfo?.roles?.includes(BasicRole.Customer) ? (
                <Popover
                  getPopupContainer={() =>
                    document.getElementById("app-layout")
                  }
                  overlayClassName="columns-collection-role-filter-popover"
                  className="columns-collection-role-filter"
                  content={menuCollectionRole}
                  trigger="click"
                  placement="bottom"
                >
                  <Button type="default">
                    {`${t("Erfassung")} (${
                      props.selectedCollectionRoles.length
                    } ${t("selected")})`}
                  </Button>
                </Popover>
              ) : null}
              {!props.profileInfo?.roles?.includes(BasicRole.Customer) ? (
                <Popover
                  getPopupContainer={() =>
                    document.getElementById("app-layout")
                  }
                  overlayClassName="columns-question-status-filter-popover"
                  className="columns-question-status-filter"
                  content={menuQuestionStatus}
                  trigger="click"
                  placement="bottom"
                >
                  <Button type="default">
                    {`Status (${props.selectedQuestionStatuses.length} ${t(
                      "selected"
                    )})`}
                  </Button>
                </Popover>
              ) : null}
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                overlayClassName="columns-filter-popover"
                className="columns-filter"
                content={menu}
                trigger="click"
                placement="bottom"
              >
                <Button type="default">
                  {`${t("Column options")} (${filteredColuns.length} ${t(
                    "hidden"
                  )})`}
                </Button>
              </Popover>
            </Space>
          </div>
          <LeversTable
            key={`levertable_${i + 1}`}
            saveLevers={onClickSaveLever}
            onChangeQuestion={(questionId: number) =>
              updateListOfEditedQuestions(questionId)
            }
            onchangeSubquestionInput={onchangeSubquestionInput.bind(this)}
            onchangeSubquestionTable={onchangeSubquestionTable.bind(this)}
            lever={lever}
            isCustomer={props.profileInfo?.roles?.includes(BasicRole.Customer)}
            config={config}
          />
        </>
      ),
    };
  });
  if (!props.profileInfo?.roles?.includes(BasicRole.Customer)) {
    items.push({
      label: <>{"REPORTS"}</>,
      key: "REPORTS-tab",
      children: <AllCharts />,
    });
    items.push({
      label: <>{t("Tipps and Tricks")}</>,
      key: "tips-tab",
      children: <HelpPage currentPageCode="levers" />,
    });
  }

  return (
    <Spin spinning={props.isLoading}>
      {" "}
      <div id="lever-section">
        <Tabs
          tabPosition="top"
          defaultActiveKey="tabpane_1"
          type="line"
          size="small"
          key={"levers-tab"}
          id="levers-tab"
          tabBarExtraContent={operations}
          items={items}
        />
      </div>
    </Spin>
  );
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    isLoading: state.leverTool.isLeverLoading,
    leverData: state.leverTool.leverData,
    selectedQuestions: state.leverTool.selectedQuestions,
    profileInfo: state.generalInfo.profileResponse,
    selectedCollectionRoles: state.leverTool.selectedCollectionRoles,
    selectedQuestionStatuses: state.leverTool.selectedQuestionStatuses,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLevers: (projectID: number) =>
      dispatch({ type: ActionTypes.GetLevers, projectID: projectID }),
    changeAllSelectedQuestions: (selectedAll: boolean) =>
      dispatch({
        type: ActionTypes.ChangeAllSelectedQuestions,
        selectedAll: selectedAll,
      }),
    changeLevers: (model: ILever[]) =>
      dispatch({ type: ActionTypes.ChangeLevers, model: model }),
    changeCollectionRoles: (model: number[]) =>
      dispatch({ type: ActionTypes.ChangeCollectionRole, model: model }),
    changeQuestionStatus: (model: number[]) =>
      dispatch({ type: ActionTypes.ChangeQuestionStatuses, model: model }),
    saveLevers: (model: QuestionAnswersDto, bmProjectId: number) =>
      dispatch({
        type: ActionTypes.SaveAllLevers,
        model: model,
        bmProjectId: bmProjectId,
      }),
    saveLeversByConsultant: (model: QuestionAnswersDto, bmProjectId: number) =>
      dispatch({
        type: ActionTypes.SaveAllLeversByConsultant,
        model: model,
        bmProjectId: bmProjectId,
      }),
    //leversUnmount: () => dispatch({ type: ActionTypes.LeversUnmount }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeversTool);
