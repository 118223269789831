import { ActionTypes } from "../actionsTypes";
import { ISelfAssessmentToolState } from "./IGeneralState";

const initialState: ISelfAssessmentToolState = {
  isLoading: false,
  viewResult: null,
  topRated: null, 
};

const SelfAssessmentToolReducer = (state = initialState, action: any): ISelfAssessmentToolState => {
  switch (action.type) {
    case ActionTypes.ChangeSelfAssessementLoader:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.SetSelfAssessmentView:
      return {
        ...state,
        viewResult: {...action.result},
      };  
      case ActionTypes.SetSelfAssessmentTopRated:
        return {
          ...state,
          topRated: action.result,
        }; 
    default:
      break;
  }
  return state;
};
export default SelfAssessmentToolReducer;

