import { Checkbox, Collapse, List, Popover, Space } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import ReactDragListView from "react-drag-listview";
import { ColumnGroupType, ColumnsType } from "antd/es/table";
import { IDProject } from "../../../../Models/IDProject";
import { dprojectsColumns } from "../DataType/TableCols";
import './coloptions.scss';
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { CaretRightOutlined } from '@ant-design/icons';
import { dprojectEbitCostsType, dprojectGeneralDataType, dProjectQuestionnairesType, _dprojectEbitCostsType, _dprojectGeneralDataType, _dProjectQuestionnairesType } from "../DataType/types";
import { t } from "i18next";


interface IProps {


    Shown_GeneralInfo: _dprojectGeneralDataType,
    Hidden_GeneralInfo: dprojectGeneralDataType

    shown_EbitCosts: _dprojectEbitCostsType
    Hidden_EbitCosts: dprojectEbitCostsType

    Shown_Questionnaires: _dProjectQuestionnairesType
    Hidden_Questionnaires: dProjectQuestionnairesType


    columns: ColumnsType<IDProject>;
    hiddenColumns: typeof dprojectsColumns;
    //changeColVisibility: (parentKey: string, childKey: string, visibile: boolean) => void;
    //changeOrder: (fromIndex: number, toIndex: number, parentKey?: string) => void
    hideGeneralInfoPanel: () => void,
    showGeneralInfoPanel: () => void,
    hideGeneralInfoThirdLevelItem: (thirdItemKey: string) => void,
    showGeneralInfoThirdLevelItem: (thirdItemKey: string) => void,
    hideEbitCostsPanel: () => void,
    showEbitCostsPanel: () => void,
    hideEbitCostsSecondLevelItem: (itemKey: string) => void,
    showEbitCostsSecondLevelItem: (itemKey: string) => void,
    hideEbitCostsThirdLevelItem: (itemKey: string) => void,
    showEbitCostsThirdLevelItem: (itemKey: string) => void,
    hideQuestionnairePanel: () => void,
    showQuestionnairePanel: () => void,
    hideLeverItem: (leverId: string) => void,
    showLeverItem: (leverId: string) => void,
    hideSubLeverItem: (suLeverId: string) => void,
    showSubLeverItem: (suLeverId: string) => void,
    hideSecondLevelItem: (itemKey: string) => void,
    showSecondLevelItem: (itemKey: string) => void,
    hideThirdLevelItem: (itemKey: string) => void,
    showThirdLevelItem: (itemKey: string) => void,
    onDragGeneralInfoThirdItemEnd: (fromIndex: number, toIndex: number) => void,
    onDragEbitCostsSecondLevelEnd: (fromIndex: number, toIndex: number) => void,
    onDragEbitCostsThirdLevelEnd: (fromIndex: number, toIndex: number, costKey: string) => void,
    onDragQuestionnairesLeversEnd: (fromIndex: number, toIndex: number) => void,
    onDragQuestionnairesSubLeversEnd: (fromIndex: number, toIndex: number, leverId: string) => void,
    onDragQuestionnairesSecondLevelEnd: (fromIndex: number, toIndex: number, leverId: string, supLeverId: string) => void,
    onDragQuestionnairesThirdLevelEnd: (
        fromIndex: number, toIndex: number,
        leverId: string, supLeverId: string,
        secondLevelItemKey: string
    ) => void,
    onDragFirstLevelEnd: (fromIndex: number, toIndex: number) => void
};

function ColOptions(props: IProps) {

    // const hideColumn = (parentKey: string, childKey?: string) => {
    //     props.changeColVisibility(parentKey, childKey, false);
    // };

    // const showColumn = (parentKey: string, childKey?: string) => {
    //     props.changeColVisibility(parentKey, childKey, true);
    // };

    // const onDragParentEnd = (fromIndex: number, toIndex: number) => {
    //     props.changeOrder(fromIndex, toIndex);
    // };
    // const onDragChildEnd = (fromIndex: number, toIndex: number, parentKey: string) => {
    //     props.changeOrder(fromIndex, toIndex, parentKey);
    // };


    const hideGeneralInfoPanel = () => {
        props.hideGeneralInfoPanel();
    };
    const showGeneralInfoPanel = () => {
        props.showGeneralInfoPanel();
    };
    const showGeneralInfoThirdLevelItem = (itemKey: string) => {
        props.showGeneralInfoThirdLevelItem(itemKey);
    }
    const hideGeneralInfoThirdLevelItem = (itemKey: string) => {
        props.hideGeneralInfoThirdLevelItem(itemKey);
    };

    const hideEbitCostsPanel = () => {
        props.hideEbitCostsPanel();
    };
    const showEbitCostsPanel = () => {
        props.showEbitCostsPanel();
    };
    const hideEbitCostsSecondLevelItem = (itemKey: string) => {
        props.hideEbitCostsSecondLevelItem(itemKey);
    };
    const showEbitCostsSecondLevelItem = (itemKey: string) => {
        props.showEbitCostsSecondLevelItem(itemKey);
    };
    const hideEbitCostsThirdLevelItem = (itemKey: string) => {
        props.hideEbitCostsThirdLevelItem(itemKey);
    };
    const showEbitCostsThirdLevelItem = (itemKey: string) => {
        props.showEbitCostsThirdLevelItem(itemKey);
    };
    const showQuestionnairePanel = () => {
        props.showQuestionnairePanel();
    };
    const hideQuestionnairePanel = () => {
        props.hideQuestionnairePanel();
    };
    const hideLeverItem = (leverId: string) => {
        props.hideLeverItem(leverId)
    };
    const showLeverItem = (leverId: string) => {
        props.showLeverItem(leverId);
    };
    const hideSubLeverItem = (subLeverId: string) => {
        props.hideSubLeverItem(subLeverId);
    };
    const showSubLeverItem = (subLeverId: string) => {
        props.showSubLeverItem(subLeverId);
    };
    const hideSecondLevelItem = (itemKey: string) => {
        props.hideSecondLevelItem(itemKey);
    };
    const showSecondLevelItem = (itemKey: string) => {
        props.showSecondLevelItem(itemKey);
    };
    const hideThirdLevelItem = (itemKey: string) => {
        props.hideThirdLevelItem(itemKey);
    };
    const showThirdLevelItem = (itemKey: string) => {
        props.showThirdLevelItem(itemKey);
    };
    const onDragGeneralInfoThirdItemEnd = (fromIndex: number, toIndex: number) => {
        props.onDragGeneralInfoThirdItemEnd(fromIndex, toIndex);
    };
    const onDragEbitCostsSecondLevelEnd = (fromIndex: number, toIndex: number) => {
        props.onDragEbitCostsSecondLevelEnd(fromIndex, toIndex);
    }
    const onDragEbitCostsThirdLevelEnd = (fromIndex: number, toIndex: number, costKey: string) => {
        props.onDragEbitCostsThirdLevelEnd(fromIndex, toIndex, costKey)
    };
    const onDragQuestionnairesLeversEnd = (fromIndex: number, toIndex: number) => {
        props.onDragQuestionnairesLeversEnd(fromIndex, toIndex);
    };
    const onDragQuestionnairesSubLeversEnd = (fromIndex: number, toIndex: number, leverId: string) => {
        props.onDragQuestionnairesSubLeversEnd(fromIndex, toIndex, leverId);
    };
    const onDragQuestionnairesSecondLevelEnd = (
        fromIndex: number, toIndex: number,
        leverId: string, supLeverId: string
    ) => {
        props.onDragQuestionnairesSecondLevelEnd(fromIndex, toIndex, leverId, supLeverId);
    }
    const onDragQuestionnairesThirdLevelEnd = (
        fromIndex: number, toIndex: number,
        leverId: string, supLeverId: string,
        secondLevelItemKey: string
    ) => {
        props.onDragQuestionnairesThirdLevelEnd(
            fromIndex, toIndex,
            leverId, supLeverId,
            secondLevelItemKey
        );
    };
    const onDragFirstLevelEnd = (fromIndex: number, toIndex: number) => {
        props.onDragFirstLevelEnd(fromIndex, toIndex);
    }
    const { Panel } = Collapse;
    //console.log('cols from coloptions: ', props.columns);

    //group questionnaire cols based on tsh.

    //end group questionnaire cols based on tsh.


    const generalInfoPanel =
        <Panel
            header={
                <Checkbox checked={false} onChange={() => { hideGeneralInfoPanel() }}>
                    General project data
                </Checkbox>
            }
            key='generalInfoPanel'
            className="general_info_panel">
            <div className="parent_draggable_div">
                <List
                    key="generalInfoPanel_ListItems"
                    className='child_List_class'>
                    <ReactDragListView.DragColumn
                        nodeSelector=".coloption_class"
                        lineClassName="dragLine"
                        onDragEnd={(fromIndex, toIndex) => onDragGeneralInfoThirdItemEnd(fromIndex, toIndex)}>
                        {
                            props.Shown_GeneralInfo.children.map((child, idx) => {
                                if (child.key === 'Name') {
                                    //return null;
                                    return (
                                        <List.Item key={child.key} className='coloption_class'>
                                            <Checkbox
                                                disabled
                                                key={`check_${child.key}`}
                                                checked={false}
                                            //onChange={() => hideGeneralInfoThirdLevelItem(child.key as string)}
                                            >
                                                {child.key}
                                            </Checkbox>
                                        </List.Item>
                                    );
                                } else {
                                    return (
                                        <List.Item key={child.key} className='coloption_class'>
                                            <Checkbox
                                                key={`check_${child.key}`}
                                                checked={false}
                                                onChange={() => hideGeneralInfoThirdLevelItem(child.key as string)}>
                                                {child.key}
                                            </Checkbox>
                                        </List.Item>
                                    );
                                }
                            })
                        }
                    </ReactDragListView.DragColumn>
                </List>
            </div>
        </Panel>

    const hidden_generalInfoPanel =
        <Panel
            header={
                <Checkbox checked={true} onChange={() => { showGeneralInfoPanel() }}>
                    General project data
                </Checkbox>
            }
            key='generalInfoPanel'>
            <div className="parent_draggable_div">
                <List
                    key="generalInfoPanel_ListItems"
                    className='child_List_class'>
                    {
                        props.Hidden_GeneralInfo?.children.map((child, idx) => {
                            if (child.key === 'Name') {
                                //return null;
                                return (
                                    <List.Item key={child.key} className='coloption_class'>
                                        <Checkbox
                                            disabled
                                            key={`check_${child.key}`}  
                                            checked={true}
                                        //onChange={() => hideColumn(col.key as string, child.key as string)}
                                        >
                                            {child.key}
                                        </Checkbox>
                                    </List.Item>
                                );
                            } else {
                                return (
                                    <List.Item key={child.key} className='coloption_class'>
                                        <Checkbox
                                            key={`check_${child.key}`}
                                            checked={true}
                                            onChange={() => showGeneralInfoThirdLevelItem(child.key as string)}>
                                            {child.key}
                                        </Checkbox>
                                    </List.Item>
                                );
                            }
                        })
                    }
                </List>
            </div>
        </Panel>

    const ebitCostsPanel =
        <Panel
            header={
                <Checkbox checked={false} onChange={() => hideEbitCostsPanel()}>
                    Ebit Costs
                </Checkbox>
            }
            key="ebitCostsPanel"
            className="first_level_panels">
            <ReactDragListView.DragColumn
                nodeSelector=".ebitcosts-secondlevel-colapses-panels"
                lineClassName="dragLine"
                onDragEnd={(fromIndex, toIndex) => onDragEbitCostsSecondLevelEnd(fromIndex, toIndex)}>
                <Collapse
                    accordion={true}
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="col_options_collapse"
                    expandIconPosition='end'>
                    {props.shown_EbitCosts?.children.map((cost) => {
                        return (
                            <Panel
                                header={
                                    <Checkbox checked={false} onChange={() => hideEbitCostsSecondLevelItem(cost.key as string)}>
                                        {cost.key}
                                    </Checkbox>
                                }
                                key={`ebitCostsPanel_${cost.key}`}
                                className="ebitcosts-secondlevel-colapses-panels">

                                <div className="parent_draggable_div">
                                    <List
                                        key={`List_${cost.key}`}
                                        className='child_List_class'>
                                        <ReactDragListView.DragColumn
                                            nodeSelector=".ebitcosts-thirdlevel-listitems"
                                            lineClassName="dragLine"
                                            onDragEnd={(fromIndex, toIndex) => onDragEbitCostsThirdLevelEnd(fromIndex, toIndex, cost.key as string)}>
                                            {
                                                (cost as ColumnGroupType<IDProject>).children.map((child, idx) => {
                                                    return (
                                                        <List.Item key={child.key} className='ebitcosts-thirdlevel-listitems'>
                                                            <Checkbox
                                                                key={`check_${child.key}`}
                                                                checked={false}
                                                                onChange={() => hideEbitCostsThirdLevelItem(child.key as string)}>
                                                                {child.key as string}
                                                            </Checkbox>
                                                        </List.Item>
                                                    );
                                                })
                                            }
                                        </ReactDragListView.DragColumn>
                                    </List>
                                </div>
                            </Panel>
                        )
                    })}
                </Collapse>
            </ReactDragListView.DragColumn>
        </Panel>

    const hidden_ebitCostsPanel =
        <Panel
            header={
                <Checkbox checked={true} onChange={() => showEbitCostsPanel()}>
                    Ebit Costs
                </Checkbox>
            }
            key="ebitCostsPanel">
            <Collapse
                accordion={true}
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="col_options_collapse"
                expandIconPosition='end'>
                {props.Hidden_EbitCosts?.children.map((cost) => {
                    return (
                        <Panel
                            header={
                                <Checkbox checked={true} onChange={() => showEbitCostsSecondLevelItem(cost.key as string)}>
                                    {cost.key}
                                </Checkbox>
                            }
                            key={`ebitCostsPanel_${cost.key}`}>

                            <div className="parent_draggable_div">
                                <List
                                    key={`List_${cost.key}`}
                                    className='child_List_class'>
                                    {
                                        (cost as ColumnGroupType<IDProject>).children.map((child, idx) => {
                                            return (
                                                <List.Item key={child.key} className='coloption_class'>
                                                    <Checkbox
                                                        key={`check_${child.key}`}
                                                        checked={true}
                                                        onChange={() => showEbitCostsThirdLevelItem(child.key as string)}>
                                                        {child.key as string}
                                                    </Checkbox>
                                                </List.Item>
                                            );
                                        })
                                    }
                                </List>
                            </div>
                        </Panel>
                    )
                })}
            </Collapse>
        </Panel>


    const questionnairesPannel =
        <Panel
            header={
                <Checkbox checked={false} onChange={() => hideQuestionnairePanel()}>
                    Questionnaires
                </Checkbox>
            }
            key="questionnairesPannel"
            className="first_level_panels">
            <ReactDragListView.DragColumn
                nodeSelector=".Questionnaire_lever_Panel"
                lineClassName="dragLine"
                onDragEnd={(fromIndex, toIndex) => onDragQuestionnairesLeversEnd(fromIndex, toIndex)}>
                <Collapse
                    accordion={true}
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="col_options_collapse"
                    expandIconPosition='end'>
                    {
                        props.Shown_Questionnaires?.children.map((lever) => {
                            return (
                                <Panel
                                    header={
                                        <Checkbox checked={false} onChange={() => hideLeverItem(lever.id)}>
                                            {`${lever.id} ${lever.title}`}
                                        </Checkbox>
                                    }
                                    key={`questionnairesPannel_${lever.id}`}
                                    className="Questionnaire_lever_Panel">

                                    <ReactDragListView.DragColumn
                                        nodeSelector=".Questionnaire_sublever_Panel"
                                        lineClassName="dragLine"
                                        onDragEnd={(fromIndex, toIndex) => onDragQuestionnairesSubLeversEnd(fromIndex, toIndex, lever.id)}>
                                        <Collapse
                                            accordion={true}
                                            bordered={false}
                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            className="col_options_collapse"
                                            expandIconPosition='end'>
                                            {
                                                lever.children.map((supLever) => {
                                                    return (
                                                        <Panel
                                                            header={
                                                                <Checkbox checked={false} onChange={() => hideSubLeverItem(supLever.id)}>
                                                                    {`${supLever.id} ${supLever.title}`}
                                                                </Checkbox>
                                                            }
                                                            key={`${lever.title}_${supLever.id}`}
                                                            className="Questionnaire_sublever_Panel">
                                                            <ReactDragListView.DragColumn
                                                                nodeSelector=".Questionnaire_scndlevelItems_Panel"
                                                                lineClassName="dragLine"
                                                                onDragEnd={(fromIndex, toIndex) => onDragQuestionnairesSecondLevelEnd(fromIndex, toIndex, lever.id, supLever.id)}>
                                                                <Collapse
                                                                    accordion={true}
                                                                    bordered={false}
                                                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                                    className="col_options_collapse"
                                                                    expandIconPosition='end'>
                                                                    {
                                                                        supLever.children.map((secondLevelItem) => {
                                                                            return (
                                                                                <Panel
                                                                                    header={
                                                                                        <Checkbox checked={false} onChange={() => hideSecondLevelItem(secondLevelItem.key as string)}>
                                                                                            {secondLevelItem.title}
                                                                                        </Checkbox>
                                                                                    }
                                                                                    key={`${supLever.title}_${secondLevelItem.key}`}
                                                                                    className="Questionnaire_scndlevelItems_Panel">
                                                                                    <div className="parent_draggable_div">
                                                                                        <List
                                                                                            key={`List_${secondLevelItem.key}`}
                                                                                            className='child_List_class'>
                                                                                            <ReactDragListView.DragColumn
                                                                                                nodeSelector=".Questionnaire_thirdlevelItems_Panel"
                                                                                                lineClassName="dragLine"
                                                                                                onDragEnd={(fromIndex, toIndex) => onDragQuestionnairesThirdLevelEnd(fromIndex, toIndex, lever.id, supLever.id, secondLevelItem.key)}>
                                                                                                {
                                                                                                    (secondLevelItem as ColumnGroupType<IDProject>).children.map((child, idx) => {
                                                                                                        return (
                                                                                                            <List.Item key={child.key} className='Questionnaire_thirdlevelItems_Panel'>
                                                                                                                <Checkbox
                                                                                                                    key={`check_${child.key}`}
                                                                                                                    checked={false}
                                                                                                                    onChange={() => hideThirdLevelItem(child.key as string)}>
                                                                                                                    {child.key as string}
                                                                                                                </Checkbox>
                                                                                                            </List.Item>
                                                                                                        );
                                                                                                    })
                                                                                                }
                                                                                            </ReactDragListView.DragColumn>
                                                                                        </List>
                                                                                    </div>
                                                                                </Panel>
                                                                            )
                                                                        })
                                                                    }
                                                                </Collapse>
                                                            </ReactDragListView.DragColumn>
                                                        </Panel>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                    </ReactDragListView.DragColumn>
                                </Panel>
                            )
                        })
                    }
                </Collapse>
            </ReactDragListView.DragColumn>
        </Panel>

    const hidden_questionnairesPannel =
        <Panel
            header={
                <Checkbox checked={true} onChange={() => showQuestionnairePanel()}>
                    Questionnaires
                </Checkbox>
            }
            key="questionnairesPannel">
            <Collapse
                accordion={true}
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="col_options_collapse"
                expandIconPosition='end'>
                {
                    props.Hidden_Questionnaires?.children.map((lever) => {
                        return (
                            <Panel
                                header={
                                    <Checkbox checked={true} onChange={() => showLeverItem(lever.id)}>
                                        {`${lever.id} ${lever.title}`}
                                    </Checkbox>
                                }
                                key={`questionnairesPannel_${lever.id}`}>
                                <Collapse
                                    accordion={true}
                                    bordered={false}
                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                    className="col_options_collapse"
                                    expandIconPosition='end'>
                                    {
                                        lever.children.map((supLever) => {
                                            return (
                                                <Panel
                                                    header={
                                                        <Checkbox checked={true} onChange={() => showSubLeverItem(supLever.id)}>
                                                            {`${supLever.id} ${supLever.title}`}
                                                        </Checkbox>
                                                    }
                                                    key={`${lever.title}_${supLever.id}`}>
                                                    <Collapse
                                                        accordion={true}
                                                        bordered={false}
                                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                        className="col_options_collapse"
                                                        expandIconPosition='end'>
                                                        {
                                                            supLever.children.map((secondLevelItem) => {
                                                                return (
                                                                    <Panel
                                                                        header={
                                                                            <Checkbox checked={true} onChange={() => showSecondLevelItem(secondLevelItem.key as string)}>
                                                                                {secondLevelItem.title}
                                                                            </Checkbox>
                                                                        }
                                                                        key={`${supLever.title}_${secondLevelItem.key}`}>
                                                                        <div className="parent_draggable_div">
                                                                            <List
                                                                                key={`List_${secondLevelItem.key}`}
                                                                                className='child_List_class'>
                                                                                {
                                                                                    (secondLevelItem as ColumnGroupType<IDProject>).children.map((child, idx) => {
                                                                                        return (
                                                                                            <List.Item key={child.key} className='coloption_class'>
                                                                                                <Checkbox
                                                                                                    key={`check_${child.key}`}
                                                                                                    checked={true}
                                                                                                    onChange={() => showThirdLevelItem(child.key as string)}>
                                                                                                    {child.key as string}
                                                                                                </Checkbox>
                                                                                            </List.Item>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </List>
                                                                        </div>
                                                                    </Panel>
                                                                )
                                                            })
                                                        }
                                                    </Collapse>
                                                </Panel>
                                            )
                                        })
                                    }
                                </Collapse>
                            </Panel>
                        )
                    })
                }
            </Collapse>
        </Panel>

    let secOrder = null;
    let thirdOrder = null;

    if (props.shown_EbitCosts && props.shown_EbitCosts?.order === 2) {
        secOrder = ebitCostsPanel;
    }

    if (props.Shown_Questionnaires && props.Shown_Questionnaires?.order === 2) {
        secOrder = questionnairesPannel;
    }

    if (props.shown_EbitCosts && props.shown_EbitCosts?.order === 3) {
        thirdOrder = ebitCostsPanel;
    }

    if (props.Shown_Questionnaires && props.Shown_Questionnaires?.order === 3) {
        thirdOrder = questionnairesPannel;
    }

    const list_ =
        <div>
            {(props.Shown_GeneralInfo || props.shown_EbitCosts || props.Shown_Questionnaires) ? <div>
                <div>
                    Shown Columns
                </div>
                <div>
                    <ReactDragListView.DragColumn
                        nodeSelector=".first_level_panels"
                        lineClassName="dragLine"
                        onDragEnd={(fromIndex, toIndex) => onDragFirstLevelEnd(fromIndex, toIndex)}>
                        <Collapse
                            accordion={true}
                            bordered={false}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            className="col_options_collapse"
                            expandIconPosition='end'>
                            {props.Shown_GeneralInfo ? generalInfoPanel : null}
                            {secOrder}
                            {thirdOrder}
                        </Collapse>
                    </ReactDragListView.DragColumn>
                </div>
            </div> : null}
            {(props.Hidden_GeneralInfo || props.Hidden_EbitCosts || props.Hidden_Questionnaires) ? <div>
                <div>
                    Hidden Columns
                </div>
                <div>
                    <Collapse
                        accordion={true}
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        className="col_options_collapse"
                        expandIconPosition='end'>
                        {props.Hidden_GeneralInfo ? hidden_generalInfoPanel : null}
                        {props.Hidden_EbitCosts ? hidden_ebitCostsPanel : null}
                        {props.Hidden_Questionnaires ? hidden_questionnairesPannel : null}
                    </Collapse>
                </div>
            </div> : null}
        </div>

    return (
        <div className="col_options_wrapper">
            <Popover getPopupContainer={() => document.getElementById('app-layout')} className='columns-popover' content={list_} trigger="click" placement="bottom">
                <a href="#">
                    <Space>
                        <div className='BuildRoundedIcon_div'><SettingOutlined /></div>
                        <span className='col_options_span'>
                        {`${t("Column options")}`}
                        </span>
                    </Space>
                </a>
            </Popover>
        </div>
    );
}


const mapStateToProps = (state: any) => {
    return {

    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        //changeColVisibility: (parentKey: string, childKey: string, visible: boolean) => dispatch({ type: ActionTypes.CHNGDPROJECTCOLVISIBIITY, parentKey: parentKey, childKey: childKey, visible: visible }),
        hideGeneralInfoPanel: () => dispatch({ type: ActionTypes.HideGeneralInfoPanel }),
        showGeneralInfoPanel: () => dispatch({ type: ActionTypes.ShowGeneralInfoPanel }),
        hideGeneralInfoThirdLevelItem: (thirdItemKey: string) => dispatch({ type: ActionTypes.HideGeneralInfoThirdLevelItem, thirdItemKey: thirdItemKey }),
        showGeneralInfoThirdLevelItem: (thirdItemKey: string) => dispatch({ type: ActionTypes.ShowGeneralInfoThirdLevelItem, thirdItemKey: thirdItemKey }),
        hideEbitCostsPanel: () => dispatch({ type: ActionTypes.HideEbitCostsPanel }),
        showEbitCostsPanel: () => dispatch({ type: ActionTypes.ShowEbitCostsPanel }),
        hideEbitCostsSecondLevelItem: (itemKey: string) => dispatch({ type: ActionTypes.HideEbitCostsSecondLevelItem, itemKey: itemKey }),
        showEbitCostsSecondLevelItem: (itemKey: string) => dispatch({ type: ActionTypes.ShowEbitCostsSecondLevelItem, itemKey: itemKey }),
        hideEbitCostsThirdLevelItem: (itemKey: string) => dispatch({ type: ActionTypes.HideEbitCostsThirdLevelItem, itemKey: itemKey }),
        showEbitCostsThirdLevelItem: (itemKey: string) => dispatch({ type: ActionTypes.ShowEbitCostsThirdLevelItem, itemKey: itemKey }),
        hideQuestionnairePanel: () => dispatch({ type: ActionTypes.HideQuestionnairePanel }),
        showQuestionnairePanel: () => dispatch({ type: ActionTypes.ShowQuestionnairePanel }),
        hideLeverItem: (leverId: string) => dispatch({ type: ActionTypes.HideLeverItem, leverId: leverId }),
        showLeverItem: (leverId: string) => dispatch({ type: ActionTypes.ShowLeverItem, leverId: leverId }),
        hideSubLeverItem: (subLeverId: string) => dispatch({ type: ActionTypes.HideSubLeverItem, subLeverId: subLeverId }),
        showSubLeverItem: (subLeverId: string) => dispatch({ type: ActionTypes.ShowSubLeverItem, subLeverId: subLeverId }),
        hideSecondLevelItem: (itemKey: string) => dispatch({ type: ActionTypes.HideSecondLevelItem, itemKey: itemKey }),
        showSecondLevelItem: (itemKey: string) => dispatch({ type: ActionTypes.ShowSecondLevelItem, itemKey: itemKey }),
        hideThirdLevelItem: (itemKey: string) => dispatch({ type: ActionTypes.HideThirdLevelItem, itemKey: itemKey }),
        showThirdLevelItem: (itemKey: string) => dispatch({ type: ActionTypes.ShowThirdLevelItem, itemKey: itemKey }),
        onDragGeneralInfoThirdItemEnd: (fromIndex: number, toIndex: number) => dispatch({ type: ActionTypes.DragGeneralInfoThirdItemEnd, fromIndex: fromIndex, toIndex: toIndex }),
        onDragEbitCostsSecondLevelEnd: (fromIndex: number, toIndex: number) => dispatch({ type: ActionTypes.DragEbitCostsSecondLevelEnd, fromIndex: fromIndex, toIndex: toIndex }),
        onDragEbitCostsThirdLevelEnd: (fromIndex: number, toIndex: number, costKey: string) => dispatch({ type: ActionTypes.DragEbitCostsThirdLevelEnd, fromIndex: fromIndex, toIndex: toIndex, costKey: costKey }),
        onDragQuestionnairesLeversEnd: (fromIndex: number, toIndex: number) => dispatch({ type: ActionTypes.DragQuestionnairesLeversEnd, fromIndex: fromIndex, toIndex: toIndex }),
        onDragQuestionnairesSubLeversEnd: (fromIndex: number, toIndex: number, leverId: string) => dispatch({ type: ActionTypes.DragQuestionnairesSubLeversEnd, fromIndex: fromIndex, toIndex: toIndex, leverId: leverId }),
        onDragQuestionnairesSecondLevelEnd: (fromIndex: number, toIndex: number, leverId: string, supLeverId: string) => dispatch({ type: ActionTypes.DragQuestionnairesSecondLevelEnd, fromIndex: fromIndex, toIndex: toIndex, leverId: leverId, supLeverId: supLeverId }),
        onDragQuestionnairesThirdLevelEnd: (
            fromIndex: number, toIndex: number,
            leverId: string, supLeverId: string,
            secondLevelItemKey: string
        ) => dispatch({
            type: ActionTypes.DragQuestionnairesThirdLevelEnd,
            fromIndex: fromIndex, toIndex: toIndex,
            leverId: leverId, supLeverId: supLeverId,
            secondLevelItemKey: secondLevelItemKey
        }),
        onDragFirstLevelEnd: (fromIndex: number, toIndex: number) => dispatch({ type: ActionTypes.DragFirstLevelEnd })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ColOptions);
