import { Form, Input, Select, Space, Table, Tooltip } from 'antd';
import { SettingOutlined } from "@ant-design/icons";
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IIndustrySegmentItem } from '../../../Models/IIndustrySegment';
import { ActionTypes } from '../../../Redux/actionsTypes';
import AdminOperation from '../../../Components/AdminOperation/AdminOperation';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { RulesName } from '../Rules/RulesValidation';
import { t } from 'i18next';
interface IProps {
    dataRowSegments: IIndustrySegmentItem[],
    industries: any;
    industryId: number | null;
    updateIndustrySegment: (model: IIndustrySegmentItem, industryId: number) => any;
    deleteIndustrySegment: (id: number, industryId: number) => any;

}
const SegmentsTable = (props: IProps) => {
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "segEn_1",
            title: `${t("English Name")}`,
            dataIndex: 'codeEn',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='codeEn' rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })} initialValue={text} >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("English Name")}`, "codeEn", searchInput, handleSearch),
            sorter: (a, b) => a?.codeEn?.localeCompare(b?.codeEn) ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: "seg_2",
            title: `${t("German Name")}`,
            dataIndex: 'codeDe',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='codeDe' rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })} initialValue={text}>
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("German Name")}`, "codeDe", searchInput, handleSearch),
            sorter: (a, b) => a?.codeDe?.localeCompare(b.codeDe),
            // defaultSortOrder: 'ascend',
        },
        {
            title: `${t("Actions")}`,
            dataIndex: '',
            width: '100px',
            render: (_: any, record: IIndustrySegmentItem) => {
                const editable = isEditing(record);
                return <AdminOperation
                    deleteId={record.id}
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                    onDelete={deleteSegment}

                />
            },
        },
        {
            title: "Best Practices",
            dataIndex: '',
            width: '15%',
            fixed: "right",
            render: (_: any, record: any) => {
                return <Space>
                    <Tooltip title={`${t("Go to")} Best Practices`}>
                        <Link to={`/admin/industries/segments/bestPractice?segmentId=${record.id}`}>
                            Best Practices <SettingOutlined />
                        </Link>
                    </Tooltip>
                </Space>
            }
        }

    ];
    const isEditing = (record: IIndustrySegmentItem) => record.key === editingKey;
    const edit = (record: Partial<IIndustrySegmentItem> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            const index = props.dataRowSegments.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = props.dataRowSegments[index];
                const updateSegment: IIndustrySegmentItem = { id: item.id, codeEn: values.codeEn, codeDe: values.codeDe, industryId: props.industryId ? props.industryId : values.industryId };
                await props.updateIndustrySegment(updateSegment, props.industryId);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }

    }
    const deleteSegment = async (id: any) => {
        try {
            await props.deleteIndustrySegment(id, props.industryId)
        } catch (error) {
            console.log('delete Failed:', error);
        }
    };
    return (
        <Form form={form} key="seg_form" onFinish={onFinish}>
            <Table
                className='table_seg'
                getPopupContainer={() => document.getElementById("app-layout")}
                key='seg_table'
                bordered
                dataSource={props.dataRowSegments}
                columns={columns}
                rowClassName="editable-row"
                scroll={{ y: "calc(100vh - 300px)" }}
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 150],
                    total: props.dataRowSegments.length,
                    showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                    showSizeChanger: true,
                    locale: { items_per_page: `/ ${t("Page")}` },
                }}
                locale={{
                    triggerDesc: t('Click to sort descending'),
                    triggerAsc: t('Click to sort ascending'),
                    cancelSort: t('Click to cancel sorting')
                }}

            />
        </Form>
    );
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateIndustrySegment: (model: IIndustrySegmentItem, industryId: number) => dispatch({ type: ActionTypes.UPDATE_INDUSRTYSEGMENT, model, industryId }),
        deleteIndustrySegment: (id: number, industryId: number) => dispatch({ type: ActionTypes.DELETE_INDUSRTYSEGMENT, id, industryId })
    };
};

export default connect(null, mapDispatchToProps)(SegmentsTable);