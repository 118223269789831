import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import { ISelfAssessmentTempItem } from "../../Models/ISelfAssessmentTemp";
import SelfAssessmentTempService from "../../Services/SelfAssessmentBmTempService";


function* OnGetAllSelfAssessment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER, isLoading: true });
        let _result: ICommonResponse = yield SelfAssessmentTempService.GetSelfAssessments();
        if (!_result.isError) {
            let result = _result.result as ISelfAssessmentTempItem[];
            yield put({ type: ActionTypes.SAVE_SELF_ASSESSMENT_BP_TEMP, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGet ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* OnCreateSelfAssessment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield SelfAssessmentTempService.CreateSelfAssessmentBestPractice(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield SelfAssessmentTempService.GetSelfAssessments();
            let result = _result.result as ISelfAssessmentTempItem[];
            yield put({ type: ActionTypes.SAVE_SELF_ASSESSMENT_BP_TEMP, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result:resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* OnUpdateSelfAssessment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield SelfAssessmentTempService.UpdateSelfAssessmentBestPractice(
            action.model
        );
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield SelfAssessmentTempService.GetSelfAssessments();
            let result = _result.result as ISelfAssessmentTempItem[];
            yield put({ type: ActionTypes.SAVE_SELF_ASSESSMENT_BP_TEMP, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* OnDeleteSelfAssessment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER, isLoading: true });
        let resultDelete: ICommonResponse = yield SelfAssessmentTempService.DeleteSelfAssessmentBestPractice(
            action.id
        );
        console.log("resultDelete", resultDelete);
        if (!resultDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
            let _result: ICommonResponse = yield SelfAssessmentTempService.GetSelfAssessments();
            let result = _result.result as ISelfAssessmentTempItem[];
            yield put({ type: ActionTypes.SAVE_SELF_ASSESSMENT_BP_TEMP, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
    } catch (error) {
        console.error("ERR in onDelete: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
    
    yield put({ type: ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER, isLoading: false });
}


function* watchGetAllSelfAssessment() {
    yield takeEvery(ActionTypes.GET_SELF_ASSESSMENT_BP_TEMP, OnGetAllSelfAssessment);
}
function* watchCreateSelfAssessment() {
    yield takeEvery(ActionTypes.CREATE_SELF_ASSESSMENT_BP_TEMP, OnCreateSelfAssessment);
}
function* watchUpdateSelfAssessment() {
    yield takeEvery(ActionTypes.UPDATE_SELF_ASSESSMENT_BP_TEMP, OnUpdateSelfAssessment);
}
function* watchDeleteSelfAssessment() {
    yield takeEvery(ActionTypes.DELETE_SELF_ASSESSMENT_BP_TEMP, OnDeleteSelfAssessment);
}

export default function* SelfAssessmentTempSagas() {
    yield all([
        watchGetAllSelfAssessment(),
        watchCreateSelfAssessment(),
        watchUpdateSelfAssessment(),
        watchDeleteSelfAssessment()

    ]);
}
