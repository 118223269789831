import React, { useEffect, useState } from 'react';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { IHelpPage } from '../../../Models/IHelpPage';
import { Spin } from 'antd';
import HelpPageTable from './HelpPageTable';
interface IProps {
    helpPagesData: IHelpPage[];
    isLoading: boolean;
    getAllHelpPages: () => any;
}

const HelpPage = (props: IProps) => {
    useEffect(() => {
        props.getAllHelpPages()
    }, []);
    const helpPages: IHelpPage[] = props?.helpPagesData?.map(h => {
        return {
            key: `help_page_${h.id}`, ...h
        }
    })
    return (
        <Spin spinning={props.isLoading}>
            <div className='admin-component'>
                <HelpPageTable helpPagesRow={helpPages} />
            </div>
        </Spin>
    )
}

const mapStateToProps = (state: any) => {
    return {
        helpPagesData: state.helpPagesForAdmin.helpPagesResult,
        isLoading: state.helpPagesForAdmin.isHelpPageLoading,
    }
}
const mapDispatchForProps = (dispatch: any) => {
    return {
        getAllHelpPages: () => dispatch({ type: ActionTypes.GET_ALL_HELP_PAGE }),
    }
}
export default connect(mapStateToProps, mapDispatchForProps)(HelpPage);