import { Bar, CartesianGrid, Cell, ComposedChart, LabelList, Rectangle, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Value } from "sass";
import { formatValue, inputFormatter } from "../../../Utils/helperFunctions";
import ChartControls from "../ChartControls/ChartControls";
import ChartDetails from "./ChartDetails";
import { Popover } from "antd";

export interface BestPractice {
    min: number,
    max: number
}

interface ProjectData {
    name: string,
    smashedName: string,
    value: number,
    isTarget: boolean,
    isComplete: boolean
}

interface IProps {
    //props for ChartDetails
    showChartDetails: boolean;
    Title: string;
    leverCode: string;
    Description: string;
    Unit: string;
    chartDetailsTextColor?: string

    //main chart props
    width: number;
    height: number;
    inHeatMapCell?: boolean;
    isInSideBar?: boolean;
    isPercentChart: boolean;
    bestPractice?: BestPractice,
    projectsData: ProjectData[],
    bordered?: boolean,

    //props for ChartControls
    showChartControls: boolean;
    showDownloadBtn?: boolean;
    showSelectCheckBox?: boolean;
    showSwitchRadarBar?: boolean;
    chartType?: string;
    switchChart?: () => void;
    selectChart?: (divId: string, add: boolean) => void;
    selectedCharts?: string[];
    backgroundColor?: string;
};

const calculateMaxValue = (Arr: number[], bpMax?: number): number => {
    let max = bpMax ? Math.max(...Arr, bpMax) : Math.max(...Arr);
    if ((max % 10 === 0) && (max < 100))
        return max + 10;
    if (max > 0)
        return Math.ceil(max / 10) * 10;
    return Math.floor(max / 10) * 10;
};
const calculateMinValue = (Arr: number[], bpMin?: number): number => {
    let min = bpMin ? Math.min(...Arr, bpMin) : Math.min(...Arr);
    if (min > 0)
        return 0;
    return Math.floor(min / 10) * 10;
};


const calculateAverage = (Projects: ProjectData[]): number => {
    if (!Projects) return 0;
    let xsProjects = Projects?.filter(item => !item.isTarget);
    if (xsProjects?.length === 0)
        return 0;
    return (xsProjects?.map(item => item?.value).reduce((a, b) => a + b) / xsProjects?.length);
};

const CustomizedTick = (props) => {
    const { x, y, stroke, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>

            <text
                x={0}
                y={0}
                dy={8}
                textAnchor="middle"
                fontSize={10}
                fill="black">
                {payload.value}
            </text>
        </g>
    );
}


const BarChartForLevers = (props: IProps) => {
    //if (props.projectsData && props.projectsData.length == 0) return;
    //console.log('BarChartForLevers');

    let avarage: number = calculateAverage(props.projectsData);
    let bestPracticeArea;
    if (props.bestPractice) {
        // max = Number(((props.bestPractice.value + props.bestPractice.intervalPercentage) * 100).toFixed(1));
        // let min = ((props.bestPractice.value - props.bestPractice.intervalPercentage) * 100).toFixed(1);
        var firstProject = props.projectsData[0];
        var lastProject = props.projectsData[props.projectsData.length - 1];
        const reference = {
            x1: (props.projectsData.length > 0) ? firstProject.name : "",
            x2: (props.projectsData.length > 0) ? lastProject.name : "",
            y1: props.bestPractice.max,
            y2: props.bestPractice.min
        }
        bestPracticeArea = props.bestPractice ?
            <ReferenceArea
                ifOverflow="extendDomain"
                alwaysShow
                //x1={reference.x1}
                //x2={reference.x2}
                y1={reference.y1}
                strokeDasharray="10 5"
                y2={reference.y2}
                stroke="orange"
                strokeOpacity={1}
                fill={"#FDF4C9"}
                fillOpacity={0.5} /> :
            null
    }

    let data = props.projectsData?.sort((a, b) => b.value - a.value).map(c => {
        return {
            name: c.name,
            value: c.value?.toFixed(1),
            color: c.isTarget ? '#779BDB' : '#7A7A7A',
            hasdashborder: c.isComplete
        }
    });


    let maxValue: number = props.bestPractice ?
        calculateMaxValue(props.projectsData.map(x => x.value), props.bestPractice.max) :
        calculateMaxValue(props.projectsData.map(x => x.value));
    //console.log("maxValue: number: ", maxValue);

    let minValue: number = props.bestPractice ?
        calculateMinValue(props.projectsData.map(x => x.value), props.bestPractice.min) :
        calculateMinValue(props.projectsData.map(x => x.value));
    //TODO in case they asked for rotation or popover
    // const renderCustomizedLabel = (_props) => {
    //     //console.log("renderCustomizedLabel: ", _props);

    //     const {
    //         x, y, width, height, value, fill
    //     } = _props;
    //     //console.log("type of value: ", typeof (value));
    //     //console.log("decimal value: ", Number(value));

    //     let valueNum = Number(value);
    //     if (isNaN(valueNum)) return;

    //     const fireOffset = valueNum < 0;
    //     //console.log("fireOffset: ", fireOffset);
    //     if (props.isInSideBar) return '';
    //     if (props.inHeatMapCell) return '';
    //     if (props.isPercentChart) return (
    //         <Popover
    //             content={
    //                 <text x={x + width / 2} y={y - 5 + (fireOffset ? height : 0)} textAnchor="middle" fill={fill} fontSize={20}>
    //                     {`${formatValue(value)}%`}
    //                 </text>
    //             }>
    //             <text x={x + width / 2} y={y - 20 + (fireOffset ? height : 0)} textAnchor="middle" fill={fill} fontSize={12} transform={`rotate(90, ${x + width / 2}, ${y - 20 + (fireOffset ? height : 0)})`}>
    //                 {`${formatValue(value)}%`}
    //             </text>
    //         </Popover>
    //     );
    //     return (
    //         <Popover
    //             content={
    //                 <text x={x + width / 2} y={y - 5 + (fireOffset ? height : 0)} textAnchor="middle" fill={fill} fontSize={20}>
    //                     {`${formatValue(value)}`}
    //                 </text>
    //             }>
    //             <text x={x + width / 2} y={y - 5 + (fireOffset ? height : 0)} textAnchor="middle" fill={fill} fontSize={12} transform={`rotate(90, ${x + width / 2}, ${y - 20 + (fireOffset ? height : 0)})`}>
    //                 {`${formatValue(value)}`}
    //             </text>
    //         </Popover>
    //     );
    // };

    const renderCustomizedLabel = (_props) => {
        //console.log("renderCustomizedLabel: ", _props);

        const {
            x, y, width, height, value, fill
        } = _props;
        //console.log("type of value: ", typeof (value));
        //console.log("decimal value: ", Number(value));

        let valueNum = Number(value);
        if (isNaN(valueNum)) return;

        const fireOffset = valueNum < 0;
        //console.log("fireOffset: ", fireOffset);
        if (props.isInSideBar) return '';
        if (props.inHeatMapCell) return '';
        if (props.isPercentChart) return (
            (props.projectsData.length < 10) ? <text x={x + width / 2} y={y - 5 + (fireOffset ? height : 0)} textAnchor="middle" fill={fill} fontSize={12}>
                {`${formatValue(value)}%`}
            </text> : <text x={x + width / 2} y={y - 5 + (fireOffset ? height : 0)} textAnchor="middle" fill={fill} fontSize={10}>
                {`${formatValue(value)}`}
            </text>
        );
        return (
            (props.projectsData.length < 10) ? <text x={x + width / 2} y={y - 5 + (fireOffset ? height : 0)} textAnchor="middle" fill={fill} fontSize={12}>
                {`${formatValue(value)}`}
            </text> : <text x={x + width / 2} y={y - 5 + (fireOffset ? height : 0)} textAnchor="middle" fill={fill} fontSize={10}>
                {`${formatValue(value)}`}
            </text>
        );
    };


    return (
        <div className="controls-details-chart-container" style={{ 'border': props.bordered ? '1px solid #ccc' : '' }}>
            {props.showChartControls ? <ChartControls
                chartDivId={props.leverCode}
                showDownloadBtn={props.showDownloadBtn}
                showSwitchRadarBar={props.showSwitchRadarBar}
                showSelectCheckBox={props.showSelectCheckBox}
                switchChart={props.switchChart}
                selectChart={props.selectChart}
                selectedCharts={props.selectedCharts}
                chartType={props.chartType}
            /> : null}
            <div id={props.leverCode} style={{ 'backgroundColor': props.backgroundColor ? props.backgroundColor : 'inherit' }} className="chartdetails-chart-container">
                <div className="chartdetails-container">
                    {props.showChartDetails ? <ChartDetails
                        Title={props.Title}
                        Description={props.Description}
                        Unit={props.Unit}
                        textColor={props.chartDetailsTextColor} /> : null}
                </div>
                <div className="chart-container" style={
                    {
                        'width': props.width.toString() + 'px',
                        'height': props.height.toString() + 'px'
                    }
                }>
                    <ResponsiveContainer id={props.Title} width="100%" height="100%" >
                        <ComposedChart
                            style={{ marginTop: "5%" }}
                            width={props.width}
                            height={props.height}
                            data={data}
                            margin={{
                                top: 15,
                                right: 20,
                                bottom: 2,
                                left: 0,
                            }}
                        >
                            <CartesianGrid vertical={false} strokeDasharray="3" />
                            {props.inHeatMapCell ? null : <CartesianGrid stroke="#f5f5f5" />}
                            {(props.inHeatMapCell || props.isInSideBar) ? null : <XAxis
                                dataKey="name"
                                interval={0}
                                //tick={data?.length > 20 ? <RotatedTick /> : <CustomizedTick />}
                                width={20}
                                tick={props.inHeatMapCell ? null : <CustomizedTick />}
                            />}
                            {(props.inHeatMapCell || props.isInSideBar) ? null : <YAxis
                                type="number"
                                axisLine={false}
                                //domain={[0, (maxValue + maxValue / 4) < 100 ? Math.ceil(maxValue + maxValue / 4) : 100]}
                                ticks={[minValue ?? 0, props.bestPractice?.min ?? 0, props.bestPractice?.max ?? 0, maxValue ?? 0]}
                                interval={0}
                                domain={[minValue, maxValue]}
                                tickFormatter={value => {
                                    if (props.inHeatMapCell) return ``;
                                    if (props.isPercentChart) return `${formatValue(value)}%`
                                    return formatValue(value);
                                }}
                            />}
                            {/* {props.inHeatMapCell ? null : <Tooltip />} */}
                            {bestPracticeArea}
                            <Bar
                                //width={props.width}
                                //height={props.height-25}
                                dataKey="value"
                                isAnimationActive={false}
                                fill="#8884d8"
                                shape={<Rectangle />}
                                barSize={props.inHeatMapCell ? 10 : 20}
                                maxBarSize={20}
                            // label={{
                            //     position: 'top',
                            //     formatter: (value) => {
                            //         if (props.isInSideBar) return '';
                            //         if (props.inHeatMapCell) return '';
                            //         if (props.isPercentChart) return `${formatValue(value)}%`;
                            //         return formatValue(value);
                            //     }
                            // }}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} stroke='black' strokeDasharray={(entry.hasdashborder) ? 3 : 0} strokeWidth={0.5} strokeOpacity={0.5} />
                                ))}
                                <LabelList dataKey="value" content={renderCustomizedLabel} position="top" />
                            </Bar>
                            <ReferenceLine y={avarage} stroke="#F9DC4C" strokeWidth={2} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div >
    );

};


export default BarChartForLevers;