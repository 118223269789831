import { ActionTypes } from "../actionsTypes";
import { ILoginResponse } from '../../Models/Responses/AuthResponse';
import { IAuthInfoState } from "./IGeneralState";

const initialState: IAuthInfoState = { 
    loginResult: null,
    verifyLoginResult: null,
    
};

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.SaveLoginResult:
            //console.log('loginResult: ', action.result);
            
            return {
                ...state, 
                loginResult: { ...action.result }
            }
        case ActionTypes.SaveVerifyLoginResult:
        return {
            ...state, 
            verifyLoginResult: { ...action.result }
        }
        default:
            break;
    }
    return state;
};

export default authReducer;