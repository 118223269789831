import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Avatar, Typography } from 'antd';
import "./index.scss";
import { AvatarSize } from "antd/lib/avatar/SizeContext";

/**
 * Avatar component  
 * props can be used to send user iamge, name and position.
 * Name and position can be shown or hidden
 * @returns Returns current user image, name and position.
 */

const { Paragraph } = Typography;
interface AppAvatarProps {
  avatarImage?: string;
  showEmpName?: boolean;
  showPosition?: boolean;
  empName?: string;
  jobPosition?: string;
  size?: number;
}
const AppAvatar = (props: AppAvatarProps) => {
  // AppAvatar.prototype = {
  //   avatarImage: PropTypes.string,
  //   showEmpName: PropTypes.bool,
  //   showPosition: PropTypes.bool,
  //   empName: PropTypes.string,
  //   jobPosition: PropTypes.string,
  // }
  const [avatarImageCurrent, setAvatarImageCurrent] = useState('');
  const [showEmpName, setShowEmpName] = useState(false);
  const [showPosition, setShowPosition] = useState(false);
  const [empName, setEmpName] = useState('');
  const [jobPosition, setJobPosition] = useState('');

  useEffect(() => {
    if (props.avatarImage) {
      setAvatarImageCurrent(props.avatarImage);
    };
    if (props.showEmpName) {
      setShowEmpName(props.showEmpName);
    }
    if (props.showPosition) {
      setShowPosition(props.showPosition);
    }
    if (props.empName) {
      setEmpName(props.empName);
    };
    if (props.jobPosition) {
      setJobPosition(props.jobPosition);
    };
  }, [props.jobPosition, props.empName, props.showPosition, props.showEmpName, props.avatarImage]);

  return (
    <div className="avatarComp">
      <Avatar size={props.size} src={avatarImageCurrent} />
      <div className="avatar-contnet">
        {props.showEmpName ?
          <Paragraph className="avatarComp-empName">{empName}</Paragraph>
          : null}
        {props.showPosition ?
          <Paragraph className="avatarComp-empPosition">{jobPosition}</Paragraph>
          : null}
      </div>
    </div >

  );
}

export default AppAvatar;