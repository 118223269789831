import { Button, Checkbox, Form, Input, Row, Col, Spin, Space } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ILoginModel } from "../../../Models/Requests/AuthModels";
import { ILoginResponse } from "../../../Models/Responses/AuthResponse";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { createBrowserHistory } from "history";
import { RulesEmail, RulesRequire } from "../../Admin/Rules/RulesValidation";
import HeaderLangThemeSwitcher from "../../../Components/HeaderLangThemeSwitcher";

interface ILoginProps {
  isLoading: boolean;
  loginResult: ILoginResponse;
  commonResult: ICommonResponse;
  startLogin: (model: ILoginModel) => any;
  //goToPath: (path: string) => any;
  //getHomePage: () => any;
  //AuthUnMount: () => any;
}

function Login(props: ILoginProps) {
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get('email');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if ((localStorage.getItem("token") && localStorage.getItem("refreshToken")) ||
      (sessionStorage.getItem("token") && sessionStorage.getItem("refreshToken"))) {
      navigate("/dashboard")
    } else {
      //localStorage.clear();
      //sessionStorage.clear();
      searchParams.delete('email');
      history.replace({
        search: searchParams.toString(),
      });
    }
  }, []);

  const onFinish = (values) => {
    console.log('onFinish Login:', values);
    localStorage.setItem('currentEmail', values.username);
    localStorage.setItem('rememberme', values.remember);
    props.startLogin({
      email: values.username,
      password: values.password
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);

  };

  return (
    <>
      <HeaderLangThemeSwitcher />
      <Row align="middle" justify="center" className="login-form">
        <Col xs={24} sm={16} md={8} className="form-card">
          <div className="header-titel">
            <p>
              {`${t("willkommen bei")}`}  <span>{`BENCHMARK YOUR PERFORMANCE (BYP)`}</span><br />
            </p>
          </div>

          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish.bind(this)}
            onFinishFailed={onFinishFailed.bind(this)}
            autoComplete="off"
          >
            <Form.Item
              label={t('E-Mail')}
              name="username"
              initialValue={email}
              rules={RulesEmail()}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("Password")}
              name="password"
              rules={RulesRequire({ filedName: "Password" })}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              noStyle
            /*   wrapperCol={{
                offset: 8,
                span: 16,
              }} */
            >
              <Space.Compact block={true} size={"large"} style={{ justifyContent: "space-between" }}>

                <Checkbox defaultChecked={true} style={{ width: 'auto' }} > {t('Remember me')}</Checkbox>

                <Link style={{ width: 'auto', textAlign: 'end' }} className="login-form-forgot" to={`/reset-password-request`}>{`${t('Forgot password?')}`}</Link>
              </Space.Compact>
            </Form.Item>
            <Spin spinning={props.isLoading}>
              <Form.Item
                noStyle
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit" className="form-btn">
                  {t('Submit')}
                </Button>
              </Form.Item>
            </Spin>
          </Form>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    isLoading: state.generalInfo.isLoading,
    loginResult: state.authInfo.loginResult,
    commonResult: state.generalInfo.commonResponse
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    startLogin: (model: ILoginModel) => dispatch({ type: ActionTypes.Login, model: model }),
    //AuthUnMount: () => dispatch({ type: ActionTypes.AuthUnmount })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);