import { CommentOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import HistoryList from "./HistoryList";
import { useState } from "react";
import { connect } from "react-redux";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
import { t } from "i18next";

interface IProps {
    id: number;
    getHistoryOfImportance: (id: number) => any;
}
function SABestPracticeHistoryModal(props: IProps) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCancel = () => {
        closeAndReset();
    };
    const closeAndReset = () => {
        setIsModalVisible(false);
    }



    return <>
        <Tooltip title={`${t('History')}`} placement="top">
            <CommentOutlined style={{ fontSize: 20 }} onClick={async () => {
                await props.getHistoryOfImportance(props.id)
                setIsModalVisible(true)
            }

            } />
        </Tooltip>
        <Modal getContainer={() => document.getElementById('app-layout')} title={`${t('History')}`} open={isModalVisible}
            onOk={null}
            onCancel={handleCancel}
            cancelText={`${t("Close")}`}

        >
            <HistoryList  />

        </Modal>
    </>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getHistoryOfImportance: (id: number) => dispatch({ type: ActionTypes.GET_HISTORY_IMPORTANCE, id })
    }
};

export default connect(null, mapDispatchToProps)(SABestPracticeHistoryModal);