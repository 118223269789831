import { Button, Checkbox, Col, Form, Input, Row, Select, Space, Spin, Table, Tabs, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IPermissionDto, IPermissionsDto } from "../../../../Models/Permissions/PermissionsDto";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState, IPermissionsState } from "../../../../Redux/reducers/IGeneralState";
import { t } from "i18next";

interface IProps {
    isForAdmin: boolean;
    viewState: IPermissionsState;
    changePermissions: (model: IPermissionsDto) => any;
    changePermissionCurrentProjectId: (model: number) => any;
}

interface DataType {
    key: React.Key;
    user_id: number;
    role_id: number;
    hasPermission: boolean;
    user_name: string;
}
function PermissionsTable(props: IProps) {
    const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
    //const [projectId, setProjectId] = useState<number>(props.viewState?.viewResult?.bmProjects?.[0]?.id ?? 0);
    useEffect(() => {
        prepareColumns();
        //setProjectId(props.viewState?.currentProjectId ?? 0);
    }, [props.viewState?.currentProjectId]);
    useEffect(() => {
        prepareColumns();
    }, [props.viewState?.viewResult]);
    const onChecked = (userId: number, roleId: number) => {

        let newResult = props.viewState?.viewResult;
        if (props.isForAdmin) {
            console.log("onchecked ", userId, roleId);
            let oldOne: IPermissionDto = props.viewState?.viewResult?.adminPermissions?.filter(x => x.userId == userId && x.roleId == roleId)?.[0];
            console.log("old Permission", oldOne);
            if (oldOne) {
                let newPermissions = newResult?.adminPermissions?.filter(x => !(x.userId == userId && x.roleId == roleId));
                console.log("new Permissions", newPermissions);
                //delete the permission
                newResult.adminPermissions = newPermissions;
            } else {
                //add the permmission
                let newPermissions = newResult?.adminPermissions;
                newPermissions.push({
                    roleId: roleId,
                    userId: userId,
                    canView: true,
                    canModify: true
                });
                newResult.adminPermissions = newPermissions;
            }
        } else {
            let projectId: number = props.viewState?.currentProjectId;
            console.log("onchecked ", userId, roleId, projectId, props.viewState?.currentProjectId);
            let permissionsPerProject = newResult?.permissionPerProjects?.filter(c => c.bmProjectId == projectId)?.[0];
            if (permissionsPerProject) {
                console.log("old permissionsPerProject", permissionsPerProject);
                let oldOne: IPermissionDto = permissionsPerProject.permissions?.filter(x => x.userId == userId && x.roleId == roleId)?.[0];
                if (oldOne) {
                    console.log("old permissionsPerProject oldOne", oldOne);
                    //delete the permission
                    let newPermissions = permissionsPerProject.permissions?.filter(x => !(x.userId == userId && x.roleId == roleId));
                    newResult.permissionPerProjects.filter(c => c.bmProjectId == projectId)[0].permissions = newPermissions;
                } else {
                    let newPermissions = permissionsPerProject.permissions;
                    newPermissions.push({
                        roleId: roleId,
                        userId: userId,
                        canView: true,
                        canModify: true
                    });
                    newResult.permissionPerProjects.filter(c => c.bmProjectId == projectId)[0].permissions = newPermissions;
                }
            } else {
                let newPermissions = newResult?.permissionPerProjects;

                newPermissions.push({
                    bmProjectId: projectId,
                    permissions: [{
                        roleId: roleId,
                        userId: userId,
                        canModify: true,
                        canView: true
                    }]
                });
                newResult.permissionPerProjects = newPermissions
            }
        }

        props.changePermissions(newResult);
    }
    const isAllRolesAssignedToUser = (userId: number) => {
        if (props.isForAdmin) {
            let permCount = props.viewState?.viewResult?.adminPermissions?.filter(x => x.userId == userId).length;

            return props.viewState?.viewResult?.adminPages.length == permCount;
        } else {
            let permCount = props.viewState?.viewResult?.permissionPerProjects?.filter(c => c.bmProjectId == props.viewState?.currentProjectId)
                ?.[0]?.permissions?.filter(x => x.userId == userId).length;
            return props.viewState?.viewResult?.tools.length == permCount;
        }
    }
    const onCheckedAssignAllRole = (userId: number) => {
        let isAllChecked = isAllRolesAssignedToUser(userId);
        let newResult = props.viewState?.viewResult;
        if (props.isForAdmin) {
            props.viewState?.viewResult?.adminPages?.map(page => {
                if (isAllChecked) {
                    let oldOne: IPermissionDto = props.viewState?.viewResult?.adminPermissions?.filter(x => x.userId == userId && x.roleId == page.value)?.[0];
                    if (oldOne) {
                        let newPermissions = newResult?.adminPermissions?.filter(x => !(x.userId == userId && x.roleId == page.value));
                        //delete the permission
                        newResult.adminPermissions = newPermissions;
                    }
                } else {
                    //add the permmission
                    let oldOne: IPermissionDto = props.viewState?.viewResult?.adminPermissions?.filter(x => x.userId == userId && x.roleId == page.value)?.[0];
                    if (!oldOne) {
                        let newPermissions = newResult?.adminPermissions;
                        newPermissions.push({
                            roleId: page.value,
                            userId: userId,
                            canView: true,
                            canModify: true
                        });
                        newResult.adminPermissions = newPermissions;
                    }
                }
            });
        } else {
            let projectId: number = props.viewState?.currentProjectId;
            let permissionsPerProject = newResult?.permissionPerProjects?.filter(c => c.bmProjectId == projectId)?.[0];
            props.viewState?.viewResult?.tools?.map(tool => {
                if (isAllChecked) {
                    let oldOne: IPermissionDto = permissionsPerProject.permissions?.filter(x => x.userId == userId && x.roleId == tool.value)?.[0];
                    if (oldOne) {
                        console.log("old permissionsPerProject oldOne", oldOne);
                        //delete the permission
                        let newPermissions = permissionsPerProject.permissions?.filter(x => !(x.userId == userId && x.roleId == tool.value));
                        newResult.permissionPerProjects.filter(c => c.bmProjectId == projectId)[0].permissions = newPermissions;
                    }
                } else {
                    if (permissionsPerProject) {

                        let newPermissions = permissionsPerProject.permissions;
                        let oldOne: IPermissionDto = newPermissions?.filter(x => x.userId == userId && x.roleId == tool.value)?.[0];
                        if (!oldOne) {
                            newPermissions.push({
                                roleId: tool.value,
                                userId: userId,
                                canView: true,
                                canModify: true
                            });
                            newResult.permissionPerProjects.filter(c => c.bmProjectId == projectId)[0].permissions = newPermissions;
                        }
                    } else {
                        let newPermissions = newResult?.permissionPerProjects; 
                        newPermissions.push({
                            bmProjectId: projectId,
                            permissions: [{
                                roleId: tool.value,
                                userId: userId,
                                canModify: true,
                                canView: true
                            }]
                        });
                        newResult.permissionPerProjects = newPermissions                    
                    }
                }
            });
        }
        props.changePermissions(newResult);
    }
    const prepareColumns = () => {
        let _cols: ColumnsType<DataType> = [
            {
                title: '',
                dataIndex: 'user_name',
                key: 'name',
                width: 160,
                fixed: 'left',
                className: "prepare_col_name",
                render: (text, record, index) => {
                    return <Space>
                        <Tooltip title={`${(record[`hasAllPermission_${record.user_id}`]) ? t("Uncheck all") : t("Check all")}`}>
                            <Checkbox key={`ch_all_roles_${record.user_id}`} checked={record[`hasAllPermission_${record.user_id}`]}
                                onClick={() => { onCheckedAssignAllRole(record.user_id); }} />
                        </Tooltip>
                        <label>{record.user_name}</label>
                    </Space>;
                }
            }
        ];
        if (props.isForAdmin) {
            props.viewState?.viewResult?.adminPages?.map(page => {
                _cols.push(
                    {
                        title: <span className="span-header-permission">{page.label}</span>,
                        dataIndex: `hasPermission_${page.value}`,
                        key: `role_${page.value}`,
                        align: 'center',
                        onHeaderCell: (column) => ({
                            style: {
                                verticalAlign: "bottom",
                                textAlign: "center"
                            }
                        }),
                        render: (text, record, index) => {
                            return <Checkbox key={`ch_${record.user_id}_${page.value}`} checked={record[`hasPermission_${page.value}`]}
                                onClick={() => { onChecked(record.user_id, page.value); }} />;
                        }
                    }
                );
            });
        }
        else {
            props.viewState?.viewResult?.tools?.map(tool => {
                _cols.push(
                    {
                        title: tool.label,
                        dataIndex: `hasPermission_${tool.value}_${props.viewState?.currentProjectId}`,
                        key: `role_${tool.value}`,
                        align: 'center',
                        //width: 30,
                        //onHeaderCell: rateHeaderCellConfig,                    
                        render: (text, record, index) => {
                            return <Checkbox key={`ch_${record.user_id}_${tool.value}`} checked={record[`hasPermission_${tool.value}_${props.viewState?.currentProjectId}`]}
                                onClick={() => { onChecked(record.user_id, tool.value); }} />;
                        }
                    }
                );
            });
        }
        setColumns(_cols);
    }

    const data = [];
    if (props.isForAdmin) {
        props.viewState?.viewResult?.admins?.map(user => {
            let newRow = {
                key: `row_${user.id}`,
                user_id: user.id,
                user_name: `${user.firstName} ${user.lastName}`
            };
            props.viewState?.viewResult?.adminPermissions?.filter(x => x.userId == user.id).map(permission => {
                newRow[`hasPermission_${permission.roleId}`] = true;
            });
            newRow[`hasAllPermission_${user.id}`] = isAllRolesAssignedToUser(user.id);
            data.push(newRow);
        });
    } else {
        props.viewState?.viewResult?.consultants?.map(user => {
            let newRow = {
                key: `row_${user.id}`,
                user_id: user.id,
                user_name: `${user.firstName} ${user.lastName}`
            };
            props.viewState?.viewResult?.permissionPerProjects?.filter(c => c.bmProjectId == props.viewState?.currentProjectId)?.[0]?.permissions?.filter(x => x.userId == user.id).map(permission => {

                newRow[`hasPermission_${permission.roleId}_${props.viewState?.currentProjectId}`] = true;
            });
            newRow[`hasAllPermission_${user.id}`] = isAllRolesAssignedToUser(user.id);
            data.push(newRow);
        });
    }
    return <>
        {(props.isForAdmin) ? null :
            <Row>
                <Col span={8}>
                    <Select
                        onChange={(value) => {

                            //console.log("onchange setProjectId", value);
                            props.changePermissionCurrentProjectId(value);
                            localStorage.setItem("currentProjectSelectedId", value.toString());
                        }
                        }
                        getPopupContainer={() => document.getElementById("app-layout")}
                        //optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        value={localStorage.getItem("currentProjectSelectedId") ?
                            Number(localStorage.getItem("currentProjectSelectedId")) :
                            props?.viewState?.currentProjectId}
                        options={props.viewState?.viewResult?.bmProjects?.map(cc => { return { label: cc.name, value: cc.id } })}
                        style={{
                            textOverflow: "inherit",
                            width: "100%",
                            fontSize: "12px",
                        }}
                    />
                </Col>
            </Row>
        }
        <Table
            columns={columns}
            dataSource={data}
            bordered
            size="small"
            className="ia_table"
            pagination={false}
            scroll={{ y: "calc(100vh - 300px)", x: true }}
        />
    </>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        viewState: state.permissions,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        changePermissions: (model: IPermissionsDto) => dispatch({ type: ActionTypes.ChangePermissions, result: model }),
        changePermissionCurrentProjectId: (model: number) => dispatch({ type: ActionTypes.ChangeCurrentProjectPermissionId, result: model }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsTable);