import { ToastMessages } from "../Enums/Messages";
import { IEventLogFilter } from "../Models/IEventLogForAdmin";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";

export default class EventLogPageForAdminService {
    static async GetAllEventLog(model:IEventLogFilter): Promise<ICommonResponse> {
      return await asupapi
        .post(endPoints.getAllEventLog, model)
        .then(function (response) {
          let result: ICommonResponse = response.data;
          return result;
        })
        .catch(function (error) {
          if (error.response) {
            console.log("error1 ", error.response);
            if (error.response.data) {
              console.log("error0 ", error.response.data);
              let result: ICommonResponse = error.response.data;
              return result;
            }
          } else if (error.request) {
            console.log("error2 ", error.request);
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.NetworkError,
              },
            } as ICommonResponse;
          } else if (error.message) {
            console.log("error3 ", error.message);
            //do something other than the other two
          }
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.UnExpectedError,
            },
          } as ICommonResponse;
        });
    }
  
    static async getEventLogAllFilterMenus(): Promise<ICommonResponse> {
      return await asupapi
        .get(endPoints.getFilterEventLog)
        .then(function (response) {
          let result: ICommonResponse = response.data;
          return result;
        })
        .catch(function (error) {
          if (error.response) {
            console.log("error1 ", error.response);
            if (error.response.data) {
              console.log("error0 ", error.response.data);
              let result: ICommonResponse = error.response.data;
              return result;
            }
          } else if (error.request) {
            console.log("error2 ", error.request);
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.NetworkError,
              },
            } as ICommonResponse;
          } else if (error.message) {
            console.log("error3 ", error.message);
            //do something other than the other two
          }
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.UnExpectedError,
            },
          } as ICommonResponse;
        });
    }
  }