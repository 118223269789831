import { ToastMessages } from "../Enums/Messages";
import { IRequestDProject } from "../Models/IRequestDProject";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";

export default class DProjectsServices {
    static async GetDProjects(bmProjectId: number): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GETDPROJECT, { params: { bmProjectId: bmProjectId } })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                ////console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async UploadDproject(dProject: IRequestDProject): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.UPLOADDPROJECT, dProject)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async FetchDprojectComments(dprojectId: number): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.DPROJECTCOMMENTS, { params: { dprojectId: dprojectId } })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async UploadDprojectComments(comment: { dprojectId: number, text: string }): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.UPOLADDPROJECTCOMMENT, comment)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async UpdateComment(comment: { dprojectId: number, text: string }): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.UPDATINGCOMMENT, comment)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async DeleteComment(commentId: number): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.DELETECOMMENT, { params: { id: commentId } })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async UpdateDproject(dproject: IRequestDProject): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.UPDATEDPROJECT, dproject)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async DeleteDproject(dprojectId: number): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.DLTDPROJECT, { params: { id: dprojectId } })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async GetDprojectsTypes(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GETDPROJECTTYPES)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async GetDprojectProgress(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetDprojectProgress)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async GetIncludeInLevers(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.getIncludeInLevers)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async UploadIncludeInLevers(includeInLeversArr: number[]): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.uploadIncludeInLevers, includeInLeversArr)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }


    static async MarkDProjectAnalysisAsComplete(): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.markDProjectAnalysisAsComplete)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
}