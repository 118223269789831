import {
  Button,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Slider,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined, SaveOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import {
  IEbitToolState,
  IGeneralInfoState,
  IGeneralState,
} from "../../../../Redux/reducers/IGeneralState";
import { EstimateDto, EstimateResult } from "../Models/EstimateResult";
import Waterfallv2 from "../BasicCharts/Waterfall-V2";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import GeneralPanel from "./GeneralPanel";
import Estimate from "../Models/Estimate";
import BasicPanel from "./BasicPanel";
import { useTranslation } from "react-i18next";
import { usePrompt } from "../BasicCharts/PromptAndBlocker";

const { Title } = Typography;
interface EstimateProps {
  ebitState: IEbitToolState;
  generalInfo: IGeneralInfoState;
  currentProject: BmProject;
  getEstimation: (projectId: number) => any;
  saveEstimation: (model: EstimateDto) => any;
  downloadEstimationReport: (projectID: number, projectName: string) => any;
}
// estimation models (input & result)
const initialResult = new EstimateResult();

function EstimateSection(props: EstimateProps) {
  const { t } = useTranslation();
  const estimateModel: Estimate = new Estimate(props.currentProject?.yearUnderReview ?? 0);
  // statefull variables
  const [estimate, setEstimate] = useState(estimateModel);
  const [scale, setScale] = useState(1);
  const [estimateResult, setEstimateResult] = useState(initialResult);
  // convert estimate result to array
  const data = Object.entries(estimateResult).map((item) => {
    return { Name: item[1].Name, Cost: item[1].Cost, Rate: item[1].Rate };
  });
  // useEffect(() => {
  //   setEstimate(estimateModel)
  // }, [])
  useEffect(() => {
    props.getEstimation(props.currentProject?.id);
    setEstimate(estimateModel);
  }, []);
  useEffect(() => {
    if (props.ebitState.estimateInfo) {
      const result: EstimateDto = props.ebitState.estimateInfo;
      let recivedEstimate: Estimate = new Estimate(
        props.currentProject?.yearUnderReview ?? 0
      );
      Object.entries(result).forEach((item) => {
        if (item[0] !== "generalinfo") {
          Object.entries(item[1]).forEach((key) => {
            if (recivedEstimate[item[0]][key[0]])
              recivedEstimate[item[0]][key[0]]["Answer"] = key[1];
          });
        } else {
          Object.entries(item[1]).forEach((key) => {
            recivedEstimate[item[0]][key[0]] = key[1];
          });
        }
      });
      setEstimate(recivedEstimate);
    }
  }, [props.ebitState.estimateInfo, props.currentProject?.yearUnderReview]);

  useEffect(() => {
    recalculate_costs();
    setEstimateResult((prev) => {
      return {
        ...prev,
        EBITToday: {
          Name: `${t("EBIT heute")}`,
          Cost:
            (estimate.generalinfo.salesLastYear *
              1000000 *
              estimate.generalinfo.ebitRate) /
            100 || 0,
          Rate: estimate.generalinfo.ebitRate || 0,
        },
      };
    });
  }, [estimate]);
  // astate where to observe the estimation (inputs) state
  const [formState, setFormState] = useState<"unchanged" | "modified" | "saving">("unchanged");

  // Show an exit prompt if the formState is NOT
  // unchanged (modified or being saved) to prevent losing enterd values.
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };
    if (formState !== "unchanged") {
      window.addEventListener("beforeunload", handler);
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    return () => { };
  }, [formState]);


  //handle change of Slider for scaling estimation waterfall chart
  const onChange = (value: number) => {
    setScale(value);
  };

  //#region estimation calculation functions

  // handle change in general panel inputs
  const handleGeneralChange = (value, name) => {
    try {
      // set the new value
      estimate.generalinfo[name] = Number(value);
      // get the value before changing it 
      let previousValue = props.ebitState.estimateInfo?.generalinfo[name];
      // //detect if the value changed
      try {
        if (Number(previousValue) !== Number(value)) {
          setFormState("modified");
        } else {
          // all fields must be checked to confirm unchanged status
          const result: EstimateDto = props.ebitState.estimateInfo;
          exit_loops: for (const item of Object.entries(result)) {
            if (item[0] !== "generalinfo") {
              for (const key of Object.entries(item[1])) {
                if (estimate[item[0]][key[0]]["Answer"] != key[1]) {
                  setFormState("modified");
                  break exit_loops;
                }
              }
            } else {
              for (const key of Object.entries(item[1])) {
                if (estimate[item[0]][key[0]] != key[1]) {
                  setFormState("modified");
                  break exit_loops;;
                }
              }
            }
            setFormState("unchanged");
          };
        }
      } catch (e) {
        console.error("handleGeneralChange check if field modfied", e);
      }
      // recalculate all costs (R&D, MC, QC , Inno), that they deppend on general
      // values (SalesLastYear, RD_rate, MC_rate, .....)
      setEstimateResult((prev) => {
        return {
          ...prev,
          EBITToday: {
            Name: `${t("EBIT heute")}`,
            Cost:
              (estimate.generalinfo.salesLastYear *
                1000000 *
                estimate.generalinfo.ebitRate) /
              100 || 0,
            Rate: estimate.generalinfo.ebitRate || 0,
          },
        };
      });
      recalculate_costs();
    }
    catch (e) {
      console.error("handleGeneralChange", value, name, e);
    }
  };

  function calculateEstimatecod() {
    let COD = estimate.cod;
    let generalinfo = estimate.generalinfo;
    let codCost: number =
      COD.additionalCostCOD.Answer +
      COD.projectCountLastYear.Answer *
      COD.avgDelayLastYear.Answer *
      (COD.avgProjectCostLastYear.Answer +
        (COD.avgExpectedSales.Answer * generalinfo.ebitRate) / 100) -
      (generalinfo.salesLastYear * 1000000 * generalinfo.estimateBenchmarkCOD) /
      100;

    let Rate = codCost / (generalinfo.salesLastYear * 1000000);
    setEstimateResult((prev) => {
      return {
        ...prev,
        codPotential: {
          Name: "COD",
          Cost: codCost > 0 ? codCost : 0,
          Rate: Rate > 0 ? Rate * 100 : 0,
        },
      };
    });
  }
  function calculateEstimateinno() {
    let Inno = estimate.inno;
    let generalinfo = estimate.generalinfo;

    // set the inno sales share value
    const innoSalesShareValue = (estimate.inno.productsSalesLastYear.Answer / (estimate.generalinfo.salesLastYear*1000000)) *100;
    estimate.inno.InnoSalesShare.Answer = isNaN(innoSalesShareValue) ? 0 : innoSalesShareValue ;

    let X1 = generalinfo.salesLastYear * 1000000;
    let X2 = generalinfo.ebitRate / 100;
    let X3 = generalinfo.estimateBenchmarkInno / 100;

    let X4 = Inno.productsSalesLastYear.Answer; // projectEstimation.turnover_products;
    let X5 = Inno.productsAvgEBITRateLastYear.Answer / 100; //projectEstimation.ebit_products/100;
    //let X6 = Inno.ebitProductExtensions.Answer / 100; //projectEstimation.ebit_products_portfolio/100;

    let euro = (X1 * X3 - X4) * X5;
    let percent = 0;
    if (euro < 0) {
      euro = 0;
    } else if (X5 !== 0) {
      percent = (euro + X1 * X2) / (euro / X5 + X1) - X2;
    }
    let inno_cost = euro;
    let Rate: number = percent;

    setEstimateResult((prev) => {
      return {
        ...prev,
        innoPotenial: {
          Name: "Inno",
          Cost: inno_cost > 0 ? inno_cost : 0,
          Rate: Rate > 0 ? Rate * 100 : 0,
        },
      };
    });
  }
  function calculateEstimaterd() {
    let RD = estimate.rd;
    let generalinfo = estimate.generalinfo;
    // console.log("RD", RD);
    // console.log("generalinfo", generalinfo);
    let Rate = ((RD.personnelCostDevelopmentArea.Answer + RD.personnelCostinterdisciplinary.Answer) / (generalinfo.salesLastYear * 1000000)) - (generalinfo.estimateBenchmarkRD / 100);
    let RD_cost = Rate * generalinfo.salesLastYear * 1000000;
    // let RD_cost =
    //      RD.personnelCostDevelopmentArea.Answer +
    //      RD.personnelCostinterdisciplinary.Answer -
    //     (generalinfo.salesLastYear * 1000000 * generalinfo.estimateBenchmarkRD) /
    //     100;
    //let Rate = RD_cost / (generalinfo.salesLastYear * 1000000);

    setEstimateResult((prev) => {
      return {
        ...prev,
        rDPotential: {
          Name: t("F&E"),
          Cost: RD_cost > 0 ? RD_cost : 0,
          Rate: Rate > 0 ? Rate * 100 : 0,
        },
      };
    });
  }
  function calculateEstimateqc() {
    let QC = estimate.qc;
    let generalinfo = estimate.generalinfo;
    //console.log("QC", QC);
    //console.log("generalinfo", generalinfo);
    let QC_cost =
      (QC.qualityCost.Answer * QC.qualityCostByRD.Answer) / 100 -
      (generalinfo.salesLastYear * 1000000 * generalinfo.estimateBenchmarkQC) /
      100;
    let Rate = QC_cost / (generalinfo.salesLastYear * 1000000);
    // let Rate = QC_cost / generalinfo.salesLastYear;
    setEstimateResult((prev) => {
      return {
        ...prev,
        qCPotential: {
          Name: t("QK"),
          Cost: QC_cost > 0 ? QC_cost : 0,
          Rate: Rate > 0 ? Rate * 100 : 0,
        },
      };
    });
  }
  function calculateEstimatemc() {
    let MC = estimate.mc;
    let generalinfo = estimate.generalinfo;
    //console.log("MC", MC);
    //console.log("generalinfo", generalinfo);
    let MCCost =
      ((MC.completedProjectLastYear.Answer *
        MC.manufactoringCostDeviation.Answer) /
        100) *
      MC.avgQuantity.Answer *
      MC.avgPlannedManufactoringCost.Answer -
      (generalinfo.salesLastYear * 1000000 * generalinfo.estimateBenchmarkMC) /
      100;

    // COD cost before new modification (to be tested and ensure to get the same result as in old website performance.asup.de
    // let MC_cost =
    //   ((MC.CompletedProjectLastYear.Answer *
    //     MC.ManufactoringCostDeviation.Answer) /
    //     100) *
    //   MC.AvgQuantity.Answer *
    //   MC.AvgPlannedManufactoringCost.Answer;
    //let Rate = MCCost / (generalinfo.salesLastYear * 1000000); //TODO: this wrong why * 1000000??
    let Rate = MCCost / (generalinfo.salesLastYear * 1000000);

    //if (!isNaN(MCCost) && MCCost > 0)
    setEstimateResult((prev) => {
      return {
        ...prev,
        mCPotential: {
          Name: t("HK"),
          Cost: MCCost > 0 ? MCCost : 0,
          Rate: Rate > 0 ? Rate * 100 : 0,
        },
      };
    });
  }
  const calculateCost: { [K: string]: Function } = {
    cod: calculateEstimatecod,
    inno: calculateEstimateinno,
    qc: calculateEstimateqc,
    mc: calculateEstimatemc,
    rd: calculateEstimaterd,
  };

  // to re-calculate all costs (COD,Inno,QC,MC,RD)
  const recalculate_costs = () => {
    for (let key of Object.keys(calculateCost)) {
      calculateCost[key]();
    }
  };
  const DownloadEstimationPDF = async () => {
    // EbitService.GetEstimationReportPDF(props.currentProject.id, props.currentProject.project_name);
    props.downloadEstimationReport(props.currentProject.id, props.currentProject.project_name);
  };
  function handle_change(type: string, value, name) {
    try {
      //set the new value
      estimate[type][name].Answer = Number(value);

      // Check if the Changed value is the count of finished project in COD panel
      // then set the count of finished project in MC panel to have the same value

      if (type === 'cod' && name === 'projectCountLastYear')
        estimate.mc.completedProjectLastYear.Answer = Number(value);

      if (type === 'inno' && name === 'productsSalesLastYear') {

        const innoSalesShareValue = (estimate.inno.productsSalesLastYear.Answer / (estimate.generalinfo.salesLastYear*1000000)) * 100;
        estimate.inno.InnoSalesShare.Answer = isNaN(innoSalesShareValue) ? 0 : innoSalesShareValue;
      }
      // get the value before the change
      let previousValue = props.ebitState.estimateInfo[type][name];
      // detect if the value changed
      try {
        if (Number(previousValue) !== Number(value)) {
          setFormState("modified");
        } else {
          //all fields must be checked to confirm unchanged status
          const result: EstimateDto = props.ebitState.estimateInfo;
          exit_loops: for (const item of Object.entries(result)) {
            if (item[0] !== "generalinfo") {
              for (const key of Object.entries(item[1])) {
                if ((estimate[item[0]][key[0]]["Answer"] && estimate[item[0]][key[0]]["Answer"] != key[1]) || !estimate[item[0]][key[0]]["Answer"]) {
                  setFormState("modified");
                  break exit_loops;
                }
              }
            } else {
              for (const key of Object.entries(item[1])) {
                if (estimate[item[0]][key[0]] != key[1]) {
                  setFormState("modified");
                  break exit_loops;;
                }
              }
            }
            setFormState("unchanged");
          };
        }
      } catch (e) {
        console.error("handle_change check if filed modified", e);
      }

      return calculateCost[type]();
    } catch (e) {
      console.error("handle_change", type, value, name, e);
    }
  }
  async function saveChanges() {
    setFormState("saving");
    let input: EstimateDto = {
      bmProjectId: props.currentProject.id,
      cod: {
        additionalCostCOD: estimate.cod.additionalCostCOD.Answer,
        avgDelayLastYear: estimate.cod.avgDelayLastYear.Answer,
        avgExpectedSales: estimate.cod.avgExpectedSales.Answer,
        avgProjectCostLastYear: estimate.cod.avgProjectCostLastYear.Answer,
        projectCountLastYear: estimate.cod.projectCountLastYear.Answer,
      },
      generalinfo: {
        ebitRate: estimate.generalinfo.ebitRate,
        estimateBenchmarkCOD: estimate.generalinfo.estimateBenchmarkCOD,
        estimateBenchmarkInno: estimate.generalinfo.estimateBenchmarkInno,
        estimateBenchmarkMC: estimate.generalinfo.estimateBenchmarkMC,
        estimateBenchmarkQC: estimate.generalinfo.estimateBenchmarkQC,
        estimateBenchmarkRD: estimate.generalinfo.estimateBenchmarkRD,
        salesLastYear: estimate.generalinfo.salesLastYear,
      },
      inno: {
        //ebitProductExtensions: estimate.inno.ebitProductExtensions.Answer,
        productsAvgEBITRateLastYear:
          estimate.inno.productsAvgEBITRateLastYear.Answer,
        productsSalesLastYear: estimate.inno.productsSalesLastYear.Answer,
      },
      mc: {
        avgPlannedManufactoringCost: estimate.mc.avgPlannedManufactoringCost.Answer,
        avgQuantity: estimate.mc.avgQuantity.Answer,
        completedProjectLastYear: estimate.mc.completedProjectLastYear.Answer,
        manufactoringCostDeviation:
          estimate.mc.manufactoringCostDeviation.Answer,
      },
      qc: {
        qualityCost: estimate.qc.qualityCost.Answer,
        qualityCostByRD: estimate.qc.qualityCostByRD.Answer,
      },
      rd: {
        personnelCostDevelopmentArea:
          estimate.rd.personnelCostDevelopmentArea.Answer,
        personnelCostinterdisciplinary:
          estimate.rd.personnelCostinterdisciplinary.Answer,
      },
    };
    await props.saveEstimation(input);
    setFormState("unchanged");
  }
  usePrompt(t("Sie haben nicht gespeicherte Änderungen, möchten Sie wirklich gehen?"), formState !== "unchanged");

  return (
    <Spin spinning={props.ebitState.isEbitLoading}>
      <Row align="middle" style={{ position: "absolute", top: "0px", right: "20px", zIndex: 2 }}>
        <Col span={24}>
          <Space align="center" size={5} style={{ flexFlow: "row-reverse" }}>
            <Tooltip placement="bottom" title={`${t("Estimation PDF")}`}>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => {
                  DownloadEstimationPDF();
                }}
                type="default"
              ></Button>
            </Tooltip>
            <Tooltip placement="bottom" title={formState === "saving" ? `${t("Saving Changes")}` : `${t("Save Changes")}`}>
              <Popconfirm
                title={`${t("Are you sure?")}`}
                onConfirm={() => saveChanges()}
                okText={`${t("Yes")}`}
                cancelText={`${t("No")}`}
              >
                <Button icon={<SaveOutlined />} type="default" disabled={formState === "saving"}></Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <div className="chart_container">
        <Row>
          <Col span={12}>
            <Waterfallv2
              key={`rate_waterfall_chart`}
              chartTitle={t("EBIT Potenzial")}
              isEstimationChart={true}
              // totalSale={estimate?.generalinfo?.salesLastYear ?? 0}
              type="Rate"
              data={data}
              scale={scale}
            />
          </Col>
          <Col span={12}>
            <Waterfallv2
              key={`cost_waterfall_chart`}
              isEstimationChart={true}
              chartTitle={t("EBIT Potenzial")}
              // totalSale={estimate?.generalinfo?.salesLastYear ?? 0}
              type="Cost"
              data={data}
              scale={scale}
            />
          </Col>
        </Row>
      </div>
      <Row align="middle">
        <Col span={24}>
          <Slider
            style={{ width: "50%", margin: "10px auto" }}
            defaultValue={1}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className="estimate-tab">
        <Collapse
          defaultActiveKey={["general_panel_1"]}
          expandIcon={({ isActive }) =>
            isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />
          }
          ghost={true}
        >
          <GeneralPanel
            key={"general_panel_1"}
            header={
              <Row>
                <Col span={25}>
                  <Title level={5}>{t("Allgemein")}</Title>
                </Col>
              </Row>
            }
            yearUnderReview={props.currentProject?.yearUnderReview.toString()}
            generalInfo={estimate.generalinfo}
            handle_change={handleGeneralChange}
          />

          <BasicPanel
            title="Cost Of Delay"
            class="celery"
            result={estimateResult.codPotential}
            type="cod"
            key="cod_5"
            model={estimate.cod}
            handle_change={handle_change}
          />
          <BasicPanel
            title={t("Innovationsleistung")}
            class="eucalyptus"
            type="inno"
            result={estimateResult.innoPotenial}
            key="inno_6"
            model={estimate.inno}
            handle_change={handle_change}
          />
          <BasicPanel
            title={t("F&E-Kosten")}
            type="rd"
            class="denim"
            key="rd_2"
            result={estimateResult.rDPotential}
            model={estimate.rd}
            handle_change={handle_change}
          />
          <BasicPanel
            title={t("Qualitätskosten")}
            type="qc"
            class="thunderbird"
            key="qc_4"
            model={estimate.qc}
            result={estimateResult.qCPotential}
            handle_change={handle_change}
          />
          <BasicPanel
            title={t("Herstellkosten")}
            class="rhino"
            result={estimateResult.mCPotential}
            type="mc"
            key="mc_5"
            model={estimate.mc}
            handle_change={handle_change}
          />
        </Collapse>
      </div>
    </Spin>
  );
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    ebitState: state.ebit,
    generalInfo: state.generalInfo,
    currentProject: state.generalInfo.profileResponse?.currentProject,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getEstimation: (projectId: number) =>
      dispatch({ type: ActionTypes.GetEbitEstimation, projectId: projectId }),
    saveEstimation: (model: EstimateDto) =>
      dispatch({ type: ActionTypes.SaveEbitEstimation, model: model }),
    downloadEstimationReport: (projectID: number, projectName: string) =>
      dispatch({ type: ActionTypes.DownloadEstimationReport, projectID, projectName }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstimateSection);
