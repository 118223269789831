import { connect } from "react-redux";
import { ActionTypes } from "../Redux/actionsTypes";
import { IGeneralState, IHelpPageState } from "../Redux/reducers/IGeneralState";
import { Empty, Spin } from "antd";
import parse from 'html-react-parser';
import { useEffect } from "react";
import 'react-quill/dist/quill.snow.css';
export interface IHelpPageProps {
    //history: any;   
    currentPageCode: string;
    helpPageState: IHelpPageState;
    getCurrentHelpPage: (pageName: string) => any;
}
const HelpPage = (props: IHelpPageProps) => {
    useEffect(() => {
        props.getCurrentHelpPage(props.currentPageCode);
    }, []);

    return <Spin spinning={props.helpPageState?.isCurrentHelpPageLoading}>
        <div className="parent-image ql-editor">
            {(props.helpPageState?.currentHelpPage) ?
                parse(props.helpPageState?.currentHelpPage) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Content"} />}
        </div> </Spin>;

}
const mapStateToProps = (state: IGeneralState) => {
    return {
        //isfullScreen: state.generalInfo.isFullScreen,
        //projects: state.projects.projects,
        //currentProject: state.generalInfo.profileResponse?.currentProject,
        //profileResponse: state.generalInfo.profileResponse,
        helpPageState: state.helpPagesForAdmin
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        //changeFullScreen: (state: boolean) => dispatch({ type: ActionTypes.ChangeFullScreen, state: state }),
        getCurrentHelpPage: (pageName: string) => dispatch({ type: ActionTypes.GetHelpPage, model: pageName })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpPage); 
