import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import HelpPageForAdminService from "../../Services/HelpPageForAdminService";
import { IHelpPage } from "../../Models/IHelpPage";

function* OnGetHelpPageById(action: any){
    try {
        yield put({ type: ActionTypes.CHANGE_HELP_PAGE_LOADER, isLoading: true });
        let _result: ICommonResponse = yield HelpPageForAdminService.GetHelpPageById(action.id);

        if (!_result.isError) {
            let result = _result.result as IHelpPage;
            yield put({ type: ActionTypes.SAVE_HELP_PAGE_FOR_EDIT, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_HELP_PAGE_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGet: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}

function* OnGetAllHelpPages() {
    try {
        yield put({ type: ActionTypes.CHANGE_HELP_PAGE_LOADER, isLoading: true });
        let _result: ICommonResponse = yield HelpPageForAdminService.GetAllHelpPage();

        if (!_result.isError) {
            let result = _result.result as IHelpPage[];
            yield put({ type: ActionTypes.SAVE_HELP_PAGE, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_HELP_PAGE_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGet: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}

function* OnGetHelpPage(action: any) {
    try {
        yield put({ type: ActionTypes.GetHelpPageLoader, isLoading: true });
        let _result: ICommonResponse = yield HelpPageForAdminService.GetHelpPage(action.model);
        if (!_result.isError) {
            let result = _result.result as string;
            yield put({ type: ActionTypes.SetHelpPage, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.GetHelpPageLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onGet: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}

function* onUpdateHelpPage(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_HELP_PAGE_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield HelpPageForAdminService.UpdateHelpPage(
            action.model
        );

        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield HelpPageForAdminService.GetHelpPageById(action.model.id);
            let result = _result.result as IHelpPage;
            yield put({ type: ActionTypes.SAVE_HELP_PAGE_FOR_EDIT, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_HELP_PAGE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnChangeHelpPageCode(action: any) {
    //console.log("OnChangeHelpPageCode", action.result );
    yield put({ type: ActionTypes.SetHelpPageCode, result: action.result });
}

function* watchGetAllHelpPages() {
    yield takeEvery(ActionTypes.GET_ALL_HELP_PAGE, OnGetAllHelpPages)
}

function* watchGetHelpPageById() {
    yield takeEvery(ActionTypes.GET_HELP_PAGE_ById, OnGetHelpPageById)
}

function* watchUpdateHelpPage() {
    yield takeEvery(ActionTypes.UPDATE_HELP_PAGE, onUpdateHelpPage)
}
function* watchGetHelpPage() {
    yield takeEvery(ActionTypes.GetHelpPage, OnGetHelpPage)
}

function* watchChangeHelpPageCode() {
    yield takeEvery(ActionTypes.ChangeHelpPageCode, OnChangeHelpPageCode)
}

export default function* HelpPagesForAdminSagas() {
    yield all([
        watchGetAllHelpPages(),
        watchUpdateHelpPage(),
        watchGetHelpPage(),
        watchChangeHelpPageCode(),
        watchGetHelpPageById()
    ])
}