
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import CustomerUsersService from "../../Services/CustomerUsersService";
import { ActionTypes } from "../actionsTypes";
import DepartmentByCustomerService from "../../Services/DepartmentByCustomerService";
import { IDepartmentByCustomerItem, IDepartmentItem } from "../../Models/IDepartment";
import UserManagementService from "../../Services/UserManagementService";
import DepartmentByBmProjectService from "../../Services/DepartmentByBmProjectService";

function* OnCreateDepartmentUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultCreate: ICommonResponse = yield CustomerUsersService.CreateCustomerUser(
      action.model
    );
    yield refreshDepartments(resultCreate, action);
    // if (!resultCreate.isError) {
    //   let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
    //   let result = _result.result as IDepartmentByCustomerItem[];
    //   yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result });
    //   yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
    // } else {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
    // } 
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onCreateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
// function* onUpdateDepartmentUser(action: any) {
//   try {
//     yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
//     yield put({ type: ActionTypes.SetModalLoader, result: true });
//     let resultUpdate: ICommonResponse = yield CustomerUsersService.UpdateCustomerUser(action.model);
//     yield refreshDepartments(resultUpdate, action);
     
//     yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
//     yield put({ type: ActionTypes.SetModalLoader, result: false });
//   } catch (error) {
//     console.error("ERR in onUpdateCustomerUser: ", error);
//     yield put({
//       type: ActionTypes.SetCommonResponse,
//       result: {
//         isError: true,
//         statusCode: 600,
//         error: { exceptionMessage: ToastMessages.UnExpectedError },
//       } as ICommonResponse,
//     });
//   }
// }
// make customer person to user
function* onMakeDepartmentPersonToUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultMake: ICommonResponse = yield CustomerUsersService.MakeCustomerPersonToUser(action.model);
    yield refreshDepartments(resultMake, action);
    // if (!resultMake.isError) {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultMake });
    //   let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
    //   let result = _result.result as IDepartmentByCustomerItem[];
    //   yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result })
    //   yield put({ type: ActionTypes.SetModalResponse, result: _result });
    // } else {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultMake });
    // }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onMake: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onDeleteDepartmentPerson(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultDelete: ICommonResponse = yield CustomerUsersService.deletePerson(action.personId);
    yield refreshDepartments(resultDelete, action);
    // if (!resultDelete.isError) {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
    //   let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
    //   let result = _result.result as IDepartmentByCustomerItem[];
    //   yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result });
    //   yield put({ type: ActionTypes.SetModalResponse, result: _result });
    // } else {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
    // }
    
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onDeletePerson: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

//BmProjectForUser Setting
function* onAssignNewBmProjectForDepartmentUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultAssign: ICommonResponse = yield CustomerUsersService.assignNewBmProjectForUser(action.model);
    yield refreshDepartments(resultAssign, action);
    // if (!resultAssign.isError) {
    //   let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
    //   let result = _result.result as IDepartmentByCustomerItem[];
    //   yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result })
    //   yield put({ type: ActionTypes.SetModalResponse, result: _result });
    // } else {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultAssign });
    // }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onUpdateCustomerUser: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onDeleteBmProjectForDepartmentUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse = yield CustomerUsersService.deleteBmProjectForUser(action.customerUserId);
    yield refreshDepartments(_result, action);
    // if (!_result.isError) {
    //   let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
    //   let result = _result.result as IDepartmentByCustomerItem[];
    //   yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result })
    //   yield put({ type: ActionTypes.SetModalResponse, result: _result });
    // } else {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    // }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onDeleteBmProjectForUSer: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onActiveDepartmentUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultActive: ICommonResponse = yield UserManagementService.ActiveUser(action.userId);
    yield refreshDepartments(resultActive, action);
    // if (!resultActive.isError) {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultActive });
    //   let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
    //   let result = _result.result as IDepartmentByCustomerItem[];
    //   yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result })
    //   yield put({ type: ActionTypes.SetModalResponse, result: _result });
    // } else {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultActive });
    // }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onActive: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onDeactivateDepartmentUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultDeactivate: ICommonResponse = yield UserManagementService.DeactivateUser(action.userId);
    yield refreshDepartments(resultDeactivate, action);
    // if (!resultDeactivate.isError) {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultDeactivate });
    //   let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
    //   let result = _result.result as IDepartmentByCustomerItem[];
    //   yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result })
    //   yield put({ type: ActionTypes.SetModalResponse, result: _result });
    // } else {
    //   yield put({ type: ActionTypes.SetCommonResponse, result: resultDeactivate });
    // }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onDeactivate: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* refreshDepartments(result, action){
  if (!result.isError) {
    if (action.customerId) {
      let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
      if (!_result.isError) {
        let result = _result.result as IDepartmentByCustomerItem[];
        yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result });
      } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _result });
      }
    }
    if (action.bmProjectId) {
      let _result: ICommonResponse = yield DepartmentByBmProjectService.GetDepartmentsByBmProject(action.bmProjectId);
      if (!_result.isError) {
        let result = _result.result as IDepartmentItem[];
        yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_BM_PROJECT, result: result });
      } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _result });
      }
    }
  }
  yield put({ type: ActionTypes.SetCommonResponse, result: result });
}

function* watchCreateDepartmentUser() {
  yield takeEvery(ActionTypes.CREATE_CUSTOMER_DEPARTMENT_USER, OnCreateDepartmentUser);
}
// function* watchUpdateDepartmentUser() {
//   yield takeEvery(ActionTypes.UPDATE_CUSTOMER_DEPARTMENT_USER, onUpdateDepartmentUser);
// }
function* watchMakeDepartmentPersonToUser() {
  yield takeEvery(ActionTypes.MAKE_DEPARTMENT_PERSON_TO_USER, onMakeDepartmentPersonToUser);
}
function* watchDeleteDepartmentPerson() {
  yield takeEvery(ActionTypes.DELETE_DEPARTMENT_PERSON, onDeleteDepartmentPerson);
}
function* watchAssignNewBmProjectForDepartmentUser() {
  yield takeEvery(ActionTypes.ASSIGN_NEW_BM_PROJECT_FOR_DEPARTMENT_USER, onAssignNewBmProjectForDepartmentUser);
}
function* watchDeleteBmProjectForDepartmentUser() {
  yield takeEvery(ActionTypes.DELETE_BM_PROJECT_FOR_DEPARTMENT_USER, onDeleteBmProjectForDepartmentUser);
}
function* watchActiveDepartmentUser() {
  yield takeEvery(ActionTypes.ACTIVE_DEPARTMENT_USER, onActiveDepartmentUser)
}
function* watchDeactivateDepartmentUser() {
  yield takeEvery(ActionTypes.DEACTIVATE_DEPARTMENT_USER, onDeactivateDepartmentUser)
}



export default function* DepartmentUsersSagas() {
  yield all([
    watchCreateDepartmentUser(),
    //watchUpdateDepartmentUser(),
    watchMakeDepartmentPersonToUser(),
    watchDeleteDepartmentPerson(),
    watchAssignNewBmProjectForDepartmentUser(),
    watchDeleteBmProjectForDepartmentUser(),
    watchActiveDepartmentUser(),
    watchDeactivateDepartmentUser()

  ]);
} 

