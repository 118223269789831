import { CostOfDelayDto, CostOfDelayWithDprojectDto, InnovationCostDto, InnovationCostWithDprojectDto, ManufacturingCostDto, ManufacturingCostWithDprojectDto, QualityCostDto, QualityCostWithDprojectDto } from "../Scenes/Tools/EBITTool-V2/Models/CostTypesResponse";
import Estimate from "../Scenes/Tools/EBITTool-V2/Models/Estimate";
import { RnDUnit } from "../Scenes/Tools/EBITTool-V2/Models/RnDUnit";
import { ToastMessages } from "../Enums/Messages";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import { saveAs } from "file-saver";
import i18n from "../i18n";
import type { RcFile } from 'antd/es/upload/interface';
export default class EbitService {
    static async GetRnD(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.getRnd)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }
    static async GetEstimationReportPDF(projectName: string) {
        await asupapi
            .get(endPoints.downloadEstimation, {
                responseType: 'blob'
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_estimation_Report" + `_${Date.now()}` + ".pdf";
                // var fileName = "estimation_" + projectName + ".pdf";//props.currentProject.project_name  
                var blob = new Blob([response.data], {
                    type: 'application/pdf',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async GetDisciplinesVsBestPracticeExcel(projectName: string) {
        await asupapi
            .get(endPoints.GetDisciplinesVsBestPracticeExcel, {
                responseType: 'blob'
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_DisciplinesVsBestPractices" + `_${Date.now()}` + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async ExportCostTypesExcel(projectName: string, projectID?:number) {
        await asupapi
            .get(endPoints.ExportCostTypesExcel, {params: {
                bmProjectId: projectID
            },
                responseType: 'blob'
            })
            .then(function (response) {
                console.log('CostTypes--response: ', response);
                var fileName = "Asup_" + projectName + "_CostTypes_Benchmark" + `_${Date.now()}` + ".xlsx";
                // var fileName = projectName + "_CostTypes_Benchmark" + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async ExportDisciplinesExcel(projectName: string) {
        await asupapi
            .get(endPoints.ExportDisciplinesExcel, {
                responseType: 'blob'
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_DiciplinesBenchmarking" + `_${Date.now()}` + ".xlsx";
                // var fileName = projectName + "_Diciplines_Benchmark" + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async ExportFullExcelReport(projectName: string) {
        await asupapi
            .get(endPoints.ExportFullExcelReport, {
                responseType: 'blob',
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_EBIT_Performance" + `_${Date.now()}` + ".xlsx";
                // var fileName = projectName + "_EBIT_Performance" + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    } 

    // static async ExportPresentation(projectName: string) {
    //     await asupapi
    //         .get(endPoints.ExportEbitPowerPoint, {
    //             responseType: 'blob'
    //         })
    //         .then(function (response) {
    //             var contentDisposition = response.headers["content-disposition"];
    //             var matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    //             var filename = "File.pptx";
    //             if (matches != null && matches[1]) {
    //                 filename = matches[1].replace(/['"]/g, '');

    //             }else{
    //                 filename = "File.pptx";
    //             }

    //             // var filename = "example.pptm";//match[1];
    //             // console.log("filename:", contentDisposition, match, filename);
    //             var blob = new Blob([response.data], {
    //                 type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    //             });
    //             saveAs(blob, filename);
    //         }).catch(function (error) {
    //             console.log('error1 ', error);
    //         });
    // }

    static async ExportPresentationPPtm(projectName: string) {
        await asupapi
            .get(endPoints.ExportEbitPowerPoint, {
                responseType: 'blob'
            })
            .then(function (response) {
                var contentDisposition = response.headers["content-disposition"];
                var matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                var filename = "File.zip";
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');

                }else{
                    filename = "File.zip";
                }

                // var filename = "example.pptm";//match[1];
                // console.log("filename:", contentDisposition, match, filename);
                var blob = new Blob([response.data], {
                    type: 'application/zip',
                });
                saveAs(blob, filename);
            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }

    static async ExportCODExcelTable(projectName: string, projectID?:number ) {
        await asupapi
            .get(endPoints.ExportCODExcelTable, {params: {
                bmProjectId: projectID
            },
                responseType: 'blob'
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_Cost_Of_Delay_Table" + `_${Date.now()}` + ".xlsx";
                // var fileName = projectName + "_Cost_Of_Delay" + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async ImportCODExcelTable(model: FormData): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.ImportCODExcelTable, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async ExportQKExcelTable(projectName: string, projectID?:number) {
        await asupapi
            .get(endPoints.ExportQKExcelTable, {params: {
                bmProjectId: projectID
            },
                responseType: 'blob'
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_Quality_Costs_Table" + `_${Date.now()}` + ".xlsx";
                // var fileName = projectName + "_Qiality_Costs" + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async ImportQKExcelTable(model: FormData): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.ImportQKExcelTable,  model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }
    static async ExportHKExcelTable(projectName: string, projectID: number) {
        await asupapi
            .get(endPoints.ExportHKExcelTable, {params: {
                bmProjectId: projectID
            },
                responseType: 'blob'
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_Manufactoring_Costs_Table" + `_${Date.now()}` + ".xlsx";
                // var fileName = projectName + "_Manufactoring_Costs" + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async ImportMCExcelTable(model: FormData): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.ImportMCExcelTable, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }
    static async ExportInnoExcelTable(projectName: string, projectID: number) {
        await asupapi
            .get(endPoints.ExportInnoExcelTable, {params: {
                bmProjectId: projectID
            },
                responseType: 'blob'
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_Innovations_Costs_Table" + `_${Date.now()}` + ".xlsx";
                // var fileName = projectName + "_Inno" + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async ImportInnoExcelTable(model: FormData): Promise<ICommonResponse> {
        console.log("mousa model", model)
        return await asupapi
            .post(endPoints.ImportInnoExcelTable, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }
    static async ExportRDTableExcel(projectName: string) {
        await asupapi
            .get(endPoints.ExportRDTableExcel, {
                responseType: 'blob'
            })
            .then(function (response) {
                var fileName = "Asup_" + projectName + "_R&D_Costs_Table" + `_${Date.now()}` + ".xlsx";
                // var fileName = projectName + "_R&D_Table" + ".xlsx";
                var blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);

            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
    static async CreateRnDUnit(rndUnit: RnDUnit): Promise<ICommonResponse> {
        console.log("CreateRnDUnit", rndUnit);
        return await asupapi
            .post(endPoints.CreateRnDUnit, rndUnit)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }


    static async UpdateRnDUnit(rndUnit: RnDUnit): Promise<ICommonResponse> {
        console.log("UpdateRnDUnit", rndUnit);
        return await asupapi
            .post(endPoints.UpdateRnDUnit, rndUnit)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async SaveUnitCostsChanges(rndUnits: RnDUnit[]): Promise<ICommonResponse> {
        console.log("SaveUnitCostsChanges", rndUnits);
        return await asupapi
            .post(endPoints.SaveRndUnitCosts, rndUnits)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async DeleteRnDUnit(rndUnit: number): Promise<ICommonResponse> {
        console.log("DeleteRnDUnit", rndUnit);
        return await asupapi
            .delete(endPoints.DeleteRnDUnit, {
                params: {
                    unitId: rndUnit
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }


    static async GetDprojects(projectID:number): Promise<ICommonResponse> {
        console.log("From Here")
        return await asupapi
            .get(endPoints.GetDprojects, { params: { bmProjectId: projectID } })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CreateQkRow(qc: QualityCostDto): Promise<ICommonResponse> {
        console.log("CreateQkRow", qc);
        return await asupapi
            .post(endPoints.CreateQualityCost, qc)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CreateQKWithNewDproject(qc: QualityCostWithDprojectDto): Promise<ICommonResponse> {
        console.log("CreateQKWithNewDproject", endPoints.CreateQualityCostWithNewDproject, qc);
        return await asupapi
            .post(endPoints.CreateQualityCostWithNewDproject, qc)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async UpdateQkRow(qc: QualityCostDto): Promise<ICommonResponse> {
        console.log("UpdateQkRow", qc);
        return await asupapi
            .post(endPoints.UpdateQualityCost, qc)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async DeleteQkRow(qcId: number, forEbit: boolean): Promise<ICommonResponse> {
        console.log("DeleteQkRow", qcId);
        return await asupapi
            .delete(endPoints.DeleteQualityCost, {
                params: {
                    dprojectId: qcId,
                    forEbit: forEbit
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async GetEstimation(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetEstimation)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async SaveEstimation(estimate: Estimate): Promise<ICommonResponse> {
        console.log("SaveEstimation", estimate);
        return await asupapi
            .post(endPoints.SaveEstimation, estimate)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async GetEvaluation(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetEvaluation)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }
    static async GetEbitBenchmark(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetEBITBenchmark)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }
    static async GetEbitProgress(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetEBITAnalyzisProgress)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CreateCODRow(model: CostOfDelayDto): Promise<ICommonResponse> {
        console.log("CreateCODRow", model);
        return await asupapi
            .post(endPoints.CreateCostOfDelay, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CreateCODWithNewDproject(model: CostOfDelayWithDprojectDto): Promise<ICommonResponse> {
        console.log("CreateCODWithNewDproject", model);
        return await asupapi
            .post(endPoints.CreateCostOfDelayWithNewDproject, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async UpdateCODRow(model: CostOfDelayDto): Promise<ICommonResponse> {
        console.log("UpdateCODRow", model);
        return await asupapi
            .post(endPoints.UpdateCostOfDelay, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async DeleteCODRow(id: number, forEbit: boolean): Promise<ICommonResponse> {
        console.log("DeleteCODRow", id);
        return await asupapi
            .delete(endPoints.DeleteCostOfDelay, {
                params: {
                    dprojectId: id,
                    forEbit: forEbit
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }



    static async CreateMCRow(model: ManufacturingCostDto): Promise<ICommonResponse> {
        console.log("CreateMCRow", model);
        return await asupapi
            .post(endPoints.CreateManufacturingCost, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CreateMCWithNewDproject(model: ManufacturingCostWithDprojectDto): Promise<ICommonResponse> {
        console.log("CreateMCWithNewDproject", model);
        return await asupapi
            .post(endPoints.CreateManufacturingCostWithNewDproject, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async UpdateMCRow(model: ManufacturingCostDto): Promise<ICommonResponse> {
        console.log("UpdateMCRow", model);
        return await asupapi
            .post(endPoints.UpdateManufacturingCost, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async DeleteMCRow(id: number, forEbit: boolean): Promise<ICommonResponse> {
        console.log("DeleteMCRow", id);
        return await asupapi
            .delete(endPoints.DeleteManufacturingCost, {
                params: {
                    dprojectId: id,
                    forEbit: forEbit
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CreateInnoRow(model: InnovationCostDto): Promise<ICommonResponse> {
        console.log("CreateInnoRow", model);
        return await asupapi
            .post(endPoints.CreateInnovationCost,model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CreateInnoWithNewDproject(model: InnovationCostWithDprojectDto): Promise<ICommonResponse> {
        console.log("CreateInnoWithNewDproject", model);
        return await asupapi
            .post(endPoints.CreateInnovationCostWithNewDproject,model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async UpdateInnoRow(model: InnovationCostDto): Promise<ICommonResponse> {
        console.log("UpdateInnoRow", model);
        return await asupapi
            .post(endPoints.UpdateInnovationCost, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async DeleteInnoRow(id: number, forEbit: boolean): Promise<ICommonResponse> {
        console.log("DeleteInnoRow", id);
        return await asupapi
            .delete(endPoints.DeleteInnovationCost, {
                params: {
                    dprojectId: id,
                    forEbit: forEbit
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

}