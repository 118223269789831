import { Button, Form, Input, InputNumber, Checkbox, Select, Alert, Spin, Space } from "antd";
import { useEffect, useState } from 'react';
import DisciplineTable from "./DisciplineTable";
import { IDisciplineItemWithKey, IDisciplineItem, IDiscipline } from '../../../Models/IDiscipline';
import { ActionTypes } from "../../../Redux/actionsTypes";
import { connect } from "react-redux";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import './index.scss'
import ModalAdmin from "../../../Components/ModalAdmin/ModalAdmins";
import ColorPickerInput from "../../../Components/ColorPickerInput";
import { RulesColorPicker, RulesDescription, RulesName, RulesRequire } from "../Rules/RulesValidation";
import { t } from "i18next";
import i18n from "../../../i18n";

interface IProps {
    dataDisciplines: IDiscipline[];
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getDisciplines: () => any;
    createDiscipline: (model: IDisciplineItem) => any;
}

const Discipline = (props: IProps) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<IDisciplineItem>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => { props.getDisciplines(); }, []);
    const disciplinesData: IDisciplineItemWithKey[] = props.dataDisciplines?.map(disc => {
        return { key: `disc_${disc.id}`, ...disc }
    }) ?? [];
    const formValuesHandler = async () => {
        try {
            await form.validateFields();

            const newDisciplines: IDisciplineItem = {
                ...formValues,
                nameEn: formValues.nameEn,
                nameDe: formValues.nameDe,
                parent: formValues.parent === -1 ? null : formValues.parent,
                viewOrder: formValues.viewOrder,
                backgroundColor: formValues.backgroundColor.substring(1),
                forgroundColor: formValues.forgroundColor.substring(1),
                isOriginal: formValues.isOriginal,
                descriptionEn: formValues.descriptionEn,
                descriptionDe: formValues.descriptionDe,
            };
            await props.createDiscipline(newDisciplines);




        } catch (error) {
            console.log(error);

        }
    }


    const closeAndReset = () => {
        form.resetFields();
        setFormValues({} as IDisciplineItem);
        setIsModalVisible(false);
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component discipline-component">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Discipline")}`} btnText={`${t("Add")}`}
                        width="1000px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>
                        <Form
                            layout="vertical"
                            form={form}


                        >
                            <Form.Item label={`${t("English Name")}`} name='nameEn'
                                rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
                                hasFeedback style={{ width: "47.5%", marginRight: "2.5%", display: "inline-block" }}>
                                <Input onChange={(e) => setFormValues({ ...formValues, nameEn: (e.target.value)?.trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("German Name")}`} name='nameDe'
                                rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
                                hasFeedback style={{ width: "47.5%", marginLeft: "2.5%", display: "inline-block" }}>
                                <Input onChange={(e) => setFormValues({ ...formValues, nameDe: (e.target.value)?.trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("Parent")}`} name='parent' style={{ width: "40%", display: "inline-block", marginRight: "2%" }}>
                                <Select showSearch placeholder={`${t("Select Parent")}`} optionFilterProp="children"
                                    onChange={(value: number) => setFormValues({ ...formValues, parent: value })}>
                                    <Select.Option value={-1} key="parent_none">{`--- ${t("None")} ---`}</Select.Option>
                                    {disciplinesData?.map((d: any) => <Select.Option value={d?.id} key={d?.id}>{i18n.language === 'de' ? d?.nameDe : d?.nameEn}</Select.Option>)}
                                    {/*  <Select.Option value={-1} key="parent_none">{`${t("None")}`}</Select.Option> */}
                                </Select>
                            </Form.Item>
                            <Form.Item label={`${t("View Order")}`} name='viewOrder' rules={RulesRequire({ filedName: `${t("View Order")}` })}
                                validateTrigger='onBlur' hasFeedback style={{ width: "20%", display: "inline-block" }}>
                                <InputNumber onChange={(e) => setFormValues({ ...formValues, viewOrder: Number(e) })} min={0} />
                            </Form.Item>

                            <Form.Item label={`${t("Background Color")}`} name='backgroundColor' style={{ width: "20%", marginRight: "3.5%", display: "inline-block", textAlign: "center" }}
                                rules={RulesColorPicker({ filedName: `${t("Background Color")}`, colorValue: formValues?.backgroundColor })} className="color-picker-required">
                                <ColorPickerInput colorValue={formValues?.backgroundColor} onChangeComplete={async (color: any, event: any) => {
                                    try {
                                        setFormValues({ ...formValues, backgroundColor: color?.hex })
                                        await form.validateFields();
                                    }
                                    catch (err) {
                                        console.log("Background color", err);
                                    }

                                }} />
                            </Form.Item>

                            <Form.Item label={`${t("Text Color")}`} name='forgroundColor' style={{ width: "10%", marginLeft: "3%", display: "inline-block", textAlign: "center" }}
                                rules={RulesColorPicker({ filedName: `${t("Text Color")}`, colorValue: formValues?.forgroundColor })}
                                className="color-picker-required"
                            >
                                <ColorPickerInput colorValue={formValues?.forgroundColor} onChangeComplete={async (color: any, event: any) => {
                                    try {
                                        setFormValues({ ...formValues, forgroundColor: color?.hex })
                                        await form.validateFields();
                                    }
                                    catch (err) {
                                        console.log("text color", err);
                                    }
                                }} />

                            </Form.Item>
                            <Form.Item label={`${t("English Description")}`} name='discriptionEn'
                                rules={RulesDescription({ name: `${t("English Description")}`, countChar: 1500})} hasFeedback>
                                <Input.TextArea onChange={(e) => setFormValues({ ...formValues, descriptionEn: (e.target.value)?.trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("German Description")}`} name='discriptionDe'
                                rules={RulesDescription({ name: `${t("German Description")}`, countChar: 1500 })}
                                hasFeedback>
                                <Input.TextArea onChange={(e) => setFormValues({ ...formValues, descriptionDe: (e.target.value)?.trimStart() })} />
                            </Form.Item>
                            <Form.Item name='isOriginal' valuePropName="checked">
                                <Checkbox onChange={(e: CheckboxChangeEvent) => setFormValues({ ...formValues, isOriginal: e.target.checked })}>{`${t("Is Original")}`}</Checkbox>
                            </Form.Item>
                        </Form>
                    </ModalAdmin>
                    <DisciplineTable
                        dataDisciplines={disciplinesData}
                    />
                </Space>
            </div >
        </Spin >
    )
};
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.disciplines.isDisciplinesLoading,
        dataDisciplines: state.disciplines.disciplinesResult,
        isModalLoading: state.disciplines.isModalLoading,
        modalResponse: state.disciplines.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getDisciplines: () => dispatch({ type: ActionTypes.GET_DISCIPLINES }),
        createDiscipline: (model: IDisciplineItem) => dispatch({ type: ActionTypes.CREATE_DISCIPLINE, model }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Discipline);