import { Badge, Button, Col, Form, Input, InputNumber, Rate, Row, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { IVote, IaVoteReviewDto } from "../../../../Models/InterfaceAnalysis/IAItems";
import TextArea from "antd/lib/input/TextArea";
import { t } from "i18next";
import { RulesName, RulesRequire } from "../../../Admin/Rules/RulesValidation";

interface IProps {
    vote: IVote;
    onReview: (model: IaVoteReviewDto) => any;
}
const ReviewForm = (props: IProps) => {
    const [form] = Form.useForm();
    const [isReject, setIsReject] = useState(true);
    const [isExludedByDefault, setIsExludedByDefault] = useState(props.vote.noPreviousInteraction || (
        props.vote.qRate == null && props.vote.kRate == null && props.vote.rRate == null
    ));
    console.log("props.vote.qRate == null && props.vote.kRate == null && props.vote.rRate == null",
        props.vote.qRate, props.vote.kRate, props.vote.rRate)
    const OnHandle = async (isAccept: boolean) => {
        try {
            await form.validateFields();
            let fields = form.getFieldsValue();
            if (!isAccept) {
                if (!fields["field_review_comment"]) {
                    return;
                }
            }

            console.log("OnHandle", fields);
            props.onReview({
                answerId: props.vote.id,
                comment: fields["field_review_comment"],
                isApproved: isAccept,
                kRate: fields["field_k"],
                qRate: fields["field_q"],
                rRate: fields["field_r"]
            });
        }
        catch (err) {
            console.log("exclude error in interface: ", err);
        }
    }

    return (
        <Form form={form} name="horizontal_ia_form" layout="vertical">
            <Row >
                <Col xs={6} sm={4} md={8} lg={8} xl={4} style={{ marginRight: "5px" }}>
                    <Form.Item
                        name={`field_q_user`}
                        initialValue={props.vote.qRate}
                    >
                        <InputNumber disabled addonBefore={<>{`Q`}</>} />
                    </Form.Item>
                </Col>
                <Col xs={6} sm={4} md={8} lg={8} xl={4} style={{ marginRight: "5px" }}>
                    <Form.Item
                        name={`field_r_user`}
                        initialValue={props.vote.rRate}
                    >
                        <InputNumber disabled addonBefore={<>{`R`}</>} />
                    </Form.Item>
                </Col>
                <Col xs={6} sm={4} md={8} lg={8} xl={4} style={{ marginRight: "5px" }}>
                    <Form.Item
                        name={`field_k_user`}
                        initialValue={props.vote.kRate}
                    >
                        <InputNumber disabled prefix={null} addonBefore={<>{`K`}</>} />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col xs={6} sm={4} md={8} lg={8} xl={4} style={{ marginRight: "5px" }}>
                    <Form.Item
                        name={`field_q`}
                        initialValue={(props.vote.review) ?
                            ((props.vote?.review?.qRate == 0) ? null : props.vote?.review?.qRate) :
                            props.vote.qRate}
                    >
                        <InputNumber precision={0} min={1} max={10} addonBefore={<>{`Q`}</>} />
                    </Form.Item>
                </Col>
                <Col xs={6} sm={4} md={8} lg={8} xl={4} style={{ marginRight: "5px" }}>
                    <Form.Item
                        name={`field_r`}
                        initialValue={(props.vote.review) ?
                            ((props.vote?.review?.rRate == 0) ? null : props.vote?.review?.rRate) :
                            props.vote.rRate}
                    >
                        <InputNumber precision={0} min={1} max={10} addonBefore={<>{`R`}</>} />
                    </Form.Item>
                </Col>
                <Col xs={6} sm={4} md={8} lg={8} xl={4} style={{ marginRight: "5px" }}>
                    <Form.Item
                        name={`field_k`}
                        initialValue={(props.vote.review) ?
                            (props.vote?.review?.kRate == 0) ? null : props.vote?.review?.kRate
                            : props.vote.kRate
                        }
                    >
                        <InputNumber precision={0} min={1} max={10} prefix={null} addonBefore={<>{`K`}</>} />
                    </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ marginRight: "5px" }}>
                    <Form.Item
                        name={`field_review_comment`}
                        initialValue={props.vote.review?.comment}
                        rules={!isReject ? [] : RulesName({ name: `${t("Reason")}`, countChar: 250 })}
                    >
                        <TextArea rows={1} autoSize={true} placeholder={`${t("Write your comment")}`} maxLength={250} />
                    </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={16} lg={16} xl={8} style={{ marginRight: "5px" }}>
                    {
                        (props.vote?.review) ? <Space align="center" direction="horizontal" style={{ width: "fit-content" }}>
                            {(props.vote.review.isApproved == false) ? <Tag color="magenta">{`${t("Excluded")}`}</Tag> :
                                <Tag color="green">{`${t("Approved")}`}</Tag>}
                        </Space>
                            :
                            (isExludedByDefault) ? <Space align="center" direction="horizontal" style={{ width: "fit-content" }}>
                                <Tag color="magenta">{`${t("Excluded")}`}</Tag>
                                <Button
                                    type="ghost"
                                    //htmlType="submit"
                                    onClick={() => {
                                        setIsExludedByDefault(false);
                                    }}
                                >{`${t("Review")}`}
                                </Button>
                            </Space>
                                :
                                <Space align="center" direction="horizontal" style={{ width: "fit-content" }}>
                                    <Button
                                        type="ghost"
                                        //htmlType="submit"
                                        onClick={() => {
                                            setIsReject(false);
                                            OnHandle(true)
                                        }}
                                    >{`${t("Save")}`}
                                    </Button>
                                    <Button
                                        type="ghost"
                                        //htmlType="submit"
                                        onClick={() => {
                                            setIsReject(true);
                                            OnHandle(false)
                                        }}
                                    >{`${t("Exclude")}`}
                                    </Button>
                                </Space>
                    }
                </Col>
            </Row>
        </Form>
    );
}

export default ReviewForm;