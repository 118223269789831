import { Button, Form, Input, InputNumber, Spin, Space, Checkbox } from "antd";
import { useEffect, useState } from 'react';
import { ActionTypes } from "../../../Redux/actionsTypes";
import { connect } from "react-redux";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import './index.scss'
import ModalAdmin from "../../../Components/ModalAdmin/ModalAdmins";
import ColorPickerInput from "../../../Components/ColorPickerInput";
import { RulesColorPicker, RulesDescription, RulesName, RulesRequire } from "../Rules/RulesValidation";
import { t } from "i18next";
import { IFrontLoadingDepartment } from "../../../Models/IFrontLoading";
import FrontLoadingDepartmentTable from "./FrontLoadingDepartmentTable";

interface IProps {
    frontLoadingDepartmentData: IFrontLoadingDepartment[];
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getAllFrontLoadingDepartment: () => any;
    createFrontLoadingDepartment: (model: IFrontLoadingDepartment) => any;
}
export interface IColorValues {
    backgroundColor: string;
    forgroundColor: string;
}

const FrontLoadingDepartment = (props: IProps) => {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [colorValues, setColorValues] = useState<IColorValues>()
    useEffect(() => { props.getAllFrontLoadingDepartment(); }, []);
    const departments: IFrontLoadingDepartment[] = props.frontLoadingDepartmentData?.map(department => {
        return { key: `frontDepart_${department.id}`, ...department }
    }) ?? [];
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();
            const newDepartment: IFrontLoadingDepartment = {
                nameEn: values.nameEn.trimStart(),
                nameDe: values.nameDe.trimStart(),
                viewOrder: values.viewOrder,
                backgroundColor: colorValues?.backgroundColor?.substring(1),
                forgroundColor: colorValues?.forgroundColor?.substring(1),
                descriptionEn: values.descriptionEn ?? '',
                descriptionDe: values.descriptionDe ?? '',
                isOriginal: values.isOriginal
            };
            await props.createFrontLoadingDepartment(newDepartment);
        } catch (error) {
            console.log(error);

        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setColorValues({} as IColorValues)
        setIsModalVisible(false);
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component frontLoadingDepartment_component ">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("FrontLoading Department")}`} btnText={`${t("Add")}`}
                        width="1000px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>
                        <Form
                            layout="vertical"
                            form={form}
                        >
                            <Form.Item label={`${t("English Name")}`} name='nameEn'
                                rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
                                hasFeedback style={{ width: "47.5%", marginRight: "2.5%", display: "inline-block" }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={`${t("German Name")}`} name='nameDe'
                                rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
                                hasFeedback style={{ width: "47.5%", marginLeft: "2.5%", display: "inline-block" }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={`${t("View Order")}`} name='viewOrder' rules={RulesRequire({ filedName: `${t("View Order")}` })}
                                validateTrigger='onBlur' hasFeedback style={{ width: "20%", display: "inline-block" }}>
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item label={`${t("Background Color")}`} name='backgroundColor' style={{ width: "20%", marginRight: "3.5%", display: "inline-block", textAlign: "center" }}
                                rules={RulesColorPicker({ filedName: `${t("Background Color")}`, colorValue: colorValues?.backgroundColor })} className="color-picker-required">
                                <ColorPickerInput colorValue={colorValues?.backgroundColor} onChangeComplete={async (color: any, event: any) => {

                                    try {
                                        setColorValues({ ...colorValues, backgroundColor: color.hex })
                                        await form.validateFields();
                                    }
                                    catch (err) {
                                        console.log("Background color", err);
                                    }
                                }} />
                            </Form.Item>
                            <Form.Item label={`${t("Text Color")}`} name='forgroundColor' style={{ width: "10%", marginLeft: "3%", display: "inline-block", textAlign: "center" }}
                                rules={RulesColorPicker({ filedName: `${t("Text Color")}`, colorValue: colorValues?.forgroundColor })}
                                className="color-picker-required"
                            >
                                <ColorPickerInput colorValue={colorValues?.forgroundColor} onChangeComplete={async (color: any, event: any) => {
                                    try {
                                        setColorValues({ ...colorValues, forgroundColor: color.hex })
                                        await form.validateFields();
                                    }
                                    catch (err) {
                                        console.log("Text color", err);
                                    }
                                }} />
                            </Form.Item>
                            <Form.Item label={`${t("English Description")}`} name='discriptionEn'
                                rules={RulesDescription({ name: `${t("English Description")}`, countChar: 1500 })} hasFeedback>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item label={`${t("German Description")}`} name='discriptionDe'
                                rules={RulesDescription({ name: `${t("German Description")}`, countChar: 1500 })}
                                hasFeedback>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name='isOriginal'  valuePropName="checked">
                                <Checkbox >{`${t("Is Original")}`}</Checkbox>
                            </Form.Item>
                        </Form>
                    </ModalAdmin>
                    <FrontLoadingDepartmentTable
                        departmentsData={departments}
                    />
                </Space>
            </div >
        </Spin >
    )
};
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.frontLoadingDepartment.isFrontLoadingDepartmentLoading,
        frontLoadingDepartmentData: state.frontLoadingDepartment.frontLoadingDepartmentResult,
        isModalLoading: state.frontLoadingDepartment.isModalLoading,
        modalResponse: state.frontLoadingDepartment.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllFrontLoadingDepartment: () => dispatch({ type: ActionTypes.GET_FRONT_LOADING_DEPARTMENTS }),
        createFrontLoadingDepartment: (model: IFrontLoadingDepartment) => dispatch({ type: ActionTypes.CREATE_FRONT_LOADING_DEPARTMENT, model })
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontLoadingDepartment);