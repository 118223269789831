import { ActionTypes } from "../actionsTypes";
import { InterfaceAnalysisState } from "./IGeneralState";

const initialState: InterfaceAnalysisState = {
    viewResult: null,
    metaData: null,
    isLoading: false,
    rounds: null,
    anonymousResult: null,
    isSuccessAnonymousVoting: false,
    progressValue: 0,
    isModalLoading: false,
    modalResponse: null,
    isLoadingRounds: false,
    isLoadingMatrix: false
};
const InterfaceAnalysisReducer = (
    state = initialState,
    action: any
): InterfaceAnalysisState => {
    switch (action.type) {
        case ActionTypes.ChangeIALoader:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case ActionTypes.ChangeIALoaderRounds:
            return {
                ...state,
                isLoadingRounds: action.isLoading,
            };
        case ActionTypes.ChangeIALoaderMatrix:
            return {
                ...state,
                isLoadingMatrix: action.isLoading,
            };
        case ActionTypes.SetInterfaceProgressValue:
            return {
                ...state,
                progressValue: action.result,
            };
        case ActionTypes.isSuccessAnonymousVoting:
            return {
                ...state,
                isSuccessAnonymousVoting: action.isSuccessAnonymousVoting,
            };
        case ActionTypes.SaveIAViewResult:
            return {
                ...state,
                viewResult: action.result,
            };
        case ActionTypes.SaveIAMetaData:
            return {
                ...state,
                metaData: action.result,
            };
        case ActionTypes.RefreshIARound:
            let oldrounds = state.rounds;
            let newOneRound = action.result;
            let restRounds = oldrounds?.rounds.filter(c => c.id != action.result.id);
            let newRounds = [...restRounds, newOneRound];
            newRounds = newRounds.sort((a, b) => { return a.id - b.id });
            oldrounds.rounds = [...newRounds];
            return {
                ...state,
                rounds: oldrounds,
            };
        case ActionTypes.RefreshIARoundVote:
            let oldrounds2 = state.rounds;
            let newOneRound2 = oldrounds2?.rounds.filter(c => c.id == action.result.roundId)?.[0];
            //let newVote = newOneRound2?.votes?.filter(c => c.id == action.result.id)?.[0];
            let oldVotes = newOneRound2?.votes?.filter(c => c.id != action.result.id);
            let newVotes = [...oldVotes, action.result];
            newVotes = newVotes.sort((a, b) => { return a.id - b.id });
            newOneRound2.votes = newVotes;
            let restRounds2 = oldrounds2?.rounds.filter(c => c.id != action.result.roundId);
            let newRounds2 = [...restRounds2, newOneRound2];
            newRounds2 = newRounds2.sort((a, b) => { return a.id - b.id });
            oldrounds2.rounds = [...newRounds2];
            return {
                ...state,
                rounds: oldrounds2,
            };
        case ActionTypes.SaveIARoundsViewResult:
            return {
                ...state,
                rounds: action.result,
            };
        case ActionTypes.SaveIAViewAnonymousResult:
            return {
                ...state,
                anonymousResult: action.result,
            };
        case ActionTypes.SetDisipline:
            let result = state.metaData;
            let newOne = action.result;
            console.log(newOne);
            let restdisciplines = result?.disciplines?.filter(c => c.id != action.result.id);
            let disciplines = [...restdisciplines, newOne];
            //console.log("ActionTypes.SetDisipline ",restdisciplines.length, action.result, disciplines.length);
            disciplines = disciplines.sort((a, b) => { return a.order - b.order });
            result.disciplines = [...disciplines];
            return {
                ...state,
                metaData: { ...result },
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            }
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            }
        default:
            break;
    }
    return state;
};

export default InterfaceAnalysisReducer;