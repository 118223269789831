import { Col, Form, Radio, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BmProject,
  ProfileResponse,
} from "../Models/Responses/ProfileResponse";
import {
  IActivityToolState,
  IEbitToolState,
  IGeneralState,
  InterfaceAnalysisState,
} from "../Redux/reducers/IGeneralState";
import { BasicRole } from "../Enums/BasicRoles";
import ProgressBarShape from "../Components/ProgressBarShape";
import { ActionTypes } from "../Redux/actionsTypes";
import Utils from "../Utils/Utils";

export interface IToolsBarProps {
  history: any;
  getEbitProgress: (projectID: number) => any;
  currentProject: BmProject;
  ebitResult: IEbitToolState;
  profileResponse: ProfileResponse;
  leversProgress: number;
  getLeversProgress: () => any;
  getDprojectProgress: () => void;
  getFrontloadingPrgress: () => void;
  getInterfaceProgress: () => any;
  getActivityProgress: () => any;
  dprojectProgress: number;
  frontloadingProgress: number;
  selfAssessmentProgress: number;
  currentFrontLoadingProjectId: number;
  interfaceAnalysis: InterfaceAnalysisState;
  activityState: IActivityToolState;
}

const ToolsBar = (props: IToolsBarProps) => {
  const currentRoute = Utils.getRoute(props.history.location.pathname);
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tool = searchParams.get("tool");
  const currentTool = `/tools?tool=${tool}`;
  const toolsList = [
    {
      label: `${t("EBIT")}`,
      path: "/tools?tool=ebit",
      progress: props.currentProject ? props.ebitResult?.ebitProgress ?? 0 : 0,
    },
    {
      label: `${t("Stellhebel")}`,
      path: "/tools?tool=levers",
      progress: props.currentProject ? props.leversProgress : 0,
    },
    {
      label: `${t("Front-Loading")}`,
      path: "/tools?tool=frontloading",
      progress: props.currentProject ? props.frontloadingProgress : 0,
    },
    {
      label: `${t("Interface")}`,
      path: "/tools?tool=interface",
      progress: props.currentProject
        ? props.interfaceAnalysis?.progressValue ?? 0
        : 0,
    },
    {
      label: `${t("Activity Analysis")}`,
      path: "/tools?tool=activity",
      progress: props.currentProject
        ? props.activityState?.progressValue ?? 0
        : 0,
    },
    {
      label: `${t("Self Assessment")}`,
      path: "/tools?tool=self-assessment",
      progress: props.currentProject ? props.selfAssessmentProgress : 0,
    },
    {
      label: `${t("D-Project")}`,
      path: "/tools?tool=dprojects",
      progress: props.currentProject ? props.dprojectProgress : 0,
    },
    {
      label: `${t("People Survey")}`,
      path: "/tools?tool=people-survey",
      progress: 0,
    },
    {
      label: `${t("Lead time analysis")}`,
      path: "/tools?tool=lead-time-analysis",
      progress: 0,
    },
  ];
  useEffect(() => {
    if (props.currentProject) {
      if (
        props.profileResponse?.roles?.includes(BasicRole.Consultant) ||
        props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)
      ) {
        props.getEbitProgress(props.currentProject.id);
        props.getLeversProgress();
        props.getDprojectProgress();
        props.getFrontloadingPrgress();
        props.getInterfaceProgress();
        props.getActivityProgress();
      }
    }
  }, [props.currentProject?.id]);
 

  useEffect(() => {
    if (props.currentProject) {
      if (
        props.profileResponse?.roles?.includes(BasicRole.Consultant) ||
        props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)
      ) {
        props.getEbitProgress(props.currentProject.id);
        props.getDprojectProgress();
      }
    }
  }, [props.ebitResult.rndInfoResponse, props.ebitResult.dProjectForEbit]);

  useEffect(() => {
    if (props.currentProject) {
      if (
        props.profileResponse?.roles?.includes(BasicRole.Consultant) ||
        props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)
      ) {
        props.getFrontloadingPrgress();
      }
    }
  }, [props.currentFrontLoadingProjectId]);

  useEffect(() => {
    if (props.currentProject && props.interfaceAnalysis?.viewResult) {
      if (
        props.profileResponse?.roles?.includes(BasicRole.Consultant) ||
        props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)
      ) {
        props.getInterfaceProgress();
      }
    }
  }, [props.interfaceAnalysis?.metaData?.votingProcessStatusId]);

  useEffect(() => {
    if (props.currentProject && props.activityState?.viewResult) {
      if (
        props.profileResponse?.roles?.includes(BasicRole.Consultant) ||
        props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)
      ) {
        props.getActivityProgress();
      }
    }
  }, [props.activityState?.viewResult?.profiles]);

  const navigateToTool = (tool: string) => {
    if (props.currentProject) {
      navigate(tool);
    } else {
      navigate("/dashboard");
    }
  };

  const tools = toolsList.map((tool, i) => {
    return (
      <a
        href={tool.path}
        key={i}
        className={`tool-link ${
          currentTool == tool.path ? "current-tool" : ""
        }`}
      >
        <Radio.Button
          onClick={() => navigateToTool(tool.path)}
          value={tool}
          key={i}
          className={`card-item ${
            currentTool == tool.path ? "current-tool" : ""
          }`}
          disabled={props.currentProject === null}
        >
          <p>{tool.label}</p>
          <ProgressBarShape
            type="circle"
            percent={tool.progress}
            width={35}
            status="normal"
            key={i}
          />
        </Radio.Button>
      </a>
    );
  });

  return (
    <Row align="middle" justify="center" className="verification-list">
      <Col xs={96} sm={72} md={48} className="form-card">
        <Form className="form-card">
          <Radio.Group defaultValue={tools[0].props.value} buttonStyle="solid">
            <Space
              size={9}
              title={props.currentProject ? "" : "Please, choose a project."}
              className="list-tools"
            >
              {tools}
            </Space>
          </Radio.Group>
        </Form>
      </Col>
    </Row>
  );
};
const mapStateToProps = (state: IGeneralState) => {
  return {
    interfaceAnalysis: state.interfaceAnalysis,
    activityState: state.activity,
    currentProject: state.generalInfo.profileResponse?.currentProject,
    ebitResult: state.ebit,
    profileResponse: state.generalInfo.profileResponse,
    // currentProject: state.projects.currentProject
    leversProgress: state.leverTool.leversProgress,
    dprojectProgress: state.dprojects.dprojectProgress,
    frontloadingProgress: state.frontLoadingProjects.frontLoadingProgress,
    currentFrontLoadingProjectId:
      state.generalInfo.profileResponse?.currentFrontLoadingProjectId,
    selfAssessmentProgress: state.selfAssessementTool?.viewResult?.progress,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getEbitProgress: (projectID: number) =>
      dispatch({ type: ActionTypes.GetEBITProgress, projectID: projectID }),

    getLeversProgress: () =>
      dispatch({ type: ActionTypes.GetLeversProgressValue }),

    getInterfaceProgress: () =>
      dispatch({ type: ActionTypes.GetInterfaceProgressValue }),

    getActivityProgress: () =>
      dispatch({ type: ActionTypes.GetActivityProgressValue }),

    getDprojectProgress: () =>
      dispatch({ type: ActionTypes.GetDProjectsProgressValue }),

    getFrontloadingPrgress: () =>
      dispatch({ type: ActionTypes.GetFrontloadingPrgress }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolsBar);
