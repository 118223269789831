import { ScatterChart, CartesianGrid, Legend, Scatter, Tooltip, XAxis, YAxis, ZAxis, LabelList, ResponsiveContainer } from "recharts";
import CustomizedDot from "./CustomizedDot";
import { useTranslation } from "react-i18next";
import { CustomizedYAxisTick, CustomizedXAxisTick } from "./CustomizedAxisTick";
import { ISABestPractice } from "../../../../Models/SelfAssessment/ISelfAssessmentView";
import { Space } from "antd";
import { useEffect, useState } from "react";

interface IProps {
    points: ISABestPractice[];
    chartWidth: number;
    chartHieght: number;
    scale: number;
};


function ScatterDiagramMin(props: IProps) {

    const { t } = useTranslation();
    const [min, setMin] = useState(1);
    const [mid, setMid] = useState(3);
    const [max, setMax] = useState(5);

    const [minY, setMinY] = useState(1);
    const [midY, setMidY] = useState(3);
    const [maxY, setMaxY] = useState(5);

    const [data, setdata] = useState(props.points);
    useEffect(() => {
        if (props.points && props.points.length > 0) {
            let pImp = props.points.map(c => c.importance);
            let minImp = Math.min(...pImp);
            let maxImp = Math.max(...pImp);
            if (minImp > 3) minImp = 3;
            if (maxImp < 3) maxImp = 3;
            setMin(minImp);
            setMax(maxImp);

            let pUrgency = props.points.map(c => c.urgency);
            let minUrgency = Math.min(...pUrgency);
            let maxUrgency = Math.max(...pUrgency);
            if (minUrgency > 3) minUrgency = 3;
            if (maxUrgency < 3) maxUrgency = 3;
            setMinY(minUrgency);
            setMaxY(maxUrgency);
        }
        setMid((1 + 5) / 2);
        setMidY((1 + 5) / 2);
        setdata(props.points);
    }, [props.points]);

    return (<ResponsiveContainer width="100%" height="100%" id="scatter_chart_min_rv"
        key={'SAMatrixChart_div_min_sd_div'}>
        <ScatterChart
            key={'SAMatrixChart_div_min_sd_div_sc'}
            id="scatter_chart_min"
            margin={{
                top: 15,
                right: 25,
                bottom: 15,
                left: 25,
            }}>
            <CartesianGrid
                strokeDasharray="0 0"
                strokeWidth={2}
            />
            <XAxis
                scale={'linear'}
                dataKey="importance"
                type="number"
                name={t("Importance")}
                label={{
                    value: t("Importance"),
                    offset: -10,
                    fontSize: 20,   
                    fontWeight: 700,
                    fill: (localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0',
                    position: "bottom",
                }}
                tickLine={false}
                ticks={[min, mid, max]}
                tick={<CustomizedXAxisTick isminimized={true} min={min} max={max} mid={mid} fillColor={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`} angle={0} />}
                fontStyle={"normal"}
                fontSize={12}
                fontWeight={700}
                color={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                fill={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                stroke={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                strokeWidth={2}
                domain={[min, max]}                
            />
            <YAxis
                scale={'linear'}
                dataKey="urgency"
                type="number"
                name={t("Urgency")}
                label={{
                    value: t("Urgency"),
                    angle: -90,
                    offset: 0,
                    fontSize: 20,   
                    fontWeight: 700,
                    fill: (localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'
                }}
                tickLine={false}
                ticks={[minY, midY, maxY]}
                tickMargin={0}
                tick={<CustomizedYAxisTick isminimized={true} min={minY} max={maxY} mid={midY} fillColor={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`} angle={-90} />}
                fontStyle={"normal"}
                fontSize={12}
                fontWeight={700}
                color={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                fill={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                stroke={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                strokeWidth={2}
                domain={[minY, maxY]} />
            <Scatter
                data={data}
                shape={<CustomizedDot
                    //strokeColor={"#d9d9d9"}
                    //fillColor={"#d9d9d9"}
                    // r={15}
                    cx={10}
                    cy={10}
                    //fontSize="12px"
                    //strokeWidth="1.6871"
                    key={0}
                    points={data}
                />
                }
                tooltipType="none"
            >
                <LabelList
                    fontSize={12}
                    fontWeight={700}
                    //stroke="black"
                    position={"center"}
                    fill="black"
                    dataKey="index"
                //style={{ "zIndex": "1000" }} 
                />
            </Scatter>
        </ScatterChart>
    </ResponsiveContainer>);
};
export default ScatterDiagramMin;
