import { ActionTypes } from "../actionsTypes";
import { IIndustryDisciplineBestPracticeState } from "./IGeneralState";

const initialState: IIndustryDisciplineBestPracticeState = {
    isIndustryDisciplineBestPracticeLoading: false,
    industryDisciplineBestPracticeResult: null,
    isModalLoading: false,
    modalResponse: null,
};
const IndustryDisciplineBestPracticeReducer = (state = initialState, action: any): IIndustryDisciplineBestPracticeState => {
    switch (action.type) {
        case ActionTypes.CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LOADER:
            return {
                ...state,
                isIndustryDisciplineBestPracticeLoading: action.isLoading,
            };
        case ActionTypes.SAVE_INDUSTRY_DISCIPLINE_BEST_PRACTICES:
            return {
                ...state,
                industryDisciplineBestPracticeResult: [...action.result],
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            };
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            };
        default:
            break;
    }
    return state;
};
export default IndustryDisciplineBestPracticeReducer;
