import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Popconfirm, Space, Spin, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { ChangeProjectFilter, ChangeProjectView } from '../DataTypes/DataTypes';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { FilterOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import './change_project_table.css';
import { GeneralBmProjectDto } from '../../../../Models/Responses/ProfileResponse';
import { IGeneralState } from '../../../../Redux/reducers/IGeneralState';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { AimOutlined } from "@ant-design/icons";
import { t } from 'i18next';
import { getColumnSearchProps } from '../../../../Components/SearchButton';

interface Iprops {
    projects: GeneralBmProjectDto[];
    tableColsProps: typeof ChangeProjectFilter;
    changeCurrentProject: (currentProjectId: number) => any;
    //changingCurrentProject: boolean;
    getProjects: () => any;
};

function ChangeProjectTable(props: Iprops) {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedProjectId, setSelectedProjectId] = useState(-1);
    const searchInput = useRef<InputRef>(null);
    //search code start
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    useEffect(() => {
        props.getProjects();
    }, [])
    type DataIndex = keyof ChangeProjectView;

    const selectCurrentProject = (project: ChangeProjectView) => {
        setSelectedProjectId(project.id);
        props.changeCurrentProject(project.id);
    };


    const columnsBeforeFilter: ColumnsType<ChangeProjectView> | any[] = [
        {
            title: `${t("Project Name")}`,
            dataIndex: 'projectName',
            width: 150,
            align: 'center',
            //className: props.filter.projectName.visible ? 'show_col' : 'hide_col',
            // ...getColumnSearchProps('projectName'),
            ...getColumnSearchProps(t("Project Name"), 'projectName', searchInput, handleSearch),
            sorter: (a, b) => a?.projectName?.localeCompare(b?.projectName) ?? 0,
            defaultSortOrder: 'asc',
        },
        {
            title: `${t("Customer Name")}`,
            dataIndex: 'customerName',
            width: 150,
            align: 'center',
            //className: props.filter.customerName.visible ? 'show_col' : 'hide_col',
            // ...getColumnSearchProps('customerName'),
            ...getColumnSearchProps(t("Customer Name"), 'customerName', searchInput, handleSearch),
            sorter: (a, b) => a?.customerName?.localeCompare(b?.customerName) ?? 0,
        },
        {
            title: `${t("Sector")}`,
            dataIndex: 'sector',
            key: 'sector',
            width: 150,
            align: 'center', 
            ...getColumnSearchProps( `${t("Sector")}`,'sector', searchInput, handleSearch),
        },
        {
            title: `${t("Segment")}`,
            dataIndex: 'segment',
            width: 150,
            align: 'center', 
            ...getColumnSearchProps(t("Segment"), 'segment', searchInput, handleSearch),
            sorter: (a, b) => a?.segment?.localeCompare(b?.segment) ?? 0,
        },
        {
            title: `${t("Year")}`,
            dataIndex: 'year',
            width: 150,
            align: 'center',
            //className: props.filter.year.visible ? 'show_col' : 'hide_col',
            // ...getColumnSearchProps('year'),
            ...getColumnSearchProps(t("Year"), 'year', searchInput, handleSearch),
            sorter: (a, b) => a?.year?.toString()?.localeCompare((b?.year)?.toString())  ?? 0,
        }
    ];

    let columnsAfterFilter = columnsBeforeFilter.filter((col) => {
        //console.log("columnsAfterFilter", col, props.tableColsProps);
        return props.tableColsProps[col.dataIndex?.toString()]?.visible ?? false;
    });

    columnsAfterFilter.push(
        {
            title: `${t("Actions")}`,
            dataIndex: 'Actions',
            render: (_: any, project: ChangeProjectView) => {
                return (<Space size={20}>
                    <Popconfirm title={`${t("Sure to change current project?")}`} onConfirm={() => selectCurrentProject(project)} cancelText={`${t("Cancel")}`}>
                        <a><AimOutlined /></a>
                    </Popconfirm> 
                </Space>);
            },
            width: 100,
            align: 'center'
        }
    );
 
    const data: ChangeProjectView[] = [];
    props.projects.forEach((project, idx) => {
        let changeProjectView: ChangeProjectView = {
            key: project.id,
            id: project.id,
            projectName: project.name,
            customerName: project.customerName,
            segment: project.segment,
            sector: project.sector,
            year: project.yearOfExecution
        };
        data.push(changeProjectView);
    });
    const [totalDataSourceLength, setTotalDataSourceLength] = useState(data.length)
    return (
        <Table
            columns={columnsAfterFilter}
            dataSource={data}
            pagination={{
                defaultPageSize: 50,
                pageSizeOptions: [50, 100, 150],
                total: totalDataSourceLength,
                showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                showSizeChanger: true,
                locale: { items_per_page: `/ ${t("Page")}` },
            }}
            scroll={{ y: 'calc(100vh - 400px)' }}
            locale={{
                filterReset: `${t("Reset")}`, triggerDesc: t('Click to sort descending'),
                triggerAsc: t('Click to sort ascending'),
                cancelSort: t('Click to cancel sorting')
            }}
            onChange={function (pagination, filters, sorter, extra: { currentDataSource: [], action: any }) {
                return setTotalDataSourceLength(extra.currentDataSource.length);
            }}
        />
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        projects: state.generalInfo.allProjects,
        tableColsProps: state.tablesColsProps.changeProjectTableColsProps,
        //currentProject: state.projects.currentProject
        currentProject: state.generalInfo.profileResponse?.currentProject,
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getProjects: () => dispatch({ type: ActionTypes.GETPROJECTS })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProjectTable);