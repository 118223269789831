import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Layout, Modal, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import { createBrowserHistory } from "history";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import SiderMenu from "./SiderMenu";
import SideRight from "./SideRight";
import "./AppLayout.scss";
import ToolsBar from "./ToolsBar";
import { connect } from "react-redux";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ProfileResponse } from "../Models/Responses/ProfileResponse";
import { BasicRole } from "../Enums/BasicRoles";
import PasswordRequiredView from "../Scenes/BMProjectsLists/BMProjects_CurrentProject/CurrentProjectCard/PasswordRequiredView";
import { IGeneralState } from "../Redux/reducers/IGeneralState";
import { ActionTypes } from "../Redux/actionsTypes";
interface IProps {
  isLoading: boolean;
  isfullScreen: boolean;
  profileResponse: ProfileResponse;
  changeFullScreen: (state: boolean) => any;
}
function AppLayout(props: IProps) {
  const history = createBrowserHistory();
  const [collapsed, setCollapsed] = useState(localStorage.getItem("sidemenu-collapsed") == "true" || false);
  const [rightCollapsed, setRightCollapsed] = useState(localStorage.getItem("sidemenu-right-collapsed") == "true" || false);
  const _isCustomer = props.profileResponse?.roles?.includes(BasicRole.Customer);

  const screen1 = useFullScreenHandle();
  const reportChange = useCallback((state, handle) => {
    if (handle === screen1) {
      props.changeFullScreen(state);
    }
  }, [screen1]);
  const onClickFullScreen = () => {
    props.changeFullScreen(true);
    screen1.enter();
  }
  const onClickExitFullScreen = () => {
    props.changeFullScreen(false);
    screen1.exit();
  }

  return (
    <FullScreen handle={screen1} onChange={reportChange}>
      <Layout
        className="app-layout"
        id="app-layout"
        style={{ minHeight: "100vh", direction: "ltr" }}
        hasSider
      >
        {!props.isfullScreen ? <SiderMenu
          collapsed={collapsed}
          onCollapse={(value) => { localStorage.setItem("sidemenu-collapsed", value); setCollapsed(value); }}
          history={history}
        /> : <></>}

        <Layout className="site-layout">
          <Spin size="large" spinning={props.isLoading}>
            <Header history={history} onClickFullScreen={onClickFullScreen} onClickExitFullScreen={onClickExitFullScreen} />
            {(!props.isfullScreen && !_isCustomer) ?
              <ToolsBar history={history} />
              : null}

            <Content>
              <PasswordRequiredView />
              <Outlet />
            </Content>
          </Spin>
          {/*  <Footer /> */}
        </Layout>
        {(!props.isfullScreen && !_isCustomer) ?
          <SideRight
            collapsed={rightCollapsed}
            onCollapse={(value) => { localStorage.setItem("sidemenu-right-collapsed", value); setRightCollapsed(value); }}
            history={history}
          /> : <></>
        }
      </Layout>
    </FullScreen>
  );
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    isLoading: state.generalInfo?.isLoading,
    isfullScreen: state.generalInfo?.isFullScreen,
    profileResponse: state.generalInfo?.profileResponse
  }
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    changeFullScreen: (state: boolean) => dispatch({ type: ActionTypes.ChangeFullScreen, state: state })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout); 
