import { Col, Collapse, Input, InputNumber, List, Row, Space, Table } from "antd";
import { connect } from "react-redux";
import { ISABestPractice, ISelfAssessmentView } from "../../../../Models/SelfAssessment/ISelfAssessmentView";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState, ISelfAssessmentToolState } from "../../../../Redux/reducers/IGeneralState";
import { formatValue } from "../../../../Utils/helperFunctions";
import SABestPracticeComments from "./SABestPracticeComments";
import { t } from "i18next";
import SASubleverBestPracticeItem from "./SASubleverBestPracticeItem";
const { Panel } = Collapse
interface IProps {
    leverId: number;
    viewState: ISelfAssessmentToolState;
    changeAnswers: (model: ISelfAssessmentView) => any;
}
function SASubleverBestPractices(props: IProps) {
    const onChange = (value: number, bpId: number) => {
        console.log("onChange", value, bpId);
        let viewStateTemp = props.viewState;
        viewStateTemp.viewResult.levers.map(lev => {
            lev.sublevers.map(sub => {
                sub.bestPractices.map(bb => {
                    if (bb.id == bpId) {
                        bb.importance = value;
                    }
                });
                if (sub.bestPractices.filter(c => c.importance) && sub.bestPractices.filter(c => c.importance).length > 0) {
                    sub.referenceValue = 1.25 - 0.25 * (sub.bestPractices.filter(c => c.importance).map(c => c.importance).reduce((a, b) => a + b, 0) / sub.bestPractices.filter(c => c.importance).length);
                } else {
                    sub.referenceValue = null;
                }
            });
            //console.log("lev?.sublevers?.length", lev?.sublevers?.length, lev?.sublevers);
            lev.referenceValue = lev.sublevers?.filter(c => c.referenceValue).map(c => c.referenceValue)?.reduce((a, b) => a + b, 0) / lev?.sublevers?.filter(c => c.referenceValue).length;
        });
        let total = 0;
        let done = 0;
        viewStateTemp.viewResult.levers.map(lev => {
            lev.sublevers.map(sub => {
                total = total + sub.bestPractices.length;
                done = done + sub.bestPractices.filter(c => c.importance >= 1).length;
            });
        });
        //console.log("SelfAssessment ", total, done);
        viewStateTemp.viewResult.progress = Math.round((done / total) * 100);
        props.changeAnswers(viewStateTemp.viewResult);
    }


    const onChangeComment = (value: string, bpId: number) => {
        //console.log("onChange", value, bpId);
        let viewStateTemp = props.viewState;
        viewStateTemp.viewResult.levers.map(lev => {
            lev.sublevers.map(sub => {
                sub.bestPractices.map(bb => {
                    if (bb.id == bpId) {
                        bb.comment = value;
                    }
                });
            });
        });
        props.changeAnswers(viewStateTemp.viewResult);
    }
  

    const tableBps = (bestPractices: ISABestPractice[]) => {
        let dataSource = bestPractices;
        return <>
            {
                dataSource.map(item => {
                    return <SASubleverBestPracticeItem key={`supLever_${item?.id}`} onChange={onChange} item={item} onChangeComment={onChangeComment} />
                })
            }
        </>;
    }

    return <Collapse accordion>
        {props.viewState?.viewResult?.levers?.filter(c => c.id == props.leverId)?.[0]?.sublevers?.map(sublever => {
            return <Panel header={`${sublever.name} (${formatValue(sublever.referenceValue * 100)}%)`} key={`sublever_key_${sublever.id}`}>
                {tableBps([...sublever.bestPractices])}
            </Panel>;
        })
        }
    </Collapse>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        viewState: state.selfAssessementTool,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        changeAnswers: (model: ISelfAssessmentView) => dispatch({ type: ActionTypes.ChangeAllSelfAssessmentView, model: model }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SASubleverBestPractices);