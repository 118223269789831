import { Table, Button, Checkbox, Input, InputRef, Popconfirm, Space } from "antd";
import { ColumnsType, ColumnType } from "antd/es/table";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { useRef, useState } from "react";
import { connect } from "react-redux";
import { EditEbitFilter, EditEbitView } from '../DataTypes/DataTypes';
import { FilterOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { BmProject, BmProjectForEditEdits } from "../../../../Models/Responses/ProfileResponse";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { formatValue } from "../../../../Utils/helperFunctions";
import { getColumnSearchProps } from "../../../../Components/SearchButton";
import { t } from "i18next";
//import BmProject from "../../../../Models/BmProject";


interface IProps {
    tableColsProps: typeof EditEbitFilter;
    currentProject: BmProject;
    projects: BmProjectForEditEdits[];
    ebit_ks: number[],
    ebit_xs: number[],
    setEbit_ks: (ids: number[]) => void,
    setEbit_xs: (ids: number[]) => void
};

function EditEbitTable(props: IProps) {
    const togglek = (projectId: number) => {
        if (props.ebit_ks.includes(projectId)) {
            let ebit_ks_copy = props.ebit_ks.filter(id => id !== projectId);
            props.setEbit_ks(ebit_ks_copy);
        } else {
            let ebit_ks_copy = [...props.ebit_ks];
            ebit_ks_copy.push(projectId);
            props.setEbit_ks(ebit_ks_copy);
        }
    };

    const togglex = (projectId: number) => {
        if (props.ebit_xs.includes(projectId)) {
            let ebit_xs_copy = props.ebit_xs.filter(id => id !== projectId);
            props.setEbit_xs(ebit_xs_copy);
        } else {
            let ebit_xs_copy = [...props.ebit_xs];
            ebit_xs_copy.push(projectId);
            props.setEbit_xs(ebit_xs_copy);
        }

    };

    type DataIndex = keyof EditEbitView;
    
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end

    const columnsBeforeFilter: any[] = [
        {
            title:`${t("Project Name")}`,
            dataIndex: 'projectName',
            key: 'ProjectName',
            width: 150,
            align: 'center',
            fixed: "left",
            //className: props.filter.projectName.visible ? 'show_col' : 'hide_col',
            ...getColumnSearchProps(`${t("Project Name")}`,'projectName', searchInput, handleSearch),
        },
        {
            title: `${t("Sector")}`,
            dataIndex: 'sector',
            key: 'sector',
            width: 100,
            align: 'center',
            //className: props.filter.customerName.visible ? 'show_col' : 'hide_col',
            ...getColumnSearchProps( `${t("Sector")}`,'sector', searchInput, handleSearch),
        },
        {
            title: `${t("Segment")}`,
            dataIndex: 'segment',
            key: 'segment',
            width: 100,
            align: 'center', 
            ...getColumnSearchProps(`${t("Segment")}`,'segment', searchInput, handleSearch),
        },
        {
            title: `${t("#Employees")}` ,
            dataIndex: 'numEmployees',
            key: 'NumEmployees',
            width: 75,
            align: 'center', 
            ...getColumnSearchProps(`${t("#Employees")}`,'numEmployees', searchInput, handleSearch),
        },
        {
            title:`${t("Sales")}`,
            dataIndex: 'sales',
            key: 'Sales',
            width: 100,
            align: 'center', 
            ...getColumnSearchProps(`${t("Sales")}`,'sales', searchInput, handleSearch),
            render: (text)=>{
                return formatValue(text)
            }
        },
        {
            title: `${t("Year")}`,
            dataIndex: 'year',
            key: 'Year',
            width: 75,
            align: 'center', 
            ...getColumnSearchProps(`${t("Year'")}`,'year', searchInput, handleSearch),
        },
        {
            title: 'F&E-Rate',
            dataIndex: 'fERate',
            key: 'FERate',
            width: 75,
            align: 'center', 
        },
        {
            title: 'QK-Anteil',
            dataIndex: 'qKAnteil',
            key: 'QKAnteil',
            width: 75,
            align: 'center', 
        },
        {
            title: 'Inno-Rate',
            dataIndex: 'innoRate',
            key: 'InnoRate',
            width: 75,
            align: 'center', 
        },
        {
            title: 'HK-Rate',
            dataIndex: 'hKRate',
            key: 'HKRate',
            width: 75,
            align: 'center', 
        },
        {
            title: 'CoD-Rate',
            dataIndex: 'coDRate',
            key: 'CoDRate',
            width: 75,
            align: 'center', 
        }
        ,
        {
            title: 'Ebit Rate',
            dataIndex: 'ebitRate',
            key: 'ebitRate',
            width: 75,
            align: 'center', 
        }
        ,
        {
            title: 'Ebit Sales',
            dataIndex: 'ebitSales',
            key: 'ebitSales',
            width: 100,
            align: 'center', 
        }
    ];

    let columnsAfterFilter = columnsBeforeFilter.filter((col) => {
        //console.log("props.tableColsProps[col.key]", props.tableColsProps, col.key);
        return props.tableColsProps[col.dataIndex.toString()]?.visible ?? false;
    });

    columnsAfterFilter.push(
        {
            title: 'k',
            dataIndex: 'Actions',
            render: (_: any, project: EditEbitView) => {
                return (<Space size={20}>
                    <Popconfirm disabled={props.ebit_xs.includes(project.id)} title={props.ebit_ks.includes(project.id) ? "Sure to remove as k?" : "Sure to add as k?"} onConfirm={() => togglek(project.id)}>
                        <a><Checkbox disabled={props.ebit_xs.includes(project.id)} checked={props.ebit_ks.includes(project.id) && !props.ebit_xs.includes(project.id)} /*onChange={(e: CheckboxChangeEvent) => togglek(e, project.id)}*/ /></a>
                    </Popconfirm>
                </Space>);
            },
            width: 50,
            align: 'center',
            fixed: 'right'
        }
    );

    columnsAfterFilter.push(
        {
            title: 'x',
            dataIndex: 'Actions',
            render: (_: any, project: EditEbitView) => {
                return (<Space size={20}>
                    <Popconfirm disabled={props.ebit_ks.includes(project.id)} title={props.ebit_xs.includes(project.id) ? "Sure to remove as x?" : "Sure to add as x?"} onConfirm={() => togglex(project.id)}>
                        <a><Checkbox disabled={props.ebit_ks.includes(project.id)} checked={props.ebit_xs.includes(project.id) && !props.ebit_ks.includes(project.id)} /*onChange={(e: CheckboxChangeEvent) => togglek(e, project.id)}*/ /></a>
                    </Popconfirm>
                </Space>);
            },
            width: 50,
            align: 'center',
            fixed: 'right'
        }
    );
    let data: EditEbitView[] = [];
    data = props.projects?.filter(p => p.id !== props.currentProject?.id).map(project => {
        return (
            {
                id: project.id,
                key: project.id,
                projectName: project.name,
                sector: project.sector,
                segment: project.segment,
                numEmployees: project.employeeCount,
                sales: project.sales,
                year: project.yearOfExecution,
                fERate: `${formatValue(project.feRate)}%`,
                qKAnteil: `${formatValue(project.qkAnteil)}%`,
                innoRate: `${formatValue(project.innoRate)}%`,
                hKRate: `${formatValue(project.hkRate)}%`,
                coDRate: `${formatValue(project.coDRate)}%`,
                ebitRate: `${formatValue(project.ebitTodayPercentage)}%`,
                ebitSales: `${formatValue(project.ebitTodayCost)}`,
            }
        );
    });
    return (
        <div className="edit_levers_table_div">
            <Table
                columns={columnsAfterFilter}
                dataSource={data}
                scroll={{ y: "calc(100vh - 400px)" }}
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 150],
                    total: data.length,
                    showTotal: (total) => `Total ${total} items`,
                    showSizeChanger: true
                }}
            />
        </div>
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        projects: state.generalInfo.ProjectsForEditEbit,
        currentProject: state.generalInfo.profileResponse?.currentProject,
        tableColsProps: state.tablesColsProps.editEbitTableColsProps
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEbitTable);