import { useState, useEffect } from "react";
import { Button, Form, Input, Space, Spin } from "antd";
import { connect } from "react-redux";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IIndustryAll, IIndustryItem, IIndustryNew } from "../../../Models/IIndustry";
import IndustryTable from "./IndustryTable";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import ModalAdmin from "../../../Components/ModalAdmin/ModalAdmins";
import "./index.scss";
import { RulesName } from "../Rules/RulesValidation";
import { useTranslation } from "react-i18next";
interface IProps {
  getIndustries: () => any;
  createIndustry: (model: IIndustryNew) => any;
  industries: IIndustryAll[];
  isLoading: boolean;
  isModalLoading: boolean;
  modalResponse: ICommonResponse;
}
const IndustryMng = (props: IProps) => {
  const [formValues, setFormValues] = useState<IIndustryNew>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => { props.getIndustries() }, []);
  const industriesData: IIndustryItem[] = props?.industries?.map(s => {
    return { key: `ind_${s.id}`, id: s.id, nameEn: s.codeEn, nameDe: s.codeDe }
  }) ?? [];
  const formValuesHandler = async () => {
    try {
      await form.validateFields();
      const newIndustry = { ...formValues };
      await props.createIndustry(newIndustry);
    } catch (error) {
      console.log(error);
    }
  }
  const closeAndReset = () => {
    setIsModalVisible(false);
    form.resetFields();
  }
  const { t, i18n } = useTranslation();
  return (
    <Spin spinning={props.isLoading || props.isModalLoading}>

      <div className="admin-component">
        <Space direction="vertical" size="small">
          <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Industry")}`} btnText={`${t("Add")}`}
            width="520px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
              <Button key="back" onClick={closeAndReset}>
                {`${t("Cancel")}`}
              </Button>,
              <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                {`${t("Save")}`} & {`${t("Send")}`}
              </Button>
            ]}>
            <Form
              layout="vertical"
              form={form}

            >
              <Form.Item label={`${t("English Name")}`} name='codeEn'
                rules={RulesName({ name: `${t("English Name")}`,countChar:128 })}
                hasFeedback>
                <Input onChange={(e) => setFormValues({ ...formValues, codeEn: (e.target.value).trimStart() })} />
              </Form.Item>
              <Form.Item label={`${t("German Name")}`} name='codeDe'
                rules={RulesName({ name: `${t("German Name")}`,countChar:128 })}
                hasFeedback>
                <Input onChange={(e) => setFormValues({ ...formValues, codeDe: (e.target.value).trimStart() })} />
              </Form.Item>
            </Form>
          </ModalAdmin>
          <IndustryTable industryDataRow={industriesData} />
        </Space>
      </div>
    </Spin>
  );
}
const mapStateToProps = (state: any) => {
  return {
    industries: state.industries.industriesResult,
    isLoading: state.industries.isIndustryLoading,
    isModalLoading: state.industries.isModalLoading,
    modalResponse: state.industries.modalResponse,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getIndustries: () => dispatch({ type: ActionTypes.GET_INDUSTRIES }),
    createIndustry: (model: IIndustryNew) => dispatch({ type: ActionTypes.CREATE_INDUSRTY, model })

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryMng);
