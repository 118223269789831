export const endPoints = {
  startBenchmarking: "/api/BM/StartBenchmarking",
  GetEstimation: "/api/EBIT/getestimate",
  SaveEstimation: "/api/EBIT/SaveEstimate",
  LoginUrl: "/api/AuthManagement/Login",
  RefreshToken: "api/AuthManagement/RefreshToken",
  VerifyLogin: "/api/AuthManagement/TwoStepVerification",
  BM_Project: "/api/BMProject/List",
  GetBenchmarkProjectsWithAllData: "/api/BMProject/GetBenchmarkProjectsWithAllData",
  GetBmProjectWithAllData : "/api/BMProject/GetBmProjectWithAllData",
  GetProjectsForEditLevers: "/api/BMProject/GetProjectsForEditLevers",
  GetProjectsForEditEbit: "/api/BMProject/GetProjectsForEditEbit",
  GetHomeInformation: "/api/Profile/GetProfile",
  CHANGECURRENTPROJECT: "/api/BMProject/changecurrentproject",
  ConfirmPassword: "api/Profile/CheckPassword",
  UPDATECURRPROJECTPEERS: "/api/BMProject/ChangeProjectPeers",
  GETDPROJECT: "api/DProject/GetDprojects", //id of bmproject should be appended t the url
  UPLOADDPROJECT: "api/DProject/SaveDproject",
  DPROJECTCOMMENTS: "api/DprojectComment/GetDprojectComments", //id of dproject should be appended t the url
  UPOLADDPROJECTCOMMENT: "api/DprojectComment/SaveDprojectComment",
  UPDATINGCOMMENT: "api/DprojectComment/UpdateDprojectComment",
  DELETECOMMENT: "api/DprojectComment/DeleteDprojectComment/", //id of comment should be appended to the url
  UPDATEDPROJECT: "api/DProject/UpdateDproject",
  DLTDPROJECT: "api/DProject/DeleteDproject", // id of Dproject should be appended to the url
  GETDPROJECTTYPES: "api/DProject/GetDprojectTypes",
  GetDprojectProgress: "api/DProject/GetDprojectProgress",
  uploadIncludeInLevers: "api/DProject/IncludeInLeversAsDefault",
  getIncludeInLevers: "api/DProject/getDProjectsIdsIncludedInLevers",
  markDProjectAnalysisAsComplete: "api/DProject/MarkDProjectAnalysisAsComplete",
  getRnd: "/api/EBIT/getRnD",
  downloadEstimation: "/api/EBIT/GetEstimationReport",
  GetDisciplinesVsBestPracticeExcel:
    "api/EBIT/GetDisciplinesVsBestPracticesExcel",
  ExportCostTypesExcel: "api/EBIT/ExportCostTypesExcel",
  ExportDisciplinesExcel: "api/EBIT/ExportDisciplinesChartsExcel",
  ExportFullExcelReport: "/api/EBIT/ExportFullReporttExcel",
  //ExportPresentation: "api/EBIT/ExportEbitPresentation",
  ExportEbitPowerPoint: "api/EBIT/ExportEbitPowerPoint",
  ExportCODExcelTable: "api/EBIT/ExportCostOfDelayExcelTable",
  ExportHKExcelTable: "api/EBIT/ExportManufactoringCostsExcelTable",
  ExportQKExcelTable: "api/EBIT/ExportQualityCostExcelTable",
  ExportInnoExcelTable: "api/EBIT/ExportInnoCostExcelTable",
  ExportRDTableExcel: "api/EBIT/ExportRDTableExcel",
  CreateRnDUnit: "/api/EBIT/CreateRnDUnit",
  DeleteRnDUnit: "/api/EBIT/DeleteRnDUnit",
  UpdateRnDUnit: "/api/EBIT/UpdateRnDUnit",
  SaveRndUnitCosts: "/api/EBIT/SaveRndUnitCosts",
  GetEBITAnalyzisProgress: "/api/EBIT/GetEBITAnalyzisProgress",
  UpdateQualityCost: "/api/EBIT/UpdateQualityCost",
  CreateQualityCost: "/api/EBIT/CreateQualityCost",
  CreateQualityCostWithNewDproject:
    "/api/EBIT/CreateQualityCostWithNewDproject",
  ImportQKExcelTable: "/api/EBIT/ImportQualityCost",
  DeleteQualityCost: "/api/EBIT/DeleteQualityCost",

  UpdateCostOfDelay: "/api/EBIT/UpdateCostOfDelay",
  CreateCostOfDelay: "/api/EBIT/CreateCostOfDelay",
  CreateCostOfDelayWithNewDproject:
    "/api/EBIT/CreateCostOfDelayWithNewDproject",
  ImportCODExcelTable: "/api/EBIT/ImportCostOfDelay",
  DeleteCostOfDelay: "/api/EBIT/DeleteCostOfDelay",

  UpdateManufacturingCost: "/api/EBIT/UpdateManufacturingCost",
  CreateManufacturingCost: "/api/EBIT/CreateManufacturingCost",
  CreateManufacturingCostWithNewDproject:
    "/api/EBIT/CreateManufacturingCostWithNewDproject",
  ImportMCExcelTable: "/api/EBIT/ImportManufacturingCost",
  DeleteManufacturingCost: "/api/EBIT/DeleteManufacturingCost",

  UpdateInnovationCost: "/api/EBIT/UpdateInnovationCost",
  CreateInnovationCost: "/api/EBIT/CreateInnovationCost",
  CreateInnovationCostWithNewDproject:
    "/api/EBIT/CreateInnovationCostWithNewDproject",
  ImportInnoExcelTable: "/api/EBIT/ImportInnovationCost",
  DeleteInnovationCost: "/api/EBIT/DeleteInnovationCost",

  GetDprojects: "/api/EBIT/GetDprojects",
  GetEvaluation: "/api/EBIT/GetEvaluation",
  GetEBITBenchmark: "/api/EBIT/GetBenchmark",

  getlevers: "/api/Lever/GetLevers",
  SaveLeversByCustomer: "/api/Lever/SaveLeversByCustomer",
  SaveLeversByConsultant: "/api/Lever/SaveLeversByConsultant",
  SaveEasyAccessLeverByConsultant: "/api/Lever/SaveEasyAccessLeverByConsultant",
  ReviewByConsultant: "/api/Lever/ReviewByConsultant",
  AddNewQuestionComment: "/api/Lever/AddNewQuestionComment",
  EditQuestionComment: "/api/Lever/EditQuestionComment",  
  DeleteQuestionComment: "/api/Lever/DeleteQuestionComment",
  getLeversEasyAccess: "/api/Lever/GetLeversEasyAccess",
  MarkKpiInEasyAccessLevers: "/api/Lever/MarkKpiInEasyAccessLevers",
  exportSelecetedChartsToExcel: "/api/Lever/ExportChartsToExcel",
  exportAllChartsToExcel: "/api/Lever/ExportAllChartsToExcel",
  exportHeatMapToExcel: "/api/Lever/ExportHeatMapToExcel",

  //   CustomerManagment
  getCustomers: "/api/Customer/GetAll",
  CreateCustomer: "/api/Customer/Create",
  DeleteCustomer: "/api/Customer/delete",
  updateCustomer: "/api/Customer/update",
  deleteImageCustomer: "/api/Customer/DeleteCustomerImage",

  // Question Management

  getQuestions: "/api/Question/GetQuestions",
  updateQuestion: "/api/Question/UpdateQuestion",

  // BmProjectAdmin
  getBmProjects: "/api/BMProject/GetAll",
  updateBmProject: "/api/BMProject/Update",
  createBmProject: "/api/BMProject/Create",
  deleteBmProject: "/api/BMProject/DeleteBmProject",
  getCompletionDetailsForBmProject: "/api/BMProject/GetCompletionDetails",
  completeBmProject: "/api/BMProject/CompleteBmProject",
  updateBmProjectExtraInfo: "/api/BMProject/UpdateBmProjectExtraInfo",
  reopenBmProject: "/api/BMProject/ReopenBmProject",
  includeBmProjectInEasyAccessLevers:
    "/api/BMProject/IncludeBmProjectInEasyAccessLevers",
  excludeBmProjectInEasyAccessLevers:
    "/api/BMProject/ExcludeBmProjectInEasyAccessLevers",

  //industry
  getIndustry: "/api/Industry/GetAll",
  createIndustry: "/api/Industry/Create",
  updateIndustry: "/api/Industry/Update",
  deleteIndustry: "/api/Industry/Delete",

  //Industry Segment
  getIndustrySegments: "/api/IndustrySegment/GetAll",
  createIndustrySegment: "/api/IndustrySegment/Create",
  updateIndustrySegment: "/api/IndustrySegment/Update",
  deleteIndustrySegment: "/api/IndustrySegment/Delete",

  //Customer Department
  getDepartmentByCustomer:
    "/api/CustomerDepartments/GetBmProjectDepartmentsByCustomerId",
  getDepartmentByBmProject:
    "/api/CustomerDepartments/GetDepartementsByBmProject",

  createCustomerDepartment: "/api/CustomerDepartments/Create",
  updateCustomerDepartment: "/api/CustomerDepartments/Update",
  updateOrdersCustomerDepartment: "/api/CustomerDepartments/UpdateOrders",
  deleteCustomerDepartment: "/api/CustomerDepartments/Delete",

  SaveCustomerUsersInQuestion: "/api/Lever/SaveCustomerUsersInQuestion",
  GetDataCollectionRoleWithUsers: "/api/Lever/GetDataCollectionRoleWithUsers",
  RemoveCustomerUserFromQuestion: "/api/Lever/RemoveCustomerUserFromQuestion",
  RemindCustomerUserForSelectedQuestion:
    "/api/Lever/RemindCustomerUserForSelectedQuestion",
  //Customer Users
  getCustomerUsers: "/api/Customer/GetAllCustomerUsers",
  createCustomerUser: "/api/Customer/CreateCustomerUser",
  updateCustomerUser: "/api/Customer/UpdateCustomerUser",

  //Administrator
  getAdministrators: "/api/Administrator/GetAll",
  createAdministrator: "/api/Administrator/Create",
  updateAdministrator: "/api/Administrator/Update",
  ActivateUser: "/api/AuthManagement/ActivateUser",
  ResetPasswordRequest: "/api/AuthManagement/ResetPasswordRequest",
  ConfirmResetPassword: "/api/AuthManagement/ConfirmResetPassword",

  //bmProjectList by customerId

  getBmProjectListByCustomerId: "/api/Customer/GetBmProjectList",

  // consultantUsers

  getConsultantUsers: "/api/Consultant/GetAll",
  createConsultantUser: "/api/Consultant/Create",
  updateConsultantUser: "/api/Consultant/Update",

  // levers management
  getAllLeversForAdmin: "/api/LeverMng/GetLeversForAdmin",

  // Activity management
  getAllActivityProfilesForAdmin: "/api/ActivityAdmin/GetProfilesTemplate",
  updateActivityProfileForAdmin: "/api/ActivityAdmin/UpdateActivtyProfile",
  updateActivityGroupForAdmin: "/api/ActivityAdmin/UpdateActivityGroup",
  updateActivityTaskForAdmin: "/api/ActivityAdmin/UpdateActivityProfileTask",
  createActivityProfileForAdmin: "/api/ActivityAdmin/CreateActivityProfile",
  createActivityGroupForAdmin: "/api/ActivityAdmin/CreateActivityGroup",
  createActivityTaskForAdmin: "/api/ActivityAdmin/AddActivityProfileTask",
  getProfileVersions: "/api/ActivityAdmin/GetActivityProfileVersions",
  getActivityProfileVersionDetails: "/api/ActivityAdmin/GetActivityProfileVersionDetails",
  changeStatusActivityProfile: "/api/ActivityAdmin/ChangeStatusActivityProfile",
  changeStatusActivityGroup: "/api/ActivityAdmin/ChangeStatusActivityGroup",
  deleteMapGroupToProfile:"/api/ActivityAdmin/DeleteMapGroupToProfile",
  getActivityProfileTasks: "/api/ActivityAdmin/GetActivityProfileTasks",
  addEditMapTaskToGroup: "/api/ActivityAdmin/AddMapTaskToGroup",
  editMapTaskToGroup: "/api/ActivityAdmin/EditMapTaskToGroup",
  deleteTaskFromGroup: "/api/ActivityAdmin/DeleteMapTaskToGroup",
  createNewProfileVersion: "/api/ActivityAdmin/CreateNewProfileVersion",
  updateProfileVersion: "/api/ActivityAdmin/UpdateProfileVersion",
  changeStatusActivityProfileVersion:
    "/api/ActivityAdmin/ChangeStatusActivityProfileVersion",

  //discipline
  getDisciplines: "/api/Discipline/GetAll",
  createDiscipline: "/api/Discipline/Create",
  updateDiscipline: "/api/Discipline/Update",
  deleteDiscipline: "/api/Discipline/Delete",

  //levers management
  updateLeverForAdmin: "/api/LeverMng/UpdateLever",
  updateSubLeverForAdmin: "/api/LeverMng/UpdateSubLever",
  updateSuccessFactorForAdmin: "/api/LeverMng/UpdateSuccessFactor",
  updateKpiForAdmin: "/api/LeverMng/UpdateKpi",
  updateQuestionForAdmin: "/api/LeverMng/UpdateQuestion",

  //industry bestPractice
  getAllIndustryBestPractice:
    "/api/IndustryBestPractice/GetIndustryBestPractice",
  createIndustryBestPractice: "/api/IndustryBestPractice/Create",
  updateIndustryBestPractice: "/api/IndustryBestPractice/Update",
  clearIndustryBestPractice: "/api/IndustryBestPractice/Clear",

  //industrySegment BestPractice
  getAllIndustrySegmentBestPractice:
    "/api/IndustrySegmentBestPractice/GetIndustrySegmentBestPractice",
  createIndustrySegmentBestPractice: "/api/IndustrySegmentBestPractice/Create",
  updateIndustrySegmentBestPractice: "/api/IndustrySegmentBestPractice/Update",
  clearIndustrySegmentBestPractice: "/api/IndustrySegmentBestPractice/Clear",
  //IndustryDisciplineBestPractice
  getAllIndustryBestPracticeDiscipline:
    "/api/IndustryBestPractice/GetAllDisciplinBP",
  //Levers
  GetLeversProgress: "/api/Lever/GetLeversProgress",
  GetLeversWithEbitChart: "/api/Lever/GetLeversWithEbitChart",
  GetLeversKpiWithEbitChart: "/api/Lever/GetLeversKpiWithEbitChart",
  UpdateDisciplinesBP:
    "/api/IndustryBestPractice/UpdateDisciplinesBP",
  ClearDisciplinesBP:
    "/api/IndustryBestPractice/ClearDisciplinesBP",
  //SegmentDisciplineBestPractice
  getAllSegmentsBestPracticeDiscipline:
    "/api/IndustrySegmentBestPractice/GetAllDisciplinBP",
    UpdateSegmentDisciplinesBP:
    "/api/IndustrySegmentBestPractice/UpdateDisciplinesBP",
    ClearSegmentDisciplinesBP:
    "/api/IndustrySegmentBestPractice/ClearDisciplinesBP",

  //customer Person to user
  makeCustomerPersonToUser: "/api/Customer/MakeCustomerPersonToUser",
  deletePerson: "/api/Customer/DeletePerson",
  assignNewBmProjectForUser: "/api/Customer/AssignOrEditNewBmProjectForUser",
  deleteBmProjectForUSer: "/api/Customer/DeleteBmProjectForUser",

  //Front loading Templates

  getAllFrontLoading: "/api/FrontloadingTemplates/GetAll",
  createFrontLoadingTemp: "/api/FrontloadingTemplates/CreateOrUpdateTemplate",
  updateFrontLoadingTemp: "/api/FrontloadingTemplates/CreateOrUpdateTemplate",
  deleteFrontLoadingTemp: "/api/FrontloadingTemplates/DeleteTemplate",
  createFrontLoadingTempPhase:
    "/api/FrontloadingTemplates/CreateOrUpdateTemplatePhase",
  updateFrontLoadingTempPhase:
    "/api/FrontloadingTemplates/CreateOrUpdateTemplatePhase",
  deleteFrontLoadingTempPhase: "/api/FrontloadingTemplates/DeleteTemplatePhase",

  //FrontLoading Tool

  saveFlProject: "/api/FrontLoadingTool/SaveFrontLoadingProject",
  getFlTemplates: "/api/FrontLoadingTool/GetFrontLoadingTemplates",
  getFlProjects: "/api/FrontLoadingTool/GetFrontLoadingProjects",
  getToBeEdittedFlProject: "/api/FrontLoadingTool/EditFrontloadingProject",
  editFlProject: "/api/FrontLoadingTool/EditFrontloadingProject",
  deleteFlProject: "/api/FrontLoadingTool/DeleteFrontloadingProject",
  selectFlProjectForConsultant:
    "/api/FrontLoadingTool/SelectFrontloadingProjectForConsultant",
  getFlProjectDetails: "/api/FrontLoadingTool/GetFrontloadingProjectDetails",
  uploadPhase: "/api/FrontLoadingTool/SavePhase",
  uploadMilestone: "/api/FrontLoadingTool/SaveMilestone",
  getPhaseValidDates: "/api/FrontLoadingTool/GetPhaseValidDates",
  getRTDCurveData: "/api/FrontLoadingTool/GetRTDCurveData",
  getBestPracticesCurves: "/api/FrontLoadingTool/GetBestPracticeCurves",
  getAboutCurrentFlProject: "/api/FrontLoadingTool/GetAboutCurrentFlProject",
  getFrontloadingPrgress: "/api/FrontLoadingTool/GetFrontloadingPrgress",
  markFlProjectAsComplete: "/api/FrontLoadingTool/MarkFlProjectAsComplete",
  deleteMilestone: "/api/FrontLoadingTool/DeleteMilestone",
  exporToExcel: "/api/FrontLoadingTool/ExporToExcel",

  //help page for admin
  getAllHelpPages: "/api/HelpPages/GetAll",
  updateHelpPage: "/api/HelpPages/Update",
  getHelpPage: "/api/HelpPages/GetHelpPage",
  getHelpPageById: "/api/HelpPages/GetHelpPageById",
  //Interface Analysis
  createInterface: "/api/InterfaceTool/Create",
  getInterfaceProgress: "/api/InterfaceTool/GetInterfaceProgress",
  getIAView: "/api/InterfaceTool/GetAll",
  GetIARounds: "/api/InterfaceTool/GetIARounds",
  GetIAMetaData: "/api/InterfaceTool/GetIAMetaData",
  getAllForAnonymous: "/api/InterfaceTool/GetAllForAnonymous",
  saveResponseForAnonymous: "/api/InterfaceTool/SaveResponseForAnonymous",
  inviteForIA: "/api/InterfaceTool/Invite",
  excludeForIA: "/api/InterfaceTool/Exclude",
  changeDepartmentStatusInInterface:
    "/api/InterfaceTool/ChangeDepartmentStatusInInterface",
  startAnalysisInterface: "/api/InterfaceTool/StartAnalysisInterface",
  endAnalysisInterface: "/api/InterfaceTool/EndAnalysisInterface",
  restartAnalysisInterface: "/api/InterfaceTool/RestartAnalysisInterface",
  completeAnalysisInterface: "/api/InterfaceTool/CompleteAnalysisInterface",
  cancelAnalysisInterface: "/api/InterfaceTool/CancelAnalysisInterface",
  reviewInterfaceAnswer: "/api/InterfaceTool/ReviewInterfaceAnswer",
  exporInterfacesToExcel: "/api/InterfaceTool/ExporToExcel",
  //frontLoading Curves
  getAllCurves: "/api/FrontloadingTemplates/GetAllCurves",
  createCurve: "/api/FrontloadingTemplates/CreateOrUpdateCurve",
  updateCurve: "/api/FrontloadingTemplates/CreateOrUpdateCurve",
  deleteCurve: "/api/FrontloadingTemplates/DeleteCurve",
  //frontLoading CurvePhase
  createCurvePhase: "/api/FrontloadingTemplates/CreateOrUpdateCurvePhase",
  updateCurvePhase: "/api/FrontloadingTemplates/CreateOrUpdateCurvePhase",
  deleteCurvePhase: "/api/FrontloadingTemplates/DeleteCurvePhase",
  //frontLoading CurvePhasePoint
  createCurvePhasePoint:
    "/api/FrontloadingTemplates/CreateOrUpdateCurvePhasePoint",
  updateCurvePhasePoint:
    "/api/FrontloadingTemplates/CreateOrUpdateCurvePhasePoint",
  deleteCurvePhasePoint: "/api/FrontloadingTemplates/DeleteCurvePhasePoint",

  //user management
  getAllUsers: "/api/Employees/GetAll",
  createUser: "/api/Employees/Create",
  updateUser: "/api/Employees/Update",
  activeUser: "/api/Employees/Activate",
  deactivateUser: "/api/Employees/Deactivate",

  //profile user
  getProfile: "/api/Profile/GetProfile",
  updateProfile: "/api/Profile/Update",
  resendActivationLinkProfile: "/api/Profile/ResendActivationLink",
  checkPassword: " /api/Profile/CheckPassword",
  changePasswordProfile: "/api/Profile/ChangePassword",
  removeProfileImage: "/api/Profile/RemoveProfileImage",

  //activity survey
  getActivityProgress: "/api/ActivityTool/GetActivityProgress",
  GetAllForAnonymousActivitySurvey: "/api/ActivityTool/GetAllForAnonymous",
  SaveParticipantAnswer: "/api/ActivityTool/SaveParticipantAnswer",
  CreateActivityVotingForPtofile: "/api/ActivityTool/Create",
  CancelActivityVotingForPtofile: "/api/ActivityTool/Cancel",
  StartActivityVotingForPtofile: "/api/ActivityTool/Start",
  EndActivityVotingForPtofile: "/api/ActivityTool/End",
  CompleteActivityVotingForPtofile: "/api/ActivityTool/Complete",
  ExportActivityVotingForPtofile: "/api/ActivityTool/Export",
  ReviewActivityVote: "/api/ActivityTool/Review",
  ReviewActivityAnswer: "/api/ActivityTool/ReviewAnswer",
  GetAllActivityVoting: "/api/ActivityTool/GetAll",
  updateActivityToolTask: "/api/ActivityTool/EditTask",
  deleteActivityToolTask: "/api/ActivityTool/DeleteTask",
  DeleteActivityAnswer: "/api/ActivityTool/DeleteAnswer",
  addActivityToolTaskToGroups: "/api/ActivityTool/AddNewTask",
  //activity project tasks
  getActivityProjectTasks: "/api/ActivityTool/GetActivityProjectTasks",
  //
  //self AssessmentBpTemplate
  getSelfAssessmentBpTemp: "/api/SelfAssessment/GetSelfAssessmentBpTemplate",
  createSelfAssessmentBpTemp:
    "/api/SelfAssessment/CreateSelfAssessmenetBpTemplate",
  updateSelfAssessmentBpTemp:
    "/api/SelfAssessment/UpdateSelfAssessmenetBpTemplate",
  deleteSelfAssessmentBpTemp:
    "/api/SelfAssessment/DeleteSelfAssessmenetBpTemplate",

  GetViewSelfAssessment: "/api/SelfAssessment/GetView",
  GetProgressSelfAssessment: "/api/SelfAssessment/GetProgress",
  SaveSelfAssessmentBp: "/api/SelfAssessment/SaveSelfAssessmentBp",
  GetHistoryOfImportance: "/api/SelfAssessment/GetHistoryOfImportance",
  ExportSelfAssessmenetToExcel: "/api/SelfAssessment/ExporToExcel",
  //error Page
  getAllError: "/api/Errors/GetAll",
  updateError: "/api/Errors/Update",

  getAllPermissions: "/api/Permissions/GetPermissions",
  savePermissions: "/api/Permissions/SavePermissions",
  //eventsLogs
  getFilterEventLog: "/api/EventLog/GetFilterDate",
  getAllEventLog: "/api/EventLog/GetAll",

  //FrontLoadingDepartment

  getAllFrontLoadingDepartment: "/api/FrontloadingDepartment/GetAll",
  createFrontLoadingDepartment: "/api/FrontloadingDepartment/Create",
  updateFrontLoadingDepartment: "/api/FrontloadingDepartment/Update",
  deleteFrontLoadingDepartment: "/api/FrontloadingDepartment/Delete",

  ImportDepartmentTable: "/api/CustomerDepartments/ImportDepartmentTable",
  ImportCustomerUser: "/api/Customer/ImportCustomerUser",

  GetCountOfUnreviewedAnswers: "/api/Lever/GetCountOfUnreviewedAnswers"
};
