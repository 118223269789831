import { ActionTypes } from "../actionsTypes";
import { IPermissionsState } from "./IGeneralState";

const initialState: IPermissionsState = {
    isLoading: false,
    viewResult: null,
    currentProjectId: 0
};
const PermissionsReducer = (
    state = initialState,
    action: any
): IPermissionsState => {
    switch (action.type) {
        case ActionTypes.ChangePermissionLoader:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case ActionTypes.SetPermissions:
            //console.log("SetPermissions ", action.result);
            return {
                ...state,
                viewResult: action.result
            };
        case ActionTypes.SetCurrentProjectPermissionId:
            //console.log("SetPermissions ", action.result);
            return {
                ...state,
                currentProjectId: action.result
            };
        default:
            break;
    }
    return state;
};

export default PermissionsReducer;