import { Button, Form, Input, InputNumber, Select } from "antd";
import { RulesName, RulesRequire } from "../../../Admin/Rules/RulesValidation";
import { t } from "i18next";
import { ActivityProfileAnalysisView, CreateActivityProfileAnalysisDto } from "../Survey/Models/ActivitySurveyData";

interface IProps {
    profile: ActivityProfileAnalysisView;
    createNewAnalysis: (model: CreateActivityProfileAnalysisDto) => any;
}
export default function ProfileViewForm(props: IProps) {

    const [form] = Form.useForm();

    return <Form form={form} key={`profile-create-form-${props.profile?.activityProfile?.id}`} layout="vertical"
        className="start_point_form"
    >
        <Form.Item initialValue={35} name={"hoursPerWeek"} label={`${t("hoursPerWeek")}`}>
            <InputNumber disabled />
        </Form.Item>
        <Form.Item initialValue={22} name={"daysPerMonth"} label={`${t("daysPerMonth")}`}>
            <InputNumber disabled />
        </Form.Item>
        <Form.Item initialValue={220} name={"daysPerYear"} label={`${t("daysPerYear")}`}>
            <InputNumber disabled />
        </Form.Item>
        <Form.Item name={"versionNumber"} label={`${t("versionNumber")}`} rules={[...RulesRequire({ filedName: t("versionNumber") })]}>
            <Select showSearch placeholder={`${t("Select Version")}`} optionFilterProp="children"
                getPopupContainer={() => document.getElementById("app-layout")}
                options={props.profile?.activityProfile?.versions}
            />
        </Form.Item>
        <Form.Item rules={RulesName({ name: `${t("Custom Profile Name")}`, countChar: 64 })} name={"customProfileName"} label={`${t("Custom Profile Name")}`}>
            <Input />
        </Form.Item>
        <Button type="primary" onClick={async () => {
            try {
                let validation = await form.validateFields();
                console.log("Close and Complete", validation);
                let values = form.getFieldsValue();
                props.createNewAnalysis({
                    activityProfileId: props.profile?.activityProfile?.id,
                    // daysPerMonth: 22,
                    // daysPerYear: 220,
                    // hoursPerWeek: 35,
                    // daysPerMonth: values["daysPerMonth"],
                    // daysPerYear: values["daysPerYear"],
                    // hoursPerWeek: values["hoursPerWeek"],
                    versionNumber: values["versionNumber"],
                    customProfileName: values["customProfileName"]
                })
            }
            catch (e) {
                console.log(e);
            }
        }} className="start_point_btn"> {`${t('Create Now')}`}</Button>
    </Form>;
}