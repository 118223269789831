import { ToastMessages } from "../Enums/Messages";
import { IConfirmTokenUser, ILoginModel, ITokenRequest, IVerificationCodeModel } from "../Models/Requests/AuthModels";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";

export default class AuthService {
  static async StartLogin(model: ILoginModel): Promise<ICommonResponse> {
        return await asupapi
        .post(endPoints.LoginUrl, model)
        .then(function (response) {
            let result: ICommonResponse = response.data;
            return result;
        })
        .catch(function (error) {
            if (error.response) {
                console.log('error1 ', error.response);
                if (error.response.data) {
                    console.log('error0 ', error?.response?.data);
                    let result: ICommonResponse = error?.response?.data ??  ToastMessages.UnExpectedError;
                    return result;
                } else {
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.UnExpectedError
                        }
                    } as ICommonResponse
                }
            } else if (error.request) {
                console.log('error2 ', error.request);
                return {
                    isError: false,
                    statusCode:  -400,
                    error: {                       
                        exceptionMessage: ToastMessages.NetworkError
                    }
                } as ICommonResponse              
            } else if (error.message) {
                console.log('error3 ', error.message);
                //do something other than the other two
            }
            return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError
                }
            } as ICommonResponse
        });
    }

    static async StartVerify(model: IVerificationCodeModel): Promise<ICommonResponse> {
        return await asupapi
        .post(endPoints.VerifyLogin, model)
        .then(function (response) {
            let result: ICommonResponse = response.data;
            return result;
        })
        .catch(function (error) {
            if (error.response) {
                console.log('error1 ', error.response);
                if (error.response.data) {
                    console.log('error0 ', error?.response?.data);
                    let result: ICommonResponse = error?.response?.data ??  ToastMessages.UnExpectedError;
                    return result;
                } else {
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.UnExpectedError
                        }
                    } as ICommonResponse
                }
            } else if (error.request) {
                console.log('error2 ', error.request);
                return {
                    isError: false,
                    statusCode:  -400,
                    error: {                       
                        exceptionMessage: ToastMessages.NetworkError
                    }
                } as ICommonResponse              
            } else if (error.message) {
                console.log('error3 ', error.message);
                //do something other than the other two
            }
            return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError
                }
            } as ICommonResponse
        });
    }

    static async ActivateUser(model: IConfirmTokenUser): Promise<ICommonResponse> {
        return await asupapi
        .post(endPoints.ActivateUser, model)
        .then(function (response) {
            let result: ICommonResponse = response.data;
            return result;
        })
        .catch(function (error) {
            if (error.response) {
                console.log('error1 ', error.response);
                if (error.response.data) {
                    console.log('error0 ', error?.response?.data);
                    let result: ICommonResponse = error?.response?.data ??  ToastMessages.UnExpectedError;
                    return result;
                } else {
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.UnExpectedError
                        }
                    } as ICommonResponse
                }
            } else if (error.request) {
                console.log('error2 ', error.request);
                return {
                    isError: false,
                    statusCode:  -400,
                    error: {                       
                        exceptionMessage: ToastMessages.NetworkError
                    }
                } as ICommonResponse              
            } else if (error.message) {
                console.log('error3 ', error.message);
                //do something other than the other two
            }
            return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError
                }
            } as ICommonResponse
        });
    }

    static async ConfirmPassword(password: string): Promise<ICommonResponse> {
        return await asupapi
        .post(endPoints.ConfirmPassword, {password: password})
        .then(function (response) {
            let result: ICommonResponse = response.data;
            return result;
        })
        .catch(function (error) {
            if (error.response) {
                console.log('error1 ', error.response);
                if (error.response.data) {
                    console.log('error0 ', error?.response?.data);
                    let result: ICommonResponse = error?.response?.data ??  ToastMessages.UnExpectedError;
                    return result;
                } else {
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.UnExpectedError
                        }
                    } as ICommonResponse
                }
            } else if (error.request) {
                console.log('error2 ', error.request);
                return {
                    isError: false,
                    statusCode:  -400,
                    error: {                       
                        exceptionMessage: ToastMessages.NetworkError
                    }
                } as ICommonResponse              
            } else if (error.message) {
                console.log('error3 ', error.message);
                //do something other than the other two
            }
            return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError
                }
            } as ICommonResponse
        });
    }

    // static async RefreshToken(model: ITokenRequest): Promise<ICommonResponse> {
    //     return await asupapi
    //     .post(endPoints.RefreshToken, model)
    //     .then(function (response) {
    //         let result: ICommonResponse = response.data;
    //         return result;
    //     })
    //     .catch(function (error) {
    //         if (error.response) {
    //             console.log('error1 ', error.response);
    //             if (error.response.data) {
    //                 console.log('error0 ', error?.response?.data);
    //                 let result: ICommonResponse = error?.response?.data ??  ToastMessages.UnExpectedError;
    //                 return result;
    //             } else {
    //                 return {
    //                     isError: false,
    //                     statusCode: -400,
    //                     error: {
    //                         exceptionMessage: ToastMessages.UnExpectedError
    //                     }
    //                 } as ICommonResponse
    //             }
    //         } else if (error.request) {
    //             console.log('error2 ', error.request);
    //             return {
    //                 isError: false,
    //                 statusCode:  -400,
    //                 error: {                       
    //                     exceptionMessage: ToastMessages.NetworkError
    //                 }
    //             } as ICommonResponse              
    //         } else if (error.message) {
    //             console.log('error3 ', error.message);
    //             //do something other than the other two
    //         }
    //         return {
    //             isError: false,
    //             statusCode: -400,
    //             error: {
    //                 exceptionMessage: ToastMessages.UnExpectedError
    //             }
    //         } as ICommonResponse
    //     });
    // }

    static async ResetPasswordRequest(email: string): Promise<ICommonResponse> {
        return await asupapi
        .post(endPoints.ResetPasswordRequest, {email: email})
        .then(function (response) {
            let result: ICommonResponse = response.data;
            return result;
        })
        .catch(function (error) {
            if (error.response) {
                console.log('error1 ', error.response);
                if (error.response.data) {
                    console.log('error0 ', error?.response?.data);
                    let result: ICommonResponse = error?.response?.data ??  ToastMessages.UnExpectedError;
                    return result;
                } else {
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.UnExpectedError
                        }
                    } as ICommonResponse
                }
            } else if (error.request) {
                console.log('error2 ', error.request);
                return {
                    isError: false,
                    statusCode:  -400,
                    error: {                       
                        exceptionMessage: ToastMessages.NetworkError
                    }
                } as ICommonResponse              
            } else if (error.message) {
                console.log('error3 ', error.message);
                //do something other than the other two
            }
            return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError
                }
            } as ICommonResponse
        });
    }
    static async ConfirmResetPassword(model: IConfirmTokenUser): Promise<ICommonResponse> {
        return await asupapi
        .post(endPoints.ConfirmResetPassword, model)
        .then(function (response) {
            let result: ICommonResponse = response.data;
            return result;
        })
        .catch(function (error) {
            if (error.response) {
                console.log('error1 ', error.response);
                if (error.response.data) {
                    console.log('error0 ', error?.response?.data);
                    let result: ICommonResponse = error?.response?.data ??  ToastMessages.UnExpectedError;
                    return result;
                } else {
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.UnExpectedError
                        }
                    } as ICommonResponse
                }
            } else if (error.request) {
                console.log('error2 ', error.request);
                return {
                    isError: false,
                    statusCode:  -400,
                    error: {                       
                        exceptionMessage: ToastMessages.NetworkError
                    }
                } as ICommonResponse              
            } else if (error.message) {
                console.log('error3 ', error.message);
                //do something other than the other two
            }
            return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError
                }
            } as ICommonResponse
        });
    }
}