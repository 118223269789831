import { Button, Form, Input, InputNumber } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { ISubLeverItem } from '../../../../Models/ILeversManagement';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { inputFormatter, inputParser } from '../../../../Utils/helperFunctions';
import i18n from '../../../../i18n';
import { RulesName, RulesRequire } from '../../Rules/RulesValidation';
import { t } from 'i18next';

interface IProps {
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    subLevers: any[];
    subLeverId: number;
    updateSubLever: (model: ISubLeverItem) => any;
}

const SubLeverEditModal = (props: IProps) => {
    const subLever: ISubLeverItem[] = props.subLevers?.filter(l => l.id === props.subLeverId).map(m => {
        return {
            id: m.id,
            nameEn: m.nameEn,
            nameDe: m.nameDe,
            ebitImpctRnd: m.ebitImpctRnd,
            ebitImpctInno: m.ebitImpctInno,
            ebitImpctCod: m.ebitImpctCod,
            ebitImpctMc: m.ebitImpctMc,
            ebitImpctQc: m.ebitImpctQc,
            successFactors: [],
        }
    }) ?? [];
  
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            let values = await form.getFieldsValue();
            await props.updateSubLever({
                id: subLever[0]?.id,
                nameEn: values?.nameEn,
                nameDe: values?.nameDe,
                ebitImpctRnd: values?.ebitImpctRnd,
                ebitImpctInno: values?.ebitImpctInno,
                ebitImpctCod: values?.ebitImpctCod,
                ebitImpctMc: values?.ebitImpctMc,
                ebitImpctQc: values?.ebitImpctQc,
                successFactors: [],
            })

        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <ModalAdmin title={`${t("Edit")} ${t("SubLevers")}`} btnText={`${t("Edit")}`}
            width="520px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form} initialValues={{...subLever[0]}}>
                <Form.Item label={`${t("English Name")}`} name='nameEn'
                    rules={RulesName({ name: `${t("English Name")}`,countChar:1500 })}>
                    <Input
                    />
                </Form.Item>
                <Form.Item label={`${t("German Name")}`} name='nameDe'
                    rules={RulesName({ name: `${t("German Name")}`,countChar:1500 })}>
                    <Input
                    />
                </Form.Item>
                <Form.Item label="Rnd" name='ebitImpctRnd' rules={RulesRequire({ filedName: 'ebitImpctRnd' })} >
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%" />
                </Form.Item>
                <Form.Item label="Inno" name='ebitImpctInno' rules={RulesRequire({ filedName: 'Inno' })} >
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%" />
                </Form.Item>
                <Form.Item label="Cod" name='ebitImpctCod' rules={RulesRequire({ filedName: 'Cod' })}>
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%" />
                </Form.Item>
                <Form.Item label="Mc" name='ebitImpctMc' rules={RulesRequire({ filedName: 'Mc' })} >
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%" />
                </Form.Item>
                <Form.Item label="Qc" name='ebitImpctQc' rules={RulesRequire({ filedName: 'Qc' })} >
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%" />
                </Form.Item>
            </Form>
        </ModalAdmin>
    )
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateSubLever: (model: ISubLeverItem) => dispatch({ type: ActionTypes.UPDATE_SUB_LEVER_FOR_ADMIN, model }),
    };
};

export default connect(null, mapDispatchToProps)(SubLeverEditModal);
