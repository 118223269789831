import { ActionTypes } from "../actionsTypes";
import { IEbitToolState } from "./IGeneralState";
import { RowTypeValue } from "../../Scenes/Tools/EBITTool-V2/Models/RndInfoResponse";

const initialState: IEbitToolState = {
    scale: 1,
    rndInfoResponse: null,
    recivedRndUnits:null,
    unitsRows: [] as RowTypeValue[],
    isEbitLoading: false,
    dProjectForEbit: [],
    dProjectForSelectedProject: [],
    estimateInfo: null,
    evaluationInfo: null,
    ebitBenchmarkingInfo: null,
    ebitProgress: null,
};

const EBITReducer = (state = initialState, action: any): IEbitToolState => {
    switch (action.type) {
        case ActionTypes.SCAlECHART:
            return {
                ...state,
                scale: action.scale
            }
        case ActionTypes.changeEbitLoader:
            return {
                ...state,
                isEbitLoading: action.isLoading
            }
        case ActionTypes.SaveRndInfo:
            return {
                ...state,
                rndInfoResponse: { ...action.result }
            }
        case ActionTypes.SaveRecivedRndUnits:
            return {
                ...state,
                recivedRndUnits: action.result
            }
        case ActionTypes.SaveRdUnitsRow:
            //console.log("SaveRdUnitsRow", action.result);
            return {
                ...state,
                unitsRows: action.result
            }
        case ActionTypes.SaveDprojectsForEbit:
            return {
                ...state,
                dProjectForEbit: action.result
            }
        case ActionTypes.saveDProjectsForSelectedProject:
            return {
                ...state,
                dProjectForSelectedProject: action.result
            }
        case ActionTypes.SetEbitEstimation:
            //console.log("SetEbitEstimation", action.result);
            return {
                ...state,
                estimateInfo: action.result
            }
        case ActionTypes.SetEbitEvaluation:
            //console.log("SetEbitEvaluation", action.result);
            return {
                ...state,
                evaluationInfo: action.result
            }
        case ActionTypes.SetEbitBenchmark:
            //console.log("SetEbitBenchmark", action.result);
            return {
                ...state,
                ebitBenchmarkingInfo: action.result
            }
        case ActionTypes.SetEbitProgress:
            //console.log("SetEbitProgress", action.result);
            return {
                ...state,
                ebitProgress: action.result
            }
        default:
            break;
    }
    return state;
};

export default EBITReducer;