import {
  Collapse,
  Form,
  InputNumber,
  Typography,
  Row,
  Col,
  Input,
  Select,
  Popconfirm,
  Space,
  Tooltip,
  Table,
  Segmented,
  Checkbox,
} from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  StopTwoTone,
} from "@ant-design/icons";
import TableTwoColumns from "../../../../../Components/TableTwoColumns";
import { ManufacturingCostRowValue } from "../../../../../Models/EBIT/CostTypes";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IEbitToolState,
  IGeneralState,
} from "../../../../../Redux/reducers/IGeneralState";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
import { BmProject } from "../../../../../Models/Responses/ProfileResponse";
import exportIcon from "../../../../..//assets/Images/export-xlsx-icon.svg";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { inputFormatter, inputParser, formatValue } from "../../../../../Utils/helperFunctions";
import i18n from "../../../../../i18n";
import TableForPanel from "./TableForPanel";
import {
  ManufacturingCostDto,
  ManufacturingCostWithDprojectDto,
} from "../../Models/CostTypesResponse";
import UploadFile from '../UploadFilesComponent';
const { Panel } = Collapse;
const { Title, Paragraph } = Typography;
const { Option } = Select;
interface InnoProps {
  ebitInfo: IEbitToolState;
  selectedProject?: BmProject,
  currentProject: BmProject;
  onUpdateMCWithNewDproject: (model: ManufacturingCostWithDprojectDto) => any;
  onCreateMC: (model: ManufacturingCostDto, projectID: number) => any;
  onUpdateMC: (model: ManufacturingCostDto, projectID: number) => any;
  onDeleteMC: (modelId: number, projectID: number, forEbit: boolean) => any;
  onDownloadHKTable: (projectName: string, projectID: number) => any;
  onImportMCTable: (frmData: FormData, forEbit: boolean) => any;
}
function HKPanel(props: InnoProps) {
  const newRowInno: ManufacturingCostRowValue = {
    key: "newMC",
    project: null,
    projectyear: 0,
    actualCosts: 0,
    otherCosts: 0,
    plannedCosts: 0,
    quantity: 0,
    rndImpact: 0,
    newProduct: "",
  };

  const { t } = useTranslation();
  const [editRecord, setEditRecord] = useState(
    newRowInno as ManufacturingCostRowValue
  );
  const [form] = Form.useForm();
  const [addFromList, setAddFromList] = useState(true);

  const plannedCosts = Form.useWatch(
    `plannedCosts_${editRecord?.project}`,
    form
  );
  const actualCosts = Form.useWatch(`actualCosts_${editRecord?.project}`, form);
  const otherCosts = Form.useWatch(`otherCosts_${editRecord?.project}`, form);
  const rndImpact = Form.useWatch(`rndImpact_${editRecord?.project}`, form);
  const quantity = Form.useWatch(`quantity_${editRecord?.project}`, form);
  const { Option } = Select;
  const isForEbit = props.selectedProject ? false : true;
  const projectId = Form.useWatch(`project_${editRecord?.project}`, form);

  useEffect(() => {
    if (props.ebitInfo?.dProjectForEbit) {
      form.setFieldsValue(fillInForm(newRowInno));
      setEditRecord(newRowInno);
    }
  }, [props.ebitInfo]);

  useEffect(() => {
    if (props.selectedProject && props.ebitInfo?.dProjectForSelectedProject) {
      form.setFieldsValue(fillInForm(newRowInno));
      setEditRecord(newRowInno);
    }
  }, [props.selectedProject]);
  let DProjectsList = props.selectedProject ? props.ebitInfo?.dProjectForSelectedProject : props.ebitInfo?.dProjectForEbit;
  const options = DProjectsList
    ?.filter(
      (c) =>
        !c.manufacturingCost &&
        c.dproject.completionYear == props.currentProject?.yearUnderReview
    )
    .map((dp) => {
      return {
        value: dp.dproject.id,
        label: dp.dproject.name,
      };
    });
  const columnsOne = [
    {
      title: "",
      dataIndex: "left",
      key: "1",
      width: "75%",
    },
    {
      title: "",
      dataIndex: "right",
      key: "2",
      width: "25%",
    },
  ];

  //4
  function calculateTotalMcSOP() {
    let sum =
    DProjectsList
        ?.filter(
          (c) =>
            c.manufacturingCost &&
            c.dproject.completionYear == props.currentProject?.yearUnderReview
        )
        ?.reduce((total, next) => {
          return (
            total +
            next.manufacturingCost.actualCosts * next.manufacturingCost.quantity + next.manufacturingCost.otherCosts
          );
        }, 0) ?? 0;
    return sum;
  }
  //3
  function calculateTotalMcPlan() {
    let sum =
    DProjectsList
        ?.filter(
          (c) =>
            c.manufacturingCost &&
            c.dproject.completionYear == props.currentProject?.yearUnderReview
        )
        ?.reduce((total, next) => {
          return (
            total +
            next.manufacturingCost.plannedCosts *
            next.manufacturingCost.quantity
          );
        }, 0) ?? 0;
    return sum;
  }
  //2
  function calculatePotentialSaving() {
    let sum =
    DProjectsList
        ?.filter(
          (c) =>
            c.manufacturingCost &&
            c.dproject.completionYear == props.currentProject?.yearUnderReview
        )
        ?.reduce((total, next) => {
          return (
            total +
            ((next.manufacturingCost.actualCosts -
              next.manufacturingCost.plannedCosts) *
              next.manufacturingCost.quantity + next.manufacturingCost.otherCosts) *
            (next.manufacturingCost.rndImpact / 100)
          );
        }, 0) ?? 0;
    let rv = sum - props.currentProject?.bpMcShare * props.currentProject?.sales;
    return rv >= 0 ? rv : 0;
  }
  //1
  function calculatePotentionalMcRate() {
    let rv = props.currentProject?.sales !== 0 ? calculatePotentialSaving() / props.currentProject?.sales : 0;
    return rv * 100;
  }

  const rowsOne = [
    {
      key: 14,
      left: (
        <Tooltip placement="bottomRight" title={`${t("Einsparpotential")} / ${t("Umsatz")}`}>
          {t("EBIT-Potenzial")}
          <sub>{t("HK")}</sub>
          {`[%]`}
        </Tooltip>
      ),
      right: `${formatValue(calculatePotentionalMcRate())} %`,
    },
    {
      key: 12,
      left: (
        <Tooltip placement="bottomRight" title={t("HK IST - HK PLAN")}>
          {t("HK-Einsparpotential")}
          <sub>{t("Einsparpotential sub")}</sub>
          {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculatePotentialSaving())} €`,
    },
  ];

  const rowsTwo = [
    {
      key: 10,
      left: (
        <Tooltip placement="bottomRight" title={t("Summe über alle HK PLAN")}>
          {t("Gesamte HK")} <sub>{`PLAN`}</sub>
        </Tooltip>
      ),
      right: `${formatValue(calculateTotalMcPlan())} €`,
    },
    {
      key: 3,
      left: (
        <Tooltip placement="bottomRight" title={t("Summe über alle HK bei SOP")}>
          {t("Gesamte HK")} <sub>{`SOP`}</sub>
        </Tooltip>
      ),
      right: `${formatValue(calculateTotalMcSOP())} €`,
    },
  ];

  function _toString(value: number, currency: string = "") {
    try {
      if (Number.isNaN(value)) {
        value = 0;
      }
      let valueNumber = new Number(value);
      let tempVal = "";
      if (valueNumber && valueNumber != 0) {
        tempVal = valueNumber.toFixed(2);
      } else {
        tempVal = valueNumber.toFixed(2);
      }
      return `${t("localizedvalue", {
        count: parseFloat(tempVal),
      })}${currency}`;
    } catch (e) {
      console.log("_toString", value, e);
      return value;
    }
  }
  const dataColInno: ColumnsType = [
    {
      title: (
        <>
          <label>{t("Produkte")}</label>
          <br></br>
          <sub>{t("Markteintritt")} n </sub>
        </>
      ),
      width: "15%",
      dataIndex: "product",
      fixed: "left", // fix element
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        //console.log("render", text, record, index);
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return (
              <Select
                disabled
                showSearch
                placeholder="Select..."
                options={options}
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  fontSize: "12px",
                }}
              />
            );
          }
          // return  <Space size={2}>
          return (
            <Row>
              <Col span={2}>
                <Checkbox
                  checked={addFromList}
                  onChange={(e) => {
                    form.setFieldsValue(fillInForm(newRowInno));
                    setEditRecord(newRowInno);
                    setAddFromList(e.target.checked);
                    console.log("form fields", form.getFieldsValue());
                  }}
                />
              </Col>
              <Col span={1}></Col>
              <Col span={21}>
                <Form.Item
                  hidden={!addFromList}
                  name={`project_${record?.project}`}
                  style={{ margin: 0 }}
                  rules={
                    !addFromList
                      ? []
                      : [
                        {
                          required: true,
                          message: `${t("required*")}`,
                        },
                      ]
                  }
                >
                  <Select
                    dropdownMatchSelectWidth={false}
                    optionFilterProp="label"
                    showSearch
                    getPopupContainer={() =>
                      isForEbit ? document.getElementById("app-layout")
                        : document.getElementsByClassName("ant-modal-root").item(0) as HTMLElement
                    }
                    placeholder={t("Auswahl")}
                    options={options}
                    style={{
                      textOverflow: "ellipsis",
                      fontSize: "12px",
                      width: "100%",
                      zIndex: 5,
                    }}
                  />
                  {/* */}
                </Form.Item>

                <Form.Item
                  hidden={addFromList}
                  name={`projectname_${record?.project}`}
                  style={{ margin: 0 }}
                  rules={
                    addFromList
                      ? []
                      : [
                        {
                          required: true,
                          message: `${t("required*")}`,
                        },
                      ]
                  }
                >
                  <Input
                    placeholder={t("Neues Entwciklungsprojekt")}
                    style={{ fontSize: "12px" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          );

          {
            /* </Space>; */
          }
        } else {
          return (
            DProjectsList.find(
              (c) => c.dproject.id == record?.project
            )?.dproject.name ?? ""
          );
        }
      }, // keep this if you need to render link with data cell.
    },
    {
      title: (
        <>
          <label>{t("Projekt-Abschlussjahr")}</label>
          <br></br>
          <sub>{`n`}</sub>
        </>
      ),
      width: "10%",
      dataIndex: "projectyear",
      defaultSortOrder: "descend",
      sorter: (a: ManufacturingCostRowValue, b: ManufacturingCostRowValue) => {
        if (
          a.projectyear == -1 ||
          b.projectyear == -1 ||
          a.projectyear == 0 ||
          b.projectyear == 0
        )
          return 0;
        else return a.projectyear - b.projectyear;
      },
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return <InputNumber disabled />;
          }
          if (addFromList) {
            return DProjectsList?.find(
              (c) => c.dproject.id == projectId
            )?.dproject.completionYear;
          } else {
            return (
              <Form.Item
                name={`ProjektAbschlussjahr_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber min={1970} maxLength={4} />
              </Form.Item>
            );
          }
        } else {
          return record?.projectyear;
        }
      },
    },
    {
      title: (
        <>
          <label>{t("HK PLAN")}</label>
          <br></br>
          <sub>{`in €`}</sub>
        </>
      ),
      dataIndex: "plannedCosts",
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`plannedCosts_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={"€"}
                // maxLength={12}
                min={0}
                // max={9999999}
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`plannedCosts_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  addonAfter={"€"}
                  // maxLength={12}
                  min={0}
                  // max={9999999}
                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.plannedCosts) + "€";
          }
        }
      },
    },
    {
      title: (
        <>
          <label>{t("HK IST")}</label>
          <br></br>
          <sub>{`in €`}</sub>
        </>
      ),
      dataIndex: "actualCosts",
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`actualCosts_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={"€"}
                // maxLength={12}
                min={0}
                // max={9999999}
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`actualCosts_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  addonAfter={"€"}
                  // maxLength={12}
                  min={0}
                  // max={9999999}
                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return _toString(record?.actualCosts, "€");
          }
        }
      },
    },
    {
      title: (
        <>

          <Tooltip placement="top" title={t("Durchs. Jahresstückzahl nach Ramp-Up")}>
            <label >{t("Stückzahl")}</label>
          </Tooltip>
        </>
      ),
      width: "8%",
      dataIndex: "quantity",
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`quantity_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                min={0}
                // style={{ width: "8ch" }}
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`quantity_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  // style={{ width: "8ch" }}
                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.quantity);
          }
        }
      },
    },
    {
      title: (
        <>
          <label>{t("Andere Kosten")}</label>
          <br></br>
          <sub>{`in €`}</sub>
        </>
      ),
      dataIndex: "otherCosts",
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`otherCosts_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={"€"}
                // maxLength={12}
                min={0}
                // max={9999999}
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`otherCosts_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  addonAfter={"€"}
                  // maxLength={12}
                  min={0}
                  // max={9999999}
                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.otherCosts) + "€";
          }
        }
      },
    },
    {
      title: (
        <>
          <label>{t("HK Abweichung")}</label>
          <br></br>
          <sub>{`in €`}</sub>
        </>
      ),
      dataIndex: "hkdeviation",
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return `${_toString(
              (record?.actualCosts - record?.plannedCosts) * record?.quantity + record?.otherCosts,
              "€"
            )}`;
          } else {
            return `${formatValue((actualCosts - plannedCosts) * quantity + otherCosts)} €`;
          }
        } else {
          if (editRecord?.key == record?.key) {
            return `${formatValue((actualCosts - plannedCosts) * quantity + otherCosts)} €`;
          } else {
            return `${formatValue(
              (record?.actualCosts - record?.plannedCosts) * record?.quantity + record?.otherCosts)} €`;
          }
        }
      },
    },
    {
      title: (
        <>
          <label>{t("Anteil F&Ee")}</label>
          <br></br>
          <sub>{`in %`}</sub>
        </>
      ),
      width: "7%",
      dataIndex: "rndImpact",
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`rndImpact_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={"%"}
                min={0}
                max={100}
                style={{ width: "10ch" }}
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`rndImpact_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  addonAfter={"%"}
                  min={0}
                  max={100}
                  style={{ width: "10ch" }}
                  // addonAfter={'%'}
                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.rndImpact) + "%";
          }
        }
      },
    },
    {
      title: (
        <>
          <label>{t("EBIT des Produktse")}</label>
          <br></br>
          <sub>{`in €`}</sub>
        </>
      ),
      dataIndex: "ebitdesproduct",
      render: (text: any, record: ManufacturingCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newMC") {
            return `${formatValue(
              (record?.actualCosts - record?.plannedCosts) *
              record?.quantity *
              (record?.rndImpact / 100))} €`;
          } else {
            return `${formatValue(
              ((actualCosts - plannedCosts) * quantity + otherCosts) * (rndImpact / 100))} €`;
          }
        } else {
          if (editRecord?.key == record?.key) {
            return `${formatValue(
              ((actualCosts - plannedCosts) * quantity + otherCosts) * (rndImpact / 100))} €`;
          } else {
            return `${formatValue(
              ((record?.actualCosts - record?.plannedCosts) *
                record?.quantity + record?.otherCosts) *
              (record?.rndImpact / 100))} €`;
          }
        }
      },
    },
    {
      title: (
        <Space wrap={true} align="center" direction="horizontal">
          <Tooltip placement="top" title="Import Manufactoring Costs excel">
            <UploadFile currentProjectId={props.currentProject?.id} isForEbit={isForEbit} onImportCostTable={props.onImportMCTable} />
          </Tooltip>
          <Tooltip placement="top" title={t("Daten exportieren")}>
            <img
              src={exportIcon}
              alt="export icon"
              onClick={() => {
                onExport();

              }}
            />
          </Tooltip>
        </Space>
      ),
      width: "7%",
      dataIndex: "actions",
      render: (text, record: ManufacturingCostRowValue, index) => {
        //console.log(text, record, index);
        if (index == 0) {
          return (
            <Space size={10}>
              {" "}
              <span>
                <Typography.Link
                  disabled={editRecord?.key != record?.key}
                  onClick={() => onAddRow(record)} //handleAdd(record?.key)}
                  style={{ marginRight: 8 }}
                >
                  <Tooltip placement="bottom" title={`${t("Add data")}`}>
                    <PlusOutlined style={{ color: "green" }} />
                  </Tooltip>
                </Typography.Link>
              </span>
            </Space>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Space size={10}>
                <Popconfirm
                  title="Sure to save?"
                  onConfirm={() => onEditRow(record)}
                >
                  <a>
                    <Tooltip placement="bottom" title="Save data">
                      <CheckCircleOutlined />
                    </Tooltip>
                  </a>
                </Popconfirm>

                <Typography.Link
                  disabled={
                    editRecord?.key != "newMC" && editRecord?.key != record?.key
                  }
                  onClick={() => {
                    form.setFieldsValue(fillInForm(newRowInno));
                    setEditRecord(newRowInno);
                  }}
                >
                  <Tooltip placement="bottom" title="Cancel Changes">
                    <StopTwoTone twoToneColor="red" />
                  </Tooltip>
                </Typography.Link>
              </Space>
            );
          } else {
            return (
              <Space size={10}>
                <Typography.Link
                  disabled={
                    editRecord?.key != "newMC" && editRecord?.key != record?.key
                  }
                  onClick={() => {
                    form.setFieldsValue(fillInForm({ ...record }));
                    setEditRecord(record);
                  }}
                >
                  <Tooltip placement="bottom" title={`${t("Edit")}`}>
                    <EditTwoTone twoToneColor="#1890ff" />
                  </Tooltip>
                </Typography.Link>

                <Popconfirm
                  title={`${t("Sure to delete?")}`}
                  onConfirm={() => onDeleteRow(record)}
                >
                  <a>
                    <Tooltip placement="bottom" title={`${t("Delete")}`}>
                      <DeleteTwoTone twoToneColor="red" />
                    </Tooltip>
                  </a>
                </Popconfirm>
              </Space>
            );
          }
        }
      },
    },
  ];

  const dataRowInno =
  DProjectsList
      ?.filter(
        (c) =>
          c.manufacturingCost &&
          c.dproject.completionYear == props.currentProject?.yearUnderReview
      )
      .map((row) => {
        if (row.manufacturingCost) {
          return {
            key: `mc_row_${row.dproject.id}`,
            project: row.dproject.id,
            projectyear: row.dproject.completionYear,
            actualCosts: row.manufacturingCost.actualCosts,
            otherCosts: row.manufacturingCost.otherCosts,
            plannedCosts: row.manufacturingCost.plannedCosts,
            quantity: row.manufacturingCost.quantity,
            rndImpact: row.manufacturingCost.rndImpact,
            newProduct: "",
          } as ManufacturingCostRowValue;
        }
      }) ?? [];

  function fillInForm(row: ManufacturingCostRowValue) {
    let rowNames: any = {};
    rowNames[`project_${row.project}`] = row.project;
    rowNames[`actualCosts_${row.project}`] = row.actualCosts;
    rowNames[`otherCosts_${row.project}`] = row.otherCosts;
    rowNames[`plannedCosts_${row.project}`] = row.plannedCosts;
    rowNames[`ProjektAbschlussjahr_${row.project}`] = row.projectyear;
    rowNames[`quantity_${row.project}`] = row.quantity;
    rowNames[`rndImpact_${row.project}`] = row.rndImpact;
    rowNames[`projectname_${row.project}`] = row.newProduct ?? "";
    return rowNames;
  }
  function getRowFromForm(obj: any) {
    let row: ManufacturingCostRowValue = {
      key: "",
      project: 0,
      projectyear: 0,
      actualCosts: 0,
      otherCosts: 0,
      plannedCosts: 0,
      quantity: 0,
      rndImpact: 0,
      newProduct: "",
    };

    Object.keys(obj).forEach((key) => {
      console.log(key, obj[key]);
      if (key.includes("projectname_")) row.newProduct = obj[key] ?? "";
      if (key.includes("project_")) row.project = obj[key];
      if (key.includes("actualCosts_")) row.actualCosts = obj[key];
      if (key.includes("otherCosts_")) row.otherCosts = obj[key];
      if (key.includes("plannedCosts_")) row.plannedCosts = obj[key];
      //if (key.includes("projectyear_")) row.projectyear = obj[key];
      if (key.includes("ProjektAbschlussjahr_"))
        row.projectyear = obj[key] ?? "";
      if (key.includes("quantity_")) row.quantity = obj[key];
      if (key.includes("rndImpact_")) row.rndImpact = obj[key];
    });
    return row;
  }
  async function onAddRow(row: ManufacturingCostRowValue) {
    try {
      console.log("obj", form.getFieldsValue());
      const row2 = await form.validateFields();
      let newData = getRowFromForm(row2);
      console.log(newData);
      if (newData.newProduct == "") {
        props.onCreateMC(
          {
            id: newData.project,
            quantity: newData.quantity,
            plannedCosts: newData.plannedCosts,
            actualCosts: newData.actualCosts,
            otherCosts: newData.otherCosts,
            rndImpact: newData.rndImpact,
            forEbit: isForEbit,
          } as ManufacturingCostDto,
          props.currentProject?.id
        );
      } else {
        console.log("newData.NewProduct:", newData.newProduct);
        var newdata2: ManufacturingCostWithDprojectDto = {
          bmProjectId: props.currentProject?.id,
          dprojectCompletionYear: newData.projectyear,
          dprojectName: newData.newProduct,
          quantity: newData.quantity,
          plannedCosts: newData.plannedCosts,
          actualCosts: newData.actualCosts,
          otherCosts: newData.otherCosts,
          rndImpact: newData.rndImpact,
          forEbit: isForEbit,
        };
        props.onUpdateMCWithNewDproject(newdata2);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function onEditRow(row: ManufacturingCostRowValue) {
    try {
      const row2 = await form.validateFields();
      let newData = getRowFromForm(row2);
      console.log(newData);
      props.onUpdateMC(
        {
          id: editRecord.project,
          quantity: newData.quantity,
          plannedCosts: newData.plannedCosts,
          actualCosts: newData.actualCosts,
          otherCosts: newData.otherCosts,
          rndImpact: newData.rndImpact,
          forEbit: isForEbit,
        } as ManufacturingCostDto,
        props.currentProject?.id
      );
    } catch (e) {
      console.log("onEditRow ", e);
    }
  }
  function onDeleteRow(row: ManufacturingCostRowValue) {
    console.log(row);
    props.onDeleteMC(row.project, props.currentProject?.id, isForEbit);
  }
  function onExport() {
    props.onDownloadHKTable(props.currentProject?.project_name, props.selectedProject ? props.currentProject?.id : null)
  }
  
  return (
    <Panel
      key={"inno_1"}
      {...props}
      header={
        <Row>
          <Col span={6} className="title-tab-hover">
            <Title level={5}>{t("Herstellkosten")}</Title>
          </Col>
          <Col span={6}>
            <input disabled value={`${t("EBIT-Potenzial")}:`} />
          </Col>
          <Col span={6}>
            <input
              style={{ width: '100%' }}
              disabled
              value={`${t("Absolut")} = ${formatValue(calculatePotentialSaving())} €`}
            />
          </Col>
          <Col span={6}>
            <input
              disabled
              value={`${t("Prozentual")} = ${formatValue(calculatePotentionalMcRate())} %`}
            />
          </Col>
          <Col span={24} className="paragraph-tab-hover">
            {" "}
            <Paragraph>{t("Herstellkosten hover")}</Paragraph>
          </Col>
        </Row>
      }
      className="cost-type-custom-panel rhino"
    >
      <section id="table-two-columns">
        <Row>
          <Col span={12}>
            <TableForPanel columns={columnsOne} rows={rowsOne} />
          </Col>
          <Col span={12}>
            <TableForPanel columns={columnsOne} rows={rowsTwo} />
          </Col>
        </Row>
      </section>
      <div className="cost-type-editable">
        <Form form={form} component={false} key="inno_form">
          <Table
            key={"inno_table"}
            bordered={false}
            dataSource={[newRowInno, ...dataRowInno]}
            columns={dataColInno}
            scroll={{ y: 500 }}
            rowClassName={() => "editable-row"}
            pagination={false}
          />
        </Form>
      </div>
    </Panel>
  );
}
const mapStateToProps = (state: IGeneralState, ownProps) => {
  return {
    ebitInfo: state.ebit,
    currentProject: ownProps.selectedProject ? ownProps.selectedProject :state.generalInfo.profileResponse?.currentProject,
    selectedProject: ownProps.selectedProject,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    onUpdateMCWithNewDproject: (model: ManufacturingCostWithDprojectDto) =>
      dispatch({ type: ActionTypes.CreateMCNewDproject, model: model }),
    onCreateMC: (model: ManufacturingCostDto, projectID: number) =>
      dispatch({
        type: ActionTypes.CreateMC,
        model: model,
        projectID: projectID
      }),
    onUpdateMC: (model: ManufacturingCostDto, projectID: number) =>
      dispatch({
        type: ActionTypes.UpdateMC,
        model: model,
        projectID: projectID
      }),
    onDeleteMC: (modelId: number, projectID: number, forEbit: boolean) =>
      dispatch({
        type: ActionTypes.DeleteMC,
        id: modelId,
        projectID: projectID,
        forEbit
      }),
    onDownloadHKTable: (projectName: string, projectID: number) => dispatch({ type: ActionTypes.DownloadHKTable,projectName, projectID}),
    onImportMCTable: (frmData: FormData, forEbit: boolean) => dispatch({ type: ActionTypes.ImportMCTable, frmData, forEbit }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HKPanel);
//