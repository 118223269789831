import { Button, Form, Input,InputNumber, Modal } from "antd";
import { RnDUnit } from "../../../Scenes/Tools/EBITTool-V2/Models/RnDUnit";
import { useTranslation } from "react-i18next";
import { inputFormatter, inputParser } from "../../../Utils/helperFunctions";
import i18n from "../../../i18n";
interface IProps {
  unit: RnDUnit;
  isModalVisible: boolean;
  handleOk: (unit: RnDUnit) => void;
  handleCancel: () => void;
  // handleChangeName: (event) => void;
  // handleChangeAvg: (event) => void;
  // handleChangeFte: (event) => void;
}
function EditModal(props: IProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const validateForm = async () => {
    try {
      form.submit();
      // const row2 = (await form.validateFields());
      // console.log(row2);
      // let newUnit: RnDUnit = props.unit;

      //props.createNewUnit(newUnit);
    } catch (e) {
      console.log("FormError: ", e);
    }
  }
  const afterValidate = async () => {
    try {
      const row2 = (await form.validateFields());
      //console.log(row2);
      let newUnit: RnDUnit = props.unit;
      newUnit.name = row2["edit_unit_name"];
      newUnit.averageAnnualCost = row2["edit_unit_avg"];
      newUnit.totalFullFimeEquivalent = row2["edit_unit_gesamt"];
      props.handleOk(newUnit);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <Modal
      getContainer={() => document.getElementById('app-layout')}
      className="EditModal"
      title= {t("Edit organizational unit")}//"Edit Unit"
      //open={props.isModalVisible} // always getting error : Property 'open' does not exist on type 'IntrinsicAttributes & ModalProps'.ts(2322)
      open={props.isModalVisible}
      onOk={validateForm}
      onCancel={props.handleCancel}
      footer={[
        <Button key="back" onClick={props.handleCancel} >
          {t("Cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={validateForm} >
          OK
        </Button>
      ]}
    >
      <Form form={form} onFinish={afterValidate} layout="vertical" >
        <Form.Item
          initialValue={props.unit.name}
          name={`edit_unit_name`}
          label={`Name`}
          rules={[
            {
              required: true,
              message: `${t("required*")}`,
            },
          ]}
        >
          <Input
            allowClear
          // value={formValues.header}
          // onChange={handleChangeHeader.bind(this)}
          />
        </Form.Item>
        <Form.Item
          initialValue={props.unit.totalFullFimeEquivalent}
          name={`edit_unit_gesamt`}
          label={t("Anzahl Mitarbeiter")}
          rules={[
            {
              required: true,
              message: `${t("required*")}`,
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value)) {
                  return Promise.reject("MA gesamt must be a number");
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          {/* <Input
            allowClear
          // value={formValues.fte}
          // onChange={handleChangeFte.bind(this)}
          /> */}
          <InputNumber
            style={{ width: "100%" }}
            formatter={value => inputFormatter(value, i18n.language)}
            parser={x => inputParser(x, i18n.language)}
          />
        </Form.Item>
        <Form.Item
          initialValue={props.unit.averageAnnualCost}
          name={`edit_unit_avg`}
          label={t("Durchschnittlicher Jahressatz")}
          rules={[
            {
              required: true,
              message: `${t("required*")}`,
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value)) {
                  return Promise.reject('Durchschn. Jahressatz must be a money');
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          {/* <Input
            allowClear
          // value={formValues.avg}
          // onChange={handleChangeAvg.bind(this)}
          /> */}
          <InputNumber
                        style={{ width: "100%" }}
                        formatter={value => inputFormatter(value, i18n.language)}
                        parser={x => inputParser(x, i18n.language)}
                    />
        </Form.Item>
        {/* <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
            >
              {`Anlegen`}
            </Button>
          )}
        </Form.Item> */}
      </Form>


      {/* <Form onFinish={props.handleOk} layout="vertical">
        <Form.Item>
          <label>Name</label>
          <Input
            allowClear
            value={props.unit.header}
            onChange={props.handleChangeName.bind(this)}
          />
          <label>MA gesamt</label>
          <Input
            allowClear
            value={props.unit.fte}
            onChange={props.handleChangeFte.bind(this)}
          />
          <label>Durchschn. Jahressatz</label>
          <Input
            allowClear
            value={props.unit.avg}
            onChange={props.handleChangeAvg.bind(this)}
          />
        </Form.Item>
      </Form> */}
      <hr />
    </Modal>
  );
}
export default EditModal;
