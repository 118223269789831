import { Col, Row, Table, Tag, Typography } from 'antd';
import React, { Fragment } from 'react'
import { ICompleteBmProjectDetails } from '../../../Models/IBMProjectItem';
import { t } from 'i18next';

interface IProps {
    details: ICompleteBmProjectDetails[];
}
const DetailsTable = (props: IProps) => {
    const columns: any[] = [

        {
            key: "projectResult_key",
            title:`${t("Project Result")}`,
            dataIndex: 'projectResult',
            render: (text: any, record: any) => {
                return <Typography>{text}</Typography>
            }
        },
        {
            key: "evaluationRate_key",
            title:`${t("Evaluation Rate")}`,
            dataIndex: 'evaluationRate',
            render: (text: any, record: any) => {
                return <Tag>{text}</Tag>
            }
        },
        {
            key: " projectImplementation_key",
            title:`${t("Project Implementation")}`,
            dataIndex: 'projectImplementation',
            render: (text: any, _: any) => {
                return <Typography>{text}</Typography>
            }
        },
        {
            key: "toolSupport_key",
            title:`${t("Tool Support")}`,
            dataIndex: 'toolSupport',
            render: (text: any, _: any) => {
                return <Typography>{text}</Typography>
            }
        },
        {
            key: "customerCooperation_key",
            title: `${t("Customer Cooperation")}`,
            dataIndex: 'customerCooperation',
            render: (text: any, _: any) => {
                return <Typography>{text}</Typography>
            }
        },
        {
            key: "lessonsLearned_key",
            title: `${t("Lessons Learned")}`,
            dataIndex: 'lessonsLearned',
            render: (text: any, _: any) => {
                return <Typography>{text}</Typography>
            }
        },
        {
            key: "bm_name_key",
            title:  `${t("Dynamic Fields")}`,
            dataIndex: 'dynamicFields',
            width: "20%",
            className: "bm_project_dynamicFields",
            render: (text: any, record: any) => {
                if (record?.dynamicFields) {
                    let FieldsJsx = record?.dynamicFields?.map((d, index) => <Fragment key={`field_${index}`}>
                        <Col span={12} style={{ borderRight: "1px solid #565656", borderBottom: "1px solid #565656" }}>{d?.fieldLabel}</Col>
                        <Col span={12} style={{ borderBottom: "1px solid #565656" }}>{d?.fieldValue}</Col></Fragment>)
                    return <Row>
                        <Col span={12} style={{ borderBottom: "1px solid #565656" }}><Typography.Text strong>{`${t("Key")}`}</Typography.Text></Col>
                        <Col span={12} style={{ borderBottom: "1px solid #565656" }}><Typography.Text strong>{`${t("Value")}`}</Typography.Text></Col>
                        {FieldsJsx}
                    </Row>
                } else {
                    return;
                }

            }
        },
        {
            key: "dataTime_key",
            title: `${t("Date Time")}`,
            dataIndex: 'dateTime',
            width: "15%",
            render: (text: any, record: any) => {
                return <Tag>{text}</Tag>
            }
        },
    ]
    return (
        <Table
            getPopupContainer={() => document.getElementById("app-layout")}
            key='bm_propjet_details_table'
            className='bm_propjet_details_table'
            bordered
            dataSource={props.details}
            columns={columns}
            rowClassName="editable-row"
            scroll={{ y: "calc(100vh - 300px)" }}
            pagination={{
                defaultPageSize: 50,
                pageSizeOptions: [50, 100, 150],
                total: props.details?.length,
                showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                showSizeChanger: true,
                locale:{items_per_page:`/ ${t("Page")}`},
            }}
            locale={{
                triggerDesc: t('Click to sort descending'),
                triggerAsc: t('Click to sort ascending'),
                cancelSort: t('Click to cancel sorting')
            }}
        />
    )
}

export default DetailsTable