import { takeEvery, all, put } from 'redux-saga/effects';
import { ActionTypes } from '../actionsTypes';
import ProfileService from '../../Services/ProfileService';
import { ICommonResponse } from '../../Models/Responses/ICommonResponse';
import { BmProject, BmProjectForEditEdits, BmProjectForEditLevers, GeneralBmProjectDto, ProfileResponse } from '../../Models/Responses/ProfileResponse';
import { ToastMessages } from '../../Enums/Messages';
import Tree from '../../DataStructure/tree';
import { BasicRole } from '../../Enums/BasicRoles';
import BmProjectService from '../../Services/BmProjectsService';
import { ILever } from '../../Models/Levers/Items';
import { ISALever, ISelfAssessmentView } from '../../Models/SelfAssessment/ISelfAssessmentView';
import { IResponseDProject } from '../../Models/IResponseDProject';
import { IDProject } from '../../Models/IDProject';
import DProjectsServices from '../../Services/DProjectsService';
import { LanguagesEnum } from '../../Enums/LanguagesEnum';
function* onGetHomePage(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result: ICommonResponse = yield ProfileService.GetHomePage();

        if (!_result.isError) {
            let result = _result.result as ProfileResponse;
            localStorage.setItem("localLang", result?.languageId == LanguagesEnum.de ? 'de' : 'en');
            yield put({ type: ActionTypes.SaveHomePage, result: result });
            sessionStorage.setItem("bm_project", result?.currentProject?.id?.toString());
            if (result?.currentProject?.leversData) {
                var levers = result.currentProject?.leversData as ILever[];
                levers = levers.sort((a, b) => a.id < b.id ? -1 : 1);
                yield put({ type: ActionTypes.SaveGetLeversResults, result: levers });
            }
            if (result?.currentProject?.dprojects) {
                let dProjectsResult = result.currentProject.dprojects as IResponseDProject[];
                let dprojects = dProjectsResult.map(newDproject => {
                    let dproject: IDProject = {
                        ...newDproject.generalDprojectInfo,
                        ...newDproject.innovation,
                        ...newDproject.costOfDelay,
                        ...newDproject.manufacturingCost,
                        ...newDproject.qualityCost,
                        ...newDproject.questionaire
                    }
                    return dproject;
                });

                yield put({ type: ActionTypes.SAVEDPROJECTS, dprojects: dprojects });
            }

            if (result?.currentProject?.selfAssessmenets) {
                var salevers = result.currentProject?.selfAssessmenets as ISelfAssessmentView;
                yield put({ type: ActionTypes.SetSelfAssessmentView, result: salevers });
            }
            if (action.applyRedirect) {
                let goToPath = "/start";
                if (result?.roles?.includes(BasicRole.Consultant) || result?.roles?.includes(BasicRole.SuperAdmin)) {
                    goToPath = "/start"
                }
                else if (result?.roles?.includes(BasicRole.Admin)) {
                    goToPath = "/admin"
                }
                else if (result?.roles?.includes(BasicRole.Customer)) {
                    goToPath = "/projects"
                }
                yield put({ type: ActionTypes.ForceToPath, model: goToPath });
            }
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    }
    catch (error) {
        console.error('ERR in onGetHomePage: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}


function* onUpdateBmProjectExtraInfo(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result: ICommonResponse = yield BmProjectService.UpdateBmProjectExtraInfo(action.model)

        yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    }
    catch (error) {
        console.error('ERR in onGetHomePage: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}


function* onChangeCurrentProject(action: any) {
    yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
    try {
        let _result: ICommonResponse = yield BmProjectService.ChangeCurrentBmProject(action.currentProjectId);
        if (!_result.isError) {            
            localStorage.removeItem("selectedQuestion");
            localStorage.removeItem("leverAllQuestionsSelected");                
            localStorage.removeItem("fronloading_tab");   
            localStorage.removeItem("frontloading_id");
            localStorage.removeItem("ebit_active_tab");   
            localStorage.removeItem("ebit_active_tab"); 
            if (action.redirectToPath) {
                window.location.replace(action.redirectToPath );
            }
            else {
                window.location.replace("/dashboard");
            }
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }

        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    } catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onSaveLeversPeers(action: any) {
    console.log('onSaveLeversPeers action: ', action);

    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        console.log("onSaveLeversPeers", action.isLevers, action.ks, action.xs);
        let _result: ICommonResponse = yield BmProjectService.UpdateCurrentProjectPeers(action.isLevers, action.ks, action.xs);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            //go to dashboard and reload 
            //yield put({ type: ActionTypes.ForceToPath, model: "/dashboard" });           
            window.location.replace("/dashboard");
            //yield put({ type: ActionTypes.GetHomePage, result: _result, applyRedirect: true });
            //yield put({ type: ActionTypes.ForceToPath, model: "/dashboard" });
        }
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    } catch (error) {
        console.error("onSaveLeversPeers Err: ", error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onGetBmProjectListShortInfo(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result2: ICommonResponse = yield ProfileService.GetProjects();
        if (!_result2.isError) {
            let result2 = _result2.result as GeneralBmProjectDto[];
            yield put({ type: ActionTypes.SaveAllProjects, projects: result2 });
            yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
        }
    }
    catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onGetProjectsForEditLevers(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result2: ICommonResponse = yield ProfileService.GetProjectsForEditLevers();
        if (!_result2.isError) {
            let result2 = _result2.result as BmProjectForEditLevers[];
            yield put({ type: ActionTypes.SetProjectsForEditLevers, projects: result2 });
            yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
        }
    }
    catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onGetProjectsForEditEbit(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result2: ICommonResponse = yield ProfileService.GetProjectsForEditEbit();
        if (!_result2.isError) {
            let result2 = _result2.result as BmProjectForEditEdits[];
            yield put({ type: ActionTypes.SetProjectsForEditEbit, projects: result2 });
            yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
        }
    }
    catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}


function* GetBenchmarkProjectsWithAllData(action: any) {
    try {
        console.log("GetBenchmarkProjectsWithAllData");
        yield put({ type: ActionTypes.BenchmarkProjectsLoader, result: true });
        let _result2: ICommonResponse = yield ProfileService.GetBenchmarkProjectsWithAllData();
        if (!_result2.isError) {
            let result2 = _result2.result as BmProject[];
            yield put({ type: ActionTypes.SaveAllBenchmarkProjects, projects: result2 });
            yield put({ type: ActionTypes.BenchmarkProjectsLoader, result: false });
        }
    }
    catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onForceToPath(action: any) {
    yield put({ type: ActionTypes.SetForceToPath, result: action.model });
}

function* AddDprojectLocally(action: any) {
    if ((action._result as ICommonResponse).isError) {
        yield put({ type: ActionTypes.SetCommonResponse, result: action._result as ICommonResponse });
    }else{
        yield put({ type: ActionTypes.SAVEDPROJECTS, dprojects: action.newDprojects });
    }    
}


function* onChangeFullScreen(action: any) {
    console.log('onChangeFullScreen ');

    yield put({ type: ActionTypes.SetChangeFullScreen, state: action.state });
}
function* onOpenAccessModal(action: any) {
    console.log("onOpenAccessModal onOpenAccessModal");
    yield put({ type: ActionTypes.SetPasswordRequiredModal, isPasswordRequired: true, hasAccessTokenToProjects: false });
}
function* onCloseAccessModal(action: any) {
    console.log("onCloseAccessModal onCloseAccessModal");
    yield put({ type: ActionTypes.SetPasswordRequiredModal, isPasswordRequired: false, hasAccessTokenToProjects: false });
}

function* onUpdateLeversInCurrentProject(action: any) {
    yield put({ type: ActionTypes.ChageLeversInCurrentProject, result: action.result });
}


function* watchUpdateBmProjectExtraInfo() {
    yield takeEvery(ActionTypes.UpdateBmProjectExtraInfo, onUpdateBmProjectExtraInfo)
}

function* watchGetHomePage() {
    yield takeEvery(ActionTypes.GetHomePage, onGetHomePage)
}
function* watchChangeFullScreen() {
    yield takeEvery(ActionTypes.ChangeFullScreen, onChangeFullScreen)
}

function* watchAddDprojectLocally() {
    yield takeEvery(ActionTypes.AddDprojectLocally, AddDprojectLocally)
}

function* watchGetProjects() {
    yield takeEvery(ActionTypes.GETPROJECTS, onGetBmProjectListShortInfo);
}

function* watchGetBenchmarkProjectsWithAllData() {
    yield takeEvery(ActionTypes.GetAllBenchmarkProjects, GetBenchmarkProjectsWithAllData);
}

function* watchForceToPath() {
    yield takeEvery(ActionTypes.ForceToPath, onForceToPath)
}
function* watchChangeCurrentProject() {
    yield takeEvery(ActionTypes.CHANGECURRENTPROJECTREMOTLY, onChangeCurrentProject);
}
function* watchSaveLeversPeers() {
    yield takeEvery(ActionTypes.UpdatePeers, onSaveLeversPeers);
}

function* watchGetProjectsForEditLevers() {
    yield takeEvery(ActionTypes.GetProjectsForEditLevers, onGetProjectsForEditLevers);
}
function* watchGetProjectsForEditEbit() {
    yield takeEvery(ActionTypes.GetProjectsForEditEbit, onGetProjectsForEditEbit);
}

function* watchOpenAccessModal() {
    yield takeEvery(ActionTypes.OpenAccessModal, onOpenAccessModal);
}
function* watchCloseAccessModal() {
    yield takeEvery(ActionTypes.CloseAccessModal, onCloseAccessModal);
}

function* watchUpdateLeversInCurrentProject() {
    yield takeEvery(ActionTypes.UpdateLeversInCurrentProject, onUpdateLeversInCurrentProject);
}

export default function* GeneralSagas() {
    yield all([
        watchGetHomePage(),
        watchChangeFullScreen(),
        watchAddDprojectLocally(),
        watchForceToPath(),
        watchChangeCurrentProject(),
        watchSaveLeversPeers(),
        watchGetProjects(),
        watchGetBenchmarkProjectsWithAllData(),
        watchGetProjectsForEditLevers(),
        watchGetProjectsForEditEbit(),
        watchOpenAccessModal(),
        watchCloseAccessModal(),
        watchUpdateLeversInCurrentProject(),
        watchUpdateBmProjectExtraInfo()
    ]);
}