import { Form, Input, InputNumber, Spin, Typography } from 'antd';
import { IActivityGroupTemplate } from '../../../../Models/IActivityManagement';
import ActivityGroupAddModal from '../ActivityMngModal/ActivityGroupAddModal'
import { useRef, useState } from 'react';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import AdminOperation from '../../../../Components/AdminOperation/AdminOperation';
import { RulesName, RulesRequire } from '../../Rules/RulesValidation';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { t } from 'i18next';
import AdminTable from '../../../../Components/AdminTable/AdminTable';
import PopconfirmAsync from '../../../../Components/PopconfirmAsync';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { formatValue, inputFormatter, inputParser } from '../../../../Utils/helperFunctions';
import i18n from '../../../../i18n';
import { IGeneralState } from '../../../../Redux/reducers/IGeneralState';

interface IProps {
    isLoading: boolean;
    activityProfileID: number;
    groups: IActivityGroupTemplate[];
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    updateActivityGroup: (model: IActivityGroupTemplate) => any;
    changeStatusActivityGroup: (groupId: number, isActive: boolean) => any;
}
const ActivityProfileGroups = (props: IProps) => {
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState(null);

    const columns: any[] = [{
        key: "serialNumber_key",
        title: `${t("Serial Number")}`,
        dataIndex: 'serialNumber',
        width: '10%',
        render: (text: any, record: any) => {
            if (editingKey === record.id) {
                return (
                    <Form.Item name='serialNumber'
                        rules={RulesRequire({ filedName: `${t("Serial Number")}` })}
                    >
                        <InputNumber
                            min={1}
                        />
                    </Form.Item>
                )
            } else {
                return <Typography.Text>{text}</Typography.Text>
            }
        },
        //sorter: (a, b) => (a?.serialNumber?.toString())?.localeCompare(b?.serialNumber?.toString()) ?? 0 ,
        defaultSortOrder: 'asc',

    },
    {
        key: "group_nameEn_1",
        title: `${t("English Name")}`,
        dataIndex: 'nameEn',
        width: "20%",
        render: (text: any, record: any) => {
            if (editingKey === record.id) {
                return (
                    <Form.Item name='nameEn'
                        rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
                    >
                        <Input />
                    </Form.Item>
                )
            } else {
                return <Typography.Text>{text}</Typography.Text>
            }
        },
        ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),
        //sorter: (a, b) => a?.nameEn?.localeCompare(b?.nameEn) ?? 0,

    },
    {
        key: "group_nameDe_2",
        title: `${t("German Name")}`,
        dataIndex: 'nameDe',
        width: "20%",
        render: (text: any, record: any) => {
            if (editingKey === record.id) {
                return (
                    <Form.Item name='nameDe'
                        rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
                    >
                        <Input />
                    </Form.Item>
                )
            } else {
                return <Typography.Text>{text}</Typography.Text>
            }
        },
        ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),
        //sorter: (a, b) => a.nameDe.localeCompare(b.nameDe) ?? 0 ,

    },

    {
        key: "bestPractice_2",
        title: `${t("Best Practice")}`,
        dataIndex: 'bestPracticeValue',
        width: "20%",
        render: (text: any, record: any) => {
            if (editingKey === record?.id) {
                return (
                    <Form.Item name='bestPracticeValue'
                        rules={RulesRequire({ filedName: `${t("Best Practice")}` })}
                        hasFeedback
                    >
                        <InputNumber
                            max={100} formatter={(value) => inputFormatter(value, i18n.language)}
                            parser={(x) => inputParser(x, i18n.language)}
                            addonAfter="%"
                        />
                    </Form.Item>
                )
            } else {
                return <Typography.Text>{`${formatValue(text)}%`}</Typography.Text>
            }
        },
        ...getColumnSearchProps(`${t("Best Practice")}`, 'bestPracticeValue', searchInput, handleSearch),

    },
    {
        key: "status_1",
        title: `${t("Status")}`,
        dataIndex: 'isActive',
        width: "15%",
        ...getColumnSearchProps(`${t("Status")}`, 'isActive', searchInput, handleSearch),
        render: (_: any, record: any) => {
            const activeStatus = record.isActive;
            return activeStatus ? (
                <PopconfirmAsync
                    title={`${t("Sure to deactivate?")}`}
                    handleOk={() => changeStatusActivityGroup(record.id, !record.isActive)}
                    isModalLoading={props.isModalLoading}
                    modalResponse={props.modalResponse}
                    deactivateUser={true}
                    iconPower
                />
            ) : (
                <PopconfirmAsync
                    title={`${t("Sure To Activate?")}`}
                    handleOk={() => changeStatusActivityGroup(record.id, !record.isActive)}
                    isModalLoading={props.isModalLoading}
                    modalResponse={props.modalResponse}
                    activeUser={true}
                    iconPower
                />
            )
        }
    },
    {
        title: `${t("Actions")}`,
        dataIndex: 'Actions',
        width: "15%",
        render: (_: any, record: IActivityGroupTemplate) => {
            const editable = isEditing(record);
            return <AdminOperation
                record={record}
                editable={editable}
                onCancel={cancel}
                onEdit={edit}
            />
        },
    },

    ]
    const isEditing = (record: IActivityGroupTemplate) => record.id === editingKey;
    const edit = (record: Partial<IActivityGroupTemplate>) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: IActivityGroupTemplate) => {
        try {
            await form.validateFields()
            const newData = [...props.groups];
            const index = newData.findIndex(item => item.id === editingKey);
            if (index > -1) {
                const item = newData[index];
                const activityGroupUpdate: IActivityGroupTemplate = {
                    id: item?.id,
                    serialNumber: values?.serialNumber,
                    activityProfileId: props?.activityProfileID,
                    bestPracticeValue: values?.bestPracticeValue,
                    nameDe: values?.nameDe,
                    nameEn: values?.nameEn
                }
                props.updateActivityGroup(activityGroupUpdate);
                setEditingKey(null);
            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }
    const changeStatusActivityGroup = async (groupId: number, isActive: boolean) => {
        try {
            await props.changeStatusActivityGroup(groupId, isActive);
        } catch (errInfo) {
            console.log('change status Failed:', errInfo);
        }
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <ActivityGroupAddModal activityProfileID={props.activityProfileID} />
                <Form form={form} key="activity_profile_form" onFinish={onFinish}>
                    <AdminTable
                        keyTable='activity_groups_table'
                        dataSource={props.groups}
                        columns={columns}
                        className='bm_propjet_details_table' />
                </Form>
            </div>
        </Spin>
    )
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.activiyProfileMng.isActivityForAdminLoading,
        isModalLoading: state.activiyProfileMng.isModalLoading,
        modalResponse: state.activiyProfileMng.modalResponse,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateActivityGroup: (model: IActivityGroupTemplate) => dispatch({ type: ActionTypes.UPDATE_ACTIVITY_GROUP_FOR_ADMIN, model }),
        changeStatusActivityGroup: (groupId: number, isActive: boolean) => dispatch({ type: ActionTypes.CHANGE_STATUS_ACTIVITY_GROUP, groupId, isActive })

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityProfileGroups);
