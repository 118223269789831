
import { Collapse, Form, Slider, InputNumber, Typography, Row, Col, Input, Select, Popconfirm, Space, Tooltip, Table, Segmented, Checkbox } from 'antd';
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  StopOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  StopTwoTone,
} from "@ant-design/icons";
import TableTwoColumns from '../../../../../Components/TableTwoColumns';
import { InnovationCostRowValue } from '../../../../../Models/EBIT/CostTypes';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IEbitToolState, IGeneralState } from '../../../../../Redux/reducers/IGeneralState';
import { ActionTypes } from '../../../../../Redux/actionsTypes';
import { InnovationCostDto, InnovationCostWithDprojectDto } from '../../Models/CostTypesResponse';
import { BmProject } from '../../../../../Models/Responses/ProfileResponse';
import exportIcon from "../../../../../assets/Images/export-xlsx-icon.svg";
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { inputFormatter, inputParser, formatValue } from '../../../../../Utils/helperFunctions';
import i18n from '../../../../../i18n';
import { ca } from 'date-fns/locale';
import TableForPanel from './TableForPanel';
import UploadFile from '../UploadFilesComponent';
const { Panel } = Collapse;
const { Title, Paragraph } = Typography;
const { Option } = Select;

interface InnoProps {
  ebitInfo: IEbitToolState,
  selectedProject?: BmProject,
  currentProject: BmProject,
  onUpdateInnoWithNewDproject: (model: InnovationCostWithDprojectDto) => any;
  onCreateInno: (model: InnovationCostDto, projectID: number) => any;
  onUpdateInno: (model: InnovationCostDto, projectID: number) => any;
  onDeleteInno: (modelId: number, projectID: number, forEbit: boolean) => any;
  onDownloadInnoTable: (projectName: string, projectID: number) => any;
  onImportInnoTable: (frmData: FormData, forEbit: boolean) => any;
}
function InnoPanel(props: InnoProps) {
  const newRowInno: InnovationCostRowValue = {
    key: "newInno",
    project: null,
    projectyear: 0,
    marginPercentage: 0,
    salesLastYear: 0,
    productType: null,
    newProduct: ""
  };

  const { t } = useTranslation();
  const [editRecord, setEditRecord] = useState(newRowInno as InnovationCostRowValue);
  const [form] = Form.useForm();
  const [addFromList, setAddFromList] = useState(true);
  const isForEbit = props.selectedProject ? false : true;
  const salesLastYear = Form.useWatch(`salesLastYear_${editRecord?.project}`, form);
  const marginPercentage = Form.useWatch(`marginPercentage_${editRecord?.project}`, form);

  const projectId = Form.useWatch(`project_${editRecord?.project}`, form);


  useEffect(() => {
    if (props.ebitInfo?.dProjectForEbit) {
      form.setFieldsValue(fillInForm(newRowInno));
      setEditRecord(newRowInno);
    }
  }, [props.ebitInfo]);
  let DProjectsList = props.selectedProject ? props.ebitInfo?.dProjectForSelectedProject : props.ebitInfo?.dProjectForEbit;
  const options = DProjectsList?.filter(c => !c.innovationCost
    && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
      && c.dproject.completionYear <= props.currentProject?.yearUnderReview)).map(dp => {
        return {
          value: dp.dproject.id,
          label: dp.dproject.name
        };
      });
  const innovationSartOptions = [{ value: 1, label: `${t("Portfolio-Erweiterung")}` }, { value: 2, label: `Substitution` }];
  const columnsOne = [
    {
      title: '',
      dataIndex: 'left',
      key: '1',
      width: '25%',
    },
    {
      title: '',
      dataIndex: 'right',
      key: '2',
      width: '75%',
    }
  ]

  //1
  function calculateEbitSubstituations() {
    let sum = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview)
      && c.innovationCost.productTypeId == 2)?.reduce((total, next) => { return total + (next.innovationCost.salesLastYear * next.innovationCost.marginPercentage / 100) }, 0) ?? 0;
    return sum;
  }
  //2
  function calculateEbitPortfolioExtensions() {
    let sum = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview)
      && c.innovationCost.productTypeId == 1)?.reduce((total, next) => { return total + (next.innovationCost.salesLastYear * next.innovationCost.marginPercentage / 100) }, 0) ?? 0;
    return sum;
  }
  //3
  function calculateEbitInno() {
    let sum = calculateEbitSubstituations() + calculateEbitPortfolioExtensions();
    return sum;
  }
  //4
  function calculationEbitNorm() {
    let rv = props.currentProject?.ebitTodayCost - calculateEbitInno();
    return rv;
  }
  //5
  function calculationEbitSubstitutionRate() {
    let totalSalesLastYearOfSubstitutionProduct = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview)
      && c.innovationCost.productTypeId == 2)?.reduce((total, next) => { return total + next.innovationCost.salesLastYear }, 0) ?? 0;
    let rv = totalSalesLastYearOfSubstitutionProduct !== 0 ? calculateEbitSubstituations() / totalSalesLastYearOfSubstitutionProduct : 0;
    return rv * 100;
  }
  //6
  function calculationPortfolioExtensionsRate() {
    let totalSalesLastYearOfSubstitutionProduct = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview)
      && c.innovationCost.productTypeId == 1)?.reduce((total, next) => { return total + next.innovationCost.salesLastYear }, 0) ?? 0;
    let rv = totalSalesLastYearOfSubstitutionProduct !== 0 ? calculateEbitPortfolioExtensions() / totalSalesLastYearOfSubstitutionProduct : 0;
    return rv * 100;
  }
  //7
  function calculationEbitInnoRate() {
    let totalInnoSales = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview)
    )?.reduce((total, next) => { return total + (next.innovationCost.salesLastYear) }, 0) ?? 0;
    let rv = totalInnoSales != 0 ? (calculateEbitInno() / totalInnoSales) : 0;
    return rv * 100;
  }
  //8
  function calculateEbitNormRate() {
    let totalInnoSales = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview))?.reduce((total, next) => { return total + (next.innovationCost.salesLastYear) }, 0) ?? 0;
    let rv = calculationEbitNorm() / (props.currentProject?.sales - totalInnoSales);
    return rv * 100;
  }
  //9
  function calculateInnovationRate() {
    let totalInnoSales = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview))?.reduce((total, next) => { return total + (next.innovationCost.salesLastYear) }, 0) ?? 0;
    let rv = props.currentProject?.sales !== 0 ? totalInnoSales / props.currentProject?.sales : 0;
    return rv * 100;
  }
  //10
  function getInnovationRateFromBmProject() {
    return props.currentProject?.innovationRateHard * 100;
  }
  //11
  function calculateSalesNormNew() {
    let totalInnoSales = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview))?.reduce((total, next) => { return total + (next.innovationCost.salesLastYear) }, 0) ?? 0;
    let rv = props.currentProject?.sales - totalInnoSales;
    return rv;
  }
  //12
  function calculateSalesInnoNew() {
    let totalInnoSales = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview)
    )?.reduce((total, next) => { return total + (next.innovationCost.salesLastYear) }, 0) ?? 0;
    let rv = ((props.currentProject?.sales - totalInnoSales) / (1 - (getInnovationRateFromBmProject() / 100))) - (props.currentProject?.sales - totalInnoSales);
    return rv;
  }
  //13
  function calculateEbitPotentialInnoCost() {
    let totalInnoSales = DProjectsList?.filter(c => c.innovationCost
      && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
        && c.dproject.completionYear <= props.currentProject?.yearUnderReview)
    )?.reduce((total, next) => { return total + (next.innovationCost.salesLastYear) }, 0) ?? 0;
    console.log("calculateEbitPotentialInnoCost");
    console.log("totalInnoSales", totalInnoSales);
    let sales = props.currentProject?.sales
    let r1 = (sales - totalInnoSales);
    console.log("r1", r1);
    let r2 = (1 - (getInnovationRateFromBmProject() / 100));
    console.log("r2", r2);
    let r3 = calculationEbitInnoRate() / 100;
    console.log("r3", r3);
    let r4 = calculateEbitInno();
    console.log("r4", r4);
    //let rv = ((r1 / r2) - (r1 * r3) - r4);
    let rv = ((((r1 / r2) - r1) * r3) - r4);
    return rv >= 0 ? rv : 0;
  }
  //14
  function calculateEbitPotentialInnRate() {

    let rv = ((calculationEbitNorm() + (calculateSalesInnoNew() * calculationEbitInnoRate() / 100)) / (calculateSalesNormNew() + calculateSalesInnoNew())) - (props.currentProject?.ebitTodayPercentage / 100);

    return rv >= 0 ? rv * 100 : 0;
  }

  const heute = (
    <>
      {t("EBIT-Potenzial")}
      <sub>Inno</sub> /
      {t("Umsatz")}
      <sub>{t("new")}</sub>
    </>
  );

  const rowsOne = [
    {
      key: 14,
      left: (
        <Tooltip placement="bottomRight" title={heute}>
          {t("EBIT-Potenzial")}
          <sub>{`Inno `}</sub>
          {`[%]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateEbitPotentialInnRate())} %`,
    },
    {
      key: 12,
      left: (
        <Tooltip placement="bottomRight" title={t("Inno-Umsatz bei Erreichen des BP-Werts")}>
          {t("Umsatz")} <sub>{`Inno, ${t("new")} `}</sub> {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateSalesInnoNew())} €`,
    },
    {
      key: 10,
      left: (
        <Tooltip placement="bottomRight" title={t("Segmentspezifische BP-Rate")}>
          {t("Innovationsrate")} <sub>{`BP`}</sub>
        </Tooltip>
      ),
      right: `${formatValue(getInnovationRateFromBmProject())} %`,
    },
    {
      key: 3,
      left: (
        <Tooltip placement="bottomRight" title={t("EBIT durch innovative Produkte")}>
          {`EBIT`} <sub>{`Inno`}</sub> {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateEbitInno())} €`,
    },
    {
      key: 4,
      left: (
        <Tooltip placement="bottomRight" title={t("EBIT durch nicht-innovative Produkte")}>
          {`EBIT`} <sub>{`norm`}</sub> {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculationEbitNorm())} €`,
    },
    {
      key: 2,
      left: (
        <p>
          {`Ebit`} <sub>{t("Portfolio-Erweiterung")}</sub> {`[€]`}
          <br />
        </p>
      ),
      right: `${formatValue(calculateEbitPortfolioExtensions())} €`,
    },
    {
      key: 1,
      left: (
        <p>
          {`Ebit`} <sub>{t("Substitution")}</sub> {`[€]`}
          <br />
        </p>
      ),
      right: `${formatValue(calculateEbitSubstituations())} €`,
    },
  ];
  const heute1 = (
    <>
      <small>{t("Umsatz")}<sub>{`Inno`}</sub></small>
      <small>{'/'}</small>
      <small>{t("Umsatz")}<sub>{`n`}</sub></small>
    </>
  );
  const rowsTwo = [
    {
      key: 13,
      left: (
        <Tooltip placement="bottomRight" title={t("Zus. EBIT bei neuen Inno- und EBIT-Raten")}>
          {t("EBIT-Potenzial")} {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateEbitPotentialInnoCost())} €`
    },
    {
      key: 11,
      left: (
        <Tooltip placement="bottomRight" title={t("Norm-Umsatz bei Erreichen des BM")}>
          {t("Umsatz")} <sub>{`norm, ${t("new")}`}</sub> {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateSalesNormNew())} €`
    },
    {
      key: 9,
      left: (
        <Tooltip placement="bottomRight" title={heute1}>
          {t("Innovationsrate")} <sub>{("IST")}</sub> {`[%]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateInnovationRate())} %`
    },
    {
      key: 7,
      left: (
        <Tooltip placement="bottomRight" title={t("EBIT-Rate durch Innovationsprodukte")}>
          {`EBIT`}<sub>{`inno`}</sub> {`[%]`}
        </Tooltip>
      ),
      right: `${formatValue(calculationEbitInnoRate())} %`
    },
    {
      key: 8,
      left: (
        <Tooltip placement="bottomRight" title={t("EBIT-Rate durch nicht-Inno Produkte")}>
          {`EBIT`}<sub>{`norm`}</sub> {`[%]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateEbitNormRate())} %`
    },
    {
      key: 6,
      left: (
        <p>
          {`Ebit`} <sub>{t("Portfolio-Erweiterung")}</sub> {`[%]`}
          <br />
        </p>
      ),
      right: `${formatValue(calculationPortfolioExtensionsRate())} %`
    },
    {
      key: 5,
      left: (
        <p>
          {`Ebit`} <sub>{t("Substitution")}</sub> {`[%]`}
          <br />
        </p>
      ),
      right: `${formatValue(calculationEbitSubstitutionRate())} %`
    }
  ]

  const dataColInno: ColumnsType = [
    {
      title: <><label>{t("Produkte")}</label><br></br><sub>{t("Markteintritt (n-2) – (n)")}</sub></>,
      dataIndex: 'product',
      fixed: 'left', // fix element
      render: (text: any, record: InnovationCostRowValue, index) => {
        //console.log("render", text, record, index);
        if (index == 0) {
          if ((editRecord?.key != "newInno")) {
            return <Select
              dropdownMatchSelectWidth={false}
              disabled
              showSearch
              placeholder={t("Auswahl")}
              options={options}
              style={{ textOverflow: 'ellipsis', width: '100%', fontSize: '12px' }}
            />;
          }
          return <Row>
            <Col span={2}><Checkbox checked={addFromList} onChange={
              (e) => {
                form.setFieldsValue(fillInForm(newRowInno));
                setEditRecord(newRowInno);
                setAddFromList(e.target.checked);
                console.log("form fields", form.getFieldsValue());
              }
            }
            /></Col>
            <Col span={1}></Col>
            <Col span={21}>
              <Form.Item
                hidden={!addFromList}
                name={`project_${record?.project}`}
                style={{ margin: 0 }}
                rules={(!addFromList) ? [] : [
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <Select
                  dropdownMatchSelectWidth={false}
                  optionFilterProp="label"
                  getPopupContainer={() =>
                    isForEbit ? document.getElementById("app-layout")
                      : document.getElementsByClassName("ant-modal-root").item(0) as HTMLElement
                  }
                  showSearch
                  placeholder={t("Auswahl")}
                  options={options}
                  style={{ textOverflow: 'ellipsis', fontSize: '12px', width: '100%'}} />

                {/* */}
              </Form.Item>


              <Form.Item
                hidden={addFromList}
                name={`projectname_${record?.project}`}
                style={{ margin: 0 }}
                rules={(addFromList) ? [] : [
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <Input placeholder={t("Neues Entwciklungsprojekt")} />
              </Form.Item>
            </Col>
          </Row>;
        } else {
          return DProjectsList.find(c => c.dproject.id == record?.project)?.dproject.name ?? "";
        }
      }, // keep this if you need to render link with data cell.    
    },
    {
      title: <><label>{t("Projekt- Abschlussjahr ")}</label><br></br><sub>{`n-2, n-1, n `}</sub></>,
      width: '10%',
      dataIndex: 'projectyear',
      defaultSortOrder: 'descend',
      sorter: (a: InnovationCostRowValue, b: InnovationCostRowValue) => {
        if (a.projectyear == -1 || b.projectyear == -1 ||
          a.projectyear == 0 || b.projectyear == 0)
          return 0;
        else return a.projectyear - b.projectyear;
      },
      render: (text: any, record: InnovationCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newInno") {
            return <InputNumber disabled />
          }
          if (addFromList) {
            return DProjectsList?.find(c => c.dproject.id == projectId)?.dproject.completionYear;
          } else {
            return <Form.Item
              name={`ProjektAbschlussjahr_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                maxLength={4}
                style={{ width: "10ch" }}
                min={1970}
              />
            </Form.Item>;
          }
        } else {
          return record?.projectyear;
        }
      }
    },
    {
      title: <><label>{t("Umsatz")}</label><br></br><sub>{`in n `}</sub></>,
      width: '15%',
      dataIndex: 'salesLastYear',
      render: (text: any, record: InnovationCostRowValue, index) => {
        if (index == 0) {
          if ((editRecord?.key != "newInno")) {
            return <InputNumber disabled />
          }
          return <Form.Item
            name={`salesLastYear_${record?.project}`}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${t("required*")}`,
              },
            ]}
          >
            <InputNumber
              addonAfter={'€'}
              min={0}
              style={{ width: "20ch" }}
              formatter={value => inputFormatter(value, i18n.language)}
              parser={x => inputParser(x, i18n.language)}
            />
          </Form.Item>;
        } else {
          if (editRecord?.key == record?.key) {
            return <Form.Item
              name={`salesLastYear_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={'€'}
                min={0}
                style={{ width: "20ch" }}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
              />
            </Form.Item>;
          } else {
            return formatValue(record?.salesLastYear) + '€';
          }
        }
      }
    },
    {
      title: <><label>{t("Produkt-EBIT")}</label><br></br><sub>{`in %`}</sub></>,
      width: '12%',
      dataIndex: 'marginPercentage',
      render: (text: any, record: InnovationCostRowValue, index) => {
        if (index == 0) {
          if ((editRecord?.key != "newInno")) {
            return <InputNumber disabled />
          }
          return <Form.Item
            name={`marginPercentage_${record?.project}`}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${t("required*")}`,
              },
            ]}
          >
            <InputNumber
              addonAfter={'%'} 
              min={0}
              max={100}
              style={{ width: "12ch" }}
              formatter={value => inputFormatter(value, i18n.language)}
              parser={x => inputParser(x, i18n.language)}
            />
          </Form.Item>;
        } else {
          if (editRecord?.key == record?.key) {
            return <Form.Item
              name={`marginPercentage_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={'%'} 
                min={0}
                max={100}
                style={{ width: "12ch" }}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
              />
            </Form.Item>;
          } else {
            return formatValue(record?.marginPercentage) + '%';
          }
        }
      }
    },
    {
      title: <><label>{t("Innovationsart")}</label></>,
      width: '13%',
      dataIndex: 'productType',
      render: (text: any, record: InnovationCostRowValue, index) => {
        if (index == 0) {
          if ((editRecord?.key != "newInno")) {
            return <Select

              disabled
              dropdownMatchSelectWidth={false}
              showSearch
              placeholder="Select..."
              style={{ textOverflow: 'ellipsis', width: '100%', fontSize: '12px' }} />;

            // </Select>;
          }
          return <Form.Item
            name={`productType_${record?.project}`}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${t("required*")}`,
              },
            ]}
          >
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              placeholder="Select..."
              style={{ textOverflow: 'ellipsis', fontSize: '12px', width: '100px', zIndex: 5 }}
              options={innovationSartOptions}
            />
          </Form.Item>;
        } else {
          if (editRecord?.key == record?.key) {
            return <Form.Item
              name={`productType_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                placeholder="Select..."
                style={{ textOverflow: 'ellipsis', width: '100%', fontSize: '12px' }}
                options={innovationSartOptions}
              />
            </Form.Item>;
          } else {
            if (record?.productType == 1) return t("Portfolio-Erweiterung");
            else return "Substitution";
          }
        }
      }
    },
    {
      title: <><label>{t("Differenz zum EBIT")}</label><br></br><sub>{`in %`}</sub></>,
      width: '12%',
      dataIndex: 'differenzebit',
      render: (text: any, record: InnovationCostRowValue, index) => {
        if (index == 0) {
          if ((editRecord?.key != "newInno")) {
            return `${formatValue(record?.marginPercentage - props.currentProject?.ebitTodayPercentage)} %`;
          } else {
            return `${formatValue(marginPercentage - props.currentProject?.ebitTodayPercentage)} %`;
          }
        } else {
          if (editRecord?.key == record?.key) {
            return `${formatValue(marginPercentage - props.currentProject?.ebitTodayPercentage)} %`;
          } else {
            return `${formatValue(record?.marginPercentage - props.currentProject?.ebitTodayPercentage)} %`;
          }
        }
      }
    },
    {
      title: <><label>{t("EBIT des Produkts")}</label><br></br><sub>{`in €`}</sub></>,
      width: '13%',
      dataIndex: 'ebitdesproduct',
      render: (text: any, record: InnovationCostRowValue, index) => {
        if (index == 0) {
          if ((editRecord?.key != "newInno")) {
            return `${formatValue(record?.salesLastYear * (record?.marginPercentage / 100))} €`;
          } else {
            return `${formatValue(salesLastYear * (marginPercentage / 100))} €`;
          }
        } else {
          if (editRecord?.key == record?.key) {
            return `${formatValue(salesLastYear * (marginPercentage / 100))} €`;
          } else {
            return `${formatValue(record?.salesLastYear * (record?.marginPercentage / 100))} €`;
          }
        }
      }
    },
    {
      title: (
        <Space wrap={true} align="center" direction="horizontal">
          <Tooltip placement="top" title={t("Daten importieren")}>
            {/* <DownloadOutlined onClick={() => { onImport(); }} /> */}
            <UploadFile currentProjectId={props.currentProject?.id} isForEbit={isForEbit} onImportCostTable={props.onImportInnoTable} />
          </Tooltip>
          <Tooltip placement="top" title={t("Daten exportieren")}>
            <img src={exportIcon} alt="export icon" onClick={() => { onExport(); }} />
          </Tooltip>
        </Space>
      ),
      width: '8%',
      dataIndex: "actions",
      render: (text, record: InnovationCostRowValue, index) => {
        //console.log(text, record, index);
        if (index == 0) {
          return <Space size={10}> <span>
            <Typography.Link
              disabled={editRecord?.key != record?.key}
              onClick={() => onAddRow(record)} //handleAdd(record?.key)}
              style={{ marginRight: 8 }}
            >
              <Tooltip placement="bottom" title={`${t("Add data")}`}>
                <PlusOutlined style={{ color: "green" }} />
              </Tooltip>
            </Typography.Link>
          </span></Space>
        } else {
          if (editRecord?.key == record?.key) {
            return <Space size={10}>
              <Popconfirm
                title="Sure to save?"
                onConfirm={() => onEditRow(record)}
              >
                <a>
                  <Tooltip placement="bottom" title="Save data">
                    <CheckCircleOutlined />
                  </Tooltip>
                </a>
              </Popconfirm>

              <Typography.Link
                disabled={editRecord?.key != "newInno" && editRecord?.key != record?.key}
                onClick={() => { form.setFieldsValue(fillInForm(newRowInno)); setEditRecord(newRowInno) }}
              >
                <Tooltip placement="bottom" title="Cancel Changes">
                  <StopTwoTone twoToneColor="red" />
                </Tooltip>
              </Typography.Link>
            </Space>;
          } else {
            return <Space size={10}>
              <Typography.Link
                disabled={editRecord?.key != "newInno" && editRecord?.key != record?.key}
                onClick={() => { form.setFieldsValue(fillInForm({ ...record })); setEditRecord(record) }}
              >
                <Tooltip placement="bottom" title={`${t("Edit")}`}>
                  <EditTwoTone twoToneColor="#1890ff" />
                </Tooltip>
              </Typography.Link>

              <Popconfirm
                title={`${t("Sure to delete?")}`}
                onConfirm={() => onDeleteRow(record)}
              >
                <a>
                  <Tooltip placement="bottom" title={`${t("Delete")}`}>
                    <DeleteTwoTone twoToneColor="red" />
                  </Tooltip>
                </a>
              </Popconfirm>
            </Space>;
          }
        }
      },
    }
  ];

  const dataRowInno = DProjectsList?.filter(c => c.innovationCost
    && (props.currentProject?.yearUnderReview - 2 <= c.dproject.completionYear
      && c.dproject.completionYear <= props.currentProject?.yearUnderReview)).map(row => {
        if (row.innovationCost) {
          return {
            key: `inno_row_${row.dproject.id}`,
            marginPercentage: row.innovationCost?.marginPercentage ?? 0,
            salesLastYear: row.innovationCost?.salesLastYear ?? 0,
            // expectedMonthlySales: row.costOfDelay.expectedMonthlySales,
            // delayInMonths: row.costOfDelay.delayInMonths,
            // monthlyProjectCosts: row.costOfDelay.monthlyProjectCosts,
            // otherCosts: row.costOfDelay.otherCosts,
            project: row.dproject.id,
            projectyear: row.dproject.completionYear,
            productType: row.innovationCost?.productTypeId ?? 0,
            newProduct: ""
          } as InnovationCostRowValue;
        }
      }) ?? [];


  function fillInForm(row: InnovationCostRowValue) {
    let rowNames: any = {};
    rowNames[`project_${row.project}`] = row.project;
    rowNames[`productType_${row.project}`] = row.productType;
    rowNames[`marginPercentage_${row.project}`] = row.marginPercentage;
    rowNames[`salesLastYear_${row.project}`] = row.salesLastYear;
    rowNames[`ProjektAbschlussjahr_${row.project}`] = row.projectyear;
    rowNames[`projectname_${row.project}`] = row.newProduct ?? "";
    return rowNames;
  }
  function getRowFromForm(obj: any) {
    let row: InnovationCostRowValue = {
      key: '',
      project: 0,
      projectyear: 0,
      marginPercentage: 0,
      salesLastYear: 0,
      productType: 0,
      newProduct: ""
    };

    Object.keys(obj).forEach(key => {
      console.log(key, obj[key]);
      if (key.includes("projectname_")) row.newProduct = obj[key] ?? "";
      if (key.includes("productType_")) row.productType = obj[key];
      if (key.includes("ProjektAbschlussjahr_")) row.projectyear = obj[key] ?? "";
      if (key.includes("project_")) row.project = obj[key];
      if (key.includes("marginPercentage_")) row.marginPercentage = obj[key];
      if (key.includes("salesLastYear_")) row.salesLastYear = obj[key];
    });
    return row;
  }
  async function onAddRow(row: InnovationCostRowValue) {
    try {
      console.log("obj", form.getFieldsValue());
      const row2 = (await form.validateFields());
      let newData = getRowFromForm(row2);
      console.log(newData);
      if (newData.newProduct == "") {
        props.onCreateInno({
          id: newData.project,
          marginPercentage: newData.marginPercentage,
          salesLastYear: newData.salesLastYear,
          productTypeId: newData.productType,
          forEbit: isForEbit,
        } as InnovationCostDto, props.currentProject?.id)
      } else {
        console.log("newData.NewProduct:", newData);
        var newdata2: InnovationCostWithDprojectDto = {
          bmProjectId: props.currentProject?.id,
          dprojectCompletionYear: newData.projectyear,
          dprojectName: newData.newProduct,
          marginPercentage: newData.marginPercentage,
          salesLastYear: newData.salesLastYear,
          productTypeId: newData.productType,
          forEbit: isForEbit,
        };
        props.onUpdateInnoWithNewDproject(newdata2);
      }
    } catch (e) {
      console.log(e);
    }

  }
  async function onEditRow(row: InnovationCostRowValue) {
    try {
      const row2 = (await form.validateFields());
      let newData = getRowFromForm(row2);
      console.log(newData);
      props.onUpdateInno({
        id: editRecord.project,
        marginPercentage: newData.marginPercentage,
        salesLastYear: newData.salesLastYear,
        productTypeId: newData.productType,
        forEbit: isForEbit,
      } as InnovationCostDto, props.currentProject?.id)
    } catch (e) {
      console.log('onEditRow ', e);
    }
  }
  function onDeleteRow(row: InnovationCostRowValue) {
    console.log(row);
    props.onDeleteInno(row.project, props.currentProject?.id, isForEbit);
  }
  function onExport() {
    props.onDownloadInnoTable(props.currentProject?.project_name, props.currentProject?.id )
  }
  function onImport() {
    alert("not implemented yet");
  }


  return (
    <Panel key={"inno_1"} {...props}
      header={<Row>
        <Col span={6} className="title-tab-hover"><Title level={5}>{`${t("Innovationsleistung")}`}</Title></Col>
        <Col span={6}><input disabled value={`${t("EBIT-Potenzial")}:`} /></Col>
        <Col span={6}><input style={{ width: '100%' }} disabled value={`${t("Absolut")} = ${formatValue(calculateEbitPotentialInnoCost())} €`} /></Col>
        <Col span={6}><input disabled value={`${t("Prozentual")} = ${formatValue(calculateEbitPotentialInnRate())} %`} /></Col>
        <Col span={24} className="paragraph-tab-hover"><Paragraph>{t("Innovation hover")}</Paragraph></Col>

      </Row>}
      className='cost-type-custom-panel eucalyptus'>
      <section id="table-two-columns">
        <Row>
          <Col span={12}>
            <TableForPanel
              columns={columnsOne}
              rows={rowsOne}
            />
          </Col>
          <Col span={12}>
            <TableForPanel
              columns={columnsOne}
              rows={rowsTwo}
            />
          </Col>
        </Row>
      </section>
      <div className='cost-type-editable'>
        <Form form={form} component={false} key="inno_form">
          <Table
            key={"inno_table"}
            bordered={false}
            dataSource={[newRowInno, ...dataRowInno]}
            columns={dataColInno}
            scroll={{ y: 500 }}
            rowClassName={() => "editable-row"}
            pagination={false}
          />
        </Form>
      </div>
    </Panel>

  );
};
const mapStateToProps = (state: IGeneralState, ownProps) => {
  return {
    ebitInfo: state.ebit,
    currentProject: ownProps.selectedProject ? ownProps.selectedProject :state.generalInfo.profileResponse?.currentProject,
    selectedProject: ownProps.selectedProject,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    onUpdateInnoWithNewDproject: (model: InnovationCostWithDprojectDto) => dispatch({ type: ActionTypes.CreateInnoNewDproject, model: model }),
    onCreateInno: (model: InnovationCostDto, projectID: number) => dispatch({ type: ActionTypes.CreateInno, model: model, projectID: projectID }),
    onUpdateInno: (model: InnovationCostDto, projectID: number) => dispatch({ type: ActionTypes.UpdateInno, model: model, projectID: projectID }),
    onDeleteInno: (modelId: number, projectID: number, forEbit: boolean) => dispatch({ type: ActionTypes.DeleteInno, id: modelId, projectID: projectID, forEbit }),
    onDownloadInnoTable: (projectName: string, projectID: number) => dispatch({ type: ActionTypes.DownloadInnoTable, projectID, projectName }),
    onImportInnoTable: (frmData: FormData, forEbit: boolean) => dispatch({ type: ActionTypes.ImportInnoTable, frmData, forEbit }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InnoPanel); 