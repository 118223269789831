import { Form, Input, Select, Table, Space, Tag } from 'antd';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PopconfirmAsync from '../../../Components/PopconfirmAsync';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { ICustomerUserItemWithKey, ICustomerUserUpdate } from '../../../Models/ICustomerUsers';
import CustomerPersonMakeUser from './CutomerPresonMakeUserModal';
import AssignBmProjectForCustomerUserModal from './AssignBmProjectForCustomerUserModal/AssignBmProjectForCustomerUser';
import AdminOperation from '../../../Components/AdminOperation/AdminOperation';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { RulesEmail, RulesName, RulesPhone, RulesRequire } from '../Rules/RulesValidation';
import { IListItem } from '../../../Models/Responses/ProfileResponse';
import { t } from 'i18next';
import AdminTable from '../../../Components/AdminTable/AdminTable';

interface IProps {
    customerUsersData: ICustomerUserItemWithKey[];
    titlesData: IListItem[];
    languageListData: IListItem[];
    collectionRoles: any[];
    isModalLoading: boolean;
    customerId: number;
    modalResponse: ICommonResponse;
    getResendActivationLink: (userId: number) => any;
    updateCustomerUser: (model: ICustomerUserUpdate, id: number) => any;
    activateUser: (userId: number, customerId: number) => any;
    deactivateUser: (userId: number, customerId: number) => any;
}
const CustomerUsersTable = (props: IProps) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "customer-user-title",
            title: `${t("Title")}`,
            dataIndex: 'titleName',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='titleId' rules={RulesRequire({ filedName: `${t("Title")}` })}  >
                            <Select showSearch placeholder={`${t("Select Title")}`} optionFilterProp="children"
                            >
                                {props.titlesData?.map((t, index) => <Select.Option value={t.value} key={index}>{t.label}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
        },
        {
            key: 'customer-user-first-name',
            title: `${t("First Name")}`,
            dataIndex: 'firstName',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='firstName'
                            rules={RulesName({ name: `${t("First Name")}`, countChar: 128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("First Name")}`, 'firstName', searchInput, handleSearch),
            sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName)  ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: 'customer-user-last-name',
            title: `${t("Last Name")}`,
            dataIndex: 'lastName',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='lastName'
                            rules={RulesName({ name: `${t("Last Name")}`, countChar: 128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("Last Name")}`, 'lastName', searchInput, handleSearch),
            sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName)  ?? 0,
        },
        {
            key: 'customer-user-email',
            title: `${t("E-Mail")}`,
            dataIndex: 'email',
            width: "15%",
            render: (text: any, record: any) => {
                if (record.isConfirmed) {
                    return <p>{text}</p>
                }
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='email'
                            rules={RulesEmail()} >
                            <Input />
                        </Form.Item>)
                } else {
                    return <p>{text}</p>
                }

            },
            ...getColumnSearchProps(`${t("E-Mail")}`, 'email', searchInput, handleSearch),
            sorter: (a, b) => a?.email?.localeCompare(b?.email)  ?? 0,
        },
        {
            key: 'customer-user-phone',
            title: `${t("Phone")}`,
            dataIndex: 'phoneNumber',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='phoneNumber' rules={RulesPhone()}>
                            <Input />
                        </Form.Item>)
                } else {
                    return <p>{text ? text : `No ${t("Phone")}`} </p>
                }
            },

        },
        {
            key: "customer-user-lan",
            title: `${t("Language")}`,
            dataIndex: 'languageName',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='defaultLanguageId' rules={RulesRequire({ filedName: `${t("Language")}` })}  >
                            <Select showSearch placeholder={`${t("Select Language")}`} optionFilterProp="children"
                                getPopupContainer={() => document.getElementById("app-layout")} >
                                {props.languageListData?.map((d: any) => <Select.Option value={d?.value} key={d?.value}>{d?.label}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
        },

        {
            title: `${t("Actions")}`,
            dataIndex: '',
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                return <AdminOperation
                    // deleteId={record.personId}
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                // onDelete={deleteUser}
                />
            },
        },

        {
            key: "customer_user_is-user",
            title: `${t("User")}`,
            dataIndex: 'isUser',
            width: "10%",
            render: (text: any, record: any) => {
                if (record.isUser === true) {
                    return <Space size="small">
                        {record.isConfirmed ? (
                            <Tag color="geekblue">{`${t("Confirmed")}`}</Tag>
                        ) : (
                            <PopconfirmAsync
                                title={`${t("Do you want resend Activation link?")}`}
                                handleOk={() => props.getResendActivationLink(record.userId)}
                                isModalLoading={props.isModalLoading}
                                modalResponse={props.modalResponse}
                                resendActive={true}
                            />)}
                        <AssignBmProjectForCustomerUserModal
                            userId={record.userId} customerId={props.customerId} bmProjectsData={record?.bmProjects} />
                    </Space>
                } else {
                    return <Space>
                        <CustomerPersonMakeUser personId={record.personId} customerId={props.customerId} email={record.email} />
                    </Space>
                }
            },

        },
        {
            key: "con_key_10",
            title: `${t("Active")}`,
            dataIndex: '',

            render: (_: any, record: any) => {
                if (record?.userId) {
                    const activeStatus = record?.isActive;
                    return activeStatus ? (
                        <PopconfirmAsync
                            title={`${t("Sure to deactivate?")}`}
                            handleOk={() => deactivateStatsUser(record.userId)}
                            isModalLoading={props.isModalLoading}
                            modalResponse={props.modalResponse}
                            deactivateUser={true}
                        />
                    ) : (
                        <PopconfirmAsync
                            title={`${t("Sure To Activate?")}`}
                            handleOk={() => activeStatsUser(record.userId)}
                            isModalLoading={props.isModalLoading}
                            modalResponse={props.modalResponse}
                            activeUser={true}
                        />
                    )
                } else {
                    return <Tag>{`${t("Participant")}`}</Tag>
                }

            }
        },

    ]

    const isEditing = (record: ICustomerUserItemWithKey) => record.key === editingKey;
    const edit = (record: Partial<ICustomerUserItemWithKey> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            const index = props.customerUsersData.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = props.customerUsersData[index];
                const newCustomerUser = { personId: item.personId, ...values };
                //console.log("updateCustomerUser", newCustomerUser);
                await props.updateCustomerUser(newCustomerUser, props.customerId);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }

    }
    // const deleteUser = async (id: any) => {
    //     try {
    //         await props.deletePerson(id, props.customerId);
    //     } catch (error) {
    //         console.log('delete Failed:', error);
    //     }
    // }
    const activeStatsUser = async (id: any) => {
        try {
            await props.activateUser(id, props.customerId);
        } catch (errInfo) {
            console.log('change status Failed:', errInfo);
        }
    }
    const deactivateStatsUser = async (id: any) => {
        try {
            await props.deactivateUser(id, props.customerId)
        } catch (errInfo) {
            console.log('change status Failed:', errInfo);
        }
    }
    return (
        <Form form={form} key="customer_users_form" onFinish={onFinish}>
            <AdminTable dataSource={props.customerUsersData} keyTable='customer_users_table' columns={columns} />
        </Form>
    );
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getResendActivationLink: (userId: number) => dispatch({ type: ActionTypes.GET_Resend_Activation_Link, userId }),
        updateCustomerUser: (model: ICustomerUserUpdate, id: number) => dispatch({ type: ActionTypes.UPDATE_CUSTOMERUSER, customerId: id, model: model }),
        // deletePerson: (personId: number, id: number) => dispatch({ type: ActionTypes.DELETE_PERSON, customerId: id, personId }),
        activateUser: (userId: number, customerId: number) => dispatch({ type: ActionTypes.ACTIVE_CUSTOMERUSER, userId, customerId }),
        deactivateUser: (userId: number, customerId: number) => dispatch({ type: ActionTypes.DEACTIVATE_CUSTOMERUSER, userId, customerId }),
    };
};

export default connect(null, mapDispatchToProps)(CustomerUsersTable);
;
