import { ActionTypes } from "../actionsTypes";
import { IBmProjectState } from "./IGeneralState";
const initialState: IBmProjectState = {
  isBmProjectLoading: false,
  bmProjectsResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const BmProjectReducer = (
  state = initialState,
  action: any
): IBmProjectState => {
  switch (action.type) {
    case ActionTypes.CHANGE_BM_PROJECT_LOADER:
      return {
        ...state,
        isBmProjectLoading: action.isLoading,
      };
    case ActionTypes.SAVE_BM_PROJECTS:
      return {
        ...state,
        bmProjectsResult: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      };
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      };
    default:
      break;
  }
  return state;
};

export default BmProjectReducer;