import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ActionTypes } from '../../../Redux/actionsTypes'
import { IErrorPage } from '../../../Models/IErrorPage'
import ErrorsPageForAdminTable from './ErrorsPageForAdminTable';
import { Spin } from 'antd';
interface IProps {
    errorsData: IErrorPage[];
    isLoading: boolean;
    getAllErrors: any;
}
const ErrorsPageForAdmin = (props: IProps) => {
    useEffect(() => {
        props.getAllErrors();
    }, []);
    const errors: IErrorPage[] = props.errorsData?.map(e => {
        return {
            key: `error_${e.id}`,
            ...e
        }
    })??[];
    return (
        <Spin spinning={props.isLoading}>
            <div className='admin-component'>
             <ErrorsPageForAdminTable errorsRow={errors}/>
            </div>
        </Spin>
    )
}
const mapStateToProps = (state: any) => {
    return {
        errorsData: state.errorsForAdmin.errorPagesResult,
        isLoading: state.errorsForAdmin.isErrorPageLoading,
    }
}
const mapDispatchForProps = (dispatch: any) => {
    return {
        getAllErrors: () => dispatch({ type: ActionTypes.GET_ALL_ERRORS }),
    }
}
export default connect(mapStateToProps, mapDispatchForProps)(ErrorsPageForAdmin);
