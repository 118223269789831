
import { Tabs, Collapse, Form, Slider, InputNumber, Typography, Row, Col, Spin } from 'antd';
import CODPanel from './CostTypePanels/CODPanel';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import "./index.scss";
import HKPanel from './CostTypePanels/HKPanel';
import QKPanel from './CostTypePanels/QKPanel';
import InnoPanel from './CostTypePanels/InnoPanel';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { IEbitToolState, IGeneralState } from '../../../../Redux/reducers/IGeneralState';
import { BmProject, ProfileResponse } from '../../../../Models/Responses/ProfileResponse';
import { useEffect } from 'react';

interface CostTypeProps {
    selectedProjectId?: number;
    selectedProject?: BmProject;
    ebitResult: IEbitToolState;
    profileResponse: ProfileResponse;
    getDprojectsForEbit: (projectID?: number) => any;
    getSelectedProject: (projectID?: number) => any;
}
const CostTypes: React.FC = (props: CostTypeProps) => {
    useEffect(() => {
        if (props.selectedProjectId == null) {
            props.getDprojectsForEbit();
        }
    }, [props.profileResponse?.currentProject]);

    useEffect(() => {
        if (props.selectedProjectId != null) {
            props.getSelectedProject(props.selectedProjectId);
            props.getDprojectsForEbit(props.selectedProjectId);
        }
    }, [props.selectedProjectId]);
    return (
        <Spin spinning={props.ebitResult.isEbitLoading}>
            <div className='cost-type-tab' id='cost-type-tab'>
                <Collapse defaultActiveKey={['cod_1']}
                    expandIcon={({ isActive }) => (
                        isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />
                    )}
                    ghost={true} >
                    <CODPanel selectedProject={props.selectedProjectId ? props.selectedProject : null}/>
                    <InnoPanel selectedProject={props.selectedProjectId ? props.selectedProject : null}/>
                    <QKPanel selectedProject={props.selectedProjectId ? props.selectedProject : null} />
                    <HKPanel selectedProject={props.selectedProjectId ? props.selectedProject : null}/>
                </Collapse>
            </div>
        </Spin>
    );
};
const mapStateToProps = (state: IGeneralState, ownProps) => {
    return {
        selectedProjectId: ownProps.selectedProjectId,
        commonResult: state.generalInfo.commonResponse,
        ebitResult: state.ebit,
        profileResponse: state.generalInfo.profileResponse,
        //sselectedProject: state.leverTool.selectedBmProject,

    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getSelectedProject: (projectID?: number) => dispatch({ type: ActionTypes.GetBmProject, projectID: projectID }),
        getDprojectsForEbit: (projectID?: number) => dispatch({ type: ActionTypes.GetDprojectsForEbit, projectID: projectID }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CostTypes);


