import { Button, ConfigProvider, DatePicker, Form, Input, Modal, Select, Spin } from "antd";
import { connect } from "react-redux";
import { AddFlProjectModel, ToUploadFlProjectModel } from "../../../../../../../Models/FrontLoadingTool/FrontLoadingTool";
import { IDProject } from "../../../../../../../Models/IDProject";
import './addeditelprojectform.scss';
import locale_en_US from 'antd/es/date-picker/locale/en_US';
import locale_de_DE from 'antd/es/date-picker/locale/de_DE';
import i18n from "../../../../../../../i18n";
import { IFlToolToBeEdittedProject, IFrontLoadingToolProject, IFrontLoadingToolTemplate } from "../../../../../../../Models/IFrontLoadingTool";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import BestpracticeCurveData from "../../Models/BestpracticeCurveData";
import de from 'antd/es/date-picker/locale/de_DE';
import en from 'antd/es/date-picker/locale/en_US';
import { ActionTypes } from "../../../../../../../Redux/actionsTypes";
import { useTranslation } from "react-i18next";
import { IGeneralState } from "../../../../../../../Redux/reducers/IGeneralState";
import { ExclamationCircleFilled } from "@ant-design/icons";
interface IProps {
    dprojects: IDProject[];
    savingFlProject: boolean;
    //saveFlProject: (values: AddFlProjectModel) => void;
    cancelSaveFlProject: () => void;
    flTemplates: IFrontLoadingToolTemplate[];
    flProjects: IFrontLoadingToolProject[];
    //toBeEdittedFlProject?: IFlToolToBeEdittedProject;
    edittingFlProject: boolean;
    bestpracticeCurves: BestpracticeCurveData[];
    confirmingEditFlProject: boolean;
    showConfirmationMsg: boolean;
    flToolToBeEdittedProject: IFlToolToBeEdittedProject;
    resetFlToolToBeEdittedProject: () => void;
    createFlProject: (toUploadFlProject: ToUploadFlProjectModel) => void;
    editFlProject: (toUploadFlProject: ToUploadFlProjectModel) => void;

    changeConfirmationMsg: (value: boolean) => any;
};
function AddEditFlProjectForm(props: IProps) {

    const [dprojectId, setdProjectId] = useState<{
        value?: number;
        validateStatus?: ValidateStatus;
        errorMsg?: string | null;
    }>({

    });

    const { t } = useTranslation();

    useEffect(() => {
        form.setFieldValue("dprojectId", props.flToolToBeEdittedProject ? props.flToolToBeEdittedProject.dprojectId : null);
        form.setFieldValue("templateId", props.flToolToBeEdittedProject ? props.flToolToBeEdittedProject.templateId : null);
        form.setFieldValue("bestpracticeCurveId", props.flToolToBeEdittedProject ? props.flToolToBeEdittedProject.bestpracticeCurveId : null);
        form.setFieldValue("flProjectActualStartDate", props.flToolToBeEdittedProject ? moment(props.flToolToBeEdittedProject.flProjectActualStartDate) : null);
        form.setFieldValue("flProjectPlannedStartDate", props.flToolToBeEdittedProject ? moment(props.flToolToBeEdittedProject.flProjectPlannedStartDate) : null);
    }, [props.flToolToBeEdittedProject]);


    useEffect(() => {
        if (!props.savingFlProject)
            form.resetFields();
    }, [props.savingFlProject]);



    const [form] = Form.useForm();

    type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];

    const validateDprojectId = (
        dprojectId: number,
    ): {
        validateStatus: ValidateStatus;
        errorMsg: string | null;
    } => {
        let dprojectsIds: number[] = props.flProjects.map(flProject => flProject.dprojectId)
            .filter(dprojectId => dprojectId !== props.flToolToBeEdittedProject?.dprojectId);
        if (!dprojectsIds.includes(dprojectId)) {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
        return {
            validateStatus: 'error',
            errorMsg: 'Dproject already selected!',
        };
    };

    const onProjectIdChange = (dprojectId: number) => {
        console.log("onProjectIdChange dprojectId: ", dprojectId);

        setdProjectId({
            ...validateDprojectId(dprojectId),
            value: dprojectId
        });
    }
    const { confirm } = Modal;
    const cancelSaveFlProject = () => {
        setdProjectId({});
        props.resetFlToolToBeEdittedProject();
        props.cancelSaveFlProject();
        form.resetFields();
    }
    const showConfirmationMsg = (data: ToUploadFlProjectModel) => {
        confirm({
            title: 'Note',
            icon: <ExclamationCircleFilled />,
            content: t('Please note that changing the template will cause any saved data related to the current template in the last phases to be lost. Are you sure you want to continue updating the project?.'),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            // okButtonProps: { disabled: props.confirmingEditFlProject, loading: props.confirmingEditFlProject },
            // cancelButtonProps: { disabled: props.confirmingEditFlProject },
            onOk() {
                confirmEdit(data);
            },
            onCancel() {
                
                //props.resetEditConfirmationAttributes();
            },
        });
    };
    const saveFlProject = (values: any) => {
        console.log("saveFlProject values: ", values);

        if (!props.flToolToBeEdittedProject) {
            let toUploadFlProject: ToUploadFlProjectModel = {
                ...values,
                dprojectId: dprojectId.value,
                flProjectActualStartDate: values.flProjectActualStartDate.format('M/D/YYYY'),
                flProjectPlannedStartDate: values.flProjectActualStartDate.format('M/D/YYYY')
                //flProjectPlannedStartDate: values.flProjectPlannedStartDate.format('M/D/YYYY')
            }
            console.log("toUploadFlProject_save: ", toUploadFlProject);

            props.createFlProject(toUploadFlProject);
        } else {

            let toUploadFlProject: ToUploadFlProjectModel = {
                ...values,
                flProjectId: props.flToolToBeEdittedProject.id,
                flProjectActualStartDate: values.flProjectActualStartDate.format('M/D/YYYY'),
                flProjectPlannedStartDate: values.flProjectActualStartDate.format('M/D/YYYY')
            };
            console.log("toUploadFlProject_edit: ", toUploadFlProject);
            if (props.flToolToBeEdittedProject.templateId != values["templateId"]) {
                let oldTemplate = props.flTemplates.filter(c => c.id == props.flToolToBeEdittedProject.templateId)?.[0];
                let newTemplate = props.flTemplates.filter(c => c.id == values["templateId"])?.[0];
                if (oldTemplate.phasesCount > newTemplate.phasesCount) {
                    //we have to notify the admin there are pahes will be deleted
                    showConfirmationMsg(toUploadFlProject);
                    return;
                }
            }

            confirmEdit(toUploadFlProject);
        }
    };
    const confirmEdit = (model: ToUploadFlProjectModel) => {
        props.editFlProject(model);
    }
    return (
        <Form
            form={form}
            onFinish={saveFlProject}
            layout="vertical"
            className="add-edit-form">
            <Form.Item
                label={props.flToolToBeEdittedProject ? t("D-Project") : t("Select D-Project")}
                name="dprojectId"
                rules={[{ required: props.flToolToBeEdittedProject ? false : true, message: 'Please, select d-project!' }]}
                validateStatus={dprojectId.validateStatus}
                help={dprojectId.errorMsg}
            >
                <Select
                    //value={dprojectId.value}
                    disabled={props.flToolToBeEdittedProject ? true : false}
                    onChange={onProjectIdChange}>
                    {props.dprojects.map((dproject, idx) => {
                        return (
                            <Select.Option key={idx} value={dproject.id}>{dproject.name}</Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label={t("Select Template")}
                name="templateId"
                rules={[{ required: true, message: t('Please, select template!') }]}
            >
                <Select                >
                    {props.flTemplates?.map((template, idx) => {
                        return (
                            <Select.Option key={idx} value={template.id}>{template.name}</Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label={t("Select Best Practice Curve")}
                name="bestpracticeCurveId"
                rules={[{ required: true, message: t('Please, select best practice curve!') }]}
            >
                <Select
                //value={bestpracticeCurveId}
                >
                    {props.bestpracticeCurves?.map((curve, idx) => {
                        return (
                            <Select.Option title={curve.description} key={"curve" + idx.toString()} value={curve.id}>{curve.name}</Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>
            <div className="flprject-date-pickers">
                <Form.Item
                    label={t("Pick Starting Date")}
                    name="flProjectActualStartDate"
                    rules={[{ required: true, message: t('Please, Pick Starting Date!') }]}
                >
                    <DatePicker
                        style={{ width: "100%" }}
                        locale={i18n.language == 'de' ? de : en}
                        format={"L"} />
                </Form.Item>
            </div>
            <Form.Item>
                <div className="save-flproject-saveBtn-container">
                    <Spin spinning={props.savingFlProject || ((props.edittingFlProject || props.confirmingEditFlProject) && (props.flToolToBeEdittedProject ? true : false))}>
                        <Button
                            htmlType="submit"
                            type="primary"
                            disabled={dprojectId.validateStatus === "error"}>
                            {t("Save")}
                        </Button>
                    </Spin>
                </div>
                <Button
                    htmlType="button"
                    style={{ margin: '0 8px' }}
                    onClick={cancelSaveFlProject}
                    disabled={props.savingFlProject || ((props.edittingFlProject || props.confirmingEditFlProject) && (props.flToolToBeEdittedProject ? true : false))}>
                    {t("Cancel")}
                </Button>
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state: IGeneralState) => {
    return {
        savingFlProject: state.frontLoadingProjects.savingFlProject,
        edittingFlProject: state.frontLoadingProjects.edittingFlProject,
        showConfirmationMsg: state.frontLoadingProjects.showConfirmationMsg,
        confirmingEditFlProject: state.frontLoadingProjects.confirmingEditFlProject,
        flProjects: state.frontLoadingProjects.flProjects,
        flToolToBeEdittedProject: state.frontLoadingProjects.flToolToBeEdittedProject,
        dprojects: state.dprojects.dprojects,
        flTemplates: state.frontLoadingProjects.flTemplates,
        bestpracticeCurves: state.frontLoadingProjects.bestpracticeCurveData,
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        resetFlToolToBeEdittedProject: () =>
            dispatch({ type: ActionTypes.SaveFlToolToBeEdittedProject, flToolToBeEdittedProject: null }),

        createFlProject: (toUploadFlProject: ToUploadFlProjectModel) =>
            dispatch({ type: ActionTypes.SaveFlProject, values: toUploadFlProject }),

        editFlProject: (toUploadFlProject: ToUploadFlProjectModel) =>
            dispatch({ type: ActionTypes.EditFlProject, flProject: toUploadFlProject }),
        changeConfirmationMsg: (value: boolean) =>
            dispatch({ type: ActionTypes.ChangeConfirmationMsg, value: value })

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFlProjectForm);
