import { Button, Popover, Spin, Table } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import saveAs from 'file-saver';
import html2canvas from 'html2canvas';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { IKpiDegree, ILever } from '../../Models/Levers/Items';
import { BmProject } from '../../Models/Responses/ProfileResponse';
import { ActionTypes } from '../../Redux/actionsTypes';
import { IGeneralState } from '../../Redux/reducers/IGeneralState';
import AsupBarChart_Ant from '../../Scenes/Charts/AsupBarChart/AsupBarChart_Ant';
import HeatMapChart from '../../Scenes/Charts/AsupHeatMapChart/HeatMapChart';
import SwitchableChartRadarBar from '../../Scenes/Charts/BarChart/SwitchableChartRadarBar';
import './allcharts.scss'
import AllChartsTopBar from './AllChartsTopBar';
import { DownloadChartAsImage } from '../../Utils/helperFunctions';

interface IradarDataElement {
    label: string;
    peer?: string;
    value: number;
}
interface ChartDetails {
    leverLevel: number,
    leverId: number,
    leverCode: string;
    chartTitle: string;
    chartType: string;
    chartReferences?: IradarDataElement[];
    chartDescription?: string;
    chartUnit?: string;
    isPercentChart?: boolean;
    bestPracticeMaxValue?: number;
    bestPracticeMinValue?: number;
};

interface ChartValue {
    name: string, //project name
    value: number | IradarDataElement[],
    isTarget: boolean,
    isComplete: boolean,
};
interface ChartDetailsAndValues {
    chartDetails: ChartDetails;
    chartValues: ChartValue[];
}
interface ChartsMeta {
    leverCode: string,
    chartType: string
}
interface ExportChartsToExcel {
    chartsMeta: ChartsMeta[]
}
interface IProps {
    leverData: ILever[];
    currentProject: BmProject;
    projects: BmProject[];
    currentUserId: number;
    isLoadBenchmarkingProjects: boolean;
    exportSelectedChartsToExcel: (exportChartsToExcelModel: any, fileName: string) => void;
    exportAllChartsToExcel: (fileName: string) => void;
}

const AllCharts = (props: IProps) => {

    const [leverLevelNumber, setLeverLevelNumber] = useState('');
    const [subleverLevelNumber, setSubleverLevelNumber] = useState('');
    const [successFactorLevelNumber, setSuccessFactorLevelNumber] = useState('');
    const [selectedCharts, setSelectedCharts] = useState(new Array<string>());
    const [renderedChartsDetailsAndValues, setrenderedChartsDetailsAndValues] = useState(new Array<ChartDetailsAndValues>());


    let [isLeverLevelVisible, setIsLeverLevelVisible] = useState(true);
    let [isSubleverLevelVisible, setIsSubleverLevelVisible] = useState(false);
    let [isSuccessFactorLevelVisible, setIsSuccessFactorLevelVisible] = useState(false);
    let [isQuestionLevelVisible, setIsQuestionLevelVisible] = useState(false);

    let [subLeversCharts, setSubLeversCharts] = useState([]);
    let [successFactorsCharts, setSuccessFactorCharts] = useState([]);
    let [questionsCharts, setQuestionsCharts] = useState([]);

    let [numCharts, setNumCharts] = useState(6);

    // useEffect(() => {
    //     console.log('renderedChartsDetailsAndValues: ', renderedChartsDetailsAndValues);

    // }, [renderedChartsDetailsAndValues]);

    // useEffect(() => {
    //     console.log('allcharts-currentproject: ', props.currentProject);
    //     console.log('allcharts-projects: ', props.projects);
    // }, [props.currentProject, props.projects]);

    useEffect(() => {

        if (isSubleverLevelVisible) {
            let charts = calculateSubleverLevelCharts(leverLevelNumber);
            setSubLeversCharts(charts);
        }
        if (isSuccessFactorLevelVisible) {
            let charts = calculateSuccessFactorLevelCharts(subleverLevelNumber, leverLevelNumber);
            setSuccessFactorCharts(charts);
        }
        if (isQuestionLevelVisible) {
            //let charts = calculateQuestionsLevelcharts(successFactorLevelNumber, subleverLevelNumber, leverLevelNumber);
            let charts = calculateQchartsDirectly(subleverLevelNumber, leverLevelNumber);
            setQuestionsCharts(charts);
        }

    }, [selectedCharts]);

    const calculateSubleverLevelCharts = (leverNumber: string): { subleverChart: JSX.Element }[] => {
        //console.log('calculateSubleverLevelCharts: ', leverNumber);

        return props.leverData?.find(l => l.number === leverNumber).subLevers.map(s => {
            return {
                subleverChart: <AsupBarChart_Ant
                    leverNumber={s.number}
                    width={500}
                    height={260}
                    //showSuccessFactorLevelCharts={(subLeverNumber: string) => showSuccessFactorLevelCharts(subLeverNumber, leverNumber)}
                    showSuccessFactorLevelCharts={(subLeverNumber: string) => showQchartsDirectly(subLeverNumber, leverNumber)}
                    showChartDetails={true}
                    showChartControls={true}
                    showDownloadBtn={true}
                    showSelectCheckBox={true}
                    bordered={true}

                    selectChart={selectChart}
                    selectedCharts={selectedCharts}
                    informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                />
            };
        });
    }

    const showSubleverLevelCharts = (e: any, leverNumber: string) => {

        let charts = calculateSubleverLevelCharts(leverNumber);

        setSubLeversCharts(charts);
        setIsSubleverLevelVisible(true);
        setIsLeverLevelVisible(false);
        setIsSuccessFactorLevelVisible(false);
        setIsQuestionLevelVisible(false);
        setLeverLevelNumber(leverNumber);
        setSelectedCharts([]);
        setrenderedChartsDetailsAndValues([]);
    }

    const calculateQchartsDirectly = (subLeverNumber: string, leverNumber: string) => {
        let subLeverSuccessFactors = props.leverData?.find(l => l.number === leverNumber)?.subLevers?.find(s => s.number === subLeverNumber)?.successFactors ?? [];
        if (subLeverSuccessFactors.length === 0) return [];
        let successFactorsKpis = [];
        subLeverSuccessFactors.forEach(successFactor => {
            successFactorsKpis.push(...successFactor.kpiLevels);
        });
        if (successFactorsKpis.length === 0) return [];
        let kpisQuestions = [];
        (successFactorsKpis as IKpiDegree[]).forEach(kpi => {
            kpisQuestions.push(...kpi.questions);
        });
        if (kpisQuestions.length === 0) return [];
        let charts = kpisQuestions.map((q, idx) => {
            return {
                questionChart:
                    <AsupBarChart_Ant
                        leverNumber={q.code}
                        width={500}
                        height={260}
                        showChartDetails={true}
                        showChartControls={true}
                        showDownloadBtn={true}
                        showSelectCheckBox={true}
                        bordered={true}

                        selectChart={selectChart}
                        selectedCharts={selectedCharts}
                        informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                    />
            };
        });

        let resultof2 = charts.reduce((r, e, i) => {
            let remainder = i % 2;
            let chartObj = {};
            chartObj[remainder.toString()] = e.questionChart;
            return (remainder ? r[r.length - 1].push(chartObj) : r.push([chartObj])) && r;
        }
            , []);

        let doubleObjectsArr = [];
        resultof2.forEach(arr => {
            let doubleObj = {};
            arr.forEach(obj => {
                doubleObj = { ...doubleObj, ...obj };
            });
            doubleObjectsArr.push(doubleObj);
        });
        return doubleObjectsArr;
    };

    const showQchartsDirectly = (subLeverNumber: string, leverNumber: string) => {
        let charts = calculateQchartsDirectly(subLeverNumber, leverNumber);

        setQuestionsCharts(charts);
        setSubleverLevelNumber(subLeverNumber);
        //setSuccessFactorLevelNumber(successFactorLeverNumber);
        //setPreviousLeverNumber(successFactorLeverNumber);
        setIsSubleverLevelVisible(false);
        setIsLeverLevelVisible(false);
        setIsSuccessFactorLevelVisible(false);
        setIsQuestionLevelVisible(true);
        setSelectedCharts([]);
        setrenderedChartsDetailsAndValues([]);
    };

    const calculateSuccessFactorLevelCharts = (subLeverNumber: string, leverNumber: string) => {
        let charts = props.leverData?.find(l => l.number === leverNumber)
            .subLevers.find(s => s.number === subLeverNumber)
            .successFactors.map((sf, idx) => {
                let chartColDataIndex = (idx < 3) ? idx.toString() : (idx % 3).toString();
                if (chartColDataIndex === '0')
                    return {
                        '0': <AsupBarChart_Ant
                            leverNumber={sf.number}
                            width={500}
                            height={260}
                            showQuestionsLevelcharts={(successFactorLeverNumber: string) => showQuestionsLevelcharts(
                                successFactorLeverNumber,
                                subLeverNumber,
                                leverNumber
                            )}
                            showChartDetails={true}
                            showChartControls={true}
                            showDownloadBtn={true}
                            showSelectCheckBox={true}
                            bordered={true}

                            selectChart={selectChart}
                            selectedCharts={selectedCharts}
                            informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                        />
                    };
                if (chartColDataIndex === '1')
                    return {
                        '1': <AsupBarChart_Ant
                            leverNumber={sf.number}
                            width={500}
                            height={260}
                            showQuestionsLevelcharts={(successFactorLeverNumber: string) => showQuestionsLevelcharts(
                                successFactorLeverNumber,
                                subLeverNumber,
                                leverNumber
                            )}
                            showChartDetails={true}
                            showChartControls={true}
                            showDownloadBtn={true}
                            showSelectCheckBox={true}
                            bordered={true}

                            selectChart={selectChart}
                            selectedCharts={selectedCharts}
                            informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                        />
                    };

                if (chartColDataIndex === '2')
                    return {
                        '2': <AsupBarChart_Ant
                            leverNumber={sf.number}
                            width={500}
                            height={260}
                            showQuestionsLevelcharts={(successFactorLeverNumber: string) => showQuestionsLevelcharts(
                                successFactorLeverNumber,
                                subLeverNumber,
                                leverNumber
                            )}
                            showChartDetails={true}
                            showChartControls={true}
                            showDownloadBtn={true}
                            showSelectCheckBox={true}
                            bordered={true}

                            selectChart={selectChart}
                            selectedCharts={selectedCharts}
                            informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                        />
                    };
            });

        let resultof3 = charts.reduce((r, e, i) =>
            (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r
            , []);
        let tripleObjectsArr = [];
        resultof3.forEach(arr => {
            let tripleObj = {};
            arr.forEach(obj => {
                tripleObj = { ...tripleObj, ...obj };
            });
            tripleObjectsArr.push(tripleObj);
        });
        return tripleObjectsArr;
    }

    // const showSuccessFactorLevelCharts = (subLeverNumber: string, leverNumber: string) => {


    //     let charts = calculateSuccessFactorLevelCharts(subLeverNumber, leverNumber);
    //     setSuccessFactorCharts(charts);
    //     setSubleverLevelNumber(subLeverNumber);
    //     //setPreviousLeverNumber(subLeverNumber);
    //     setIsSubleverLevelVisible(false);
    //     setIsLeverLevelVisible(false);
    //     setIsSuccessFactorLevelVisible(true);
    //     setIsQuestionLevelVisible(false);
    //     setSelectedCharts([]);
    //     setRenderedChartsTitles([]);
    // };


    const calculateQuestionsLevelcharts = (successFactorLeverNumber: string, subLeverNumber: string, leverNumber: string) => {
        let charts = [];
        props.leverData?.find(l => l.number === leverNumber)
            .subLevers.find(sl => sl.number === subLeverNumber)
            .successFactors.find(sf => sf.number === successFactorLeverNumber)
            .kpiLevels.forEach(kpi => {
                let kpiCharts = kpi.questions.map((q, idx) => {
                    return {
                        questionChart:
                            <AsupBarChart_Ant
                                leverNumber={q.code}
                                width={500}
                                height={260}
                                showChartDetails={true}
                                showChartControls={true}
                                showDownloadBtn={true}
                                showSelectCheckBox={true}
                                bordered={true}

                                selectChart={selectChart}
                                selectedCharts={selectedCharts}
                                informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                            />
                    };
                });
                charts.push(...kpiCharts);
            });
        let resultof3 = charts.reduce((r, e, i) => {
            let remainder = i % 3;
            let chartObj = {};
            chartObj[remainder.toString()] = e.questionChart;
            return (remainder ? r[r.length - 1].push(chartObj) : r.push([chartObj])) && r;
        }
            , []);

        let tripleObjectsArr = [];
        resultof3.forEach(arr => {
            let tripleObj = {};
            arr.forEach(obj => {
                tripleObj = { ...tripleObj, ...obj };
            });
            tripleObjectsArr.push(tripleObj);
        });
        return tripleObjectsArr;
    };

    const showQuestionsLevelcharts = (successFactorLeverNumber: string, subLeverNumber: string, leverNumber: string) => {

        let charts = calculateQuestionsLevelcharts(successFactorLeverNumber, subLeverNumber, leverNumber);
        setQuestionsCharts(charts);
        setSuccessFactorLevelNumber(successFactorLeverNumber);
        //setPreviousLeverNumber(successFactorLeverNumber);
        setIsSubleverLevelVisible(false);
        setIsLeverLevelVisible(false);
        setIsSuccessFactorLevelVisible(false);
        setIsQuestionLevelVisible(true);
        setSelectedCharts([]);
        setrenderedChartsDetailsAndValues([]);
    };

    let Cols: ColumnsType<{ '0': JSX.Element, '1': JSX.Element, '2': JSX.Element }> = [
        {
            title: <div></div>,
            dataIndex: '0',
            key: '0',
            align: 'center'
            //width: 255
        },
        {
            title: <div></div>,
            dataIndex: '1',
            key: '1',
            align: 'center'
            //width: 255
        },
        // {
        //     title: <div></div>,
        //     dataIndex: '2',
        //     key: '2',
        //     align: 'center'
        //     //width: 255
        // }
    ];

    const backToLeversCharts = () => {
        setSelectedCharts([]);
        setrenderedChartsDetailsAndValues([]);
        setIsSubleverLevelVisible(false);
        setIsLeverLevelVisible(true);
        setIsSuccessFactorLevelVisible(false);
        setIsQuestionLevelVisible(false);
    };

    const backToSubLeversCharts = () => {
        setSelectedCharts([]);
        setrenderedChartsDetailsAndValues([]);
        setIsSubleverLevelVisible(true);
        setIsLeverLevelVisible(false);
        setIsSuccessFactorLevelVisible(false);
        setIsQuestionLevelVisible(false);
    };

    const selectChart = (divId: string, add: boolean) => {
        console.log('selectChart: ', divId, ', add: ', add);
        if (!divId) return;
        setSelectedCharts((selectedCharts) => {
            let newSelectedCharts = [...selectedCharts];
            if (add)
                newSelectedCharts.push(divId);
            else
                newSelectedCharts = newSelectedCharts.filter(sc => sc !== divId);
            console.log("newSelectedCharts: ", newSelectedCharts);

            return newSelectedCharts;
        });
    };
    const getFileName = (): string => {
        if (isLeverLevelVisible) return props.currentProject.project_name + '_' + 'Stellhebel' + `_${Date.now()}`;
        if (isSubleverLevelVisible) {
            let leverName = props.leverData?.find(lever => lever.number === leverLevelNumber)?.name ?? '';
            return props.currentProject.project_name + '_' + leverName + `_${Date.now()}`;
        }
        if (isQuestionLevelVisible) {
            let subleverName = props.leverData?.find(lever => lever.number === leverLevelNumber)?.subLevers
                .find(subLever => subLever.number === subleverLevelNumber)?.name ?? '';
            return props.currentProject.project_name + '_' + subleverName + `_${Date.now()}`;
        }
    };
    const getAllChartsFileName = (): string => {
        return props.currentProject.project_name + '_' + `_${Date.now()}`;
    };
    const exportAllChartsToExcel = () => {
        console.log("exportAllChartsToExcel");
        let fileName: string = getAllChartsFileName();
        props.exportAllChartsToExcel(fileName);
    };
    const exportSelectedChartsToExcel = () => {
        console.log("renderedChartsDetailsAndValues: ", renderedChartsDetailsAndValues);
        console.log("selectedCharts: ", selectedCharts);


        let chartsToExport = renderedChartsDetailsAndValues.filter(c => selectedCharts.includes(c.chartDetails.leverCode));
        let chartsToExportGroupedByChartType = {
            barCharts: chartsToExport.filter(c => c.chartDetails.chartType === 'Bar'),
            radarCharts: chartsToExport.filter(c => c.chartDetails.chartType === 'Radar'),
        };
        //console.log('chartsToExportGroupedByChartType: ', chartsToExportGroupedByChartType);
        let chartsMeta: ChartsMeta[] = [];
        let barChartsMeta: ChartsMeta[] = chartsToExportGroupedByChartType.barCharts.map(barChart => {
            return {
                leverCode: barChart.chartDetails.leverCode,
                chartType: 'Bar'
            }
        });
        chartsMeta.push(...barChartsMeta);
        let radarChartsMeta: ChartsMeta[] = chartsToExportGroupedByChartType.radarCharts.map(radarChart => {
            return {
                leverCode: radarChart.chartDetails.leverCode,
                chartType: 'Radar'
            }
        });
        chartsMeta.push(...radarChartsMeta);

        let exportChartsToExcelModel: ExportChartsToExcel = {
            chartsMeta: chartsMeta
        };
        // prepare filename.
        let fileName = getFileName(); //chartsToExportGroupedByChartType
        //console.log('getFileName fileName: ', fileName);

        console.log("exportChartsToExcelModel: ", exportChartsToExcelModel);

        props.exportSelectedChartsToExcel(exportChartsToExcelModel, fileName);
    };

    const DownloadAllChartsAsImage = async () => {
        //setDownloadInProgress(true);
        selectedCharts.forEach(async selectedChart => {
            let chartDetails = renderedChartsDetailsAndValues.find(c => c.chartDetails.leverCode === selectedChart);
            let fileName = props.currentProject.project_name +
                '_' + chartDetails?.chartDetails.chartTitle.split(' ', 2)[1] +
                '_' + chartDetails?.chartDetails.chartType;
            //+ '_' + Date.now().toString();
            const canvasSave = document.getElementById(selectedChart);
            // let canvas = await html2canvas(canvasSave);
            // canvas.toBlob(function (blob) {
            //     saveAs(blob, `${fileName}.png`);
            // }, 'png', 1);
            DownloadChartAsImage(fileName, canvasSave);
        });

        //finishedDownload();

    };


    const finishedDownload = () => {
        setSelectedCharts([]);
    };

    const saveInformedChartDetailsAndValues = (chartDetailsAndValues: ChartDetailsAndValues) => {
        //console.log(">>>>>chartDetailsAndValues: ", chartDetailsAndValues);

        setrenderedChartsDetailsAndValues((renderedChartsDetailsAndValues) => {
            let newChartsDetailsAndValues = renderedChartsDetailsAndValues.map(chartDetailsAndValues => {
                return {
                    ...chartDetailsAndValues,
                    chartDetails: { ...chartDetailsAndValues.chartDetails },
                    chartValues: chartDetailsAndValues.chartValues.map(chartValue => {
                        return {
                            ...chartValue
                        }
                    })
                }
            });
            //let sameTitleChart = newChartsDetailsAndValues.find(c => c.chartDetails.chartTitle === chartDetailsAndValues.chartDetails.chartTitle);
            let sameTitleChart = newChartsDetailsAndValues.find(c => c.chartDetails.leverCode === chartDetailsAndValues.chartDetails.leverCode);
            if (typeof sameTitleChart === 'undefined') {
                newChartsDetailsAndValues.push(chartDetailsAndValues);
                return newChartsDetailsAndValues.filter(c => c.chartDetails.chartTitle !== '');
            }
            if (sameTitleChart.chartDetails.chartType === chartDetailsAndValues.chartDetails.chartType)
                return newChartsDetailsAndValues.filter(c => c.chartDetails.chartTitle !== '');

            let indexOfSameTitleDiffrentTypeChart: number = newChartsDetailsAndValues.findIndex(c => c.chartDetails.chartTitle === chartDetailsAndValues.chartDetails.chartTitle);
            if (indexOfSameTitleDiffrentTypeChart !== -1)
                newChartsDetailsAndValues[indexOfSameTitleDiffrentTypeChart] = chartDetailsAndValues;
            return newChartsDetailsAndValues
        });
    };

    const onCheckAllChange = (checked: boolean) => {
        if (!checked) {
            setSelectedCharts([]);
        } else {
            setSelectedCharts(renderedChartsDetailsAndValues.map(c => {
                return c.chartDetails.leverCode
            }));
        }
    };

    return (
        props.currentProject ?
            (props.isLoadBenchmarkingProjects) ? <Spin size="large" /> :
                <div className='allChartsContainer'>
                    {/* {(selectedCharts.length > 0) ?  */}
                    <AllChartsTopBar
                        selectedCharts={selectedCharts}
                        finishedDownload={finishedDownload}
                        checkAll={renderedChartsDetailsAndValues.length === selectedCharts.length}
                        onCheckAllChange={onCheckAllChange}
                        DownloadAllChartsAsImage={DownloadAllChartsAsImage}
                        backBtnTitle={isSubleverLevelVisible ? 'Back to levers charts' : isQuestionLevelVisible ? 'Back to sublevers charts' : ''}
                        isBackBtnVisible={isSubleverLevelVisible || isQuestionLevelVisible}
                        goBack={isSubleverLevelVisible ? backToLeversCharts : isQuestionLevelVisible ? backToSubLeversCharts : null}
                        exportSelectedChartsToExcel={exportSelectedChartsToExcel}
                        exportAllChartsToExcel={exportAllChartsToExcel} />
                    {/* : null} */}
                    <div className='topLevelChartsContainer'>
                        {isLeverLevelVisible ? <div className='top-level-six-charts'>
                            <div className='stellhebel-strategie-div'>
                                <div className='stellhebellLevelChart'>
                                    <SwitchableChartRadarBar
                                        leverNumber={'0.'}
                                        height={260}
                                        width={500}
                                        selectChart={selectChart}
                                        selectedCharts={selectedCharts}
                                        informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                                    /*showNextLevel={showSubleverLevelCharts}*/
                                    />
                                </div>
                                <div className='strategieChartDiv'
                                    onClick={(e) => showSubleverLevelCharts(e, '1.')}
                                    title="Click to show sublevers"
                                >
                                    <SwitchableChartRadarBar
                                        leverNumber={'1.'}
                                        height={260}
                                        width={500}
                                        selectChart={selectChart}
                                        selectedCharts={selectedCharts}
                                        informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                                    />
                                </div>
                            </div>
                            <div className='process-projectmanager-div'>
                                <div className='processChartDiv'
                                    onClick={(e) => showSubleverLevelCharts(e, '2.')}
                                    title="Click to show sublevers"
                                >
                                    <SwitchableChartRadarBar
                                        leverNumber={'2.'}
                                        height={260}
                                        width={500}
                                        selectChart={selectChart}
                                        selectedCharts={selectedCharts}
                                        informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                                    />
                                </div>
                                <div className='projectManChartDiv'
                                    onClick={(e) => showSubleverLevelCharts(e, '3.')}
                                    title="Click to show sublevers"
                                >
                                    <SwitchableChartRadarBar
                                        leverNumber={'3.'}
                                        height={260}
                                        width={500}
                                        selectChart={selectChart}
                                        selectedCharts={selectedCharts}
                                        informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                                    />
                                </div>
                            </div>
                            <div className='structure-people-div'>
                                <div className='structureChartDiv'
                                    onClick={(e) => showSubleverLevelCharts(e, '4.')}
                                    title="Click to show sublevers"
                                >
                                    <SwitchableChartRadarBar
                                        leverNumber={'4.'}
                                        height={260}
                                        width={500}
                                        selectChart={selectChart}
                                        selectedCharts={selectedCharts}
                                        informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                                    />
                                </div>
                                <div className='peopleChartDiv'
                                    onClick={(e) => showSubleverLevelCharts(e, '5.')}
                                    title="Click to show sublevers"
                                >
                                    <SwitchableChartRadarBar
                                        leverNumber={'5.'}
                                        height={260}
                                        width={500}
                                        selectChart={selectChart}
                                        selectedCharts={selectedCharts}
                                        informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                                    />
                                </div>
                            </div>

                            <div className='toplevelheatmap'>
                                <HeatMapChart withCharts={true} width={800} height={800} project={props.currentProject}></HeatMapChart>
                            </div>

                        </div> : null}

                        {isSubleverLevelVisible ? <div className='sublever-level'>
                            <div className='sublever-level-container'>
                                <div className='sublevers-charts-div'>
                                    <div className='lever-first-sublever-div'>
                                        <div className='sublever-lever-div'>
                                            <SwitchableChartRadarBar
                                                leverNumber={leverLevelNumber}
                                                height={260}
                                                width={500}
                                                selectChart={selectChart}
                                                selectedCharts={selectedCharts}
                                                informChartDetailsAndValuesUp={saveInformedChartDetailsAndValues}
                                            />
                                        </div>
                                        <div className='first-sublever-div'>
                                            {(subLeversCharts?.length > 0) ? subLeversCharts[0].subleverChart : null}
                                        </div>
                                    </div>
                                    <div className='second-third-sublever-div'>
                                        <div className='second-sublever-div'>
                                            {(subLeversCharts?.length > 0) ? subLeversCharts[1].subleverChart : null}
                                        </div>
                                        <div className='third-sublever-div'>
                                            {(subLeversCharts?.length > 0) ? subLeversCharts[2].subleverChart : null}
                                        </div>
                                    </div>
                                    <div className='fourth-fifth-sublever-div'>
                                        <div className='fourth-sublever-div'>
                                            {(subLeversCharts?.length > 0) ? subLeversCharts[3].subleverChart : null}
                                        </div>
                                        <div className='fifth-sublever-div'>
                                            {(subLeversCharts?.length > 0) ? subLeversCharts[4].subleverChart : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}

                        {isSuccessFactorLevelVisible ? <div className='success-factor-level'>
                            <div className='success-factor-charts-table'>
                                <Table showHeader={false} dataSource={successFactorsCharts} columns={Cols} pagination={false} />
                            </div>
                        </div> : null}

                        {isQuestionLevelVisible ? <div className='question-level'>
                            <div className='questions-charts-table'>
                                <Table showHeader={false} dataSource={questionsCharts} columns={Cols} pagination={false} />
                            </div>
                        </div> : null}
                    </div>
                </div>
            :
            <Navigate to={'/start'} />
    );
}


const mapStateToProps = (state: IGeneralState) => {
    return {
        currentProject: state.generalInfo.profileResponse?.currentProject,
        leverData: state.leverTool.leverData,
        projects: state.generalInfo.allBenchmarkingProjects,
        isLoadBenchmarkingProjects: state.generalInfo?.benchmarkProjectsLoader,
        currentUserId: state.generalInfo.profileResponse?.id,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        exportSelectedChartsToExcel: (exportChartsToExcelModel: any, fileName: string) => dispatch({ type: ActionTypes.ExportSelectedChartsToExcel, exportChartsToExcelModel: exportChartsToExcelModel, fileName: fileName }),
        exportAllChartsToExcel: (fileName: string) => dispatch({ type: ActionTypes.ExportAllChartsToExcel, fileName: fileName })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCharts);