import { useTranslation } from "react-i18next";

interface IProps {
    x?: number;
    y?: number;
    fillColor: string;
    angle: number;
    stroke?: string;
    payload?: any;
    min: number;
    mid: number;
    max: number;
    isminimized: boolean;
}

export function CustomizedYAxisTick(props: IProps) {

    const { t } = useTranslation();

    const { x, y, stroke, payload } = props;

    return (
        <g
            transform=
            {
                `translate(${(payload.value == props.max) ? x - 30 : (payload.value == props.min || payload.value == props.mid) ? x - 30 : x}
            ,${(payload.value == props.max) ? y + 20 : (payload.value == props.min) ? y - 50 : y})`
            }>
            <text fontSize={20} fontWeight={700} x={0} y={0} dy={16} textAnchor="end" fill={props.fillColor} transform={`rotate(${props.angle})`}>
                {(props.isminimized) ?
                    (payload.value == props.min) ? props.min : (payload.value == props.max) ? props.max : null
                    :
                    (payload.value == props.min) ? t("Low") : (payload.value == props.max) ? t("Very high") : null
                }
            </text>
        </g>
    );
};

export function CustomizedXAxisTick(props: IProps) {

    const { t } = useTranslation();

    const { x, y, stroke, payload } = props;

    return (
        <g
            transform=
            {
                `translate(${(payload.value == props.max) ? x : (payload.value == props.min || payload.value == props.mid) ? x + 30 : x}
            ,${(payload.value == props.max) ? y + 10 : (payload.value == props.min) ? y + 10 : y + 10})`
            }>
            <text fontSize={20} fontWeight={700} x={0} y={0} dy={16} textAnchor="end" fill={props.fillColor} transform={`rotate(${props.angle})`}>
                {(props.isminimized) ?
                    (payload.value == props.min) ? props.min : (payload.value == props.max) ? props.max : null
                    :
                    (payload.value == props.min) ? t("Coarse") : (payload.value == props.max) ? t("Very coarse") : null
                }
            </text>
        </g>
    );
}

