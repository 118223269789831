import { Col, InputNumber, Popconfirm, Row, Switch, Table, Tooltip } from "antd";
import { t } from "i18next";
import { ActivityProfileAnalysisView, ProfileGroup, ProfileGroupTask } from "../Survey/Models/ActivitySurveyData";
import { DeleteOutlined } from "@ant-design/icons";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { connect } from 'react-redux';
import TaskAddEditModal from "./TaskAddEditModal";
import { formatValue, tableLocalTranslate } from "../../../../Utils/helperFunctions";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { ActivityGroup } from "../Survey/Models/ActivityGroup";
import { useState } from "react";


interface IProps {
    profile: ActivityProfileAnalysisView;
    deleteActivityToolTask: (id: number) => any;
    editable: boolean;
    isLoading: boolean;
    withResult: boolean;


}

const ProjectProfileTasks = (props: IProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const expandedRowActivitiesRender = (group, task: ProfileGroupTask) => {
        const tasksColumns = [
            {
                title: `${t("Name")}`,
                dataIndex: 'name',
                key: 'activityName',
                render: (text, record, index) => {
                    return `${group.groupSerialNumber}.${task.serialNumberInGroup}.${index + 1} ${text}`
                }
            }
        ];

        const data = task.activities;
        if (!isChecked) return null;
        if (data == null || data.length == 0) return null;
        return <Table indentSize={5}
            size="small" showHeader={false} pagination={false} columns={tasksColumns} dataSource={data} locale={tableLocalTranslate()} />;
    };
    const expandedRowTaskRender = (group: ProfileGroup) => {
        const tasksColumns = [
            {
                title: `${t("Name")}`,
                dataIndex: 'name',
                key: 'taskName',
                width: "50%",
                render: (text, record) => {
                    return `${group.groupSerialNumber}.${record["serialNumberInGroup"]}. ${text}`
                }
            },
            props.editable ? {
                title: `${t("Action")}`,
                dataIndex: 'task_action',
                key: 'task_action',
                render: (_, record) => {
                    return <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", columnGap: "5px" }}>
                        <TaskAddEditModal
                            oldTaskData={record}
                            groups={props.profile?.originalGroups}
                            isADD={false}
                            profileId={props.profile?.activityProfile?.id} />
                        {/* <Popconfirm title={`${t("Sure to Delete")}`}
                            onConfirm={async () => await deleteTask(record?.taskId)}
                            okButtonProps={{ loading: props.isLoading }}
                            getPopupContainer={() => document.getElementById("app-layout")}
                            cancelText={`${t("Cancel")}`} >
                            <Tooltip title={`${t("Delete Task")}`}>
                                <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
                            </Tooltip>
                        </Popconfirm> */}
                    </div>
                }
            } : (props.withResult) ? {
                title: `${t("Average")}`,
                dataIndex: 'task_average',
                key: 'task_average',
                render: (_, record) => {
                    return "";
                    //return `${formatValue(props.profile.finalResult?.tasksResult?.filter(x => x.taskId == record?.taskId)?.[0]?.percentage)}%`;
                }
            } : {
                width: 0
            },
            (props.withResult) ? {
                title: `${t("Hours per week")}`,
                dataIndex: 'task_hours_per_week',
                key: 'task_hours_per_week',
                render: (_, record) => {
                    return `${formatValue(props.profile.finalResult?.tasksResult?.filter(x => x.taskId == record?.taskId)?.[0]?.hoursPerWeek)}`;
                }
            } : {
                width: 0
            },
            (props.withResult) ? {
                title: `${t("Days per month")}`,
                dataIndex: 'task_days_per_month',
                key: 'task_days_per_month',
                render: (_, record) => {
                    return `${formatValue(props.profile.finalResult?.tasksResult?.filter(x => x.taskId == record?.taskId)?.[0]?.daysPerMonth)}`;
                }
            } : {
                width: 0
            },
            (props.withResult) ? {
                title: `${t("Days per year")}`,
                dataIndex: 'task_days_per_year',
                key: 'task_days_per_year',
                render: (_, record) => {
                    return `${formatValue(props.profile.finalResult?.tasksResult?.filter(x => x.taskId == record?.taskId)?.[0]?.daysPerYear)}`;
                }
            } : {
                width: 0
            },
        ];

        const data = group.tasks;
        return <Table
            indentSize={5}
            size="small"
            showHeader={false}
            pagination={false} columns={tasksColumns} dataSource={data} expandable={isChecked ? {
                defaultExpandAllRows: true,
                expandedRowRender: (task) => {
                    return expandedRowActivitiesRender(group, task);
                }
            } : null}
            rowKey="serialNumberInGroup"
            locale={tableLocalTranslate()}
        />;
    };
    const groupsColumns = [
        {
            title: <>{`${t("Name")}`} </>,
            dataIndex: 'name',
            key: 'groupName',
            width: "40%",
            render: (text, record) => {
                return `${record['groupSerialNumber']}. ${text}`;
            }
        },
        props.editable ? {
            title: `${t("Action")}`,
            dataIndex: 'group_action',
            key: 'group_action',
            render: () => {
                return <TaskAddEditModal
                    groups={props.profile?.originalGroups} isADD={true} profileId={props.profile?.activityProfile?.id} />
            }
        } : (props.withResult) ? {
            title: `${t("Average")}`,
            dataIndex: 'group_average',
            key: 'group_average',
            render: (_, record) => {
                return `${formatValue(props.profile.finalResult?.groupsResult?.filter(x => x.groupId == record?.groupId)?.[0]?.percentage)}%`;
            }
        } : {
            width: 0
        },

        (props.withResult) ? {
            title: `${t("Hours per week")}`,
            dataIndex: 'task_hours_per_week',
            key: 'task_hours_per_week',
            render: (_, record) => {
                return "";
            }
        } : {
            width: 0
        },

        (props.withResult) ? {
            title: `${t("Days per month")}`,
            dataIndex: 'task_days_per_month',
            key: 'task_days_per_month',
            render: (_, record) => {
                return "";
            }
        } : {
            width: 0
        },

        (props.withResult) ? {
            title: `${t("Days per year")}`,
            dataIndex: 'task_days_per_year',
            key: 'task_days_per_year',
            render: (_, record) => {
                return "";
            }
        } : {
            width: 0
        },
    ];
    const convertProfileGroupToDataGroup = (group: ActivityGroup): any => {
        return {
            tasks: group.children?.sort((a, b)=> a.serialNumberInGroup - b.serialNumberInGroup).map(c => {
                return {
                    activities: c.children,
                    serialNumberInGroup: c.serialNumberInGroup,
                    name: c.name,
                    nameEn: c.nameEn,
                    nameDe: c.nameDe,
                    activitiesForEdit: c.activities,
                    taskGroupId: c.taskGroupId,
                    key: c.key,
                    taskId: c.taskId,
                    taskSharePercent: 0,
                    daysPerMonth: 0,
                    daysPerYear: 0,
                    hoursPerWeek: 0
                }
            }),
            groupId: group.groupId,
            name: group.name,
            groupSerialNumber: group.groupSerialNumber,
            key: group.key,
            groupAverage: 0
        }
    }

    const dataSource = props.profile?.profileData?.sort((a, b)=> a.groupSerialNumber - b.groupSerialNumber).map((c) => { return convertProfileGroupToDataGroup(c) });
    const deleteTask = async (id: number) => {
        try {
            await props.deleteActivityToolTask(id)
        } catch (error) {
            console.log(error);
        }
    }

    return <>
        {(props.withResult) ?
            <Row>
                <Col span={5} className="col-data-review-activity">
                    {`${t("Total Hours")}:`}
                </Col>
                <Col span={5} className="col-data-review-activity">
                    <InputNumber disabled={true} value={props.profile?.finalResult?.totals?.hoursPerWeek} addonBefore={`${t("Hours per week")}`} />
                </Col>
                <Col span={5} className="col-data-review-activity">
                    <InputNumber disabled={true} value={props.profile?.finalResult?.totals?.daysPerMonth} addonBefore={`${t("Days per month")}`} />
                </Col>
                <Col span={5} className="col-data-review-activity">
                    <InputNumber disabled={true} value={props.profile?.finalResult?.totals?.daysPerYear} addonBefore={`${t("Days per year")}`} />
                </Col>
            </Row> : null}
        <Switch checked={isChecked} checkedChildren={`${t('Show Tasks')}`} unCheckedChildren={`${t('Hide Tasks')}`} defaultChecked
            onChange={(isChecked) => {
                setIsChecked(isChecked);
            }}
        />
        <Table
            scroll={{ y: "calc(100vh - 450px)" }}
            indentSize={5}
            className='table-groups'
            size="small"
            dataSource={dataSource} columns={groupsColumns} pagination={false}
            locale={tableLocalTranslate()}
            expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (group) => {
                    return expandedRowTaskRender(group);
                }
            }}
        />
    </>;
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.activity.isLoading
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteActivityToolTask: (id: number) => dispatch({ type: ActionTypes.DeleteActivityToolTask, id }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectProfileTasks)