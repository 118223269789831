import { dprojectsColumns, questionnaireColsDetails, getColsObjFromLocalStorage } from './DataType/TableCols';
import { connect } from "react-redux";
import { IDProject } from "../../../Models/IDProject";
import { ColumnGroupType, ColumnsType, ColumnType } from "antd/es/table";
import './dprojects.scss';
import ColOptions from "./ColOptions/ColOptions";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import { dprojectInputFormatter, dprojectParser, formatValue, getColumns, getCurrentNode, getLevels, inputFormatter, inputParser, orderAndCopyEbitCosts, orderAndCopyGeneralInfo, orderAndCopyQuestionnaires, splitEbitCosts, splitGeneralInfo, splitQuestionnaire, transformId } from "../../../Utils/helperFunctions";
import { Avatar, Button, Form, Input, InputNumber, List, Modal, Popconfirm, Select, Skeleton, Space, Spin, Table, Tooltip, notification, Checkbox, Tabs } from "antd";
import { PlusOutlined, EditOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined, FileDoneOutlined } from '@ant-design/icons';
import { FixedType } from 'rc-table/lib/interface';
import { Navigate, useNavigate } from 'react-router-dom';
import { BmProject } from '../../../Models/Responses/ProfileResponse';
import { IDprojectToIRequestDproject, IRequestDProject } from '../../../Models/IRequestDProject';
import { emptyDProject } from './DataType/emptyProject';
import { DProjectValidationRules } from './DataType/DProjectValidationRules';
import { ErrorActions } from '../../../Models/Errors/ErrorActions';
import { dprojectEbitCostsType, dprojectGeneralDataType, dProjectQuestionnairesType, QuestionnaireLeverLevelType, QuestionnairesecondLevelType, QuestionnaireSubLeverLevelType, QuestionnairethirdLevelType } from './DataType/types';
import { CommentOutlined } from "@ant-design/icons";
import i18n from '../../../i18n';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IKpiDegree, ILever, ISubLever } from '../../../Models/Levers/Items';
import { useTranslation } from 'react-i18next';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import HelpPage from '../../../Layout/HelpPage';


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    addingDproject: boolean,
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: IDProject;
    index: number;
    children: React.ReactNode;
}

interface IProps {
    fetchingDprojects: boolean;
    tableCols: typeof dprojectsColumns;
    //changeColsOrder: (fromIndex: number, toIndex: number, parentKey?: string) => void;
    currentProject: BmProject;
    setQuestionnaireCols: (questionnaireStruct: dProjectQuestionnairesType) => void;
    dprojectsTableColsInitialized: boolean;
    //filterByLever: (leverId: number) => void;
    dprojectsFetchedSuccess: boolean;
    getDprojects: (id: number) => void;
    dprojects: IDProject[],
    setDprojects: (dprojects: IDProject[]) => void,
    uploadDproject: (dproject: IRequestDProject) => void,
    uploadingDproject: boolean,
    setAddingDproject: (val: boolean) => void
    addingDproject: boolean,
    fetchDprojectComments: (dprojectId: number) => void,
    fetchingDprojectComments: boolean,
    dprojectComments: { id: number, userId: number, name: string, image: string, text: string }[],
    currentUserId: number,
    uploadDprojectComment: (comment: { dprojectId: number, text: string }) => void,
    addingDprojectComment: boolean,
    setAddingDprojectComment: (val: boolean) => void,
    cancelAddComment: () => void,
    uploadingDprojectComment: boolean,
    saveEditComment: (toBeupdatedComment: { Id: number, text: string }, dProjectId: number) => void,
    updatingComment: boolean,
    deleteComment: (id: number, dProjectId: number) => void,
    deleteingComment: boolean,
    updateDproject: (dproject: IRequestDProject) => void,
    updatingDproject: boolean,
    deleteDproject: (dprojectId: number, bm_projectId: number) => void,
    dltingDproject: boolean,
    //
    getDprojectsTypes: () => void,
    gettingDprojectTypes: boolean,
    DprojectTypesFetchedSuccess: boolean,
    types: { label: string, key: number }[]
    //Errors
    showErrorMessage: boolean,
    errorMessage: object | string,
    setShowErrorMessageToFalse: () => void,

    //setIsLoadingToTrue: () => void,
    resetComments: () => void;

    //all new
    dprojectsTable_GeneralInfo: dprojectGeneralDataType,
    dprojectTable_EbitCosts: dprojectEbitCostsType,
    dprojectTable_Questionnare: dProjectQuestionnairesType,
    getIncludeInLevers: () => void,
    dprojectIdsIncludedInLevers: number[],
    uploadIncludeInLevers: (includeInLeversArr: number[]) => void,
    uploadingIncludeInLevers: boolean,
    gettingIncludeInLevers: boolean,
    sortCommentsAssending: () => void,
    sortCommentsDescending: () => void,
    markingDProjectAnalysisAsComplete: boolean,
    dprojectProgress: number,
    markDProjectAnalysisAsComplete: () => void
};


function DProjects(props: IProps) {

    //const [data, setData] = useState(customer_Dprojects);
    //const [addingDproject, setAddingDproject] = useState(false);
    // comments modal state related
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inModalDprojectId, setInModalDprojectId] = useState(-1);
    const [toViewComments, setToViewComments] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [isEdittingComment, setIsEdittingComment] = useState(false);
    const [edittingCommentId, setEdittingCommentId] = useState(-1);
    const [commentText, setCommentText] = useState('');
    //const [addingComment, setAddingComment] = useState(false);
    const [newCommentText, setNewCommentText] = useState('');
    // editting d-project
    const [toBeEdittedDProjectId, setToBeEdittedDProjectId] = useState(-2);
    const [toBeEdittedDataIndex, setToBeEdittedDataIndex] = useState('');
    const [toBeEdittedValue, setToBeEdittedValue] = useState(null);
    const [deleteingCommentId, setDeleteingCommentId] = useState(-1);
    //const [productMargin, setProductMargin] = useState('');
    const [dProjectValidationRules, setdProjectValidationRules] = useState(DProjectValidationRules);
    const [toBeFocusedDataIndex, setToBeFocusedDataIndex] = useState('');
    const [toBeDeleteDprojectId, setToBeDeleteDprojectId] = useState(-1);

    const [includeInLeversArr, setIncludeInLeversArr] = useState([]);
    const [edittingIncludeInLevers, setEdittingIncludeInLevers] = useState(false);
    const [uploadingIncludeInLevers, setUploadingIncludeInLevers] = useState(false);


    const [form] = Form.useForm();

    const navigate = useNavigate();

    const { TextArea } = Input;

    const { Option } = Select;

    const { t } = useTranslation();

    useEffect(() => {
        console.log("props.dprojectComments: ", props.dprojectComments);

    }, [
        props.dprojectComments
    ]);



    useEffect(() => {
        if (props.showErrorMessage) {
            let message: ReactNode = '';
            if (typeof props.errorMessage === 'string') {
                message = props.errorMessage;
            } else if (typeof props.errorMessage === 'object') {
                let requiredKeys = Object.keys(props.errorMessage);
                let divs = requiredKeys.map((key, index) => {
                    if (props.errorMessage[key].length > 0) {
                        return (
                            <div key={index}>
                                <div className='required-title'>
                                    {key}
                                </div>
                                <div className='required_list' >
                                    <ul>
                                        {
                                            props.errorMessage[key].map((msg, index) => {
                                                return (
                                                    <li key={index}>
                                                        {msg}
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                });
                message = divs;
            }
            notification['error']({
                message: <div className='toast_error_title'>{message}</div>,
            });
        }
        return () => {
            props.setShowErrorMessageToFalse();
        };
    }, [props.showErrorMessage]);

    useEffect(() => {
        form.validateFields();
    }, [dProjectValidationRules]);

    useEffect(() => {
        if (!props.dltingDproject) {
            setToBeDeleteDprojectId(-1);
        }
    }, [props.dltingDproject]);

    useEffect(() => {
        if (!props.updatingDproject) {
            setToBeEdittedDProjectId(-2);
        }
    }, [props.updatingDproject]);

    useEffect(() => {
        if (!props.updatingComment) {
            setIsEdittingComment(false);
            setEdittingCommentId(-1);
        }
    }, [props.updatingComment]);

    useEffect(() => {
        if (!props.deleteingComment) {
            setDeleteingCommentId(-1);
        }
    }, [props.deleteingComment]);

    useEffect(() => {
        if (!props.addingDprojectComment) {
            setCommentText('');
        }
    }, [props.addingDprojectComment]);

    const checkIfToAddPercentCharacter = (col: ColumnType<IDProject>): boolean => {
        return (col.dataIndex === 'productMargin') ||
            (col.dataIndex === 'mcShareOfRD') ||
            (col.dataIndex === 'qualityShareOfRD') ||
            (col.dataIndex === 'q1_Revenue_share') ||
            (col.dataIndex === 'q2_Revenue_share') ||
            (col.dataIndex === 'q3_Revenue_share') ||
            (col.dataIndex === 'q4_Deviation') ||
            (col.dataIndex === 'q5_Deviation') ||
            (col.dataIndex === 'q6_Deviation') ||
            (col.dataIndex === 'q17_Deviation') ||
            (col.dataIndex === 'q19_Deviation') ||
            (col.dataIndex === 'q22_MC_Deviation');
    };

    useEffect(() => {
        if (props.currentProject) {
            //1. fetch Dprojects from backend.
            props.getDprojects(props.currentProject.id);
            // fetch dprojects types
            props.getDprojectsTypes();
            //2. initialize columns in store.
            //let initialColumns = dprojectsColumns; // contains general project data and EBIT KPIs.
            let questionaireStartOrder = dprojectsColumns.length;

            let questionnaireStruct: dProjectQuestionnairesType = {
                title: 'Questionnaire Data',
                order: 3,
                visible: true,
                children: []
            };

            let leversdata = props.currentProject.leversData;
            if (leversdata) {
                let questionnaireColsDetails = getColsObjFromLocalStorage('questionnaireColsDetails');
                let leversCodes = Object.keys(questionnaireColsDetails);
                let leverArr: QuestionnaireLeverLevelType[] = [];
                leversCodes.forEach((leverCode, leverCodeIdx) => {
                    let leverLevelItem = questionnaireColsDetails[leverCode];
                    let leverLevels = getLevels(leverCode);
                    let leverNode = getCurrentNode(props.currentProject, leverLevels);
                    if (leverNode) {
                        let subLeversCodes = Object.keys(leverLevelItem).filter(key => (key !== 'id') && (key !== 'order') && (key !== 'visible'));
                        let subLeversArr: QuestionnaireSubLeverLevelType[] = [];

                        subLeversCodes.forEach((subLeverCode, subLeverCodeIdx) => {
                            let subLeverItem = leverLevelItem[subLeverCode];
                            let subLeverLevels = getLevels(subLeverCode);
                            let subLeverNode = getCurrentNode(props.currentProject, subLeverLevels);
                            if (subLeverNode) {
                                let secondLevelCodes = Object.keys(subLeverItem).filter(key => (key !== 'id') && (key !== 'order') && (key !== 'visible'));
                                let secondLevelArr: QuestionnairesecondLevelType[] = [];

                                secondLevelCodes.forEach((secondLevelCode, secondLevelIdIdx) => {
                                    let secondLevelItem = subLeverItem[secondLevelCode];
                                    let secondLevelLevels = getLevels(secondLevelCode);
                                    let secondLevelNode = getCurrentNode(props.currentProject, secondLevelLevels);

                                    if (secondLevelNode) {

                                        let currentProjectKPI_name = (secondLevelNode as IKpiDegree).name;
                                        let parentColKPI_ID = secondLevelCode;
                                        let parentColKPI_Name = currentProjectKPI_name;
                                        let parentColTitle = parentColKPI_ID + '\n' + parentColKPI_Name;
                                        let parentColKey = parentColKPI_ID + parentColKPI_Name;
                                        let KPI_Questions = (secondLevelNode as IKpiDegree).questions;
                                        let KPI_Question: string = '';
                                        if (KPI_Questions && KPI_Questions.length > 0) {
                                            KPI_Question = KPI_Questions[0].text
                                        }

                                        let thirdLevelKeys = Object.keys(secondLevelItem).filter(key => (key !== 'id') && (key !== 'order') && (key !== 'visible'));
                                        let thirdLevelArr: QuestionnairethirdLevelType[] = [];

                                        thirdLevelKeys.forEach((thirdLevelKey, thirdLevelKeyIdx) => {
                                            let width: number;
                                            if (checkIfToAddPercentCharacter(secondLevelItem[thirdLevelKey])) {
                                                width = 90;
                                            } else {
                                                width = 150;
                                            }

                                            let thirdLevelItem = secondLevelItem[thirdLevelKey];
                                            thirdLevelArr.push({
                                                title: thirdLevelItem.title,
                                                dataIndex: thirdLevelItem.dataIndex,
                                                key: thirdLevelItem.key,
                                                width: width,
                                                className: `q_${secondLevelItem.id}`,
                                                visible: thirdLevelItem.visible,
                                                order: thirdLevelItem.order,
                                            });
                                        });

                                        secondLevelArr.push({
                                            id: secondLevelCode,
                                            title: parentColTitle,
                                            key: parentColKey,
                                            className: `q_${secondLevelItem.id}`,
                                            visible: secondLevelItem.visible,
                                            order: secondLevelItem.order,
                                            fixed: false as FixedType,
                                            onHeaderCell: (column) => {
                                                return {
                                                    onMouseEnter: (e) => { /*//console.log('col_enter: ', e)*/ },
                                                    onMouseLeave: (e) => { /*//console.log('col_leave: ', e)*/ },
                                                    title: KPI_Question
                                                };
                                            },
                                            children: thirdLevelArr
                                        });
                                    }
                                });

                                subLeversArr.push({
                                    id: transformId(subLeverItem.id),
                                    title: (subLeverNode as ISubLever).name,
                                    order: subLeverItem.order,//subLeverCodeIdx,
                                    visible: subLeverItem.visible,
                                    children: secondLevelArr
                                });

                            }
                        });

                        leverArr.push({
                            id: transformId(leverLevelItem.id),
                            title: (leverNode as ILever).name,
                            order: leverLevelItem.order,//leverCodeIdx,
                            visible: leverLevelItem.visible,
                            children: subLeversArr
                        });
                    }
                });
                questionnaireStruct.children = leverArr;
            } else {
                console.error('CurrentProject Levers data does not exist.');
            }

            props.setQuestionnaireCols(questionnaireStruct);

            //initialize includeInLeversArr
            props.getIncludeInLevers();
            return () => {
                //props.setIsLoadingToTrue();
            };
        } else {
        }
    }, []);

    useEffect(() => {
        if (props.currentProject) {
            //1. fetch Dprojects from backend.
            //props.getDprojects(props.currentProject.id);
            // fetch dprojects types
            props.getDprojectsTypes();
            //2. initialize columns in store.
            //let initialColumns = dprojectsColumns; // contains general project data and EBIT KPIs.

            let questionnaireStruct: dProjectQuestionnairesType = {
                title: 'Questionnaire Data',
                order: 3,
                visible: true,
                children: []
            };

            let leversdata = props.currentProject.leversData;
            if (leversdata) {
                let questionnaireColsDetails = getColsObjFromLocalStorage('questionnaireColsDetails');
                let leversCodes = Object.keys(questionnaireColsDetails);
                let leverArr: QuestionnaireLeverLevelType[] = [];
                leversCodes.forEach((leverCode, leverCodeIdx) => {
                    let leverLevelItem = questionnaireColsDetails[leverCode];
                    let leverLevels = getLevels(leverCode);
                    let leverNode = getCurrentNode(props.currentProject, leverLevels);
                    if (leverNode) {
                        let subLeversCodes = Object.keys(leverLevelItem).filter(key => (key !== 'id') && (key !== 'order') && (key !== 'visible'));
                        let subLeversArr: QuestionnaireSubLeverLevelType[] = [];

                        subLeversCodes.forEach((subLeverCode, subLeverCodeIdx) => {
                            let subLeverItem = leverLevelItem[subLeverCode];
                            let subLeverLevels = getLevels(subLeverCode);
                            let subLeverNode = getCurrentNode(props.currentProject, subLeverLevels);
                            if (subLeverNode) {
                                let secondLevelCodes = Object.keys(subLeverItem).filter(key => (key !== 'id') && (key !== 'order') && (key !== 'visible'));
                                let secondLevelArr: QuestionnairesecondLevelType[] = [];

                                secondLevelCodes.forEach((secondLevelCode, secondLevelIdIdx) => {
                                    let secondLevelItem = subLeverItem[secondLevelCode];
                                    let secondLevelLevels = getLevels(secondLevelCode);
                                    let secondLevelNode = getCurrentNode(props.currentProject, secondLevelLevels);

                                    if (secondLevelNode) {

                                        let currentProjectKPI_name = (secondLevelNode as IKpiDegree).name;
                                        let parentColKPI_ID = secondLevelCode;
                                        let parentColKPI_Name = currentProjectKPI_name;
                                        let parentColTitle = parentColKPI_ID + '\n' + parentColKPI_Name;
                                        let parentColKey = parentColKPI_ID + parentColKPI_Name;
                                        let KPI_Questions = (secondLevelNode as IKpiDegree).questions;
                                        let KPI_Question: string = '';
                                        if (KPI_Questions && KPI_Questions.length > 0) {
                                            KPI_Question = KPI_Questions[0].text
                                        }

                                        let thirdLevelKeys = Object.keys(secondLevelItem).filter(key => (key !== 'id') && (key !== 'order') && (key !== 'visible'));
                                        let thirdLevelArr: QuestionnairethirdLevelType[] = [];

                                        thirdLevelKeys.forEach((thirdLevelKey, thirdLevelKeyIdx) => {
                                            let width: number;
                                            if (checkIfToAddPercentCharacter(secondLevelItem[thirdLevelKey])) {
                                                width = 90;
                                            } else {
                                                width = 150;
                                            }

                                            let thirdLevelItem = secondLevelItem[thirdLevelKey];
                                            thirdLevelArr.push({
                                                title: thirdLevelItem.title,
                                                dataIndex: thirdLevelItem.dataIndex,
                                                key: thirdLevelItem.key,
                                                width: width,
                                                className: `q_${secondLevelItem.id}`,
                                                visible: secondLevelItem.visible,
                                                order: secondLevelItem.order,
                                            });
                                        });

                                        secondLevelArr.push({
                                            id: secondLevelCode,
                                            title: parentColTitle,
                                            key: parentColKey,
                                            className: `q_${secondLevelItem.id}`,
                                            visible: secondLevelItem.visible,
                                            order: secondLevelItem.order,
                                            fixed: false as FixedType,
                                            onHeaderCell: (column) => {
                                                return {
                                                    onMouseEnter: (e) => { /*//console.log('col_enter: ', e)*/ },
                                                    onMouseLeave: (e) => { /*//console.log('col_leave: ', e)*/ },
                                                    title: KPI_Question
                                                };
                                            },
                                            children: thirdLevelArr
                                        });
                                    }
                                });

                                subLeversArr.push({
                                    id: transformId(subLeverItem.id),
                                    title: (subLeverNode as ISubLever).name,
                                    order: subLeverItem.order,
                                    visible: subLeverItem.visible,
                                    children: secondLevelArr
                                });

                            }
                        });

                        leverArr.push({
                            id: transformId(leverLevelItem.id),
                            title: (leverNode as ILever).name,
                            order: leverLevelItem.order,
                            visible: leverLevelItem.visible,
                            children: subLeversArr
                        });
                    }
                });
                questionnaireStruct.children = leverArr;
                console.log("questionnaireStruct: ", questionnaireStruct);

            } else {
                console.error('CurrentProject Levers data does not exist.');
            }

            props.setQuestionnaireCols(questionnaireStruct);

            //initialize includeInLeversArr
            props.getIncludeInLevers();
            return () => {
                //props.setIsLoadingToTrue();
            };
        } else {
        }
    }, [props.currentProject]);

    useEffect(() => {
        setIncludeInLeversArr(props.dprojectIdsIncludedInLevers);
        setEdittingIncludeInLevers(false);
    }, [props.dprojectIdsIncludedInLevers]);

    useEffect(() => {
        setUploadingIncludeInLevers(props.uploadingIncludeInLevers);
        if (!props.uploadingIncludeInLevers)
            setEdittingIncludeInLevers(false);
    }, [props.uploadingIncludeInLevers]);

    useEffect(() => {
        if (!props.addingDprojectComment) {
            setCommentText('');
            setNewCommentText('');
            setIsEdittingComment(false);
            setEdittingCommentId(-1);
        }
    }, [props.addingDprojectComment]);

    if (props.dprojectsTableColsInitialized && props.dprojectsFetchedSuccess && props.DprojectTypesFetchedSuccess) { // after initializing dprojectsTableCols in store by useEffect hook.

        // //order.
        let Ordered_GeneralInfo = orderAndCopyGeneralInfo(props.dprojectsTable_GeneralInfo);
        let Ordered_EbitCosts = orderAndCopyEbitCosts(props.dprojectTable_EbitCosts);
        let Ordered_Questionnare = orderAndCopyQuestionnaires(props.dprojectTable_Questionnare);
        //splite shown/hidden.
        let [Shown_GeneralInfo, Hidden_GeneralInfo] = splitGeneralInfo(Ordered_GeneralInfo);
        let [shown_EbitCosts, Hidden_EbitCosts] = splitEbitCosts(Ordered_EbitCosts);
        let [Shown_Questionnaires, Hidden_Questionnaires] = splitQuestionnaire(Ordered_Questionnare);

        //console.log('Shown_Questionnaires: ', Shown_Questionnaires);


        let columns: ColumnsType<IDProject> = [];
        let hiddenColumns: typeof dprojectsColumns = [];

        columns = getColumns(
            Shown_GeneralInfo,
            shown_EbitCosts,
            Shown_Questionnaires
        );
        const isBtnsDisabled = (): boolean => {
            return props.uploadingDproject ||
                props.updatingDproject ||
                props.dltingDproject ||
                props.uploadingIncludeInLevers
        }

        // const changeOrder = (fromIndex: number, toIndex: number, parentKey?: string) => {
        //     if (parentKey) {
        //         props.changeColsOrder(fromIndex, toIndex, parentKey);
        //     } else {
        //         props.changeColsOrder(fromIndex, toIndex);
        //     }
        // };

        const saveIncludeInLevers = async () => {
            props.uploadIncludeInLevers(includeInLeversArr);
        }

        const editIncludeInLevers = () => {
            setEdittingIncludeInLevers(true);
        };
        const cancelEditIncludeInLevers = () => {
            props.getIncludeInLevers();

        };
        const includeInLevers = (e: CheckboxChangeEvent, dprojectId: number) => {

            if (e.target.checked) {
                setIncludeInLeversArr((includeInLeversArr) => {
                    let newIncludeInLeversArr = [...includeInLeversArr];
                    newIncludeInLeversArr.push(dprojectId);
                    return newIncludeInLeversArr;
                });
            } else {
                setIncludeInLeversArr((includeInLeversArr) => {
                    let newIncludeInLeversArr = [...includeInLeversArr];
                    return newIncludeInLeversArr.filter(dpId => dpId !== dprojectId);
                });
            }
        }
        //add some properties to cols and thier children, like detect new lines in title, add onCell property which adds customizable properties to each cell -->
        //--> to be exploited in EditableCell components which will override normal table cell
        //console.log('columns2255: ', columns);

        const mergedColumns: ColumnsType<IDProject> = columns.map((col: ColumnType<IDProject>) => {

            return {
                ...col,
                align: 'center',
                width: 150,
                title: col.title.toString().split('\n').map((s, idx) => <span key={idx}>
                    {s}
                    <br />
                </span>),
                children: (col as ColumnGroupType<IDProject>).children.map((child: ColumnType<IDProject>) => {
                    return {
                        ...child,
                        align: 'center',
                        onCell: (record: IDProject, rowIndex: number) => {
                            //console.log('datatype fro: ', child.dataIndex.toString(), typeof record[child.dataIndex.toString()]);

                            return {
                                record,
                                inputType: typeof record[child.dataIndex.toString()] === 'string' ? 'text' : 'number',
                                dataIndex: child.dataIndex.toString(),
                                title: child.title.toString(),
                                editing: isEditing(record),
                                addingDproject: isAddingDproject(record)
                            }
                        },
                        render: (value, row, index) => {
                            if (value !== null || typeof (value) !== 'undefined') {
                                if ((typeof (value) == 'number') && (child.dataIndex != 'finishingYear')) {
                                    return <span>{`${formatValue(value)}` + (checkIfToAddPercentCharacter(child) ? "%" : "")}</span>;
                                } else {
                                    return <span>{value}</span>;;
                                }
                            }
                        },
                    };
                }),
            };
        });
        const editDproject = (record: IDProject) => {
            if (!isBtnsDisabled()) {
                let { projectId, id, key, ...formItemsFieldsNames } = { ...emptyDProject };
                Object.keys(formItemsFieldsNames).forEach((dataIndex) => {
                    form.setFieldValue(dataIndex, ((record[dataIndex] == null) || (typeof (record[dataIndex]) === 'undefined')) ? '' : record[dataIndex]); // undefined wrong because form input item witll change from being controlled to be uncontrolled. Null wrong because of compilation error to controll input form with null. So input form item should be cleared with empty string.
                });
                setToBeEdittedDProjectId(record.id);
            }
        };
        const deleteDproject = (record: any) => {
            if (!isBtnsDisabled()) {
                setToBeDeleteDprojectId(record.id);
                props.deleteDproject(record.id, props.currentProject.id);
            }
        };
        // Add Operations column (Edit d-project, Delete d-project) to the mergedColumns
        mergedColumns.unshift({
            title: '',
            key: 'operations',
            className: 'd_projects_operations_col',
            width: 60,
            fixed: 'left' as FixedType,
            onCell: (record: IDProject, rowIndex: number) => {
                return {
                    record,
                    inputType: "Actions",
                    dataIndex: "Actions",
                    title: "",
                    editing: false,
                    addingDproject: false
                }
            },
            render: (_: any, record: IDProject) => {
                //console.log('render record: ', record);
                let operationCellContent;
                if (record.id !== -1) {
                    if (record.id !== toBeEdittedDProjectId) {
                        operationCellContent = <div className='d_projects_operations_div'>
                            <div className='d_projects_edit_Btn_div'>
                                {/* <Popconfirm title="Sure to edit?" onConfirm={() => editDproject(record)}>
                                    <a><EditOutlined style={{ 'color': 'green' }} /></a>
                                </Popconfirm> */}
                                <a
                                    title={t('View comments')}
                                    onClick={() => viewComments(record.comments, record.id, record.name)}>
                                    <CommentOutlined style={{ 'color': 'blue', 'fontSize': '14' }} />
                                </a>
                            </div>
                            <div className='d_projects_edit_Btn_div'>
                                {/* <Popconfirm title="Sure to edit?" onConfirm={() => editDproject(record)}>
                                    <a><EditOutlined style={{ 'color': 'green' }} /></a>
                                </Popconfirm> */}
                                <a
                                    title={t('Edit d-project')}
                                    onClick={() => editDproject(record)}>
                                    <EditOutlined style={{ 'color': 'green' }} />
                                </a>
                            </div>
                            <div className='d_projects_delete_Btn_div'>
                                {(props.dltingDproject && record.id == toBeDeleteDprojectId) ? <Spin size='small' /> : <Popconfirm title={t("Sure to delete?")} onConfirm={() => deleteDproject(record)}>
                                    <a title={t('Delete d-project')}>
                                        <DeleteOutlined style={{ 'color': 'red' }} />
                                    </a>
                                </Popconfirm>}
                            </div>
                            <div className='d_projects_includeinlevers_check_div'>
                                <Checkbox onChange={(e) => includeInLevers(e, record.id)} checked={includeInLeversArr?.includes(record.id)} disabled={!edittingIncludeInLevers} />
                            </div>
                        </div>;
                    } else {
                        operationCellContent = <div className='custom_cell_Btns'>
                            <div className='confirm_Edit_Btn_div'>
                                {props.updatingDproject ? <Spin size='small' /> : <Popconfirm title={t("Confirm edit?")} onConfirm={() => confirmEdit()}>
                                    <Button
                                        title={t('Edit d-project')}
                                        disabled={isBtnsDisabled()}
                                        onClick={() => { }}
                                        className='confirm_Edit_Btn'
                                        size='small'
                                        shape='default'
                                        icon={<CheckOutlined />} />
                                </Popconfirm>}
                            </div>
                            <div className='cancel_Edit_Btn_div'>
                                <Popconfirm title={t("Cancel edit?")} onConfirm={() => cancelEdit()}>
                                    <Button
                                        title={t('Cancel edit')}
                                        disabled={isBtnsDisabled()}
                                        onClick={() => { }}
                                        className='cancel_Edit_Btn'
                                        size='small'
                                        shape='default'
                                        icon={<CloseOutlined />} />
                                </Popconfirm>
                            </div>
                        </div>;
                    }
                } else {
                    operationCellContent = null;
                }
                return operationCellContent;
            },
            children: []
        });
        const viewComments = (comments: { id: number, userId: number, name: string, text: string }[], DprojectId: number, DprojectName: string) => {

            setModalTitle(`${t("Comments on")} ${DprojectName}`);
            setInModalDprojectId(DprojectId);
            setIsModalVisible(true);
            props.fetchDprojectComments(DprojectId)
            //setToViewComments(comments); from reducer
        };

        const changeCellValue = (e) => {
            //console.log('e.target.value: ', e);
            if (typeof e === 'number') {
                setToBeEdittedValue(e);
            } else {
                setToBeEdittedValue(e.target.value);
            }
            //setToBeEdittedValue(e);
        };

        const confirmEdit = async () => {
            try {
                const row = (await form.validateFields()) as IDProject;
                //console.log('confirmEdit row: ', row);

                let edittedDProject: IDProject;
                if (row.hasOwnProperty('undefinedKPI')) {
                    delete row['undefinedKPI'];
                    edittedDProject = { ...row, id: toBeEdittedDProjectId, projectId: props.currentProject.id, key: toBeEdittedDProjectId, comments: [] }; //
                    let toUploadDproject: IRequestDProject = IDprojectToIRequestDproject(edittedDProject);
                    //console.log('toUploadDproject: ', toUploadDproject);
                    props.updateDproject(toUploadDproject);
                } else {
                    edittedDProject = { ...row, id: toBeEdittedDProjectId, projectId: props.currentProject.id, key: toBeEdittedDProjectId, comments: [] };
                    console.log('edittedDProject: ', edittedDProject);

                    let toUploadDproject: IRequestDProject = IDprojectToIRequestDproject(edittedDProject);
                    console.log('toUploadDproject: ', toUploadDproject);
                    props.updateDproject(toUploadDproject);
                }
            } catch (error) {
                console.error('Err Validating Dproject fields: ', error);
            }
        };
        const cancelEdit = () => {
            setToBeEdittedDProjectId(-2);
        };

        const isFieldEmpty = (dataIndex: string) => {
            return ((form.getFieldValue(dataIndex) === null) || (form.getFieldValue(dataIndex) === '') || (typeof form.getFieldValue(dataIndex) === 'undefined'));
        };

        const onProjectTypeChange = (e) => {
            let revenueRequired: boolean = !(isFieldEmpty('productMargin') && isFieldEmpty('projectType'));
            let productMarginRequired: boolean = !(isFieldEmpty('revenue') && isFieldEmpty('projectType'));
            let projectTypeRequired: boolean = !(isFieldEmpty('revenue') && isFieldEmpty('productMargin'));
            setdProjectValidationRules(dProjectValidationRules => { return { ...dProjectValidationRules, productMargin: productMarginRequired, projectType: projectTypeRequired, revenue: revenueRequired } });
        };

        const formItemChangedValue = (e: ChangeEvent<HTMLInputElement> | number | null, dataIndex: string) => {

            //to not loose focus after rerender
            setToBeFocusedDataIndex(dataIndex);

            if (dataIndex === 'revenue' || dataIndex === 'productMargin' || dataIndex === 'projectType') {
                let revenueRequired: boolean = !(isFieldEmpty('productMargin') && isFieldEmpty('projectType'));
                let productMarginRequired: boolean = !(isFieldEmpty('revenue') && isFieldEmpty('projectType'));
                let projectTypeRequired: boolean = !(isFieldEmpty('revenue') && isFieldEmpty('productMargin'));
                setdProjectValidationRules(dProjectValidationRules => { return { ...dProjectValidationRules, productMargin: productMarginRequired, projectType: projectTypeRequired, revenue: revenueRequired } });
            } else if (dataIndex === 'delay' || dataIndex === 'missedExpectedSales' || dataIndex === 'projectCostsDelayedMonths' || dataIndex === 'otherAdditionalCosts') {
                let delayRequired: boolean = !(isFieldEmpty('missedExpectedSales') && isFieldEmpty('projectCostsDelayedMonths') && isFieldEmpty('otherAdditionalCosts'));
                let missedExpectedSalesRequired: boolean = !(isFieldEmpty('delay') && isFieldEmpty('projectCostsDelayedMonths') && isFieldEmpty('otherAdditionalCosts'));
                let projectCostsDelayedMonthsRequired: boolean = !(isFieldEmpty('delay') && isFieldEmpty('missedExpectedSales') && isFieldEmpty('otherAdditionalCosts'));
                let otherAdditionalCostsRequired: boolean = !(isFieldEmpty('delay') && isFieldEmpty('missedExpectedSales') && isFieldEmpty('projectCostsDelayedMonths'));
                setdProjectValidationRules(dProjectValidationRules => { return { ...dProjectValidationRules, delay: delayRequired, missedExpectedSales: missedExpectedSalesRequired, projectCostsDelayedMonths: projectCostsDelayedMonthsRequired, otherAdditionalCosts: otherAdditionalCostsRequired } });

            } else if (dataIndex === 'mcPlanned' || dataIndex === 'mcActual' || dataIndex === 'quantity' || dataIndex === 'otherCosts' || dataIndex === 'mcShareOfRD') {
                let mcPlannedRequired = !(isFieldEmpty('mcActual') && isFieldEmpty('quantity') && isFieldEmpty('otherCosts') && isFieldEmpty('mcShareOfRD'));
                let mcActualRequired = !(isFieldEmpty('mcPlanned') && isFieldEmpty('quantity') && isFieldEmpty('otherCosts') && isFieldEmpty('mcShareOfRD'));
                let quantityRequired = !(isFieldEmpty('mcActual') && isFieldEmpty('mcPlanned') && isFieldEmpty('otherCosts') && isFieldEmpty('mcShareOfRD'));
                let otherCostsRequired = !(isFieldEmpty('mcActual') && isFieldEmpty('mcPlanned') && isFieldEmpty('quantity') && isFieldEmpty('mcShareOfRD'));
                let mcShareOfRDRequired = !(isFieldEmpty('mcActual') && isFieldEmpty('mcPlanned') && isFieldEmpty('quantity') && isFieldEmpty('otherCosts'));
                setdProjectValidationRules(dProjectValidationRules => { return { ...dProjectValidationRules, mcPlanned: mcPlannedRequired, mcActual: mcActualRequired, quantity: quantityRequired, otherCosts: otherCostsRequired, mcShareOfRD: mcShareOfRDRequired } });
            } else if (dataIndex === 'qualityCosts' || dataIndex === 'qualityShareOfRD') {
                let qualityCostsRequired = !(isFieldEmpty('qualityShareOfRD'));
                let qualityShareOfRDRequired = !(isFieldEmpty('qualityCosts'));
                setdProjectValidationRules(dProjectValidationRules => { return { ...dProjectValidationRules, qualityCosts: qualityCostsRequired, qualityShareOfRD: qualityShareOfRDRequired } });
            } else {
                setdProjectValidationRules(DProjectValidationRules);
            }
        }


        const isColumnEditable = (dataIndex: string): boolean => {
            if (
                dataIndex === 'q1_Total_Sales_in_N' ||
                dataIndex === 'q1_Revenue_share' ||
                dataIndex === 'q2_Total_Sales_in_N' ||
                dataIndex === 'q2_Revenue_share' ||
                dataIndex === 'q3_Total_Sales_in_N' ||
                dataIndex === 'q3_Revenue_share' ||
                dataIndex === 'q4_Deviation' ||
                dataIndex === 'q5_Deviation' ||
                dataIndex === 'q6_Deviation' ||
                dataIndex === 'q17_Deviation' ||
                dataIndex === 'q19_Planned_project_runtime' ||
                dataIndex === 'q19_Deviation' ||
                dataIndex === 'q21_Budget_Deviation' ||
                dataIndex === 'q22_MC_Deviation'
                
            )
                return false;
            return true;
        };

        const isColumnDeviation = (dataIndex: string): boolean => {
            if (
                dataIndex === 'q4_Deviation' ||
                dataIndex === 'q5_Deviation' ||
                dataIndex === 'q6_Deviation' ||
                dataIndex === 'q17_Deviation' ||
                dataIndex === 'q19_Deviation' || 
                dataIndex === 'q22_MC_Deviation'
            )
                return true;
            return false;
        };
        const calculateDeviation = (dataIndex: string, record: IDProject): number => {
            switch (dataIndex) {
                case 'q4_Deviation':
                    if (!record['q4_Target_price'] || record['q4_Target_price'] === 0) return 0;
                    return (record['q4_Actual_price'] / record['q4_Target_price']) - 1;
                case 'q5_Deviation':
                    if (!record['q5_Planned_quantities'] || record['q5_Planned_quantities'] === 0) return 0;
                    return (record['q5_Actual_quantities'] / record['q5_Planned_quantities']) - 1;
                case 'q6_Deviation':
                    if (!record['q6_Planned_project_costs'] || record['q6_Planned_project_costs'] === 0) return 0;
                    return (record['actualProjectCosts'] / record['q6_Planned_project_costs']) - 1;
                case 'q17_Deviation':
                    if (!record['q17_Planned_revenue'] || record['q17_Planned_revenue'] === 0) return 0;
                    return (record['q17_Actual_revenue'] / record['q17_Planned_revenue']) - 1;
                case 'q19_Deviation':
                    if (!record['q19_Planned_project_runtime'] || record['q19_Planned_project_runtime'] === 0) return 0;
                    return (record['runTime'] / record['q19_Planned_project_runtime']) - 1;
                case 'q22_MC_Deviation':
                    if (!record['mcPlanned'] || record['mcPlanned'] === 0) return 0;
                    return (record['mcActual'] / record['mcPlanned']) - 1;

                default:
                    break;
            }
            return 0;
        };

        const formattableInput = (dataIndex: string): boolean => {
            if (dataIndex === 'finishingYear')
                return false;
            return true;
        };

        const markDProjectAnalysisAsComplete = () => {
            console.log("markDProjectAnalysisAsComplete");

            props.markDProjectAnalysisAsComplete();
        };

        const EditableCell: React.FC<EditableCellProps> = ({
            addingDproject,
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        }) => {
            if (dataIndex === 'projectType')
                return <td {...restProps} >
                    {addingDproject ? <Form.Item
                        key={dataIndex}
                        name={dataIndex}
                        style={{ margin: 0 }}

                        rules={[
                            {
                                required: dProjectValidationRules[dataIndex],
                                message: <div className='validation-message'>*</div>,
                            },
                        ]}>
                        {/* <ProjectTypeDropdown selectedTypeKey='-1' selectType={(key: string) => //console.log('selected key: ', key)} /> */}
                        <Select
                            placeholder="Select"
                            onChange={onProjectTypeChange}
                            allowClear
                        >
                            {props.types.map(type => {
                                return <Option key={type.key} value={type.key}>{type.label}</Option>
                            })}
                        </Select>
                    </Form.Item> :
                        !editing ? (<div className="custom-cell_div">
                            <div className="custom-cell_children_div">
                                {
                                    //children
                                    props.types.find(type => type.key === record[dataIndex])?.label//
                                }
                            </div>
                        </div>) :
                            (
                                <div className='custom_cell_Input_div'>
                                    <div className='custom_cell_form_item'>
                                        <Form.Item
                                            key={dataIndex}
                                            name={dataIndex}
                                            //initialValue={record[dataIndex]}
                                            style={{ margin: 0 }}
                                            rules={[
                                                {
                                                    required: dProjectValidationRules[dataIndex],
                                                    message: <div className='validation-message'>*</div>,
                                                },
                                            ]}>
                                            {
                                                <Select
                                                    placeholder="Select"
                                                    onChange={onProjectTypeChange}
                                                    allowClear
                                                >
                                                    {props.types.map(type => {
                                                        return <Option key={type.key} value={type.key}>{type.label}</Option>
                                                    })}
                                                </Select>
                                            }
                                        </Form.Item>
                                    </div>
                                </div>
                            )
                    }
                </td>;

            if (addingDproject && isColumnEditable(dataIndex))
                return <td {...restProps} >
                    <Form.Item
                        key={dataIndex}
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: dProjectValidationRules[dataIndex],
                                message: <div className='validation-message'>*</div>
                            },
                        ]}>
                        {dataIndex === 'name' ? <Input className='edit_input_str' autoFocus={dataIndex === toBeFocusedDataIndex} onChange={(e) => formItemChangedValue(e, dataIndex)} /> :
                            <InputNumber
                                formatter={formattableInput(dataIndex) ? (value: number) => {
                                    // typeof value is always string (surprisingly !!)
                                    // if value = null or value = undefined, then value.toString() = ''
                                    return dprojectInputFormatter(value);
                                } : null}
                                parser={formattableInput(dataIndex) ? x => dprojectParser(x, i18n.language) : null}
                                controls={false}

                                autoFocus={dataIndex === toBeFocusedDataIndex}
                                className='edit_input_num'
                                onChange={(e: number) => formItemChangedValue(e, dataIndex)}
                            />}
                    </Form.Item>
                </td>;
            //
            if (editing && isColumnEditable(dataIndex))
                return <td {...restProps} >
                    <div className='custom_cell_Input_div'>
                        <div className='custom_cell_form_item'>
                            <Form.Item
                                key={dataIndex}
                                name={dataIndex}
                                initialValue={record[dataIndex]}
                                style={{ margin: 0 }}
                                rules={[
                                    {
                                        required: dProjectValidationRules[dataIndex],
                                        message: <div className='validation-message'>*</div>
                                    }
                                ]}>
                                {
                                    (dataIndex === 'name') ? <Input className='edit_input_str' autoFocus={dataIndex === toBeFocusedDataIndex} onChange={(e) => formItemChangedValue(e, dataIndex)} /> :
                                        <InputNumber
                                            //addonAfter={<></>}
                                            formatter={formattableInput(dataIndex) ? (value: number) => {
                                                // typeof value is always string (surprisingly !!)
                                                // if value = null or value = undefined, then value.toString() = ''
                                                return dprojectInputFormatter(value);
                                            } : null}
                                            parser={formattableInput(dataIndex) ? x => dprojectParser(x, i18n.language) : null}
                                            controls={false}
                                            className='edit_input_num'
                                            autoFocus={dataIndex === toBeFocusedDataIndex}
                                            onChange={(e: number) => formItemChangedValue(e, dataIndex)}
                                        />
                                    // <Input className='edit_input_str' /*value={record[dataIndex]} onFocus={} onChange={changeCellValue} */ />
                                }
                            </Form.Item>
                        </div>
                    </div>
                </td>;
            //
            if (isColumnDeviation(dataIndex)) {
                <td {...restProps} >
                    <div className="custom-cell_div">
                        <div className="custom-cell_children_div">
                            {calculateDeviation(dataIndex, record)}
                        </div>
                    </div>
                </td>;
            }
            return <td {...restProps} >
                <div className="custom-cell_div">
                    <div className="custom-cell_children_div">
                        {children}
                    </div>
                </div>
            </td>;
        };

        // const filterByLever = (leverId: number) => {
        //     props.filterByLever(leverId);
        // };

        const addDProject = () => {
            setToBeEdittedDProjectId(-2); // cancel edit
            let newData = [...props.dprojects];
            newData.unshift({ ...emptyDProject, projectId: props.currentProject.id, id: -1 });
            Object.keys(emptyDProject).forEach((dataIndex) => {
                form.setFieldValue(dataIndex, emptyDProject[dataIndex])
            });
            //form.resetFields(Object.keys(emptyDProject));
            props.setDprojects(newData);
            props.setAddingDproject(true);
        };

        const cancelAddDProject = () => {
            let newData = [...props.dprojects];
            newData.shift();
            props.setDprojects(newData);
            props.setAddingDproject(false);
        };

        const isAddingDproject = (record: IDProject) => {
            return (record.id === -1);
        };

        const isEditing = (record: IDProject) => {
            return (record.id === toBeEdittedDProjectId);
        };

        // const maxDprojectId = (data: IDProject[]) => {
        //     let ids = data.map(d => d.id);
        //     //console.log('ids: ', ids);

        //     let maxId = Math.max(...ids);
        //     ////console.log('maxId: ', maxId);

        //     return maxId;
        // }

        // const maxCommentId = (data: IDProject[]): number => {
        //     let ids = data.map(dp => {
        //         return dp.comments.map(comment => comment.id)
        //     });
        //     let commentsIds: number[] = [];
        //     ids.forEach(array => {
        //         commentsIds.push(...array);
        //     });
        //     let maxId = Math.max(...commentsIds);
        //     ////console.log('maxId: ', maxId);

        //     return maxId;
        // }


        const Save = async () => {
            if (props.addingDproject) {
                try {
                    const row = (await form.validateFields()) as IDProject;
                    ////console.log('row: ', row);

                    let newDProject: IDProject;
                    if (row.hasOwnProperty('undefinedKPI')) {
                        delete row['undefinedKPI'];
                        newDProject = { ...row, id: 1, projectId: props.currentProject.id, key: 1, comments: [{ id: 1, userId: 1, name: 'Me', text: (row.comments as unknown as string) }] }; //
                        let toUploadDproject: IRequestDProject = IDprojectToIRequestDproject(newDProject); //TODO return projectType to innovation performance properties.
                        ////console.log('newDProject: ', newDProject);

                        ////console.log('toUploadDproject: ', toUploadDproject);
                        props.uploadDproject(toUploadDproject);
                    } else {
                        newDProject = { ...row, id: 1, projectId: props.currentProject.id, key: 1, comments: [{ id: 1, userId: 1, name: 'Me', text: (row.comments as unknown as string) }] };
                        let toUploadDproject: IRequestDProject = IDprojectToIRequestDproject(newDProject);
                        ////console.log('toUploadDproject: ', toUploadDproject);
                        props.uploadDproject(toUploadDproject);
                    }
                } catch (error) {
                    console.error('Err Validating Dproject fields: ', error);
                }
            }
        };

        const handleOk = () => {
            setIsModalVisible(false);
            setCommentText('');
            setNewCommentText('');
            setIsEdittingComment(false);
            setEdittingCommentId(-1);
        };
        const handleCancel = () => {
            setIsModalVisible(false);
            props.resetComments();
            setCommentText('');
            setNewCommentText('');
            setIsEdittingComment(false);
            setEdittingCommentId(-1);
        };
        const editComment = (commentId: number, commentText: string) => {
            if (!props.deleteingComment) {
                setIsEdittingComment(true);
                setEdittingCommentId(commentId);
                setCommentText(commentText);
            }
        };
        const deleteComment = (id: number) => {
            setDeleteingCommentId(id);
            props.deleteComment(id, inModalDprojectId);
        };
        const changeCommentText = (e: ChangeEvent<HTMLTextAreaElement>) => {
            setCommentText(e.target.value);
        };
        const saveEditComment = (inModalDProjectId: number, commentId: number, commentText: string) => {
            let toBeupdatedComment = {
                Id: commentId,
                text: commentText
            };
            props.saveEditComment(toBeupdatedComment, inModalDProjectId);
        };
        const cancelEditComment = () => {
            if (!props.updatingComment) {
                setIsEdittingComment(false);
                setEdittingCommentId(-1);
            }
        };

        const addComment = () => {
            props.setAddingDprojectComment(true);
        }
        const cancelAddComment = () => {
            props.cancelAddComment();
            setNewCommentText('');
        };
        const changeNewCommentText = (e: ChangeEvent<HTMLTextAreaElement>) => {
            setNewCommentText(e.target.value);
        };
        const saveNewComment = () => {
            let toBeuploadedComment = {
                dprojectId: inModalDprojectId,
                text: newCommentText
            };
            ////console.log('toBeuploadedComment: ', toBeuploadedComment);

            props.uploadDprojectComment(toBeuploadedComment);
        };

        const sortCommentsAssending = () => {
            props.sortCommentsAssending();
        }

        const sortCommentsDescending = () => {
            props.sortCommentsDescending();
        }
        const itemsTabs = [];

        itemsTabs.push({
            label: <>{t("General")}</>, key: 'general-tab',
            children: (
                <div className="Dproject_wrapper">

                    <Modal getContainer={() => document.getElementById('app-layout')} title={modalTitle} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        {props.fetchingDprojectComments ? <Spin size='large' /> : <div>
                            <div className="add_comment_div">
                                <div className="add_comment_button_div">
                                    <Tooltip title={!props.addingDprojectComment ? t("Add comment") : ""}>
                                        {
                                            !props.addingDprojectComment ? <Button
                                                title={t('Add comment')}
                                                onClick={addComment}
                                                type="primary"
                                                shape="default"
                                                icon={<PlusOutlined />} /> :

                                                <div className="cancel_save_div">
                                                    <Space size="small" style={{ marginBottom: "1rem" }}>
                                                        <Popconfirm title={t("Sure to cancel?")} onConfirm={cancelAddComment}>
                                                            <a><Button
                                                                className='cancel_save_Btn'
                                                                title={t('Cancel')}
                                                                type="primary"
                                                                shape="default"
                                                                disabled={props.uploadingDprojectComment}>{t("Cancel")}</Button></a>
                                                        </Popconfirm>
                                                        {props.uploadingDprojectComment ? <Spin size='default' /> : <Button
                                                            title={t('Save Comment')}
                                                            className="save_comment_Btn"
                                                            onClick={saveNewComment}>{t("Save")}</Button>}
                                                    </Space>
                                                </div>

                                        }
                                    </Tooltip>
                                </div>
                                {
                                    props.addingDprojectComment ? <div className="add_comment_textarea_div">
                                        <TextArea className="add_comment_textarea" onChange={changeNewCommentText} rows={4} placeholder={`${t("Type a comment")} ...`} /*maxLength={6}*/ value={newCommentText} />
                                    </div> : null
                                }

                            </div>
                            <div className='comments-container'>
                                <div className='comments-list-header'>
                                    <div className='comments-litteral-title'>
                                        {t("Comments")}
                                    </div>
                                    <div className='order-btns-div'>
                                        <div className='up-btn'>
                                            <a title={t("Ascending")} onClick={sortCommentsAssending}>
                                                <ArrowUpOutlined style={{ 'fontSize': '12px', 'fontWeight': "bolder" }} />
                                            </a>
                                        </div>
                                        <div className='down-btn'>
                                            <a title={t("Descending")} onClick={sortCommentsDescending}>
                                                <ArrowDownOutlined style={{ 'fontSize': '12px', 'fontWeight': "bolder" }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='list-comments'>
                                    <List
                                        className="demo-loadmore-list"
                                        //loading={initLoading}
                                        itemLayout="horizontal"
                                        //loadMore={loadMore}
                                        dataSource={props.dprojectComments}
                                        renderItem={item => (
                                            <List.Item
                                                actions={
                                                    (isEdittingComment && (item.id === edittingCommentId)) ?
                                                        [props.updatingComment ? <Spin size='default' /> : <a key="list-loadmore-edit" onClick={() => saveEditComment(inModalDprojectId, item.id, commentText)}>{t("Save")}</a>, <a key="list-loadmore-edit" onClick={cancelEditComment}>{t("Cancel")}</a>] :
                                                        (item.userId == props.currentUserId) ? [<a key="list-loadmore-edit" onClick={() => editComment(item.id, item.text)}>{t("Edit")}</a>,
                                                        (props.deleteingComment && (item.id === deleteingCommentId)) ? <Spin size='default' /> : (<Popconfirm title={t("Sure to delete?")} onConfirm={() => deleteComment(item.id)}>
                                                            <a key="list-loadmore-edit">{t("Delete")}</a>
                                                        </Popconfirm>)
                                                        ] : [] //<a key="list-loadmore-edit" onClick={() => deleteComment(item.id)}>Delete</a>
                                                }>
                                                <Skeleton avatar title={false} loading={false} active>
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={item.image /*'https://randomuser.me/api/portraits/men/71.jpg'*/} />}
                                                        title={item.name}
                                                        description={isEdittingComment && (item.id === edittingCommentId) ?
                                                            (
                                                                <TextArea onChange={changeCommentText} rows={4} value={commentText} />
                                                            )
                                                            : item.text}
                                                    />
                                                </Skeleton>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </div>
                        </div >}
                    </Modal >


                    <div className="DProject_control_div">
                        <div className="dprojects_left">
                            {!edittingIncludeInLevers ? <div className="dprojects_left">
                                <div className="add_d-project_div">
                                    <Tooltip title={!props.addingDproject ? t("Add d-project") : t("Cancel adding d-project")}>
                                        {
                                            !props.addingDproject ? <Button
                                                title={t("Add d-project")}
                                                disabled={isBtnsDisabled()}
                                                onClick={addDProject}
                                                type="primary"
                                                shape="default"
                                                icon={<PlusOutlined />} /> :
                                                <Popconfirm title={t("Sure to cancel?")} onConfirm={cancelAddDProject}>
                                                    <a><Button
                                                        title={t('Cancel')}
                                                        disabled={isBtnsDisabled()}
                                                        type="primary"
                                                        shape="default">Cancel</Button></a>
                                                </Popconfirm>

                                        }
                                    </Tooltip>
                                </div>
                                {!props.addingDproject ? null : <div className="save_d-project_div">
                                    {props.uploadingDproject ? <Spin size='default' /> : <Button
                                        title={t('Save d-project')}
                                        disabled={isBtnsDisabled()}
                                        onClick={Save}
                                        className="save_d-project_button"
                                        type="primary"
                                        shape="default">
                                        Save
                                    </Button>}
                                </div>}
                            </div> : null}


                            {!props.addingDproject ? <div className="dprojects_left">
                                <div className="add_d-project_div">
                                    <Tooltip placement='bottom' title={!edittingIncludeInLevers ? t("Edit include in levers") : t("Cancel edit include in levers")}>
                                        {
                                            !edittingIncludeInLevers ? <Button
                                                onClick={editIncludeInLevers}
                                                type="primary"
                                                shape="default"
                                                icon={<EditOutlined />} /> :
                                                <Popconfirm title={t("Sure to cancel?")} onConfirm={cancelEditIncludeInLevers}>
                                                    <Spin spinning={props.gettingIncludeInLevers}>
                                                        <a>
                                                            <Button type="primary" shape="default" disabled={isBtnsDisabled()}>Cancel</Button>
                                                        </a>
                                                    </Spin>
                                                </Popconfirm>

                                        }
                                    </Tooltip>
                                </div>
                                {!edittingIncludeInLevers ? null : <div className="save_d-project_div">
                                    {uploadingIncludeInLevers ? <Spin size='default' /> : <Button
                                        title={t('Edit Include In Levers')}
                                        disabled={isBtnsDisabled()}
                                        onClick={saveIncludeInLevers}
                                        className="save_d-project_button"
                                        type="primary"
                                        shape="default">
                                        {t("Save")}
                                    </Button>}
                                </div>}
                            </div> : null}


                        </div>
                        <div className="dprojects_right">
                            {/* <div className="filter_by_lever_div">
                            <LeversDropDown filterByLever={filterByLever} />
                        </div> */}
                            <div className="coloptions_div">
                                <Spin spinning={props.markingDProjectAnalysisAsComplete}>
                                    <Popconfirm title={t("Are you sure?")} onConfirm={() => markDProjectAnalysisAsComplete()}
                                        okText={`${t("Yes")}`}
                                        cancelText={`${t("No")}`}>
                                        <Button
                                            title={
                                                (props.dprojectProgress === 100) ?
                                                    t("Mark d-project analysis as incomplete.") :
                                                    t("Mark d-project analysis as complete.")
                                            }
                                            style={{ "marginRight": "10px" }}
                                            shape="circle"
                                            icon={<FileDoneOutlined style={{ 'color': 'blue' }} />}
                                            size="middle" />
                                    </Popconfirm>
                                </Spin>
                                <ColOptions
                                    Shown_GeneralInfo={Shown_GeneralInfo}
                                    Hidden_GeneralInfo={Hidden_GeneralInfo}

                                    shown_EbitCosts={shown_EbitCosts}
                                    Hidden_EbitCosts={Hidden_EbitCosts}

                                    Shown_Questionnaires={Shown_Questionnaires}
                                    Hidden_Questionnaires={Hidden_Questionnaires}

                                    columns={columns}
                                    hiddenColumns={hiddenColumns}
                                // changeOrder={changeOrder} 
                                />
                            </div>
                        </div>
                    </div>
                    {/* <DProjectTable addingDproject={addingDproject} columns={columns} /> */}
                    <div className="dproject_table_wrapper">
                        <Spin
                            size='large'
                            spinning={props.fetchingDprojects}>
                            <Form form={form} component={false}>
                                <Table
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    className='dproject_table'
                                    columns={mergedColumns}
                                    dataSource={props.dprojects}
                                    bordered
                                    scroll={{ y: "calc(100vh - 500px)", x: "1500px" }}
                                    //scroll={{ y: "1500px" }}
                                    pagination={{
                                        defaultPageSize: 50,
                                        pageSizeOptions: [50, 100, 150],
                                        total: props.dprojects.length,
                                        showTotal: (total) => `Total ${total} items`,
                                        showSizeChanger: true
                                    }} />
                            </Form>
                        </Spin>
                    </div>
                </div >
            )
        });
        itemsTabs.push({
            label: <>{t("Tipps and Tricks")}</>, key: 'tips-tab',
            children: <HelpPage currentPageCode="dprojects" />
        });
        return <Tabs key={"dprojects-tab"} items={itemsTabs} />
    } else {
        return (
            <div className="general-spin"> <Spin size="large" key={'columns_spin'} /></div>
        );
    }
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        markingDProjectAnalysisAsComplete: state.dprojects.markingDProjectAnalysisAsComplete,
        dprojectProgress: state.dprojects.dprojectProgress,
        gettingIncludeInLevers: state.dprojects.gettingIncludeInLevers,
        uploadingIncludeInLevers: state.dprojects.uploadingIncludeInLevers,
        dprojectIdsIncludedInLevers: state.dprojects.dprojectIdsIncludedInLevers,
        fetchingDprojects: state.dprojects.fetchingDprojects,
        showErrorMessage: state.dprojects.showErrorMessage,
        errorMessage: state.dprojects.errorMessage,
        dprojectsTableColsInitialized: state.tablesColsProps.dprojectsTableColsInitialized,
        tableCols: state.tablesColsProps.dprojectsTableCols,
        currentProject: state.generalInfo.profileResponse?.currentProject,
        dprojectsFetchedSuccess: state.dprojects.dprojectsFetchedSuccess,
        dprojects: state.dprojects.dprojects,
        uploadingDproject: state.dprojects.uploadingDproject,
        addingDproject: state.dprojects.addingDproject,
        fetchingDprojectComments: state.dprojects.fetchingDprojectComments,
        dprojectComments: state.dprojects.comments,
        addingDprojectComment: state.dprojects.addingDprojectComment,
        uploadingDprojectComment: state.dprojects.uploadingDprojectComment,
        updatingComment: state.dprojects.updatingComment,
        deleteingComment: state.dprojects.deleteingComment,
        updatingDproject: state.dprojects.updatingDproject,
        dltingDproject: state.dprojects.dltingDproject,
        gettingDprojectTypes: state.dprojects.gettingDprojectTypes,
        DprojectTypesFetchedSuccess: state.dprojects.DprojectTypesFetchedSuccess,
        types: state.dprojects.types,
        currentUserId: state.generalInfo.profileResponse?.id,
        // all new babe
        dprojectsTable_GeneralInfo: state.tablesColsProps.dprojectsTable_GeneralInfo,
        dprojectTable_EbitCosts: state.tablesColsProps.dprojectTable_EbitCosts,
        dprojectTable_Questionnare: state.tablesColsProps.dprojectTable_Questionnare
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {

        resetComments: () =>
            dispatch({ type: ActionTypes.SAVEDPROJECTCOMMENTS, dprojectComments: [] }),

        setShowErrorMessageToFalse: () =>
            dispatch({ type: ErrorActions.SETSHOWERRORTOFALSE }),

        getDprojectsTypes: () =>
            dispatch({ type: ActionTypes.GETDPROJECTSTYPES }),

        deleteDproject: (dprojectId: number, bm_projectId: number) =>
            dispatch({ type: ActionTypes.DELETEDPROJECT, dprojectId: dprojectId, bm_projectId: bm_projectId }),

        updateDproject: (dproject: IRequestDProject) =>
            dispatch({ type: ActionTypes.UPDATEDPROJECT, dproject: dproject }),

        deleteComment: (id: number, dProjectId: number) =>
            dispatch({ type: ActionTypes.DELETECOMMENT, commentId: id, dProjectId: dProjectId }),

        saveEditComment: (toBeupdatedComment: { Id: number, text: string }, dProjectId: number) =>
            dispatch({ type: ActionTypes.UPDATEDPROJECTCOMMENT, comment: toBeupdatedComment, dProjectId: dProjectId }),

        cancelAddComment: () =>
            dispatch({ type: ActionTypes.CANCELADDCOMMENT }),

        setAddingDprojectComment: (val: boolean) =>
            dispatch({ type: ActionTypes.SETADDINGDPROJECTCOMMENT, val: val }),

        uploadDprojectComment: (comment: { dprojectId: number, text: string }) =>
            dispatch({ type: ActionTypes.UPOLADDPROJECTCOMMENT, comment: comment }),

        fetchDprojectComments: (dprojectId: number) =>
            dispatch({ type: ActionTypes.FETCHDPROJECTCOMMENTS, dprojectId: dprojectId }),

        setAddingDproject: (val: boolean) =>
            dispatch({ type: ActionTypes.SETADDINGDPROJECT, val: val }),

        uploadDproject: (dproject: IRequestDProject) =>
            dispatch({ type: ActionTypes.UPLOADDPROJECT, dproject: dproject }),

        setDprojects: (dprojects: IDProject[]) =>
            dispatch({ type: ActionTypes.SETDPROJECTS, dprojects: dprojects }),

        getDprojects: (bmProjectId) =>
            dispatch({ type: ActionTypes.GETDPROJECTS, bmProjectId: bmProjectId }),

        setQuestionnaireCols: (questionnaireCols: dProjectQuestionnairesType) =>
            dispatch({ type: ActionTypes.SetQuestionnaireCols/*SETINITIALDPROJECTSCOLS*/, questionnaireCols: questionnaireCols }),

        // changeColsOrder: (fromIndex: number, toIndex: number, parentKey?: string) =>
        //     dispatch({ type: ActionTypes.CHANGECOLSORDER, fromIndex: fromIndex, toIndex: toIndex, parentKey: parentKey }),

        // filterByLever: (leverId: number) =>
        //     dispatch({ type: ActionTypes.FILTERDPROJECTSCOLSBYLEVERID, leverId: leverId }),

        getIncludeInLevers: () =>
            dispatch({ type: ActionTypes.GetIncludeInLevers }),

        uploadIncludeInLevers: (includeInLeversArr: number[]) =>
            dispatch({ type: ActionTypes.UploadIncludeInLevers, includeInLeversArr: includeInLeversArr }),

        sortCommentsAssending: () =>
            dispatch({ type: ActionTypes.SortCommentsAssending }),

        sortCommentsDescending: () =>
            dispatch({ type: ActionTypes.SortCommentsDescending }),

        markDProjectAnalysisAsComplete: () =>
            dispatch({ type: ActionTypes.MarkDProjectAnalysisAsComplete })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DProjects);