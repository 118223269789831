import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { Children, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
//import phasesToColumns from "./Helpers/PhasesToColumns";
import { getTableData, getTableRows } from "./Helpers/PhasesToTableData";
import FlTableColumn from "./Models/FlTableColumns";
import { Fte, MileStone, Phase } from "./Models/Phase";
import "./inputtab.scss";
import FlProjectDetails from "./Models/FlProjectDetails";
import {
  CloseCircleOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import moment, { Moment } from "moment";
import {
  AddedMilestoneToUpload,
  FormObject,
  PhaseToUpload,
} from "./Models/FormObject";
import arrangFormValues, {
  arrangeMilestoneFormObj,
} from "./Helpers/ArrangFormValues";
import { RangePickerProps } from "antd/lib/date-picker";
import PhaseValidDates from "./Models/PhaseValidDates";
import ToBeEditedPhaseDates from "./Models/ToBeEditedPhaseDates";
import ToBeEdittedMilestoneDates from "./Models/ToBeEdittedMilestonesDates";
import i18n from "../../../../../i18n";
import de from "antd/es/date-picker/locale/de_DE";
import en from "antd/es/date-picker/locale/en_US";
import {
  dprojectInputFormatter,
  dprojectParser,
  formatValue,
} from "../../../../../Utils/helperFunctions";
import { useTranslation } from "react-i18next";
import { ProfileResponse } from "../../../../../Models/Responses/ProfileResponse";
import { useNavigate } from "react-router-dom";
import { BasicRole } from "../../../../../Enums/BasicRoles";
import { IGeneralState } from "../../../../../Redux/reducers/IGeneralState";

type ValidateStatus = Parameters<typeof Form.Item>[0]["validateStatus"];
interface ValidationValue {
  value: Moment;
  validateStatus?: ValidateStatus;
  errorMsg?: string | null;
}

interface IProps {
  currentFrontLoadingProjectId: number;
  getFlProjectDetails: (id: number) => void;
  gettingFlProjectDetails: boolean;
  flProjectDetails: FlProjectDetails;
  active: boolean;
  uploadPhase: (phase: PhaseToUpload) => void;
  uploadingPhase: boolean;
  uploadMilestone: (
    milestone: AddedMilestoneToUpload,
    cuurentFlProjectId: number
  ) => void;
  getPhaseValidDates: (phaseId: number) => void;
  phaseValidDates: PhaseValidDates;
  gettingPhaseValidDates: boolean;
  changeMilestoneActualDateMax: (actualDateMax: string) => void;
  changeMilestonePlannedDateMax: (plannedDateMax: string) => void;
  uploadingMilestone: boolean;
  deleteMilestone: (id: number, flProjectId: number) => void;
  deletingMilestone: boolean;
  profileResponse: ProfileResponse;
}

function InputTab(props: IProps) {
  const [tableColsAndDataReady, setTableColsAndDataReady] = useState(false);
  const [tableCols, setTableCols] = useState<FlTableColumn[]>([]);
  const [tableData, setTableData] = useState([]);
  const [toBeEdittedPhaseId, setToBeEdittedPhaseId] = useState(0);
  const [toBeDeletedMilestoneId, setToBeDeletedMilestoneId] = useState(0);
  const [phaseIdToAddMilestone, setPhaseIdToAddMilestone] = useState(0);
  const [phaseValideDates, setPhaseValideDates] = useState<PhaseValidDates>({
    phaseLimitDates: {
      actualEndMin: "",
      actualEndMax: "",
      //plannedEndMin: "",
      //plannedEndMax: "",
    },
    milestoneLimiDates: {
      actualMin: "",
      actualMax: "",
      //plannedMin: "",
      //plannedMax: "",
    },
  });
  const [milestonesDates, setMilestonesDates] = useState<ToBeEdittedMilestoneDates[]>([]);
  const [form] = Form.useForm();
  const [milestoneForm] = Form.useForm();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.getFlProjectDetails(props.currentFrontLoadingProjectId);
  }, [props.currentFrontLoadingProjectId]);

  useEffect(() => {
    setToBeEdittedPhaseId(0);
    setToBeDeletedMilestoneId(0);
    setPhaseIdToAddMilestone(0);
    form.resetFields();
    milestoneForm.resetFields();
    if (props.flProjectDetails !== null) {
      if (props.flProjectDetails?.flProjectPhases?.length > 0) {
        console.log(
          "props.flProjectPhases: ",
          props.flProjectDetails.flProjectPhases
        );
        //let dataSource = getTableData(props.flProjectDetails.flProjectPhases, props.flProjectDetails.flDisciplines);
        let orderedPhases = props.flProjectDetails.flProjectPhases.sort(
          (a, b) =>
            a.phaseNumber > b.phaseNumber
              ? 1
              : b.phaseNumber > a.phaseNumber
                ? -1
                : 0
        );
        orderedPhases.forEach((phase) => {
          phase.mileStones.sort((a, b) =>
            moment(a.actualDate as string).isAfter(
              moment(b.actualDate as string)
            )
              ? 1
              : moment(a.actualDate as string).isBefore(
                moment(b.actualDate as string)
              )
                ? -1
                : 0
          );
        });
        let orderedDepartments = props.flProjectDetails?.flDepartments?.sort(
          (a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0)
        );
        let dataSource = getTableRows(orderedPhases, orderedDepartments);
        console.log("dataSource: ", dataSource);

        let cols = phasesToColumns(props.flProjectDetails.flProjectPhases);
        console.log("cols: ", cols);

        setTableData(dataSource);
        setTableCols(cols);
      }
      setTableColsAndDataReady(true);
    }
  }, [props.flProjectDetails]);

  useEffect(() => {
    if (phaseIdToAddMilestone == 0)
      setTableCols(
        phasesToColumns(props.flProjectDetails?.flProjectPhases ?? [])
      );
  }, [
    toBeEdittedPhaseId,
    props.gettingPhaseValidDates,
    phaseValideDates,
    props.uploadingPhase,
    props.deletingMilestone,
    phaseIdToAddMilestone,
    milestonesDates,
  ]);

  useEffect(() => {
    console.log("props.UploadingPhase: ", props.uploadingPhase);

    if (!props.uploadingPhase) form.resetFields();
  }, [props.uploadingPhase]);

  useEffect(() => {
    if (phaseIdToAddMilestone !== 0) {
      if (toBeEdittedPhaseId === 0) {
        let latestDateToFocus = moment(props.flProjectDetails.flProjectActualStartDate);
        let newPhases: Phase[] = props.flProjectDetails.flProjectPhases.map(
          (phase, phaseIdx) => {
            if (phase.id !== phaseIdToAddMilestone) return phase;
            console.log("phase.actualEndDate", phase.actualEndDate);
            if (phase.actualEndDate) latestDateToFocus = moment(phase.actualEndDate);
            //let orderedDiscipline = props.flProjectDetails.flDisciplines.sort((a, b) => (a.order > b.order) ? 1 : -1)
            let newMilestoneFtes: Fte[] =
              props.flProjectDetails.flDepartments.map(
                (department, departmentIdx) => {
                  return {
                    id: undefined,
                    departmentId: department.id,
                    value: (
                      <Form
                        key="milestoneForm"
                        form={milestoneForm}
                        onFinish={onFinishMilestoneForm}
                      >
                        <Form.Item
                          key={
                            "phaseId_milestoneForm_hidden" + phase.id.toString()
                          }
                          name={"phaseId"}
                          initialValue={phase.id}
                          hidden={true}
                        ></Form.Item>
                        <Form.Item
                          key={departmentIdx}
                          name={department.id.toString()}
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: false,
                              message: (
                                <div className="required_message">
                                  {t("Field is required!")}
                                </div>
                              ),
                            },
                          ]}
                          initialValue={null}
                        >
                          <InputNumber
                            formatter={(value: number) => {
                              // typeof value is always string (surprisingly !!)
                              // if value = null or value = undefined, then value.toString() = ''
                              return dprojectInputFormatter(value);
                            }}
                            parser={(x) => dprojectParser(x, i18n.language)}
                            key={departmentIdx.toString() + "fteInput"}
                            className="edit_input_str"
                          />
                        </Form.Item>
                      </Form>
                    ),
                  };
                }
              );

            let newMileStone: MileStone = {
              id: -1000,
              name: (
                <Form
                  key="milestoneForm"
                  form={milestoneForm}
                  onFinish={onFinishMilestoneForm}
                >
                  <Form.Item
                    key="milestone_Form.Item"
                    name="milestoneName"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <div className="required_message">
                            {t("Field is required!")}
                          </div>
                        ),
                      },
                    ]}
                    initialValue={null}
                  >
                    <Input
                      key="milestoneNameInput"
                      className="edit_input_str"
                    />
                  </Form.Item>
                </Form>
              ),
              actualDate: (
                <Form
                  key="milestoneForm"
                  form={milestoneForm}
                  onFinish={onFinishMilestoneForm}
                >
                  <Form.Item
                    key="actualDate_Form.Item"
                    name="milestoneActualDate"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <div className="required_message">
                            {t("Field is required!")}
                          </div>
                        ),
                      },
                    ]}
                    initialValue={null}
                  >
                    <DatePicker
                      defaultPickerValue={latestDateToFocus}
                      key="milestoneActualDatePicker_milestoneForm"
                      disabledDate={
                        //(current: Moment) => disabledMilestoneActualDates(current, phase.id)
                        disabledMilestoneActualDates
                      }
                      disabled={props.gettingPhaseValidDates}
                      onChange={(value: Moment) =>
                        onMilestoneActualDateChang(value, -1000)
                      }
                      locale={i18n.language == "de" ? de : en}
                      format={"L"}
                    />
                  </Form.Item>
                </Form>
              ),
              gateName:
                canAddGate() === 0 ? (
                  <Tooltip
                    trigger="hover"
                    //visible={canAddGate() !== mileStone.id}
                    title={
                      <div className="toolTip-container-div">
                        <div className="tooltip_text_div">
                          {t("Gate can be added only for milestone at the end of phase.")}
                        </div>
                        <div className="tooltip_icon_div">
                          <CloseCircleOutlined
                            style={{ fontSize: "30px" }}
                            className="tooltip_err_icon"
                          />
                        </div>
                      </div>
                    }
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                  >
                    <Form
                      key="milestoneForm"
                      form={milestoneForm}
                      onFinish={onFinishMilestoneForm}
                    >
                      <Form.Item
                        key="gateName_Form.Item"
                        name="milestoneGateName"
                        style={{ margin: 0 }}
                        initialValue={null}
                      >
                        <Input
                          key="milestonegateNameInput"
                          className="edit_input_str"
                          disabled={canAddGate() === 0}
                        />
                      </Form.Item>
                    </Form>
                  </Tooltip>
                ) : (
                  <Form
                    key="milestoneForm"
                    form={milestoneForm}
                    onFinish={onFinishMilestoneForm}
                  >
                    <Form.Item
                      key="gateName_Form.Item"
                      name="milestoneGateName"
                      style={{ margin: 0 }}
                      initialValue={null}
                    >
                      <Input
                        key="milestonegateNameInput"
                        className="edit_input_str"
                        disabled={canAddGate() === 0}
                      />
                    </Form.Item>
                  </Form>
                ),
              ftes: newMilestoneFtes,
            };

            return {
              id: phase.id,
              name: phase.name,
              actualEndDate: phase.actualEndDate,
              //plannedEndDate: phase.plannedEndDate,
              templatePhaseId: phase.templatePhaseId,
              phaseNumber: phase.phaseNumber,
              phaseDuration: phase.phaseDuration,
              mileStones: [...phase.mileStones, newMileStone],
            };
          }
        );
        let newCols = phasesToColumns(newPhases);
        let orderedPhases = newPhases.sort((a, b) =>
          a.phaseNumber > b.phaseNumber
            ? 1
            : b.phaseNumber > a.phaseNumber
              ? -1
              : 0
        );
        let newDataSource = getTableRows(
          orderedPhases,
          props.flProjectDetails.flDepartments
        );

        setTableData(newDataSource);
        setTableCols(newCols);
      } else {
      }
    } else {
      setTableCols(
        phasesToColumns(props.flProjectDetails?.flProjectPhases ?? [])
      );
      milestoneForm.resetFields();
    }
  }, [
    phaseValideDates,
    props.gettingPhaseValidDates,
    props.uploadingMilestone,
    milestonesDates,
  ]);

  useEffect(() => {
    setPhaseValideDates(props.phaseValidDates);
  }, [props.phaseValidDates]);

  useEffect(() => {
    console.log("Input tab will unmount.");

    return () => {
      if (!props.active) {
        setTableCols([]);
        setTableColsAndDataReady(false);
        setTableData([]);
        setToBeEdittedPhaseId(0);
        setPhaseIdToAddMilestone(0);
        setToBeDeletedMilestoneId(0);
        setPhaseValideDates({
          phaseLimitDates: {
            actualEndMin: "",
            actualEndMax: "",
            //plannedEndMin: "",
            //plannedEndMax: "",
          },
          milestoneLimiDates: {
            actualMin: "",
            actualMax: "",
            //plannedMin: "",
            //plannedMax: "",
          },
        });
      }
    };
  }, [props.active]);

  const isAdmin = (): boolean => {
    if (props.profileResponse && props.profileResponse?.roles && props.profileResponse.roles.includes(BasicRole.Consultant) || props.profileResponse.roles.includes(BasicRole.SuperAdmin)) return true;
    return false;
  };

  const edittingPhase = (phaseId: number): boolean => {
    return phaseId === toBeEdittedPhaseId && !props.gettingPhaseValidDates;
  };
  const addingMilestoneToPhase = (phaseId: number): boolean => {
    return phaseId === phaseIdToAddMilestone && !props.gettingPhaseValidDates;
  };
  const ToEditPhase = (phaseId: number) => {
    props.getPhaseValidDates(phaseId);
    let phaseDates: ToBeEditedPhaseDates = getToBeEditedPhaseDates(phaseId);
    props.changeMilestoneActualDateMax(phaseDates.phaseActualEndDate);
    // props.changeMilestonePlannedDateMax(phaseDates.phasePlannedEndDate);
    setMilestonesDates(getToBeEdittedMilestonesDates(phaseId));
    setToBeEdittedPhaseId(phaseId);
    form.resetFields();
  };

  const getToBeEditedPhaseDates = (phaseId: number): ToBeEditedPhaseDates => {
    let toBeEditedPhase = props.flProjectDetails.flProjectPhases.find(
      (phase) => phase.id === phaseId
    );
    return {
      phaseActualEndDate: toBeEditedPhase.actualEndDate,
      //phasePlannedEndDate: toBeEditedPhase.plannedEndDate,
    };
  };

  const getToBeEdittedMilestonesDates = (
    phaseId: number
  ): ToBeEdittedMilestoneDates[] => {
    let toBeEditedPhase = props.flProjectDetails.flProjectPhases.find(
      (phase) => phase.id === phaseId
    );
    return toBeEditedPhase.mileStones.map<ToBeEdittedMilestoneDates>(
      (milestone, idx) => {
        return {
          milestoneId: milestone.id,
          milestoneActualDate: milestone.actualDate as string,
          //milestonePlannedDate: milestone.plannedDate as string,
        };
      }
    );
  };

  const onFinishMilestoneForm = (values: any) => {
    console.log("onFinishMilestoneForm values: ", values);
    let toUploadMilestone = arrangeMilestoneFormObj(values);
    console.log("toUploadMilestone: ", toUploadMilestone);

    props.uploadMilestone(
      toUploadMilestone,
      props.currentFrontLoadingProjectId
    );
  };

  const toAddMilestone = (phaseId: number) => {
    props.getPhaseValidDates(phaseId);
    setMilestonesDates([
      ...getToBeEdittedMilestonesDates(phaseId),
      {
        milestoneId: -1000, //it is an imaginary id for the added milestone
        milestoneActualDate: "",
        //milestonePlannedDate: "",
      },
    ]);
    setPhaseIdToAddMilestone(phaseId);
    milestoneForm.resetFields();
  };

  const cancelSavePhase = () => {
    setToBeEdittedPhaseId(0);
    form.resetFields();
  };

  const cancelSaveMilestone = () => {
    setPhaseIdToAddMilestone(0);
    milestoneForm.resetFields();
  };

  const onPhaseActualEndDateChange = (value: Moment) => {
    props.changeMilestoneActualDateMax(value.format("M/D/YYYY"));
  };

  const onMilestoneActualDateChang = (value: Moment, milestoneId: number) => {
    let newMilestonesDates: ToBeEdittedMilestoneDates[] = [];
    milestonesDates.forEach((milestoneDate) => {
      if (milestoneDate.milestoneId === milestoneId) {
        newMilestonesDates.push({
          milestoneId: milestoneDate.milestoneId,
          milestoneActualDate: value.format("M/D/YYYY"),
          //milestonePlannedDate: milestoneDate.milestonePlannedDate,
        });
      } else {
        newMilestonesDates.push({ ...milestoneDate });
      }
    });
    setMilestonesDates(newMilestonesDates);
    form && form?.setFieldValue(`${milestoneId.toString()}_gateName`, '');
    milestoneForm && milestoneForm?.setFieldValue(`${milestoneId.toString()}_gateName`, '');
  };

  // const onMilestonePlannedDateChange = (value: Moment, milestoneId: number) => {
  //   let newMilestonesDates: ToBeEdittedMilestoneDates[] = [];
  //   milestonesDates.forEach((milestoneDate) => {
  //     if (milestoneDate.milestoneId === milestoneId) {
  //       newMilestonesDates.push({
  //         milestoneId: milestoneDate.milestoneId,
  //         milestoneActualDate: milestoneDate.milestoneActualDate,
  //         //milestonePlannedDate: value.format("M/D/YYYY"),
  //       });
  //     } else {
  //       newMilestonesDates.push({ ...milestoneDate });
  //     }
  //   });
  //   setMilestonesDates(newMilestonesDates);
  // };  
  const canAddGate = (): number => {
    let id = 0;

    console.log("milestonesDates: ", milestonesDates);
    if (
      (milestonesDates[milestonesDates.length - 1].milestoneId === -1000) &&
      (milestonesDates[milestonesDates.length - 1].milestoneActualDate === '')
    ) return 0;

    milestonesDates.forEach((milestoneDate, idx) => {
      if (
        moment(milestoneDate.milestoneActualDate).isSame(
          moment(props.phaseValidDates.milestoneLimiDates.actualMax),
          "date"
        )
        //  &&
        // moment(milestoneDate.milestonePlannedDate).isSame(
        //   moment(props.phaseValidDates.milestoneLimiDates.plannedMax),
        //   "date"
        // )
      ) {
        id = milestoneDate.milestoneId;
        console.log("milestone id which can gate to: ", id);

      }
    });

    return id;
  };

  const disabledPhaseActualDates: RangePickerProps["disabledDate"] = (
    current: Moment
  ) => {
    return (
      current.isBefore(moment(phaseValideDates.phaseLimitDates.actualEndMin).add(2, "days")) || //we added 2 days to avoid zero months phase duration, because we have to stick to one precision in phase duration 
      current.isAfter(
        moment(phaseValideDates.phaseLimitDates.actualEndMax).add(1, "days")
      )
    );
  };
  const previouslySelectedMilestonesDates = (phaseId: number): Moment[] => {
    let phase = props.flProjectDetails.flProjectPhases.find(phase => phase.id === phaseId);
    return phase?.mileStones.map(milestone => {
      return moment(milestone.actualDate as string);
    }) ?? [];
  };
  // const isCurrentPreviouslySelected = (current: Moment, phaseId: number): boolean => {
  //   let isCurrentPreviouslySelected = false;
  //   previouslySelectedMilestonesDates(phaseId).forEach(date => {
  //     if (current.isSame(date)) {
  //       console.log("current: ", current);
  //       console.log("date: ", date);
  //       isCurrentPreviouslySelected = true;
  //     }
  //   });
  //   return isCurrentPreviouslySelected;
  // };
  // const disabledMilestoneActualDates/*:RangePickerProps["disabledDate"]*/ = (current: Moment, phaseId: number): boolean => {

  //   return (
  //     current.isBefore(moment(phaseValideDates.milestoneLimiDates.actualMin).add(1, "days")) ||
  //     current.isAfter(moment(phaseValideDates.milestoneLimiDates.actualMax)) ||
  //     isCurrentPreviouslySelected(current, phaseId)
  //   );
  // };

  const isCurrentPreviouslySelected = (current: Moment): boolean => {
    let isCurrentPreviouslySelected = false;
    milestonesDates.forEach(milestoneDate => {
      if (current.isSame(moment(milestoneDate.milestoneActualDate))) {
        isCurrentPreviouslySelected = true;
      }
    });
    return isCurrentPreviouslySelected;
  };

  const disabledMilestoneActualDates = (current: Moment): boolean => {

    return (
      current.isBefore(moment(phaseValideDates.milestoneLimiDates.actualMin).add(1, "days")) ||
      current.isAfter(moment(phaseValideDates.milestoneLimiDates.actualMax)) ||
      isCurrentPreviouslySelected(current)
    );
  };

  const finishForm = (values) => {
    let phaseToUpload: PhaseToUpload = arrangFormValues(
      values,
      props.currentFrontLoadingProjectId
    );
    console.log("phaseToUpload: ", phaseToUpload);

    props.uploadPhase(phaseToUpload);
  };

  const deleteMilestone = (id: number) => {
    setToBeDeletedMilestoneId(id);
    props.deleteMilestone(id, props.currentFrontLoadingProjectId);
  };

  const phasesToColumns = (phases: Phase[]): FlTableColumn[] => {

    let phasesCols = phases.map((phase, phaseIdx) => {
      let latestDateToFocus = moment(props.flProjectDetails.flProjectActualStartDate);
      if (phaseIdx > 0) {
        if (phases[phaseIdx - 1]?.actualEndDate) {
          console.log("phases[phaseIdx-1]?.actualEndDate", phases[phaseIdx - 1]?.actualEndDate);
          latestDateToFocus = moment(phases[phaseIdx - 1]?.actualEndDate);
        }
      }

      return new FlTableColumn(
        edittingPhase(phase.id) ? (
          <div className="phaseCellDiv">
            <div className="phaseFormItemDiv">
              <Form form={form} onFinish={finishForm}>
                <Form.Item
                  key={"phaseId_hidden" + phase.id.toString()}
                  name={"phaseId"}
                  initialValue={phase.id}
                  hidden={true}
                ></Form.Item>
                <Form.Item
                  key={"templatePhaseId_hidden" + phase.id.toString()}
                  name={"templatePhaseId"}
                  initialValue={phase.templatePhaseId}
                  hidden={true}
                ></Form.Item>
                <Form.Item
                  key={phase.id}
                  name={"phaseName"}
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="required_message">
                          {t("Field is required!")}
                        </div>
                      ),
                    },
                  ]}
                  initialValue={phase.name}>
                  <Input
                    key={phase.id.toString() + "phaseNameInput"}
                    className="edit_input_str"
                  />
                </Form.Item>
              </Form>
            </div>

            <div className="editPhaseControlDiv">
              <Form form={form} onFinish={finishForm}>
                <Form.Item>
                  <div className="editPhaseBtnsDiv">
                    <div className="phaseEditBtn">
                      <Spin size="small" spinning={props.uploadingPhase}>
                        <Button
                          htmlType="submit"
                          shape="circle"
                          icon={<SaveOutlined style={{ color: "green" }} />}
                          size="small"
                          title={t("Save phase")}
                          key="submit_btn"
                          disabled={props.uploadingPhase}
                        //onClick={() => savePhase()}
                        />
                      </Spin>
                    </div>
                    <div className="phaseAddMilestone">
                      <Button
                        key="cancelsubmit_btn"
                        shape="circle"
                        icon={<CloseOutlined style={{ color: "red" }} />}
                        size="small"
                        title={t("Cancel")}
                        onClick={() => cancelSavePhase()}
                        disabled={props.uploadingPhase}
                      />
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <div className="phaseCellDiv">
            <div className="phaseNameDiv">{phase.name}</div>
            {addingMilestoneToPhase(phase.id) ? (
              <div className="editPhaseControlDiv">
                <Form
                  key="milestoneForm"
                  form={milestoneForm}
                  onFinish={onFinishMilestoneForm}
                >
                  <Form.Item>
                    <div className="editPhaseBtnsDiv">
                      <div className="phaseEditBtn">
                        <Spin
                          size="small"
                          spinning={
                            props.uploadingMilestone &&
                            phase.id == phaseIdToAddMilestone
                          }
                        >
                          <Button
                            htmlType="submit"
                            shape="circle"
                            icon={<SaveOutlined style={{ color: "green" }} />}
                            size="small"
                            title="Save milestone"
                            key="submit_btn_addMilestone"
                          //onClick={() => savePhase()}
                          />
                        </Spin>
                      </div>
                      <div className="phaseAddMilestone">
                        <Button
                          key="cancelsubmit_btn_addMilestone"
                          shape="circle"
                          icon={<CloseOutlined style={{ color: "red" }} />}
                          size="small"
                          title={`${t("Cancel")}`}
                          onClick={() => cancelSaveMilestone()}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <div className="phaseControlDiv">
                {toBeEdittedPhaseId !== 0 || phaseIdToAddMilestone !== 0 ? (
                  <Spin
                    size="small"
                    spinning={
                      props.gettingPhaseValidDates &&
                      toBeEdittedPhaseId === phase.id
                    }>
                    <Tooltip
                      trigger="hover"
                      title={
                        <div className="toolTip-container-div">
                          <div className="tooltip_text_div">
                            {
                              <span>
                                {t("Please, complete openned form or cancel it.")}
                              </span>
                            }
                          </div>
                          <div className="tooltip_info_icon_div">
                            <InfoCircleOutlined
                              style={{ fontSize: "30px" }}
                              className="tooltip_err_icon"
                            />
                          </div>
                        </div>
                      }>
                      {/* <div className="phaseEditBtn"> */}
                      <Button
                        key="ToEditPhase_btn"
                        shape="circle"
                        icon={<EditOutlined style={{ color: "green" }} />}
                        size="small"
                        title={t("Edit phase")}
                        disabled={
                          props.gettingPhaseValidDates ||
                          props.uploadingPhase ||
                          toBeEdittedPhaseId !== 0 ||
                          phaseIdToAddMilestone !== 0 ||
                          props.deletingMilestone
                        }
                        onClick={() => ToEditPhase(phase.id)}
                      />
                      {/* </div> */}
                    </Tooltip>
                  </Spin>
                ) : (
                  <div className="phaseEditBtn">
                    <Spin
                      size="small"
                      spinning={
                        props.gettingPhaseValidDates &&
                        toBeEdittedPhaseId === phase.id
                      }
                    >
                      <Button
                        key="ToEditPhase_btn"
                        shape="circle"
                        icon={<EditOutlined style={{ color: "green" }} />}
                        size="small"
                        title={t("Edit phase")}
                        disabled={
                          props.gettingPhaseValidDates ||
                          props.uploadingPhase ||
                          toBeEdittedPhaseId != 0 ||
                          phaseIdToAddMilestone != 0 ||
                          props.deletingMilestone
                        }
                        onClick={() => ToEditPhase(phase.id)}
                      />
                    </Spin>
                  </div>
                )}
                {phase.id < 0 ||
                  toBeEdittedPhaseId != 0 ||
                  phaseIdToAddMilestone != 0 ? (
                  <div className="phaseAddMilestone">
                    <Spin
                      size="small"
                      spinning={
                        props.gettingPhaseValidDates &&
                        phase.id == phaseIdToAddMilestone
                      }
                    >
                      <Tooltip
                        title={
                          <div className="toolTip-container-div">
                            <div className="tooltip_text_div">
                              {toBeEdittedPhaseId != 0 ||
                                phaseIdToAddMilestone != 0 ? (
                                <span>
                                  {t("Please, complete openned form or cancel it.")}
                                </span>
                              ) : (
                                <span>
                                  {t("To add a milestone, you need to create the phase first.")}
                                </span>
                              )}
                            </div>
                            <div className="tooltip_info_icon_div">
                              <InfoCircleOutlined
                                style={{ fontSize: "30px" }}
                                className="tooltip_err_icon"
                              />
                            </div>
                          </div>
                        }>
                        <Button
                          key="add_Milestone_Btn"
                          shape="circle"
                          icon={<PlusOutlined style={{ color: "green" }} />}
                          size="small"
                          title={t("Add Milestone")}
                          disabled={
                            props.gettingPhaseValidDates ||
                            props.uploadingPhase ||
                            phase.id < 0 ||
                            toBeEdittedPhaseId != 0 ||
                            phaseIdToAddMilestone != 0 ||
                            props.deletingMilestone
                          }
                          onClick={() => toAddMilestone(phase.id)}
                        />
                      </Tooltip>
                    </Spin>
                  </div>
                ) : (
                  <div className="phaseAddMilestone">
                    <Spin
                      size="small"
                      spinning={
                        props.gettingPhaseValidDates &&
                        phase.id == phaseIdToAddMilestone
                      }
                    >
                      <Button
                        key="add_Milestone_Btn"
                        shape="circle"
                        icon={<PlusOutlined style={{ color: "green" }} />}
                        size="small"
                        title={t("Add Milestone")}
                        disabled={
                          props.gettingPhaseValidDates ||
                          props.uploadingPhase ||
                          phase.id < 0 ||
                          toBeEdittedPhaseId != 0 ||
                          phaseIdToAddMilestone != 0 ||
                          props.deletingMilestone
                        }
                        onClick={() => toAddMilestone(phase.id)}
                      />
                    </Spin>
                  </div>
                )}
              </div>
            )}
          </div>
        ),
        phase.name + phaseIdx.toString(), // key
        {
          children: [
            new FlTableColumn(
              (
                <div className="phase_duration_value_div">
                  <div className="phase_duration_value">
                    {phase.phaseDuration}
                  </div>
                </div>
              ),
              "phase_duration_jj" + phase.id.toString(),
              {
                width: 25,
                DataIndex: phase.id.toString() + "_phase",
                onCell: (record, rowIndex) => {
                  //console.log("oncell", record, rowIndex);
                  if (record["key"] != 'total') return { colSpan: 1 }
                  else return { colSpan: 1 + (phase.mileStones?.length) }
                },
                render: (value, record, index) => {
                  return value === 0
                    ? value
                    : value
                      ? <div className="walaa_phase">{formatValue(value)}</div>
                      : null;
                },
              }
            ),
            new FlTableColumn(
              edittingPhase(phase.id) ? (
                <div
                  className="phaseFormItemDiv"
                  key={phase.id.toString() + "phase_actualEndDate_div"}
                >
                  <Form form={form} onFinish={finishForm}>
                    <Form.Item
                      key={phase.id.toString() + "phase_actualEndDate"}
                      name="phaseActualEndDate"
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: (
                            <div className="required_message">
                              {t("Field is required!")}
                            </div>
                          ),
                        },
                      ]}
                      initialValue={
                        phase.actualEndDate === ""
                          ? null
                          : moment(phase.actualEndDate)
                      }
                    >
                      <DatePicker
                        defaultPickerValue={(phase.actualEndDate) ? moment(phase.actualEndDate) : moment(latestDateToFocus)}
                        key="phaseActualDatePicker"
                        disabledDate={disabledPhaseActualDates}
                        onChange={onPhaseActualEndDateChange}
                        locale={i18n.language == "de" ? de : en}
                        format={"L"}
                      />
                    </Form.Item>
                  </Form>
                </div>
              ) : phase.actualEndDate === "" ? null : (
                moment(phase.actualEndDate).format(
                  i18n.language === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"
                )
              ),
              "phase_actual_end_date",
              {
                children: phase.mileStones?.map(
                  (mileStone, mileStoneIdx) => {
                    let latestDateToFocusMileStone = (phase.actualEndDate) ? moment(phase.actualEndDate) : moment(latestDateToFocus);
                    if (mileStoneIdx > 0) {
                      if (phase.mileStones[mileStoneIdx - 1]?.actualDate) {
                        console.log("phase.mileStones[mileStoneIdx - 1]?.actualDate", phase.mileStones[mileStoneIdx - 1]?.actualDate);
                        latestDateToFocusMileStone = moment(phase.mileStones[mileStoneIdx - 1]?.actualDate as string);
                      }
                    }
                    return new FlTableColumn(
                      edittingPhase(phase.id) ? (
                        <div
                          className="phaseFormItemDiv"
                          key={mileStoneIdx}
                        >
                          <Form form={form} onFinish={finishForm}>
                            <Form.Item
                              key={mileStone.id}
                              name={`${mileStone.id.toString()}_milestoneName`}
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <div className="required_message">
                                      {t("Field is required!")}
                                    </div>
                                  ),
                                },
                              ]}
                              initialValue={mileStone.name}
                            >
                              <Input
                                key="milestone_input_name"
                                className="edit_input_str"
                              />
                            </Form.Item>
                          </Form>
                        </div>
                      ) : addingMilestoneToPhase(phase.id) ||
                        mileStone.id < 0 ? (
                        mileStone.name
                      ) : (
                        <div className="milestone-name-title_div">
                          <div className="milestone-delete-div">
                            <Spin
                              size="small"
                              spinning={
                                props.deletingMilestone &&
                                toBeDeletedMilestoneId == mileStone.id
                              }
                            >
                              <Popconfirm
                                disabled={props.deletingMilestone}
                                title={t("Sure to delete?")}
                                onConfirm={() =>
                                  deleteMilestone(mileStone.id)
                                }
                              >
                                <a title={t("Delete Milestone")}>
                                  <CloseSquareOutlined
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  />
                                </a>
                              </Popconfirm>
                            </Spin>
                          </div>
                          <div className="milestone-name-div">
                            {mileStone.name}
                          </div>
                        </div>
                      ),
                      mileStone.name + mileStoneIdx.toString(),
                      {
                        children: [
                          new FlTableColumn(
                            edittingPhase(phase.id) ? (
                              <div
                                className="phaseFormItemDiv"
                                key={
                                  mileStoneIdx.toString() + "actualDate"
                                }
                              >
                                <Form form={form} onFinish={finishForm}>
                                  <Form.Item
                                    key={
                                      mileStone.id.toString() +
                                      "actualDate"
                                    }
                                    name={`${mileStone.id.toString()}_actualDate`}
                                    style={{ margin: 0 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: (
                                          <div className="required_message">
                                            {t("Field is required!")}
                                          </div>
                                        ),
                                      },
                                    ]}

                                    initialValue={
                                      mileStone.actualDate === ""
                                        ? null
                                        : moment(
                                          mileStone.actualDate as string
                                        )
                                    }
                                  >
                                    <DatePicker
                                      defaultPickerValue={(mileStone.actualDate) ? moment(mileStone.actualDate as string) : moment(latestDateToFocusMileStone)}
                                      key="milestoneActualDatePicker"
                                      //disabled={!canAddMilestoneActualDate()}
                                      disabledDate={
                                        //(current: Moment) => disabledMilestoneActualDates(current, phase.id)
                                        disabledMilestoneActualDates
                                      }
                                      onChange={(value: Moment) =>
                                        onMilestoneActualDateChang(
                                          value,
                                          mileStone.id
                                        )
                                      }
                                      locale={
                                        i18n.language == "de" ? de : en
                                      }
                                      format={"L"}
                                    />
                                  </Form.Item>
                                </Form>
                              </div>
                            ) : mileStone.actualDate ===
                              "" ? null : typeof mileStone.actualDate ===
                                "string" ? (
                              moment(
                                mileStone.actualDate as string
                              ).format(
                                i18n.language === "de"
                                  ? "DD.MM.YYYY"
                                  : "MM/DD/YYYY"
                              )
                            ) : (
                              mileStone.actualDate
                            ),
                            mileStone.actualDate + "actualDate",
                            {
                              children: [
                                new FlTableColumn(
                                  edittingPhase(phase.id) ? (
                                    canAddGate() ===
                                      mileStone.id ? (
                                      <div
                                        className="phaseFormItemDiv"
                                        key={
                                          mileStoneIdx.toString() +
                                          "plannedDate"
                                        }
                                      >
                                        <Form
                                          form={form}
                                          onFinish={finishForm}
                                        >
                                          <Form.Item
                                            key={
                                              mileStone.id.toString() +
                                              "gateName"
                                            }
                                            name={`${mileStone.id.toString()}_gateName`}
                                            style={{ margin: 0 }}
                                            initialValue={
                                              mileStone.gateName
                                            }
                                          >
                                            <Input
                                              key="gateInputname"
                                              className="edit_input_str"
                                              disabled={
                                                canAddGate() !==
                                                mileStone.id
                                              }
                                            />
                                          </Form.Item>
                                        </Form>
                                      </div>
                                    ) : (
                                      <Tooltip
                                        trigger="hover"
                                        title={
                                          <div className="toolTip-container-div">
                                            <div className="tooltip_text_div">
                                              {t("Gate can be added only for milestone at the end of phase.")}
                                            </div>
                                            <div className="tooltip_icon_div">
                                              <CloseCircleOutlined
                                                style={{
                                                  fontSize: "30px",
                                                }}
                                                className="tooltip_err_icon"
                                              />
                                            </div>
                                          </div>
                                        }
                                        getPopupContainer={(
                                          triggerNode
                                        ) =>
                                          triggerNode.parentElement
                                        }>
                                        <div
                                          className="phaseFormItemDiv"
                                          key={
                                            mileStoneIdx.toString() +
                                            "plannedDate"
                                          }>
                                          <Form form={form} onFinish={finishForm}>
                                            <Form.Item key={mileStone.id.toString() + "gateName"} name={`${mileStone.id.toString()}_gateName`} style={{ margin: 0 }} initialValue={mileStone.gateName}                                                        >
                                              <Input
                                                key="gateInputname"
                                                className="edit_input_str"
                                                disabled={canAddGate() !== mileStone.id}
                                              />
                                            </Form.Item>
                                          </Form>
                                        </div>
                                      </Tooltip>
                                    )
                                  ) : (
                                    mileStone.gateName
                                  ),
                                  "gateNameFlTableColumn",
                                  {
                                    DataIndex: mileStone.id.toString(),
                                    onCell: (record, rowIndex) => {
                                      //console.log("oncell", record, rowIndex);
                                      if (record["key"] != 'total') return { colSpan: 1 }
                                      else return { colSpan: 0 }
                                    },
                                    render: (
                                      value,
                                      record,
                                      index
                                    ) => {
                                      let dataCellContent =
                                        edittingPhase(phase.id) ? (
                                          record["key"] === "total" ? null : (
                                            <div
                                              className="phaseFormItemDiv"
                                              key={
                                                index.toString() +
                                                "fteValueDiv"
                                              }
                                            >
                                              <Form
                                                form={form}
                                                onFinish={
                                                  finishForm
                                                }
                                              >
                                                <Form.Item
                                                  key={
                                                    "ftevalueId" +
                                                    record[
                                                      "departmentId"
                                                    ].toString()
                                                  }
                                                  name={`${mileStone.id.toString()}+${record[
                                                    "departmentId"
                                                  ]
                                                    }_fteValueId`}
                                                  initialValue={
                                                    record[
                                                    `${mileStone.id.toString()}_fteId`
                                                    ]
                                                  }
                                                  hidden={true}
                                                ></Form.Item>
                                                <Form.Item
                                                  key={
                                                    index.toString() +
                                                    "ftevalue"
                                                  }
                                                  name={`${mileStone.id.toString()}+${record[
                                                    "departmentId"
                                                  ]
                                                    }_fteValue`}
                                                  style={{
                                                    margin: 0,
                                                  }}
                                                  rules={[
                                                    {
                                                      required: false,
                                                      message: (
                                                        <div className="required_message">
                                                          {t("Field is required!")}
                                                        </div>
                                                      ),
                                                    },
                                                    {
                                                      validator: (_, value) => ((typeof (value) === 'undefined') || (value >= 0)) ? Promise.resolve() : Promise.reject(new Error('must be positive'))
                                                    },
                                                  ]}
                                                  initialValue={
                                                    value
                                                  }
                                                >
                                                  <InputNumber
                                                    formatter={(
                                                      value
                                                    ) => {
                                                      return dprojectInputFormatter(value);
                                                    }}
                                                    parser={(x) =>
                                                      dprojectParser(
                                                        x,
                                                        i18n.language
                                                      )
                                                    }
                                                    key={
                                                      record[
                                                        "departmentId"
                                                      ].toString() +
                                                      mileStone.id.toString()
                                                    }
                                                    className="edit_input_str"
                                                  />
                                                </Form.Item>
                                              </Form>
                                            </div>
                                          )
                                        ) : value ? (
                                          typeof value ===
                                            "number" ? (
                                            <div
                                              className="phaseFormItemDiv"
                                              key={
                                                index.toString() +
                                                "fteValueDiv"
                                              }
                                            >{formatValue(value)}</div>
                                          ) : (
                                            <div
                                              className="phaseFormItemDiv"
                                              key={
                                                index.toString() +
                                                "fteValueDiv"
                                              }
                                            >{value}</div>
                                          )
                                        ) : null;
                                      return dataCellContent;
                                    },
                                  }
                                ),
                              ]
                            }
                          ),
                        ],
                      }
                    );
                  }
                ),
              }
            ),
          ],
        }
      );
    });

    let firstCol = new FlTableColumn(
      (
        <div className="phaseHeaderDiv">
          <div className="phaseHeaderContentDiv">Phase</div>
        </div>
      ),
      "phase_header",
      {
        align: "left",
        width: 180,
        fixed: 'left',
        children: [
          new FlTableColumn(
            (
              <div className="PhaseHeaderActualDateDiv">
                <div className="PhaseHeaderActualDateValue">
                  {t("Phase actual end date")}
                </div>
              </div>
            ),
            "phase_actualDate_header",
            {
              align: "left",
              width: 180,
              fixed: 'left',
              children: [
                new FlTableColumn(
                  (
                    <div className="MileStoneHeadrDiv">
                      <div className="MileStoneHeaderValueDiv">
                        {t("Milestone")}
                      </div>
                    </div>
                  ),
                  "milestone_header",
                  {
                    align: "left",
                    width: 180,
                    fixed: 'left',
                    children: [
                      new FlTableColumn(
                        (
                          <div className="ActualdateHeaderDiv">
                            <div className="ActualdateHeaderValueDiv">
                              {t("Milestone actual date")}
                            </div>
                          </div>
                        ),
                        "actualdate_header",
                        {
                          align: "left",
                          width: 180,
                          fixed: 'left',
                          children: [
                            new FlTableColumn(
                              (
                                <div className="GateHeaderDiv">
                                  <div className="GateHeaderValueDiv">
                                    {t("Milestoen gate")}
                                  </div>
                                </div>
                              ),
                              "gate_header",
                              {
                                width: 180,
                                fixed: 'left',
                                align: "left",
                                DataIndex: "department",
                                render: (
                                  value: string,
                                  record,
                                  index
                                ) => {
                                  //console.log("DisciplinesSpansDiv ", value, index, record);                                  
                                  let values = value.split(".");
                                  if (record["key"] == 'total') return <div className="fte-total-header">{values[1]}</div>;
                                  return (
                                    <div
                                      style={{
                                        backgroundColor:
                                          record["backGroundColor"],
                                        color:
                                          record["foreGroundColor"],
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="DisciplinesSpansDiv">
                                        <span className="DisciplineOrderSpan">
                                          {(record["key"] == 'total')
                                            ? null
                                            : values[0]}
                                        </span>
                                        <span className="DisciplineNameSpan">
                                          {values[1]}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                },
                              }
                            ),
                          ],
                        }
                      ),
                    ],
                  }
                ),
              ],
            }
          ),
        ],
      }
    );

    return [firstCol, ...phasesCols];
  };

  let view = props.active ? (
    <Spin spinning={(props.uploadingPhase || props.uploadingMilestone || props.gettingFlProjectDetails)}>
       <Alert message={`${t("Phase duration must be at least two days")}`} type="warning" />
      <div className="input_tab_main_div">     
        {(tableColsAndDataReady) ? (
          <div className="fl_table_div">
            <div className="navigation-div">
              <div className="navigate-to-template-admin">
                {props.flProjectDetails?.flProjectPhases.length === 0 ? (
                  <div className="template-warning-link-div">
                    <div className="template-warning-div">
                      {t("The selected template doesn't include enough phases.")}
                    </div>
                    <div className="template-link-div">
                      {isAdmin() ? (
                        <a
                          className="navigate-to-template-link"
                          onClick={() =>
                            navigate("/admin/front-loading-template")
                          }
                        >
                          {t("Edit Template phases")}
                        </a>
                      ) : (
                        <div className="contact-an-administrator">
                          {t("Please, contact an administrator")}
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="navigate-to-discipline-admin">
                {props.flProjectDetails?.flDepartments.length === 0 ? (
                  <div className="template-warning-link-div">
                    <div className="template-warning-div">
                      {t("There are no defined departments for this BM Project.")}
                    </div>
                    <div className="template-link-div">
                      {isAdmin() ? (
                        <a
                          className="navigate-to-template-link"
                          onClick={() => navigate("/dashboard")}
                        >
                          {t("Edit project departments")}
                        </a>
                      ) : (
                        <div className="contact-an-administrator">
                          {t("Please, contact an administrator")}
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <Table
              dataSource={tableData}
              columns={tableCols}
              pagination={false}
            />
          </div>
        ) : (
          <div className="fl_table_spinner_div">
            <Spin />
          </div>
        )}
      </div>
    </Spin>
  ) : null;

  return view;
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    currentFrontLoadingProjectId:
      state.generalInfo.profileResponse?.currentFrontLoadingProjectId,
    flProjectDetails: state.frontLoadingProjects.flProjectDetails,
    gettingFlProjectDetails: state.frontLoadingProjects.gettingFlProjectDetails,
    uploadingPhase: state.frontLoadingProjects.uploadingPhase,
    phaseValidDates: state.frontLoadingProjects.phaseValidDates,
    gettingPhaseValidDates: state.frontLoadingProjects.gettingPhaseValidDates,
    uploadingMilestone: state.frontLoadingProjects.uploadingMilestone,
    deletingMilestone: state.frontLoadingProjects.deletingMilestone,
    profileResponse: state.generalInfo?.profileResponse,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getFlProjectDetails: (id: number) =>
      dispatch({ type: ActionTypes.GetFlProjectDetails, id: id }),

    uploadPhase: (phase: PhaseToUpload) =>
      dispatch({ type: ActionTypes.UploadPhase, phase: phase }),

    uploadMilestone: (
      milestone: AddedMilestoneToUpload,
      cuurentFlProjectId: number
    ) =>
      dispatch({
        type: ActionTypes.UploadMilestone,
        milestone: milestone,
        flProjectId: cuurentFlProjectId,
      }),

    getPhaseValidDates: (phaseId: number) =>
      dispatch({ type: ActionTypes.GetPhaseValidDates, phaseId: phaseId }),

    changeMilestoneActualDateMax: (actualDateMax: string) =>
      dispatch({
        type: ActionTypes.ChangeMilestoneActualDateMax,
        actualDateMax: actualDateMax,
      }),

    changeMilestonePlannedDateMax: (plannedDateMax: string) =>
      dispatch({
        type: ActionTypes.ChangeMilestonePlannedDateMax,
        plannedDateMax: plannedDateMax,
      }),

    deleteMilestone: (id: number, flProjectId: number) =>
      dispatch({
        type: ActionTypes.DeleteMilestone,
        id: id,
        flProjectId: flProjectId,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputTab);
