import { ActionTypes } from "../actionsTypes";
import {  IIndustrySegmentBestPracticeState } from "./IGeneralState";

const initialState: IIndustrySegmentBestPracticeState = {
  isIndustrySegmentBestPracticeLoading: false,
  industrySegmentBestPracticeResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const IndustrySegmentBestPracticeReducer = (state = initialState, action: any): IIndustrySegmentBestPracticeState => {
  switch (action.type) {
    case ActionTypes.CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER:
      return {
        ...state,
        isIndustrySegmentBestPracticeLoading: action.isLoading,
      };
    case ActionTypes.SAVE_INDUSTRY_SEGMENT_BEST_PRACTICES:
      return {
        ...state,
        industrySegmentBestPracticeResult: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      };
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      };
    default:
      break;
  }
  return state;
};
export default IndustrySegmentBestPracticeReducer;
