import { ToastMessages } from "../../Enums/Messages";
import { takeEvery, put, all } from "redux-saga/effects";
import { ActionTypes } from "../actionsTypes";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import LeversForAdminService from '../../Services/LeversManagementForAdminService';
import { ILeverManagementItem } from "../../Models/ILeversManagement";

function* onGetLeversForAdmin() {
    try {
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: true });
        let _result: ICommonResponse = yield LeversForAdminService.GetLeversForAdmin();
        if (!_result.isError) {
            let result = _result.result as ILeverManagementItem[];
            yield put({ type: ActionTypes.SAVE_LEVERS_FOR_ADMIN, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({
            type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER,
            isLoading: false,
        });
    } catch (error) {
        console.error("ERR in onGetQuestion: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onUpdateLever(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield LeversForAdminService.UpdateLever(action.model);

        if (!resultUpdate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield LeversForAdminService.GetLeversForAdmin();
            let result = _result.result as ILeverManagementItem[];
            yield put({ type: ActionTypes.SAVE_LEVERS_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result:resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });

        }
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateSubLever(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield LeversForAdminService.UpdateSubLever(action.model);

        if (!resultUpdate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield LeversForAdminService.GetLeversForAdmin();
            let result = _result.result as ILeverManagementItem[];
            yield put({ type: ActionTypes.SAVE_LEVERS_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result:resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateSuccessFactor(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield LeversForAdminService.UpdateSuccessFactor(action.model);
        if (!resultUpdate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield LeversForAdminService.GetLeversForAdmin();
            let result = _result.result as ILeverManagementItem[];
            yield put({ type: ActionTypes.SAVE_LEVERS_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateKpi(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield LeversForAdminService.UpdateKpi(action.model);
        if (!resultUpdate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield LeversForAdminService.GetLeversForAdmin();
            let result = _result.result as ILeverManagementItem[];
            yield put({ type: ActionTypes.SAVE_LEVERS_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result:resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateQuestion(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield LeversForAdminService.UpdateQuestion(action.model);
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        if (!resultUpdate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield LeversForAdminService.GetLeversForAdmin();
            let result = _result.result as ILeverManagementItem[];
            yield put({ type: ActionTypes.SAVE_LEVERS_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultUpdate});
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* watchGetLeversForAdmin() {
    yield takeEvery(ActionTypes.GET_LEVERS_FOR_ADMIN, onGetLeversForAdmin);
}
function* watchUpdateLeverForAdmin() {
    yield takeEvery(ActionTypes.UPDATE_LEVER_FOR_ADMIN, onUpdateLever);
}
function* watchUpdateSubLeverForAdmin() {
    yield takeEvery(ActionTypes.UPDATE_SUB_LEVER_FOR_ADMIN, onUpdateSubLever);
}
function* watchUpdateSuccessFactorForAdmin() {
    yield takeEvery(ActionTypes.UPDATE_SUCCESS_FACTOR_FOR_ADMIN, onUpdateSuccessFactor);
}
function* watchUpdateUpdateKpiForAdmin() {
    yield takeEvery(ActionTypes.UPDATE_KPIS_FOR_ADMIN, onUpdateKpi);
}
function* watchUpdateUpdateQuestionForAdmin() {
    yield takeEvery(ActionTypes.UPDATE_QUESTION_FOR_ADMIN, onUpdateQuestion);
}
export default function* LeversMngSaga() {
    yield all([
        watchGetLeversForAdmin(),
        watchUpdateLeverForAdmin(),
        watchUpdateSubLeverForAdmin(),
        watchUpdateSuccessFactorForAdmin(),
        watchUpdateUpdateKpiForAdmin(),
        watchUpdateUpdateQuestionForAdmin()
    ]);
}
