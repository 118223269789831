import { ToastMessages } from "../Enums/Messages";
import { IBMProjectItem, ICompleteBmProject, IUpdateBmProjectExtraInfoDto } from "../Models/IBMProjectItem";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";

export default class BmProjectService {
  static async GetBmProjects(customerId: number ): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getBmProjects, {
        params: {
          customerId
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async GetBmProjectWithAllData(bmProjectId:number): Promise<ICommonResponse> {
    return await asupapi
        .get(endPoints.GetBmProjectWithAllData, {params: {bmProjectId: bmProjectId}})
        .then(function (response) {
            let result: ICommonResponse = response.data;
            return result;
        })
        .catch(function (error) {
            if (error.response) {
                console.log('error1 ', error.response);
                if (error.response.data) {
                    console.log('error0 ', error?.response?.data);
                    let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                    return result;
                } else {
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.UnExpectedError
                        }
                    } as ICommonResponse
                }
            } else if (error.request) {
                console.log('error2 ', error.request);
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.NetworkError
                    }
                } as ICommonResponse
            } else if (error.message) {
                console.log('error3 ', error.message);
                //do something other than the other two
            }
            return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError
                }
            } as ICommonResponse
        });
}
  static async UpdateBmProject(model: IBMProjectItem): Promise<ICommonResponse> {
    console.log("UpdateBmProject", model);
    return await asupapi
      .post(endPoints.updateBmProject, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async CreateBmProject(model: IBMProjectItem): Promise<ICommonResponse> {
    console.log("CreateBmProject", model);
    return await asupapi
      .post(endPoints.createBmProject, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async ChangeCurrentBmProject(projectId: number): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.CHANGECURRENTPROJECT, { projectId: projectId })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async UpdateCurrentProjectPeers(isLevers: boolean, levers_ks: number[], levers_xs: number[]): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.UPDATECURRPROJECTPEERS, { levers: isLevers, ks: levers_ks, xs: levers_xs })
      .then(function (response) {
        console.log('UpdateCurrentProjectPeers response: ', response);

        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async deleteBmProject(id: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.deleteBmProject, {
        params: {
          id
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async GetCompletionDetailsForBmProject(bmProjectId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getCompletionDetailsForBmProject, {
        params: {
          bmProjectId
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async CompleteBmProject(model: ICompleteBmProject): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.completeBmProject, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async ReopenBmProject(bmProjectId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.reopenBmProject, {
        params: {
          bmProjectId
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async IncludeBmProjectInEasyAccessLevers(bmProjectId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.includeBmProjectInEasyAccessLevers, {
        params: {
          bmProjectId
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async ExcludeBmProjectInEasyAccessLevers(bmProjectId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.excludeBmProjectInEasyAccessLevers, {
        params: {
          bmProjectId
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async UpdateBmProjectExtraInfo(model: IUpdateBmProjectExtraInfoDto): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.updateBmProjectExtraInfo, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
}