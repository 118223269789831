import React, { useRef, useState } from 'react'
import { IErrorPage, IErrorUpdate } from '../../../Models/IErrorPage'
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { Form, Input, Table, Typography } from 'antd';
import AdminOperation from '../../../Components/AdminOperation/AdminOperation';
import ErrorLogModal from './ErrorLogModal';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { t } from 'i18next';

interface IProps {
  errorsRow: IErrorPage[];
  updateError: (model: IErrorUpdate) => any;
}
const ErrorsPageForAdminTable = (props: IProps) => {
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  //search code start
  const searchInput = useRef<any>(null);
  const handleSearch = (
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };
  //search code end
  const columns: any[] = [
    {
      key: "orginalMessage_key",
      title:`${t("Original Message")}`,
      dataIndex: "orginalMessage",
      render: (text: any, _: any) => {
        return <Typography.Text>{text}</Typography.Text>
      },
      ...getColumnSearchProps(`${t("Original Message")}`, 'orginalMessage', searchInput, handleSearch),
      sorter: (a, b) => a?.orginalMessage?.localeCompare(b?.orginalMessage) ?? 0,
      //defaultSortOrder: 'ascend',
    },
    {
      key: "likeMessage_key",
      title: `${t("Like Message")}`,
      dataIndex: 'likeMessage',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='likeMessage' >
              <Input.TextArea autoSize/>
            </Form.Item>
          )
        } else {
          return <Typography.Text>{text}</Typography.Text>
        }
      },
    },
    {
      key: "messageEn_key",
      title: `${t("English Message")}`,
      dataIndex: 'messageEn',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='messageEn' >
            <Input.TextArea autoSize/>
            </Form.Item>
          )
        } else {
          return <Typography.Text>{text}</Typography.Text>
        }
      },
      ...getColumnSearchProps(`${t("English Message")}`, 'messageEn', searchInput, handleSearch),
      sorter: (a, b) => a?.messageEn?.localeCompare(b?.messageEn) ?? 0,
    },
    {
      key: " messageDe_key",
      title:`${t("Germany Message")}`,
      dataIndex: 'messageDe',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='messageDe' >
             <Input.TextArea autoSize/>
            </Form.Item>
          )
        } else {
          return <Typography.Text>{text}</Typography.Text>
        }
      },
      ...getColumnSearchProps(`${t("Germany Message")}`, 'messageDe', searchInput, handleSearch),
      sorter: (a, b) => a?.messageDe?.localeCompare(b?.messageDe) ?? 0,

    },
    {
      title:`${t("Logs")}`,
      dataIndex: '',
      render: (_: any, record: any) => {
        return <ErrorLogModal logs={record?.logs} />
      },
    },
    {
      title:`${t("Actions")}`,
      dataIndex: '',
      width: '10%',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return <AdminOperation
          record={record}
          editable={editable}
          onCancel={cancel}
          onEdit={edit}
        />
      },
    }
  ]
  const isEditing = (record: IErrorUpdate) => record.key === editingKey;
  const edit = (record: Partial<IErrorUpdate> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const onFinish = async (values: any) => {
    try {
      await form.validateFields()
      const index = props.errorsRow.findIndex(item => item.key === editingKey);
      if (index > -1) {
        const item = props.errorsRow[index];
        const errorUpdate = { id: item.id, ...values }
        await props.updateError(errorUpdate);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      setEditingKey('');
    }

  }
  return (<Form form={form} key="error_form" onFinish={onFinish}>
    <Table
      key='error_table'
      getPopupContainer={() => document.getElementById("app-layout")}
      className='table_seg'
      bordered
      dataSource={props.errorsRow}
      columns={columns}
      rowClassName="editable-row"
      scroll={{ y: "calc(100vh - 300px)" }}
      pagination={{
        defaultPageSize: 50,
        pageSizeOptions: [50, 100, 150],
        total: props.errorsRow.length,
        showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
        showSizeChanger: true,
        locale:{items_per_page:`/ ${t("Page")}`},
      }}
      locale={{
        triggerDesc: t('Click to sort descending'),
        triggerAsc: t('Click to sort ascending'),
        cancelSort: t('Click to cancel sorting')
    }}
    />
  </Form>
  );
}

const mapDispatchForProps = (dispatch: any) => {
  return {
    updateError: (model: IErrorUpdate) => dispatch({ type: ActionTypes.UPDATE_ERROR, model }),
  }
}
export default connect(null, mapDispatchForProps)(ErrorsPageForAdminTable);