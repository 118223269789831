import { Button, Form, Input, InputNumber, Space } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { RulesName, RulesRequire } from '../../Rules/RulesValidation';
import { IActivity, IActivityTaskTemplate } from '../../../../Models/IActivityManagement';
import { t } from 'i18next';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';

interface IProps {
    activityProfileID: number;
    oldTaskData: IActivityTaskTemplate;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    updateActivityProfileTask: (model: IActivityTaskTemplate) => any;
}
const ActivityProfileTaskEditModal = (props: IProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const formValuesHandler = async () => {
        try {
            await form.validateFields()
            let activities: IActivity[] = form.getFieldsValue(['activities'])?.activities;
            let values = form.getFieldsValue();
            const activityTaskUpdate: IActivityTaskTemplate = {
                id: props.oldTaskData.id,
                activityProfileId: props.activityProfileID,
                serialNumberInProfile: values?.serialNumberInProfile,
                textDe: values.textDe,
                textEn: values.textEn,
                activities: activities,
            }
            props.updateActivityProfileTask(activityTaskUpdate);

        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <ModalAdmin title={`${t("Edit Activity Profile Task")}`} btnText={`${t("Edit")}`}
            width="520px"
            closeAndReset={closeAndReset}
            modalResponse={props.modalResponse}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" onClick={() => formValuesHandler()} loading={props.isModalLoading}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form} initialValues={props.oldTaskData}>
                <Form.Item label={`${t("Serial Number In Profile")}`} name='serialNumberInProfile'
                    rules={RulesRequire({ filedName: `${t("serialNumberInProfile")}` })}
                >
                    <InputNumber
                        min={1}
                    />
                </Form.Item>
                <Form.Item label={`${t("English Text")}`} name='textEn'
                    rules={RulesName({ name: `${t("English Text")}`,countChar:128 })}>
                    <Input
                    />
                </Form.Item>
                <Form.Item label={`${t("German Text")}`} name='textDe'
                    rules={RulesName({ name: `${t("German Text")}`,countChar:128 })}>
                    <Input
                    />
                </Form.Item>
                <Form.List name="activities">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'activityEn']}
                                        rules={[{ required: true, message: `${t("Missing english activity")}` },
                                        { max: 100, message: `${t("English activity")} ${t("must be less than 100 characters.")}` }

                                        ]}
                                    >
                                        <Input placeholder={`${t("English activity")}`} />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'activityDe']}
                                        rules={[{ required: true, message: `${t("Missing germany activity")}` },
                                        { max: 100, message: `${t("Germany activity")} ${t("must be less than 100 characters.")}` }
                                        ]}
                                    >
                                        <Input placeholder={`${t("Germany activity")}`} />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    {`${t("Add activities")}`}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </ModalAdmin>
    )
}
const mapStateToProps = (state: any) => {
    return {
        isModalLoading: state.activiyProfileMng.isModalLoading,
        modalResponse: state.activiyProfileMng.modalResponse,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateActivityProfileTask: (model: IActivityTaskTemplate) => dispatch({ type: ActionTypes.UPDATE_ACTIVITY_TASK_FOR_ADMIN, model }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityProfileTaskEditModal);
