import { IDProject } from "../../../../Models/IDProject";

export const emptyDProject: IDProject = {
    projectId: 0,
    id: 0,
    key: 0,

    name: '',
    finishingYear: undefined,
    runTime: undefined,
    comments: undefined,
    actualProjectCosts: undefined,

    revenue: undefined,
    productMargin: undefined,
    projectType: undefined,

    delay: undefined,
    missedExpectedSales: undefined,
    projectCostsDelayedMonths: undefined,
    otherAdditionalCosts: undefined,

    mcPlanned: undefined,
    mcActual: undefined,
    quantity: undefined,
    otherCosts: undefined,
    mcShareOfRD: undefined,

    qualityCosts: undefined,
    qualityShareOfRD: undefined,

    q1_Total_Sales_in_N: undefined,
    q1_Revenue_share: undefined,

    q2_Total_Sales_in_N: undefined,
    q2_Revenue_share: undefined,

    q3_Total_Sales_in_N: undefined,
    q3_Revenue_share: undefined,

    q4_Target_price: undefined,
    q4_Actual_price: undefined,
    q4_Deviation: undefined,

    q5_Planned_quantities: undefined,
    q5_Actual_quantities: undefined,
    q5_Deviation: undefined,

    q6_Planned_project_costs: undefined,
    q6_Deviation: undefined,

    q7_customer_experience: undefined,

    q8_technical_safeguarding: undefined,

    q9_tool_dropped_parts: undefined,

    q10_series_production: undefined,

    q11_costs_last_3months: undefined,

    q12_costs_last_3months: undefined,

    q13_Average_delay: undefined,

    q14_One_time_yield_rate: undefined,

    q15_Claim_Tickets: undefined,

    q16_Complaint_rate: undefined,

    q17_Planned_revenue: undefined,
    q17_Actual_revenue: undefined,
    q17_Deviation: undefined,

    q18_Support_requests: undefined,

    q19_Planned_project_runtime: undefined,
    q19_Deviation: undefined,

    q20_features_relaized: undefined,

    q21_Budget_Deviation: undefined,

    q22_MC_Deviation: undefined,

    q4522_Subcontractor_in_the_project: undefined,
}; 