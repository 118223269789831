import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { ToastMessages } from "../Enums/Messages";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import { IUserManagement } from "../Models/IUserEmployee";

export default class UserManagementService {
    static async GetAllUsers(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.getAllUsers, {})
            .then(function (response) {
                let result: ICommonResponse = response.data;
                console.log("result test", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async CreateUser(model: IUserManagement): Promise<ICommonResponse> {
        console.log("Create User :", model);
        return await asupapi
            .post(endPoints.createUser, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async UpdateUser(model: IUserManagement): Promise<ICommonResponse> {
        console.log("Update User", model);
        return await asupapi
            .post(endPoints.updateUser, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async ActiveUser(id: number): Promise<ICommonResponse> {
        console.log("active UserId :", id);
        return await asupapi
            .get(endPoints.activeUser, {
                params: {
                    userId: id
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async DeactivateUser(id: number): Promise<ICommonResponse> {
        console.log("deactivate User");
        return await asupapi
            .get(endPoints.deactivateUser, {
                params: {
                    userId: id
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
}

