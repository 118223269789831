import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import './change_project.css';
import ColOptionsDrop from './ColOptionsDrop/ColOPtionsDrop';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import { BmProject, GeneralBmProjectDto } from '../../../Models/Responses/ProfileResponse';
import { Button, Result, Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ChangeProjectTable from './ChangeProjectTable/ChangeProjectTable';

interface Iprops {
    projects: GeneralBmProjectDto[];
    currentProject: BmProject;
    isLoading: boolean;
    currentUserId: number;
    changeCurrentProject: (currentProjectId: number) => any;
};

function CustomerProjects(props: Iprops) {
    const navigate = useNavigate();
    const changeCurrentProject = (currentProjectId: number) => {
        let projects = props.projects.filter((project, idx) => {
            return (project.id === currentProjectId);
        });
        if (projects) {
            if (projects.length > 0) {
                let selectedProject = projects[0];
                props.changeCurrentProject(currentProjectId);
            }
        }
    };

    let view = (
        <div className='change_project_div'>
            <div className='columns_options_div'>
                <ColOptionsDrop />
            </div>
            <div className='change_project_table_div'>
                 <ChangeProjectTable changeCurrentProject={changeCurrentProject}/>
            </div>
        </div>);
    return (
        <Spin spinning={props.isLoading}>
            <div className='change_project_container'>
                {view}
            </div>
        </Spin>
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        projects: state.generalInfo.allProjects,
        isLoading: state.generalInfo.isLoading,        
        tablesColsProps: state.tablesColsProps.CustomerProjectsTableColsProps,
        currentProject: state.generalInfo.profileResponse?.currentProject,
        currentUserId: state.generalInfo?.profileResponse?.id
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        changeCurrentProject: (currentProjectId: number) => dispatch({ type: ActionTypes.CHANGECURRENTPROJECTREMOTLY, currentProjectId: currentProjectId, redirectToPath: "/projects" }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProjects);