import { ActionTypes } from "../actionsTypes";
import { IFrontLoadingDepartmentState } from "./IGeneralState";

const initialState: IFrontLoadingDepartmentState = {
    isFrontLoadingDepartmentLoading: false,
    frontLoadingDepartmentResult: null,
    isModalLoading: false,
    modalResponse: null,
};

const FrontLoadingDepartmentReducer = (state = initialState, action: any): IFrontLoadingDepartmentState => {
    switch (action.type) {
        case ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER:
          return {
            ...state,
            isFrontLoadingDepartmentLoading: action.isLoading,
          };
        case ActionTypes.SAVE_FRONT_LOADING_DEPARTMENTS:
          return {
            ...state,
            frontLoadingDepartmentResult: action.result,
          };
        case ActionTypes.SetModalLoader:
          return {
            ...state,
            isModalLoading: action.result
          };
        case ActionTypes.SetModalResponse:
          return {
            ...state,
            modalResponse: action.result
          };
        default:
          break;
      }
      return state;
};
export default FrontLoadingDepartmentReducer ;
    