import {
  Collapse,
  Form,
  InputNumber,
  Typography,
  Row,
  Col,
  Input,
  Select,
  Popconfirm,
  Space,
  Tooltip,
  Table,
  Segmented,
  Checkbox,
  Spin,
} from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  StopOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  StopTwoTone,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { Dproject } from "../../EBITTool-V2/Models/CostTypesResponse";
import { inputFormatter, inputParser, formatValue } from "../../../../Utils/helperFunctions";
import i18n from "../../../../i18n";
import { ILeverQuestion } from "../../../../Models/Levers/Items";
import { ISubquestionRate, ISubquestionSale } from "../../../../Models/Requests/SaveLevers";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IResponseDProject } from "../../../../Models/IResponseDProject";
import { IDProject } from "../../../../Models/IDProject";
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import { ICommonResponse } from "../../../../Models/Responses/ICommonResponse";
import DProjectsServices from "../../../../Services/DProjectsService";
import { put } from "redux-saga/effects";
const { Panel } = Collapse;
const { Title } = Typography;
const { Option } = Select;
const { Text } = Typography;
interface DProjctInLeverRowSaleValue {
  key: string;
  project: number;//d-project id
  projectyear: number;//year of complation
  plannedSales: number;
  actualSales: number;
  NewProduct?: string;
}
interface IProps {
  question: ILeverQuestion;
  onchangeSubquestionTable: (questionV: ILeverQuestion) => any;
  dprojects: IDProject[];
  addDprojectLocally: (newDprojects: IDProject[], _result: ICommonResponse) => any;
  saveLevers: () => any;
  currentBmProject: BmProject;
}

function SubQuestionTableSales(props: IProps) {
  const newRowCOD: DProjctInLeverRowSaleValue = {
    key: "newDProjectInleverRow",
    project: null,
    projectyear: 0,
    plannedSales: 0,
    actualSales: 0,
    NewProduct: "",
  };
  const { t } = useTranslation();
  const [editRecord, setEditRecord] = useState<DProjctInLeverRowSaleValue>(
    newRowCOD
  );
  const [form] = Form.useForm();
  const [addFromList, setAddFromList] = useState(true);
  const projectId = Form.useWatch(`project_${editRecord?.project}`, form);
  const [addNewDprojectLoader, setAddNewDProjectLoader] = useState(false);
  useEffect(() => {
    //console.log("props.dprojects", props.dprojects);
    if (props.dprojects) {
      form.setFieldsValue(fillInForm(newRowCOD));
      setEditRecord(newRowCOD);
    }
  }, [props.dprojects]);

  const options = props.dprojects?.filter(c => !(props.question.subquestions.sales.map(c => c.dprojectId).includes(c.id)))
    .map((dp) => {
      return {
        value: dp.id,
        label: dp.name,
      };
    });

  const dataColCODSale: ColumnsType = [
    {
      title: (
        <p>
          {`${t("Products")}`}
        </p>
      ),
      width: '30%',
      dataIndex: "product",
      fixed: "left", // fix element  
      ellipsis: true,
      render: (text: any, record: DProjctInLeverRowSaleValue, index) => {
        //console.log("render", text, record, index);
        if (index == 0) {
          if (editRecord?.key != "newDProjectInleverRow") {
            return (
              <Select
                disabled
                showSearch
                placeholder="Select..."
                options={options}
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  fontSize: "12px",
                }}
              />
            );
          }
          // return (
          //   <Space size={2}>
          return <Row>
            <Col span={2}>              <Checkbox
              checked={addFromList}
              onChange={(e) => {
                form.setFieldsValue(fillInForm(newRowCOD));
                setEditRecord(newRowCOD);
                setAddFromList(e.target.checked);
                console.log("form fields", form.getFieldsValue());
              }}
            /></Col>
            <Col span={1}></Col>
            <Col span={21}>
              <Form.Item
                hidden={!addFromList}
                name={`project_${record?.project}`}
                style={{ margin: 0 }}
                rules={
                  !addFromList
                    ? []
                    : [
                      {
                        required: true,
                        message: `${t("required*")}`,
                      },
                    ]
                }
              >
                <Select                  
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={() =>
                    document.getElementById("app-layout")
                  }
                  optionFilterProp="label"
                  showSearch
                  placeholder={t("Auswahl")}
                  options={options}
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "12px",
                    width: "100%",
                    zIndex: 5,
                  }}
                />
              </Form.Item>

              <Form.Item
                hidden={addFromList}
                name={`projectname_${record?.project}`}
                style={{ margin: 0 }}
                rules={
                  addFromList
                    ? []
                    : [
                      {
                        required: true,
                        message: `${t("required*")}`,
                      },
                    ]
                }
              >
                <Input placeholder={t("Neues Entwciklungsprojekt")} />
              </Form.Item>
            </Col>
            {/* </Space> */}
          </Row>
          {/* ); */ }
        } else {
          let name = props.dprojects.find(
            (c) => c.id == record?.project
          )?.name ?? "";
          return name;
        }
      }, // keep this if you need to render link with data cell.
    },
    {
      title: (
        <p>
             {`${t("Year")}`}
        </p>
      ),
      width: '20%',
      dataIndex: "projectyear",
      render: (text: any, record: DProjctInLeverRowSaleValue, index) => {
        if (record?.key == "sum") return <></>;
        if (index == 0) {
          if (editRecord?.key != "newDProjectInleverRow") {
            return <InputNumber
              maxLength={4}
              disabled />;
          }
          if (addFromList) {
            return props.dprojects?.find(
              (c) => c.id == projectId
            )?.finishingYear;
          } else {
            return (
              <Form.Item
                name={`ProjektAbschlussjahr_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  maxLength={4}
                  min={1970} />
              </Form.Item>
            );
          }
        } else {
          return props.dprojects.find(
            (c) => c.id == record?.project
          )?.finishingYear ?? "";
        }
      },
    },
    {
      title: (
        <p>
          {`${t("Plan")}`}
        </p>
      ),
      width: '25%',
      dataIndex: "plannedSales",
      render: (text: any, record: DProjctInLeverRowSaleValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newDProjectInleverRow") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`plannedSales_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`plannedSales_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.plannedSales);
          }
        }
      },
    },
    {
      title: (
        <p>
          {`IST`}
        </p>
      ),
      width: '25%',
      dataIndex: "actualSales",
      render: (text: any, record: DProjctInLeverRowSaleValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newDProjectInleverRow") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`actualSales_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`actualSales_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  formatter={value => inputFormatter(value, i18n.language)}
                  parser={x => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.actualSales);
          }
        }
      },
    },
    {
      title: <></>,
      width: '8%',
      dataIndex: "actions",
      render: (text, record: DProjctInLeverRowSaleValue, index) => {
        if (record?.key == "sum") return <></>;
        if (index == 0) {
          return (
            <Space size={20}>
              {" "}
              <span>
                <Typography.Link
                  disabled={editRecord?.key != record?.key}
                  onClick={() => onAddRow(record)} //handleAdd(record?.key)}
                  style={{ marginRight: 8 }}
                >
                  <Tooltip placement="bottom" title={`${t("Add")}`}>
                    <PlusOutlined style={{ color: "green" }} />
                  </Tooltip>
                </Typography.Link>
              </span>
            </Space>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Space size={10}>
                <Popconfirm
               title={`${t("Sure to save?")}`}
                  onConfirm={() => onEditRow(record)}
                >
                  <a>
                    <Tooltip placement="bottom"  title={`${t("Save data")}`}>
                      <CheckCircleOutlined />
                    </Tooltip>
                  </a>
                </Popconfirm>

                <Typography.Link
                  disabled={
                    editRecord?.key != "newDProjectInleverRow" &&
                    editRecord?.key != record?.key
                  }
                  onClick={() => {
                    form.setFieldsValue(fillInForm(newRowCOD));
                    setEditRecord(newRowCOD);
                  }}
                >
                  <Tooltip placement="bottom"  title={`${t("Cancel Changes")}`}>
                    <StopTwoTone twoToneColor="red" />
                  </Tooltip>
                </Typography.Link>
              </Space>
            );
          } else {
            return (
              <Space size={10}>
                <Typography.Link
                  disabled={
                    editRecord?.key != "newDProjectInleverRow" &&
                    editRecord?.key != record?.key
                  }
                  onClick={() => {
                    form.setFieldsValue(fillInForm({ ...record }));
                    setEditRecord(record);
                  }}
                >
                  <Tooltip placement="bottom" title={`${t("Edit")}`}>
                    <EditTwoTone twoToneColor="#1890ff" />
                  </Tooltip>
                </Typography.Link>

                <Popconfirm
                  title={`${t("Sure to delete?")}`}
                  onConfirm={() => onDeleteRow(record)}
                >
                  <a>
                    <Tooltip placement="bottom" title={`${t("Delete")}`}>
                      <DeleteTwoTone twoToneColor="red" />
                    </Tooltip>
                  </a>
                </Popconfirm>
              </Space>
            );
          }
        }
      },
    },
  ];



  const dataRowCODSale = props.question.subquestions.sales.map((row) => {
    return {
      key: `qk_row_${row.dprojectId}`,
      actualSales: row.actualSales,
      plannedSales: row.plannedSales,
      project: row.dprojectId,
      projectyear: row.dprojectCompletionYear,
      NewProduct: "",
    } as DProjctInLeverRowSaleValue;
  }) ?? [];

  dataRowCODSale.push(
    {
      key: `sum`,
      actualSales: dataRowCODSale.reduce((a, v) => a = a + v.actualSales, 0),
      plannedSales: dataRowCODSale.reduce((a, v) => a = a + v.plannedSales, 0),
      project: 0,
      projectyear: 0,
      NewProduct: "",
    } as DProjctInLeverRowSaleValue
  );

  function fillInForm(row: any) {
    let rowNames: any = {};
    rowNames[`project_${row.project}`] = row.project;
    rowNames[`projectname_${row.project}`] = row.NewProduct ?? "";
    rowNames[`ProjektAbschlussjahr_${row.project}`] = row.projectyear;

    rowNames[`actualSales_${row.project}`] = row.actualSales;
    rowNames[`plannedSales_${row.project}`] = row.plannedSales;
    return rowNames;
  }
  function getRowFromForm(obj: any) {
    let row = {
      key: "",
      project: 0,
      projectyear: 0,
      actualSales: 0,
      plannedSales: 0,
      plannedRate: 0,
      NewProduct: "",
    };

    Object.keys(obj).forEach((key) => {
      console.log(key, obj[key]);
      if (key.includes("projectname_")) row.NewProduct = obj[key] ?? "";
      if (key.includes("ProjektAbschlussjahr_")) row.projectyear = obj[key] ?? "";
      if (key.includes("project_")) row.project = obj[key];
      if (key.includes("plannedSales_")) row.plannedSales = obj[key];
      if (key.includes("actualSales_")) row.actualSales = obj[key];
      if (key.includes("plannedRate_")) row.plannedRate = obj[key];
    });
    return row;
  }

  async function onAddRow(row: DProjctInLeverRowSaleValue) {
    try {
      console.log("obj", form.getFieldsValue());
      const row2 = await form.validateFields();
      let newData = getRowFromForm(row2);
      let question = props.question;
      if (newData.NewProduct == "") {
        question.subquestions.sales.push({
          dprojectName: "",
          dprojectId: newData.project,
          actualSales: (newData as unknown as DProjctInLeverRowSaleValue).actualSales,
          dprojectCompletionYear: 0,
          plannedSales: (newData as unknown as DProjctInLeverRowSaleValue).plannedSales,
          isReadonly: false
        });
      } else {
        setAddNewDProjectLoader(true);
        let newDprojects: IDProject[] = [];
        let _result: ICommonResponse = await DProjectsServices.UploadDproject({
          generalDprojectInfo: {
            name: newData.NewProduct,
            finishingYear: newData.projectyear,
            actualProjectCosts: 0,
            comments: [],
            id: 0,
            key: 0,
            projectId: props.currentBmProject.id,
            runTime: 0
          },
          costOfDelay: null,
          innovation: null,
          manufacturingCost: null,
          qualityCost: null,
          questionaire: null
        });
        if (!_result.isError) {
          let _result2: ICommonResponse = await DProjectsServices.GetDProjects(props.currentBmProject.id);
          if (!_result2.isError) {
            let result2 = _result2.result as IResponseDProject[];
            newDprojects = result2.map(newDproject => {
              let dproject: IDProject = {
                ...newDproject.generalDprojectInfo,
                ...newDproject.innovation,
                ...newDproject.costOfDelay,
                ...newDproject.manufacturingCost,
                ...newDproject.qualityCost,
                ...newDproject.questionaire
              }
              return dproject;
            });


            let newDp = newDprojects.filter(c => c.name == newData.NewProduct && c.finishingYear == newData.projectyear)?.[0];
            console.log("newDp", newDp);
            let _dprojectId = newDp?.id ?? props.dprojects.length + 1;
            question.subquestions.sales.push({
              dprojectName: newData.NewProduct,
              dprojectId: _dprojectId,
              actualSales: (newData as unknown as DProjctInLeverRowSaleValue).actualSales,
              dprojectCompletionYear: newData.projectyear,
              plannedSales: (newData as unknown as DProjctInLeverRowSaleValue).plannedSales,
              isReadonly: false
            });

          }
        } else {
        }
        props.addDprojectLocally(newDprojects, _result);

        setAddNewDProjectLoader(false);
      }
      let Sum_Actual = question.subquestions.sales.reduce((a, v) => a = a + v.actualSales, 0);
      let Sum_Planned = question.subquestions.sales.reduce((a, v) => a = a + v.plannedSales, 0);
      if (question.kpiValue.formula == "(Sum_Actual-Sum_Planned)/Sum_Planned") {
        question.kpiValue.value = (Sum_Actual - Sum_Planned) / Sum_Planned;
      } else {
        question.kpiValue.value = (Sum_Actual / Sum_Planned) - 1;
      }
      if (question.kpiValue.unit == "%") {
        question.kpiValue.value = question.kpiValue.value * 100;
      }
      form.setFieldsValue(fillInForm(newRowCOD));
      setEditRecord(newRowCOD);
      await props.onchangeSubquestionTable(question);

      //props.saveLevers();

    } catch (e) {
      console.log(e);
    }
  }

  async function onEditRow(row: DProjctInLeverRowSaleValue) {
    try {
      //console.log("obj", form.getFieldsValue());
      const row2 = await form.validateFields();
      //console.log("obj row2", row2);
      let newData = getRowFromForm(row2);
      //console.log(newData);
      let question = props.question;

      question.subquestions.sales.filter(c => c.dprojectId == row.project).map(c => {
        c.plannedSales = (newData as unknown as DProjctInLeverRowSaleValue).plannedSales;
        c.actualSales = (newData as unknown as DProjctInLeverRowSaleValue).actualSales;
      });


      let Sum_Actual = question.subquestions.sales.reduce((a, v) => a = a + v.actualSales, 0);
      let Sum_Planned = question.subquestions.sales.reduce((a, v) => a = a + v.plannedSales, 0);
      if (question.kpiValue.formula == "(Sum_Actual-Sum_Planned)/Sum_Planned") {
        question.kpiValue.value = (Sum_Actual - Sum_Planned) / Sum_Planned;
      } else {
        question.kpiValue.value = (Sum_Actual / Sum_Planned) - 1;
      }
      //      question.kpiValue.value =
      // (question.subquestions.sales.reduce((a, v) => a = a + v.actualSales/question.subquestions.sales.reduce((a, v) => a = a + v.plannedSales, 0), 0)) - 1;
      if (question.kpiValue.unit == "%") {
        question.kpiValue.value = question.kpiValue.value * 100;
      }

      console.log('question', question);
      form.setFieldsValue(fillInForm(newRowCOD));
      setEditRecord(newRowCOD);
      props.onchangeSubquestionTable(question);
    } catch (e) {
      console.log("onEditRow ", e);
    }
  }
  function onDeleteRow(row: DProjctInLeverRowSaleValue) {
    //console.log(row);    
    let question = props.question;

    question.subquestions.sales = question.subquestions.sales.filter(c => c.dprojectId != row.project);

    let Sum_Actual = question.subquestions.sales.reduce((a, v) => a = a + v.actualSales, 0);
    let Sum_Planned = question.subquestions.sales.reduce((a, v) => a = a + v.plannedSales, 0);
    if (question.kpiValue.formula == "(Sum_Actual-Sum_Planned)/Sum_Planned") {
      question.kpiValue.value = (Sum_Actual - Sum_Planned) / Sum_Planned;
    } else {
      question.kpiValue.value = (Sum_Actual / Sum_Planned) - 1;
    }
    // question.kpiValue.value =
    //   (question.subquestions.sales.reduce((a, v) => a = a + v.actualSales/question.subquestions.sales.reduce((a, v) => a = a + v.plannedSales, 0), 0)) - 1;
    if (question.kpiValue.unit == "%") {
      question.kpiValue.value = question.kpiValue.value * 100;
    }
    props.onchangeSubquestionTable(question);
  }

  return (
    <div className="editable-table-dproject-lever" id="editable-table-dproject-lever">
      <Form form={form} component={false} key="cod_form">
        <Table
          key={"cod_table"}
          bordered={false}
          dataSource={[newRowCOD, ...dataRowCODSale]}
          columns={dataColCODSale}
          scroll={{ y: 500 }}
          rowClassName={() => "editable-row"}
          pagination={false}
          className={"subquestion-table"}
        />
      </Form>
    </div>
  );
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    dprojects: state.dprojects.dprojects,// state.generalInfo?.profileResponse?.currentProject?.dprojects,
    currentBmProject: state.generalInfo?.profileResponse?.currentProject,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    addDprojectLocally: (newDprojects: IDProject[], _result: ICommonResponse) => dispatch({ type: ActionTypes.AddDprojectLocally, newDprojects: newDprojects, _result: _result })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubQuestionTableSales);
