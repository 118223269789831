import { Table, Button, Checkbox, Input, InputRef, Popconfirm, Space } from "antd";
import { ColumnsType, ColumnType } from "antd/es/table";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { useRef, useState } from "react";
import { connect } from "react-redux";
import { EditLeversFilter, EditLeversView } from '../DataTypes/DataTypes';
import { FilterOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import { BmProject, BmProjectForEditLevers } from "../../../../Models/Responses/ProfileResponse";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { formatValue } from "../../../../Utils/helperFunctions";
import { getColumnSearchProps } from "../../../../Components/SearchButton"; 
import { t } from "i18next";

interface IProps {
    tableColsProps: typeof EditLeversFilter;
    currentProject: BmProject;
    projects: BmProjectForEditLevers[];
    levers_ks: number[],
    levers_xs: number[],
    setLevers_ks: (ids: number[]) => void,
    setLevers_xs: (ids: number[]) => void
};

function EditLeversTable(props: IProps) {
    const togglek = (projectId: number) => {
        if (props.levers_ks.includes(projectId)) {
            let levers_ks_copy = props.levers_ks.filter(id => id !== projectId);
            props.setLevers_ks(levers_ks_copy);
        } else {
            let levers_ks_copy = [...props.levers_ks];
            levers_ks_copy.push(projectId);
            props.setLevers_ks(levers_ks_copy);
        }
    };

    const togglex = (projectId: number) => {
        if (props.levers_xs.includes(projectId)) {
            let levers_xs_copy = props.levers_xs.filter(id => id !== projectId);
            props.setLevers_xs(levers_xs_copy);
        } else {
            let levers_xs_copy = [...props.levers_xs];
            levers_xs_copy.push(projectId);
            props.setLevers_xs(levers_xs_copy);
        }

    };

    type DataIndex = keyof EditLeversView;

    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
     
    const columnsBeforeFilter: any[] = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'ProjectName',
            width: 150,
            align: 'center',
            fixed: "left",
            //className: props.filter.projectName.visible ? 'show_col' : 'hide_col',
            ...getColumnSearchProps('Project Name','projectName', searchInput, handleSearch),
        },
        {
            title: `${t("Sector")}`,
            dataIndex: 'sector',
            key: 'sector',
            width: 150,
            align: 'center',
            //className: props.filter.customerName.visible ? 'show_col' : 'hide_col',
            ...getColumnSearchProps( `${t("Sector")}`,'sector', searchInput, handleSearch),
        },
        {
            title: `${t("Segment")}`,
            dataIndex: 'segment',
            key: 'segment',
            width: 150,
            align: 'center',
             ...getColumnSearchProps(`${t("Segment")}`,'segment', searchInput, handleSearch),
        },
        {
            title: 'Strategy',
            dataIndex: 'strategy',
            key: 'Strategy',
            width: 100,
            align: 'center',
            //className: props.filter.year.visible ? 'show_col' : 'hide_col',
            //...getColumnSearchProps('strategy'),
        },
        {
            title: 'Process',
            dataIndex: 'process',
            key: 'Process',
            width: 100,
            align: 'center',
            //className: props.filter.year.visible ? 'show_col' : 'hide_col',
            //...getColumnSearchProps('process'),
        },
        {
            title: 'Project Mgmt',
            dataIndex: 'projectMgmt',
            key: 'ProjectMgmt',
            width: 100,
            align: 'center',
            //className: props.filter.year.visible ? 'show_col' : 'hide_col',
            //...getColumnSearchProps('projectMgmt'),
        },
        {
            title: 'Structure',
            dataIndex: 'structure',
            key: 'Structure',
            width: 100,
            align: 'center',
            //className: props.filter.year.visible ? 'show_col' : 'hide_col',
            //...getColumnSearchProps('structure'),
        },
        {
            title: 'People',
            dataIndex: 'people',
            key: 'People',
            width: 100,
            align: 'center',
            //className: props.filter.year.visible ? 'show_col' : 'hide_col',
            //...getColumnSearchProps('people'),
        },
        {
            title: `${t("company PI")}`,
            dataIndex: 'average',
            key: 'Average',
            width: 100,
            align: 'center',
            //className: props.filter.year.visible ? 'show_col' : 'hide_col',
            //...getColumnSearchProps('average'),
        }
    ];

    let columnsAfterFilter = columnsBeforeFilter.filter((col) => {
        //console.log("props.tableColsProps[col.key]", props.tableColsProps, col.key);
        return props.tableColsProps[col.dataIndex.toString()]?.visible ?? false;
    });

    columnsAfterFilter.push(
        {
            title: 'k',
            dataIndex: 'Actions',
            render: (_: any, project: EditLeversView) => {
                return (<Space size={20}>
                    <Popconfirm disabled={props.levers_xs.includes(project.id)} title={props.levers_ks.includes(project.id) ? "Sure to remove as k?" : "Sure to add as k?"} onConfirm={() => togglek(project.id)}>
                        <a><Checkbox disabled={props.levers_xs.includes(project.id)} checked={props.levers_ks.includes(project.id) && !props.levers_xs.includes(project.id)} /*onChange={(e: CheckboxChangeEvent) => togglek(e, project.id)}*/ /></a>
                    </Popconfirm>
                </Space>);
            },
            width: 50,
            align: 'center',
            fixed: 'right'
        }
    );

    columnsAfterFilter.push(
        {
            title: 'x',
            dataIndex: 'Actions',
            render: (_: any, project: EditLeversView) => {
                return (<Space size={20}>
                    <Popconfirm disabled={props.levers_ks.includes(project.id)} title={props.levers_xs.includes(project.id) ? "Sure to remove as x?" : "Sure to add as x?"} onConfirm={() => togglex(project.id)}>
                        <a><Checkbox disabled={props.levers_ks.includes(project.id)} checked={props.levers_xs.includes(project.id) && !props.levers_ks.includes(project.id)} /*onChange={(e: CheckboxChangeEvent) => togglek(e, project.id)}*/ /></a>
                    </Popconfirm>
                </Space>);
            },
            width: 50,
            align: 'center',
            fixed: "right"
        }
    );
    let data: EditLeversView[] = [];
    data = props.projects.filter(p => p.id !== props.currentProject.id)
        .map(project => {
            return (
                {
                    id: project.id,
                    key: project.id,
                    projectName: project.name,
                    sector: project.sector,
                    segment: project.segment,
                    strategy: `${formatValue(project.strategyRate)}%`,
                    process: `${formatValue(project.processRate)}%`,
                    projectMgmt: `${formatValue(project.projectMngRate)}%`,
                    structure: `${formatValue(project.structureRate)}%`,
                    people: `${formatValue(project.peopleRate)}%`,
                    average: `${formatValue(project.average)}%`
                }
            );
        });
    return (
        <div className="edit_levers_table_div">
            <Table
                columns={columnsAfterFilter}
                dataSource={data}
                scroll={{ y: "calc(100vh - 400px)" }}
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 150],
                    total: data.length,
                    showTotal: (total) => `Total ${total} items`,
                    showSizeChanger: true
                }} />
        </div>
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        //projects: state.projects.projects,
        //currentProject: state.projects.currentProject,
        projects: state.generalInfo.ProjectsForEditLevers,
        currentProject: state.generalInfo.profileResponse?.currentProject,
        tableColsProps: state.tablesColsProps.editLeversTableColsProps
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLeversTable);