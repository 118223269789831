import React, { useState } from 'react';
import { Button, Checkbox, Form, Select } from 'antd';
import { ICustomerNewBmProjectForUser } from '../../../../Models/ICustomerUsers';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { IBmProjectList } from '../../../../Models/IBmProjectList';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { RulesRequire } from '../../Rules/RulesValidation';
import { t } from 'i18next';
interface IProps {
    customerId: number;
    userId: number;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    bmProjectList: IBmProjectList[];
    bmProjectsData: ICustomerNewBmProjectForUser[];
    collectionRoles: any[];
    createBmProjectForCustomerUser: (model: ICustomerNewBmProjectForUser, id: number) => any;

}
const CustomerUserAssignBmProjectModal = (props: IProps) => {
    const [formValues, setFormValues] = useState<ICustomerNewBmProjectForUser>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const newBmProject = {
                userId: props.userId,
                ...formValues
            };
            await props.createBmProjectForCustomerUser(newBmProject, props.customerId)
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    let bmProjectsListExceptSelectedProjects: IBmProjectList[] =
        props.bmProjectList?.filter(item => !props.bmProjectsData?.map(x => x?.bmProjectId)?.includes(item?.id)) ?? [];
    return (
        <ModalAdmin
            title={`${t("Add")} ${t("New")} ${t("Bm Project")}`}
            btnText={`${t("Add")}`}
            width="520px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save")}`} & {`${t("Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form}>
                <Form.Item label={`${t("Bm Project")}`} name='bmProjectId' rules={RulesRequire({ filedName: `${t("Bm Project")}` })} >
                    <Select showSearch placeholder={`${t("Select Bm Project")}`} optionFilterProp="children"
                        onChange={(value: number) => setFormValues({ ...formValues, bmProjectId: value })}>
                        {bmProjectsListExceptSelectedProjects?.map((b: any) => <Select.Option value={b?.id} key={b?.id}>{b?.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label={`${t("Data Collection Roles")}`} name='dataCollectionRoles' rules={RulesRequire({ filedName: `${t("Data Collection Roles")}` })}>
                    <Checkbox.Group options={props.collectionRoles && props.collectionRoles} onChange={(list: any[]) => setFormValues({ ...formValues, dataCollectionRoles: list })} />
                </Form.Item>
            </Form>
        </ModalAdmin>
    )
}
const mapStateToProps = (state: any) => {
    return {
        isModalLoading: state.customerUser?.isModalLoading,
        modalResponse: state.customerUser?.modalResponse,
        collectionRoles: state.generalInfo.profileResponse?.generalSettings?.collectionRoles,
        bmProjectList: state.bmProjectList.bmProjectListResult,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        createBmProjectForCustomerUser: (model: ICustomerNewBmProjectForUser, id: number) => dispatch({ type: ActionTypes.ASSIGN_NEW_BM_PROJECT_FOR_USER, model: model, customerId: id })

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerUserAssignBmProjectModal);