import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import React from "react";

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={localStorage.getItem('selectedTheme') ?? "light"}
          insertionPoint="styles-insertion-point"
        >
          <I18nextProvider i18n={i18n} key={"lang_provider"} >
            <App />
          </I18nextProvider>
        </ThemeSwitcherProvider>
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
