import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { createBrowserHistory } from "history";
import { Outlet } from "react-router-dom";
import AsupHeader from "./Header";
import SiderMenu from "./SiderMenu";
import "./AdminAppLayout.scss";
import { AsupFooter } from "../Footer";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { ActionTypes } from "../../Redux/actionsTypes";
import { IGeneralState } from "../../Redux/reducers/IGeneralState";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import BreadCrumbAdmin from "../../Components/Breadcrumb/BreadcrumbAdmin";
interface IProps {
  isLoading: boolean;
  isfullScreen: boolean;
  //profileResponse: ProfileResponse;
  changeFullScreen: (state: boolean) => any;
}
function AdminAppLayout(props: IProps) {
  const history = createBrowserHistory();
  const screen1 = useFullScreenHandle();
  const reportChange = useCallback((state, handle) => {
    if (handle === screen1) {
      props.changeFullScreen(state);
    }
  }, [screen1]);
  const onClickFullScreen = () => {
    props.changeFullScreen(true);
    screen1.enter();
  }
  const onClickExitFullScreen = () => {
    props.changeFullScreen(false);
    screen1.exit();
  }

  return (
    <FullScreen handle={screen1} onChange={reportChange}>
      <Layout className="app-admin-layout" id="app-layout" style={{ minHeight: '100vh', direction: 'ltr' }} hasSider>
        <SiderMenu collapsed={false} onCollapse={true} history={history} />
        <Layout className="site-layout">
          <AsupHeader history={history} onClickFullScreen={onClickFullScreen} onClickExitFullScreen={onClickExitFullScreen} />
          <BreadCrumbAdmin />
          <Content>
            <Outlet />
          </Content>
          <AsupFooter />
        </Layout>
      </Layout>
    </FullScreen>);
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    isLoading: state.generalInfo?.isLoading,
    isfullScreen: state.generalInfo?.isFullScreen,
    //profileResponse: state.generalInfo?.profileResponse
  }
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    changeFullScreen: (state: boolean) => dispatch({ type: ActionTypes.ChangeFullScreen, state: state })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAppLayout); 
