import { SettingOutlined, SisternodeOutlined } from "@ant-design/icons";
import { Form, Input, Space, Table, Tooltip } from 'antd';
import type { InputRef } from 'antd';
import React, { useState, useRef } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IIndustryItem, IIndustryNew } from '../../../Models/IIndustry';
import { ActionTypes } from '../../../Redux/actionsTypes';
import AdminOperation from "../../../Components/AdminOperation/AdminOperation";
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { RulesName } from "../Rules/RulesValidation";
import i18n from "../../../i18n";
import { t } from "i18next";
interface IProps {
    industryDataRow: IIndustryItem[],
    updateIndustry: (model: IIndustryNew) => any;
    deleteIndustry: (id: number) => any;
}
const IndustryTable = (props: IProps) => {
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    //search code start
    const searchInput = useRef<InputRef>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "nameEn_1",
            title: `${t("English Name")}`,
            dataIndex: 'nameEn',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='nameEn' rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })} >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),
            sorter: (a, b) => a?.nameEn?.localeCompare(b?.nameEn) ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: "nameDe_2",
            title: `${t("German Name")}`,
            dataIndex: 'nameDe',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='nameDe' rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}  >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),
            sorter: (a, b) => a?.nameDe?.localeCompare(b?.nameDe)  ?? 0,
            // defaultSortOrder: 'ascend',
        },
        {
            title: `${t("Actions")}`,
            dataIndex: '',
            width: '100px',
            render: (_: any, record: IIndustryItem) => {
                const editable = isEditing(record);
                return <AdminOperation
                    deleteId={record.id}
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                    onDelete={deleteIndustry}
                />

            },

        },
        {
            title: `${t("Segments")}`,
            dataIndex: '',
            width: '100px',
            fixed: "right",
            render: (_: any, record: any) => {
                return <Space>
                    <Tooltip title={`${t("Go to")} ${t("Segments")}`}>
                        <Link to={`/admin/industries/segments?industryId=${record.id}`}
                        >
                            {`${t("Segments")}`} <SisternodeOutlined />
                        </Link>
                    </Tooltip>
                </Space>

            }
        },
        {
            title: "Best Practices",
            dataIndex: '',
            width: '15%',
            fixed: "right",
            render: (_: any, record: any) => {
                return <Space>
                    <Tooltip title={`${t("Go to")} Best Practices`}>
                        <Link to={`/admin/industries/bestPractice?industryId=${record.id}`} >
                            Best Practices <SettingOutlined />
                        </Link>
                    </Tooltip>
                </Space >

            }
        }


    ];
    const isEditing = (record: IIndustryItem) => record.key === editingKey;
    const edit = (record: Partial<IIndustryItem> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            const index = props.industryDataRow.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = props.industryDataRow[index];
                const newIndustry: IIndustryNew = { id: item.id, codeEn: values.nameEn, codeDe: values.nameDe, };
                await props.updateIndustry(newIndustry);
                setEditingKey('');
                form.resetFields()
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
            setEditingKey('');
        }

    }
    const deleteIndustry = async (id: any) => {
        try {
            await props.deleteIndustry(id);

        } catch (error) {
            console.log('delete Failed:', error);
        }
    };
    return (<Form form={form} key="ind_form" onFinish={onFinish}>
        <Table
            key='ind_table'
            getPopupContainer={() => document.getElementById("app-layout")}
            className='table_seg'
            bordered
            dataSource={props.industryDataRow}
            columns={columns}
            rowClassName="editable-row"
            scroll={{ y: "calc(100vh - 300px)" }}
            pagination={{
                defaultPageSize: 50,
                pageSizeOptions: [50, 100, 150],
                total: props.industryDataRow.length,
                showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                showSizeChanger: true,
                locale: { items_per_page: `/ ${t("Page")}` },
            }}
            locale={{
                triggerDesc: t('Click to sort descending'),
                triggerAsc: t('Click to sort ascending'),
                cancelSort: t('Click to cancel sorting')
            }}
        />
    </Form>
    );
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateIndustry: (model: IIndustryNew) => dispatch({ type: ActionTypes.UPDATE_INDUSRTY, model: model }),
        deleteIndustry: (id: number) => dispatch({ type: ActionTypes.DELETE_INDUSTRY, id }),
    };
};

export default connect(null, mapDispatchToProps)(IndustryTable);