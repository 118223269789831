import ColoredTable from "../ColoredTable";
import { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { IEbitToolState, IGeneralInfoState, IGeneralState } from "../../Redux/reducers/IGeneralState";
import { ActionTypes } from "../../Redux/actionsTypes";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

interface RDProps {
  ebitState: IEbitToolState,
  generalInfo: IGeneralInfoState,
  getRndInfo: any;
  getRecivedRndUnits: any;
}

function RD(props: RDProps) {
  let navigate = useNavigate();
  useEffect(() => {
    if (props.generalInfo?.profileResponse?.currentProject) {
      props.getRndInfo(props.generalInfo?.profileResponse.currentProject.id);
      props.getRecivedRndUnits(props.generalInfo?.profileResponse.currentProject.id);
    }
  }, [props.generalInfo?.profileResponse]);

  if (props.generalInfo.isPartialLoader) {
    return (<div className="general-spin">
      <Spin size="large" />
    </div>);
  } else {
    return (
      <section id="rd-section">
        {(props.ebitState?.rndInfoResponse?.disciplines) ?
          <ColoredTable
            data={props.ebitState.rndInfoResponse}
            recievedRndUints={props.ebitState.recivedRndUnits}
            //projects={props.generalInfo?.allProjects}
            currentProject={props.generalInfo?.profileResponse?.currentProject}
          />
          : <></>
        }
      </section>
    );
  }
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    ebitState: state.ebit,
    generalInfo: state.generalInfo,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getRndInfo: (input: any) => dispatch({ type: ActionTypes.GetRndInfo, projectID: input }),
    getRecivedRndUnits: (input: any) => dispatch({ type: ActionTypes.GetRecivedRndUnits}),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(RD);
