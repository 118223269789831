import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { ToastMessages } from "../Enums/Messages";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import { IFrontLoadingTemp, IFrontLoadingTempPhase } from "../Models/IFrontLoading";

export default class FrontLoadingService {
    static async GetAllFrontLoadingTemp(): Promise<ICommonResponse> {
        return await asupapi.get(
            endPoints.getAllFrontLoading
        ).then(function (response) {
            let result: ICommonResponse = response.data;
            console.log("result test", result);
            return result;

        }).catch(function (error) {
            if (error.response) {
                console.log("error1", error.response);
                if (error.response.data) {
                    console.log("error0 ", error.response.data);
                    let result: ICommonResponse = error.response.data;
                    return result;
                }
            } else if (error.request) {
                console.log("error2 ", error.request);
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.NetworkError,
                    },
                } as ICommonResponse;
            } else if (error.message) {
                console.log("error3 ", error.message);
                //do something other than the other two
            }
            return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError,
                },
            } as ICommonResponse;
        })
    }
    static async CreateFrontLoadingTemp(model: IFrontLoadingTemp): Promise<ICommonResponse> {
        console.log("CreateFrontLoadingTemp", model);
        return await asupapi
            .post(endPoints.createFrontLoadingTemp, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async UpdateFrontLoadingTemp(model: IFrontLoadingTemp): Promise<ICommonResponse> {
        console.log("Update FrontLoadingTemp", model );
        return await asupapi
            .post(endPoints.updateFrontLoadingTemp, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async deleteFrontLoadingTemp(id: number): Promise<ICommonResponse> {
        console.log("deleteFrontLoadingTemp");
        return await asupapi
            .get(endPoints.deleteFrontLoadingTemp, {
                params: {
                    id
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    //FrontLoadingPhase
    static async CreateFrontLoadingTempPhase(model: IFrontLoadingTempPhase): Promise<ICommonResponse> {
        console.log("CreateFrontLoadingTempPhase", model);
        return await asupapi
            .post(endPoints.createFrontLoadingTempPhase, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async UpdateFrontLoadingTempPhase(model: IFrontLoadingTempPhase): Promise<ICommonResponse> {
        console.log("Update FrontLoadingTempPhase", model);
        return await asupapi
            .post(endPoints.updateFrontLoadingTempPhase, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async deleteFrontLoadingTempPhase(id: number): Promise<ICommonResponse> {
        console.log("deleteFrontLoadingTempPhase");
        return await asupapi
            .get(endPoints.deleteFrontLoadingTempPhase, {
                params: {
                    id
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }  
}