import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import {PlusOutlined} from "@ant-design/icons";
import "./index.scss";

type Props = {
  title: string,
  btnText: string,
  icon? : JSX.Element,
  isClosed: boolean,
  children: JSX.Element,
  footerchildren: JSX.Element,
};
const ModalComponent = ({ title, btnText, children, icon=null, footerchildren, isClosed ,width }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 200);
  };
  const handleCancel = () => {setVisible(false); };
  useEffect(() => {
    if (isClosed) {
      setVisible(false)
    }
  })
  return (
    <div className='general-model-component'>
      <Button type="primary" onClick={showModal} shape="default" icon={icon? icon :<PlusOutlined />}>
        {btnText}
      </Button>
      <Modal
        getContainer={() => document.getElementById('app-layout')}
        title={title}
        centered
        open={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={footerchildren}
        width={width}
      >
        {[children]}
      </Modal>
    </div >
  );
};

export default ModalComponent;