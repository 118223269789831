import { Button, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { IActivityProfileTemplateVersionItem } from '../../../../Models/IActivityManagement';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { IListItem } from '../../../../Models/Responses/ProfileResponse';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { RulesName } from '../../Rules/RulesValidation';
interface IProps {
  isModalLoading: boolean;
  profileVersionData: IActivityProfileTemplateVersionItem[],
  profileId: number;
  modalResponse: ICommonResponse;
  createNewProfileVersion: (nameEn: string, nameDe: string, profileId: number, fromProfileVersionId: number | null) => any;
}
const CreateNeVersionModal: React.FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  let versionsList: IListItem[] = props.profileVersionData.map(v => {
    return {
      label: `${v.versionNumber}`,
      value: v.id
    }
  })
  versionsList.push({ label: "--- Empty ---", value: -1 })
  const formValuesHandler = async () => {
    try {
      await form.validateFields();
      let versionId = form.getFieldValue("versionId") === -1 ? null : form.getFieldValue('versionId');
      let nameEn = form.getFieldValue("nameEn");
      let nameDe = form.getFieldValue("nameDe");
      await props.createNewProfileVersion(nameEn, nameDe, props.profileId, versionId)
    } catch (error) {
      console.log(error);
    }
  }
  const closeAndReset = () => {
    setIsModalVisible(false);
    form.resetFields();
  }
  return (
    <ModalAdmin title={`${t("Create New Version")}`} btnText={`${t("Create New Version")}`}
      width="520px"
      modalResponse={props.modalResponse}
      closeAndReset={closeAndReset}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      footerchildren={[
        <Button key="back" onClick={closeAndReset}>
          {`${t("Cancel")}`}
        </Button>,
        <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
          {`${t("Create")}`}
        </Button>
      ]}>
      <Form layout="vertical" form={form}>
        <Form.Item label={`${t("English Name")}`} name='nameEn'
          rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
        >
          <Input />
        </Form.Item>
        <Form.Item label={`${t("German Name")}`} name='nameDe'
          rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
        >
          <Input />
        </Form.Item>
        <Form.Item label={`${t("From Versions")}`} name='versionId'>
          <Select
            getPopupContainer={() => document.getElementById("app-layout")}
            showSearch
            placeholder={`${t("Select Version")}`}
            options={versionsList}
            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
          />
        </Form.Item>
      </Form>
    </ModalAdmin>
  )
}
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.activityProfileTasks.isActProTasksLoading,
    isModalLoading: state.activityProfileVersions.isModalLoading,
    modalResponse: state.activityProfileVersions.modalResponse,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    createNewProfileVersion: (nameEn: string, nameDe: string, profileId: number, fromProfileVersionId: number | null) => dispatch({ type: ActionTypes.CREATE_NEW_PROFILE_VERSION, profileId, fromProfileVersionId, nameEn, nameDe })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNeVersionModal);