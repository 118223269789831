import { ActionTypes } from "../actionsTypes";
import { IProfileMngState } from "./IGeneralState";
const initialState: IProfileMngState = {
  isProfileLoading: false,
  profileResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const ProfileMngReducer = (state = initialState, action: any): IProfileMngState => {
  switch (action.type) {
    case ActionTypes.CHANGE_PROFILE_LOADER:
      return {
        ...state,
        isProfileLoading: action.isLoading,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      };
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      };
    default:
      break;
  }
  return state;
};
export default ProfileMngReducer;
