import { ActionTypes } from "../actionsTypes";
import { IHelpPageState } from "./IGeneralState";

const initialState: IHelpPageState = {
    isHelpPageLoading: false,
    helpPagesResult: null,
    isModalLoading: false,
    modalResponse: null,
    isCurrentHelpPageLoading: false,
    currentHelpPage: null,
    currentPageCode: 'dashboard',
    helpPageForEdit: null

}

const HelpPgeForAdminReducer = (state = initialState, action: any): IHelpPageState => {
    switch (action.type) {
        case ActionTypes.CHANGE_HELP_PAGE_LOADER:
            return {
                ...state,
                isHelpPageLoading: action.isLoading,
            };
        case ActionTypes.SAVE_HELP_PAGE:
            return {
                ...state,
                helpPagesResult: action.result
            };
        case ActionTypes.SAVE_HELP_PAGE_FOR_EDIT:
                return {
                    ...state,
                    helpPageForEdit: action.result
                };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            }
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            }
        case ActionTypes.GetHelpPageLoader:
            return {
                ...state,
                isCurrentHelpPageLoading: action.isLoading,
            };
        case ActionTypes.SetHelpPage:
            return {
                ...state,
                currentHelpPage: action.result
            };
        case ActionTypes.SetHelpPageCode:
            //console.log("SetHelpPageCode", action.result);
            return {
                ...state,
                currentPageCode: action.result
            };
        default:
            break;
    }
    return state;
}
export default HelpPgeForAdminReducer;