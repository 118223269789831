import { ScatterChart, CartesianGrid, Legend, Scatter, Tooltip, XAxis, YAxis, ZAxis, LabelList, ResponsiveContainer } from "recharts";
import CustomizedDot from "./CustomizedDot";
import { useTranslation } from "react-i18next";
import { CustomizedYAxisTick, CustomizedXAxisTick } from "./CustomizedAxisTick";
import { ISABestPractice } from "../../../../Models/SelfAssessment/ISelfAssessmentView";
import { Space } from "antd";
import { useEffect, useState } from "react";

interface IProps {
    points: ISABestPractice[];
    chartWidth: number;
    chartHieght: number;
    scale: number;
};

function ScatterDiagram(props: IProps) {
    const { t } = useTranslation();
    const [mid, setMid] = useState(3);
    const [max, setMax] = useState(5);
    const [data, setdata] = useState(props.points);
    useEffect(() => {
        setMax(5);
        setMid((1 + 5) / 2);
        setdata(props.points);
    }, [props.points]);

    return (<ResponsiveContainer width="100%" height="90%" key={'SAMatrixChart_div_sd_div'}>
        <ScatterChart
            key={'SAMatrixChart_div_sd_div_sc'}
            margin={{
                top: 15,
                right: 25,
                bottom: 15,
                left: 25,
            }}>
            <CartesianGrid
                strokeDasharray="0 0"
                strokeWidth={2}
            />
            <XAxis
                scale={'linear'}
                dataKey="importance"
                type="number"
                name={t("Importance")}                
                label={{                  
                    value: t("Importance"),
                    offset: -10,        
                    fontSize: 20,            
                    fontWeight: 700,
                    position: "bottom",
                    fill: (localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'
                }}
                tickLine={false}
                ticks={[1, mid, max]}
                // tickFormatter={value => {
                //     console.log("tickFormatter", value)
                //     if (value == 1) return t("Coarse");
                //     if (value == mid) return t("Importance");
                //     if (value == max) return t("Very coarse");
                //     return "";
                // }}
                tick={<CustomizedXAxisTick isminimized={false} min={1} max={max} mid={mid} fillColor={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`} angle={0} />}
                //fontStyle={"normal"}
                fontSize={20}
                fontWeight={700}
                color={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                fill={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                stroke={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                strokeWidth={2}
                domain={[1, max]}

            />
            <YAxis
                scale={'linear'}
                dataKey="urgency"
                type="number"
                name={t("Urgency")}
                label={{
                    value: t("Urgency"),
                    angle: -90,
                    offset: 0,                    
                    fontSize: 20,            
                    fontWeight: 700,
                    fill: (localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'
                }}
                tickLine={false}
                ticks={[1, mid, max]}
                // tickFormatter={value => {
                //     if (value == 1) return t("Low");
                //     if (value == mid) return t("Urgency");
                //     if (value == max) return t("Very high");
                //     return "";
                // }}
                //tickSize={0}
                tickMargin={0}
                tick={<CustomizedYAxisTick isminimized={false} min={1} max={max} mid={mid} fillColor={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`} angle={-90} />}
                fontStyle={"normal"}
                fontSize={12}
                fontWeight={700}
                color={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                fill={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                stroke={`${(localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "white" : '#1644b0'}`}
                strokeWidth={2}
                domain={[1, max]} />
            <Scatter
                data={data}
                shape={<CustomizedDot
                    //strokeColor={"#d9d9d9"}
                    //fillColor={"#d9d9d9"}
                    //r={8}
                    cx={10}
                    cy={10}
                    //fontSize="9px"
                    //strokeWidth="1.6871"
                    key={0}
                    points={data}
                />
                }
                tooltipType="none"
            >
                <LabelList
                    fontSize={12}
                    fontWeight={700}
                    //stroke="black"
                    fill="black"
                    position={"center"}
                    
                    dataKey="index"
                    //style={{ "zIndex": "1000" }} 
                    />
            </Scatter>
        </ScatterChart>
    </ResponsiveContainer>);
};
export default ScatterDiagram;
