import { ActionTypes } from "../actionsTypes";
import { ICustomerUserState } from "./IGeneralState";

const initialState: ICustomerUserState = {
  isCustomerUserLoading: false,
  customerUsersResult: null,
  isModalLoading: false,
   modalResponse: null,
};
const CustomerUserReducer = (state = initialState, action: any):  ICustomerUserState => {
  switch (action.type) {
    case ActionTypes.CHANGE_CUSTOMERUSER_LOADER:
      return {
        ...state,
        isCustomerUserLoading: action.isLoading,
      };
    case ActionTypes.SAVE_CUSTOMERUSERS:
      return {
        ...state,
        customerUsersResult: action.result,
      };
      case ActionTypes.SetModalLoader:
        return {
            ...state,
            isModalLoading: action.result
        };
    case ActionTypes.SetModalResponse:
        return {
            ...state,
            modalResponse: action.result
        };
    default:
      break;
  }
  return state;
};
export default CustomerUserReducer;
