import { Input, Switch } from "antd";
import React, { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { IGeneralState } from "../../Redux/reducers/IGeneralState";
import { connect } from "react-redux";
import { ActionTypes } from "../../Redux/actionsTypes";
import { IProfileUserUpdate } from "../../Models/IProfileUser";
import { ProfileResponse } from "../../Models/Responses/ProfileResponse";
import { LanguagesEnum } from "../../Enums/LanguagesEnum";
//import i18n from "../../i18n";

export function ThemeSwitcher() {
    const { t, i18n } = useTranslation();
    const [isDarkMode, setIsDarkMode] = React.useState((localStorage.getItem('selectedTheme') ?? "light") == "light" ? false : true);
    const { switcher, currentTheme, status, themes } = useThemeSwitcher();

    const toggleTheme = (isChecked) => {
        setIsDarkMode(isChecked);
        localStorage.setItem("selectedTheme", isChecked ? themes.dark : themes.light);
        switcher({ theme: isChecked ? themes.dark : themes.light });
    };

    // Avoid theme change flicker
    if (status === "loading") {
        return null;
    }

    return <Switch checked={isDarkMode} defaultChecked={(localStorage.getItem('selectedTheme') ?? "light") == "light" ? false : true} checkedChildren={t("dark")} unCheckedChildren={t("light")} onChange={toggleTheme} />;
}

export function ExportLanguageSwitcher() {
    const { t, i18n } = useTranslation();

    const [isDe, setIsDe] = React.useState((localStorage.getItem('exported_files_lang') ?? "de") == "de" ? true : false);

    const toggleTheme = (isChecked) => {
        setIsDe(isChecked);
        localStorage.setItem("exported_files_lang", isChecked ? 'de' : 'en');
    };

    return <Switch checked={isDe} defaultChecked={(localStorage.getItem('exported_files_lang') ?? "de") == "de" ? true : false} checkedChildren={t("Export files in Deutsch")} unCheckedChildren={t("Export files in English")} onChange={toggleTheme} />;
}