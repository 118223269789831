import saveAs from "file-saver";
import { ToastMessages } from "../Enums/Messages";
import { AddFlProjectModel, AddFlProjectToUploadModel, EditFlProjectToUploadModel } from "../Models/FrontLoadingTool/FrontLoadingTool";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { AddedMilestoneToUpload, PhaseToUpload } from "../Scenes/Tools/FrontLoading/FrontLoadingTabs/InputTab/Models/FormObject";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";

export default class FrontLoadingToolServices {

  static async SaveFlProject(values: AddFlProjectToUploadModel): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.saveFlProject, values)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetFlTemplates(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getFlTemplates)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetFlProjects(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getFlProjects)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetToBeEdittedFlProject(flProjectId: number) {
    return await asupapi
      .get(endPoints.getToBeEdittedFlProject, { params: { id: flProjectId } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async EditFlProject(flProject: EditFlProjectToUploadModel, editConfirmed?: boolean) {
    return await asupapi
      .post(endPoints.editFlProject, flProject, { params: { editConfirmed: editConfirmed ? true : false } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async DeletFlProject(id: number) {
    return await asupapi
      .get(endPoints.deleteFlProject, { params: { id: id } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async selectFlProjectForConsultant(id: number) {
    return await asupapi
      .get(endPoints.selectFlProjectForConsultant, { params: { id: id } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async getFlProjectDetails(id: number) {
    return await asupapi
      .get(endPoints.getFlProjectDetails, { params: { id: id } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async uploadPhase(phase: PhaseToUpload) {
    return await asupapi
      .post(endPoints.uploadPhase, phase)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async uploadMilestone(milestone: AddedMilestoneToUpload) {
    return await asupapi
      .post(endPoints.uploadMilestone, milestone)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async getPhaseValidDates(phaseId: number) {
    return await asupapi
      .get(endPoints.getPhaseValidDates, { params: { phaseId: phaseId } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetRTDCurveData(flProjectId: number) {
    return await asupapi
      .get(endPoints.getRTDCurveData, { params: { flProjectId: flProjectId } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetBestPracticesCurves() {
    return await asupapi
      .get(endPoints.getBestPracticesCurves)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetAboutCurrentFlProject() {
    return await asupapi
      .get(endPoints.getAboutCurrentFlProject)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetFrontloadingPrgress() {
    return await asupapi
      .get(endPoints.getFrontloadingPrgress)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async MarkFlProjectAsComplete() {
    return await asupapi
      .post(endPoints.markFlProjectAsComplete)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async DeleteMilestone(id: number) {
    return await asupapi
      .post(endPoints.deleteMilestone, null, { params: { id: id } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        //console.log("result test2", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }


  static async ExportToExcel() {
    await asupapi
      .post(endPoints.exporToExcel, null, {
        responseType: 'blob'
      })
      .then(function (response) {
        var contentDisposition = response.headers["content-disposition"];
        var match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
        var filename = match[1];
        console.log("filename:", filename);
        var blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, filename);

      }).catch(function (error) {
        console.log('error1 ', error);
      });
  }

}