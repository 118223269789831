import { ActionTypes } from "../actionsTypes";
import { IIndustryState } from "./IGeneralState";

const initialState: IIndustryState = {
    isIndustryLoading: false,
    industriesResult: null,
    isModalLoading: false,
    modalResponse: null,
}
const IndustryReducer = (state = initialState, action: any): IIndustryState => {
    switch (action.type) {
        case ActionTypes.CHANGE_INDUSTRY_LOADER:
            return {
                ...state,
                isIndustryLoading: action.isLoading,
            };
        case ActionTypes.SAVE_INDUSTRIES:
            return {
                ...state,
                industriesResult: action.result
            };
            case ActionTypes.SetModalLoader:
                return {
                    ...state,
                    isModalLoading: action.result
                }
            case ActionTypes.SetModalResponse:
                return {
                    ...state,
                    modalResponse: action.result
                }
        default:
            break;
    }
    return state;
}
export default IndustryReducer;