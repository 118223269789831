export interface EditLeversView {
    id: number;
    key: number;
    projectName: string;
    sector: string;
    //numEmployees: number;
    segment: string;
    //complexity: string;
    strategy: string;
    process: string;
    projectMgmt: string;
    structure: string;
    people: string;
    average: string;
};

export const EditLeversFilter = {
    projectName: {
        visible: true
    },
    // branche: {
    //     visible: true
    // },
    numEmployees: {
        visible: true
    },
    segment: {
        visible: true
    },
    sector:{
        visible: true
    },
    // complexity: {
    //     visible: true
    // },
    strategy: {
        visible: true
    },
    process: {
        visible: true
    },
    projectMgmt: {
        visible: true
    },
    structure: {
        visible: true
    },
    people: {
        visible: true
    },
    average: {
        visible: true
    },
};