import peopleIcon from '../../assets/Images/Sidebar/People.svg';
import projectIcon from '../../assets/Images/Sidebar/projectt.svg';
import StrategyIcon from '../../assets/Images/Sidebar/Strategy.svg';
import StructureIcon from '../../assets/Images/Sidebar/Structure.svg';
import ProcessIcon from '../../assets/Images/Sidebar/Process.svg';
// import ReactHighcharts from "react-highcharts";
// import HighchartsMore from "highcharts/highcharts-more";
import { renderToStaticMarkup } from 'react-dom/server';
import { Button, Space } from 'antd';
import React from 'react';
//HighchartsMore(ReactHighcharts.Highcharts);


const DemoRadar = () => {
  let arrayImag = []
  arrayImag.push(peopleIcon, projectIcon, StrategyIcon, StructureIcon, ProcessIcon);
  React.useLayoutEffect = React.useEffect;
    
  const radarData: any[] = [

    {
      leverId: 182,
      label: "3.1. Projektführung",
      peer: "Ks_average",
      value: 66.66666666666666
    },
    {
      leverId: 183,
      label: "3.2. PJM- Organisation",
      peer: "Ks_average",
      value: 62.5
    },
    {
      leverId: 184,
      label: "3.3. Projektteam",
      peer: "Ks_average",
      value: 0
    },
    {
      leverId: 185,
      label: "3.4. Einzelprojektmanagement",
      peer: "Ks_average",
      value: 66.66666666666666
    },
    {
      leverId: 186,
      label: "3.5. Multiprojektmanagement",
      peer: "Ks_average",
      value: 5
    },
    {
      leverId: 182,
      label: "3.1. Projektführung",
      peer: "Reference",
      value: 2
    },
    {
      leverId: 183,
      label: " 3.2. PJM- Organisation",
      peer: "Reference",
      value: 58
    },
    {
      leverId: 184,
      label: "3.3. Projektteam",
      peer: "Reference",
      value: 10
    },
    {
      leverId: 185,
      label: "3.4. Einzelprojektmanagement",
      peer: "Reference",
      value: 10,
    },
    {
      leverId: 186,
      label: "3.5. Multiprojektmanagement",
      peer: "Reference",
      value: 80
    }

  ]


  const radar = {
    chart: {
      renderTo: "container",
      polar: true
    },
    // pane: {
    //   startAngle: 0,
    //   endAngle: 360
    // },
    title: {
      text: "demo radar"
    },
    xAxis: {
      categories: radarData.map(f => f.label),
      line: null,
      tickLine: null,
      labels: {
        useHTML: true,
        formatter: function () {

          const myArrayText = this.value.split(" ");
          const myText = myArrayText.map(m => <span key={m}>{m}</span>);
          const htmlString = renderToStaticMarkup(
            <Button size="small" type='link'>
              <Space direction='vertical' size="small" style={{ display: 'flex' }}>
                <img src={arrayImag[2]} alt='' />
                {myText}
              </Space>
            </Button>
          );
          return htmlString;
        },
      },
    },
    yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
      min: 0
    },


    series: [
      {
        type: 'line',
        name: "Reference",
        data: radarData.filter(f => f.peer === "Reference").map(f => f.value),
      },
      {
        type: 'line',
        name: "Ks_average",
        data: radarData.filter(f => f.peer === "Ks_average").map(f => f.value),
      },
    ],
  };
  return <></>;
  // return (
  //   <ReactHighcharts config={radar}></ReactHighcharts>
  // );
}
export default DemoRadar; 