import { Col, Row, Table, Typography, Image, Avatar, Space } from 'antd';
import { IEventLogFilter, ILogs } from '../../../Models/IEventLogForAdmin';
import userImage from "../../../assets/Images/user_image.png";
import parse from 'html-react-parser';
import { t } from 'i18next';
interface IProps {
  logsData: ILogs[];
  setFilters: (model: IEventLogFilter) => any;
  filters: IEventLogFilter;
  totalCount: number;
}
const EventLogsForAdminTable = (props: IProps) => {
  console.log(props.logsData);

  const columns: any[] = [
    {
      key: "dateTime_key",
      title: `${t("Date Time")}`,
      dataIndex: "dateTime",
      ellipsis: true,
      render: (text: any, _: any) => {
        return <Typography.Text style={{ padding: ".5rem" }}>{text}</Typography.Text>
      },
    },
    {
      key: "user_key",
      title: `${t("User")}`,
      ellipsis: true,
      render: (text: any, record: any) => {
        return <Space direction='vertical' >
          {
            record?.image ? (<Avatar src={record?.image} size={50} />) : (
              <Avatar src={userImage} size={50} />
            )
          }
          {record?.fullName}
          {record?.email}
        </Space>
        {/* <Row style={{ padding: ".5rem" }}>
          <Col span={24}> {
            record?.image ? (<Avatar src={record?.image} size={50} />) : (
              <Avatar src={userImage} size={50} />
            )
          }
          </Col>
          <Col span={24}>{record?.fullName}</Col>
        </Row> */}
      },
    },

  /*   {
      key: "email_key",
      title: `${t("E-Mail")}`,
      dataIndex: "email",
      ellipsis: true,
      render: (text: any, _: any) => {
        return <Typography.Text style={{ padding: ".5rem" }}>{text}</Typography.Text>
      },
    }, */
    {
      key: "device_key",
      title: `${t("Device")}`,
      width: "15%",
      render: (_: any, record: any) => {
        return <Row style={{ padding: ".5rem" }}>
          <Col span={24}>{record?.ipAddress}</Col>
          <Col span={24}>{record?.userAgenet}</Col>
        </Row>
      },
    },
    {
      key: "eventSource_key",
      title: `${t("Event Source")}`,
      ellipsis: true,
      render: (_: any, record: ILogs) => {
        return <Row style={{ padding: ".5rem" }}>
          <Col span={24}>{record?.eventSource?.name}</Col>
          <Col span={24}>{record?.eventSource?.application}</Col>
          <Col span={24}>{record?.eventSource?.pathOrUrl}</Col>
          <Col span={24}>{record?.eventSource?.description}</Col>
        </Row>
      },
    },
    {
      key: "eventTypes_key",
      title: `${t("Event Types")}`,
      ellipsis: true,
      render: (_: any, record: ILogs) => {
        return <Row style={{ padding: ".5rem" }} >
          <Col span={24}>{record?.eventType?.label}</Col>
        </Row>
      },
    },
    {
      key: "eventLevels_key",
      title: `${t("Event Levels")}`,
      ellipsis: true,
      render: (_: any, record: ILogs) => {
        return <Row style={{ padding: ".5rem" }} >
          <Col span={24}>{record?.eventLevel?.label}</Col>
        </Row>
      },
    },
    {
      key: "details_key",
      title: `${t("Details")}`,
      width: "15%",
      render: (_: any, record: ILogs) => {
        return <Row >
          <Col span={24}>{record?.auditType?.label} {record?.entityType?.label}</Col>

          <Col span={24}>{parse(record?.detailAsHtml)}</Col>
        </Row>
      },
    },
  ]

  return (
    <Table
      key='error_table'
      className='table_seg'
      bordered
      dataSource={props.logsData}
      columns={columns}
      rowClassName="editable-row"
      scroll={{ y: "calc(100vh - 350px)", x: true }}
      tableLayout="fixed"
      pagination={{
        total: props.totalCount,
        showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
        showSizeChanger: true,
        pageSizeOptions: [50, 100, 150],
        defaultPageSize: 50,
        locale: { items_per_page: `/ ${t("Page")}` },
        onChange: (page: number, pageSize: number) => {
          return props.setFilters({
            pageIndex: page - 1,
            pageLength: pageSize,
            eventTypeIds: props.filters?.eventTypeIds,
            eventLevelIds: props.filters?.eventLevelIds,
            entityTypeIds: props.filters?.entityTypeIds,
            auditTypeIds: props.filters?.auditTypeIds,
            startDate: props.filters?.startDate,
            endDate: props.filters?.endDate
          })
        }
      }}
      locale={{
        triggerDesc: t('Click to sort descending'),
        triggerAsc: t('Click to sort ascending'),
        cancelSort: t('Click to cancel sorting')
      }}
    />
  );
}


export default EventLogsForAdminTable;

