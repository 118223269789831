import React, { useState } from 'react'
import { ISelfAssessmentTempBp } from '../../../../Models/ISelfAssessmentTemp';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { connect } from 'react-redux';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { Button, Form, Input, InputNumber, Space, Spin } from 'antd';
import { RulesName } from '../../Rules/RulesValidation';
import SelfAssessmentBpTable from './SelfAssessmentBpTable';
import { t } from 'i18next';
interface IProps {
  subLeverId: number;
  selfAssessmentBp: ISelfAssessmentTempBp[];
  isLoading: boolean;
  isModalLoading: boolean;
  modalResponse: ICommonResponse;
  createBestPractice: (model: ISelfAssessmentTempBp) => any;
}
const SelfAssessmentBestPractice = (props: IProps) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<ISelfAssessmentTempBp>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const bestPractices: ISelfAssessmentTempBp[] = props.selfAssessmentBp?.map(be => {
    return { ...be, key: `admin_${be.id}` }
  }) ?? [];

  const formValuesHandler = async () => {
    try {
      await form.validateFields();
      const newBestPractice = { subLeverId: props.subLeverId, ...formValues };
      await props.createBestPractice(newBestPractice);
    } catch (error) {
      console.log(error);
    }
  }
  const closeAndReset = () => {
    form.resetFields();
    setIsModalVisible(false);
  }
  return (
    <Spin spinning={props.isLoading || props.isModalLoading}>
      <div className="admin-component">
        <Space direction="vertical" size="small">
          <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Best Practice")}`} btnText={`${t("Add")}`}
            width="520px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
              <Button key="back" onClick={closeAndReset}>
                {`${t("Cancel")}`}
              </Button>,
              <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                {`${t("Save")}`} & {`${t("Send")}`}
              </Button>
            ]}>
            <Form
              layout="vertical"
              form={form}
            >
              <Form.Item label={`${t("English Name")}`} name='nameEn' rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })} hasFeedback>
                <Input onChange={(e) => setFormValues({ ...formValues, nameEn: e.target.value })} />
              </Form.Item>
              <Form.Item label={`${t("German Name")}`} name='nameDe' rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })} hasFeedback>
                <Input onChange={(e) => setFormValues({ ...formValues, nameDe: e.target.value })} />
              </Form.Item>
              <Form.Item label={`${t("Serial Number")}`} name="serialNumber" >
                <InputNumber onChange={(value: number) => setFormValues({ ...formValues, serialNumber: value })} />
              </Form.Item>
            </Form>
          </ModalAdmin>
          <SelfAssessmentBpTable subLeverId={props.subLeverId} selfAssessmentBpData={bestPractices}
          />
        </Space>
      </div >
    </Spin>
  )
}
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.selfAssessments?.isSelfAssessmentLoading,
    isModalLoading: state.selfAssessments?.isModalLoading,
    modalResponse: state.selfAssessments?.modalResponse,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    createBestPractice: (model: ISelfAssessmentTempBp) => dispatch({ type: ActionTypes.CREATE_SELF_ASSESSMENT_BP_TEMP, model }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessmentBestPractice);