import React, { useEffect } from "react";
import {
  Layout,
  Menu,
  MenuProps,
  Typography,
  Badge,
  Popover,
  Button,
  Space,
  Spin,
  Empty,
  Skeleton,
} from "antd";
import closeIcon from "./../assets/Images/Sidebar/close.svg";
import openIcon from "./../assets/Images/Sidebar/open.svg";
import "./SideRight.css";
import { BmProject } from "../Models/Responses/ProfileResponse";
import { BasicRole } from "../Enums/BasicRoles";
import AsupRadarChartAnt from "../Scenes/Charts/AsupRadarChart/AsupRadarChartAnt";
import AsupBarChart_Ant from "../Scenes/Charts/AsupBarChart/AsupBarChart_Ant";
import { formatValue } from "../Utils/helperFunctions";
import HeatMapChart from "../Scenes/Charts/AsupHeatMapChart/HeatMapChart";
import { IGeneralState } from "../Redux/reducers/IGeneralState";
import { ActionTypes } from "../Redux/actionsTypes";
import { connect } from "react-redux";
import { t } from "i18next";

const { Title } = Typography;
const { Sider } = Layout;
export interface ISiderMenuProps {
  collapsed: boolean;
  onCollapse: any;
  history: any;
  currentProject: BmProject;
  getAllBenchmarkProjects: () => any;
  isLoadBenchmarkingProjects: boolean;
  allBenchMarkingProjects: BmProject[];
  roles: number[];
}

const SideRight = (props: ISiderMenuProps) => {
  const { collapsed, history, onCollapse } = props;
  useEffect(() => {
    if (
      (props.roles &&
        (props.roles?.includes(BasicRole.Consultant) ||
          props.roles?.includes(BasicRole.SuperAdmin))) ||
      props.allBenchMarkingProjects == null ||
      props.allBenchMarkingProjects == undefined ||
      props.allBenchMarkingProjects.length == 0
    ) {
      props.getAllBenchmarkProjects();
    }
  }, []); 

  return (
    <Sider
      trigger={null}
      className={"sidebar sidebar-right"}
      width={210}
      collapsedWidth={0}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Spin spinning={props.isLoadBenchmarkingProjects}>
        {!collapsed &&
        props.currentProject &&
        props.currentProject != null &&
        props.allBenchMarkingProjects &&
        props.allBenchMarkingProjects.length >= 0 ? (
          <>
            <div className="sidebar-radar-chart">
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                placement="rightBottom"
                content={
                  // <img alt='' src={sidebarChart3} width={500} />
                  <div className="topLevelChartsContainer">
                    <AsupBarChart_Ant
                      leverNumber={"0."}
                      height={255}
                      width={650}
                      showChartDetails={true}
                      showChartControls={false}
                    />
                  </div>
                }
                trigger="hover"
              >
                <div className="radar-chart-popover">
                  <p>{`${t("Unternehmens PI")}`}</p>
                  <div>
                    <AsupBarChart_Ant
                      leverNumber={"0."}
                      chartDetailsTextColor={"white"}
                      isInSideBar={true}
                      showChartDetails={false}
                      showChartControls={false}
                    />
                  </div>
                </div>
              </Popover>
            </div>
            <hr />
            {/* <div className='All-charts-Btn_div'>
              <Button type="primary" shape="round" size='middle' onClick={navigateToAllChartsPage}>
                All charts
              </Button>
            </div> */}

            <div className="sidebar-radar-chart">
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                placement="rightBottom"
                content={
                  <AsupRadarChartAnt
                    leverNumber="0."
                    isSmall={false}
                    height={255}
                    width={650}
                    showChartDetails={true}
                    tooltip={{
                      showCrosshairs: false,
                      showMarkers: false,
                      fields: ["peer", "value"],
                      formatter: (point) => {
                        return {
                          name: point.peer,
                          value: formatValue(point.value),
                        };
                      },
                    }}
                    radarIcon={true}
                    axisFontSize={14}
                    showChartControls={false}
                  />
                }
                trigger="hover"
              >
                <div className="radar-chart-popover">
                  <p>{`${t("Stellhebel").toUpperCase()}`}</p>
                  <AsupRadarChartAnt
                    leverNumber="0."
                    isSmall={true}
                    height={100}
                    width={100}
                    showChartDetails={false}
                    tooltip={false}
                    radarIcon={false}
                    axisFontSize={1}
                    showChartControls={false}
                  />
                </div>
              </Popover>
            </div>

            <div className="sidebar-radar-chart">
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                placement="rightBottom"
                content={
                  <AsupRadarChartAnt
                    leverNumber="1."
                    isSmall={false}
                    height={255}
                    width={650}
                    showChartDetails={true}
                    showChartControls={false}
                    tooltip={{
                      showCrosshairs: false,
                      showMarkers: false,
                      fields: ["peer", "value"],
                      formatter: (point) => {
                        //console.log('point: ', point);
                        return {
                          name: point.peer,
                          value: formatValue(point.value),
                        };
                      },
                    }}
                    radarIcon={true}
                    axisFontSize={14}
                  />
                }
                trigger="hover"
              >
                <div className="radar-chart-popover">
                  {/* <img alt='' src={strategyIcon} className="radar-icon" /> */}
                  <p>{`1. ${t("Strategy").toUpperCase()}`}</p>
                  <AsupRadarChartAnt
                    //level={2} // 1 for main radar 2 for children radars
                    //chartTitle='Strategie' // when main radar the title is 'Stellhebel' when children radars the is 'Strategie' or 'Prozess' or 'Projektmanagement' or 'Struktur' or 'Menschen'
                    //data={[customer_data, peer_1_data, peer_2_data, peer_3_data, peer_4_data]}
                    leverNumber="1."
                    isSmall={true}
                    height={100}
                    width={100}
                    showChartDetails={false}
                    showChartControls={false}
                    tooltip={false}
                    radarIcon={false}
                    axisFontSize={1}
                  />
                </div>
              </Popover>
            </div>

            <div className="sidebar-radar-chart">
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                placement="rightBottom"
                content={
                  <AsupRadarChartAnt
                    //level={2} // 1 for main radar 2 for children radars
                    //chartTitle='Strategie' // when main radar the title is 'Stellhebel' when children radars the is 'Strategie' or 'Prozess' or 'Projektmanagement' or 'Struktur' or 'Menschen'
                    //data={[customer_data, peer_1_data, peer_2_data, peer_3_data, peer_4_data]}
                    leverNumber="2."
                    isSmall={false}
                    height={255}
                    width={650}
                    showChartDetails={true}
                    showChartControls={false}
                    tooltip={{
                      showCrosshairs: false,
                      showMarkers: false,
                      fields: ["peer", "value"],
                      formatter: (point) => {
                        //console.log('point: ', point);
                        return {
                          name: point.peer,
                          value: formatValue(point.value),
                        };
                      },
                    }}
                    radarIcon={true}
                    axisFontSize={14}
                  />
                }
                trigger="hover"
              >
                <div className="radar-chart-popover">
                  {/* <img alt='' src={strategyIcon} className="radar-icon" /> */}
                  <p>{`2. ${t("Process").toUpperCase()}`}</p>
                  <AsupRadarChartAnt
                    //level={2} // 1 for main radar 2 for children radars
                    //chartTitle='Strategie' // when main radar the title is 'Stellhebel' when children radars the is 'Strategie' or 'Prozess' or 'Projektmanagement' or 'Struktur' or 'Menschen'
                    //data={[customer_data, peer_1_data, peer_2_data, peer_3_data, peer_4_data]}
                    leverNumber="2."
                    isSmall={true}
                    height={100}
                    width={100}
                    showChartDetails={false}
                    showChartControls={false}
                    tooltip={false}
                    radarIcon={false}
                    axisFontSize={1}
                  />
                </div>
              </Popover>
            </div>

            <div className="sidebar-radar-chart">
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                placement="rightBottom"
                content={
                  <AsupRadarChartAnt
                    //level={2} // 1 for main radar 2 for children radars
                    //chartTitle='Strategie' // when main radar the title is 'Stellhebel' when children radars the is 'Strategie' or 'Prozess' or 'Projektmanagement' or 'Struktur' or 'Menschen'
                    //data={[customer_data, peer_1_data, peer_2_data, peer_3_data, peer_4_data]}
                    leverNumber="3."
                    isSmall={false}
                    height={255}
                    width={650}
                    padding={[40, 50, 30, 0]}
                    showChartDetails={true}
                    showChartControls={false}
                    tooltip={{
                      showCrosshairs: false,
                      showMarkers: false,
                      fields: ["peer", "value"],
                      formatter: (point) => {
                        //console.log('point: ', point);
                        return {
                          name: point.peer,
                          value: formatValue(point.value),
                        };
                      },
                    }}
                    radarIcon={true}
                    axisFontSize={14}
                  />
                }
                trigger="hover"
              >
                <div className="radar-chart-popover">
                  {/* <img  alt='' src={strategyIcon} className="radar-icon" /> */}
                  <p>{`3. ${t("Project Management").toUpperCase()}`}</p>
                  <AsupRadarChartAnt
                    //level={2} // 1 for main radar 2 for children radars
                    //chartTitle='Strategie' // when main radar the title is 'Stellhebel' when children radars the is 'Strategie' or 'Prozess' or 'Projektmanagement' or 'Struktur' or 'Menschen'
                    //data={[customer_data, peer_1_data, peer_2_data, peer_3_data, peer_4_data]}
                    leverNumber="3."
                    isSmall={true}
                    height={100}
                    width={100}
                    showChartDetails={false}
                    showChartControls={false}
                    tooltip={false}
                    radarIcon={false}
                    axisFontSize={1}
                  />
                </div>
              </Popover>
            </div>

            <div className="sidebar-radar-chart">
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                placement="rightBottom"
                content={
                  <AsupRadarChartAnt
                    //level={2} // 1 for main radar 2 for children radars
                    //chartTitle='Strategie' // when main radar the title is 'Stellhebel' when children radars the is 'Strategie' or 'Prozess' or 'Projektmanagement' or 'Struktur' or 'Menschen'
                    //data={[customer_data, peer_1_data, peer_2_data, peer_3_data, peer_4_data]}
                    leverNumber="4."
                    isSmall={false}
                    height={255}
                    width={650}
                    showChartDetails={true}
                    showChartControls={false}
                    tooltip={{
                      showCrosshairs: false,
                      showMarkers: false,
                      fields: ["peer", "value"],
                      formatter: (point) => {
                        //console.log('point: ', point);
                        return {
                          name: point.peer,
                          value: formatValue(point.value),
                        };
                      },
                    }}
                    radarIcon={true}
                    axisFontSize={14}
                  />
                }
                trigger="hover"
              >
                <div className="radar-chart-popover">
                  {/* <img alt='' src={strategyIcon} className="radar-icon" /> */}
                  <p>{`4. ${t("Structure").toUpperCase()}`}</p>
                  <AsupRadarChartAnt
                    //level={2} // 1 for main radar 2 for children radars
                    //chartTitle='Strategie' // when main radar the title is 'Stellhebel' when children radars the is 'Strategie' or 'Prozess' or 'Projektmanagement' or 'Struktur' or 'Menschen'
                    //data={[customer_data, peer_1_data, peer_2_data, peer_3_data, peer_4_data]}
                    leverNumber="4."
                    isSmall={true}
                    height={100}
                    width={100}
                    showChartDetails={false}
                    showChartControls={false}
                    tooltip={false}
                    radarIcon={false}
                    axisFontSize={1}
                  />
                </div>
              </Popover>
            </div>

            <div className="sidebar-radar-chart">
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                placement="rightBottom"
                content={
                  <AsupRadarChartAnt
                    //level={2} // 1 for main radar 2 for children radars
                    //chartTitle='Strategie' // when main radar the title is 'Stellhebel' when children radars the is 'Strategie' or 'Prozess' or 'Projektmanagement' or 'Struktur' or 'Menschen'
                    //data={[customer_data, peer_1_data, peer_2_data, peer_3_data, peer_4_data]}
                    leverNumber="5."
                    isSmall={false}
                    height={255}
                    width={650}
                    showChartDetails={true}
                    showChartControls={false}
                    tooltip={{
                      showCrosshairs: false,
                      showMarkers: false,
                      fields: ["peer", "value"],
                      formatter: (point) => {
                        //console.log('point: ', point);
                        return {
                          name: point.peer,
                          value: formatValue(point.value),
                        };
                      },
                    }}
                    radarIcon={true}
                    axisFontSize={14}
                  />
                }
                trigger="hover"
              >
                <div className="radar-chart-popover">
                  <p>{`5. ${t("People").toUpperCase()}`}</p>
                  <AsupRadarChartAnt
                    leverNumber="5."
                    isSmall={true}
                    height={100}
                    width={100}
                    showChartDetails={false}
                    showChartControls={false}
                    tooltip={false}
                    radarIcon={false}
                    axisFontSize={1}
                  />
                </div>
              </Popover>
            </div>
            <hr />

            <div className="sidebar-chart">
              <Popover
                getPopupContainer={() => document.getElementById("app-layout")}
                placement="rightBottom"
                content={
                  <div className="toplevelheatmap">
                    <HeatMapChart
                      withCharts={true}
                      width={700}
                      height={700}
                      project={props.currentProject}
                    />
                  </div>
                }
                trigger="hover"
              >
                <div className="radar-chart-popover">
                  <p>{`${t("Heatmap").toUpperCase()}`}</p>
                  <div className="toplevelheatmap" style={{ zIndex: 1000 }}>
                    <HeatMapChart
                      withCharts={false}
                      project={props.currentProject}
                      width={140}
                      height={140}
                      isHeatMapIcon={true}
                    />
                  </div>
                </div>
              </Popover>
            </div>
          </>
        ) : (
          <div style={{ height: "100vh" }}></div>
        )}
      </Spin>
      <div className="sidebar-collapse-right">
        {collapsed ? (
          <img
            src={openIcon}
            width={25}
            onClick={() => props.onCollapse(!collapsed)}
            alt="closed sidebar icon"
            className="sidebar-collapse"
          />
        ) : (
          <img
            src={closeIcon}
            width={25}
            onClick={() => props.onCollapse(!collapsed)}
            alt="opened sidebar icon"
            className="sidebar-collapse"
          />
        )}
      </div>
    </Sider>
  );
};

const mapStateToProps = (state: IGeneralState) => {
  return {
    roles: state.generalInfo?.profileResponse?.roles,
    currentProject: state.generalInfo?.profileResponse?.currentProject,
    allBenchMarkingProjects: state.generalInfo.allBenchmarkingProjects,
    isLoadBenchmarkingProjects: state.generalInfo?.benchmarkProjectsLoader,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllBenchmarkProjects: () =>
      dispatch({ type: ActionTypes.GetAllBenchmarkProjects }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideRight);
