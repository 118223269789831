import { ActionTypes } from "../actionsTypes";
import { IActivityForAdminState, ILeversForAdminState } from "./IGeneralState";

const initialState: IActivityForAdminState = {
    isActivityForAdminLoading: false,
    ActivityProfilesForAdminResult: null,
    isModalLoading: false,
    modalResponse: null,
};
const ActivityManagementForAdminReducer = (
    state = initialState,
    action: any
): IActivityForAdminState => {
    switch (action.type) {
        case ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER:
            return {
                ...state,
                isActivityForAdminLoading: action.isLoading,
            };
        case ActionTypes.SAVE_ACTIVITY_FOR_ADMIN:
            return {
                ...state,
                ActivityProfilesForAdminResult: action.result,
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            }
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            }
        default:
            break;
    }
    return state;
};

export default ActivityManagementForAdminReducer;