import { ActionTypes } from "../actionsTypes";
import { IActivityToolState } from "./IGeneralState";
import { ActivitySurveyData } from "../../Scenes/Tools/ActivityTool/Survey/Models/ActivitySurveyData";
import { IActivityProfileVotingProcess, IBmProjectMemeber, IProfileVersion } from "../../Models/Activity/ActivityModels"; 
const initialState: IActivityToolState = {
    isSuccessAnonymousVoting: false,
    progressValue: null,
    viewResult: null,
    isLoading: false,

    activityAnalyzeResult: [] as IActivityProfileVotingProcess[],
    activitySurveyData: null,
    bmProjectMembers: [] as IBmProjectMemeber[],
    currentProfileId: 0,
    currentversionId: 0,
    //ProfileVersionTasks: null as IActivityProfileVersion,
    profileVersionsIds: [] as IProfileVersion[],
    isModalLoading: false,
    modalResponse: null,
};

const ActivityReducer = (state = initialState, action: any): IActivityToolState => {
    switch (action.type) {
        case ActionTypes.changeActivityLoader:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case ActionTypes.SetActivityProgressValue:
            return {
                ...state,
                progressValue: action.result,
            };
        case ActionTypes.isSuccessAnonymousVoting:
            return {
                ...state,
                isSuccessAnonymousVoting: action.isSuccessAnonymousVoting,
            };
        case ActionTypes.setActivityViewResult:
            return {
                ...state,
                viewResult: action.result
            }
        case ActionTypes.saveActivitySurveyData:
            console.log("ActionTypes.saveActivitySurveyData", "action.result");
            return {
                ...state,
                activitySurveyData: { ...action.result }
            }

        case ActionTypes.SetActivityAnalyzeResult:
            return {
                ...state,
                activityAnalyzeResult: action.result
            }
        case ActionTypes.SET_BM_PROJECT_MEMBERS:
            return {
                ...state,
                bmProjectMembers: action.result
            }
        // case ActionTypes.SET_PROFILE_VERSION_TASKS:
        //     return {
        //         ...state,
        //         ProfileVersionTasks: action.result
        //     }
        case ActionTypes.SET_ACTIVITY_PROFILE_VERSIONS:
            return {
                ...state,
                profileVersionsIds: action.result,
                currentProfileId: action.profileId,
            }
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            };
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            };
        default:
            break;
    }
    return state;
};

export default ActivityReducer;