import { Col, Row, Tabs } from "antd";
import { connect } from "react-redux";
import { ISABestPractice, ISelfAssessmentView } from "../../../../Models/SelfAssessment/ISelfAssessmentView";
import { IGeneralState, ISelfAssessmentToolState } from "../../../../Redux/reducers/IGeneralState";
import { formatValue } from "../../../../Utils/helperFunctions";
import SASubleverBestPractices from "./SASubleverBestPractices";
import SATopRated from "./SATopRated";

interface IProps {
    dataSource: ISABestPractice[];
    defaultTopRated: number;
    viewState: ISelfAssessmentToolState;
}
function SABestPractices(props: IProps) {

    const items = props.viewState?.viewResult?.levers?.map(lever => {
        return { label: `${lever.name} (${formatValue(lever.referenceValue * 100)}%)`, key: `lever-${lever.id}-tab`, children: <SASubleverBestPractices leverId={lever.id} /> };
    });

    return <Row>
        <Col span={14}>
            <Tabs items={items} tabPosition={"left"} />
        </Col>
        <Col span={10}><SATopRated dataSource={props.dataSource} defaultTopRated={props.defaultTopRated} /></Col>
    </Row>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        viewState: state.selfAssessementTool,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SABestPractices);