import AsupColumnChart from "../BasicCharts/ASupColumnchart"
import { Col, Row } from "antd";
import { EBITBenchmarkData } from '../Models/EBITBenchmarkData';
import i18n from "../../../../i18n";
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import { useTranslation } from "react-i18next";

interface Iprops {
  ebitBenchmarkingInfo: EBITBenchmarkData;
  selectChart?: (chartDivId: string, add: boolean) => void;
  selectedCharts?: string[],
}
const BenchmarkRDDisciplines = (props: Iprops) => {
  const { t } = useTranslation();
  const benchmarkData = props.ebitBenchmarkingInfo;
  const SummaryDisciplinesNames = [t("Gesamte F&E-Kosten"), t("Interdisziplinäre F&E Kosten"), t("Originäre F&E Kosten")]
  const GetChartDataByDisciplineName = (disciplineName: string) => {
    // let projectsCountPerBranch = {};
    return benchmarkData?.projectsBenchmarks?.map((d) => {
      let item = d.projectDisciplinesBenchmark.find(x => i18n.language === 'de' ? x.name == disciplineName : x.englishName == disciplineName);
      // if (!d.isTarget)
      //   projectsCountPerBranch[d.annoName] = (projectsCountPerBranch[d.annoName] || 0) + 1;
      return {
        name: d.projectName,
        // annoName: d.annoName.substring(0, 1).toUpperCase() + projectsCountPerBranch[d.annoName],
        value: item?.rate,
        isTarget: d.isTarget,
        isComplete: d.isComplete
      }
    }
    );
  }
  const GetChartBestPracticeByDisciplineName = (disciplineName: string) => {
    let item = benchmarkData.disciplinesBestPractices.find(x => x.name == disciplineName || x.englishName == disciplineName);
    return {
      value: item?.bestPractice?.value ?? 0,
      max: item?.bestPractice?.max ?? 0,
      min: item?.bestPractice?.min ?? 0,
    }
  };

  const AllDisciplinesNames = benchmarkData?.disciplinesBestPractices?.map(d => i18n.language === 'de' ? d.name : d.englishName);
  let view1 = AllDisciplinesNames?.map(name => {
    let currentDiscipline = GetChartDataByDisciplineName(name);
    let disciplineBestPractice = GetChartBestPracticeByDisciplineName(name);
    return <Col span={12}>
      <AsupColumnChart
        selectedCharts={props.selectedCharts}
        isTopLevelChart={false}
        key={name}
        subTitle={!SummaryDisciplinesNames.includes(name) ? `${name} / ${t("Gesamte F&E-Kosten")}` : `${name} / ${t("Umsatz")}`}
        ebitBMProjectsForChart={currentDiscipline}
        bestPractice={disciplineBestPractice}
        selectChart={props.selectChart}
      />
    </Col>
  });

  let view = [];
  for (var i = 0; i < view1?.length; i += 2) {
    view.push(<Row >{view1[i]} {view1[i + 1]}</Row>)
  }


  return (
    <>
      {view}
    </>
  );
};
export default BenchmarkRDDisciplines;