
import { Collapse, Form, Slider, InputNumber, Typography, Row, Col, Input, Select, Popconfirm, Space, Tooltip, Table, Segmented, Checkbox } from 'antd';
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  CheckCircleOutlined,
  StopTwoTone,
} from "@ant-design/icons";
import { QualityCostRowValue } from '../../../../../Models/EBIT/CostTypes';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IEbitToolState, IGeneralState } from '../../../../../Redux/reducers/IGeneralState';
import { ActionTypes } from '../../../../../Redux/actionsTypes';
import { DProjectForEbit, QualityCostDto, QualityCostWithDprojectDto } from '../../Models/CostTypesResponse';
import { BmProject } from '../../../../../Models/Responses/ProfileResponse';
import exportIcon from "../../../../../assets/Images/export-xlsx-icon.svg";
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { inputFormatter, inputParser, formatValue } from '../../../../../Utils/helperFunctions';
import i18n from '../../../../../i18n';
import TableForPanel from './TableForPanel';
import UploadFile from '../UploadFilesComponent';
const { Panel } = Collapse;
const { Title, Paragraph } = Typography;
const { Option } = Select;
interface QkProps {
  //header: any;
  //key: string;
  ebitInfo: IEbitToolState,
  currentProject: BmProject,
  selectedProject?: BmProject,
  onUpdateQkWithNewDproject: (model: QualityCostWithDprojectDto) => any;
  onCreateQk: (model: QualityCostDto, projectID: number) => any;
  onUpdateQk: (model: QualityCostDto, projectID: number) => any;
  onDeleteQk: (modelId: number, projectID: number, forEbit: boolean) => any;
  onDownloadQKTable: (projectName: string, projectID?: number) => any;
  onImportQKTable: (frmData: FormData, forEbit: boolean) => any;
}
function QKPanel(props: QkProps) {
  const newRowQk: QualityCostRowValue = {
    key: "newQk",
    Produkte: null,
    ProjektAbschlussjahr: 0,
    Umsatz: 0,
    ProduktMarge: 0,
    Differenz: 0,
    NewProduct: ""
  };
  const { t } = useTranslation();
  const [editRecord, setEditRecord] = useState(newRowQk as QualityCostRowValue);
  const [form] = Form.useForm();
  const Umsatz = Form.useWatch(`Umsatz_${editRecord?.Produkte}`, form);
  const ProduktMarge = Form.useWatch(`ProduktMarge_${editRecord?.Produkte}`, form);
  const projectId = Form.useWatch(`project_${editRecord?.Produkte}`, form);
  const [addFromList, setAddFromList] = useState(true);
  const isForEbit = props.selectedProject ? false : true;
  useEffect(() => {
    if (props.ebitInfo?.dProjectForEbit) {
      form.setFieldsValue(fillInForm(newRowQk));
      setEditRecord(newRowQk);
    }
  }, [props.ebitInfo]);

  useEffect(() => {
    if (props.selectedProject && props.ebitInfo?.dProjectForSelectedProject) {
      form.setFieldsValue(fillInForm(newRowQk));
      setEditRecord(newRowQk);
    }
  }, [props.selectedProject]);
  let DProjectsList = props.selectedProject ? props.ebitInfo?.dProjectForSelectedProject : props.ebitInfo?.dProjectForEbit;
  const options = DProjectsList?.filter(c => !c.qualityCost).map(dp => {
    return {
      value: dp.dproject.id,
      label: dp.dproject.name
    };
  });

  const columnsOne = [
    {
      title: '',
      dataIndex: 'left',
      key: '1',
      width: '75%',
    },
    {
      title: '',
      dataIndex: 'right',
      key: '2',
      width: '25%',
    }
  ]
  function calculateBMAverage() {
    //BM average
    let rv = props.currentProject?.sales !== 0 ? calculateRDcostsOnSalesToday() / props.currentProject?.sales : 0;
    return rv * 100;
  }

  function calculateRDcostsOnSalesToday() {
    //R&D share of sales R&D costs / sales today
    let Bpqcshare = 0;//TODO: from best practice, normaly is 0
    let sum = DProjectsList?.filter(c => c.qualityCost).reduce((acc, next) => { return acc + (next.qualityCost.annualCosts * (next.qualityCost.rndImpact / 100)) }, 0);
    let rv = sum - (Bpqcshare * props.currentProject?.sales);
    return rv >= 0 ? rv : 0;
  }
  const heute = (
    <>
      {t("EBIT-Potenzial")}
      <sub>{t(`QK`)}</sub> /
      {t("Umsatz")}
    </>
  );
  const rowsOne = [
    {
      key: 'BM_Durchschnitt_01',
      left: (
        <Tooltip placement="bottomRight" title={heute}>
          {t("EBIT-Potenzial")}<sub>{`${t("QK")}`}</sub>{`[%]`}
        </Tooltip>
      ),
      right: formatValue(calculateBMAverage()) + '%'
    }
  ]
  const rowsTwo = [
    {
      key: 'total_02',
      left: (
        <Tooltip placement="bottomRight" title={t("Summe QK über alle Projekte")}>
          {t("EBIT-Potenzial")}<sub>{`${t("QK")}`}</sub>{`[€]`}
        </Tooltip>
      ),
      right: formatValue(calculateRDcostsOnSalesToday()) + '€'
    },
  ]
  const dataColQK: ColumnsType = [
    {
      title: <><label>{`Products`}</label><br></br><sub>{t("Produktname")}</sub></>,
      dataIndex: 'Produkte',
      fixed: 'left', // fix element
      render: (text: any, record: QualityCostRowValue, index) => {
        //console.log("render", text, record, index);
        if (index == 0) {
          if ((editRecord?.key != "newQk")) {
            return <Select
              disabled
              showSearch
              placeholder="Select..."
              options={options}
              style={{ textOverflow: 'ellipsis', width: '100%', fontSize: '12px' }}
            />;
          }
          return <Row>
            <Col span={2}><Checkbox checked={addFromList} onChange={
              (e) => {
                form.setFieldsValue(fillInForm(newRowQk));
                setEditRecord(newRowQk);
                setAddFromList(e.target.checked);
                console.log("form fields", form.getFieldsValue());
              }
            }
            /></Col>
            <Col span={1}></Col>
            <Col span={21}>
              <Form.Item
                hidden={!addFromList}
                name={`project_${record?.Produkte}`}
                style={{ margin: 0 }}
                rules={(!addFromList) ? [] : [
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <Select
                  dropdownMatchSelectWidth={false}
                  optionFilterProp="label"
                  getPopupContainer={() =>
                    isForEbit ? document.getElementById("app-layout")
                      : document.getElementsByClassName("ant-modal-root").item(0) as HTMLElement
                  }
                  showSearch
                  placeholder={t("Auswahl")}
                  options={options}
                  style={{ textOverflow: 'ellipsis', fontSize: '12px', width: '100%', zIndex: 5 }} />

                {/* */}
              </Form.Item>


              <Form.Item
                hidden={addFromList}
                name={`projectname_${record?.Produkte}`}
                style={{ margin: 0 }}
                rules={(addFromList) ? [] : [
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <Input placeholder={t("Neues Entwciklungsprojekt")} />
              </Form.Item>
            </Col>
          </Row>
        } else {
          return DProjectsList?.find(c => c.dproject.id == record?.Produkte)?.dproject.name ?? "";
          // if (editRecord?.key == record?.key) {
          //   return <Form.Item
          //     name={`project_${record?.Produkte}`}
          //     style={{ margin: 0 }}
          //     rules={[
          //       {
          //         required: true,
          //         message: `required*`,
          //       },
          //     ]}
          //   >
          //     <Select
          //       showSearch
          //       placeholder="Select D-Project"
          //       options={options}
          //       style={{ textOverflow: 'ellipsis', width: '100%', fontSize: '12px' }} />
          //   </Form.Item>;
          // } else {
          //   return props.ebitInfo?.dProjectForEbit.find(c => c.dproject.id == record?.Produkte)?.dproject.name ?? "";
          // }
        }
      }, // keep this if you need to render link with data cell.    
    },
    {
      title: <><label>{t("Erfassungsjahr")}</label></>,
      width: '10%',
      dataIndex: 'ProjektAbschlussjahr',
      defaultSortOrder: 'descend',
      sorter: (a: QualityCostRowValue, b: QualityCostRowValue) => {
        if (a.ProjektAbschlussjahr == -1 || b.ProjektAbschlussjahr == -1 ||
          a.ProjektAbschlussjahr == 0 || b.ProjektAbschlussjahr == 0)
          return 0;
        else return a.ProjektAbschlussjahr - b.ProjektAbschlussjahr;
      },
      render: (text: any, record: QualityCostRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newQk") {
            return <InputNumber disabled />
          }
          if (addFromList) {
            return DProjectsList?.find(c => c.dproject.id == projectId)?.dproject.completionYear;
          } else {
            return <Form.Item
              name={`ProjektAbschlussjahr_${record?.Produkte}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                maxLength={4}
                min={1970}
              />
            </Form.Item>;
          }
        } else {
          return record?.ProjektAbschlussjahr;
        }
      }
    },
    {
      title: <>
        <label>{t("Kosten")} [n] </label>
        <br></br>
        <Tooltip placement="top" title={t("Nonkonformitätskosten sind Gewährleistung, sowie Ausschuss und Nacharbeit in der Produktion.")}>
          <sub>{t("Nonkonformitätskosten")}</sub>
        </Tooltip>
      </>,
      dataIndex: 'Umsatz',
      render: (text: any, record: QualityCostRowValue, index) => {
        if (index == 0) {
          if ((editRecord?.key != "newQk")) {
            return <InputNumber disabled />
          }
          return <Form.Item
            name={`Umsatz_${record?.Produkte}`}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${t("required*")}`,
              },
            ]}
          >
            <InputNumber
              addonAfter={'€'}
              // maxLength={12}
              min={0}
              // max={9999999}
              style={{ width: "20ch" }}
              formatter={value => inputFormatter(value, i18n.language)}
              parser={x => inputParser(x, i18n.language)}
            />
          </Form.Item>;
        } else {
          if (editRecord?.key == record?.key) {
            return <Form.Item
              name={`Umsatz_${record?.Produkte}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={'€'}
                min={0}
                style={{ width: "20ch" }}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
              />
            </Form.Item>;
          } else {
            return formatValue(record?.Umsatz) + "€";
          }
        }
      }
    },
    {
      title: <><label>{t("Anteil F&E")}</label>
        <br></br><sub> in %</sub></>,
      dataIndex: 'ProduktMarge',
      render: (text: any, record: QualityCostRowValue, index) => {
        if (index == 0) {
          if ((editRecord?.key != "newQk")) {
            return <InputNumber disabled />
          }
          return <Form.Item
            name={`ProduktMarge_${record?.Produkte}`}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${t("required*")}`,
              },
            ]}
          >
            <InputNumber
              addonAfter={'%'}
              min={0}
              max={100}
              style={{ width: "12ch" }}
              formatter={value => inputFormatter(value, i18n.language)}
              parser={x => inputParser(x, i18n.language)}
            />
          </Form.Item>;
        } else {
          if (editRecord?.key == record?.key) {
            return <Form.Item
              name={`ProduktMarge_${record?.Produkte}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={'%'}
                min={0}
                max={100}
                style={{ width: "12ch" }}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
              />
            </Form.Item>;
          } else {
            return formatValue(record?.ProduktMarge) + '%';
          }
        }
      }
    },
    {
      title: <><label>{t("F&E-Anteil Qualitätskosten")}</label>
        <br></br><sub>in € </sub></>,
      dataIndex: 'Differenz',
      render: (_: any, record: QualityCostRowValue, index) => {
        if (index == 0) {
          if ((editRecord?.key != "newQk")) {
            return `${formatValue(record?.Umsatz * (record?.ProduktMarge / 100))} €`;
          } else {
            return `${formatValue(Umsatz * (ProduktMarge / 100))} €`;
          }
        } else {
          if (editRecord?.key == record?.key) {
            return `${formatValue(Umsatz * (ProduktMarge / 100))} €`;
          } else {
            return `${formatValue(record?.Umsatz * (record?.ProduktMarge / 100))} €`;
          }
        }
      },
    },
    {
      title: (
        <Space wrap={true} align="center" direction="horizontal"> 
          <Tooltip placement="top" title={t("Daten importieren")}>           
            <UploadFile currentProjectId={props.currentProject?.id} isForEbit={isForEbit} onImportCostTable={props.onImportQKTable} />
          </Tooltip>
          <Tooltip placement="top" title={t("Daten exportieren")}>
            <img src={exportIcon} alt="export icon" onClick={() => { onExport(); }} />
          </Tooltip>
        </Space>
      ),
      width: '8%',
      dataIndex: "actions",
      render: (text, record: QualityCostRowValue, index) => {
        //console.log(text, record, index);
        if (index == 0) {
          return <Space size={10}> <span>
            <Typography.Link
              disabled={editRecord?.key != record?.key}
              onClick={() => onAddRow(record)} //handleAdd(record?.key)}
              style={{ marginRight: 8 }}
            >
              <Tooltip placement="bottom" title={`${t("Add data")}`}>
                <PlusOutlined style={{ color: "green" }} />
              </Tooltip>
            </Typography.Link>
          </span></Space>
        } else {
          if (editRecord?.key == record?.key) {
            return <Space size={10}>
              <Popconfirm
                title="Sure to save?"
                onConfirm={() => onEditRow(record)}
              >
                <a>
                  <Tooltip placement="bottom" title="Save data">
                    <CheckCircleOutlined />
                  </Tooltip>
                </a>
              </Popconfirm>

              <Typography.Link
                disabled={editRecord?.key != "newQk" && editRecord?.key != record?.key}
                onClick={() => { form.setFieldsValue(fillInForm(newRowQk)); setEditRecord(newRowQk) }}
              >
                <Tooltip placement="bottom" title="Cancel Changes">
                  <StopTwoTone twoToneColor="red" />
                </Tooltip>
              </Typography.Link>
            </Space>;
          } else {
            return <Space size={10}>
              <Typography.Link
                disabled={editRecord?.key != "newQk" && editRecord?.key != record?.key}
                onClick={() => { form.setFieldsValue(fillInForm({ ...record })); setEditRecord(record) }}
              >
                <Tooltip placement="bottom" title={`${t("Edit")}`}>
                  <EditTwoTone twoToneColor="#1890ff" />
                </Tooltip>
              </Typography.Link>

              <Popconfirm
                title={`${t("Sure to delete?")}`}
                onConfirm={() => onDeleteRow(record)}
              >
                <a>
                  <Tooltip placement="bottom" title={`${t("Delete")}`}>
                    <DeleteTwoTone twoToneColor="red" />
                  </Tooltip>
                </a>
              </Popconfirm>
            </Space>;
          }
        }
      },
    }
  ];
  
  
  const dataRowQK = DProjectsList?.filter(c => c.qualityCost).map(row => {
    if (row.qualityCost) {
      return {
        key: `qk_row_${row.dproject.id}`,
        Produkte: row.dproject.id,
        ProjektAbschlussjahr: row.dproject.completionYear,
        Umsatz: row.qualityCost?.annualCosts,
        ProduktMarge: row.qualityCost?.rndImpact,
        Differenz: row.qualityCost.annualCosts * row.qualityCost.rndImpact,
        alreadyAddedcell: true
      };
    }
  }) ?? [];


  function fillInForm(row: QualityCostRowValue) {
    let rowNames: any = {};
    rowNames[`project_${row.Produkte}`] = row.Produkte;
    rowNames[`Umsatz_${row.Produkte}`] = row.Umsatz;
    rowNames[`ProjektAbschlussjahr_${row.Produkte}`] = row.ProjektAbschlussjahr;
    rowNames[`ProduktMarge_${row.Produkte}`] = row.ProduktMarge;
    rowNames[`Differenz_${row.Produkte}`] = row.Differenz;
    rowNames[`projectname_${row.Produkte}`] = row.NewProduct ?? "";
    return rowNames;
  }
  function getRowFromForm(obj: any) {
    let row: QualityCostRowValue = {
      key: '',
      Produkte: 0,
      NewProduct: "",
      ProjektAbschlussjahr: 0,
      Umsatz: 0,
      ProduktMarge: 0,
      Differenz: 0
    };

    Object.keys(obj).forEach(key => {
      console.log(key, obj[key]);
      if (key.includes("projectname_")) row.NewProduct = obj[key] ?? "";
      if (key.includes("project_")) row.Produkte = obj[key];
      if (key.includes("Umsatz")) row.Umsatz = obj[key];
      if (key.includes("ProjektAbschlussjahr_")) row.ProjektAbschlussjahr = obj[key];
      if (key.includes("ProduktMarge_")) row.ProduktMarge = obj[key];
    });
    return row;
  }
  async function onAddRow(row: QualityCostRowValue) {
    try {
      console.log("obj", form.getFieldsValue());
      const row2 = (await form.validateFields());
      let newData = getRowFromForm(row2);
      console.log(newData);
      if (newData.NewProduct == "") {
        props.onCreateQk({
          id: newData.Produkte,
          annualCosts: newData.Umsatz,
          rndImpact: newData.ProduktMarge,
          forEbit: isForEbit,
        } as QualityCostDto, props.currentProject?.id)
      } else {
        console.log("newData.NewProduct:", newData);
        var newdata2: QualityCostWithDprojectDto = {
          bmProjectId: props.currentProject?.id,
          annualCosts: newData.Umsatz,
          rndImpact: newData.ProduktMarge,
          dprojectCompletionYear: newData.ProjektAbschlussjahr,
          dprojectName: newData.NewProduct,
          forEbit: isForEbit,
        };
        props.onUpdateQkWithNewDproject(newdata2);
      }
    } catch (e) {
      console.log(e);
    }

  }
  async function onEditRow(row: QualityCostRowValue) {
    try {
      const row2 = (await form.validateFields());
      let newData = getRowFromForm(row2);
      console.log(newData);
      props.onUpdateQk({
        id: editRecord.Produkte, //newData.Produkte,
        annualCosts: newData.Umsatz,
        rndImpact: newData.ProduktMarge,
        forEbit: isForEbit
      } as QualityCostDto, props.currentProject?.id)
    } catch (e) {
      console.log('onEditRow ', e);
    }

  }
  function onDeleteRow(row: QualityCostRowValue) {
    console.log(row);
    props.onDeleteQk(row.Produkte, props.currentProject?.id, isForEbit);
  }
  function onExport() {
    props.onDownloadQKTable(props.currentProject?.project_name, props.selectedProject ? props.currentProject?.id : null)
  }
  
  return (
    <Panel key={"qk_2"} {...props}
      header={<Row>
        <Col span={6} className="title-tab-hover"><Title level={5}>{t("Quality Cost ")}</Title></Col>
        <Col span={6}><input disabled value={`${t("EBIT-Potenzial")}:`} /></Col>
        <Col span={6}><input style={{ width: '100%' }} disabled value={`${t("Absolut")} = ${formatValue(calculateRDcostsOnSalesToday())} €`} /></Col>
        <Col span={6}><input disabled value={`${t("Prozentual")} = ${formatValue(calculateBMAverage())} %`} /></Col>
        <Col span={24} className="paragraph-tab-hover"> <Paragraph>
          {t("Quality hover")}</Paragraph></Col>
      </Row>}
      className='cost-type-custom-panel thunderbird'>
      <section id="table-two-columns">
        <Row>
          <Col span={12}>
            <TableForPanel
              columns={columnsOne}
              rows={rowsOne}
            />
          </Col>
          <Col span={12}>
            <TableForPanel
              columns={columnsOne}
              rows={rowsTwo}
            />
          </Col>
        </Row>
      </section>
      {/* <Title level={5} >{`Betrachtet werden die Nonkonformitätskosten (Fehlerkosten / vermeidbare Qualitätskosten),
        welche durch fehlerhafte Produkte verursacht werden. Dies sind z.B. Rückrufaktionen, Garantie (Reparatur,
        Austausch, Wertminderung), Ausschuss.`}</Title> */}
      <div className='cost-type-editable'>
        <Form form={form} component={false} key="qk_form">
          <Table
            key={"qk_table"}
            bordered={false}
            dataSource={[newRowQk, ...dataRowQK]}
            columns={dataColQK}
            scroll={{ y: 500 }}
            rowClassName={() => "editable-row"}
            pagination={false}
          />
        </Form>
      </div>
    </Panel>

  );
};
const mapStateToProps = (state: IGeneralState, ownProps) => {
  return {
    ebitInfo: state.ebit,
    // projects: state.generalInfo.allProjects,
    currentProject: ownProps.selectedProject ? ownProps.selectedProject :state.generalInfo.profileResponse?.currentProject,
    selectedProject: ownProps.selectedProject,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    onUpdateQkWithNewDproject: (model: QualityCostWithDprojectDto) => dispatch({ type: ActionTypes.CreateQkNewDproject, model: model }),
    onCreateQk: (model: QualityCostDto, projectID: number) => dispatch({ type: ActionTypes.CreateQk, model: model, projectID: projectID }),
    onUpdateQk: (model: QualityCostDto, projectID: number) => dispatch({ type: ActionTypes.UpdateQk, model: model, projectID: projectID }),
    onDeleteQk: (modelId: number, projectID: number, forEbit: boolean) => dispatch({ type: ActionTypes.DeleteQk, qkId: modelId, projectID: projectID, forEbit }),
    onDownloadQKTable: (projectName: string, projectID?: number) => dispatch({ type: ActionTypes.DownloadQKTable, projectName, projectID }),
    onImportQKTable: (frmData: FormData, forEbit: boolean) => dispatch({ type: ActionTypes.ImportQKTable, frmData, forEbit }),
  };
};
//
export default connect(mapStateToProps, mapDispatchToProps)(QKPanel); 