import { Typography, Col, List } from 'antd';
import { IActivityTask, IActivityTaskTemplate } from '../../../../Models/IActivityManagement';
import ActivityTaskAddModal from '../ActivityMngModal/ActivityTaskAddModal'
import { useRef } from 'react';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import { t } from 'i18next';
import AdminTable from '../../../../Components/AdminTable/AdminTable';
import ActivityProfileTaskEditModal from './ActivityProfileTaskEditModal';
import TaskActivitiesModal from '../TaskActivitiesModal';
interface IProps {
    tasks: IActivityTaskTemplate[];
    activityProfileId: number;
}
const TasksTable = (props: IProps) => {
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end;
    const columns: any[] = [
        {
            key: "serialNumber_key",
            title: `${t("Serial Number")}`,
            dataIndex: 'serialNumberInProfile',
            width: '15%',
            render: (text: any, record: IActivityTask) => <Typography.Text>{text}</Typography.Text>,
            //sorter: (a, b) => (a?.serialNumberInProfile?.toString())?.localeCompare(b?.serialNumberInProfile?.toString()) ?? 0,
            defaultSortOrder: 'asc',

        },
        {
            key: "task_textEn",
            title: `${t("English Text")}`,
            dataIndex: 'textEn',
            width: "20%",
            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,
            ...getColumnSearchProps(`${t("English Text")}`, 'textEn', searchInput, handleSearch),
            //sorter: (a, b) => a?.textEn?.localeCompare(b?.textEn) ?? 0,

        },
        {
            key: "task_textDe",
            title: `${t("German Text")}`,
            dataIndex: 'textDe',
            width: "20%",
            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,
            ...getColumnSearchProps(`${t("German Text")}`, 'textDe', searchInput, handleSearch),
            //sorter: (a, b) => a?.textDe?.localeCompare(b?.textDe) ?? 0,
        },
        {
            key: "tasks_activities",
            title: `${t("Activities")}`,
            dataIndex: 'activities',
            className: "tasks_dynamicFields",
            width: "15%",
            render: (text: any, record: any) => {
                if (record?.activities?.length > 0) {
                    return <TaskActivitiesModal activities={record?.activities} />
                } else {
                    return;
                }
            }
        },
        {
            title: `${t("Edit")}`,
            dataIndex: '',
            width: '15%',
            render: (_: any, record: IActivityTaskTemplate) => <ActivityProfileTaskEditModal
                activityProfileID={props.activityProfileId}
                oldTaskData={record} />

        },
    ]
    return (
        <div className="admin-component">
            <ActivityTaskAddModal  activityProfileID={props.activityProfileId} />
            <AdminTable
                keyTable='activity_task_table'
                className='activity_task_table'
                dataSource={props.tasks}
                columns={columns}
            />
        </div>
    )
}

export default TasksTable;