import React, { useState } from 'react';
import './barchart.scss';
import AsupRadarChartAnt from '../AsupRadarChart/AsupRadarChartAnt';
import AsupBarChart_Ant from '../AsupBarChart/AsupBarChart_Ant';
import { formatValue } from '../../../Utils/helperFunctions';
import { Datum } from '@antv/g2plot';


interface IradarDataElement {
    label: string;
    peer?: string;
    value: number;
}

interface ChartDetails {
    leverLevel: number,
    leverId: number,
    leverCode: string,
    chartTitle: string,
    chartReferences?: IradarDataElement[];
    chartDescription?: string,
    chartUnit?: string,
    isPercentChart?: boolean,
    bestPracticeMaxValue?: number,
    bestPracticeMinValue?: number
};

interface ChartValue {
    name: string, //project name
    value: number | IradarDataElement[],
    isTarget: boolean,
    isComplete: boolean,
};
interface ChartDetailsAndValues {
    chartDetails: ChartDetails;
    chartValues: ChartValue[];
};

interface IProps {
    leverNumber: string,
    width: number,
    height: number,
    showNextLevel?: (leverNumber: string) => void;
    selectChart?: (divId: string, add: boolean) => void;
    selectedCharts: string[];

    //Tell higher level component with the chart title (div id)
    informChartDetailsAndValuesUp?: (chartDetailsAndValues: ChartDetailsAndValues) => void,
}

const SwitchableChartRadarBar = (props: IProps) => {

    const [chartType, setChartType] = useState('radar');


    const switchToRadarChart = () => {
        setChartType('radar');
    };
    const switchToBarChart = () => {
        setChartType('bar');
    };

    return (
        <div className="rechart_chart_container" /*onClick={() => props.showNextLevel(props.leverNumber)}*/>
            {(chartType === 'radar') ?
                <AsupRadarChartAnt
                    showChartDetails={true}
                    showChartControls={true}
                    showSwitchRadarBar={true}
                    showDownloadBtn={true}
                    chartType={chartType}
                    switchChart={switchToBarChart}
                    selectChart={props.selectChart}
                    selectedCharts={props.selectedCharts}
                    showSelectCheckBox={true}

                    height={props.height} //{props.height}
                    width={props.width} //{props.width}
                    padding={[60,0]}
                    tooltip={{
                        showCrosshairs: false,
                        showMarkers: false,
                        fields: ['peer', 'value'],
                        formatter: (point) => {
                            //console.log('point: ', point);
                            return { name: point.peer, value: formatValue(point.value) }
                        }
                    }}
                    radarIcon={undefined}
                    axisFontSize={9}
                    leverNumber={props.leverNumber}
                    isSmall={false}
                    bordered={true}

                    informChartDetailsAndValuesUp={props.informChartDetailsAndValuesUp}
                />
                : <AsupBarChart_Ant
                    showChartDetails={true}

                    showChartControls={true}
                    showSwitchRadarBar={true}
                    showDownloadBtn={true}
                    chartType={chartType}
                    switchChart={switchToRadarChart}
                    selectChart={props.selectChart}
                    selectedCharts={props.selectedCharts}
                    showSelectCheckBox={true}

                    leverNumber={props.leverNumber}
                    width={props.width}
                    height={props.height}
                    bordered={true}
                
                    informChartDetailsAndValuesUp={props.informChartDetailsAndValuesUp}
                />}
        </div>
    );
}

export default SwitchableChartRadarBar;
