
import { ToastMessages } from "../../Enums/Messages";
import { takeEvery, put, all } from "redux-saga/effects";
import { ActionTypes } from "../actionsTypes";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { IBMProjectALL } from "../../Models/IBMProjectItem";
import BmProjectService from "../../Services/BmProjectsService";

function* onGetBmProject(action) {
    try {
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: true });
        let _result: ICommonResponse = yield BmProjectService.GetBmProjects(action.customerId ?? null);
        if (!_result.isError) {
            let result = _result.result as IBMProjectALL[];
            yield put({ type: ActionTypes.SAVE_BM_PROJECTS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({
            type: ActionTypes.CHANGE_BM_PROJECT_LOADER,
            isLoading: false,
        });
    } catch (error) {
        console.error("ERR in onBMProject: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onCreateBmProject(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield BmProjectService.CreateBmProject(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield BmProjectService.GetBmProjects(action.customerId ?? null);
            let result = _result.result as IBMProjectALL[];
            yield put({ type: ActionTypes.SAVE_BM_PROJECTS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateBmProject(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield BmProjectService.UpdateBmProject(
            action.model
        );
        if (!resultUpdate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let resultCus: ICommonResponse = yield BmProjectService.GetBmProjects(action.customerId ?? null);
            let result = resultCus.result as IBMProjectALL[];
            yield put({ type: ActionTypes.SAVE_BM_PROJECTS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onIncludeBmProjectInEasyAccessLevers(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: true });
        let resultInclude: ICommonResponse = yield BmProjectService.IncludeBmProjectInEasyAccessLevers(
            action.bmProjectId
        );

        if (!resultInclude.isError) {
            let _result: ICommonResponse = yield BmProjectService.GetBmProjects(action.customerId ?? null);
            let result = _result.result as IBMProjectALL[];
            yield put({ type: ActionTypes.SAVE_BM_PROJECTS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultInclude });
        }
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in IncludeQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onExcludeBmProjectInEasyAccessLevers(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: true });
        let resultExclude: ICommonResponse = yield BmProjectService.ExcludeBmProjectInEasyAccessLevers(
            action.bmProjectId
        );
        if (!resultExclude.isError) {
            let _result: ICommonResponse = yield BmProjectService.GetBmProjects(action.customerId ?? null);
            let result = _result.result as IBMProjectALL[];
            yield put({ type: ActionTypes.SAVE_BM_PROJECTS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultExclude });
        }
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in ExcludeQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}



function* watchBmProjects() {
    yield takeEvery(ActionTypes.GET_BM_PROJECTS, onGetBmProject);
}
function* watchUpdateBmProject() {
    yield takeEvery(ActionTypes.UPDATE_BM_PROJECT, onUpdateBmProject)
}
function* watchCreateBmProject() {
    yield takeEvery(ActionTypes.CREATE_BM_PROJECT, onCreateBmProject)
}
function* watchIncludeBmProjectInEasyAccessLevers() {
    yield takeEvery(ActionTypes.INCLUDE_BM_PROJECT, onIncludeBmProjectInEasyAccessLevers)
}
function* watchExcludeBmProjectInEasyAccessLevers() {
    yield takeEvery(ActionTypes.EXCLUDE_BM_PROJECT, onExcludeBmProjectInEasyAccessLevers)
}

export default function* BmProjectSagas() {
    yield all([
        watchBmProjects(),
        watchUpdateBmProject(),
        watchCreateBmProject(),
        watchIncludeBmProjectInEasyAccessLevers(),
        watchExcludeBmProjectInEasyAccessLevers()

    ]);
}
