import { Button, Space, Spin, Switch, Tabs } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CurrentFlProject, IFrontLoadingToolProject } from "../../../../Models/IFrontLoadingTool";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import BestPracticesTab from "./BestPracticesTab/BestPracticesTab";
import CostTypesVsProjectTab from "./CostTypesVsProjectTab/CostTypesVsProjectTab";
import InputTab from "./InputTab/InputTab";
import OverViewProjectsTab from "./OverViewProjectsTab/OverViewProjectsTab";
import ProjectComparisonTab from "./ProjectComparisonTab/ProjectComparisonTab";
import RTDCurveTab from "./RTDCurveTab/RTDCurveTab";
import './frontloadingtabs.css';
import moment from "moment";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { DownloadChartAsImage } from "../../../../Utils/helperFunctions";
import { RTDCurveData } from "./RTDCurveTab/Models/RTDCurveData";
import HelpPage from "../../../../Layout/HelpPage";

interface IProps {
    getFlProjects: () => void;
    getAboutCurrentFlProject: () => void;
    flProjects: IFrontLoadingToolProject[];
    gettingFlProjects: boolean;
    gettingCurrentFlProject: boolean;
    currentFlProject: CurrentFlProject;
    exportToExcel: () => any;
    RTDCurveData: RTDCurveData;
}

function FrontLoadingTabs(props: IProps) {

    const [activeKey, setActiveKey] = useState(localStorage.getItem("fronloading_tab") ?? "overviewproject");
    const [showBestPracticeCurve, setShowBestPracticeCurve] = useState(false);
    useEffect(() => {
        props.getFlProjects();
        props.getAboutCurrentFlProject();
        setActiveKey(activeKey);
    }, []);

    const onChangeTab = (key: string) => {
        localStorage.setItem("fronloading_tab", key);
        setActiveKey(key);
    };

    const { t } = useTranslation();
    const onExport = () => {
        props.exportToExcel();
    }
    type PositionType = 'left' | 'right';
    const currentFlProject: Record<PositionType, React.ReactNode> = {
        left: null,
        right: <Spin spinning={props.gettingCurrentFlProject}>

            <div className="extra-tab-currentflproject">
                <div className="currentFlProject-Name-div" title={props.currentFlProject?.name}>
                    {props.currentFlProject?.name}
                </div>
                <div className="currentFlProject-dates-div">
                    <div className="currentFlProject-startdate-div">
                        {t("Start Date:")} < span
                            className="currentFlProject-startdate-span">
                            {props.currentFlProject?.startDate ? moment(props.currentFlProject?.startDate)
                                .format(i18n.language === 'de' ? 'DD.MM.YYYY' : 'MM/DD/YYYY') : null}
                        </span>
                    </div>
                    <div className="currentFlProject-enddate-div">
                        {t("End Date:")} <span className="currentFlProject-enddate-span">
                            {props.currentFlProject?.endDate ?
                                moment(props.currentFlProject?.endDate)
                                    .format(i18n.language === 'de' ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
                                : "No phase yet"}
                        </span> {props.currentFlProject?.lastPhase ? t(`at phase`) : null} {props.currentFlProject?.lastPhase ? <span
                            className="currentFlProject-lastphase-span"
                            title={props.currentFlProject?.lastPhase}>
                            {props.currentFlProject?.lastPhase}
                        </span> : null}
                    </div>
                </div>
            </div>
        </Spin>
    };

    const tabs = [
        {
            label: `${t("Overview Projects")}`,
            key: 'overviewproject',
            forceRender: true,
            children: (activeKey === 'overviewproject') ? <OverViewProjectsTab
                active={activeKey === 'overviewproject'}
                flProjects={props.flProjects} /> : null
        },
        {
            label: `${t("Input")}`,
            key: "input",
            forceRender: true,
            children: (activeKey === "input") ? <InputTab active={activeKey === "input"} /> : null
        },
        {
            label: `${t("RTD Curve")}`,
            key: 'RTDCurve',
            forceRender: true,
            children: (activeKey === 'RTDCurve') ?
                <>
                    {(props.RTDCurveData == null) ? null :
                        <Space direction="horizontal">
                            {/* <Button type="default" icon={<FileExcelOutlined style={{ 'margin': 'auto' }} />} onClick={onExport}
                                title={`${t("Export")}`}
                            /> */}
                            <Button className='chart-download-Btn'
                                icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                                onClick={() => DownloadChartAsImage(`${t("RTD Curve")}`, document.getElementById('RTDCurve_tab_main_div'))}
                                type="default"
                                title={`${t("Download chart")}`}
                            />
                            <Switch
                                checkedChildren={`${t("Hide BP")}`}
                                unCheckedChildren={`${t("Show BP")}`}
                                style={{ 'zIndex': 100000 }}
                                checked={showBestPracticeCurve}
                                onChange={() => {
                                    setShowBestPracticeCurve(!showBestPracticeCurve);
                                }}
                                size='default'
                                title={showBestPracticeCurve ? `${t("Hide Best Practice Curve")}` : `${t("Show Best Practice Curve")}`} />
                        </Space>
                    }
                    <RTDCurveTab showBestPracticeCurve={showBestPracticeCurve} active={activeKey === 'RTDCurve'} flProjects={props.flProjects} />
                </>
                : null
        },
        {
            label: `${t("Project Comparison")}`,
            key: 'projectcomparison',
            forceRender: true,
            children: (activeKey === 'projectcomparison') ? <ProjectComparisonTab
                active={activeKey === 'projectcomparison'}
                flProjects={props.flProjects} /> : null
        },
        {
            label: `${t("Cost Types vs Project")}`,
            key: 'costTypesVsProjec',
            forceRender: true,
            children: (activeKey === 'costTypesVsProjec') ? <CostTypesVsProjectTab active={activeKey === 'costTypesVsProjec'} /> : null
        },
        {
            label: `${t("Best Practices")}`,
            key: 'bestpractices',
            forceRender: true,
            children: (activeKey === 'bestpractices') ? <BestPracticesTab active={activeKey === 'bestpractices'} /> : null
        },
        {
            label: <>{t("Tipps and Tricks")}</>, key: 'tips-frontloading-tab',
            children: <HelpPage currentPageCode="frontloading" />
        }
    ];

    return (
        <Spin spinning={props.gettingFlProjects}>
            <div className="frontloading_main_div">
                <Tabs
                    tabBarExtraContent={props.currentFlProject ? currentFlProject : null}
                    onChange={onChangeTab}
                    type="card"
                    items={tabs}
                    destroyInactiveTabPane={true}
                    activeKey={activeKey}
                />
            </div>
        </Spin>
    );
}

const mapStateToProps = (state) => {
    return {
        flProjects: state.frontLoadingProjects.flProjects,
        gettingFlProjects: state.frontLoadingProjects.gettingFlProjects,
        currentFlProject: state.frontLoadingProjects.currentFlProject,
        gettingCurrentFlProject: state.frontLoadingProjects.gettingAboutCurrentFlProject,
        RTDCurveData: state.frontLoadingProjects?.RTDCurveData,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getFlProjects: () => dispatch({ type: ActionTypes.GetFlProjects }),
        getAboutCurrentFlProject: () => dispatch({ type: ActionTypes.GetAboutCurrentFlProject }),
        exportToExcel: () => dispatch({ type: ActionTypes.ExportFrontloadingToExcel })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontLoadingTabs);