import { useEffect, useState } from "react";
import { Button, Form, Input, Space, Spin, Tabs, Tooltip, Typography } from "antd";
import { connect } from "react-redux";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IActivityProfileTemplateVersionItem, IProfileVersionsAll } from '../../../../Models/IActivityManagement';
import { ICommonResponse } from "../../../../Models/Responses/ICommonResponse";
import "./index.scss";
import { Link, useSearchParams } from "react-router-dom";
import { t } from "i18next";
import CreateNeVersionModal from "./CreateNeVersionModal";
import PopconfirmAsync from "../../../../Components/PopconfirmAsync";
import GroupActivityTable from "./GroupActivityVersionTable";
import AdminTable from "../../../../Components/AdminTable/AdminTable";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { RulesName } from "../../Rules/RulesValidation";
import AdminOperation from "../../../../Components/AdminOperation/AdminOperation";
import { SisternodeOutlined } from "@ant-design/icons";

interface IProps {
    profileVersionsData: IProfileVersionsAll,
    isLoading: boolean,
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getProfileVersionsForAdmin: (id: number) => any;
    changeStatusProfileVersion: (profileId: number, profileVersionId: number, isActive: boolean) => any;
    changeStatusActivityGroup: (profileId: number, groupId: number, isActive: boolean) => any;
    deleteMapGroupToProfile: (profileId: number, groupId: number, profileVersionId: number) => any;
    updateProfileVersion: (profileId: number, profileVersionId: number, nameEn: string, nameDe: string) => any;
}
const ProfileVersions = (props: IProps) => {

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record: IActivityProfileTemplateVersionItem) => record.id.toString() === editingKey;
    const [searchParams] = useSearchParams();
    const profileId = Number(searchParams.get('profileId'));
    useEffect(() => {
        props.getProfileVersionsForAdmin(profileId);
    }, [profileId]);
    const versionsData: IActivityProfileTemplateVersionItem[] = props.profileVersionsData?.versions ?? [];

    const changeStatusActivityProfileVersion = async (profileVersionId: number, isActive: boolean) => {
        try {
            await props.changeStatusProfileVersion(profileId, profileVersionId, isActive);
        } catch (errInfo) {
            console.log('change status Failed:', errInfo);
        }
    }

    const onFinish = async (values: IActivityProfileTemplateVersionItem) => {
        try {
            await form.validateFields()
            const newData = [...versionsData];
            const index = newData.findIndex(item => item.id.toString() === editingKey);
            if (index > -1) {
                const item = newData[index];
                props.updateProfileVersion(profileId, item.id, values.nameEn ?? item.nameEn, values.nameDe ?? item.nameDe);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }
    const edit = (record: Partial<IActivityProfileTemplateVersionItem>) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id.toString());
    };
    const cancel = () => {
        setEditingKey('');
    };
    const columns: any[] = [
        {
            key: "version_number",
            title: `${t("versionNumber")}`,
            dataIndex: 'versionNumber',
            render: (text: any, record: any) => {
                return <Typography.Text>{text}</Typography.Text>
            }
        },
        {
            key: "nameEn_1",
            title: `${t("English Name")}`,
            dataIndex: 'nameEn',
            render: (text: any, record: any) => {
                if (editingKey === record.id.toString()) {
                    return (
                        <Form.Item name='nameEn'
                            rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <Typography.Text>{text}</Typography.Text>
                }
            }
        },
        {
            key: "nameDe_2",
            title: `${t("German Name")}`,
            dataIndex: 'nameDe',
            render: (text: any, record: any) => {
                if (editingKey === record.id.toString()) {
                    return (
                        <Form.Item name='nameDe'
                            rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <Typography.Text>{text}</Typography.Text>
                }
            }
        },
        {
            title: `${t("Actions")}`,
            dataIndex: 'Actions',
            width: "10%",
            render: (_: any, record: IActivityProfileTemplateVersionItem) => {
                const editable = isEditing(record);
                return <AdminOperation
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                />
            },
        },
        {
            key: "status_1",
            title: `${t("Completed?")}`,
            dataIndex: 'isActive',
            width: "10%",
            render: (_: any, record: any) => {
                const activeStatus = record.isActive;
                const versionId = record.id;
                return activeStatus ? (
                    <PopconfirmAsync
                        title={`${t("Sure to Reopen?")}`}
                        handleOk={() => changeStatusActivityProfileVersion(versionId, !activeStatus)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        deactivateUser={true}
                        iconPower
                    />
                ) : (
                    <PopconfirmAsync
                        title={`${t("Sure To Complete?")}`}
                        handleOk={() => changeStatusActivityProfileVersion(versionId, !activeStatus)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        activeUser={true}
                        iconPower
                    />
                )
            }
        },
        {
            title: `${t("Details")}`,
            dataIndex: '',
            width: "7%",
            fixed: "right",
            render: (_: any, record: any) => {
                return <Space>
                    <Tooltip title={`${t("Go to")} ${t("Details")}`}>
                        <Link to={`/admin/activity/profileversion?profileVersionId=${record?.id}&profileId=${props.profileVersionsData.profileId}`}
                        >
                            <SisternodeOutlined style={{ fontSize: "15px" }} />
                        </Link>
                    </Tooltip>
                </Space>

            }
        },

    ];
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <Typography className="profile_title_Active ">
                <p>{props.profileVersionsData?.profileName}</p>
                <CreateNeVersionModal profileId={profileId} profileVersionData={versionsData} />
            </Typography>
            <Form form={form} key="activity_profile_form" onFinish={onFinish}>
                <AdminTable
                    keyTable='activityProfileVersionsMng_table'
                    className='activityProfileVersionsMng_table'
                    dataSource={versionsData}
                    columns={columns}
                />
            </Form> 
        </Spin >


    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        profileVersionsData: state.activityProfileVersions.ActivityProfileVersionsForAdminResult,
        isLoading: state.activityProfileVersions.isActivityProfileVersionForAdminLoading,
        isModalLoading: state.activityProfileVersions.isModalLoading,
        modalResponse: state.activityProfileVersions.modalResponse,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateProfileVersion: (profileId: number, profileVersionId: number, nameEn: string, nameDe: string) => dispatch({ type: ActionTypes.Update_PROFILE_VERSION, profileVersionId, nameEn, nameDe, profileId }),
        getProfileVersionsForAdmin: (id: number) => dispatch({ type: ActionTypes.GET_ACTIVITY_PROFILE_VERSIONS, profileId: id }),
        changeStatusProfileVersion: (profileId: number, profileVersionId: number, isActive: boolean) => dispatch({ type: ActionTypes.CHANGE_STATUS_ACTIVITY_PROFILE_VERSION, profileId, profileVersionId, isActive }),
        changeStatusActivityGroup: (profileId: number, groupId: number, isActive: boolean) => dispatch({ type: ActionTypes.CHANGE_STATUS_ACTIVITY_GROUP, profileId, groupId, isActive }),
        deleteMapGroupToProfile: (profileId: number, groupId: number, profileVersionId: number) => dispatch({ type: ActionTypes.DELETE_MAP_GROUP_TO_PROFILE, profileId, groupId, versionId: profileVersionId })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileVersions);
