import React, { useRef, useState } from 'react';
import { Form, Input, InputNumber, Table, Tag } from 'antd';
import { ICurvePhaseItem } from '../../../../../Models/ICurves';
import AdminOperation from '../../../../../Components/AdminOperation/AdminOperation';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../../Redux/actionsTypes';
//import CurvesPhasePointModal from './CurvesPhasePointModal';
import { formatValue } from '../../../../../Utils/helperFunctions';
import { RulesDescription, RulesName, RulesPercentage } from '../../../Rules/RulesValidation';
import { t } from 'i18next';
import { getColumnSearchProps } from '../../../../../Components/SearchButton';
import { FilterConfirmProps } from 'antd/lib/table/interface';
interface IProps {
  phaseData: ICurvePhaseItem[],
  curveId: number;
  updateCurvePhase: (model: ICurvePhaseItem) => any;
  deleteCurvePhase: (id: number) => any;
  sumFtePhases: () => any;
  sumDurationPhases: () => any;
}
const CurvesPhaseTable = (props: IProps) => {
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  let durationPercentageValue = Form.useWatch("durationPercentage", form)
  let ftePercentageValue = Form.useWatch("ftePercentage", form)
  const searchInput = useRef<any>(null);
  const handleSearch = (
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };
  const columns: any[] = [
    {
      key: "phaseNumber_1",
      title: 'Phase Number',
      dataIndex: 'phaseNumber',
      render: (text: any, record: any) => {
        return <Tag>{text}</Tag>
      },
    },
    {
      key: "nameEn_1",
      title: `${t("English Name")}`,
      dataIndex: 'nameEn',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='nameEn'
              rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
            >
              <Input />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
      ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),
      
    },
    {
      key: "nameDe_2",
      title: `${t("German Name")}`,
      dataIndex: 'nameDe',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='nameDe'
              rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
            >
              <Input />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
      ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),
      
    },
    {
      key: "descriptionEn_3",
      title: `${t("English Description")}`,
      dataIndex: 'descriptionEn',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='descriptionEn'
              rules={RulesDescription({ name: `${t("English Description")}`, countChar: 1500 })}
            >
              <Input.TextArea autoSize />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
    },
    {
      key: "descriptionDe_4",
      title: `${t("German Description")}`,
      dataIndex: 'descriptionDe',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='descriptionDe'
              rules={RulesDescription({ name: `${t("German Description")}`, countChar: 1500 })}
            >
              <Input.TextArea autoSize />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
    },
    {
      key: "durationPercentage_2",
      title: `${t("Duration Percentage")}`,
      dataIndex: 'durationPercentage',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='durationPercentage'
              rules={RulesPercentage({
                filedName: `${t("Duration Percentage")}`,
                valuePercentage: durationPercentageValue,
                remainingPercentage: props.sumDurationPhases() + record.durationPercentage
              })}
            >
              <InputNumber
                addonAfter="%"
              />
            </Form.Item>
          )
        } else {
          return <Tag>{`${formatValue(text)}%`}</Tag>
        }
      },
    },
    {
      key: "ftePercentage_2",
      title: `${t("Fte Percentage")}`,
      dataIndex: 'ftePercentage',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='ftePercentage'
              rules={RulesPercentage({
                filedName: `${t("Fte Percentage")}`,
                valuePercentage: ftePercentageValue,
                remainingPercentage: props.sumFtePhases() + record.ftePercentage
              })}
            >
              <InputNumber
                addonAfter="%"
              />
            </Form.Item>
          )
        } else {
          return <Tag>{`${formatValue(text)}%`}</Tag>
        }
      },
    },
    {
      title: `${t("Actions")}`,
      dataIndex: '',
      width: '100px',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return <AdminOperation
          deleteId={record.id}
          record={record}
          editable={editable}
          onCancel={cancel}
          onEdit={edit}
          onDelete={deleteCurvePhase}
        />
      },
    }
    // {
    //   title: `${t("Points")}`,
    //   dataIndex: "",
    //   render: (_any, record: any) => {
    //     return <CurvesPhasePointModal pointsData={record.points} phaseId={record.id} phasesData={props.phaseData} />
    //   }
    // }
  ];
  const isEditing = (record: ICurvePhaseItem) => record.key === editingKey;
  const edit = (record: Partial<ICurvePhaseItem> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const onFinish = async (values: any) => {
    try {
      await form.validateFields()
      const index = props?.phaseData.findIndex(item => item.key === editingKey);
      if (index > -1) {
        const item = props?.phaseData[index];
        const phaseUpdate: ICurvePhaseItem = { id: item.id, curveId: props.curveId, ...values };
        await props.updateCurvePhase(phaseUpdate);
        console.log(phaseUpdate);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }

  }
  const deleteCurvePhase = async (id: any) => {
    try {
      await props.deleteCurvePhase(id);
    } catch (error) {
      console.log('delete Failed:', error);
    }

  }
  return (
    <Form form={form} key="ind_form" onFinish={onFinish}>
      <Table
        key='ind_table'
        getPopupContainer={() => document.getElementById("app-layout")}
        className='table_seg'
        bordered
        dataSource={props?.phaseData}
        columns={columns}
        rowClassName="editable-row"
        scroll={{ y: "calc(100vh - 300px)" }}
        pagination={{
          defaultPageSize: 50,
          pageSizeOptions: [50, 100, 150],
          total: props?.phaseData.length,
          showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
          showSizeChanger: true,
          locale: { items_per_page: `/ ${t("Page")}` },
        }}
        locale={{
          triggerDesc: t('Click to sort descending'),
          triggerAsc: t('Click to sort ascending'),
          cancelSort: t('Click to cancel sorting')
        }}
      />
    </Form>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCurvePhase: (model: ICurvePhaseItem) => dispatch({ type: ActionTypes.UPDATE_CURVE_PHASE, model: model }),
    deleteCurvePhase: (id: number) => dispatch({ type: ActionTypes.DELETE_CURVE_PHASE, id })

  };
};
export default connect(null, mapDispatchToProps)(CurvesPhaseTable);






