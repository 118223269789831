import React, { useState } from 'react';
import { Button, Form, Input, InputNumber, Space, Spin, Tag } from 'antd';
import { connect } from 'react-redux';
import { ICurvePhaseItem } from '../../../../../Models/ICurves';
import { ICommonResponse } from '../../../../../Models/Responses/ICommonResponse';
import ModalAdmin from '../../../../../Components/ModalAdmin/ModalAdmins';
import { ActionTypes } from '../../../../../Redux/actionsTypes';
import { inputFormatter, inputParser } from '../../../../../Utils/helperFunctions';
import i18n from '../../../../../i18n';
import CurvesPhaseTable from './CurvesPhaseTable';
import { RulesDescription, RulesName, RulesPercentage, RulesRequire } from '../../../Rules/RulesValidation';
import { t } from 'i18next';

interface IProps {
  createCurvePhase: (model: ICurvePhaseItem) => any;
  phasesData: ICurvePhaseItem[]
  curveId: number;
  isLoading: boolean;
  isModalLoading: boolean;
  modalResponse: ICommonResponse;
}
const CurvesPhase = (props: IProps) => {
  const [formValues, setFormValues] = useState<ICurvePhaseItem>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const phaseDataRow: ICurvePhaseItem[] = props.phasesData?.map(c => {
    return { key: `Curve_phase_${c.id}`, ...c }
  }) ?? [];
  const formValuesHandler = async () => {
    try {
      await form.validateFields();
      const newCurvePhase = {
        curveId: props.curveId,

        ...formValues
      };
      await props.createCurvePhase(newCurvePhase);
    } catch (error) {
      console.log(error);
    }
  }
  const closeAndReset = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormValues({} as ICurvePhaseItem)
  }
  const sumDurationPhases = () => {
    let sum: number = props.phasesData
      .map(d => d.durationPercentage)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    return 100 - sum;
  }

  const sumPhases = () => {
    let sum: number = props.phasesData
      .map(d => d.ftePercentage)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    return 100 - sum;
  }
  return (
    <Spin spinning={props.isLoading || props.isModalLoading}>
      <div className="admin-component">

        <Space direction="vertical" size="small">
          <Space style={{ justifyContent: "space-between" }} >
            <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Curve")} Phase`} btnText={`${t("Add")}`}
              width="520px"
              modalResponse={props.modalResponse}
              closeAndReset={closeAndReset}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                  {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                  {`${t("Save")}`} & {`${t("Send")}`}
                </Button>
              ]}>
              <Form
                layout="vertical"
                form={form}
              >
                <Form.Item label={`${t("English Name")}`} name='nameEn'
                  rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
                  hasFeedback>
                  <Input onChange={(e) => setFormValues({ ...formValues, nameEn: (e.target.value).trimStart() })} />
                </Form.Item>
                <Form.Item label={`${t("German Name")}`} name='nameDe'
                  rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
                  hasFeedback>
                  <Input onChange={(e) => setFormValues({ ...formValues, nameDe: (e.target.value).trimStart() })} />
                </Form.Item>
                <Form.Item label={`${t("English Description")}`} name='descriptionEn'
                  rules={RulesDescription({ name: `${t("English Description")}`, countChar: 1500 })}
                  hasFeedback>
                  <Input.TextArea onChange={(e) => setFormValues({ ...formValues, descriptionEn: (e.target.value).trimStart() })} />
                </Form.Item>
                <Form.Item label={`${t("German Description")}`} name='descriptionDe'
                  rules={RulesDescription({ name: `${t("German Description")}`, countChar: 1500 })}
                  hasFeedback>
                  <Input.TextArea onChange={(e) => setFormValues({ ...formValues, descriptionDe: (e.target.value).trimStart() })} />
                </Form.Item>

                <Form.Item label={`${t("Duration Percentage")}`} name='durationPercentage' key='durationPercentage'
                  rules={RulesPercentage({
                    filedName: `${t("Duration Percentage")}`,
                    valuePercentage: formValues?.durationPercentage,
                    remainingPercentage: sumDurationPhases()
                  })}

                >
                  <InputNumber
                    formatter={(value) => inputFormatter(value, i18n.language)}
                    parser={(x) => inputParser(x, i18n.language)}
                    addonAfter="%"
                    onChange={(value: number) => setFormValues({
                      ...formValues,
                      durationPercentage: value
                    })}
                    min={1}
                    max={100}
                  />
                </Form.Item>
                <Form.Item label={`${t("Fte Percentage")}`} name='ftePercentage' key='ftePercentage'
                  rules={RulesPercentage({
                    filedName: `${t("Fte Percentage")}`,
                    valuePercentage: formValues?.ftePercentage,
                    remainingPercentage: sumPhases()
                  })}

                >
                  <InputNumber
                    formatter={(value) => inputFormatter(value, i18n.language)}
                    parser={(x) => inputParser(x, i18n.language)}
                    addonAfter="%"
                    onChange={(value: number) => setFormValues({
                      ...formValues,
                      ftePercentage: value
                    })}
                    min={1}
                    max={100}
                  />
                </Form.Item>
              </Form>
            </ModalAdmin>

            <Tag style={{ fontWeight: "600", padding: ".2rem .5rem" }}>
              {sumPhases() === 0 ?
                (<span style={{ color: "rgb(249, 5, 5)" }}>{`${t("Fte Values ​​have been completed")}`}</span>)
                : (<>{`${t('Fte Remaining Percentage')}`}
                  <span style={{ color: "#84a5f4", marginLeft: ".2rem" }}>{`${sumPhases()}%`}</span>
                </>
                )}
            </Tag>

            <Tag style={{ fontWeight: "600", padding: ".2rem .5rem" }}>
              {sumDurationPhases() === 0 ?
                (<span style={{ color: "rgb(249, 5, 5)" }}>{`${t("Duration Values ​​have been completed")}`}</span>)
                : (<>{`${t('Duration Remaining Percentage')}`}
                  <span style={{ color: "#84a5f4", marginLeft: ".2rem" }}>{`${sumDurationPhases()}%`}</span>
                </>
                )}
            </Tag>
          </Space>
          <CurvesPhaseTable curveId={props.curveId} phaseData={phaseDataRow} sumFtePhases={sumPhases} sumDurationPhases={sumDurationPhases} />
        </Space>
      </div>
    </Spin >
  );
}
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.curves?.isFrontLoadingLoading,
    isModalLoading: state.curves?.isModalLoading,
    modalResponse: state.curves?.modalResponse
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    createCurvePhase: (model: ICurvePhaseItem) => dispatch({ type: ActionTypes.CREATE_CURVE_PHASE, model })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurvesPhase);
