import { ActionTypes } from "../actionsTypes";
import { IIndustryBestPracticeState } from "./IGeneralState";

const initialState: IIndustryBestPracticeState = {
  isIndustryBestPracticeLoading: false,
  industryBestPracticeResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const IndustryBestPracticeReducer = (state = initialState, action: any): IIndustryBestPracticeState => {
  switch (action.type) {
    case ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER:
      return {
        ...state,
        isIndustryBestPracticeLoading: action.isLoading,
      };
    case ActionTypes.SAVE_INDUSTRY_BEST_PRACTICES:
      return {
        ...state,
        industryBestPracticeResult: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      };
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      };
    default:
      break;
  }
  return state;
};
export default IndustryBestPracticeReducer;
