import React, { useEffect, useState } from 'react'
import { IFrontLoadingTemp, IFrontLoadingTempAll, IFrontLoadingTempAllWithKey } from '../../../../Models/IFrontLoading';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { Button, Form, Input, Select, Space, Spin } from 'antd';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import FrontLoadingTable from './FrontLoadingTable';
import { ICurvesAll } from '../../../../Models/ICurves';
import { RulesName } from '../../Rules/RulesValidation';
import { t } from 'i18next';

interface IProps {
    getAllFrontLoading: () => any;
    createFrontLoadingTemp: (model: IFrontLoadingTemp) => any;
    AllFrontLoadingTemp: IFrontLoadingTempAll[]
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    AllCurves: ICurvesAll[];
    getAllCurves: () => any;
}
const FrontLoadingTemp = (props: IProps) => {
    const [formValues, setFormValues] = useState<IFrontLoadingTemp>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => { props.getAllFrontLoading(); props.getAllCurves() }, []);
    const frontLoadingTempData: IFrontLoadingTempAllWithKey[] = props.AllFrontLoadingTemp?.map(f => {
        return {
            key: `temp_${f.template.id}`,
            id: f.template.id,
            nameEn: f.template.nameEn,
            nameDe: f.template.nameDe,
            curveId: f.template.curveId,
            phases: f.phases
        }
    }) ?? [];
    // const curvesData = props?.AllCurves?.map(c => {
    //     return {
    //         key: `curve_${c.curve.id}`,
    //         value: c.curve.id,
    //         label: c.curve.nameEn
    //     }
    // }) ?? [];
    // console.log(curvesData);

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const newFrontLoadTemp = { ...formValues };
            await props.createFrontLoadingTemp(newFrontLoadTemp);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("FrontLoading")} ${t("Templates")}`} btnText={`${t("Add")}`}
                        width="520px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>
                        <Form
                            layout="vertical"
                            form={form}

                        >
                            <Form.Item label={`${t("English Name")}`}  name='nameEn'
                                rules={RulesName({ name: `${t("English Name")}`,countChar:128 })}
                                hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, nameEn:(e.target.value).trimStart()})} />
                            </Form.Item>
                            <Form.Item label={`${t("German Name")}`} name='nameDe'
                                rules={RulesName({ name: `${t("German Name")}`,countChar:128 })}
                                hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, nameDe:(e.target.value).trimStart() })} />
                            </Form.Item>
                        </Form>
                    </ModalAdmin>
                    <FrontLoadingTable frontLoadingTempData={frontLoadingTempData}  />
                </Space>
            </div>

        </Spin>
    );
}
const mapStateToProps = (state: any) => {
    return {
        AllFrontLoadingTemp: state.frontLoadingTemp?.frontLoadingLoadingResult,
        isLoading: state.frontLoadingTemp?.isFrontLoadingLoading,
        isModalLoading: state.frontLoadingTemp?.isModalLoading,
        modalResponse: state.frontLoadingTemp?.modalResponse,
        AllCurves: state.curves?.curvesResult
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllFrontLoading: () => dispatch({ type: ActionTypes.GET_ALL_FRONT_LOADING_TEMP }),
        createFrontLoadingTemp: (model: IFrontLoadingTemp) => dispatch({ type: ActionTypes.CREATE_FRONT_LOADING_TEMP, model }),
        getAllCurves: () => dispatch({ type: ActionTypes.GET_ALL_CURVES }),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontLoadingTemp);
