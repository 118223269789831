import { ActionTypes } from "../actionsTypes";
import { QuestionState } from "./IGeneralState";
const initialState: QuestionState = {
  isQuestionLoading: false,
  questions: null,
};
const QuestionReducer = (
  state = initialState,
  action: any
): QuestionState  => { 
  switch (action.type) {
    case ActionTypes.CHANGE_QUESTION_LOADER:
      return {
        ...state,
        isQuestionLoading: action.isLoading,
      };
    case ActionTypes.SAVE_QUESTIONS:
      return {
        ...state,
        questions: action.result,
      };
    default:
      break;
  }
  return state;
};

export default QuestionReducer;