import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import AdministratorsService from "../../Services/AdministratorsService";
import { IAdministrator} from '../../Models/IAdministrator';
import ProfileService from "../../Services/ProfileService";

function* OnGetAdministrators(action: any) {
    try {
        //console.log("OnGetAdministrators");
        yield put({ type: ActionTypes.CHANGE_ADMINISTRATOR_LOADER, isLoading: true });
        let _result: ICommonResponse = yield AdministratorsService.GetAdministrators();
        if (!_result.isError) {
            let result = _result.result as IAdministrator[];
            yield put({ type: ActionTypes.SAVE_ADMINISTRATORS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_ADMINISTRATOR_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in OnGetAdministrators: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnCreateAdministrator(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ADMINISTRATOR_LOADER, isLoading: true, });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let _result: ICommonResponse = yield AdministratorsService.CreateAdministrator(action.model);
        if (!_result.isError) {
            let _result: ICommonResponse = yield AdministratorsService.GetAdministrators();
            let result = _result.result as IAdministrator[];
            yield put({ type: ActionTypes.SAVE_ADMINISTRATORS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_ADMINISTRATOR_LOADER, isLoading: false, });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateAdministrator: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onUpdateAdministrator(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ADMINISTRATOR_LOADER, isLoading: true });

        let _result: ICommonResponse = yield AdministratorsService.UpdateAdministrator(action.model);

        if (!_result.isError) {
            let _result: ICommonResponse = yield AdministratorsService.GetAdministrators();
            let result = _result.result as IAdministrator[];
            yield put({ type: ActionTypes.SAVE_ADMINISTRATORS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_ADMINISTRATOR_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateAdministrator: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* watchGetAdministrators() {
    yield takeEvery(ActionTypes.GET_ADMINISTRATORS, OnGetAdministrators);
}
function* watchCreateAdministrator() {
    yield takeEvery(ActionTypes.CREATE_ADMINISTRATOR, OnCreateAdministrator);
}
function* watchUpdateAdministrator() {
    yield takeEvery(ActionTypes.UPDATE_ADMINISTRATOR, onUpdateAdministrator);
}
export default function* AdministratorSagas() {
    yield all([
        watchGetAdministrators(),
        watchCreateAdministrator(),
        watchUpdateAdministrator(),
       
    ]);
}
