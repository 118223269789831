
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import PermissionsService from "../../Services/PermissionsService";
import { IPermissionsDto } from "../../Models/Permissions/PermissionsDto";

function* OnGetAll(action: any) {
    try {
        yield put({ type: ActionTypes.ChangePermissionLoader, isLoading: true });
        let _result: ICommonResponse = yield PermissionsService.GetAllPermissions();
        if (!_result.isError) {
            let result = _result.result as IPermissionsDto;
            yield put({ type: ActionTypes.SetPermissions, result: result });
            yield put({
                type: ActionTypes.SetCurrentProjectPermissionId,
                result: localStorage.getItem("currentProjectSelectedId") ?
                    Number(localStorage.getItem("currentProjectSelectedId")) :
                    result?.bmProjects?.[0]?.id
            });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangePermissionLoader, isLoading: false });
    } catch (error) {
        console.error("ERR in  Permissions: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnSaveAll(action: any) {
    try {
        yield put({ type: ActionTypes.ChangePermissionLoader, result: true });
        let resultCreate: ICommonResponse = yield PermissionsService.SaveAllPermissions(
            action.model
        );
        if (!resultCreate.isError) {
            let _result: ICommonResponse = yield PermissionsService.GetAllPermissions();
            let result = _result.result as IPermissionsDto;
            yield put({ type: ActionTypes.SetPermissions, result: result });
            yield put({
                type: ActionTypes.SetCurrentProjectPermissionId,
                result: localStorage.getItem("currentProjectSelectedId") ?
                    Number(localStorage.getItem("currentProjectSelectedId")) :
                    result?.bmProjects?.[0]?.id
            });
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.ChangePermissionLoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onChangePermissions(action: any) {
    yield put({ type: ActionTypes.SetPermissions, result: action.result });
}

function* onChangeCurrentProjectPermissionId(action: any) {
    yield put({ type: ActionTypes.SetCurrentProjectPermissionId, result: action.result });
}


function* watchGetAllPermissions() {
    yield takeEvery(ActionTypes.GetPermissions, OnGetAll);
}
function* watchSaveAllPermissions() {
    yield takeEvery(ActionTypes.SavePermissions, OnSaveAll);
}

function* watchChangePermissions() {
    yield takeEvery(ActionTypes.ChangePermissions, onChangePermissions);
}

function* watchChangeCurrentProjectPermissionId() {
    yield takeEvery(ActionTypes.ChangeCurrentProjectPermissionId, onChangeCurrentProjectPermissionId);
}

export default function* PermissionsSagas() {
    yield all([
        watchGetAllPermissions(),
        watchSaveAllPermissions(),
        watchChangePermissions(),
        watchChangeCurrentProjectPermissionId()
    ]);
}