import { Button, Form, Row, Col, Radio, Space, Progress } from "antd";
import { useState } from "react";
import "./index.scss";



interface IProgressBarShapeProps{
  type: "circle" | "line" | "dashboard" ;
  percent: number;
  width: number;
  status: "success" | "normal" | "active" | "exception";
}
const ProgressBarShape = (props: IProgressBarShapeProps) =>{

    
  return (
    <Progress type={props.type} percent={props.percent} width={props.width} 
    status={props.status}
    strokeColor='#3DC549'
    trailColor='#ccc'
    strokeWidth={12}/> 
  )
}

export default ProgressBarShape;