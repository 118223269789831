import { ActionTypes } from "../actionsTypes";
import { IErrorPageState } from "./IGeneralState";

const initialState: IErrorPageState = {
    isErrorPageLoading: false,
    errorPagesResult: null,
    isModalLoading: false,
    modalResponse: null,

}

const ErrorPgeForAdminReducer = (state = initialState, action: any): IErrorPageState => {
    switch (action.type) {
        case ActionTypes.CHANGE_ALL_ERRORS_LOADER:
            return {
                ...state,
                isErrorPageLoading: action.isLoading,
            };
        case ActionTypes.SAVE_ALL_ERRORS:
            return {
                ...state,
                errorPagesResult: action.result
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            }
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            }
        default:
            break;
    }
    return state;
}
export default ErrorPgeForAdminReducer;