import { Radar, Tooltip } from "@ant-design/plots";
import { List, Spin } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BmProject } from "../../../Models/Responses/ProfileResponse";
import { ISALever, ISASublever } from "../../../Models/SelfAssessment/ISelfAssessmentView";
import { IGeneralState, ISelfAssessmentState, ISelfAssessmentToolState } from "../../../Redux/reducers/IGeneralState";
import { formatValue, groupBy, transformId } from "../../../Utils/helperFunctions";
import ChartControls from "../ChartControls/ChartControls";
import "./radarchart.css";
import { t } from "i18next";


interface ChartDetails {
  leverLevel: number,
  leverId: number,
  leverCode: string;
  chartTitle: string;
  chartType: string;
  chartReferences: IradarDataElement[];
};

interface ChartValueForProject {
  name: string; //project name
  value: IradarDataElement[]; //IradarDataElement without peer attribute
  isTarget: boolean;
  isComplete: boolean;
};

interface ChartDetailsAndValues {
  chartDetails: ChartDetails;
  chartValues: ChartValueForProject[];
};
interface Iprops {

  showChartDetails: boolean;

  height: number;
  width: number;
  padding?: number[];
  tooltip: false | Tooltip;
  radarIcon: any;
  axisFontSize: number;
  leverNumber: string;
  isSmall: boolean;
  //
  currentProject: BmProject;
  projects: BmProject[],
  //currentUserId: number;

  //props for ChartControls
  showChartControls: boolean;
  showDownloadBtn?: boolean;
  showSelectCheckBox?: boolean;
  showSwitchRadarBar?: boolean;
  chartType?: string;
  switchChart?: () => void;
  selectChart?: (chartDivId: string, add: boolean) => void;
  selectedCharts?: string[];
  bordered?: boolean;
  selfAssessment: ISelfAssessmentToolState;
  //Tell higher level component with the chart title (div id)
  informChartDetailsAndValuesUp?: (chartDetailsAndValues: ChartDetailsAndValues) => void,
}

interface IradarDataElement {
  leverId: number,
  label: string;
  peer?: string;
  value: number;
}

// const mapToInitialIradarDataElementArr = (projects: BmProject[], leverNumber: string): IradarDataElement[] => {
//   if (!(projects?.length > 0))
//     return [];
//   let dataArr = (leverNumber === '0.') ?
//     projects[0].leversData :
//     projects[0].leversData?.filter(lever => lever.number === leverNumber)[0]?.subLevers;

//   return dataArr?.map(element => {// initial average value
//     return {
//       leverId: element.id,
//       label: element.number + " " + element.name,// name from Api should be used to exploite backend localization
//       peer: 'Others',
//       value: 0
//     };
//   });
// };

const getChartDetailsAndValues = (selfAssessmenet: ISALever[], projects: BmProject[], leverNumber: string, currentProject: BmProject) => {
  let chartValuesForProjects: ChartValueForProject[] = [];
  // currentProject.bM_levers_ks.includes(p.id) || currentProject.bM_levers_xs.includes(p.id) 
  let relatedProjects = projects?.filter(p => currentProject?.otherBmProjects?.filter(c => c.isTargetLever == true || c.isTargetLever == false).map(c => { return c.id }).includes(p.id) || p.id === currentProject.id);
  relatedProjects?.forEach(p => {
    let chartValueFor_p: ChartValueForProject;
    let dataArr = (leverNumber === '0.') ?
      p?.leversData :
      p?.leversData?.find(lever => lever.number === leverNumber)?.subLevers;

    let projectRadarData: IradarDataElement[] = dataArr?.map(element => {
      return {
        leverId: element.id,
        label: element.number + " " + element.name,
        value: element.kpiValue
      };
    });

    chartValueFor_p = {
      name: p.project_name,
      value: projectRadarData,
      isTarget: (p.id === currentProject.id) || (currentProject?.otherBmProjects.filter(c => c.isTargetLever == true).map(c => { return c.id })?.includes(p.id)),
      isComplete: p.isComplete
    };
    chartValuesForProjects.push(chartValueFor_p);
  });

  return {
    chartDetails: {
      leverLevel: (leverNumber === '0.') ? 0 : 1,
      leverId: (leverNumber === '0.') ? 0 : currentProject?.leversData?.find(lever => lever.number === leverNumber)?.id,
      leverCode: leverNumber,
      chartTitle: getChartTitle(currentProject, leverNumber),
      chartType: 'Radar',
      chartReferences: getReferenceValuesForLabel(selfAssessmenet, currentProject, leverNumber)
    },
    chartValues: chartValuesForProjects
  };

};

const averageValuesReducer = (projects: BmProject[], leverNumber: string, peer: string): IradarDataElement[] => {

  let projectsRadarData: IradarDataElement[] = [];

  projects.forEach(p => {

    let dataArr = (leverNumber === '0.') ?
      p?.leversData :
      p?.leversData?.find(lever => lever.number === leverNumber)?.subLevers;

    let projectRadarData = dataArr?.map(element => {
      return {
        leverId: element.id,
        label: element.number + " " + element.name,
        peer: peer,
        value: element.kpiValue
      };
    });
    if (projectRadarData)
      projectsRadarData?.push(...projectRadarData);

  });

  let projectsRadarDataGroupedByLabel = groupBy(projectsRadarData, 'label');

  let labels = Object.keys(projectsRadarDataGroupedByLabel);

  return labels.map((label, idx) => {
    if (!(projectsRadarDataGroupedByLabel[label]?.length > 0)) {
      return {
        leverId: 0,
        label: label,
        peer: peer,
        value: 0
      }
    };

    return {
      leverId: projectsRadarDataGroupedByLabel[label][0].leverId,
      label: label,
      peer: peer,
      value: projectsRadarDataGroupedByLabel[label]
        .map(element => element.value)
        .reduce((a, b) => a + b) / projectsRadarDataGroupedByLabel[label].length
    }
  });
}

const getReferenceValuesForLabel = (selfAssessmenet: ISALever[], currentProject: BmProject, leverNumber: string): IradarDataElement[] => {
  //console.log('currentProject_ radar selfAssessmenet: ', selfAssessmenet);

  let dataArr: ISALever[] | ISASublever[] = (leverNumber === '0.') ?
    selfAssessmenet :
    selfAssessmenet?.find(lever => lever.serialNumber === leverNumber)?.sublevers;

  return dataArr?.map((element: ISALever | ISASublever) => {
    switch (element.serialNumber) {
      case '1.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '2.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '3.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '4.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '5.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      //
      case '1.1.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '1.2.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '1.3.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '1.4.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '1.5.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      //
      case '2.1.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '2.2.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }

      case '2.3.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '2.4.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '2.5.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      //

      case '3.1.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '3.2.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '3.3.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '3.4.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '3.5.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      //
      case '4.1.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '4.2.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '4.3.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '4.4.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '4.5.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      //       
      case '5.1.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '5.2.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '5.3.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '5.4.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      case '5.5.':
        return {
          leverId: element.id,
          label: element.name,
          peer: "Reference",
          value: element.referenceValue * 100
        }
      default:
        break;
    }
  });
};

const getChartTitle = (currentProject: BmProject, leverNumber: string): string => {
  if (!currentProject) return;
  return (
    leverNumber === '0.' ? t('Stellhebel').toUpperCase() :
      currentProject.leversData?.find(lever => lever.number === leverNumber)?.number +
      " " +
      currentProject.leversData?.find(lever => lever.number === leverNumber)?.name.toUpperCase()
  );
}



const AsupRadarChart_Ant = (props: Iprops) => {

  let [data, setData] = useState([]);
  let [Title, setTitle] = useState('');

  useEffect(() => {
    let bmKsProjects = props.projects?.filter(p => props.currentProject?.otherBmProjects?.filter(c => c.isTargetLever == true).map(c => { return c.id })?.includes(p.id)) ?? [];
    let bmXsProjects = props.projects?.filter(p => props.currentProject?.otherBmProjects?.filter(c => c.isTargetLever == false).map(c => { return c.id })?.includes(p.id)) ?? [];
    //let othersProjects = [...bmKsProjects, ...bmXsProjects];

    let ksAveageVlues: IradarDataElement[] = averageValuesReducer([...bmKsProjects, props.currentProject], props.leverNumber, 'Ks_average');//getksAveageVlues(props.currentProject, props.leverNumber);

    var xsAveageVlues: IradarDataElement[] = averageValuesReducer(bmXsProjects, props.leverNumber, 'Xs_average');//averageOthersValues(othersProjects, props.leverNumber);

    console.log("props.selfAssessment", props.selfAssessment);
    let referencesValueForLabel: IradarDataElement[] = getReferenceValuesForLabel(props.selfAssessment?.viewResult?.levers, props.currentProject, props.leverNumber);
    console.log("referencesValueForLabel: ", referencesValueForLabel);
    

    let radarData = [];
    if (ksAveageVlues) {
      radarData = [...ksAveageVlues];
    }
    if (xsAveageVlues) {
      radarData = [...radarData, ...xsAveageVlues];
    }
    if (referencesValueForLabel) {
      radarData = [...radarData, ...referencesValueForLabel];
    }
    let Title = getChartTitle(props.currentProject, props.leverNumber);


    if (props.informChartDetailsAndValuesUp) {
      let chartDetailsAndValues = getChartDetailsAndValues(props.selfAssessment?.viewResult?.levers, props.projects, props.leverNumber, props.currentProject);
      //console.log('chartDetailsAndValues: ', chartDetailsAndValues);
      props.informChartDetailsAndValuesUp(chartDetailsAndValues);
    }

    setTitle(Title);
    setData(radarData);


  }, [props.currentProject, props.projects, props.selfAssessment]);

  const config = {
    meta: {
      value: {
        alias: 'value',
        nice: true,
        formatter: (v: any) => formatValue(v),
        values: ['0', '0.25', '0.5', '0.75', '1'],
        range: [0, 1],
      },
      label: {
        alias: 'label',
        nice: true,
      },
    },
    data,
    xField: "label",
    yField: "value",
    tooltip: props.tooltip,
    colorField: "peer",
    seriesField: "peer",
    renderer: "svg" as const,
    legend: false as const,
    //autofit:true,
    height: props.height,
    width: props.height,
    padding: props.padding,
    xAxis: {
      line: null,
      tickLine: null,
      //tickCount: 4,
      lineWidth: 2,
      label: props.isSmall ? false : {
        style: {
          fill: "#ccc",
          fontSize: props.axisFontSize,
          textBaseline: 'alphabetic',
        },
        //content: "string",
        rotate: false,
        //type: 'outer ',
        nice: true,
        formatter: (text: any, item, index) => {
          //   console.log(text, item, index);
          //   let temp: string[] = text.split(" ");
          //   let res = '';
          //   temp.map(t=>{
          //     res = res + `${t}\n\t`;// ` <tspan>${t}</tspan>`
          //   });
          // return res;
          return text;
        },
      },
      grid: {
        line: {
          type: "line",
          style: {
            // lineDash: null,
            // stroke: "#ccc",
            //strokeOpacity: 1, 
            lineWidth: 3,
          },
        },

        alternateColor: ["rgb(127 127 127)", "rgb(127 127 127)"],
      },
    },
    yAxis: {
      label: false,
      line: null,
      tick: '',
      tickLine: null,
      tickCount: 5,
      max: 100,
      // label: {
      //   style: {
      //     fill: "#ccc",
      //   },
      // },
      grid: {
        line: {
          type: "line",
          style: {
            lineWidth: 2,
          },
        },
      },
    },

    lineStyle: ({ peer }) => {
      if (peer == "Reference") {
        return {
          stroke: "#000000",
          lineDash: [10, 5],
          lineWidth: 2,
        };
      }
      if ((peer != "Reference") && (peer != 'Xs_average')) {
        return {
          fill: "#ffffff",
          fillOpacity: 0.4,
          stroke: "#000000",
          lineWidth: 2,
        };
      }
      if (peer == "Xs_average") {
        return {
          stroke: "#F9DC4C",
          lineWidth: 2,
        };
      }
    },

    point: {
      size: 2,
    },
  };
  return (
    <div className="controls-details-chart-container" style={{ 'border': props.bordered ? '1px solid #ccc' : '' }} >
      {props.showChartControls ? < ChartControls
        chartDivId={props.leverNumber}
        showDownloadBtn={props.showDownloadBtn}
        showSwitchRadarBar={props.showSwitchRadarBar}
        showSelectCheckBox={props.showSelectCheckBox}
        switchChart={props.switchChart}
        chartType={props.chartType}
        selectChart={props.selectChart}
        selectedCharts={props.selectedCharts}
      /> : null}
      {(data.length > 0) ? <div id={props.leverNumber} className="chartdetails-chart-container" >
        {props.showChartDetails ? (
          <div className="radar_title">
            <span className="radarTitle">
              {props.radarIcon ? props.radarIcon : null}
              {
                Title
              }
            </span>
            <span className="chartType">{"[Performance Index]"}</span>
          </div>
        ) : null}

        <div className="radar_chart" style={(props.isSmall) ? {} :
          {
            'width': props.width.toString() + 'px',
            'height': props.height.toString() + 'px'
          }
        }>
          <Radar {...config} />
        </div>
      </div> :
        <Spin size={props.isSmall ? "small" : "large"} />}
    </div>
  );
};



const mapStateToProps = (state: IGeneralState) => {
  return {
    currentProject: state.generalInfo.profileResponse?.currentProject,
    projects: state.generalInfo.allBenchmarkingProjects,
    selfAssessment: state.selfAssessementTool
  }
};
const mapDispatchToProps = (dispatch: any) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AsupRadarChart_Ant);