import React from 'react';
import { Col, Row } from "antd";
import StackedColumnChart from "../../../../EBITTool-V2/BasicCharts/StackedColumnChart";
import { EBITProjectBenchmarkData, EBITBenchmarkData } from '../../../../EBITTool-V2/Models/EBITBenchmarkData';
import { useTranslation } from "react-i18next";
interface Iprops {
    ebitBenchmarkingInfo: EBITBenchmarkData;
    projectName: string;
    setDisciplineName: (string) => void;
}
const DisciplinesVSBestPractice = (props: Iprops) => {
    const { t } = useTranslation();
    const benchmarkData = props.ebitBenchmarkingInfo;
    const GetProjectDiciplinesRates = () => {
        let currentProjectDisciplines: EBITProjectBenchmarkData = benchmarkData?.projectsBenchmarks.find(x => x.projectName === props.projectName);
        return currentProjectDisciplines?.projectDisciplinesBenchmark?.filter(s => s.color)?.map(x => {
            return {
                name: x.name,
                englishName: x.englishName,
                projectName: props.projectName,
                rate: x.rate,
                viewOrder: x.viewOrder,
                color: x.color
            }
        }
        );
    }
    const GetDiciplinesBestPractice = () => {
        return benchmarkData?.disciplinesBestPractices?.map(x => {
            let viewOrder = benchmarkData?.projectsBenchmarks[0]?.projectDisciplinesBenchmark?.find(s => s.name == x.name)?.viewOrder || -1;
            let color = benchmarkData?.projectsBenchmarks[0]?.projectDisciplinesBenchmark?.find(s => s.name == x.name)?.color;
            return {
                name: x.name,
                projectName: "Best Practices",
                englishName: x.englishName,
                rate: x.bestPractice?.value ?? 0,
                viewOrder: viewOrder,
                color: color,
            }
        }
        );

    }
    const SummaryDisciplinesNames = [t("Gesamte F&E-Kosten").toLowerCase(), t("Interdisziplinäre F&E Kosten").toLowerCase(), t("Originäre F&E Kosten").toLowerCase()]
    let allDiscipines = GetProjectDiciplinesRates()?.filter(x => !SummaryDisciplinesNames.includes(x.name.toLowerCase()) && !SummaryDisciplinesNames.includes(x.englishName.toLowerCase()));
    let allDisciplinesBestPractice = GetDiciplinesBestPractice()?.filter(x => !SummaryDisciplinesNames.includes(x.name.toLowerCase()) && !SummaryDisciplinesNames.includes(x.englishName.toLowerCase()));
    return (
        <div style={{ height: '72vh'}}>
            <Row>
                <Col span={24}>
                    <StackedColumnChart isPercent={true} isForFrontLoading={true} setDisciplinName={props.setDisciplineName} isTopLevelChart={false} chartTitle={t("Verteilung der F&E-Kosten")} topLevelTitle={t("Verteilung der originären")} data={allDiscipines} bestPracticeRates={allDisciplinesBestPractice} />
                </Col>
                
            </Row>
        </div>
    );
};

export default React.memo(DisciplinesVSBestPractice);