export interface ChangeProjectView {
    key: number; // same as id just for rnedering in react
    id: number;
    projectName: string;
    customerName: string;
    segment: string;
    sector: string;
    year: number;
}

export const ChangeProjectFilter = {
    projectName: {
        visible: true
    },
    customerName: {
        visible: true
    },
    sector: {
        visible: true
    },
    segment: {
        visible: true
    },
    year: {
        visible: true
    }
}
