import { Form, Input, Table } from 'antd';
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux';
import { MenuOutlined, TeamOutlined } from '@ant-design/icons';
//import { SortEnd, SortableContainer, SortableContainerProps, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { IDepartmentItem } from '../../../../Models/IDepartment';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import ColorPickerInput from '../../../../Components/ColorPickerInput';
import { RulesName } from '../../Rules/RulesValidation';
import AdminOperation from '../../../../Components/AdminOperation/AdminOperation';
import { deleteFirstLetter } from '../../../../Utils/helperFunctions';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import DepartmentUsersModal from './DepartmentUsersModal';
import { t } from 'i18next';
interface DataType {
    index: number;
    key: string;
    name: string;
    id: number;
    foreColor: string;
    backgroundColor: string;
    users: any[];
}
interface IProps {
    dataSource: DataType[];
    setDataSource: (data: DataType[]) => any;
    updateDepartment: (model: IDepartmentItem, customerId: number) => any;
    deleteDepartmentById: (departmentId: number, customerId: number) => any;
    updateOrderDepartments: (model: IDepartmentItem[], customerId: number) => any;
    bmProjectId: number;
    customerId: number;
    isFromBmProject: boolean;
}
// const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
//     <tr {...props} />
// ));
// const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
//     <tbody {...props} />
// ));
// const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const DepartmentBmProjectTable = (props: IProps) => {
    const [formValues, setFormValues] = useState<IDepartmentItem>()
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end

    const columns: any[] = [
        // {
        //     title: `${t("Sort")}`,
        //     dataIndex: 'sort',
        //     width: '10%',
        //     className: 'drag-visible',
        //     fixed: true,
        //     render: () => <DragHandle />,
        // },
        {
            key: "dep_name",
            title: `${t("Department")}`,
            dataIndex: 'name',
            fixed: true,
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='name'
                            rules={RulesName({ name: `${t("Department")}`,countChar:128 })}
                            initialValue={text}
                            hasFeedback>
                            <Input
                                autoFocus
                                onChange={(e) =>
                                    setFormValues({ ...formValues, name: (e.target.value)?.trimStart() })
                                }

                            />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("Department")}`, 'name', searchInput, handleSearch),
        },
        {
            key: "dep_foreColor",
            title: `${t("Text Color")}`,
            dataIndex: 'foreColor',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='foreColor'  >
                            <ColorPickerInput colorValue={formValues?.foreColor ?? text}
                                onChangeComplete={(color: any, event: any) => setFormValues({ ...formValues, foreColor: color?.hex })} />
                        </Form.Item>
                    )
                } else {
                    return <div style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        background: text,
                        marginLeft: "auto",
                        marginRight: "auto",
                        border: "0.5px solid #ccc"
                    }}>
                    </div >
                }
            },
        }, {
            key: "dep_backgroundColor",
            title: `${t("Background Color")}`,
            dataIndex: 'backgroundColor',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='backgroundColor'   >
                            <ColorPickerInput colorValue={formValues?.backgroundColor ?? text}
                                onChangeComplete={(color: any, event: any) => setFormValues({ ...formValues, backgroundColor: color?.hex })} />
                        </Form.Item>
                    )
                } else {
                    return <div style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        background: text,
                        marginLeft: "auto",
                        marginRight: "auto",
                        border: "0.5px solid #ccc"
                    }}>
                    </div >
                }
            },
        },

        {
            title: `${t("Actions")}`,
            dataIndex: '',
            width: "20%",
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                return <AdminOperation
                    deleteId={record.id}
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                    onDelete={deleteDepartment}
                    onSave={save}
                />
            },
        },
        // {
        //     title: `${t("Users")}`,
        //     dataIndex: "",
        //     render: (text, record: any) => {
        //         return <DepartmentUsersModal 
        //         isFromBmProject={props.isFromBmProject}
        //          usersData={record?.users}
        //          customerId={props.customerId}
        //           bmProjectId={props.bmProjectId}
        //            departmentId={record?.id}
                   
        //            />
        //     }
        // },

    ];
    const isEditing = (record: IDepartmentItem) => record.key === editingKey;
    const edit = (record: Partial<IDepartmentItem> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
        setFormValues({ ...formValues, backgroundColor: null, foreColor: null })

    };
    const save = async (key: any) => {
        try {
            await form.validateFields()
            const index = props.dataSource.findIndex(item => item.key === key);
            if (index > -1) {
                const item = props.dataSource[index];
                const departmentUpdate: IDepartmentItem = {
                    viewOrder: formValues.viewOrder ?? item.index,
                    id: item.id,
                    name: formValues?.name ?? item.name,
                    bmProjectId: props.bmProjectId,
                    foreColor: formValues.foreColor ? deleteFirstLetter(formValues.foreColor) : deleteFirstLetter(item.foreColor),
                    backgroundColor: formValues.backgroundColor ? deleteFirstLetter(formValues.backgroundColor) : deleteFirstLetter(item.backgroundColor),
                    isOriginal: formValues.isOriginal
                };
                await props.updateDepartment(departmentUpdate, props.customerId);
                setEditingKey('');
                setFormValues({ ...formValues, backgroundColor: null, foreColor: null })
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }

    }
    const deleteDepartment = async (id: any) => {
        try {
            await props.deleteDepartmentById(id, props.customerId)
        } catch (errInfo) {
            console.log('deleted Failed:', errInfo);
        }
    }
    // const onSortEnd = async ({ oldIndex, newIndex }: SortEnd) => {
    //     // console.log("oldIndex, newIndex ", oldIndex, newIndex);
    //     if (oldIndex !== newIndex) {
    //         let newData = arrayMoveImmutable(props.dataSource.slice(), oldIndex, newIndex).filter(
    //             (el: DataType) => !!el,
    //         );
    //         // let itemOne = props.dataSource?.find(d => d.index === oldIndex);
    //         // let itemTwo = props.dataSource?.find(d => d.index === newIndex);
    //         // console.log(itemOne);
    //         // console.log(itemTwo);
    //         //console.log('Sorted items: ', newData);
    //         newData = newData.map((x, i) => {
    //             x.index = i;
    //             return x;
    //         })
    //         // console.log('Sorted items02: ', newData);
    //         props.updateOrderDepartments(newData.map(item => {
    //             return {
    //                 viewOrder: item.index,
    //                 id: item.id,
    //                 name: item.name,
    //                 bmProjectId: props.bmProjectId,
    //                 foreColor: "",
    //                 backgroundColor: "",
    //             };
    //         }), props.customerId);
    //         props.setDataSource(newData);
    //     }

    // };

    // const DraggableContainer = (props: SortableContainerProps) => (
    //     <SortableBody
    //         useDragHandle
    //         disableAutoscroll
    //         helperClass="row-dragging"
    //         onSortEnd={onSortEnd}
    //         {...props}
    //     />
    // );

    // const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    //     //console.log("restProps['data-row-key']", restProps['data-row-key'], restProps);
    //     const index = props.dataSource.findIndex(x => x.index === restProps['data-row-key']);
    //     return <SortableItem index={index} {...restProps} />;
    // };
    return (
        <Form form={form} key="Dep_form" >
            <Table
                key='Dep_table'
                getPopupContainer={() => document.getElementById("app-layout")}
                bordered
                dataSource={props.dataSource}
                columns={columns}
                className='ia_config_table'
                scroll={{ y: "calc(100vh - 400px)" }}
                rowKey='index'
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 150],
                    total: props.dataSource.length,
                    showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                    showSizeChanger: true,
                    locale: { items_per_page: `/ ${t("Page")}` },
                }}
                // components={editingKey ? null : ({
                //     body: {
                //         wrapper: DraggableContainer,
                //         row: DraggableBodyRow,
                //     },
                // })}
                locale={{
                    triggerDesc: t('Click to sort descending'),
                    triggerAsc: t('Click to sort ascending'),
                    cancelSort: t('Click to cancel sorting')
                }}



            />
        </Form>
    );
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDepartment: (model: IDepartmentItem, customerId: number) => dispatch({ type: ActionTypes.UPDATE_DEPARTMENT_BY_BM_CUSTOMER, model, customerId }),
        updateOrderDepartments: (model: IDepartmentItem[], customerId: number) => dispatch({ type: ActionTypes.UPDATE_Order_DEPARTMENT_BY_BM_PROJECT, model, customerId }),
        deleteDepartmentById: (departmentId: number, customerId: number) => dispatch({ type: ActionTypes.DELETE_DEPARTMENT_BY_BM_CUSTOMER, departmentId, customerId }),
    };
};
export default connect(null, mapDispatchToProps)(DepartmentBmProjectTable);
