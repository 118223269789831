import { appAdminRouters, appPagesInformationList } from "../Router/router.config";

class Utils {

    getPageTitle = (pathname: string) => {
        const routes = appAdminRouters.filter(route => route.key === pathname);
        const localizedAppName = "AS&P" //L('AppName');
        if (!routes || routes.length === 0) {
            return localizedAppName;
        }

        return (routes[0] as any).label + ' | ' + localizedAppName;
    };

    getPageHeaderTitle = (pathname: string) => {
        const routes = appAdminRouters.filter(route => route.key === pathname);
        //console.log('getPageHeaderTitle: ', routes);
        if (!routes || routes.length === 0) {
            return "";
        }
        //console.log('getPageHeaderTitle: ', routes[0]);
        return (routes[0] as any).label;
    };
    getPageTitleByPath = (pathname: string) => {
        const routes = appPagesInformationList.filter(route => route.key === pathname);
        //console.log('getPageHeaderTitle: ', routes);
        if (!routes || routes.length === 0) {
            return "";
        }
        //console.log('getPageHeaderTitle: ', routes[0]);
        return (routes[0] as any).label;
    };

    getHelpPageCodeByPath = (pathname: string) => {
        const routes = appPagesInformationList.filter(route => route.key === pathname);
        if (!routes || routes.length === 0) {
            return "";
        }
        return (routes[0] as any).code;
    };

    checkAccess = (pageCode: string, permissions: string[]) => {
        if (pageCode) {
            console.log("pageCode, permissions ", pageCode, permissions);
            if (permissions.includes(pageCode)) {
                return true;
            }

            return false;
        }
        return true;
    }

    getRoute = (path: string): any => {
        return appAdminRouters.filter(route => route.key === path)[0];
    };
}
export default new Utils();