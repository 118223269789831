import React, { useState } from 'react'
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { ICompleteBmProjectDetails } from '../../../Models/IBMProjectItem';
import { Button, Modal, Tooltip } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import DetailsTable from './DetailsTable';
import { t } from 'i18next';
interface IProps {
    details: ICompleteBmProjectDetails[];
    isLoading: boolean;
    getDetails: (model: any) => any;
    bmProjectId: number;
    bmProjectName: string;
}
const DetailsCompletionBmProjectModal = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const detailsData: ICompleteBmProjectDetails[] = props.details?.map((d, index) => {
        return {
            key: `detail_${index}`,
            ...d
        }
    }) ?? [];

    const showModal = async () => {
        try {
            setIsModalOpen(true);
            await props.getDetails(props.bmProjectId)
        } catch (error) {
            console.log(error);
        }

    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <> <Tooltip title={`${t("Show Details Completion Bm Project")}`}>
            <Button onClick={showModal} >
                <DatabaseOutlined />{`${t("Details")}`}
            </Button>
        </Tooltip>
            <Modal title={`${t("Completion")} ( ${props.bmProjectName} ) ${t("Details")}`}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={[]}
                width={1200}
                getContainer={() => document.getElementById("app-layout")}>
                <DetailsTable details={detailsData} />
            </Modal >
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        details: state.detailsCompletion?.detailsResult,
        isLoading: state.detailsCompletion?.isDetailsLoading,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getDetails: (bmProjectId: number) => dispatch({ type: ActionTypes.GET_COMPLETION_DETAILS_BM_PROJECT, bmProjectId })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailsCompletionBmProjectModal);