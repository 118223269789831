import { ActionTypes } from "../actionsTypes";
import { CustomerToolState } from "./IGeneralState";
const initialState: CustomerToolState = {
  isCustomerLoading: false,
  responseResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const CustomerReducer = (
  state = initialState,
  action: any
): CustomerToolState => { 
  switch (action.type) {
    case ActionTypes.CHANGE_CUSTOMER_LODAER:
      return {
        ...state,
        isCustomerLoading: action.isLoading,
      };
    case ActionTypes.SAVE_CUSTOMERS:
      return {
        ...state,
        responseResult: action.result,
      };
      case ActionTypes.SetModalLoader:
        return {
            ...state,
            isModalLoading: action.result
        };
    case ActionTypes.SetModalResponse:
        return {
            ...state,
            modalResponse: action.result
        };
    default:
      break;
  }
  return state;
};

export default CustomerReducer;
