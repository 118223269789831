import { IDepartmentItem } from "../../Models/IDepartment";
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import DepartmentByBmProjectService from "../../Services/DepartmentByBmProjectService";

//TODO: to be deleted
function* OnGetDepartmentsByBmProject(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: true });
    let _result: ICommonResponse = yield DepartmentByBmProjectService.GetDepartmentsByBmProject(action.bmProjectId);
    if (!_result.isError) {
      let result = _result.result as IDepartmentItem[];
      yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_BM_PROJECT, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: false });
  } catch (error) {
    console.error("ERR in onGetCustomerDepartments: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onCreateDepartment(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultCreate: ICommonResponse = yield DepartmentByBmProjectService.CreateDepartmentByBmProject(
      action.model
    );
    if (!resultCreate.isError) {
      let _result: ICommonResponse = yield DepartmentByBmProjectService.GetDepartmentsByBmProject(action.bmProjectId);
      let result = _result.result as IDepartmentItem[];
      yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_BM_PROJECT, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
    }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onCreateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onUpdateDepartment(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: true });
    let resultUpdate: ICommonResponse = yield DepartmentByBmProjectService.UpdateDepartmentByBmProject(
      action.model
    );

    if (!resultUpdate.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
      let _result: ICommonResponse = yield DepartmentByBmProjectService.GetDepartmentsByBmProject(action.bmProjectId);
      let result = _result.result as IDepartmentItem[];
      yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_BM_PROJECT, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
    }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: false });
  } catch (error) {
    console.error("ERR in onUpdateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onUpdateOrderDepartments(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: true });
    let resultUpdate: ICommonResponse = yield DepartmentByBmProjectService.UpdateOrderDepartmentByBmProject(
      action.model
    );

    if (!resultUpdate.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
      let _result: ICommonResponse = yield DepartmentByBmProjectService.GetDepartmentsByBmProject(action.bmProjectId);
      let result = _result.result as IDepartmentItem[];
      yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_BM_PROJECT, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
    }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: false });
  } catch (error) {
    console.error("ERR in onUpdateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onDeleteDepartment(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: true });
    let resultDelete: ICommonResponse = yield DepartmentByBmProjectService.DeleteDepartmentByBmProject(
      action.departmentId
    );
    if (!resultDelete.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
      let _result: ICommonResponse = yield DepartmentByBmProjectService.GetDepartmentsByBmProject(action.bmProjectId);
      let result = _result.result as IDepartmentItem[];
      yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_BM_PROJECT, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
    }
    yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER, isLoading: false });
  } catch (error) {
    console.error("ERR in onUpdateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
// function* watchGetDepartmentsByBmProject() {
//   yield takeEvery(ActionTypes.GET_DEPARTMENTS_BY_BM_PROJECT, OnGetDepartmentsByBmProject);
// }
// function* watchCreateDepartment() {
//   yield takeEvery(ActionTypes.CREATE_DEPARTMENT_BY_BM_PROJECT, onCreateDepartment);
// }


// function* watchUpdateOrderDepartments() {
//   yield takeEvery(ActionTypes.UPDATE_Order_DEPARTMENT_BY_BM_PROJECT, onUpdateOrderDepartments);
// }

// function* watchUpdateDepartment() {
//   yield takeEvery(ActionTypes.UPDATE_DEPARTMENT_BY_BM_PROJECT, onUpdateDepartment);
// }
// function* watchDeleteDepartment() {
//   yield takeEvery(ActionTypes.DELETE_DEPARTMENT_BY_BM_PROJECT, onDeleteDepartment);
// }


export default function* DepartmentsSagas() {
  yield all([
    // watchGetDepartmentsByBmProject(),
    // // watchGetCustomerDepartments(),
    // watchCreateDepartment(),
    // watchUpdateDepartment(),
    // watchDeleteDepartment(),
    // watchUpdateOrderDepartments()
  ]);
}
