import { ToastMessages } from "../Enums/Messages";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";

export default class ProfileService {
    static async GetHomePage(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetHomeInformation)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async GetProjects(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.BM_Project)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async GetProjectsForEditLevers(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetProjectsForEditLevers)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async GetProjectsForEditEbit(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetProjectsForEditEbit)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async GetBenchmarkProjectsWithAllData(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetBenchmarkProjectsWithAllData)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

   
}