import { Table, Row, Col } from "antd";
import "./TableForPanel.scss";

const TableForPanel = (props) => {
  return (
    <Table
      key={'rnd_result'}
      // tableLayout="fixed"
      columns={props.columns}
      dataSource={props.rows}
      pagination={false}
      scroll={{ x: "max-content" }}
    />
  );
};

export default TableForPanel;
