import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IActivityProfileTemplate } from '../../../Models/IActivityManagement';
import { ActionTypes } from '../../../Redux/actionsTypes';
import ActivityProfileMngTable from './ActivityProfileMgmtTable';
import "./index.scss";
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { Spin, Space, Typography } from 'antd';
import ActivityProfileAddModal from './ActivityMngModal/ActivityProfileAddModal';
import { t } from 'i18next';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
interface IProps {
    isLoading: boolean;
    allActivityProfiles: IActivityProfileTemplate[];
    functionTypes: any[];
    getAllActivityProfileForAdmin: () => any;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
}

const ActivityMng = (props: IProps) => {
    useEffect(() => { props.getAllActivityProfileForAdmin() }, []);
    const allActivityProfileData: IActivityProfileTemplate[] = props.allActivityProfiles?.map(p => {
        return {
            key: `profile_${p.id}`,
            ...p
        }
    }) ?? [];

    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
            <Space direction="vertical" size="small" >
                <Typography.Title level={2}>{`${t("Activity Profiles Management")}`}</Typography.Title>
                <ActivityProfileAddModal
                    isModalLoading={props.isModalLoading}
                    modalResponse={props.modalResponse}
                />
                <ActivityProfileMngTable
                    allActivityProfileData={allActivityProfileData}
                    isModalLoading={props.isModalLoading}
                    modalResponse={props.modalResponse}
                    functionTypes={props.functionTypes}
                />
                </Space>
            </div>
        </Spin>
    )
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        allActivityProfiles: state.activiyProfileMng.ActivityProfilesForAdminResult,
        isLoading: state.activiyProfileMng.isActivityForAdminLoading,
        isModalLoading: state.activiyProfileMng.isModalLoading,
        modalResponse: state.activiyProfileMng.modalResponse,
        functionTypes: state.generalInfo.profileResponse?.generalSettings?.functionTypeList,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllActivityProfileForAdmin: () => dispatch({ type: ActionTypes.GET_ACTIVITY_FOR_ADMIN }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityMng);
