import { Avatar, Button, Form, Input, List, Comment, Tooltip, Badge, Tag, Card, Tabs, Row, Col, Descriptions, Space, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IGeneralState, InterfaceAnalysisState } from "../../../Redux/reducers/IGeneralState";
import { formatValue } from "../../../Utils/helperFunctions";
import VotesModal from "./widgets/VotesModal";
import { IVote, IaVoteReviewDto } from "../../../Models/InterfaceAnalysis/IAItems";
import TextArea from "antd/lib/input/TextArea";
import { t } from "i18next";
import { ActionTypes } from "../../../Redux/actionsTypes";
import ReviewForm from "./widgets/ReviewForm";
import _ from "lodash";
import { VotingProcessStatusEnum } from "../../../Enums/VotingProcessStatusEnum";
interface IProps {
    result: InterfaceAnalysisState;
    reviewAnswer: (model: IaVoteReviewDto) => any;
}

interface DataType {
    key: React.Key;
    id: number;
    qValue: number;
    rValue: number;
    kValue: number;
    name: string;
}

const whiteColor = "#FFFFFF";
const blackColor = "#0C0C0C";
const lightBlueColor = "#F2F6FF";
const redColor = "#FC3E40";
const greenColor = "#01AF50";
const yellowColor = "#F7DA46";
const titleCellConfig = (record, rowIndex) => ({
    style: {
        backgroundColor: record["backColor"],
        color: record["foreColor"]
    },
});

const rateHeaderCellConfig = (column) => ({
    style: {
        backgroundColor: lightBlueColor,
        color: blackColor
    },
});
const handleRateValueCell = (record, rowIndex, keyValue) => {
    let val = null;

    Object.keys(record).forEach(key => {
        if (key == keyValue) {
            val = record[key] ?? null;
        }
    });
    if (val == undefined || val == null || val < 0) {
        if (record.key == "total_avg") {
            return {
                colSpan: 3, style: {
                    backgroundColor: lightBlueColor,
                    color: blackColor
                }
            }
        };
        return ({
            style: {
                backgroundColor: lightBlueColor,
                color: blackColor
            }
        });
    } else {
        if (record.key == "total_avg") {
            return {
                colSpan: 3, style: {
                    backgroundColor: (val == 0) ? whiteColor : (val < 3) ? redColor : (val < 8) ? yellowColor : greenColor,
                    color: blackColor,
                }
            }
        };
        return ({
            style: {
                backgroundColor: (val == 0) ? whiteColor : (val < 3) ? redColor : (val < 8) ? yellowColor : greenColor,
                color: blackColor,
            }
        });
    }
};

const handeRateValueRender = (text, record, index, toDiscId) => {
    if (record.key == "total_avg" || record.key == "avg_breaks") {
        if (text == "" || text == undefined || text == null || Number.parseFloat(text) < 0) return <></>;
        return <strong>{formatValue(Number.parseFloat(text))}</strong>;
    }
    if (text == "" || text == undefined || text == null || Number.parseFloat(text) < 0) return <></>;
    else return <VotesModal fromDiscId={record.fromDisciplineId} toDiscId={toDiscId} value={text} key={`vote_modal_${index}_${record.fromDisciplineId}_${toDiscId}`} />;
};

function IAReviewList(props: IProps) {
    const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
    useEffect(() => {
        prepareColumns();
    }, [props.result?.metaData?.disciplines]);

    const prepareColumns = () => {
        let _cols: ColumnsType<DataType> = [
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
                width: 160,
                fixed: 'left',
                className: "prepare_col_name",
                onCell: titleCellConfig
            }
        ];
        props.result?.metaData?.disciplines?.filter(c => c.inColumn == null || c.inColumn == true).map(disc => {
            _cols.push({
                title: disc.name,
                className: "prepare_col_disc",
                onHeaderCell: (column) => ({
                    style: {
                        backgroundColor: disc.backColor,
                        color: disc.frontendColor,
                    },
                }), //disciplineHeaderCellConfig,
                children: [
                    {
                        title: <Tooltip title={`${t("Quality of work results in cooperation with the respective interface")}`}>
                            {`Q`}
                        </Tooltip>,
                        dataIndex: `qValue_${disc.id}`,
                        key: `Q_${disc.id}`,
                        align: 'center',
                        width: 30,
                        onHeaderCell: rateHeaderCellConfig,

                        onCell: (record, rowIndex) => {
                            if (record.key == "total_avg") {
                                return {
                                    colSpan: 0
                                }
                            };
                            return handleRateValueCell(record, rowIndex, `qValue_${disc.id}`);
                        },
                        render: (text, record, index) => {
                            return handeRateValueRender(text, record, index, disc.id);
                        }
                    },
                    {
                        title: <Tooltip title={`${t("Speed of reaction during cooperation with the respective interface.")}`}>
                            {`R`}
                        </Tooltip>,
                        dataIndex: `rValue_${disc.id}`,
                        key: `R_${disc.id}`,
                        align: 'center',
                        width: 30,
                        onHeaderCell: rateHeaderCellConfig,
                        onCell: (record, rowIndex) => {
                            if (record.key == "total_avg") { return { colSpan: 0 } };
                            return handleRateValueCell(record, rowIndex, `rValue_${disc.id}`);
                        },
                        render: (text, record, index) => {
                            return handeRateValueRender(text, record, index, disc.id);
                        }
                    },
                    {
                        title: <Tooltip title={`${t("Communication during cooperation with the respective interface.")}`}>
                            {`K`}
                        </Tooltip>,
                        dataIndex: `kValue_${disc.id}`,
                        key: `K_${disc.id}`,
                        align: 'center',
                        width: 30,
                        onHeaderCell: rateHeaderCellConfig,
                        onCell: (record, rowIndex) => {
                            return handleRateValueCell(record, rowIndex, `kValue_${disc.id}`);
                        },
                        render: (text, record, index) => {
                            return handeRateValueRender(text, record, index, disc.id);
                        }
                    }
                ]
            });
        });
        setColumns(_cols);
    }


    const convertAnswersToTabs = (votes: IVote[]) => {
        let items = [];
        let grouped = _.groupBy(votes, 'parentId');
        //console.log("grouped parentId", grouped);
        Object.entries(grouped).map(([grpId, grpVotes], indx) => {
            if (grpVotes && grpVotes?.length > 0) {
                let needProcess2 = grpVotes?.filter(c => c.review == null && !(c.noPreviousInteraction || (c.kRate == null && c.qRate == null && c.rRate == null)));
                items.push({
                    label: <Badge dot={needProcess2?.length > 0}>
                        <><b>{`${t("Participant Answer:")} `}</b>{`#${indx + 1}`}</>
                    </Badge>,
                    key: `ansewer_to_dep_${grpId}`,
                    children:
                        <List
                            itemLayout="vertical"
                            size="small"
                            dataSource={grpVotes}
                            renderItem={(item, index) => (
                                <List.Item
                                    key={`${item.toDisciplineId}_${item.fromDisciplineId}_${grpId}`}
                                /* actions={(item.noPreviousInteraction) ? [<Badge status="warning" text={`${t("No Previous Interaction")}`} />] : []} */
                                >
                                    <Comment

                                        content={<>
                                            <Row>
                                                <Col span={24}>
                                                    <b>{`${t("Department")}:`}</b>{` ${item.toDisciplineName}`}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <b>{`${t("Participant Comment:")} `}</b>{(item.comment == null || item.comment == "") ? `${t("No Comment")}` : item.comment}
                                                </Col>
                                            </Row>
                                        </>}
                                        datetime={(item.noPreviousInteraction) ? [<Badge status="warning" text={`${t("No Previous Interaction")}`} />] : []}
                                    />
                                    {<ReviewForm key={`review_${item.id}`} vote={item} onReview={(model: IaVoteReviewDto) => props.reviewAnswer(model)} />}
                                </List.Item>
                            )
                            }
                        />

                });
            }
        });
        return <Tabs tabPosition="top" items={items} />;
    }


    const getDepartmentsCards = (round) => {
        let votes = round?.votes.sort(c => c.toDisciplineId).sort(c => c.fromDisciplineId);
        let items = [];
        props.result?.metaData.disciplines.sort(c => c.order).map(dis => {
            let votesSinglePage: IVote[] = votes?.filter(c => c.fromDisciplineId == dis.id);
            let needProcess = votesSinglePage?.filter(c => c.review == null && !(c.noPreviousInteraction || (c.kRate == null && c.qRate == null && c.rRate == null)));
            let grouped = _.groupBy(needProcess, 'parentId');
            if (votesSinglePage && votesSinglePage?.length > 0) {
                items.push({
                    label: <Badge size="small" count={Object.entries(grouped)?.length}>
                        {dis.name}
                    </Badge>,
                    key: `ansewer_from_dep_${dis.id}`,
                    children: convertAnswersToTabs(votesSinglePage)
                });
            }
        });
        return <Tabs tabPosition="left" items={items} />;
    }
    const roundsTabs = () => {
        let items = [];
        props.result?.rounds?.rounds?.map((round, index) => {
            items.push({
                label: `${t("Round")} ${index + 1}`,
                key: `round_${index}`,
                children: <>
                    <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                        <Col span={16}>
                            <Descriptions layout="horizontal">
                                <Descriptions.Item label={`${t("Started at")}`}>{round?.startAt}</Descriptions.Item>
                                <Descriptions.Item label={`${t("Ended at")}`}>{round?.endAt}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>

                    <Card title={`${t("Answers for review")} (${round?.totalReviewedAnswered}/${round?.totaAnswersForReview})`} className="current_project_card">
                        {getDepartmentsCards(round)}
                    </Card>
                </>
            });
        })

        return <Spin spinning={props.result?.isLoadingRounds}><Tabs tabPosition="top" items={items} /></Spin>;
    }

    return roundsTabs();
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        result: state.interfaceAnalysis
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        reviewAnswer: (model: IaVoteReviewDto) => dispatch({ type: ActionTypes.ReviewAnswerIA, model: model })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IAReviewList);