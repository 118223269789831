import { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { put } from "redux-saga/effects";
import { ILever } from "../../Models/Levers/Items";
import { ActionTypes } from "../../Redux/actionsTypes";
import {
  IEbitToolState,
  IGeneralState,
} from "../../Redux/reducers/IGeneralState";
import CurrentProject from "../BMProjectsLists/BMProjects_CurrentProject/CurrentProject";
import DProjects from "./DProjects/DProjects";
import EBITToolV2 from "./EBITTool-V2";
import IATool from "./InterfaceAnalysis/IATool";
import SATool from "./SelfAssessment/SATool";
import LeversTool from "./LeversTool/LeversTool";
import FrontLoadingTabs from "./FrontLoading/FrontLoadingTabs/FrontLoadingTabs";
import { BmProject } from "../../Models/Responses/ProfileResponse";
import CommingSoon from "../../Components/CommingSoon";
import { IAsupAppConfig } from "../../Models/Requests/IAsupAppConfig";
import ActivityTool from "./ActivityTool/ActivityTool";
import PeopleSurvey from "./PeopleSurvey/PeopleSurvey";
import LeadTimeAnalysis from "./LeadTimeAnalysis/LeadTimeAnalysis";
var FormulaParser = require("hot-formula-parser").Parser;
var parser = new FormulaParser();
interface AllToolsProps {
  leverData: ILever[];
  currentProject: BmProject;
  //updateLeversInCurrentProject: (result: ILever[]) => any;
  getLeversProgress: () => any;
  getSubleversWithEbit: () => any;
  changeCurrentHelpPageCode: (pageName: string) => any;
  ebitResult: IEbitToolState;
}
const asupAppConfig = (window as any).globalTS as IAsupAppConfig;
function AllTools(props: AllToolsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tool = searchParams.get("tool");
  useEffect(() => {
    props.getSubleversWithEbit();
    props.getLeversProgress();
  }, [props.ebitResult?.dProjectForEbit]);
  useEffect(() => {
    props.getSubleversWithEbit();
    props.getLeversProgress();
  }, [props.leverData]);
  useEffect(() => {
    props.getSubleversWithEbit();
    props.getLeversProgress();
  }, [props.currentProject]);

  switch (tool) {
    case "levers":
      return asupAppConfig.isLeversActive ? <LeversTool /> : <CommingSoon />;
    case "ebit":
      return asupAppConfig.isEbitActive ? <EBITToolV2 /> : <CommingSoon />;
    case "dprojects":
      return <DProjects />;
    case "interface":
      return asupAppConfig.isInterfaceActive ? <IATool /> : <CommingSoon />;
    case "self-assessment":
      return asupAppConfig.isSelfAssessmenetActive ? (
        <SATool />
      ) : (
        <CommingSoon />
      );
    case "frontloading":
      return asupAppConfig.isFrontloadingActive ? (
        <FrontLoadingTabs />
      ) : (
        <CommingSoon />
      );
    case "activity":
      return asupAppConfig.isActivityActive ? (
        <ActivityTool />
      ) : (
        <CommingSoon />
      );
    case "people-survey":
      return <PeopleSurvey />; // asupAppConfig.isPeopleSurvayActive? <PeopleSurvey /> : <CommingSoon />;   
    case "lead-time-analysis":
      return <LeadTimeAnalysis />;
    default:
      return <CurrentProject />;
  }
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    // isLoading: state.leverTool.isLeverLoading,
    leverData: state.leverTool.leverData,
    currentProject: state.generalInfo?.profileResponse?.currentProject,
    // selectedQuestions: state.leverTool.selectedQuestions,
    profileInfo: state.generalInfo.profileResponse,
    ebitResult: state.ebit,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    //updateLeversInCurrentProject: (result: ILever[]) => dispatch({ type: ActionTypes.UpdateLeversInCurrentProject, result: result }),
    getLeversProgress: () =>
      dispatch({ type: ActionTypes.GetLeversProgressValue }),
    getSubleversWithEbit: () =>
      dispatch({ type: ActionTypes.GetLeversWithEbitChart }),
    changeCurrentHelpPageCode: (pageName: string) =>
      dispatch({ type: ActionTypes.ChangeHelpPageCode, result: pageName }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTools);
