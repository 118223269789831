import { Tabs, Collapse, Slider, Typography, Row, Col } from "antd";
import RD from "../../../Components/RD";
import CostTypes from "./CostType/index";
import { connect } from "react-redux";
import { BmProject } from "../../../Models/Responses/ProfileResponse";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import './EBITTabs-V2.scss';
import EstimateSection from "./EstimateQuestions/EstimateSection";
import EvaluationPanel from "./EvaluationPanel";
import EvaluationTopLevelPanel from './EvaluationTopLevelPanel'
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import HelpPage from "../../../Layout/HelpPage";
// const { TabPane } = Tabs;

// for using server-side calculation (i.e when getting the estimate result from estimate API )
// let data = Object.entries(recieved_data).map((item) => {
//   return { Name: item[0], Cost: item[1].cost, Rate: item[1].rate };
// });

interface IProps {
  currentProject: BmProject;
  projects: BmProject[];
}

const EBITTabs: React.FC = (props: IProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(localStorage.getItem("ebit_active_tab") ?? "estimate_3");
  useEffect(() => {
    setActiveTab(activeTab);
  }, []);
  // #endregion
  return (
    <div id="EbitTabs" style={{ height: '100%' }}>
      <Tabs onChange={(key) => {
        localStorage.setItem("ebit_active_tab", key);
        setActiveTab(key);
      }} defaultActiveKey={activeTab} tabPosition="top" type="line" size="small">
        <Tabs.TabPane
          tab={t("Estimate")}
          key="estimate_3"
          className="estimate-panel"
        >
          <EstimateSection />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("R&D")} key="ebit_1">
          <RD />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Cost Types")} key="cost_type_2">
          <CostTypes/>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Evaluation")} key="evaluation_4"
          className="evaluation-panel"
        >
          <EvaluationTopLevelPanel />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Tipps and Tricks")} key="tips_4">
          <HelpPage currentPageCode="ebit" />
        </Tabs.TabPane>

      </Tabs>
    </div>
  );
};

const mapStateToProps = (state: IGeneralState) => {
  // console.log("mousa", state.generalInfo.ProjectsForEditEbit)
  return {
    // ebitState: state.ebit,
    //projects: state.generalInfo.allProjects,
    currentProject: state.generalInfo.profileResponse?.currentProject,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EBITTabs);
