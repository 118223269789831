import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Empty, Form, Input, InputNumber, Menu, Modal, Popconfirm, Popover, Result, Row, Space, Spin, Tabs, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import { ICancelProcessDto, ICompleteProcessDto, IIaDiscipline, InterfaceAnalysisExportExcel } from "../../../Models/InterfaceAnalysis/IAItems";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState, InterfaceAnalysisState } from "../../../Redux/reducers/IGeneralState";
import IATable from "./IATable";
import './IATool.scss';
import 'moment/locale/de.js';
import 'moment/locale/en-gb.js';
import { t } from "i18next";
import { VotingProcessStatusEnum } from "../../../Enums/VotingProcessStatusEnum";
import Countdown from "antd/lib/statistic/Countdown";
import { RulesDescription, RulesDurations, RulesRequire } from "../../Admin/Rules/RulesValidation";
import IAReviewList from "./IAReviewList";
import { FileExcelOutlined } from "@ant-design/icons";
import Departments from "./widgets/Departments/Departments";
import { ProfileResponse } from "../../../Models/Responses/ProfileResponse";
import HelpPage from "../../../Layout/HelpPage";
const { Paragraph } = Typography;
moment.locale(i18n.language);
console.log("i18n.language i18n.language", i18n.language);
interface IProps {
    getView: () => any;
    createNewAnalysis: () => any;
    goToSetDiscipline: (model: IIaDiscipline) => any;
    startNewAnalysis: (duration: number) => any;
    endAnalysis: () => any;
    completeAnalysis: (model: ICompleteProcessDto) => any;
    //restartAnalysis: (duration: number) => any;
    cancelAnalysis: (model: ICancelProcessDto) => any;
    state: InterfaceAnalysisState;
    exportToExcel: (model: InterfaceAnalysisExportExcel) => any;
    getRounds: () => any;
    getMetaData: () => any;
}
function IATool(props: IProps) {
    const [duration, setDuration] = useState<number>(10);
    const [showCompleteModal, setShowCompleteModal] = useState(false);
    const [showResetartModal, setShowRestartModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [form] = Form.useForm();
    const [checkNotifyAll, setCheckNotifyAll] = useState(false);
    //const [excludePreviosRoundsInvitation, setExcludePreviosRoundsInvitation] = useState(false);
    //const [preserveParticipantsList, setPreserveParticipantsList] = useState(false);
    //const [shouldNotifyParticipants, setShouldNotifyParticipants] = useState(false);
    const [shouldResetDepartmentsList, setShouldResetDepartmentsList] = useState(false);
    const [activeTab, setActiveTab] = useState(localStorage.getItem("interface_tool_tab") ?? 'ended-tab');
    const [completeActiveTab, setCompleteActiveTab] = useState(localStorage.getItem("interface_tool_complete_tab") ?? 'table-tab');
    useEffect(() => {
        props.getMetaData();
        props.getView();
        props.getRounds();
    }, []);
    const columnsFilter = (col: IIaDiscipline, isRow: boolean) => {
        if (isRow) {
            col.inRow = false;
        } else {
            col.inColumn = false;
        }
        props.goToSetDiscipline(col);
    }
    const columnsFilterAdd = (col: IIaDiscipline, isRow: boolean) => {
        if (isRow) {
            col.inRow = true;
        } else {
            col.inColumn = true;
        }
        props.goToSetDiscipline(col);
    }
    const menu = (isRow: boolean) => {
        let shownItems = isRow ?
            props.state?.metaData?.disciplines?.filter(c => c.inAnalysis).filter(c => c.inRow == null || c.inRow == true)
            : props.state?.metaData?.disciplines?.filter(c => c.inAnalysis).filter(c => c.inColumn == null || c.inColumn == true);
        let hiddenItems = isRow ?
            props.state?.metaData?.disciplines?.filter(c => c.inAnalysis).filter(c => c.inRow == false)
            : props.state?.metaData?.disciplines?.filter(c => c.inAnalysis).filter(c => c.inColumn == false);
        return (
            <Menu getPopupContainer={() => document.getElementById('app-layout')} theme='light'>
                <p>Shown items </p>
                {shownItems?.map(col => {
                    return (
                        <Menu.Item key={`menu_item_${col.id}`} >
                            <Checkbox
                                key={`menu_item_ck_${col.id}`}
                                onChange={() => columnsFilter(col, isRow)}
                            >
                                {col.name}
                            </Checkbox>
                        </Menu.Item>
                    );
                })}

                <div className='hidden-items '>
                    <p>hiden items </p>
                    {hiddenItems?.map(col => {
                        return (
                            <Menu.Item key={`menu_item_${col.id}`}>
                                <Checkbox
                                    defaultChecked
                                    key={`menu_item_ck_${col.id}`}
                                    onChange={() => columnsFilterAdd(col, isRow)}
                                >
                                    {col.name}
                                </Checkbox>
                            </Menu.Item>
                        );
                    })}
                </div>
            </Menu>

        )
    };
    const confirmStart = () => {
        console.log('Clicked on Yes.');
    };
    const confirmEnd = () => {
        console.log('Clicked on Yes.');
    };
    const onChangeExpiryDate = () => {

    }
    const onExport = () => {
        props.exportToExcel({
            inColumnsDepartments: props.state?.metaData?.disciplines.filter(c => c.inAnalysis).filter(c => c.inColumn != false).map(c => { return c.id }),
            inRowsDepartments: props.state?.metaData?.disciplines.filter(c => c.inAnalysis).filter(c => c.inRow != false).map(c => { return c.id })
        });
    }
    const operations = <Space size={16} align="center">
        <Popover getPopupContainer={() => document.getElementById('app-layout')} overlayClassName='columns-filter-ia-popover' className='columns-filter' content={menu(false)} trigger="click" placement="bottom">
            <Button type="default">
                {`${t("Column options")} (${props.state?.metaData?.disciplines.filter(c => c.inAnalysis).filter(c => c.inColumn == false).length} ${t("hidden")})`}
            </Button>
        </Popover>
        <Popover getPopupContainer={() => document.getElementById('app-layout')} overlayClassName='columns-filter-ia-popover' className='columns-filter' content={menu(true)} trigger="click" placement="bottom">
            <Button type="default">
                {`${t("Row options")} (${props.state?.metaData?.disciplines.filter(c => c.inAnalysis).filter(c => c.inRow == false).length} ${t("hidden")} )`}
            </Button>
        </Popover>
        <Button type="default" icon={<FileExcelOutlined style={{ 'margin': 'auto' }} />} onClick={onExport}>
            {`${t("Export")}`}
        </Button>
    </Space>;

    // const items = [
    //     { label: 'Results', key: 'table-tab', children: <IATable /> },
    //     { label: 'Configuration', key: 'config-tab', children: <IAConfigure /> }
    // ];
    const markAsCompleteSection = () => {
        return <>
            <Button type="primary" onClick={() => { setShowCompleteModal(true); form.setFieldValue("notify", checkNotifyAll) }}>{`${t("Close and Complete")}`}</Button>
            <Modal title={`${t("Close and Complete")}`} cancelText={`${t("Cancel")}`}  open={showCompleteModal} onOk={async () => {
                let validation = await form.validateFields();
                console.log("Close and Complete", validation);
                let values = form.getFieldsValue();
                console.log("Close and Complete", values);
                props.completeAnalysis({
                    reason: values["reason"],
                    //notify: values["notify"]
                });
                setShowCompleteModal(false)
            }}
                onCancel={() => { setShowCompleteModal(false) }}>
                <Form form={form} >
                    <Form.Item name={"reason"} label={`${t("Reason")}`} rules={[...RulesDescription({ name: t("Reason"), countChar: 1500 }), ...RulesRequire({ filedName: t("Reason") })]}>
                        <Input.TextArea allowClear showCount placeholder={`${t("Type a reason...")}`} rows={4} maxLength={256} />
                    </Form.Item>
                    {/*   <Form.Item name={"notify"}>
                        <Checkbox checked={checkNotifyAll} onChange={(val) => {
                            form.setFieldValue("notify", val.target.checked);
                            setCheckNotifyAll(val.target.checked)
                        }}>
                            {`${t("Notify all participants")}`}
                        </Checkbox>
                    </Form.Item> */}
                </Form>
            </Modal>
        </>;
    }
    const restartSection = () => {
        return <>
            <Button type="primary" onClick={() => {
                setShowRestartModal(true);
            }}>{`${t("Extend")}`}</Button>
            <Modal title={`${t("Extend")}`} cancelText={`${t("Cancel")}`} open={showResetartModal} onOk={async () => {
                try {
                    let validation = await form.validateFields();

                    let values = form.getFieldsValue();
                    props.startNewAnalysis(values["duration"]);
                    setShowRestartModal(false)
                } catch (ee) {
                    console.error(ee);
                }
            }}
                onCancel={() => { setShowRestartModal(false) }}>
                <Form form={form} >
                    <Form.Item initialValue={10} name={"duration"} label={`${t("Duration in minutes")}`} rules={[...RulesRequire({ filedName: t("Duration in minutes") }), ...RulesDurations({ filedName: t("Duration in minutes") })]}>
                        <InputNumber value={duration} onChange={(val) => { setDuration(val) }} />
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
    const cancelSection = () => {
        return <>
            <Button

                type="primary" onClick={() => {
                    setShowCancelModal(true);
                    form.setFieldValue("shouldResetDepartmentsList", shouldResetDepartmentsList);
                }}>{`${t("Reset")}`}</Button>
            <Modal title={`${t("Reset")}`} cancelText={`${t("Cancel")}`}  open={showCancelModal} onOk={async () => {
                let validation = await form.validateFields();
                console.log("Reset", validation);
                let values = form.getFieldsValue();
                console.log("Reset", values);
                props.cancelAnalysis({
                    reason: values["reason"],
                    shouldResetDepartmentsList: values["shouldResetDepartmentsList"]
                });
                form.resetFields();
                setShowCancelModal(false)
            }}
                onCancel={() => {
                    form.resetFields();
                    setShowCancelModal(false)
                }}>
                <Form form={form} >
                    <Form.Item name={"reason"} label={`${t("Reason")}`} rules={[...RulesDescription({ name: t("Reason"), countChar: 1500 }), ...RulesRequire({ filedName: t("Reason") })]}>
                        <Input.TextArea allowClear showCount placeholder={`${t("Type a reason...")}`} rows={4} maxLength={256} />
                    </Form.Item>
                    <Form.Item name={"shouldResetDepartmentsList"}>
                        <Checkbox checked={shouldResetDepartmentsList} onChange={(val) => {
                            form.setFieldValue("shouldResetDepartmentsList", val.target.checked);
                            setShouldResetDepartmentsList(val.target.checked)
                        }}>
                            {`${t("Reset departments list?")}`}
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
    const preparePresentation = () => {
        switch (props.state?.metaData?.votingProcessStatusId) {
            case VotingProcessStatusEnum.Created:
                let createdItems = [];
                createdItems.push(
                    {
                        label: <>{t("General")}</>, key: 'general-tab',
                        children: <>
                            <Row gutter={16} className="voting-process-header">
                                <Col span={24}>
                                    <InputNumber addonBefore={`${t("Duration in minutes")}`} value={duration} onChange={(val) => { setDuration(val) }} min={10} max={10080} defaultValue={10} />
                                    <Popconfirm getPopupContainer={() => document.getElementById('app-layout')} placement="topLeft" title={`${t("Are you sure?")}`} onConfirm={() => { props.startNewAnalysis(duration) }} okText={`${t("Yes")}`} cancelText={`${t("No")}`}>
                                        <Button style={{ marginLeft: "10px" }} type="default" >{`${t("Start new voting round")}`}</Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                            <Row gutter={16} ><Col span={24}>
                                <Paragraph copyable>{props.state?.metaData?.token}</Paragraph>
                            </Col></Row>
                            <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                                <Col >
                                    <Departments departments={props.state?.metaData?.disciplines} enableEdit={true} />

                                </Col>
                            </Row>
                        </>
                    });
                createdItems.push({
                    label: <>{t("Tipps and Tricks")}</>, key: 'tips-tab',
                    children: <HelpPage currentPageCode="interface" />
                });
                return <Tabs items={createdItems} />;
            case VotingProcessStatusEnum.Started:
                let startedItems = [];
                if (props.state?.metaData?.remainingMinutes <= 0) {
                    startedItems.push(
                        {
                            label: <>{t("General")}</>, key: 'general-tab',
                            children: <Result
                                status="warning"
                                title={`${t("The round is under processing, please wait for a minute then refresh")}`}
                                extra={
                                    <Button type="primary" key="refresh_btn" onClick={() => { window.location.reload() }}>
                                        {`${t("Refresh")}`}
                                    </Button>
                                }
                            />
                        }
                    );
                } else {
                    startedItems.push(
                        {
                            label: <>{t("General")}</>, key: 'general-tab',
                            children: <>
                                <Row gutter={16} className="voting-process-header">
                                    <Col span={22}>
                                        <Space align="start" direction="horizontal">
                                            <Countdown onFinish={() => {
                                                props.getMetaData();
                                                props.getRounds();
                                                props.getView();
                                            }}
                                                suffix={<Popconfirm getPopupContainer={() => document.getElementById('app-layout')} placement="topLeft" title={`${t("Are you sure?")}`} onConfirm={() => { props.endAnalysis() }} okText={`${t("Yes")}`} cancelText={`${t("No")}`}>
                                                    <Button>{`${t("End")}`}</Button>
                                                </Popconfirm>}
                                                value={Date.now() + props.state?.metaData?.remainingMinutes * 60 * 1000} format="HH:mm:ss" />
                                        </Space>
                                    </Col>
                                    <Col span={2}>
                                        {cancelSection()}
                                    </Col>
                                </Row>
                                <Row gutter={16} ><Col span={24}>
                                    <Paragraph copyable>{props.state?.metaData?.token}</Paragraph>
                                </Col>
                                </Row>
                                <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                                    <Col span={24}>
                                        <Descriptions layout="horizontal">
                                            <Descriptions.Item label={`${t("Started at")}`}>{props.state?.metaData?.startAt}</Descriptions.Item>
                                            <Descriptions.Item label={`${(props.state?.metaData?.votingProcessStatusId != VotingProcessStatusEnum.Started) ? t("Expired at") : t("Expire at")}`}>{props.state?.metaData?.expireAt}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                                <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                                    <Col span={24}>
                                        <Departments departments={props.state?.metaData?.disciplines} enableEdit={false} />
                                    </Col>
                                </Row>
                            </>
                        });
                }

                startedItems.push({
                    label: <>{t("Tipps and Tricks")}</>, key: 'tips-tab',
                    children: <HelpPage currentPageCode="interface" />
                });
                return <Tabs items={startedItems} />;
            case VotingProcessStatusEnum.Ended:
                let endedItems = [
                    {
                        label: `${t("Answers")}`,
                        key: 'ended-tab',
                        children: <IAReviewList />
                    },
                    {
                        label: `${t("Departments")}`,
                        key: 'departments-tab',
                        children: <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                            <Col span={24}>
                                <Departments departments={props.state?.metaData?.disciplines} enableEdit={false} />
                            </Col>
                        </Row>

                    },
                    {
                        label: `${t("Results Matrix")}`,
                        key: 'table-tab',
                        children: <Card title={`${t("Results Matrix")}`} >
                            <IATable />
                        </Card>
                    },
                    {
                        label: <>{t("Tipps and Tricks")}</>, key: 'tips-interface-tab',
                        children: <HelpPage currentPageCode="interface" />
                    }
                ]
                return <>
                    <Row>
                        <Col span={24}>
                            <Space align="center" direction="horizontal" className="top-options">
                                {markAsCompleteSection()}
                                {cancelSection()}
                                {restartSection()}
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Tabs onChange={(activeKey) => {
                                localStorage.setItem("interface_tool_tab", activeKey);
                                setActiveTab(activeKey);
                            }} activeKey={activeTab} items={endedItems}
                                tabBarExtraContent={(activeTab == "table-tab") ? operations : []}
                                onTabClick={(activeKey) => {
                                    if (activeKey == "ended-tab") {
                                        props.getRounds();
                                    }
                                    if (activeKey == "table-tab") {
                                        props.getView();
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </>

            case VotingProcessStatusEnum.Completed:
                let items = [
                    {
                        label: `${t("Results Matrix")}`, key: 'table-tab', children: <Card title={`${t("Results Matrix")}`} >
                            <IATable />
                        </Card>
                    },
                    {
                        label: `${t("Answers")}`,
                        key: 'ended-tab',
                        children: <IAReviewList />
                    },
                    {
                        label: `${t("Departments")}`, key: 'config-tab', children: <Space direction="vertical" className="current_project_card_wrapper voting-process-body">
                            <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                                <Col span={24}>
                                    <Departments departments={props.state?.metaData?.disciplines} enableEdit={false} />
                                </Col>
                            </Row>
                        </Space>
                    },
                    {
                        label: <>{t("Tipps and Tricks")}</>, key: 'tips-interface-tab',
                        children: <HelpPage currentPageCode="interface" />
                    }
                ];
                return <Tabs onChange={(activeCompleteKey) => {
                    localStorage.setItem("interface_tool_complete_tab", activeCompleteKey);
                    setCompleteActiveTab(activeCompleteKey);
                }} activeKey={completeActiveTab} items={items} tabBarExtraContent={(completeActiveTab == "table-tab") ? operations : []}
                    onTabClick={(activeKey) => {
                        if (activeKey == "ended-tab") {
                            props.getRounds();
                        }
                        if (activeKey == "table-tab") {
                            props.getView();
                        }
                    }} />;
            default:
                break;
        }
        return <></>;
    }

    return <Spin spinning={props.state.isLoading}>
        <div className="interface-analysis">
            {(props.state?.metaData?.isCreated == true) ?
                preparePresentation()
                :
                (props.state?.metaData?.isCreated == false) ? <Empty
                    description={
                        <span>
                            {`${t('There is no interface analysis yet')}`}
                        </span>
                    }
                >
                    <Button type="primary" onClick={() => { props.createNewAnalysis() }}> {`${t('Create Now')}`}</Button>
                </Empty>
                    :
                    <Empty description={`${t("No data")}`}></Empty>
            }
        </div>
    </Spin>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        state: state.interfaceAnalysis
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createNewAnalysis: () => dispatch({ type: ActionTypes.CreateInterfaceAnalysis }),
        startNewAnalysis: (duration: number) => dispatch({ type: ActionTypes.StartIARound, duration: duration }),
        endAnalysis: () => dispatch({ type: ActionTypes.EndIARound }),
        completeAnalysis: (model: ICompleteProcessDto) => dispatch({ type: ActionTypes.CompleteIARound, model: model }),
        //restartAnalysis: (duration: number) => dispatch({ type: ActionTypes.RestartIARound, duration: duration }),
        cancelAnalysis: (model: ICancelProcessDto) => dispatch({ type: ActionTypes.CancelIARound, model: model }),
        getView: () => dispatch({ type: ActionTypes.GetIAView }),
        goToSetDiscipline: (model: IIaDiscipline) => dispatch({ type: ActionTypes.GoToSetDisipline, model: model }),
        exportToExcel: (model: InterfaceAnalysisExportExcel) => dispatch({ type: ActionTypes.ExportIAToExcel, model: model }),
        getRounds: () => dispatch({ type: ActionTypes.GetIARoundsView }),
        getMetaData: () => dispatch({ type: ActionTypes.GetIAMetaData }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IATool);