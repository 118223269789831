import React, { useState } from 'react'
import {  Modal, Tooltip } from 'antd';
import { IActivityGroupTemplate } from '../../../../Models/IActivityManagement';
import { GroupOutlined } from '@ant-design/icons';
import ActivityProfileGroups from './ActivityGroupsTable';
import { t } from 'i18next';
interface IProps {
    groups: IActivityGroupTemplate[];
    activityProfileId: number;
    ActivityProfileName: string;
}
const ActivityProfileGroupModal = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const profileGroups: IActivityGroupTemplate[] = props.groups?.map((g, index) => {
        return {
            key: `group_${index}`,
            ...g
        }
    }) ?? [];

    const showModal = async () => {
        try {
            setIsModalOpen(true);
        } catch (error) {
            console.log(error);
        }

    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <> <Tooltip title={`${t("show Activity Profile Template Groups")}`}>
            {/* <Button > */}
                {/* <DatabaseOutlined  />  */}
                <GroupOutlined onClick={showModal} style={{fontSize:"15px"}}/>
                {/* {`${t("Activity Groups")}`}
            </Button> */}
        </Tooltip>
            <Modal title={`( ${props.ActivityProfileName} ) ${t("Profile Groups")}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} 
            width={1200} getContainer={() => document.getElementById("app-layout")}  footer={[]}>
                <ActivityProfileGroups
                activityProfileID={props.activityProfileId} groups={profileGroups}  />
            </Modal>
        </>
    );
};
export default ActivityProfileGroupModal;