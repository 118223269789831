import type { RadioChangeEvent } from 'antd';
import { Tabs, Collapse } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import React, { useState } from 'react';

// const { TabPane } = Tabs;
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const TabsAccordion: React.FC = () => {
  return (
    <div>
      <Tabs defaultActiveKey="1" type="card" size='middle'>
        <Tabs.TabPane tab="Card Tab 1" key="1">
          <Collapse defaultActiveKey={['1']} >
            <Panel header="This is panel header 1" key="1" >
              <p>{text}</p>
            </Panel>
            <Panel header="This is panel header 2" key="2">
              <p>{text}</p>
            </Panel>
            <Panel header="This is panel header 3" key="3">
              <p>{text}</p>
            </Panel>
          </Collapse>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Card Tab 2" key="2">
          Content of card tab 2
        </Tabs.TabPane>
        <Tabs.TabPane tab="Card Tab 3" key="3">
          Content of card tab 3
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default TabsAccordion;