import { ActionTypes } from "../actionsTypes";
import { IAdministratorsState } from "./IGeneralState";

const initialState: IAdministratorsState = {
  isAdministratorsLoading: false,
  administratorsResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const AdministratorsReducer = (state = initialState, action: any): IAdministratorsState => {
  switch (action.type) {
    case ActionTypes.CHANGE_ADMINISTRATOR_LOADER:
      return {
        ...state,
        isAdministratorsLoading: action.isLoading,
      };
    case ActionTypes.SAVE_ADMINISTRATORS:
      return {
        ...state,
        administratorsResult: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      };
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      };
    default:
      break;
  }
  return state;
};
export default AdministratorsReducer;
