import { ActionTypes } from "../actionsTypes";

const initialState = {
    isRotating: false
};

const rotatingReducer = (state = initialState, action: any) => {
    //console.log('rotatingReducer ', state, action, action.type);
    switch (action.type) {

        case ActionTypes.StartRotate:
            return {
                ...state,
                isRotating: true
            }
        case ActionTypes.StopRotate:
            return {
                ...state,
                isRotating: false
            }

        default:
            break;
    }
    return state;
};

export default rotatingReducer;