import { Button, Checkbox, Col, Form, Input, InputNumber, Menu, Modal, Popover, Row, Select, Space, Spin, Table, Tabs, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { EasyAccessDto, EasyAccessDtoFilter, EasyAccessMarkKpi, EasyAccessQuestionAnswers, EasyLeverQuestionAnswer, KpiLevelColumn, QuestionLevelColumn } from "../../../../Models/EasyAccessLever/IEasyAccessLever";
import { formatValue, inputFormatter, inputParser } from "../../../../Utils/helperFunctions";
import i18n from "../../../../i18n";
import { ILeverCell } from "../../../../Models/Levers/Items";
import { t } from "i18next";
import "./index.scss"
import { CopyOutlined, DatabaseOutlined, SaveOutlined, SettingOutlined } from "@ant-design/icons";
import SubQuestionEasyAccessTable from "./SubQuestionTableSaleEasyAccess";
import SubQuestionEasyAccessTableReadonly from "./SubQuestionTableReadonlyEasyAccess";
import Configuration from "./Configuration";
import CostType from "../../EBITTool-V2/CostType";
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import SubQuestionTableRateEasyAccess from "./SubQuestionTableRateEasyAccess";
import SubQuestionTableSaleEasyAccess from "./SubQuestionTableSaleEasyAccess";
import SubQuestionTableCostOfDelayEasyAccess from "./SubQuestionTableCostOfDelayEasyAccess";
var FormulaParser = require("hot-formula-parser").Parser;

var parser = new FormulaParser();
const { Title, Text } = Typography;
interface IProps {
    isLoading: boolean;
    easyaccessLever: EasyAccessDto;
    setIsModalLoading: (isLoading: boolean) => any;
    getEasyAccessLever: (filter: EasyAccessDtoFilter) => any;
    changeEasyAccessLevers: (model: EasyAccessDto) => any;
    SaveEasyAccessLeversByConsultant: (model: EasyAccessQuestionAnswers, filter: EasyAccessDtoFilter) => any;
    markKpiInEasyAccessLevers: (model: EasyAccessMarkKpi) => any;
}

interface TrackChange {
    bmProjectId: number;
    questionIds: number[];
}

function EasyAccessLever(props: IProps) {

    const [activeTab, setActiveTab] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modaldata, setmodaldata] = useState({});
    const [listOfChangesBmProjects, setListOfChangesBmProjects] = useState<TrackChange[]>([])
    //const [listOfChangesQuestions, setListOfChangesQuestions] = useState<number[]>([])
    useEffect(() => {
        let oldBranches = null;
        if (localStorage.getItem("easy_access_branches")) {
            oldBranches = JSON.parse(localStorage.getItem("easy_access_branches"));
        }
        props.getEasyAccessLever({
            leverId: parseInt(localStorage.getItem("easy_access_lever_id") ?? "0"),
            branchesIds: oldBranches,
            hiddenKpi: null
        });
    }, []);
    useEffect(() => {
        if (props.easyaccessLever) {
            setActiveTab(`lever_${localStorage.getItem("easy_access_lever_id") ?? props.easyaccessLever.levers[0].value}`);
        }
    }, [props.easyaccessLever])

    function LeversCalculator(tab: EasyAccessDto, bmProjectId: number) {

        tab.currentTab.easyAccessLeverBmProjects.filter(c => c.id == bmProjectId).map(cc => {
            cc.questions.map((question) => {
                if (question.kpiValue.unit == '%') {
                    parser.setVariable(question.kpiValue.code, question.kpiValue.value / 100);
                } else {
                    parser.setVariable(question.kpiValue.code, question.kpiValue.value);
                }
                question?.subquestions?.fields?.map((fieldRows) => {
                    fieldRows
                        .filter((c) => c.code != "")
                        .sort((a, b) => a.code < b.code ? -1 : 1)
                        .map((field) => {
                            if (field.unit == "%") {
                                parser.setVariable(field.code, field.value / 100);
                            } else {
                                parser.setVariable(field.code, field.value);
                            }
                        });
                });
            });
        });
        tab.currentTab.easyAccessLeverBmProjects.filter(c => c.id == bmProjectId).map(cc => {
            cc.questions.map((question) => {
                if (question.kpiValue.unit == '%') {
                    parser.setVariable(question.kpiValue.code, question.kpiValue.value / 100);
                } else {
                    parser.setVariable(question.kpiValue.code, question.kpiValue.value);
                }
                question?.subquestions?.fields?.map((fieldRows) => {
                    fieldRows
                        .filter((c) => c.code != "")
                        .sort((a, b) => a.code < b.code ? -1 : 1)
                        .map((field) => {
                            if (field.unit == "%") {
                                parser.setVariable(field.code, field.value / 100);
                            } else {
                                parser.setVariable(field.code, field.value);
                            }
                        });
                });
            })
        });

        tab.currentTab.easyAccessLeverBmProjects.filter(c => c.id == bmProjectId).map(cc => {
            cc.questions.map((question) => {
                if (question.kpiValue.unit == '%') {
                    parser.setVariable(question.kpiValue.code, question.kpiValue.value / 100);
                } else {
                    parser.setVariable(question.kpiValue.code, question.kpiValue.value);
                }
                question?.subquestions?.fields?.map((fieldRows) => {
                    fieldRows
                        .filter((c) => c.code != "")
                        .sort((a, b) => a.code < b.code ? -1 : 1)
                        .map((field) => {
                            if (field.unit == "%") {
                                parser.setVariable(field.code, field.value / 100);
                            } else {
                                parser.setVariable(field.code, field.value);
                            }
                        });
                });
            })
        });


        tab.currentTab.easyAccessLeverBmProjects.filter(c => c.id == bmProjectId).map(cc => {
            cc.questions.map((question) => {
                question?.subquestions?.fields?.map((fieldRows) => {
                    fieldRows
                        .filter(
                            (c) =>
                                c.formula != ""
                        )
                        .sort((a, b) => a.code < b.code ? -1 : 1)
                        .map((field) => {
                            //console.log('2: ', field.code, field.value);
                            var formulaResult = parser.parse(field.formula) as {
                                error;
                                result;
                            };
                            if (field.unit == '%') {
                                field.value = formulaResult.result * 100;
                                parser.setVariable(field.code, field.value / 100);
                            } else {
                                field.value = formulaResult.result;
                                parser.setVariable(field.code, field.value);
                            }
                        });
                });

                question?.subquestions?.fields?.map((fieldRows) => {
                    fieldRows
                        .filter(
                            (c) =>
                                c.formula != ""
                        )
                        .sort((a, b) => a.code > b.code ? -1 : 1)
                        .map((field) => {
                            //console.log('2: ', field.code, field.value);
                            var formulaResult = parser.parse(field.formula) as {
                                error;
                                result;
                            };
                            if (field.unit == '%') {
                                field.value = formulaResult.result * 100;
                                parser.setVariable(field.code, field.value / 100);
                            } else {
                                field.value = formulaResult.result;
                                parser.setVariable(field.code, field.value);
                            }
                        });
                });

                if (question.kpiValue.formula && question.kpiValue.formula != ""
                    && question.kpiValue.formula != "Sum_Planned/Length_Planned"
                    && question.kpiValue.formula != "(Sum_Actual/Sum_Planned)-1"
                    && question.kpiValue.formula != "(Sum_Actual-Sum_Planned)/Sum_Planned") {
                    var formulaResult2 = parser.parse(question.kpiValue.formula) as {
                        error;
                        result;
                    };
                    if (formulaResult2.result != null) {
                        if (question.kpiValue.unit == '%') {
                            question.kpiValue.value = formulaResult2.result * 100;
                        } else {
                            question.kpiValue.value = formulaResult2.result;
                        }

                    } else {
                        //console.log("formulaResult2.error", question.kpiValue.formula, formulaResult2.error);
                    }
                } else {

                }
            });
        });


        return tab;
    }
    const showModal = (record) => {
        props.setIsModalLoading(true);
        setmodaldata(record);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        props.setIsModalLoading(false);
        setmodaldata(null);
        setIsModalVisible(false);
    };

    const onchangeSubquestionInput = (value: any, bmProjectId: number, questionV: QuestionLevelColumn, cell: ILeverCell) => {
        var tab = { ...props.easyaccessLever };
        var all = tab.currentTab.easyAccessLeverBmProjects;

        var listOfChangesBmProjectsOld = [...listOfChangesBmProjects];
        if (listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProjectId)?.length > 0) {
            listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProjectId)[0].questionIds.push(questionV.id)
        } else {
            listOfChangesBmProjectsOld.push({
                bmProjectId: bmProjectId,
                questionIds: [questionV.id]
            });
        }
        setListOfChangesBmProjects([...listOfChangesBmProjectsOld]);

        all.filter(x => x.id == bmProjectId)?.map(bmProject => {
            bmProject.questions.map(question => {
                if (question.code == cell.code) {
                    //console.log("onchangeSubquestionInput", value, bmProjectId, questionV, cell);
                    question.kpiValue.value = value;
                } else {
                    if (question.code == questionV.code) {
                        question?.subquestions?.fields?.map(fieldRow => {
                            fieldRow.map(field => {
                                if (cell.code == field.code) {
                                    //console.log("onchangeSubquestionInput", value, bmProjectId, questionV, cell);
                                    field.value = value;
                                }
                            });
                        });
                    }
                }
            });
        });
        tab.currentTab.easyAccessLeverBmProjects = all;
        tab = LeversCalculator(tab, bmProjectId);
        props.changeEasyAccessLevers(tab);
    }

    const onchangeSubquestionTable = (bmProjectId: number, questionV: QuestionLevelColumn) => {
        var tab = { ...props.easyaccessLever };
        var all = tab.currentTab.easyAccessLeverBmProjects;

        var listOfChangesBmProjectsOld = [...listOfChangesBmProjects];
        if (listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProjectId)?.length > 0) {
            listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProjectId)[0].questionIds.push(questionV.id)
        } else {
            listOfChangesBmProjectsOld.push({
                bmProjectId: bmProjectId,
                questionIds: [questionV.id]
            });
        }
        setListOfChangesBmProjects([...listOfChangesBmProjectsOld]);

        all.filter(x => x.id == bmProjectId)?.map(bmProject => {
            bmProject.questions.map(question => {
                if (question.code == questionV.code) {
                    question.kpiValue.value = questionV.kpiValue.value;
                } else {
                    if (question.code == questionV.code) {
                        if (question?.subquestions.columnsNumber == 2) {
                            question.subquestions.rates = questionV.subquestions.rates;
                        }
                        else {
                            if (question?.subquestions.columnsNumber == 3) {
                                question.subquestions.sales = questionV.subquestions.sales;
                            }
                        }
                    }
                }
            });
        });
        tab.currentTab.easyAccessLeverBmProjects = all;
        tab = LeversCalculator(tab, bmProjectId);
        props.changeEasyAccessLevers(tab);
    }
    const onApplyFilter = (branches: number[], hiddenKpi: number[]) => {
        console.log("onApplyFilter");
        let lId = parseInt(activeTab.replace("lever_", ""));
        props.getEasyAccessLever({
            branchesIds: branches,
            leverId: lId,
            hiddenKpi: hiddenKpi
        });
    }
    const onClickSaveLever = () => {
        var tab = { ...props.easyaccessLever };
        var all = tab.currentTab.easyAccessLeverBmProjects;
        var list = [];
        console.log(listOfChangesBmProjects);

        all?.filter(c => listOfChangesBmProjects.map(c => c.bmProjectId).includes(c.id))?.map(bm => {
            bm.questions?.filter(c => listOfChangesBmProjects.filter(c => c.bmProjectId == bm.id)?.[0].questionIds.includes(c.id))?.map(qu => {
                var temp: EasyLeverQuestionAnswer = {
                    answerValue: qu.kpiValue.value,
                    questionId: qu.id,
                    answerSubquestions: qu.subquestions,
                    bmProjectId: bm.id
                };
                list.push(temp);
            });
        });
        var model: EasyAccessQuestionAnswers = {
            answers: list
        };
        let lId = parseInt(activeTab.replace("lever_", ""));
        let branches = (localStorage.getItem("easy_access_branches")) ? JSON.parse(localStorage.getItem("easy_access_branches")) : [];
        let hiddenKpis = (localStorage.getItem("easy_access_hiddenkpis")) ? JSON.parse(localStorage.getItem("easy_access_hiddenkpis")) : [];
        props.SaveEasyAccessLeversByConsultant(model, {
            branchesIds: branches,
            hiddenKpi: hiddenKpis,
            leverId: lId
        });
    }
    const SubQuestionToFields = (question: QuestionLevelColumn, kpi: KpiLevelColumn) => {
        let filesColumns = [];
        question.subquestions?.fields?.map((fieldrow, fi) => {

            fieldrow.map(field => {
                if (field.code == '') {
                } else {
                    filesColumns.push({
                        title: <Space direction="horizontal">
                            <Tooltip title={`Paste from Excel Column`}>
                                <CopyOutlined onClick={() => { handlePastedValue(question, field) }} />
                            </Tooltip>
                            <Text
                                style={{ width: 150 }}
                                ellipsis={{ tooltip: `${field.code} ${field.displayname}` }}
                            >
                                {`${field.code} ${field.displayname}`}
                            </Text>
                        </Space>,
                        dataIndex: `field_${question.id}_${field.code}`,
                        key: `field_${question.id}_${field.code}`,
                        width: 150,
                        className: `prepare_col_name ${kpi.isMarked ? "isMarked" : ""}`,
                        render: (text, record, index) => {
                            let questionRecord = record[`question_${question.id}`] as QuestionLevelColumn;
                            let fvalue = null;
                            questionRecord.subquestions.fields.map((ff, fi) => {
                                if (ff?.filter(c => c.code == field.code)?.[0]) {
                                    fvalue = ff?.filter(c => c.code == field.code)?.[0]?.value
                                }
                            });
                            return (field.unit == '%') ?
                                <InputNumber
                                    disabled={!field.isEditable}
                                    min={0} max={100}
                                    key={`input_field_${question.code}_${field.code}_${record["projectId"]}`}
                                    addonAfter={(field.unit) ? <>{field.unit}</> : null}
                                    name={`${question.code}_${field.code}_${record["projectId"]}`}
                                    formatter={value => `${inputFormatter(value, i18n.language)}`}
                                    parser={x => inputParser(x, i18n.language)}
                                    value={fvalue}
                                    onChange={(e) => {
                                        //console.log("ONCHANGE ", e, question.code);
                                        onchangeSubquestionInput(e, record["projectId"], question, field);
                                    }
                                    }
                                /> :
                                <InputNumber
                                    disabled={!field.isEditable}
                                    key={`input_field_${question.code}_${field.code}_${record["projectId"]}`}
                                    addonAfter={(field.unit) ? <>{field.unit}</> : null}
                                    name={`${question.code}_${field.code}_${record["projectId"]}`}
                                    formatter={value => `${inputFormatter(value, i18n.language)}`}
                                    parser={x => inputParser(x, i18n.language)}
                                    value={fvalue}
                                    onChange={(e) => {
                                        //console.log("ONCHANGE ", e, question.code);
                                        onchangeSubquestionInput(e, record["projectId"], question, field);
                                    }
                                    }
                                />
                        }
                    });
                }
            });
        })
        return filesColumns;
    }

    const questionFieldsAsColumn = (question: QuestionLevelColumn, kpi: KpiLevelColumn) => {
        if ((question.kpiValue?.formula == null || question.kpiValue?.formula == "")) {
            return [{
                title: <Space direction="horizontal">
                    <Tooltip title={`Paste from Excel Column`}>
                        <CopyOutlined onClick={() => { handlePastedValue(question, null) }} />
                    </Tooltip>
                    <Text
                        style={{ width: 150 }}
                        ellipsis={{ tooltip: `Answer` }}
                    >
                        {`Answer`}
                    </Text>
                </Space>,
                dataIndex: `answer_${question.id}`,
                key: `answer_${question.id}`,
                width: 150,
                className: `prepare_col_name ${kpi.isMarked ? "isMarked" : ""}`,
                render: (text, record, index) => {
                    let questionRecord = record[`question_${question.id}`];
                    if(questionRecord.kpiValue?.formula == null || questionRecord.kpiValue?.formula == "")
                    {
                        return (question.kpiValue.unit == '%') ? <InputNumber
                        min={0} max={100}
                        addonAfter={<>{question.kpiValue.unit}</>}
                        key={`input_field_${question.code}_${question.id}_${record["projectId"]}`}
                        name={`${question.code}_${question.kpiValue.code}`}
                        formatter={value => `${inputFormatter(value, i18n.language)}`}
                        parser={x => inputParser(x, i18n.language)}
                        value={questionRecord.kpiValue.value}
                        onChange={(e) => {
                            onchangeSubquestionInput(e, record["projectId"], question, question.kpiValue);
                        }
                        }
                    />
                        :
                        <InputNumber
                            addonAfter={<>{question.kpiValue.unit}</>}
                            key={`input_field_${question.code}_${question.id}_${record["projectId"]}`}
                            name={`${question.code}_${question.kpiValue.code}`}
                            formatter={value => `${inputFormatter(value, i18n.language)}`}
                            parser={x => inputParser(x, i18n.language)}
                            value={questionRecord.kpiValue.value}
                            onChange={(e) => {
                                onchangeSubquestionInput(e, record["projectId"], question, question.kpiValue);
                            }
                            }
                        />
                    }else{
                        return <Tag color="geekblue" className='kpi-value'>
                        {formatValue(questionRecord.kpiValue.value)}&nbsp;{questionRecord.kpiValue.unit}
                    </Tag>  
                    }
                }
            }];
        } else {
            let fields = [];
            question.subquestions?.isTable ?
                fields.push({
                    title: <Space direction="horizontal">
                        {(question.kpiValue.formula == "(Sum_Actual-Sum_Planned)/Sum_Planned") ? null :
                            <Tooltip title={`Paste from Excel Column`}>
                                <CopyOutlined onClick={() => { (question.subquestions.columnsNumber == 3) ? handlePastedValueForTable3(question) : handlePastedValueForTable2(question) }} />
                            </Tooltip>}
                        {`Table`}
                    </Space>,
                    dataIndex: `table_${question.id}`,
                    key: `table_${question.id}`,
                    width: 600,
                    className: `prepare_col_name ${kpi.isMarked ? "isMarked" : ""}`,
                    render: (text, record, index) => {
                        let questionRecord = record[`question_${question.id}`] as QuestionLevelColumn;
                        if (question.subquestions.isReadonly) {
                            return <SubQuestionEasyAccessTableReadonly key={`subtable_${question.id}_${record["projectId"]}`} bmProjectId={record["projectId"]} question={questionRecord} dprojects={record["dprojects"]} />;
                        }
                        if (question.subquestions.columnsNumber == 4) {
                            return <SubQuestionTableCostOfDelayEasyAccess key={`subtable_${question.id}_${record["projectId"]}`} question={questionRecord} onchangeSubquestionTable={onchangeSubquestionTable} dprojects={record["dprojects"]} saveLevers={onClickSaveLever} bmProjectId={record["projectId"]} />
                        }
                        if (question.subquestions.columnsNumber == 2)
                            return <SubQuestionTableRateEasyAccess key={`subtable_${question.id}_${record["projectId"]}`} question={questionRecord} onchangeSubquestionTable={onchangeSubquestionTable} dprojects={record["dprojects"]} saveLevers={onClickSaveLever} bmProjectId={record["projectId"]} />
                        if (question.subquestions.columnsNumber == 3)
                            return <SubQuestionTableSaleEasyAccess key={`subtable_${question.id}_${record["projectId"]}`} question={questionRecord} onchangeSubquestionTable={onchangeSubquestionTable} dprojects={record["dprojects"]} saveLevers={onClickSaveLever} bmProjectId={record["projectId"]} />
                    }
                }) :
                fields = SubQuestionToFields(question, kpi)

            return [
                ...fields,
                {
                    title: 'Answer',
                    dataIndex: `answer_${question.id}`,
                    key: `answer_${question.id}`,
                    className: `prepare_col_name ${kpi.isMarked ? "isMarked" : ""}`,
                    width: 100,
                    render: (text, record, index) => {
                        let questionRecord = record[`question_${question.id}`];
                        return <Tag color="geekblue" className='kpi-value'>
                            {formatValue(questionRecord.kpiValue.value)}&nbsp;{questionRecord.kpiValue.unit}
                        </Tag>
                    }
                }];
        }
    }
    const onMarkKpi = (kpiId, kpiIsMarked) => {
        console.log("onMarkKpi", kpiId, kpiIsMarked);
        var tab = { ...props.easyaccessLever };
        var all = tab.currentTab.kpis;
        var kpi = all.filter(c => c.id == kpiId)?.[0];
        if (kpi) {
            console.log("onMarkKpi", kpiId, kpiIsMarked);
            kpi.isMarked = !kpiIsMarked;
            kpi.markingColor = (!kpiIsMarked) ? "#dffadf" : null
        }
        props.changeEasyAccessLevers(tab);
        props.markKpiInEasyAccessLevers({
            KpiId: kpiId,
            IsMarked: !kpiIsMarked
        })
    }

    const prepareColumns = (kpis: KpiLevelColumn[]) => {
        let _cols: ColumnsType<any> = [
            {
                title: 'Project Name',
                dataIndex: 'projectName',
                key: 'projectName',
                fixed: 'left',
                width: 200,
                className: "prepare_col_name",
                render: (text, record, index) => {
                    return <><Tooltip title={`${t("Show Cost Types")}`}>
                        <DatabaseOutlined key={`cost_type_icon_${record.id}`} onClick={() => { showModal(record) }} />
                    </Tooltip>
                        {text}</>
                }
            }
        ];
        //console.log("kpis", kpis);
        kpis.filter(c => !c.isHidden)?.map(kpi => {
            let questionsChildren = kpi.questions.map(question => {
                let num = question.subquestions?.fields?.length * 200 ?? 200;
                return {
                    title: <Text
                        style={{ width: num }}
                        ellipsis={{ tooltip: `${question.text}` }}
                    >
                        {`${question.text}`}
                    </Text>,
                    dataIndex: `question_${question.id}`,
                    key: `question_${question.id}`,
                    className: `prepare_col_name ${kpi.isMarked ? "isMarked" : ""}`,
                    children: questionFieldsAsColumn(question, kpi)
                }
            });
            _cols.push({
                title: <Space direction="horizontal">
                    <Tooltip title={`${kpi.isMarked ? "Unmark kpi as completed" : "Mark kpi as completed"}`}>
                        <Checkbox checked={kpi.isMarked} onChange={() => { onMarkKpi(kpi.id, kpi.isMarked) }} />
                    </Tooltip>
                    <Text
                        style={{ width: questionsChildren.length * 200 }}
                        ellipsis={{ tooltip: `${kpi.number} ${kpi.name}` }}
                    >
                        {`${kpi.number} ${kpi.name}`}
                    </Text>
                </Space>,
                width: questionsChildren.length * 200,
                dataIndex: `kpi_name_${kpi.id}`,
                key: `kpi_name_${kpi.id}`,
                className: `prepare_col_name ${kpi.isMarked ? "isMarked" : ""}`,
                children: questionsChildren
            })
        });

        //setColumns(_cols);
        return _cols;
    }

    const handlePastedValueForTable3 = (question: QuestionLevelColumn) => {
        try {
            navigator.clipboard.readText()
                .then(text => {
                    var tab = { ...props.easyaccessLever };
                    var all = tab.currentTab.easyAccessLeverBmProjects;
                    console.log("handlePastedValueForTable3", text);
                    var splittedtext = text.split('\r\n');
                    console.log("handlePastedValueForTable3 splittedtext", splittedtext);

                    var listOfChangesBmProjectsOld = [...listOfChangesBmProjects];

                    all.sort((a, b) => a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())).map((bmProject, i) => {
                        if (i < splittedtext.length) {
                            if (splittedtext[i] != '') {
                                let splittedRow = splittedtext[i]?.split('\t');
                                let name = 'Default Name';
                                let year = 1950;
                                let val: any = 0;
                                let val2: any = 0;
                                if (splittedRow.length == 4) {
                                    name = splittedRow[0];
                                    year = parseInt(splittedRow[1]);
                                    val = splittedRow[2];
                                    val2 = splittedRow[3];
                                }
                                if (splittedRow.length == 3) {
                                    name = splittedRow[0];
                                    val = splittedRow[1];
                                    val2 = splittedRow[2];
                                }

                                if (splittedRow.length == 2) {
                                    val = splittedRow[0];
                                    val2 = splittedRow[1];
                                }
                                console.log("SplitterRow:", name, year, val);

                                let valueFromCopy = val.replace('%', '');
                                if (valueFromCopy == null || valueFromCopy == "") return;

                                bmProject.questions.filter(c => c.code == question.code).map(questionItem => {
                                    if (listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)?.length > 0) {
                                        listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)[0].questionIds.push(questionItem.id)
                                    } else {
                                        listOfChangesBmProjectsOld.push({
                                            bmProjectId: bmProject.id,
                                            questionIds: [questionItem.id]
                                        });
                                    }
                                    questionItem.subquestions.sales.push({
                                        dprojectName: name,
                                        dprojectId: 0,
                                        actualSales: val2,
                                        dprojectCompletionYear: year,
                                        plannedSales: val,
                                        isReadonly: false
                                    });

                                    onchangeSubquestionTable(bmProject.id, questionItem);
                                })


                            }
                        }
                    });

                    setListOfChangesBmProjects([...listOfChangesBmProjectsOld]);
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                });
        }
        catch (err02) {
            console.error('Failed to read clipboard contents. ', err02);
            alert("Copy paste is not supported on this browser");
        }
    }

    const handlePastedValueForTable2 = (question: QuestionLevelColumn) => {
        try {
            navigator.clipboard.readText()
                .then(text => {
                    var tab = { ...props.easyaccessLever };
                    var all = tab.currentTab.easyAccessLeverBmProjects;
                    console.log("handlePastedValueForTable2", text);
                    var splittedtext = text.split('\r\n');
                    console.log("handlePastedValueForTable2 splittedtext", splittedtext);
                    let idDP = 9876543210;
                    var listOfChangesBmProjectsOld = [...listOfChangesBmProjects];
                    all.sort((a, b) => a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())).map((bmProject, i) => {
                        if (i < splittedtext.length) {
                            if (splittedtext[i] != '') {
                                let splittedRow = splittedtext[i]?.split('\t');
                                let name = 'Default Name';
                                let year = 1950;
                                let val: any = 0;
                                if (splittedRow.length == 3) {
                                    name = splittedRow[0];
                                    year = parseInt(splittedRow[1]);
                                    val = splittedRow[2];
                                }
                                if (splittedRow.length == 2) {
                                    name = splittedRow[0];
                                    val = splittedRow[1];
                                }

                                if (splittedRow.length == 1) {
                                    val = splittedRow[0];
                                }
                                console.log("SplitterRow:", name, year, val);

                                let valueFromCopy = val?.replace('%', '');
                                if (valueFromCopy == null || valueFromCopy == "") return;

                                bmProject.questions.filter(c => c.code == question.code).map(async questionItem => {
                                    questionItem.subquestions.rates.push({
                                        dprojectName: name,
                                        dprojectId: idDP + 1,
                                        dprojectCompletionYear: year,
                                        rate: valueFromCopy,
                                        isReadonly: false
                                    });
                                    console.log("question.subquestions.rates", bmProject.id, questionItem.subquestions.rates);
                                    if (listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)?.length > 0) {
                                        listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)[0].questionIds.push(questionItem.id)
                                    } else {
                                        listOfChangesBmProjectsOld.push({
                                            bmProjectId: bmProject.id,
                                            questionIds: [questionItem.id]
                                        });
                                    }

                                    await onchangeSubquestionTable(bmProject.id, questionItem);
                                })
                            }
                        }
                    });

                    setListOfChangesBmProjects([...listOfChangesBmProjectsOld]);
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                });
        }
        catch (err02) {
            console.error('Failed to read clipboard contents. ', err02);
            alert("Copy paste is not supported on this browser");
        }
    }

    const handlePastedValue = (question: QuestionLevelColumn, field: ILeverCell) => {
        try {
            navigator.clipboard.readText()
                .then(text => {
                    var tab = { ...props.easyaccessLever };
                    var all = tab.currentTab.easyAccessLeverBmProjects;

                    var splittedtext = text.split('\r\n');
                    var listOfChangesBmProjectsOld = [...listOfChangesBmProjects];

                    all.sort((a, b) => a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())).map((bmProject, i) => {
                        if (i < splittedtext.length) {

                            let valueFromCopy = splittedtext[i]?.replace('%', '');
                            if (valueFromCopy == null || valueFromCopy == "") return;
                            if (field == null) {
                                bmProject.questions.filter(c => c.code == question.code).map(questionItem => {
                                    var listOfChangesBmProjectsOld = [...listOfChangesBmProjects];
                                    if (listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)?.length > 0) {
                                        listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)[0].questionIds.push(questionItem.id)
                                    } else {
                                        listOfChangesBmProjectsOld.push({
                                            bmProjectId: bmProject.id,
                                            questionIds: [questionItem.id]
                                        });
                                    }
                                    setListOfChangesBmProjects([...listOfChangesBmProjectsOld]);

                                    if (questionItem.code == question.code) {

                                        if (listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)?.length > 0) {
                                            listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)[0].questionIds.push(questionItem.id)
                                        } else {
                                            listOfChangesBmProjectsOld.push({
                                                bmProjectId: bmProject.id,
                                                questionIds: [questionItem.id]
                                            });
                                        }

                                        //console.log("question.code: ", question.code, i, splittedtext);                                                                       
                                        questionItem.kpiValue.value = valueFromCopy;
                                    }
                                })
                            } else {
                                bmProject.questions.filter(c => c.code == question.code).map(questionItem => {
                                    if (questionItem.code == question.code) {
                                        if (listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)?.length > 0) {
                                            listOfChangesBmProjectsOld?.filter(c => c.bmProjectId == bmProject.id)[0].questionIds.push(questionItem.id)
                                        } else {
                                            listOfChangesBmProjectsOld.push({
                                                bmProjectId: bmProject.id,
                                                questionIds: [questionItem.id]
                                            });
                                        }
                                        questionItem?.subquestions?.fields?.map(fieldRow => {
                                            fieldRow.map(fieldItem => {
                                                if (fieldItem.code == field.code) {
                                                    //console.log("field.code: ", question.code, i, splittedtext);
                                                    fieldItem.value = valueFromCopy;
                                                }
                                            });
                                        });
                                    }
                                })
                            }
                        }

                        tab = LeversCalculator(tab, bmProject.id);
                        props.changeEasyAccessLevers(tab);
                    });

                    setListOfChangesBmProjects([...listOfChangesBmProjectsOld]);
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                });
        }
        catch (err02) {
            console.error('Failed to read clipboard contents. ', err02);
            alert("Copy paste is not supported on this browser");
        }
    }

    const getCurrentTab = (leverId: number) => {
        if (leverId == props.easyaccessLever.currentTab.leverId) {
            let columns = prepareColumns(props.easyaccessLever.currentTab.kpis);
            let data =
                props.easyaccessLever.currentTab?.easyAccessLeverBmProjects?.map(bm => {
                    let tempD = {
                        projectId: bm.id,
                        projectName: <Text>{bm.name} {"-"} <Text style={{ "color": "rgb(8 111 185)" }}>
                            {bm.year}
                        </Text>
                        </Text>,
                        dprojects: bm.dprojects
                    }
                    bm.questions.map(qq => {
                        //tempD[`answer_${qq.id}`] = qq.kpiValue.value;
                        tempD[`question_${qq.id}`] = qq;
                    });

                    return tempD;
                });

            return <Table
                key={`easy_access_lever_${leverId}`}
                columns={columns}
                dataSource={data}
                bordered
                size="small"
                className="ea_lever_table"
                scroll={{ y: "calc(80vh - 135px)", x: '1500px' }}
                pagination={false}
            />
        } else {
            return <></>;
        }
    }

    let tabs = props.easyaccessLever?.levers?.map(lever => {
        return {
            label: lever.label,
            key: `lever_${lever.value}`,
            children: getCurrentTab(lever.value)
        }
    });


    const operations = <Space size={16} align="center">
        <Button type="default" icon={<SaveOutlined />} onClick={onClickSaveLever}>
            {`${t("Save All")}`}
        </Button>
    </Space>;

    return <Spin spinning={props.isLoading}>
        <Row>
            <Col span={24}>
                <Configuration originalBranches={props.easyaccessLever?.branches} originalKpis={props.easyaccessLever?.currentTab?.kpis}
                    onApplyFilter={onApplyFilter} />
            </Col>
        </Row>
        <Tabs tabPosition="top" items={tabs}
            activeKey={activeTab}
            onChange={(activekey) => {
                let selectedBranches = JSON.parse(localStorage.getItem("easy_access_branches"))
                let lId = parseInt(activekey.replace("lever_", ""));
                localStorage.setItem("easy_access_lever_id", activekey.replace("lever_", ""))
                setActiveTab(activekey);
                props.getEasyAccessLever({
                    leverId: lId,
                    branchesIds: selectedBranches,
                    hiddenKpi: null
                })
            }}
            tabBarExtraContent={operations} />
        <Modal
            key={"cost-types-modal"}
            title={modaldata ? `${modaldata["projectName"]?.props?.children[0]} Cost Types` : ''}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleOk}
            cancelButtonProps={{ style: { display: 'none' } }}
            width="80%"
        >
            <CostType selectedProjectId={modaldata ? modaldata["projectId"] : null} />
        </Modal>
    </Spin>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        easyaccessLever: state.leverTool?.easyaccessLever,
        isLoading: state.leverTool.isLeverLoading,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setIsModalLoading: (isLoading: boolean) => dispatch({ type: ActionTypes.changeLeverLoader, isLoading }),
        getEasyAccessLever: (filter: EasyAccessDtoFilter) => dispatch({ type: ActionTypes.GetEasyAccessLevers, filter: filter }),
        markKpiInEasyAccessLevers: (model: EasyAccessMarkKpi) => dispatch({ type: ActionTypes.MarkKpiInEasyAccessLevers, model: model }),
        changeEasyAccessLevers: (model: EasyAccessDto) => dispatch({ type: ActionTypes.ChangeEasyAccessLevers, model: model }),
        SaveEasyAccessLeversByConsultant: (model: EasyAccessQuestionAnswers, filter: EasyAccessDtoFilter) => dispatch({ type: ActionTypes.SaveEasyAccessLeversByConsultant, model: model, filter: filter }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EasyAccessLever);