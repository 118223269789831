import { Col, Empty, Row, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Area, AreaChart, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
import RTDCurve from "./components/RTDCurve";
import { BestPracticeAtTimePoint, FlDepartment, RTDCurveData } from "./Models/RTDCurveData";
import "./RTDcurvetab.css";
import { IFrontLoadingState, IFrontloadingnitialState, IGeneralState } from "../../../../../Redux/reducers/IGeneralState";
import FlTableColumn from "../InputTab/Models/FlTableColumns";
import { IFrontLoadingToolProject } from "../../../../../Models/IFrontLoadingTool";

interface IProps {
    active: boolean;
    currentFrontLoadingProjectId: number;
    getRTDCurveData: (flProjectId: number, isCurrentFlProject: boolean) => void;
    gettingRTDCurveData: boolean;
    RTDCurveData: RTDCurveData;
    frontLoadingProjects: IFrontloadingnitialState;
    flProjects: IFrontLoadingToolProject[];
    resetRTDCurveData: () => void;
    showBestPracticeCurve: boolean;
};

function RTDCurveTab(props: IProps) {
    useEffect(() => {
        props.getRTDCurveData(props.currentFrontLoadingProjectId, true);
        return () => {
            props.resetRTDCurveData();
        };
    }, []);
    const currentFlProjectCols = [new FlTableColumn(
        <div className="current_flProject_table_title">
            {props.flProjects.find(p => p.id === props.currentFrontLoadingProjectId)?.name}
        </div>,
        "current_flProject_table",
        {
            width: 180,
            align: "left",
            DataIndex: "name",
            render: (text, record, index) => {
                return (<div key={"current_FlProject_table" + index.toString()} style={{
                    backgroundColor: record["backGroundColor"],
                    color: record["foreGroundColor"],
                    height: "100%",
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <div className="DepartmentSpansDiv">
                        <span className="DepartmentOrderSpan">
                            {record['order']}
                        </span>
                        <span className="DepartmentNameSpan">
                            {text}
                        </span>
                    </div>
                </div>);
            },
        }
    )];

    return (
        props.active ? <Spin
            size="large"
            spinning={props.gettingRTDCurveData} >
            {
                (props.RTDCurveData == null) ? <Empty /> :
                    <div id="RTDCurve_tab_main_div" className="RTDCurve_tab_main_div">
                        <Row gutter={24}>
                            <Col span={3}>
                                <div className="current_flproject_div" style={{ width: 180 }}>
                                    <Spin spinning={props.gettingRTDCurveData}>
                                        <Table
                                            columns={currentFlProjectCols}
                                            dataSource={props.RTDCurveData?.flDepartments}
                                            pagination={false} />
                                    </Spin>
                                </div>

                            </Col>
                            <Col span={21} >
                                <RTDCurve showBestPracticeCurve={props.showBestPracticeCurve} key={`rtd_curve_tab_${props.RTDCurveData?.flProjectId}`} RTDCurveData={props.RTDCurveData} withLegend={false} />
                            </Col>
                        </Row>
                    </div>
            }
        </Spin> : null
    );
};

const mapStateToProps = (state: IGeneralState) => {
    return {
        currentFrontLoadingProjectId: state.generalInfo.profileResponse?.currentFrontLoadingProjectId,
        gettingRTDCurveData: state.frontLoadingProjects.gettingRTDCurveData,
        RTDCurveData: state.frontLoadingProjects.RTDCurveData,
        frontLoadingProjects: state.frontLoadingProjects
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getRTDCurveData: (flProjectId: number, isCurrentFlProject: boolean) =>
            dispatch({ type: ActionTypes.GetRTDCurveData, flProjectId: flProjectId, isCurrentFlProject: isCurrentFlProject }),
        resetRTDCurveData: () =>
            dispatch({ type: ActionTypes.SaveRTDCurveData, RTDCurveData: null })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RTDCurveTab);