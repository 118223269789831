import { Button, Col, Form, Input, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { RulesEmail } from "../../Admin/Rules/RulesValidation";
import HeaderLangThemeSwitcher from "../../../Components/HeaderLangThemeSwitcher";
interface IActivateProps {
    isLoading: boolean;
    commonResult: ICommonResponse;
    resetPasswordRequest: (email: string) => any;
}

function ResetPasswordRequest(props: IActivateProps) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();


    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     if (props.commonResult) {
    //         if (props.commonResult?.isError == false) {

    //             navigate(`/login`);
    //         }
    //     }
    // }, [props.commonResult]);

    const onFinish = (values) => {
        console.log('onFinish activate:', values);
        props.resetPasswordRequest(values.username)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <HeaderLangThemeSwitcher />
            <Row align="middle" justify="center" className="login-form">
                <Col xs={24} sm={16} md={8} className="form-card">
                    <div className="header-titel">
                        <p>
                            <span>{`${t("Reset Password")}`}</span><br />
                        </p>
                    </div>
                    <div className="header-activity">
                        {t(`Your reset password link will send to your email.`)}
                    </div>


                    <Form
                        form={form}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish.bind(this)}
                        onFinishFailed={onFinishFailed.bind(this)}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={t("E-Mail")}
                            name="username"

                            rules={RulesEmail()}
                        >
                            <Input />
                        </Form.Item>

                        <Spin spinning={props.isLoading}>
                            <Form.Item
                                noStyle
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit" className="form-btn">
                                    {t("Submit")}
                                </Button>
                            </Form.Item>
                        </Spin>
                    </Form>
                </Col>
            </Row></>);
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.generalInfo.isLoading,
        commonResult: state.generalInfo.commonResponse
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        resetPasswordRequest: (email: string) => dispatch({ type: ActionTypes.ResetPasswordRequest, email: email })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordRequest);