import { Divider, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { ISegmentDisciplineBestPracticeItem, ISegmentDisciplineBestPracticeItemWithKey } from '../../../Models/ISegmentDisciplineBestPractice';
import SegmentDisciplineBPTable from './SegmentDisciplineBPTable';
import './index.scss';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import { t } from 'i18next';
interface IProps {
    allSegmentDisciplineBp: ISegmentDisciplineBestPracticeItem[];
    isLoading: boolean;
    industrySegmentId: number;
    getALLSegmentDisciplineBp: (id: number) => any;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
};
const SegmentDisciplineBP = (props: IProps) => {
    useEffect(() => { props.getALLSegmentDisciplineBp(props.industrySegmentId); }, [])
    const segmentDisciplineBpData: ISegmentDisciplineBestPracticeItemWithKey[] =
        props.allSegmentDisciplineBp?.map((p) => {
            return {
                key: `segmentDis_${p.disciplineId}`, ...p
            };
        }) ?? [];
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <Divider><Typography.Title level={4}>{`${t("Discipline")}`} BestPractice</Typography.Title></Divider>
            <div className="segDis_best_practice">
                <SegmentDisciplineBPTable
                    industrySegmentId={props.industrySegmentId}
                    segmentDisciplineBpData={[...segmentDisciplineBpData]}
                />
            </div>
        </Spin >
    );
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        allSegmentDisciplineBp: state.segmentDisciplineBP.segmentDisciplineBestPracticeResult,
        isLoading: state.segmentDisciplineBP.isSegmentDisciplineBestPracticeLoading,
        isModalLoading: state.segmentDisciplineBP.isModalLoading,
        modalResponse: state.segmentDisciplineBP.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getALLSegmentDisciplineBp: (id: number) => dispatch({ type: ActionTypes.GET_SEGMENT_DISCIPLINE_BEST_PRACTICES, industrySegmentId: id }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SegmentDisciplineBP);
