import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import {
    CloseCircleOutlined
} from "@ant-design/icons";
const ColorPickerInput = ({ colorValue, onChangeComplete}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
    const handleClick = () => { setDisplayColorPicker(prevState => !prevState) };
    const handleClose = () => { setDisplayColorPicker(false) }

    return (
        <>
            <div onClick={handleClick} style={{
                width: '40px',
                height: '40px',
                borderRadius: '5px',
                background: colorValue ? colorValue : "rgba(204, 204, 204, 0.3)",
                marginLeft: "auto",
                marginRight: "auto",
                border: "0.5px solid #ccc",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>{colorValue ? null : <CloseCircleOutlined style={{ fontSize: '20px' }} />}
            </div>

            {displayColorPicker ? <div style={{
                position: 'fixed',
                zIndex: '999',
                left:"50%",
                top:"50%",
             
            }}>
                <div onClick={handleClose} style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px'

                }} />
                <ChromePicker color={colorValue}
                    onChange={onChangeComplete}
                   
                />


            </div> : null}


        </>
    )
}

export default ColorPickerInput;