import { ActionTypes } from "../actionsTypes";
import { ICompletionBmProjectDetailsState } from "./IGeneralState";
const initialState: ICompletionBmProjectDetailsState = {
    detailsResult: null,
    isDetailsLoading: false,
};
const CompletionBmProjectDetailsReducer = (
    state = initialState,
    action: any
): ICompletionBmProjectDetailsState => {
    switch (action.type) {
        case ActionTypes.CHANGE_COMPLETION_DETAILS_LOADER:
            return {
                ...state,
                isDetailsLoading: action.isLoading,
            };
        case ActionTypes.SAVE_COMPLETION_DETAILS:
            return {
                ...state,
                detailsResult: action.result,
            };
        default:
            break;
    }
    return state;
};

export default CompletionBmProjectDetailsReducer;