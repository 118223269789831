import React, { ReactElement, useEffect, useState } from "react";
import { Form, Button, Input, Spin, InputNumber, Card, } from "antd";
import { connect } from "react-redux";
import { deleteFirstLetter } from "../../../../Utils/helperFunctions";
import { IDepartmentItem } from "../../../../Models/IDepartment";
import ColorPickerInput from "../../../../Components/ColorPickerInput";
import { RulesColorPicker, RulesName, RulesRequire } from "../../Rules/RulesValidation";
import { ICommonResponse } from "../../../../Models/Responses/ICommonResponse";
import ModalAdmin from "../../../../Components/ModalAdmin/ModalAdmins";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import DepartmentBmProjectTable from "./DepartmentBmProjectTable";
import { t } from "i18next";
interface IProps {
    departments: IDepartmentItem[];
    isLoading: boolean;
    createDepartment: (model: IDepartmentItem, id: number) => any;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    bmProjectId: number;
    buttonAdd?: ReactElement;
    customerId: number;
    isFromBmProject: boolean;
}
function DepartmentBmProject(props: IProps) {
    const [dataSource, setDataSource] = useState(null);
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<IDepartmentItem>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => {
        if (props.departments) {
            setDataSource(props.departments?.map(d => {
                return {
                    index: d.viewOrder,
                    key: `department_${d.id}`,
                    name: d.name,
                    id: d.id,
                    foreColor: d.foreColor,
                    backgroundColor: d.backgroundColor,
                    users: d.users,
                }
            }));
        }
    }, [props])
    if (dataSource === null) {
        return;
    }

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const departmentNew: IDepartmentItem = {
                ...formValues,
                bmProjectId: props.bmProjectId,
                foreColor: deleteFirstLetter(formValues.foreColor),
                backgroundColor: deleteFirstLetter(formValues.backgroundColor),
            }
            await props.createDepartment(departmentNew, props.customerId);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setFormValues({} as IDepartmentItem);
        setIsModalVisible(false);
    }

    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component" style={{padding:"1.5rem 1rem"}}>
                <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Department")}`}
                    btnText={`${t("Add")}`}
                    width="500px"
                    modalResponse={props.modalResponse}
                    closeAndReset={closeAndReset}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    footerchildren={[
                        <Button key="back" onClick={closeAndReset}>
                             {`${t("Cancel")}`}
                        </Button>,
                        <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                            {`${t("Save")}`} & {`${t("Send")}`}
                        </Button>
                    ]}>
                    <Form layout="vertical" form={form}>
                        <Form.Item
                            label={`${t("Department")} Name`}
                            name="name"
                            rules={RulesName({ name: `${t("Department")} Name`,countChar:128 })}
                            hasFeedback
                        >
                            <Input
                                onChange={(e) =>
                                    setFormValues({ ...formValues, name: (e.target.value)?.trimStart() })
                                }
                            />
                        </Form.Item>
                        {/* <Form.Item label={`${t("View Order")}`} name='viewOrder' rules={RulesRequire({ filedName: `${t("View Order")}` })} hasFeedback >
                            <InputNumber onChange={(e) => setFormValues({ ...formValues, viewOrder: Number(e) })} />
                        </Form.Item> */}
                        <Form.Item name='foreColor' label={`${t("Text Color")}`}
                            rules={RulesColorPicker({ filedName: `${t("Text Color")}`, colorValue: formValues?.foreColor })}
                            hasFeedback className="color-picker-required">
                            <ColorPickerInput colorValue={formValues?.foreColor}
                                onChangeComplete={async (color: any, event: any) => {
                                    setFormValues({ ...formValues, foreColor: color?.hex })
                                    await form.validateFields();
                                }}
                            />
                        </Form.Item>
                        <Form.Item name='backgroundColor' label={`${t("Background Color")}`}
                            rules={RulesColorPicker({ filedName: `${t("Background Color")}`, colorValue: formValues?.backgroundColor })}
                            hasFeedback className="color-picker-required">
                            <ColorPickerInput colorValue={formValues?.backgroundColor}
                                onChangeComplete={async (color: any, event: any) => {
                                    setFormValues({ ...formValues, backgroundColor: color?.hex })
                                    await form.validateFields();
                                }}
                            />
                        </Form.Item>
                    </Form>
                </ModalAdmin>
                <DepartmentBmProjectTable
                    isFromBmProject={props.isFromBmProject}
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    bmProjectId={props.bmProjectId}
                    customerId={props.customerId}
                />
            </div>
            {/* }>
            </Card> */}
        </Spin>
    );
};
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.departmentsByCustomer.isDepartmentByCustomerLoading,
        isModalLoading: state.departmentsByCustomer.isModalLoading,
        modalResponse: state.departmentsByCustomer.modalResponse,

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        createDepartment: (model: IDepartmentItem, id: number) =>
            dispatch({ type: ActionTypes.CREATE_DEPARTMENT_BY_BM_CUSTOMER, model: model, customerId: id }),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentBmProject);