import { SmileOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Form, Input, InputNumber, Layout, List, Result, Row, Space, Spin, Switch, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { formatValue, inputFormatter, inputParser, objectHasProperty, openNotificationWithIcon, tableLocalTranslate } from '../../../../Utils/helperFunctions';
import { ActivityParticipantAnswer } from './Models/ActivityParticipantAnswerDto';
import { TotalName, Totals } from './Models/Totals';
import './survey.scss';
import { useSearchParams } from 'react-router-dom';
import logoAsup from '../../../../assets/Images/asup-logo-HR.png';
import Countdown from 'antd/lib/statistic/Countdown';
import { IActivityToolState, IGeneralState } from '../../../../Redux/reducers/IGeneralState';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { ToastMessages } from '../../../../Enums/Messages';
import { ActivityGroup, ActivityTask } from './Models/ActivityGroup';
import { ColumnType } from 'antd/es/table';
import customerImg from "../../../../assets/Images/customer_default.png";
import i18n from '../../../../i18n';
import HeaderLangThemeSwitcher from '../../../../Components/HeaderLangThemeSwitcher';
//import Paragraph from 'antd/lib/skeleton/Paragraph';
const { Title, Paragraph, Text, Link } = Typography;
interface IProps {
    getView: (tokens: string) => void;
    result: IActivityToolState;
    commonResult: ICommonResponse;
    saveResponse: (participantAnswer: ActivityParticipantAnswer) => void;
}
interface ActivityGroupExtend {
    key: number;
    groupId: number;
    name: string;
    groupSerialNumber: number;
    tasks: ActivityTaskExtend[];
    bestPractice: number;
    groupAverage: number;
    totalHoursPerWeek: number;
    totalDaysPerMonth: number;
    totalDaysPerYear: number;
    daysPerMonth: number;
    daysPerYear: number;
    hoursPerWeek: number;
}
interface ActivityTaskExtend {
    key: number;
    taskId: number;
    taskGroupId: number;
    serialNumberInGroup: number;
    name: string;
    activities: ActivityTask[];
    nameEn?: string;
    nameDe?: string;
    taskSharePercent: number;
    hoursPerWeek: number;
    daysPerMonth: number;
    daysPerYear: number;
}
function Survey(props: IProps) {
    const [passPhrase, setPassPhrase] = useState<string>('');
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    useEffect(() => {
        let lang = localStorage.getItem("localLang") ?? "de";
        localStorage.clear();
        localStorage.setItem("localLang", lang);
        props.getView(token);
    }, []);
    useEffect(() => {
        if(props.result?.isSuccessAnonymousVoting == true){ 
            openNotificationWithIcon("success", `${t("Success")}`, `${t("Thanks for your voting")}`);
        }
    }, [props.result?.isSuccessAnonymousVoting])
    const [groups, setGroups] = useState<ActivityGroupExtend[]>([]);
    const [totalHoursPerWeek, setTotalHoursPerWeek] = useState<number>();
    const [totalDaysPerMonth, setTotalDaysPerMonth] = useState<number>();
    const [totalDaysPerYear, setTotalDaysPerYear] = useState<number>();

    const [timer, setTimer] = useState<number>();

    const [form] = Form.useForm();

    const convertProfileGroupToDataGroup = (group: ActivityGroup): ActivityGroupExtend => {
        return {
            tasks: group.children.map(c => {
                return {
                    activities: c.children,
                    serialNumberInGroup: c.serialNumberInGroup,
                    name: c.name,
                    nameEn: c.nameEn,
                    nameDe: c.nameDe,
                    //activitiesForEdit: c.activities,
                    taskGroupId: c.taskGroupId,
                    key: c.key,
                    taskId: c.taskId,
                    taskSharePercent: 0,
                    daysPerMonth: 0,
                    daysPerYear: 0,
                    hoursPerWeek: 0,
                    groupAverage: 0,
                    totalDaysPerMonth: 0,
                    totalDaysPerYear: 0,
                    totalHoursPerWeek: 0,
                }
            }),
            groupId: group.groupId,
            name: group.name,
            groupSerialNumber: group.groupSerialNumber,
            key: group.key,
            groupAverage: 0,
            totalDaysPerMonth: 0,
            totalDaysPerYear: 0,
            totalHoursPerWeek: 0,
            daysPerMonth: 0,
            daysPerYear: 0,
            hoursPerWeek: 0,
            bestPractice: 0
        }
    }

    // useEffect(() => {
    //     console.log("groups changed, ", groups);
    // }, [groups]);

    useEffect(() => {
        if (props.result?.activitySurveyData && props.result?.activitySurveyData?.groups?.length > 0) {
            setTotalHoursPerWeek(props.result?.activitySurveyData?.maxHoursPerweek);
            setTotalDaysPerMonth(props.result?.activitySurveyData?.maxDaysPerMonth);
            setTotalDaysPerYear(props.result?.activitySurveyData?.maxDaysPerYear);
            setTimer(Date.now() + (props.result?.activitySurveyData?.remainingMinutes * 60 * 1000));
            let _groups: ActivityGroupExtend[] = props.result?.activitySurveyData?.groups.map((c) => { return convertProfileGroupToDataGroup(c) });

            _groups?.push({
                groupAverage: 0,
                groupId: -1000,
                name: 'Total',
                groupSerialNumber: 0,
                key: -1000,
                tasks: [],
                daysPerMonth: 0,
                daysPerYear: 0,
                hoursPerWeek: 0,
                bestPractice: 0,
                totalDaysPerMonth: 0,
                totalDaysPerYear: 0,
                totalHoursPerWeek: 0
            });
            //setGroups(_groups);
            //console.log("Groups", _groups);

            let newGroups = calculateTasksSharePercentsAndTotal([..._groups], props.result?.activitySurveyData?.maxHoursPerweek, TotalName.TotalHoursPerWeek);
            setGroups(newGroups);
        }
    }, [props.result?.activitySurveyData])

    useEffect(() => {
        //console.log("calculateTasksSharePercentsAndTotal(groups, totalHoursPerWeek, TotalName.TotalHoursPerWeek");
        let newGroups = calculateTasksSharePercentsAndTotal(groups, totalHoursPerWeek, TotalName.TotalHoursPerWeek);
        setGroups([...newGroups]);
    }, [totalHoursPerWeek]);

    useEffect(() => {
        //console.log("calculateTasksSharePercentsAndTotal(groups, totalDaysPerMonth, TotalName.TotalDaysPerMonth");
        let newGroups = calculateTasksSharePercentsAndTotal(groups, totalDaysPerMonth, TotalName.TotalDaysPerMonth);
        setGroups([...newGroups]);
    }, [totalDaysPerMonth]);

    useEffect(() => {
        //console.log("calculateTasksSharePercentsAndTotal(groups, totalDaysPerYear, TotalName.TotalDaysPerYear");
        let newGroups = calculateTasksSharePercentsAndTotal(groups, totalDaysPerYear, TotalName.TotalDaysPerYear);
        setGroups([...newGroups]);
    }, [totalDaysPerYear]);

    const { t } = useTranslation();
    //totalHoursPerWeek, max_hours_per_week_form_input 
    //max_days_per_month_form_input
    //daysPerYear_form_input
    const getFieldInHeader = (initialValue: number, key: string) => {
        return <Form
            key="form"
            form={form}
        >
            <Form.Item
                key={`${key}_form_item`}
                name={key}
                style={{ margin: 0 }}
                initialValue={initialValue}
            >
                <InputNumber
                    formatter={value => inputFormatter(value, i18n.language)}
                    parser={x => inputParser(x, i18n.language)}
                    key={key}
                    min={(key == "max_hours_per_week_form_input") ? 10 : (key == "max_days_per_month_form_input") ? 7 : 84}
                    max={(key == "max_hours_per_week_form_input") ? 60 : (key == "max_days_per_month_form_input") ? 31 : 366}
                    addonAfter={(key == "max_hours_per_week_form_input") ? `${t("Hours per week")}` : (key == "max_days_per_month_form_input") ? `${t("Days per month")}` : `${t("Days per year")}`}
                    onChange={(value: number) => {
                        if (key == "max_hours_per_week_form_input") {
                            setTotalHoursPerWeek(value);
                        } else if (key == "max_days_per_month_form_input") {
                            setTotalDaysPerMonth(value);
                        } else if (key == "daysPerYear_form_input") {
                            setTotalDaysPerYear(value);
                        }
                    }}
                />
            </Form.Item>
        </Form>
    }
    //record['taskId'], record['taskGroupId'], daysPerYear
    const getFieldForTask = (taskId: number, taskGroupId: number, key: string) => {
        return <Form
            key="form"
            form={form}
            layout='inline'
        >
            <Form.Item
                key={`${key}_form_item_${taskId}`}
                name={`${key}_${taskId}`}
            >
                <InputNumber
                    formatter={value => inputFormatter(value, i18n.language)}
                    parser={x => inputParser(x, i18n.language)}
                    key={`${key}_form_input_${taskId}`}
                    max={(key == "daysPerYear") ? totalDaysPerYear : (key == "daysPerMonth") ? totalDaysPerMonth : totalHoursPerWeek}
                    min={0}
                    onChange={(value: number) => {
                        console.log("Input number: ", key, value, taskId, taskGroupId);
                        if (key == "daysPerYear") {
                            ondaysPerYearChange(value, taskId, taskGroupId);
                        }
                        else if (key == "daysPerMonth") {
                            ondaysPerMonthChange(value, taskId, taskGroupId);
                        } else if (key == "hoursPerWeek") {
                            onhoursPerWeekChange(value, taskId, taskGroupId);
                        }
                    }
                    }
                />
            </Form.Item>
        </Form>
    }
    const submitForm = () => {
        if (passPhrase == '' || passPhrase == undefined || passPhrase == null) {
            openNotificationWithIcon("warning", `${t("Warning")}`, `${t("Please, Input random name for this voting")}`);
            return;
        }
        props.saveResponse({
            token: token,
            passPhrase: passPhrase,
            maxDaysPerMonth: totalDaysPerMonth,
            maxDaysPerYear: totalDaysPerYear,
            maxHoursPerweek: totalHoursPerWeek,
            groups: groups.map(c => {
                return {
                    ...c, children: c.tasks.map(tt => {
                        let taskTemp: ActivityTask = {
                            children: tt.activities,
                            daysPerMonth: tt.daysPerMonth,
                            daysPerYear: tt.daysPerYear,
                            hoursPerWeek: tt.hoursPerWeek,
                            key: tt.key,
                            taskGroupId: tt.taskGroupId,
                            taskId: tt.taskId,
                            name: tt.name,
                            serialNumberInGroup: tt.serialNumberInGroup,
                            taskSharePercent: tt.taskSharePercent,
                            activitesDe: [],
                            activitesEn: [],
                            activities: [],
                            nameDe: "",
                            nameEn: ""

                        }
                        return taskTemp;
                    }
                    )
                }
            })
        });
    };

    const calculateTasksSharePercentsAndTotal = (_groups: ActivityGroupExtend[], totalValue: number, whichTotal: number): ActivityGroupExtend[] => {
        //console.log("calculateTasksSharePercentsAndTotal", _groups);
        if (!totalValue) totalValue = 0;
        //console.log("calculateTasksSharePercentsAndTotal", groups, totalValue, whichTotal);
        let total: number = 0;
        let totalDaysPerMonthTotal = 0;
        let totalDaysPerYearTotal = 0;
        let totalHoursPerWeekTotal = 0;
        let groupAverage: number = 0;
        _groups = _groups.map(_group => {
            if (_group.groupId < 0) return _group;
            groupAverage = 0;
            let tasks = _group?.tasks?.map(task => {
                let share = 0;
                let newhoursPerWeek: number = task.hoursPerWeek;
                let newdaysPerMonth: number = task.daysPerMonth;
                let newdaysPerYear: number = task.daysPerYear;
                switch (whichTotal) {
                    case TotalName.TotalHoursPerWeek:
                        if (totalValue > 0 && task.hoursPerWeek > 0)
                            share = (task.hoursPerWeek / totalValue) * 100;
                        newdaysPerMonth = (share / 100) * (totalDaysPerMonth ?? props.result?.activitySurveyData?.maxDaysPerMonth);
                        newdaysPerYear = (share / 100) * (totalDaysPerYear ?? props.result?.activitySurveyData?.maxDaysPerYear);
                        console.log("TotalName.TotalHoursPerWeek")
                        form.setFieldValue(`hoursPerWeek_${task.taskId}`, newhoursPerWeek);
                        form.setFieldValue(`daysPerMonth_${task.taskId}`, newdaysPerMonth);
                        form.setFieldValue(`daysPerYear_${task.taskId}`, newdaysPerYear);
                        break;
                    case TotalName.TotalDaysPerMonth:
                        if (totalValue > 0 && task.daysPerMonth > 0)
                            share = (task.daysPerMonth / totalValue) * 100;
                        newhoursPerWeek = (share / 100) * (totalHoursPerWeek ?? props.result?.activitySurveyData?.maxHoursPerweek);
                        newdaysPerYear = (share / 100) * (totalDaysPerYear ?? props.result?.activitySurveyData?.maxDaysPerYear);
                        form.setFieldValue(`hoursPerWeek_${task.taskId}`, newhoursPerWeek);
                        form.setFieldValue(`daysPerMonth_${task.taskId}`, newdaysPerMonth);
                        form.setFieldValue(`daysPerYear_${task.taskId}`, newdaysPerYear);
                        break;
                    case TotalName.TotalDaysPerYear:
                        if (totalValue > 0 && task.daysPerYear > 0)
                            share = (task.daysPerYear / totalValue) * 100;
                        newdaysPerMonth = (share / 100) * (totalDaysPerMonth ?? props.result?.activitySurveyData?.maxDaysPerMonth);
                        newhoursPerWeek = (share / 100) * (totalHoursPerWeek ?? props.result?.activitySurveyData?.maxHoursPerweek);
                        form.setFieldValue(`daysPerMonth_${task.taskId}`, newdaysPerMonth);
                        form.setFieldValue(`hoursPerWeek_${task.taskId}`, newhoursPerWeek);
                        form.setFieldValue(`daysPerYear_${task.taskId}`, newdaysPerYear);
                        break;
                    default:
                        break;
                };
                groupAverage = groupAverage + share;
                return {
                    ...task,
                    taskSharePercent: share,
                    hoursPerWeek: newhoursPerWeek,
                    daysPerMonth: newdaysPerMonth,
                    daysPerYear: newdaysPerYear
                };
            })
            total = total + groupAverage;
            let _totalDaysPerMonth = tasks?.reduce((t, c) => t + (c.daysPerMonth ?? 0), 0);
            totalDaysPerMonthTotal += (_totalDaysPerMonth ?? 0);
            let _totalDaysPerYear = tasks?.reduce((t, c) => t + (c.daysPerYear ?? 0), 0);
            totalDaysPerYearTotal += (_totalDaysPerYear ?? 0);
            let _totalHoursPerWeek = tasks?.reduce((t, c) => t + (c.hoursPerWeek ?? 0), 0);
            totalHoursPerWeekTotal += (_totalHoursPerWeek ?? 0);

            return {
                ..._group,
                tasks: tasks,

                totalDaysPerMonth: _totalDaysPerMonth ?? 0,
                totalDaysPerYear: _totalDaysPerYear ?? 0,
                totalHoursPerWeek: _totalHoursPerWeek ?? 0,

                groupAverage: groupAverage,
            };

        });
        let _totalGroup = _groups.find(c => c.groupId < 0);
        if (_totalGroup) {
            _totalGroup.totalDaysPerMonth = totalDaysPerMonthTotal;
            _totalGroup.totalDaysPerYear = totalDaysPerYearTotal;
            _totalGroup.totalHoursPerWeek = totalHoursPerWeekTotal;
            _totalGroup.groupAverage = total;
            console.log("_totalGroup ", _totalGroup);

            //_groups.push(_totalGroup);
        }
        return [..._groups];
    };

    const isTotalCell = (groupId: number) => {
        if (groupId < 0) return true;
        return false;
    };
    const customRound = (num: number) => {
        return Math.round(num * 10) / 10
    }
    const onhoursPerWeekChange = (value: number, taskId: number, taskGroupId: number) => {
        let _groups = [...groups];
        let group = _groups.find(g => g.groupId == taskGroupId);
        let task = group?.tasks?.find(t => t.taskId == taskId);
        console.log("onhoursPerWeekChange", taskId);
        if (task) {
            console.log("onhoursPerWeekChange", task);
            let hoursPerWeekValue = value;
            let taskSharePercent = customRound((hoursPerWeekValue / totalHoursPerWeek) * 100);
            let daysPerMonthValue = customRound((taskSharePercent / 100) * totalDaysPerMonth);
            let daysPerYearValue = customRound((taskSharePercent / 100) * totalDaysPerYear);


            task.taskSharePercent = taskSharePercent;
            task.hoursPerWeek = hoursPerWeekValue;
            task.daysPerMonth = daysPerMonthValue;
            task.daysPerYear = daysPerYearValue;

            let total: number = 0;
            let totalDaysPerMonthTotal = 0;
            let totalDaysPerYearTotal = 0;
            let totalHoursPerWeekTotal = 0;

            _groups?.filter(c => c.groupId > 0)?.map(group => {
                let _totalDaysPerMonth = group.tasks?.reduce((t, c) => t + (c.daysPerMonth ?? 0), 0) ?? 0;
                totalDaysPerMonthTotal += (_totalDaysPerMonth ?? 0);
                let _totalDaysPerYear = group.tasks?.reduce((t, c) => t + (c.daysPerYear ?? 0), 0) ?? 0;
                totalDaysPerYearTotal += (_totalDaysPerYear ?? 0);
                let _totalHoursPerWeek = group.tasks?.reduce((t, c) => t + (c.hoursPerWeek ?? 0), 0) ?? 0;
                totalHoursPerWeekTotal += (_totalHoursPerWeek ?? 0);

                console.log("debug onhoursPerWeekChange ", _totalHoursPerWeek, _totalDaysPerMonth, _totalDaysPerYear)

                group.totalDaysPerMonth = _totalDaysPerMonth;
                group.totalDaysPerYear = _totalDaysPerYear;
                group.totalHoursPerWeek = _totalHoursPerWeek;

                let groupAverage = (_totalHoursPerWeek / totalHoursPerWeek) * 100;
                group.groupAverage = groupAverage;

                total += groupAverage;
            })

            let totalGroup = _groups.find(g => g.groupId < 0);
            if (totalGroup) {
                totalGroup.groupAverage = total;
                totalGroup.totalDaysPerMonth = totalDaysPerMonthTotal;
                totalGroup.totalDaysPerYear = totalDaysPerYearTotal;
                totalGroup.totalHoursPerWeek = totalHoursPerWeekTotal;
            }


            form.setFieldValue(`daysPerMonth_${taskId}`, daysPerMonthValue);
            form.setFieldValue(`daysPerYear_${taskId}`, daysPerYearValue);
            setGroups([..._groups]);
        }
    };

    const ondaysPerMonthChange = (value: number, taskId: number, taskGroupId: number) => {
        let _groups = [...groups];

        let group = _groups.find(g => g.groupId === taskGroupId);
        let task = group?.tasks?.find(t => t.taskId === taskId);

        if (task) {
            let daysPerMonthValue = value;
            let taskSharePercent = customRound((daysPerMonthValue / totalDaysPerMonth) * 100);
            let hoursPerWeekValue = customRound((taskSharePercent / 100) * totalHoursPerWeek);
            let daysPerYearValue = customRound((taskSharePercent / 100) * totalDaysPerYear);

            task.taskSharePercent = taskSharePercent;
            task.hoursPerWeek = hoursPerWeekValue;
            task.daysPerMonth = daysPerMonthValue;
            task.daysPerYear = daysPerYearValue;

            let total: number = 0;
            let totalDaysPerMonthTotal = 0;
            let totalDaysPerYearTotal = 0;
            let totalHoursPerWeekTotal = 0;
            _groups?.filter(c => c.groupId > 0)?.map(group => {
                let _totalDaysPerMonth = group.tasks?.reduce((t, c) => t + c.daysPerMonth ?? 0, 0);
                totalDaysPerMonthTotal += (_totalDaysPerMonth ?? 0);
                let _totalDaysPerYear = group.tasks?.reduce((t, c) => t + c.daysPerYear ?? 0, 0);
                totalDaysPerYearTotal += (_totalDaysPerYear ?? 0);
                let _totalHoursPerWeek = group.tasks?.reduce((t, c) => t + c.hoursPerWeek ?? 0, 0);
                totalHoursPerWeekTotal += (_totalHoursPerWeek ?? 0);

                group.totalDaysPerMonth = _totalDaysPerMonth;
                group.totalDaysPerYear = _totalDaysPerYear;
                group.totalHoursPerWeek = _totalHoursPerWeek;

                let groupAverage = (_totalHoursPerWeek / totalHoursPerWeek) * 100;
                group.groupAverage = groupAverage;

                total += groupAverage;
            })

            let totalGroup = _groups.find(g => g.groupId < 0);
            if (totalGroup) {
                totalGroup.groupAverage = total;
                totalGroup.totalDaysPerMonth = totalDaysPerMonthTotal;
                totalGroup.totalDaysPerYear = totalDaysPerYearTotal;
                totalGroup.totalHoursPerWeek = totalHoursPerWeekTotal;
            }

            form.setFieldValue(`hoursPerWeek_${taskId}`, hoursPerWeekValue);
            form.setFieldValue(`daysPerYear_${taskId}`, daysPerYearValue);

            setGroups([..._groups]);
        }
    };

    const ondaysPerYearChange = (value: number, taskId: number, taskGroupId: number) => {
        let _groups = [...groups];
        let group = _groups.find(g => g.groupId == taskGroupId);
        let task = group?.tasks?.find(t => t.taskId == taskId);

        if (task) {

            let daysPerYearValue = value;
            let taskSharePercent = customRound((daysPerYearValue / totalDaysPerYear) * 100);
            let hoursPerWeekValue = customRound((taskSharePercent / 100) * totalHoursPerWeek);
            let daysPerMonthValue = customRound((taskSharePercent / 100) * totalDaysPerMonth);

            task.taskSharePercent = taskSharePercent;
            task.hoursPerWeek = hoursPerWeekValue;
            task.daysPerMonth = daysPerMonthValue;
            task.daysPerYear = daysPerYearValue;

            let total: number = 0;
            let totalDaysPerMonthTotal = 0;
            let totalDaysPerYearTotal = 0;
            let totalHoursPerWeekTotal = 0;
            _groups?.filter(c => c.groupId > 0)?.map(group => {
                let _totalDaysPerMonth = group.tasks?.reduce((t, c) => t + c.daysPerMonth ?? 0, 0);
                totalDaysPerMonthTotal += (_totalDaysPerMonth ?? 0);
                let _totalDaysPerYear = group.tasks?.reduce((t, c) => t + c.daysPerYear ?? 0, 0);
                totalDaysPerYearTotal += (_totalDaysPerYear ?? 0);
                let _totalHoursPerWeek = group.tasks?.reduce((t, c) => t + c.hoursPerWeek ?? 0, 0);
                totalHoursPerWeekTotal += (_totalHoursPerWeek ?? 0);

                console.log("debug ondaysPerYearChange ", _totalHoursPerWeek, _totalDaysPerMonth, _totalDaysPerYear)

                group.totalDaysPerMonth = _totalDaysPerMonth;
                group.totalDaysPerYear = _totalDaysPerYear;
                group.totalHoursPerWeek = _totalHoursPerWeek;

                let groupAverage = (_totalHoursPerWeek / totalHoursPerWeek) * 100;
                group.groupAverage = groupAverage;

                total += groupAverage;
            })

            let totalGroup = _groups.find(g => g.groupId < 0);
            if (totalGroup) {
                totalGroup.groupAverage = total;
                totalGroup.totalDaysPerMonth = totalDaysPerMonthTotal;
                totalGroup.totalDaysPerYear = totalDaysPerYearTotal;
                totalGroup.totalHoursPerWeek = totalHoursPerWeekTotal;
            }

            form.setFieldValue(`daysPerMonth_${taskId}`, daysPerMonthValue);
            form.setFieldValue(`hoursPerWeek_${taskId}`, hoursPerWeekValue);

            setGroups([..._groups]);
        }
    };

    const expandedRowActivitiesRender = (group: any, task: any) => {
        const tasksColumns = [
            {
                title: `${t("Name")}`,
                dataIndex: 'name',
                key: 'activityName',
                render: (text: string, record, index) => {
                    return `${group.groupSerialNumber}.${task['serialNumberInGroup']}.${index + 1} ${text}`;
                }
            }
        ];

        const data = task.activities;
        if (!isChecked) return null;
        if (data == null || data.length == 0) return null;
        return <Table className='table-activities'
            indentSize={5}
            size="small" showHeader={false} pagination={false} columns={tasksColumns} dataSource={data} locale={tableLocalTranslate()} />;
    };

    const expandedRowTaskRender = (group: any) => {
        const tasksColumns = [
            {
                title: `${t("Group")}`,
                key: 'group_key',
                dataIndex: 'name',
                width: "45%",
                //align: "left", 
                render: (text: string, record) => {
                    return `${group.groupSerialNumber}.${record['serialNumberInGroup']}. ${text}`;
                }
            },
            {
                title: `${t("Average")}`,
                key: 'groupAverage',
                dataIndex: 'groupAverage',
                width: "5%",
                render: (text, record) => {
                    return null;
                    //return <strong>{objectHasProperty(record, 'groupSerialNumber') ? formatValue(text) + "%" : null}</strong>
                }
            },
            {
                title: `${t("Share Percentage")}`,
                key: 'taskSharePercentUnit',
                dataIndex: 'taskSharePercent',
                width: "5%",
                render: (text, record) => {
                    return formatValue(text) + "%"
                    //return <strong>{objectHasProperty(record, 'serialNumberInGroup') ? formatValue(text) + "%" : null}</strong>
                }
            },
            {
                title: getFieldInHeader(totalHoursPerWeek, "max_hours_per_week_form_input"),
                key: 'hoursPerWeekUnit',
                dataIndex: 'hoursPerWeek',
                width: "15%",
                render: (text, record, index) => {
                    return getFieldForTask(record['taskId'], record['taskGroupId'], "hoursPerWeek");
                    // return objectHasProperty(record, 'serialNumberInGroup') ? getFieldForTask(record['taskId'], record['taskGroupId'], "hoursPerWeek") :
                    //     objectHasProperty(record, 'groupSerialNumber') ? formatValue(record["totalHoursPerWeek"]) : null
                }
            },
            {
                title: getFieldInHeader(totalDaysPerMonth, "max_days_per_month_form_input"),
                key: 'daysPerMonthUnit',
                dataIndex: 'daysPerMonth',
                width: "15%",
                render: (text, record, index) => {
                    return getFieldForTask(record['taskId'], record['taskGroupId'], "daysPerMonth");
                    // return objectHasProperty(record, 'serialNumberInGroup') ?
                    //     getFieldForTask(record['taskId'], record['taskGroupId'], "daysPerMonth") :
                    //     objectHasProperty(record, 'groupSerialNumber') ? formatValue(record["totalDaysPerMonth"]) : null
                }
            },
            {
                title: getFieldInHeader(totalDaysPerYear, "daysPerYear_form_input"),
                key: 'daysPerYearUnit',
                dataIndex: 'daysPerYear',
                width: "15%",
                render: (text, record, index) => {
                    return getFieldForTask(record['taskId'], record['taskGroupId'], "daysPerYear");
                    // if (objectHasProperty(record, 'groupSerialNumber')) console.log(record)
                    // return objectHasProperty(record, 'serialNumberInGroup') ?
                    //     getFieldForTask(record['taskId'], record['taskGroupId'], "daysPerYear") :
                    //     objectHasProperty(record, 'groupSerialNumber') ? formatValue(record["totalDaysPerYear"]) : null
                }
            }
        ];

        const data = group.tasks;
        if (data == null || data.length == 0) return null;
        return <Table
            className='table-tasks'
            indentSize={5}
            size="small"
            showHeader={false}
            pagination={false}
            columns={tasksColumns} dataSource={data} expandable={isChecked ? {
                defaultExpandAllRows: true,
                indentSize: 5,
                expandedRowRender: (task) => {
                    return expandedRowActivitiesRender(group, task);
                }
            } : null}
            rowKey="serialNumberInGroup"
            locale={tableLocalTranslate()}

        />;
    };

    const columns: ColumnType<any>[] = [
        {
            title: `${t("Group")}`,
            key: 'group_key',
            dataIndex: 'name',
            width: "45%",
            //align: "center", 
            render: (text: string, record) => {
                return objectHasProperty(record, 'groupSerialNumber') ?
                    isTotalCell(record['groupId']) ? <strong>{text}</strong> : `${record['groupSerialNumber']}. ${text}` : text
            }
        },
        {
            title: `${t("Average")}`,
            key: 'groupAverage',
            dataIndex: 'groupAverage',
            width: "5%",
            render: (text, record) => {
                return <strong>{objectHasProperty(record, 'groupSerialNumber') ? formatValue(text) + "%" : null}</strong>

            }
        },
        {
            title: `${t("Share Percentage")}`,
            key: 'taskSharePercentUnit',
            dataIndex: 'taskSharePercent',
            width: "5%",
            render: (text, record) => {
                return <strong>{objectHasProperty(record, 'serialNumberInGroup') ? formatValue(text) + "%" : null}</strong>
            }
        },
        {
            title: getFieldInHeader(totalHoursPerWeek, "max_hours_per_week_form_input"),
            key: 'hoursPerWeekUnit',
            dataIndex: 'hoursPerWeek',
            width: "15%",
            render: (text, record, index) => {
                return objectHasProperty(record, 'groupSerialNumber') ? formatValue(record["totalHoursPerWeek"]) : null;
                // return objectHasProperty(record, 'serialNumberInGroup') ? getFieldForTask(record['taskId'], record['taskGroupId'], "hoursPerWeek") :
                //     objectHasProperty(record, 'groupSerialNumber') ? formatValue(record["totalHoursPerWeek"]) : null
            }
        },
        {
            title: getFieldInHeader(totalDaysPerMonth, "max_days_per_month_form_input"),
            key: 'daysPerMonthUnit',
            dataIndex: 'daysPerMonth',
            width: "15%",
            render: (text, record, index) => {
                return objectHasProperty(record, 'groupSerialNumber') ? formatValue(record["totalDaysPerMonth"]) : null
            }
        },
        {
            title: getFieldInHeader(totalDaysPerYear, "daysPerYear_form_input"),
            key: 'daysPerYearUnit',
            dataIndex: 'daysPerYear',
            width: "15%",
            render: (text, record, index) => {
                return objectHasProperty(record, 'groupSerialNumber') ? formatValue(record["totalDaysPerYear"]) : null;
            }
        }
    ];

    // if (props.result?.isSuccessAnonymousVoting) {
    //     return <Result
    //         status="success"
    //         title={`${t("Thanks for your voting")}`}

    //     />;
    // }
    if (props.result?.isLoading) {
        return <Spin spinning={props.result?.isLoading}>
            <Result
                icon={<SmileOutlined />}
                title="Loading"
            />
        </Spin>
    }
    else if (!props.result?.activitySurveyData) {
        console.log("props.result?.activitySurveyData", props.result?.activitySurveyData);
        return <Result
            status="404"
            title="404"
            subTitle={(typeof props.commonResult?.error?.exceptionMessage == 'string') ? props.commonResult?.error?.exceptionMessage as string : ToastMessages.UnExpectedError}
        />;
    }
    else if (props.result?.activitySurveyData?.remainingMinutes <= 0) {
        return <Result
            status="warning"
            title="The round is under processing."
        />;
    }

    return <Layout className="activity_tool_anonymous">
        <HeaderLangThemeSwitcher />
        <Layout.Header>
            <Space >
                <img width={100} src={logoAsup} alt='asup_logo' />
                <Typography.Title level={1} className='activity-title'>{`${t("Activity Survey")}`}</Typography.Title>
            </Space>
        </Layout.Header>
        <Layout.Content  >
            <Card>
                <List.Item style={{ padding: " .8rem" }}>
                    <List.Item.Meta
                        avatar={<Avatar src={props.result?.activitySurveyData?.customerLogo ? props.result?.activitySurveyData?.customerLogo : customerImg} size="large" />}
                        title={<Space direction="vertical" size="small" style={{ display: 'flex' }}>
                            <Typography.Title level={4} >{props.result?.activitySurveyData?.customerName}</Typography.Title>
                            <Typography.Title level={5} type="secondary">{props.result?.activitySurveyData?.projectName}</Typography.Title>
                        </Space>}
                        description={<p style={{ marginBottom: '0' }}>
                            <Countdown value={timer} format="HH:mm:ss" />
                            <Paragraph>
                                <ul>
                                    <li>
                                        {`${t("Hours per week should be between 10 and 60")}`}
                                    </li>
                                    <li>
                                        {`${t("Days per month should be between 7 and 31")}`}
                                    </li>
                                    <li>
                                        {`${t("Days per year should be between 84 and 366")}`}
                                    </li>
                                </ul>
                            </Paragraph>
                            <Input value={passPhrase} style={{ width: "50%" }} maxLength={64} addonBefore={`${t("Random Name")}`} allowClear onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setPassPhrase(e.target.value) }} />
                            <br />

                            <Switch checked={isChecked} checkedChildren={`${t('Show Tasks')}`} unCheckedChildren={`${t('Hide Tasks')}`} defaultChecked
                                onChange={(isChecked) => {
                                    setIsChecked(isChecked);
                                }}
                            />
                        </p>}
                    />
                </List.Item>

                <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                    {(groups && groups.length > 0) ?
                        <Table
                            className='table-groups'
                            size="small"
                            indentSize={5}
                            columns={columns}
                            expandable={{
                                defaultExpandAllRows: true,
                                indentSize: 5,
                                expandedRowRender: (group) => {
                                    return expandedRowTaskRender(group);
                                }
                            }}
                            pagination={false}
                            dataSource={groups}
                        /> : null
                    }
                    {(props.result?.isSuccessAnonymousVoting) ? <></> :
                        <Row style={{ marginTop: "1rem" }}>
                            <Col offset={8} span={8}>
                                <Button type="primary" block={true} onClick={() => {
                                    submitForm();
                                }} className="ia-btn" >{`${t("Save")}`}</Button>
                            </Col>
                        </Row>
                    }

                </div>
            </Card>
        </Layout.Content>
    </Layout>;

};

const mapStateToProps = (state: IGeneralState) => {
    return {
        result: state.activity,
        commonResult: state.generalInfo?.commonResponse
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getView: (token: string) =>
            dispatch({ type: ActionTypes.GetSurveyDataForParticipant, token: token }),
        saveResponse: (participantAnswer: ActivityParticipantAnswer) =>
            dispatch({ type: ActionTypes.UploadParticipantAnswer, participantAnswer: participantAnswer }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
