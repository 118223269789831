import { Button, Form, Input, InputNumber, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { IActivity, IActivityProfileTemplate, IActivityTaskTemplate, IActivityToolTask } from '../../../../Models/IActivityManagement';
import { t } from 'i18next';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { RulesName, RulesRequire } from '../../../Admin/Rules/RulesValidation';
import { IListItem } from '../../../../Models/Responses/ProfileResponse';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import i18n from '../../../../i18n';

interface IProps {
    oldTaskData?: IActivityToolTask;
    groups: IListItem[];
    profileId: number;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    updateActivityToolTask: (model: IActivityToolTask) => any;
    addActivityToolTask: (model: IActivityToolTask) => any;
    isADD: boolean;

}
const TaskAddEditModal = (props: IProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();



    const formValuesHandler = async () => {
        try {
            await form.validateFields()
            let activitiesEn: string[] = form.getFieldsValue(['activitiesForEdit'])?.activitiesForEdit?.map(a => a?.activityEn);
            let activitiesDe: string[] = form.getFieldsValue(['activitiesForEdit'])?.activitiesForEdit?.map(a => a?.activityDe);

            let values = form.getFieldsValue();
            let activityTask: IActivityToolTask = {
                order: values?.serialNumberInGroup,
                nameEn: values.nameEn,
                nameDe: values.nameDe,
                groupId: values?.taskGroupId,
                profileId: props.profileId,
                activitesEn: activitiesEn,
                activitesDe: activitiesDe
            }

            if (!props.isADD) {
                activityTask = { ...activityTask, id: props.oldTaskData?.taskId }
            }
            props.isADD ? await props.addActivityToolTask(activityTask) : await props.updateActivityToolTask(activityTask)
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }

    return (
        <ModalAdmin
            //  title={`${props.isADD ? t("Add Activity Profile Task") : t("Edit") + ` ${props.oldTaskData?.nameEn}`}`}
            title={props.isADD ? t("Add Activity Profile Task") : <> {`${t("Edit")} `}
                <span style={{ color: "#086fb9", marginLeft: ".5rem" }}>
                    {i18n.language === 'de' ? props.oldTaskData?.nameDe : props.oldTaskData?.nameEn}</span>
            </>}
            btnText={props.isADD ? 'addIcon' : 'editIcon'}
            titleTooltip={`${props.isADD ? t("Add Task") : t("Edit Task")}`}
            width="520px"
            closeAndReset={closeAndReset}
            modalResponse={props.modalResponse}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" onClick={() => formValuesHandler()} loading={props.isModalLoading}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form} initialValues={props.isADD ? null : props.oldTaskData}>
                <Form.Item label={`${t("Serial Number In Group")}`} name='serialNumberInGroup'
                    rules={RulesRequire({ filedName: `${t("Serial Number In Group")}` })}
                >
                    <InputNumber
                        min={1}
                    />
                </Form.Item>
                <Form.Item label={`${t("English Name")}`} name='nameEn'
                    rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}>
                    <Input
                    />
                </Form.Item>
                <Form.Item label={`${t("German Name")}`} name='nameDe'
                    rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}>
                    <Input
                    />
                </Form.Item>
                <Form.Item label={`${t("Groups")}`} name='taskGroupId' rules={RulesRequire({ filedName: `${t("Group")}` })} hasFeedback>
                    <Select
                        showSearch
                        placeholder={`${t("Select Group")}`}
                        optionFilterProp="children"
                        getPopupContainer={() => document.getElementById("app-layout")}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={props.groups}
                    />
                </Form.Item>
                <Form.List name="activitiesForEdit">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'activityEn']}
                                        rules={[{ required: true, message: `${t("Missing english activity")}` },
                                        { max: 50, message: `${t("English activity")} ${t("must be less than 50 characters.")}` }

                                        ]}
                                    >
                                        <Input placeholder={`${t("English activity")}`} />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'activityDe']}
                                        rules={[{ required: true, message: `${t("Missing germany activity")}` },
                                        { max: 50, message: `${t("Germany activity")} ${t("must be less than 50 characters.")}` }
                                        ]}
                                    >
                                        <Input placeholder={`${t("Germany activity")}`} />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    {`${t("Add activities")}`}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

            </Form>
        </ModalAdmin>
    )
}
const mapStateToProps = (state: any) => {
    return {
        isModalLoading: state.activity.isModalLoading,
        modalResponse: state.activity.modalResponse,
        isLoading: state.activity.isLoading,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateActivityToolTask: (model: IActivityToolTask) => dispatch({ type: ActionTypes.UpdateActivityToolsTask, model }),
        addActivityToolTask: (model: IActivityToolTask) => dispatch({ type: ActionTypes.AddActivityToolsTasksToGroup, model }),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskAddEditModal);
