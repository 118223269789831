import React, { useEffect } from 'react'
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { ISelfAssessmentTempItem } from '../../../Models/ISelfAssessmentTemp';
import { Collapse, Tabs, TabsProps } from 'antd';
import SelfAssessmentBestPractice from './SelfAssessmentBestPractice/SelfAssessmentBestPractice';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import './index.scss';
interface IProps {
    isLoading: boolean;
    selfAssessments: ISelfAssessmentTempItem[];
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getSelfAssessment: () => any;
}
const SelfAssessment = (props: IProps) => {
    useEffect(() => { props.getSelfAssessment() }, []);
    const { Panel } = Collapse;
    const selfAssessmentsHeaderTabs: TabsProps['items'] = props.selfAssessments?.map((s: any) => {
        return {
            key: `self_${s.id}`,
            label: s.name,
            children: <Collapse defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                isActive ? (
                    <MinusCircleTwoTone />
                ) : (
                    <PlusCircleTwoTone />
                )} >
                {s?.sublevers?.map((su, index) => <Panel header={su.name} key={`${index + 1}`}>
                    <SelfAssessmentBestPractice subLeverId={su.id} selfAssessmentBp={su.bestPractices} />
                </Panel>)}
            </Collapse >
        }
    }) ?? [];
    return (
        <Tabs items={selfAssessmentsHeaderTabs} className='selfAssessment-tap' />
    )
}
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.selfAssessments?.isSelfAssessmentLoading,
        selfAssessments: state.selfAssessments?.selfAssessmentResult,
        isModalLoading: state.selfAssessments?.isModalLoading,
        modalResponse: state.selfAssessments?.modalResponse,

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getSelfAssessment: () => dispatch({ type: ActionTypes.GET_SELF_ASSESSMENT_BP_TEMP }),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessment);