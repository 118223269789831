import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Button } from "antd";
import { t } from "i18next";

const BreadCrumbAdmin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const breadCrumbView = () => {
        const { pathname } = location;
        const pathnames = pathname.split("/").filter((item) => item);
        const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
        return (
            <div>
                <Breadcrumb >
                    {pathnames.length === 2 ? (null) : (
                        pathnames.slice().splice(1, pathnames.length - 1)
                            .map((name, index) => {
                                const routeTo = `/${pathnames.slice(0, index + 2).join("/")}`;
                                const isLast = index === pathnames.length - 2;
                                const isBeforeLast = index === pathnames.length - 3;
                                return isLast ? (
                                    <Breadcrumb.Item key={`bread_${index}`}><Button type="link" style={{ color: "currentcolor", padding: ".2rem " }}>{`${t(capitalize(name))}`}</Button></Breadcrumb.Item>
                                ) : (
                                 isBeforeLast ? (
                                        <Breadcrumb.Item key={`bread_${index}`}>
                                            <Button onClick={() => navigate(-1)} type="link" style={{ color: "currentcolor", padding: ".2rem " }}>{`${t(capitalize(name))}`}</Button>
                                        </Breadcrumb.Item>
                                    ) : (
                                        <Breadcrumb.Item key={`bread_${index}`}>
                                            <Button onClick={() => navigate(routeTo)} type="link" style={{ color: "currentcolor", padding: ".2rem " }}>{`${t(capitalize(name))}`}</Button>
                                        </Breadcrumb.Item>
                                    )
                                )
                            })
                    )}
                </Breadcrumb>
            </div>
        );
    };
    return <>{breadCrumbView()}</>;
};

export default BreadCrumbAdmin;