import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import saveAs from "file-saver";
import html2canvas from "html2canvas";
import { connect } from "react-redux";
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { formatValue } from "../../../../Utils/helperFunctions";
import AsupRadarChartAnt from "../../../Charts/AsupRadarChart/AsupRadarChartAnt";
import './SARadarCharts.scss'
import { t } from "i18next";
import AsupRadarChartSA from "./AsupRadarChartSA";
import { DownloadChartAsImage as _DownloadChartAsImage } from '../../../../Utils/helperFunctions';
interface IProps {
    currentProject: BmProject;
}


function SARadarCharts(props: IProps) {

    const DownloadChartAsImage = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const canvasSave = document.getElementById("sa-radar-main-div");
        // html2canvas(canvasSave)
        //     .then((canvas) => {
        //         canvas.toBlob(function (blob) {
        //             saveAs(blob, `${props.currentProject.project_name}.png`)
        //         })
        //     })
        _DownloadChartAsImage(`${props.currentProject.project_name}.png`, canvasSave);
    };
    return (<div className="sa-radar-main-div">
        <div className="chart-download-Btn_div">
            <Button className='chart-download-Btn'
                icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                onClick={DownloadChartAsImage}
                type="default"
                title={`${t("Download As Image")}`}
                size='small'
            />
        </div>
        <Space id="sa-radar-main-div" direction="vertical" align="center">
            <div className="topLevelChartsContainer">
                <AsupRadarChartAnt
                    showChartControls={false}
                    leverNumber='1.'
                    isSmall={false}
                    height={260}
                    width={500}
                    showChartDetails={false}
                    tooltip={{
                        showCrosshairs: false,
                        showMarkers: false,
                        fields: ['peer', 'value'],
                        formatter: (point) => {
                            //console.log('point: ', point);
                            return { name: point.peer, value: formatValue(point.value) }
                        }
                    }}
                    radarIcon={true}
                    axisFontSize={10} />
            </div>

            <Space direction="horizontal" align="center">

                <div className="topLevelChartsContainer">
                    <AsupRadarChartAnt
                        leverNumber='5.'
                        isSmall={false}
                        height={260}
                        width={400}
                        showChartDetails={false}
                        tooltip={{
                            showCrosshairs: false,
                            showMarkers: false,
                            fields: ['peer', 'value'],
                            formatter: (point) => {
                                //console.log('point: ', point);
                                return { name: point.peer, value: formatValue(point.value) }
                            }
                        }}
                        radarIcon={true}
                        axisFontSize={10}
                        showChartControls={false} />
                </div>
                <div className="topLevelChartsContainer">
                    <AsupRadarChartAnt
                        showChartControls={false}
                        leverNumber='0.'
                        isSmall={false}
                        height={260}
                        width={360}
                        showChartDetails={false}
                        tooltip={{
                            showCrosshairs: false,
                            showMarkers: false,
                            fields: ['peer', 'value'],
                            formatter: (point) => {
                                //console.log('point: ', point);
                                return { name: point.peer, value: formatValue(point.value) }
                            }
                        }}
                        radarIcon={true}
                        axisFontSize={10} />
                </div>

                <div className="topLevelChartsContainer">
                    <AsupRadarChartAnt
                        showChartControls={false}
                        leverNumber='2.'
                        isSmall={false}
                        height={260}
                        width={400}
                        showChartDetails={false}
                        tooltip={{
                            showCrosshairs: false,
                            showMarkers: false,
                            fields: ['peer', 'value'],
                            formatter: (point) => {
                                //console.log('point: ', point);
                                return { name: point.peer, value: formatValue(point.value) }
                            }
                        }}
                        radarIcon={true}
                        axisFontSize={10} />
                </div>
            </Space>
            <Space direction="horizontal" align="center">

                <div className="topLevelChartsContainer">
                    <AsupRadarChartAnt
                        showChartControls={false}
                        leverNumber='4.'
                        isSmall={false}
                        height={260}
                        width={500}
                        showChartDetails={false}
                        tooltip={{
                            showCrosshairs: false,
                            showMarkers: false,
                            fields: ['peer', 'value'],
                            formatter: (point) => {
                                //console.log('point: ', point);
                                return { name: point.peer, value: formatValue(point.value) }
                            }
                        }}
                        radarIcon={true}
                        axisFontSize={10} />
                </div>
                <div className="topLevelChartsContainer">
                    <AsupRadarChartAnt
                        showChartControls={false}
                        showChartDetails={false}
                        leverNumber='3.'
                        isSmall={false}
                        height={260}
                        width={500}
                        tooltip={{
                            showCrosshairs: false,
                            showMarkers: false,
                            fields: ['peer', 'value'],
                            formatter: (point) => {
                                //console.log('point: ', point);
                                return { name: point.peer, value: formatValue(point.value) }
                            }
                        }}
                        radarIcon={true}
                        axisFontSize={10} />
                </div>
            </Space>
        </Space>
    </div>
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        currentProject: state.generalInfo?.profileResponse?.currentProject,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SARadarCharts);