import { Space, Tooltip } from "antd";
import { ISABestPractice } from "../../../../Models/SelfAssessment/ISelfAssessmentView";

type CustomizedDotProps = {
     cx: number;
     cy: number;
    // r: number;
    // strokeColor?: string;
    // strokeWidth?: string;
    // fillColor?: string;
    // fontSize?: string;
    payLoad?: number,
    name?: string;
    importance?: number;
    urgency?: number;
    points: ISABestPractice[];
    id?: number;
};


function CustomizedDot(props: CustomizedDotProps) {
    const { cx, cy, name, importance, urgency, id } = props;

    //let topPoints = props.points.sort((a, b) => a.importance + a.urgency > b.importance + b.urgency ? -1 : 1)?.slice(0, 5);
    let tempOrdered = props.points.sort((a, b) => a.importance + a.urgency > b.importance + b.urgency ? -1 : 1);
    let topRated = 5;
    if (tempOrdered.length > topRated) {
        let x = tempOrdered[topRated - 1];
        tempOrdered = tempOrdered.filter(c => (c.importance + c.urgency) >= (x.importance + x.urgency));
    }

    console.log("topPoints", tempOrdered, props.points);
    let fillColor = (tempOrdered.filter(c => c.id == id)?.length >= 1) ? "#e7591fdb" : "#d9d9d9";
    let radius = (tempOrdered.filter(c => c.id == id)?.length >= 1) ? 10 : 8;
    //let fontsize = (tempOrdered.filter(c => c.id == id)?.length >= 1) ? "12px" : props.fontSize;
    let samePoints = props.points.filter(c => c.importance == importance && c.urgency == urgency);
    let tooltipTxt = <Space align="start" direction="vertical" style={{ color: "white", padding: "2px" }}>
        {samePoints.map(c => {
            return <p>
                <p>{`${c.name}: (${c.importance},${c.urgency} )`}</p>
            </p>
        })}
    </Space>;
    // if ((tempOrdered.filter(c => c.id == id)?.length >= 1)) {
    //     return (
    //         <Tooltip title={tooltipTxt}>
    //             <svg x={cx-10} y={cy-10} width={40} height={40} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ 'shapeRendering': 'geometricPrecision', 'textRendering': 'geometricPrecision', 'imageRendering': '-webkit-optimize-contrast', 'fillRule': 'evenodd', 'clipRule': 'evenodd' }}
    //             >
    //                 <g id="UrTavla">
    //                     <circle style={{ 'fill': fillColor, 'stroke': props.strokeColor, 'strokeWidth': props.strokeWidth, 'strokeMiterlimit': '10' }} cx={10} cy={10} r={radius}>
    //                     </circle>
    //                     <text x="50%" y="50%" textAnchor="middle" stroke="black" strokeWidth="1px" dy=".3em" style={{ 'fontSize': fontsize }}>{props.payLoad}</text>
    //                 </g>
    //             </svg>
    //         </Tooltip>
    //     );
    // }
    return (
        <Tooltip title={tooltipTxt}>
            <svg x={cx - 15} y={cy - 15}  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ 'shapeRendering': 'geometricPrecision', 'textRendering': 'geometricPrecision', 'imageRendering': '-webkit-optimize-contrast', 'fillRule': 'evenodd', 'clipRule': 'evenodd' }}
            >
                <g id="UrTavla">
                    <circle style={{ 'fill': fillColor, 'stroke': "#d9d9d9", 'strokeWidth': 1, 'strokeMiterlimit': '10' }} 
                    cx={15} cy={15} r={15}>
                    </circle>
                    {/* <text x="50%" y="50%" textAnchor="middle" stroke="black" strokeWidth="1px" dy=".3em" style={{ 'fontSize': props.fontSize }}>{props.payLoad}</text> */}
                </g>
            </svg>
        </Tooltip>
    );
};

export default CustomizedDot;