import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { IHelpPage } from '../../../Models/IHelpPage';
import './index.scss'
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { useSearchParams } from 'react-router-dom';
import HelpPageEditForm from './HelpPageEditForm';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
interface IProps {
    helpPageData: IHelpPage;
    isLoading: boolean;
    getHelpPageById: (id: number) => any;
}
const HelpPageEdit: React.FC<IProps> = (props) => {

    useEffect(() => { props.getHelpPageById(helpPageId) }, []);

    const [searchParams, setSearchParams] = useSearchParams();

    const helpPageId = Number(searchParams.get('helpPageId'));

    useEffect(() => { props.getHelpPageById(helpPageId) }, [helpPageId]);

    const record: IHelpPage = props.helpPageData;//?.filter(x => x.id === helpPageId)[0];

    return (
        <Spin spinning={props.isLoading}>
            < HelpPageEditForm record={record} />
        </Spin>
    )
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        helpPageData: state.helpPagesForAdmin.helpPageForEdit,
        isLoading: state.helpPagesForAdmin.isHelpPageLoading,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getHelpPageById: (id: number) => dispatch({ type: ActionTypes.GET_HELP_PAGE_ById, id: id }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpPageEdit);