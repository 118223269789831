import React, { useRef, useState } from 'react';
import { Button, Popconfirm, Space, Typography } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { t } from 'i18next';
import AdminTable from '../../../Components/AdminTable/AdminTable';
import { IFrontLoadingDepartment } from '../../../Models/IFrontLoading';
import FrontLoadingDepartmentEditModal from './FrontLoadingDepartmentEditModal';

interface IProps {
  departmentsData: IFrontLoadingDepartment[],
  deleteFrontLoadingDepartment: (id: number) => any;
}

const FrontLoadingDepartmentTable = (props: IProps) => {
  //search code start
  const searchInput = useRef<any>(null);
  const handleSearch = (
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };
  //search code end
  const columns: any[] = [
    {
      key: "department_key_1",
      title: `${t("View Order")}`,
      dataIndex: 'viewOrder',
      width: "7%",
      render: (text: any, record: any) => <Typography>{text}</Typography>,
      sorter: (a, b) => a?.viewOrder > b?.viewOrder,
      defaultSortOrder: 'ascend',
    },
    {
      key: "department_key_2",
      title: `${t("English Name")}`,
      dataIndex: 'nameEn',
      width: "10%",
      render: (text: any, record: any) => <Typography>{text}</Typography>,
      ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),
      //sorter: (a, b) => a?.nameEn?.localeCompare(b?.nameEn) ?? 0,
    },
    {
      key: "department_isOriginal_2",
      title: `${t("is Original")}`,
      dataIndex: 'isOriginal',
      width: "5%",
      render: (text: any, record: any) => <Typography>{text ? `${t("Yes")}` : `${t("No")}`}</Typography>
    },
    {
      key: "department_key_3",
      title: `${t("German Name")}`,
      dataIndex: 'nameDe',
      width: "10%",
      render: (text: any, record: any) => <Typography>{text}</Typography>,
      ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),
      sorter: (a, b) => a?.nameDe?.localeCompare(b?.nameDe) ?? 0,
    },
    {
      key: "department_key_4",
      title: `${t("Background Color")}`,
      dataIndex: 'backgroundColor',
      width: "7%",
      render: (text: any, record: any) => {
        return <div style={{
          width: '40px',
          height: '40px',
          borderRadius: '5px',
          background: text,
          marginLeft: "auto",
          marginRight: "auto",
          border: "0.5px solid #ccc"
        }}>
        </div >
      },

    },
    {
      key: "department_key_5",
      title: `${t("Text Color")}`,
      dataIndex: 'forgroundColor',
      width: "7%",
      render: (text: any, record: any) => {
        return <div style={{
          width: '40px',
          height: '40px',
          borderRadius: '5px',
          background: text,
          marginLeft: "auto",
          marginRight: "auto",
          border: "0.5px solid #ccc"
        }}>
        </div>
      },
    },
    {
      key: "department_key_6",
      title: `${t("English Description")}`,
      dataIndex: 'descriptionEn',
      render: (text: any, record: any) => <Typography>{text}</Typography>,
    },
    {
      key: "department_key_7",
      title: `${t("German Description")}`,
      dataIndex: 'descriptionDe',
      render: (text: any, record: any) => <Typography>{text}</Typography>,
    },
    {
      key: "department_key_8",
      title: `${t("Actions")}`,
      dataIndex: '',
      width: "12%",
      render: (_: any, record: any) => {
        return <Space direction="vertical" >
          <FrontLoadingDepartmentEditModal departmentData={record} />
          <Popconfirm title={`${t("Sure to Delete")}`}
            onConfirm={() => deleteDepartment(record.id)}
            getPopupContainer={() => document.getElementById("app-layout")}
            cancelText={`${t("Cancel")}`}
          >
            <Button type="link">
              <DeleteOutlined style={{ fontSize: " 20px", cursor: "pointer", color: "red" }} />
              {`${t("Delete")}`}
            </Button>
          </Popconfirm>
        </Space>
      },
    }

  ];

  const deleteDepartment = async (id: any) => {
    try {
      await props.deleteFrontLoadingDepartment(id);
    } catch (error) {
      console.log('delete Failed:', error);
    }

  }
  return (
    <AdminTable
      keyTable='frontLoadingDepartment_table'
      className='frontLoadingDepartment_table'
      dataSource={props.departmentsData}
      columns={columns}
    />

  );

};
const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteFrontLoadingDepartment: (id: number) => dispatch({ type: ActionTypes.DELETE_FRONT_LOADING_DEPARTMENT, id }),
  };
};
export default connect(null, mapDispatchToProps)(FrontLoadingDepartmentTable);


