import { ILever } from "./../../Models/Levers/Items";
import { ActionTypes } from "../actionsTypes";
import { ILeversToolState } from "./IGeneralState";

const initialState: ILeversToolState = {
  easyaccessLever: null,
  isLeverLoading: false,
  isDCRolesLoading: false,
  leverData: [],
  selectedQuestions: [],
  selectedCollectionRoles: [],
  selectedQuestionStatuses: [],
  exportingSelectedChartsToExcel: false,
  exportingHeatMapToExcel: false,
  usersInDataCollectionRoleDto: null,
  isModalLoading: false,
  modalResponse: null,
  leversProgress: 0,
  subLeverOnEbit: [],
  leversWithEbitChartLoading: false,
  kpiLeverOnEbit: [],
  kpileverWithEbitChartLoading: false,
  exportingAllChartsToExcel: false, 
};

const leversToolReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.ExportingHeatMap:
      return {
        ...state,
        exportingHeatMapToExcel: action.value,
      };

    case ActionTypes.ExportingSelectedChartsToExcel:
      return {
        ...state,
        exportingSelectedChartsToExcel: action.value,
      };
    case ActionTypes.ExportingAllChartsToExcel:
      return {
        ...state,
        exportingAllChartsToExcel: action.value,
      };
    case ActionTypes.getDataCollectionRolesLoader:
      return {
        ...state,
        isDCRolesLoading: action.isLoading,
      };
    case ActionTypes.changeLeverLoader:
      return {
        ...state,
        isLeverLoading: action.isLoading,
      };
    case ActionTypes.SaveGetEasyAccessLeversResults:
      //console.log("ActionTypes.SaveGetEasyAccessLeversResults", action.result);
      return {
        ...state,
        easyaccessLever: { ...action.result },
      };
    case ActionTypes.SaveGetLeversResults:
      return {
        ...state,
        leverData: action.result,
        selectedQuestions:
          JSON.parse(localStorage.getItem("selectedQuestion")) ?? [],
      };
    case ActionTypes.SetAllSelectedQuestions:
      var tempLevers = [...state.leverData];
      let tempQ2 = [];
      if (action.selectedAll) {
        tempLevers.map((c) =>
          c.subLevers.map((s) =>
            s.successFactors.map((sf) =>
              sf.kpiLevels.map((k) => {
                if (state.selectedCollectionRoles.length > 0) {
                  if (
                    k.collectionRoles
                      .map((c) => {
                        return c.value;
                      })
                      .some((ss) => state.selectedCollectionRoles.includes(ss))
                  ) {
                    k.questions?.filter(c=>c.showToCustomer == true)?.map((q) => {
                      tempQ2.push(q.id);
                    });
                  }
                } else {
                  k.questions?.filter(c=>c.showToCustomer == true)?.map((q) => {
                    tempQ2.push(q.id);
                  });
                }
              })
            )
          )
        );
      } else {
        tempQ2 = [];
      }
      localStorage.setItem("selectedQuestion", JSON.stringify(tempQ2));
      return {
        ...state,
        selectedQuestions: tempQ2,
      };
    case ActionTypes.SetSelectedQuestions:
      let tempQ = [...state.selectedQuestions];
      if (tempQ.includes(action.questionId)) {
        tempQ = tempQ.filter((c) => c != action.questionId);
      } else {
        tempQ.push(action.questionId);
      }
      localStorage.setItem("selectedQuestion", JSON.stringify(tempQ));
      return {
        ...state,
        selectedQuestions: tempQ,
      };
    case ActionTypes.SetGetLeversResults:
      var temp = [...action.result];
      return {
        ...state,
        leverData: temp,
      };
    case ActionTypes.SetGetLeverResult:
      var temp2 = [...state.leverData];
      temp2.map((c) => {
        if (c.id == (action.result as ILever).id) {
          c = action.result;
        }
      });
      return {
        ...state,
        leverData: temp2,
      };
    case ActionTypes.SetCollectionRole:
      //console.log('SetCollectionRole', action.result);
      return {
        ...state,
        selectedCollectionRoles: [...action.result],
      };
    case ActionTypes.SetQuestionStatuses:
      //console.log('SetQuestionStatuses', action.result);
      return {
        ...state,
        selectedQuestionStatuses: [...action.result],
      };

    // case ActionTypes.LeversUnmount: // just for cleanning up
    //     return {
    //         ...state,
    //         isLoading: false,
    //         leverData: []
    //     }
    case ActionTypes.SaveDataCollectionRoleWithUsersResults:
      return {
        ...state,
        usersInDataCollectionRoleDto: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result,
      };
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result,
      };
    case ActionTypes.SetLeversProgressValue:
      return {
        ...state,
        leversProgress: action.result,
      };
    case ActionTypes.LeversWithEbitChartLoader:
      return {
        ...state,
        leversWithEbitChartLoading: action.isLoading,
      };
    case ActionTypes.SetSubleversWithEbit:
      return {
        ...state,
        subLeverOnEbit: action.result,
      };
    case ActionTypes.LeversKpiWithEbitChartLoader:
      return {
        ...state,
        kpileverWithEbitChartLoading: action.isLoading,
      };
    case ActionTypes.SetKpileversWithEbit:
      return {
        ...state,
        kpiLeverOnEbit: action.result,
      };
    default:
      break;
  }
  return state;
};

export default leversToolReducer;
