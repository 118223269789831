export class Totals {
    totalHoursPerWeek: number = 0;
    totalDaysPerMonth: number = 0;
    totalDaysPerYear: number = 0;
}

export enum TotalName {
    TotalHoursPerWeek = 1,
    TotalDaysPerMonth = 2,
    TotalDaysPerYear = 3
};