import { Table, Row, Col } from "antd";
import "./index.scss";

const TableTwoColumns = (props) => {
  return (
    <section id="table-two-columns">
      <Row>
        <Col span={24}>
          <Table
            key={'rnd_result'}
            // tableLayout="fixed"
            columns={props.columns}
            dataSource={props.rows}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
    </section>
  );
};

export default TableTwoColumns;
