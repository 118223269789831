import { Button, Checkbox, Spin, Tooltip } from "antd";
import { DownloadOutlined, RollbackOutlined, FileExcelOutlined} from "@ant-design/icons";
import './allchartstopbar.scss';
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { connect } from "react-redux"; 

interface IProps {
    checkAll?: boolean;
    selectedCharts: string[];
    onCheckAllChange: (checked: boolean) => void;
    isCheckAllVisible: boolean;
    backBtnTitle: string;
    isBackBtnVisible: boolean;
    goBack: () => void;
    exportChartsToExcel: () => void;
    DownloadAllChartsAsImage: () => void,
};



const EvaluationPanelTopBar = (props: IProps) => {

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        props.onCheckAllChange(e.target.checked);
    };

    return (
        <div className='AllChartsTopBar-container'>
            <div className="return-back-div">
                {props.isBackBtnVisible ? <Button
                    size='small'
                    style={{ 'alignSelf': 'center', 'backgroundColor': 'inherit' }}
                    type="primary"
                    shape="default"
                    icon={<RollbackOutlined  style={{ 'color': 'black' }} />}
                    title={props.backBtnTitle}
                    onClick={props.goBack} /> : null}
            </div>
            <div className="download-checkall_div">
                <div className="downloadall-div">
                    <Button className='chart-download-Btn'
                        icon={<FileExcelOutlined style={{ 'margin': 'auto' }}/>}
                        onClick={props.exportChartsToExcel}
                        type="default"
                        title="Download Excel Report"
                        size='small'
                    />
                </div>
                <div className="downloadall-div">
                    <Button className='chart-download-Btn'
                        icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                        onClick={props.DownloadAllChartsAsImage}
                        type="default"
                        title="Download Selected Charts As Images"
                        disabled={(props.selectedCharts.length === 0)}
                        size='small'
                    />
                </div>
                {props.isCheckAllVisible && <div className="checkall-div" title={props.checkAll ? "Unselect all" : "Select all"}>
                    <Checkbox
                        style={{ 'margin': 'auto' }}
                        onChange={onCheckAllChange}
                        checked={props.checkAll} />
                </div>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationPanelTopBar);