import { Badge, Space, Spin, Table, Tabs, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IGeneralState,
  InterfaceAnalysisState,
} from "../../../Redux/reducers/IGeneralState";
import { formatValue } from "../../../Utils/helperFunctions";
import VotesModal from "./widgets/VotesModal";
import { t } from "i18next";
interface IProps {
  result: InterfaceAnalysisState;
}

interface DataType {
  key: React.Key;
  id: number;
  qValue: number;
  rValue: number;
  kValue: number;
  name: string;
}

const whiteColor = "#FFFFFF";
const blackColor = "#0C0C0C";
const lightBlueColor = "#F2F6FF";
const redColor = "#FC3E40";
//const greenColor = "#01AF50";
const greenColor = "#008631";
const lightGreenColor = "#00C04D";
//const orangeColor = "#FFA500";
const yellowColor = "#F7DA46";
const titleCellConfig = (record, rowIndex) => ({
  style: {
    backgroundColor: record["backColor"],
    color: record["foreColor"],
  },
});

const rateHeaderCellConfig = (column) => ({
  style: {
    backgroundColor: lightBlueColor,
    color: blackColor,
  },
});
const handleRateValueCell = (record, rowIndex, keyValue) => {
  let val = null;

  Object.keys(record).forEach((key) => {
    if (key == keyValue) {
      val = record[key] ?? null;
    }
  });
  if (val == undefined || val == null || val < 0) {
    if (record.key == "total_avg") {
      return {
        colSpan: 3,
        style: {
          backgroundColor: lightBlueColor,
          color: blackColor,
        },
      };
    }
    return {
      style: {
        backgroundColor: lightBlueColor,
        color: blackColor,
      },
    };
  } else {
    if (record.key == "total_avg") {
      return {
        colSpan: 3,
        style: {
          backgroundColor:
            val == 0
              ? lightBlueColor
              : val < 4
              ? redColor              
              : val < 7
              ? yellowColor
              : val < 9
              ? lightGreenColor
              : greenColor,
          color: blackColor,
        },
      };
    }
    return {
      style: {        
        backgroundColor:
          val == 0
            ? lightBlueColor
            : val < 4
            ? redColor            
            : val < 7
            ? yellowColor
            : val < 9
            ? lightGreenColor
            : greenColor,
        color: blackColor,
      },
    };
  }
};

const handeRateValueRender = (text, record, index, toDiscId) => {
  if (record.key == "total_avg" || record.key == "avg_breaks") {
    if (
      text == "" ||
      text == undefined ||
      text == null ||
      Number.parseFloat(text) < 0
    )
      return <></>;
    return <strong>{formatValue(Number.parseFloat(text))}</strong>;
  }
  if (
    text == "" ||
    text == undefined ||
    text == null ||
    Number.parseFloat(text) < 0
  )
    return <></>;
  else
    return (
      <VotesModal
        fromDiscId={record.fromDisciplineId}
        toDiscId={toDiscId}
        value={text}
        key={`vote_modal_${index}_${record.fromDisciplineId}_${toDiscId}`}
      />
    );
};

function IATable(props: IProps) {
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
  const [widthTable, setWidthTable] = useState(0);
  useEffect(() => {
    prepareColumns();
    var length = props.result?.metaData?.disciplines
      ?.filter((c) => c.inAnalysis)
      ?.filter((c) => c.inColumn == null || c.inColumn == true).length;
    setWidthTable(130 + 90 * length);
  }, [props.result?.metaData?.disciplines]);

  const prepareColumns = () => {
    let _cols: ColumnsType<DataType> = [
      {
        title: "",
        dataIndex: "name",
        key: "name",
        width: 130,
        fixed: "left",
        className: "prepare_col_name",
        onCell: titleCellConfig,
      },
    ];
    props.result?.metaData?.disciplines
      ?.filter((c) => c.inAnalysis)
      ?.filter((c) => c.inColumn == null || c.inColumn == true)
      .map((disc) => {
        _cols.push({
          title: disc.name,
          className: "prepare_col_disc",
          width: 90,
          onHeaderCell: (column) => ({
            style: {
              backgroundColor: disc.backColor,
              color: disc.frontendColor,
            },
          }), //disciplineHeaderCellConfig,
          children: [
            {
              title: (
                <Tooltip
                  title={`${t(
                    "Quality of work results in cooperation with the respective interface"
                  )}`}
                >
                  {`Q`}
                </Tooltip>
              ),
              dataIndex: `qValue_${disc.id}`,
              key: `Q_${disc.id}`,
              align: "center",
              width: 30,
              onHeaderCell: rateHeaderCellConfig,

              onCell: (record, rowIndex) => {
                if (record.key == "total_avg") {
                  return {
                    colSpan: 0,
                  };
                }
                return handleRateValueCell(
                  record,
                  rowIndex,
                  `qValue_${disc.id}`
                );
              },
              render: (text, record, index) => {
                return handeRateValueRender(text, record, index, disc.id);
              },
            },
            {
              title: (
                <Tooltip
                  title={`${t(
                    "Speed of reaction during cooperation with the respective interface."
                  )}`}
                >
                  {`R`}
                </Tooltip>
              ),
              dataIndex: `rValue_${disc.id}`,
              key: `R_${disc.id}`,
              align: "center",
              width: 30,
              onHeaderCell: rateHeaderCellConfig,
              onCell: (record, rowIndex) => {
                if (record.key == "total_avg") {
                  return { colSpan: 0 };
                }
                return handleRateValueCell(
                  record,
                  rowIndex,
                  `rValue_${disc.id}`
                );
              },
              render: (text, record, index) => {
                return handeRateValueRender(text, record, index, disc.id);
              },
            },
            {
              title: (
                <Tooltip
                  title={`${t(
                    "Communication during cooperation with the respective interface."
                  )}`}
                >
                  {`K`}
                </Tooltip>
              ),
              dataIndex: `kValue_${disc.id}`,
              key: `K_${disc.id}`,
              align: "center",
              width: 30,
              onHeaderCell: rateHeaderCellConfig,
              onCell: (record, rowIndex) => {
                return handleRateValueCell(
                  record,
                  rowIndex,
                  `kValue_${disc.id}`
                );
              },
              render: (text, record, index) => {
                return handeRateValueRender(text, record, index, disc.id);
              },
            },
          ],
        });
      });
    setColumns(_cols);
  };

  const data = [];
  props.result?.metaData?.disciplines
    ?.filter((c) => c.inAnalysis)
    ?.filter((c) => c.inRow == null || c.inRow == true)
    .map((disc) => {
      let tt = {
        key: `disc_from_${disc.id}`,
        fromDisciplineId: disc.id,
        name: disc.name,
        foreColor: disc.frontendColor,
        backColor: disc.backColor,
      };
      props.result?.viewResult?.averageVotes
        .filter((c) => c.fromDisciplineId == disc.id)
        .map((vote) => {
          tt[`kValue_${vote.toDisciplineId}`] = vote.kRate;
          tt[`qValue_${vote.toDisciplineId}`] = vote.qRate;
          tt[`rValue_${vote.toDisciplineId}`] = vote.rRate;
        });
      data.push(tt);
    });
  let hiddenRows = props.result?.metaData?.disciplines
    .filter((c) => c.inAnalysis)
    .filter((c) => c.inRow == false)
    .map((c) => {
      return c.id;
    });
  let avg = {
    key: "avg_breaks",
    name: ` ${t("Average")}`,
  };
  let totalAvg = {
    key: "total_avg",
    name: `${t("Total Average")}`,
  };
  props.result?.metaData?.disciplines
    .filter((c) => c.inAnalysis)
    .map((disc) => {
      var kValue = 0;
      var qValue = 0;
      var rValue = 0;
      var length = 0;
      let llK = props.result?.viewResult?.averageVotes.filter(
        (c) =>
          c.toDisciplineId == disc.id &&
          c.fromDisciplineId != disc.id &&
          !hiddenRows.includes(c.fromDisciplineId) &&
          c.kRate > 0
      );

      if (llK?.length > 0) {
        avg[`kValue_${disc.id}`] =
          llK?.reduce((total, r) => total + r.kRate, 0) / llK.length;
        kValue = llK?.reduce((total, r) => total + r.kRate, 0) / llK.length;
        length++;
      } else {
        avg[`kValue_${disc.id}`] = -1;
      }

      let llq = props.result?.viewResult?.averageVotes.filter(
        (c) =>
          c.toDisciplineId == disc.id &&
          c.fromDisciplineId != disc.id &&
          !hiddenRows.includes(c.fromDisciplineId) &&
          c.qRate > 0
      );

      if (llq?.length > 0) {
        avg[`qValue_${disc.id}`] =
          llq?.reduce((total, r) => total + r.qRate, 0) / llq.length;
        qValue = llq?.reduce((total, r) => total + r.qRate, 0) / llq.length;
        length++;
      } else {
        avg[`qValue_${disc.id}`] = -1;
      }

      let llr = props.result?.viewResult?.averageVotes.filter(
        (c) =>
          c.toDisciplineId == disc.id &&
          c.fromDisciplineId != disc.id &&
          !hiddenRows.includes(c.fromDisciplineId) &&
          c.rRate > 0
      );

      if (llr?.length > 0) {
        avg[`rValue_${disc.id}`] =
          llr?.reduce((total, r) => total + r.rRate, 0) / llr.length;
        rValue = llr?.reduce((total, r) => total + r.rRate, 0) / llr.length;
        length++;
      } else {
        avg[`rValue_${disc.id}`] = -1;
      }

      totalAvg[`kValue_${disc.id}`] = (rValue + qValue + kValue) / length;
    });
  data.push(avg);
  data.push(totalAvg);

  return (
    <Spin spinning={props.result?.isLoadingMatrix}>
      {" "}
      <Table
        columns={columns}
        dataSource={data}
        bordered
        size="small"
        className="ia_table"
        pagination={false}
        // scroll={{ x: 'calc(90wh - 50px)', y: "calc(100vh - 310px)" }}
        scroll={{ x: "calc(90wh - 50px)", y: `${widthTable}px` }}
      />
      <br />
      <Space direction="horizontal">
        <Badge color={`${redColor}`} text={`${t("Very low")}(1,2,3)`} />
        {/* <Badge color={`${orangeColor}`} text={`${t("Low")}[2-4]`} /> */}
        <Badge color={`${yellowColor}`} text={`${t("Sufficient")}(4,5,6)`} />
        <Badge color={`${lightGreenColor}`} text={`${t("High")}(7,8)`} />
        <Badge color={`${greenColor}`} text={`${t("Very high")}(9,10)`} />
      </Space>
      <br />
      <Space direction="horizontal">
        <Badge color="black" text={`Q - ${t("Quality Of Work Results")}`} />
        <Badge color="black" text={`R - ${t("Response Time")}`} />
        <Badge color="black" text={`K - ${t("Communication")}`} />
      </Space>
    </Spin>
  );
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    result: state.interfaceAnalysis,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IATable);
