import { ActionTypes } from "../actionsTypes";
import { IDisciplinesState } from "./IGeneralState";

const initialState: IDisciplinesState = {
    isDisciplinesLoading: false,
    disciplinesResult: null,
    isModalLoading: false,
    modalResponse: null,
};

const DisciplinesReducer = (state = initialState, action: any): IDisciplinesState => {
    switch (action.type) {
        case ActionTypes.CHANGE_DISCIPLINE_LOADER:
          return {
            ...state,
            isDisciplinesLoading: action.isLoading,
          };
        case ActionTypes.SAVE_DISCIPLINES:
          return {
            ...state,
            disciplinesResult: action.result,
          };
        case ActionTypes.SetModalLoader:
          return {
            ...state,
            isModalLoading: action.result
          };
        case ActionTypes.SetModalResponse:
          return {
            ...state,
            modalResponse: action.result
          };
        default:
          break;
      }
      return state;
};
export default DisciplinesReducer;
    