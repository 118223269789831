import { ActionTypes } from "../actionsTypes";
import { IConsultantUsersState } from "./IGeneralState";

const initialState: IConsultantUsersState = {
  isConsultantLoading: false,
  consultantUsersResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const ConsultantUsersReducer = (
  state = initialState,
  action: any
): IConsultantUsersState => {
  switch (action.type) {
    case ActionTypes.CHANGE_CONSULTANT_USER_LOADER:
      return {
        ...state,
        isConsultantLoading: action.isLoading,
      };
    case ActionTypes.SAVE_CONSULTANT_USERS:
      return {
        ...state,
        consultantUsersResult: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      };
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      };
    default:
      break;
  }
  return state;
};

export default ConsultantUsersReducer;