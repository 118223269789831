import { ActionTypes } from "../actionsTypes";
import { IBmProjectListState } from "./IGeneralState";
const initialState: IBmProjectListState = {
  isBmProjectListLoading: false,
  bmProjectListResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const BmProjectListReducer = (
  state = initialState,
  action: any
): IBmProjectListState => {
  switch (action.type) {
    case ActionTypes.CHANGE_BMPROJECT_LIST_LOADER:
      return {
        ...state,
        isBmProjectListLoading: action.isLoading,
      };
    case ActionTypes.SAVE_BMPROJECT_LIST:
      return {
        ...state,
        bmProjectListResult: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      };
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      };

    default:
      break;
  }
  return state;
};

export default BmProjectListReducer;