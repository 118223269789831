import { Col, Collapse, Form, Input, InputNumber, List, Row, Space, Table } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { put } from "redux-saga/effects";
import { ISABestPractice, ISelfAssessmentView } from "../../../../Models/SelfAssessment/ISelfAssessmentView";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState, ISelfAssessmentToolState } from "../../../../Redux/reducers/IGeneralState";
import { formatValue, inputFormatter, inputParser } from "../../../../Utils/helperFunctions";
import SABestPracticeComments from "./SABestPracticeComments";
import SABestPracticeHistoryModal from "./SABestPracticeHistoryModal/SABestPracticeHistoryModal";
import { t } from "i18next";
import i18n from "../../../../i18n"; 
const { Panel } = Collapse
interface IProps {
    item: ISABestPractice;
    onChange: (value, id) => any;
    onChangeComment: (value, id) => any;
}
function SASubleverBestPracticeItem(props: IProps) {
    const [importanceValue, setImportanceValue] = useState<number>();
    const [commentValue, setCommentValue] = useState('');
    useEffect(() => {
        setImportanceValue(props.item.importance);
        setCommentValue(props.item.comment);
    }, [])
    return <> <Row>
        <Col span={20}>
            {props.item.name}
        </Col>
        <Col span={4}>
            <SABestPracticeHistoryModal id={props.item.id} />
        </Col>
    </Row>

        <Row>
            <Col span={24}>
                <Space.Compact block direction="horizontal">
                    <InputNumber
                        key={`meta_of_sa_input_${props.item?.id}`}
                        // formatter={(value) => inputFormatter(value, i18n.language)}
                        // parser={(x) => inputParser(x, i18n.language)}
                        decimalSeparator={i18n.language == 'en'? ".": ","}                        
                        precision={1}
                        placeholder="#"
                        value={importanceValue}
                        min={1} max={5}
                        onChange={(v) => {
                            setImportanceValue(v);
                            props.onChange(v, props.item.id)
                        }}
                    />
                    <TextArea
                        key={`meta_of_sa_txt_${props.item?.id}`}
                        className="text-area-space-compact" value={commentValue} autoSize rows={1} placeholder={`${t("Leave your comment")}`} maxLength={1500}
                        onChange={(e) => {
                            setCommentValue(e.target.value);
                            props.onChangeComment(e.target.value, props.item.id);
                        }}
                    />
                </Space.Compact>
            </Col>
        </Row>
    </>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SASubleverBestPracticeItem);