export enum ActionTypes {
  SetClearForm = "SetClearForm",
  ToResetClearForm = "ToResetClearForm",

  ChangeHomeLoader = "ChangeHomeLoader",
  SetCommonResponse = "SetCommonResponse",
  BenchmarkProjectsLoader = "BenchmarkProjectsLoader",
  SETISLOADINGTOTRUE = "SETISLOADINGTOTRUE",
  GetProjectsForEditLevers = "GetProjectsForEditLevers",
  GetProjectsForEditEbit = "GetProjectsForEditEbit",
  SetProjectsForEditLevers = "SetProjectsForEditLevers",
  SetProjectsForEditEbit = "SetProjectsForEditEbit",
  StartRotate = "Startrotate",
  StopRotate = "Stoprotate",

  GETPROJECTS = "GETPROJECTS",

  ERRORGETPROJECTS = "ERRORGETPROJECTS",
  CURRENTPROJECTCHANGEDSUCCESSFULLY = "CURRENTPROJECTCHANGEDSUCCESSFULLY",
  CurrentProjectChangeFailure = 'CurrentProjectChangeFailure',
  resetChangeCurrentProjectFailure = 'resetChangeCurrentProjectFailure',
  CHANGECURRENTPROJECTREMOTLY = "CHANGECURRENTPROJECTREMOTLY",
  CHANGEPROJECTWILLUNMOUNT = "CHANGEPROJECTWILLUNMOUNT",
  SETCHANGINGCURRENTPROJECT = "SETCHANGINGCURRENTPROJECT",
  SETSAVINGLEVERSPEERS = "SETSAVINGLEVERSPEERS",

  SAVELEVERSPEERS = "SAVELEVERSPEERS",

  UpdatePeers = 'UpdatePeers',
  SAVEEBITPEERS = "SAVEEBITPEERS",

  SETSAVINGEBITPEERS = "SETSAVINGEBITPEERS",


  UPDATEFILTER = "UPDATEFILTER",
  SETINITIALDPROJECTSCOLS = "SETINITIALDPROJECTSCOLS",
  CHNGDPROJECTCOLVISIBIITY = "CHNGDPROJECTCOLVISIBIITY",
  CHANGECOLSORDER = "CHANGECOLSORDER",

  //Dprojects
  GETDPROJECTS = "GETDPROJECTS",
  SAVEDPROJECTS = "SAVEDPROJECTS",
  SETDPROJECTS = "SETDPROJECTS",
  UPLOADDPROJECT = "UPLOADDPROJECT",
  UPLOADINGDPROJECT = "UPLOADINGDPROJECT",
  SETADDINGDPROJECT = "SETADDINGDPROJECT",
  FETCHDPROJECTCOMMENTS = "FETCHDPROJECTCOMMENTS",
  FETCHINGDPROJECTCOMMENTS = "FETCHINGDPROJECTCOMMENTS",
  SAVEDPROJECTCOMMENTS = "SAVEDPROJECTCOMMENTS",
  UPOLADDPROJECTCOMMENT = "UPOLADDPROJECTCOMMENT",
  UPLOADINGDPROJECTCOMMENT = "UPLOADINGDPROJECTCOMMENT",
  SETADDINGDPROJECTCOMMENT = "SETADDINGDPROJECTCOMMENT",
  CANCELADDCOMMENT = "CANCELADDCOMMENT",
  UPDATEDPROJECTCOMMENT = "UPDATEDPROJECTCOMMENT",
  UPDATINGCOMMENT = "UPDATINGCOMMENT",
  DELETECOMMENT = "DELETECOMMENT",
  DELETEINGCOMMENT = "DELETEINGCOMMENT",
  UPDATEDPROJECT = "UPDATEDPROJECT",
  UPDATINGDPROJECT = "UPDATINGDPROJECT",
  DELETEDPROJECT = "DELETEDPROJECT",
  DLTINGDPROJECT = "DLTINGDPROJECT",
  GETDPROJECTSTYPES = "GETDPROJECTSTYPES",
  GETINFTYPES = "GETINFTYPES",
  DPROJECTTYPESFSUCCESS = "DPROJECTTYPESFSUCCESS",
  GetDProjectsProgressValue = "GetDProjectsProgressValue",
  SaveDprojectsProgress = "SaveDprojectsProgress",

  FILTERDPROJECTSCOLSBYLEVERID = "FILTERDPROJECTSCOLSBYLEVERID",

  FetchingDprojects = "FetchingDprojects",
  FetchingDprojectComments = "FetchingDprojectComments",
  GetIncludeInLevers = "GetIncludeInLevers",
  SaveDprojectsIdsIncludedInLevers = "SaveDprojectsIdsIncludedInLevers",
  UploadIncludeInLevers = "UploadIncludeInLevers",
  UploadingIncludeInLevers = "UploadingIncludeInLevers",
  GettingIncludeInLevers = "GettingIncludeInLevers",
  SortCommentsAssending = "SortCommentsAssending",
  SortCommentsDescending = "SortCommentsDescending",
  MarkDProjectAnalysisAsComplete = "MarkDProjectAnalysisAsComplete",
  MarkingDProjectAnalysisAsComplete = "MarkingDProjectAnalysisAsComplete",

  //Dproject ColOptions
  SetQuestionnaireCols = "SetQuestionnaireCols",
  HideGeneralInfoPanel = "HideGeneralInfoPanel",
  ShowGeneralInfoPanel = "ShowGeneralInfoPanel",
  HideGeneralInfoThirdLevelItem = "HideGeneralInfoThirdLevelItem",
  ShowGeneralInfoThirdLevelItem = "ShowGeneralInfoThirdLevelItem",
  HideEbitCostsPanel = "HideEbitCostsPanel",
  ShowEbitCostsPanel = "ShowEbitCostsPanel",
  HideEbitCostsSecondLevelItem = "HideEbitCostsSecondLevelItem",
  ShowEbitCostsSecondLevelItem = "ShowEbitCostsSecondLevelItem",
  HideEbitCostsThirdLevelItem = "HideEbitCostsThirdLevelItem",
  ShowEbitCostsThirdLevelItem = "ShowEbitCostsThirdLevelItem",
  HideQuestionnairePanel = "HideQuestionnairePanel",
  ShowQuestionnairePanel = "ShowQuestionnairePanel",
  ShowLeverItem = "ShowLeverItem",
  HideLeverItem = "HideLeverItem",
  HideSubLeverItem = "HideSubLeverItem",
  ShowSubLeverItem = "ShowSubLeverItem",
  ShowSecondLevelItem = "ShowSecondLevelItem",
  HideSecondLevelItem = "HideSecondLevelItem",
  HideThirdLevelItem = "HideThirdLevelItem",
  ShowThirdLevelItem = "ShowThirdLevelItem",
  DragGeneralInfoThirdItemEnd = "DragGeneralInfoThirdItemEnd",
  DragEbitCostsSecondLevelEnd = "DragEbitCostsSecondLevelEnd",
  DragEbitCostsThirdLevelEnd = "DragEbitCostsThirdLevelEnd",
  DragQuestionnairesLeversEnd = "DragQuestionnairesLeversEnd",
  DragQuestionnairesSubLeversEnd = "DragQuestionnairesSubLeversEnd",
  DragQuestionnairesSecondLevelEnd = "DragQuestionnairesSecondLevelEnd",
  DragQuestionnairesThirdLevelEnd = "DragQuestionnairesThirdLevelEnd",
  DragFirstLevelEnd = "DragFirstLevelEnd",

  //FrontLoading tool actions types
  GetFlProjects = "GetFlProjects",
  SaveFlProject = "SaveFlProject",
  SaveingFlProject = "SaveingFlProject",
  GetFlTemplates = "GetFlTemplates",
  GetingFlTemplates = "GetingFlTemplates",
  SaveFlTemplates = "SaveFlTemplates",
  GettingFlProjects = "GettingFlProjects",
  SaveFlProjects = "SaveFlProjects",
  ShowAddFlProjectForm = "ShowAddFlProjectForm",
  GetToBeEdittedFlProject = "GetToBeEdittedFlProject",
  GettingToBeEdittedFlProject = "GettingToBeEdittedFlProject",
  SaveFlToolToBeEdittedProject = "SaveFlToolToBeEdittedProject",
  EditFlProject = "EditFlProject",
  EdittingFlProject = "EdittingFlProject",
  DeletFlProject = "DeletFlProject",
  DeletingFlProject = "DeletingFlProject",
  SelectFlProjectForConsultant = "SelectFlProjectForConsultant",
  SelectingFlProjectForConsultant = "SelectingFlProjectForConsultant",
  UpdateCurrentFlProjectId = "UpdateCurrentFlProjectId",
  GetFlProjectDetails = "GetFlProjectDetails",
  GettingFlProjectDetails = "GettingFlProjectDetails",
  SaveFlProjectDetails = "SaveFlProjectDetails",
  UploadPhase = 'UploadPhase',
  UploadingPhase = "UploadingPhase",
  UploadMilestone = "UploadMilestone",
  UploadingMilestone = "UploadingMilestone",
  GetPhaseValidDates = "GetPhaseValidDates",
  GettingPhaseValidDates = "GettingPhaseValidDates",
  SavePhaseValidDates = "SavePhaseValidDates",
  ChangeMilestoneActualDateMax = "ChangeMilestoneActualDateMax",
  ChangeMilestonePlannedDateMax = "ChangeMilestonePlannedDateMax",
  GetRTDCurveData = "GetRTDCurveData",
  GettingRTDCurveData = "GettingRTDCurveData",
  SaveRTDCurveData = "SaveRTDCurveData",
  GetBestPracticesCurves = "GetBestPracticesCurves",
  GettingBestPracticesCurves = "GettingBestPracticesCurves",
  SaveBestpracticeCurveData = "SaveBestpracticeCurveData",
  ResetRTDCurveDataForComarativeProject = "ResetRTDCurveDataForComarativeProject",
  ShowConfirmationMsg = "ShowConfirmationMsg",
  SaveToBeEdittedFlProject = "SaveToBeEdittedFlProject",
  ConfirmEditFlProject = "ConfirmEditFlProject",
  ConfirmingEditFlProject = "ConfirmingEditFlProject",
  ResetEditConfirmationAttributes = "ResetEditConfirmationAttributes",
  GetAboutCurrentFlProject = "GetAboutCurrentFlProject",
  GettingAboutCurrentFlProject = "GettingAboutCurrentFlProject",
  SaveCurrentFlProject = "SaveCurrentFlProject",
  GetFrontloadingPrgress = "GetFrontloadingPrgress",
  SaveFrontloadingPrgress = "SaveFrontloadingPrgress",
  MarkFlProjectAsComplete = "MarkFlProjectAsComplete",
  MarkingFlProjectAsComplete = "MarkingFlProjectAsComplete",
  DeleteMilestone = "DeleteMilestone",
  DeletingMilestone = "DeletingMilestone",
  ChangeConfirmationMsg = "ChangeConfirmationMsg",
  ExportFrontloadingToExcel = "ExportFrontloadingToExcel",
  // EBIT actionTypes
  SCAlECHART = "SCAlECHART",
  GetEbitInfo = "GetEbitInfo",
  GetRndInfo = "GetRndInfo",
  SaveRndInfo = "SaveRndInfo",
  SaveRecivedRndUnits = "SaveRecivedRndUnits",
  GetRecivedRndUnits = "GetRecivedRndUnits",
  UpdateRdUnitsRow = "UpdateRdUnitsRow",
  SaveRdUnitsRow = "SaveRdUnitsRow",
  changeEbitLoader = "changeEbitLoader",
  CreateNewUnit = "CreateNewUnit",
  GetDprojectsForEbit = "GetDprojectsForEbit",
  SaveDprojectsForEbit = "SaveDprojectsForEbit",
  saveDProjectsForSelectedProject = "saveDProjectsForSelectedProject",
  CreateQk = "CreateQk",
  CreateQkNewDproject = "CreateQkNewDproject",
  ImportQKTable = "ImportQKTable",
  UpdateQk = "UpdateQk",
  DeleteQk = "DeleteQk",
  DownloadEstimationReport = "DownloadEstimationReport",
  DownloadDisciplinesVsBestPractice = "DownloadDisciplinesVsBestPractice",
  DownloadDisciplinesExcel = "DownloadDisciplinesExcel",
  DownloadFullExcelReport = "DownloadFullExcelReport",
  DownloadPresentation = "DownloadPresentation",
  DownloadRDTableExcel = "DownloadRDTableExcel",
  DownloadCostTypesExcel = "DownloadCostTypesExcel",
  CreateInno = "CreateInno",
  CreateInnoNewDproject = "CreateInnoNewDproject",
  ImportInnoTable = "ImportInnoTable",
  UpdateInno = "UpdateInno",
  DeleteInno = "DeleteInno",
  //AddCommentToLeverQuestionSuccess = 'AddCommentToLeverQuestionSuccess'
  CreateMC = "CreateMC",
  CreateMCNewDproject = "CreateMCNewDproject",
  ImportMCTable = "ImportMCTable",
  UpdateMC = "UpdateMC",
  DeleteMC = "DeleteMC",

  CreateCOD = "CreateCOD",
  CreateCODNewDproject = "CreateCODNewDproject",
  ImportCODTable = "ImportCODTable",
  UpdateCOD = "UpdateCOD",
  DeleteCOD = "DeleteCOD",
  DownloadCODTable = "DownloadCODTable",
  DownloadHKTable = "DownloadHKTable",
  DownloadQKTable = "DownloadQKTable",
  DownloadInnoTable = "DownloadInnoTable",
  DeleteUnit = "DeleteUnit",
  UpdateUnit = "UpdateUnit",
  SaveUnitCostsChanges = "SaveUnitCostsChanges",
  SaveEbitEstimation = "SaveEbitEstimation",
  GetEbitEstimation = "GetEbitEstimation",
  SetEbitEstimation = "SetEbitEstimation",
  GetEbitEvaluation = "GetEbitEvaluation",
  SetEbitEvaluation = "SetEbitEvaluation",
  GetEbitBenchmark = "GetEbitBenchmark",
  SetEbitBenchmark = "SetEbitBenchmark",
  GetEBITProgress = "GetEBITProgress",
  SetEbitProgress = "SetEbitProgress",

  // Activity
  GetActivityAnalyzeResult = "GetActivityAnalyzeResult",
  SetActivityAnalyzeResult = "SetActivityAnalyzeResult",
  changeActivityLoader = "changeActivityLoader",
  SetActivityProgressValue = "SetActivityProgressValue",
  setActivityViewResult = "setActivityViewResult",
  CHANGE_PROFILE_VERSIONS_LOADER = "CHANGE_PROFILE_VERSIONS_LOADER",
  SAVE_PROFILE_VERSIONS = "SAVE_PROFILE_VERSIONS",
  SAVE_PROFILE_VERSION_Details = "SAVE_PROFILE_VERSION_Details",
  GET_BM_PROJECT_MEMBERS = "GET_BM_PROJECT_MEMBERS",
  SET_BM_PROJECT_MEMBERS = "SET_BM_PROJECT_MEMBERS",
  //Activity Survey
  GetSurveyDataForParticipant = "GetSurveyDataForParticipant",
  // GettingSurveyDataForParticipant = "GettingSurveyDataForParticipant",
  saveActivitySurveyData = "saveActivitySurveyData",
  UploadParticipantAnswer = "UploadParticipantAnswer",
  //UploadingParticipantAnswer = "UploadingParticipantAnswer",
  //
  //Activity Project Tasks
  GetActivityProjectTasks = "GetActivityProjectTasks",
  GettingActivityProjectTasks = "GettingActivityProjectTasks",
  CreateActivityProfileAnalysis = "CreateActivityProfileAnalysis",
  CancelActivityProfileAnalysis = "CancelActivityProfileAnalysis",
  StartActivityProfileAnalysis = "StartActivityProfileAnalysis",
  EndActivityProfileAnalysis = "EndActivityProfileAnalysis",
  CompleteActivityProfileAnalysis = "CompleteActivityProfileAnalysis",
  ExportActivityProfileAnalysis = "ExportActivityProfileAnalysis",
  ReviewActivityVote = "ReviewActivityVote",
  RemoveActivityVote = "RemoveActivityVote",
  GetActivityProfileAnalysis = "GetActivityProfileAnalysis",
  AddActivityToolsTasksToGroup = "AddActivityToolsTasksToGroup",
  GetActivityProgressValue = "GetActivityProgressValue",
  UpdateActivityToolsTask = "UpdateActivityToolsTask",
  DeleteActivityToolTask = " DeleteActivityToolTask",

  GET_PROFILE_VERSION_TASKS = "GET_PROFILE_VERSION_TASKS",
  SET_PROFILE_VERSION_TASKS = "SET_PROFILE_VERSION_TASKS",
  SET_CURRENT_PROFILE_ID = "SET_CURRENT_PROFILE_ID",
  GET_CURRENT_PROFILE_VERSIONS = "GET_CURRENT_PROFILE_VERSIONS",
  SET_ACTIVITY_PROFILE_VERSIONS = "SET_ACTIVITY_PROFILE_VERSIONS",
  //Levers
  LeversUnmount = "LeversUnmount",
  GetLevers = "GetLevers",
  ChangeLever = "ChangeLever",
  ChangeLevers = "ChangeLevers",
  ChangeEasyAccessLevers = "ChangeEasyAccessLevers",
  SaveGetLeversResults = "SaveGetLeversResults",
  SaveGetEasyAccessLeversResults = "SaveGetEasyAccessLeversResults",
  ChangeSubQuestionValue = "ChangeSubQuestionValue",
  AddCommentToQuestion = "AddCommentToQuestion",

  ExportSelectedChartsToExcel = "ExportSelectedChartsToExcel",
  ExportAllChartsToExcel = "ExportAllChartsToExcel",
  ExportingSelectedChartsToExcel = "ExportingSelectedChartsToExcel",
  ExportingAllChartsToExcel = "ExportingAllChartsToExcel",

  ExportHeatMap = 'ExportHeatMap',
  ExportingHeatMap = 'ExportingHeatMap',
  //AddCommentToLeverQuestionSuccess = 'AddCommentToLeverQuestionSuccess'

  //Auth
  Login = "Login",
  VerifyLogin = "VerifyLogin",
  SaveLoginResult = "SaveLoginResult",
  LoginResultError = "LoginResultError",
  AuthUnmount = "AuthUnmount",
  SaveVerifyLoginResult = "SaveVerifyLoginResult",

  //General
  GetHomePage = "GetHomePage",
  UpdateBmProjectExtraInfo= "UpdateBmProjectExtraInfo",
  SaveHomePage = "SaveHomePage",
  SaveAllProjects = "SaveAllProjects",
  SaveAllBenchmarkProjects = "SaveAllBenchmarkProjects",
  GetAllBenchmarkProjects = "GetAllBenchmarkProjects",
  GetBmProject = "GetBmProject",
  SaveBmProject = "SaveBmProject",
  //  StartPartialLoader = "StartPartialLoader",
  StopPartialLoader = "StopPartialLoader",
  ChangeFullScreen = "ChangeFullScreen",
  SetChangeFullScreen = "SetChangeFullScreen",
  //Customer mng
  CHANGE_CUSTOMER_LODAER = "CHANGE_CUSTOMER_LODAER",
  SAVE_CUSTOMERS = "SAVE_CUSTOMERS",
  GET_CUSTOMERS = "GET_CUSTOMERS",
  changeLeverLoader = "changeLeverLoader",
  SetGetLeversResults = "SetGetLeversResults",
  SetGetLeverResult = "SetGetLeverResult",
  SetSelectedQuestions = "SetSelectedQuestions",
  SetAllSelectedQuestions = "SetAllSelectedQuestions",
  ChangeAllSelectedQuestions = "ChangeAllSelectedQuestions",
  ChangeSelectedQuestions = "ChangeSelectedQuestions",
  SaveAllLevers = "SaveAllLevers",
  SaveAllLeversByConsultant = "SaveAllLeversByConsultant",
  ReviewQuestionByConsultant = "ReviewQuestionByConsultant",
  AddcommentToQuestionByConsultant = "AddcommentToQuestionByConsultant",
  EditcommentToQuestionByConsultant = "EditcommentToQuestionByConsultant",
  DeletecommentToQuestionByConsultant = "DeletecommentToQuestionByConsultant",
  CREATE_CUSTOMER = "CREATE_CUSTOMER",
  DELETE_CUSTOMER = "DELETE_CUSTOMER",
  UPDATE_CUSTOMER = "UPDATE_CUSTOMER",
  DELETE_IMAGE_CUSTOMER = "DELETE_IMAGE_CUSTOMER",
  //  SetEasyAccessLeversResults = "SetEasyAccessLeversResults",
  // Question Mangment
  CHANGE_QUESTION_LOADER = "CHANGE_QUESTION_LODAER",
  SAVE_QUESTIONS = "SAVE_QUESTIONS",
  GET_QUESTIONS = "GET_QUESTIONS",
  UPDATE_QUESTION = "UPDATE_QUESTION",
  ChageLeversInCurrentProject = "ChageLeversInCurrentProject",
  UpdateLeversInCurrentProject = "UpdateLeversInCurrentProject",
  AddDprojectLocally = "AddDprojectLocally",
  UpdateDprojectsLocally = "UpdateDprojectsLocally",
  // BMproject Mangment
  CHANGE_BM_PROJECT_LOADER = "CHANGE_BM_PROJECT_LODAER",
  SAVE_BM_PROJECTS = "SAVE_BM_PROJECTS",
  GET_BM_PROJECTS = "GET_BM_PROJECTS",
  UPDATE_BM_PROJECT = "UPDATE_BM_PROJECT",
  CREATE_BM_PROJECT = "CREATE_BM_PROJECT",
  DELETE_BM_PROJECT = "DELETE_BM_PROJECT",
  COMPLETE_BM_PROJECT = 'COMPLETE_BM_PROJECT',
  REOPEN_BM_PROJECT = 'REOPEN_BM_PROJECT',
  INCLUDE_BM_PROJECT = 'INCLUDE_BM_PROJECT',
  EXCLUDE_BM_PROJECT = 'EXCLUDE_BM_PROJECT',
  //Indusry
  CHANGE_INDUSTRY_LOADER = "CHANGE_INDUSTRY_LODAER",
  SAVE_INDUSTRIES = "SAVE_INDUSTRIES",
  GET_INDUSTRIES = "GET_INDUSTRIES",
  SetCollectionRole = "SetCollectionRole",
  ChangeCollectionRole = "ChangeCollectionRole",
  ChangeQuestionStatuses = "ChangeQuestionStatuses",
  SetQuestionStatuses = "SetQuestionStatuses",
  UPDATE_INDUSRTY = "UPDATE_INDUSRTY",
  CREATE_INDUSRTY = "CREATE_INDUSRTY",
  DELETE_INDUSTRY = "DELETE_INDUSTRY",
  //Indusry
  CHANGE_INDUSTRYSEGMENT_LOADER = "CHANGE_INDUSTRYSEGMENT_LODAER",
  SAVE_INDUSTRYSEGMENTS = "SAVE_INDUSTRYSEGMENTS",
  GET_INDUSTRYSEGMENTS = "GET_INDUSTRYSEGMENTS",
  UPDATE_INDUSRTYSEGMENT = "UPDATE_INDUSRTYSEGMENT",
  CREATE_INDUSRTYSEGMENT = "CREATE_INDUSRTYSEGMENT",
  DELETE_INDUSRTYSEGMENT = "DELETE_INDUSRTYSEGMENT",

  //Customer Department
  CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER = "CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER",
  SAVE_DEPARTMENTS_BY_CUSTOMER = "SAVE_DEPARTMENTS_BY_CUSTOMER",
  GET_DEPARTMENTS_BY_CUSTOMER = "GET_DEPARTMENTS_BY_CUSTOMER",
  UPDATE_DEPARTMENT_BY_BM_CUSTOMER = "UPDATE_DEPARTMENT_BY_BM_CUSTOMER",
  CREATE_DEPARTMENT_BY_BM_CUSTOMER = "CREATE_DEPARTMENT_BY_BM_CUSTOMER",
  DELETE_DEPARTMENT_BY_BM_CUSTOMER = "DELETE_DEPARTMENT_BY_BM_CUSTOMER",

  CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER = " CHANGE_DEPARTMENTS_BY_BM_PORJECT_LOADER",
  SAVE_DEPARTMENTS_BY_BM_PROJECT = "SAVE_DEPARTMENTS_BY_BM_PROJECT",
  GET_DEPARTMENTS_BY_BM_PROJECT = "GET_DEPARTMENTS_BY_BM_PROJECT",
  UPDATE_DEPARTMENT_BY_BM_PROJECT = "UPDATE_DEPARTMENT_BY_BM_PROJECT",
  CREATE_DEPARTMENT_BY_BM_PROJECT = "CREATE_DEPARTMENT_BY_BM_PROJECT",
  DELETE_DEPARTMENT_BY_BM_PROJECT = "DELETE_DEPARTMENT_BY_BM_PROJECT",
  UPDATE_Order_DEPARTMENT_BY_BM_PROJECT = "UPDATE_Order_DEPARTMENT_BY_BM_PROJECT",

  //Customer users
  CHANGE_CUSTOMERUSER_LOADER = "CHANGE_CUSTOMERUSER_LODAER",
  SAVE_CUSTOMERUSERS = "SAVE_CUSTOMERUSERS",
  GET_CUSTOMERUSERS = "GET_CUSTOMERUSERS",
  CREATE_CUSTOMERUSER = "CREATE_CUSTOMERUSER",
  UPDATE_CUSTOMERUSER = "UPDATE_CUSTOMERUSER",
  ACTIVE_CUSTOMERUSER = "ACTIVE_CUSTOMERUSER",
  DEACTIVATE_CUSTOMERUSER = "DEACTIVATE_CUSTOMERUSER",



  SaveCustomerUsersInQuestion = "SaveCustomerUsersInQuestion",
  RemoveCustomerUserFromQuestion = "RemoveCustomerUserFromQuestion",
  RemindCustomerUserForSelectedQuestion = "RemindCustomerUserForSelectedQuestion",
  SaveDataCollectionRoleWithUsersResults = "SaveDataCollectionRoleWithUsersResults",
  GetDataCollectionRoleWithUsers = "GetDataCollectionRoleWithUsers",
  getDataCollectionRolesLoader = "getDataCollectionRolesLoader",
  SetModalResponse = "SetModalResponse",
  SetModalLoader = "SetModalLoader",
  ActivateUser = "ActivateUser",
  ResetPasswordRequest = "ResetPasswordRequest",
  ResetPassword = "ResetPassword",
  //Administrator
  CHANGE_ADMINISTRATOR_LOADER = "CHANGE_ADMINISTRATOR_LODAER",
  SAVE_ADMINISTRATORS = "SAVE_ADMINISTRATORS",
  GET_ADMINISTRATORS = "GET_ADMINISTRATORS",
  CREATE_ADMINISTRATOR = "CREATE_ADMINISTRATOR",
  UPDATE_ADMINISTRATOR = "UPDATE_ADMINISTRATOR",
  //BmProjectList
  GET_BMPROJECT_LIST_BY_ID = "GET_BMPROJECT_LIST_BY_ID",
  CHANGE_BMPROJECT_LIST_LOADER = "CHANGE_BM_LIST_LOADER",
  SAVE_BMPROJECT_LIST = "SAVE_BMPROJECT_LIST",

  // Consultant Users 
  CHANGE_CONSULTANT_USER_LOADER = "CHANGE_CONSULTANT_USER_LODAER",
  SAVE_CONSULTANT_USERS = "SAVE_CONSULTANT_USERS",
  GET_CONSULTANT_USERS = "GET_CONSULTANT_USERS",
  CREATE_CONSULTANT_USER = "CREATE_CONSULTANT_USER",
  UPDATE_CONSULTANT_USER = "UPDATE_CONSULTANT_USER",

  //// resend activation link
  CHANGE_Resend_Activation_Link_LOADER = "CHANGE_Resend_Activation_Link_LOADER",
  SAVE_Resend_Activation_Link = "SAVE_Resend_Activation_Link",
  GET_Resend_Activation_Link = "GET_Resend_Activation_Link",

  // Levers Management For Admin
  CHANGE_LEVERS_FOR_ADMIN_LOADER = "CHANGE_LEVERS_FOR_ADMIN_LODAER",
  SAVE_LEVERS_FOR_ADMIN = "SAVE_LEVERS_FOR_ADMIN",
  GET_LEVERS_FOR_ADMIN = "GET_LEVERS_FOR_ADMIN",

  //Discipline
  CHANGE_DISCIPLINE_LOADER = "CHANGE_DISCIPLINE_LOADER",
  SAVE_DISCIPLINES = "SAVE_DISCIPLINES",
  GET_DISCIPLINES = "GET_DISCIPLINES",
  CREATE_DISCIPLINE = "CREATE_DISCIPLINE",
  UPDATE_DISCIPLINE = "UPDATE_DISCIPLINE",
  DELETE_DISCIPLINE = "DELETE_DISCIPLINE",

  //levers Management For Admin UpdateAll
  UPDATE_LEVER_FOR_ADMIN = "UPDATE_LEVER_FOR_ADMIN",
  UPDATE_SUB_LEVER_FOR_ADMIN = "UPDATE_SUB_LEVER_FOR_ADMIN",
  UPDATE_SUCCESS_FACTOR_FOR_ADMIN = "UPDATE_SUCCESS_FACTOR_FOR_ADMIN",
  UPDATE_KPIS_FOR_ADMIN = " UPDATE_KPIS_FOR_ADMIN",
  UPDATE_QUESTION_FOR_ADMIN = "UPDATE_QUESTION_FOR_ADMIN",
  ForceToPath = "ForceToPath",
  SetForceToPath = "SetForceToPath",

  // Activity Management For Admin
  CHANGE_ACTIVITY_FOR_ADMIN_LOADER = "CHANGE_ACTIVITY_FOR_ADMIN_LOADER",
  SAVE_ACTIVITY_FOR_ADMIN = "SAVE_ACTIVITY_FOR_ADMIN",
  GET_ACTIVITY_FOR_ADMIN = "GET_ACTIVITY_FOR_ADMIN",
  GET_ACTIVITY_PROFILE_VERSIONS = "GET_ACTIVITY_PROFILE_VERSIONS",
  GET_ACTIVITY_PROFILE_VERSION_Detail = "GET_ACTIVITY_PROFILE_VERSION_Detail",
  UPDATE_ACTIVITY_PROFILE_FOR_ADMIN = "UPDATE_ACTIVITY_PROFILE_FOR_ADMIN ",
  UPDATE_ACTIVITY_GROUP_FOR_ADMIN = "UPDATE_ACTIVITY_GROUP_FOR_ADMIN",
  UPDATE_ACTIVITY_TASK_FOR_ADMIN = "UPDATE_ACTIVITY_TASK_FOR_ADMIN",
  CREATE_ACTIVITY_PROFILE_FOR_ADMIN = "CREATE_ACTIVITY_PROFILE_FOR_ADMIN",
  CREATE_ACTIVITY_GROUP_FOR_ADMIN = "CREATE_ACTIVITY_GROUP_FOR_ADMIN",
  CREATE_ACTIVITY_TASK_FOR_ADMIN = "CREATE_ACTIVITY_TASK_FOR_ADMIN",
  CREATE_ACTIVITY_PROFILE_VERSION = "CREATE_ACTIVITY_PROFILE_VERSION",
  CHANGE_STATUS_ACTIVITY_PROFILE = "CHANGE_STATUS_ACTIVITY_PROFILE",
  CHANGE_STATUS_ACTIVITY_GROUP = "CHANGE_STATUS_ACTIVITY_GROUP",
  CHANGE_PROFILE_TASKS_LOADER = "CHANGE_PROFILE_TASKS_LOADER",
  SAVE_PROFILE_TASKS = "SAVE_PROFILE_TASKS",
  GET_ACTIVITY_PROFILE_TASKS = "GET_ACTIVITY_PROFILE_TASKS",
  ADD_EDIT_MAP_TASK_GROUP = "ADD_EDIT_MAP_TASK_GROUP",
  EDIT_MAP_TASK_GROUP = "EDIT_MAP_TASK_GROUP",
  DELETE_TASK_FROM_GROUP = "DELETE_TASK_FROM_GROUP",
  CREATE_NEW_PROFILE_VERSION = "CREATE_NEW_PROFILE_VERSION",
  Update_PROFILE_VERSION = "Update_PROFILE_VERSION",
  CHANGE_STATUS_ACTIVITY_PROFILE_VERSION = "CHANGE_STATUS_ACTIVITY_PROFILE_VERSION",
  DELETE_MAP_GROUP_TO_PROFILE="DELETE_MAP_GROUP_TO_PROFILE",

  //indsutryBestPractice

  CHANGE_INDUSTRY_BEST_PRACTICE_LOADER = "CHANGE_INDUSTRY_BEST_PRACTICE_LODAER",
  SAVE_INDUSTRY_BEST_PRACTICES = "SAVE_INDUSTRY_BEST_PRACTICES",
  GET_INDUSTRY_BEST_PRACTICES = "GET_INDUSTRY_BEST_PRACTICES",
  CREATE_INDUSTRY_BEST_PRACTICE = "CREATE_INDUSTRY_BEST_PRACTICE",
  UPDATE_INDUSTRY_BEST_PRACTICE = "UPDATE_INDUSTRY_BEST_PRACTICE",
  CLEAR_INDUSTRY_BEST_PRACTICE = "CLEAR_INDUSTRY_BEST_PRACTICE",

  //indsutrySegmentBestPractice

  CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LOADER = "CHANGE_INDUSTRY_SEGMENT_BEST_PRACTICE_LODAER",
  SAVE_INDUSTRY_SEGMENT_BEST_PRACTICES = "SAVE_INDUSTRY_SEGMENT_BEST_PRACTICES",
  GET_INDUSTRY_SEGMENT_BEST_PRACTICES = "GET_INDUSTRY_SEGMENT_BEST_PRACTICES",
  CREATE_INDUSTRY_SEGMENT_BEST_PRACTICE = "CREATE_INDUSTRY_SEGMENT_BEST_PRACTICE",
  UPDATE_INDUSTRY_SEGMENT_BEST_PRACTICE = "UPDATE_INDUSTRY_SEGMENT_BEST_PRACTICE",
  CLEAR_INDUSTRY_SEGMENT_BEST_PRACTICE = "CLEAR_INDUSTRY_SEGMENT_BEST_PRACTICE",

  //indsutryDisciplineBestPractice

  CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LOADER = "CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LODAER",
  SAVE_INDUSTRY_DISCIPLINE_BEST_PRACTICES = "SAVE_INDUSTRY_DISCIPLINE_BEST_PRACTICES",
  GET_INDUSTRY_DISCIPLINE_BEST_PRACTICES = "GET_INDUSTRY_DISCIPLINE_BEST_PRACTICES",
  UPDATE_INDUSTRY_DISCIPLINE_BEST_PRACTICE = "UPDATE_INDUSTRY_DISCIPLINE_BEST_PRACTICE",
  CLEAR_INDUSTRY_DISCIPLINE_BEST_PRACTICE = "CLEAR_INDUSTRY_DISCIPLINE_BEST_PRACTICE",

  OpenAccessModal = "OpenAccessModal",
  CloseAccessModal = "CloseAccessModal",
  SetPasswordRequiredModal = "SetPasswordRequiredModal",
  ConfirmPassword = "ConfirmPassword",
  SetLeversProgressValue = "SetLeversProgressValue",
  GetLeversProgressValue = "GetLeversProgressValue",
  GetInterfaceProgressValue = "GetInterfaceProgressValue",
  GetLeversWithEbitChart = "GetLeversWithEbitChart",
  SetSubleversWithEbit = "SetSubleversWithEbit",
  LeversWithEbitChartLoader = "LeversWithEbitChartLoader",
  GetLeversKpiWithEbitChart = "GetLeversKpiWithEbitChart",
  SetKpileversWithEbit = "SetKpileversWithEbit",
  LeversKpiWithEbitChartLoader = "LeversKpiWithEbitChartLoader",
  GetEasyAccessLevers = "GetEasyAccessLevers",
  MarkKpiInEasyAccessLevers = "MarkKpiInEasyAccessLevers",
  SaveEasyAccessLeversByConsultant = "SaveEasyAccessLeversByConsultant",
  //segmentDisciplineBestPractice

  CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LOADER = "CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LODAER",
  SAVE_SEGMENT_DISCIPLINE_BEST_PRACTICES = "SAVE_SEGMENT_DISCIPLINE_BEST_PRACTICES",
  GET_SEGMENT_DISCIPLINE_BEST_PRACTICES = "GET_SEGMENT_DISCIPLINE_BEST_PRACTICES",
  UPDATE_SEGMENT_DISCIPLINE_BEST_PRACTICE = "UPDATE_SEGMENT_DISCIPLINE_BEST_PRACTICE",
  CLEAR_SEGMENT_DISCIPLINE_BEST_PRACTICE = "CLEAR_SEGMENT_DISCIPLINE_BEST_PRACTICE",

  //customer Person to user
  MAKE_CUSTOMER_PERSON_TO_USER = "MAKE_CUSTOMER_PERSON_TO_USER",
  DELETE_PERSON = "DELETE_PERSON",
  //BmProjectForUser Setting
  ASSIGN_NEW_BM_PROJECT_FOR_USER = "ASSIGN_NEW_BM_PORJECT_FOR_USER",
  DELETE_BM_PROJECT_FOR_USER = "DELETE_BM_PROJECT_FOR_USER",


  //frontLoading

  CHANGE_FRONT_LOADING_LOADER = "CHANGE_FRONT_LOADING_LOADER",
  SAVE_FRONT_LOADING = " SAVE_FRONT_LOADING",
  //Template
  GET_ALL_FRONT_LOADING_TEMP = "GET_ALL_FRONT_LOADING_TEMP",
  CREATE_FRONT_LOADING_TEMP = "CREATE_FRONT_LOADING_TEMP",
  UPDATE_FRONT_LOADING_TEMP = "UPDATE_FRONT_LOADING_TEMP",
  DELETE_FRONT_LOADING_TEMP = "DELETE_FRONT_LOADING_TEMP",
  // phase
  CREATE_FRONT_LOADING_TEMP_PHASE = "CREATE_FRONT_LOADING_TEMP_PHASE",
  UPDATE_FRONT_LOADING_TEMP_PHASE = "UPDATE_FRONT_LOADING_TEMP_PHASE",
  DELETE_FRONT_LOADING_TEMP_PHASE = "DELETE_FRONT_LOADING_TEMP_PHASE",

  //help pages
  CHANGE_HELP_PAGE_LOADER = "CHANGE_HELP_PAGE_LOADER",
  SAVE_HELP_PAGE = "SAVE_HELP_PAGE",
  SAVE_HELP_PAGE_FOR_EDIT = "SAVE_HELP_PAGE_FOR_EDIT",
  GET_ALL_HELP_PAGE = "GET_ALL_HELP_PAGE",
  GET_HELP_PAGE_ById = "GET_HELP_PAGE_ById",
  UPDATE_HELP_PAGE = "UPDATE_HELP_PAGE",
  SetHelpPage = "SetHelpPage",
  GetHelpPage = "GetHelpPage",
  GetHelpPageLoader = "GetHelpPageLoader",
  SetHelpPageCode = "SetHelpPageCode",
  ChangeHelpPageCode = "ChangeHelpPageCode",
  //Interface Analysis
  CreateInterfaceAnalysis = "CreateInterfaceAnalysis",
  GetIAView = "GetIAView",
  GetIAMetaData = "GetIAMetaData",
  GetIAViewAnonymous = "GetIAViewAnonymous",
  //InviteCandidateForIA = "InviteCandidateForIA",
  ExcludeFromIA = "ExcludeFromIA",
  StartIARound = "StartIARound",
  EndIARound = "EndIARound",
  CompleteIARound = "CompleteIARound",
  RestartIARound = "RestartIARound",
  CancelIARound = "CancelIARound",
  ReviewAnswerIA = "ReviewAnswerIA",
  ExportIAToExcel = "ExportIAToExcel",
  ChangeIALoaderRounds = "ChangeIALoaderRounds",
  ChangeIALoaderMatrix = "ChangeIALoaderMatrix",
  SaveResponseForAnonymousAnalysisInterface = "SaveResponseForAnonymousAnalysisInterface",
  SaveIAViewResult = "SaveIAViewResult",
  SaveIAMetaData = "SaveIAMetaData",
  SaveIARoundsViewResult = "SaveIARoundsViewResult",
  RefreshIARound = "RefreshIARound",
  RefreshIARoundVote = "RefreshIARoundVote",
  SaveIAViewAnonymousResult = "SaveIAViewAnonymousResult",
  ChangeIALoader = "ChangeIALoader",
  SetInterfaceProgressValue = "SetInterfaceProgressValue",
  isSuccessAnonymousVoting = "isSuccessAnonymousVoting",
  ChangeDepartmentStatusInInterface = "ChangeDepartmentStatusInInterface",
  SetDisipline = "SetDisipline",
  GoToSetDisipline = "GoToSetDisipline",

  //frontLoading Curves
  CHANGE_CURVES_LOADER = "CHANGE_CURVES_LOADER",
  SAVE_CURVES = "SAVE_CURVES",
  GET_ALL_CURVES = 'GET_ALL_CURVES',
  CREATE_CURVE = 'CREATE_CURVE',
  UPDATE_CURVE = 'UPDATE_CURVE',
  DELETE_CURVE = 'DELETE_CURVE',
  //frontLoading CurvePhase
  CREATE_CURVE_PHASE = 'CREATE_CURVE_PHASE',
  UPDATE_CURVE_PHASE = 'UPDATE_CURVE_PHASE',
  DELETE_CURVE_PHASE = 'DELETE_CURVE_PHASE',
  //frontLoading CurvePhasePoint
  CREATE_CURVE_PHASE_POINT = 'CREATE_CURVE_PHASE_POINT',
  UPDATE_CURVE_PHASE_POINT = 'UPDATE_CURVE_PHASE_POINT',
  DELETE_CURVE_PHASE_POINT = 'DELETE_CURVE_PHASE_POINT',

  //user management
  CHANGE_USERS_LOADER = "CHANGE_USERS_LOADER",
  SAVE_USERS = "SAVE_USERS",
  GET_ALL_USERS = "GET_ALL_USERS",
  CREATE_USER = "CREATE_USER",
  UPDATE_USER = "UPDATE_USER",
  ACTIVE_USER = "ACTIVE_USER",
  DEACTIVATE_USER = "DEACTIVATE_USER",

  //Profile User
  CHANGE_PROFILE_LOADER = "CHANGE_PROFILE_LOADER",
  //SAVE_PROFILE = "SAVE_PROFILE",
  //GET_PROFILE = "GET_PORFILE",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  REMOVE_PROFILE_IMAGE = "REMOVE_PROFILE_IMAGE",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",

  //self AssessmentBpTemplate
  CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER = "CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER",
  SAVE_SELF_ASSESSMENT_BP_TEMP = "SAVE_SELF_ASSESSMENT_BP_TEMP",
  GET_SELF_ASSESSMENT_BP_TEMP = "GET_SELF_ASSESSMENT_BP_TEMP",
  CREATE_SELF_ASSESSMENT_BP_TEMP = "CREATE_SELF_ASSESSMENT_BP_TEMP",
  UPDATE_SELF_ASSESSMENT_BP_TEMP = "UPDATE_SELF_ASSESSMENT_BP_TEMP",
  DELETE_SELF_ASSESSMENT_BP_TEMP = "DELETE_SELF_ASSESSMENT_BP_TEMP",

  GetSelfAssessmentView = "GetSelfAssessmentView",
  SetSelfAssessmentView = "SetSelfAssessmentView",
  ChangeSelfAssessmentTopRated = "ChangeSelfAssessmentTopRated",
  ExportSelfAssessntToExcel = "ExportSelfAssessntToExcel",
  SetSelfAssessmentTopRated = "SetSelfAssessmentTopRated",
  SetProgressSelfAssessment = "SetProgressSelfAssessment",
  GetProgressSelfAssessment = "GetProgressSelfAssessment",
  SaveSelfAssessmentView = "SaveSelfAssessmentView",
  ChangeSelfAssessementLoader = "ChangeSelfAssessementLoader",
  ChangeAllSelfAssessmentView = "ChangeAllSelfAssessmentView",

  SAVE_COMPLETION_DETAILS = "SAVE_COMPLETION_DETAILS",
  CHANGE_COMPLETION_DETAILS_LOADER = "CHANGE_COMPLETION_DETAILS_LOADER",
  GET_COMPLETION_DETAILS_BM_PROJECT = 'GET_COMPLETION_DETAILS_BM_PROJECT',


  CREATE_CUSTOMER_DEPARTMENT_USER = "CREATE_CUSTOMER_DEPARTMENT_USER",
  UPDATE_CUSTOMER_DEPARTMENT_USER = "UPDATE_CUSTOMER_DEPARTMENT_USER",
  MAKE_DEPARTMENT_PERSON_TO_USER = "MAKE_DEPARTMENT_PERSON_TO_USER",
  DELETE_DEPARTMENT_PERSON = "DELETE_DEPARTMENT_PERSON",
  ASSIGN_NEW_BM_PROJECT_FOR_DEPARTMENT_USER = "ASSIGN_NEW_BM_PROJECT_FOR_DEPARTMENT_USER",
  DELETE_BM_PROJECT_FOR_DEPARTMENT_USER = "DELETE_BM_PROJECT_FOR_DEPARTMENT_USER",
  ACTIVE_DEPARTMENT_USER = "ACTIVE_DEPARTMENT_USER",
  DEACTIVATE_DEPARTMENT_USER = "DEACTIVATE_DEPARTMENT_USER",

  // Error Page For Admin 
  SAVE_ALL_ERRORS = "SAVE_ALL_ERRORS",
  CHANGE_ALL_ERRORS_LOADER = "CHANGE_ALL_ERRORS_LOADER",
  GET_ALL_ERRORS = 'GET_ALL_ERRORS',
  UPDATE_ERROR = 'UPDATE_ERROR',

  GetPermissions = "GetPermissions",
  SetPermissions = "SetPermissions",
  ChangePermissionLoader = "ChangePermissionLoader",
  SavePermissions = "SavePermissions",
  ChangePermissions = "ChangePermissions",
  SetCurrentProjectPermissionId = "SetCurrentProjectPermissionId",
  ChangeCurrentProjectPermissionId = "ChangeCurrentProjectPermissionId",
  CHANGE_HISTORY_IMPORTANCE_LOADER = "CHANGE_HISTORY_IMPORTANCE_LOADER",
  SAVE_HISTORY_IMPORTANCE = "SAVE_HISTORY_IMPORTANCE",
  GET_HISTORY_IMPORTANCE = "GET_HISTORY_IMPORTANCE",
  // Event Log 
  SAVE_EVENT_LOGS = "SAVE_ALL_EVENT_LOGS",
  CHANGE_EVENT_LOGS_LOADER = "CHANGE_EVENT_LOGS_LOADER",
  GET_ALL_EVENT_LOGS = 'GET_ALL_EVENT_LOGS',
  CHANGE_EVENT_LOGS_FILTERS_LOADER = "CHANGE_EVENT_LOGS_FILTERS_LOADER",
  SAVE_EVENT_LOGS_FILTERS = "SAVE_EVENT_LOGS_FILTERS",
  GET_FILTER_EVENT_LOGS_MENUS = '  GET_FILTER_EVENT_LOGS_MENUS',

  //FrontLoadingDepartment
  CHANGE_FRONT_LOADING_DEPARTMENT_LOADER = "CHANGE_FRONT_LOADING_DEPARTMENT_LOADER",
  SAVE_FRONT_LOADING_DEPARTMENTS = "SAVE_FRONT_LOADING_DEPARTMENTS",
  GET_FRONT_LOADING_DEPARTMENTS = "GET_FRONT_LOADING_DEPARTMENTS",
  CREATE_FRONT_LOADING_DEPARTMENT = "CREATE_FRONT_LOADING_DEPARTMENT",
  UPDATE_FRONT_LOADING_DEPARTMENT = "UPDATE_FRONT_LOADING_DEPARTMENT",
  DELETE_FRONT_LOADING_DEPARTMENT = "DELETE_FRONT_LOADING_DEPARTMENT",

  ImportDepartment = "ImportDepartment",
  ImportCustomerUser = "ImportCustomerUser",
  GetIARoundsView = "GetIARoundsView"
}


export enum HelpPageCodes {
  ChangeProject = "change project",
  CurrentProject = "current project",
  EbitPartner = "ebit partner",
  LeverPartner = "lever partner"
}