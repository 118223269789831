import { all } from "redux-saga/effects";
import AuthenticationSagas from "./AuthenticationSagas";
import DProjectsSagas from "./DProjectsSagas";
import EbitPageSagas from "./EbitPageSagas";
import GeneralSagas from "./GeneralSagas";
import leversSagas from "./leversSagas";
import CustomerMngSagas from "./CustomerMngSagas";
import QuestionSagas from "./QuestionSagas";
import BmProjectSagas from "./BmProjectSagas";
import industrySagas from "./industrySagas";
import industrySegmentSagas from "./IndustrySegmentSagas";
import CustomerUsersSagas from "./CustomerUsersSagas";
import BmProjectListByCustomerIdSagas from "./BmProjectListByCustomerIdSaga";
import SegmentBestPracticeDisciplineSagas from "./SegmentDisciplineBPSagas";
import industryDisciplineBPSagas from "./industryDisciplineBPSagas";
import IndustrySegmentBestPracticeSagas from "./IndustrySegmentBestPracticeSagas";
import IndustryBestPracticeSagas from "./IndustryBestPracticeSagas";
import disciplinesSagas from "./disciplinesSagas";
import consultantUsersSagas from "./consultantUsersSagas";
import AdministratorSagas from "./AdministratorSagas";
import LeversMngSaga from "./LeversMngSaga";
import ActivityMngSaga from "./ActivityMngSaga"
import FrontloadingTemplateSagas from "./FrontloadingTemplateSagas";
import FrontLoadingToolSagas from "./FrontLoadingToolSagas";
import HelpPagesForAdminSagas from "./HelpPageForAdminSagas";
import InterfaceAnalysisSaga from "./InterfaceAnalysisSaga";
import FrontloadinCurveSagas from "./FrontloadinCurveSagas";
import UserManagementSagas from "./UserManagmentSagas";
import ProfileForUsersSagas from "./ProfileForUsersSagas";
import DepartmentsSagas from "./DepartmentsSagas";
import ActivitySagas from "./ActivitySaga";
import DepartmentsByCustomerSagas from "./DepartmentsByCustomerSagas";
import SelfAssessmentTempSagas from "./SelfAssessmentBpTempSagas";
import SelfAssessmetToolSagas from "./SelfAssessmetToolSagas";
import BmProjectCompletionSagas from "./BmProjectCompletionSagas";
import DepartmentUsersSagas from "./DepartmentUsersSaga";
import ErrorPagesForAdminSagas from "./ErrorPageForAdminSagas";
import PermissionsSagas from "./PermissionsSagas";
import EventLogsForAdminSagas from "./EventLogsForAdminSagas";
import FrontLoadingDepartmentSagas from "./FrontLoadingDepartmentSagas";

export default function* rootSaga() {
  yield all([
    leversSagas(),
    AuthenticationSagas(),
    GeneralSagas(),
    DProjectsSagas(),
    EbitPageSagas(),
    ActivitySagas(),
    CustomerMngSagas(),
    QuestionSagas(),
    BmProjectSagas(),
    industrySagas(),
    industrySegmentSagas(),
    DepartmentsSagas(),
    CustomerUsersSagas(),
    BmProjectListByCustomerIdSagas(),
    consultantUsersSagas(),
    AdministratorSagas(),
    LeversMngSaga(),
    ActivityMngSaga(),
    disciplinesSagas(),
    IndustryBestPracticeSagas(),
    IndustrySegmentBestPracticeSagas(),
    industryDisciplineBPSagas(),
    SegmentBestPracticeDisciplineSagas(),
    FrontloadingTemplateSagas(),
    FrontLoadingToolSagas(),
    HelpPagesForAdminSagas(),
    InterfaceAnalysisSaga(),
    FrontloadinCurveSagas(),
    UserManagementSagas(),
    ProfileForUsersSagas(),
    DepartmentsByCustomerSagas(),
    SelfAssessmentTempSagas(),
    SelfAssessmetToolSagas(),
    BmProjectCompletionSagas(),
    DepartmentUsersSagas(),
    ErrorPagesForAdminSagas(),
    PermissionsSagas(),
    EventLogsForAdminSagas(),
    FrontLoadingDepartmentSagas() 
  ]);
}