import {
  Avatar,
  List,
  Tooltip,
  Modal,
  Space,
  Button,
  Spin,
  Form,
  Tabs,
  Popconfirm,
  Typography,
  Row,
  Col,
  Badge,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  AddQuestionCommentDto,
  ConsultantQuestionCommentDto,
  ILever,
  ILeverQuestion,
  ILeverQuestionComment,
} from "../../../Models/Levers/Items";
import {
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import { ProfileResponse } from "../../../Models/Responses/ProfileResponse";
import React from "react";
import { SubQuestionSectionViewTemp } from "./SubQuestionsTemplate/SubQuestionSection";
import {
  formatValue,
  openNotificationWithIcon,
} from "../../../Utils/helperFunctions";
import { QuestionAnswerReviewDto } from "../../../Models/Requests/SaveLevers";
import { CustomerUserOutQuestionDto } from "../../../Models/Requests/CustomerUsers";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../../Enums/Messages";
import SubQuestionTableReadonly from "./SubQuestionsTemplate/SubQuestionTableReadonly";
import { t } from "i18next";
import { RulesRequire } from "../../Admin/Rules/RulesValidation";
import SubQuestionTableCostOfDelaysReadonly from "./SubQuestionsTemplate/SubQuestionTableCostOfDelaysReadonly";

interface ICommentsMngProps {
  question: ILeverQuestion;
  isModalLoading: boolean;
  modalResponse: ICommonResponse;
  leverData: ILever[];
  profile: ProfileResponse;
  onchangeSubquestionInput: any;
  reviewQuestion: (model: QuestionAnswerReviewDto, projectId: number) => any;
  removeUserForQuestion: (model: CustomerUserOutQuestionDto) => any;
  remindUserForQuestion: (model: CustomerUserOutQuestionDto) => any;
  AddcommentToQuestionByConsultant: (model: AddQuestionCommentDto) => any;
  EditcommentToQuestionByConsultant: (model: AddQuestionCommentDto) => any;
  DeletecommentToQuestionByConsultant: (id: number) => any;
}

function CommentsMng(props: ICommentsMngProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [editedReviews, setEditedreviews] = useState<QuestionAnswerReviewDto[]>(
    (props.question?.reviews ?? []).map((c) => {
      return {
        questionCustomerAnswerId: c.id,
        consultantComment: c.review?.comment,
        isAccepted: c.review?.isAccepted,
      };
    })
  );
  const [form] = Form.useForm();

  const [editform] = Form.useForm();

  const [reviews, setReviews] = useState(props.question?.reviews ?? []);

  const [comments, setComments] = useState(props.question?.comments ?? []);

  const [currentCommentId, setCurrentCommentId] = useState(0);

  useEffect(() => {
    setReviews(props.question?.reviews);
    setComments(props.question.comments);
    form.resetFields();
    setCurrentCommentId(0);
  }, [props.leverData, props.question?.reviews, props.question.comments, form]);

  useEffect(() => {
    if (isModalVisible) {
      if (props.modalResponse?.isError) {
        if (typeof props.modalResponse?.error?.exceptionMessage == "string") {
          if (props.modalResponse?.error?.exceptionMessage) {
            openNotificationWithIcon(
              "error",
              "Error",
              props.modalResponse.error?.exceptionMessage as string
            );
          } else {
            openNotificationWithIcon(
              "error",
              "Error",
              ToastMessages.UnExpectedError
            );
          }
        } else {
          openNotificationWithIcon(
            "error",
            "Error",
            ToastMessages.UnExpectedError
          );
        }
      } else {
        if (props.modalResponse?.message) {
          openNotificationWithIcon(
            "success",
            "Success",
            props.modalResponse?.message
          );
          closeAndReset();
        }
      }
    }
  }, [props.modalResponse]);

  const handleCancel = () => {
    closeAndReset();
  };
  const closeAndReset = () => {
    setIsModalVisible(false);
  };

  const reviewAnswer = (item: ILeverQuestionComment, isAccepted: boolean) => {
    var toBeReview = editedReviews.find(
      (c) => c.questionCustomerAnswerId == item.id
    );
    if (toBeReview) {
      toBeReview.isAccepted = isAccepted;

      props.reviewQuestion(toBeReview, props.profile.currentProject.id);

      if (isAccepted) {
      }
    }
  };
  const removeUserFromQuestionCollection = (item: ILeverQuestionComment) => {
    props.removeUserForQuestion({
      customerUserId: item.commenter.id,
      questionId: props.question.id,
    });
  };
  const remindUserFromQuestionCollection = (item: ILeverQuestionComment) => {
    props.remindUserForQuestion({
      customerUserId: item.commenter.id,
      questionId: props.question.id,
    });
  };
  const renderReviewShortcut = () => {
    //const _isCustomer = props.profile?.roleId == BasicRole.Customer;
    return (
      <Tooltip
        title={`${t("Click to review customer users")} (${reviews?.length})`}
        placement="top"
      >
        <Badge
          size="small"
          count={
            reviews?.filter((c) => c.review == null && c.hasAnswer === true)
              .length
          }
        >
          <CommentOutlined
            style={{ fontSize: 20 }}
            onClick={() => setIsModalVisible(true)}
          />
        </Badge>
      </Tooltip>
    );
  };

  const actionList = (item: ILeverQuestionComment) => {
    if (item.hasAnswer) {
      if (item.review != null) {
      } else {
        return [
          <Button
            type="ghost"
            onClick={(e) => {
              return reviewAnswer(item, false);
            }}
          >
            Reject
          </Button>,
          <Button
            type="ghost"
            onClick={(e) => {
              return reviewAnswer(item, true);
            }}
          >
            Accept
          </Button>,
        ];
      }
    } else {
      return [
        <Button
          type="ghost"
          onClick={(e) => {
            return remindUserFromQuestionCollection(item);
          }}
        >
          Resend
        </Button>,
        <Button
          type="ghost"
          onClick={(e) => {
            return removeUserFromQuestionCollection(item);
          }}
        >
          Remove
        </Button>,
      ];
    }
  };

  const onChangeComment = (value: any, item: ILeverQuestionComment) => {
    let editedReviewsTemp = editedReviews;
    editedReviewsTemp.find(
      (c) => c.questionCustomerAnswerId == item.id
    ).consultantComment = value;
    setEditedreviews(editedReviewsTemp);
  };

  const editConsultantComment = async () => {
    try {
      await editform.validateFields();
      let values = editform.getFieldsValue();
      console.log(
        "addNewComment",
        values["currentCommentEdit"],
        currentCommentId
      );
      props.EditcommentToQuestionByConsultant({
        id: currentCommentId,
        text: values["currentCommentEdit"],
      });
    } catch (err) {
      console.log("editConsultantComment", err);
    }
  };

  const addNewComment = async () => {
    try {
      await form.validateFields();
      let values = form.getFieldsValue();
      console.log(
        "editConsultantComment",
        values["currentComment"],
        props.question.id
      );
      props.AddcommentToQuestionByConsultant({
        questionVersionId: props.question.id,
        text: values["currentComment"],
      });
    } catch (err) {
      console.log("addNewComment", err);
    }
  };
  const DeleteComment = (id: number) => {
    props.DeletecommentToQuestionByConsultant(id);
  };

  const CommentsOfConsultants = () => {
    return (
      <>
        {props.question?.comments && props.question?.comments.length > 0 ? (
          <List
            className="demo-loadmore-list comments-on-levers"
            itemLayout="vertical"
            key={`question_comments_${props.question.id}`}
            dataSource={
              props.question?.comments as ConsultantQuestionCommentDto[]
            }
            renderItem={(item) => (
              <List.Item
                key={`question_comment_${props.question.id}_${item.id}`}
                actions={
                  props.profile.id == item.commenter.id
                    ? [
                        <span>{item.dateTime}</span>,
                        <Typography.Link
                          onClick={() => {
                            editform.setFieldValue(
                              "currentCommentEdit",
                              item.text
                            );
                            setCurrentCommentId(item.id);
                          }}
                        >
                          <Tooltip
                            title={`${t("Edit")}`}
                            placement="bottom"
                            getPopupContainer={() =>
                              document.getElementById("app-layout")
                            }
                          >
                            <EditOutlined />
                          </Tooltip>
                        </Typography.Link>,
                        <Popconfirm
                          title={`${t("Sure to Delete")}`}
                          onConfirm={() => {
                            DeleteComment(item.id);
                          }}
                          getPopupContainer={() =>
                            document.getElementById("app-layout")
                          }
                          cancelText={`${t("Cancel")}`}
                        >
                          <Tooltip
                            title={`${t("Delete")}`}
                            placement="bottom"
                            getPopupContainer={() =>
                              document.getElementById("app-layout")
                            }
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>,
                      ]
                    : [<span>{item.dateTime}</span>]
                }
              >
                <List.Item.Meta
                  key={`question_comment_meta_${item.id}`}
                  avatar={<Avatar src={item.commenter?.image} />}
                  title={<label>{item.commenter?.fullName}</label>}                  
                  description={
                    item.id == currentCommentId ? (
                      <Form
                        form={editform}
                        key={`new_comment_form_by_consultant`}
                        layout="vertical"
                      >
                        {" "}
                        <Form.Item
                          name={"currentCommentEdit"}
                          rules={[...RulesRequire({ filedName: t("Comment") })]}
                        >
                          <TextArea
                            allowClear
                            autoSize={{ minRows: 2, maxRows: 2 }}
                            placeholder={`${t("Type a comment")}`}
                            maxLength={1500}
                          />
                        </Form.Item>
                        <Button
                          type="default"
                          onClick={editConsultantComment}
                        >{`${t("Save")}`}</Button>
                        <Button
                          type="default"
                          danger
                          onClick={() => {
                            setCurrentCommentId(0);
                          }}
                        >{`${t("Cancel")}`}</Button>
                      </Form>
                    ) : (
                      item.text
                    )
                  }
                />
              </List.Item>
            )}
          />
        ) : null}
        <Form
          form={form}
          key={`new_comment_form_by_consultant`}
          layout="vertical"
        >
          {" "}
          <Form.Item
            name={"currentComment"}
            rules={[...RulesRequire({ filedName: t("Comment") })]}
          >
            <TextArea
              //onChange={(e) => { onChangeCommentByConsltant(e.target.value) }}
              //value={currentComment}
              allowClear
              autoSize={{ minRows: 2, maxRows: 2 }}
              placeholder={`${t("Type a comment")}`}
              maxLength={1500}
            />
          </Form.Item>
          <Button type="primary" onClick={addNewComment}>{`${t(
            "Add"
          )}`}</Button>
        </Form>
      </>
    );
  };

  const ContentOfComment = (item: ILeverQuestionComment) => {
    if (item.review != null) {
      return (
        <div className="comment-content">
          <label
            className={`${
              item.review.isAccepted ? "accepted-label" : "rejected-label"
            }`}
          >
            {item.review.isAccepted ? "Accepted" : "Rejected"}{" "}
            {`by ${item.review.reviewer.fullName}`}
          </label>
          <TextArea
            onChange={(e) => {
              onChangeComment(e.target.value, item);
            }}
            value={
              editedReviews.find((c) => c.questionCustomerAnswerId == item.id)
                ?.consultantComment
            }
            allowClear
            autoSize={{ minRows: 4, maxRows: 6 }}
            placeholder="Your Comment"
            maxLength={1500}
          />
        </div>
      );
    } else {
      return (
        <div className="comment-content">
          <TextArea
            onChange={(e) => {
              onChangeComment(e.target.value, item);
            }}
            value={
              editedReviews.find((c) => c.questionCustomerAnswerId == item.id)
                ?.consultantComment
            }
            allowClear
            autoSize={{ minRows: 4, maxRows: 6 }}
            placeholder="Your Comment"
            maxLength={1500}
          />
        </div>
      );
    }
  };
  const tabItems = [
    {
      label: `${t("Comments")}`,
      key: `consultant_comments_${props.question.id}`,
      children: CommentsOfConsultants(),
    },
    {
      label: `${t("Invitations")}`,
      key: `invitations_${props.question.id}`,
      children: (
        <List
          className="demo-loadmore-list reviews-on-levers"
          itemLayout="vertical"
          key={`question_reviews_${props.question.id}`}
          dataSource={props.question?.reviews as ILeverQuestionComment[]}
          locale={{ emptyText: "No Invitations For This Question Yet" }}
          renderItem={(item) => (
            <List.Item
              key={`question_review_${props.question.id}_${item.id}`}                                       
              actions={actionList(item)}
            >
              <List.Item.Meta
                key={`question_review_meta_${item.id}`}              
                title={<label>{item.commenter?.fullName}</label>}                
                //title={<label>{item.answerDate}</label>}                
                description={
                  <Space direction="vertical">
                   <label>{item.answerDate}</label>
                   <label>{item.hasAnswer
                    ? item.text == null || item.text.length == 0
                      ? "No comments"
                      : item.text
                    : "No answer yet"}</label>
                  </Space>
                  
                }
              />
              {item.hasAnswer ? (
                <Space align="start" direction="vertical">
                  {item.subquestions == null ? (
                    <label>{`Question Answer= ${formatValue(
                      item.answerValue.value
                    )}${item.answerValue.unit}`}</label>
                  ) : null}
                  {/* <label>{`KPI Value= ${formatValue(item.kpiValue.value)}${
                    item.kpiValue.unit
                  }`}</label>
                  <label>{`Question Answer= ${formatValue(
                    item.answerValue.value
                  )}${item.answerValue.unit}`}</label> */}
                  <div>
                    {item.subquestions?.isTable ? (
                      (item.subquestions?.columnsNumber == 4)? 
                      <SubQuestionTableCostOfDelaysReadonly
                        question={props.question}
                        subquestions={item.subquestions}
                      />
                      :
                      <SubQuestionTableReadonly
                        question={props.question}
                        subquestions={item.subquestions}
                      />
                    ) : (
                      SubQuestionSectionViewTemp(
                        props.question,
                        item.subquestions
                      )
                    )}
                  </div>
                  {ContentOfComment(item)}
                </Space>
              ) : null}
            </List.Item>
          )}
        />
      ),
    },
  ];
  return (
    <>
      {renderReviewShortcut()}
      <Modal
        getContainer={() => document.getElementById("app-layout")}
        title={props.question.text}
        open={isModalVisible}
        onOk={null}
        onCancel={handleCancel}
        footer={
          <Button key="back" onClick={handleCancel}>
            {`${t("Close")}`}
          </Button>
        }
        //cancelText={`${t("Close")}`}
        key={`modal_question_reviews_${props.question.id}`}
      >
        <Spin spinning={props.isModalLoading}>
          <Tabs items={tabItems} />
        </Spin>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    isModalLoading: state.leverTool.isModalLoading,
    modalResponse: state.leverTool.modalResponse,
    leverData: state.leverTool.leverData,
    profile: state.generalInfo.profileResponse,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    reviewQuestion: (model: QuestionAnswerReviewDto, projectId: number) =>
      dispatch({
        type: ActionTypes.ReviewQuestionByConsultant,
        model: model,
        projectId: projectId,
      }),
    AddcommentToQuestionByConsultant: (model: AddQuestionCommentDto) =>
      dispatch({
        type: ActionTypes.AddcommentToQuestionByConsultant,
        model: model,
      }),
    EditcommentToQuestionByConsultant: (model: AddQuestionCommentDto) =>
      dispatch({
        type: ActionTypes.EditcommentToQuestionByConsultant,
        model: model,
      }),
    DeletecommentToQuestionByConsultant: (id: number) =>
      dispatch({
        type: ActionTypes.DeletecommentToQuestionByConsultant,
        id: id,
      }),
    remindUserForQuestion: (model: CustomerUserOutQuestionDto) =>
      dispatch({
        type: ActionTypes.RemindCustomerUserForSelectedQuestion,
        model: model,
      }),
    removeUserForQuestion: (model: CustomerUserOutQuestionDto) =>
      dispatch({
        type: ActionTypes.RemoveCustomerUserFromQuestion,
        model: model,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsMng);
