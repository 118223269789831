
import { Avatar, Col, Divider, List, Row, Tag, Typography } from "antd";
import { connect } from "react-redux";
import { IGeneralState } from "../../../../../Redux/reducers/IGeneralState";
import { ISABestPracticeHistoryImportance } from "../../../../../Models/SelfAssessment/ISelfAssessmentView";
import InfiniteScroll from 'react-infinite-scroll-component';
import userAvatar from "../../../../../assets/Images/user_image.png";
import { upperCaseFirstLetter } from "../../../../../Utils/helperFunctions";
import { t } from "i18next";


interface IProps {
    historyData: ISABestPracticeHistoryImportance[];
    isLoading: boolean;
}
const HistoryList = (props: IProps) => {
    const loadMoreData = () => {
        if (props.isLoading) {
            return;
        }
    }
    if (!props.historyData) {
        return;
    }

    return <div
        id="scrollableDiv"
        style={{
            height: 400,
            overflow: 'auto',
            padding: '0 16px',
            border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
    >
        <InfiniteScroll
            dataLength={props.historyData?.length}
            hasMore={props.historyData?.length < 5}
            //endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv"
            next={loadMoreData}
            loader={props?.isLoading}
        >
            <List
                dataSource={props.historyData}
                renderItem={(item) => (
                    <List.Item key={`comment_${item?.id}`} extra={<Typography.Text disabled>{item?.dateTime}</Typography.Text>}
                    //actions={[<Tag color="#197fdd">{item?.importance}</Tag>]}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={item?.user?.image ? item?.user?.image : userAvatar} size='large' />}
                            description={<Row>
                                <Col span={24}><Typography>{upperCaseFirstLetter(item?.user?.firstName)}&nbsp;{upperCaseFirstLetter(item?.user?.lastName)}</Typography></Col>
                                <Col span={24}><b>{`${t('Importance')}: `}</b>{`${(item?.importance == 0)? "-" : item?.importance}`}</Col>
                                <Col span={24}><b>{`${t('Comment')}: `}</b>{`${item?.comment}`}</Col> 
                            </Row>}
                        />

                    </List.Item>
                )}
            />
        </InfiniteScroll>
    </div>
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        historyData: state.HistoryOfImportance?.historyResult,
        isLoading: state.HistoryOfImportance?.isLoading,
    }
};



export default connect(mapStateToProps, null)(HistoryList);