import { connect } from "react-redux";
import EditLeversTable from "./EditLeversTable/EditLeversTable";
import ColOPtionsDrop from "./ColOptionsDrop/ColOPtionsDrop";
import { Button, Card, Col, notification, Popconfirm, Result, Row, Spin, Table, Tabs } from "antd";
import './editlevers.css';
import { useEffect, useState } from "react";
//import BmProject from "../../../Models/BmProject";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { useNavigate } from "react-router-dom";
import { BmProject } from "../../../Models/Responses/ProfileResponse";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import { HelpPageCodes } from "../../../Redux/HelpPageCodes";
import { t } from "i18next";
import HelpPage from "../../../Layout/HelpPage";
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from "react-i18next";
interface DataType {
    key: string;
    levers: string;
    project: string;
    ebit: string;
}
interface IProps {
    currentProject: BmProject;
    //projects: BmProject[];
    GetProjectsForEditLevers: () => any;
    savePeers: (levers_ks: number[], levers_xs: number[]) => void;
    isLoading: boolean;
    openAccessModal: () => any;
    hasAccessTokenToProjects: boolean;
    changeCurrentHelpPageCode: (pageName: string) => any;
    //savingLeversPeers: boolean,
    //EditLeversWillUnmount: () => void,
    //navigateToCurrentProjectPage: boolean
};

function EditLevers(props: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [levers_ks, setLevers_ks] = useState(props.currentProject?.otherBmProjects.filter(c => c.isTargetLever == true).map(c => { return c.id }) ?? []);
    const [levers_xs, setLevers_xs] = useState(props.currentProject?.otherBmProjects.filter(c => c.isTargetLever == false).map(c => { return c.id }) ?? []);
    useEffect(() => {
        if (props.hasAccessTokenToProjects == true) {
            props.GetProjectsForEditLevers();
        }
    }, [props.hasAccessTokenToProjects]);
    useEffect(() => {
        //get access
        props.changeCurrentHelpPageCode(HelpPageCodes.LeverPartner);
        //console.log("openAccessModal");
        props.openAccessModal();
    }, []);
    const changeLevers_ks = (ids: number[]) => {
        setLevers_ks(ids);
    };
    const changeLevers_xs = (ids: number[]) => {
        setLevers_xs(ids);
    };

    const reset = () => {
        setLevers_ks([]);
        setLevers_xs([]);
    };
    const save = () => {
        props.savePeers(levers_ks, levers_xs);
    };
    const columns: ColumnsType<DataType> = [
        {
            title: <div className="current_project_table_title_div">{t("Levers B-M Project")}</div>,
            dataIndex: 'levers',
            key: 'name',
            align: 'center',
            className: 'current_project_table_col'
        },
        {
            title: <div className="current_project_table_title_div">{t("Project")}</div>,
            dataIndex: 'project',
            key: 'project',
            align: 'center',
            className: 'current_project_table_col'
        }
        // ,
        // {
        //     title: <div className="current_project_table_title_div">{t("EBIT B-M Project")}</div>,
        //     dataIndex: 'ebit',
        //     key: 'ebit',
        //     align: 'center',
        //     className: 'current_project_table_col'
        // }
    ];
    //console.log("props.currentProject?.OtherBmProjects", props.currentProject?.otherBmProjects);
    let data: DataType[] = props.currentProject?.otherBmProjects?.filter(c=>c.isTargetLever != null).sort((a,b)=> a.fullName.localeCompare(b.fullName)).map(bm => {
        return {
            key: bm.id.toString(),
            levers: (bm.isTargetLever != null) ? (bm.isTargetLever == true) ? 'k' : 'x' : '',
            project: `${bm.name} (${bm.fullName})`,
            ebit: (bm.isTargetEbit != null) ? (bm.isTargetEbit == true) ? 'k' : 'x' : '',
        };
    }); 
    if (props.hasAccessTokenToProjects) {
        let items = [{
            label: <>{t("Edit Levers B-M")}</>, key: 'general-tab',
            children: <Spin spinning={props.isLoading} className="edit_levers_container">
                <Row gutter={16} className="current_project_card_wrapper current_project-responsive-part2">
                    <Col span={24}> 
                            <Table
                                className="current_project_table"
                                bordered
                                pagination={false}
                                columns={columns}
                                dataSource={data}
                                scroll={{ y: 'calc(100vh - 800px)' }} 
                            /> 
                    </Col>
                </Row>
                <div className="edit_levers_ctrl_div">
                    <div className="edit_levers_btns_div">
                        <div className="edit_levers_reset_btn_div">
                            <Popconfirm title="Sure to reset?" onConfirm={() => reset()}>
                                <a><Button className="edit_levers_reset_btn">Reset</Button></a>
                            </Popconfirm>
                        </div>
                        <div className="edit_levers_save_btn_save">
                            <Popconfirm title="Sure to save?" onConfirm={() => save()}>
                                <a><Button className="edit_levers_save_btn">Save BM</Button></a>
                            </Popconfirm>
                        </div>
                    </div>
                    <div className="edit_levers_ColOPtions_div">
                        <ColOPtionsDrop />
                    </div>
                </div>
                <EditLeversTable
                    levers_ks={levers_ks ?? []}
                    levers_xs={levers_xs ?? []}
                    setLevers_ks={changeLevers_ks}
                    setLevers_xs={changeLevers_xs} />
            </Spin>
        },
        {
            label: <>{t("Tipps and Tricks")}</>, key: 'tips-dashboard-tab',
            children: <HelpPage currentPageCode="lever partner" />
        }]
        return (
            <Tabs tabPosition="top" defaultActiveKey="general-tab" type="line" size='small'
                key={"lever-partner-tab"} id="lever-partner-tab" items={items} />
        );
    } else {
        return <Result
            status="403"
            title="403"
            subTitle={`${t("Sorry, you are not authorized to access this page.")}`}
            extra={<Button type="primary" onClick={() => { navigate("/dashboard") }}>{`${t("Back Home")}`}</Button>}
        />;
    }
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        //projects: state.projects.projects,
        //currentProject: state.projects.currentProject,
        isLoading: state.generalInfo.isLoading,
        currentProject: state.generalInfo.profileResponse?.currentProject,
        hasAccessTokenToProjects: state.generalInfo.hasAccessTokenToProjects
        //savingLeversPeers: state.projects.savingLeversPeers,        
        //navigateToCurrentProjectPage: state.projects.navigateToCurrentProjectPage
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        openAccessModal: () => dispatch({ type: ActionTypes.OpenAccessModal }),
        savePeers: (levers_ks: number[], levers_xs: number[]) => dispatch({ type: ActionTypes.UpdatePeers, isLevers: true, ks: levers_ks, xs: levers_xs }),
        GetProjectsForEditLevers: () => dispatch({ type: ActionTypes.GetProjectsForEditLevers }),
        changeCurrentHelpPageCode: (pageName: string) => dispatch({ type: ActionTypes.ChangeHelpPageCode, result: pageName })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLevers);