import { Button, Col, notification, Popconfirm, Result, Row, Spin, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BmProject, BmProjectForEditEdits, GeneralBmProjectDto } from "../../../Models/Responses/ProfileResponse";
//import BmProject from "../../../Models/BmProject";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { HelpPageCodes } from "../../../Redux/HelpPageCodes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import ColOPtionsDrop from "./ColOptionsDrop/ColOPtionsDrop";
import EditEbitTable from "./EditEbitTable/EditEbitTable";
import { t } from "i18next";
import HelpPage from "../../../Layout/HelpPage";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
interface DataType {
    key: string;
    levers: string;
    project: string;
    ebit: string;
}
interface IProps {
    currentProject: BmProject;
    //projects: BmProjectForEditEdits[];
    isLoading: boolean;
    savePeers: (ebit_ks: number[], ebit_xs: number[]) => void,
    getProjectsForEditEbit: () => any;
    openAccessModal: () => any;
    hasAccessTokenToProjects: boolean;
    changeCurrentHelpPageCode: (pageName: string) => any;
    //savingEbitPeers: boolean,
    //navigateToCurrentProjectPage: boolean, 
};

function EditEbit(props: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ebit_ks, setEbit_ks] = useState(props.currentProject?.otherBmProjects.filter(c => c.isTargetEbit == true).map(c => { return c.id }) ?? []);// it was changed to bM_ebit_ks to match response properties
    const [ebit_xs, setEbit_xs] = useState(props.currentProject?.otherBmProjects.filter(c => c.isTargetEbit == false).map(c => { return c.id }) ?? []);
    useEffect(() => {
        if (props.hasAccessTokenToProjects == true) {
            props.getProjectsForEditEbit();
        }
    }, [props.hasAccessTokenToProjects]);
    useEffect(() => {
        //get access
        props.changeCurrentHelpPageCode(HelpPageCodes.EbitPartner);
        //console.log("openAccessModal");
        props.openAccessModal();
    }, []);


    const changeEbit_ks = (ids: number[]) => {
        setEbit_ks(ids);
    };
    const changeEbit_xs = (ids: number[]) => {
        setEbit_xs(ids);
    };

    const reset = () => {
        setEbit_ks([]);
        setEbit_xs([]);
    };
    const save = () => {
        props.savePeers(ebit_ks, ebit_xs);
    };
    const columns: ColumnsType<DataType> = [         
        {
            title: <div className="current_project_table_title_div">{t("Project")}</div>,
            dataIndex: 'project',
            key: 'project',
            align: 'center',
            className: 'current_project_table_col'
        }
        ,
        {
            title: <div className="current_project_table_title_div">{t("EBIT B-M Project")}</div>,
            dataIndex: 'ebit',
            key: 'ebit',
            align: 'center',
            className: 'current_project_table_col'
        }
    ];
    
    let data: DataType[] = props.currentProject?.otherBmProjects?.filter(c=>c.isTargetEbit != null).sort((a,b)=> a.fullName.localeCompare(b.fullName)).map(bm => {
        return {
            key: bm.id.toString(),
            levers: (bm.isTargetLever != null) ? (bm.isTargetLever == true) ? 'k' : 'x' : '',
            project: `${bm.name} (${bm.fullName})`,
            ebit: (bm.isTargetEbit != null) ? (bm.isTargetEbit == true) ? 'k' : 'x' : '',
        };
    }); 
    if (props.hasAccessTokenToProjects) {
        let items = [{
            label: <>{t("Edit EBIT B-M")}</>, key: 'ebit-lever-tab',
            children: <Spin spinning={props.isLoading}>
                <Row gutter={16} className="current_project_card_wrapper current_project-responsive-part2">
                    <Col span={24}>
                        <Table
                            className="current_project_table"
                            bordered
                            pagination={false}
                            columns={columns}
                            dataSource={data}
                            scroll={{ y: 'calc(100vh - 800px)' }}
                        />
                    </Col>
                </Row>
                <div className="edit_levers_ctrl_div">
                    <div className="edit_levers_btns_div">
                        <div className="edit_levers_reset_btn_div">
                            <Popconfirm title="Sure to reset?" onConfirm={() => reset()}>
                                <a><Button className="edit_levers_reset_btn">Reset</Button></a>
                            </Popconfirm>
                        </div>
                        <div className="edit_levers_save_btn_save">
                            <Popconfirm title="Sure to save?" onConfirm={() => save()}>
                                <a><Button className="edit_levers_save_btn">Save BM</Button></a>
                            </Popconfirm>

                        </div>
                    </div>
                    <div className="edit_levers_ColOPtions_div">
                        <ColOPtionsDrop />
                    </div>
                </div>
                <EditEbitTable
                    ebit_ks={ebit_ks}
                    ebit_xs={ebit_xs}
                    setEbit_ks={changeEbit_ks}
                    setEbit_xs={changeEbit_xs} />
            </Spin>
        },
        {
            label: <>{t("Tipps and Tricks")}</>, key: 'tips-dashboard-tab',
            children: <HelpPage currentPageCode="ebit partner" />
        }]
        return (
            <Tabs tabPosition="top" defaultActiveKey="ebit-lever-tab" type="line" size='small'
                key={"lever-partner-tab"} id="lever-partner-tab" items={items} />
        );
    } else {
        return <Result
            status="403"
            title="403"
            subTitle={`${t("Sorry, you are not authorized to access this page.")}`}
            extra={<Button type="primary" onClick={() => { navigate("/dashboard") }}>{`${t("Back Home")}`}</Button>}
        />;
    }
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.generalInfo.isLoading,
        currentProject: state.generalInfo.profileResponse?.currentProject,
        hasAccessTokenToProjects: state.generalInfo.hasAccessTokenToProjects
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        openAccessModal: () => dispatch({ type: ActionTypes.OpenAccessModal }),
        getProjectsForEditEbit: () => dispatch({ type: ActionTypes.GetProjectsForEditEbit }),
        savePeers: (ebit_ks: number[], ebit_xs: number[]) => dispatch({ type: ActionTypes.UpdatePeers, isLevers: false, ks: ebit_ks, xs: ebit_xs }),
        changeCurrentHelpPageCode: (pageName: string) => dispatch({ type: ActionTypes.ChangeHelpPageCode, result: pageName })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEbit);