import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IKpiDegree, ILever, ILeverCell, ILeverQuestion, ISubLever, ISucessFactor } from '../../../Models/Levers/Items';
import { BmProject } from '../../../Models/Responses/ProfileResponse';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import { getCurrentNode, getLevels, smashedXs } from '../../../Utils/helperFunctions';
import ASupColumnchart from '../BarChart/SwitchableChartRadarBar';
import BarChartForLevers from '../BarChartForLevers/BarChartForLevers';
import './asupbarchart_ant.scss';

interface IRechartData {
    name: string,
    value: number,
    isTarget: boolean,
    isComplete: boolean
}

interface ChartDetails {
    leverLevel: number,
    leverId: number,
    leverCode: string,
    chartTitle: string,
    chartType: string;
    chartDescription: string,
    chartUnit: string,
    isPercentChart?: boolean,
    bestPracticeMaxValue?: number,
    bestPracticeMinValue?: number
};
interface ChartDetailsAndValues {
    chartDetails: ChartDetails;
    chartValues: IRechartData[];
}
interface Iprops {
    // chart details props
    showChartDetails: boolean;
    chartDetailsTextColor?: string;

    //ChartControls props
    showChartControls: boolean;
    switchChart?: () => void;
    selectChart?: (divId: string, add: boolean) => void;
    selectedCharts?: string[];
    chartType?: string;
    showSwitchRadarBar?: boolean;
    showSelectCheckBox?: boolean;
    showDownloadBtn?: boolean;

    leverNumber: string,
    inHeatMapCell?: boolean,
    isInSideBar?: boolean,
    width?: number,
    height?: number,
    bordered?: boolean,
    //
    currentProject: BmProject,
    projects: BmProject[],
    currentUserId: number,

    showSuccessFactorLevelCharts?: (leverNumber: string) => void,
    showQuestionsLevelcharts?: (successFactorLeverNumber: string) => void,
    showSubleverLevelCharts?: (subLeverNumber: string) => void,

    //Tell higher level component with the chart title (div id)
    //informChartTitleup?: (chartTitle: string) => void,
    informChartDetailsAndValuesUp?: (chartDetailsAndValues: ChartDetailsAndValues) => void,
    backgroundColor?: string,
}




// const getLevels = (leverNumber: string): string[] => {
//     if (leverNumber === "0.")
//         return [];
//     if (leverNumber.includes('_'))
//         return leverNumber.split('_').filter(element => element !== 'q');
//     return leverNumber.split('.').filter(element => element !== "");
// };

// const getCurrentNode = (project: BmProject, levels: string[]): ILever | ISubLever | ISucessFactor | IKpiDegree | ILeverQuestion => {
//     let number: string = "";
//     let currentNode: ILever | ISubLever | ISucessFactor | IKpiDegree | ILeverQuestion;
//     levels.forEach((level, idx) => {
//         number = number + level + ".";
//         switch (idx) {
//             case 0:
//                 currentNode = project.leversData?.find(l => l.number === number);
//                 break;
//             case 1:
//                 currentNode = (currentNode as ILever)?.subLevers?.find(s => s.number === number);
//                 break;
//             case 2:
//                 currentNode = (currentNode as ISubLever)?.successFactors?.find(sf => sf.number === number);
//                 break;
//             case 3:
//                 currentNode = (currentNode as ISucessFactor)?.kpiLevels?.find(kl => kl.number === number);
//                 break;
//             case 4:
//                 // construct the right code for question 
//                 let questionCode = `q_${number.replaceAll('.', '_').slice(0, -1)}`;
//                 currentNode = (currentNode as IKpiDegree)?.questions?.find(q => q.code === questionCode);
//                 break;

//             default:
//                 break;
//         }
//     });

//     return currentNode;
// };


const getLeverAsBarChartData = (project: BmProject, currentProject: BmProject, leverNumber: string): IRechartData => {
    let levels = getLevels(leverNumber);
    let isCurrentProject = (project.id === currentProject.id);

    let name: string = currentProject?.otherBmProjects?.filter(c => c.id == project.id)?.[0]?.name; //project.smashedName;

    if (isCurrentProject) name = project.shortName;
    //if (currentProject?.otherBmProjects.filter(c=>c.isTargetLever == true).map(c=>{return c.id}).includes(project.id)) name = project.shortName;

    if (levels.length === 0)
        return {
            name: name,
            value: ((project.leversData != null && project.leversData.length > 0) ? project.leversData?.map(item => item.kpiValue)?.reduce((a, b) => a + b) : 0) / 5,
            isTarget: currentProject?.otherBmProjects?.filter(c => c.isTargetLever == true).map(c => { return c.id }).includes(project.id) || isCurrentProject,
            isComplete: project.isComplete,
        };

    let currentNode = getCurrentNode(project, levels);     
    if (levels.length === 5)
        return {
            name: name,
            value: (currentNode as ILeverQuestion)?.kpiValue?.value,//(currentNode as ILeverQuestion).kpiDegreeOfFulfillment.value,
            isTarget: currentProject?.otherBmProjects?.filter(c => c.isTargetLever == true).map(c => { return c.id }).includes(project.id) || isCurrentProject,
            isComplete: project.isComplete,
        };
    return {
        name: name,
        value: (currentNode as IKpiDegree)?.kpiValue,
        isTarget: currentProject.otherBmProjects.filter(c => c.isTargetLever == true).map(c => { return c.id }).includes(project.id) || isCurrentProject,
        isComplete: project.isComplete,
    };
};



const getChartDetails = (levels: string[], currentProject: BmProject): ChartDetails => {
    if (levels.length === 0) {
        return {
            leverLevel: levels.length,
            leverId: 0,
            leverCode: '0.',
            chartTitle: 'Stellhebel',
            chartType: 'Bar',
            chartDescription: '[Performance Index]',
            chartUnit: '',
            isPercentChart: true
        }
    } else if (levels.length === 5) {
        let q = (getCurrentNode(currentProject, levels) as ILeverQuestion);
        //console.log('levels.slice(0, 3): ', levels.slice(0, 4));

        let k = (getCurrentNode(currentProject, levels.slice(0, 4)) as IKpiDegree); //slice to go up one level to kpi node because chart title will be the name of the kpi which the question belongs to
        //console.log('getCurrentNode(currentProject, levels.slice(0, 3)) as IKpiDegree: ', k);

        return {
            leverLevel: levels.length,
            leverId: q.id,
            leverCode: q.code,

            chartTitle: k.number + ' ' + k.name,
            chartType: 'Bar',
            chartDescription: q.text,
            chartUnit: '[' + q.unit + ']',
            isPercentChart: (q.unit === '%'),
            bestPracticeMaxValue: q.piUpperLimit,
            bestPracticeMinValue: q.piFloor,
        }
    } else {
        let node = (getCurrentNode(currentProject, levels) as ILever | ISubLever | ISucessFactor | IKpiDegree);
        return {
            leverLevel: levels.length,
            leverId: node.id,
            leverCode: node.number,
            chartTitle: node?.number + ' ' + node?.name,
            chartType: 'Bar',
            chartDescription: '[Performance Index]',
            chartUnit: '',
            isPercentChart: true
        }
    }
}

const AsupBarChart_Ant = (props: Iprops) => {

    //let iniData: IbarChartData[] = [];
    let iniChartDetails: ChartDetails = {
        leverLevel: 0,
        leverId: 0,
        leverCode: '',
        chartTitle: '',
        chartType: 'Bar',
        chartDescription: '',
        chartUnit: '',
        bestPracticeMaxValue: undefined,
        bestPracticeMinValue: undefined
    };
    let [_chartDetails, setChartDetails] = useState(iniChartDetails);
    let [rechartDataArr, setRechartDataArr] = useState([]);

    let level = getLevels(props.leverNumber).length;

    useEffect(() => {
        if ((!props.currentProject) && (!(props.projects?.length > 0))) return;
        let rechartData = new Array<IRechartData>();

        let chartDetails: ChartDetails;

        let bmKsProjects = props.projects?.filter(p => props.currentProject?.otherBmProjects?.filter(c => c.isTargetLever == true).map(c => { return c.id })?.includes(p.id)) ?? [];
        let bmXsProjects = props.projects?.filter(p => props.currentProject?.otherBmProjects?.filter(c => c.isTargetLever == false).map(c => { return c.id })?.includes(p.id)) ?? [];
        let projects = [...bmKsProjects, ...bmXsProjects, props.currentProject];
        rechartData = projects.map(project => {
            return getLeverAsBarChartData(project, props.currentProject, props.leverNumber);
        });
        //
        let levels: string[] = getLevels(props.leverNumber);
        chartDetails = getChartDetails(levels, props.currentProject);
        if (props.informChartDetailsAndValuesUp)
            props.informChartDetailsAndValuesUp({
                chartDetails: chartDetails,
                chartValues: rechartData
            });
        setChartDetails(chartDetails);
        setRechartDataArr(rechartData);
    }, [props.currentProject, props.projects]);

    useEffect(() => {
        if ((!props.currentProject) && (!(props.projects?.length > 0))) return;
        let rechartData = new Array<IRechartData>();

        let chartDetails: ChartDetails;

        let bmKsProjects = props.projects?.filter(p => props.currentProject?.otherBmProjects?.filter(c => c.isTargetLever == true).map(c => { return c.id })?.includes(p.id)) ?? [];
        let bmXsProjects = props.projects?.filter(p => props.currentProject?.otherBmProjects?.filter(c => c.isTargetLever == false).map(c => { return c.id })?.includes(p.id)) ?? [];
        let projects = [...bmKsProjects, ...bmXsProjects, props.currentProject];
        rechartData = projects.map(project => {
            return getLeverAsBarChartData(project, props.currentProject, props.leverNumber);
        });
        //
        let levels: string[] = getLevels(props.leverNumber);
        chartDetails = getChartDetails(levels, props.currentProject);
        if (props.informChartDetailsAndValuesUp)
            props.informChartDetailsAndValuesUp({
                chartDetails: chartDetails,
                chartValues: rechartData
            });
        setChartDetails(chartDetails);
        setRechartDataArr(rechartData);
    }, []);

    const getHeightWidth = (): { height?: number, width?: number } => {
        if (props.isInSideBar)
            return {
                height: 120,
                width: 150
            };
        if (props.height && props.width)
            return {
                height: props.height,
                width: props.width
            }
        if (props.height)
            return {
                height: props.height,
            };
        if (props.width)
            return {
                width: props.width
            };
    };

    let size = getHeightWidth();
    //console.log('barchartSize: ', size);

    const showNextLevel = () => {

        switch (level) {
            case 1:
                //props.showSubleverLevelCharts(props.leverNumber);
                break;
            case 2:
                props.showSuccessFactorLevelCharts(props.leverNumber);
                break;
            case 3:
                props.showQuestionsLevelcharts(props.leverNumber);
                break;
            default:
                break;
        }
    };

    let view;
    if (rechartDataArr) {
        view =
            <div onClick={showNextLevel}>
                <BarChartForLevers
                    showChartDetails={props.showChartDetails}
                    leverCode={props.leverNumber}
                    Title={_chartDetails.chartTitle}
                    Description={_chartDetails.chartDescription}
                    Unit={_chartDetails.chartUnit}
                    chartDetailsTextColor={props.chartDetailsTextColor}

                    width={size?.width}
                    height={size?.height}
                    inHeatMapCell={props.inHeatMapCell}
                    isInSideBar={props.isInSideBar}
                    isPercentChart={_chartDetails.isPercentChart}
                    bestPractice={
                        (typeof _chartDetails.bestPracticeMinValue === 'undefined' ||
                            typeof _chartDetails.bestPracticeMaxValue === 'undefined') ?
                            undefined :
                            {
                                min: _chartDetails.bestPracticeMinValue,
                                max: _chartDetails.bestPracticeMaxValue
                            }
                    }
                    projectsData={rechartDataArr}
                    bordered={props.bordered}

                    showChartControls={props.showChartControls}
                    showDownloadBtn={props.showDownloadBtn}
                    showSelectCheckBox={props.showSelectCheckBox}
                    showSwitchRadarBar={props.showSwitchRadarBar}
                    chartType={props.chartType}
                    switchChart={props.switchChart}
                    selectChart={props.selectChart}
                    selectedCharts={props.selectedCharts}
                    backgroundColor={props.backgroundColor}
                />
            </div>
    } else {
        view = <div className='asup-bar-chart-spin'>
            <Spin size={(props.isInSideBar || props.inHeatMapCell) ? 'small' : 'large'} />
        </div>;
    }
    return view;
};

const mapStateToProps = (state: IGeneralState) => {
    return {
        currentProject: state.generalInfo.profileResponse?.currentProject,
        projects: state.generalInfo.allBenchmarkingProjects,
        currentUserId: state.generalInfo.profileResponse?.id,
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AsupBarChart_Ant);

