import { Button, Col, Row, Slider } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ISABestPractice } from "../../../../Models/SelfAssessment/ISelfAssessmentView";
import { IGeneralState, ISelfAssessmentToolState } from "../../../../Redux/reducers/IGeneralState";
import ScatterDiagram from "../components/ScatterDiagram";
import './SAMatrixChart.scss';
import SATopRated from "./SATopRated";
import saveAs from "file-saver";
import html2canvas from "html2canvas";
import { DownloadOutlined } from "@ant-design/icons";
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import { t } from "i18next";
import { DownloadChartAsImage as _DownloadChartAsImage } from '../../../../Utils/helperFunctions';
import ScatterDiagramMin from "../components/ScatterDiagramMin";
interface IProps {
    dataSource: ISABestPractice[];
    defaultTopRated: number;
    viewState: ISelfAssessmentToolState;
    currentProject: BmProject;
}
function SAMatrixChart(props: IProps) {
    const [scale, setScale] = useState(0);
    const onChange = (value: number) => {
        setScale(value);
    };
    const DownloadChartAsImage = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const canvasSave = document.getElementById("SAMatrixChart_div");
        _DownloadChartAsImage(`${props.currentProject.project_name}`, canvasSave);
    };

    const DownloadChartAsImageMin = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const canvasSave = document.getElementById("scatter_chart_min_rv");
        _DownloadChartAsImage(`${props.currentProject.project_name}`, canvasSave);
    };

    return (
        <>
            <Row>
                <Col span={14}>
                    <Button className='chart-download-Btn'
                        icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                        onClick={DownloadChartAsImageMin}
                        type="default"
                        title={`${t("Download As Image")}`}
                        size='small'
                    />
                    <div key={'SAMatrixChart_div_Min'} className="SAMatrixChart_div" id="SAMatrixChart_div_Min" style={{ width: "100%", height: "calc(100vh - 450px)" }}>
                        <ScatterDiagramMin key={'SAMatrixChart_div_min_sd'} scale={scale} points={[...props.dataSource.filter(c => c.urgency)]} chartWidth={500} chartHieght={400} />
                    </div>
                </Col>
                <Col span={10}><SATopRated dataSource={props.dataSource} defaultTopRated={props.defaultTopRated} /></Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button className='chart-download-Btn'
                        icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                        onClick={DownloadChartAsImage}
                        type="default"
                        title={`${t("Download As Image")}`}
                        size='small'
                    />
                    <div key={'SAMatrixChart_div'} className="SAMatrixChart_div" id="SAMatrixChart_div" style={{ width: "100%", height: "80vh" }}>
                        <ScatterDiagram key={'SAMatrixChart_div_sd'} scale={scale} points={[...props.dataSource.filter(c => c.urgency)]} chartWidth={500} chartHieght={400} />
                    </div>
                </Col>
            </Row>
        </>
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        viewState: state.selfAssessementTool,
        currentProject: state.generalInfo?.profileResponse?.currentProject,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SAMatrixChart);