import { Button, Col, Form, Input, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IConfirmTokenUser } from "../../../Models/Requests/AuthModels";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import { useTranslation } from "react-i18next";
import "./index.scss";
interface IActivateProps {
    isLoading: boolean;
    commonResult: ICommonResponse;
    activateUser: (model: IConfirmTokenUser) => any;
}

function Activation(props: IActivateProps) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    const { t, i18n } = useTranslation();
    // useEffect(() => {
    //     localStorage.clear(); 
    //   }, []); 
    useEffect(() => {
        if (props.commonResult) {
            if (props.commonResult?.isError == false) {

                navigate(`/login?email=${email}`);
            }
        }
    }, [props.commonResult]);

    const onFinish = (values) => {
        console.log('onFinish activate:', values);
        if (token) {
            if (email) {
                props.activateUser({
                    email: email,
                    token: token,
                    newPassword: values.password
                })
            } else {
                alert("Invalid Link");
            }
        } else {
            alert("Invalid Link");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);

    };
    return (<Row align="middle" justify="center" className="login-form">
        <Col xs={24} sm={16} md={8} className="form-card">
            <div className="header-titel">
            <p>
                {`${t("willkommen bei")}`}  <span>{`BENCHMARK YOUR PERFORMANCE (BYP)`}</span><br />
                
            </p>
            </div>
            <div className="header-activity">
            {`Your account activated successfully. Please, set your password.`}
                <br />
                {`${email}`}
            </div>
           
         
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }} 
                onFinish={onFinish.bind(this)}
                onFinishFailed={onFinishFailed.bind(this)}
                autoComplete="off"
            >
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Password is incorrect',
                        },
                        () => ({
                            validator(_, value) {
                                if (!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/g)) {
                                    return Promise.reject('Use at least 6 characters including (0,9), (a-z) and (A-Z)');
                                }
                                return Promise.resolve();
                            }
                        })
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label={`${t("Confirm Password")}`}
                    name="confirm"
                    rules={[
                        {
                            required: true,
                            message: 'Confirm Password is incorrect.',
                        },
                        () => ({
                            validator(_, value) {
                                if (value && value != form.getFieldValue('password')) {
                                    return Promise.reject('Password and Confirm Password do not match');
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Spin spinning={props.isLoading}>
                    <Form.Item
                        noStyle
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" className="form-btn">
                            Submit
                        </Button>
                    </Form.Item>
                </Spin>
            </Form>
        </Col>
    </Row>);
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.generalInfo.isLoading,
        commonResult: state.generalInfo.commonResponse
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        activateUser: (model: IConfirmTokenUser) => dispatch({ type: ActionTypes.ActivateUser, model: model }),
        //AuthUnMount: () => dispatch({ type: ActionTypes.AuthUnmount })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Activation);