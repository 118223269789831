import React, { useState, useRef } from "react";
import { Table, Form, Input, Image, Select, Upload, Button, UploadProps, UploadFile, Space, Avatar, Popconfirm, Tooltip, } from "antd";
import { TeamOutlined, UploadOutlined, PartitionOutlined, FileImageOutlined, ProjectOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { Link, useNavigate } from "react-router-dom";
import { getColumnSearchProps } from "../../../Components/SearchButton";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { ItemCustomerManagementDT } from "../../../Models/ItemAdminTable/Items";
import { IGeneralSettings } from "../../../Models/Responses/ProfileResponse";
import AdminOperation from "../../../Components/AdminOperation/AdminOperation";
import { RulesName, RulesRequire } from "../Rules/RulesValidation";
import { t } from "i18next";
import customerImg from "../../../assets/Images/customer_default.png";
import AdminTable from "../../../Components/AdminTable/AdminTable";


interface IProps {
    customerData: ItemCustomerManagementDT[];
    generalSettings: IGeneralSettings;
    onUpdateCustomer: (model: ItemCustomerManagementDT) => any;
    onDeleteImageCustomer: (customerId: number) => any,
    isModalLoading: boolean;
    isLoading: boolean;
}
const CustomerMngTable = (props: IProps) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [editingKey, setEditingKey] = useState('');

    const uploadProps: UploadProps = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end

    const columns: any[] = [
        {
            title: `${t("Logo")}`,
            dataIndex: "logo",
            width: "17%",
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name="logo" style={{ margin: 0 }}
                        >
                            <Upload accept="image/*"  {...uploadProps} >
                                <Button icon={<UploadOutlined />}>{`${t("Click to upload")}`}</Button>
                            </Upload>
                        </Form.Item>
                    );
                } else {
                    if (record.logo) {
                        return <Space size="middle">
                            <Avatar src={record.logo} size="large" />
                            <Popconfirm title={`${t("Sure to Remove Image")}`}
                                onConfirm={() => deleteImageCustomer(record?.id)}
                                getPopupContainer={() => document.getElementById("app-layout")}
                                cancelText={`${t("Cancel")}`}
                            >
                                <Tooltip title={`${t("Remove Image")}`} placement="bottom" getPopupContainer={() => document.getElementById("app-layout")}>
                                    <DeleteOutlined style={{ color: "red", fontSize: "15px" }} />
                                </Tooltip>
                            </Popconfirm>

                        </Space>

                    } else {
                        return <Avatar src={customerImg} size="large" />
                    }
                }

            }
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item
                            initialValue={record?.name}
                            name="name"
                            rules={RulesName({ name: `${t("Customer")} Name` ,countChar:128})}
                        >
                            <Input />
                        </Form.Item>
                    );
                } else {
                    return text;
                }
            },
            ...getColumnSearchProps(`${t("Customer")} Name`, 'name', searchInput, handleSearch),
            sorter: (a, b) => a?.name?.localeCompare(b?.name) ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            title: `${t("Location")}`,
            dataIndex: "location",
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item
                            initialValue={record?.location}
                            name="location"
                            rules={RulesName({ name: `${t("Location")}` ,countChar:128})}
                        >
                            <Input />
                        </Form.Item>
                    );
                } else {
                    return text
                }
            },
            ...getColumnSearchProps(`${t("Location")}`, 'location', searchInput, handleSearch),
        },

        {
            title: `${t("Currency")}`,
            dataIndex: "currencyId",
            width: '10%',
            render: (text: any, record) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item
                            name="currencyId"
                            style={{ margin: 0 }}
                            rules={RulesRequire({ filedName: `${t("Currency")}` })}
                        >
                            <Select
                                getPopupContainer={() => document.getElementById("app-layout")}
                                showSearch
                                placeholder={`${t("Select Currency")}`}
                                options={props.generalSettings?.curruncyList}
                                style={{
                                    textOverflow: "ellipsis",
                                    fontSize: "12px",
                                    width: "100px",
                                    zIndex: 5,
                                }}
                                filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                            />
                        </Form.Item>
                    );
                } else {
                    return props.generalSettings?.curruncyList?.find(c => c?.value === record.currencyId)?.label;
                }
            }
        },
        {
            title: `${t("Language")}`,
            dataIndex: "defaultLanguageId",
            width: '10%',
            render: (text: any, record: any, index) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item
                            name="defaultLanguageId"
                            style={{ margin: 0 }}
                            rules={RulesRequire({ filedName: `${t("Language")}` })}
                        >
                            <Select
                                getPopupContainer={() => document.getElementById("app-layout")}
                                showSearch
                                placeholder={`${t("Select Language")}`}
                                options={props.generalSettings?.languageList}
                                style={{
                                    textOverflow: "ellipsis",
                                    fontSize: "12px",
                                    width: "100px",
                                    zIndex: 5,
                                }}
                                filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                            />
                        </Form.Item>
                    );
                } else {
                    return props.generalSettings?.languageList?.find(c => c?.value === record.defaultLanguageId)?.label;
                }
            }
        },
        {
            title: `${t("Actions")}`,
            dataIndex: "actions",
            width: '10%',
            render: (text, record: any, index) => {
                const editable = isEditing(record);
                return <AdminOperation
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                />

            },
        },
        {
            title: `${t("Details")}`,
            dataIndex: "",
            render: (text: any, record: any) => {
                return <Space>
                    {/* <Link to={`/admin/customer/department?customerId=${record.id}&customerName=${record.name}`}>
                        <PartitionOutlined />
                        <p>{`${t("Department")}`}</p>
                    </Link> */}
                    <Link to={`/admin/customer/users?customerId=${record.id}&customerName=${record.name}`}>
                        <TeamOutlined />
                        <p>{`${t("Users")}`}</p>
                    </Link>
                    <Link to={`/admin/customer/bm-project?customerId=${record.id}&customerName=${record.name}`}>
                        <ProjectOutlined />
                        <p>{`${t("Bm Project")}`}</p>
                    </Link >
                </Space>
            }

        },
        // {
        //     title: "Users",
        //     dataIndex: "",
        //     render: (text, record: any) => {
        //         return <Link to={`/admin/customer-management/users/${record.id}`}>
        //             <TeamOutlined />
        //             <p>Users</p>
        //         </Link>
        //     }
        // },

    ];
    const isEditing = (record: any) => record.key === editingKey;
    const edit = (record: Partial<ItemCustomerManagementDT> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
        setFileList([]);
    };

    const onFinish = async (values: any) => {
        try {
            await form.validateFields();
            const index = props.customerData.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = props.customerData[index];
                const newCustomer: ItemCustomerManagementDT = {
                    ...values,
                    id: item.id,
                    logo: values?.logo?.file,
                };
                await props.onUpdateCustomer(newCustomer);
                setFileList([]);
                setEditingKey('');
            }

        } catch (e) {
            console.log("onEditRow ", e);
        }
    }
    const deleteImageCustomer = async (customerId: number) => {
        try {
            
            await props.onDeleteImageCustomer(customerId)
        } catch (e) {
            console.log("onDelete ", e);
        }
    }
    return (
        <Form form={form} key="cod_form_customer" onFinish={onFinish}>
            <AdminTable dataSource={props.customerData} keyTable="mng_table_customer" columns={columns} />
        </Form>
    )
}
const mapStateToProps = (state: any) => {
    return {
        generalSettings: state.generalInfo?.profileResponse?.generalSettings,
        isModalLoading: state.customerReducer?.isModalLoading,
        isLoading: state.customerReducer?.isCustomerLoading,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onDeleteImageCustomer: (customerId: number) =>
            dispatch({
                type: ActionTypes.DELETE_IMAGE_CUSTOMER,
                customerId
            }),
        onUpdateCustomer: (model: ItemCustomerManagementDT) =>
            dispatch({ type: ActionTypes.UPDATE_CUSTOMER, model: model }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerMngTable);