import { Button, Form, Input, InputNumber, Spin, Space } from "antd";
import { useState } from 'react';
import { ActionTypes } from "../../../Redux/actionsTypes";
import { connect } from "react-redux";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import ModalAdmin from "../../../Components/ModalAdmin/ModalAdmins";
import ColorPickerInput from "../../../Components/ColorPickerInput";
import { RulesColorPicker, RulesDescription, RulesName, RulesRequire } from "../Rules/RulesValidation";
import { t } from "i18next";
import { IColorValues } from "./FrontLoadingDepartment";
import { IFrontLoadingDepartment } from "../../../Models/IFrontLoading";
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";

interface IProps {
    departmentData: IFrontLoadingDepartment,
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    updateFrontLoadingDepartment: (model: IFrontLoadingDepartment) => any;
}
const FrontLoadingDepartmentEditModal = (props: IProps) => {
    const [form] = Form.useForm();
    const [colorValues, setColorsValues] = useState<IColorValues>({
        backgroundColor: props.departmentData?.backgroundColor,
        forgroundColor: props.departmentData?.forgroundColor
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            let values = await form.getFieldsValue();
            const updateDepartment: IFrontLoadingDepartment = {
                id: props.departmentData?.id,
                nameEn: values.nameEn.trimStart(),
                nameDe: values.nameDe.trimStart(),
                viewOrder: values.viewOrder,
                backgroundColor: colorValues?.backgroundColor?.substring(1),
                forgroundColor: colorValues?.forgroundColor?.substring(1),
                descriptionEn: values.descriptionEn,
                descriptionDe: values.descriptionDe,
                isOriginal: values.isOriginal
            };
            await props.updateFrontLoadingDepartment(updateDepartment)
        } catch (error) {
            console.log(error);

        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setColorsValues({
            backgroundColor: props.departmentData?.backgroundColor,
            forgroundColor: props.departmentData?.forgroundColor
        });
        setIsModalVisible(false);
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component discipline-component">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Discipline")}`} btnText={`${t("Edit")}`}
                        width="1000px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={props.departmentData}
                        >
                            <Form.Item label={`${t("English Name")}`} name='nameEn'
                                rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
                                style={{ width: "47.5%", marginRight: "2.5%", display: "inline-block" }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={`${t("German Name")}`} name='nameDe'
                                rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
                                style={{ width: "47.5%", marginLeft: "2.5%", display: "inline-block" }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={`${t("View Order")}`} name='viewOrder' rules={RulesRequire({ filedName: `${t("View Order")}` })}
                                validateTrigger='onBlur' hasFeedback style={{ width: "20%", display: "inline-block" }}>
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item label={`${t("Background Color")}`} name='backgroundColor' style={{ width: "20%", marginRight: "3.5%", display: "inline-block", textAlign: "center" }}
                                rules={RulesColorPicker({ filedName: `${t("Background Color")}`, colorValue: colorValues?.backgroundColor })} className="color-picker-required">
                                <ColorPickerInput colorValue={colorValues?.backgroundColor} onChangeComplete={async (color: any, event: any) => {
                                    setColorsValues({ ...colorValues, backgroundColor: color.hex })
                                    await form.validateFields();
                                }} />
                            </Form.Item>

                            <Form.Item label={`${t("Text Color")}`} name='forgroundColor' style={{ width: "10%", marginLeft: "3%", display: "inline-block", textAlign: "center" }}
                                rules={RulesColorPicker({ filedName: `${t("Text Color")}`, colorValue: colorValues?.forgroundColor })}
                                className="color-picker-required"
                            >
                                <ColorPickerInput colorValue={colorValues?.forgroundColor} onChangeComplete={async (color: any, event: any) => {
                                    setColorsValues({ ...colorValues, forgroundColor: color.hex })
                                    await form.validateFields();
                                }} />
                            </Form.Item>
                            <Form.Item label={`${t("English Description")}`} name='descriptionEn'
                                rules={RulesDescription({ name: `${t("English Description")}`, countChar: 1500 })} hasFeedback>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item label={`${t("German Description")}`} name='descriptionDe'
                                rules={RulesDescription({ name: `${t("German Description")}`, countChar: 1500 })}
                                hasFeedback>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name='isOriginal'  valuePropName="checked">
                                <Checkbox >{`${t("Is Original")}`}</Checkbox>
                            </Form.Item>
                        </Form>
                    </ModalAdmin>
                </Space>
            </div >
        </Spin >
    )
};
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.frontLoadingDepartment.isFrontLoadingDepartmentLoading,
        isModalLoading: state.frontLoadingDepartment.isModalLoading,
        modalResponse: state.frontLoadingDepartment.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateFrontLoadingDepartment: (model: IFrontLoadingDepartment) => dispatch({ type: ActionTypes.UPDATE_FRONT_LOADING_DEPARTMENT, model }),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontLoadingDepartmentEditModal);