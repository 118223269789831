import { IPotential } from "../../../../Models/EBIT/EstimateResult";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { formatValue } from "../../../../Utils/helperFunctions";

interface Iprops {
  chartTitle: string;
  type: string;
  data: IPotential[];
  scale: number;
  // totalSale: number;
  width?: number;
  height?: number;
  showTooltip?: boolean;
  isMinimized?: boolean;
  isTopLevel?: boolean;
  isEstimationChart?: boolean,
}

const Waterfallv2 = (props: Iprops) => {
  const { t } = useTranslation();
  //console.log("waterfall", props.data);
  const potentialLabel = props.isMinimized ? "Pot." : `${t("EBIT Potenzial")}`;
  const todayLabel = props.isMinimized ? t("Heute") : `${t("EBIT heute")}`;
  if (props.data && props.data.length == 0) return;
  const data = props.data?.map((item) => {
    return {
      Name:
        props.isMinimized && item.Name == "EBIT Potenzial"
          ? potentialLabel
          : props.isMinimized && item.Name == "EBIT heute"
            ? todayLabel
            : t(item.Name),

      Cost: isNaN(item.Cost) || item.Cost <= 0 ? 0 : item.Cost,
      Rate: isNaN(item.Rate) || item.Rate <= 0 ? 0 : item.Rate,
    };
  });
  let newValues;
  if (props.type === "Rate") {
    newValues = data?.map((curr, i, array) => {
      return {
        Name: curr.Name,
        Cost: curr.Cost,
        Costpv: 0,
        Rate: curr.Rate,
        Ratepv:
          array[i - 1] && array[i - 2]
            ? (array[i - 1].Rate += array[i - 2].Rate)
            : array[i - 1]
              ? array[i - 1].Rate
              : 0,
      };
    });
  } else {
    newValues = data?.map((curr, i, array) => {
      return {
        Name: curr.Name,
        Cost: curr.Cost,
        Costpv:
          array[i - 1] && array[i - 2]
            ? (array[i - 1].Cost += array[i - 2].Cost)
            : array[i - 1]
              ? array[i - 1].Cost
              : 0,
        Rate: curr.Rate,
        Ratepv: 0,
      };
    });
  }
  //console.log("waterfall newValues", newValues);
  let totalCost = newValues.reduce((accCost, item) => {
    if (item.Name !== todayLabel) accCost += item.Cost;
    return accCost;
  }, 0);
  let totalRate = newValues.reduce((accRate, item) => {
    if (item.Name !== todayLabel) accRate += item.Rate;
    return accRate;
  }, 0);
  let TotalCostpv = data[0].Cost;
  let TotalRatepv = data[0].Rate;
  // push the sum of the costs and rate (only for estimation waterfall)
  if (props.isEstimationChart) {
    var index = data.findIndex((x) => x.Name == potentialLabel);
    if (index === -1) {
      newValues.push({
        Name: potentialLabel,
        Cost: Number(totalCost),
        Costpv: TotalCostpv,
        Rate: totalRate,
        Ratepv: TotalRatepv,
      });
    }
    else {
      newValues[index].Costpv = TotalCostpv;
      newValues[index].Ratepv = TotalRatepv;
    }
  }
  else {
    newValues[6].Costpv = TotalCostpv;
    newValues[6].Ratepv = TotalRatepv;
  }
  //console.log("waterfall newValues", newValues);
  const colors = [
    "#fabf18",
    "#93c452",
    "#24a64f",
    "#086fb9",
    "#c11613",
    "#1e2a57",
    "#fabf18",
  ];

  const renderCustomizedLabel = (props, type, isMinimized: boolean) => {
    const index = props.index;
    const { x, y, width } = props;
    const radius = 10;
    const target = newValues[index];
    return (
      <g>
        <text
          fontSize={isMinimized ? "7px" : "12px"}
          x={x + width / 2}
          y={y - radius}
          fill={colors[index]}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {
            // target[type] !== 0 ?
              type === "Rate"
                ? `${formatValue(target[type])}%`
                : `${formatValue(target[type] / 1000000)}`
              // : "0.0"
              }
        </text>
      </g>
    );
  };

  return (
    <div
      className={props.isMinimized ? "" : "barChart_container_waterfull"}
      style={{ margin: props.isMinimized ? "0px" : props.isTopLevel ? "0px" : "5px 10px 5px 10px", height: props.isTopLevel ? '27vh' : null }}

    >
      <div className="chartTitle_container" style={{ textAlign: props.isTopLevel ? 'center' : null, }}>
        {!props.isTopLevel && <><span className="chartTitle" >
          <strong>{props.chartTitle}</strong>
        </span>
          <span className="chartType">
            {props.type === "Rate" ? "[in %]" : [`[in ${t("Millionen")} €]`]}
          </span></>
        }
      </div>
      <div
        className={props.isMinimized ? "" : "chart_container"}
        style={
          props.isMinimized
            ? { height: props.height } : { height: props.isTopLevel ? '26vh' :(200 + 10 * props.scale) }
        }
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={200 + 10 * props.scale}
            data={newValues}
            margin={
              props.isMinimized
                ? {}
                : {
                  top: 25,
                  right: 30,
                  left: 20,
                  bottom: 0,
                }
            }
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis
              dataKey="Name"
              // height={50}  
              angle={props.isMinimized ? 40 : 0}
              textAnchor={props.isMinimized ? 'middle' : 'middle'}
              dy={6}
              // rotate={props.isMinimized ? 45 : 0}
              // tickMargin={25}
              interval={0}
              fontSize={props.isMinimized ? "6px" : "12px"}
            />
            <YAxis
              domain={props.isEstimationChart ? (props.type === "Rate" ? [0, TotalRatepv + totalRate] : [0, TotalCostpv + totalCost])
                : props.isMinimized ?
                  (props.type === "Rate" ? [0, 6 / 5 * (newValues[6].Ratepv + newValues[6].Rate)] : [0, 6 / 5 * (newValues[6].Costpv + newValues[6].Cost)]) :
                  (props.type === "Rate" ? [0, newValues[6].Ratepv + newValues[6].Rate] : [0, newValues[6].Costpv + newValues[6].Cost])}

              max={props.isEstimationChart ? (props.type === "Rate" ? TotalRatepv + totalRate : TotalCostpv + totalCost)
                : props.isMinimized ?
                  (props.type === "Rate" ? 6 / 5 * (newValues[6].Ratepv + newValues[6].Rate) : 6 / 5 * (newValues[6].Costpv + newValues[6].Cost)) :
                  (props.type === "Rate" ? newValues[6].Ratepv + newValues[6].Rate : newValues[6].Costpv + newValues[6].Cost)}
              type={"number"} 
              hide={true}
              axisLine={false} 
            />
            {props.showTooltip ? <Tooltip /> : <></>} 
            <Bar
              dataKey={props.type === "Rate" ? "Ratepv" : "Costpv"}
              stackId="a"
              fill="transparent"
            />
            <Bar dataKey={props.type === "Rate" ? "Rate" : "Cost"} stackId="a" isAnimationActive={false}>

              {newValues?.map((item, index) => {
                return <Cell key={index} fill={colors[index]} />;
              })}
              <LabelList
                dataKey={props.type === "Rate" ? "Rate" : "Cost"}
                content={(prop) => renderCustomizedLabel(prop, props.type, props.isMinimized)}
                position={"top"}
              />

            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default Waterfallv2;
