import { ActionTypes } from "../actionsTypes";
import { IFrontLoadingState } from "./IGeneralState";

const initialState: IFrontLoadingState = {
    isFrontLoadingLoading: false,
    frontLoadingLoadingResult: null,
    isModalLoading: false,
    modalResponse: null,
}
const FrontLoadingTempReducer = (state = initialState, action: any): IFrontLoadingState => {
    switch (action.type) {
        case ActionTypes.CHANGE_FRONT_LOADING_LOADER:
            return {
                ...state,
                isFrontLoadingLoading: action.isLoading,
            };
        case ActionTypes.SAVE_FRONT_LOADING:
            return {
                ...state,
                frontLoadingLoadingResult: action.result
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            }
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            }
        default:
            break;
    }
    return state;
}
export default FrontLoadingTempReducer;
