import { Table, Typography } from "antd"
import { IErrorLogs } from "../../../Models/IErrorPage"
import { useRef } from "react";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { getColumnSearchProps } from "../../../Components/SearchButton";
import { t } from "i18next";

const ErrorLogTable = (props: { logs: IErrorLogs[] }) => {
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "message_key",
            title: `${t("Message")}`,
            dataIndex: 'message',
            width: "30%",
            render: (text: any, _: any) => {
                return <Typography>{text}</Typography>
            },
            ...getColumnSearchProps(`${t("Message")}`, 'message', searchInput, handleSearch),
            sorter: (a, b) => a?.message?.localeCompare(b?.message) ?? 0,
            // defaultSortOrder: 'ascend',
        },
        {
            key: "userName_key",
            title: `${t("User Name")}`,
            dataIndex: 'userName',
            width: "30%",
            render: (text: any, _: any) => {
                return <Typography>{text}</Typography>
            },
            ...getColumnSearchProps(`${t("User Name")}`, 'userName', searchInput, handleSearch),
            sorter: (a, b) => a?.userName?.localeCompare(b?.userName) ?? 0,
            defaultSortOrder: 'ascend',

        },
        {
            key: " dateTime_key",
            title: `${t("Date Time")}`,
            dataIndex: 'dateTime',
            width: "40%",
            render: (text: any, _: any) => {
                return <Typography>{text}</Typography>
            }
        },
    ]
    return (
        <Table
            key='error_logs_table'
            getPopupContainer={() => document.getElementById("app-layout")}
            bordered
            dataSource={props.logs}
            columns={columns}
            scroll={{ y: "calc(100vh - 300px)" }}
            pagination={{
                defaultPageSize: 50,
                pageSizeOptions: [50, 100, 150],
                total: props.logs.length,
                showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                showSizeChanger: true,
                locale:{items_per_page:`/ ${t("Page")}`},
            }}
            locale={{
                triggerDesc: t('Click to sort descending'),
                triggerAsc: t('Click to sort ascending'),
                cancelSort: t('Click to cancel sorting')
            }}

        />
    )
}
export default ErrorLogTable;