import { Modal, Popconfirm, Switch } from "antd";
import { ProfileResponse } from "../../Models/Responses/ProfileResponse";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IProfileUserUpdate } from "../../Models/IProfileUser";
import React, { useState } from "react";
import { LanguagesEnum } from "../../Enums/LanguagesEnum";
import { IGeneralState } from "../../Redux/reducers/IGeneralState";
import { ActionTypes } from "../../Redux/actionsTypes";
import { connect } from "react-redux";

interface IProps {
    profileData: ProfileResponse;
    updateProfile: (model: IProfileUserUpdate) => any;
}
const LanguageSwitcher = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isDe, setIsDe] = React.useState(((localStorage.getItem('localLang') ?? "de") == "de" ? true : false));

    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const hideModal = () => {
        setOpen(false);
    };

    const toggleTheme = async () => {
        console.log("toggleTheme: ", isDe);
        let isChecked = !isDe;
        setIsDe(isChecked);
        console.log("toggleTheme: ", isChecked);
        console.log();
        await localStorage.setItem("localLang", isChecked ? 'de': 'en');
        await i18n.changeLanguage(isChecked ? 'de': 'en');
        if (props.profileData) {
            //on update profile we make reload
            props.updateProfile({
                firstName: props.profileData.firstName,
                lastName: props.profileData.lastName,
                languageId: isChecked ? LanguagesEnum.de : LanguagesEnum.en,
                id: props.profileData.id,
                titleId: props.profileData.titleId,
                phoneNumber: props.profileData.phoneNumber,
                image: props.profileData.image
            });
        } else {
            window.location.reload();
        }
        hideModal();
    };

    return <>
        <Switch checked={isDe} checkedChildren={t("Deutsch UI")} unCheckedChildren={t("English UI")} onChange={showModal} />
        <Modal
            title={t("Are you sure?")}
            open={open}
            onOk={toggleTheme}
            onCancel={hideModal}
            okText="Ok"
            cancelText="No"
        >
            <p>{t("Unsaved data will be gone")}</p>
        </Modal>
    </>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        profileData: state.generalInfo?.profileResponse
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateProfile: (model: IProfileUserUpdate) => dispatch({ type: ActionTypes.UPDATE_PROFILE, model })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);