import { IIndustryAll } from "../../Models/IIndustry";
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import IndustryService from "../../Services/IndustryService";
import { ActionTypes } from "../actionsTypes";

function* OnGetIndustries() {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_LOADER, isLoading: true });
        let _result: ICommonResponse = yield IndustryService.GetIndustries();

        if (!_result.isError) {
            let result = _result.result as IIndustryAll[];
            yield put({ type: ActionTypes.SAVE_INDUSTRIES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetIndustries: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}
function* onCreateIndustry(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield IndustryService.CreateIndustry(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate  });
            let _result: ICommonResponse = yield IndustryService.GetIndustries();
            let result = _result.result as IIndustryAll[];
            yield put({ type: ActionTypes.SAVE_INDUSTRIES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateIndustry(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield IndustryService.UpdateIndustry(
            action.model
        );
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield IndustryService.GetIndustries();
            let result = _result.result as IIndustryAll[];
            yield put({ type: ActionTypes.SAVE_INDUSTRIES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result:resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteIndustry(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_LOADER, isLoading: true });
        let resultDelete: ICommonResponse = yield IndustryService.DeleteIndustry(
            action.id
        );
        if (!resultDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
            let _result: ICommonResponse = yield IndustryService.GetIndustries();
            let result = _result.result as IIndustryAll[];
            yield put({ type: ActionTypes.SAVE_INDUSTRIES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* watchGetIndustries() {
    yield takeEvery(ActionTypes.GET_INDUSTRIES, OnGetIndustries)
}
function* watchCreateIndustry() {
    yield takeEvery(ActionTypes.CREATE_INDUSRTY, onCreateIndustry)
}
function* watchUpdateIndustry() {
    yield takeEvery(ActionTypes.UPDATE_INDUSRTY, onUpdateIndustry)
}
function* watchDeleteIndustry() {
    yield takeEvery(ActionTypes.DELETE_INDUSTRY, onDeleteIndustry)
}

export default function* industrySagas() {
    yield all([
        watchGetIndustries(),
        watchCreateIndustry(),
        watchUpdateIndustry(),
        watchDeleteIndustry()
    ])
}