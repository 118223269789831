import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import ProfileMngService from "../../Services/ProfileMngService";
import ProfileService from "../../Services/ProfileService";
import { ProfileResponse } from "../../Models/Responses/ProfileResponse";
import { LanguagesEnum } from "../../Enums/LanguagesEnum";

function* onUpdateProfile(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield ProfileMngService.UpdateProfile(action.model);
        if (!resultUpdate?.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            window.location.reload();
            // let _result: ICommonResponse = yield ProfileService.GetHomePage();
            // let result = _result.result as ProfileResponse;
            // localStorage.setItem("localLang", result?.languageId == LanguagesEnum.de ? 'de' : 'en');
            // yield put({ type: ActionTypes.SaveHomePage, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdate Profile: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onGetResendActivationLink(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_Resend_Activation_Link_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultActiveEmail: ICommonResponse = yield ProfileMngService.GetResendActivationLink(action.userId);
        if (!resultActiveEmail.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultActiveEmail });
            let result = resultActiveEmail.result;
            yield put({ type: ActionTypes.SAVE_Resend_Activation_Link, result: result });
            // yield put({ type: ActionTypes.SetModalResponse, result: resultActiveEmail });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultActiveEmail });
        }
        yield put({ type: ActionTypes.CHANGE_Resend_Activation_Link_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in on Get Resend Activation Link: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onRemoveProfileImage(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultRemove: ICommonResponse = yield ProfileMngService.RemoveProfileImage();
        if (!resultRemove.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultRemove });
            let _result: ICommonResponse = yield ProfileService.GetHomePage();
            let result = _result.result as ProfileResponse;
            localStorage.setItem("localLang", result?.languageId == LanguagesEnum.de ? 'de' : 'en');
            yield put({ type: ActionTypes.SaveHomePage, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultRemove });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in on Remove Profile Image: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onChangePassword(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultChange: ICommonResponse = yield ProfileMngService.ChangePasswordProfile(action.model);
        if (!resultChange.isError) {
            let _result: ICommonResponse = yield ProfileService.GetHomePage();
            let result = _result.result as ProfileResponse;
            localStorage.setItem("localLang", result?.languageId == LanguagesEnum.de ? 'de' : 'en');
            yield put({ type: ActionTypes.SaveHomePage, result: result });
            yield put({ type: ActionTypes.SetClearForm, clearForm: 1 });
            yield put({ type: ActionTypes.SetCommonResponse, result: resultChange });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultChange });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onChange Password: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* ToClearForm(action: any) {
    yield put({ type: ActionTypes.SetClearForm, clearForm: 0 });
}
function* watchUpdateProfile() {
    yield takeEvery(ActionTypes.UPDATE_PROFILE, onUpdateProfile)
}

function* watchGetResendActivationLink() {
    yield takeEvery(ActionTypes.GET_Resend_Activation_Link, onGetResendActivationLink)
}
function* WatchRemoveProfileImage() {
    yield takeEvery(ActionTypes.REMOVE_PROFILE_IMAGE, onRemoveProfileImage)
}
function* WatchChangePassword() {
    yield takeEvery(ActionTypes.CHANGE_PASSWORD, onChangePassword)
}

function* WatchToClearForm() {
    yield takeEvery(ActionTypes.ToResetClearForm, ToClearForm)
}


export default function* ProfileForUsersSagas() {
    yield all([
        watchGetResendActivationLink(),
        watchUpdateProfile(),
        WatchRemoveProfileImage(),
        WatchChangePassword(),
        WatchToClearForm()
    ]);
}