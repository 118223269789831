import { useEffect, useState } from 'react';
import { IUserManagement } from '../../../Models/IUserEmployee';
import { Button, Checkbox, Form, Input, Select, Space, Spin } from "antd";
import { connect } from 'react-redux';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import ModalAdmin from '../../../Components/ModalAdmin/ModalAdmins';
import { RulesEmail, RulesName, RulesPhone, RulesRequire } from '../Rules/RulesValidation';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { IGeneralSettings } from '../../../Models/Responses/ProfileResponse';
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import UserManagementTable from './UserManagementTable';
import { t } from 'i18next';

interface IProps {
    usersData: IUserManagement[];
    isLoading: boolean;
    generalSettings: IGeneralSettings;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getAllUsers: () => any;
    createUser: (model: IUserManagement) => any;
}
const UserManagement = (props: IProps) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<IUserManagement>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => { props.getAllUsers() }, []);
    const usersDataRow: IUserManagement[] = props.usersData?.map(u => {
        return { ...u, key: `user_${u.id}` }
    }) ?? [];
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const newUser = { ...formValues };

            await props.createUser(newUser);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setIsModalVisible(false);
        setFormValues({} as IUserManagement);

    }

    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Users")}`} btnText={`${t("Add")}`}
                        width="520px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>
                        <Form
                            layout="vertical"
                            form={form}

                        >
                            <Form.Item label={`${t("First Name")}`} name='firstName'
                                rules={RulesName({ name: `${t("First Name")}`, countChar: 128 })}
                                hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, firstName: (e.target.value).trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("Last Name")}`} name='lastName'
                                rules={RulesName({ name: `${t("Last Name")}`, countChar: 128 })}
                                hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, lastName: (e.target.value).trimStart() })} />
                            </Form.Item>
                            <Form.Item name='email' label="E-Mail" rules={RulesEmail()} hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, email: (e.target.value).trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("Title")}`} name='titleId' rules={RulesRequire({ filedName: t('Title') })} style={{ width: '48%', display: "inline-block", marginRight: "2%" }} >
                                <Select showSearch placeholder={`${t("Select Title")}`}
                                    options={props.generalSettings?.titles}
                                    optionFilterProp="children"
                                    getPopupContainer={() => document.getElementById("app-layout")}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    onChange={(value: number) => { setFormValues({ ...formValues, titleId: value }) }} />
                            </Form.Item>
                            <Form.Item label={`${t("Language")}`} name='languageId' rules={RulesRequire({ filedName: `${t("Language")}` })} style={{ width: '48%', display: "inline-block", marginLeft: "2%" }} >
                                <Select
                                    onChange={(value: number) =>
                                        setFormValues({ ...formValues, languageId: value })
                                    }
                                    optionFilterProp="children"
                                    getPopupContainer={() => document.getElementById("app-layout")}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    showSearch
                                    placeholder={`${t("Select Language")}`}
                                    options={props.generalSettings?.languageList}
                                />
                            </Form.Item>
                            <Form.Item label={`${t("Phone")}`} name="phoneNumber"
                                rules={RulesPhone()}

                            >
                                <Input onChange={(e) => setFormValues({ ...formValues, phoneNumber: (e.target.value).trimStart() })} />
                            </Form.Item>
                        </Form>

                    </ModalAdmin>
                    < UserManagementTable usersDataRow={usersDataRow} />
                </Space>
            </div >
        </Spin >
    )
}
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.usersMng?.isUserLoading,
        usersData: state.usersMng?.usersResult,
        generalSettings: state.generalInfo?.profileResponse?.generalSettings,
        isModalLoading: state.usersMng.isModalLoading,
        modalResponse: state.usersMng.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllUsers: () => dispatch({ type: ActionTypes.GET_ALL_USERS }),
        createUser: (model: IUserManagement) => dispatch({ type: ActionTypes.CREATE_USER, model }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

