import { ToastMessages } from "../Enums/Messages";
import { ISelfAssessmentTempBp } from "../Models/ISelfAssessmentTemp";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { ISelfAssessmentSave } from "../Models/SelfAssessment/ISelfAssessmentView";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import { saveAs } from "file-saver";
export default class SelfAssessmentTempService {
    static async GetSelfAssessments(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.getSelfAssessmentBpTemp)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1", error.response);
                    if (error.response.data) {
                        console.log('error0', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }

                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            })
    }
    static async CreateSelfAssessmentBestPractice(model: ISelfAssessmentTempBp): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.createSelfAssessmentBpTemp, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1", error.response);
                    if (error.response.data) {
                        console.log('error0', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }

                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            })
    }
    static async UpdateSelfAssessmentBestPractice(model: ISelfAssessmentTempBp): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.updateSelfAssessmentBpTemp, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1", error.response);
                    if (error.response.data) {
                        console.log('error0', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }

                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            })
    }
    static async DeleteSelfAssessmentBestPractice(id: number): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.deleteSelfAssessmentBpTemp, {
                params: {
                    id
                }
            })
            .then(function (response) {
                console.log("DeleteSelfAssessmentBestPractice ", response.data);
                let result = response.data;
                console.log("DeleteSelfAssessmentBestPractice ", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1", error.response);
                    if (error.response.data) {
                        console.log('error0', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }

                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            })
    }
    static async GetSelfAssessmentView(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetViewSelfAssessment)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1", error.response);
                    if (error.response.data) {
                        console.log('error0', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }

                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            })
    }
    static async SaveSelfAssessment(model: ISelfAssessmentSave): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.SaveSelfAssessmentBp, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1", error.response);
                    if (error.response.data) {
                        console.log('error0', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }

                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            })
    }
    static async GetSelfAssessmentHistoryOfImportance(id: number): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetHistoryOfImportance, {
                params: {
                    id
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1", error.response);
                    if (error.response.data) {
                        console.log('error0', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }

                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            })
    }

    static async ExportToExcel(projectName: string) {
        await asupapi
            .get(endPoints.ExportSelfAssessmenetToExcel, {
                responseType: 'blob'
            })
            .then(function (response) {
                var contentDisposition = response.headers["content-disposition"];
                var match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
                
                //console.log("filename:", filename);

                var filename = match[1];
                var blob = new Blob([response.data], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, filename); 
            }).catch(function (error) {
                console.log('error1 ', error);
            });
    }
}