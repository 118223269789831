import { ToastMessages } from "../Enums/Messages";
import { IKpiItem, ILeverItem, IQuestionItem, ISubLeverItem, ISuccessFactorItem } from "../Models/ILeversManagement";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
export default class LeversForAdminService {
  static async GetLeversForAdmin(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getAllLeversForAdmin, {})
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async UpdateLever(model: ILeverItem): Promise<ICommonResponse> {
    console.log("Update lever", model);
    return await asupapi
      .post(endPoints.updateLeverForAdmin, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async UpdateSubLever(model:ISubLeverItem): Promise<ICommonResponse> {
    console.log("Update SubLever", model);
    return await asupapi
      .post(endPoints.updateSubLeverForAdmin, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async UpdateSuccessFactor(model:ISuccessFactorItem): Promise<ICommonResponse> {
    console.log("Update SuccessFactor", model);
    return await asupapi
      .post(endPoints.updateSuccessFactorForAdmin, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async UpdateKpi(model:IKpiItem): Promise<ICommonResponse> {
    console.log("Update Kpis", model);
    return await asupapi
      .post(endPoints.updateKpiForAdmin, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async UpdateQuestion(model:IQuestionItem): Promise<ICommonResponse> {
    console.log("Update Question", model);
    return await asupapi
      .post(endPoints.updateQuestionForAdmin, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
}