import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Tag, Tooltip, Typography } from 'antd';
import { CheckCircleOutlined, ClearOutlined, CloseCircleOutlined, FolderOpenOutlined, MailOutlined, PoweroffOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../Utils/helperFunctions';
import { ToastMessages } from '../../Enums/Messages';
import { ICommonResponse } from '../../Models/Responses/ICommonResponse';
import { t } from 'i18next';
import './index.scss';

interface IProps {
    modalResponse: ICommonResponse;
    btnTitle?: string;
    title: string;
    isModalLoading: boolean;
    handleOk: () => any;
    resendActive?: boolean;
    activeUser?: boolean;
    deactivateUser?: boolean;
    reOpenBmProject?: boolean;
    iconPower?: boolean;
    include?: boolean;
    exclude?: boolean
}
const PopconfirmAsync = (props: IProps) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (open) {
            if (props.modalResponse?.isError) {
                if (typeof props.modalResponse?.error?.exceptionMessage == 'string') {
                    if (props.modalResponse?.error?.exceptionMessage) {
                        openNotificationWithIcon("error", "Error", props.modalResponse.error?.exceptionMessage as string);
                    } else {
                        openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
                    }
                } else {
                    openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
                }
            } else {
                if (props.modalResponse?.message) {
                    openNotificationWithIcon("success", "Success", props.modalResponse?.message);
                    handleCancel();
                }
            }
        }
    }, [props.modalResponse]);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Popconfirm
            title={props.title}
            // open={open}
            onConfirm={props.handleOk}
            okButtonProps={{ loading: props.isModalLoading }}
            onCancel={handleCancel}
            getPopupContainer={() => document.getElementById("app-layout")}
            cancelText={`${t("Cancel")}`}
            className='popconfirm_async'

        >
            {
                props.resendActive && <Tooltip title={`${t("Resend Activation Link")}`} style={{ cursor: "pointer" }} getPopupContainer={() => document.getElementById("app-layout")}>
                    <Typography.Link style={{ cursor: ' pointer' }} onClick={showPopconfirm}>
                        {props.btnTitle}<MailOutlined style={{ color: "lightslategray", fontSize: "20px" }} />
                    </Typography.Link>
                </Tooltip>
            }{
                (props.deactivateUser ? (props.iconPower ? (<PoweroffOutlined className="version-icon-on" />) :
                    (<CheckCircleOutlined style={{ fontSize: "18px", cursor: " pointer" }} />)) : null)

            }{
                (props.activeUser ? (props.iconPower
                    ? (<PoweroffOutlined className="version-icon-off" />) :
                    (<CloseCircleOutlined style={{ fontSize: "18px", cursor: " pointer" }} />)) : null)

            }
            {
                props.reOpenBmProject && <Tooltip title={`${t("Reopen Bm Project")}`} getPopupContainer={() => document.getElementById("app-layout")}>
                    <Space direction="vertical" style={{ cursor: " pointer" }} size={2}>
                        <FolderOpenOutlined style={{ fontSize: "18px" }} />
                        <Typography.Text strong>{`${t("Reopen")}`}</Typography.Text>
                        <Typography.Text strong>{`${t("Bm Project")}`}t</Typography.Text>
                    </Space>
                </Tooltip>
            }{
                props.include && <Tag style={{ cursor: " pointer", display: "flex", alignItems: "center", padding: ".3rem .2rem" ,  }} >
                    <CheckCircleOutlined style={{ fontSize: "18px"}} />
                    <p style={{ marginBottom: 0, marginLeft: ".5rem", fontWeight: "bold" }}>{`${t("Included")}`}</p>
                </Tag>

            }{
                props.exclude && <Tag style={{ cursor: " pointer", display: "flex", alignItems: "center", padding: ".3rem .2rem" }} >
                   <CloseCircleOutlined style={{ fontSize: "18px", cursor: " pointer" }} />
                    <p style={{ marginBottom: 0, marginLeft: ".5rem", fontWeight: "bold" }}>{`${t("Excluded")}`}</p>
                </Tag>

            }
        </Popconfirm>
    );
};

export default PopconfirmAsync;
export const PopconfirmClearAll = (props: { onConfirm: () => any; bpId: number | null }) => {
    const [open, setOpen] = useState(false);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return props.bpId ? (
        <Popconfirm title={`${t("Sure to Clear All?")}`} onConfirm={props.onConfirm} getPopupContainer={() => document.getElementById("app-layout")} onCancel={handleCancel} open={open}>
            <Button style={{ display: "flex", padding: "10px 30px", justifyContent: "space-between", alignItems: "center", marginLeft: "10px" }} onClick={showPopconfirm}>
                <ClearOutlined /> <Typography.Title level={5} style={{ color: "currentcolor" }}>
                    {`${t("Clear All")}`}</Typography.Title>
            </Button>
        </Popconfirm >
    ) :
        (<Button style={{ display: "flex", padding: "10px 30px", justifyContent: "space-between", alignItems: "center", marginLeft: "10px" }} disabled>
            <ClearOutlined /> <Typography.Title level={5} style={{ color: "currentcolor" }}>
                {`${t("Clear All")}`}</Typography.Title>
        </Button>)
}