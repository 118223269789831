import { ReactNode } from "react";

export default class FlTableColumn {
    title: ReactNode;
    key: string;
    dataIndex?: string;
    width?: number | string;
    fixed?: 'left' | 'right';
    align?: 'left' | 'right' | 'center';
    className?: string;
    render?: (text: any, record: any, index: number) => ReactNode;
    onCell?: (record: any, rowIndex: number) => any;
    children?: FlTableColumn[];
    constructor(
        Title: ReactNode,
        Key: string,
        options?: {
            DataIndex?: string,
            width?: number | string;
            fixed?: 'left' | 'right';
            align?: 'left' | 'right' | 'center';
            className?: string;
            render?: (text: any, record: any, index: number) => ReactNode;
            onCell?: (record: any, rowIndex: number) => any;
            children?: FlTableColumn[]
        }
    ) {
        this.title = Title;
        this.key = Key;
        this.dataIndex = options?.DataIndex?? null;
        if(options?.width ){
        this.width = options?.width ?? 100;
        }
        if(options?.fixed)
        {
            this.fixed = options?.fixed ?? "left";
        }
        this.align = options?.align ?? "center";
        this.className = options?.className?? null;
        this.render = options?.render??null;
        this.onCell = options?.onCell??null;
        this.children = options?.children ?? [];
    }
} 