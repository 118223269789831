export enum HeatMapQEFColorsLimits{
    EF_Red_L = 0,
    EF_Red_H = 20,

    EF_Orange_L = 20,
    EF_Orange_H = 40,

    EF_Yellow_L = 40,
    EF_Yellow_H = 60,

    EF_Chartreuse_L = 60,
    EF_Chartreuse_H = 80,

    EF_Green_L = 80,
    EF_Green_H = 100,
}