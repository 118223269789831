import { Button, Form, Input, Popconfirm, Select, Space, Table, TableProps, Tooltip, Typography } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, StopOutlined, EditOutlined } from "@ant-design/icons";
import React, { useRef, useState } from 'react';
import { IAdministratorItemWithKey, IAdministratorNew } from '../../../../Models/IAdministrator'
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import PopconfirmAsync from '../../../../Components/PopconfirmAsync';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { t } from 'i18next';

interface IProps {
    dataAdminUsers: IAdministratorItemWithKey[];
    titlesData: any[];
    languageListData: any[];
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    updateAdminUser: (model: IAdministratorNew) => any;
    getResendActivationLink: (userId: number) => any;
}

const AdminUsersTable = (props: IProps) => {
    const { dataAdminUsers } = props;
    const [editingKey, setEditingKey] = useState('');
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const [form] = Form.useForm();
    const columns: any[] = [
        {
            key: "admin_key_1",
            title: 'Title',
            dataIndex: 'titleId',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='titleId' rules={[{ required: true, message: "Please enter Title" }]}  >
                            <Select showSearch placeholder="Select Title" optionFilterProp="children" >
                                {props.titlesData?.map((t, index) => <Select.Option value={t.value} key={index}>{t.label}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    )
                } else {
                    return <p>{props.titlesData?.find(t => t.value === record?.titleId)?.label}</p>
                }
            },
            editable: true,
        },
        {
            key: "admin_key_2",
            title: 'First Name',
            dataIndex: 'firstName',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='firstName' rules={[{ required: true, message: "Please enter First Name" }]} >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            editable: true,
            ...getColumnSearchProps("First Name","firstName", searchInput, handleSearch),
            sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName) ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: "user_key_3",
            title: 'Last Name',
            dataIndex: 'lastName',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='lastName' rules={[{ required: true, message: "Please enter Last Name" }]} >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            editable: true,
            ...getColumnSearchProps("Last Name","lastName", searchInput, handleSearch),
            sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName) ?? 0,
            // defaultSortOrder: 'ascend',
        },
        {
            key: "user_key_4",
            title: 'E-mail',
            dataIndex: 'email',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='email'
                        >
                            <Input disabled />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            editable: true,
        },
        {
            key: "user_key_5",
            title: 'Phone',
            dataIndex: 'phoneNumber',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='phoneNumber' rules={[{ required: true, message: "Please enter  Phone" }]}>
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            editable: true,
        },
        {
            key: "user_key_6",
            title: 'Role',
            dataIndex: 'role',

        },
        {
            key: "con_key_8",
            title: 'Language',
            dataIndex: 'defaultLanguage',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return <Form.Item name='defaultLanguageId' rules={[{ required: true, message: "Please enter language" }]}  >
                        <Select
                                showSearch
                                placeholder="Select..."
                                options={props?.languageListData}
                            />
                    </Form.Item>
                } else {
                    return <>{props.languageListData?.find(t => t.value === record?.languageId)?.label}</>
                }
            },
            editable: true,
        },
        {
            title: 'Active',
            dataIndex: 'Actions',
            width: '100px',
            fixed: "right",
            render: (_: any, record: IAdministratorItemWithKey) => {
                return <Space>
                    <PopconfirmAsync
                        title="Do you want resend Active link"
                        handleOk={() => props.getResendActivationLink(record.id)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        resendActive={true}
                    />
                </Space>

            },
        },
        {
            title: 'Actions',
            dataIndex: '',
            width: '100px',
            render: (_: any, record: IAdministratorItemWithKey) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space size="small">
                        <Button htmlType="submit" type='link' >
                            <Tooltip title="Save">
                                <CheckCircleOutlined style={{ color: "green", fontSize: "20px" }} />
                            </Tooltip>
                        </Button>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel} >
                            <Tooltip title={`${t("Cancel")}`}>
                                <StopOutlined style={{ cursor: "pointer", color: "red", fontSize: "20px" }} />
                            </Tooltip>
                        </Popconfirm>
                    </Space >
                ) : (<Space size="small">
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        <Tooltip title={`${t("Edit")}`}>
                            <EditOutlined style={{ color: "#4faaff", fontSize: "20px" }} />
                        </Tooltip>
                    </Typography.Link>
                    <Popconfirm title={`${t("Sure to delete?")}`} onConfirm={() => deleteUser(record.id)}>
                        <Tooltip title={`${t("Delete")}`}>
                            <DeleteOutlined style={{ cursor: "pointer", color: "red", fontSize: "20px" }} />
                        </Tooltip>
                    </Popconfirm>
                </Space>)
            },
        },


    ];

    const isEditing = (record: IAdministratorItemWithKey) => record.key === editingKey;
    const edit = (record: Partial<IAdministratorItemWithKey> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            const index = dataAdminUsers.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = dataAdminUsers[index];
                const newAdminUser = {
                    id: item.id,
                    ...values
                }
                console.log(newAdminUser);
                await props.updateAdminUser(newAdminUser)
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }

    }
    const deleteUser = (id: any) => {
        console.log(id);
    }


    return (
        <Form form={form} key="admins_form" onFinish={onFinish}>
            <Table
                key='admins_table'
                bordered
                dataSource={dataAdminUsers}
                columns={columns}
                rowClassName="editable-row"
                scroll={{ y: "calc(100vh - 300px)" }}
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 150],
                    total: dataAdminUsers.length,
                    showTotal: (total) => `Total ${total} items`,
                    showSizeChanger: true
                }}
            />

        </Form>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getResendActivationLink: (userId: number) => dispatch({ type: ActionTypes.GET_Resend_Activation_Link, userId }),
        updateAdminUser: (model: IAdministratorNew) => dispatch({ type: ActionTypes.UPDATE_ADMINISTRATOR, model })
    }
}
export default connect(null, mapDispatchToProps)(AdminUsersTable);
