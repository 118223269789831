import { Button, Modal, Space, Tooltip, Typography } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from '../../Utils/helperFunctions';
import { ToastMessages } from '../../Enums/Messages';
import './index.scss';
import { t } from 'i18next';

interface IProps {
    title: string |ReactElement;
    width: string;
    btnText?: string | ReactElement;
    titleTooltip?: string;
    modalResponse?: any;
    isModalVisible: boolean;
    setIsModalVisible: (v: boolean) => any;
    children: JSX.Element;
    footerchildren: JSX.Element[];
    closeAndReset: () => any;
    eventClick?: any;
};
const ModalAdmin = (props: IProps) => {
    useEffect(() => {
        if (props.isModalVisible) {
            if (props.modalResponse?.isError) {
                if (typeof props.modalResponse?.error?.exceptionMessage == 'string') {
                    if (props.modalResponse?.error?.exceptionMessage) {
                        openNotificationWithIcon("error", "Error", props.modalResponse.error?.exceptionMessage as string);
                    } else {
                        openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
                    }
                } else {
                    openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
                }
            } else {
                if (props.modalResponse?.message) {
                    openNotificationWithIcon("success", "Success", props.modalResponse?.message);
                    props.closeAndReset();
                }
            }
        }
    }, [props.modalResponse]);


    const handleCancel = () => {
        props.closeAndReset();
    };
    const ButtonAdmin = () => {
        switch (props.btnText) {
            case 'editIcon':
                return <Tooltip title={`${props.titleTooltip ?? t("Edit")}`}><EditOutlined onClick={
                    () => {
                        props.setIsModalVisible(true)
                    }
                } style={{ color: "#4faaff" }} />
                </Tooltip>
            case 'addIcon':
                return <Tooltip title={`${props.titleTooltip ?? t("Add")}`}><PlusOutlined onClick={
                    () => {
                        props.setIsModalVisible(true)
                    }
                } />
                </Tooltip>
            case t('Add'):
                return <Button type="primary" onClick={
                    () => {
                        props.setIsModalVisible(true)
                    }
                } shape="default" icon={<PlusOutlined />} >
                    {props.btnText}
                </Button >
            case t('Edit'):
                return <Button type="primary" onClick={
                    () => {
                        props.setIsModalVisible(true)
                    }
                } shape="default" icon={<EditOutlined style={{ fontSize: "16px" }} />} >
                    {props.btnText}
                </Button>

            default:
                return <Button type="primary" onClick={() => props.setIsModalVisible(true)}>
                    <Tooltip title={props.titleTooltip}>
                        {props.btnText}
                    </Tooltip>
                </Button>
        }
    }
    return (
        <div className='general-model-component '>
            <>{!props.btnText ? (
                <div className='ant-card-head-wrapper ant-card-head'>
                    {/* <div className='ant-card-head-title'>{`${t("Department")}`}</div> */}
                    <Button type="ghost" className="add-new-depart-bm" onClick={() => props.setIsModalVisible(true)}>{`${t("Add")}`}</Button>
                </div>
            ) : (
                ButtonAdmin()
            )}</>



            <Modal
                className='admin-model'
                title={props.title}
                getContainer={() => document.getElementById('app-layout')}
                centered
                open={props.isModalVisible}
                onOk={null}
                onCancel={handleCancel}
                footer={props.footerchildren}
                width={props.width}
                style={{ height: "auto" }}
            >
                {[props.children]}
            </Modal>
        </div >
    );
};

export default ModalAdmin;