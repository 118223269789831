import { ToastMessages } from "../../Enums/Messages";
import { takeEvery, put, all } from "redux-saga/effects";
import { ActionTypes } from "../actionsTypes";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import ActivityForAdminService from '../../Services/ActivityManagementForAdminService';
import { IActivityProfileTemplate, IActivityGroupTemplate, IActivityTask, IProfileVersionsAll, IActivityTaskTemplate, IActivityProfileVersionDetail } from "../../Models/IActivityManagement";

function* onGetActivityProfileForAdmin() {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
        if (!_result.isError) {
            let result = _result.result as IActivityProfileTemplate[];
            yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({
            type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER,
            isLoading: false,
        });
    } catch (error) {
        console.error("ERR in onGetActivityProfileForAdmin: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateActivityProfile(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield ActivityForAdminService.UpdateActivityProfile(action.model);

        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
            let result = _result.result as IActivityGroupTemplate[];
            yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
            // yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });

        }
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateActivityProfile: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onCreateActivityProfile(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield ActivityForAdminService.CreateActivityProfile(action.model);
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
            let result = _result.result as IActivityGroupTemplate[];
            yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });

        }
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateActivityProfile: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateActivityGroup(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield ActivityForAdminService.UpdateActivityGroup(action.model);

        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
            let result = _result.result as IActivityGroupTemplate[];
            yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
            // yield put({ type: ActionTypes.SetModalResponse, result: resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateActivityGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onCreateActivityGroup(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield ActivityForAdminService.CreateActivityGroup(action.model);

        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
            let result = _result.result as IActivityGroupTemplate[];
            yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateActivityGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onCreateActivityTask(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield ActivityForAdminService.CreateActivityTask(action.model);

        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
            let result = _result.result as IActivityGroupTemplate[];
            yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateActivityTask: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateActivityTask(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield ActivityForAdminService.UpdateActivityTask(action.model);

        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
            let result = _result.result as IActivityTask[];
            yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
            // yield put({ type: ActionTypes.SetModalResponse, result: resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateActivityGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onGetActivityProfileVersions(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        let _result: ICommonResponse = yield ActivityForAdminService.getProfileVersions(action.profileId);
        if (!_result.isError) {
            let result = _result.result as IProfileVersionsAll;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSIONS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetActivityProfileVersions: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}

function* onGetActivityProfileVersionDetails(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        let _result: ICommonResponse = yield ActivityForAdminService.getActivityProfileVersionDetails(action.profileVersionId);
        if (!_result.isError) {
            let result = _result.result as IActivityProfileVersionDetail;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSION_Details, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetActivityProfileVersionDetails: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}

function* onChangeStatusActivityProfile(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultChange: ICommonResponse = yield ActivityForAdminService.changeStatusActivityProfile(action.profileId, action.isActive);
        if (!resultChange.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultChange });
            let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
            let result = _result.result as IActivityGroupTemplate[];
            yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultChange });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultChange });

        }
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onChangeStatusActivityProfile: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onChangeStatusActivityGroup(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultChange: ICommonResponse = yield ActivityForAdminService.changeStatusActivityGroup(action.groupId, action.isActive);
        if (!resultChange.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultChange });
            if (action.profileId) {
                let _result: ICommonResponse = yield ActivityForAdminService.getProfileVersions(action.profileId);
                let result = _result.result as IProfileVersionsAll;
                yield put({ type: ActionTypes.SAVE_PROFILE_VERSIONS, result: result });
            } else {
                let _result: ICommonResponse = yield ActivityForAdminService.GetActivityProfileForAdmin();
                let result = _result.result as IActivityGroupTemplate[];
                yield put({ type: ActionTypes.SAVE_ACTIVITY_FOR_ADMIN, result: result });
            }
            yield put({ type: ActionTypes.SetModalResponse, result: resultChange });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultChange });

        }
        yield put({ type: ActionTypes.CHANGE_ACTIVITY_FOR_ADMIN_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onChangeStatusActivityGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onGetActivityProfileTasks(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_TASKS_LOADER, isLoading: true });
        let _result: ICommonResponse = yield ActivityForAdminService.getActivityProfileAllTasks(action.profileId);
        if (!_result.isError) {
            let result = _result.result as IActivityTaskTemplate[];
            yield put({ type: ActionTypes.SAVE_PROFILE_TASKS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_TASKS_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetActivityProfileVersions: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}
function* onAddEditMapTasksToGroup(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultAdd: ICommonResponse = yield ActivityForAdminService.addEditMapTasksToGroups(action.model);
        if (!resultAdd.isError) {
            let _result: ICommonResponse = yield ActivityForAdminService.getActivityProfileVersionDetails(action.profileVersionId);
            let result = _result.result as IActivityProfileVersionDetail;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSION_Details, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultAdd });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultAdd });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in  onAddEditMapTasksToGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onEditMapTasksToGroup(action: any) {
    try { 
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultAdd: ICommonResponse = yield ActivityForAdminService.editMapTasksToGroups(action.model);
        if (!resultAdd.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultAdd });
            let _result: ICommonResponse = yield ActivityForAdminService.getActivityProfileVersionDetails(action.profileVersionId);
            let result = _result.result as IActivityProfileVersionDetail;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSION_Details, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultAdd });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in  onAddEditMapTasksToGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteTaskFromGroup(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultDelete: ICommonResponse = yield ActivityForAdminService.deleteTaskFromMapGroup(action.id);
        if (!resultDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
            let _result: ICommonResponse = yield ActivityForAdminService.getActivityProfileVersionDetails(action.profileVersionId);
            let result = _result.result as IActivityProfileVersionDetail;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSION_Details, result: result });
            // yield put({ type: ActionTypes.SetModalResponse, result: resultDelete });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDeleteTaskFromGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onUpdateProfileVersion(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield ActivityForAdminService.updateProfileVersion(action.profileVersionId, action.nameEn, action.nameDe);
        if (!resultCreate.isError) {
            let _result: ICommonResponse = yield ActivityForAdminService.getActivityProfileVersionDetails(action.profileVersionId);
            let result = _result.result as IActivityProfileVersionDetail;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSION_Details, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDeleteTaskFromGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onCreateNewProfileVersion(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield ActivityForAdminService.createNewProfileVersion(action.profileId, action.fromProfileVersionId,
            action.nameEn, action.nameDe);
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield ActivityForAdminService.getProfileVersions(action.profileId);
            let result = _result.result as IProfileVersionsAll;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSIONS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDeleteTaskFromGroup: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onChangeStatusActivityProfileVersion(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultChange: ICommonResponse = yield ActivityForAdminService.changeStatusActivityProfileVersion(action.profileVersionId, action.isActive);
        if (!resultChange.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultChange });
            let _result: ICommonResponse = yield ActivityForAdminService.getProfileVersions(action.profileId);
            let result = _result.result as IProfileVersionsAll;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSIONS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultChange });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultChange });

        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onChangeStatusActivityProfileVersion: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteMapGroupToProfile(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultDelete: ICommonResponse = yield ActivityForAdminService.deleteMapGroupToProfile(action.groupId, action.versionId);
        if (!resultDelete.isError) {
            let _result: ICommonResponse = yield ActivityForAdminService.getActivityProfileVersionDetails(action.profileVersionId);
            let result = _result.result as IActivityProfileVersionDetail;
            yield put({ type: ActionTypes.SAVE_PROFILE_VERSION_Details, result: result });
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDeleteGroupToProfile: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* watchGetActivityProfileVersionDetails() {
    yield takeEvery(ActionTypes.GET_ACTIVITY_PROFILE_VERSION_Detail, onGetActivityProfileVersionDetails)
}
function* watchGetActivityProfileVersions() {
    yield takeEvery(ActionTypes.GET_ACTIVITY_PROFILE_VERSIONS, onGetActivityProfileVersions)
}
function* watchGetActivityProfileForAdmin() {
    yield takeEvery(ActionTypes.GET_ACTIVITY_FOR_ADMIN, onGetActivityProfileForAdmin);
}
function* watchUpdateActivityProfile() {
    yield takeEvery(ActionTypes.UPDATE_ACTIVITY_PROFILE_FOR_ADMIN, onUpdateActivityProfile);
}
function* watchCreateActivityProfile() {
    yield takeEvery(ActionTypes.CREATE_ACTIVITY_PROFILE_FOR_ADMIN, onCreateActivityProfile);
}
function* watchCreateActivityGroupForAdmin() {
    yield takeEvery(ActionTypes.CREATE_ACTIVITY_GROUP_FOR_ADMIN, onCreateActivityGroup);
}
function* watchUpdateActivityGroupForAdmin() {
    yield takeEvery(ActionTypes.UPDATE_ACTIVITY_GROUP_FOR_ADMIN, onUpdateActivityGroup);
}
function* watchUpdateActivityTaskForAdmin() {
    yield takeEvery(ActionTypes.UPDATE_ACTIVITY_TASK_FOR_ADMIN, onUpdateActivityTask);
}
function* watchCreateActivityTaskForAdmin() {
    yield takeEvery(ActionTypes.CREATE_ACTIVITY_TASK_FOR_ADMIN, onCreateActivityTask);
}
function* watchChangeStatusActivityProfile() {
    yield takeEvery(ActionTypes.CHANGE_STATUS_ACTIVITY_PROFILE, onChangeStatusActivityProfile)
}
function* watchChangeStatusActivityGroup() {
    yield takeEvery(ActionTypes.CHANGE_STATUS_ACTIVITY_GROUP, onChangeStatusActivityGroup)
}
function* watchGetActivityProfileTasks() {
    yield takeEvery(ActionTypes.GET_ACTIVITY_PROFILE_TASKS, onGetActivityProfileTasks)
}
function* watchAddEditMapTasksToGroup() {
    yield takeEvery(ActionTypes.ADD_EDIT_MAP_TASK_GROUP, onAddEditMapTasksToGroup)
}
function* watchEditMapTasksToGroup() {
    yield takeEvery(ActionTypes.EDIT_MAP_TASK_GROUP, onEditMapTasksToGroup)
}
function* watchDeleteTaskFromGroup() {
    yield takeEvery(ActionTypes.DELETE_TASK_FROM_GROUP, onDeleteTaskFromGroup)
}
function* watchCreateNewProfileVersion() {
    yield takeEvery(ActionTypes.CREATE_NEW_PROFILE_VERSION, onCreateNewProfileVersion)
}
function* watchUpdateProfileVersion() {
    yield takeEvery(ActionTypes.Update_PROFILE_VERSION, onUpdateProfileVersion)
}
function* watchChangeStatusActivityProfileVersion() {
    yield takeEvery(ActionTypes.CHANGE_STATUS_ACTIVITY_PROFILE_VERSION, onChangeStatusActivityProfileVersion)
}
function* watchDeleteMapGroupToProfile() {
    yield takeEvery(ActionTypes.DELETE_MAP_GROUP_TO_PROFILE, onDeleteMapGroupToProfile)
}
export default function* LeversMngSaga() {
    yield all([
        watchUpdateProfileVersion(),
        watchGetActivityProfileVersionDetails(),
        watchGetActivityProfileForAdmin(),
        watchUpdateActivityProfile(),
        watchUpdateActivityGroupForAdmin(),
        watchUpdateActivityTaskForAdmin(),
        watchCreateActivityProfile(),
        watchCreateActivityGroupForAdmin(),
        watchCreateActivityTaskForAdmin(),
        watchGetActivityProfileVersions(),
        watchChangeStatusActivityProfile(),
        watchChangeStatusActivityGroup(),
        watchGetActivityProfileTasks(),
        watchAddEditMapTasksToGroup(),
        watchEditMapTasksToGroup(),
        watchDeleteTaskFromGroup(),
        watchCreateNewProfileVersion(),
        watchChangeStatusActivityProfileVersion(),
        watchDeleteMapGroupToProfile(),
    ]);
}
