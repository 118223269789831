import { Button, Popover, Checkbox, Table, Switch, Tooltip, Badge, InputNumber, Select, Tag, Space, Typography, Empty } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import ReactDragListView from "react-drag-listview";
import { connect } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import { ILever, ILeverCell, ILeverQuestion, ILeversPageConfig } from '../../../Models/Levers/Items';
import { formatValue, inputFormatter, inputParser } from '../../../Utils/helperFunctions';
import SubQuestionSection from './SubQuestionsTemplate/SubQuestionSection';
import i18n from '../../../i18n';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import { ActionTypes } from '../../../Redux/actionsTypes';
import LeverInfoGraphicEvaluationChart from './LeversChart/LeverInfoGraphicEvaluationChart';
import AsupBarChart_Ant from '../../Charts/AsupBarChart/AsupBarChart_Ant';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IListItem } from '../../../Models/Responses/ProfileResponse';
import { t } from 'i18next';
const { Text } = Typography;
interface DataType {
  key: React.ReactNode;
  title: any,
  ef: any;
  kpi: any;
  kpiWert: any;
  //einheit: any;
  wertebereich: any;
  kommentar: any;
  Ziel: any;
  //Erklärung: any;
  //Erledigt: any;
  Erfassung: any;
  Gewichtung: any;
  Funktionsart: any;
  piuntergrenze: any;
  bestpracticevalue: any;
  piobergrenze: any;
  kpierfüllungsgrad: any;
  eferfüllungsgrad: any;
  tsherfüllungsgrad: any;
  fe: any;
  inno: any;
  cod: any;
  hk: any;
  qk: any;
  bpminus: any;
  bpplus: any;
  estimate: any;
  children?: DataType[];
}

interface ILeverFormProps {
  lever: ILever;
  config: ILeversPageConfig;
  isCustomer: boolean;
  selectedCollectionRole: number[];
  selectedQuestionStatuses: number[];
  changeLever: (model: ILever) => any;
  onchangeSubquestionInput: (value: any, questionV: ILeverQuestion, cell: ILeverCell) => any;
  onchangeSubquestionTable: (questionV: ILeverQuestion) => any;
  questionStatusList: IListItem[];
  saveLevers: () => any;
  onChangeQuestion: (questionId: number) => any;
}

function LeversTable(props: ILeverFormProps) {
  const onChangeComment = (value: any, questionId: number) => {
    var temp = { ...props.lever };
    props.onChangeQuestion(questionId);
    temp.subLevers?.map(sublever => {
      sublever.successFactors.map(successFactor => {
        successFactor.kpiLevels.map(kpiLevel => {
          kpiLevel.questions.map(question => {
            if (question.id == questionId) {
              question.currentCustomerComment = value;
            }
          });
        });
      });
    });
    props.changeLever(temp);
  }
  const onchangeQuestionStatusId = (value: any, questionV: ILeverQuestion) => {
    var temp = { ...props.lever };
    props.onChangeQuestion(questionV.id);
    temp.subLevers?.map(sublever => {
      sublever.successFactors.map(successFactor => {
        successFactor.kpiLevels.map(kpiLevel => {
          kpiLevel.questions.map(question => {
            if (question.code == questionV.code) {
              question.statusId = value;
            }
          });
        });
      });
    });
    props.changeLever(temp);
  }
  const changeQuestionEstimationStatus = (value: CheckboxChangeEvent, questionV: ILeverQuestion) => {
    var temp = { ...props.lever };
    props.onChangeQuestion(questionV.id);
    temp.subLevers?.map(sublever => {
      sublever.successFactors.map(successFactor => {
        successFactor.kpiLevels.map(kpiLevel => {
          kpiLevel.questions.map(question => {
            if (question.code == questionV.code) {
              question.isEstimated = value.target.checked;
              console.log("changeQuestionEstimationStatus", value.target.checked);
            }
          });
        });
      });
    });
    props.changeLever(temp);
  }
  const getQuestionAnswerTooltip = (questionCode: string) => {
    switch (questionCode) {
      case "q_1_1_1_1_1": {
        return `${t("Data from admin bm project information")}.`
      }
      case "q_1_1_3_2_1": {
        return `${t("Data from admin bm project information")}.`
      }
      case "q_2_5_1_2_1": {
        return `${t("See")} 3.5.2.1`
      }
      case "q_2_5_2_1_1": {
        return `${t("See")} 3.5.2.1`
      }
      case "q_4_1_3_1_1": {
        return `${t("See")} 3.5.2.1`
      }
      case "q_4_1_3_2_1": {
        return `${t("See")} 3.5.2.1`
      }
      case "q_2_3_2_3_1": {
        return t("Connected with Frontloading")
      }
      case "q_2_3_2_4_1": {
        return t("Connected with Frontloading")
      }
      case "q_1_5_1_1_1": {
        return t("From R&D in Ebit : KPI = Total internal R&D costs/Total R&D costs")
      }
      case "q_3_4_3_1_1": {
        return `${t("See")} 2.2.2.1`
      }
      case "q_3_4_1_1_1": {
        return t("Connected with dproject and cost of delay in Ebit")
      }
      case "q_3_4_3_2_1": {
        return t("From manufactoring cost type in Ebit")
      }
      default: {
        return t("Calculated Value")
      }
    }
  }
  const { Option } = Select;
  const dataReal: DataType[] = (props.lever as ILever)?.subLevers?.map(sublever => {
    var temp: DataType = {
      key: `sublever_${sublever.id}`,
      title: `${sublever.number} ${sublever.name}`,
      bestpracticevalue: null,
      bpminus: null,
      bpplus: null,
      ef: null,
      eferfüllungsgrad: null,
      Erfassung: null,
      //Erledigt: null,
      estimate: null,
      fe: <Tag color="orange">{`${formatValue(sublever.fe * 100)}%`}</Tag>,
      cod: <Tag color="orange">{`${formatValue(sublever.cod * 100)}%`}</Tag>,
      qk: <Tag color="orange">{`${formatValue(sublever.qc * 100)}%`}</Tag>,
      inno: <Tag color="orange">{`${formatValue(sublever.inno * 100)}%`}</Tag>,
      hk: <Tag color="orange">{`${formatValue(sublever.mc * 100)}%`}</Tag>,
      Funktionsart: null,
      Gewichtung: <Tag color="default">{sublever.weighting}{"%"}</Tag>,
      kpi: null,
      kpierfüllungsgrad: null,
      piobergrenze: null,
      piuntergrenze: null,
      tsherfüllungsgrad: <Tag color="geekblue" className='kpi-value'>
        {formatValue(sublever.tshDegreeOfFulfillment)}{"%"}
      </Tag>,
      Ziel: null,
      kpiWert: (props.isCustomer) ? <></> : <Tag color="geekblue" className='kpi-value'> {formatValue(sublever.tshDegreeOfFulfillment)}&nbsp;{"%"}</Tag>,
      wertebereich: null,
      kommentar: null,
      //Erklärung: null,
      children: sublever.successFactors.map(successfactor => {
        var sftemp: DataType = {
          key: `successfactor_${successfactor.id}`,
          title: `${successfactor.number} ${successfactor.name}`,
          bestpracticevalue: null,
          bpminus: null,
          bpplus: null,
          cod: null,
          ef: null,
          eferfüllungsgrad: <Tag color="geekblue" className='kpi-value'>{formatValue(successfactor.efDegreeOfFulfillment)}{"%"}</Tag>,
          Erfassung: null,
          //Erledigt: null,
          estimate: null,
          fe: null,
          Funktionsart: null,
          Gewichtung: <Tag color="default">{successfactor.weighting}{"%"}</Tag>,
          inno: null,
          kpi: null,
          kpierfüllungsgrad: null,
          piobergrenze: null,
          piuntergrenze: null,
          qk: null,
          tsherfüllungsgrad: null,
          Ziel: null,
          kpiWert: (props.isCustomer) ? <></> : <Tag color="geekblue" className='kpi-value'> {formatValue(successfactor.efDegreeOfFulfillment)}&nbsp;{"%"}</Tag>,
          //einheit: null,
          wertebereich: null,
          kommentar: null,
          hk: null,
          //Erklärung: null,
          children: successfactor.kpiLevels.filter(kpi => props.selectedCollectionRole.length == 0 ||
            kpi.collectionRoles.map(cr => { return cr.value }).some(ccc => props.selectedCollectionRole.map(t => t).includes(ccc)))
            .map(kpiLevel => {
              var kpilevelTemp: DataType = {
                key: `kpi_${kpiLevel.id}`,
                title: <>{`${kpiLevel.number} ${kpiLevel.name}`}&nbsp;{(props.isCustomer) ? null : <Tag color="#2db7f5">
                  {kpiLevel.collectionRoles.map(c => { return c.label.toUpperCase() }).join('/')}
                </Tag>}</>,
                bestpracticevalue: null,
                bpminus: null,
                bpplus: null,
                cod: null,
                ef: null,
                eferfüllungsgrad: null,
                Erfassung: null, //<Tag color="#2db7f5">PM/FC</Tag>,
                //Erledigt: null,
                estimate: null,
                fe: null,
                Funktionsart: null,
                Gewichtung: null,
                inno: null,
                kpi: null,
                kpierfüllungsgrad: null,
                piobergrenze: null,
                piuntergrenze: null,
                qk: null,
                tsherfüllungsgrad: null,
                Ziel: null,
                kpiWert: (props.isCustomer) ? <></> : <Tag color="geekblue" className='kpi-value'>{formatValue(kpiLevel.kpiValue)}&nbsp;{"%"}</Tag>,
                //einheit: null,
                wertebereich: null,
                kommentar: null,
                hk: null,
                //Erklärung: null,
                children: kpiLevel.questions
                  .filter(ques => props.selectedQuestionStatuses.length == 0 ||
                    props.selectedQuestionStatuses.includes(ques.statusId))
                  .map(question => {
                    var questionTemp = {
                      key: `question_${question.id}`,
                      title: <SubQuestionSection
                        saveLevers={props.saveLevers}
                        isCustomer={props.isCustomer}
                        key={`q_subquestionsection_${question.id}`} question={question}
                        onchangeSubquestionInput={props.onchangeSubquestionInput.bind(this)}
                        onchangeSubquestionTable={props.onchangeSubquestionTable.bind(this)} />,

                      kpiWert: (question.kpiValue?.formula == null || question.kpiValue?.formula == "") ?
                        (
                          (question.kpiValue.unit == '%') ?
                            <InputNumber
                              min={0} max={100}
                              addonAfter={<>{question.kpiValue.unit}</>}
                              key={`input_field_${question.code}_${question.id}`}
                              name={`${question.code}_${question.kpiValue.code}`}
                              formatter={value => `${inputFormatter(value, i18n.language)}`}
                              parser={x => inputParser(x, i18n.language)}
                              value={question.kpiValue.value}
                              onChange={(e) => {
                                props.onchangeSubquestionInput(e, question, question.kpiValue);
                              }}
                            /> : <InputNumber
                              addonAfter={<>{question.kpiValue.unit}</>}
                              key={`input_field_${question.code}_${question.id}`}
                              name={`${question.code}_${question.kpiValue.code}`}
                              formatter={value => `${inputFormatter(value, i18n.language)}`}
                              parser={x => inputParser(x, i18n.language)}
                              value={question.kpiValue.value}
                              onChange={(e) => {
                                props.onchangeSubquestionInput(e, question, question.kpiValue);
                              }}
                            />
                        )
                        : props.isCustomer? null: <Tooltip title={getQuestionAnswerTooltip(question.code)} placement="top">
                          <Tag color="geekblue" className='kpi-value'>
                            {formatValue(question.kpiValue.value)}&nbsp;{question.kpiValue.unit}
                          </Tag>
                        </Tooltip>
                      ,
                      bpminus: null,
                      bpplus: null,
                      cod: null,
                      ef: null,
                      StatusId: <Select
                        getPopupContainer={() => document.getElementById('app-layout')}
                        key={`question_status_select_${question.id}`
                        }
                        onChange={(e) => { onchangeQuestionStatusId(e, question) }
                        }
                        value={question.statusId}
                        style={{
                          textOverflow: "wrap",
                          width: "100%",
                          fontSize: "12px",
                        }}
                        options={props.questionStatusList}
                      />,
                      eferfüllungsgrad: null,
                      Erfassung: null,
                      estimate: <Checkbox checked={question.isEstimated} onChange={(e) => { changeQuestionEstimationStatus(e, question) }} />,

                      Gewichtung: <Tag color="default">{question.weighting}{"%"}</Tag>,
                      inno: null,
                      kpi: null,
                      kpierfüllungsgrad: <Tag color="geekblue" className='kpi-value'>
                        {formatValue(question.kpiDegreeOfFulfillment)}{"%"}
                      </Tag>,
                      Funktionsart: <Popover getPopupContainer={() => document.getElementById('app-layout')}
                        placement="bottomLeft"
                        key={`popover_functionType_${question.id}`}
                        content={<LeverInfoGraphicEvaluationChart
                          lowRange={question.minInput}
                          hightRange={question.maxInput}
                          bestPractice={question.bestPracticeValue}
                          functionTypeId={question.functionType.value}
                          piLower={question.piFloor}
                          piUpper={question.piUpperLimit}
                          xAxisUnit={question.unit}
                        />}
                        trigger="hover"
                      >
                        <Tag color="default">{question.functionType.label}</Tag>
                      </Popover>,
                      piuntergrenze: <Tag color="default">{`${formatValue(question.piFloor)}${question.unit == "%" ? "%" : ""}`}</Tag>,
                      bestpracticevalue: <Tag color="default">{`${formatValue(question.bestPracticeValue)}${question.unit == "%" ? "%" : ""}`}</Tag>,
                      piobergrenze: <Tag color="default">{`${formatValue(question.piUpperLimit)}${question.unit == "%" ? "%" : ""}`}</Tag>,
                      einheit: null,
                      wertebereich: <Tag color="default">{`${question.minInput} ${t('bis')} ${question.maxInput}`}</Tag>,
                      kommentar: (props.isCustomer) ? <TextArea
                        onChange={(e) => { onChangeComment(e.target.value, question.id) }}
                        value={question.currentCustomerComment}
                        allowClear
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        placeholder="Your Comment" maxLength={256} /> : null,

                      hk: null,
                      fe: null,
                      qk: null,
                      tsherfüllungsgrad: null,
                      Ziel: null,
                    };
                    return questionTemp;
                  })
              };
              return kpilevelTemp;
            })
        };
        return sftemp;
      })
    };
    return temp;

  }) ?? [];


  const xWidth = props.config.columns.slice(6, props.config.columns.length).length * props.config.columnsWidth + 500;
  const yHight = props.isCustomer ? 'calc(100vh - 250px)' : 'calc(100vh - 330px)';
  //const xWidth = props.config.columns.slice(6, 22).length * props.config.columnsWidth + 500;
  //console.log('sdhf=' + xWidth);
  if (dataReal && dataReal.length > 0) {
    return (
      <div className='levers-table'>
        <Table
          className={"one-lever-table"}
          rowClassName={() => "one-lever-row"}
          key="lever_table_"
          size="small"
          //defaultExpandAllRows
          columns={props.config.columns.sort((a, b) => a.index < b.index ? -1 : 1)}
          //scroll={{ x: xWidth, y: 'calc(100vh - 250px)' }}
          scroll={{ x: xWidth, y: yHight }}
          pagination={false}
          bordered={false}
          dataSource={dataReal}
          expandable={{
            defaultExpandAllRows: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <MinusCircleOutlined style={{ fontSize: '10px' }} onClick={e => onExpand(record, e)} />
              ) : (
                <PlusCircleOutlined style={{ fontSize: '10px' }} onClick={e => onExpand(record, e)} />
              )
          }}
        />
      </div>
    );
  } else {
    <Empty description={`${t("No data")}`}></Empty>
  }
};

const mapStateToProps = (state: IGeneralState) => {
  return {
    isLoading: state.leverTool.isLeverLoading,
    questionStatusList: state.generalInfo?.profileResponse?.generalSettings?.questionStatusList,
    selectedCollectionRole: state.leverTool.selectedCollectionRoles,
    selectedQuestionStatuses: state.leverTool.selectedQuestionStatuses
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeLever: (model: ILever) => dispatch({ type: ActionTypes.ChangeLever, model: model })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeversTable);