
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import { ISegmentDisciplineBestPracticeItem } from "../../Models/ISegmentDisciplineBestPractice";
import SegmentBestPracticeDisciplineService from "../../Services/SegmentDisciplineBPService";



function* OnGetAllSegmentBestPracticeDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: true });
        let _result: ICommonResponse = yield SegmentBestPracticeDisciplineService.GetSegmentBestPracticeDiscipline(action.industrySegmentId)
        if (!_result.isError) {
            let result = _result.result as ISegmentDisciplineBestPracticeItem[];
            yield put({ type: ActionTypes.SAVE_SEGMENT_DISCIPLINE_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetSegmentDisciplineBestPRactice: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onUpdateSegmentBestPracticeDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield SegmentBestPracticeDisciplineService.UpdateDisciplinesBP(action.model);
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let resultCus: ICommonResponse = yield SegmentBestPracticeDisciplineService.GetSegmentBestPracticeDiscipline(action.model.industrySegmentId);
            let result = resultCus.result as ISegmentDisciplineBestPracticeItem[];
            yield put({ type: ActionTypes.SAVE_SEGMENT_DISCIPLINE_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onClearSegmentBestPracticeDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: true });
        let resultClear: ICommonResponse = yield SegmentBestPracticeDisciplineService.ClearDisciplinesBP(action.industrySegmentId);
        if (!resultClear.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultClear });
            let resultCus: ICommonResponse = yield SegmentBestPracticeDisciplineService.GetSegmentBestPracticeDiscipline(action.industrySegmentId);
            let result = resultCus.result as ISegmentDisciplineBestPracticeItem[];
            yield put({ type: ActionTypes.SAVE_SEGMENT_DISCIPLINE_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultClear });
        }
        yield put({ type: ActionTypes.CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onClearQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}


function* watchGetAllSegmentBestPracticeDiscipline() {
    yield takeEvery(ActionTypes.GET_SEGMENT_DISCIPLINE_BEST_PRACTICES, OnGetAllSegmentBestPracticeDiscipline);
}

function* watchUpdateSegmentBestPracticeDiscipline() {
    yield takeEvery(ActionTypes.UPDATE_SEGMENT_DISCIPLINE_BEST_PRACTICE, onUpdateSegmentBestPracticeDiscipline);
}
function* watchClearSegmentBestPracticeDiscipline() {
    yield takeEvery(ActionTypes.CLEAR_SEGMENT_DISCIPLINE_BEST_PRACTICE, onClearSegmentBestPracticeDiscipline);
}
export default function* SegmentBestPracticeDisciplineSagas() {
    yield all([
        watchGetAllSegmentBestPracticeDiscipline(),
        watchUpdateSegmentBestPracticeDiscipline(),
        watchClearSegmentBestPracticeDiscipline()
    ]);
}
