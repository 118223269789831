import React, { useRef, useState } from 'react'
import { IUserManagement } from '../../../Models/IUserEmployee';
import { Form, Input, Select, Space, Table, Tag, Image, Avatar } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { IGeneralSettings } from '../../../Models/Responses/ProfileResponse';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { RulesName, RulesPhone, RulesRequire } from '../Rules/RulesValidation';
import AdminOperation from '../../../Components/AdminOperation/AdminOperation';
import PopconfirmAsync from '../../../Components/PopconfirmAsync';
import userImage from "../../../assets/Images/user_image.png";


import { t } from 'i18next';

interface IProps {
    usersDataRow: IUserManagement[];
    generalSettings: IGeneralSettings;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    updateUser: (model: IUserManagement) => any;
    activateUser: (userId: number) => any;
    deactivateUser: (userId: number) => any;
    getResendActivationLink: (userId: number) => any;
    getHomePage: () => void;
}

const UserManagementTable = (props: IProps) => {
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "user_key_img",
            title: `${t("Image")}`,
            dataIndex: "image",
            render: (_: any, record: any) => {
                if (record?.image) {
                    return <Avatar src={record?.image} size={50} />
                } else {
                    return <Avatar src={userImage} size={50} />
                }

            }

        },
        {
            key: "user_key_1",
            title: t('Title'),
            dataIndex: 'titleId',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='titleId' rules={RulesRequire({ filedName: 'Title' })} >
                            <Select
                                showSearch
                                placeholder={`${t("Select Title")}`}
                                options={props.generalSettings?.titles}
                                getPopupContainer={() => document.getElementById("app-layout")}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            />
                        </Form.Item>
                    )
                } else {
                    return <p>{props.generalSettings?.titles?.find(t => t.value === record?.titleId)?.label}</p>
                }
            },
            editable: true,
        },
        {
            key: "user_key_2",
            title: `${t("First Name")}`,
            dataIndex: 'firstName',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='firstName'
                            rules={RulesName({ name: `${t("First Name")}`,countChar:128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            editable: true,
            ...getColumnSearchProps(`${t("First Name")}`, "firstName", searchInput, handleSearch),
            sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName) ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: "user_key_3",
            title: `${t("Last Name")}`,
            dataIndex: 'lastName',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='lastName'
                            rules={RulesName({ name: `${t("Last Name")}`,countChar:128 })} >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            editable: true,
            ...getColumnSearchProps(`${t("Last Name")}`, "lastName", searchInput, handleSearch),
            sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName) ?? 0,
            // defaultSortOrder: 'ascend',
        },
        {
            key: "user_key_4",
            title: "E-Mail",
            dataIndex: 'email',
            width: "20%",
            render: (text: any,  record: IUserManagement) => {
                if(record.isConfirmed)   return <p>{text}</p>;
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='email'>
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            editable: true,
            ...getColumnSearchProps("E-Mail", "email", searchInput, handleSearch),
            sorter: (a, b) => a?.email?.localeCompare(b?.email) ?? 0,
        },
        {
            key: "user_key_5",
            title: `${t("Phone")}`,
            dataIndex: 'phoneNumber',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='phoneNumber'
                            rules={RulesPhone()}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            editable: true,
        },
        {
            key: "con_key_8",
            title: `${t("Language")}`,
            dataIndex: 'languageId',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return <Form.Item name='languageId' rules={RulesRequire({ filedName: `${t("Language")}` })}  >
                        <Select
                            showSearch
                            placeholder="Select..."
                            options={props.generalSettings?.languageList}
                            getPopupContainer={() => document.getElementById("app-layout")}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        />
                    </Form.Item>
                } else {
                    return <>{props.generalSettings?.languageList?.find(t => t.value === record?.languageId)?.label}</>
                }
            },
            editable: true,
        },
        {
            key: "con_key_11",
            title: `${t("Actions")}`,
            dataIndex: '',
            render: (_: any, record: IUserManagement) => {
                const editable = isEditing(record);
                return <AdminOperation
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}

                />
            },
        },

        {
            key: "con_key_10",
            title: `${t("Active")}`,
            dataIndex: '',
            render: (_: any, record: IUserManagement) => {
                const activeStatus = record.isActive;
                return activeStatus ? (
                    <PopconfirmAsync
                        title={`${t("Sure to deactivate?")}`}
                        handleOk={() => deactivateStatsUser(record.id)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        deactivateUser={true}
                    />
                ) : (
                    <PopconfirmAsync
                        title={`${t("Sure To Activate?")}`}
                        handleOk={() => activeStatsUser(record.id)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        activeUser={true}
                    />
                )
            }
        },
        {
            title: `${t("Active E-Mail")}`,
            dataIndex: '',
            width: '100px',
            render: (_: any, record: any) => {
                if (record.isConfirmed) {
                    return <Tag color="geekblue">{`${t("Confirmed")}`}</Tag>
                } else {
                    return <Space>
                        <PopconfirmAsync
                            title={`${t("Do you want resend Activation link?")}`}
                            handleOk={() => props.getResendActivationLink(record.id)}
                            isModalLoading={props.isModalLoading}
                            modalResponse={props.modalResponse}
                            resendActive={true}
                        />

                    </Space>

                }

            },
        },

    ];

    const isEditing = (record: IUserManagement) => record.key === editingKey;
    const edit = (record: Partial<IUserManagement> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            const index = props.usersDataRow.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = props.usersDataRow[index];
                const newtUser = {
                    id: item.id,
                    titleId: Number(values.titleId),
                    languageId: Number(values.languageId),
                    ...values
                }
                await props.updateUser(newtUser);
                await props.getHomePage();
                form.resetFields();
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }

    }
    const activeStatsUser = async (id: any) => {
        try {
            await props.activateUser(id);
        } catch (errInfo) {
            console.log('change status Failed:', errInfo);
        }
    }
    const deactivateStatsUser = async (id: any) => {
        try {
            await props.deactivateUser(id)
        } catch (errInfo) {
            console.log('change status Failed:', errInfo);
        }
    }
    return (
        <Form form={form} key="user_form" onFinish={onFinish}>
            <Table
                key='user_table'
                getPopupContainer={() => document.getElementById("app-layout")}
                bordered
                dataSource={props.usersDataRow}
                columns={columns}
                rowClassName="editable-row"
                scroll={{ y: "calc(100vh - 300px)" }}
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 150],
                    total: props.usersDataRow.length,
                    showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                    showSizeChanger: true,
                    locale: { items_per_page: `/ ${t("Page")}` },
                }}
                locale={{
                    triggerDesc: t('Click to sort descending'),
                    triggerAsc: t('Click to sort ascending'),
                    cancelSort: t('Click to cancel sorting')
                }}
            />
        </Form>
    );
};
const mapStateToProps = (state: any) => {
    return {
        generalSettings: state.generalInfo?.profileResponse?.generalSettings,
        isModalLoading: state.usersMng.isModalLoading,
        modalResponse: state.usersMng.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateUser: (model: IUserManagement) => dispatch({ type: ActionTypes.UPDATE_USER, model }),
        activateUser: (userId: number) => dispatch({ type: ActionTypes.ACTIVE_USER, userId }),
        deactivateUser: (userId: number) => dispatch({ type: ActionTypes.DEACTIVATE_USER, userId }),
        getResendActivationLink: (userId: number) => dispatch({ type: ActionTypes.GET_Resend_Activation_Link, userId }),
        getHomePage: () => dispatch({ type: ActionTypes.GetHomePage }),


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserManagementTable);



