import { connect } from "react-redux";
import EBITTabs from "./EBITTabs-V2";

function EBITTool2(props) {
  return <EBITTabs />;
}

const mapStateToProps = (state: any) => {
  return {};
};
const mapDispatchToProps = (dispatch: any) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(EBITTool2);
