import {
  Form,
  InputNumber,
  Typography,
  Row,
  Col,
  Input,
  Select,
  Popconfirm,
  Space,
  Tooltip,
  Table,
  Segmented,
  Checkbox,
  Spin,
} from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  StopOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  StopTwoTone,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { inputFormatter, inputParser, formatValue } from "../../../../Utils/helperFunctions";
import i18n from "../../../../i18n";
import { IResponseDProject } from "../../../../Models/IResponseDProject"; 
import { ICommonResponse } from "../../../../Models/Responses/ICommonResponse";
import DProjectsServices from "../../../../Services/DProjectsService";
import { DprojectEasyAccess, EasyAccessDto, QuestionLevelColumn } from "../../../../Models/EasyAccessLever/IEasyAccessLever";
import { ActionTypes } from "../../../../Redux/actionsTypes";

interface DProjctInLeverRowDeviationRateValue {
  key: string;
  project: number;//d-project id
  projectyear: number;//year of complation
  plannedRate: number;
  runtime: number;
  delays: number;
  NewProduct?: string;
  isReadonly: boolean;
}
interface IProps {
  question: QuestionLevelColumn;
  onchangeSubquestionTable: (bmProjectId: number, questionV: QuestionLevelColumn) => any;
  dprojects: DprojectEasyAccess[];
  //addDprojectLocally: (newDprojects: DprojectEasyAccess[], _result: ICommonResponse) => any;
  saveLevers: () => any;
  bmProjectId: number;
  easyaccessLever: EasyAccessDto;
  changeEasyAccessLevers: (model: EasyAccessDto) => any;
}

function SubQuestionTableCostOfDelayEasyAccess(props: IProps) {
  const newRowCOD: DProjctInLeverRowDeviationRateValue = {
    key: "newDProjectInleverRow",
    project: null,
    projectyear: 0,
    plannedRate: 0,
    NewProduct: "", 
    delays:0,
    runtime:0,
    isReadonly: false
  };
  const { t } = useTranslation();
  const [editRecord, setEditRecord] = useState<DProjctInLeverRowDeviationRateValue>(
    newRowCOD
  );
  const [form] = Form.useForm();
  const [addFromList, setAddFromList] = useState(true);
  const projectId = Form.useWatch(`project_${editRecord?.project}`, form);
  const [addNewDprojectLoader, setAddNewDProjectLoader] = useState(false);
  useEffect(() => {
    //console.log("props.dprojects", props.dprojects);
    if (props.dprojects) {
      form.setFieldsValue(fillInForm(newRowCOD));
      setEditRecord(newRowCOD);
    }
  }, [props.dprojects]);

  const runtime = Form.useWatch(
    `runtime_${editRecord?.project}`,
    form
  );
  const delays = Form.useWatch(
    `delays_${editRecord?.project}`,
    form
  );

  const options = props.dprojects?.filter(c =>
    !(props.question.subquestions.deviationRates?.map(c => c.dprojectId).includes(c.id)))?.map((dp) => {
      return {
        value: dp.id,
        label: dp.name,
      };
    });



  const dataColCODRate: ColumnsType = [
    {
      title: (
        <p>
          {`Products`}
        </p>
      ),
      width: '30%',
      dataIndex: "product",
      //fixed: "left", // fix element
      ellipsis: true,
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        //console.log("render", text, record, index);
        if (index == 0) {
          if (editRecord?.key != "newDProjectInleverRow") {
            return (
              <Select
                disabled
                showSearch
                placeholder="Select..."
                options={options}
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  fontSize: "12px",
                }}
              />
            );
          }
          // return (
          //   <Space size={2}>
          return <Row>
            <Col span={2}>              <Checkbox
              checked={addFromList}
              onChange={(e) => {
                form.setFieldsValue(fillInForm(newRowCOD));
                setEditRecord(newRowCOD);
                setAddFromList(e.target.checked);
                console.log("form fields", form.getFieldsValue());
              }}
            /></Col>
            <Col span={1}></Col>
            <Col span={21}>
              <Form.Item
                hidden={!addFromList}
                name={`project_${record?.project}`}
                style={{ margin: 0 }}
                rules={
                  !addFromList
                    ? []
                    : [
                      {
                        required: true,
                        message: `required*`,
                      },
                    ]
                }
              >
                <Select
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={() =>
                    document.getElementById("app-layout")
                  }
                  optionFilterProp="label"
                  showSearch
                  placeholder={t("Auswahl")}
                  options={options}
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "12px",
                    width: "100%",
                    zIndex: 5,
                  }}
                />
              </Form.Item>

              <Form.Item
                hidden={addFromList}
                name={`projectname_${record?.project}`}
                style={{ margin: 0 }}
                rules={
                  addFromList
                    ? []
                    : [
                      {
                        required: true,
                        message: `required*`,
                      },
                    ]
                }
              >
                <Input placeholder={t("Neues Entwciklungsprojekt")} />
              </Form.Item>
            </Col>
            {/* </Space> */}
          </Row>
          {/* ); */ }
        } else {
          return (
            props.dprojects.find(
              (c) => c.id == record?.project
            )?.name ?? ((record?.projectyear) ? "Default Name" : "")
          );
        }
      }, // keep this if you need to render link with data cell.
    },
    {
      title: (
        <p>
          {`Year`}
        </p>
      ),
      width: '10%',
      dataIndex: "projectyear",
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        if (record?.key == "sum") return <></>;
        if (index == 0) {
          if (editRecord?.key != "newDProjectInleverRow") {
            return <InputNumber
              maxLength={4}
              disabled />;
          }
          if (addFromList) {
            return props.dprojects?.find(
              (c) => c.id == projectId
            )?.finishingYear;
          } else {
            return (
              <Form.Item
                name={`ProjektAbschlussjahr_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `required*`,
                  },
                ]}
              >
                <InputNumber
                  maxLength={4}
                  min={1970} />
              </Form.Item>
            );
          }
        } else {
          return (
            props.dprojects.find(
              (c) => c.id == record?.project
            )?.finishingYear ?? record?.projectyear
          );
        }
      },
    },
    {
      title: (
        <p>
          {props.question.subquestions.headers?.[1]?.displayname}
        </p>
      ),
      width: '20%',
      dataIndex: props.question.subquestions.headers?.[1]?.dataIndex,
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        if (index == 0 && editRecord?.key != "newDProjectInleverRow") {
          return <InputNumber disabled />;
        }
        if (index == 0 || editRecord?.key == record?.key) {
          return (
            <Form.Item
              name={`runtime_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `required*`,
                },
              ]}
            >
              {(props.question.subquestions.headers?.[1]?.unit != "") ?
                (props.question.subquestions.headers?.[1]?.unit == '%') ?
                  <InputNumber
                    min={0} max={100}
                    addonAfter={props.question.subquestions.headers?.[1]?.unit}
                    formatter={(value) => inputFormatter(value, i18n.language)}
                    parser={(x) => inputParser(x, i18n.language)}
                  /> :
                  <InputNumber
                    addonAfter={props.question.subquestions.headers?.[1]?.unit}
                    formatter={(value) => inputFormatter(value, i18n.language)}
                    parser={(x) => inputParser(x, i18n.language)}
                  /> :
                <InputNumber

                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              }
            </Form.Item>
          );
        }
        return `${formatValue(record?.runtime)}${props.question.subquestions.headers?.[1]?.unit}`;
      },
    },
    {
      title: (
        <p>
          {props.question.subquestions.headers?.[2]?.displayname}
        </p>
      ),
      width: '20%',
      dataIndex: props.question.subquestions.headers?.[2]?.dataIndex,
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        if (index == 0 && editRecord?.key != "newDProjectInleverRow") {
          return <InputNumber disabled />;
        }
        if (index == 0 || editRecord?.key == record?.key) {
          return (
            <Form.Item
              name={`delays_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `required*`,
                },
              ]}
            >
              {(props.question.subquestions.headers?.[2]?.unit != "") ?
                (props.question.subquestions.headers?.[2]?.unit == '%') ?
                  <InputNumber
                    min={0} max={100}
                    addonAfter={props.question.subquestions.headers?.[2]?.unit}
                    formatter={(value) => inputFormatter(value, i18n.language)}
                    parser={(x) => inputParser(x, i18n.language)}
                  /> :
                  <InputNumber
                    addonAfter={props.question.subquestions.headers?.[2]?.unit}
                    formatter={(value) => inputFormatter(value, i18n.language)}
                    parser={(x) => inputParser(x, i18n.language)}
                  /> :
                <InputNumber

                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              }
            </Form.Item>
          );
        }
        return `${formatValue(record?.delays)}${props.question.subquestions.headers?.[2]?.unit}`;
      },
    },
    {
      title: (
        <p>
          {props.question.subquestions.headers?.[3]?.displayname}
        </p>
      ),
      width: '15%',
      dataIndex: "plannedRate",
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        if (record?.key == "sum") return  `${formatValue(record.plannedRate)}${props.question.subquestions.headers?.[3]?.unit}`;
        if (index == 0 && editRecord?.key != "newDProjectInleverRow") {
          return <InputNumber disabled />;
        }
        if (index == 0 || editRecord?.key == record?.key) {
          return `${formatValue((delays / (runtime - delays)) * 100)}${props.question.subquestions.headers?.[3]?.unit}`;
        }
        return `${formatValue((record?.delays / (record?.runtime - record?.delays)) * 100)}${props.question.subquestions.headers?.[3]?.unit}`;
      },
    },
    {
      title: <></>,
      width: '8%',
      dataIndex: "actions",
      render: (text, record: DProjctInLeverRowDeviationRateValue, index) => {
        if (record?.key == "sum") return <></>;
        if (record?.isReadonly) return <></>;
        if (index == 0) {
          return (
            <Space size={20}>
              {" "}
              <Spin spinning={addNewDprojectLoader}>
                <Typography.Link
                  disabled={editRecord?.key != record?.key}
                  onClick={() => onAddRow(record)} //handleAdd(record?.key)}
                  style={{ marginRight: 8 }}
                >
                  <Tooltip placement="bottom" title={`${t("Add data")}`}>
                    <PlusOutlined style={{ color: "green" }} />
                  </Tooltip>
                </Typography.Link>
              </Spin>
            </Space>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Space size={10}>
                <Popconfirm
                  title={`${t("Sure to save?")}`}
                  onConfirm={() => onEditRow(record)}
                  cancelText={`${t("Cancel")}`}
                >
                  <a>
                    <Tooltip placement="bottom" title={`${t("Save data?")}`}>
                      <CheckCircleOutlined />
                    </Tooltip>
                  </a>
                </Popconfirm>

                <Typography.Link
                  disabled={
                    editRecord?.key != "newDProjectInleverRow" &&
                    editRecord?.key != record?.key
                  }
                  onClick={() => {
                    form.setFieldsValue(fillInForm(newRowCOD));
                    setEditRecord(newRowCOD);
                  }}
                >
                  <Tooltip placement="bottom" title={`${t("Cancel Changes")}`}>
                    <StopTwoTone twoToneColor="red" />
                  </Tooltip>
                </Typography.Link>
              </Space>
            );
          } else {
            return (
              <Space size={10}>
                <Typography.Link
                  disabled={
                    editRecord?.key != "newDProjectInleverRow" &&
                    editRecord?.key != record?.key
                  }
                  onClick={() => {
                    console.log("record", record);
                    form.setFieldsValue(fillInForm({ ...record }));
                    setEditRecord(record);
                  }}
                >
                  <Tooltip placement="bottom" title={`${t("Edit")}`}>
                    <EditTwoTone twoToneColor="#1890ff" />
                  </Tooltip>
                </Typography.Link>

                <Popconfirm
                  title={`${t("Sure to delete?")}`}
                  onConfirm={() => onDeleteRow(record)}
                  cancelText={`${t("Cancel")}`}
                >
                  <a>
                    <Tooltip placement="bottom" title={`${t("Delete")}`}>
                      <DeleteTwoTone twoToneColor="red" />
                    </Tooltip>
                  </a>
                </Popconfirm>
              </Space>
            );
          }
        }
      },
    },
  ];

  const dataRowCODRate = props.question.subquestions?.deviationRates?.map((row) => {
    return {
      key: `qk_row_${row.dprojectId}`,
      plannedRate: 0,//row.rate,
      project: row.dprojectId,
      projectyear: row.dprojectCompletionYear,
      delays: row.delayMonths,
      runtime: row.runtimeMonths,
      NewProduct: ""
    } as DProjctInLeverRowDeviationRateValue;
  }) ?? [];

  dataRowCODRate.push({
    key: `sum`,
    plannedRate: dataRowCODRate.reduce((a, v) => a = a + ((v.delays / (v.runtime - v.delays)) * 100), 0) / dataRowCODRate.length,
    project: 0,
    projectyear: 0,
    delays: dataRowCODRate.reduce((a, v) => a = a + v.delays, 0),
    runtime: dataRowCODRate.reduce((a, v) => a = a + v.runtime, 0),
    NewProduct: "",
  } as DProjctInLeverRowDeviationRateValue);

  function fillInForm(row: any) {
    let rowNames: any = {};
    rowNames[`project_${row.project}`] = row.project;
    rowNames[`projectname_${row.project}`] = row.NewProduct ?? "";
    rowNames[`ProjektAbschlussjahr_${row.project}`] = row.projectyear;
 
    rowNames[`runtime_${row.project}`] = row.runtime;
    rowNames[`delays_${row.project}`] = row.delays;
    return rowNames;
  }
  function getRowFromForm(obj: any) {
    let row = {
      key: "",
      project: 0,
      projectyear: 0,
      delays: 0,
      runtime: 0,
      plannedRate: 0,
      NewProduct: "",
    };

    Object.keys(obj).forEach((key) => {
      console.log(key, obj[key]);
      if (key.includes("projectname_")) row.NewProduct = obj[key] ?? "";
      if (key.includes("ProjektAbschlussjahr_")) row.projectyear = obj[key] ?? "";
      if (key.includes("project_")) row.project = obj[key];
      if (key.includes("delays_")) row.delays = obj[key];
      if (key.includes("runtime_")) row.runtime = obj[key];
      if (key.includes("plannedRate_")) row.plannedRate = obj[key];
    });
    return row;
  }

  async function onAddRow(row: DProjctInLeverRowDeviationRateValue) {
    try {
      console.log("obj", form.getFieldsValue());
      const row2 = await form.validateFields();
      let newData = getRowFromForm(row2);
      let question = props.question;
      if (newData.NewProduct == "") {

        if (question.subquestions.columnsNumber == 4) {
          question.subquestions.deviationRates.push({
            dprojectName: "",
            dprojectId: newData.project,
            delayMonths: newData.delays,
            runtimeMonths: newData.runtime,
            rate: newData.plannedRate,
            dprojectCompletionYear: 0,
            isReadonly: false
          });
        }
      } else {
        setAddNewDProjectLoader(true);
        let newDprojects: DprojectEasyAccess[] = [];
        let _result: ICommonResponse = await DProjectsServices.UploadDproject({
          generalDprojectInfo: {
            name: newData.NewProduct,
            finishingYear: newData.projectyear,
            actualProjectCosts: 0,
            comments: [],
            id: 0,
            key: 0,
            projectId: props.bmProjectId,
            runTime: 0
          },
          costOfDelay: null,
          innovation: null,
          manufacturingCost: null,
          qualityCost: null,
          questionaire: null
        });
        if (!_result.isError) {
          let _result2: ICommonResponse = await DProjectsServices.GetDProjects(props.bmProjectId);
          if (!_result2.isError) {
            let result2 = _result2.result as IResponseDProject[];
            newDprojects = result2.map(newDproject => {
              let dproject: DprojectEasyAccess = {
                id: newDproject.generalDprojectInfo.id,
                name: newDproject.generalDprojectInfo.name,
                finishingYear: newDproject.generalDprojectInfo.finishingYear
              }
              return dproject;
            });


            let newDp = newDprojects.filter(c => c.name == newData.NewProduct && c.finishingYear == newData.projectyear)?.[0];
            console.log("newDp", newDp);
            let _dprojectId = newDp?.id ?? props.dprojects.length + 1;
            if (question.subquestions.columnsNumber == 4) {
              question.subquestions.deviationRates.push({
                dprojectName: newData.NewProduct,
                dprojectId: _dprojectId,
                dprojectCompletionYear: newData.projectyear,
                delayMonths: newData.delays,
                rate: newData.plannedRate,
                runtimeMonths: newData.runtime,
                isReadonly: false
              });
            }
          }
        } else {
        }

        var tab = { ...props.easyaccessLever };
        var all = tab.currentTab.easyAccessLeverBmProjects;
        all.filter(x => x.id == props.bmProjectId)?.map(bmProject => {
          bmProject.dprojects = newDprojects;
        });
        tab.currentTab.easyAccessLeverBmProjects = all;

        props.changeEasyAccessLevers(tab);

        setAddNewDProjectLoader(false);
      }

      if (question.subquestions.columnsNumber == 4) {
        question.kpiValue.value = (props.question.subquestions.headers?.[3]?.unit == "%") ?
          question.subquestions.deviationRates?.reduce((a, v) => a = a + (v.delayMonths / (v.runtimeMonths - v.delayMonths)), 0) / question.subquestions.deviationRates?.length :
          question.subquestions.deviationRates?.reduce((a, v) => a = a + ((v.delayMonths / (v.runtimeMonths - v.delayMonths)) * 100), 0) / question.subquestions.deviationRates?.length;
        if (question.kpiValue.unit == "%") {
          question.kpiValue.value = question.kpiValue.value * 100;
        }
      }
      form.setFieldsValue(fillInForm(newRowCOD));
      setEditRecord(newRowCOD);
      await props.onchangeSubquestionTable(props.bmProjectId, question);

      //props.saveLevers();

    } catch (e) {
      console.log(e);
    }
  }

  async function onEditRow(row: DProjctInLeverRowDeviationRateValue) {
    try {
      //console.log("obj", form.getFieldsValue());
      const row2 = await form.validateFields();
      //console.log("obj row2", row2);
      let newData = getRowFromForm(row2);
      //console.log(newData);
      let question = props.question;
  
      if (question.subquestions.columnsNumber == 4) {
        question.subquestions.deviationRates?.filter(c => c.dprojectId == row.project)?.map(c => {
          c.delayMonths = newData.delays;
          c.runtimeMonths = newData.runtime;

        });

        question.kpiValue.value = (props.question.subquestions.headers?.[3]?.unit == "%") ?
          question.subquestions.deviationRates?.reduce((a, v) => a = a + (v.delayMonths / (v.runtimeMonths - v.delayMonths)), 0) / question.subquestions?.deviationRates?.length :
          question.subquestions.deviationRates?.reduce((a, v) => a = a + ((v.delayMonths / (v.runtimeMonths - v.delayMonths)) * 100), 0) / question.subquestions?.deviationRates?.length;
        if (question.kpiValue.unit == "%") {
          question.kpiValue.value = question.kpiValue.value * 100;
        }
      }

      console.log('question', question);
      form.setFieldsValue(fillInForm(newRowCOD));
      setEditRecord(newRowCOD);
      props.onchangeSubquestionTable(props.bmProjectId, question);
    } catch (e) {
      console.log("onEditRow ", e);
    }
  }
  function onDeleteRow(row: DProjctInLeverRowDeviationRateValue) {
    console.log(row);
    //props.onDeleteCOD(row.project, props.currentProject.id);
    let question = props.question;
 
    if (question.subquestions.columnsNumber == 4) {
      question.subquestions.deviationRates = question.subquestions.deviationRates?.filter(c => c.dprojectId != row.project);

      question.kpiValue.value = (props.question.subquestions.headers?.[3]?.unit == "%") ?
        question.subquestions.deviationRates?.reduce((a, v) => a = a + (v.delayMonths / (v.runtimeMonths - v.delayMonths)), 0) / question.subquestions.deviationRates?.length :
        question.subquestions.deviationRates?.reduce((a, v) => a = a + ((v.delayMonths / (v.runtimeMonths - v.delayMonths)) * 100), 0) / question.subquestions.deviationRates?.length;
      if (question.kpiValue.unit == "%") {
        question.kpiValue.value = question.kpiValue.value * 100;
      }
    }
    props.onchangeSubquestionTable(props.bmProjectId, question);
  }

  return (
    <div className="editable-table-dproject-lever" id="editable-table-dproject-lever">
      <Form form={form} component={false} key="cod_form">
        <Table
          key={"cod_table"}
          bordered={false}
          dataSource={ [newRowCOD, ...dataRowCODRate]}
          columns={dataColCODRate}
          scroll={{ y: 500 }}
          rowClassName={() => "editable-row"}
          pagination={false}
          className={"subquestion-table"}
        />
      </Form>
    </div>
  );
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    easyaccessLever: state.leverTool?.easyaccessLever,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    changeEasyAccessLevers: (model: EasyAccessDto) => dispatch({ type: ActionTypes.ChangeEasyAccessLevers, model: model }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubQuestionTableCostOfDelayEasyAccess);
