import { SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Menu, Modal, Popover, Space } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { IListItem } from '../../../../Models/Responses/ProfileResponse';
import { KpiLevelColumn } from '../../../../Models/EasyAccessLever/IEasyAccessLever';

interface IProps {
  originalBranches: IListItem[];
  originalKpis: KpiLevelColumn[];
  onApplyFilter: (branches: number[], kpis: number[]) => any;
}
function Configuration(props: IProps) {
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [hiddenKpi, setHiddenKpi] = useState([]);

  useEffect(() => {

    setHiddenKpi(props.originalKpis?.filter(c => c.isHidden)?.map(c => { return c.id }));
    if (localStorage.getItem("easy_access_branches")) {
      let oldBranches = JSON.parse(localStorage.getItem("easy_access_branches"));
      setSelectedBranches(oldBranches);
    } else {
      setSelectedBranches(props.originalBranches?.map(c => { return c.value }));
    }
  }, [props.originalBranches, props.originalKpis])

  const columnsFilter = (col: KpiLevelColumn) => {
    //const start = new Date().getTime();
    var oldHidden = [...hiddenKpi];
    if (oldHidden.includes(col.id)) {
      oldHidden = oldHidden.filter(c => c != col.id);
    } else {
      oldHidden.push(col.id)
    }
    setHiddenKpi(oldHidden);

    localStorage.setItem("easy_access_hiddenkpis", JSON.stringify(oldHidden));
  }

  const branchFilter = (branchId: number) => {
    var oldSelected = [...selectedBranches];
    if (oldSelected.includes(branchId)) {
      oldSelected = oldSelected.filter(c => c != branchId);
    } else {
      oldSelected.push(branchId);
    }
    setSelectedBranches(oldSelected);

    localStorage.setItem("easy_access_branches", JSON.stringify(oldSelected));
  }
  const menuKpi = () => {
    return <Menu getPopupContainer={() => document.getElementById('app-layout')} theme='light'>

      {props.originalKpis?.map(col => {
        return (
          <Menu.Item key={`menu_item_${col.id}`} >
            <Checkbox
              key={`menu_item_ck_${col.id}`}
              checked={!hiddenKpi?.includes(col.id)}
              onChange={() => columnsFilter(col)}
            >
              {`${col.number} ${col.name}`}
            </Checkbox>
          </Menu.Item>
        );
      })}
    </Menu>

  }

  const menuBranch = () => {
    let shownItems = props.originalBranches;

    return <Menu getPopupContainer={() => document.getElementById('app-layout')} theme='light'>
      {shownItems?.map(col => {
        return (
          <Menu.Item key={`menu_item_branch_${col.value}`} >
            <Checkbox
              key={`menu_item_ck_branch_${col.value}`}
              checked={selectedBranches?.includes(col.value)}
              onChange={() => branchFilter(col.value)}
            >
              {col.label}
            </Checkbox>
          </Menu.Item>
        );
      })}
    </Menu>

  }


  return (
    <Space size={16} align="center">
      <Popover getPopupContainer={() => document.getElementById('app-layout')} overlayClassName='columns-filter-easyaccess-popover' className='columns-filter' content={menuKpi()} trigger="click" placement="bottom">
        <Button type="default">
          {`${t("KPI options")} (${props.originalKpis?.filter(c => !hiddenKpi?.includes(c.id)).length} ${t("shown")})`}
        </Button>
      </Popover>
      <Popover getPopupContainer={() => document.getElementById('app-layout')} overlayClassName='columns-filter-easyaccess-popover' className='columns-filter' content={menuBranch()} trigger="click" placement="bottom">
        <Button type="default">
          {`${t("Industy options")} (${props.originalBranches?.filter(c => selectedBranches?.includes(c.value)).length} ${t("shown")} )`}
        </Button>
      </Popover>
      <Button type="default" icon={<SettingOutlined />} onClick={() => {
        props.onApplyFilter(selectedBranches, hiddenKpi)
      }}>
        {`${t("Apply Filter")}`}
      </Button>
    </Space>
  );
};

export default Configuration;