import { Button, Form, Input, List, Modal, Comment, Tabs, Badge, Tag, Space, Avatar, Row, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IVote } from '../../../../Models/InterfaceAnalysis/IAItems';
import { IGeneralState, InterfaceAnalysisState } from '../../../../Redux/reducers/IGeneralState';
import { formatValue } from '../../../../Utils/helperFunctions';
import { t } from 'i18next';

interface IProps {
    value: string;
    fromDiscId: number;
    toDiscId: number;
    result: InterfaceAnalysisState;
}
function VotesModal(props: IProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const details = () => {
        return <List
            itemLayout="vertical"
            size="small"
            dataSource={props.result?.viewResult?.votes.filter(c => c.fromDisciplineId == props.fromDiscId && c.toDisciplineId == props.toDiscId)}
            renderItem={item => (
                <List.Item
                    key={`${item.toDisciplineId}_${item.fromDisciplineId}`}
                    //actions={(item.noPreviousInteraction) ? [<Badge status="warning" text={`${t("No Previous Interaction")}`} />] : []}
                >
                    <Comment
                        author={<Tag color="default">{`${item.fromDisciplineName} => ${item.toDisciplineName}`}</Tag>}
                        datetime={(item.noPreviousInteraction) ? [<Badge status="warning" text={`${t("No Previous Interaction")}`} />] : []}

                        content={<>
                            <Row>
                                <Col>
                                    {<><b>{`${t("Participant Comment:")} `}</b>{(item.comment == null || item.comment == "") ? `${t("No Comment")}` : item.comment}</>}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7} style={{ padding: "5px" }}>
                                    <Input addonBefore={<>{`Q`}</>} value={item.qRate} />
                                </Col>
                                <Col span={7} style={{ padding: "5px" }}>
                                    <Input addonBefore={<>{`R`}</>} value={item.rRate} />
                                </Col>
                                <Col span={7} style={{ padding: "5px" }}>
                                    <Input addonBefore={<>{`K`}</>} value={item.kRate} />
                                </Col>
                            </Row>
                        </>}
                    />
                        {(item.review != null)?
                        <Comment
                        author={`${t("Consultant")}: ${item.review?.consultantName}`}
                        avatar={<Avatar src={item.review?.consultantImage} alt="IMG" />}
                        datetime={
                            <Space align="center" direction="horizontal" style={{ width: "fit-content" }}>
                                {(item.review?.isApproved == false) ? <Tag color="magenta">{`${t("Excluded")}`}</Tag> : <Tag color="green">{`${t("Approved")}`}</Tag>}</Space>
                        }
                        content={<>
                            <Row>
                                <Col>
                                    {<><b>{`${t("Consultant")}: `}</b>{(item.review?.comment == null || item.review?.comment == "") ? `${t("No Comment")}` : item.review?.comment}                       </>}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7} style={{ padding: "5px" }}>
                                    <Input addonBefore={<>{`Q`}</>} value={item.review?.qRate} />
                                </Col>
                                <Col span={7} style={{ padding: "5px" }}>
                                    <Input addonBefore={<>{`R`}</>} value={item.review?.rRate} />
                                </Col>
                                <Col span={7} style={{ padding: "5px" }}>
                                    <Input addonBefore={<>{`K`}</>} value={item.review?.kRate} />
                                </Col>
                            </Row>
                        </>}
                    />
                    : 
                    "No review yet"
                        }
                    
                </List.Item>
            )} />;
    }

    return (
        <>
            <Button type="ghost" className="btn-vote-avg" onClick={showModal} title={`${t("Click for more Details")}`}>
                {formatValue(Number.parseFloat(props.value))}
            </Button>
            <Modal title={`${t("History")}`} open={isModalOpen} closable={true} onCancel={handleCancel} footer={[]}>
                {details()}
            </Modal>
        </>
    );
};
const mapStateToProps = (state: IGeneralState) => {
    return {
        result: state.interfaceAnalysis
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VotesModal); 