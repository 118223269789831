import { ActionTypes } from "../actionsTypes";
import { IActivityProfileTasksState } from "./IGeneralState";

const initialState: IActivityProfileTasksState = {
    isActProTasksLoading: false,
    profileTasksResult: null,
}
const ActivityProfileTasksReducer = (state = initialState, action: any): IActivityProfileTasksState => {
    switch (action.type) {
        case ActionTypes.CHANGE_PROFILE_TASKS_LOADER:
            return {
                ...state,
                isActProTasksLoading: action.isLoading,
            };
        case ActionTypes.SAVE_PROFILE_TASKS:
            return {
                ...state,
                profileTasksResult: action.result
            };
        default:
            break;
    }
    return state;
}
export default ActivityProfileTasksReducer;