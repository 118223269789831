import { createStore, combineReducers } from "redux";
// saga middleware
import createSagaMiddleware from "redux-saga";
import { applyMiddleware } from "redux";

//reducers //here we import all reducers which contains the properties of state slices
import benchMarkingReducer from "./reducers/benchMarkingReducer";

//import rootsagas from index
import rootSaga from "./sagas/index"; // this represent areducer but for actions which require excuting async code.
import rotatingReducer from "./reducers/rotatingReducer";
import ProjectsReducer from "./reducers/projectsReducer";
import TablesColsPropsReducer from "./reducers/tablesColsPropsReducer";
//import FiltersReducer from './reducers/filtersReducer';
import EBITReducer from "./reducers/EBITReducer";
import ActivityReducer from "./reducers/ActivityReducer";
import ActivityProfileVersionsReducer from "./reducers/ActivityProfileVersionsReducer";
import generalInfoReducer from "./reducers/generalInfoReducer";
import authReducer from "./reducers/authReducer";
import leversToolReducer from "./reducers/leversToolReducer";
import DProjectsReducer from "./reducers/DProjectsReducer";
import CustomerReducer from "./reducers/CustomerReducer";
import QuestionReducer from "./reducers/QuestionReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import BmProjectReducer from "./reducers/bmProjectReducer";
import IndustryReducer from "./reducers/industryReducer";
import IndustrySegmentsReducer from "./reducers/indusrtySegmentReducer";
import CustomerUserReducer from './reducers/customerUserReducer';
import BmProjectListReducer from './reducers/bmProjectListReducer';
import AdministratorsReducer from './reducers/administratorReducer';
import ConsultantUsersReducer from './reducers/ConsultantUsersReducer';
import LeversManagementForAdminReducer from './reducers/leversManagementForAdminReducer';
import DisciplinesReducer from './reducers/disciplineReducer';
import IndustryBestPracticeReducer from './reducers/industryBestPracticeReducer';
import IndustrySegmentBestPracticeReducer from './reducers/industrySegmentBestPracticeReducer';
import IndustryDisciplineBestPracticeReducer from './reducers/IndustryDisciplineBestPracticeReducer';
import SegmentDisciplineBestPracticeReducer from './reducers/SegmentDisciplineBPReducer';
import FrontLoadingTempReducer from './reducers/FrontLoadingReducer';
import FrontLoadingToolReducer from './reducers/FrontLoadingToolReducer';
import HelpPgeForAdminReducer from './reducers/HelpPageForAdminReducer';
import InterfaceAnalysisReducer from './reducers/InterfaceAnalysisReducer';
import FrontLoadingCurvesReducer from "./reducers/FrontLoadingCurvesReducer";
import UsersManagementReducer from "./reducers/UserManagementReducer";
import ProfileMngReducer from "./reducers/ProfileMngReducer";
import DepartmentsByBmProjectReducer from "./reducers/DepartmentsByBmProjectReducer";
import DepartmentsByCustomerReducer from "./reducers/DepartmentsByCustomerReducer";
import SelfAssessmentBpTempReducer from "./reducers/SelfAssessmentBpTempReducer";
import SelfAssessmentToolReducer from "./reducers/SelfAssessmentToolReducer";
import CompletionBmProjectDetailsReducer from "./reducers/CompletionBmProjectDetailsReducer";
import ErrorPgeForAdminReducer from "./reducers/ErrorPageForAdminReducer";
import ActivityManagementForAdminReducer from "./reducers/ActivityManagementForAdminReducer";
import PermissionsReducer from "./reducers/PermissionsReducer";
import { EventLogsForAdminReducer, EventLogsFiltersForAdminReducer } from "./reducers/EventLogsForAdminReducer";
import SelfAssessmentHistoryReducer from "./reducers/SelfAssementHistoryReducer";
import ActivityProfileTasksReducer from "./reducers/ActivityProfileTasksReducer";
import FrontLoadingDepartmentReducer from "./reducers/FrontLoadingDepartmentReducer";

const rootReducer = combineReducers({
  // these are state slices which can be consumed separately in any desired component
  //state slices
  benchMarking: benchMarkingReducer,
  rotater: rotatingReducer,
  projects: ProjectsReducer,
  tablesColsProps: TablesColsPropsReducer,
  //filters: FiltersReducer,
  ebit: EBITReducer,
  activity: ActivityReducer,
  leverTool: leversToolReducer,
  generalInfo: generalInfoReducer,
  authInfo: authReducer,
  dprojects: DProjectsReducer,
  customerReducer: CustomerReducer,
  questionReducer: QuestionReducer,
  bmProjects: BmProjectReducer,
  industries: IndustryReducer,
  industrySegments: IndustrySegmentsReducer,
  activityProfileVersions: ActivityProfileVersionsReducer,
  departments: DepartmentsByBmProjectReducer,
  customerUser: CustomerUserReducer,
  bmProjectList: BmProjectListReducer,
  administrators: AdministratorsReducer,
  consultantUsers: ConsultantUsersReducer,
  leversForAdmin: LeversManagementForAdminReducer,
  activiyProfileMng: ActivityManagementForAdminReducer,
  disciplines: DisciplinesReducer,
  industryBestPractice: IndustryBestPracticeReducer,
  industrySegmentBestPractice: IndustrySegmentBestPracticeReducer,
  industryDisciplineBestPractice: IndustryDisciplineBestPracticeReducer,
  segmentDisciplineBP: SegmentDisciplineBestPracticeReducer,

  frontLoadingProjects: FrontLoadingToolReducer,
  frontLoadingTemp: FrontLoadingTempReducer,
  helpPagesForAdmin: HelpPgeForAdminReducer,

  interfaceAnalysis: InterfaceAnalysisReducer,
  curves: FrontLoadingCurvesReducer,
  usersMng: UsersManagementReducer,
  profileData: ProfileMngReducer,
  departmentsByCustomer: DepartmentsByCustomerReducer,
  selfAssessments: SelfAssessmentBpTempReducer,
  selfAssessementTool: SelfAssessmentToolReducer,
  detailsCompletion: CompletionBmProjectDetailsReducer,
  errorsForAdmin: ErrorPgeForAdminReducer,
  permissions: PermissionsReducer,
  eventLogs: EventLogsForAdminReducer,
  eventLogsFilter: EventLogsFiltersForAdminReducer,
  HistoryOfImportance: SelfAssessmentHistoryReducer,
  activityProfileTasks: ActivityProfileTasksReducer,
  frontLoadingDepartment: FrontLoadingDepartmentReducer
});

const sagaMiddleware = createSagaMiddleware();
let middleware = [sagaMiddleware];

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)),); // creating the store with rootReducer which assemple all individual reducers in reducers folder
// and with saga middleware which is a middleware separating between the rootReducer and the subscribers components which dispatch async actions

sagaMiddleware.run(rootSaga); // start the middleware

export default store;
