import React, { useState } from 'react'
import { IFrontLoadingTempPhase } from '../../../../Models/IFrontLoading';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { Button, Form, Input, Space, Spin } from 'antd';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import FrontLoadingTempPhaseTable from './FrontLoadingTempPhaseTable';
import { RulesName } from '../../Rules/RulesValidation';
import { t } from 'i18next';

interface IProps {
    createFrontLoadingTempPhase: (model: IFrontLoadingTempPhase) => any;
    phasesData: IFrontLoadingTempPhase[]
    templateId: number;
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
}
const FrontLoadingTempPhase = (props: IProps) => {
    const [formValues, setFormValues] = useState<IFrontLoadingTempPhase>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const phaseDataRow: IFrontLoadingTempPhase[] = props.phasesData?.map(f => {
        return { key: `Temp_phase_${f.id}`, ...f }
    }) ?? [];
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const newFrontLoadTempPhase = {
                templateId: props.templateId,
                ...formValues
            };

            await props.createFrontLoadingTempPhase(newFrontLoadTempPhase);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("FrontLoading")} ${t("Templates")} Phase`} btnText={`${t("Add")}`}
                        width="520px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>

                        <Form
                            layout="vertical"
                            form={form}

                        >
                            <Form.Item label={`${t("English Name")}`} name='nameEn'
                                rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
                                hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, nameEn: (e.target.value).trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("German Name")}`} name='nameDe'
                                rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
                                hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, nameDe: (e.target.value).trimStart() })} />
                            </Form.Item>
                        </Form>
                    </ModalAdmin>
                    <FrontLoadingTempPhaseTable phasesDataRow={phaseDataRow} templateId={props.templateId} />
                </Space>
            </div>
        </Spin>
    );
}
const mapStateToProps = (state: any) => {
    return {
        AllFrontLoadingTemp: state.frontLoadingTemp?.frontLoadingLoadingResult,
        isLoading: state.frontLoadingTemp?.isFrontLoadingLoading,
        isModalLoading: state.frontLoadingTemp?.isModalLoading,
        modalResponse: state.frontLoadingTemp?.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        createFrontLoadingTempPhase: (model: IFrontLoadingTempPhase) => dispatch({ type: ActionTypes.CREATE_FRONT_LOADING_TEMP_PHASE, model })

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontLoadingTempPhase);
