import React, { useEffect, useState } from 'react';
import { Avatar, Col, Layout, Menu, MenuProps } from 'antd';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import userAvatar from "./../../assets/Images/user_image.png";
import { LogoutOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { ProfileResponse } from '../../Models/Responses/ProfileResponse';
import Utils from '../../Utils/Utils';
import AppAvatar from '../../Components/AppAvatar';
import { appAdminRouters, appAdminRoutersLinks } from '../../Router/router.config';
import { IGeneralState } from '../../Redux/reducers/IGeneralState';
import { ActionTypes } from '../../Redux/actionsTypes';

const { Sider } = Layout;
export interface ISiderMenuProps {
  profileResponse: ProfileResponse;
  collapsed: boolean;
  onCollapse: any;
  history: any;
}

const SiderMenu = (props: ISiderMenuProps) => {
  const { collapsed, history, onCollapse } = props;
  const location = useLocation();
  // const [currentRoute, setCurrentRoute] = useState<any>();
  // //const navigate = useNavigate();
  // //const currentRoute = Utils.getRoute(history.location.pathname);
  
  // useEffect(() => {
  //   setCurrentRoute(Utils.getRoute(history.location.pathname));
  // }, [history.location.pathname]);

  // const onClick: MenuProps['onClick'] = e => {
  //   //console.log('click ', e);
  //   history.push(e.key);
  //   navigate(e.key);
  // };

  // const userDropdownMenu = (
  //   <Menu theme='dark'>
  //     <Menu.Item key="2">
  //       <Link to="/logout">
  //         <LogoutOutlined />
  //         {/* <span> {L('Logout')}</span> */}
  //         <span> {'Logout'}</span>
  //       </Link>
  //     </Menu.Item>
  //   </Menu>
  // );
  return (
    <Sider trigger={null}
      className={"sidebar"}
      width={210}
      collapsed={collapsed}
      onCollapse={onCollapse}>
      {collapsed ? (
        <Col style={{ textAlign: 'center', marginBottom: 10 }}>
          <AppAvatar size={64}
            showEmpName={false}
            showPosition={false}
            avatarImage={userAvatar}
          />
        </Col>
      ) : (
        <Col style={{ textAlign: "center" }}>
          {(props.profileResponse) ?
            <AppAvatar
              size={(collapsed) ? 40 : 64}
              key="appAvatarUser"
              avatarImage={props.profileResponse?.image === '' ? userAvatar : props.profileResponse?.image}
              empName={`${props.profileResponse?.firstName} ${props.profileResponse?.lastName} `}
              jobPosition={props.profileResponse?.rolesNames?.join("/")}
              showEmpName={!collapsed}
              showPosition={!collapsed}
            />
            : null}
        </Col>
      )}

      <div>
        <Menu
          defaultSelectedKeys={[location.pathname]}//{[currentRoute ? currentRoute.path : '']}
          mode="inline"
          theme="light"
          className='slider-menu-links'
          //onClick={onClick} 
          items={appAdminRoutersLinks}
        />
      </div>
    </Sider>
  );
};

const mapStateToProps = (state: IGeneralState) => {
  return {
    profileResponse: state.generalInfo.profileResponse,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getEbitEvaluation: (projectID: number) =>
      dispatch({ type: ActionTypes.GetEbitEvaluation, projectID: projectID }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiderMenu);

