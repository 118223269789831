import { Button, Input, Space } from "antd";
import { ClearOutlined, CloseOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { t } from "i18next";

export const getColumnSearchProps = (placeholder: string, dataIndex: string, searchInput: any, handleSearch: (confirm: (param?: FilterConfirmProps) => void) => any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()} >
            <Input
                ref={searchInput}
                placeholder={`${t("Search")} ${placeholder} ...`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(confirm)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ minWidth:"maxContent"}}
                >
                    {`${t("Search")}`}
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                    }}
                    icon={<ClearOutlined />}
                    size="small"
                    style={{ minWidth:"maxContent"}}
                >
                    {`${t("Clear")}`}
                </Button>
                <Button
                    onClick={async () => {
                        await setSelectedKeys([]);
                        await clearFilters();
                        await handleSearch(confirm);
                        await close();
                    }}
                    icon={<ReloadOutlined />}
                    size="small"
                    style={{ minWidth:"maxContent"}}
                >
                    {`${t("Reset")}`}
                </Button>
                <Button
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => {
                        close();
                    }}
                >
                    {`${t("Close")}`}
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
        record?.[dataIndex]
            ?.toString()
            ?.toLowerCase()
            ?.includes((value as string)?.toLowerCase()) ?? false,
    onFilterDropdownOpenChange: (visible: any) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    },
    getPopupContainer: () => document.getElementById("app-layout")
});