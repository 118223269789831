import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import BmProjectListByCustomerIdService from "../../Services/BmProjectListByCustomerIdService";
import { IBmProjectList } from "../../Models/IBmProjectList";

function* OnGetBmProjectList(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_BMPROJECT_LIST_LOADER, isLoading: true });
    let _result: ICommonResponse = yield BmProjectListByCustomerIdService.GetBmProjectList(action.customerId);
    if (!_result.isError) {
      let result = _result.result as IBmProjectList[];
      yield put({ type: ActionTypes.SAVE_BMPROJECT_LIST, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.CHANGE_BMPROJECT_LIST_LOADER, isLoading: false });
  } catch (error) {
    console.error("ERR in onGetBmProjectList: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* watchGetBmProjectList() {
  yield takeEvery(ActionTypes.GET_BMPROJECT_LIST_BY_ID,OnGetBmProjectList);
}

export default function* BmProjectListByCustomerIdSagas() {
  yield all([
    watchGetBmProjectList(),
  ]);
}
