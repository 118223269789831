import { Button, Spin } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { BasicRole } from "../../../../Enums/BasicRoles";
import i18n from "../../../../i18n";
import { ISubLeverOnEbit } from "../../../../Models/Levers/Items";
//import { ProfileResponse } from "../../../../Models/Responses/ProfileResponse";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
// import { DownloadOutlined } from "@ant-design/icons";
// import html2canvas from "html2canvas";
// import saveAs from "file-saver";
import { t } from "i18next";

interface Iprops {
    isSubLever: boolean;
    isMinimized?: boolean;
    width?: number;
    height?: number; 
    leversWithEbit: ISubLeverOnEbit[];
    showTooltip?: boolean; 
}


const StackedRowsChart = (props: Iprops) => {
    
    const data = props.leversWithEbit;

    return (
        <div
            className={props.isMinimized ? "" : "barChart_container_rowstack"}
            style={{ margin: props.isMinimized ? "0px" : "5px 10px 5px 10px" }}
            id={props.isMinimized ? "chart_container_stacked_row_small" : "chart_container_stacked_row_big"}
        >
            <div >
                <div className="chartTitle_container"  >
                    <span className="chartTitle" >
                        <strong>{(props.isSubLever) ? `${i18n.t("EBIT Wirksamkeit der Teilstellhebel")}` : `${i18n.t("EBIT Wirksamkeit der Teilstellhebel mit KPI betrachtet")}`}</strong>
                    </span>
                </div>
                <div

                    className={props.isMinimized ? "" : "chart_container"}
                    style={
                        props.isMinimized
                            ? { height: props.height } : { height: 700 }
                    }
                >
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            layout="vertical"
                            data={data}                            
                            // width={500}
                            // height={200 + 10}
                        >
                            {props.showTooltip ? <Tooltip /> : <></>}
                            {props.showTooltip ? <CartesianGrid strokeDasharray="3 3" /> : <CartesianGrid strokeDasharray="3 3" />}
                            <XAxis hide type="number" />
                            {props.isMinimized ? <YAxis hide dataKey="name" type="category"
                                fontSize={"6px"} tickMargin={5} tickSize={5} />
                                : <YAxis width={200} dataKey="name" type="category"
                                    tickMargin={5} tickSize={5}
                                    fontSize={props.isMinimized ? "8px" : "12px"} />}

                            <Bar isAnimationActive={false} dataKey="fe" stackId="a" fill="#0070C0" />
                            <Bar isAnimationActive={false} dataKey="inno" stackId="a" fill="#00B050" />
                            <Bar isAnimationActive={false} dataKey="cod" stackId="a" fill="#92D050" />
                            <Bar isAnimationActive={false} dataKey="mc" stackId="a" fill="#002060" />
                            <Bar isAnimationActive={false} dataKey="qc" stackId="a" fill="#C00000" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>

    );
};
export default StackedRowsChart;