import { Col, Menu, Row, Typography, Input, Drawer, Space, Tooltip, Button, Spin, Empty, Avatar } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CompressOutlined, ExpandOutlined, QuestionCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { useState } from "react";
import { connect } from "react-redux";
import { SettingOutlined } from "@ant-design/icons/lib/icons";
import { BmProject, ProfileResponse } from "../Models/Responses/ProfileResponse";
import Utils from "../Utils/Utils";
import { BasicRole } from "../Enums/BasicRoles";
import { ExportLanguageSwitcher, ThemeSwitcher } from "../Components/Switcher";
import { IGeneralState, IHelpPageState } from "../Redux/reducers/IGeneralState";
import { ActionTypes } from "../Redux/actionsTypes";
import parse from 'html-react-parser';
import { t } from "i18next";
import cutomerImg from "../assets/Images/customer_default.png"
import LanguageSwitcher from "../Components/Switcher/LanguageSwitcher";
export interface IHeaderProps {
  history: any;
  currentProject: BmProject;
  profileResponse: ProfileResponse;
  changeFullScreen: any;
  isfullScreen: boolean;
  onClickFullScreen: any;
  onClickExitFullScreen: any;
  helpPageState: IHelpPageState;
  getCurrentHelpPage: (pageName: string) => any;
}
const { Title, Text } = Typography;
const Header = (props: IHeaderProps) => {
  const location = useLocation();
  const { Search } = Input;
  //const onSearch = (value: string) => console.log(value);
  const currentRoute = Utils.getPageHeaderTitle(props.history.location.pathname);
  let navigate = useNavigate();
  const logout = () => {
    console.log('logout');
    //TODO: clear
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login')
  }
  const [visible, setVisible] = useState(false);

  // const showDrawer = () => {
  //   setVisible(true);
  //   //console.log("showDrawer ", location.pathname);
  //   props.getCurrentHelpPage(props.helpPageState?.currentPageCode);
  // };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <Row className={'header-container'}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
        <Space align="center" size={5}  >
          {(props.currentProject == null) ?
            <Title level={2}>{`No Project Selected`}</Title>
            :
            <><Avatar src={props.currentProject?.customerImage ? props.currentProject?.customerImage : cutomerImg} />
              <Title level={3}>{props.currentProject.project_name} {"-"} <Text style={{ "color": "rgb(8 111 185)" }}>
                {props.currentProject.yearUnderReview}
              </Text>
              </Title></>
          }

        </Space>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} >
        <Space align="center" size={5} style={{ flexFlow: 'row-reverse', float:"right"}}>
          <Tooltip placement="bottom" title={`${t("Logout")}`}>
            <Button icon={<LogoutOutlined />} onClick={() => { logout(); }} type="ghost"></Button>
          </Tooltip>
          {(props.profileResponse?.roles?.includes(BasicRole.Admin) || props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)) ?
            <Tooltip placement="bottom" title={`${t("Administration")}`}>
              <Link to={"/admin"} >
                <Button icon={<SettingOutlined />} type="ghost" />
              </Link>
            </Tooltip> : null
          }
          {/* <Tooltip placement="bottom" title={`${t("General Help")}`}>
            <Button icon={<QuestionCircleOutlined />} onClick={showDrawer} type="ghost"></Button>
          </Tooltip> */}
          {props.isfullScreen ? <Tooltip placement="bottom" title="Exit Full Screen">
            <Button icon={<CompressOutlined />} onClick={() => {
              props.onClickExitFullScreen();
              props.changeFullScreen(false)
            }} type="ghost"></Button>
          </Tooltip> :
            <Tooltip placement="bottom" title={`${t("Full Screen")}`}>
              <Button icon={<ExpandOutlined />} onClick={() => {
                props.onClickFullScreen();
                props.changeFullScreen(true)
              }} type="ghost"></Button>
            </Tooltip>
          }
          <ThemeSwitcher />

          <LanguageSwitcher />
          {(!props.profileResponse?.roles?.includes(BasicRole.Customer)) ?
            <ExportLanguageSwitcher /> : null
          }

        </Space>
      </Col>

      {/* <Drawer getContainer={() => document.getElementById('app-layout')} key={`drawer_header`} title="Quick Help" placement="right" onClose={onClose} open={visible}>
        <Spin spinning={props.helpPageState?.isCurrentHelpPageLoading}>
          {(props.helpPageState?.currentHelpPage) ?
            parse(props.helpPageState?.currentHelpPage) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Content"} />}
        </Spin>
      </Drawer> */}
    </Row>
  );
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    isfullScreen: state.generalInfo.isFullScreen,
    //projects: state.projects.projects,
    currentProject: state.generalInfo.profileResponse?.currentProject,
    profileResponse: state.generalInfo.profileResponse,
    helpPageState: state.helpPagesForAdmin
  }
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    changeFullScreen: (state: boolean) => dispatch({ type: ActionTypes.ChangeFullScreen, state: state }),
    getCurrentHelpPage: (pageName: string) => dispatch({ type: ActionTypes.GetHelpPage, model: pageName })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header); 
