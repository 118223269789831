import { ActionTypes } from "../actionsTypes";
//import BmProject from "../../Models/BmProject";

const initialState = {
    isLoading: true,
    //projects: Array<BmProject>(),
    changingCurrentProject: false,
    savingLeversPeers: false,
    savingEbitPeers: false,
    //currentProject: currentProject,
    //navigateToCurrentProjectPage: false,
    changeCurrentProjectFailure: false
};

const ProjectsReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case ActionTypes.SETSAVINGEBITPEERS:
            return {
                ...state,
                savingEbitPeers: true
            }

        case ActionTypes.SETSAVINGLEVERSPEERS:
            return {
                ...state,
                savingLeversPeers: true
            }

        // case ActionTypes.SETLOADINGTOTRUE:
        //     return {
        //         ...state,
        //         isLoading: true
        //     }

        // case ActionTypes.SAVEPROJECTSLOCALLY:
        //     console.log('SAVEPROJECTSLOCALLY: ', action.projects);

        //     return {
        //         ...state,
        //         //projects: action.projects,
        //         isLoading: false
        //     }

        case ActionTypes.ERRORGETPROJECTS:
            return {
                ...state,
                isLoading: false
            }
        case ActionTypes.SETCHANGINGCURRENTPROJECT:
            return {
                ...state,
                changingCurrentProject: action.val
            }
        case ActionTypes.CURRENTPROJECTCHANGEDSUCCESSFULLY:
            //console.log('ActionTypes.CHANGECURRENTPROJECT: ', action);
            return {
                ...state,
                //currentProject: { ...action.currentProject },
                changingCurrentProject: false,
                savingLeversPeers: false,
                savingEbitPeers: false,
                changeCurrentProjectFailure: false,
                //navigateToCurrentProjectPage: true,
            }
        case ActionTypes.CurrentProjectChangeFailure:
            return {
                ...state,
                changingCurrentProject: false,
                isLoading: false,
                savingLeversPeers: false,
                savingEbitPeers: false,
                changeCurrentProjectFailure: true,
                //navigateToCurrentProjectPage: false,
            }
        case ActionTypes.resetChangeCurrentProjectFailure:
            return {
                ...state,
                changeCurrentProjectFailure: false,
            }
        default:
            break;
    }
    return state;
};

export default ProjectsReducer;