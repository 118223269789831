import { IQuestionItem } from '../../Models/IQuestion';
import QuestionService from "../../Services/QuestionService";
import { ToastMessages } from "../../Enums/Messages";
import { takeEvery, put, all } from "redux-saga/effects";
import { ActionTypes } from "../actionsTypes";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";

function* onGetQuestion() {
  try {
    yield put({ type: ActionTypes.CHANGE_QUESTION_LOADER, isLoading: true });
    let _result: ICommonResponse = yield QuestionService.GetQuestions();
    if (!_result.isError) {
      let result = _result.result as IQuestionItem[];
      yield put({ type: ActionTypes.SAVE_QUESTIONS, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({
      type: ActionTypes.CHANGE_QUESTION_LOADER,
      isLoading: false,
    });
  } catch (error) {
    console.error("ERR in onGetQuestion: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onUpdateQuestion(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_QUESTION_LOADER, isLoading: true });
    let _result: ICommonResponse = yield QuestionService.UpdateQuestion(
      action.model
    );

    if (!_result.isError) {
      let resultCus: ICommonResponse = yield QuestionService.GetQuestions();
      let result = resultCus.result as IQuestionItem[];
      yield put({ type: ActionTypes.SAVE_QUESTIONS, result: result });
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.CHANGE_QUESTION_LOADER, isLoading: false });
  } catch (error) {
    console.error("ERR in onUpdateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* watchQuestion() {
  yield takeEvery(ActionTypes.GET_QUESTIONS, onGetQuestion);
}
function* watchUpdateQuestion() {
  yield takeEvery(ActionTypes.UPDATE_QUESTION, onUpdateQuestion);
}

export default function* QuestionSagas() {
  yield all([
    watchQuestion(),
    watchUpdateQuestion()

  ]);
}
