
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import FrontLoadingCurvesService from "../../Services/FrontLoadingCurves";
import { ICurvesItem } from "../../Models/ICurves";

function* OnGetAllCurves(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: true });
        let _result: ICommonResponse = yield FrontLoadingCurvesService.GetAllCurves();
        if (!_result.isError) {
            let result = _result.result as ICurvesItem[];
            yield put({ type: ActionTypes.SAVE_CURVES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGet: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnCreateCurve(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield FrontLoadingCurvesService.CreateCurve(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield FrontLoadingCurvesService.GetAllCurves();
            let result = _result.result as ICurvesItem[];
            yield put({ type: ActionTypes.SAVE_CURVES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateCurve(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield FrontLoadingCurvesService.UpdateCurve(action.model)

        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield FrontLoadingCurvesService.GetAllCurves();
            let result = _result.result as ICurvesItem[];
            yield put({ type: ActionTypes.SAVE_CURVES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteCurve(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultDelete: ICommonResponse = yield FrontLoadingCurvesService.deleteCurve(action.id);
        if (!resultDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
            let _result: ICommonResponse = yield FrontLoadingCurvesService.GetAllCurves();
            let result = _result.result as ICurvesItem[];
            yield put({ type: ActionTypes.SAVE_CURVES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDelete: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

//Curves Phase s 

function* OnCreateCurvePhase(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield FrontLoadingCurvesService.CreateCurvePhase(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield FrontLoadingCurvesService.GetAllCurves();
            let result = _result.result as ICurvesItem[];
            yield put({ type: ActionTypes.SAVE_CURVES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateCurvePhase(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield FrontLoadingCurvesService.UpdateCurvePhase(action.model)
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield FrontLoadingCurvesService.GetAllCurves();
            let result = _result.result as ICurvesItem[];
            yield put({ type: ActionTypes.SAVE_CURVES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteCurvePhase(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultDelete: ICommonResponse = yield FrontLoadingCurvesService.deleteCurvePhase(action.id);
        if (!resultDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
            let _result: ICommonResponse = yield FrontLoadingCurvesService.GetAllCurves();
            let result = _result.result as ICurvesItem[];
            yield put({ type: ActionTypes.SAVE_CURVES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_CURVES_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDelete: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
////
function* watchGetAllCurves() {
    yield takeEvery(ActionTypes.GET_ALL_CURVES, OnGetAllCurves);
}
function* watchCreateCurve() {
    yield takeEvery(ActionTypes.CREATE_CURVE, OnCreateCurve);
}
function* watchUpdateCurve() {
    yield takeEvery(ActionTypes.UPDATE_CURVE, onUpdateCurve);
}
function* watchDeleteCurve() {
    yield takeEvery(ActionTypes.DELETE_CURVE, onDeleteCurve);
}
///phase
function* watchCreateCurvePhase() {
    yield takeEvery(ActionTypes.CREATE_CURVE_PHASE, OnCreateCurvePhase);
}
function* watchUpdateCurvePhase() {
    yield takeEvery(ActionTypes.UPDATE_CURVE_PHASE, onUpdateCurvePhase);
}
function* watchDeleteCurvePhase() {
    yield takeEvery(ActionTypes.DELETE_CURVE_PHASE, onDeleteCurvePhase);
}

export default function* FrontloadinCurveSagas() {
    yield all([
        watchGetAllCurves(),
        watchCreateCurve(),
        watchUpdateCurve(),
        watchDeleteCurve(),
        watchCreateCurvePhase(),
        watchUpdateCurvePhase(),
        watchDeleteCurvePhase()
    ]);
}