import { ItemCustomerManagement } from "./../../Models/ItemAdminTable/Items";
import CustomersService from "../../Services/CustomerManagement";
import { ToastMessages } from "../../Enums/Messages";
import { takeEvery, put, all } from "redux-saga/effects";
import { ActionTypes } from "../actionsTypes";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
//import { ItemCustomers } from "../../Models/ItemAdminTable/Items";
function* onGetCustomer() {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: true });
    let _result: ICommonResponse = yield CustomersService.GetCustomer();
    if (!_result.isError) {
      let result = _result.result as ItemCustomerManagement[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERS, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({
      type: ActionTypes.CHANGE_CUSTOMER_LODAER,
      isLoading: false,
    });
  } catch (error) {
    console.error("ERR in onGetCustomer: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onCreateCustomer(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultCreate: ICommonResponse = yield CustomersService.CreateCustomerRow(
      action.model
    );
    if (!resultCreate.isError) {
      // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
      let _result: ICommonResponse = yield CustomersService.GetCustomer();
      let result = _result.result as ItemCustomerManagement[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onCreateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onUpdateCustomer(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: true });
    let resultUpdate: ICommonResponse = yield CustomersService.UpdateCustomer(
      action.model
    );

    if (!resultUpdate.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
      let _result: ICommonResponse = yield CustomersService.GetCustomer();
      let result = _result.result as ItemCustomerManagement[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERS, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: false });
  } catch (error) {
    console.error("ERR in onUpdateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onDeleteCustomer(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: true });
    let resultDelete: ICommonResponse = yield CustomersService.DeleteCustomer(
      action.id
    );
    if (!resultDelete.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
      let _result: ICommonResponse = yield CustomersService.GetCustomer();
      let result = _result.result as ItemCustomerManagement[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERS, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: false });
  } catch (error) {
    console.error("ERR in onDeleteCOD: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onDeleteImageCustomer(action: any) {
  
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: true });
    let resultDelete: ICommonResponse = yield CustomersService.DeleteImageCustomer(
      action.customerId
    );
    if (!resultDelete.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
      let _result: ICommonResponse = yield CustomersService.GetCustomer();
      let result = _result.result as ItemCustomerManagement[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERS, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMER_LODAER, isLoading: false });
  } catch (error) {
    console.error("ERR in onDeleteCOD: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* watchCustomers() {
  yield takeEvery(ActionTypes.GET_CUSTOMERS, onGetCustomer);
}
function* watchCreateCustomer() {
  yield takeEvery(ActionTypes.CREATE_CUSTOMER, onCreateCustomer);
}
function* watchUpdateCustomer() {
  yield takeEvery(ActionTypes.UPDATE_CUSTOMER, onUpdateCustomer);
}
function* watchDeleteCustomer() {
  yield takeEvery(ActionTypes.DELETE_CUSTOMER, onDeleteCustomer);
}
function* watchDeleteImageCustomer() {
  yield takeEvery(ActionTypes.DELETE_IMAGE_CUSTOMER, onDeleteImageCustomer);
}
export default function* CustomerMngSagas() {
  yield all([
    watchCustomers(),
    watchCreateCustomer(),
    watchDeleteCustomer(),
    watchUpdateCustomer(),
    watchDeleteImageCustomer()
  ]);
}
