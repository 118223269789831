import { Button, Form, Input, InputNumber } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { inputFormatter, inputParser } from '../../../../Utils/helperFunctions';
import i18n from '../../../../i18n';
import { RulesName, RulesRequire } from '../../Rules/RulesValidation';
import { IActivityGroupTemplate } from '../../../../Models/IActivityManagement';
import { t } from 'i18next';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';

interface IProps {
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    activityProfileID: number;
    createActivityGroup: (model: IActivityGroupTemplate) => any;
}

const ActivityGroupAddModal = (props: IProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            let values = await form.getFieldsValue();
            const newGroup: IActivityGroupTemplate = {
                activityProfileId: props?.activityProfileID,
                nameEn: values?.nameEn,
                nameDe: values?.nameDe,
                bestPracticeValue: values?.bestPracticeValue,
                serialNumber: values?.serialNumber,
            }
            await props.createActivityGroup(newGroup);

        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <ModalAdmin title={`${t("Add Activity Group")}`} btnText={`${t("Add")}`}
            width="520px"
            closeAndReset={closeAndReset}
            modalResponse={props.modalResponse}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Close")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form}>
                <Form.Item label={`${t("Serial Number")}`} name='serialNumber'
                    rules={RulesRequire({ filedName: `${t("Serial Number")}` })}>
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item label={`${t("English Name")}`} name='nameEn'
                    rules={RulesName({ name: `${t("English Name")}`,countChar:128 })}>
                    <Input />
                </Form.Item>
                <Form.Item label={`${t("German Name")}`} name='nameDe'
                    rules={RulesName({ name: `${t("German Name")}`,countChar:128})}>
                    <Input />
                </Form.Item>
                <Form.Item label={`${t("Best Practice")}`} name='bestPracticeValue' rules={RulesRequire({ filedName: `${t("Best Practice")}` })}>
                    <InputNumber formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%" />
                </Form.Item>
            </Form>
        </ModalAdmin>
    )
}
const mapStateToProps = (state: any) => {
    return {
        isModalLoading: state.activiyProfileMng.isModalLoading,
        modalResponse: state.activiyProfileMng.modalResponse,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        createActivityGroup: (model: IActivityGroupTemplate) => dispatch({ type: ActionTypes.CREATE_ACTIVITY_GROUP_FOR_ADMIN, model }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityGroupAddModal);
