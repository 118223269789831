import React from 'react';
import { Button, Popconfirm, Space, Tooltip, Typography } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, StopOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import "./index.scss";
import { t } from 'i18next';
interface IProps {
    editable?: boolean;
    deleteId?: number;
    record?: any;
    onCancel?: () => any;
    onEdit?: (record: any) => any;
    onDelete?: (id: number) => any;
    onSave?: (key: string) => any;
}
const AdminOperation = (props: IProps) => {
    return <Space size="small" className='admin-operation'>
        {props.editable ? (
            <>
                {props.onSave ? (<Button htmlType="submit" type='link' onClick={() => props.onSave(props.record.key)}>
                    <Tooltip title={`${t("Save")}`} placement="bottom" getPopupContainer={() => document.getElementById("app-layout")}>
                        <SaveOutlined style={{ color: "#3cad43" }} />
                    </Tooltip>
                </Button>) : (<Button htmlType="submit" type='link'  >
                    <Tooltip title={`${t("Save")}`} placement="bottom" getPopupContainer={() => document.getElementById("app-layout")}>

                        <SaveOutlined style={{ color: "#3cad43" }} />
                    </Tooltip>
                </Button>)}
                <Typography.Link onClick={props.onCancel} style={{ marginTop: "2px" }}>
                    <Tooltip placement="bottom" title={`${t("Cancel")}`} getPopupContainer={() => document.getElementById("app-layout")}>
                        <StopOutlined />
                    </Tooltip>
                </Typography.Link>
            </>
        ) : (<>
            <Typography.Link disabled={props.editable} onClick={() => props.onEdit(props.record)} >
                <Tooltip title={`${t("Edit")}`} placement="bottom" getPopupContainer={() => document.getElementById("app-layout")}>
                    <EditOutlined />
                </Tooltip>
            </Typography.Link>
            {props.deleteId && <Popconfirm title={`${t("Sure to Delete")}`} disabled={props.editable}
                onConfirm={() => props.onDelete(props.deleteId)}
                getPopupContainer={() => document.getElementById("app-layout")}
                cancelText={`${t("Cancel")}`}

            >
                <Tooltip title={`${t("Delete")}`} placement="bottom" getPopupContainer={() => document.getElementById("app-layout")}>
                    <DeleteOutlined />
                </Tooltip>
            </Popconfirm>}
        </>)}
    </Space >
}

export default AdminOperation;