import React, { useState } from 'react';
import { CheckCircleOutlined, DeleteOutlined, StopOutlined, EditOutlined } from "@ant-design/icons";
import { ICustomerNewBmProjectForUser } from '../../../../Models/ICustomerUsers';
import { Button, Checkbox, Form, Popconfirm, Select, Space, Table, Tooltip, Typography } from 'antd';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import AdminOperation from '../../../../Components/AdminOperation/AdminOperation';
import { IGeneralState } from '../../../../Redux/reducers/IGeneralState';
import { RulesRequire } from '../../Rules/RulesValidation';
import { t } from 'i18next';

interface IProps {
    userId: number;
    customerId: number;
    bmProjectsData: ICustomerNewBmProjectForUser[];
    collectionRoles: any[];
    bmProjectList: any[];
    updateBmProjectForCustomerUser: (model: ICustomerNewBmProjectForUser, id: number) => any;
    deleteBmProjectForCustomerUser: (bmProjectId: number, id: number) => any;

}
const CustomerUserAssignBmProjectTable = (props: IProps) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const bmProjectDataRow = props.bmProjectsData?.map(bm => {
        return {
            key: `${bm.id}_bm`,
            ...bm
        }
    }) ?? [];

    const columns: any[] = [
        {
            key: "customer-user-bmProjectId",
            title: `${t("Bm Project")}`,
            dataIndex: 'bmProjectId',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='bmProjectId' rules={RulesRequire({ filedName: `${t("Bm Project")}` })}  >
                            <Select showSearch placeholder={`${t("Select BmProject")}`} optionFilterProp="children"       getPopupContainer={() => document.getElementById("app-layout")}>
                                {props.bmProjectList?.map((b: any) => <Select.Option value={b?.id} key={b?.id}>{b?.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    )
                } else {
                    return <Typography>{props?.bmProjectList?.find(bm => bm?.id === record?.bmProjectId)?.name}</Typography>
                }

            }
        },
        {
            key: "customer-user-coll",
            title: `${t("Data Collection Roles")}`,
            dataIndex: 'dataCollectionRoles',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='dataCollectionRoles' rules={RulesRequire({ filedName: 'Data Collection Roles' })} >
                            <Checkbox.Group options={props.collectionRoles && props.collectionRoles} style={{ textAlign: 'left' }} />
                        </Form.Item>
                    )
                } else {
                    return <Checkbox.Group options={props.collectionRoles && props.collectionRoles} value={record?.dataCollectionRoles} disabled style={{ textAlign: 'left' }} />
                }

            }
        },
        {
            title:`${t("Actions")}`,
            dataIndex: '',
            width: "10%",
            render: (_: any, record: ICustomerNewBmProjectForUser) => {
                const editable = isEditing(record);
                return <AdminOperation
                    deleteId={record.id}
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                    onDelete={deleteBmProject}
                />
            },
        },

    ]
    const isEditing = (record: ICustomerNewBmProjectForUser) => record.key === editingKey;
    const edit = (record: Partial<ICustomerNewBmProjectForUser> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            const index = bmProjectDataRow.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = bmProjectDataRow[index];
                const updateBmProject: ICustomerNewBmProjectForUser = {
                    id: item.id,
                    userId: props.userId,
                    ...values
                }
               await props.updateBmProjectForCustomerUser(updateBmProject, props.customerId);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }
    const cancel = () => {
        setEditingKey('');
    };
    const deleteBmProject = async (id: number) => {
        try {
            await props.deleteBmProjectForCustomerUser(id, props.customerId);

        } catch (error) {
            console.log('delete Failed:', error);
        }
    }
    return (
        <Form form={form} key="assignBm_form" onFinish={onFinish} >
            <Table
                key='assignBm_table'
                getPopupContainer={() => document.getElementById("app-layout")}
                bordered
                dataSource={bmProjectDataRow}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    hideOnSinglePage: true,
                    pageSize: 5,
                    defaultPageSize: 5,
                    total: bmProjectDataRow.length,
                    showSizeChanger: false,
                    locale:{items_per_page:`/ ${t("Page")}`},
                }}

            />
        </Form>
    )
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        collectionRoles: state.generalInfo.profileResponse?.generalSettings?.collectionRoles,
        bmProjectList: state.bmProjectList.bmProjectListResult,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateBmProjectForCustomerUser: (model: ICustomerNewBmProjectForUser, id: number) => dispatch({ type: ActionTypes.ASSIGN_NEW_BM_PROJECT_FOR_USER, model: model, customerId: id }),
        deleteBmProjectForCustomerUser: (customerUserId: number, id: number) => dispatch({ type: ActionTypes.DELETE_BM_PROJECT_FOR_USER, customerUserId, customerId: id }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerUserAssignBmProjectTable)
