import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }: any) => {
  if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export const ProtectedAdminRoute = ({ children }: any) => {
  if (isAdmin()) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export const ProtectedCustomerRoute = ({ children }: any) => {
  console.log(localStorage.getItem("token") || sessionStorage.getItem("token"));
  if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export const isAdmin = (): boolean => {
  if (localStorage.getItem("token") || sessionStorage.getItem("token"))
    return true;
  return false;
}
