import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import ChangeProjectTable from './ChangeProjectTable/ChangeProjectTable';
import './change_project.css';
import ColOptionsDrop from './ColOptionsDrop/ColOPtionsDrop';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import { BmProject, GeneralBmProjectDto } from '../../../Models/Responses/ProfileResponse';
import { Button, Result, Spin, Tabs } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelpPageCodes } from '../../../Redux/HelpPageCodes';
import { t } from 'i18next';
import CostType from '../../Tools/EBITTool-V2/CostType';
import HelpPage from '../../../Layout/HelpPage';

interface Iprops {
    projects: GeneralBmProjectDto[];
    currentProject: BmProject;
    isLoading: boolean;
    currentUserId: number;
    changeCurrentProject: (currentProjectId: number) => any;
    openAccessModal: () => any;
    hasAccessTokenToProjects: boolean;
    changeCurrentHelpPageCode: (pageName: string) => any;
};

function ChangeProject(props: Iprops) {
    const navigate = useNavigate();
    useEffect(() => {
        //get access
        props.changeCurrentHelpPageCode(HelpPageCodes.ChangeProject);
        console.log("openAccessModal");
        props.openAccessModal();
    }, []);
    const changeCurrentProject = (currentProjectId: number) => {
        let projects = props.projects.filter((project, idx) => {
            return (project.id === currentProjectId);
        });
        if (projects) {
            if (projects.length > 0) {
                let selectedProject = projects[0];
                props.changeCurrentProject(currentProjectId);
            }
        }
    };
    let view = (
        <div className='change_project_div'>
            <div className='columns_options_div'>
                <ColOptionsDrop />
            </div>
            <div className='change_project_table_div'>
                <ChangeProjectTable changeCurrentProject={changeCurrentProject} />
            </div>
        </div>);
    if (props.hasAccessTokenToProjects) {
        let items = [{
            label: <>{t("Change Project")}</>, key: 'change-project-tab',
            children: <Spin spinning={props.isLoading}>
                <div className='change_project_container'>
                    {view}
                </div>
            </Spin>
        },
        {
            label: <>{t("Tipps and Tricks")}</>, key: 'tips-change-project-tab',
            children: <HelpPage currentPageCode="change project" />
        }]

        return (
            <Tabs tabPosition="top" defaultActiveKey="change-project-tab" type="line" size='small'
                key={"change-project-tabs"} id="change-project-tabs" items={items} />
        );
    } else {
        return <Result
            status="403"
            title="403"
            subTitle={`${t("Sorry, you are not authorized to access this page.")}`}
            extra={<Button type="primary" onClick={() => { navigate("/dashboard") }}>{`${t("Back Home")}`}</Button>}
        />;
    }
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        projects: state.generalInfo.allProjects,
        isLoading: state.generalInfo.isLoading,
        //changingCurrentProject: state.projects.changingCurrentProject,
        //navigateToCurrentProjectPage: state.projects.navigateToCurrentProjectPage,
        //changeCurrentProjectFailure: state.projects.changeCurrentProjectFailure,
        tablesColsProps: state.tablesColsProps.changeProjectTableColsProps,
        currentProject: state.generalInfo.profileResponse?.currentProject,
        currentUserId: state.generalInfo?.profileResponse?.id,
        hasAccessTokenToProjects: state.generalInfo.hasAccessTokenToProjects
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        openAccessModal: () => dispatch({ type: ActionTypes.OpenAccessModal }),
        changeCurrentProject: (currentProjectId: number) => dispatch({ type: ActionTypes.CHANGECURRENTPROJECTREMOTLY, currentProjectId: currentProjectId }),
        changeCurrentHelpPageCode: (pageName: string) => dispatch({ type: ActionTypes.ChangeHelpPageCode, result: pageName })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProject);