import { Avatar, Button, Form, Input, List, Comment, Tooltip, Badge, Tag, Card, Tabs, Row, Col, Descriptions, Space, InputNumber, Empty } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import _ from "lodash";
import { IActivityToolState, IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { ActivityProfileAnalysisRoundView, ActivityProfileAnalysisView, ActivityResponseReviewDto, ActivityVoteReviewDto } from "../Survey/Models/ActivitySurveyData";
import ActivityReviewForm from "./ActivityReviewForm";
import ActivityAnswerForm from "./ActivityAnswerForm";
interface IProps {
    profile: ActivityProfileAnalysisView;
    result: IActivityToolState;
    reviewAnswer: (model: ActivityResponseReviewDto) => any;
    removeAnswer: (answerId: number) => any;
}

function ActivityReviewList(props: IProps) {
    const displyAnswersAsTabs = (round: ActivityProfileAnalysisRoundView) => {
        let items = [];
        if (round.answers && round.answers?.length == 0) return <Empty description={`${t("No answers")}`} />;
        round.answers?.map((answer, indx) => {
            items.push({
                //label: <><b>{`${t("Participant Answer:")} `}</b>{`#${indx + 1}`}</>,
                label: <>{`#${indx + 1}: `}<b>{answer.passPhrase}</b></>,
                key: `ansewer_to_${answer.answerId}`,
                children: (answer.answersOnTasks && answer.answersOnTasks?.length > 0) ?
                    <ActivityAnswerForm answer={answer} onReview={props.reviewAnswer}
                        onRemove={() => { props.removeAnswer(answer.answerId) }} />
                    : <Empty />
            });
        });
        return <Tabs tabPosition="top" items={items} />;
    }


    const roundsTabs = () => {
        let items = [];
        props.profile?.rounds?.map((round, index) => {
            //round.answers?.filter(c => c. == null && !(c.noPreviousInteraction || (c.kRate == null && c.qRate == null && c.rRate == null)));
            items.push({
                label: `${t("Round")} ${index + 1}`,
                key: `round_${index}`,
                children: <>
                    <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                        <Col span={16}>
                            <Descriptions layout="horizontal">
                                <Descriptions.Item label={`${t("Started at")}`}>{round?.startAt}</Descriptions.Item>
                                <Descriptions.Item label={`${t("Ended at")}`}>{round?.endAt}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>

                    <Card title={`${t("Answers for review")} (${round?.totalReviewedAnswered}/${round?.totaAnswersForReview})`} className="current_project_card">
                        {displyAnswersAsTabs(round)}
                    </Card>
                </>
            });
        })

        return <Tabs tabPosition="top" items={items} />;
    }

    return roundsTabs();
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        result: state.activity
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        reviewAnswer: (model: ActivityResponseReviewDto) => dispatch({ type: ActionTypes.ReviewActivityVote, model: model }),
        removeAnswer: (answerId: number) => dispatch({ type: ActionTypes.RemoveActivityVote, answerId: answerId })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReviewList);