import React, { useRef, useState } from 'react';
import { CheckCircleOutlined, DeleteOutlined, StopOutlined, EditOutlined } from "@ant-design/icons";
import { IFrontLoadingTempPhase } from '../../../../Models/IFrontLoading';
import { Button, Form, Input, Popconfirm, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import { RulesName } from '../../Rules/RulesValidation';
import { formatValue } from '../../../../Utils/helperFunctions';
import AdminOperation from '../../../../Components/AdminOperation/AdminOperation';
import { t } from 'i18next';



interface IProps {
    templateId: number;
    phasesDataRow: IFrontLoadingTempPhase[];
    updateFrontLoadingTempPhase: (model: IFrontLoadingTempPhase) => any;
    deleteFrontLoadingTempPhase: (id: number) => any;
}
const FrontLoadingPhaseTable = (props: IProps) => {
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "number_1",
            title: 'Phase Number',
            dataIndex: 'phaseNumber',
            render: (text: any, record: any) => {
                return <Tag>{text}</Tag>
            },
        },
        {
            key: "nameEn_1",
            title:`${t("English Name")}`,
            dataIndex: 'nameEn',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='nameEn'
                        rules={RulesName({ name: `${t("English Name")}`,countChar:128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("English Name")}`,'nameEn', searchInput, handleSearch), 
            defaultSortOrder: 'ascend',
        },
        {
            key: "nameDe_2",
            title:`${t("German Name")}`,
            dataIndex: 'nameDe',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='nameDe'
                            rules={RulesName({ name: `${t("German Name")}`,countChar:128})}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("German Name")}`,'nameDe', searchInput, handleSearch), 
            defaultSortOrder: 'ascend',
        },
        {
            title:`${t("Actions")}`,
            dataIndex: '',
            width: '100px',
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                return <AdminOperation
                deleteId={record.id}
                record={record}
                editable={editable}
                onCancel={cancel}
                onEdit={edit}
                onDelete={deleteTempPhase}
            />

            },
        }
    ];
    const isEditing = (record: IFrontLoadingTempPhase) => record.key === editingKey;
    const edit = (record: Partial<IFrontLoadingTempPhase> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            const index = props?.phasesDataRow.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = props?.phasesDataRow[index];
                const frontLoadingTempPhaseUpdate: IFrontLoadingTempPhase = { id: item.id, templateId: props.templateId, ...values };
                await props.updateFrontLoadingTempPhase(frontLoadingTempPhaseUpdate);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }

    }
    const deleteTempPhase = async (id: any) => {
        try {
            await props.deleteFrontLoadingTempPhase(id)

        } catch (error) {
            console.log('delete Failed:', error);
        }
    }
    return (
        <Form form={form} key="front_temp_form" onFinish={onFinish}>
            <Table
                key='front_temp_table'
                getPopupContainer={() => document.getElementById("app-layout")}
                bordered
                dataSource={props?.phasesDataRow}
                columns={columns}
                rowClassName="editable-row"
                scroll={{ y: "calc(100vh - 300px)" }}
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 150],
                    total: props?.phasesDataRow.length,
                    showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                    showSizeChanger: true,
                    locale:{items_per_page:`/ ${t("Page")}`},
                }}
                locale={{
                    triggerDesc: t('Click to sort descending'),
                    triggerAsc: t('Click to sort ascending'),
                    cancelSort: t('Click to cancel sorting')
                }}
            />
        </Form>
    );
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateFrontLoadingTempPhase: (model: IFrontLoadingTempPhase) => dispatch({ type: ActionTypes.UPDATE_FRONT_LOADING_TEMP_PHASE, model }),
        deleteFrontLoadingTempPhase: (id: number) => dispatch({ type: ActionTypes.DELETE_FRONT_LOADING_TEMP_PHASE, id })
    };
};

export default connect(null, mapDispatchToProps)(FrontLoadingPhaseTable);
