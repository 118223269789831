import { Button, Popconfirm, Popover, Result, Space, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ISABestPractice, ISelfAssessmentSave } from "../../../Models/SelfAssessment/ISelfAssessmentView";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState, ISelfAssessmentState, ISelfAssessmentToolState } from "../../../Redux/reducers/IGeneralState";
import SABestPractices from "./widgets/SABestPractices";
import SAMatrixChart from "./widgets/SAMatrixChart";
import SARadarCharts from "./widgets/SARadarCharts";
import "./SATool.scss";
import { FileExcelOutlined, SaveOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { usePrompt } from "../EBITTool-V2/BasicCharts/PromptAndBlocker";
import isEqual from 'lodash.isequal'
import _ from "lodash";
import HelpPage from "../../../Layout/HelpPage";
interface IProps {
    viewState: ISelfAssessmentToolState;
    projectName: string;
    getDetails: () => any;
    saveAnswers: (model: ISelfAssessmentSave) => any;
    exportToExcel: (projectName: string) => any;
}
function SATool(props: IProps) {
    // a state where to observe the R&D (inputs) state
    const [formState, setFormState] = useState<"unchanged" | "modified" | "saving">("unchanged");

    // Show an exit prompt if the formState is NOT
    // unchanged (modified or being saved) to prevent losing enterd values.
    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "";
        };
        if (formState !== "unchanged") {
            window.addEventListener("beforeunload", handler);
            return () => {
                window.removeEventListener("beforeunload", handler);
            };
        }
        return () => { };
    }, [formState]);

    const [oldResult, setOldResult] = useState(null);

    useEffect(() => {
        if (oldResult == null) {
            // console.log("setOldResult(props.viewState.viewResult) 2", props.viewState.viewResult, {...props.viewState.viewResult});
            if (props.viewState.viewResult) {
                setOldResult(_.cloneDeep(props.viewState.viewResult))
            }
        }
        else {
            let notChanged: boolean = isEqual(oldResult, props.viewState.viewResult);
            // console.log("isEqual(oldResult, props.viewState.viewResult) ", notChanged, oldResult, props.viewState.viewResult);
            if (!notChanged) {
                setFormState("modified");
            }
            else {
                setFormState("unchanged");
            }
        }
    }, [props.viewState.viewResult])


    const [dataSource, setDataSource] = useState<ISABestPractice[]>([]);
    const [topRated, setToprated] = useState<number>();
    useEffect(() => {

        let temp2 = updateList();

        setDataSource(temp2);
    }, [props.viewState]);

    const updateList = () => {
        let temp = [];
        props.viewState?.viewResult?.levers?.map(lever => {
            lever.sublevers.map(sublever => {
                sublever.bestPractices.map(bp => {
                    temp.push(bp);
                })
            })
        });
        let temp2: ISABestPractice[] = [];
        let tempOrdered = temp.filter(c => c.importance).sort((a, b) => {
            return ((b.importance ?? 0) * (b.urgency ?? 0)) - ((a.importance ?? 0) * (a.urgency ?? 0))
        });
        let topRated = props.viewState?.topRated ?? (parseInt(localStorage.getItem("sa_top_rated") ?? "20"));
        setToprated(topRated);
        if (tempOrdered.length > topRated) {
            let x = tempOrdered[topRated - 1];
            tempOrdered = tempOrdered.filter(c => ((c.importance ?? 0) * (c.urgency ?? 0)) >= ((x.importance ?? 0) * (x.urgency ?? 0))             );
        }
        tempOrdered?.map((bp, i) => {
            bp.index = i + 1;
            bp.key = `key_${bp.id}`;
            temp2.push(bp)
        })
        return temp2
    }

    const onExport = () => {
        props.exportToExcel(props.projectName);
    }

    const onSaveAll = () => {
        let bpList = [];
        props.viewState?.viewResult?.levers?.map(lever => {
            lever.sublevers.map(sub => {
                sub.bestPractices.map(bp => {
                    // if (bp.importance) {
                    bpList.push({
                        id: bp.id,
                        importance: bp.importance,
                        urgency: bp.urgency,
                        comment: bp.comment
                    })
                    //}
                })
            })
        })
        let model: ISelfAssessmentSave = {
            bestPractices: bpList
        };

        props.saveAnswers(model);
        setFormState("unchanged");
        setOldResult(null);
    }

    const operations = <Space size={16} align="center">
        <Button type="default" icon={<SaveOutlined />} onClick={onSaveAll}>
            {`${t("Save All")}`}
        </Button>
        <Button type="default" icon={<FileExcelOutlined style={{ 'margin': 'auto' }} />} onClick={onExport}>
            {`${t("Export")}`}
        </Button>
    </Space>;

    const items = [
        { label: t("Best Practices"), key: 'bp-tab', children: <SABestPractices dataSource={dataSource} defaultTopRated={topRated} /> },
        { label: t("Eisenhower Matrix"), key: 'matrix-tab', children: <SAMatrixChart dataSource={dataSource} defaultTopRated={topRated} /> },
        { label: t("Radar Chart"), key: 'radar-tab', children: <SARadarCharts /> },
        {
            label: <>{t("Tipps and Tricks")}</>, key: 'tips-sa-tab',
            children: <HelpPage currentPageCode="self-assessment" />
        }
    ];

    usePrompt(t("Sie haben nicht gespeicherte Änderungen, möchten Sie wirklich gehen?"), formState !== "unchanged");

    return <Spin spinning={props.viewState.isLoading}>
        <Tabs key={"self-assessement-tab"} id="self-assessement-tab" tabBarExtraContent={operations} items={items} />
    </Spin>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        viewState: state.selfAssessementTool,
        projectName: state.profileData?.profileResult?.currentProject?.project_name,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getDetails: () => dispatch({ type: ActionTypes.GetSelfAssessmentView }),
        saveAnswers: (model: ISelfAssessmentSave) => dispatch({ type: ActionTypes.SaveSelfAssessmentView, model: model }),
        exportToExcel: (projectName: string) => dispatch({ type: ActionTypes.ExportSelfAssessntToExcel, projectName: projectName }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SATool);