import { Radio, RadioChangeEvent, Space, Button, Spin, Modal } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import './frontloadingprojectselection.scss';
import { EditOutlined, DeleteOutlined, ExclamationCircleFilled, FileDoneOutlined } from '@ant-design/icons';
import AddEditFlProjectForm from "../AddEditFlProjectForm/AddEditFlProjectForm";
import { IDProject } from "../../../../../../../Models/IDProject";
import { IFlToolToBeEdittedProject, IFrontLoadingToolProject, IFrontLoadingToolTemplate } from "../../../../../../../Models/IFrontLoadingTool";
import { connect } from "react-redux";
import { ActionTypes } from "../../../../../../../Redux/actionsTypes";
import { AddFlProjectModel, EditFlProjectToUploadModel } from "../../../../../../../Models/FrontLoadingTool/FrontLoadingTool";
import BestpracticeCurveData from "../../Models/BestpracticeCurveData";
import Popconfirm from "antd/lib/popconfirm";
import { useTranslation } from "react-i18next";
import { IGeneralState } from "../../../../../../../Redux/reducers/IGeneralState";
import { BmProject } from "../../../../../../../Models/Responses/ProfileResponse";


interface IProps {
    flProjects: IFrontLoadingToolProject[];
    getToBeEdittedFlProjectData: (toBeEdittedFlProjectId: number) => void;
    flToolToBeEdittedProject: IFlToolToBeEdittedProject;
    gettingToBeEdittedFlProject: boolean;
    //editFlProject: (flProject: EditFlProjectToUploadModel) => void;
    edittingFlProject: boolean;
    deletFlProject: (id: number, isCurrentFlProject: boolean) => void;
    deletingFlProject: boolean;
    selectFlProjectForConsultant: (id: number) => void;
    selectingFlProjectForConsultant: boolean;
    currentFrontLoadingProjectId: number;
    //bestpracticeCurves: BestpracticeCurveData[];
    toBeEdittedFlProject: EditFlProjectToUploadModel; //TODO rename, after confirmation
    showConfirmationMsg: boolean;
    confirmEditFlProject: (flProject: EditFlProjectToUploadModel) => void;
    confirmingEditFlProject: boolean;
    resetEditConfirmationAttributes: () => void;
    resetFlToolToBeEdittedProject: () => void;
    markFlProjectAsComplete: () => void;
    markingFlProjectAsComplete: boolean;
    frontloadingProgress: number;

    currentProject: BmProject;
};


function FrontLoadingProjectSelection(props: IProps) {
    const [toBeDeletedFlProjectId, setToBeDeletedFlProjectId] = useState(0);
    //const [showEditForm, setShowEditForm] = useState(false);

    //const { confirm } = Modal;
    const { t } = useTranslation();

    useEffect(() => {
        if (!props.deletingFlProject) {
            setToBeDeletedFlProjectId(0);
        }
    }, [props.deletingFlProject]);

    // useEffect(() => {
    //     console.log("props.showConfirmationMsg: ", props.showConfirmationMsg);
    //     if (props.showConfirmationMsg) {
    //         showConfirmationMsg();
    //     }
    // }, [props.showConfirmationMsg]);

    // const showConfirmationMsg = () => {
    //     confirm({
    //         title: 'Note',
    //         icon: <ExclamationCircleFilled />,
    //         content: t('Please note that changing the template will cause any saved data related to the current template in the last phases to be lost. Are you sure you want to continue updating the project?.'),
    //         okText: 'Yes',
    //         okType: 'danger',
    //         cancelText: 'No',
    //         okButtonProps: { disabled: props.confirmingEditFlProject, loading: props.confirmingEditFlProject },
    //         cancelButtonProps: { disabled: props.confirmingEditFlProject },
    //         onOk() {
    //             props.confirmEditFlProject(props.toBeEdittedFlProject);
    //         },
    //         onCancel() {
    //             props.resetEditConfirmationAttributes();
    //         },
    //     });
    // };

    const editFlProject = (id: number) => {
        props.getToBeEdittedFlProjectData(id);
    };

    const deletFlProject = (toBeDeletedFlProjectId: number) => {
        setToBeDeletedFlProjectId(toBeDeletedFlProjectId);
        props.deletFlProject(toBeDeletedFlProjectId, (toBeDeletedFlProjectId === props.currentFrontLoadingProjectId));
    };

    const selectFlProjectForConsultant = (e: RadioChangeEvent) => {
        props.selectFlProjectForConsultant(e.target.value);
    };

    const markFlProjectAsComplete = () => {
        props.markFlProjectAsComplete();
    };

    return (
        <div className="fl_selection_main_div">
            <Radio.Group /*onChange={onChange}*/ value={props.currentFrontLoadingProjectId} style={{ "width": "100%" }}>
                <Spin spinning={props.selectingFlProjectForConsultant}>
                    <Space wrap={true} direction="vertical" style={{ "width": "100%" }}>
                        {
                            props.flProjects?.map((flProject, idx) => {
                                return (
                                    <div style={{ "width": "100%" }} key={idx}>
                                        <div className="radio_item_div">
                                            <Radio
                                                value={flProject.id}
                                                style={{ "width": "200px" }}
                                                onChange={selectFlProjectForConsultant}>
                                                <div className="fl_selection_radio_div" title={flProject.name}>
                                                    <strong>{flProject.name}</strong>
                                                </div>
                                            </Radio>
                                            <Space wrap={true} align="end" direction="horizontal">
                                                {(flProject.id === props.currentFrontLoadingProjectId) ? <Spin spinning={props.markingFlProjectAsComplete}>
                                                    <Popconfirm title={t("Are you sure?")} onConfirm={() => markFlProjectAsComplete()}
                                                        okText={`${t("Yes")}`}
                                                        cancelText={`${t("No")}`}>
                                                        <Button
                                                            title={
                                                                (props.frontloadingProgress === 100) ?
                                                                    t("Mark Front-loading analysis as uncomplete.") :
                                                                    t("Mark Front-loading analysis as complete.")
                                                            }
                                                            style={{ "marginRight": "5px" }}
                                                            shape="circle"
                                                            icon={<FileDoneOutlined style={{ 'color': 'blue' }} />}
                                                            size="small" />
                                                    </Popconfirm>
                                                </Spin> : null}
                                                <Button
                                                    shape="circle"
                                                    icon={<EditOutlined style={{ 'color': 'green' }} />}
                                                    size="small"
                                                    style={{ "marginRight": "5px" }}
                                                    disabled={flProject.id === props.flToolToBeEdittedProject?.id}
                                                    onClick={() => editFlProject(flProject.id)} />
                                                <Spin spinning={props.deletingFlProject && (toBeDeletedFlProjectId === flProject.id)}>
                                                    <Popconfirm title={t("Are you sure?")} onConfirm={() => deletFlProject(flProject.id)}
                                                        okText={`${t("Yes")}`}
                                                        cancelText={`${t("No")}`}>
                                                        <Button
                                                            disabled={flProject.id === props.flToolToBeEdittedProject?.id}
                                                            shape="circle"
                                                            icon={<DeleteOutlined style={{ 'color': 'red' }} />}
                                                            size="small" />
                                                    </Popconfirm>
                                                </Spin>
                                            </Space>
                                            {/* <div className="radio-Btns-edit-delete">
                                               
                                            </div> */}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Space>
                </Spin>
            </Radio.Group>
        </div>
    );
};

const mapStateToProps = (state: IGeneralState) => {
    return {
        currentProject: state.profileData?.profileResult?.currentProject,
        toBeEdittedFlProject: state.frontLoadingProjects.toBeEdittedFlProject,//TODO rename, after confirmation
        showConfirmationMsg: state.frontLoadingProjects.showConfirmationMsg,
        flToolToBeEdittedProject: state.frontLoadingProjects.flToolToBeEdittedProject,
        gettingToBeEdittedFlProject: state.frontLoadingProjects.gettingToBeEdittedFlProject,
        edittingFlProject: state.frontLoadingProjects.edittingFlProject,
        deletingFlProject: state.frontLoadingProjects.deletingFlProject,
        selectingFlProjectForConsultant: state.frontLoadingProjects.selectingFlProjectForConsultant,
        currentFrontLoadingProjectId: state.generalInfo?.profileResponse?.currentFrontLoadingProjectId,
        confirmingEditFlProject: state.frontLoadingProjects.confirmingEditFlProject,
        markingFlProjectAsComplete: state.frontLoadingProjects.markingFlProjectAsComplete,
        frontloadingProgress: state.frontLoadingProjects.frontLoadingProgress,
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getToBeEdittedFlProjectData: (id: number) =>
            dispatch({ type: ActionTypes.GetToBeEdittedFlProject, id: id }),

        deletFlProject: (id: number, isCurrentFlProject: boolean) =>
            dispatch({ type: ActionTypes.DeletFlProject, id: id, isCurrentFlProject: isCurrentFlProject }),

        selectFlProjectForConsultant: (id: number) =>
            dispatch({ type: ActionTypes.SelectFlProjectForConsultant, id: id }),

        confirmEditFlProject: (flProject: EditFlProjectToUploadModel) =>
            dispatch({ type: ActionTypes.ConfirmEditFlProject, flProject: flProject }),

        resetEditConfirmationAttributes: () =>
            dispatch({ type: ActionTypes.ResetEditConfirmationAttributes }),

        resetFlToolToBeEdittedProject: () =>
            dispatch({ type: ActionTypes.SaveFlToolToBeEdittedProject, flToolToBeEdittedProject: null }),

        markFlProjectAsComplete: () =>
            dispatch({ type: ActionTypes.MarkFlProjectAsComplete })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontLoadingProjectSelection);
