import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { ActivityProfileAnalysisView, CancelActivityProfileAnalysis } from "../Survey/Models/ActivitySurveyData";
import { useState } from "react";
import { t } from "i18next";
import { RulesDescription, RulesRequire } from "../../../Admin/Rules/RulesValidation";

interface Iprops {
    profile: ActivityProfileAnalysisView;
    cancelAnalysis: (model: CancelActivityProfileAnalysis) => any;
}
const ResetSection = (props: Iprops) => {
    const [resetForm] = Form.useForm();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [shouldResetTasks, SetShouldResetTasks] = useState(false);
    
    return <>
    <Button
        type="primary" onClick={() => {
            setShowCancelModal(true);
            resetForm.setFieldValue("shouldResetTasks", false);
            SetShouldResetTasks(false);
            resetForm.setFieldValue("reason", '');
            resetForm.setFieldValue("newVersionId", null);
        }}>{`${t("Reset")}`}</Button>
    <Modal title={`${t("Reset")}`}
    cancelText={`${t("Cancel")}`}
        key={`modal_cancel_${props.profile.projectProfileId}`}
        getContainer={() => document.getElementById("app-layout")} open={showCancelModal} onOk={async () => {
            let validation = await resetForm.validateFields();
            console.log("Reset", validation);
            let values = resetForm.getFieldsValue();
            console.log("Reset", values);
            props.cancelAnalysis({
                reason: values["reason"],
                shouldResetTasks: values["shouldResetTasks"],
                profileId: props.profile.activityProfile.id,
                newVersionId: values["newVersionId"]
            });
            setShowCancelModal(false)
           
        }}
        onCancel={() => { setShowCancelModal(false) }}>
        <Form key={`modal_form_cancel_${props.profile.projectProfileId}`} form={resetForm} layout="vertical" >
            <Form.Item name={"reason"} label={`${t("Reason")}`} rules={[...RulesDescription({ name: t("Reason"), countChar: 1500 }), ...RulesRequire({ filedName: t("Reason") })]}>
                <Input.TextArea allowClear showCount placeholder={`${t("Type a reason...")}`} rows={4} maxLength={256} />
            </Form.Item>
            <Form.Item name={"newVersionId"} label={`${t("Please select a version to be used in the next round after reset")}`}>
                <Select
                    showSearch
                    key={`modal_form_select_cancel_${props.profile.projectProfileId}`} 
                    //placeholder={`${t("Select Version")}`}
                    optionFilterProp="children"
                    options={props.profile?.activityProfile?.versions ?? []}
                />
            </Form.Item>
            <Form.Item name={"shouldResetTasks"}>
                <Checkbox checked={shouldResetTasks} onChange={(val) => {
                    resetForm.setFieldValue("shouldResetTasks", val.target.checked);
                    SetShouldResetTasks(val.target.checked)
                }}>
                    {`${t("Should reset tasks?")}`}
                </Checkbox>
            </Form.Item>
        </Form>
    </Modal>
</>;
}
export default ResetSection;