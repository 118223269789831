import { IPotential } from "../../../../Models/EBIT/EstimateResult";
import { Popconfirm, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Tabs,
  Collapse,
  CollapsePanelProps,
  Form,
  Slider,
  InputNumber,
  Typography,
  Row,
  Col,
  Result,
} from "antd";
import { inputFormatter, inputParser, formatValue } from "../../../../Utils/helperFunctions";
import { t } from "i18next";
import i18n from "../../../../i18n";
const { Title } = Typography;
type IProps = {
  key: string;
  class: string;
  model: {};
  result: IPotential;
  title: string;
  type: string;
  handle_change: (type, value, name) => void;
};

function BasicPanel(props: IProps) {
  let data = Object.entries(props.model).map((item) => {
    return { name: item[0], value: item[1] };
  });
  function _toString(value: number, currency: string = "") {
    try {
      if (Number.isNaN(value)) {
        value = 0;
      }
      let valueNumber = value;// new Number(value);
      let tempVal = "";
      if (valueNumber && valueNumber != 0) {
        tempVal = valueNumber.toFixed(2)
      } else {
        tempVal = valueNumber.toFixed(3)
      }
      return `${t("localizedvalue", { count: parseFloat(tempVal) })}${currency}`;
    } catch (e) {
      console.log('_toString', value, e);
      return value;
    }
  }

  const renderWarning = (value: number) => {
    if (value < 0) {
      return (
        <Tooltip title={"Minimaler wert ist: 0 "}>
          <ExclamationCircleOutlined
            style={{ marginLeft: "10px", color: "red" }}
          />
        </Tooltip>
      );
    }
  };
  const renderPanelHeader = () => {
    return (
      <Row>
        <Col span={6}>
          <Title level={5}>{props.title}</Title>
        </Col>
        <Col span={4}>
          <label>{`${t("EBIT-Potenzial")}:`}</label>
        </Col>
        <Col span={2}>{renderWarning(props.result.Cost)}</Col>
        <Col span={6}>{`${t("Absolut")} = `}<label style={{ color: props.result.Cost < 0 ? "red" : "" }}>{formatValue(props.result.Cost) + '€'}</label></Col>
        {/* <Col span={3} style={{ color: props.result.Cost < 0 ? "red" : "" }}>
          <input
            disabled
            value={_toString(props.result.Cost, '€')}
          />
        </Col>
 */}
        <Col span={6}>{`${t("Prozentual")} = `}<label style={{ color: props.result.Cost < 0 ? "red" : "" }}>{formatValue(props.result.Rate) + '%'}</label></Col>
      </Row>
    );
  };
  const renderLabel = (item) => {
    return (
      <>
        <span>{item.value["Question"]}
          {item.value["Tooltip"] && <Tooltip title={item.value["Tooltip"]}>
            <ExclamationCircleOutlined
              style={{ marginLeft: "5px", color: "black" }}
            />
          </Tooltip>}
        </span>
      </>
    )
  }
  let view = data.map((item) => {
    const fieldType = props.model[item.name].AnswerType;
    if (typeof item.value !== "string") {
      if (item.value["headerTooltip"] !== undefined) {
        return <Form.Item key={item.name} style={{ marginBottom: "1%" }}>
          <label style={{ fontWeight: "bold" }}>
            <span>
              {item.value["title"]}
              <Tooltip title={item.value["headerTooltip"]}>
                <ExclamationCircleOutlined
                  style={{ marginLeft: "5px", color: "black", verticalAlign: "middle" }}
                />
              </Tooltip>
            </span>
          </label>

        </Form.Item>
      } else {
        return (
          <Form.Item label={renderLabel(item)}>
            {/* <Col span={21}>
              <label> */}

            {/* </label>
  
            </Col> */}

            {/* <Col span={3}> */}

            <InputNumber
              readOnly={item.name === 'completedProjectLastYear' || item.name === 'InnoSalesShare'}
              //maxLength={13}
              min={0}
              //max={fieldType == 2 ? 100 : 9999999999}
              formatter={value => inputFormatter(value, i18n.language)}
              parser={x => inputParser(x, i18n.language)}
              style={{ width: fieldType == 1 ? "22ch" : "22ch" }}
              onChange={(valuee) =>
                props.handle_change(props.type, valuee, item.name)
              }
              value={props.model[item.name].Answer}
              addonAfter={
                fieldType == 1
                  ? "€"
                  : fieldType == 2
                    ? "%"
                    : ""
              }
            />
            {/* </Col> */}
            {item.value["TooltipAnswer"] && <Tooltip title={item.value["TooltipAnswer"]}>
              <ExclamationCircleOutlined
                style={{ marginLeft: "10px", color: "black", verticalAlign: "middle" }}
              />
            </Tooltip>}
          </Form.Item>
        );
      }
    }

    else
      return (
        <Form.Item key={item.name} style={{ marginBottom: "1%" }}>
          <label style={{ fontWeight: "bold" }}>{item.value}</label>
        </Form.Item>
      );
  });
  return (
    <Collapse.Panel
      {...props}
      className={`estimate-custom-panel ${props.class}`}
      key={props.key}
      header={renderPanelHeader()}
    >
      <Form layout="vertical">{view}</Form>
    </Collapse.Panel>
  );
}
export default BasicPanel;