import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ILeverManagementItem, ILeverManagementItemWithKey } from '../../../Models/ILeversManagement';
import { ActionTypes } from '../../../Redux/actionsTypes';
import LeversMngTable from './LeversMngTable';
import "./index.scss";
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { Spin } from 'antd';

interface IProps {
    isLoading: boolean;
    allLevers: ILeverManagementItem[];
    functionTypes: any[];
    units: any[];
    collectionRoles: any[];
    getAllLeversForAdmin: () => any;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
}

const LeversMng = (props: IProps) => {
    useEffect(() => { props.getAllLeversForAdmin() }, []);
    const allLeverData: ILeverManagementItemWithKey[] = props.allLevers?.map(l => {
        return {
            key: `lever_${l.id}`,
            ...l
        }
    }) ?? [];
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <LeversMngTable
                    allLeversData={allLeverData}
                    isModalLoading={props.isModalLoading}
                    modalResponse={props.modalResponse}
                    functionTypes={props.functionTypes}
                    units={props.units}
                    collectionRoles={props.collectionRoles}
                />
            </div>
        </Spin>
    )
}

const mapStateToProps = (state: any) => {
    return {
        allLevers: state.leversForAdmin.leversForAdminResult?.levers,
        isLoading: state.leversForAdmin.isLeversForAdminLoading,
        isModalLoading: state.leversForAdmin.isModalLoading,
        modalResponse: state.leversForAdmin.modalResponse,
        functionTypes: state.generalInfo.profileResponse?.generalSettings?.functionTypeList,
        units: state.generalInfo.profileResponse?.generalSettings?.units ?? [],
        collectionRoles: state.generalInfo.profileResponse?.generalSettings?.collectionRoles,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllLeversForAdmin: () => dispatch({ type: ActionTypes.GET_LEVERS_FOR_ADMIN }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeversMng);
