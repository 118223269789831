import { ErrorActions } from "../../Models/Errors/ErrorActions";
import { ActionTypes } from "../actionsTypes";
import { IDprojectsState } from "./IGeneralState";

const initialState: IDprojectsState = {
    uploadingIncludeInLevers: false,
    fetchingDprojects: false,
    dprojectsFetchedSuccess: false,
    dprojects: [],
    uploadingDproject: false,
    addingDproject: false,
    comments: [],
    fetchingDprojectComments: true,
    uploadingDprojectComment: false,
    addingDprojectComment: false,
    updatingComment: false,
    deleteingComment: false,
    updatingDproject: false,
    dltingDproject: false,
    DprojectTypesFetchedSuccess: false,
    gettingDprojectTypes: false,
    types: [],
    dprojectProgress: 0,

    showErrorMessage: false,
    errorMessage: '',
    dprojectIdsIncludedInLevers: [],
    gettingIncludeInLevers: false,

    markingDProjectAnalysisAsComplete: false,
};

const DProjectsReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case ActionTypes.MarkingDProjectAnalysisAsComplete:
            return {
                ...state,
                markingDProjectAnalysisAsComplete: action.value
            };

        case ActionTypes.SortCommentsAssending:
            let comments = state.comments.sort((a, b) => a.id - b.id).filter(c => true);
            return {
                ...state,
                comments: comments
            };

        case ActionTypes.SortCommentsDescending:
            let _comments = state.comments.sort((a, b) => b.id - a.id).filter(c => true);
            return {
                ...state,
                comments: _comments
            };

        case ActionTypes.GettingIncludeInLevers:
            return {
                ...state,
                gettingIncludeInLevers: action.value
            }

        case ActionTypes.FetchingDprojectComments:
            return {
                ...state,
                fetchingDprojectComments: action.value
            };

        case ActionTypes.FetchingDprojects:
            return {
                ...state,
                fetchingDprojects: action.value
            }

        case ActionTypes.SaveDprojectsProgress:
            return {
                ...state,
                dprojectProgress: action.dprojectProgress
            }

        case ActionTypes.SAVEDPROJECTS:
            return {
                ...state,
                dprojects: [...action.dprojects],
                dprojectsFetchedSuccess: true,
                uploadingDproject: false,
                addingDproject: false,
                updatingDproject: false,
                dltingDproject: false
            }
        case ErrorActions.NOTFOUNDBMPROJECT:
            return {
                ...state,
                //dprojects: [],
                dprojectsFetchedSuccess: true,
                uploadingDproject: false,
                addingDproject: false,
                updatingDproject: false,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.SETSHOWERRORTOFALSE:
            //console.log('SETSHOWERRORTOFALSE in d-roject reducer');

            return {
                ...state,
                showErrorMessage: false,
            }
        case ErrorActions.SHOWERRMESSAGEDIRECTLY:
            return {
                ...state,
                //dprojects: [],
                dprojectsFetchedSuccess: true,
                uploadingDproject: false,
                addingDproject: false,
                updatingDproject: false,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.NOINTERNETCONNECTION:
            return {
                ...state,
                dprojectsFetchedSuccess: true,
                uploadingDproject: false,
                addingDproject: false,
                updatingDproject: false,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            };
        case ErrorActions.UPDP_NOINTERNET:
            return {
                ...state,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.UNKOWNERR:
            return {
                ...state,
                dprojectsFetchedSuccess: true,
                uploadingDproject: false,
                addingDproject: false,
                updatingDproject: false,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            };
        case ErrorActions.UPDP_UNKOWNERR:
            return {
                ...state,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.EDDP_NOINTERNET:
            return {
                ...state,
                updatingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.EDDP_UNKOWNERR:
            return {
                ...state,
                updatingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.UpDproject_SHOWERRMSGDIRECTLY:
            return {
                ...state,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            };
        case ErrorActions.EDDproject_SHOWERRMSGDIRECTLY:
            return {
                ...state,
                updatingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.INVALIDEDPPAYLOAD:
            return {
                ...state,
                updatingDproject: false,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.ErrorSaveInnovation:
            return {
                ...state,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }

        case ErrorActions.ErrorSaveCostOfDelay:
            return {
                ...state,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }

        case ErrorActions.ErrorSaveMC:
            return {
                ...state,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.ErrorSaveQC:
            return {
                ...state,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.ErrInsertDproject:
            return {
                ...state,
                uploadingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ActionTypes.SETDPROJECTS:
            return {
                ...state,
                dprojects: [...action.dprojects],
            }

        case ErrorActions.DPVALIDERR:
            return {
                ...state,
                uploadingDproject: false,
                updatingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.NOtFOUNDDPROJECT:
            return {
                ...state,
                updatingDproject: false,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.ERRORUPDATEDPROJECT:
            return {
                ...state,
                updatingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.DDP_NOINTERNET:
            return {
                ...state,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.DDP_UNKOWNERR:
            return {
                ...state,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.DDproject_SHOWERRMSGDIRECTLY:
            return {
                ...state,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.ERRORDLTDP:
            return {
                ...state,
                dltingDproject: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ActionTypes.UPLOADINGDPROJECT:
            return {
                ...state,
                uploadingDproject: action.value
            }
        case ActionTypes.SETADDINGDPROJECT:
            return {
                ...state,
                addingDproject: action.val
            }
        case ActionTypes.SAVEDPROJECTCOMMENTS:
            return {
                ...state,
                comments: [...action.dprojectComments],
                fetchingDprojectComments: false,
                uploadingDprojectComment: false,
                addingDprojectComment: false,
                updatingComment: false,
                deleteingComment: false
            }
        case ErrorActions.GETDPCOMMENTS_NOINTERNET:
            return {
                ...state,
                fetchingDprojectComments: false,
                uploadingDprojectComment: false,
                addingDprojectComment: false,
                updatingComment: false,
                deleteingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.GETDPCOMMENTS_UNKOWNERR:
            return {
                ...state,
                fetchingDprojectComments: false,
                uploadingDprojectComment: false,
                addingDprojectComment: false,
                updatingComment: false,
                deleteingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.GETDPCOMMENTS_NFDPROJECT:
            return {
                ...state,
                fetchingDprojectComments: false,
                uploadingDprojectComment: false,
                addingDprojectComment: false,
                updatingComment: false,
                deleteingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.GETDPCOMMENTS_SHOWERRMSGDIRECTLY:
            return {
                ...state,
                fetchingDprojectComments: false,
                uploadingDprojectComment: false,
                addingDprojectComment: false,
                updatingComment: false,
                deleteingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.UPDPCOMMENT_NOINTERNET:
            return {
                ...state,
                uploadingDprojectComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.UPDPCOMMENT_UNKOWNERR:
            return {
                ...state,
                uploadingDprojectComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.INVALIDDPCOMMENT:
            return {
                ...state,
                uploadingDprojectComment: false,
                updatingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.UpDpCOMMENT_SHOWERRMSGDIRECTLY:
            return {
                ...state,
                uploadingDprojectComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.EDDpCOMMENT_SHOWERRMSGDIRECTLY:
            return {
                ...state,
                updatingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.DPCOMMENTVALIDERR:
            return {
                ...state,
                uploadingDprojectComment: false,
                updatingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.EDDPCOMMENT_NOINTERNET:
            return {
                ...state,
                updatingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.EDDPCOMMENT_UNKOWNERR:
            return {
                ...state,
                updatingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.NotFoundComment:
            return {
                ...state,
                updatingComment: false,
                deleteingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.DELDPCOMMENT_NOINTERNET:
            return {
                ...state,
                deleteingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.DELDPCOMMENT_UNKOWNERR:
            return {
                ...state,
                deleteingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ErrorActions.DELDpCOMMENT_SHOWERRMSGDIRECTLY:
            return {
                ...state,
                deleteingComment: false,
                showErrorMessage: true,
                errorMessage: action.message
            }
        case ActionTypes.UPLOADINGDPROJECTCOMMENT:
            return {
                ...state,
                uploadingDprojectComment: action.value,
            }
        case ActionTypes.SETADDINGDPROJECTCOMMENT:
            return {
                ...state,
                addingDprojectComment: action.val
            }
        case ActionTypes.CANCELADDCOMMENT:
            return {
                ...state,
                addingDprojectComment: false,
                uploadingDprojectComment: false
            }

        case ActionTypes.UPDATINGCOMMENT:
            return {
                ...state,
                updatingComment: action.value
            }
        case ActionTypes.DELETEINGCOMMENT:
            return {
                ...state,
                deleteingComment: action.value
            }
        case ActionTypes.UPDATINGDPROJECT:
            return {
                ...state,
                updatingDproject: action.value
            }
        case ActionTypes.DLTINGDPROJECT:
            return {
                ...state,
                dltingDproject: action.value
            }
        case ActionTypes.GETINFTYPES:
            return {
                ...state,
                gettingDprojectTypes: action.value
            }
        case ActionTypes.DPROJECTTYPESFSUCCESS:
            return {
                ...state,
                types: [...action.dprojectsTypes],
                gettingDprojectTypes: false,
                DprojectTypesFetchedSuccess: true
            }
        case ActionTypes.SaveDprojectsIdsIncludedInLevers:
            return {
                ...state,
                dprojectIdsIncludedInLevers: action.dprojectIds
            }

        case ActionTypes.UploadingIncludeInLevers:
            return {
                ...state,
                uploadingIncludeInLevers: action.value
            }
    }
    return state;
};

export default DProjectsReducer;