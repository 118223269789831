import { Alert, Button, Form, Input, InputNumber, Select } from 'antd';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { FunctionTypes } from '../../../../Enums/FunctionTypes';
import { IQuestionItem } from '../../../../Models/ILeversManagement';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import TextEditorForRichText from '../../../../Components/TextEditorForRichText';
import { inputFormatter, inputParser } from '../../../../Utils/helperFunctions';
import i18n from '../../../../i18n';
import { RulesName, RulesRequire } from '../../Rules/RulesValidation';
import { t } from 'i18next';
import { log } from 'console';

interface IProps {
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    questions: any[];
    questionId: number;
    functionTypes: any[];
    units: any[];
    updateQuestion: (model: IQuestionItem) => any;

}

const QuestionsEditModal = (props: IProps) => {
    const question: IQuestionItem[] = props.questions?.filter(l => l.id === props.questionId).map(m => {
        return {
            id: m.id,
            nameEn: m.nameEn,
            nameDe: m.nameDe,
            goalEn: m.goalEn,
            goalDe: m.goalDe,
            explanationEn: m.explanationEn,
            explanationDe: m.explanationDe,
            inputMin: m.inputMin,
            inputMax: m.inputMax,
            unitId: m.unitId,
            functionTypeId: m.functionTypeId,
            minBorder: m.minBorder,
            maxBorder: m.maxBorder,
            bestPracticeValue: m.bestPracticeValue,
        }
    }) ?? [];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [richTextValues, setRichTextValues] = useState({
        goalEn: question[0]?.goalEn,
        goalDe: question[0]?.goalDe,
        explanationEn: question[0]?.explanationEn,
        explanationDe: question[0]?.explanationDe,
    })
    const [errorMessage, setErrorMessage] = useState('');
    const [form] = Form.useForm();
    console.log(Form.useWatch('functionTypeId', form));

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            let values = await form.getFieldsValue();
            if (values?.functionTypeId == FunctionTypes?.linear || values?.functionTypeId == FunctionTypes?.linear_bis) {
                if (values?.maxBorder !== values?.bestPracticeValue) {
                    throw new Error(`${t("when Function Type is Liner the max border should equal best practice value")}`)
                }
            }
            if (values?.maxBorder < values?.bestPracticeValue) {
                throw new Error(`${t("The max border must be greater than or equal to the best practice value")}`)
            }
            if (values?.minBorder > values?.bestPracticeValue) {
                throw new Error(`${t("The min border must be less than or equal to best practice value")}`)
            }
            await props.updateQuestion({
                id: question[0]?.id,
                nameEn: values?.nameEn,
                nameDe: values?.nameDe,
                goalEn: values?.goalEn ?? richTextValues.goalEn,
                goalDe: values?.goalDe ?? richTextValues.goalDe,
                explanationEn: values?.explanationEn ?? richTextValues.explanationEn,
                explanationDe: values?.explanationDe ?? richTextValues.explanationDe,
                inputMin: values?.inputMin,
                inputMax: values?.inputMax,
                unitId: values?.unitId,
                functionTypeId: values?.functionTypeId,
                minBorder: values?.minBorder,
                maxBorder: values?.maxBorder,
                bestPracticeValue: values?.bestPracticeValue,
            });
            setErrorMessage('');
        } catch (error) {
            setErrorMessage(error.message);
        }
    }
    console.log(props.functionTypes);


    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <ModalAdmin title={`${t("Edit")} ${t("Questions")}`} btnText={`${t("Edit")}`}
            width="1000px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}>
            <>
                <Form layout="vertical" form={form} className='lever_questions_form' initialValues={{ ...question[0] }}>
                    <Form.Item label={`${t("English Name")}`} name='nameEn'
                        rules={RulesName({ name: `${t("English Name")}`, countChar: 1500 })}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={`${t("German Name")}`} name='nameDe'
                        rules={RulesName({ name: `${t("German Name")}`, countChar: 1500 })}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={`${t("English Target")}`} name='goalEn' style={{ width: '47.5%', display: "inline-block", marginRight: "2.5%" }}>
                        <TextEditorForRichText
                            onChange={(value) =>
                                setRichTextValues({ ...richTextValues, goalEn: value })}
                            value={richTextValues?.goalEn}
                            placeholder={`${t("placeholder")}`}
                        />
                    </Form.Item>
                    <Form.Item label={`${t("German Target")}`} name='goalDe'
                        style={{ width: '47.5%', display: "inline-block", marginLeft: "2.5%" }}>
                        <TextEditorForRichText
                            onChange={(value) =>
                                setRichTextValues({ ...richTextValues, goalDe: value })}
                            value={richTextValues?.goalDe}
                            placeholder={`${t("placeholder")}`}
                        />
                    </Form.Item>
                    <Form.Item label={`${t("English Explanation")}`} name='explanationEn'
                        style={{ width: '47.5%', display: "inline-block", marginRight: "2.5%" }}>
                        <TextEditorForRichText
                            onChange={(value) =>
                                setRichTextValues({ ...richTextValues, explanationEn: value })}
                            value={richTextValues?.explanationEn}
                            placeholder={`${t("placeholder")}`}
                        />
                    </Form.Item>
                    <Form.Item label={`${t("German Explanation")}`} name='explanationDe'
                        style={{ width: '47.5%', display: "inline-block", marginLeft: "2.5%" }}>
                        <TextEditorForRichText
                            onChange={(value) =>
                                setRichTextValues({ ...richTextValues, explanationDe: value })}
                            value={richTextValues?.explanationDe}
                            placeholder={`${t("placeholder")}`}
                        />
                    </Form.Item>
                    <Form.Item label={`${t("Unit")}`} name='unitId' rules={RulesRequire({ filedName: `${t("Unit")}` })}
                        style={{ width: '47.5%', display: "inline-block", marginRight: "2.5%" }}>
                        <Select showSearch placeholder="Select Unit" optionFilterProp="children"
                            getPopupContainer={() => document.getElementById("app-layout")}
                        >
                            {props.units?.map((i: any) => <Select.Option value={i.value} key={i.value}>{i.label}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label={`${t("Function Type")}`} name='functionTypeId' rules={RulesRequire({ filedName: `${t("Function Type")}` })}
                        style={{ width: '47.5%', display: "inline-block", marginLeft: "2.5%" }}>
                        <Select showSearch placeholder="Select Unit" optionFilterProp="children"
                            getPopupContainer={() => document.getElementById("app-layout")}
                        >
                            {props.functionTypes?.map((i: any) => <Select.Option value={i.value} key={i.value}>{i.label}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label={`${t("input Min")}`} name='inputMin' rules={RulesRequire({ filedName: `${t("input Min")}` })}
                        style={{ width: '47.5%', display: "inline-block", marginRight: "2.5%" }}>
                        <InputNumber
                            formatter={(value) => inputFormatter(value, i18n.language)}
                            parser={(x) => inputParser(x, i18n.language)}
                            addonAfter="%" />
                    </Form.Item>
                    <Form.Item label={`${t("input Max")}`} name='inputMax' rules={RulesRequire({ filedName: `${t("input Max")}` })}
                        style={{ width: '47.5%', display: "inline-block", marginLeft: "2.5%" }}>
                        <InputNumber
                            formatter={(value) => inputFormatter(value, i18n.language)}
                            parser={(x) => inputParser(x, i18n.language)}
                            addonAfter="%" />
                    </Form.Item>

                    <Form.Item label={`${t("min Border")}`} name='minBorder' rules={RulesRequire({ filedName: `${t("min Border")}` })}
                        style={{ width: '47.5%', display: "inline-block", marginRight: "2.5%" }}>
                        <InputNumber
                            formatter={(value) => inputFormatter(value, i18n.language)}
                            parser={(x) => inputParser(x, i18n.language)}
                            addonAfter="%" />
                    </Form.Item>
                    <Form.Item label={`${t("max Border")}`} name='maxBorder' rules={RulesRequire({ filedName: `${t("max Border")}` })}
                        style={{ width: '47.5%', display: "inline-block", marginLeft: "2.5%" }}>
                        <InputNumber
                            formatter={(value) => inputFormatter(value, i18n.language)}
                            parser={(x) => inputParser(x, i18n.language)}
                            addonAfter="%" />
                    </Form.Item>
                    <Form.Item label={`${t("bestPractice value")}`} name='bestPracticeValue' rules={RulesRequire({ filedName: `${t("bestPractice value")}` })} >
                        <InputNumber
                            formatter={(value) => inputFormatter(value, i18n.language)}
                            parser={(x) => inputParser(x, i18n.language)}
                            addonAfter="%" />
                    </Form.Item>
                </Form>
                {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
            </>
        </ModalAdmin>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateQuestion: (model: IQuestionItem) => dispatch({ type: ActionTypes.UPDATE_QUESTION_FOR_ADMIN, model }),
    };
};

export default connect(null, mapDispatchToProps)(QuestionsEditModal);
