import { Button, Checkbox, Col, Form, Input, Row, Select, Space, Spin, Table, Tabs } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IEmployeePermissionDto, IPermissionDto, IPermissionsDto } from "../../../../Models/Permissions/PermissionsDto";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState, IPermissionsState } from "../../../../Redux/reducers/IGeneralState";

interface IProps {
    viewState: IPermissionsState;
    changePermissions: (model: IPermissionsDto) => any;
    changePermissionCurrentProjectId: (model: number) => any;
}

interface DataType {
    key: React.Key;
    user_id: number;
    hasPermission: boolean;
    user_name: string;
}
function SuperadminTable(props: IProps) {
    const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
    //const [projectId, setProjectId] = useState<number>(props.viewState?.viewResult?.bmProjects?.[0]?.id ?? 0);
    useEffect(() => {
        prepareColumns();
        //setProjectId(props.viewState?.currentProjectId ?? 0);
    }, [props.viewState?.currentProjectId]);
    useEffect(() => {
        prepareColumns();
    }, [props.viewState?.viewResult]);

    const onChecked = (userId: number, hasPermission: boolean) => {
        console.log("onchecked  userId, hasPermission", userId, hasPermission);
        let newResult = props.viewState?.viewResult;

        let oldOne: IEmployeePermissionDto = props.viewState?.viewResult?.allEmployees?.filter(x => x.userId == userId)?.[0];
        console.log("old Permission", oldOne);
        if (oldOne) {
            //let newPermission = newResult?.allEmployees?.filter(x => (x.userId == userId))?.[0];
            oldOne.isSuperAdmin = !hasPermission;
        }

        props.changePermissions(newResult);
    }
    const prepareColumns = () => {
        let _cols: ColumnsType<DataType> = [
            {
                title: '',
                dataIndex: 'user_name',
                key: 'name',
                width: 160,
                fixed: 'left',
                className: "prepare_col_name"
            },
            {
                title: 'Is Superuser',
                dataIndex: `hasSuperAdminPermission`,
                key: `hasSuperAdminPermission`,
                align: 'center',
                render: (text, record, index) => {
                    return <Checkbox key={`ch_superuser_${record.user_id}`} checked={record.hasPermission}
                        onClick={() => { onChecked(record.user_id, record.hasPermission); }} />;
                }
            }
        ];
        setColumns(_cols);
    }

    const data = [];
    props.viewState?.viewResult?.allEmployees?.map(user => {
        let newRow = {
            key: `row_${user.userId}`,
            user_id: user.userId,
            user_name: `${user.fullName}`,
            hasPermission: user.isSuperAdmin
        };
        data.push(newRow);
    });

    return <>
        <Table
            columns={columns}
            dataSource={data}
            bordered
            size="small"
            className="ia_table"
            pagination={false}
        />
    </>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        viewState: state.permissions,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        changePermissions: (model: IPermissionsDto) => dispatch({ type: ActionTypes.ChangePermissions, result: model }),
        changePermissionCurrentProjectId: (model: number) => dispatch({ type: ActionTypes.ChangeCurrentProjectPermissionId, result: model }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperadminTable);