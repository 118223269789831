import totalLeverIcon from "./../assets/Images/Sidebar/Total.svg"
import { MenuProps, Radio } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { ItemType, MenuItemType } from "antd/lib/menu/hooks/useItems";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { SettingOutlined } from "@ant-design/icons/lib/icons";
const appRoutersConsultant: MenuProps['items'] = [
  {
    key: '/start',
    label: `${t('Start')}`,
    icon: <HomeOutlined />
  },
  {
    key: '/dashboard',
    label: `${t('Dashboard')}`,
    icon: <SettingOutlined />
  },
  {
    key: '/profile',
    label: `${t('Profile')}`,
    icon: <UserOutlined />
  }
];

const appRoutersCustomer: MenuProps['items'] = [
  {
    key: '/projects',
    label: `${t('Dashboard')}`,
    icon: <HomeOutlined />
  },
  {
    key: '/levers',
    label: `${t('Levers')}`,
    icon: <img src={totalLeverIcon} width={15} />
  }
];



const appAdminRouters: MenuProps["items"] = [
  {
    key: '/admin',
    label: `${t('Start')}`,
    /* icon: <HomeOutlined /> */
  },
  {
    key: "#item01",
    label: `${t("Authentications")}`,
    /* icon: <HomeOutlined />, */
    children: [
      {
        key: "/admin/users-management",
        label: `${t("Users Management")}`,
        /* icon: <HomeOutlined />, */
      },
      {
        key: "/admin/permissions",
        label: `${t("Permissions")}`,
        /*  icon: <HomeOutlined />, */
      },
      {
        key: "/admin/profile",
        label: `${t("My Profile")}`,
        /*  icon: <HomeOutlined />, */
      },
    ],
  },
  {
    key: "/admin/customer",
    label: `${t("Customers")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/bm-project",
    label: `${t("BM Projects")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/disciplines",
    label: `${t("Disciplines")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/industries",
    label: `${t("Industries")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "#item02",
    label: `${t("FrontLoading")}`,
    /* icon: <HomeOutlined />, */
    children: [
      {
        key: "/admin/front-loading-template",
        label: `${t("Templates")}`,
        /* icon: <HomeOutlined />, */
      },
      {
        key: "/admin/front-loading-curves",
        label: `${t("Best Practices")}`,
        /* icon: <HomeOutlined />, */
      },
      {
        key: "/admin/front-loading-departments",
        label: `${t("Departments")}`,
        /* icon: <HomeOutlined />, */
      },
    ],
  },
  {
    key: "/admin/activity",
    label: `${t("Activity")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/help-page",
    label: `${t("Help Pages")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/audit-log",
    label: `${t("Audit Trails")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/lever-management",
    label: `${t("Levers Questions")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/self-assessment",
    label: `${t("Self Assessment")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/error-page",
    label: `${t("Errors")}`,
    /* icon: <HomeOutlined />, */
  },
  {
    key: "/admin/easy-access-lever",
    label: `${t("Easy Access Levers")}`
  }

];
const appPagesInformationList = [
  {
    key: "/admin/users-management",
    label: `${t("Users Management")}`,
    code: "users-mng"
  },
  {
    key: "/admin/permissions",
    label: `${t("Permissions")}`,
    code: "permissions-mng"
  },
  // {
  //   key: "/profile",
  //   label: `${t("My Profile")}`,
  //   code: "profile-mng"
  // },
  // {
  //   key: "/admin/profile",
  //   label: `${t("My Profile")}`,
  //   code: "profile-mng"
  // },
  {
    key: "/admin/customer",
    label: `${t("Customers")}`,
    code: "customers-mng"
  },
  {
    key: "/admin/customer/users",
    label: `${t("Customer")} ${t("Users")}`,
    code: "customers-users-mng"
  },
  {
    key: "/admin/customer/department",
    label: `${t("Customer")} ${t("Departments")}`,
    code: "customers-department-mng"
  },
  // {
  //   key: "/admin",
  //   label: "",
  //   code: "welcome"
  // },
  {
    key: "/admin/bm-project",
    label: `${t("BM Projects")}`,
    code: "bmproject-mng"
  },
  {
    key: "/admin/customer/bm-project",
    label: `${t("Customer")} ${t("BM Project")}`,
    code: "customer-bmproject-mng"
  },
  {
    key: "/admin/disciplines",
    label: `${t("Disciplines")}`,
    code: "disciplines-mng"
  },
  {
    key: "/admin/industries",
    label: `${t("Industries")}`,
    code: "industries-mng"
  },
  {
    key: "/admin/industries/segments",
    label: `${t("Industry")} ${t("Segments")}`,
    code: "industry-segement-mng"
  },
  {
    key: "/admin/front-loading-template",
    label: `${t("FrontLoading")}-${t("Templates")}`,
    code: "frontloading-template-mng"
  },
  {
    key: "/admin/front-loading-curves",
    label: `${t("FrontLoading")}-${t("Best Practices")}`,
    code: "frontloading-bp-mng"
  },
  {
    key: "/admin/front-loading-departments",
    label: `${t("FrontLoading")}-${t("Departments")}`,
    code: "frontloading-department-mng"
  },
  {
    key: "/admin/help-page",
    label: `${t("Help Pages")}`,
    code: "help-pages-mng"
  },
  {
    key: "/admin/audit-log",
    label: `${t("Audit Trails")}`,
    code: "audit-trial-mng"
  },
  {
    key: "/admin/lever-management",
    label: `${t("Levers Questions")}`,
    code: "levers-mng"
  },
  {
    key: "/admin/self-assessment",
    label: `${t("Self Assessment")}`,
    code: "self-assessment-mng"
  },
  {
    key: "/admin/activity",
    label: `${t("Activity Analysis")}`,
    code: "activity-mng"
  },
  {
    key: "/admin/error-page",
    label: `${t("Errors")}`,
    code: "errors-mng"
  },
  {
    key: "/admin/easy-access-lever",
    label: `${t("Easy Access Levers")}`,
    code: "easy-access-lever"
  }
];
const convertMenuItemToLink = (item) => {
  return {
    key: item.key,
    label: (<NavLink to={`${item.key}`}>{(item as MenuItemType).label}</NavLink>
    )
  };
}

const appAdminRoutersLinks: MenuProps["items"] = appAdminRouters.map(c => {
  if ((c as any).children) {
    return {
      key: c.key,
      label: `${(c as MenuItemType).label}`,
      children: (c as any).children.map(cc => { return convertMenuItemToLink(cc) })
    };
  } else {
    return convertMenuItemToLink(c);
  }
})
export { appRoutersConsultant };
export { appRoutersCustomer };
export { appAdminRouters };
export { appPagesInformationList }
export { appAdminRoutersLinks }