import { Button, Col, Layout, Menu, MenuProps, Popover, Space } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import userAvatar from "./../assets/Images/user_image.png";
import closeIcon from "./../assets/Images/Sidebar/close.svg";
import openIcon from "./../assets/Images/Sidebar/open.svg";
import logoSidebar from "./../assets/Images/Sidebar/logo.svg";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
//import BmProject from '../../Models/BmProject';
import { useTranslation } from "react-i18next";
import { IEbitToolState, IGeneralState } from "../Redux/reducers/IGeneralState";
import { BmProject, ProfileResponse } from "../Models/Responses/ProfileResponse";
import { BasicRole } from "../Enums/BasicRoles";
import Utils from "../Utils/Utils";
import AppAvatar from "../Components/AppAvatar";
import Waterfallv2 from "../Scenes/Tools/EBITTool-V2/BasicCharts/Waterfall-V2";
import StackedRowsChart from "../Scenes/Tools/EBITTool-V2/BasicCharts/StackedRowsChart";
import { appRoutersConsultant, appRoutersCustomer } from "../Router/router.config";
import { ActionTypes } from "../Redux/actionsTypes";
import { DownloadOutlined } from "@ant-design/icons";
import { DownloadChartAsImage } from "../Utils/helperFunctions";
import { ISubLeverOnEbit } from "../Models/Levers/Items";

const { Sider } = Layout;
export interface ISiderMenuProps {
  collapsed: boolean;
  onCollapse: any;
  history: any;
  ebitResult: IEbitToolState;
  profileResponse: ProfileResponse;
  currentProject: BmProject;
  getEbitEvaluation: (projectID: number) => any;
  leversWithEbit: ISubLeverOnEbit[];
  leversWithEbitChartLoading: boolean;

  getSubleversWithEbit: () => any;
}

const SiderMenu = (props: ISiderMenuProps) => {
  const { t } = useTranslation();
  const { collapsed, history, onCollapse } = props;
  const location = useLocation();
  const navigate = useNavigate();
  //const currentRoute = Utils.getRoute(history.location.pathname);
  const _isCustomer = props.profileResponse?.roles?.includes(BasicRole.Customer);

  useEffect(() => {
    if (props.profileResponse?.currentProject) {
      if (props.profileResponse?.roles?.includes(BasicRole.Consultant) || props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)) {
        props.getEbitEvaluation(props.profileResponse.currentProject.id);

        props.getSubleversWithEbit();
      }
    }
  }, [props.profileResponse?.currentProject, props.ebitResult.dProjectForEbit, props.ebitResult.rndInfoResponse]);

  const data =
    props.ebitResult?.evaluationInfo?.data?.map((p) => {
      return { Name: p.name, Cost: p.cost, Rate: p.rate };
    }) ?? [];

  const onClick: MenuProps["onClick"] = (e) => {
    //console.log('click ', e);
    history.push(e.key);
    navigate(e.key);
  };

  return (
    <Sider
      trigger={null}
      className={"sidebar sidebar-right"}
      width={210}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Space direction="vertical" align="center" size={(collapsed) ? 20 : 20}>
        <div className="footer-menu">
          <Space direction={(collapsed ? "vertical" : "horizontal")} align="center" size={(collapsed) ? 0 : 70} >

            {!collapsed ? (
              <div className="sidebar-logo">
                <img alt="" src={logoSidebar} width={70} />
              </div>
            ) : null}
            <img
              src={(collapsed) ? openIcon : closeIcon}
              width={25}
              onClick={() => props.onCollapse(!collapsed)}
              alt={(collapsed) ? "closed sidebar icon" : "opened sidebar icon"}
              className="sidebar-collapse"
            />
          </Space>
        </div>
        <Col style={{ textAlign: "center" }}>
          {(props.profileResponse) ?
            <AppAvatar
              size={(collapsed) ? 40 : 64}
              key="appAvatarUser"
              avatarImage={props.profileResponse?.image === '' ? userAvatar : props.profileResponse?.image}
              empName={`${props.profileResponse?.firstName} ${props.profileResponse?.lastName}`}
              jobPosition={props.profileResponse?.rolesNames?.join("/")}
              showEmpName={!collapsed}
              showPosition={!collapsed}
            />
            : null}
        </Col>
      </Space>

      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        theme="dark"
        onClick={onClick}
        items={(_isCustomer) ? appRoutersCustomer : appRoutersConsultant}
        inlineIndent={10}
      />
      {!collapsed && !_isCustomer && props.currentProject && props.currentProject != null ? (
        <div className="sidebar-chart">
          {props.ebitResult?.evaluationInfo ? (
            <Popover getPopupContainer={() => document.getElementById('app-layout')}
              className="popover-ebit-potential"
              placement="rightBottom"
              key="chart_popover-1"
              content={<>
                <div className="downloadall-div">
                  <Button className='chart-download-Btn'
                    icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                    onClick={() => DownloadChartAsImage(`${t("EBIT Potenzial")}`, document.getElementById('watterfall_id_1'))}
                    type="default"
                    title={`${t("Download chart")}`}
                    size='small' />
                </div>
                <div id="watterfall_id_1">
                  <Waterfallv2
                    key={`rate_waterfall_chart_popover_percent`}
                    chartTitle={t("EBIT Potenzial")}
                    type="Rate"
                    data={data}
                    scale={1}
                    showTooltip={true}
                  />

                </div>
              </>}
              trigger="hover"
            >
              <div className="popover-content">
                <Waterfallv2
                  key={`rate_waterfall_chart_percent`}
                  chartTitle={t("EBIT Potenzial")}
                  // totalSale={props.ebitResult?.evaluationInfo?.ebitSales ?? 0}
                  type="Rate"
                  data={data}
                  scale={1}
                  height={150}
                  width={200}
                  showTooltip={false}
                  isMinimized={true}
                />
                {/* <StackedChart height={150} tooltip={false} /> */}
              </div>
            </Popover>
          ) : (
            <></>
          )}
          <div className="sidebar-chart">
            {props.ebitResult?.evaluationInfo ? (
              <Popover getPopupContainer={() => document.getElementById('app-layout')}
                className="popover-ebit-potential"
                placement="rightBottom"
                key="chart_popover-2"
                content={<>
                  <div className="downloadall-div">
                    <Button className='chart-download-Btn'
                      icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                      onClick={() => DownloadChartAsImage(`${t("EBIT Potenzial")}`, document.getElementById('watterfall_id_2'))}
                      type="default"
                      title={`${t("Download chart")}`}
                      size='small' />
                  </div>
                  <div id="watterfall_id_2">
                    <Waterfallv2
                      key={`rate_waterfall_chart_popover_million`}
                      chartTitle={t("EBIT Potenzial")}
                      type="Cost"
                      data={data}
                      scale={1}
                      showTooltip={true}
                    />
                  </div>
                </>}
                trigger="hover"
              >
                <div className="popover-content">
                  <Waterfallv2
                    key={`rate_waterfall_chart_million`}
                    chartTitle={t("EBIT Potenzial")}
                    type="Cost"
                    data={data}
                    scale={1}
                    height={150}
                    width={200}
                    showTooltip={false}
                    isMinimized={true}
                  />
                </div>
              </Popover>
            ) : (
              <></>
            )}
          </div>
          <div className="sidebar-chart">
            {props.leversWithEbitChartLoading ? <></> :
              <Popover getPopupContainer={() => document.getElementById('app-layout')}
                className="popover-ebit-potential"
                placement="rightBottom"
                key="chart_popover-stacked-row"
                content={
                  <>
                    <div className="downloadall-div">
                      <Button className='chart-download-Btn'
                        icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                        onClick={() => DownloadChartAsImage(`${t("EBIT Wirksamkeit der Teilstellhebel")}`, document.getElementById('stack_row_chart_id'))}
                        type="default"
                        title={`${t("Download chart")}`}
                        size='small' />
                    </div>
                    <div id="stack_row_chart_id">
                      <StackedRowsChart
                        key={"stack-chart-row-big"}
                        leversWithEbit={props.leversWithEbit}
                        isMinimized={false}
                        showTooltip={true}
                        isSubLever={true}
                      />
                    </div>
                  </>}
                trigger="hover"
              >
                <div className="popover-content">
                  <StackedRowsChart
                    key={"stack-chart-row-small"}
                    leversWithEbit={props.leversWithEbit}
                    height={150}
                    width={200}
                    isMinimized={true}
                    showTooltip={false}
                    isSubLever={true}
                  />
                </div>
              </Popover>
            }
          </div>
        </div>
      ) : null}
    </Sider>
  );
};

const mapStateToProps = (state: IGeneralState) => {
  return {
    ebitResult: state.ebit,
    profileResponse: state.generalInfo.profileResponse,
    currentProject: state.generalInfo?.profileResponse?.currentProject,
    leversWithEbit: state.leverTool?.subLeverOnEbit,
    leversWithEbitChartLoading: state.leverTool?.leversWithEbitChartLoading,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getEbitEvaluation: (projectID: number) =>
      dispatch({ type: ActionTypes.GetEbitEvaluation, projectID: projectID }),

    getSubleversWithEbit: () => dispatch({ type: ActionTypes.GetLeversWithEbitChart }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiderMenu);
