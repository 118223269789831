import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { ToastMessages } from "../Enums/Messages";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import { ICustomerNewBmProjectForUser, ICustomerPersonToUser, ICustomerUserItem, ICustomerUserUpdate } from "../Models/ICustomerUsers";

export default class CustomerUsersService {
  static async GetCustomerUsers(customerId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getCustomerUsers, {
        params: {
          customerId: customerId,
        },
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        console.log("result test", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async CreateCustomerUser(model: ICustomerUserItem): Promise<ICommonResponse> {
    console.log("CreateCustomerUser", model);
    return await asupapi
      .post(endPoints.createCustomerUser, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async UpdateCustomerUser(model: ICustomerUserUpdate): Promise<ICommonResponse> {
    console.log("UpdateCustomerUser", model);
    return await asupapi
      .post(endPoints.updateCustomerUser, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  // make customer person to user
  static async MakeCustomerPersonToUser(model: ICustomerPersonToUser): Promise<ICommonResponse> {
    console.log("makeCustomerPersonToUser", model);
    return await asupapi
      .post(endPoints.makeCustomerPersonToUser, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async deletePerson(PersonId: number): Promise<ICommonResponse> {
    console.log("deletePerson");
    return await asupapi
      .get(endPoints.deletePerson, {
        params: {
          PersonId
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  //BmProject Setting
  static async assignNewBmProjectForUser(model: ICustomerNewBmProjectForUser): Promise<ICommonResponse> {
    console.log("assignNewBmProjectForUser", model);
    return await asupapi
      .post(endPoints.assignNewBmProjectForUser, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async deleteBmProjectForUser(id: number): Promise<ICommonResponse> {
    console.log("deleteBmProjectForUser, ", id);
    return await asupapi
      .get(endPoints.deleteBmProjectForUSer, {
        params: {
          id
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async ImportUserTable(model: FormData): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.ImportCustomerUser, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log('error1 ', error.response);
          if (error.response.data) {
            console.log('error0 ', error?.response?.data);
            let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError
              }
            } as ICommonResponse
          }
        } else if (error.request) {
          console.log('error2 ', error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError
            }
          } as ICommonResponse
        } else if (error.message) {
          console.log('error3 ', error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError
          }
        } as ICommonResponse
      });
  }
}
