import { IDepartmentByCustomerItem } from "../../Models/IDepartment";
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import DepartmentByCustomerService from "../../Services/DepartmentByCustomerService";
import DepartmentByBmProjectService from "../../Services/DepartmentByBmProjectService";
;

function* OnGetDepartmentsByCustomer(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
        let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
        if (!_result.isError) {
            let result = _result.result as IDepartmentByCustomerItem[];
            yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetCustomerDepartments: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onCreateDepartmentByCustomer(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield DepartmentByBmProjectService.CreateDepartmentByBmProject(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
            let result = _result.result as IDepartmentByCustomerItem[];
            yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateDepartmentByCustomer(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield DepartmentByBmProjectService.UpdateDepartmentByBmProject(
            action.model
        );

        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
            let result = _result.result as IDepartmentByCustomerItem[];
            yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteDepartmentByCustomer(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: true });
        let resultDelete: ICommonResponse = yield DepartmentByBmProjectService.DeleteDepartmentByBmProject(
            action.departmentId
        );
        if (!resultDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
            let _result: ICommonResponse = yield DepartmentByCustomerService.GetDepartmentsByCustomer(action.customerId);
            let result = _result.result as IDepartmentByCustomerItem[];
            yield put({ type: ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* watchCreateDepartmentByCustomer() {
    yield takeEvery(ActionTypes.CREATE_DEPARTMENT_BY_BM_CUSTOMER, onCreateDepartmentByCustomer);
}
function* watchUpdateDepartmentByCustomer() {
    yield takeEvery(ActionTypes.UPDATE_DEPARTMENT_BY_BM_CUSTOMER, onUpdateDepartmentByCustomer);
}
function* watchDeleteDepartmentByCustomer() {
    yield takeEvery(ActionTypes.DELETE_DEPARTMENT_BY_BM_CUSTOMER, onDeleteDepartmentByCustomer);
}
function* watchGetDepartmentsByCustomer() {
    yield takeEvery(ActionTypes.GET_DEPARTMENTS_BY_CUSTOMER, OnGetDepartmentsByCustomer);
}
export default function* DepartmentsByCustomerSagas() {
    yield all([
        watchGetDepartmentsByCustomer(),
        watchCreateDepartmentByCustomer(),
        watchUpdateDepartmentByCustomer(),
        watchDeleteDepartmentByCustomer()
    ]);
}
