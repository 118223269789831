import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import { IIndustryDisciplineBestPracticeItem } from "../../Models/IIndustryDisciplineBestPractice";
import IndustryBestPracticeDisciplineService from "../../Services/IndustryDisciplineBPService";


function* OnGetAllIndustryBestPracticeDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: true });
        let _result: ICommonResponse = yield IndustryBestPracticeDisciplineService.GetIndustryBestPracticeDiscipline(action.industryId);
        if (!_result.isError) {
            let result = _result.result as IIndustryDisciplineBestPracticeItem[];
            yield put({ type: ActionTypes.SAVE_INDUSTRY_DISCIPLINE_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetIndustryDisciplineBestPRactice: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onUpdateIndustryBestPracticeDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield IndustryBestPracticeDisciplineService.UpdateDisciplinesBP(action.model);
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let resultCus: ICommonResponse = yield IndustryBestPracticeDisciplineService.GetIndustryBestPracticeDiscipline(action.industryId);
            let result = resultCus.result as IIndustryDisciplineBestPracticeItem[];
            yield put({ type: ActionTypes.SAVE_INDUSTRY_DISCIPLINE_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onClearIndustryBestPracticeDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: true });
        let resultClear: ICommonResponse = yield IndustryBestPracticeDisciplineService.ClearDisciplinesBP(action.industryId);
        if (!resultClear.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultClear  });
            let resultCus: ICommonResponse = yield IndustryBestPracticeDisciplineService.GetIndustryBestPracticeDiscipline(action.industryId);
            let result = resultCus.result as IIndustryDisciplineBestPracticeItem[];
            yield put({ type: ActionTypes.SAVE_INDUSTRY_DISCIPLINE_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultClear });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_DISCIPLINE_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onClearQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* watchGetAllIndustryBestPracticeDiscipline() {
    yield takeEvery(ActionTypes.GET_INDUSTRY_DISCIPLINE_BEST_PRACTICES, OnGetAllIndustryBestPracticeDiscipline);
}
function* watchUpdateIndustryBestPracticeDiscipline() {
    yield takeEvery(ActionTypes.UPDATE_INDUSTRY_DISCIPLINE_BEST_PRACTICE, onUpdateIndustryBestPracticeDiscipline);
}
function* watchClearIndustryBestPracticeDiscipline() {
    yield takeEvery(ActionTypes.CLEAR_INDUSTRY_DISCIPLINE_BEST_PRACTICE, onClearIndustryBestPracticeDiscipline);
}
export default function* industryDisciplineBPSagas() {
    yield all([
        watchGetAllIndustryBestPracticeDiscipline(),
        watchUpdateIndustryBestPracticeDiscipline(),
        watchClearIndustryBestPracticeDiscipline()
    ]);
}
