import { Checkbox, Form, Input, InputNumber, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { connect } from "react-redux";
import ColorPickerInput from '../../../../../Components/ColorPickerInput';
import { IGeneralState } from "../../../../../Redux/reducers/IGeneralState";
import { IDepartementStatusInInterface } from '../../../../../Models/InterfaceAnalysis/IAItems';
import { ActionTypes } from '../../../../../Redux/actionsTypes';
import { t } from 'i18next';
import { RulesName, RulesRequire } from '../../../../Admin/Rules/RulesValidation';
import { IDepartmentItem } from '../../../../../Models/IDepartment';
import { ICommonResponse } from '../../../../../Models/Responses/ICommonResponse';
import { deleteFirstLetter } from '../../../../../Utils/helperFunctions';
import AdminOperation from '../../../../../Components/AdminOperation/AdminOperation';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
interface IProps {
    changeDepartmentStatusInInterface: (model: IDepartementStatusInInterface) => any;
    enableEdit: boolean;
    bmProjectId: number;
    data: DataType[];
    updateDepartment: (model: IDepartmentItem, bmProjectId: number) => any;
    deleteDepartmentById: (departmentId: number, bmProjectId: number) => any;
}

interface DataType {
    key: string;
    name: string;
    id: number;
    foreColor: string;
    backColor: string;
    isInclude: boolean;
    index: number;
    viewOrder: number;
    isOriginal: boolean;
}
function DepartmentsTable(props: IProps) {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<IDepartmentItem>();
    const [editingKey, setEditingKey] = useState('');

    const columns: ColumnsType<DataType> = [
        {
            key: "dep_order",
            title: `${t("View Order")}`,
            dataIndex: 'viewOrder',
            width: '10%',
            render: (text: any, record: any) => {
                if (props.enableEdit) {
                    if (editingKey === record.key) {
                        return (
                            <Form.Item name='viewOrder'
                                rules={RulesRequire({ filedName: `${t("View Order")}` })}>
                                <InputNumber
                                    min={0}
                                    onChange={(value: number) =>
                                        setFormValues({ ...formValues, viewOrder: value })
                                    }

                                />
                            </Form.Item>
                        )
                    } else {
                        return <p>{text}</p>
                    }
                } else {
                    return <p>{text}</p>
                }
            },
        },
        {
            key: "dep_name",
            title: `${t("Name")}`,
            dataIndex: 'name',
            width: '20%',
            render: (text: any, record: any) => {
                if (props.enableEdit) {
                    if (editingKey === record.key) {
                        return (
                            <Form.Item name='name'
                                rules={RulesName({ name: `${t("Name")}`, countChar: 128 })}>
                                <Input
                                    autoFocus
                                    onChange={(e) =>
                                        setFormValues({ ...formValues, name: (e.target.value).trimStart() })
                                    }

                                />
                            </Form.Item>
                        )
                    } else {
                        return <p>{text}</p>
                    }
                } else {
                    return <p>{text}</p>
                }
            },
        },
        {
            key: "dep_isOriginal",
            title: `${t("Is Original")}`,
            dataIndex: 'isOriginal',
            width: '20%',
            render: (text: any, record: any) => {
                if (props.enableEdit) {
                    if (editingKey === record.key) {
                        return (
                            <Form.Item name="isOriginal">
                                <Checkbox checked={record.isOriginal} onChange={(e: CheckboxChangeEvent) => setFormValues({ ...formValues, isOriginal: e.target.checked })} />
                            </Form.Item>
                        )
                    } else {
                        return <Checkbox checked={record.isOriginal}  />
                    }
                } else {
                    return <Checkbox checked={record.isOriginal}  />
                }
            },
        },
        {
            title: `${t('Include in analysis')}`,
            dataIndex: 'isInclude',
            render: (text, record, index) => {
                return <Checkbox key={`disc_config_list_ck_${record.id}`} checked={record.isInclude}
                    onChange={() => {
                        if (props.enableEdit) {
                            props.changeDepartmentStatusInInterface({
                                departmentId: record.id,
                                include: !record.isInclude
                            })
                        }
                    }} />;
            }
        },
        {
            title: `${t('Background Color')}`,
            dataIndex: 'backColor',
            render: (text, record, index) => {
                if (props.enableEdit) {
                    if (editingKey === record.key) {
                        return <ColorPickerInput key={`disc_config_list_backcolor_${record.id}`}
                            colorValue={formValues?.backgroundColor ?? record?.backColor} onChangeComplete={(color: any, event: any) => {
                                setFormValues({ ...formValues, backgroundColor: color.hex })
                            }} />;
                    }
                    else {
                        return <div style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '5px',
                            background: text,
                            // marginLeft: "auto",
                            // marginRight: "auto",
                            border: "0.5px solid #ccc"
                        }}>
                        </div >
                    }
                } else {
                    return <div style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        background: text,
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        border: "0.5px solid #ccc"
                    }}>
                    </div >
                }
            }
        },
        {
            title: `${t('Text Color')}`,
            dataIndex: 'foreColor',
            render: (text, record, index) => {
                if (props.enableEdit) {
                    if (editingKey === record.key) {
                        return <ColorPickerInput key={`disc_config_list_forecolor_${record.id}`}
                            colorValue={formValues?.foreColor ?? record?.foreColor}
                            onChangeComplete={(color: any, event: any) => {
                                setFormValues({ ...formValues, foreColor: color.hex })
                            }} />;
                    } else {
                        return <div style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '5px',
                            background: text,
                            // marginLeft: "auto",
                            // marginRight: "auto",
                            border: "0.5px solid #ccc"
                        }}>
                        </div >
                    }
                } else {
                    return <div style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        background: text,
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        border: "0.5px solid #ccc"
                    }}>
                    </div >
                }
            }
        }
    ];

    if (props.enableEdit) {
        columns.push({
            title: `${t("Actions")}`,
            dataIndex: '',
            width: "10%",
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                return <AdminOperation
                    deleteId={record.id}
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                    onDelete={deleteDepartment}
                    onSave={save}
                />
            }
        });
    }


    const isEditing = (record: IDepartmentItem) => record.key === editingKey;
    const edit = (record: Partial<IDepartmentItem> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
        setFormValues({ ...formValues, backgroundColor: null, foreColor: null })

    };
    const save = async (key: any) => {
        try {
            await form.validateFields()
            const index = props.data?.findIndex(item => item.key === key);
            if (index > -1) {
                const item = props.data[index];
                const departmentUpdate: IDepartmentItem = {
                    id: item.id,
                    viewOrder: formValues?.viewOrder ?? item.viewOrder,
                    name: formValues?.name ?? item.name,
                    bmProjectId: props.bmProjectId,
                    foreColor: formValues.foreColor ? deleteFirstLetter(formValues.foreColor) : deleteFirstLetter(item.foreColor),
                    backgroundColor: formValues.backgroundColor ? deleteFirstLetter(formValues.backgroundColor) : deleteFirstLetter(item.backColor),
                    isOriginal: formValues.isOriginal
                };
                await props.updateDepartment(departmentUpdate, props.bmProjectId);
                setEditingKey('');
                setFormValues({ ...formValues, backgroundColor: null, foreColor: null })
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }

    }
    const deleteDepartment = async (id: any) => {
        try {
            await props.deleteDepartmentById(id, props.bmProjectId)
        } catch (errInfo) {
            console.log('deleted Failed:', errInfo);
        }
    }
    return (
        <Form form={form}>
            <Table
                pagination={false}
                dataSource={props.data}
                columns={columns}
                rowKey="index"
                scroll={{ y: "calc(100vh - 310px)" }}
                className='ia_config_table'
            />
        </Form>
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        bmProjectId: state.generalInfo.profileResponse?.currentProject?.id
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        changeDepartmentStatusInInterface: (model: IDepartementStatusInInterface) => dispatch({ type: ActionTypes.ChangeDepartmentStatusInInterface, model: model }),
        updateDepartment: (model: IDepartmentItem, bmProjectId: number) => dispatch({ type: ActionTypes.UPDATE_DEPARTMENT_BY_BM_PROJECT, model, bmProjectId }),
        deleteDepartmentById: (departmentId: number, bmProjectId: number) => dispatch({ type: ActionTypes.DELETE_DEPARTMENT_BY_BM_PROJECT, departmentId, bmProjectId }),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsTable);