import {
  Collapse,
  Form,
  Typography,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { Dproject } from "../../EBITTool-V2/Models/CostTypesResponse";
import { formatValue } from "../../../../Utils/helperFunctions";
import { ILeverQuestion } from "../../../../Models/Levers/Items";
import { ILeverSubquestion } from "../../../../Models/Requests/SaveLevers";
import { IDProject } from "../../../../Models/IDProject";
import { ActionTypes } from "../../../../Redux/actionsTypes";
const { Panel } = Collapse;
const { Title } = Typography;
const { Option } = Select;

interface DProjctInLeverRowSaleValue {
  key: string;
  project: number;//d-project id
  projectyear: number;//year of complation
  plannedSales: number;
  actualSales: number;
  NewProduct?: string;
}
interface DProjctInLeverRowRateValue {
  key: string;
  project: number;//d-project id
  projectyear: number;//year of complation
  plannedRate: number;
  NewProduct?: string;
}
interface IProps {
  question: ILeverQuestion;
  subquestions: ILeverSubquestion;
  dprojects: IDProject[];
}

function SubQuestionTableReadonly(props: IProps) {
  const { t } = useTranslation();

  const dataColCODSale: ColumnsType = [
    {
      title: (
        <p>
          {`${t("Products")}`}
        </p>
      ),
      width: '30%',
      dataIndex: "product",
      fixed: "left", // fix element
      render: (text: any, record: DProjctInLeverRowSaleValue, index) => {
        return (
          props.dprojects.find(
            (c) => c.id == record?.project
          )?.name ?? ""
        );
      },
    },
    {
      title: (
        <p>
             {`${t("Year")}`}
        </p>
      ),
      width: '20%',
      dataIndex: "projectyear",
      render: (text: any, record: DProjctInLeverRowSaleValue, index) => {
        if (record?.key == "sum") return <></>;
        return props.dprojects.find(
          (c) => c.id == record?.project
        )?.finishingYear ?? "";
      },
    },
    {
      title: (
        <p>
          {`${t("Plan")}`}
        </p>
      ),
      width: '25%',
      dataIndex: "plannedSales",
      render: (text: any, record: DProjctInLeverRowSaleValue, index) => {
        return formatValue(record?.plannedSales);
      },
    },
    {
      title: (
        <p>
          {`IST`}
        </p>
      ),
      width: '25%',
      dataIndex: "actualSales",
      render: (text: any, record: DProjctInLeverRowSaleValue, index) => {
        return formatValue(record?.actualSales);
      },
    }
  ];

  const dataColCODRate: ColumnsType = [
    {
      title: (
        <p>
          {`${t("Products")}`}
        </p>
      ),
      width: '30%',
      dataIndex: "product",
      fixed: "left", // fix element
      render: (text: any, record: DProjctInLeverRowRateValue, index) => {
        return (
          props.dprojects.find(
            (c) => c.id == record?.project
          )?.name ?? ""
        );
      },
    },
    {
      title: (
        <p>
           {`${t("Year")}`}
        </p>
      ),
      width: '20%',
      dataIndex: "projectyear",
      render: (text: any, record: DProjctInLeverRowRateValue, index) => {
        if (record?.key == "sum") return <></>;
        return (
          props.dprojects.find(
            (c) => c.id == record?.project
          )?.finishingYear ?? ""
        );
      },
    },
    {
      title: (
        <p>
          {props.subquestions.headers?.[1]?.displayname}
        </p>
      ),
      width: '25%',
      dataIndex: "plannedRate",
      render: (text: any, record: DProjctInLeverRowRateValue, index) => {
        return `${formatValue(record?.plannedRate)}${props.subquestions.headers?.[1]?.unit}`;
      },
    }
  ];

  const dataRowCODSale = (props.subquestions.columnsNumber == 3) ? props.subquestions.sales.map((row) => {
    return {
      key: `qk_row_${row.dprojectId}`,
      actualSales: row.actualSales,
      plannedSales: row.plannedSales,
      project: row.dprojectId,
      projectyear: row.dprojectCompletionYear,
      NewProduct: "",
    } as DProjctInLeverRowSaleValue;
  }) ?? [] : [];
  const dataRowCODRate = (props.subquestions.columnsNumber == 2) ? props.subquestions.rates.map((row) => {
    return {
      key: `qk_row_${row.dprojectId}`,
      plannedRate: row.rate,
      project: row.dprojectId,
      projectyear: row.dprojectCompletionYear,
      NewProduct: "",
    } as DProjctInLeverRowRateValue;
  }) ?? [] : [];
  dataRowCODSale.push(
    {
      key: `sum`,
      actualSales: dataRowCODSale.reduce((a, v) => a = a + v.actualSales, 0),
      plannedSales: dataRowCODSale.reduce((a, v) => a = a + v.plannedSales, 0),
      project: 0,
      projectyear: 0,
      NewProduct: "",
    } as DProjctInLeverRowSaleValue
  );
  dataRowCODRate.push({
    key: `sum`,
    plannedRate: dataRowCODRate.reduce((a, v) => a = a + v.plannedRate, 0) / dataRowCODRate.length,
    project: 0,
    projectyear: 0,
    NewProduct: "",
  } as DProjctInLeverRowRateValue);
 

  return (
    <div className="editable-table-dproject-lever" id="editable-table-dproject-lever">
      <Table
        key={"cod_table"}
        bordered={false}
        dataSource={(props.subquestions.columnsNumber == 3) ? dataRowCODSale : dataRowCODRate}
        columns={(props.subquestions.columnsNumber == 3) ? dataColCODSale : dataColCODRate}
        scroll={{ y: 500 }}
        rowClassName={() => "editable-row"}
        pagination={false}
        className={"subquestion-table-readonly"}
      />
    </div>
  );
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    dprojects: state.dprojects.dprojects,// state.generalInfo?.profileResponse?.currentProject?.dprojects,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return { 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubQuestionTableReadonly);
