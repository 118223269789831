import { Badge, Button, Col, Form, Input, InputNumber, List, Comment, Row, Space, Tag, Switch, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { t } from "i18next";
import { ActivityResponseReviewDto, ActivityVoteReviewDto, Answer, AnswersOnTask } from "../Survey/Models/ActivitySurveyData";
import { RulesName, RulesRequire } from "../../../Admin/Rules/RulesValidation";
import { inputFormatter, inputParser } from "../../../../Utils/helperFunctions";
import i18n from "../../../../i18n";
import { DeleteOutlined } from "@ant-design/icons";

interface IProps {
    //vote: AnswersOnTask;
    answer: Answer;
    onReview: (model: ActivityResponseReviewDto) => any;
    onRemove: () => any;
}
const ActivityAnswerForm = (props: IProps) => {
    const [form] = Form.useForm();
    //const [isReject, setIsReject] = useState(true);
    const onRemove = () => {
        try {
            props.onRemove();
        }
        catch (err) {
            console.log("OnRemove error: ", err);
        }
    }
    const OnHandle = async (isAccept: boolean) => {
        try {
            await form.validateFields();
            let fields = form.getFieldsValue();
            console.log("OnHandle", fields);
            let model: ActivityResponseReviewDto = {
                answerId: props.answer.answerId,
                isApproved: isAccept,
                votes: []
            };
            props.answer.answersOnTasks?.map(c => {
                let percentage = fields[`percentage_${c.answerTaskId}`];
                let comment = fields[`field_review_comment_${c.answerTaskId}`];
                let isApprove = fields[`isapproved_${c.answerTaskId}`];
                model.votes.push({
                    answerTaskId: c.answerTaskId,
                    comment: comment,
                    isApproved: isApprove,
                    percentage: percentage
                });
            });

            props.onReview(model);
        }
        catch (err) {
            console.log("Exclude error: ", err);
        }
    }

    return (
        <Form form={form} >
            <List
                itemLayout="vertical"
                size="small"
                header={<Row>
                    <Popconfirm title={`${t("Sure to Delete")}`}
                        onConfirm={() => onRemove()}
                        getPopupContainer={() => document.getElementById("app-layout")}
                        cancelText={`${t("Cancel")}`}
                    >
                        <Button type="link">
                            <DeleteOutlined style={{ fontSize: " 20px", cursor: "pointer", color: "red" }} />
                            {`${t("Delete")}`}
                        </Button>
                    </Popconfirm>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                        {(props.answer?.answersOnTasks?.filter(c => c.review != null)?.length > 0) ?
                            <Space align="center" direction="horizontal" style={{ width: "fit-content" }}>
                                {(props.answer?.answersOnTasks.filter(c => c.review?.isApproved)?.length > 0) ?
                                    <Tag color="green">{`${t("Approved")}`}</Tag> : <Tag color="magenta">{`${t("Excluded")}`}</Tag>}
                                <span>{`${t("Total Hours")}:`}</span>
                            </Space>
                            : <Space align="center" direction="horizontal" style={{ width: "fit-content" }}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        OnHandle(true)
                                    }}
                                >{`Save`}
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        OnHandle(false)
                                    }}
                                >{`Reject`}
                                </Button>
                                <span>{`${t("Total Hours")}:`}</span>
                            </Space>
                        }
                    </Col>
                    {/* <Col  xs={12} sm={12} md={12} lg={12} xl={2} className="col-data-review-activity">
                        <span>{`${t("Total Hours")}:`}</span>
                    </Col> */}
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                        <InputNumber disabled={true} value={props.answer.hoursPerWeek} addonBefore={`${t("Hours per week")}`} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                        <InputNumber disabled={true} value={props.answer.daysPerMonth} addonBefore={`${t("Days per month")}`} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                        <InputNumber disabled={true} value={props.answer.daysPerYear} addonBefore={`${t("Days per year")}`} />
                    </Col>
                </Row>}
                dataSource={props.answer.answersOnTasks}
                renderItem={(item, index) => (
                    <List.Item key={`${item.answerTaskId}`} >
                        <Comment content={<>{`${t("Task")}: ${item.taskName}`}</>} />
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                                <InputNumber
                                    min={0}
                                    disabled={true}
                                    formatter={(value) => inputFormatter(value, i18n.language)}
                                    parser={(x) => inputParser(x, i18n.language)}
                                    value={item.hoursPerWeek} addonBefore={`${t("Hours per week")}`} />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                                <InputNumber
                                    min={0}
                                    disabled={true}
                                    formatter={(value) => inputFormatter(value, i18n.language)}
                                    parser={(x) => inputParser(x, i18n.language)}
                                    value={item.daysPerMonth} addonBefore={`${t("Days per month")}`} />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                                <InputNumber
                                    min={0}
                                    disabled={true}
                                    formatter={(value) => inputFormatter(value, i18n.language)}
                                    parser={(x) => inputParser(x, i18n.language)}
                                    value={item.daysPerYear} addonBefore={`${t("Days per year")}`} />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                                <InputNumber
                                    min={0}
                                    disabled={true}
                                    formatter={(value) => inputFormatter(value, i18n.language)}
                                    parser={(x) => inputParser(x, i18n.language)}
                                    value={item.percentage} addonBefore={`${t("Percentage")}`} addonAfter={"%"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="col-data-review-activity">
                                <Form.Item
                                    name={`percentage_${item.answerTaskId}`}
                                    initialValue={item.review?.isApproved ? item?.review?.percentage : item.percentage}
                                    rules={RulesRequire({ filedName: `${t("Percentage")}` })}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => inputFormatter(value, i18n.language)}
                                        parser={(x) => inputParser(x, i18n.language)}
                                        addonBefore={`${t("Percentage")}`} addonAfter={"%"} />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={8} className="col-data-review-activity">
                                <Form.Item
                                    name={`field_review_comment_${item.answerTaskId}`}
                                    initialValue={item.review?.comment}
                                >
                                    <TextArea rows={1} autoSize={true} placeholder={`${t("Write your comment")}`} maxLength={250} />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                                <Form.Item
                                    name={`isapproved_${item.answerTaskId}`}
                                    initialValue={(item.review == null) ? true : item.review.isApproved}
                                >
                                    <Switch disabled={item.review != null}
                                        checkedChildren="Approved" unCheckedChildren="Rejected" defaultChecked={(item.review == null) ? true : item.review.isApproved} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </List.Item>
                )
                }
            />
        </Form>
    );
};

export default ActivityAnswerForm;