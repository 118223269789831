import { ActionTypes } from "../actionsTypes";
import { IActivityProfileVersionForAdminState } from "./IGeneralState";

const initialState: IActivityProfileVersionForAdminState = {
    isActivityProfileVersionForAdminLoading: false,
    ActivityProfileVersionsForAdminResult: null,
    activityProfileVersionDetailsForAdminResult: null,
    isModalLoading: false,
    modalResponse: null,

}
const ActivityProfileVersionsReducer = (state = initialState, action: any): IActivityProfileVersionForAdminState => {
    switch (action.type) {
        case ActionTypes.CHANGE_PROFILE_VERSIONS_LOADER:
            return {
                ...state,
                isActivityProfileVersionForAdminLoading: action.isLoading,
            };
        case ActionTypes.SAVE_PROFILE_VERSIONS:
            return {
                ...state,
                ActivityProfileVersionsForAdminResult: action.result
            };
        case ActionTypes.SAVE_PROFILE_VERSION_Details:
                return {
                    ...state,
                    activityProfileVersionDetailsForAdminResult: action.result
                };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            };
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            };
        default:
            break;
    }
    return state;
}
export default ActivityProfileVersionsReducer;