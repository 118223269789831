import { ActionTypes } from "../actionsTypes";
import { IDepartmentByBmProjectState } from "./IGeneralState";


const initialState: IDepartmentByBmProjectState = {
  isDepartmentLoading: false,
  departmentsResult: null,
  isModalLoading: false,
  modalResponse: null,
};
const DepartmentsByBmProjectReducer = (state = initialState, action: any): IDepartmentByBmProjectState => {
  switch (action.type) {
    case ActionTypes.CHANGE_DEPARTMENTS_BY_BM_PROJECT_LOADER:
      return {
        ...state,
        isDepartmentLoading: action.isLoading,
      };
    case ActionTypes.SAVE_DEPARTMENTS_BY_BM_PROJECT:
      return {
        ...state,
       departmentsResult: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      }
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      }
    default:
      break;
  }
  return state;
};
export default DepartmentsByBmProjectReducer;
