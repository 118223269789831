import { Avatar, Badge, Col, Dropdown, Layout, Menu, Row, Typography, Input, Space, Tooltip, Button, Drawer, Spin, Empty } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CompressOutlined, ExpandOutlined, LogoutOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import Utils from "../../Utils/Utils";
import { connect } from "react-redux";
import { ActionTypes } from "../../Redux/actionsTypes";
import { IGeneralState, IHelpPageState } from "../../Redux/reducers/IGeneralState";
import parse from 'html-react-parser';
import { ProfileResponse } from "../../Models/Responses/ProfileResponse";
import { BasicRole } from "../../Enums/BasicRoles";
import { useState } from "react";
import { ThemeSwitcher } from "../../Components/Switcher";
import { t } from "i18next";
import LanguageSwitcher from "../../Components/Switcher/LanguageSwitcher";
import 'react-quill/dist/quill.snow.css';
export interface IHeaderProps {
  history: any;
  profileResponse: ProfileResponse;
  changeFullScreen: any;
  isfullScreen: boolean;
  onClickFullScreen: any;
  onClickExitFullScreen: any;
  helpPageState: IHelpPageState;
  getCurrentHelpPage: (pageName: string) => any;
}
const { Title } = Typography;
const userDropdownMenu = (
  <Menu>
    <Menu.Item key="2">
      <Link to="/logout">
        <LogoutOutlined />
        {/* <span> {L('Logout')}</span> */}
        <span> {'Logout'}</span>
      </Link>
    </Menu.Item>
  </Menu>
);

const AsupHeader = (props: IHeaderProps) => {
  const location = useLocation();
  const { Search } = Input;
  const onSearch = (value: string) => console.log(value);
  let navigate = useNavigate();
  const logout = () => {
    console.log('logout');
    //TODO: clear
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login')
  }
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
    //console.log("showDrawer ", location.pathname);
    props.getCurrentHelpPage(props.helpPageState?.currentPageCode);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <Row className={'header-container'}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
        <Space align="center" size={5}  >
          <Title level={2}>{Utils.getPageTitleByPath(location.pathname)}</Title>
        </Space>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} >
        <Space align="center" size={5} style={{ flexFlow: 'row-reverse' , float:"right"}}>
          <Tooltip placement="bottom" title={`${t('Logout')}`}>
            <Button icon={<LogoutOutlined />} onClick={() => { logout(); }} type="ghost"></Button>
          </Tooltip>
          {(props.profileResponse?.roles?.includes(BasicRole.Consultant) || props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)) ?
            <Tooltip placement="bottom" title={`${t("Consultant")}`}>
              <Link to={"/dashboard"} >
                <Button icon={<SettingOutlined />} type="ghost" />
              </Link>
            </Tooltip> : null
          }
          <Tooltip placement="bottom" title={`${t("General Help")}`}>
            <Button icon={<QuestionCircleOutlined />} onClick={showDrawer} type="ghost"></Button>
          </Tooltip>
          {props.isfullScreen ? <Tooltip placement="bottom" title={`${t("Exit Full Screen")}`}>
            <Button icon={<CompressOutlined />} onClick={() => {
              props.onClickExitFullScreen();
              props.changeFullScreen(false)
            }} type="ghost"></Button>
          </Tooltip> :
            <Tooltip placement="bottom" title={`${t("Full Screen")}`}>
              <Button icon={<ExpandOutlined />} onClick={() => {
                props.onClickFullScreen();
                props.changeFullScreen(true)
              }} type="ghost"></Button>
            </Tooltip>
          }
          <ThemeSwitcher />
          <LanguageSwitcher />
        </Space>
      </Col>

      <Drawer getContainer={() => document.getElementById('app-layout')} key={`drawer_header`} title={`${t("Quick Help")}`} placement="right" onClose={onClose} open={visible}>
        <Spin spinning={props.helpPageState?.isCurrentHelpPageLoading}>
          <div className="parent-image ql-editor">
          {(props.helpPageState?.currentHelpPage) ?
            parse(props.helpPageState?.currentHelpPage) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`${t("No Content")}`} />}
         </div> </Spin>
      </Drawer>
    </Row>
  );
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    isfullScreen: state.generalInfo.isFullScreen,
    profileResponse: state.generalInfo.profileResponse,
    helpPageState: state.helpPagesForAdmin
  }
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    changeFullScreen: (state: boolean) => dispatch({ type: ActionTypes.ChangeFullScreen, state: state }),
    getCurrentHelpPage: (pageName: string) => dispatch({ type: ActionTypes.GetHelpPage, model: pageName })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AsupHeader);  
