
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import UserManagementService from "../../Services/UserManagementService";
import { IUserManagement } from "../../Models/IUserEmployee";



function* OnGetAllUsers(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: true });
        let _result: ICommonResponse = yield UserManagementService.GetAllUsers();
        if (!_result.isError) {
            let result = _result.result as IUserManagement[];
            yield put({ type: ActionTypes.SAVE_USERS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGet Users: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnCreateUser(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield UserManagementService.CreateUser(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate});
            let _result: ICommonResponse = yield UserManagementService.GetAllUsers();
            let result = _result.result as IUserManagement[];
            yield put({ type: ActionTypes.SAVE_USERS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateUser(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield UserManagementService.UpdateUser(action.model);

        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate});
            let resultCus: ICommonResponse = yield UserManagementService.GetAllUsers();
            let result = resultCus.result as IUserManagement[];
            yield put({ type: ActionTypes.SAVE_USERS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onActiveUser(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultActive: ICommonResponse = yield UserManagementService.ActiveUser(action.userId);
        if (!resultActive.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultActive});
            let _result: ICommonResponse = yield UserManagementService.GetAllUsers();
            let result = _result.result as IUserManagement[];
            yield put({ type: ActionTypes.SAVE_USERS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultActive });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultActive });
        }
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onActive: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeactivateUser(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let  resultDeactivate: ICommonResponse = yield UserManagementService.DeactivateUser(action.userId);
        if (! resultDeactivate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDeactivate});
            let _result: ICommonResponse = yield UserManagementService.GetAllUsers();
            let result = _result.result as IUserManagement[];
            yield put({ type: ActionTypes.SAVE_USERS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultDeactivate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result:resultDeactivate });
        }
        yield put({ type: ActionTypes.CHANGE_USERS_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDeactivate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}



function* watchGetAllUsers() {
    yield takeEvery(ActionTypes.GET_ALL_USERS, OnGetAllUsers);
}
function* watchCreateUser() {
    yield takeEvery(ActionTypes.CREATE_USER, OnCreateUser);
}
function* watchUpdateUser() {
    yield takeEvery(ActionTypes.UPDATE_USER, onUpdateUser)
}

function* watchActiveUser() {
    yield takeEvery(ActionTypes.ACTIVE_USER, onActiveUser)
}
function* watchDeactivateUser() {
    yield takeEvery(ActionTypes.DEACTIVATE_USER, onDeactivateUser)
}

export default function* UserManagementSagas() {
    yield all([
        watchGetAllUsers(),
        watchCreateUser(),
        watchUpdateUser(),
        watchActiveUser(),
        watchDeactivateUser()
    ]);
}
