import { all, put, takeEvery } from "redux-saga/effects";
import { ToastMessages } from "../../Enums/Messages";
import { CurrentFlProject, IFlToolToBeEdittedProject, IFrontLoadingToolProject, IFrontLoadingToolTemplate } from "../../Models/IFrontLoadingTool";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import FlProjectDetails from "../../Scenes/Tools/FrontLoading/FrontLoadingTabs/InputTab/Models/FlProjectDetails"; 
import PhaseValidDates from "../../Scenes/Tools/FrontLoading/FrontLoadingTabs/InputTab/Models/PhaseValidDates";
import BestpracticeCurveData from "../../Scenes/Tools/FrontLoading/FrontLoadingTabs/OverViewProjectsTab/Models/BestpracticeCurveData";
import { RTDCurveData } from "../../Scenes/Tools/FrontLoading/FrontLoadingTabs/RTDCurveTab/Models/RTDCurveData";
import FrontLoadingToolServices from "../../Services/FrontLoadingToolServices";
import { ActionTypes } from "../actionsTypes";

function* onGetFlProjects(action: any) {
    try {
        yield put({ type: ActionTypes.GettingFlProjects, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.GetFlProjects();
        if (!_result.isError) {
            let result = _result.result as IFrontLoadingToolProject[];
            yield put({ type: ActionTypes.SaveFlProjects, projects: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingFlProjects, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GettingFlProjects, value: false });
    }
}

function* onSaveFlProject(action: any) {
    //console.log('onSaveFlProject: ', action);
    try {
        yield put({ type: ActionTypes.SaveingFlProject, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.SaveFlProject(action.values);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.GetFlProjects });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.SaveingFlProject, value: false });
        yield put({ type: ActionTypes.ShowAddFlProjectForm, value: false });

        yield put({ type: ActionTypes.GetAboutCurrentFlProject });
        yield put({ type: ActionTypes.GetFlProjects });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.SaveingFlProject, value: false });
    }
}

function* onGetFlTemplates(action: any) {
    try {
        yield put({ type: ActionTypes.GetingFlTemplates, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.GetFlTemplates();
        if (!_result.isError) {
            let result = _result.result as IFrontLoadingToolTemplate[];
            yield put({ type: ActionTypes.SaveFlTemplates, templates: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GetingFlTemplates, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GetingFlTemplates, value: false });
    }
}

function* onGetToBeEdittedFlProject(action: any) {
    try {
        yield put({ type: ActionTypes.GettingToBeEdittedFlProject, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.GetToBeEdittedFlProject(action.id);
        if (!_result.isError) {                    
            let result = _result.result as IFlToolToBeEdittedProject;
            yield put({ type: ActionTypes.SaveFlToolToBeEdittedProject, flToolToBeEdittedProject: result });
 
            yield put({ type: ActionTypes.GetAboutCurrentFlProject });
            yield put({ type: ActionTypes.GetFlProjects });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingToBeEdittedFlProject, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GettingToBeEdittedFlProject, value: false });
    }
}

function* onChangeConfirmationMsg(action: any){
    yield put({ type: ActionTypes.ShowConfirmationMsg, value: action.value });
}

function* onEditFlProject(action: any) {
    try {
        yield put({ type: ActionTypes.EdittingFlProject, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.EditFlProject(action.flProject); 
        if (!_result.isError) {
            let result = _result.message as string; 
            if (result !== "") { 
                yield put({ type: ActionTypes.SetCommonResponse, result: _result });
                yield put({ type: ActionTypes.GetAboutCurrentFlProject });
                yield put({ type: ActionTypes.SaveFlToolToBeEdittedProject, flToolToBeEdittedProject: null });
                yield put({ type: ActionTypes.GetFlProjects });
            } else {
                yield put({ type: ActionTypes.SaveToBeEdittedFlProject, flProject: action.flProject });
                yield put({ type: ActionTypes.ShowConfirmationMsg, value: true });
            }
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.EdittingFlProject, value: false });
        }
        yield put({ type: ActionTypes.EdittingFlProject, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.EdittingFlProject, value: false });
    }

}

function* onConfirmEditFlProject(action: any) {
    try {
        yield put({ type: ActionTypes.ConfirmingEditFlProject, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.EditFlProject(action.flProject, true);
        //console.log("onConfirmEditFlProject _result: ", _result);

        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.GetAboutCurrentFlProject });
            yield put({ type: ActionTypes.SaveFlToolToBeEdittedProject, flToolToBeEdittedProject: null });
            yield put({ type: ActionTypes.GetFlProjects });
            yield put({ type: ActionTypes.ResetEditConfirmationAttributes });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ConfirmingEditFlProject, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.ConfirmingEditFlProject, value: false });
    }
}

function* onDeletFlProject(action: any) {
    try {
        yield put({ type: ActionTypes.DeletingFlProject, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.DeletFlProject(action.id);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            if (action.isCurrentFlProject) {
                yield put({ type: ActionTypes.UpdateCurrentFlProjectId, value: null });
                yield put({ type: ActionTypes.SaveCurrentFlProject, currentFlProject: null });
            }
            yield put({ type: ActionTypes.GetFlProjects });
            yield
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.DeletingFlProject, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.DeletingFlProject, value: false });
    }
}

function* onSelectFlProjectForConsultant(action: any) {
    try {
        yield put({ type: ActionTypes.SelectingFlProjectForConsultant, value: true });
        localStorage.setItem("frontloading_id", action.id);
        let _result: ICommonResponse = yield FrontLoadingToolServices.selectFlProjectForConsultant(action.id);
        if (!_result.isError) {
            let result = _result.result as number;
            console.log("returned flproject id for consultant: ", result);
            yield put({ type: ActionTypes.UpdateCurrentFlProjectId, value: result });
            yield put({ type: ActionTypes.GetAboutCurrentFlProject });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.SelectingFlProjectForConsultant, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.SelectingFlProjectForConsultant, value: false });
    }
}

function* onGetFlProjectDetails(action: any) {
    try {
        yield put({ type: ActionTypes.GettingFlProjectDetails, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.getFlProjectDetails(action.id);
        if (!_result.isError) {
            let result = _result.result as FlProjectDetails;
            yield put({ type: ActionTypes.SaveFlProjectDetails, details: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingFlProjectDetails, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GettingFlProjectDetails, value: false });
    }
}

function* onUploadPhase(action: any) {
    try {
        yield put({ type: ActionTypes.UploadingPhase, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.uploadPhase(action.phase);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.GetFlProjectDetails, id: action.phase.flProjectId });
            yield put({ type: ActionTypes.GetAboutCurrentFlProject })
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.UploadingPhase, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.UploadingPhase, value: false });
    }
}

function* onUploadMilestone(action: any) {
    try {
        yield put({ type: ActionTypes.UploadingMilestone, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.uploadMilestone(action.milestone);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.GetFlProjectDetails, id: action.flProjectId });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.UploadingMilestone, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.UploadingMilestone, value: false });
    }
}

function* onGetPhaseValidDates(action: any) {
    try {
        yield put({ type: ActionTypes.GettingPhaseValidDates, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.getPhaseValidDates(action.phaseId);
        if (!_result.isError) {
            let result = _result.result as PhaseValidDates;
            yield put({ type: ActionTypes.SavePhaseValidDates, phaseValidDates: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingPhaseValidDates, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GettingPhaseValidDates, value: false });
    }
}

function* onGetRTDCurveData(action: any) {
    try {
        yield put({ type: ActionTypes.GettingRTDCurveData, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.GetRTDCurveData(action.flProjectId);
        if (!_result.isError) {
            let result = _result.result as RTDCurveData;
            yield put({ type: ActionTypes.SaveRTDCurveData, RTDCurveData: result, isCurrentFlProject: action.isCurrentFlProject });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingRTDCurveData, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GettingRTDCurveData, value: false });
    }
}

function* onGetBestPracticesCurves(action: any) {
    try {
        yield put({ type: ActionTypes.GettingBestPracticesCurves, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.GetBestPracticesCurves();
        if (!_result.isError) {
            let result = _result.result as BestpracticeCurveData[];
            console.log("BestpracticeCurveData: ", result);
            yield put({ type: ActionTypes.SaveBestpracticeCurveData, BestpracticeCurveData: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingBestPracticesCurves, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GettingBestPracticesCurves, value: false });
    }
}

function* onGetAboutCurrentFlProject(action: any) {
    try {
        yield put({ type: ActionTypes.GettingAboutCurrentFlProject, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.GetAboutCurrentFlProject();
        if (!_result.isError) {
            let result = _result.result as CurrentFlProject;
            //console.log("AboutCurrentFlProject: ", result);
            yield put({ type: ActionTypes.SaveCurrentFlProject, currentFlProject: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingAboutCurrentFlProject, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GettingAboutCurrentFlProject, value: false });
    }
}

function* onGetFrontloadingPrgress(action: any) {
    try {
        //yield put({ type: ActionTypes.GettingAboutCurrentFlProject, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.GetFrontloadingPrgress();
        if (!_result.isError) {
            let result = _result.result as number;
            console.log("AboutCurrentFlProject: ", result);
            yield put({ type: ActionTypes.SaveFrontloadingPrgress, progressValue: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        //yield put({ type: ActionTypes.GettingAboutCurrentFlProject, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        //yield put({ type: ActionTypes.GettingAboutCurrentFlProject, value: false });
    }
}

function* onMarkFlProjectAsComplete(action: any) {
    try {
        yield put({ type: ActionTypes.MarkingFlProjectAsComplete, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.MarkFlProjectAsComplete();
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.GetFrontloadingPrgress });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.MarkingFlProjectAsComplete, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.MarkingFlProjectAsComplete, value: false });
    }
}

function* onDeleteMilestone(action: any) {
    try {
        yield put({ type: ActionTypes.DeletingMilestone, value: true });
        let _result: ICommonResponse = yield FrontLoadingToolServices.DeleteMilestone(action.id);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.GetFlProjectDetails, id: action.flProjectId });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.DeletingMilestone, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.DeletingMilestone, value: false });
    }
}

function* OnFrontloadingExportToExcel(action: any) {
    try {
        yield FrontLoadingToolServices.ExportToExcel();
    } catch (error) {
        console.error("ERR in onGet: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* watchGetFlProjects() {
    yield takeEvery(ActionTypes.GetFlProjects, onGetFlProjects);
}

function* watchSaveFlProjects() {
    yield takeEvery(ActionTypes.SaveFlProject, onSaveFlProject);
}

function* watchGetFlTemplates() {
    yield takeEvery(ActionTypes.GetFlTemplates, onGetFlTemplates);
}

function* watchGetToBeEdittedFlProject() {
    yield takeEvery(ActionTypes.GetToBeEdittedFlProject, onGetToBeEdittedFlProject);
}

function* watchEditFlProject() {
    yield takeEvery(ActionTypes.EditFlProject, onEditFlProject);
}

function* watchConfirmEditFlProject() {
    yield takeEvery(ActionTypes.ConfirmEditFlProject, onConfirmEditFlProject);
}

function* watchDeletFlProject() {
    yield takeEvery(ActionTypes.DeletFlProject, onDeletFlProject);
}

function* watchSelectFlProjectForConsultant() {
    yield takeEvery(ActionTypes.SelectFlProjectForConsultant, onSelectFlProjectForConsultant);
}

function* watchGetFlProjectDetails() {
    yield takeEvery(ActionTypes.GetFlProjectDetails, onGetFlProjectDetails);
}

function* watchUploadPhase() {
    yield takeEvery(ActionTypes.UploadPhase, onUploadPhase);
}

function* watchUploadMilestone() {
    yield takeEvery(ActionTypes.UploadMilestone, onUploadMilestone);
}

function* watchGetPhaseValidDates() {
    yield takeEvery(ActionTypes.GetPhaseValidDates, onGetPhaseValidDates);
}

function* watchGetRTDCurveData() {
    yield takeEvery(ActionTypes.GetRTDCurveData, onGetRTDCurveData);
}

function* watchGetBestPracticesCurves() {
    yield takeEvery(ActionTypes.GetBestPracticesCurves, onGetBestPracticesCurves);
}

function* watchGetAboutCurrentFlProject() {
    yield takeEvery(ActionTypes.GetAboutCurrentFlProject, onGetAboutCurrentFlProject);
}

function* watchGetFrontloadingPrgress() {
    yield takeEvery(ActionTypes.GetFrontloadingPrgress, onGetFrontloadingPrgress);
}

function* watchMarkFlProjectAsComplete() {
    yield takeEvery(ActionTypes.MarkFlProjectAsComplete, onMarkFlProjectAsComplete);
}

function* watchDeleteMilestone() {
    yield takeEvery(ActionTypes.DeleteMilestone, onDeleteMilestone);
}

function* watchChangeConfirmationMsg(){
    yield takeEvery(ActionTypes.ChangeConfirmationMsg, onChangeConfirmationMsg);
}
function* watchFrontloadingExportToExcel() {
    yield takeEvery(ActionTypes.ExportFrontloadingToExcel, OnFrontloadingExportToExcel);
}
export default function* FrontLoadingToolSagas() {
    yield all([
        watchChangeConfirmationMsg(),
        
        watchDeleteMilestone(),
        watchMarkFlProjectAsComplete(),
        watchGetFrontloadingPrgress(),
        watchGetAboutCurrentFlProject(),
        watchConfirmEditFlProject(),
        watchGetFlProjects(),
        watchSaveFlProjects(),
        watchGetFlTemplates(),
        watchGetToBeEdittedFlProject(),
        watchEditFlProject(),
        watchDeletFlProject(),
        watchSelectFlProjectForConsultant(),
        watchGetFlProjectDetails(),
        watchUploadPhase(),
        watchUploadMilestone(),
        watchGetPhaseValidDates(),
        watchGetRTDCurveData(),
        watchGetBestPracticesCurves(),
        watchFrontloadingExportToExcel()
    ]);
}