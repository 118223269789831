import { ToastMessages } from "../Enums/Messages";
import { ICancelProcessDto, ICompleteProcessDto, IDepartementStatusInInterface, IaVoteReviewDto, IaVotesAddDto } from "../Models/InterfaceAnalysis/IAItems";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";

export default class InterfaceToolService {

  static async getInterfaceProgress(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getInterfaceProgress)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async createInterface(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.createInterface)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async getAll(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getIAView)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async getIARounds(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.GetIARounds)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async getIAMetaData(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.GetIAMetaData)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async getAllAnonymous(token: string): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getAllForAnonymous, {
        params: { "token": token }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async invite(personId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.inviteForIA, { params: { "id": personId } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async exclude(participantId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.excludeForIA, { params: { "id": participantId } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async changeDepartmentStatusInInterface(model: IDepartementStatusInInterface): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.changeDepartmentStatusInInterface, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async startAnalysisInterface(duration: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.startAnalysisInterface, { params: { "duration": duration } })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async endAnalysisInterface(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.endAnalysisInterface)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async restartAnalysisInterface(duration: number, excludePreviosRoundsInvitation: boolean): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.restartAnalysisInterface, {
        params: {
          "duration": duration,
          "excludePreviosRoundsInvitation": excludePreviosRoundsInvitation
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async completeAnalysisInterface(model: ICompleteProcessDto): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.completeAnalysisInterface, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async cancelAnalysisInterface(model: ICancelProcessDto): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.cancelAnalysisInterface, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async saveResponseForAnonymousAnalysisInterface(model: IaVotesAddDto): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.saveResponseForAnonymous, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async reviewAnswerAnalysisInterface(model: IaVoteReviewDto): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.reviewInterfaceAnswer, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
}