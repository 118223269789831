import { FixedType } from "rc-table/lib/interface";
import { dprojectEbitCostsType, dprojectGeneralDataType } from "./types";

export const dprojectGeneralDataCols: dprojectGeneralDataType = {
  title: "General project data",
  key: "General project data",
  className: "General_project_data_class",
  visible: true, // custom prop does not belong to ColumnsType<> type.
  order: 1, // custom prop does not belong to ColumnsType<> type.
  fixed: "left" as FixedType,
  onHeaderCell: (column) => {
    return {
      onMouseEnter: (e: any) => {
        /*console.log('col_enter: ', e)*/
      },
      onMouseLeave: (e: any) => {
        /*console.log('col_leave: ', e)*/
      },
    };
  },
  children: [
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      width: 150, //90,
      className: "General_project_data_class",
      visible: true, // custom prop does not belong to ColumnsType<> type.
      order: 1, // custom prop does not belong to ColumnsType<> type.
      fixed: "left" as FixedType,
    },
    {
      title: "Finishing Year",
      dataIndex: "finishingYear",
      key: "FinishingYear",
      width: 90, //no need for extra width 4 charters max
      className: "General_project_data_class",
      visible: true, // custom prop does not belong to ColumnsType<> type.
      order: 2, // custom prop does not belong to ColumnsType<> type.
      fixed: "left" as FixedType,
    },
    {
      title: "Run Time (months)",
      dataIndex: "runTime",
      key: "RunTime",
      width: 90, //60,
      className: "General_project_data_class",
      visible: true, // custom prop does not belong to ColumnsType<> type.
      order: 3, // custom prop does not belong to ColumnsType<> type.
      fixed: "left" as FixedType,
    },
    {
      title: "Actual project costs (€)",
      dataIndex: "actualProjectCosts",
      key: "Actual project costs",
      width: 90, //90,
      className: "General_project_data_class",
      visible: true, // custom prop does not belong to ColumnsType<> type.
      order: 4, // custom prop does not belong to ColumnsType<> type.
      fixed: "left" as FixedType,
    },
  ],
};

export const dprojectEbitCostsCols: dprojectEbitCostsType = {
  title: "Ebit Costs",
  order: 2,
  visible: true,
  children: [
    {
      title: "Innovation",
      key: "Innovation",
      className: "Innovation_class",
      visible: true, // custom prop does not belong to ColumnsType<> type.
      order: 2, // custom prop does not belong to ColumnsType<> type.
      fixed: false as FixedType,
      onHeaderCell: (column) => {
        return {
          onMouseEnter: (e) => {
            /*console.log('col_enter: ', e)*/
          },
          onMouseLeave: (e) => {
            /*console.log('col_leave: ', e)*/
          },
        };
      },
      children: [
        {
          title: "Revenue (€ - N)",
          dataIndex: "revenue",
          key: "Revenue",
          width: 150, //60,
          className: "Innovation_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 1, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Product margin (%)",
          dataIndex: "productMargin",
          key: "ProductMargin",
          width: 90, //every column with % will not need extra width
          className: "Innovation_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 2, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Project type (Portfolio extension or substitution)",
          dataIndex: "projectType",
          key: "ProjectType",
          width: 150, //90,
          className: "Innovation_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 3, // custom prop does not belong to ColumnsType<> type.
        },
      ],
    },
    {
      title: "CoD",
      key: "CoD",
      className: "CoD_class",
      visible: true, // custom prop does not belong to ColumnsType<> type.
      order: 3, // custom prop does not belong to ColumnsType<> type.
      fixed: false as FixedType,
      onHeaderCell: (column) => {
        return {
          onMouseEnter: (e) => {
            /*console.log('col_enter: ', e)*/
          },
          onMouseLeave: (e) => {
            /*console.log('col_leave: ', e)*/
          },
        };
      },
      children: [
        {
          title: "Delay (months)",
          dataIndex: "delay",
          key: "Delay",
          width: 90, //60,
          className: "CoD_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 1, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Missed/Expected sales (€ / month)",
          dataIndex: "missedExpectedSales",
          key: "MissedExpectedSales",
          width: 150,
          className: "CoD_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 2, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Project costs delayed months (€ / month)",
          dataIndex: "projectCostsDelayedMonths",
          key: "ProjectCostsDelayedMonths",
          width: 150,
          className: "CoD_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 3, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Other additional costs",
          dataIndex: "otherAdditionalCosts",
          key: "Other additional costs",
          width: 150,
          className: "CoD_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 4, // custom prop does not belong to ColumnsType<> type.
        },
      ],
    },
    {
      title: "Manufacturing costs",
      key: "Manufacturing costs",
      className: "Manufacturing_costs_class",
      visible: true, // custom prop does not belong to ColumnsType<> type.
      order: 4, // custom prop does not belong to ColumnsType<> type.
      fixed: false as FixedType,
      onHeaderCell: (column) => {
        return {
          onMouseEnter: (e) => {
            /*console.log('col_enter: ', e)*/
          },
          onMouseLeave: (e) => {
            /*console.log('col_leave: ', e)*/
          },
        };
      },
      children: [
        {
          title: "MC Planned (€)",
          dataIndex: "mcPlanned",
          key: "MCPlanned",
          width: 150, //60,
          className: "Manufacturing_costs_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 1, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "MC Actual (€)",
          dataIndex: "mcActual",
          key: "MCActual",
          width: 150, //60,
          className: "Manufacturing_costs_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 2, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Quantity (n)",
          dataIndex: "quantity",
          key: "Quantity",
          width: 150, //60,
          className: "Manufacturing_costs_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 3, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Other costs (€)",
          dataIndex: "otherCosts",
          key: "OtherCosts",
          width: 150, //60,
          className: "Manufacturing_costs_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 4, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Share of R&D (%)",
          dataIndex: "mcShareOfRD",
          key: "MCShareOfRD",
          width: 90,
          className: "Manufacturing_costs_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 5, // custom prop does not belong to ColumnsType<> type.
        },
      ],
    },
    {
      title: "Quality costs",
      key: "Quality costs",
      className: "Quality_costs_class",
      visible: true, // custom prop does not belong to ColumnsType<> type.
      order: 5, // custom prop does not belong to ColumnsType<> type.
      fixed: false as FixedType,
      onHeaderCell: (column) => {
        return {
          onMouseEnter: (e) => {
            /*console.log('col_enter: ', e)*/
          },
          onMouseLeave: (e) => {
            /*console.log('col_leave: ', e)*/
          },
        };
      },
      children: [
        {
          title: "Quality costs (warranty, rework, rejects in production) (€)",
          dataIndex: "qualityCosts",
          key: "QualityCosts",
          width: 150,
          className: "Quality_costs_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 1, // custom prop does not belong to ColumnsType<> type.
        },
        {
          title: "Share of R&D (%)",
          dataIndex: "qualityShareOfRD",
          key: "QualityShareOfRD",
          width: 90,
          className: "Quality_costs_class",
          visible: true, // custom prop does not belong to ColumnsType<> type.
          order: 2, // custom prop does not belong to ColumnsType<> type.
        },
      ],
    },
  ],
};

export const dprojectsColumns = [
  {
    title: "General project data",
    key: "General project data",
    className: "General_project_data_class",
    visible: true, // custom prop does not belong to ColumnsType<> type.
    order: 1, // custom prop does not belong to ColumnsType<> type.
    fixed: "left" as FixedType,
    onHeaderCell: (column) => {
      return {
        onMouseEnter: (e: any) => {
          /*console.log('col_enter: ', e)*/
        },
        onMouseLeave: (e: any) => {
          /*console.log('col_leave: ', e)*/
        },
      };
    },
    children: [
      {
        title: "Name",
        dataIndex: "name",
        key: "Name",
        width: 90,
        className: "General_project_data_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 1, // custom prop does not belong to ColumnsType<> type.
        fixed: "left" as FixedType,
      },
      {
        title: "Finishing Year",
        dataIndex: "finishingYear",
        key: "FinishingYear",
        width: 60,
        className: "General_project_data_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 2, // custom prop does not belong to ColumnsType<> type.
        fixed: "left" as FixedType,
      },
      {
        title: "Run Time (months)",
        dataIndex: "runTime",
        key: "RunTime",
        width: 60,
        className: "General_project_data_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 3, // custom prop does not belong to ColumnsType<> type.
        fixed: "left" as FixedType,
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "Comments",
        width: 90,
        className: "General_project_data_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 4, // custom prop does not belong to ColumnsType<> type.
        fixed: "left" as FixedType,
      },
    ],
  },
  {
    title: "Innovation",
    key: "Innovation",
    className: "Innovation_class",
    visible: true, // custom prop does not belong to ColumnsType<> type.
    order: 2, // custom prop does not belong to ColumnsType<> type.
    fixed: false as FixedType,
    onHeaderCell: (column) => {
      return {
        onMouseEnter: (e) => {
          /*console.log('col_enter: ', e)*/
        },
        onMouseLeave: (e) => {
          /*console.log('col_leave: ', e)*/
        },
      };
    },
    children: [
      {
        title: "Revenue (€ - N)",
        dataIndex: "revenue",
        key: "Revenue",
        width: 60,
        className: "Innovation_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 1, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Product margin (%)",
        dataIndex: "productMargin",
        key: "ProductMargin",
        width: 60,
        className: "Innovation_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 2, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Project type (Portfolio extension or substitution)",
        dataIndex: "projectType",
        key: "ProjectType",
        width: 90,
        className: "Innovation_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 3, // custom prop does not belong to ColumnsType<> type.
      },
    ],
  },
  {
    title: "CoDggg",
    key: "CoDkkkk",
    className: "CoD_class",
    visible: true, // custom prop does not belong to ColumnsType<> type.
    order: 3, // custom prop does not belong to ColumnsType<> type.
    fixed: false as FixedType,
    onHeaderCell: (column) => {
      return {
        onMouseEnter: (e) => {
          /*console.log('col_enter: ', e)*/
        },
        onMouseLeave: (e) => {
          /*console.log('col_leave: ', e)*/
        },
      };
    },
    children: [
      {
        title: "Delay (months)",
        dataIndex: "delay",
        key: "Delay",
        width: 60,
        className: "CoD_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 1, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Missed/Expected sales (€ / month)",
        dataIndex: "missedExpectedSales",
        key: "MissedExpectedSales",
        width: 60,
        className: "CoD_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 2, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Project costs delayed months (€ / month)",
        dataIndex: "projectCostsDelayedMonths",
        key: "ProjectCostsDelayedMonths",
        width: 90,
        className: "CoD_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 3, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Other additional costs",
        dataIndex: "otherAdditionalCosts",
        key: "Other additional costs",
        width: 60,
        className: "CoD_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 4, // custom prop does not belong to ColumnsType<> type.
      },
    ],
  },
  {
    title: "Manufacturing costs",
    key: "Manufacturing costs",
    className: "Manufacturing_costs_class",
    visible: true, // custom prop does not belong to ColumnsType<> type.
    order: 4, // custom prop does not belong to ColumnsType<> type.
    fixed: false as FixedType,
    onHeaderCell: (column) => {
      return {
        onMouseEnter: (e) => {
          /*console.log('col_enter: ', e)*/
        },
        onMouseLeave: (e) => {
          /*console.log('col_leave: ', e)*/
        },
      };
    },
    children: [
      {
        title: "MC Planned (€)",
        dataIndex: "mcPlanned",
        key: "MCPlanned",
        width: 60,
        className: "Manufacturing_costs_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 1, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "MC Actual (€)",
        dataIndex: "mcActual",
        key: "MCActual",
        width: 60,
        className: "Manufacturing_costs_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 2, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Quantity (n)",
        dataIndex: "quantity",
        key: "Quantity",
        width: 60,
        className: "Manufacturing_costs_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 3, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Other costs (€)",
        dataIndex: "otherCosts",
        key: "OtherCosts",
        width: 60,
        className: "Manufacturing_costs_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 4, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Share of R&D (%)",
        dataIndex: "mcShareOfRD",
        key: "MCShareOfRD",
        width: 60,
        className: "Manufacturing_costs_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 5, // custom prop does not belong to ColumnsType<> type.
      },
    ],
  },
  {
    title: "Quality costs",
    key: "Quality costs",
    className: "Quality_costs_class",
    visible: true, // custom prop does not belong to ColumnsType<> type.
    order: 5, // custom prop does not belong to ColumnsType<> type.
    fixed: false as FixedType,
    onHeaderCell: (column) => {
      return {
        onMouseEnter: (e) => {
          /*console.log('col_enter: ', e)*/
        },
        onMouseLeave: (e) => {
          /*console.log('col_leave: ', e)*/
        },
      };
    },
    children: [
      {
        title: "Quality costs (warranty, rework, rejects in production) (€)",
        dataIndex: "qualityCosts",
        key: "QualityCosts",
        width: 110,
        className: "Quality_costs_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 1, // custom prop does not belong to ColumnsType<> type.
      },
      {
        title: "Share of R&D (%)",
        dataIndex: "qualityShareOfRD",
        key: "QualityShareOfRD",
        width: 60,
        className: "Quality_costs_class",
        visible: true, // custom prop does not belong to ColumnsType<> type.
        order: 2, // custom prop does not belong to ColumnsType<> type.
      },
    ],
  },
  // Questionaire columns will be added in (initialize columns in store) stage in Dprojects.tsx component
];

export const questionnaireColsDetails = [
  {
    id: 1121,
    subCol1: {
      title: "Total Sales in N",
      key: "1121 Total Sales in N",
      dataIndex: "q1_Total_Sales_in_N",
    },
    subCol2: {
      title: "Revenue share",
      key: "1121 Revenue share",
      dataIndex: "q1_Revenue_share",
    },
  },
  {
    id: 1122,
    subCol1: {
      title: "Total Sales in N",
      key: "1122 Total Sales in N",
      dataIndex: "q2_Total_Sales_in_N",
    },
    subCol2: {
      title: "Revenue share",
      key: "1122 Revenue share",
      dataIndex: "q2_Revenue_share",
    },
  },
  {
    id: 1123,
    subCol1: {
      title: "Total Sales in N (Portfolio extension + Substitution)",
      key: "1123 Total Sales in N",
      dataIndex: "q3_Total_Sales_in_N",
    },
    subCol2: {
      title: "Revenue share",
      key: "1123 Revenue share",
      dataIndex: "q3_Revenue_share",
    },
  },
  {
    id: 2211,
    subCol1: {
      title: "Target price @Project Approval (€)",
      key: "2211 Target price",
      dataIndex: "q4_Target_price",
    },
    subCol2: {
      title: "Actual price @SOP (€)",
      key: "2211 Actual price",
      dataIndex: "q4_Actual_price",
    },
    subCol3: {
      title: "Deviation (%)",
      key: "2211 Deviation",
      dataIndex: "q4_Deviation",
    },
  },
  {
    id: 2212,
    subCol1: {
      title: "Planned quantities @Project Approval (n)",
      key: "2212 Planned quantities",
      dataIndex: "q5_Planned_quantities",
    },
    subCol2: {
      title: "Actual quantities @SOP (n)",
      key: "2212 Actual quantities",
      dataIndex: "q5_Actual_quantities",
    },
    subCol3: {
      title: "Deviation (%)",
      key: "2212 Deviation",
      dataIndex: "q5_Deviation",
    },
  },
  {
    id: 2221,
    subCol1: {
      title: "Planned project costs @Project Approval (€)",
      key: "2221 Planned project costs",
      dataIndex: "q6_Planned_project_costs",
    },
    subCol2: {
      title: "Deviation (%)",
      key: "2221 Deviation",
      dataIndex: "q6_Deviation",
    },
  },
  {
    id: 2311,
    subCol1: {
      title: "Share of secured customer-experience functions @SOP (%) ",
      key: "2311 Share of secured customer-experience functions",
      dataIndex: "q7_customer_experience",
    },
  },
  {
    id: 2312,
    subCol1: {
      title: "Share of technical safeguarding of requirements @SOP (%)",
      key: "2312 Share of technical safeguarding of requirements",
      dataIndex: "q8_technical_safeguarding",
    },
  },
  {
    id: 2321,
    subCol1: {
      title: "Share of tool-dropped parts @last prototype (%)",
      key: "2321 Share of tool-dropped parts",
      dataIndex: "q9_tool_dropped_parts",
    },
  },
  {
    id: 2322,
    subCol1: {
      title:
        "Share parts manufactured from series production equipment @last prototype (%)",
      key: "2322 Share parts manufactured from series production equipment",
      dataIndex: "q10_series_production",
    },
  },
  {
    id: 2323,
    subCol1: {
      title: "Share of costs in the last 3 months (%)",
      key: "2323 Share of costs in the last 3 months",
      dataIndex: "q11_costs_last_3months",
    },
  },
  {
    id: 2324,
    subCol1: {
      title: "Share of costs in the last 3 months (%)",
      key: "2324 Share of costs in the last 3 months",
      dataIndex: "q12_costs_last_3months",
    },
  },
  {
    id: 2411,
    subCol1: {
      title: "Average delay before SOP (months)",
      key: "2411 Average delay before SOP",
      dataIndex: "q13_Average_delay",
    },
  },
  {
    id: 2421,
    subCol1: {
      title: "One-time-yield rate @3 months after SOP (%)",
      key: "2421 One-time-yield rate @3 months after SOP",
      dataIndex: "q14_One_time_yield_rate",
    },
  },
  {
    id: 2422,
    subCol1: {
      title: "Software Claim-Tickets per developer @3 months after SOP (n)",
      key: "2422 Software Claim-Tickets per developer @3 months after SOP",
      dataIndex: "q15_Claim_Tickets",
    },
  },
  {
    id: 2431,
    subCol1: {
      title: "Complaint rate @First 6 months after SOP (%)",
      key: "2431 Complaint rate @First 6 months after SOP",
      dataIndex: "q16_Complaint_rate",
    },
  },
  {
    id: 2432,
    subCol1: {
      title: "Planned revenue @first 6 months after SOP (€)",
      key: "2432 Planned revenue @first 6 months after SOP",
      dataIndex: "q17_Planned_revenue",
    },
    subCol2: {
      title: "Actual revenue @first 6 months after SOP (€)",
      key: "2432 Actual revenue @first 6 months after SOP",
      dataIndex: "q17_Actual_revenue",
    },
    subCol3: {
      title: "Deviation (%)",
      key: "2432 Deviation",
      dataIndex: "q17_Deviation",
    },
  },
  {
    id: 2511,
    subCol1: {
      title:
        "Share of Customer Support requests forwarded to the developer @from 6 months after SOP (%)",
      key: "2511 Share of Customer Support requests forwarded to the developer @from 6 months after SOP",
      dataIndex: "q18_Support_requests",
    },
  },
  {
    id: 3411,
    subCol1: {
      title: "Delay in months",
      key: "3411 Planned project runtime @Project Approval",
      dataIndex: "q19_Planned_project_runtime",
    },
    subCol2: {
      title: "Deviation (%)",
      key: "3411 Deviation",
      dataIndex: "q19_Deviation",
    },
  },
  {
    id: 3421,
    subCol1: {
      title: "Share of originally planned features relaized @SOP (%)",
      key: "3421 Share of originally planned features relaized @SOP",
      dataIndex: "q20_features_relaized",
    },
  },
  {
    id: 3431,
    subCol1: {
      title: "Budget Deviation (%)",
      key: "3431 Budget Deviation",
      dataIndex: "q21_Budget_Deviation",
    },
  },
  {
    id: 3432,
    subCol1: {
      title: "MC Deviation @SOP (%)",
      key: "3432 MC Deviation @SOP",
      dataIndex: "q22_MC_Deviation",
    },
  },
  {
    id: 4522,
    subCol1: {
      title: "Subcontractor in the project (level)",
      key: "4522 Subcontractor in the project",
      dataIndex: "q4522_Subcontractor_in_the_project",
    },
  },
];

export const getPath = (id: string, map: Record<string, string>): string[] => {
  switch (id.length) {
    case "1.".length:
      return [id];
    case "1.1.".length:
      return [id.slice(0, 2), id];
    case "1.1.1.1.".length:
      return [id.slice(0, 2), id.slice(0, 4), id];
    default:
      return [
        map[id].slice(0, 2),
        map[id].slice(0, 4),
        map[id].slice(0, 8),
        map[id],
      ];
  }
};

export const changeQuestionnaireColOrder = (id: string, order: number) => {
  let questionnaireColsDetails = getColsObjFromLocalStorage(
    "questionnaireColsDetails"
  );
  let path = getPath(id, map);
  let obj = questionnaireColsDetails[path[0]];
  path.shift();
  if (path && path.length > 0) {
    for (let i = 0; i < path.length; i++) {
      obj = obj[path[i]];
    }
  }
  obj.order = order;
  saveColsObjectToLocalStorage(
    questionnaireColsDetails,
    "questionnaireColsDetails"
  );
};

export const changeVisibilityRecusivly = (obj: any, visible: boolean) => {
  let keys = Object.keys(obj).filter(
    (key) =>
      key !== "id" &&
      key !== "order" &&
      key !== "visible" &&
      key !== "title" &&
      key !== "key" &&
      key !== "dataIndex"
  );
  if (keys.length === 0) {
    obj.visible = visible;
    return;
  }
  keys.forEach((key) => {
    obj[key].visible = visible;
    changeVisibilityRecusivly(obj[key], visible);
  });
};

export const changeQuestionnaireColVisibility = (
  id: string,
  visible: boolean
) => {
  let questionnaireColsDetails = getColsObjFromLocalStorage(
    "questionnaireColsDetails"
  );
  let path = getPath(id, map);
  let obj = questionnaireColsDetails[path[0]];
  path.shift();
  if (path && path?.length > 0) {
    for (let i = 0; i < path.length; i++) {
      obj = obj[path[i]];
    }
  }
  obj.visible = visible;
  changeVisibilityRecusivly(obj, visible);
  saveColsObjectToLocalStorage(
    questionnaireColsDetails,
    "questionnaireColsDetails"
  );
};

const map: Record<string, string> = {
  "1.1.2.1. Total Sales in N": "1.1.2.1.1.",
  "1.1.2.1. Revenue share": "1.1.2.1.2.",
  "1.1.2.2. Total Sales in N": "1.1.2.2.1.",
  "1.1.2.2. Revenue share": "1.1.2.2.2.",
  "1.1.2.3. Total Sales in N": "1.1.2.3.1.",
  "1.1.2.3. Revenue share": "1.1.2.3.2.",
  "2.2.1.1. Target price": "2.2.1.1.1.",
  "2.2.1.1. Actual price": "2.2.1.1.2.",
  "2.2.1.1. Deviation": "2.2.1.1.3.",
  "2.2.1.2. Planned quantities": "2.2.1.2.1.",
  "2.2.1.2. Actual quantities": "2.2.1.2.2.",
  "2.2.1.2. Deviation": "2.2.1.2.3.",
  "2.2.2.1. Planned project costs": "2.2.2.1.1.",
  "2.2.2.1. Deviation": "2.2.2.1.2.",
  "2.3.1.1. Share of secured customer-experience functions": "2.3.1.1.1.",
  "2.3.1.2. Share of technical safeguarding of requirements": "2.3.1.2.1.",
  "2.3.2.1. Share of tool-dropped parts": "2.3.2.1.1.",
  "2.3.2.2. Share parts manufactured from series production equipment":
    "2.3.2.2.1.",
  "2.3.2.3. Share of costs in the last 3 months": "2.3.2.3.1.",
  "2.3.2.4. Share of costs in the last 3 months": "2.3.2.4.1.",
  "2.4.1.1. Average delay before SOP": "2.4.1.1.1.",
  "2.4.2.1. One-time-yield rate @3 months after SOP": "2.4.2.1.1.",
  "2.4.2.2. Software Claim-Tickets per developer @3 months after SOP":
    "2.4.2.2.1.",
  "2.4.3.1. Complaint rate @First 6 months after SOP": "2.4.3.1.1.",
  "2.4.3.2. Planned revenue @first 6 months after SOP": "2.4.3.2.1.",
  "2.4.3.2. Actual revenue @first 6 months after SOP": "2.4.3.2.2.",
  "2.4.3.2. Deviation": "2.4.3.2.3.",
  "2.5.1.1. Share of Customer Support requests forwarded to the developer @from 6 months after SOP":
    "2.5.1.1.1.",
  "3.4.1.1. Planned project runtime @Project Approval": "3.4.1.1.1.",
  "3.4.1.1. Deviation": "3.4.1.1.2.",
  "3.4.2.1. Share of originally planned features relaized @SOP": "3.4.2.1.1.",
  "3.4.3.1. Budget Deviation": "3.4.3.1.1.",
  "3.4.3.2. MC Deviation @SOP": "3.4.3.2.1.",
  "4.5.2.2. Subcontractor in the project": "4.5.2.2.1.",
};

export const _questionnaireColsDetails = {
  // add sub lever level to questionnaire columns
  "1.": {
    id: 1,
    order: 1,
    visible: true,
    "1.1.": {
      //1 --> 11
      id: 11,
      order: 1,
      visible: true,
      "1.1.2.1.": {
        id: 1121,
        order: 1,
        visible: true,
        "1.1.2.1.1.": {
          title: "Total Sales in N", //TODO translation
          key: "1.1.2.1. Total Sales in N",
          dataIndex: "q1_Total_Sales_in_N",
          order: 1,
          visible: true,
        },
        "1.1.2.1.2.": {
          title: "Revenue share", //TODO translation
          key: "1.1.2.1. Revenue share",
          dataIndex: "q1_Revenue_share",
          order: 2,
          visible: true,
        },
      },
      "1.1.2.2.": {
        id: 1122,
        order: 2,
        visible: true,
        "1.1.2.2.1.": {
          title: "Total Sales in N", //TODO translation
          key: "1.1.2.2. Total Sales in N",
          dataIndex: "q2_Total_Sales_in_N",
          order: 1,
          visible: true,
        },
        "1.1.2.2.2.": {
          title: "Revenue share", //TODO translation
          key: "1.1.2.2. Revenue share",
          dataIndex: "q2_Revenue_share",
          order: 2,
          visible: true,
        },
      },
      "1.1.2.3.": {
        id: 1123,
        order: 3,
        visible: true,
        "1.1.2.3.1.": {
          title: "Total Sales in N (Portfolio extension + Substitution)", //TODO translation
          key: "1.1.2.3. Total Sales in N",
          dataIndex: "q3_Total_Sales_in_N",
          order: 1,
          visible: true,
        },
        "1.1.2.3.2.": {
          title: "Revenue share", //TODO translation
          key: "1.1.2.3. Revenue share",
          dataIndex: "q3_Revenue_share",
          order: 2,
          visible: true,
        },
      },
    },
  },
  "2.": {
    id: 2,
    order: 2,
    visible: true,
    "2.2.": {
      //2 --> 22
      id: 22,
      order: 1,
      visible: true,
      "2.2.1.1.": {
        id: 2211,
        order: 1,
        visible: true,
        "2.2.1.1.1.": {
          title: "Target price @Project Approval (€)", //TODO translation
          key: "2.2.1.1. Target price",
          dataIndex: "q4_Target_price",
          order: 1,
          visible: true,
        },
        "2.2.1.1.2.": {
          title: "Actual price @SOP (€)", //TODO translation
          key: "2.2.1.1. Actual price",
          dataIndex: "q4_Actual_price",
          order: 2,
          visible: true,
        },
        "2.2.1.1.3.": {
          title: "Deviation (%)", //TODO translation
          key: "2.2.1.1. Deviation",
          dataIndex: "q4_Deviation",
          order: 3,
          visible: true,
        },
      },
      "2.2.1.2.": {
        id: 2212,
        order: 2,
        visible: true,
        "2.2.1.2.1.": {
          title: "Planned quantities @Project Approval (n)", //TODO translation
          key: "2.2.1.2. Planned quantities",
          dataIndex: "q5_Planned_quantities",
          order: 1,
          visible: true,
        },
        "2.2.1.2.2.": {
          title: "Actual quantities @SOP (n)", //TODO translation
          key: "2.2.1.2. Actual quantities",
          dataIndex: "q5_Actual_quantities",
          order: 2,
          visible: true,
        },
        "2.2.1.2.3.": {
          title: "Deviation (%)", //TODO translation
          key: "2.2.1.2. Deviation",
          dataIndex: "q5_Deviation",
          order: 3,
          visible: true,
        },
      },
      "2.2.2.1.": {
        id: 2221,
        order: 3,
        visible: true,
        "2.2.2.1.1.": {
          title: "Planned project costs @Project Approval (€)", //TODO translation
          key: "2.2.2.1. Planned project costs",
          dataIndex: "q6_Planned_project_costs",
          order: 1,
          visible: true,
        },
        "2.2.2.1.2.": {
          title: "Deviation (%)", //TODO translation
          key: "2.2.2.1. Deviation",
          dataIndex: "q6_Deviation",
          order: 2,
          visible: true,
        },
      },
    },
    "2.3.": {
      //3 --> 23
      id: 23,
      order: 2,
      visible: true,
      "2.3.1.1.": {
        id: 2311,
        order: 1,
        visible: true,
        "2.3.1.1.1.": {
          title: "Share of secured customer-experience functions @SOP (%) ", //TODO translation
          key: "2.3.1.1. Share of secured customer-experience functions",
          dataIndex: "q7_customer_experience",
          order: 1,
          visible: true,
        },
      },
      "2.3.1.2.": {
        id: 2312,
        order: 2,
        visible: true,
        "2.3.1.2.1.": {
          title: "Share of technical safeguarding of requirements @SOP (%)", //TODO translation
          key: "2.3.1.2. Share of technical safeguarding of requirements",
          dataIndex: "q8_technical_safeguarding",
          order: 1,
          visible: true,
        },
      },
      "2.3.2.1.": {
        id: 2321,
        order: 3,
        visible: true,
        "2.3.2.1.1.": {
          title: "Share of tool-dropped parts @last prototype (%)", //TODO translation
          key: "2.3.2.1. Share of tool-dropped parts",
          dataIndex: "q9_tool_dropped_parts",
          order: 1,
          visible: true,
        },
      },
      "2.3.2.2.": {
        id: 2322,
        order: 4,
        visible: true,
        "2.3.2.2.1.": {
          title:
            "Share parts manufactured from series production equipment @last prototype (%)", //TODO translation
          key: "2.3.2.2. Share parts manufactured from series production equipment",
          dataIndex: "q10_series_production",
          order: 1,
          visible: true,
        },
      },
      "2.3.2.3.": {
        id: 2323,
        order: 5,
        visible: true,
        "2.3.2.3.1.": {
          title: "Share of costs in the last 3 months (%)", //TODO translation
          key: "2.3.2.3. Share of costs in the last 3 months",
          dataIndex: "q11_costs_last_3months",
          order: 1,
          visible: true,
        },
      },
      "2.3.2.4.": {
        id: 2324,
        order: 6,
        visible: true,
        "2.3.2.4.1.": {
          title: "Share of costs in the last 3 months (%)", //TODO translation
          key: "2.3.2.4. Share of costs in the last 3 months",
          dataIndex: "q12_costs_last_3months",
          order: 1,
          visible: true,
        },
      },
    },
    "2.4.": {
      //4 --> 24
      id: 24,
      order: 3,
      visible: true,
      "2.4.1.1.": {
        id: 2411,
        order: 1,
        visible: true,
        "2.4.1.1.1.": {
          title: "Average delay before SOP (months)", //TODO translation
          key: "2.4.1.1. Average delay before SOP",
          dataIndex: "q13_Average_delay",
          order: 1,
          visible: true,
        },
      },
      "2.4.2.1.": {
        id: 2421,
        order: 2,
        visible: true,
        "2.4.2.1.1.": {
          title: "One-time-yield rate @3 months after SOP (%)", //TODO translation
          key: "2.4.2.1. One-time-yield rate @3 months after SOP",
          dataIndex: "q14_One_time_yield_rate",
          order: 1,
          visible: true,
        },
      },
      "2.4.2.2.": {
        id: 2422,
        order: 3,
        visible: true,
        "2.4.2.2.1.": {
          title: "Software Claim-Tickets per developer @3 months after SOP (n)", //TODO translation
          key: "2.4.2.2. Software Claim-Tickets per developer @3 months after SOP",
          dataIndex: "q15_Claim_Tickets",
          order: 1,
          visible: true,
        },
      },
      "2.4.3.1.": {
        id: 2431,
        order: 4,
        visible: true,
        "2.4.3.1.1.": {
          title: "Complaint rate @First 6 months after SOP (%)", //TODO translation
          key: "2.4.3.1. Complaint rate @First 6 months after SOP",
          dataIndex: "q16_Complaint_rate",
          order: 1,
          visible: true,
        },
      },
      "2.4.3.2.": {
        id: 2432,
        order: 5,
        visible: true,
        "2.4.3.2.1.": {
          title: "Planned revenue @first 6 months after SOP (€)", //TODO translation
          key: "2.4.3.2. Planned revenue @first 6 months after SOP",
          dataIndex: "q17_Planned_revenue",
          order: 1,
          visible: true,
        },
        "2.4.3.2.2.": {
          title: "Actual revenue @first 6 months after SOP (€)", //TODO translation
          key: "2.4.3.2. Actual revenue @first 6 months after SOP",
          dataIndex: "q17_Actual_revenue",
          order: 2,
          visible: true,
        },
        "2.4.3.2.3.": {
          title: "Deviation (%)",
          key: "2.4.3.2. Deviation",
          dataIndex: "q17_Deviation",
          order: 3,
          visible: true,
        },
      },
    },
    "2.5.": {
      //5 --> 25
      id: 25,
      order: 4,
      visible: true,
      "2.5.1.1.": {
        id: 2511,
        order: 1,
        visible: true,
        "2.5.1.1.1.": {
          title:
            "Share of Customer Support requests forwarded to the developer @from 6 months after SOP (%)", //TODO translation
          key: "2.5.1.1. Share of Customer Support requests forwarded to the developer @from 6 months after SOP",
          dataIndex: "q18_Support_requests",
          order: 1,
          visible: true,
        },
      },
    },
  },
  "3.": {
    id: 3,
    order: 3,
    visible: true,
    "3.4.": {
      //6 --> 34
      id: 34,
      order: 1,
      visible: true,
      "3.4.1.1.": {
        id: 3411,
        order: 1,
        visible: true,
        "3.4.1.1.1.": {
          title: "Delay in months", //TODO translation
          key: "3.4.1.1. Planned project runtime @Project Approval",
          dataIndex: "q19_Planned_project_runtime",
          order: 1,
          visible: true,
        },
        "3.4.1.1.2.": {
          title: "Deviation (%)", //TODO translation
          key: "3.4.1.1. Deviation",
          dataIndex: "q19_Deviation",
          order: 2,
          visible: true,
        },
      },
      "3.4.2.1.": {
        id: 3421,
        order: 2,
        visible: true,
        "3.4.2.1.1.": {
          title: "Share of originally planned features relaized @SOP (%)", //TODO translation
          key: "3.4.2.1. Share of originally planned features relaized @SOP",
          dataIndex: "q20_features_relaized",
          order: 1,
          visible: true,
        },
      },
      "3.4.3.1.": {
        id: 3431,
        order: 3,
        visible: true,
        "3.4.3.1.1.": {
          title: "Budget Deviation (%)", //TODO translation
          key: "3.4.3.1. Budget Deviation",
          dataIndex: "q21_Budget_Deviation",
        },
      },
      "3.4.3.2.": {
        id: 3432,
        order: 4,
        visible: true,
        "3.4.3.2.1.": {
          title: "MC Deviation @SOP (%)", //TODO translation
          key: "3.4.3.2. MC Deviation @SOP",
          dataIndex: "q22_MC_Deviation",
          order: 1,
          visible: true,
        },
      },
    },
  },
  "4.": {
    id: 4,
    order: 4,
    visible: true,
    "4.5.": {
      id: 45,
      order: 1,
      visible: true,
      "4.5.2.2.": {
        id: 4522,
        order: 1,
        visible: true,
        "4.5.2.2.1.": {
          title: "Subcontractor in the project (level)", //TODO translation
          key: "4.5.2.2. Subcontractor in the project",
          dataIndex: "q4522_Subcontractor_in_the_project",
          order: 1,
          visible: true,
        },
      },
    },
  },
};

export const saveColsObjectToLocalStorage = (
  obj: Object,
  name:
    | "dprojectEbitCostsCols"
    | "dprojectGeneralDataCols"
    | "questionnaireColsDetails"
) => {
  try {
    localStorage.setItem(name, JSON.stringify(obj));
  } catch (error) {
    console.error("ERR in saveColsObjectToLocalStorage: ", error);
  }
};

export const removeColsObjFromLocalStorage = (name: string) => {
  if (localStorage.getItem(name) === null) return;
  localStorage.removeItem(name);
};

export const getColsObjFromLocalStorage = (
  name:
    | "dprojectGeneralDataCols"
    | "dprojectEbitCostsCols"
    | "questionnaireColsDetails"
) => {
  if (localStorage.getItem(name) === null) {
    switch (name) {
      case "dprojectGeneralDataCols":
        return dprojectGeneralDataCols;

      case "dprojectEbitCostsCols":
        return dprojectEbitCostsCols;

      case "questionnaireColsDetails":
        return _questionnaireColsDetails;

      default:
        break;
    }
  }
  let obj: any = JSON.parse(localStorage.getItem(name));
  return obj;
};
