import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Input, InputNumber, Popconfirm, Space, Table, Tooltip, Typography, } from 'antd';
import { IIndustryDisciplineBestPracticeItem, IIndustryDisciplineBestPracticeItemWithKey, IIndustryDisciplineBestPracticeUpdate, IndustryBestPracticeDisciplineList } from '../../../Models/IIndustryDisciplineBestPractice';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { SaveOutlined, DeleteOutlined, } from "@ant-design/icons";
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { formatValue, inputFormatter, inputParser } from '../../../Utils/helperFunctions';
import i18n from '../../../i18n';
import { t } from 'i18next';
import AdminTable from '../../../Components/AdminTable/AdminTable';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
interface IProps {
    allIndustryDiscipline: IIndustryDisciplineBestPracticeItem[];
    industryId: number;
    industryDisciplineData: IIndustryDisciplineBestPracticeItemWithKey[];
    updateIndustryDisciplineBp: (model: IndustryBestPracticeDisciplineList) => any;
    clearIndustryDisciplineBp: (industryId: number) => any;
}
const IndustryDisciplineTable = (props: IProps) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [form] = Form.useForm();
    const [sumOfBP, setSumOfBp] = useState(0);
    //const [formValues, setFormValues] = useState<IIndustryDisciplineBestPracticeUpdate>();
    //search code start
    const searchInput = useRef<any>(null);
    useEffect(() => {
        //console.log("props.industryDisciplineData", props.industryDisciplineData);
        form.resetFields();
        let tempSum = 0;
        props.industryDisciplineData.map(cc => {
            form.setFieldValue(`bestPracticeMin_${cc.disciplineId}`, cc.bestPracticeMin ?? '');
            form.setFieldValue(`bestPracticeMax_${cc.disciplineId}`, cc.bestPracticeMax ?? '');
            form.setFieldValue(`bestPractice_${cc.disciplineId}`, cc.bestPractice ?? '');
            if (cc.bestPractice) tempSum = tempSum + cc.bestPractice;
        });
        setSumOfBp(tempSum);
    }, [props.allIndustryDiscipline]);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "indDis_bestP_1",
            title: `${t("Discipline")}`,
            dataIndex: "disciplineName",
            render: (text: any, _: any) => {
                return <Typography.Text strong>{text}</Typography.Text>
            },
            ...getColumnSearchProps('Name Discipline', 'disciplineName', searchInput, handleSearch),
            sorter: (a, b) => a?.disciplineName?.localeCompare(b?.disciplineName) ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: "indDis_bestP_2",
            title: " MIN",
            dataIndex: "bestPracticeMin",
            render: (text: any, record: any) => {
                return <Form.Item name={`bestPracticeMin_${record.disciplineId}`} key={`bestPracticeMin_${record.disciplineId}`}>
                    <InputNumber

                        addonAfter="%"
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        min={0} max={100}
                    //onChange={(value: number) => setFormValues({ ...formValues, bestPracticeMin: value })}
                    />
                </Form.Item>
            }
        },
        {
            key: "indDis_bestP_3",
            title: "BP",
            dataIndex: "bestPractice",
            render: (text: any, record: any) => {
                return <Form.Item name={`bestPractice_${record.disciplineId}`} key={`bestPractice_${record.disciplineId}`}>
                    <InputNumber
                        onChange={onChange}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%"
                        min={0} max={100}
                    //onChange={(value: number) => setFormValues({ ...formValues, bestPractice: value })}
                    />
                </Form.Item>
            }
        },
        {
            key: "indDis_bestP_4",
            title: "MAX",
            dataIndex: "bestPracticeMax",
            render: (text: any, record: any) => {
                return <Form.Item name={`bestPracticeMax_${record.disciplineId}`} key={`bestPracticeMax_${record.disciplineId}`} >
                    <InputNumber
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%"
                        min={0} max={100}
                    //onChange={(value: number) => setFormValues({ ...formValues, bestPracticeMax: value })}
                    />
                </Form.Item>
            }
        }
        //,
        // {
        //     key: "indDis_best_operation",
        //     title: `${t("Actions")}`,
        //     dataIndex: "",
        //     fixed: "right",
        //     width: "10%",
        //     render: (_: any, record: any) => {

        //         return <Space size={6}>
        //             <Tooltip title={`${t("Save")}`} getPopupContainer={() => document.getElementById("app-layout")}>
        //                 <SaveOutlined style={{ fontSize: "1.2rem" }} onClick={() => onFormValueHandler(record.disciplineId)} />
        //             </Tooltip>
        //             <Popconfirm title={`${t("Sure to Clear All?")}`} cancelText={`${t("Cancel")}`} onConfirm={async () => await onClearAll(record?.id, record.industryId)} getPopupContainer={() => document.getElementById("app-layout")} disabled={!record.id}>
        //                 <Button disabled={!record.id} style={{ border: 'none', backgroundColor: "inherit" }}>
        //                     <DeleteOutlined style={{ fontSize: "1.2rem" }} />
        //                 </Button>
        //             </Popconfirm >
        //         </Space>
        //     },
        // },
    ];


    const onClearAll = () => {
        try {
            props.clearIndustryDisciplineBp(props.industryId);
        } catch (error) {
            console.log(error);
        }
    }
    const onChange = () => {
        let tempSum = 0;
        props.industryDisciplineData.map(disc => {
            let bestPractice = form.getFieldValue(`bestPractice_${disc.disciplineId}`);
            if (bestPractice) {
                tempSum = tempSum + bestPractice;
            }
        });
        setSumOfBp(tempSum);
    }
    const onSaveAll = () => {
        try {
            setErrorMessage('');
            var model: IndustryBestPracticeDisciplineList = {
                industryId: props.industryId,
                list: []
            };
            var hasError = false;
            let bestPractice;
            let bestPracticeMin;
            let bestPracticeMax;
            for (const disc of props.industryDisciplineData) {
                bestPractice = form.getFieldValue(`bestPractice_${disc.disciplineId}`) as number;
                bestPracticeMin = form.getFieldValue(`bestPracticeMin_${disc.disciplineId}`) as number;
                bestPracticeMax = form.getFieldValue(`bestPracticeMax_${disc.disciplineId}`) as number;

                if (bestPractice >= 0 && bestPracticeMin >= 0 && bestPracticeMax >= 0) {
                    if (bestPracticeMin > bestPractice) {
                        setErrorMessage(`${t("The Best Practice Min should less than or equal to Best Practice value in")}(${disc.disciplineName})`);
                        hasError = true;
                        break;
                    } else if (bestPracticeMax < bestPractice) {
                        setErrorMessage(`${t("The Best Practice Max should greater or equal to Best Practice value in")}(${disc.disciplineName})`);
                        hasError = true;
                        break;
                    }
                    model.list.push({
                        bestPractice: (bestPractice != '')? bestPractice : 0,
                        bestPracticeMax: (bestPracticeMax != '')? bestPracticeMax : 0,
                        bestPracticeMin: (bestPracticeMin != '')? bestPracticeMin : 0,
                        disciplineId: disc.disciplineId
                    });
                } else {
                    console.log("bestPractice,bestPracticeMin, bestPracticeMax", bestPractice, bestPracticeMin, bestPracticeMax, bestPractice >= 0 && bestPracticeMin >= 0 && bestPracticeMax >= 0, bestPractice != '' && bestPracticeMin != '' && bestPracticeMax != '');
                }
            };
            if (hasError) return;
            if (sumOfBP != 100) {
                setErrorMessage(`${t("Sum of best practices is")}: ${formatValue(sumOfBP)}. ${t("It must be 100%")}`);
                return;
            } else {
                setErrorMessage('');
                props.updateIndustryDisciplineBp(model);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    return (

        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
            <Alert message={`${t("All best practices percentages should add up to 100.")}`} type="info" showIcon />
            <Alert message={`${t("The remaining best practices percentages is")}: ${formatValue(100 - sumOfBP)}.`} type="info" showIcon />
            <Space size={6}>
                <Tooltip title={`${t("Save")}`} getPopupContainer={() => document.getElementById("app-layout")}>
                    <SaveOutlined style={{ fontSize: "1.2rem" }} onClick={() => onSaveAll()} />
                </Tooltip>
                <Popconfirm title={`${t("Sure to Clear All?")}`} cancelText={`${t("Cancel")}`}
                    onConfirm={() => onClearAll()} getPopupContainer={() => document.getElementById("app-layout")}
                >
                    <Button
                        //disabled={!record.id}
                        style={{ border: 'none', backgroundColor: "inherit" }}>
                        <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                    </Button>
                </Popconfirm>
            </Space>
            <Form form={form} component={false} key='IndDis_best_practice_table_form'>
                <AdminTable
                    dataSource={props.industryDisciplineData}
                    keyTable={'IndDis_best_practice_table'}
                    columns={columns}
                />
            </Form>
        </Space>

    );
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        allIndustryDiscipline: state.industryDisciplineBestPractice.industryDisciplineBestPracticeResult
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateIndustryDisciplineBp: (model: IndustryBestPracticeDisciplineList) => dispatch({ type: ActionTypes.UPDATE_INDUSTRY_DISCIPLINE_BEST_PRACTICE, model: model, industryId: model.industryId }),
        clearIndustryDisciplineBp: (industryId: number) => dispatch({ type: ActionTypes.CLEAR_INDUSTRY_DISCIPLINE_BEST_PRACTICE, industryId: industryId })
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(IndustryDisciplineTable);