import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import { ISelfAssessmentTempItem } from "../../Models/ISelfAssessmentTemp";
import SelfAssessmentTempService from "../../Services/SelfAssessmentBmTempService";
import {
  ISABestPracticeHistoryImportance,
  ISelfAssessmentView,
} from "../../Models/SelfAssessment/ISelfAssessmentView";

function* OnGetAllSelfAssessmentView() {
  console.log("OnGetAllSelfAssessmentView");
  try {
    yield put({
      type: ActionTypes.ChangeSelfAssessementLoader,
      isLoading: true,
    });
    let _result: ICommonResponse =
      yield SelfAssessmentTempService.GetSelfAssessmentView();
    if (!_result.isError) {
      let result = _result.result as ISelfAssessmentView;
      yield put({ type: ActionTypes.SetSelfAssessmentView, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({
      type: ActionTypes.ChangeSelfAssessementLoader,
      isLoading: false,
    });
  } catch (error) {
    console.error("ERR in onGet ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* OnChangeAllSelfAssessmentTopRated(action: any) {
  yield put({
    type: ActionTypes.SetSelfAssessmentTopRated,
    result: action.model,
  });
}

function* OnChangeAllSelfAssessmentView(action: any) {
  //console.log("OnChangeAllSelfAssessmentView", action.model);
  yield put({ type: ActionTypes.SetSelfAssessmentView, result: action.model });
}
function* OnSaveSelfAssessment(action: any) {
  console.log("OnSaveSelfAssessment ", action.model);
  try {
    yield put({
      type: ActionTypes.ChangeSelfAssessementLoader,
      isLoading: true,
    });
    let resultCreate: ICommonResponse =
      yield SelfAssessmentTempService.SaveSelfAssessment(action.model);
    if (!resultCreate.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
      let _result: ICommonResponse =
        yield SelfAssessmentTempService.GetSelfAssessmentView();
      let result = _result.result as ISelfAssessmentView;
      yield put({ type: ActionTypes.SetSelfAssessmentView, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
    }
    yield put({
      type: ActionTypes.ChangeSelfAssessementLoader,
      isLoading: false,
    });
  } catch (error) {
    console.error("ERR in onCreate: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* OnGetHistoryImportance(action: any) {
  try {
    yield put({
      type: ActionTypes.CHANGE_HISTORY_IMPORTANCE_LOADER,
      isLoading: true,
    });
    let resultGet: ICommonResponse =
      yield SelfAssessmentTempService.GetSelfAssessmentHistoryOfImportance(
        action.id
      );
    if (!resultGet.isError) {
      let result = resultGet.result as ISABestPracticeHistoryImportance[];
      yield put({ type: ActionTypes.SAVE_HISTORY_IMPORTANCE, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultGet });
    }
    yield put({
      type: ActionTypes.CHANGE_HISTORY_IMPORTANCE_LOADER,
      isLoading: false,
    });
  } catch (error) {
    console.error("ERR in onGet: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* OnExportToExcel(action: any) {
  try {
    yield put({
      type: ActionTypes.ChangeSelfAssessementLoader,
      isLoading: true,
    });
    yield SelfAssessmentTempService.ExportToExcel(action.projectName);
    yield put({
      type: ActionTypes.ChangeSelfAssessementLoader,
      isLoading: false,
    });
  } catch (error) {
    console.error("ERR in onGet: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
    yield put({
      type: ActionTypes.ChangeSelfAssessementLoader,
      isLoading: false,
    });
  }
}

function* watchOnGetAllSelfAssessmentView() {
  yield takeEvery(
    ActionTypes.GetSelfAssessmentView,
    OnGetAllSelfAssessmentView
  );
}

function* watchOnChangeAllSelfAssessmentView() {
  yield takeEvery(
    ActionTypes.ChangeAllSelfAssessmentView,
    OnChangeAllSelfAssessmentView
  );
}

function* watchOnSaveSelfAssessment() {
  yield takeEvery(ActionTypes.SaveSelfAssessmentView, OnSaveSelfAssessment);
}
function* watchOnGetHistoryImportance() {
  yield takeEvery(ActionTypes.GET_HISTORY_IMPORTANCE, OnGetHistoryImportance);
}
function* watchOnChangeAllSelfAssessmentTopRated() {
  yield takeEvery(
    ActionTypes.ChangeSelfAssessmentTopRated,
    OnChangeAllSelfAssessmentTopRated
  );
}
function* watchSelfAssessmenetExportToExcel() {
  yield takeEvery(ActionTypes.ExportSelfAssessntToExcel, OnExportToExcel);
}

export default function* SelfAssessmetToolSagas() {
  yield all([
    watchOnGetAllSelfAssessmentView(),
    watchOnSaveSelfAssessment(),
    watchOnChangeAllSelfAssessmentView(),
    watchOnGetHistoryImportance(),
    watchOnChangeAllSelfAssessmentTopRated(),
    watchSelfAssessmenetExportToExcel(),
  ]);
}
