import { Moment } from "moment";
import { object } from "prop-types";
import { AddedMilestoneToUpload, FormObject, MappingObject, MilestoneToUpload, PhaseMiestone, PhaseToUpload } from "../Models/FormObject";

interface MilestonesNamesAndIds {
    id: number;
    name: string;
}

interface DepartmentFTE {
    id: number,
    departmentId: number;
    fteValue: number;
}

export default function arrangFormValues(values: any, flProjectId: number): PhaseToUpload {

    let milestonesNamesAndIds: MilestonesNamesAndIds[] = getMilestonesNamesAndIds(values);
    let phaseMilestones: MilestoneToUpload[] = milestonesNamesAndIds.map(milestoneNameId => {
        //return values keys belong to milestone Id
        let milestoneProperties: string[] = getMilestoneProperties(milestoneNameId.id, values);
        let mappingObject: MappingObject = createMappingObject(milestoneProperties);
        let ftes: DepartmentFTE[] = getMilestoneFTEs(mappingObject.milestoneFTEs, values);
        return {
            milestoneId: milestoneNameId.id,
            milestoneName: milestoneNameId.name,
            milestoneActualDate: (values[mappingObject.milestoneActualDate] as Moment).format('M/D/YYYY'),
            //milestonePlannedDate: (values[mappingObject.milestoneActualDate] as Moment).format('M/D/YYYY'),
            milestoneGateName: values[mappingObject.milestoneGateName],
            milestoneFTEs: ftes
        }
    });
    let formObject: PhaseToUpload = {
        flProjectId: flProjectId,
        phaseId: values.phaseId,
        templatePhaseId: values.templatePhaseId,
        phaseName: values.phaseName,
        phaseActualEndDate: (values.phaseActualEndDate as Moment).format('M/D/YYYY'),
        //phasePlannedEndDate: (values.phaseActualEndDate as Moment).format('M/D/YYYY'),
        phaseMilestones: phaseMilestones
    };


    return formObject;
}

function getMilestoneFTEs(ftesKeys: string[], values: any): DepartmentFTE[] {
    return ftesKeys.map(ftekey => {
        let disiplineId_fteValue = ftekey.split('+');
        let disciplineId = parseInt(disiplineId_fteValue[1].split('_')[0]);
        return {
            id: values[ftekey + 'Id'],
            departmentId: disciplineId,
            fteValue: values[ftekey]
        }
    });
}

function createMappingObject(properties: string[]): MappingObject {
    let mappingObject: MappingObject = {
        milestoneActualDate: properties.find(p => p.includes('actualDate')),
        milestonePlannedDate: properties.find(p => p.includes('plannedDate')),
        milestoneGateName: properties.find(p => p.includes('gateName')),
        milestoneFTEs: properties.filter(p => p.includes('fteValue') && !p.includes('fteValueId')),

    };
    return mappingObject;
}

function getMilestoneProperties(id: number, values: any): string[] {
    let properties: string[] = Object.keys(values).filter(key => key.includes(id.toString()));
    return properties;
}

function extractIdFromId_Name(value: string): number {
    return parseInt(value.split('_')[0]);
}


function getMilestonesNamesAndIds(values: any): MilestonesNamesAndIds[] {
    let id_milestoneNames = Object.keys(values).filter(key => key.includes('milestoneName'));
    return id_milestoneNames.map(id_milestoneName => {
        let milestoneId = extractIdFromId_Name(id_milestoneName);
        return {
            id: milestoneId,
            name: values[id_milestoneName]
        };
    });
}

export function arrangeMilestoneFormObj(values: any): AddedMilestoneToUpload {
    let keys = Object.keys(values);
    let disciplineIds = keys.filter(k => {
        return (k != "milestoneName") &&
            (k != "milestoneActualDate") &&
            //(k != "milestonePlannedDate") &&
            (k !== "milestoneGateName")&&
            (k !== "phaseId")
    });
    let ftes = disciplineIds.map((disciplineId, idx) => {
        return {
            id: idx,
            departmentId: parseInt(disciplineId),
            fteValue: values[disciplineId]
        }
    });
    return {
        phaseId: values.phaseId,
        milestoneName: values.milestoneName,
        milestoneActualDate: (values.milestoneActualDate as Moment).format('M/D/YYYY'),
        milestonePlannedDate: (values.milestoneActualDate as Moment).format('M/D/YYYY'),
        milestoneGateName: values.milestoneGateName,
        milestoneFTEs: ftes
    }
}