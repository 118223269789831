import { Alert, Button, Form, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { IListItem } from '../../../../../Models/Responses/ProfileResponse';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../../Redux/actionsTypes';
import { t } from 'i18next';
import ModalAdmin from '../../../../../Components/ModalAdmin/ModalAdmins';
import { ICommonResponse } from '../../../../../Models/Responses/ICommonResponse';
import { IActivityTask, IActivityTaskTemplate, IMapTaskToGroup, IProfileVersionsAll } from '../../../../../Models/IActivityManagement';
import { RulesRequire } from '../../../Rules/RulesValidation';
import i18n from '../../../../../i18n';
import { IGeneralState } from '../../../../../Redux/reducers/IGeneralState';
interface IProps {
    groupId: number;
    activityProfileId: number;
    //profileVersionsData: IProfileVersionsAll,
    isModalLoading: boolean;
    profileVersionId: number;
    allTasks: IActivityTaskTemplate[];
    tasksInGroup: IActivityTask[];
    modalResponse: ICommonResponse;
    addEditMapTaskToGroup: (model: IMapTaskToGroup, profileVersionId: number) => any;
    getProfileAllTasks: (profileId: number) => any;
}
const MapTaskToGroupModal: React.FC<IProps> = (props) => {
    useEffect(() => { props.getProfileAllTasks(props.activityProfileId) }, [props.activityProfileId]);
    const [isTaskHaveGroup, setIsTaskHaveGroup] = useState(false);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    if (!props.allTasks) {
        return;
    }
    let tasksList: IListItem[] = props.allTasks?.filter(item => !props.tasksInGroup?.map(x => x?.taskId)?.includes(item?.id))?.map(t => {
        return {
            label: i18n.language === 'de' ? t?.textDe : t?.textEn,
            value: t?.id
        }
    }) ?? [];
    // let tasksInAllGroups = [];
    // let demoTasks = props.profileVersionsData?.list?.filter(v => v.versionId === props.profileVersionId)[0]?.profileGroups?.map(x => x.versionTasks);
    // for (let i = 0; i < demoTasks.length; i++) {
    //     for (let j = 0; j < demoTasks[i].length; j++) {
    //         tasksInAllGroups.push(demoTasks[i][j])
    //     }
    // }
    //let tasksListCurrent: IListItem[] = tasksList && tasksList?.filter(item => !tasksInAllGroups?.map(x => x?.taskId)?.includes(item?.value))
    // let checkIsTaskHasGroup = (taskIdSelected: number) => {
    //     if (tasksInAllGroups?.length > 0) {
    //         if (tasksInAllGroups?.find(t => t.taskId === taskIdSelected)) {
    //             setIsTaskHaveGroup(true)

    //         } else {
    //             setIsTaskHaveGroup(false)
    //         }
    //     } else {
    //         setIsTaskHaveGroup(false)
    //     }
    // }
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            let values = form.getFieldsValue();
            let newMap: IMapTaskToGroup = {
                taskId: values.taskId,
                profileVersionId: props.profileVersionId,
                groupId: props.groupId,
                serialNumberInGroup: values.serialNumberInGroup
            }
            await props.addEditMapTaskToGroup(newMap, props.profileVersionId)
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        setIsTaskHaveGroup(false);
        form.resetFields();
    }
    return (
        <ModalAdmin title={`${t("Add Tasks To Group")}`} btnText={`${t("Add")}`}
            width="520px"

            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form}>
                <Form.Item label={`${t("Tasks")}`} name='taskId' rules={RulesRequire({ filedName: `${t("Task")}` })}>
                    <Select
                        getPopupContainer={() => document.getElementById("app-layout")}
                        showSearch
                        // onChange={(value: number) => checkIsTaskHasGroup(value)}
                        placeholder={`${t("Select Task")}`}
                        options={tasksList}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                </Form.Item>
                {isTaskHaveGroup && <Alert
                    message={`${t("This task that already mapped to other group, will be removed and add it to current group.")}`}
                    banner
                    style={{ marginBottom: "1rem" }} />}
                <Form.Item label={`${t("Serial Number In Group")}`} name='serialNumberInGroup' rules={RulesRequire({ filedName: `${t("Serial Number In Group")}` })}>
                    <InputNumber />
                </Form.Item>
            </Form>
        </ModalAdmin>
    )
}
const mapStateToProps = (state: IGeneralState) => {
    return {        
        allTasks: state.activityProfileTasks.profileTasksResult,
        isLoading: state.activityProfileTasks.isActProTasksLoading,
        isModalLoading: state.activityProfileVersions.isModalLoading,
        modalResponse: state.activityProfileVersions.modalResponse,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        //getProfileVersionDetails: (profileVersionId: number)=> dispatch({ type: ActionTypes.GET_ACTIVITY_PROFILE_VERSION_Detail, profileVersionId: profileVersionId}),
        addEditMapTaskToGroup: (model: IMapTaskToGroup, profileVersionId: number) => dispatch({ type: ActionTypes.ADD_EDIT_MAP_TASK_GROUP, model, profileVersionId }),
        getProfileAllTasks: (profileId: number) => dispatch({ type: ActionTypes.GET_ACTIVITY_PROFILE_TASKS, profileId }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapTaskToGroupModal);