import { connect } from 'react-redux'
import { ActionTypes } from '../../../Redux/actionsTypes'
import { Spin } from 'antd';
import { IAllEventLogs, IEventLogFilter, IEventLogFilterMenus, ILogs } from '../../../Models/IEventLogForAdmin';
import { useEffect, useState } from 'react';
import EventLogsFiltersForm from './EventLogsFiltersForm';
import EventLogsForAdminTable from './EventLogsForAdminTable';
interface IProps {
    eventLogs: IAllEventLogs;
    getAllEventLogs: (model: IEventLogFilter) => any;
    isLoadingLogs: boolean;
    isLoadingFilters: boolean;
    eventLogsFilters: IEventLogFilterMenus;
    getAllFiltersEventLogs: () => any;
}
const EventLogsForAdmin = (props: IProps) => {
    const [dataSourceLogs, setDataSourceLogs] = useState<ILogs[]>();
    const [filters, setFilters] = useState<IEventLogFilter>();
    const [totalCount, setTotalCount] = useState<number>();
    useEffect(() => {
        props.getAllFiltersEventLogs();
    }, []);
    useEffect(() => {
        props.getAllEventLogs(filters);
    }, [filters]);
    useEffect(() => {
        if (props.eventLogs) {
            setDataSourceLogs(props.eventLogs?.logs?.map(l => {
                return {
                    key: `log_${l.id}`,
                    ...l
                }
            }))
            setTotalCount(props.eventLogs?.totalCount)
        }
    }, [props])
    if (dataSourceLogs === null || dataSourceLogs === undefined) {
        return;
    };

    return (
        <Spin spinning={props.isLoadingLogs || props.isLoadingFilters}>
            <div className='admin-component'>
                <EventLogsFiltersForm filtersMenus={props.eventLogsFilters} setFilters={setFilters} filters={filters} />
                < EventLogsForAdminTable logsData={dataSourceLogs} setFilters={setFilters} filters={filters} totalCount={totalCount} />
            </div>
        </Spin>
    )
}
const mapStateToProps = (state: any) => {
    return {
        eventLogs: state.eventLogs?.eventLogsResult,
        isLoadingLogs: state.eventLogs?.isEventLogsLoading,
        eventLogsFilters: state.eventLogsFilter?.filterMenus,
        isLoadingFilters: state.eventLogsFilter?.isFilterMenusLoading,
    }
}
const mapDispatchForProps = (dispatch: any) => {
    return {
        getAllFiltersEventLogs: () => dispatch({ type: ActionTypes.GET_FILTER_EVENT_LOGS_MENUS }),
        getAllEventLogs: (model: IEventLogFilter) => dispatch({ type: ActionTypes.GET_ALL_EVENT_LOGS, model }),
    }
}
export default connect(mapStateToProps, mapDispatchForProps)(EventLogsForAdmin);
