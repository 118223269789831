import {  Tabs} from "antd";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import 'moment/locale/de.js';
import 'moment/locale/en-gb.js';
import { t } from "i18next";
import HelpPage from "../../../Layout/HelpPage"; 
import CommingSoon from "../../../Components/CommingSoon";
moment.locale(i18n.language);

interface IProps {
}
function LeadTimeAnalysis(props: IProps) {

    const [activeTab, setActiveTab] = useState(localStorage.getItem("lead_time_analysis_tool_tab") ?? 'start-point-tab');

    const items = () => {
        let tempItems = [];
        tempItems.push({
            label: `${t("Start Point")}`, key: 'start-point-tab',
            children: <CommingSoon />
        });
        tempItems.push({
            label: <>{t("Tipps and Tricks")}</>, key: 'tips-lead-time-analysis-tab',
            children: <HelpPage currentPageCode="lead-time-analysis" />
        });
        return tempItems;
    }

    return <Tabs onChange={(activeKey) => {
        localStorage.setItem("lead_time_analysis_tool_tab", activeKey);
        setActiveTab(activeKey);
    }} activeKey={activeTab} items={items()} />
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        //state: state.activity
    }
};


const mapDispatchToProps = (dispatch: any) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadTimeAnalysis);