import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

type StateProps = {
  // Attributes that you want mapped from redux store
  loggedin: boolean;
}
type DispatchProps = {
  // Dispatch actions  
}
type OwnProps = {
  // Other props that component expects when being created
}

const AnonymousRoute = ({ children }: any) => {
  
  return children; 
};

export default AnonymousRoute;
