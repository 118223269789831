import { takeEvery, all, put } from 'redux-saga/effects';
import { ActionTypes } from '../actionsTypes';
import { ICommonResponse } from '../../Models/Responses/ICommonResponse';
import { ToastMessages } from '../../Enums/Messages';
import EbitService from '../../Services/EbitService';
import { RndInfoResponse } from '../../Scenes/Tools/EBITTool-V2/Models/RndInfoResponse';
import { DProjectForEbit } from '../../Scenes/Tools/EBITTool-V2/Models/CostTypesResponse';
import Estimate from '../../Scenes/Tools/EBITTool-V2/Models/Estimate';
import { EvaluationData } from '../../Scenes/Tools/EBITTool-V2/Models/EvaluationData';
import { EBITBenchmarkData } from '../../Scenes/Tools/EBITTool-V2/Models/EBITBenchmarkData';

function* onDownloadEstimationReport(action: any) {
    try {
        yield EbitService.GetEstimationReportPDF(action.projectName);
    } catch (error) {
        console.error('ERR in DownloadEstimationReport: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDownloadDisciplinesVsBestPractice(action: any) {
    try {
        yield EbitService.GetDisciplinesVsBestPracticeExcel(action.projectName);
    } catch (error) {
        console.error('ERR in DownloadDisciplinesVsBestPractice: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDownloadDisciplinesExcel(action: any) {
    try {
        yield EbitService.ExportDisciplinesExcel(action.projectName);
    } catch (error) {
        console.error('ERR in onDownloadDisciplinesExcel: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDownloadFullExcelReport(action: any) {
    try {
        yield EbitService.ExportFullExcelReport(action.projectName);
    } catch (error) {
        console.error('ERR in onDownloadFullExcelReport: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDownloadPresentation(action: any) {
    try { 
        yield EbitService.ExportPresentationPPtm(action.projectName);
    } catch (error) {
        console.error('ERR in onDownloadPresentation: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDownloadCODTable(action: any) {
    try {
        yield EbitService.ExportCODExcelTable(action.projectName, action.projectID);
    } catch (error) {
        console.error('ERR in onDownloadCODTable: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onDownloadHKTable(action: any) {
    try {
        yield EbitService.ExportHKExcelTable(action.projectName, action.projectID);
    } catch (error) {
        console.error('ERR in onDownloadHKTable: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDownloadQKTable(action: any) {
    try {
        yield EbitService.ExportQKExcelTable(action.projectName, action.projectID);
    } catch (error) {
        console.error('ERR in onDownloadQKTable: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDownloadInnoTable(action: any) {
    try {
        yield EbitService.ExportInnoExcelTable(action.projectName, action.projectID);
    } catch (error) {
        console.error('ERR in onDownloadInnoTable: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onDownloadCostTypesExcel(action: any) {
    try {
        yield EbitService.ExportCostTypesExcel(action.projectName);
    } catch (error) {
        console.error('ERR in DownloadCostTypesExcel: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDownloadRDTableExcel(action: any) {
    try {
        yield EbitService.ExportRDTableExcel(action.projectName);
    } catch (error) {
        console.error('ERR in DownloadRDTableExcel: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onGetRnd(action: any) {
    //console.log('onGetRnd ', action.projectID);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.GetRnD();

        if (!_result.isError) {
            let result = _result.result as RndInfoResponse;
            // yield put({type: ActionTypes.SaveRecivedRndUnits, result: result.rnDUnits});

            yield put({ type: ActionTypes.SaveRndInfo, result: result });
            yield put({ type: ActionTypes.SaveRecivedRndUnits, result: result.rnDUnits });
            //yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetRnd: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onGetSavedRnd(action: any) {
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.GetRnD();

        if (!_result.isError) {
            let result = _result.result as RndInfoResponse;
            yield put({ type: ActionTypes.SaveRecivedRndUnits, result: result.rnDUnits });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetRnd: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onCreateNewUnit(action: any) {
    console.log('onCreateNewUnit ');
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateRnDUnit(action.rndUnit);

        if (!_result.isError) {
            let result = _result.result as RndInfoResponse;
            yield put({ type: ActionTypes.SaveRndInfo, result: result });
            yield put({ type: ActionTypes.GetRecivedRndUnits });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateNewUnit: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onUpdateUnit(action: any) {
    console.log('onUpdateUnit ');
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.UpdateRnDUnit(action.rndUnit);

        if (!_result.isError) {
            let result = _result.result as RndInfoResponse;
            yield put({ type: ActionTypes.SaveRndInfo, result: result });
            yield put({ type: ActionTypes.GetRecivedRndUnits });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onUpdateUnit: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDeleteUnit(action: any) {
    console.log('onDeleteUnit ');
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.DeleteRnDUnit(action.rndUnitId);

        if (!_result.isError) {
            let result = _result.result as RndInfoResponse;
            yield put({ type: ActionTypes.SaveRndInfo, result: result });
            yield put({ type: ActionTypes.GetRecivedRndUnits });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onDeleteUnit: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* SaveUnitCostsChanges(action: any) {
    console.log('SaveUnitCostsChanges ');
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });

        let _result: ICommonResponse = yield EbitService.SaveUnitCostsChanges(action.rndUnits);

        if (!_result.isError) {
            let result = _result.result as RndInfoResponse;
            yield put({ type: ActionTypes.SaveRecivedRndUnits, result: result.rnDUnits });
            yield put({ type: ActionTypes.SaveRndInfo, result: result });

            yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onUpdateUnit: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onUpdateEbitRndLocalRow(action: any) {
    yield put({ type: ActionTypes.SaveRdUnitsRow, result: action.result });
    yield put({ type: ActionTypes.GetRecivedRndUnits });
}

function* onGetDprojects(action: any) {
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.GetDprojects(action.projectID);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.projectID) {
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            }
            else {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetDprojects: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onCreateQk(action: any) {
    console.log('onCreateQk ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateQkRow(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateQk: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onCreateQKWithNewDproject(action: any) {
    console.log('onCreateQKWithNewDproject ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateQKWithNewDproject(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });

            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateQKWithNewDproject: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onImportQKTable(action: any) {
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.ImportQKExcelTable(action.frmData);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onImportQk: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onUpdateQk(action: any) {
    console.log('onUpdateQk ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.UpdateQkRow(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onUpdateQk: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDeleteQk(action: any) {
    console.log('onDeleteQk ', action.qkId);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.DeleteQkRow(action.qkId, action.forEbit);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onDeleteQk: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onCreateCOD(action: any) {
    console.log('onCreateCOD ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateCODRow(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateQk: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onCreateCODWithNewDproject(action: any) {
    console.log('onCreateCODWithNewDproject ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateCODWithNewDproject(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateQKWithNewDproject: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onImportCODTable(action: any) {
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.ImportCODExcelTable(action.frmData);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateCOD: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onUpdateCOD(action: any) {
    console.log('onUpdateCOD ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.UpdateCODRow(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onUpdateQk: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDeleteCOD(action: any) {
    console.log('onDeleteCOD ', action.id);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.DeleteCODRow(action.id, action.forEbit);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onDeleteCOD: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}


function* onCreateInno(action: any) {
    console.log('onCreateInno ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateInnoRow(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateInno: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onCreateInnoWithNewDproject(action: any) {
    console.log('onCreateInnoWithNewDproject ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateInnoWithNewDproject(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateInnoWithNewDproject: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onImportInnoTable(action: any) {
    console.log("Mousa action", action.frmData )
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.ImportInnoExcelTable(action.frmData);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onImportInno: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onUpdateInno(action: any) {
    console.log('onUpdateInno ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.UpdateInnoRow(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onUpdateInno: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDeleteInno(action: any) {
    console.log('onDeleteInno ', action.id);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.DeleteInnoRow(action.id, action.forEbit);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onDeleteInno: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}


function* onCreateMC(action: any) {
    console.log('onCreateMC ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateMCRow(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateMC: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onCreateMCWithNewDproject(action: any) {
    console.log('onCreateMCWithNewDproject ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.CreateMCWithNewDproject(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onCreateMCWithNewDproject: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onImportMCTable(action: any) {
    console.log('onImportMCTable ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.ImportMCExcelTable(action.frmData);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onImportMC: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onUpdateMC(action: any) {
    console.log('onUpdateMC ', action.model);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.UpdateMCRow(action.model);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.model.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onUpdateMC: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onDeleteMC(action: any) {
    console.log('onDeleteMC ', action.id);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.DeleteMCRow(action.id, action.forEbit);

        if (!_result.isError) {
            let result = _result.result as DProjectForEbit[];
            if (action.forEbit) {
                yield put({ type: ActionTypes.SaveDprojectsForEbit, result: result });
            }
            else
                yield put({ type: ActionTypes.saveDProjectsForSelectedProject, result: result });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onDeleteMC: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}


function* onGetEstimate(action: any) {
    console.log('onGetEstimate ', action.qkId);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.GetEstimation();

        if (!_result.isError) {
            let result = _result.result as Estimate;
            yield put({ type: ActionTypes.SetEbitEstimation, result: result });
            //yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetEstimate: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onGetEvaluation(action: any) {
    //console.log('onGetEvaluation ', action.projectID);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.GetEvaluation();

        if (!_result.isError) {
            let result = _result.result as EvaluationData;
            yield put({ type: ActionTypes.SetEbitEvaluation, result: result });
            // yield put({ type: ActionTypes.GETPROJECTS })
            //yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetEvaluation: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onGetEBITBenchmark(action: any) {
    console.log('onGetBenchmark ', action.projectID);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.GetEbitBenchmark();
        if (!_result.isError) {
            let result = _result.result as EBITBenchmarkData;
            yield put({ type: ActionTypes.SetEbitBenchmark, result: result });
            // yield put({ type: ActionTypes.GETPROJECTS })
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetEBITBenchmark: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onGetEBITProgress(action: any) {
    console.log('onGetEBITProgress ', action.projectID);
    try {
        // yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.GetEbitProgress();
        if (!_result.isError) {
            let result = _result.result as number;
            yield put({ type: ActionTypes.SetEbitProgress, result: result });
            // yield put({ type: ActionTypes.GETPROJECTS })
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        // yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetEBITBenchmark: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* onSaveEstimate(action: any) {
    console.log('onSaveEstimate ', action.qkId);
    try {
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: true });
        let _result: ICommonResponse = yield EbitService.SaveEstimation(action.model);

        if (!_result.isError) {
            let result = _result.result as Estimate;
            yield put({ type: ActionTypes.SetEbitEstimation, result: result });
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.changeEbitLoader, isLoading: false });
    } catch (error) {
        console.error('ERR in onDeleteQk: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}
function* watchGetEBITBenchmark() {
    yield takeEvery(ActionTypes.GetEbitBenchmark, onGetEBITBenchmark)
}
function* watchGetEBITProgress() {
    yield takeEvery(ActionTypes.GetEBITProgress, onGetEBITProgress)
}

function* watchDownloadEstimationReport() {
    yield takeEvery(ActionTypes.DownloadEstimationReport, onDownloadEstimationReport)
}
function* watchDownloadDisciplinesVsBestPractice() {
    yield takeEvery(ActionTypes.DownloadDisciplinesVsBestPractice, onDownloadDisciplinesVsBestPractice)
}
function* watchDownloadDisciplinesExcel() {
    yield takeEvery(ActionTypes.DownloadDisciplinesExcel, onDownloadDisciplinesExcel)
}
function* watchDownloadFullExcelReport() {
    yield takeEvery(ActionTypes.DownloadFullExcelReport, onDownloadFullExcelReport)
}
function* watchDownloadPresentation() {
    yield takeEvery(ActionTypes.DownloadPresentation, onDownloadPresentation)
}
function* watchDownloadCODTable() {
    yield takeEvery(ActionTypes.DownloadCODTable, onDownloadCODTable)
}
function* watchImportCODTable() {
    yield takeEvery(ActionTypes.ImportCODTable, onImportCODTable)
}
function* watchImportInnoTable() {
    yield takeEvery(ActionTypes.ImportInnoTable, onImportInnoTable)
}
function* watchImportQKTable() {
    yield takeEvery(ActionTypes.ImportQKTable, onImportQKTable)
}
function* watchImportMCTable() {
    yield takeEvery(ActionTypes.ImportMCTable, onImportMCTable)
}
function* watchDownloadHKTable() {
    yield takeEvery(ActionTypes.DownloadHKTable, onDownloadHKTable)
}
function* watchDownloadQKTable() {
    yield takeEvery(ActionTypes.DownloadQKTable, onDownloadQKTable)
}
function* watchDownloadInnoTable() {
    yield takeEvery(ActionTypes.DownloadInnoTable, onDownloadInnoTable)
}
function* watchDownloadCostTypesExcel() {
    yield takeEvery(ActionTypes.DownloadCostTypesExcel, onDownloadCostTypesExcel)
}
function* watchDownloadRDTableExcel() {
    yield takeEvery(ActionTypes.DownloadRDTableExcel, onDownloadRDTableExcel)
}

function* watchRndInfo() {
    yield takeEvery(ActionTypes.GetRndInfo, onGetRnd)
}
function* watchonGetSavedRnd() {
    yield takeEvery(ActionTypes.GetRecivedRndUnits, onGetSavedRnd)
}
function* watchUpdateEbitRndLocalRow() {
    yield takeEvery(ActionTypes.UpdateRdUnitsRow, onUpdateEbitRndLocalRow)
}
function* watchCreateNewUnit() {
    yield takeEvery(ActionTypes.CreateNewUnit, onCreateNewUnit)
}

function* watchUpdateUnit() {
    yield takeEvery(ActionTypes.UpdateUnit, onUpdateUnit)
}

function* watchDeleteUnit() {
    yield takeEvery(ActionTypes.DeleteUnit, onDeleteUnit)
}
function* watchSaveUnitCostsChanges() {
    yield takeEvery(ActionTypes.SaveUnitCostsChanges, SaveUnitCostsChanges)
}
function* watchGetDprojects() {
    yield takeEvery(ActionTypes.GetDprojectsForEbit, onGetDprojects)
}

function* watchDeleteQk() {
    yield takeEvery(ActionTypes.DeleteQk, onDeleteQk)
}
function* watchCreateQk() {
    yield takeEvery(ActionTypes.CreateQk, onCreateQk)
}
function* watchCreateQKWithNewDproject() {
    yield takeEvery(ActionTypes.CreateQkNewDproject, onCreateQKWithNewDproject)
}
function* watchUpdateQk() {
    yield takeEvery(ActionTypes.UpdateQk, onUpdateQk)
}

function* watchDeleteCOD() {
    yield takeEvery(ActionTypes.DeleteCOD, onDeleteCOD)
}
function* watchCreateCOD() {
    yield takeEvery(ActionTypes.CreateCOD, onCreateCOD)
}
function* watchCreateCODWithNewDproject() {
    yield takeEvery(ActionTypes.CreateCODNewDproject, onCreateCODWithNewDproject)
}
function* watchUpdateCOD() {
    yield takeEvery(ActionTypes.UpdateCOD, onUpdateCOD)
}


function* watchDeleteInno() {
    yield takeEvery(ActionTypes.DeleteInno, onDeleteInno)
}
function* watchCreateInno() {
    yield takeEvery(ActionTypes.CreateInno, onCreateInno)
}
function* watchCreateInnoWithNewDproject() {
    yield takeEvery(ActionTypes.CreateInnoNewDproject, onCreateInnoWithNewDproject)
}
function* watchUpdateInno() {
    yield takeEvery(ActionTypes.UpdateInno, onUpdateInno)
}

function* watchDeleteMC() {
    yield takeEvery(ActionTypes.DeleteMC, onDeleteMC)
}
function* watchCreateMC() {
    yield takeEvery(ActionTypes.CreateMC, onCreateMC)
}
function* watchCreateMCWithNewDproject() {
    yield takeEvery(ActionTypes.CreateMCNewDproject, onCreateMCWithNewDproject)
}
function* watchUpdateMC() {
    yield takeEvery(ActionTypes.UpdateMC, onUpdateMC)
}

function* watchGetEstimation() {
    yield takeEvery(ActionTypes.GetEbitEstimation, onGetEstimate)
}

function* watchSaveEstimation() {
    yield takeEvery(ActionTypes.SaveEbitEstimation, onSaveEstimate)
}

function* watchGetEvaluation() {
    yield takeEvery(ActionTypes.GetEbitEvaluation, onGetEvaluation)
}


export default function* EbitPageSagas() {
    yield all([
        //watchBasicInfo(),
        watchRndInfo(),
        watchUpdateEbitRndLocalRow(),
        watchCreateNewUnit(),
        watchDeleteUnit(),
        watchGetDprojects(),
        watchDownloadEstimationReport(),
        watchDownloadDisciplinesVsBestPractice(),
        watchDownloadDisciplinesExcel(),
        watchDownloadFullExcelReport(),
        watchDownloadPresentation(),
        watchDownloadCODTable(),
        watchImportCODTable(),
        watchImportInnoTable(),
        watchImportQKTable(),
        watchImportMCTable(),
        watchDownloadHKTable(),
        watchDownloadQKTable(),
        watchDownloadInnoTable(),
        watchDownloadCostTypesExcel(),
        watchDownloadRDTableExcel(),
        watchCreateQk(),
        watchCreateQKWithNewDproject(),
        watchDeleteQk(),
        watchUpdateQk(),

        watchCreateCOD(),
        watchCreateCODWithNewDproject(),
        watchDeleteCOD(),
        watchUpdateCOD(),

        watchCreateInno(),
        watchCreateInnoWithNewDproject(),
        watchDeleteInno(),
        watchUpdateInno(),

        watchCreateMC(),
        watchCreateMCWithNewDproject(),
        watchDeleteMC(),
        watchUpdateMC(),

        watchUpdateUnit(),
        watchSaveUnitCostsChanges(),
        watchGetEstimation(),
        watchSaveEstimation(),
        watchGetEvaluation(),
        watchGetEBITBenchmark(),
        watchGetEBITProgress(),

        watchonGetSavedRnd(),
    ]);
}