import { Col, Row } from "antd";
import StackedColumnChart from "../BasicCharts/StackedColumnChart";
import { EBITProjectBenchmarkData, EBITBenchmarkData } from '../Models/EBITBenchmarkData';
import { useTranslation } from "react-i18next";
interface Iprops {
    ebitBenchmarkingInfo: EBITBenchmarkData;
    projectName: string;
    OriginalDisciplines: string[],
    originalDisciplinesEnglish: string[],
    selectedCharts?: string[],
    isTopLevelChart: boolean,
    selectChart?: (chartDivId: string, add: boolean) => void;
}
const DisciplinesVSBestPractice = (props: Iprops) => {
    const { t } = useTranslation();
    const benchmarkData = props.ebitBenchmarkingInfo;
    const GetProjectDiciplinesRates = () => {
        let currentProjectDisciplines: EBITProjectBenchmarkData = benchmarkData?.projectsBenchmarks.find(x => x.projectName === props.projectName);
        return currentProjectDisciplines?.projectDisciplinesBenchmark?.filter(s => s.color)?.map(x => {
            return {
                name: x.name,
                englishName: x.englishName,
                projectName: props.projectName,
                rate: x.rate,
                viewOrder: x.viewOrder,
                color: x.color,
                isOriginal: props.OriginalDisciplines?.includes(x.name.toLowerCase())
                    || props.originalDisciplinesEnglish?.includes(x.englishName.toLowerCase()),
            }
        }
        );
    }
    const GetDiciplinesBestPractice = () => {
        return benchmarkData?.disciplinesBestPractices?.map(x => {
            let viewOrder = benchmarkData?.projectsBenchmarks[0]?.projectDisciplinesBenchmark?.find(s => s.name == x.name)?.viewOrder || -1;
            let color = benchmarkData?.projectsBenchmarks[0]?.projectDisciplinesBenchmark?.find(s => s.name == x.name)?.color;
            return {
                name: x.name,
                projectName: "Best Practices",
                englishName: x.englishName,
                rate: x.bestPractice?.value ?? 0,
                viewOrder: viewOrder,
                color: color,
                isOriginal: props.OriginalDisciplines?.includes(x.name.toLowerCase())
                    || props.originalDisciplinesEnglish?.includes(x.englishName.toLowerCase()),
            }
        }
        );

    }
    const SummaryDisciplinesNames = [t("Gesamte F&E-Kosten").toLowerCase(), t("Interdisziplinäre F&E Kosten").toLowerCase(), t("Originäre F&E Kosten").toLowerCase()]
    let originalDiscipines = GetProjectDiciplinesRates()?.filter(x => x.isOriginal && !SummaryDisciplinesNames.includes(x.name.toLowerCase()) && !SummaryDisciplinesNames.includes(x.englishName.toLowerCase()));
    let originalDisciplinesBestPractice = GetDiciplinesBestPractice()?.filter(x => x.isOriginal && !SummaryDisciplinesNames.includes(x.name.toLowerCase()) && !SummaryDisciplinesNames.includes(x.englishName.toLowerCase()));
    let interDisciplines = GetProjectDiciplinesRates()?.filter(x => !x.isOriginal && !SummaryDisciplinesNames.includes(x.name.toLowerCase()) && !SummaryDisciplinesNames.includes(x.englishName.toLowerCase()));
    let interDisciplinesBestPractice = GetDiciplinesBestPractice()?.filter(x => !x.isOriginal && !SummaryDisciplinesNames.includes(x.name.toLowerCase()) && !SummaryDisciplinesNames.includes(x.englishName.toLowerCase()));
    return (
        <div style={{ height: props.isTopLevelChart ? '27vh' : null }}>
            <Row gutter={[16, 24]}>
                <Col span={12}>
                    <StackedColumnChart selectedCharts={props.selectedCharts} selectChart={props.selectChart} isTopLevelChart={props.isTopLevelChart} chartTitle={t("Verteilung der originären F&E-Kosten")} topLevelTitle={t("Verteilung der originären")} data={originalDiscipines} bestPracticeRates={originalDisciplinesBestPractice} />
                </Col>
                <Col span={12}>
                    <StackedColumnChart selectedCharts={props.selectedCharts} selectChart={props.selectChart} isTopLevelChart={props.isTopLevelChart} chartTitle={t("Verteilung der interdisziplinären F&E-Kosten")} topLevelTitle={t("Verteilung der interdisziplinären")} data={interDisciplines} bestPracticeRates={interDisciplinesBestPractice} />
                </Col>
                {!props.isTopLevelChart && (
                <>
                <Col span={12}>
                    <StackedColumnChart isPercent={true} selectedCharts={props.selectedCharts} selectChart={props.selectChart} isTopLevelChart={props.isTopLevelChart} chartTitle={t("Verteilung der originären F&E-Kosten")+" "} data={originalDiscipines} bestPracticeRates={originalDisciplinesBestPractice} />
                </Col>
                <Col span={12}>
                    <StackedColumnChart isPercent={true} selectedCharts={props.selectedCharts} selectChart={props.selectChart} isTopLevelChart={props.isTopLevelChart} chartTitle={t("Verteilung der interdisziplinären F&E-Kosten")+" "} data={interDisciplines} bestPracticeRates={interDisciplinesBestPractice} />
                </Col></>)}
            </Row>
        </div>
    );
};

export default DisciplinesVSBestPractice;