import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { ToastMessages } from "../Enums/Messages";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import { ItemCustomerManagementDT } from "../Models/ItemAdminTable/Items";
export default class CustomersService {
  static async GetCustomer(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getCustomers, {})
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async CreateCustomerRow(
    model: ItemCustomerManagementDT
  ): Promise<ICommonResponse> {
    var formData = new FormData();
    formData.append("logo", "");
    formData.append("File", model.logo);
    formData.append("currencyId", model.currencyId.toString());
    formData.append("defaultLanguageId", model.defaultLanguageId.toString());
    formData.append("name", model.name);
    formData.append("location", model.location);
    console.log("CreateCustomerRow", formData);
    return await asupapi.post(endPoints.CreateCustomer, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(function (response) {
      let result: ICommonResponse = response.data;
      return result;
    }).catch(function (error) {
      if (error.response) {
        console.log("error1 ", error.response);
        if (error.response.data) {
          console.log("error0 ", error.response.data);
          let result: ICommonResponse = error.response.data;
          return result;
        }
      } else if (error.request) {
        console.log("error2 ", error.request);
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.NetworkError,
          },
        } as ICommonResponse;
      } else if (error.message) {
        console.log("error3 ", error.message);
        //do something other than the other two
      }
      return {
        isError: false,
        statusCode: -400,
        error: {
          exceptionMessage: ToastMessages.UnExpectedError,
        },
      } as ICommonResponse;
    });
  }
  static async DeleteCustomer(id: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.DeleteCustomer, {
        params: {
          customerId: id,
        },
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async UpdateCustomer(model: ItemCustomerManagementDT): Promise<ICommonResponse> {
    var formData = new FormData();
    formData.append("logo", "");
    formData.append("id", model.id.toString());
    formData.append("File", model.logo);
    formData.append("currencyId", model.currencyId.toString());
    formData.append("defaultLanguageId", model.defaultLanguageId.toString());
    formData.append("name", model.name);
    formData.append("location", model.location);
    console.log("UpdateCustomer", formData);
    return await asupapi.post(endPoints.updateCustomer, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(function (response) {
      let result: ICommonResponse = response.data;
      return result;
    }).catch(function (error) {
      if (error.response) {
        console.log("error1 ", error.response);
        if (error.response.data) {
          console.log("error0 ", error.response.data);
          let result: ICommonResponse = error.response.data;
          return result;
        }
      } else if (error.request) {
        console.log("error2 ", error.request);
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.NetworkError,
          },
        } as ICommonResponse;
      } else if (error.message) {
        console.log("error3 ", error.message);
        //do something other than the other two
      }
      return {
        isError: false,
        statusCode: -400,
        error: {
          exceptionMessage: ToastMessages.UnExpectedError,
        },
      } as ICommonResponse;
    });
  }
  static async DeleteImageCustomer(customerId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.deleteImageCustomer, {
        params: {
          customerId: customerId
        },
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
}