import { Form, Input, InputNumber, Table, Typography } from 'antd';
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux';
import { ISelfAssessmentTempBp } from '../../../../Models/ISelfAssessmentTemp';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { RulesName } from '../../Rules/RulesValidation';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import AdminOperation from '../../../../Components/AdminOperation/AdminOperation';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { t } from 'i18next';
interface IProps {
  selfAssessmentBpData: ISelfAssessmentTempBp[];
  subLeverId: number;
  updateBestPractice: (model: ISelfAssessmentTempBp) => any;
  deleteBestPractice: (id: number) => any;
}
const SelfAssessmentBpTable = (props: IProps) => {
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  //search code start
  const searchInput = useRef<any>(null);
  const handleSearch = (
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };
  //search code end
  const columns: any[] = [
    {
      key: "bp_En",
      title: `${t("English Name")}`,
      dataIndex: 'nameEn',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='nameEn' rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })} initialValue={text} >
              <Input />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
      ...getColumnSearchProps(`${t("English Name")}`, "nameEn", searchInput, handleSearch),
      sorter: (a, b) => a?.nameEn?.localeCompare(b?.nameEn) ?? 0,
      defaultSortOrder: 'ascend',
    },
    {
      key: "bp_de",
      title: `${t("German Name")}`,
      dataIndex: 'nameDe',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='nameDe' rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })} initialValue={text}>
              <Input />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
      ...getColumnSearchProps(`${t("German Name")}`, "nameDe", searchInput, handleSearch),
      sorter: (a, b) => a?.nameDe?.localeCompare(b?.nameDe) ?? 0,
    }, {
      key: "bp_sn",
      title: `${t("Serial Number")}`,
      dataIndex: 'serialNumber',
      width: "10%",
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name="serialNumber" >
              <InputNumber disabled />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }

      },
    },
    {
      title: `${t("Actions")}`,
      dataIndex: '',
      width: '10%',
      render: (_: any, record: ISelfAssessmentTempBp) => {
        const editable = isEditing(record);
        return <AdminOperation
          deleteId={record.id}
          record={record}
          editable={editable}
          onCancel={cancel}
          onEdit={edit}
          onDelete={deleteBestPractice}

        />
      },
    },
  ];
  const isEditing = (record: ISelfAssessmentTempBp) => record.key === editingKey;
  const edit = (record: Partial<ISelfAssessmentTempBp> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const onFinish = async (values: any) => {
    try {
      await form.validateFields()
      const index = props.selfAssessmentBpData.findIndex(item => item.key === editingKey);
      if (index > -1) {
        const item = props.selfAssessmentBpData[index];
        const updateBestPractice: ISelfAssessmentTempBp = { id: item.id, subLeverId: props.subLeverId, ...values };
        await props.updateBestPractice(updateBestPractice);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }

  }
  const deleteBestPractice = async (id: any) => {
    try {
      await props.deleteBestPractice(id)
    } catch (error) {
      console.log('delete Failed:', error);
    }
  };
  return (
    <Form form={form} key="seg_form" onFinish={onFinish}>
      <Table
        className='table_seg'
        getPopupContainer={() => document.getElementById("app-layout")}
        key='seg_table'
        bordered
        dataSource={props.selfAssessmentBpData}
        columns={columns}
        rowClassName="editable-row"
        scroll={{ y: "calc(100vh - 300px)" }}
        pagination={{
          defaultPageSize: 50,
          pageSizeOptions: [50, 100, 150],
          total: props.selfAssessmentBpData.length,
          showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
          showSizeChanger: true,
          locale: { items_per_page: `/ ${t("Page")}` },
        }}
        locale={{
          triggerDesc: t('Click to sort descending'),
          triggerAsc: t('Click to sort ascending'),
          cancelSort: t('Click to cancel sorting')
        }}

      />
    </Form>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateBestPractice: (model: ISelfAssessmentTempBp) => dispatch({ type: ActionTypes.UPDATE_SELF_ASSESSMENT_BP_TEMP, model }),
    deleteBestPractice: (id: number) => dispatch({ type: ActionTypes.DELETE_SELF_ASSESSMENT_BP_TEMP, id })
  };
};

export default connect(null, mapDispatchToProps)(SelfAssessmentBpTable);
