import { Space } from 'antd'
import React from 'react'
import { ThemeSwitcher } from '../Switcher'
import LanguageSwitcher from '../Switcher/LanguageSwitcher'

const index = () => {
    return (
        <Space style={{ position: "relative" }}>
            <span style={{ position: "absolute", top: "18px", right: "2%" }}>
                <Space><LanguageSwitcher />
                    <ThemeSwitcher />
                </Space>
            </span>
        </Space>
    )
}

export default index