import { Alert, Empty, Form, InputNumber, Popconfirm, Spin, Table, Tag, Typography, } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { PlusCircleTwoTone, MinusCircleTwoTone, DeleteOutlined, } from "@ant-design/icons";
import { connect } from 'react-redux';
import { t } from 'i18next';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { IActivityProfileVersionDetail, IActivityTask, IMapTaskToGroup } from '../../../../Models/IActivityManagement';
import { RulesRequire } from '../../Rules/RulesValidation';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import TaskActivitiesModal from '../TaskActivitiesModal';
import AdminOperation from '../../../../Components/AdminOperation/AdminOperation';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import MapTaskToGroupModal from './MapTaskToGroup/MapTaskToGroupModal';
import { Button } from 'antd/lib/radio';
import { useSearchParams } from 'react-router-dom';
import { IGeneralState } from '../../../../Redux/reducers/IGeneralState';


interface IProps {
    //originDataRow: any[];
    profileVersionData: IActivityProfileVersionDetail;
    isLoading: boolean;
    isModalLoading: boolean;
    editMapTaskInGroup: (model: IMapTaskToGroup, profileVersionId: number) => any;
    deleteTaskFromGroup: (id: number, profileVersionId: number) => any;
    //profileVersionId: number;
    //activityProfileId: number;
    deleteMapGroupToProfile: (groupId: number, profileVersionId: number) => any;
    getProfileVersionDetails: (profileVersionId: number) => any;
}

const GroupActivityVersionTable = (props: IProps) => {

    const [editingKey, setEditingKey] = useState(null);
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const profileVersionId = Number(searchParams.get('profileVersionId'));
    const profileId = Number(searchParams.get('profileId'));
    useEffect(() => {
        props.getProfileVersionDetails(profileVersionId);
    }, [profileVersionId]);
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end


    const columns: any[] = [
        {
            key: "Serial-number-key",
            title: `${t("Serial Number")}`,
            dataIndex: 'serialNumber',

            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,

        },
        {
            key: "name_en-key",
            title: `${t("English Name")}`,
            dataIndex: 'nameEn',
            //...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),

            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,

        },
        {
            key: "name_de-key",
            title: `${t("German Name")}`,
            dataIndex: 'nameDe',
            //...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),
            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,

        },
        {
            key: "bp-key",
            title: `${t("Best Practice")}`,
            dataIndex: 'bestPracticeValue',
            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,

        },


    ]
    // NESTED TABLE 1
    const expandedRowRenderI = (record: any) => {

        let tasks = record?.versionTasks?.map(t => { return { ...t, key: `task_${t.id}` } })?.sort(
            (a, b) => a?.serialNumberInGroup - b?.serialNumberInGroup
        )
        let groupId = record?.id
        const columns: any[] = [

            {
                key: "serial-group_key",
                title: `${t("Serial Number In Group")}`,
                dataIndex: 'serialNumberInGroup',
                width: '15%',
                render: (text: any, record: IActivityTask) => {
                    if (editingKey === record.id) {
                        return (
                            <Form.Item name='serialNumberInGroup'
                                rules={RulesRequire({ filedName: `${t("Serial Number")}` })}
                                hasFeedback>
                                <InputNumber />
                            </Form.Item>
                        )
                    } else {
                        return <Typography.Text>{text}</Typography.Text>
                    }
                }
            },
            {
                key: "task_textEn",
                title: `${t("English Text")}`,
                dataIndex: 'textEn',
                width: "20%",
                render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,
                //...getColumnSearchProps(`${t("English Text")}`, 'textEn', searchInput, handleSearch),

            },
            {
                key: "task_textDe",
                title: `${t("German Text")}`,
                dataIndex: 'textDe',
                width: "20%",
                render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,
                //...getColumnSearchProps(`${t("German Text")}`, 'textDe', searchInput, handleSearch),
            },
            {
                key: "tasks_activities",
                title: `${t("Activities")}`,
                dataIndex: 'activities',
                className: "tasks_dynamicFields",
                width: "15%",
                render: (text: any, record: any) => {
                    if (record?.activities?.length > 0) {
                        return <TaskActivitiesModal activities={record?.activities} />
                    } else {
                        return;
                    }
                }
            },
            {
                title: `${t("Actions")}`,
                dataIndex: 'Actions',
                width: '10%',
                render: (_: any, record: IActivityTask) => {
                    const editable = isEditing(record);
                    return <AdminOperation
                        deleteId={record.id}
                        record={record}
                        editable={editable}
                        onCancel={cancel}
                        onEdit={edit}
                        onDelete={deleteTask}
                    />
                },
            },
        ]
        const isEditing = (record: IActivityTask) => record.id === editingKey;
        const edit = (record: Partial<IActivityTask>) => {
            form.setFieldsValue({ ...record });
            setEditingKey(record.id);
        };
        const cancel = () => {
            setEditingKey(null);
        };
        const onFinish = async (values: IActivityTask) => {
            try {
                await form.validateFields()
                const newData = [...tasks];
                const index = newData.findIndex(item => item.id === editingKey);
                if (index > -1) {
                    const item = newData[index];
                    const taskEdit: IMapTaskToGroup = {
                        id: item.id,
                        taskId: item.taskId,
                        profileVersionId: profileVersionId,
                        groupId: groupId,
                        serialNumberInGroup: values.serialNumberInGroup
                    }
                    await props.editMapTaskInGroup(taskEdit, profileVersionId)
                    setEditingKey(null);
                }
            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
        }
        const deleteTask = async (id: any) => {
            try {
                await props.deleteTaskFromGroup(id, profileVersionId);
            } catch (error) {
                console.log('delete Failed:', error);
            }
        }
        const deleteMapGroupToProfile = async (groupId: number, versionId: number) => {
            try {
                await props.deleteMapGroupToProfile(groupId, profileVersionId)
            } catch (errInfo) {
                console.log('delete map Group Failed:', errInfo);
            }
        }

        return (
            <Form form={form} key={`key-form_${groupId}`} onFinish={onFinish} name={`form-name_${groupId}`}>
                <Table dataSource={tasks}
                    columns={columns}
                    pagination={false}
                    rowKey={"id"}
                    key={`key_table_${groupId}`}
                    bordered getPopupContainer={() => document.getElementById("app-layout")}
                    title={() =>
                        <>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Tag color="#58bf49" style={{ padding: ".4rem 2.5rem", fontSize: "1.1rem" }}>
                                    {`${t("Tasks")}`}</Tag>
                                <div style={{ display: "flex", columnGap: "1rem" }}>
                                    <MapTaskToGroupModal profileVersionId={profileVersionId}
                                        activityProfileId={profileId}
                                        groupId={groupId}
                                        tasksInGroup={tasks} />
                                    <Popconfirm title={`${t("Sure to Delete Map Group")}`}
                                        onConfirm={() => deleteMapGroupToProfile(groupId, profileVersionId)}
                                        getPopupContainer={() => document.getElementById("app-layout")}
                                        cancelText={`${t("Cancel")}`}
                                    >
                                        <Button style={{ backgroundColor: "red", color: "#fff" }} >
                                            <DeleteOutlined color='red' style={{ marginRight: ".3rem" }} />
                                            {`${t("Delete all tasks in the group")}`}
                                        </Button>
                                    </Popconfirm>
                                </div>
                            </div>
                        </>
                    }
                    size='small'
                    className='table_map-tasks'
                />
            </Form>
        )
    }
    const [totalDataSourceLength, setTotalDataSourceLength] = useState(props.profileVersionData?.profileGroups?.length)
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: ".5rem" }}>
                    <Tag color="#496dbf" style={{ float: "left", padding: ".4rem 2.5rem", fontSize: "1.1rem" }}>
                        {`${t("Groups")}`}</Tag>
                    <Alert
                        message={
                            `${t("The empty group is not shown in the activity analysis nor the voting")}`}
                        banner />

                </div>
                {(props.profileVersionData?.profileGroups && props.profileVersionData?.profileGroups.length > 0) ?
                    <Table
                        rowKey={"id"}
                        getPopupContainer={() => document.getElementById("app-layout")}
                        className='table_map-group leverMng_table '
                        key='groups_tasks_table'
                        bordered
                        expandable={{
                            defaultExpandAllRows: false,
                            //rowExpandable: (record) => true,
                            expandedRowRender: (record) => {
                                return expandedRowRenderI(record);
                            },
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                ) : (
                                    <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                )
                        }}
                        pagination={false}
                        dataSource={props.profileVersionData?.profileGroups}
                        columns={columns}
                        rowClassName="editable-row"
                        scroll={{ y: "calc(100vh - 350px)" }}
                    // pagination={{
                    //     defaultPageSize: 50,
                    //     pageSizeOptions: [50, 100, 150],
                    //     total: totalDataSourceLength,
                    //     showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                    //     showSizeChanger: true,
                    //     locale: { items_per_page: `/ ${t("Page")}` },
                    // }}
                    // locale={{
                    //     filterReset: `${t("Reset")}`, triggerDesc: t('Click to sort descending'),
                    //     triggerAsc: t('Click to sort ascending'),
                    //     cancelSort: t('Click to cancel sorting')
                    // }}
                    // onChange={function (pagination, filters, sorter, extra: { currentDataSource: [], action: any }) {
                    //     return setTotalDataSourceLength(extra.currentDataSource.length);
                    // }}
                    />
                    : <Empty description={"There are no groups or tasks in the profile template"}  />}


            </div>
        </Spin >
    );
};

const mapStateToProps = (state: IGeneralState) => {
    return {
        profileVersionData: state.activityProfileVersions.activityProfileVersionDetailsForAdminResult,
        isLoading: state.activityProfileVersions.isActivityProfileVersionForAdminLoading,
        isModalLoading: state.activityProfileVersions.isModalLoading,
        modalResponse: state.activityProfileVersions.modalResponse,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getProfileVersionDetails: (profileVersionId: number) => dispatch({ type: ActionTypes.GET_ACTIVITY_PROFILE_VERSION_Detail, profileVersionId: profileVersionId }),
        editMapTaskInGroup: (model: IMapTaskToGroup, profileVersionId: number) => dispatch({ type: ActionTypes.EDIT_MAP_TASK_GROUP, model, profileVersionId }),
        deleteTaskFromGroup: (id: number, profileVersionId: number) => dispatch({ type: ActionTypes.DELETE_TASK_FROM_GROUP, id, profileVersionId }),
        deleteMapGroupToProfile: (groupId: number, profileVersionId: number) => dispatch({ type: ActionTypes.DELETE_MAP_GROUP_TO_PROFILE, groupId, versionId: profileVersionId, profileVersionId: profileVersionId })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupActivityVersionTable);



