import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Input, InputNumber, Popconfirm, Space, Table, Tooltip, Typography, } from 'antd';
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { ISegmentDisciplineBestPracticeItem, ISegmentDisciplineBestPracticeItemWithKey, ISegmentDisciplineBestPracticeUpdate, IndustrySegmentBestPracticeDisciplinesList } from '../../../Models/ISegmentDisciplineBestPractice';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { formatValue, inputFormatter, inputParser } from '../../../Utils/helperFunctions';
import i18n from '../../../i18n';
import { t } from 'i18next';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
interface IProps {
    industrySegmentId: number;
    allSegmentDisciplineBp: ISegmentDisciplineBestPracticeItem[];
    segmentDisciplineBpData: ISegmentDisciplineBestPracticeItemWithKey[];
    updateSegmentDisciplineBp: (model: IndustrySegmentBestPracticeDisciplinesList) => any;
    clearSegmentDisciplineBp: (industrySegmentId: number) => any;
}
const SegmentDisciplineBPTable = (props: IProps) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [form] = Form.useForm();
    const [sumOfBP, setSumOfBp] = useState(0);
    useEffect(() => {
        form.resetFields();
        let tempSum = 0;
        props.segmentDisciplineBpData.map(cc => {
            form.setFieldValue(`bestPracticeMin_${cc.disciplineId}`, cc.bestPracticeMin ?? '');
            form.setFieldValue(`bestPracticeMax_${cc.disciplineId}`, cc.bestPracticeMax ?? '');
            form.setFieldValue(`bestPractice_${cc.disciplineId}`, cc.bestPractice ?? '');
            if (cc.bestPractice) tempSum = tempSum + cc.bestPractice;
        });
        setSumOfBp(tempSum);
    }, [props.allSegmentDisciplineBp]);
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "segDis_bestP_1",
            title: "Discipline",
            dataIndex: "disciplineName",
            render: (text: any, _: any) => {
                return <Typography.Text strong>{text}</Typography.Text>
            },
            ...getColumnSearchProps('Name Discipline', 'disciplineName', searchInput, handleSearch),
            sorter: (a, b) => a?.disciplineName?.localeCompare(b?.disciplineName) ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: "segDis_bestP_2",
            title: " MIN",
            dataIndex: "bestPracticeMin",
            render: (text: any, record: any) => {
                return <Form.Item name={`bestPracticeMin_${record.disciplineId}`} key={`bestPracticeMin_${record.disciplineId}`}
                    rules={[{ required: true, message: `` }]}>
                    <InputNumber
                        addonAfter="%"
                        min={0} max={100}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                    />
                </Form.Item>
            }
        },
        {
            key: "segDis_bestP_3",
            title: " BP",
            dataIndex: "bestPractice",
            render: (text: any, record: any) => {
                return <Form.Item name={`bestPractice_${record.disciplineId}`} key={`bestPractice_${record.disciplineId}`} rules={[{ required: true, message: `` }]}>
                    <InputNumber
                        onChange={onChange}
                        min={0} max={100}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%"
                    />
                </Form.Item>
            }
        },
        {
            key: "segDis_bestP_4",
            title: "MAX",
            dataIndex: "bestPracticeMax",
            render: (text: any, record: any) => {
                return <Form.Item name={`bestPracticeMax_${record.disciplineId}`} key={`bestPracticeMax_${record.disciplineId}`} rules={[{ required: true, message: `` }]}>
                    <InputNumber
                        min={0} max={100}
                        formatter={(value) => inputFormatter(value, i18n.language)}
                        parser={(x) => inputParser(x, i18n.language)}
                        addonAfter="%"
                    />
                </Form.Item>
            }
        }
        // ,
        // {
        //     key: "segDis_best_operation",
        //     title: `${t("ِActions")}`,
        //     dataIndex: "",
        //     fixed: "right",
        //     width: "10%",
        //     render: (_: any, record: any) => {
        //         return <Space size={6}>
        //             <Tooltip title={`${t("Save")}`} getPopupContainer={() => document.getElementById("app-layout")}>
        //                 <SaveOutlined style={{ fontSize: "1.5rem" }} onClick={() => onFormValueHandler(record.disciplineId)} />
        //             </Tooltip>
        //             <Popconfirm title={`${t("Sure to Clear All?")}`} cancelText={`${t("Cancel")}`} onConfirm={async () => await onClearAll(record?.id, record.industrySegmentId)} getPopupContainer={() => document.getElementById("app-layout")} disabled={!record.id}>
        //                 <Button disabled={!record.id} style={{ border: 'none', backgroundColor: "inherit" }}>
        //                     <DeleteOutlined style={{ fontSize: "1.5rem" }} />
        //                 </Button>
        //             </Popconfirm >
        //         </Space>
        //     },
        // },
    ];
    const onClearAll = () => {
        try {
            props.clearSegmentDisciplineBp(props.industrySegmentId);
            //form.resetFields();
        } catch (error) {
            console.log(error);
        }
    }
    const onSaveAll = () => {
        try {
            setErrorMessage('');
            let model: IndustrySegmentBestPracticeDisciplinesList = {
                industrySegmentId: props.industrySegmentId,
                list: []
            };
            let hasError = false;
            //let sum:number = 0;
            let bestPractice;
            let bestPracticeMin;
            let bestPracticeMax;
            props.segmentDisciplineBpData.map(disc => {
                bestPractice = form.getFieldValue(`bestPractice_${disc.disciplineId}`) as number;
                bestPracticeMin = form.getFieldValue(`bestPracticeMin_${disc.disciplineId}`) as number;
                bestPracticeMax = form.getFieldValue(`bestPracticeMax_${disc.disciplineId}`) as number;
                //sum = sum + bestPractice;
                if (bestPractice >= 0 && bestPracticeMin >= 0 && bestPracticeMax >= 0) {
                    //console.log("FOOOOR", bestPracticeMin, bestPractice, bestPracticeMax);
                    if (bestPracticeMin > bestPractice) {
                        setErrorMessage(`${t("The Best Practice Min should less than or equal to Best Practice value in")}(${disc.disciplineName})`);
                        hasError = true;
                        return;
                    }
                    if (bestPracticeMax < bestPractice) {
                        setErrorMessage(`${t("The Best Practice Max should greater or equal to Best Practice value in")}(${disc.disciplineName})`);
                        hasError = true;
                        return;
                    }
                    model.list.push({
                        bestPractice: (bestPractice != '')? bestPractice : 0,
                        bestPracticeMax: (bestPracticeMax != '')? bestPracticeMax : 0,
                        bestPracticeMin: (bestPracticeMin != '')? bestPracticeMin : 0,
                        disciplineId: disc.disciplineId
                    });
                } else {
                    console.log("bestPractice,bestPracticeMin, bestPracticeMax", bestPractice, bestPracticeMin, bestPracticeMax, bestPractice >= 0 && bestPracticeMin >= 0 && bestPracticeMax >= 0, bestPractice != '' && bestPracticeMin != '' && bestPracticeMax != '');
                }
            })
            if (hasError) return;
            //const sum = model.list.reduce((accumulator: number, currentValue) => accumulator + currentValue.bestPractice, 0);
            if (sumOfBP != 100) {
                setErrorMessage(`${t("Sum of best practices is")}: ${formatValue(sumOfBP)}. ${t("It must be 100%")}`);
                return;
            } else {
                setErrorMessage('');
                props.updateSegmentDisciplineBp(model);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    const onChange = () => {
        let tempSum = 0;
        props.segmentDisciplineBpData.map(disc => {
            let bestPractice = form.getFieldValue(`bestPractice_${disc.disciplineId}`);
            if (bestPractice) {
                tempSum = tempSum + bestPractice;
            }
        });
        setSumOfBp(tempSum);
    }

    return (

        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
            <Alert message={`${t("All best practices percentages should add up to 100.")}`} type="info" showIcon />
            <Alert message={`${t("The remaining best practices percentages is")}: ${formatValue(100 - sumOfBP)}.`} type="info" showIcon />

            <Space size={6}>
                <Tooltip title={`${t("Save")}`} getPopupContainer={() => document.getElementById("app-layout")}>
                    <SaveOutlined style={{ fontSize: "1.2rem" }} onClick={() => onSaveAll()} />
                </Tooltip>
                <Popconfirm title={`${t("Sure to Clear All?")}`} cancelText={`${t("Cancel")}`}
                    onConfirm={() => onClearAll()} getPopupContainer={() => document.getElementById("app-layout")}
                >
                    <Button
                        //disabled={!record.id}
                        style={{ border: 'none', backgroundColor: "inherit" }}>
                        <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                    </Button>
                </Popconfirm>
            </Space>
            <Form form={form} component={false}>
                <Table
                    key='SegDis_best_practice_table'
                    bordered
                    dataSource={props.segmentDisciplineBpData}
                    columns={columns}
                    rowClassName="editable-row"
                    size="small"
                    scroll={{ y: "calc(100vh - 300px)" }}
                    pagination={{
                        defaultPageSize: 50,
                        pageSizeOptions: [50, 100, 150],
                        total: props.segmentDisciplineBpData.length,
                        showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                        showSizeChanger: true,
                        locale: { items_per_page: `/ ${t("Page")}` },
                    }}
                    locale={{
                        triggerDesc: t('Click to sort descending'),
                        triggerAsc: t('Click to sort ascending'),
                        cancelSort: t('Click to cancel sorting')
                    }}

                />
            </Form>
        </Space>
    );
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        allSegmentDisciplineBp: state.segmentDisciplineBP.segmentDisciplineBestPracticeResult,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {

        updateSegmentDisciplineBp: (model: IndustrySegmentBestPracticeDisciplinesList) => dispatch({ type: ActionTypes.UPDATE_SEGMENT_DISCIPLINE_BEST_PRACTICE, model: model }),
        clearSegmentDisciplineBp: (industrySegmentId: number) => dispatch({ type: ActionTypes.CLEAR_SEGMENT_DISCIPLINE_BEST_PRACTICE, industrySegmentId: industrySegmentId })
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SegmentDisciplineBPTable);


