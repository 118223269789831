import { Button, Form, Row, Col, Radio, Space, RadioChangeEvent, Input } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IVerificationCodeModel } from "../../../Models/Requests/AuthModels";
import { ILoginResponse, IVerifyLoginResponse } from "../../../Models/Responses/AuthResponse";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import { MailOutlined } from "@ant-design/icons";
import "./index.scss";
import { t } from "i18next";
import HeaderLangThemeSwitcher from "../../../Components/HeaderLangThemeSwitcher";


interface IVerificationProps {
  authResult: ILoginResponse;
  commonResult: ICommonResponse;
  verifyLoginResult: IVerifyLoginResponse;
  verifyLogin: (model: IVerificationCodeModel) => any;
}
function Verification(props: IVerificationProps) {
  const navigate = useNavigate();
  const [codesList, setCodesList] = useState([]);

  useEffect(() => {
    console.log("useEffect loginResult verify");
    if (props.authResult && props.authResult.randomCodes && props.authResult.randomCodes.length > 0) {
      setCodesList(props.authResult.randomCodes);
    }
  }, [props.authResult]);

  useEffect(() => {
    if (props.verifyLoginResult && props.verifyLoginResult.refreshToken) {
      if (localStorage.getItem("rememberme") != 'true') {
        sessionStorage.setItem("token", props.verifyLoginResult.token);
        sessionStorage.setItem("refreshToken", props.verifyLoginResult.refreshToken);
      }
      else {
        localStorage.setItem("token", props.verifyLoginResult.token);
        localStorage.setItem("refreshToken", props.verifyLoginResult.refreshToken);
      }
    }
  }, [props.verifyLoginResult]);


  const verify = (e: RadioChangeEvent) => {
    props.verifyLogin({
      token: e.target.value,
      email: localStorage.getItem('currentEmail'),
      provider: "Email"
    });
  }
  const codes = codesList.map((code, i) => {
    return (
      <Radio.Button value={code} key={`code_${i}`}>
        {code}
      </Radio.Button>
    )
  });
  return (<>
    <HeaderLangThemeSwitcher />
    <Row align="middle" justify="center" className="verification-list">
      <Col xs={96} sm={72} md={48} className="form-card">

        <div className="header-titel">
          <p>
            <span>{`${t("Reset password")}`}</span><br />
          </p>
        </div>
        <div className="header-activity">
          {`${t(`Choose the code sent to you at`)}`}
          <br />
          {localStorage.getItem('currentEmail')}
        </div>
        <Form
          className="form-card"
        >
          <Radio.Group onChange={verify.bind(this)} buttonStyle="solid">
            <Space size={20}>
              {codes}
            </Space>
          </Radio.Group>
        </Form>
      </Col>
    </Row >  </>
  )
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    authResult: state.authInfo.loginResult,
    commonResult: state.generalInfo.commonResponse,
    verifyLoginResult: state.authInfo.verifyLoginResult
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    verifyLogin: (model: IVerificationCodeModel) => dispatch({ type: ActionTypes.VerifyLogin, model: model }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Verification);