
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import { IIndustryBestPracticeItem } from "../../Models/IIndustryBestPractice";
import IndustryBestPracticeService from "../../Services/IndustryBestPracticeService";
import IndustryBestPracticeDisciplineService from "../../Services/IndustryDisciplineBPService";
import { IIndustryDisciplineBestPracticeItem } from "../../Models/IIndustryDisciplineBestPractice";

function* OnGetAllIndustryBestPractice(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER, isLoading: true });
        let _result: ICommonResponse = yield IndustryBestPracticeService.GetIndustryBestPractice(action.industryId);
        if (!_result.isError) {
            let result = _result.result as IIndustryBestPracticeItem;
            yield put({ type: ActionTypes.SAVE_INDUSTRY_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetIndustryBestPRactices: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* OnCreateIndustryBestPractice(action: any) {
    try {
        yield put({
            type: ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER,
            isLoading: true,
        });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let _result: ICommonResponse =
            yield IndustryBestPracticeService.CreateIndustryBestPractice(action.model);
        if (!_result.isError) {
            let _result: ICommonResponse =
                yield IndustryBestPracticeService.GetIndustryBestPractice(action.industryId);
            let result = _result.result as IIndustryBestPracticeItem;
            yield put({ type: ActionTypes.SAVE_INDUSTRY_BEST_PRACTICES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        }
        yield put({
            type: ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER,
            isLoading: false,
        });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateIndustryBestPractice(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER, isLoading: true });
        let resultCreate: ICommonResponse = yield IndustryBestPracticeService.UpdateIndustryBestPractice(action.model);
        if (!resultCreate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield IndustryBestPracticeService.GetIndustryBestPractice(action.industryId);
            let result = _result.result as IIndustryBestPracticeItem;
            yield put({ type: ActionTypes.SAVE_INDUSTRY_BEST_PRACTICES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onClearIndustryBestPractice(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER, isLoading: true });
        let resultClear: ICommonResponse = yield IndustryBestPracticeService.ClearIndustryBestPractice(action.industryId);
        if (!resultClear.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultClear });
            let resultBp: ICommonResponse = yield IndustryBestPracticeService.GetIndustryBestPractice(action.industryId);
            let _resultBp = resultBp.result as IIndustryBestPracticeItem;
            yield put({ type: ActionTypes.SAVE_INDUSTRY_BEST_PRACTICES, result: _resultBp });
            let resultDBp: ICommonResponse = yield IndustryBestPracticeDisciplineService.GetIndustryBestPracticeDiscipline(action.industryId);
            let _resultDBp = resultDBp.result as IIndustryDisciplineBestPracticeItem[];
            yield put({ type: ActionTypes.SAVE_INDUSTRY_DISCIPLINE_BEST_PRACTICES, result: _resultDBp });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultClear });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRY_BEST_PRACTICE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onClearQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* watchGetAllIndustryBestPractice() {
    yield takeEvery(ActionTypes.GET_INDUSTRY_BEST_PRACTICES, OnGetAllIndustryBestPractice);
}
function* watchCreateIndustryBestPractice() {
    yield takeEvery(ActionTypes.CREATE_INDUSTRY_BEST_PRACTICE, OnCreateIndustryBestPractice);
}
function* watchUpdateIndustryBestPractice() {
    yield takeEvery(ActionTypes.UPDATE_INDUSTRY_BEST_PRACTICE, onUpdateIndustryBestPractice);
}
function* watchClearIndustryBestPractice() {
    yield takeEvery(ActionTypes.CLEAR_INDUSTRY_BEST_PRACTICE, onClearIndustryBestPractice);
}
export default function* IndustryBestPracticeSagas() {
    yield all([
        watchGetAllIndustryBestPractice(),
        watchCreateIndustryBestPractice(),
        watchUpdateIndustryBestPractice(),
        watchClearIndustryBestPractice()
    ]);
}
