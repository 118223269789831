import { ActionTypes } from "../actionsTypes";
import { IIndustrySegmentState } from "./IGeneralState";

const initialState: IIndustrySegmentState= {
    isIndustrySegmentLoading: false,
    industrySegmentsResult: null,
    isModalLoading: false,
    modalResponse: null,
}
const IndustrySegmentsReducer = (state = initialState, action: any): IIndustrySegmentState => {
    switch (action.type) {
        case ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER:
            return {
                ...state,
                isIndustrySegmentLoading: action.isLoading,
            };
        case ActionTypes.SAVE_INDUSTRYSEGMENTS:
            return {
                ...state,
                industrySegmentsResult: action.result
            };
            case ActionTypes.SetModalLoader:
                return {
                    ...state,
                    isModalLoading: action.result
                };
            case ActionTypes.SetModalResponse:
                return {
                    ...state,
                    modalResponse: action.result
                };
        default:
            break;
    }
    return state;
}
export default IndustrySegmentsReducer;