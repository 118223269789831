import saveAs from "file-saver";
import { ToastMessages } from "../Enums/Messages";
import {
  CustomerUserOutQuestionDto,
  CustomerUsersInQuestionsDto,
} from "../Models/Requests/CustomerUsers";
import {
  QuestionAnswerReviewDto,
  QuestionAnswersDto,
} from "../Models/Requests/SaveLevers";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import {
  EasyAccessDtoFilter,
  EasyAccessMarkKpi,
  EasyAccessQuestionAnswers,
} from "../Models/EasyAccessLever/IEasyAccessLever";
import { AddQuestionCommentDto } from "../Models/Levers/Items";

export default class LeverService {
  static async ExportHeatMapToExcel(fileName: string) {
    await asupapi
      .get(endPoints.exportHeatMapToExcel, {
        responseType: "blob",
      })
      .then(function (response) {
        //console.log('ExportSelectedChartsToExcel--response: ', response);
        var file_name = fileName + ".xlsx";
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //console.log('ExportSelectedChartsToExcel--response: ', blob);
        saveAs(blob, file_name);
      })
      .catch(function (error) {
        console.log("error1 ", error);
      });
  }

  static async ExportSelectedChartsToExcel(
    exportChartsToExcelModel: any,
    fileName: string
  ) {
    console.log("fileName ExportSelectedChartsToExcel: ", fileName);

    try {
      let response = await asupapi.post(
        endPoints.exportSelecetedChartsToExcel,
        exportChartsToExcelModel,
        {
          responseType: "blob",
        }
      );
      //console.log('ExportSelectedChartsToExcel--response: ', response);
      //var fileName = fileName + ".xlsx";
      var blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //console.log('ExportSelectedChartsToExcel--response: ', blob);
      saveAs(blob, fileName + ".xlsx");
    } catch (error) {
      console.log("error1 ", error);
    }
  }

  static async ExportAllChartsToExcel(fileName: string) {
    console.log("fileName ExportAllChartsToExcel: ", fileName);

    try {
      let response = await asupapi.post(
        endPoints.exportAllChartsToExcel, null,
        {
          responseType: "blob",
        }
      );
      var contentDisposition = response.headers["content-disposition"];
      var matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      var filename = "File.zip";
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      } else {
        filename = "File.zip";
      }

      var blob = new Blob([response.data], {
        type: 'application/zip',
      });
      saveAs(blob, filename);
    } catch (error) {
      console.log("error1 ", error);
    }
  }

  static async GetLevers(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getlevers)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetEasyAccessLevers(
    filter: EasyAccessDtoFilter
  ): Promise<ICommonResponse> {
    console.log("GetEasyAccessLevers", filter);
    return await asupapi
      .post(endPoints.getLeversEasyAccess, filter)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async MarkKpiInEasyAccessLevers(
    filter: EasyAccessMarkKpi
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.MarkKpiInEasyAccessLevers, filter)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async SaveLeversByCustomer(
    model: QuestionAnswersDto
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.SaveLeversByCustomer, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async ReviewQuestionAnswerByConsultant(
    model: QuestionAnswerReviewDto
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.ReviewByConsultant, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async SaveLeversByConsultant(
    model: QuestionAnswersDto
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.SaveLeversByConsultant, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async SaveCustomerUsersInQuestion(
    model: CustomerUsersInQuestionsDto
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.SaveCustomerUsersInQuestion, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetDataCollectionRoleWithUsers(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.GetDataCollectionRoleWithUsers)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async RemoveCustomerUserFromQuestion(
    model: CustomerUserOutQuestionDto
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.RemoveCustomerUserFromQuestion, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async RemindCustomerUserForSelectedQuestion(
    model: CustomerUserOutQuestionDto
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.RemindCustomerUserForSelectedQuestion, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetLeversProgress(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.GetLeversProgress)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetLeversWithEbitChart(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.GetLeversWithEbitChart)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetLeversKpiWithEbitChart(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.GetLeversKpiWithEbitChart)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async SaveEasyAccessLeversByConsultant(
    model: EasyAccessQuestionAnswers
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.SaveEasyAccessLeverByConsultant, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async AddcommentToQuestionByConsultant(
    model: AddQuestionCommentDto
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.AddNewQuestionComment, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async EditcommentToQuestion(
    model: AddQuestionCommentDto
  ): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.EditQuestionComment, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
  static async DeleteQuestionComment(id: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.DeleteQuestionComment, {
        params: {
          commentId: id,
        },
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async GetCountOfUnreviewedAnswers(): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.GetCountOfUnreviewedAnswers)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error?.response?.data);
            let result: ICommonResponse =
              error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }
}
