import { BmProject } from '../../Models/Responses/ProfileResponse';
import { ActionTypes } from "../actionsTypes";
import { IGeneralInfoState } from './IGeneralState';

const initialState: IGeneralInfoState = {
    isLoading: false,
    commonResponse: null,
    roleId: 0,
    profileResponse: null,
    allProjects: [],
    allBenchmarkingProjects: null,
    isPartialLoader: false,
    isFullScreen: false,
    forceToPath: '',
    benchmarkProjectsLoader: false,
    ProjectsForEditEbit: [],
    ProjectsForEditLevers: [],
    isPasswordRequired: false,
    hasAccessTokenToProjects: false,
    clearForm: 0
};

const generalInfoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.SetClearForm:
            return {
                ...state,
                clearForm: action.clearForm
            }
        case ActionTypes.SetForceToPath:
            return {
                ...state,
                forceToPath: action.result
            }
        case ActionTypes.SetPasswordRequiredModal:
            //console.log("SetPasswordRequiredModal", action.isPasswordRequired, action.hasAccessTokenToProjects);
            return {
                ...state,
                isPasswordRequired: action.isPasswordRequired,
                hasAccessTokenToProjects: action.hasAccessTokenToProjects
            }
        case ActionTypes.SetChangeFullScreen:
            return {
                ...state,
                isFullScreen: action.state
            }
        case ActionTypes.ChangeHomeLoader:
            return {
                ...state,
                isLoading: action.result
            }
        case ActionTypes.BenchmarkProjectsLoader:
            return {
                ...state,
                benchmarkProjectsLoader: action.result
            }
        case ActionTypes.StopPartialLoader:
            return {
                ...state,
                isPartialLoader: false
            }
        case ActionTypes.SaveHomePage:
            return {
                ...state,
                profileResponse: { ...action.result }
            }
        case ActionTypes.SaveAllProjects:
            return {
                ...state,
                allProjects: action.projects
            }
        case ActionTypes.SetProjectsForEditEbit:
            return {
                ...state,
                ProjectsForEditEbit: action.projects
            }
        case ActionTypes.SetProjectsForEditLevers:
            return {
                ...state,
                ProjectsForEditLevers: action.projects
            }
        case ActionTypes.SaveAllBenchmarkProjects:
            return {
                ...state,
                allBenchmarkingProjects: [...action.projects]
            }
        case ActionTypes.SetCommonResponse:
            return {
                ...state,
                commonResponse: { ...action.result }
            }
        case ActionTypes.CURRENTPROJECTCHANGEDSUCCESSFULLY:
            //console.log('ActionTypes.CURRENTPROJECTCHANGEDSUCCESSFULLY in generalInfoReducer: ', action);
            let currentProjectCopy = { ...action.currentProject };
            let profileResponseCopy = { ...state.profileResponse, currentProject: { ...currentProjectCopy } };
            let allProjectsCopy = [...state.allProjects];
            let stateCopy = {
                ...state,
                allProjects: [...allProjectsCopy],
                profileResponse: { ...profileResponseCopy }
            };
            //console.log('stateCopy: ', stateCopy);
            return {
                ...stateCopy
            }
        // case ActionTypes.UpdateDprojectsLocally:
        //     let _dprojects = state.profileResponse.currentProject.dprojects;
        //     _dprojects.push(action.result);
        //     let currentProject_dproject_copy: BmProject = {
        //         ...state.profileResponse.currentProject,
        //         dprojects: _dprojects
        //     }
        //     return {
        //         ...state,
        //         currentProject: currentProject_dproject_copy
        //     }
        case ActionTypes.UpdateCurrentFlProjectId:
            let profileResponseCopyFl = { ...state.profileResponse, currentFrontLoadingProjectId: action.value };            
            return {
                ...state,
                profileResponse: profileResponseCopyFl
            };
        default:
            break;
    }
    return state;
};

export default generalInfoReducer;