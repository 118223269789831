import { ActionTypes } from "../actionsTypes";
import { ILeversForAdminState } from "./IGeneralState";

const initialState: ILeversForAdminState = {
    isLeversForAdminLoading: false,
    leversForAdminResult: null,
    isModalLoading: false,
    modalResponse: null,
};
const LeversManagementForAdminReducer = (
    state = initialState,
    action: any
): ILeversForAdminState => {
    switch (action.type) {
        case ActionTypes.CHANGE_LEVERS_FOR_ADMIN_LOADER:
            return {
                ...state,
                isLeversForAdminLoading: action.isLoading,
            };
        case ActionTypes.SAVE_LEVERS_FOR_ADMIN:
            return {
                ...state,
                leversForAdminResult: action.result,
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            }
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            }
        default:
            break;
    }
    return state;
};

export default LeversManagementForAdminReducer;