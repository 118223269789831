//import { MenuOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { arrayMoveImmutable } from 'array-move';
import React, { useState } from 'react';
//import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
//import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import "./index.scss";

interface DataType {
  key: String;
  tsh: string;
  ef: string;
  kpi: string;
  frage: string;
  age: number;
  address: string;
  description: string;
  index: number;
}

//const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const columns: ColumnsType<DataType> = [
  Table.EXPAND_COLUMN,
  { title: '#', dataIndex: 'key', key: 'key'},
  { title: 'TSH', dataIndex: 'tsh', key: 'tsh', onCell: (record, index) => ({colSpan: record.key ==="1" ? 3 : 0 })},
  { title: 'EF', dataIndex: 'ef', key: 'ef', onCell: (record, index) => ({colSpan: record.key ==="1.1" ? 3 : 0 }) },
  { title: 'kpi', dataIndex: 'kpi', key: 'kpi', onCell: (record, index) => ({colSpan: record.key ==="1.1.1" ? 3 : 0 }) },
  { title: 'frage', dataIndex: 'frage', key: 'frage' },
  { title: 'Age', dataIndex: 'age', key: 'age' },
  { title: 'Address', dataIndex: 'address', key: 'address' },
];

const data: DataType[] = [
  {
    key: '1',
    tsh: 'John Brown',
    ef: 'John Brown',
    kpi: 'John Brown',
    frage: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
    index: 0,
  },
  {
    key: '2',
    tsh: 'John Brown',
    ef: 'John Brown',
    kpi: 'John Brown',
    frage: 'John Brown',
    age: 42,
    address: 'London No. 1 Lake Park',
    description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
    index: 1,
  },
];

const data2: DataType[] = [
  {
    key: '1.1',
    tsh: 'John Brown',
    ef: 'John Brown',
    kpi: 'John Brown',
    frage: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
    index: 0,
  },
];

const data3: DataType[] = [
  {
    key: '1.1.1',
    tsh: 'John Brown',
    ef: 'John Brown',
    kpi: 'John Brown',
    frage: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
    index: 0,
  },
];

// const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
//   <tr {...props} />
// ));
// const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
//   <tbody {...props} />
// ));

const ExpandTable: React.FC = () => {

  const [dataSource, setDataSource] = useState(data);

  // const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
  //   if (oldIndex !== newIndex) {
  //     const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
  //       (el: DataType) => !!el,
  //     );
  //     //console.log('Sorted items: ', newData);
  //     setDataSource(newData);
  //   }
  // };

  // const DraggableContainer = (props: SortableContainerProps) => (
  //   <SortableBody
  //     useDragHandle
  //     disableAutoscroll
  //     helperClass="row-dragging"
  //     onSortEnd={onSortEnd}
  //     {...props}
  //   />
  // );

  // const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
  //   // function findIndex base on Table rowKey props and should always be a right array index
  //   const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
  //   return <SortableItem index={index} {...restProps} />;
  // };

  return (
    <Table
      // className='levers-table'
      pagination={false}
      columns={columns}

      dataSource={data}
      rowKey="index"
      // components={{
      //   body: {
      //     wrapper: DraggableContainer,
      //     row: DraggableBodyRow,
          
      //   },
      // }}
      expandable={{
        expandedRowRender: record =>  <Table
        pagination={false}
        columns={columns}
        dataSource={data2}
        showHeader={false}
        expandable={{
          expandedRowRender: record =>  <Table
          pagination={false}
          columns={columns}
          showHeader={false}
          dataSource={data3}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
          }}
          ></Table>,
          // rowExpandable: record => record.name !== 'Not Expandable',
        }}
        ></Table>,
        // rowExpandable: record => record.name !== 'Not Expandable',
      }}
    />

  );
};

export default ExpandTable;