import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import { IErrorPage } from "../../Models/IErrorPage";
import ErrorPageForAdminService from "../../Services/ErrorPageForAdminService";

function* OnGetAllErrorPage() {
    try {
        yield put({ type: ActionTypes.CHANGE_ALL_ERRORS_LOADER, isLoading: true });
        let _result: ICommonResponse = yield ErrorPageForAdminService.GetAllErrorPage();

        if (!_result.isError) {
            let result = _result.result as IErrorPage[];
            yield put({ type: ActionTypes.SAVE_ALL_ERRORS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_ALL_ERRORS_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGet: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}

function* onUpdateError(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_ALL_ERRORS_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield ErrorPageForAdminService.UpdateError(
            action.model
        );
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield ErrorPageForAdminService.GetAllErrorPage();
            let result = _result.result as IErrorPage[];
            yield put({ type: ActionTypes.SAVE_ALL_ERRORS, result: result })
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_ALL_ERRORS_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* watchGetAllErrorPage() {
    yield takeEvery(ActionTypes.GET_ALL_ERRORS, OnGetAllErrorPage)
}

function* watchUpdateError() {
    yield takeEvery(ActionTypes.UPDATE_ERROR, onUpdateError)
}

export default function* ErrorPagesForAdminSagas() {
    yield all([
        watchGetAllErrorPage(),
        watchUpdateError()
    ])
}