import { useEffect } from "react";
import { connect } from "react-redux";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import CurrentProjectCard from "./CurrentProjectCard/CurrentProjectCard";
import { t } from "i18next";
import HelpPage from "../../../Layout/HelpPage";
import { Tabs } from "antd";

function CurrentProject(props) {

    let items = [{
        label: <>{t("Current Project")}</>, key: 'general-tab',
        children: <CurrentProjectCard />
    },
    {
        label: <>{t("Tipps and Tricks")}</>, key: 'tips-dashboard-tab',
        children: <HelpPage currentPageCode="current project" />
    }]

    return (
        <Tabs tabPosition="top" defaultActiveKey="general-tab" type="line" size='small'
            key={"dashboard-tab"} id="dashboard-tab" items={items} />
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        projects: state.generalInfo.allProjects,
        currentProject: state.generalInfo.profileResponse?.currentProject,
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentProject);