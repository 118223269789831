export interface Generalinfo {
  salesLastYear: number;
  ebitRate: number;
  estimateBenchmarkInno: number;
  estimateBenchmarkQC: number;
  estimateBenchmarkRD: number;
  estimateBenchmarkCOD: number;
  estimateBenchmarkMC: number;
}

export interface Cod {
  projectCountLastYear: number;
  avgDelayLastYear: number;
  avgProjectCostLastYear: number;
  avgExpectedSales: number;
  additionalCostCOD: number;
}

export interface Inno {
  productsSalesLastYear: number;
  productsAvgEBITRateLastYear: number;
  //ebitProductExtensions: number;
}

export interface Rd {
  personnelCostDevelopmentArea: number;
  personnelCostinterdisciplinary: number;
}

export interface Qc {
  qualityCost: number;
  qualityCostByRD: number;
}

export interface Mc {
  completedProjectLastYear: number;
  manufactoringCostDeviation: number;
  //manufactoringCostByRDDeviation: number;
  avgQuantity: number;
  avgPlannedManufactoringCost: number;
}

export interface EstimateDto {
  bmProjectId: number;
  generalinfo: Generalinfo;
  cod: Cod;
  inno: Inno;
  rd: Rd;
  qc: Qc;
  mc: Mc;
}


//Dont change anything here because will change in waterfull
export class EstimateResult {
  public EBITToday: Potential = new Potential("EBIT heute");
  public codPotential: Potential = new Potential("COD");
  public innoPotenial: Potential = new Potential("INNO");
  public rDPotential: Potential = new Potential("F&E");
  public qCPotential: Potential = new Potential("QK");
  public mCPotential: Potential = new Potential("HK");
}

export class Potential {
  Name: string;
  Cost: number;
  Rate: number;
  constructor(name: string) {
    this.Name = name;
    this.Cost = 0;
    this.Rate = 0;
  }
}
