import { connect } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Login from "../Scenes/AnonymousPages/Login";
import Activation from "../Scenes/AnonymousPages/Activation";
import { ProtectedRoute, ProtectedAdminRoute, ProtectedCustomerRoute } from "./ProtectedRoute";
import ComponentExample from "../Components/ComponentExample";
import LeversTool from "../Scenes/Tools/LeversTool/LeversTool";
import Segments from "../Scenes/Admin/Segments";
import Users from "../Scenes/Admin/Users";
import Permissions from "../Scenes/Admin/Permissions/Permissions";
import FrontLoading from "../Scenes/Admin/FrontLoading";
import EditLevers from "../Scenes/BMProjectsLists/EditLevers/EditLevers";
import EditEbit from "../Scenes/BMProjectsLists/EditEbit/EditEbit";
import RD from "../Components/RD";
import EBITToolV2 from "../Scenes/Tools/EBITTool-V2";
//import ActivityTool from "../Scenes/Tools/ActivityTool";
import CurrentProject from "../Scenes/BMProjectsLists/BMProjects_CurrentProject/CurrentProject";
import ChangeProject from "../Scenes/BMProjectsLists/BMProjects_ChangeProject/ChangeProject";
import AnonymousRoute from "./AnonymousRoute";
import { ActionTypes } from "../Redux/actionsTypes";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { ProfileResponse } from "../Models/Responses/ProfileResponse";
import { IGeneralState } from "../Redux/reducers/IGeneralState";
import { useEffect } from "react";
import { openNotificationWithIcon } from "../Utils/helperFunctions";
import AllCharts from "../Components/AllChartsPage/AllCharts";
import BmProject from "../Scenes/Admin/BmProject/BmProject";
import DisciplineA from "../Scenes/Admin/DisciplineMng/Discipline";
import IndustryMng from "../Scenes/Admin/IndustryMng";
import CustomerUsers from "../Scenes/Admin/CustomerUsers/CustomerUsers";
import Verification from "../Scenes/AnonymousPages/Verification";
import AllTools from "../Scenes/Tools/AllTools";
import LeversMng from "../Scenes/Admin/LeversMng";
import IndustryBestPractice from "../Scenes/Admin/IndustryBestPractice/IndustryBestPractice";
import IndustrySegmentBestPractice from "../Scenes/Admin/IndustrySegmentBestPractice/IndustrySegmentBestPractice";
import CustomerProjects from "../Scenes/BMProjectsLists/BMProjects_ChangeProject/CustomerProjects";

import DemoRadar from "../Components/demoRadar";
import CustomerMng from "../Scenes/Admin/CustomerManagement/CustomerMng";
import Curves from "../Scenes/Admin/FrontLoading/Curves/Curves";
import UserManagement from "../Scenes/Admin/UserManagement/UserManagement";
import LoginLayout from "../Layout/LoginLayout";
import AppLayout from "../Layout/AppLayout";
import AdminAppLayout from "../Layout/AdminAppLayout/AdminAppLayout";
import ProfileMng from "../Scenes/Admin/ProfileMng/ProfileMng";
import DepartmentByCustomer from "../Scenes/Admin/DepartmentByCustomer/DepartmentByCustomer";
import SelfAssessment from "../Scenes/Admin/SelfAssessment/SelfAssessment";
import Utils from "../Utils/Utils";
import ErrorsPageForAdmin from "../Scenes/Admin/ErrorsPageForAdmin/ErrorsPageForAdmin";
import EventLogsForAdmin from "../Scenes/Admin/EventLogsForAdmin/EventLogsForAdmin";
import IAToolAnonymous from "../Scenes/Tools/InterfaceAnalysis/IAToolAnonymous";
import ActiviyMng from "../Scenes/Admin/Activity"
import ActivityMngTable from "../Scenes/Admin/Activity/ActivityProfileVersions"
import { Button, Image, Result } from "antd";
import homePageImage from "./../assets/Images/byp-Screen.jpg";
import { BasicRole } from "../Enums/BasicRoles";
import ResetPassword from "../Scenes/AnonymousPages/ResetPassword";
import ResetPasswordRequest from "../Scenes/AnonymousPages/ResetPasswordRequest";
import Survey from "../Scenes/Tools/ActivityTool/Survey/Survey";
import { ToastMessages } from "../Enums/Messages";
import { t } from "i18next";
import FrontLoadingDepartment from "../Scenes/Admin/ForntLoadingDepartment/FrontLoadingDepartment";
import useWebSocket from 'react-use-websocket';
import EasyAccessLever from "../Scenes/Tools/LeversTool/EasyAccess/EasyAccessLever";
import HelpPages from "../Scenes/Admin/HelpPagesAdmin/HelpPages";
import HelpPageEdit from "../Scenes/Admin/HelpPagesAdmin/HelpPageEdit";
import HelpPage from "../Scenes/Admin/HelpPages/HelpPage";
import GroupActivityVersionTable from "../Scenes/Admin/Activity/ActivityProfileVersions/GroupActivityVersionTable";
import LeversToolCustomer from "../Scenes/Tools/LeversTool/LeversToolCustomer";
interface IRouteProps {
  commonResult: ICommonResponse;
  //isLoading: boolean;
  profileResponse: ProfileResponse;
  goToPath: string;
  getHomePage: () => void;
  resetForceToPath: () => any;
  changeCurrentHelpPageCode: (pageName: string) => any;
}

//const WS_URL = 'ws://127.0.0.1:8000';
function Router(props: IRouteProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tool = searchParams.get('tool');
  // useWebSocket(WS_URL, {
  //   onOpen: () => {
  //     console.log('WebSocket connection established.');
  //   }
  // });
  useEffect(() => {
    //console.log("searchParams changes ", tool);
    if (tool) {
      console.log("searchParams changes ", tool);
      props.changeCurrentHelpPageCode(tool);
      if (props.profileResponse) {
        //Check Access
        //console.log("props.profileResponse.permissions", props.profileResponse.permissions);        
        if (!props?.profileResponse?.roles?.includes(BasicRole.SuperAdmin)) {
          let hasAccess = Utils.checkAccess(tool, props.profileResponse.permissions);
          if (!hasAccess) {
            if (location.pathname.includes("/admin/")) {
              navigate("/admin/access-denied");
            } else {
              //navigate("/access-denied");
            }
          }
        }
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (props.profileResponse && props.profileResponse?.roles) {
      if (props.profileResponse.roles.includes(BasicRole.Customer)) {
        if (location.pathname != "/levers" && location.pathname != "/projects" && location.pathname != "/access-denied-customer"
          && location.pathname != "/login") {
          navigate("/access-denied-customer");
        }
      } else {
        if (!props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)) {
          if (location.pathname != "/tools") {
            let pageCode = Utils.getHelpPageCodeByPath(location.pathname);
            props.changeCurrentHelpPageCode(pageCode);
            let hasAccess = Utils.checkAccess(pageCode, props.profileResponse.permissions);
            if (!hasAccess) {
              if (location.pathname.includes("/admin/") && location.pathname != "/admin/access-denied") {
                navigate("/admin/access-denied");
              } else {
                //navigate("/access-denied");
              }
            }
          }
        }
      }
    }
  }, [props.profileResponse]);

  useEffect(() => {
    console.log("location", location);
    if (props.profileResponse && props.profileResponse?.roles) {
      if (props.profileResponse.roles.includes(BasicRole.Customer)) {
        if (location.pathname != "/levers" && location.pathname != "/projects" && location.pathname != "/access-denied-customer"
          && location.pathname != "/login") {
          navigate("/access-denied-customer");
        }
      }
    }
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      if (!props.profileResponse || location.pathname == "/projects" || location.pathname == "/dashboard" || location.pathname == "/admin") {
        props.getHomePage();
      }

      if (location.pathname != "/tools") {
        let pageCode = Utils.getHelpPageCodeByPath(location.pathname);
        props.changeCurrentHelpPageCode(pageCode);
        //Check Access
        if (props.profileResponse) {
          if (!props.profileResponse?.roles?.includes(BasicRole.SuperAdmin)) {
            //console.log("props.profileResponse.permissions", props.profileResponse.permissions);
            let hasAccess = Utils.checkAccess(pageCode, props.profileResponse.permissions);
            if (!hasAccess) {
              if (location.pathname.includes("/admin/") && location.pathname != "/admin/access-denied") {
                navigate("/admin/access-denied");
              } else {
                //navigate("/access-denied");
              }
            }
          }
        }
      }
    }
  }, [location]);

  useEffect(() => {
    //console.log("props.goToPath", props.goToPath);
    if (props.goToPath) {
      if (props.goToPath != location.pathname) {
        navigate(props.goToPath);
        props.resetForceToPath();
      }
    }
  }, [props.goToPath])

  useEffect(() => {
    if (props.commonResult) {
      if (props.commonResult?.error?.exceptionMessage) {
        if (typeof props.commonResult?.error?.exceptionMessage == 'string') {
          if (props.commonResult?.error?.exceptionMessage) {
            openNotificationWithIcon("error", "Error", props.commonResult.error?.exceptionMessage as string);
          } else {
            openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
          }
        } else {
          openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
        }
      } else if (props.commonResult?.message) {
        console.log("props.commonResult: ", props.commonResult?.message);

        openNotificationWithIcon("success", "Success", props.commonResult?.message);
      }
    }
  }, [props.commonResult]);

  return (
    <Routes>
      <Route
        element={
          <AnonymousRoute>
            <LoginLayout />
          </AnonymousRoute>
        }
      >
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Verification />} />
        <Route path="/activate" element={<Activation />} />
        <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {/* <Route path="/interface-survey" element={<IAToolAnonymous />} />
        <Route path="/activity-survey" element={<Survey />} /> */}
      </Route>

      <Route       >
        <Route path="/interface-survey" element={<IAToolAnonymous />} />
        <Route path="/activity-survey" element={<Survey />} />
      </Route>
      <Route
        element={
          <ProtectedRoute>
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/access-denied-customer" element={<Result
          status="403"
          title="403"
          subTitle=""
          extra={<Button type="primary" onClick={() => { navigate("/projects") }}>{`${t("Back Home")}`}</Button>}
        />} />
        <Route path="/access-denied" element={<Result
          status="403"
          title="403"
          subTitle={`${t("Sorry, you are not authorized to access this page.")}`}
          extra={<Button type="primary" onClick={() => { navigate("/dashboard") }}>{`${t("Back Home")}`}</Button>}
        />} />
        <Route path="/start" element={<Image
          width={"100%"}
          style={{margin: "5px"}}
          height={"100%"}
          src={homePageImage}
          preview={false}
        />} />
        <Route path="/dashboard" element={<CurrentProject />} />
        <Route path="/projects" element={<CustomerProjects />} />
        <Route path="/tools" element={<AllTools />} />
        <Route path="/levers" element={<LeversToolCustomer />} />
        <Route path="/rd" element={<RD />} />
        <Route path="/changeProject" element={<ChangeProject />}></Route>
        <Route path="/editLevers" element={<EditLevers />} />
        <Route path="/editEbit" element={<EditEbit />} />
        <Route path="/ebit2" element={<EBITToolV2 />} />
        <Route path="/allcharts" element={<AllCharts />} />
        <Route path="/profile" element={<ProfileMng />} />
      </Route>
      <Route
        element={
          <ProtectedAdminRoute>
            <AdminAppLayout />
          </ProtectedAdminRoute>
        }
      >
        <Route path="/admin/access-denied" element={<Result
          status="403"
          title="403"
          subTitle={`${t("Sorry, you are not authorized to access this page.")}`}
          extra={<Button type="primary" onClick={() => { navigate("/admin") }}>{`${t("Back Home")}`}</Button>}
        />} />
        <Route path="/admin" element={<img alt="" src={homePageImage} width={"100%"} />} />
        <Route path="/admin/component-example" element={<ComponentExample />} />
        <Route path="/admin/customer" element={<CustomerMng />} />
        <Route path="/admin/customer/users" element={<CustomerUsers />} />
        <Route path="/admin/customer/department" element={<DepartmentByCustomer isFromBmProject={false} />} />
        <Route path="/admin/customer/bm-project" element={<BmProject />} />
        <Route path="/admin/bm-project" element={<BmProject />} />
        <Route path="/admin/disciplines" element={<DisciplineA />} />
        <Route path="/admin/industries" element={<IndustryMng />} />
        <Route path="/admin/industries/segment" element={<Segments />} />
        <Route path="/admin/industries/bestPractice" element={<IndustryBestPractice />} />
        <Route path="/admin/industries/segments" element={<Segments />} />
        <Route path="/admin/industries/segments/bestPractice" element={<IndustrySegmentBestPractice />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/users-management" element={<UserManagement />} />
        <Route path="/admin/profile" element={<ProfileMng />} />
        <Route path="/admin/permissions" element={<Permissions />} />
        <Route path="/admin/activity" element={<ActiviyMng />} />
        <Route path="/admin/activity/versions" element={<ActivityMngTable />} />
        <Route path="/admin/activity/profileversion" element={<GroupActivityVersionTable />} />
        <Route path="/admin/front-loading/templates" element={<FrontLoading />} />
        <Route path="/admin/front-loading/curves" element={<Curves />} />
        <Route path="/admin/front-loading-template" element={<FrontLoading />} />
        <Route path="/admin/front-loading-curves" element={<Curves />} />
        <Route path="/admin/front-loading/departments" element={<FrontLoadingDepartment />} />
        <Route path="/admin/front-loading-departments" element={<FrontLoadingDepartment />} />
        <Route path="/admin/help-page" element={<HelpPage />} />
        {/* <Route path="/admin/help-page" element={<HelpPages />} /> */}
        <Route path="/admin/help-page/edit" element={<HelpPageEdit />} />        
        <Route path="/admin/audit-log" element={<EventLogsForAdmin />} />
        <Route path="/admin/lever-management" element={<LeversMng />} />
        <Route path="/admin/self-assessment" element={<SelfAssessment />} />
        <Route path="/admin/error-page" element={<ErrorsPageForAdmin />} />
        <Route path="/admin/easy-access-lever" element={<EasyAccessLever />} />
      </Route>

      <Route path="/demo-radar" element={<DemoRadar />} />
    </Routes>
  );
};

const mapStateToProps = (state: IGeneralState) => {
  return {
    commonResult: state.generalInfo?.commonResponse,
    goToPath: state.generalInfo?.forceToPath,
    //isLoading: state.generalInfo.isLoading,
    profileResponse: state.generalInfo?.profileResponse
  }
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getHomePage: () => dispatch({ type: ActionTypes.GetHomePage }),
    resetForceToPath: () => dispatch({ type: ActionTypes.ForceToPath, model: "" }),
    changeCurrentHelpPageCode: (pageName: string) => dispatch({ type: ActionTypes.ChangeHelpPageCode, result: pageName })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);