import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Empty, Form, Input, InputNumber, Menu, Modal, Popconfirm, Popover, Result, Row, Select, Space, Spin, Tabs, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import { ICancelProcessDto, ICompleteProcessDto, IIaDiscipline, InterfaceAnalysisExportExcel } from "../../../Models/InterfaceAnalysis/IAItems";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IActivityToolState, IGeneralState } from "../../../Redux/reducers/IGeneralState";
import './ActivityTool.scss';
import 'moment/locale/de.js';
import 'moment/locale/en-gb.js';
import { t } from "i18next";
import { VotingProcessStatusEnum } from "../../../Enums/VotingProcessStatusEnum";
import Countdown from "antd/lib/statistic/Countdown";
import { RulesDescription, RulesDurations, RulesRequire } from "../../Admin/Rules/RulesValidation";
import { FileExcelOutlined } from "@ant-design/icons";
import { ActivityProfileAnalysisView, CancelActivityProfileAnalysis, CompleteActivityProfileAnalysis, CreateActivityProfileAnalysisDto, EndActivityProfileAnalysis, ExportActivityProfileAnalysis, StartActivityProfileAnalysis } from "./Survey/Models/ActivitySurveyData";
import ProfileViewForm from "./Widgets/ProfileViewForm";
import ProjectProfileTasks from "./Widgets/ProjectProfileTasks";
import ActivityReviewList from "./Widgets/ActivityReviewList";
import ActivityProfileResult from "./Widgets/ActivityProfileResult";
import HelpPage from "../../../Layout/HelpPage";
import ResetSection from "./Widgets/ResetSection";
const { Paragraph } = Typography;
moment.locale(i18n.language);
//console.log("i18n.language i18n.language", i18n.language);
interface IProps {
    getView: () => any;
    createNewAnalysis: (model: CreateActivityProfileAnalysisDto) => any;
    startNewAnalysis: (model: StartActivityProfileAnalysis) => any;
    cancelAnalysis: (model: CancelActivityProfileAnalysis) => any;
    state: IActivityToolState;

    endAnalysis: (model: EndActivityProfileAnalysis) => any;
    completeAnalysis: (model: CompleteActivityProfileAnalysis) => any;
    exportToExcel: (model: ExportActivityProfileAnalysis) => any;

}
function ActivityTool(props: IProps) {
    const [duration, setDuration] = useState<number>(10);
    const [showCompleteModal, setShowCompleteModal] = useState(false);
    const [showResetartModal, setShowRestartModal] = useState(false);

    const [extendForm] = Form.useForm();

    const [completeForm] = Form.useForm();



    const [activeTab, setActiveTab] = useState(localStorage.getItem("activity_tool_tab") ?? 'start-point-tab');
    const [activeProfileTab, setActiveProfileTab] = useState(localStorage.getItem("activity_tool_profile_tab") ?? 'ended-tab');
    const [completeActiveTab, setCompleteActiveTab] = useState(localStorage.getItem("activity_tool_complete_tab") ?? 'table-tab');
    useEffect(() => {
        props.getView();
    }, []);

    const onExport = (profileId: number) => {
        props.exportToExcel({ projectProfileId: profileId });
    }


    const markAsCompleteSection = (profile: ActivityProfileAnalysisView) => {
        return <>
            <Button type="primary" onClick={() => { setShowCompleteModal(true); }}>{`${t("Close and Complete")}`}</Button>
            <Modal title={`${t("Close and Complete")}`} open={showCompleteModal} onOk={async () => {
                let validation = await completeForm.validateFields();
                let values = completeForm.getFieldsValue();
                props.completeAnalysis({
                    reason: values["reason"],
                    profileId: profile.activityProfile.id
                });
                setShowCompleteModal(false)
            }}
                onCancel={() => { setShowCompleteModal(false) }}>
                <Form form={completeForm} >
                    <Form.Item name={"reason"} label={`${t("Reason")}`} rules={[...RulesDescription({ name: t("Reason"), countChar: 1500 }), ...RulesRequire({ filedName: t("Reason") })]}>
                        <Input.TextArea allowClear showCount placeholder={`${t("Type a reason...")}`} rows={4} maxLength={256} />
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
    const restartSection = (profile: ActivityProfileAnalysisView) => {
        return <>
            <Button type="primary" onClick={() => {
                setShowRestartModal(true);
            }}>{`${t("Extend")}`}</Button>
            <Modal key={`modal_${profile.projectProfileId}`} title={`${t("Extend")}`} open={showResetartModal} cancelText={`${t("Cancel")}`} onOk={async () => {
                try {
                    let validation = await extendForm.validateFields();

                    let values = extendForm.getFieldsValue();
                    if (values["duration"]) {
                        if (values["duration"] < 10 || values["duration"] > 10080) {
                            console.log("Invalid duration", values["duration"]);
                        } else {
                            props.startNewAnalysis({
                                duration: values["duration"],
                                profileId: profile.activityProfile.id
                            });

                            setShowRestartModal(false)
                        }
                    }
                } catch (err) {
                    console.log("on extend", err);
                }
            }}
                onCancel={() => { setShowRestartModal(false) }}>
                <Form form={extendForm} >
                    <Form.Item initialValue={10} name={"duration"} label={`${t("Duration in minutes")}`} rules={[...RulesRequire({ filedName: t("Duration in minutes") }), ...RulesDurations({ filedName: t("Duration in minutes") })]}>
                        <InputNumber value={duration} onChange={(val) => { setDuration(val) }} />
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }

    const prepareProfileView = (profile: ActivityProfileAnalysisView) => {
        const preparePresentation = () => {
            switch (profile?.votingProcessStatusId) {
                case VotingProcessStatusEnum.Created:
                    return <>
                        <Row gutter={16} className="voting-process-header">
                            <Col span={22}>
                                <InputNumber addonBefore={`${t("Duration in minutes")}`} value={duration} onChange={(val) => { setDuration(val) }} min={10} max={10080} defaultValue={10}  />
                                <Popconfirm getPopupContainer={() => document.getElementById('app-layout')} placement="topLeft" title={`${t("Are you sure?")}`} onConfirm={() => {
                                    if (duration) {
                                        if (duration < 10 || duration > 10080) {

                                        } else {
                                            props.startNewAnalysis({
                                                duration: duration,
                                                profileId: profile.activityProfile.id
                                            })
                                        }
                                    }
                                }}
                                    okText={`${t("Yes")}`} cancelText={`${t("No")}`}>
                                    <Button style={{ marginLeft: "10px" }} type="default" >{`${t("Start new voting round")}`}</Button>
                                </Popconfirm>
                            </Col>
                            <Col span={2}>
                                <ResetSection profile={profile} cancelAnalysis={props.cancelAnalysis} />
                            </Col>
                        </Row>
                        <Row gutter={16} ><Col span={24}>
                            <Paragraph copyable>{profile?.token}</Paragraph>
                        </Col></Row>
                        <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                            <Col span={24} >
                                <ProjectProfileTasks profile={profile} editable={false} withResult={false} />
                            </Col>
                        </Row>
                    </>;
                case VotingProcessStatusEnum.Started:
                    if (profile?.remainingMinutes <= 0) {
                        return <Result
                            status="warning"
                            title="The round is under processing, please wait for a minute then refresh"
                            extra={
                                <Button type="primary" key="refresh_btn" onClick={() => { window.location.reload() }}>
                                    {`${t("Refresh")}`}
                                </Button>
                            }
                        />;
                    }
                    return <>
                        <Row gutter={16} className="voting-process-header">
                            <Col span={22}>
                                <Space align="start" direction="horizontal">
                                    <Countdown onFinish={() => {
                                        props.getView();
                                    }}
                                        suffix={<Popconfirm getPopupContainer={() => document.getElementById('app-layout')}
                                            placement="topLeft" title={`${t("Are you sure?")}`} onConfirm={() => {
                                                props.endAnalysis({
                                                    profileId: profile.activityProfile.id
                                                })
                                            }} okText={`${t("Yes")}`} cancelText={`${t("No")}`}>
                                            <Button>{`${t("End")}`}</Button>
                                        </Popconfirm>}
                                        value={Date.now() + profile?.remainingMinutes * 60 * 1000} format="HH:mm:ss" />
                                </Space>
                            </Col>
                            <Col span={2}>
                                <ResetSection profile={profile} cancelAnalysis={props.cancelAnalysis} />
                            </Col>
                        </Row>
                        <Row gutter={16} ><Col span={24}>
                            <Paragraph copyable>{profile?.token}</Paragraph>
                        </Col>
                        </Row>
                        <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                            <Col span={24}>
                                <Descriptions layout="horizontal">
                                    <Descriptions.Item label={`${t("Started at")}`}>{profile?.startAt}</Descriptions.Item>
                                    <Descriptions.Item label={`${(profile?.votingProcessStatusId != VotingProcessStatusEnum.Started) ? t("Expired at") : t("Expire at")}`}>{profile?.expireAt}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                            <Col span={24}>
                                <ProjectProfileTasks profile={profile} editable={false} withResult={false} />
                            </Col>
                        </Row>
                    </>;
                case VotingProcessStatusEnum.Ended:
                    let endedItems = [
                        {
                            label: `${t("Answers")}`,
                            key: 'ended-tab',
                            children: <ActivityReviewList profile={profile} />
                        },
                        {
                            label: `${t("Results")}`,
                            key: 'tasks-tab',
                            children: <Row gutter={16} className="current_project_card_wrapper voting-process-body">
                                <Col span={24}>
                                    <ProjectProfileTasks profile={profile} editable={false} withResult={true} />
                                </Col>
                            </Row>

                        },
                        {
                            label: `${t("Charts")}`, key: 'charts-tab', children: <ActivityProfileResult profile={profile} />
                        }
                    ]
                    let operations = <Space align="center" direction="horizontal" className="top-options">
                        {markAsCompleteSection(profile)}
                        <ResetSection profile={profile} cancelAnalysis={props.cancelAnalysis} />
                        {restartSection(profile)}
                        <Button type="default" icon={<FileExcelOutlined style={{ 'margin': 'auto' }} />} onClick={() => {
                            onExport(profile.activityProfile.id)
                        }}>
                            {`${t("Export")}`}
                        </Button>
                    </Space>;
                    return <Tabs onChange={(activeKey) => {
                        localStorage.setItem("activity_tool_profile_tab", activeKey);
                        setActiveProfileTab(activeKey);
                    }} activeKey={activeProfileTab} items={endedItems} tabBarExtraContent={operations} />;
                case VotingProcessStatusEnum.Completed:
                    let items = [
                        {
                            label: `${t("Charts")}`, key: 'charts-tab', children: <ActivityProfileResult profile={profile} />
                        },
                        {
                            label: `${t("Answers")}`,
                            key: 'ended-tab',
                            children: <ActivityReviewList profile={profile} />
                        },
                        {
                            label: `${t("Results")}`, key: 'config-tab', children: <ProjectProfileTasks profile={profile} editable={false} withResult={true} />
                        }
                    ];
                    let operations2 = <Space align="center" direction="horizontal" className="top-options">
                        <Button type="default" icon={<FileExcelOutlined style={{ 'margin': 'auto' }} />} onClick={() => {
                            onExport(profile.activityProfile.id)
                        }}>
                            {`${t("Export")}`}
                        </Button>
                    </Space>;
                    return <Tabs onChange={(activeCompleteKey) => {
                        localStorage.setItem("activity_tool_complete_tab", activeCompleteKey);
                        setCompleteActiveTab(activeCompleteKey);
                    }} activeKey={completeActiveTab} items={items} tabBarExtraContent={operations2} />;
                default:
                    break;
            }
            return <></>;
        }
        return <div className="profile-cardbody" key={`profile-cardbodyid-${profile?.activityProfile?.id}`}>
            {(profile?.isCreated == true) ?
                preparePresentation()
                :
                (profile?.isCreated == false) ?
                    <Row>
                        <Col span={24}>
                            <ProfileViewForm profile={profile} createNewAnalysis={props.createNewAnalysis} />
                        </Col>
                    </Row>
                    :
                    <Empty description={`${t("No data")}`}></Empty>
            }
        </div>;
    }
    const items = () => {
        let tempItems = [];
        tempItems.push({
            label: `${t("Start Point")}`, key: 'start-point-tab',
            children:
                <>
                    {(props.state?.viewResult?.profiles && props.state?.viewResult?.profiles?.length > 0) ?
                        (props.state?.viewResult?.profiles?.filter(c => !c.isCreated) && props.state?.viewResult?.profiles?.filter(c => !c.isCreated).length > 0) ?
                            props.state?.viewResult?.profiles?.filter(c => !c.isCreated).map((profile) => {
                                return <Card key={`profile_card_${profile.activityProfile?.id}`} title={`${profile.activityProfile?.name}`} bordered={false}>
                                    {prepareProfileView(profile)}
                                </Card>;
                            }) : <Empty description={`${t("There are no profiles left for analysis")}`}></Empty>
                        :
                        <Empty description={`${t("There are no profiles yet")}`}></Empty>
                    }
                </>
        });

        props.state?.viewResult?.profiles?.filter(c => c.isCreated).map((profile) => {
            tempItems.push({
                label: `${profile.activityProfile.name}`, key: `profile-${profile.activityProfile.id}-tab`,
                children: prepareProfileView(profile)
            });
        });
        tempItems.push({
            label: <>{t("Tipps and Tricks")}</>, key: 'tips-activity-tab',
            children: <HelpPage currentPageCode="activity" />
        });
        return tempItems;
    }

    return <Spin spinning={props.state.isLoading}>
        <Tabs onChange={(activeKey) => {
            localStorage.setItem("activity_tool_tab", activeKey);
            setActiveTab(activeKey);
        }} activeKey={activeTab} items={items()} />
    </Spin>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        state: state.activity
    }
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        createNewAnalysis: (model: CreateActivityProfileAnalysisDto) => dispatch({ type: ActionTypes.CreateActivityProfileAnalysis, model: model }),
        cancelAnalysis: (model: CancelActivityProfileAnalysis) => dispatch({ type: ActionTypes.CancelActivityProfileAnalysis, model: model }),
        startNewAnalysis: (model: StartActivityProfileAnalysis) => dispatch({ type: ActionTypes.StartActivityProfileAnalysis, model: model }),
        endAnalysis: (model: EndActivityProfileAnalysis) => dispatch({ type: ActionTypes.EndActivityProfileAnalysis, model: model }),
        completeAnalysis: (model: CompleteActivityProfileAnalysis) => dispatch({ type: ActionTypes.CompleteActivityProfileAnalysis, model: model }),
        getView: () => dispatch({ type: ActionTypes.GetActivityProfileAnalysis }),
        exportToExcel: (model: ExportActivityProfileAnalysis) => dispatch({ type: ActionTypes.ExportActivityProfileAnalysis, model: model }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTool);