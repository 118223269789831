import { Button, Checkbox, Switch } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';
import './chartcontrol.scss'
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect } from "react";
import {DownloadChartAsImage as _DownloadChartAsImage} from '../../../Utils/helperFunctions';

interface IProps {
    showDownloadBtn?: boolean;
    showSelectCheckBox?: boolean;
    showSwitchRadarBar?: boolean;


    chartDivId: string;
    chartType?: string;
    switchChart?: () => void;
    selectChart?: (chartDivId: string, add: boolean) => void;
    selectedCharts?: string[];
};

const ChartControls = (props: IProps) => {


    // useEffect(() => {
    //     console.log('subleversTest props.selectedCharts: ', props.selectedCharts);

    //     console.log('subleversTest props.chartDivId: ', props.chartDivId);

    //     console.log('subleversTest props.selectedCharts?.includes(props.chartDivId): ', props.selectedCharts?.includes(props.chartDivId));


    // }, [props.selectedCharts]);


    const DownloadChartAsImage = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const canvasSave = document.getElementById(props.chartDivId);
        // html2canvas(canvasSave)
        //     .then((canvas) => {
        //         canvas.toBlob(function (blob) {
        //             saveAs(blob, `${props.chartDivId}.png`)
        //         }, 'png', 1)
        //     })
        _DownloadChartAsImage(`${props.chartDivId}.png`, canvasSave);
    };
    const switchChart = (checked, e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.switchChart)
            props.switchChart();
    };
    const selectChart = (e: CheckboxChangeEvent) => {
        //e.preventDefault();
        //e.stopPropagation();
        console.log('subleversTest chartTitle: ', props.chartDivId);

        if (!props.selectChart) return;
        if (e.target.checked)
            props.selectChart(props.chartDivId, true);
        else
            props.selectChart(props.chartDivId, false);
    };
    const checkBoxClicked = (e) => {
        //e.preventDefault();
        e.stopPropagation();
    };
    //console.log('controls props: ', props);

    return (
        <div>
            <div className='chart-controls'>

                {props.showDownloadBtn ? <div>
                    <Button className='chart-download-Btn'
                        icon={<DownloadOutlined style={{'margin':'auto'}} />}
                        onClick={(e) => DownloadChartAsImage(e)}
                        type="default"
                        title="Download chart" />
                </div> : null}
                {props.showSwitchRadarBar ? <div>
                    <Switch
                        style={{ 'zIndex': 1000 }}
                        checked={props.chartType === 'radar'}
                        onChange={(checked, e) => switchChart(checked, e)}
                        size='small'
                        title={(props.chartType === 'radar') ? "Switch to bar chart" : "Switch to radar chart"} />
                    {/* </Tooltip> */}
                </div> : null}
            </div>
            {props.showSelectCheckBox ? <div
                title={(props.selectedCharts?.includes(props.chartDivId) === true) ? "Unselect chart" : "Select chart"}
                style={{ 'position': 'absolute', 'top': '5px', 'left': '5px', 'zIndex': '1000' }}
                onClick={checkBoxClicked}>
                <Checkbox checked={props.selectedCharts?.includes(props.chartDivId) === true} onChange={selectChart} style={{ 'zIndex': '100000' }} />
            </div> : null}
        </div>
    );
};

export default ChartControls;