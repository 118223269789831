
import { Col, Row } from "antd";
import "./index.scss";
import FrontLoadingTemp from "./FrontLoadingTemp/FrontLoadingTemp";

const FrontLoading = (props) => {
    
    return (
        <FrontLoadingTemp />
    )
}

export default FrontLoading;