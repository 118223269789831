import { Avatar, Button, Card, Col, Form, Image, Layout, List, Result, Row, Select, Space, Spin, Switch, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import { IIaDiscipline, IaVotesAddDto } from "../../../Models/InterfaceAnalysis/IAItems";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState, InterfaceAnalysisState } from "../../../Redux/reducers/IGeneralState";
import './IATool.scss';
import 'moment/locale/de.js';
import 'moment/locale/en-gb.js';
import Table, { ColumnsType } from "antd/lib/table";
import Starts from "./widgets/Starts";
import TextArea from "antd/lib/input/TextArea";
import { CheckCircleFilled, CloseCircleFilled, SmileOutlined } from "@ant-design/icons";
import logoAsup from '../../../assets/Images/asup-logo-HR.png';
import { useSearchParams } from "react-router-dom";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import Countdown from "antd/lib/statistic/Countdown";
import { t } from "i18next";
import { ToastMessages } from "../../../Enums/Messages";
import { openNotificationWithIcon } from "../../../Utils/helperFunctions";
import HeaderLangThemeSwitcher from "../../../Components/HeaderLangThemeSwitcher";
import customerImg from "../../../assets/Images/customer_default.png";
moment.locale(i18n.language);

interface DataType {
    key?: React.Key;
    id: number;
    qValue: number | null;
    rValue: number | null;
    kValue: number | null;
    name: string;
    noInteraction: boolean;
    comment?: string;
}
interface IProps {
    getView: (token) => any;
    goToSetDiscipline: (model: IIaDiscipline) => any;
    result: InterfaceAnalysisState;
    commonResult: ICommonResponse;
    saveResponse: (model: IaVotesAddDto) => any;
}
const titleCellConfig = (record, rowIndex) => ({
    style: {
        backgroundColor: record["backColor"],
        color: record["foreColor"]
    },
});
const IAToolAnonymous = (props: IProps) => {
    const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
    const [starsValues, setStarsValue] = useState<DataType[]>();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [selectedDepartmentId, setSelectedDepartmentId] = useState<number>(null)
    useEffect(() => {
        prepareColumns();
    }, [props.result?.anonymousResult?.disciplines]);
    useEffect(() => {
        let lang = localStorage.getItem("localLang") ?? "de";
        localStorage.clear();
        localStorage.setItem("localLang", lang);
        props.getView(token);
    }, []);
    useEffect(() => {
        console.log("Changes starsValues", starsValues);
    }, [starsValues])
    useEffect(() => {
        if (props.result?.anonymousResult?.disciplines) {
            setStarsValue(props.result?.anonymousResult?.disciplines?.map(d => {
                return {
                    id: d.id,
                    qValue: null,
                    kValue: null,
                    rValue: null,
                    name: d.name,
                    comment: '',
                    noInteraction: false
                }
            }) ?? [] as DataType[])
        } else {
            return;
        }
    }, [props.result?.anonymousResult?.disciplines])
    interface IPropsIcon {
        name: string;
        rowId: number;
        data: DataType[];
        setStarsValue: (data: DataType[]) => void;
    }
    const IconDisable = (props: IPropsIcon) => {

        const changeNoInteraction = () => {
            console.log();
            //setDisabledValue((prevState: Boolean) => !prevState);
            let dd = props.data;
            let elementIndex = dd?.findIndex(d => d.id === props.rowId)
            let element: any = {
                id: props.rowId,
                qValue: null,
                kValue: null,
                rValue: null,
                name: props.name,
                comment: dd[elementIndex]?.comment,
                noInteraction: !dd[elementIndex]?.noInteraction
            };
            dd[elementIndex] = element;
            props.setStarsValue(dd)
        }
        return <Tooltip title={`${(!props.data?.filter(d => d.id === props.rowId)?.[0]?.noInteraction) ?
            t("I have interaction with this department") : t("No Previous Interaction")}`}>
            <Switch
                checkedChildren={<CheckCircleFilled style={{
                    cursor: "pointer",
                    fontSize: ".9rem",
                    color: "currentcolor"
                }}
                //title={`${t("I have interaction with this department")}`}
                //onClick={(e) => changeNoInteraction()}
                />}
                unCheckedChildren={<CloseCircleFilled
                    style={{
                        fontSize: ".9rem",
                        cursor: "pointer",
                        color: "currentcolor"
                    }}
                />}
                defaultChecked={!props.data?.filter(d => d.id === props.rowId)?.[0]?.noInteraction}
                onChange={(e) => changeNoInteraction()}
            />
        </Tooltip>
    }
    const prepareColumns = () => {
        let _cols: ColumnsType<DataType> = [
            {
                title: `${t("Departments")}`,
                dataIndex: 'name',
                key: 'name',
                width: '200px',
                fixed: 'left',
                className: "prepare_col_name",
                onCell: titleCellConfig,
            },
            {
                title: `${t("Do you have interaction with it")}?`,
                dataIndex: 'noIteraction',
                key: 'noIteraction',
                width: '150px',
                fixed: 'left',
                className: "prepare_col_name",
            },
            {
                title: `${t("Quality Of Work Results")}`,
                dataIndex: 'q',
                key: 'q',
                width: '350px',
                //fixed: 'left',
                className: "prepare_col_name",
            },
            {
                title: `${t("Response Time")}`,
                dataIndex: 'r',
                key: 'r',
                width: '350px',
                //fixed: 'left',
                className: "prepare_col_name",
            },
            {
                title: `${t("Communication")}`,
                dataIndex: 'c',
                key: 'c',
                width: '350px',
                //fixed: 'left',
                className: "prepare_col_name",
            },
            {
                title: `${t("Comments")}`,
                dataIndex: 'comment',
                key: 'comments',
                width: '350px',
                //fixed: 'left',
                className: "prepare_col_name",
            }

        ];

        setColumns(_cols);
    }
    const data = [];
    props.result?.anonymousResult?.disciplines.filter(c => c.id != selectedDepartmentId).map(disc => {
        let tt = {
            key: `disc_from_${disc.id}`,
            fromDisciplineId: disc.id,
            name: disc.name,
            noIteraction: <IconDisable name={disc.name} rowId={disc.id} data={starsValues} setStarsValue={(dataTemp: DataType[]) => setStarsValue([...dataTemp])} />,
            foreColor: disc.frontendColor,
            backColor: disc.backColor,
            q: <Starts data={starsValues} dicId={disc.id} qValue={true} />,
            r: <Starts data={starsValues} dicId={disc.id} rValue={true} />,
            c: <Starts data={starsValues} dicId={disc.id} kValue={true} />,
            comment: <TextArea rows={2} maxLength={250}
                //required={!starsValues?.filter(d => d.id === disc.id)?.[0].noInteraction}
                placeholder={`${t("Leave a comment if you don't want to answer")}`}
                onChange={(e) => {
                    let dataComment: DataType[] = starsValues;
                    let elementIndex = dataComment?.findIndex(d => d.id === disc.id)
                    let element: DataType = {
                        id: disc.id,
                        qValue: dataComment[elementIndex]?.qValue,
                        kValue: dataComment[elementIndex]?.kValue,
                        rValue: dataComment[elementIndex]?.rValue,
                        name: dataComment[elementIndex]?.name,
                        comment: e.target.value,
                        noInteraction: dataComment[elementIndex]?.noInteraction,
                    };
                    dataComment[elementIndex] = element;
                    setStarsValue(dataComment)
                    //console.log("onchange comment", dataComment[elementIndex]);
                }}
            />
        };
        data.push(tt);
    });
    if (props.result?.isSuccessAnonymousVoting) {
        return <Result
            status="success"
            title={`${t("Thanks for your voting")}`}
        />;
    }
    if (props.result?.isLoading) {
        return <Spin spinning={props.result?.isLoading}>
            <Result
                icon={<SmileOutlined />}
                title={`${t("Loading")}`}
            />
        </Spin>
    }
    if (!props.result?.anonymousResult) {
        return <Result
            status="404"
            title="404"
            subTitle={(typeof props.commonResult?.error?.exceptionMessage == 'string') ? props.commonResult?.error?.exceptionMessage as string : ToastMessages.UnExpectedError}
        />;
    }
    if (props.result?.anonymousResult?.remainingMinutes <= 0) {
        return <Result
            status="warning"
            title={`${t("The round is under processing.")}`}
        />;
    }
    return <Layout className="ia_tool_anonymous">
        <HeaderLangThemeSwitcher />
        <Layout.Header>
            <Space >
                <img width={100} src={logoAsup} alt="asup_logo" />
                <Typography.Title level={1} className="ia_tool_title">{`${t("Interface Survey")}`}</Typography.Title>
            </Space>
        </Layout.Header>
        <Layout.Content  >
            <Card>
                <List.Item style={{ padding: " .8rem" }}>
                    <List.Item.Meta
                        avatar={<Avatar src={props.result?.anonymousResult?.customerLogo ? props.result?.anonymousResult?.customerLogo : customerImg} size="large" />}
                        title={<Space direction="vertical" size="small" style={{ display: 'flex' }}>
                            <Typography.Title level={4} >{props.result?.anonymousResult?.customerName}</Typography.Title>
                            <Typography.Title level={5} type="secondary">{props.result?.anonymousResult?.projectName}</Typography.Title>
                        </Space>
                        }

                        description={<p style={{ marginBottom: '0' }}>
                            <Countdown value={Date.now() + props.result?.anonymousResult?.remainingMinutes * 60 * 1000} format="HH:mm:ss" />
                            {`${t("If you are unable to make any statements about cooperation with specific departments, please also note this in the free text and do not select an evaluation number")}`}
                        </p>}

                    />
                </List.Item>
                <Row style={{ padding: "0 2rem" }}>
                    <Col span={12}>
                        <Form.Item
                            label={`${t("Please, Choose your department")}`}
                            name="selectedDepartmentId"
                            rules={[{ required: true, message: `${t("Please, Choose your department")}` }]}
                        >
                            <Select
                                showSearch
                                placeholder={`${t("Select a department")}`}
                                optionFilterProp="children"
                                onChange={(value) => {
                                    setSelectedDepartmentId(value);
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={props.result?.anonymousResult?.disciplines?.map(c => {
                                    return {
                                        label: c.name,
                                        value: c.id
                                    }
                                })}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Spin spinning={props.result?.isLoading} >
                    <Table
                        columns={columns}
                        dataSource={data}
                        bordered
                        size="small"
                        className="ia_table "
                        pagination={false}
                        //scroll={{ x: 'calc(700px + 50%)', y: "calc(100vh - 450px)" }}
                        scroll={{ y: "calc(100vh - 450px)" }}
                    >
                    </Table>
                </Spin>
                <Space style={{ justifyContent: "flex-end", width: "100%", padding: "0 2rem", marginTop: "1rem" }}>
                    <Space size={[0, 8]} wrap>
                        <Tag color="#FC3E40">{`${t("Very low")}`}</Tag>
                        <Tag color="#F7DA46">{`${t("Sufficient")}`}</Tag>
                        <Tag color="#00C04D">{`${t("High")}`}</Tag>
                        <Tag color="#008631">{`${t("Very high")}`}</Tag>
                    </Space>
                    <Button type="primary" style={{ padding: "0 3rem" }} onClick={() => {
                        //console.log("Save", starsValues);
                        if (!selectedDepartmentId) {
                            openNotificationWithIcon("warning", `${t("Warning")}`, `${t("Please, Choose your department")}`);
                            return;
                        }
                        props.saveResponse({
                            token: token,
                            participantDepartmentId: selectedDepartmentId,
                            votes: starsValues.map(c => {
                                return {
                                    comment: c.comment,
                                    departementId: c.id,
                                    kRate: c.kValue,
                                    qRate: c.qValue,
                                    rRate: c.rValue,
                                    noPreviousInteraction: c.noInteraction
                                }
                            })
                        })
                    }} className="ia-btn" >{`${t("Save")}`}</Button>
                </Space>

            </Card>
        </Layout.Content>
    </Layout>
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        result: state.interfaceAnalysis,
        commonResult: state.generalInfo?.commonResponse,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getView: (token: string) => dispatch({ type: ActionTypes.GetIAViewAnonymous, token: token }),
        saveResponse: (model: IaVotesAddDto) => dispatch({ type: ActionTypes.SaveResponseForAnonymousAnalysisInterface, model: model }),
        goToSetDiscipline: (model: IIaDiscipline) => dispatch({ type: ActionTypes.GoToSetDisipline, model: model })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IAToolAnonymous);