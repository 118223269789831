import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RnDUnit } from "../../Scenes/Tools/EBITTool-V2/Models/RnDUnit";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { t } from "i18next";
//const { t } = useTranslation();
export const getBasicColoredTableHeader = () => {
  return [
    {
      title: "",
      dataIndex: "title",
      fixed: "left",
      width: "280px",
      onCell: (record, rowIndex) => ({
        style: {
          backgroundColor: record["isSubRow"] ? "#FFF" : record["color"],
          color: record["isSubRow"] ? "#000" : null,
        },
      }),
    },
    {
      title: "",
      children: [
        {
          title: "%",
          dataIndex: "percent",
          fixed: "left",
          // width: "140px",
        },
      ],
    },
    {

      title: i18n.t("Kostenstruktur"),
      children: [
        {
          title: `${i18n.t("FTE intern")}`,
          dataIndex: "fte_intern",
          // width: "140px",
          key: "6",
        },
        {
          title: `${i18n.t("Gesamt")}`,
          dataIndex: "gesamt",
          // width: "140px",
          key: "7",
        },
        {
          title: `${i18n.t("PK intern")}`,
          dataIndex: "pk_intern",
          // width: "140px",
          key: "8",
        },
        {
          title: `${i18n.t("PK extern")}`,
          dataIndex: "pk_extern",
          // width: "140px",
          key: "9",
        },
      ],
    },
  ] as ColumnsType;
}

export const renderWarning = (unit: RnDUnit) => {
  //console.log("renderWarning ", unit);
  let sumFull = 0;
  unit.rDCosts.map((item) => {
    sumFull += item?.fullTimeEquivalent ?? 0;
  });

  if (sumFull != unit.totalFullFimeEquivalent) {
    return (
      <Tooltip placement='bottom' title={i18n.t("Gesamt") + ": " + unit.totalFullFimeEquivalent + " " + i18n.t("Erfasst") + ": " + sumFull}>
        <ExclamationCircleOutlined
          style={{ marginLeft: "1px", color: "red" }}
        />
      </Tooltip>
    );
  }
};

// Rendering the header of each R&D Unit
export const renderPanelHeader = (unit: RnDUnit, toggleUpdateModal: (vunit: RnDUnit) => any, deleteUnit: (id: number) => any
) => {
  return (
    <Row
      style={{ marginBottom: 0, fontWeight: "normal", color: "black" }}
    >
      <Col span={1}></Col>
      <Col span={6} style={{ textAlign: "center" }}>
        {unit.totalFullFimeEquivalent.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}{" "}
        FTE {renderWarning(unit)}
      </Col>
      <Col span={14} style={{ textAlign: "center" }}>
        {`${i18n.t("localizedvalue", { count: unit.averageAnnualCost })} ${i18n.t("Durchs. Jahressatz")}`}
      </Col>
      <Col span={3} style={{ textAlign: "center" }}>
        <EditOutlined
          style={{ marginRight: "5px" }}
          onClick={(event) => {
            event.stopPropagation();
            toggleUpdateModal(unit);
          }}
        />
        <Popconfirm
          title={`${t("Are you sure?")}`}
          onConfirm={() => deleteUnit(unit.id)}
          okText={`${t("Yes")}`}
          cancelText={`${t("No")}`}
        >
          <DeleteOutlined />
        </Popconfirm>
      </Col>
    </Row>
  );
};

export const getBasicColoredTableHeaderChangable = (units: RnDUnit[], toggleUpdateModal: (vunit: RnDUnit) => any, deleteUnit: (id: number) => any,
  saveChanges: () => any, AddNewUnit: () => any, onExport: () => any) => {

  let columns = [
    {
      title: <Space align="center">
        <Tooltip placement="bottom" title={`${i18n.t("Speichern")}`}>
          <Popconfirm
            title={`${t("Are you sure?")}`}
            onConfirm={() => saveChanges()}
            okText={`${t("Yes")}`}
            cancelText={`${t("No")}`}
          >
            <Button icon={<SaveOutlined />} type="default"></Button></Popconfirm>
        </Tooltip>
        <Tooltip placement="bottom" title={`${i18n.t("Daten als Excel downloaden")}`}>
          <Button icon={<DownloadOutlined />} onClick={() => { onExport() }} type="default"></Button>
        </Tooltip>
        <Tooltip placement="bottom" title={`${i18n.t("Add new organizational unit")}`}>
          <Button icon={<PlusOutlined />} onClick={() => { AddNewUnit() }} type="default"></Button>
        </Tooltip>
      </Space>,
      dataIndex: "title",
      fixed: "left",
      width: "25vh",
      onCell: (record, rowIndex) => ({
        style: {
          backgroundColor: record["isSubRow"] ? "#FFFFFF" : record["backgroundColor"],
          color: record["isSubRow"] ? "#000" : record["foreColor"],
        },
      }),
    },
    {
      title: "",
      children: [
        {
          title: "%",
          dataIndex: "percent",
          fixed: "left",
          // width: "140px",
        },
      ],
    },
    {
      title: i18n.t("Kostenstruktur"),
      children: [
        {
          title: `${i18n.t("FTE intern")}`,
          dataIndex: "fte_intern",
          // width: "140px",
          key: "6",
        },
        {
          title: `${i18n.t("Gesamt")}`,
          dataIndex: "gesamt",
          // width: "140px",
          key: "7",
        },
        {
          title: `${i18n.t("PK intern")}`,
          dataIndex: "pk_intern",
          // width: "140px",
          key: "8",
        },
        {
          title: `${i18n.t("PK extern")}`,
          dataIndex: "pk_extern",
          // width: "140px",
          key: "9",
        },
      ],
    },
  ] as ColumnsType;
  // Fixed part we add each time we add unit
  units.map((unit, index) => {
    let addColumns: ColumnsType = [
      {
        title: (
          <Row>
            <Col span={24}>
              <span>
                {" "}
                {unit.name}
                {renderPanelHeader(unit, toggleUpdateModal, deleteUnit)}
              </span>
            </Col>
          </Row>
        ),

        children: [
          {
            key: 1,
            title: "FTE",
            dataIndex: `fte_mit_pe_beteiligung_${unit.id}`,
            onCell: (record, rowIndex) => ({
              style: {
                backgroundColor: record["isSubRow"] ? "#FFFFFF" : record["backgroundColor"],
                color: record["isSubRow"] ? "#000" : record["foreColor"],
                //backgroundColor: record["color"],
                opacity: index % 2 === 0 ? 0.9 : 0.7,
              },
            }),
          },
          {
            key: 2,
            title: `${i18n.t("Int. Kosten")}`,
            dataIndex: `interne_kosten_${unit.id}`,
            onCell: (record, rowIndex) => ({
              style: {
                backgroundColor: record["isSubRow"] ? "#FFFFFF" : record["backgroundColor"],
                color: record["isSubRow"] ? "#000" : record["foreColor"],
                //backgroundColor: record["color"],
                opacity: index % 2 === 0 ? 0.9 : 0.7,
              },
            }),
          },
          {
            key: 3,
            title: `${i18n.t("Ext. Kosten")}`,
            dataIndex: `externe_kosten_${unit.id}`,
            onCell: (record, rowIndex) => ({
              style: {
                backgroundColor: record["isSubRow"] ? "#FFFFFF" : record["backgroundColor"],
                color: record["isSubRow"] ? "#000" : record["foreColor"],
                //backgroundColor: record["color"],
                opacity: index % 2 === 0 ? 0.9 : 0.7,
              },
            }),
          },
          {
            key: 4,
            title: `${i18n.t("Kosten gesamt")}`,
            dataIndex: `kosten_gesamt_${unit.id}`,
            onCell: (record, rowIndex) => ({
              style: {
                backgroundColor: record["isSubRow"] ? "#FFFFFF" : record["backgroundColor"],
                color: record["isSubRow"] ? "#000" : record["foreColor"],
                //backgroundColor: record["color"],
                opacity: index % 2 === 0 ? 0.9 : 0.7,
              },
            }),
          },
        ],
      },
    ];

    columns = columns.concat(addColumns);
  })
  console.log("columns ebit ", columns);
  return columns;
}