
import { ToastMessages } from "../Enums/Messages";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import { saveAs } from "file-saver";
import { ActivityParticipantAnswer } from "../Scenes/Tools/ActivityTool/Survey/Models/ActivityParticipantAnswerDto";
import { ActivityResponseReviewDto, ActivityVoteReviewDto, CancelActivityProfileAnalysis, CompleteActivityProfileAnalysis, CreateActivityProfileAnalysisDto, EndActivityProfileAnalysis, ExportActivityProfileAnalysis, StartActivityProfileAnalysis } from "../Scenes/Tools/ActivityTool/Survey/Models/ActivitySurveyData";
import { IActivityTask, IMapTaskToGroup } from "../Models/IActivityManagement";
export default class ActivityService {
    static async GetProfileVersionTasks(profileId: number, versionId: number): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetProfileVersionTasks, {
                params: {
                    profileId,
                    versionId
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async GetCurrentProfileVersions(profileId: number): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetCurrentProfileVersions, {
                params: {
                    profileId
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }
    //survey services
    static async GetSurveyDataForParticipant(token: string): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetAllForAnonymousActivitySurvey, { params: { token: token } })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async UploadParticipantAnswer(participantAnswer: ActivityParticipantAnswer): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.SaveParticipantAnswer, participantAnswer)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async GetActivityProjectTasks(activityProjectProfileId: number): Promise<ICommonResponse> {
        console.log("activityProjectProfileId: ", activityProjectProfileId);

        return await asupapi
            .get(endPoints.getActivityProjectTasks, {
                params: {
                    activityProjectProfileId: activityProjectProfileId
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async CancelActivityProfileAnalysis(model: CancelActivityProfileAnalysis): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.CancelActivityVotingForPtofile, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CreateActivityProfileAnalysis(model: CreateActivityProfileAnalysisDto): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.CreateActivityVotingForPtofile, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async StartActivityProfileAnalysis(model: StartActivityProfileAnalysis): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.StartActivityVotingForPtofile, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async GetAll(): Promise<ICommonResponse> {
        return await asupapi
            .get(endPoints.GetAllActivityVoting)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                //console.log("result test2", result);
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async UpdateActivityToolTask(model: IActivityTask): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.updateActivityToolTask, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async DeleteActivityToolTask(id: number): Promise<ICommonResponse> {
       
        
        return await asupapi
            .get(endPoints.deleteActivityToolTask, {
                params: { id },
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async addActivityToolTaskToGroups(model: IMapTaskToGroup) {
        return await asupapi
            .post(endPoints.addActivityToolTaskToGroups, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }

    static async EndActivityProfileAnalysis(model: EndActivityProfileAnalysis): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.EndActivityVotingForPtofile, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async CompleteActivityProfileAnalysis(model: CompleteActivityProfileAnalysis): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.CompleteActivityVotingForPtofile, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }
    static async ReviewActivityAnswer(model: ActivityResponseReviewDto): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.ReviewActivityAnswer, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async ReviewActivityVote(model: ActivityVoteReviewDto): Promise<ICommonResponse> {
        return await asupapi
            .post(endPoints.ReviewActivityVote, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('error1 ', error.response);
                    if (error.response.data) {
                        console.log('error0 ', error?.response?.data);
                        let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
                        return result;
                    } else {
                        return {
                            isError: false,
                            statusCode: -400,
                            error: {
                                exceptionMessage: ToastMessages.UnExpectedError
                            }
                        } as ICommonResponse
                    }
                } else if (error.request) {
                    console.log('error2 ', error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError
                        }
                    } as ICommonResponse
                } else if (error.message) {
                    console.log('error3 ', error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError
                    }
                } as ICommonResponse
            });
    }

    static async ExportToExcel(model: ExportActivityProfileAnalysis) {
        await asupapi
          .post(endPoints.ExportActivityVotingForPtofile, model, {
            responseType: 'blob'
          })
          .then(function (response) {
            var contentDisposition = response.headers["content-disposition"];
            var match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
            var filename = match[1];
            console.log("filename:", filename);
            var blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, filename);
    
          }).catch(function (error) {
            console.log('error1 ', error);
          });
      }

      static async getActivityProgress(): Promise<ICommonResponse> {
        return await asupapi
          .get(endPoints.getActivityProgress)
          .then(function (response) {
            let result: ICommonResponse = response.data;
            return result;
          })
          .catch(function (error) {
            if (error.response) {
              console.log("error1 ", error.response);
              if (error.response.data) {
                console.log("error0 ", error.response.data);
                let result: ICommonResponse = error.response.data;
                return result;
              }
            } else if (error.request) {
              console.log("error2 ", error.request);
              return {
                isError: false,
                statusCode: -400,
                error: {
                  exceptionMessage: ToastMessages.NetworkError,
                },
              } as ICommonResponse;
            } else if (error.message) {
              console.log("error3 ", error.message);
              //do something other than the other two
            }
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError,
              },
            } as ICommonResponse;
          });
      }

      static async DeleteActivityAnswer(answerId: number): Promise<ICommonResponse> {            
        return await asupapi
            .get(endPoints.DeleteActivityAnswer, {
                params: { answerId },
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
}