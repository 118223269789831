import { Breadcrumb } from "antd";
import { t } from "i18next";

export function getLeversTableHeader(isMinimized: boolean) {
    let LeverTableHeader = [
        {
            title: `${t("Number")}`,            
            dataIndex: 'title',
            key: 'title',            
            width: '600px',
            fixed: "left",
            index: 1,
        },
        {
            title: `${(isMinimized ? 'KPI' : t("KPI Value"))}`,
            onCell: (record, index) => ({ colSpan: 1 }),
            dataIndex: 'kpiWert',
            key: 'kpiWert',
            width: '120px',
            index: 2,
        },
        {
            title: `${(isMinimized ? t('Range') : t('Wertebereich'))}`,
            dataIndex: 'wertebereich',
            key: 'wertebereich',
            width: '100px',
            index: 3,
        },
        {
            title: `Status`,
            dataIndex: 'StatusId',
            key: 'StatusId',
            width: '100px',
            index: 4
        },
        {
            title: `${(isMinimized ? t('Weight') : t('Gewichtung'))}`,
            dataIndex: 'Gewichtung',
            key: 'Gewichtung',
            width: '100px',
            index: 5
        },
        {
            title: `${(isMinimized ? t('PI Graf') : t("Funktionsart"))}`,
            dataIndex: 'Funktionsart',
            key: 'Funktionsart',
            width: '100px',
            index: 6
        },
        {
            title: `${(isMinimized ? t('PI min') : t('PI Untergrenze'))}`,
            dataIndex: 'piuntergrenze',
            key: 'piuntergrenze',
            width: '100px',
            index: 7
        },
        {
            title: `${(isMinimized ? t('Best') : t('Best Practice Wert'))}`,
            dataIndex: 'bestpracticevalue',
            key: 'bestpracticevalue',
            width: '100px',
            index: 8
        },
        {
            title: `${(isMinimized ? t('PI max') : t('PI Obergrenze'))}`,
            dataIndex: 'piobergrenze',
            key: 'piobergrenze',
            width: '100px',
            index: 9
        }];

    let LeverTableHeader2 = isMinimized ? [
        {
            title: `Erfüllungsgrad`,
            children: [
                {
                    title: `${(isMinimized ? 'KPI' : t('KPI Erfüllungsgrad'))}`,
                    dataIndex: 'kpierfüllungsgrad',
                    key: 'kpierfüllungsgrad',
                    width: '100px',
                    index: 10
                },
                {
                    title: `${(isMinimized ? 'EF' : t('EF Erfüllungsgrad'))}`,
                    dataIndex: 'eferfüllungsgrad',
                    key: 'eferfüllungsgrad',
                    width: '100px',
                    index: 11
                },
                {
                    title: `${(isMinimized ? 'TSH' : t('TSH Erfüllungsgrad'))}`,
                    dataIndex: 'tsherfüllungsgrad',
                    key: 'tsherfüllungsgrad',
                    width: '100px',
                    index: 12
                }
            ]
        }
    ] : [
        {
            title: `${(isMinimized ? 'KPI' : t('KPI Erfüllungsgrad'))}`,
            dataIndex: 'kpierfüllungsgrad',
            key: 'kpierfüllungsgrad',
            width: '100px',
            index: 10
        },
        {
            title: `${(isMinimized ? 'EF' : t('EF Erfüllungsgrad'))}`,
            dataIndex: 'eferfüllungsgrad',
            key: 'eferfüllungsgrad',
            width: '100px',
            index: 11
        },
        {
            title: `${(isMinimized ? 'TSH' : t('TSH Erfüllungsgrad'))}`,
            dataIndex: 'tsherfüllungsgrad',
            key: 'tsherfüllungsgrad',
            width: '100px',
            index: 12
        }] as any[];

    let LeverTableHeader3 = [
        {
            title: `${(isMinimized ? 'F&E' : 'F&E')}`,
            dataIndex: 'fe',
            key: 'fe',
            width: '100px',
            index: 13
        },
        {
            title: `${(isMinimized ? 'INNO' : 'INNO')}`,
            dataIndex: 'inno',
            key: 'inno',
            width: '100px',
            index: 14
        },
        {
            title: `${(isMinimized ? 'COD' : 'COD')}`,
            dataIndex: 'cod',
            key: 'cod',
            width: '100px',
            index: 15
        },
        {
            title: `${(isMinimized ? 'HK' : 'HK')}`,
            dataIndex: 'hk',
            key: 'hk',
            width: '6vw',
            index: 16
        },
        {
            title: `${(isMinimized ? 'QK' : 'QK')}`,
            dataIndex: 'qk',
            key: 'qk',
            width: '100px',
            index: 17
        },
        {
            title: `${(isMinimized ? 'Estim.' : t('Estimate'))}`,
            dataIndex: 'estimate',
            key: 'estimate',
            width: '100px',
            index: 18
        }
    ];

    return LeverTableHeader.concat(LeverTableHeader2).concat(LeverTableHeader3);
}

export function getLeversCustomerTableHeader(isMinimized: boolean) {
    let LeverTableHeader = [
        {
            title: `${t("Number")}`,            
            dataIndex: 'title',
            key: 'title',
            width: '600px',
            fixed: "left",
            index: 1,
        },
        {
            title: `${(isMinimized ? 'KPI' : 'KPI Wert')}`,
            onCell: (record, index) => ({ colSpan: 1 }),
            dataIndex: 'kpiWert',
            key: 'kpiWert',
            width: '100px',
            index: 2
        },
        {
            title: `${(isMinimized ? 'Range' : 'Wertebereich')}`,
            dataIndex: 'wertebereich',
            key: 'wertebereich',
            width: '100px',
            index: 3,
        },
        {
            title: `${(isMinimized ? 'Komm.' : 'Kommentar')}`,
            dataIndex: 'kommentar',
            key: 'kommentar',            
            index: 4,
        }];

    return LeverTableHeader;
}