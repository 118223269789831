
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import ConsultantUsersService from "../../Services/ConsultantUsersService";
import { IConsultantUser } from "../../Models/IConsultantUsers";

function* OnGetConsultantUsers(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CONSULTANT_USER_LOADER, isLoading: true });
        let _result: ICommonResponse = yield ConsultantUsersService.GetConsultantUsers();
        if (!_result.isError) {
            let result = _result.result as IConsultantUser[];
            yield put({ type: ActionTypes.SAVE_CONSULTANT_USERS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_CONSULTANT_USER_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetConsultant Users: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnCreateConsultantUser(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CONSULTANT_USER_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let _result: ICommonResponse = yield ConsultantUsersService.CreateConsultantUser(
            action.model
        );
        if (!_result.isError) {
            let _result: ICommonResponse = yield ConsultantUsersService.GetConsultantUsers();
            let result = _result.result as IConsultantUser[];
            yield put({ type: ActionTypes.SAVE_CONSULTANT_USERS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_CONSULTANT_USER_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateConsultantUser(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_CONSULTANT_USER_LOADER, isLoading: true });
        let _result: ICommonResponse = yield ConsultantUsersService.UpdateConsultantUser(action.model);

        if (!_result.isError) {
            let resultCus: ICommonResponse = yield ConsultantUsersService.GetConsultantUsers();
            let result = resultCus.result as IConsultantUser[];
            yield put({ type: ActionTypes.SAVE_CONSULTANT_USERS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_CONSULTANT_USER_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateConsultant: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}


function* watchGetConstantUsers() {
    yield takeEvery(ActionTypes.GET_CONSULTANT_USERS, OnGetConsultantUsers);
}
function* watchCreateConstantUser() {
    yield takeEvery(ActionTypes.CREATE_CONSULTANT_USER, OnCreateConsultantUser);
}
function* watchUpdateConstantUser() {
    yield takeEvery(ActionTypes.UPDATE_CONSULTANT_USER, onUpdateConsultantUser)
}

export default function* consultantUsersSagas() {
    yield all([
        watchGetConstantUsers(),
        watchCreateConstantUser(),
        watchUpdateConstantUser(),
    ]);
}
