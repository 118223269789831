import React, { useState } from 'react';
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from 'antd';
import { UploadProps , Tooltip } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import { useTranslation } from "react-i18next";

interface IProps {
    //currentProject: BmProject;
    isForEbit: boolean;
    currentProjectId: number;
    onImportCostTable: (frmData: FormData, forEbit: boolean) => any;
}
function UploadFile(props: IProps) {
    const { t } = useTranslation();
    const uploadExcel = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        fmData.append('File', file as RcFile);
        fmData.append('BmProjectId', props.currentProjectId.toString());
        fmData.append('forEbit', props.isForEbit.toString());
        try {
            await props.onImportCostTable(fmData, props.isForEbit);
            onSuccess("Ok");
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    };

    const uploadDialogProps: UploadProps = {
        customRequest(options) {
            uploadExcel(options)
        },
        beforeUpload: (file) => {
            const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isExcel) {
                message.error(`${file.name} is not an Excel file`);
            }
            return isExcel || Upload.LIST_IGNORE;
        },
        showUploadList: false,
    };

    return (
        <Upload
            {...uploadDialogProps}>
                <Tooltip placement="top" title={t("Daten importieren")}>
                <Button size="small" icon={<UploadOutlined style={{ fontSize: '12px' }} />} />
                </Tooltip>
            
        </Upload>
    )
}
export default UploadFile;