import { Table, Typography, Tag, Button, Tooltip, Space, } from 'antd';
import React, { useRef, useState } from 'react';
import { PlusCircleTwoTone, MinusCircleTwoTone, EditOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import { IBMProjectItem } from '../../../Models/IBMProjectItem';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { formatValue } from '../../../Utils/helperFunctions';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { IListItem } from '../../../Models/Responses/ProfileResponse';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import PopconfirmAsync from '../../../Components/PopconfirmAsync';
import CompletionBmProjectModal from './CompletionBmProjectModal';
import DetailsCompletionBmProjectModal from './DetailsCompletionBmProjectModal';
import { t } from 'i18next';
import BmProjectEditModal from './BmProjectEditModal';
import { IIndustryAll } from '../../../Models/IIndustry';
import i18n from '../../../i18n';

interface IProps {
    originDataRow: any;
    customers: IListItem[];
    industries: IListItem[];
    languagesList: IListItem[];
    currencyList: IListItem[];
    reOpenBmProject: (bmProjectId: number, customerId: number) => any;
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    customerId?: number;
    industriesData: IIndustryAll[];
    includeBmProject: (bmProjectId: number, customerId: number) => any;
    excludeBmProject: (bmProjectId: number, customerId: number) => any;

}

const BmProjectTable = (props: IProps) => {

    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end


    const columns: any[] = [
        {
            key: "bmProject_name_key",
            title: 'Name',
            dataIndex: 'name',
            width: "15%",
            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>,
            ...getColumnSearchProps("Name ", 'name', searchInput, handleSearch),
            sorter: (a, b) => a?.name?.localeCompare(b?.name)  ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: "bmProject_shortname_key",
            title: `${t("Short Name")}`,
            dataIndex: 'shortName',
            width: "10%",
            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>
        },
        props.customerId ? ({ width: 0 }) : (
            {
                key: "customer_name_key",
                title: `${t("Customer")}`,
                dataIndex: 'customerId',
                width: "10%",
                render: (text: any, record: any) => <Typography.Text>{record?.customerName}</Typography.Text>

            }
        )
        ,
        {
            key: "industry_key",
            title: `${t("Industry")}`,
            dataIndex: 'industryId',
            width: "10%",
            render: (text: any, record: any) => <Typography.Text>{record?.industry}</Typography.Text>

        },
        {
            key: "industry_segment_key",
            title: `${t("Industry")} ${t("Segments")}`,
            dataIndex: 'industrySegmentId',
            width: "10%",
            render: (text: any, record: any) => <Typography.Text>{record.industrySegment}</Typography.Text>

        },

        {
            title: `${t("Actions")}`,
            dataIndex: "",
            width: "15%",
            render: (_: any, record: IBMProjectItem) => {
                if (record.isCompleted) {
                    return <Button type="primary"
                        shape="default" icon={<EditOutlined style={{ fontSize: "16px" }} />} disabled>
                        {`${t("Edit")}`}
                    </Button>
                } else {
                    return <BmProjectEditModal
                        customerId={props.customerId}
                        bmProjectData={record}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        isLoading={props.isLoading}
                        customers={props.customers}
                        industries={props.industries}
                        languagesList={props.languagesList}
                        currencyList={props.currencyList}
                        segments={
                            props.industriesData?.filter(i => i.id === record?.industryId)?.map(a => a?.industrySegmentResultDto)[0]
                                .map(i => {
                                    return { value: i.id, label: i18n.language === 'de' ? i?.codeDe : i?.codeEn }
                                })}
                    />
                }

            }
        },
        {
            title: `${t("Easy Access Levers")}`,
            dataIndex: "isIncludedInEasyAccessLever",
            width: "13%",
            render: (_any, record: any) => {
                if (record?.isIncludedInEasyAccessLever) {
                    return <PopconfirmAsync
                        title={`${t("Sure to Exclude?")}`}
                        handleOk={() => excludeBmProject(record.id)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        include={true}
                    />
                } else {
                    return <PopconfirmAsync
                        title={`${t("Sure To Include?")}`}
                        handleOk={() => includeBmProject(record.id)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        exclude={true}
                    />
                }
            }, filters: [
                {
                    text: `${t("Include")}`,
                    value: true,
                },
                {
                    text: `${t("Exclude")}`,
                    value: false,
                },
            ],
            onFilter: (value: string, record) => record?.isIncludedInEasyAccessLever === value,


        },
        {
            title: `${t("Completion")}`,
            dataIndex: "isCompleted",
            width: "15%",
            render: (_any, record: any) => {
                if (record?.isCompleted) {
                    return <PopconfirmAsync
                        title={`${t("Do you want reopen Bm project")}`}
                        handleOk={() => onReopen(record.id)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        reOpenBmProject={true}
                    />
                } else {
                    return <CompletionBmProjectModal bmProjectId={record?.id} customerId={props.customerId} />

                }
            }, filters: [
                {
                    text: `${t("Completed")}`,
                    value: true,
                },
                {
                    text: `${t("Opened")}`,
                    value: false,
                },
            ],
            onFilter: (value: string, record) => record?.isCompleted === value,


        },
        {
            title: `${t("Details")}`,
            dataIndex: "",
            width: "15%",
            render: (_any, record: any) => {
                return <DetailsCompletionBmProjectModal bmProjectId={record?.id} bmProjectName={record?.name} />
            }

        }
    ]
    // NESTED TABLE 1
    const expandedRowRenderI = (record: any) => {
        const dataI = [{ ...record }];
        const columns = [{
            key: "currency_key",
            title: `${t("Currency")}`,
            dataIndex: 'currencyId',
            render: (text: any, record: any) => <Tag color="lime">{record?.currencyCode}</Tag>

        },
        {
            key: "defaultLang_key",
            title: `${t("Language")}`,
            dataIndex: 'defaultLanguageId',
            render: (text: any, record: any) => <Tag color="lime">{record?.defaultLanguageCode}</Tag>
        },
        {
            key: "yearUnder_review_key",
            title: `${t("Year under Review")}`,
            dataIndex: 'yearUnderReview',
            render: (text: any, record: any) => <Tag color='blue'>{text}</Tag>
        },

        {
            key: "year_of_execution_key",
            title: `${t("Year of Execution")}`,
            dataIndex: 'yearOfExecution',
            render: (text: any, record: any) => <Tag color='blue'>{text}</Tag>
        },


        {
            key: "locations_key",
            title: `${t("Locations")}`,
            dataIndex: 'locations',
            render: (text: any, record: any) => <Typography.Text>{text}</Typography.Text>
        },
        {
            key: "Internal eighter numbers_key",
            title: 'Internal eighter numbers',
            dataIndex: 'internalEighterNumbers',
            render: (text: any, record: any) => <Tag color="magenta">{text}</Tag>
        },
        {
            key: "rndEmployees_key",
            title: `${t("Number of R&D Employees")}`,
            dataIndex: 'rndEmployees',
            render: (text: any, record: any) => {
                return <Tag color="magenta">{`${formatValue(text)}`}</Tag>
            }
        },
        {
            key: "innovationRateHard_key",
            title: `${t("Innovation Rate (hard)")}`,
            dataIndex: 'innovationRateHard',
            render: (text: any, record: any) => <Tag color="magenta">{`${formatValue(text)}%`}</Tag>
        },

        ]

        return <Table columns={columns} dataSource={dataI} pagination={false}
            bordered

            expandable={{
                rowExpandable: (record) => true,
                expandedRowRender: (record) => {
                    return expandedRowRenderII(record);
                },
                defaultExpandAllRows: true,
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                    ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                    )
            }}

        />;
    }
    // NESTED TABLE 2
    const expandedRowRenderII = (record: any) => {
        const dataII = [{ ...record }];
        const columns = [
            {
                key: "ebitAmountN_key",
                title: `${t("Ebit Amount")} N`,
                dataIndex: 'ebitAmountN',
                render: (text: any, record: any) => <Tag color="gold">{`${formatValue(text)}`}</Tag>
            },
            {
                key: "ebitAmountN1_key",
                title: `${t("Ebit Amount")} N-1`,
                dataIndex: 'ebitAmountN1',
                render: (text: any, record: any) => <Tag color="gold">{`${formatValue(text)}`}</Tag>
            },
            {
                key: "ebitAmountN2_key",
                title: `${t("Ebit Amount")} N-2`,
                dataIndex: 'ebitAmountN2',
                render: (text: any, record: any) => <Tag color="gold">{`${formatValue(text)}`}</Tag>
            },
            {
                key: "ebitPercentageN_key",
                title: 'Ebit N',
                dataIndex: 'ebitPercentageN',
                render: (text: any, record: any) => {
                    return <Tag color="cyan">{`${formatValue(text)}%`}</Tag>

                },
            },
            {
                key: "ebitPercentageN1_key",
                title: 'Ebit N-1',
                dataIndex: 'ebitPercentageN1',
                render: (text: any, record: any) => <Tag color="cyan">{`${formatValue(text)}%`}</Tag>

            },
            {
                key: "ebitPercentageN2_key",
                title: 'Ebit N-2',
                dataIndex: 'ebitPercentageN2',
                render: (text: any, record: any) => <Tag color="cyan">{`${formatValue(text)}%`}</Tag>
            },
            {
                key: "salesN_key",
                title: `${t("Sales")} N`,
                dataIndex: 'salesN',
                render: (text: any, record: any) => <Tag color="green">{`${formatValue(text)}`}</Tag>

            },
            {
                key: "salesN1_key",
                title: `${t("Sales")} N-1`,
                dataIndex: 'salesN1',
                render: (text: any, record: any) => <Tag color="green">{text !== null ? formatValue(text) : ""}</Tag>
            },
            {
                key: "salesN2_key",
                title: `${t("Sales")} N-2`,
                dataIndex: 'salesN2',
                render: (text: any, record: any) => <Tag color="green">{text !== null ? formatValue(text) : ""}</Tag>

            }
        ]

        return <Table columns={columns} dataSource={dataII} pagination={false} bordered />
    };

    const onReopen = async (id: number) => {
        try {
            await props.reOpenBmProject(id, props.customerId ?? null)
        } catch (errInfo) {
            console.log('reOpen Failed:', errInfo);
        }
    }
    const includeBmProject = async (id: number) => {
        try {
            await props.includeBmProject(id, props.customerId ?? null)
        } catch (errInfo) {
            console.log('include Failed:', errInfo);
        }
    }
    const excludeBmProject = async (id: number) => {
        try {
            await props.excludeBmProject(id, props.customerId ?? null)
        } catch (errInfo) {
            console.log('exclude Failed:', errInfo);
        }
    }
    const [totalDataSourceLength, setTotalDataSourceLength] = useState(props.originDataRow?.length)
    return (
        <Table
            getPopupContainer={() => document.getElementById("app-layout")}
            className='table_bmProject'
            key='bm_table'
            bordered
            expandable={{
                expandedRowRender: (record) => {
                    return expandedRowRenderI(record);
                },
                // defaultExpandAllRows: true,
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                    ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                    )
            }}
            dataSource={props.originDataRow}
            columns={columns}
            rowClassName="editable-row"
            scroll={{ y: "calc(100vh - 350px)"}}
            pagination={{
                defaultPageSize: 50,
                pageSizeOptions: [50, 100, 150],
                total: totalDataSourceLength,
                showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                showSizeChanger: true,
                locale: { items_per_page: `/ ${t("Page")}` },
            }}
            locale={{
                filterReset: `${t("Reset")}`, triggerDesc: t('Click to sort descending'),
                triggerAsc: t('Click to sort ascending'),
                cancelSort: t('Click to cancel sorting')
            }}
            onChange={function (pagination, filters, sorter, extra: { currentDataSource: [], action: any }) {
                return setTotalDataSourceLength(extra.currentDataSource.length);
            }}
        />
    );
};
const mapStateToProps = (state: any) => {
    return {
        industriesData: state.industries.industriesResult,
        isLoading: state.bmProjects.isBmProjectLoading,
        isModalLoading: state.bmProjects.isModalLoading,
        modalResponse: state.bmProjects.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        reOpenBmProject: (bmProjectId: number, customerId: number) => dispatch({ type: ActionTypes.REOPEN_BM_PROJECT, bmProjectId, customerId }),
        includeBmProject: (bmProjectId: number, customerId: number) => dispatch({ type: ActionTypes.INCLUDE_BM_PROJECT, bmProjectId, customerId }),
        excludeBmProject: (bmProjectId: number, customerId: number) => dispatch({ type: ActionTypes.EXCLUDE_BM_PROJECT, bmProjectId, customerId })


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BmProjectTable);


