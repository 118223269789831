import React, { useRef, useState } from 'react';
import { Form, Input, Table } from 'antd';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import { ICurvesItem } from '../../../../Models/ICurves';
import AdminOperation from '../../../../Components/AdminOperation/AdminOperation';
import CurvesPhaseModal from './CurvesPhaseModal';
import { RulesDescription, RulesName } from '../../Rules/RulesValidation';
import { t } from 'i18next';
interface IProps {
  curvesData: ICurvesItem[],
  updateCurve: (model: ICurvesItem) => any;
  deleteCurve: (id: number) => any;
}
const CurvesTable = (props: IProps) => {
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  //search code start
  const searchInput = useRef<any>(null);
  const handleSearch = (
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };
  //search code end
  const columns: any[] = [
    {
      key: "nameEn_1",
      title: `${t("English Name")}`,
      dataIndex: 'nameEn',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='nameEn'
              rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
            >
              <Input />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
      ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),
      sorter: (a, b) => a?.nameEn?.localeCompare(b?.nameEn) ?? 0,
      defaultSortOrder: 'ascend',
    },
    {
      key: "nameDe_2",
      title: `${t("German Name")}`,
      dataIndex: 'nameDe',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='nameDe'
              rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
            >
              <Input />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
      ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),
      sorter: (a, b) => a?.nameDe?.localeCompare(b?.nameEn) ?? 0,
      // defaultSortOrder: 'ascend',
    },
    {
      key: "descriptionEn_3",
      title: `${t("English Description")}`,
      dataIndex: 'descriptionEn',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='descriptionEn'
              rules={RulesDescription({ name: `${t("English Description")}`, countChar: 1500 })}
            >
              <Input.TextArea autoSize />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
    },
    {
      key: "descriptionDe_4",
      title: `${t("German Description")}`,
      dataIndex: 'descriptionDe',
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='descriptionDe'
              rules={RulesDescription({ name: `${t("German Description")}`, countChar: 1500 })}
            >
              <Input.TextArea autoSize />
            </Form.Item>
          )
        } else {
          return <p>{text}</p>
        }
      },
    },
    {
      title: `${t("Actions")}`,
      dataIndex: '',
      width: '100px',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return <AdminOperation
          deleteId={record.id}
          record={record}
          editable={editable}
          onCancel={cancel}
          onEdit={edit}
          onDelete={deleteCurve}
        />
      },
    },
    {
      title: "Phases",
      dataIndex: "",
      render: (_any, record: any) => {
        return <CurvesPhaseModal phasesData={record.phases} curveId={record.id} />
      }

    }
  ];
  const isEditing = (record: ICurvesItem) => record.key === editingKey;
  const edit = (record: Partial<ICurvesItem> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const onFinish = async (values: any) => {
    try {
      await form.validateFields()
      const index = props?.curvesData.findIndex(item => item.key === editingKey);
      if (index > -1) {
        const item = props?.curvesData[index];
        const curvesUpdate: ICurvesItem = { id: item.id, ...values };
        await props.updateCurve(curvesUpdate);
        console.log(curvesUpdate);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }

  }
  const deleteCurve = async (id: any) => {
    try {
      await props.deleteCurve(id);
    } catch (error) {
      console.log('delete Failed:', error);
    }

  }


  return (
    <Form form={form} key="ind_form" onFinish={onFinish}>
      <Table
        key='ind_table'
        getPopupContainer={() => document.getElementById("app-layout")}
        className='table_seg'
        bordered
        dataSource={props?.curvesData}
        columns={columns}
        rowClassName="editable-row"
        scroll={{ y: "calc(100vh - 300px)" }}
        pagination={{
          defaultPageSize: 50,
          pageSizeOptions: [50, 100, 150],
          total: props?.curvesData.length,
          showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
          showSizeChanger: true,
          locale: { items_per_page: `/ ${t("Page")}` },
        }}
        locale={{
          triggerDesc: t('Click to sort descending'),
          triggerAsc: t('Click to sort ascending'),
          cancelSort: t('Click to cancel sorting')
        }}
      />
    </Form>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCurve: (model: ICurvesItem) => dispatch({ type: ActionTypes.UPDATE_CURVE, model: model }),
    deleteCurve: (id: number) => dispatch({ type: ActionTypes.DELETE_CURVE, id })

  };
};
export default connect(null, mapDispatchToProps)(CurvesTable);


