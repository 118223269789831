import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import DisciplinesService from "../../Services/DisciplinesService";
import { IDiscipline, IDisciplineItem } from '../../Models/IDiscipline';

function* OnGetDisciplines(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_DISCIPLINE_LOADER, isLoading: true });
        let _result: ICommonResponse = yield DisciplinesService.GetDisciplines();
        if (!_result.isError) {
            let result = _result.result as IDiscipline[];
            yield put({ type: ActionTypes.SAVE_DISCIPLINES, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_DISCIPLINE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in OnGetDisciplines: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnCreateDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_DISCIPLINE_LOADER, isLoading: true, });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield DisciplinesService.CreateDiscipline(action.model);
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield DisciplinesService.GetDisciplines();
            let result = _result.result as IDisciplineItem[];
            yield put({ type: ActionTypes.SAVE_DISCIPLINES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_DISCIPLINE_LOADER, isLoading: false, });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateDiscipline: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_DISCIPLINE_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let  resultUpdate: ICommonResponse = yield DisciplinesService.UpdateDiscipline(action.model);
        if (! resultUpdate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result:  resultUpdate });
            let _result: ICommonResponse = yield DisciplinesService.GetDisciplines();
            let result = _result.result as IDisciplineItem[];
            yield put({ type: ActionTypes.SAVE_DISCIPLINES, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result:  resultUpdate  });

        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_DISCIPLINE_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateDiscipline: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteDiscipline(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_DISCIPLINE_LOADER, isLoading: true });
        let resultDelete: ICommonResponse = yield DisciplinesService.DeleteDiscipline(action.id);

        if (!resultDelete.isError) {            
            let _result: ICommonResponse = yield DisciplinesService.GetDisciplines();
            let result = _result.result as IDisciplineItem[];
            yield put({ type: ActionTypes.SAVE_DISCIPLINES, result: result });
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_DISCIPLINE_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onDeleteDiscipline: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* watchGetDisciplines() {
    yield takeEvery(ActionTypes.GET_DISCIPLINES, OnGetDisciplines);
}
function* watchCreateDiscipline() {
    yield takeEvery(ActionTypes.CREATE_DISCIPLINE, OnCreateDiscipline);
}
function* watchUpdateDiscipline() {
    yield takeEvery(ActionTypes.UPDATE_DISCIPLINE, onUpdateDiscipline);
}
function* watchDeleteDiscipline() {
    yield takeEvery(ActionTypes.DELETE_DISCIPLINE, onDeleteDiscipline);
  }

export default function* disciplinesSagas() {
    yield all([
        watchGetDisciplines(),
        watchCreateDiscipline(),
        watchUpdateDiscipline(),
        watchDeleteDiscipline(),
    ]);
}
