import { Button, Col, Form, Input, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IConfirmTokenUser } from "../../../Models/Requests/AuthModels";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import { useTranslation } from "react-i18next";
import "./index.scss";
import HeaderLangThemeSwitcher from "../../../Components/HeaderLangThemeSwitcher";
import { RulesPassword } from "../../Admin/Rules/RulesValidation";
interface IActivateProps {
    isLoading: boolean;
    commonResult: ICommonResponse;
    confirmResetPassword: (model: IConfirmTokenUser) => any;
}

function ResetPassword(props: IActivateProps) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    const { t, i18n } = useTranslation();
    // useEffect(() => {
    //     localStorage.clear(); 
    //   }, []); 
    useEffect(() => {
        if (props.commonResult) {
            if (props.commonResult?.isError == false) {

                navigate(`/login?email=${email}`);
            }
        }
    }, [props.commonResult]);

    const onFinish = (values) => {
        console.log('onFinish activate:', values);
        if (token) {
            if (email) {
                props.confirmResetPassword({
                    email: email,
                    token: token,
                    newPassword: values.password
                })
            } else {
                alert("Invalid Link");
            }
        } else {
            alert("Invalid Link");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);

    };
    return (
        <>
            <HeaderLangThemeSwitcher />
            <Row align="middle" justify="center" className="login-form">
                <Col xs={24} sm={16} md={8} className="form-card">
                    <div className="header-titel">
                        <p>
                            <span>{t("Reset password")}</span><br />
                        </p>
                    </div>
                    <div className="header-activity">
                        {`${t("Please, set your password.")}`}
                        <br />
                        {`${email}`}
                    </div>


                    <Form
                        form={form}
                        name="basic"
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish.bind(this)}
                        onFinishFailed={onFinishFailed.bind(this)}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={`${t("Password")}`}
                            name="password"
                            labelAlign="left"
                            rules={RulesPassword({ filedName: `${t("Password")}` })}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label={`${t("Confirm Password")}`}
                            name="confirm"
                            labelAlign="left"
                            rules={[
                                {
                                    required: true,
                                    message: `${t("Confirm Password is Required*")}`,
                                },
                                () => ({
                                    validator(_, value) {
                                        if (value && value !== form.getFieldValue('password')) {
                                            return Promise.reject(`${t("New Password and Confirm Password do not match")}`);
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Spin spinning={props.isLoading}>
                            <Form.Item
                                noStyle
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit" className="form-btn">
                                    {`${t("Submit")}`}
                                </Button>
                            </Form.Item>
                        </Spin>
                    </Form>
                </Col>
            </Row></>);
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.generalInfo.isLoading,
        commonResult: state.generalInfo.commonResponse
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        confirmResetPassword: (model: IConfirmTokenUser) => dispatch({ type: ActionTypes.ResetPassword, model: model })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);