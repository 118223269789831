import { ActionTypes } from "../actionsTypes";
import { ChangeProjectFilter } from "../../Scenes/BMProjectsLists/BMProjects_ChangeProject/DataTypes/DataTypes";
import { EditLeversFilter } from "../../Scenes/BMProjectsLists/EditLevers/DataTypes/DataTypes";
import { EditEbitFilter } from "../../Scenes/BMProjectsLists/EditEbit/DataTypes/DataTypes";
import { tablesFilters } from "../../Services/tablesFilters";

import {
  changeQuestionnaireColOrder,
  changeQuestionnaireColVisibility,
  dprojectEbitCostsCols,
  dprojectGeneralDataCols,
  dprojectsColumns,
  getColsObjFromLocalStorage,
  saveColsObjectToLocalStorage,
} from "../../Scenes/Tools/DProjects/DataType/TableCols";
import {
  dprojectEbitCostsSecondLevelType,
  dprojectEbitCostsType,
  dprojectGeneralDataType,
  dProjectQuestionnairesType,
  QuestionnairesecondLevelType,
} from "../../Scenes/Tools/DProjects/DataType/types";

const ini: dProjectQuestionnairesType = null;

const initialState = {
  changeProjectTableColsProps: ChangeProjectFilter,
  editLeversTableColsProps: EditLeversFilter,
  editEbitTableColsProps: EditEbitFilter,

  dprojectsTable_GeneralInfo: getColsObjFromLocalStorage(
    "dprojectGeneralDataCols"
  ),
  dprojectTable_EbitCosts: getColsObjFromLocalStorage("dprojectEbitCostsCols"),
  dprojectTable_Questionnare: ini, // will be initialized after dproject component mounts

  dprojectsTableColsInitialized: false,
  dprojectsTableCols: [], // second level up ->
};

const TablesColsPropsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.UPDATEFILTER:
      switch (action.filter) {
        case tablesFilters.changeProjectTableFilter:
          let changeProjectTableFilter_copy = {};
          Object.keys(ChangeProjectFilter).forEach((key) => {
            if (key === action.colName) {
              changeProjectTableFilter_copy[key] = {
                ...state.changeProjectTableColsProps[key],
                visible: !state.changeProjectTableColsProps[key].visible,
              };
            } else {
              changeProjectTableFilter_copy[key] = {
                ...state.changeProjectTableColsProps[key],
              };
            }
          });

          //console.log('FiltersReducer: ', action.colName,  changeProjectTableFilter_copy);
          return {
            ...state,
            changeProjectTableColsProps: changeProjectTableFilter_copy,
          };

        case tablesFilters.editLeversTableFilter:
          let editLeversTableFilter_copy = {};
          //console.log('action: ', action);

          Object.keys(EditLeversFilter).forEach((key) => {
            if (key === action.colName) {
              editLeversTableFilter_copy[key] = {
                ...state.editLeversTableColsProps[key],
                visible: !state.editLeversTableColsProps[key].visible,
              };
            } else {
              editLeversTableFilter_copy[key] = {
                ...state.editLeversTableColsProps[key],
              };
            }
          });
          console.log(
            "FiltersReducer: lever ",
            action.colName,
            editLeversTableFilter_copy
          );
          return {
            ...state,
            editLeversTableColsProps: editLeversTableFilter_copy,
          };
        case tablesFilters.editEbitTableFilter:
          let editEbitTableFilter_copy = {};
          //console.log('action: ', action);

          Object.keys(EditEbitFilter).forEach((key) => {
            if (key === action.colName) {
              editEbitTableFilter_copy[key] = {
                ...state.editEbitTableColsProps[key],
                visible: !state.editEbitTableColsProps[key].visible,
              };
            } else {
              editEbitTableFilter_copy[key] = {
                ...state.editEbitTableColsProps[key],
              };
            }
          });
          console.log(
            "FiltersReducer: ebit ",
            action.colName,
            editEbitTableFilter_copy
          );
          return {
            ...state,
            editEbitTableColsProps: editEbitTableFilter_copy,
          };
        default:
          break;
      }
      break;

    case ActionTypes.SetQuestionnaireCols:
      //console.log('action.questionnaireCols: ', action.questionnaireCols);

      let GeneralInfoCols: dprojectGeneralDataType =
        state.dprojectsTable_GeneralInfo;
      let EbitCostsCols: dprojectEbitCostsSecondLevelType[] =
        state.dprojectTable_EbitCosts.children;
      let dProjectQuestionnaire: dProjectQuestionnairesType =
        action.questionnaireCols;
      let QuestionnaireCols: QuestionnairesecondLevelType[] = [];
      dProjectQuestionnaire.children.forEach((lever) => {
        lever.children.forEach((subLever) => {
          QuestionnaireCols.push(...subLever.children);
        });
      });

      let new_dprojectsTableCols = [];

      if (state.dprojectsTable_GeneralInfo.order === 1) {
        new_dprojectsTableCols.push(GeneralInfoCols);
      } else if (state.dprojectTable_EbitCosts.order === 1) {
        new_dprojectsTableCols.push(...EbitCostsCols);
      } else {
        new_dprojectsTableCols.push(...QuestionnaireCols);
      }

      if (state.dprojectsTable_GeneralInfo.order === 2) {
        new_dprojectsTableCols.push(GeneralInfoCols);
      } else if (state.dprojectTable_EbitCosts.order === 2) {
        new_dprojectsTableCols.push(...EbitCostsCols);
      } else {
        new_dprojectsTableCols.push(...QuestionnaireCols);
      }

      if (state.dprojectsTable_GeneralInfo.order === 3) {
        new_dprojectsTableCols.push(GeneralInfoCols);
      } else if (state.dprojectTable_EbitCosts.order === 3) {
        new_dprojectsTableCols.push(...EbitCostsCols);
      } else {
        new_dprojectsTableCols.push(...QuestionnaireCols);
      }

      return {
        ...state,
        dprojectsTableColsInitialized: true,
        dprojectTable_Questionnare: action.questionnaireCols,
        dprojectsTableCols: new_dprojectsTableCols,
      };
    case ActionTypes.HideGeneralInfoPanel:
      let thirdLevelItems = state.dprojectsTable_GeneralInfo.children.map(
        (child) => {
          if (child.key === "Name") {
            return {
              ...child,
            };
          }
          return {
            ...child,
            visible: false,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectsTable_GeneralInfo,
          children: thirdLevelItems,
        },
        "dprojectGeneralDataCols"
      );

      return {
        ...state,
        dprojectsTable_GeneralInfo: {
          ...state.dprojectsTable_GeneralInfo,
          children: thirdLevelItems,
        },
      };
    case ActionTypes.ShowGeneralInfoPanel:
      let shownThirdLevelItems = state.dprojectsTable_GeneralInfo.children.map(
        (child) => {
          return {
            ...child,
            visible: true,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectsTable_GeneralInfo,
          visible: true,
          children: shownThirdLevelItems,
        },
        "dprojectGeneralDataCols"
      );

      return {
        ...state,
        dprojectsTable_GeneralInfo: {
          ...state.dprojectsTable_GeneralInfo,
          visible: true,
          children: shownThirdLevelItems,
        },
      };
    case ActionTypes.HideGeneralInfoThirdLevelItem:
      let ThirdLevelItems_ = state.dprojectsTable_GeneralInfo.children.map(
        (child) => {
          if (child.key == action.thirdItemKey) {
            return {
              ...child,
              visible: false,
            };
          }
          return {
            ...child,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectsTable_GeneralInfo,
          visible:
            ThirdLevelItems_.filter((item) => item.visible === true).length > 0,
          children: ThirdLevelItems_,
        },
        "dprojectGeneralDataCols"
      );

      return {
        ...state,
        dprojectsTable_GeneralInfo: {
          ...state.dprojectsTable_GeneralInfo,
          visible:
            ThirdLevelItems_.filter((item) => item.visible === true).length > 0,
          children: ThirdLevelItems_,
        },
      };
    case ActionTypes.ShowGeneralInfoThirdLevelItem:
      let ThirdLevelItems0 = state.dprojectsTable_GeneralInfo.children.map(
        (child) => {
          if (child.key == action.thirdItemKey) {
            return {
              ...child,
              visible: true,
            };
          }
          return {
            ...child,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectsTable_GeneralInfo,
          visible: true,
          children: ThirdLevelItems0,
        },
        "dprojectGeneralDataCols"
      );

      return {
        ...state,
        dprojectsTable_GeneralInfo: {
          ...state.dprojectsTable_GeneralInfo,
          visible: true,
          children: ThirdLevelItems0,
        },
      };
    case ActionTypes.HideEbitCostsPanel:
      let secondLevelItems = state.dprojectTable_EbitCosts.children.map(
        (secondLevelItem) => {
          let thirdLevelItems = secondLevelItem.children.map(
            (thirdLevelItem) => {
              return {
                ...thirdLevelItem,
                visible: false,
              };
            }
          );
          return {
            ...secondLevelItem,
            visible: false,
            children: thirdLevelItems,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectTable_EbitCosts,
          children: secondLevelItems,
          visible: false,
        },
        "dprojectEbitCostsCols"
      );

      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          children: secondLevelItems,
          visible: false,
        },
      };
    case ActionTypes.ShowEbitCostsPanel:
      let secondLevelItems_ = state.dprojectTable_EbitCosts.children.map(
        (secondLevelItem) => {
          let thirdLevelItems = secondLevelItem.children.map(
            (thirdLevelItem) => {
              return {
                ...thirdLevelItem,
                visible: true,
              };
            }
          );
          return {
            ...secondLevelItem,
            visible: true,
            children: thirdLevelItems,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectTable_EbitCosts,
          children: secondLevelItems_,
          visible: true,
        },
        "dprojectEbitCostsCols"
      );

      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          children: secondLevelItems_,
          visible: true,
        },
      };

    case ActionTypes.HideEbitCostsSecondLevelItem:
      let secondLevelItems0 = state.dprojectTable_EbitCosts.children.map(
        (secondLevelItem) => {
          let thirdLevelItems = secondLevelItem.children.map(
            (thirdLevelItem) => {
              if (secondLevelItem.key == action.itemKey) {
                return {
                  ...thirdLevelItem,
                  visible: false,
                };
              }
              return {
                ...thirdLevelItem,
              };
            }
          );
          if (secondLevelItem.key == action.itemKey) {
            return {
              ...secondLevelItem,
              visible: false,
              children: thirdLevelItems,
            };
          } else {
            return {
              ...secondLevelItem,
              children: thirdLevelItems,
            };
          }
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectTable_EbitCosts,
          visible:
            secondLevelItems0.filter((item) => item.visible === true).length >
            0,
          children: secondLevelItems0,
        },
        "dprojectEbitCostsCols"
      );

      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          visible:
            secondLevelItems0.filter((item) => item.visible === true).length >
            0,
          children: secondLevelItems0,
        },
      };

    case ActionTypes.ShowEbitCostsSecondLevelItem:
      let secondLevelItems1 = state.dprojectTable_EbitCosts.children.map(
        (secondLevelItem) => {
          let thirdLevelItems = secondLevelItem.children.map(
            (thirdLevelItem) => {
              if (secondLevelItem.key == action.itemKey) {
                return {
                  ...thirdLevelItem,
                  visible: true,
                };
              }
              return {
                ...thirdLevelItem,
              };
            }
          );
          if (secondLevelItem.key == action.itemKey) {
            return {
              ...secondLevelItem,
              visible: true,
              children: thirdLevelItems,
            };
          } else {
            return {
              ...secondLevelItem,
              children: thirdLevelItems,
            };
          }
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectTable_EbitCosts,
          visible: true,
          children: secondLevelItems1,
        },
        "dprojectEbitCostsCols"
      );

      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          visible: true,
          children: secondLevelItems1,
        },
      };

    case ActionTypes.HideEbitCostsThirdLevelItem:
      let secondLevelItems2 = state.dprojectTable_EbitCosts.children.map(
        (secondLevelItem) => {
          let thirdLevelItems = secondLevelItem.children.map(
            (thirdLevelItem) => {
              if (thirdLevelItem.key == action.itemKey) {
                return {
                  ...thirdLevelItem,
                  visible: false,
                };
              }
              return {
                ...thirdLevelItem,
              };
            }
          );
          return {
            ...secondLevelItem,
            visible:
              thirdLevelItems.filter((item) => item.visible === true).length >
              0,
            children: thirdLevelItems,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectTable_EbitCosts,
          visible:
            secondLevelItems2.filter((item) => item.visible === true).length >
            0,
          children: secondLevelItems2,
        },
        "dprojectEbitCostsCols"
      );

      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          visible:
            secondLevelItems2.filter((item) => item.visible === true).length >
            0,
          children: secondLevelItems2,
        },
      };

    case ActionTypes.ShowEbitCostsThirdLevelItem:
      let secondLevelItems3 = state.dprojectTable_EbitCosts.children.map(
        (secondLevelItem) => {
          let thirdLevelItems = secondLevelItem.children.map(
            (thirdLevelItem) => {
              if (thirdLevelItem.key == action.itemKey) {
                return {
                  ...thirdLevelItem,
                  visible: true,
                };
              }
              return {
                ...thirdLevelItem,
              };
            }
          );
          return {
            ...secondLevelItem,
            visible: true,
            children: thirdLevelItems,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectTable_EbitCosts,
          visible: true,
          children: secondLevelItems3,
        },
        "dprojectEbitCostsCols"
      );

      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          visible: true,
          children: secondLevelItems3,
        },
      };

    case ActionTypes.HideQuestionnairePanel:
      let levers0 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                return {
                  ...thirdLevelItem,
                  visible: false,
                };
              }
            );
            return {
              ...secondLevelItem,
              visible: false,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            visible: false,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          visible: false,
          children: subLevers,
        };
      });

      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: false,
          children: levers0,
        },
      };

    case ActionTypes.ShowQuestionnairePanel:
      let levers1 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                return {
                  ...thirdLevelItem,
                  visible: true,
                };
              }
            );
            return {
              ...secondLevelItem,
              visible: true,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            visible: true,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          visible: true,
          children: subLevers,
        };
      });
      changeQuestionnaireColVisibility("1.", true);
      changeQuestionnaireColVisibility("2.", true);
      changeQuestionnaireColVisibility("3.", true);
      changeQuestionnaireColVisibility("4.", true);
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: true,
          children: levers1,
        },
      };

    case ActionTypes.HideLeverItem:
      let levers2 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = [];
        if (lever.id === action.leverId) {
          subLevers = lever.children.map((subLever) => {
            let secondLevelItems = subLever.children.map((secondLevelItem) => {
              let thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                    visible: false,
                  };
                }
              );
              return {
                ...secondLevelItem,
                visible: false,
                children: thirdLevelItems,
              };
            });
            return {
              ...subLever,
              visible: false,
              children: secondLevelItems,
            };
          });
        } else {
          subLevers = lever.children.map((subLever) => {
            let secondLevelItems = subLever.children.map((secondLevelItem) => {
              let thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                  };
                }
              );
              return {
                ...secondLevelItem,
                children: thirdLevelItems,
              };
            });
            return {
              ...subLever,
              children: secondLevelItems,
            };
          });
        }
        return {
          ...lever,
          visible: false,
          children: subLevers,
        };
      });
      changeQuestionnaireColVisibility(action.leverId, false);
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: levers2.filter((lever) => lever.visible === true).length > 0,
          children: levers2,
        },
      };

    case ActionTypes.ShowLeverItem:
      let levers3 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = [];
        if (lever.id === action.leverId) {
          subLevers = lever.children.map((subLever) => {
            let secondLevelItems = subLever.children.map((secondLevelItem) => {
              let thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                    visible: true,
                  };
                }
              );
              return {
                ...secondLevelItem,
                visible: true,
                children: thirdLevelItems,
              };
            });
            return {
              ...subLever,
              visible: true,
              children: secondLevelItems,
            };
          });
        } else {
          subLevers = lever.children.map((subLever) => {
            let secondLevelItems = subLever.children.map((secondLevelItem) => {
              let thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                  };
                }
              );
              return {
                ...secondLevelItem,
                children: thirdLevelItems,
              };
            });
            return {
              ...subLever,
              children: secondLevelItems,
            };
          });
        }
        return {
          ...lever,
          visible: true,
          children: subLevers,
        };
      });
      changeQuestionnaireColVisibility(action.leverId, true);
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: true,
          children: levers3,
        },
      };

    case ActionTypes.HideSubLeverItem:
      let levers4 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = [];
          if (subLever.id === action.subLeverId) {
            secondLevelItems = subLever.children.map((secondLevelItem) => {
              let thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                    visible: false,
                  };
                }
              );
              return {
                ...secondLevelItem,
                visible: false,
                children: thirdLevelItems,
              };
            });
          } else {
            secondLevelItems = subLever.children.map((secondLevelItem) => {
              let thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                  };
                }
              );
              return {
                ...secondLevelItem,
                children: thirdLevelItems,
              };
            });
          }

          return {
            ...subLever,
            visible: false,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          visible:
            subLevers.filter((subLever) => subLever.visible === true).length >
            0,
          children: subLevers,
        };
      });
      changeQuestionnaireColVisibility(action.subLeverId, false);
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: levers4.filter((lever) => lever.visible === true).length > 0,
          children: levers4,
        },
      };

    case ActionTypes.ShowSubLeverItem:
      let levers5 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = [];
          if (subLever.id === action.subLeverId) {
            secondLevelItems = subLever.children.map((secondLevelItem) => {
              let thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                    visible: true,
                  };
                }
              );
              return {
                ...secondLevelItem,
                visible: true,
                children: thirdLevelItems,
              };
            });
          } else {
            secondLevelItems = subLever.children.map((secondLevelItem) => {
              let thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                  };
                }
              );
              return {
                ...secondLevelItem,
                children: thirdLevelItems,
              };
            });
          }

          return {
            ...subLever,
            visible: true,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          visible: true,
          children: subLevers,
        };
      });
      changeQuestionnaireColVisibility(action.subLeverId, true);
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: true,
          children: levers5,
        },
      };

    case ActionTypes.HideSecondLevelItem:
      let levers6 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = [];
            if (secondLevelItem.key == action.itemKey) {
              thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                    visible: false,
                  };
                }
              );
              changeQuestionnaireColVisibility(secondLevelItem.id, false);
            } else {
              thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                  };
                }
              );
            }
            return {
              ...secondLevelItem,
              visible: false,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            visible:
              secondLevelItems.filter(
                (secondLevelItem) => secondLevelItem.visible === true
              ).length > 0,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          visible:
            subLevers.filter((subLever) => subLever.visible === true).length >
            0,
          children: subLevers,
        };
      });
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: levers6.filter((lever) => lever.visible === true).length > 0,
          children: levers6,
        },
      };

    case ActionTypes.ShowSecondLevelItem:
      let levers7 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = [];
            if (secondLevelItem.key == action.itemKey) {
              thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                    visible: true,
                  };
                }
              );
              changeQuestionnaireColVisibility(secondLevelItem.id, true);
            } else {
              thirdLevelItems = secondLevelItem.children.map(
                (thirdLevelItem) => {
                  return {
                    ...thirdLevelItem,
                  };
                }
              );
            }
            return {
              ...secondLevelItem,
              visible: true,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            visible: true,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          visible: true,
          children: subLevers,
        };
      });
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: true,
          children: levers7,
        },
      };

    case ActionTypes.HideThirdLevelItem:
      let levers8 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                if (thirdLevelItem.key == action.itemKey) {
                  return {
                    ...thirdLevelItem,
                    visible: false,
                  };
                } else {
                  return {
                    ...thirdLevelItem,
                  };
                }
              }
            );
            return {
              ...secondLevelItem,
              visible:
                thirdLevelItems.filter((t) => t.visible === true).length > 0,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            visible:
              secondLevelItems.filter((t) => t.visible === true).length > 0,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          visible: subLevers.filter((t) => t.visible === true).length > 0,
          children: subLevers,
        };
      });
      changeQuestionnaireColVisibility(action.itemKey, false);
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: levers8.filter((t) => t.visible === true).length > 0,
          children: levers8,
        },
      };

    case ActionTypes.ShowThirdLevelItem:
      let levers9 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                if (thirdLevelItem.key == action.itemKey) {
                  return {
                    ...thirdLevelItem,
                    visible: true,
                  };
                } else {
                  return {
                    ...thirdLevelItem,
                  };
                }
              }
            );
            return {
              ...secondLevelItem,
              visible:
                thirdLevelItems.filter((t) => t.visible === true).length > 0,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            visible:
              secondLevelItems.filter((t) => t.visible === true).length > 0,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          visible: subLevers.filter((t) => t.visible === true).length > 0,
          children: subLevers,
        };
      });
      changeQuestionnaireColVisibility(action.itemKey, true);
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          visible: levers9.filter((t) => t.visible === true).length > 0,
          children: levers9,
        },
      };

    // case ActionTypes.CHNGDPROJECTCOLVISIBIITY:
    //   //console.log('action: ', action);
    //   if (action.childKey) {
    //     let dprojectsColumns_copy: typeof dprojectsColumns = [];
    //     state.dprojectsTableCols.forEach((col) => {
    //       let children_copy: {
    //         title: string;
    //         dataIndex: string;
    //         key: string;
    //         width: number;
    //         className: string;
    //         visible: boolean;
    //         order: number;
    //       }[] = [];
    //       let allChildrenVisibility: boolean = false;
    //       col.children.forEach((child) => {
    //         if (child.key === action.childKey) {
    //           children_copy.push({ ...child, visible: action.visible });
    //           allChildrenVisibility = allChildrenVisibility || !child.visible;
    //         } else {
    //           children_copy.push({ ...child });
    //           allChildrenVisibility = allChildrenVisibility || child.visible;
    //         }
    //       });
    //       if (!allChildrenVisibility) {
    //         dprojectsColumns_copy.push({
    //           ...col,
    //           visible: false,
    //           children: children_copy,
    //         });
    //       } else {
    //         dprojectsColumns_copy.push({
    //           ...col,
    //           visible: true,
    //           children: children_copy,
    //         });
    //       }
    //     });
    //     return {
    //       ...state,
    //       dprojectsTableCols: dprojectsColumns_copy,
    //     };
    //   } else {
    //     let dprojectsColumns_copy: typeof dprojectsColumns = [];
    //     state.dprojectsTableCols.forEach((col) => {
    //       let children_copy: {
    //         title: string;
    //         dataIndex: string;
    //         key: string;
    //         width: number;
    //         className: string;
    //         visible: boolean;
    //         order: number;
    //       }[] = [];
    //       col.children.forEach((child) => {
    //         if (col.key === action.parentKey) {
    //           if (child.key === "Name") {
    //             children_copy.push({ ...child, visible: true }); //Name child in general dproject data always shown
    //           } else {
    //             children_copy.push({ ...child, visible: action.visible });
    //           }
    //         } else {
    //           children_copy.push({ ...child });
    //         }
    //       });
    //       if (col.key === action.parentKey) {
    //         dprojectsColumns_copy.push({
    //           ...col,
    //           visible: action.visible,
    //           children: children_copy,
    //         });
    //       } else {
    //         dprojectsColumns_copy.push({ ...col, children: children_copy });
    //       }
    //     });
    //     return {
    //       ...state,
    //       dprojectsTableCols: dprojectsColumns_copy,
    //     };
    //   }

    case ActionTypes.DragGeneralInfoThirdItemEnd:
      let children = state.dprojectsTable_GeneralInfo.children.filter(
        (child) => child.visible
      );
      let affectedSlice = [];
      if (action.fromIndex < action.toIndex) {
        affectedSlice = children.slice(action.fromIndex, action.toIndex + 1);
        let gTemp: number;
        for (let i = 0; i < affectedSlice.length; i++) {
          if (i === 0) {
            gTemp = affectedSlice[i].order;
            affectedSlice[i].order =
              affectedSlice[affectedSlice.length - 1].order;
          } else {
            let lTemp: number = affectedSlice[i].order;
            affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      } else {
        affectedSlice = children.slice(action.toIndex, action.fromIndex + 1);
        let gTemp: number;
        for (let i = affectedSlice.length - 1; i >= 0; i--) {
          if (i === affectedSlice.length - 1) {
            gTemp = affectedSlice[i].order;
            affectedSlice[i].order = affectedSlice[0].order;
          } else {
            let lTemp: number = affectedSlice[i].order;
            affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      }

      affectedSlice.forEach((affectedChild) => {
        state.dprojectsTable_GeneralInfo.children.forEach((child) => {
          if (child.key === affectedChild.key) {
            child.order = affectedChild.order;
          }
        });
      });

      let newChildren = state.dprojectsTable_GeneralInfo.children.map(
        (child) => {
          return {
            ...child,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectsTable_GeneralInfo,
          children: newChildren,
        },
        "dprojectGeneralDataCols"
      );

      return {
        ...state,
        dprojectsTable_GeneralInfo: {
          ...state.dprojectsTable_GeneralInfo,
          children: newChildren,
        },
      };

    case ActionTypes.DragEbitCostsSecondLevelEnd:
      let EC_secondLevelItems = state.dprojectTable_EbitCosts.children.filter(
        (secondLevelItem) => secondLevelItem.visible
      );
      let EC_affectedSlice = [];
      if (action.fromIndex < action.toIndex) {
        EC_affectedSlice = EC_secondLevelItems.slice(
          action.fromIndex,
          action.toIndex + 1
        );
        let gTemp: number;
        for (let i = 0; i < EC_affectedSlice.length; i++) {
          if (i === 0) {
            gTemp = EC_affectedSlice[i].order;
            EC_affectedSlice[i].order =
              EC_affectedSlice[EC_affectedSlice.length - 1].order;
          } else {
            let lTemp: number = EC_affectedSlice[i].order;
            EC_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      } else {
        EC_affectedSlice = EC_secondLevelItems.slice(
          action.toIndex,
          action.fromIndex + 1
        );
        let gTemp: number;
        for (let i = EC_affectedSlice.length - 1; i >= 0; i--) {
          if (i === EC_affectedSlice.length - 1) {
            gTemp = EC_affectedSlice[i].order;
            EC_affectedSlice[i].order = EC_affectedSlice[0].order;
          } else {
            let lTemp: number = EC_affectedSlice[i].order;
            EC_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      }

      EC_affectedSlice.forEach((affectedChild) => {
        state.dprojectTable_EbitCosts.children.forEach((child) => {
          if (child.key === affectedChild.key) {
            child.order = affectedChild.order;
          }
        });
      });

      let newECsecondLevelItems = state.dprojectTable_EbitCosts.children.map(
        (ECsecondLevelItem) => {
          let newECThirdLevelItems = ECsecondLevelItem.children.map(
            (ECThirdLevelItems) => {
              return {
                ...ECThirdLevelItems,
              };
            }
          );
          return {
            ...ECsecondLevelItem,
            children: newECThirdLevelItems,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectTable_EbitCosts,
          children: newECsecondLevelItems,
        },
        "dprojectEbitCostsCols"
      );

      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          children: newECsecondLevelItems,
        },
      };

    case ActionTypes.DragEbitCostsThirdLevelEnd:
      let EC_thirdLevelItems = state.dprojectTable_EbitCosts.children.filter(
        (secondLevelItem) =>
          secondLevelItem.visible && secondLevelItem.key == action.costKey
      )[0].children;
      let EC_thirdLevel_affectedSlice = [];
      if (action.fromIndex < action.toIndex) {
        EC_thirdLevel_affectedSlice = EC_thirdLevelItems.slice(
          action.fromIndex,
          action.toIndex + 1
        );
        let gTemp: number;
        for (let i = 0; i < EC_thirdLevel_affectedSlice.length; i++) {
          if (i === 0) {
            gTemp = EC_thirdLevel_affectedSlice[i].order;
            EC_thirdLevel_affectedSlice[i].order =
              EC_thirdLevel_affectedSlice[
                EC_thirdLevel_affectedSlice.length - 1
              ].order;
          } else {
            let lTemp: number = EC_thirdLevel_affectedSlice[i].order;
            EC_thirdLevel_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      } else {
        EC_thirdLevel_affectedSlice = EC_thirdLevelItems.slice(
          action.toIndex,
          action.fromIndex + 1
        );
        let gTemp: number;
        for (let i = EC_thirdLevel_affectedSlice.length - 1; i >= 0; i--) {
          if (i === EC_thirdLevel_affectedSlice.length - 1) {
            gTemp = EC_thirdLevel_affectedSlice[i].order;
            EC_thirdLevel_affectedSlice[i].order =
              EC_thirdLevel_affectedSlice[0].order;
          } else {
            let lTemp: number = EC_thirdLevel_affectedSlice[i].order;
            EC_thirdLevel_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      }

      // EC_thirdLevel_affectedSlice.forEach(affectedChild => {
      //     state.dprojectTable_EbitCosts.children.forEach(child => {
      //         if (child.key === affectedChild.key) {
      //             child.order = affectedChild.order
      //         }
      //     });
      // });

      let newECsecondLevelItems1 = state.dprojectTable_EbitCosts.children.map(
        (ECsecondLevelItem) => {
          let newECThirdLevelItems = ECsecondLevelItem.children.map(
            (ECThirdLevelItems) => {
              if (ECsecondLevelItem.key == action.costKey) {
                EC_thirdLevel_affectedSlice.forEach((affectedChild) => {
                  if (ECThirdLevelItems.key === affectedChild.key) {
                    return {
                      ...ECThirdLevelItems,
                      order: affectedChild.order,
                    };
                  }
                });
                return {
                  ...ECThirdLevelItems,
                };
              } else {
                return {
                  ...ECThirdLevelItems,
                };
              }
            }
          );
          return {
            ...ECsecondLevelItem,
            children: newECThirdLevelItems,
          };
        }
      );

      saveColsObjectToLocalStorage(
        {
          ...state.dprojectTable_EbitCosts,
          children: newECsecondLevelItems1,
        },
        "dprojectEbitCostsCols"
      );

      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          children: newECsecondLevelItems1,
        },
      };

    case ActionTypes.DragQuestionnairesLeversEnd:
      let Q_leversItems = state.dprojectTable_Questionnare.children.filter(
        (lever) => lever.visible
      );
      let levers_affectedSlice = [];
      if (action.fromIndex < action.toIndex) {
        levers_affectedSlice = Q_leversItems.slice(
          action.fromIndex,
          action.toIndex + 1
        );
        let gTemp: number;
        for (let i = 0; i < levers_affectedSlice.length; i++) {
          if (i === 0) {
            gTemp = levers_affectedSlice[i].order;
            levers_affectedSlice[i].order =
              levers_affectedSlice[levers_affectedSlice.length - 1].order;
          } else {
            let lTemp: number = levers_affectedSlice[i].order;
            levers_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      } else {
        levers_affectedSlice = Q_leversItems.slice(
          action.toIndex,
          action.fromIndex + 1
        );
        let gTemp: number;
        for (let i = levers_affectedSlice.length - 1; i >= 0; i--) {
          if (i === levers_affectedSlice.length - 1) {
            gTemp = levers_affectedSlice[i].order;
            levers_affectedSlice[i].order = levers_affectedSlice[0].order;
          } else {
            let lTemp: number = levers_affectedSlice[i].order;
            levers_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      }

      // levers_affectedSlice.forEach(affectedChild => {
      //     state.dprojectTable_Questionnare.children.forEach(child => {
      //         if (child.id === affectedChild.id) {
      //             child.order = affectedChild.order
      //         }
      //     });
      // });

      let levers10 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                if (thirdLevelItem.key == action.itemKey) {
                  return {
                    ...thirdLevelItem,
                  };
                } else {
                  return {
                    ...thirdLevelItem,
                  };
                }
              }
            );
            return {
              ...secondLevelItem,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            children: secondLevelItems,
          };
        });
        levers_affectedSlice.forEach((affectedChild) => {
          if (lever.id === affectedChild.id) {
            lever.order = affectedChild.order;
            changeQuestionnaireColOrder(lever.id, affectedChild.order);
          }
        });
        return {
          ...lever,
          children: subLevers,
        };
      });
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          children: levers10,
        },
      };

    case ActionTypes.DragQuestionnairesSubLeversEnd:
      let Q_subLeversItems = state.dprojectTable_Questionnare.children
        .filter((lever) => lever.visible && lever.id === action.leverId)[0]
        .children.filter((subLever) => subLever.visible === true);
      let subLevers_affectedSlice = [];
      if (action.fromIndex < action.toIndex) {
        subLevers_affectedSlice = Q_subLeversItems.slice(
          action.fromIndex,
          action.toIndex + 1
        );
        let gTemp: number;
        for (let i = 0; i < subLevers_affectedSlice.length; i++) {
          if (i === 0) {
            gTemp = subLevers_affectedSlice[i].order;
            subLevers_affectedSlice[i].order =
              subLevers_affectedSlice[subLevers_affectedSlice.length - 1].order;
          } else {
            let lTemp: number = subLevers_affectedSlice[i].order;
            subLevers_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      } else {
        subLevers_affectedSlice = Q_subLeversItems.slice(
          action.toIndex,
          action.fromIndex + 1
        );
        let gTemp: number;
        for (let i = subLevers_affectedSlice.length - 1; i >= 0; i--) {
          if (i === subLevers_affectedSlice.length - 1) {
            gTemp = subLevers_affectedSlice[i].order;
            subLevers_affectedSlice[i].order = subLevers_affectedSlice[0].order;
          } else {
            let lTemp: number = subLevers_affectedSlice[i].order;
            subLevers_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      }

      // levers_affectedSlice.forEach(affectedChild => {
      //     state.dprojectTable_Questionnare.children.forEach(child => {
      //         if (child.id === affectedChild.id) {
      //             child.order = affectedChild.order
      //         }
      //     });
      // });

      let levers11 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                if (thirdLevelItem.key == action.itemKey) {
                  return {
                    ...thirdLevelItem,
                  };
                } else {
                  return {
                    ...thirdLevelItem,
                  };
                }
              }
            );
            return {
              ...secondLevelItem,
              children: thirdLevelItems,
            };
          });
          if (lever.id === action.leverId)
            subLevers_affectedSlice.forEach((affectedChild) => {
              if (subLever.id === affectedChild.id) {
                subLever.order = affectedChild.order;
                changeQuestionnaireColOrder(subLever.id, affectedChild.order);
              }
            });
          return {
            ...subLever,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          children: subLevers,
        };
      });
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          children: levers11,
        },
      };

    case ActionTypes.DragQuestionnairesSecondLevelEnd:
      let Q_scndLevelsItems = state.dprojectTable_Questionnare.children
        .filter(
          (lever) => lever.visible === true && lever.id === action.leverId
        )[0]
        .children.filter(
          (subLever) =>
            subLever.visible === true && subLever.id === action.supLeverId
        )[0]
        .children.filter((scndLever) => scndLever.visible === true);
      let scndLevel_affectedSlice = [];
      if (action.fromIndex < action.toIndex) {
        scndLevel_affectedSlice = Q_scndLevelsItems.slice(
          action.fromIndex,
          action.toIndex + 1
        );
        let gTemp: number;
        for (let i = 0; i < scndLevel_affectedSlice.length; i++) {
          if (i === 0) {
            gTemp = scndLevel_affectedSlice[i].order;
            scndLevel_affectedSlice[i].order =
              scndLevel_affectedSlice[scndLevel_affectedSlice.length - 1].order;
          } else {
            let lTemp: number = scndLevel_affectedSlice[i].order;
            scndLevel_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      } else {
        scndLevel_affectedSlice = Q_scndLevelsItems.slice(
          action.toIndex,
          action.fromIndex + 1
        );
        let gTemp: number;
        for (let i = scndLevel_affectedSlice.length - 1; i >= 0; i--) {
          if (i === scndLevel_affectedSlice.length - 1) {
            gTemp = scndLevel_affectedSlice[i].order;
            scndLevel_affectedSlice[i].order = scndLevel_affectedSlice[0].order;
          } else {
            let lTemp: number = scndLevel_affectedSlice[i].order;
            scndLevel_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      }

      // levers_affectedSlice.forEach(affectedChild => {
      //     state.dprojectTable_Questionnare.children.forEach(child => {
      //         if (child.id === affectedChild.id) {
      //             child.order = affectedChild.order
      //         }
      //     });
      // });

      let levers12 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                if (thirdLevelItem.key == action.itemKey) {
                  return {
                    ...thirdLevelItem,
                  };
                } else {
                  return {
                    ...thirdLevelItem,
                  };
                }
              }
            );
            if (subLever.id === action.supLeverId)
              scndLevel_affectedSlice.forEach((affectedChild) => {
                if (secondLevelItem.key === affectedChild.key) {
                  secondLevelItem.order = affectedChild.order;
                  changeQuestionnaireColOrder(
                    secondLevelItem.id,
                    affectedChild.order
                  );
                }
              });
            return {
              ...secondLevelItem,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          children: subLevers,
        };
      });
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          children: levers12,
        },
      };

    case ActionTypes.DragQuestionnairesThirdLevelEnd:
      let Q_thirdLevelsItems = state.dprojectTable_Questionnare.children
        .filter(
          (lever) => lever.visible === true && lever.id === action.leverId
        )[0]
        .children.filter(
          (subLever) =>
            subLever.visible === true && subLever.id === action.supLeverId
        )[0]
        .children.filter(
          (scndLever) =>
            scndLever.visible === true &&
            scndLever.key === action.secondLevelItemKey
        )[0]
        .children.filter((thirdLevelItem) => thirdLevelItem.visible === true);
      let thirdLevel_affectedSlice = [];
      if (action.fromIndex < action.toIndex) {
        thirdLevel_affectedSlice = Q_thirdLevelsItems.slice(
          action.fromIndex,
          action.toIndex + 1
        );
        let gTemp: number;
        for (let i = 0; i < thirdLevel_affectedSlice.length; i++) {
          if (i === 0) {
            gTemp = thirdLevel_affectedSlice[i].order;
            thirdLevel_affectedSlice[i].order =
              thirdLevel_affectedSlice[
                thirdLevel_affectedSlice.length - 1
              ].order;
          } else {
            let lTemp: number = thirdLevel_affectedSlice[i].order;
            thirdLevel_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      } else {
        thirdLevel_affectedSlice = Q_thirdLevelsItems.slice(
          action.toIndex,
          action.fromIndex + 1
        );
        let gTemp: number;
        for (let i = thirdLevel_affectedSlice.length - 1; i >= 0; i--) {
          if (i === thirdLevel_affectedSlice.length - 1) {
            gTemp = thirdLevel_affectedSlice[i].order;
            thirdLevel_affectedSlice[i].order =
              thirdLevel_affectedSlice[0].order;
          } else {
            let lTemp: number = thirdLevel_affectedSlice[i].order;
            thirdLevel_affectedSlice[i].order = gTemp;
            gTemp = lTemp;
          }
        }
      }

      // levers_affectedSlice.forEach(affectedChild => {
      //     state.dprojectTable_Questionnare.children.forEach(child => {
      //         if (child.id === affectedChild.id) {
      //             child.order = affectedChild.order
      //         }
      //     });
      // });

      let levers13 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                if (secondLevelItem.key === action.secondLevelItemKey)
                  thirdLevel_affectedSlice.forEach((affectedChild) => {
                    if (thirdLevelItem.key === affectedChild.key) {
                      thirdLevelItem.order = affectedChild.order;
                      changeQuestionnaireColOrder(
                        thirdLevelItem.key,
                        affectedChild.order
                      );
                    }
                  });
                return {
                  ...thirdLevelItem,
                };
              }
            );
            return {
              ...secondLevelItem,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          children: subLevers,
        };
      });
      return {
        ...state,
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          children: levers13,
        },
      };

    case ActionTypes.DragFirstLevelEnd:
      let levers14 = state.dprojectTable_Questionnare?.children.map((lever) => {
        let subLevers = lever.children.map((subLever) => {
          let secondLevelItems = subLever.children.map((secondLevelItem) => {
            let thirdLevelItems = secondLevelItem.children.map(
              (thirdLevelItem) => {
                return {
                  ...thirdLevelItem,
                };
              }
            );
            return {
              ...secondLevelItem,
              children: thirdLevelItems,
            };
          });
          return {
            ...subLever,
            children: secondLevelItems,
          };
        });
        return {
          ...lever,
          children: subLevers,
        };
      });

      let newECsecondLevelItems2 = state.dprojectTable_EbitCosts.children.map(
        (ECsecondLevelItem) => {
          let newECThirdLevelItems = ECsecondLevelItem.children.map(
            (ECThirdLevelItems) => {
              return {
                ...ECThirdLevelItems,
              };
            }
          );
          return {
            ...ECsecondLevelItem,
            children: newECThirdLevelItems,
          };
        }
      );
      let ebitCostsOrder = state.dprojectTable_EbitCosts.order;
      let questionnaireOreder = state.dprojectTable_Questionnare.order;
      return {
        ...state,
        dprojectTable_EbitCosts: {
          ...state.dprojectTable_EbitCosts,
          children: newECsecondLevelItems2,
          order: questionnaireOreder,
        },
        dprojectTable_Questionnare: {
          ...state.dprojectTable_Questionnare,
          children: levers14,
          order: ebitCostsOrder,
        },
      };

    // case ActionTypes.CHANGECOLSORDER:
    //   let dprojectsColumns_copy: typeof dprojectsColumns = [];
    //   state.dprojectsTableCols.forEach((col) => {
    //     let children_copy: {
    //       title: string;
    //       dataIndex: string;
    //       key: string;
    //       width: number;
    //       className: string;
    //       visible: boolean;
    //       order: number;
    //     }[] = [];
    //     col.children.forEach((child) => {
    //       children_copy.push({ ...child });
    //     });
    //     dprojectsColumns_copy.push({ ...col, children: children_copy });
    //   });

    //   if (action.parentKey) {
    //     let parent = dprojectsColumns_copy.filter(
    //       (col) => col.key === action.parentKey
    //     )[0];
    //     let children = parent.children.filter((child) => child.visible);
    //     let affectedSlice = [];
    //     if (action.fromIndex < action.toIndex) {
    //       affectedSlice = children.slice(action.fromIndex, action.toIndex + 1);
    //       let gTemp: number;
    //       for (let i = 0; i < affectedSlice.length; i++) {
    //         if (i === 0) {
    //           gTemp = affectedSlice[i].order;
    //           affectedSlice[i].order =
    //             affectedSlice[affectedSlice.length - 1].order;
    //         } else {
    //           let lTemp: number = affectedSlice[i].order;
    //           affectedSlice[i].order = gTemp;
    //           gTemp = lTemp;
    //         }
    //       }
    //     } else {
    //       affectedSlice = children.slice(action.toIndex, action.fromIndex + 1);
    //       let gTemp: number;
    //       for (let i = affectedSlice.length - 1; i >= 0; i--) {
    //         if (i === affectedSlice.length - 1) {
    //           gTemp = affectedSlice[i].order;
    //           affectedSlice[i].order = affectedSlice[0].order;
    //         } else {
    //           let lTemp: number = affectedSlice[i].order;
    //           affectedSlice[i].order = gTemp;
    //           gTemp = lTemp;
    //         }
    //       }
    //     }

    //     affectedSlice.forEach((affectedChild) => {
    //       parent.children.forEach((child) => {
    //         if (child.key === affectedChild.key) {
    //           child.order = affectedChild.order;
    //         }
    //       });
    //     });

    //     dprojectsColumns_copy.forEach((col) => {
    //       if (col.key === parent.key) {
    //         col = { ...parent };
    //       }
    //     });

    //     return {
    //       ...state,
    //       dprojectsTableCols: dprojectsColumns_copy,
    //     };
    //   } else {
    //     //console.log('action: ', action);

    //     let shownCols = dprojectsColumns_copy.filter((col) => col.visible);
    //     let affectedSlice: typeof dprojectsColumns;
    //     if (action.fromIndex < action.toIndex) {
    //       affectedSlice = shownCols.slice(action.fromIndex, action.toIndex + 1);

    //       //console.log('affectedSlice_before: ', affectedSlice);

    //       let gTemp: number;
    //       for (let i = 0; i < affectedSlice.length; i++) {
    //         if (i === 0) {
    //           gTemp = affectedSlice[i].order;
    //           affectedSlice[i].order =
    //             affectedSlice[affectedSlice.length - 1].order;
    //         } else {
    //           let lTemp: number = affectedSlice[i].order;
    //           affectedSlice[i].order = gTemp;
    //           gTemp = lTemp;
    //         }
    //       }
    //     } else {
    //       affectedSlice = shownCols.slice(action.toIndex, action.fromIndex + 1);

    //       //console.log('affectedSlice_before: ', affectedSlice);

    //       let gTemp: number;
    //       for (let i = affectedSlice.length - 1; i >= 0; i--) {
    //         if (i === affectedSlice.length - 1) {
    //           gTemp = affectedSlice[i].order;
    //           affectedSlice[i].order = affectedSlice[0].order;
    //         } else {
    //           let lTemp: number = affectedSlice[i].order;
    //           affectedSlice[i].order = gTemp;
    //           gTemp = lTemp;
    //         }
    //       }
    //     }

    //     // console.log('affectedSlice_after: ', affectedSlice);

    //     affectedSlice.forEach((affectedCol) => {
    //       dprojectsColumns_copy.forEach((col) => {
    //         if (col.key === affectedCol.key) {
    //           col.order = affectedCol.order;
    //         }
    //       });
    //     });
    //     // console.log('dprojectsColumns_copy: ', dprojectsColumns_copy);

    //     return {
    //       ...state,
    //       dprojectsTableCols: dprojectsColumns_copy,
    //     };
    //   }

    case ActionTypes.FILTERDPROJECTSCOLSBYLEVERID:
      let dprojectsColumns_copy_levers: any[] = [];
      state.dprojectsTableCols.forEach((col) => {
        let children_copy: {
          title: string;
          dataIndex: string;
          key: string;
          width: number;
          className: string;
          visible: boolean;
          order: number;
        }[] = [];
        if (["1", "2", "3", "4", "5"].includes(col.key.charAt(0))) {
          // it is a questionnaire KPI
          if (action.leverId == 6) {
            col.children.forEach((child) => {
              children_copy.push({ ...child, visible: true });
            });
            dprojectsColumns_copy_levers.push({
              ...col,
              children: children_copy,
              visible: true,
            });
          } else {
            if (col.key.charAt(0) === action.leverId.toString()) {
              col.children.forEach((child) => {
                children_copy.push({ ...child, visible: true });
              });
              dprojectsColumns_copy_levers.push({
                ...col,
                children: children_copy,
                visible: true,
              });
            } else {
              col.children.forEach((child) => {
                children_copy.push({ ...child, visible: false });
              });
              dprojectsColumns_copy_levers.push({
                ...col,
                children: children_copy,
                visible: false,
              });
            }
          }
        } else {
          // it is not a questionnaire KPI
          col.children.forEach((child) => {
            children_copy.push({ ...child });
          });
          dprojectsColumns_copy_levers.push({
            ...col,
            children: children_copy,
          });
        }
      });
      return {
        ...state,
        dprojectsTableCols: dprojectsColumns_copy_levers,
      };
    default:
      break;
  }
  return state;
};

export default TablesColsPropsReducer;
