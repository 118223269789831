import { useEffect } from "react";
import { connect } from "react-redux";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
import './bestpractices.css';
import { Image } from "antd";
import bestPracticeImg from '../../../../../assets/Images/FlBestPractices/reference.png';

interface IProps {
    active: boolean;
};

function BestPracticesTab(props: IProps) {


    return (
        props.active ? <div className="BestPractices_tab_main_div">
            <Image src={bestPracticeImg} />
        </div> : null
    );
};

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BestPracticesTab);