import { Button, Form, Input, InputNumber, Checkbox, Select, Alert, Spin, Space } from "antd";
import { useState } from 'react';
import { IDisciplineItem, IDiscipline } from '../../../Models/IDiscipline';
import { ActionTypes } from "../../../Redux/actionsTypes";
import { connect } from "react-redux";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import './index.scss'
import ModalAdmin from "../../../Components/ModalAdmin/ModalAdmins";
import ColorPickerInput from "../../../Components/ColorPickerInput";
import { RulesColorPicker, RulesDescription, RulesName, RulesRequire } from "../Rules/RulesValidation";
import { t } from "i18next";
import i18n from "../../../i18n";

interface IProps {
    disciplines: IDiscipline[];
    dataDiscipline: IDisciplineItem;
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    updateDiscipline: (model: IDisciplineItem) => any;


}
interface IColorValue {
    backgroundColor: string;
    forgroundColor: string;
}

const DisciplineEditModal = (props: IProps) => {
    const [form] = Form.useForm();
    const [colorValues, setColorsValues] = useState<IColorValue>({
        backgroundColor: props.dataDiscipline?.backgroundColor,
        forgroundColor: props.dataDiscipline?.forgroundColor
    });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            let values = await form.getFieldsValue();
            const updateDiscipline: IDisciplineItem = {
                id: props.dataDiscipline?.id,
                nameEn: values?.nameEn?.trimStart(),
                nameDe: values?.nameDe?.trimStart(),
                parent: values?.parent === -1 ? null : values?.parent,
                viewOrder: values.viewOrder,
                backgroundColor: colorValues?.backgroundColor?.substring(1),
                forgroundColor: colorValues?.forgroundColor?.substring(1),
                isOriginal: values?.isOriginal,
                descriptionEn: values?.descriptionEn?.trimStart(),
                descriptionDe: values?.descriptionDe?.trimStart(),
            };

            await props.updateDiscipline(updateDiscipline)

        } catch (error) {
            console.log(error);

        }
    }


    const closeAndReset = () => {
        form.resetFields();
        setColorsValues({
            backgroundColor: props.dataDiscipline?.backgroundColor,
            forgroundColor: props.dataDiscipline?.forgroundColor
        });
        setIsModalVisible(false);
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component discipline-component">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={ (props.dataDiscipline != null)? props.dataDiscipline.nameDe :`${t("Add")} ${t("New")} ${t("Discipline")}`} btnText={`${t("Edit")}`}
                        width="1000px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`}
                            </Button>
                        ]}>
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={props.dataDiscipline}
                        >
                            <Form.Item label={`${t("English Name")}`} name='nameEn'
                                rules={RulesName({ name: `${t("English Name")}` ,countChar:128})}
                                style={{ width: "47.5%", marginRight: "2.5%", display: "inline-block" }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={`${t("German Name")}`} name='nameDe'
                                rules={RulesName({ name: `${t("German Name")}`,countChar:128 })}
                                style={{ width: "47.5%", marginLeft: "2.5%", display: "inline-block" }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={`${t("Parent")}`} name='parent' style={{ width: "40%", display: "inline-block", marginRight: "2%",}}>
                                <Select showSearch placeholder={`${t("Select Parent")}`} optionFilterProp="children"
                                >
                                    <Select.Option value={-1} key="parent_none">{`--- ${t("None")} ---`}</Select.Option>
                                    {props.disciplines?.map((d: any) => <Select.Option value={d?.id} key={d?.id}>{i18n.language === 'de' ? d?.nameDe : d?.nameEn}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label={`${t("View Order")}`} name='viewOrder' rules={RulesRequire({ filedName: `${t("View Order")}` })}
                                validateTrigger='onBlur' hasFeedback style={{ width: "20%", display: "inline-block",  }}>
                                <InputNumber min={0} />
                            </Form.Item>

                            <Form.Item label={`${t("Background Color")}`} name='backgroundColor' style={{ width: "20%", marginRight: "3.5%", display: "inline-block", textAlign: "center" }}
                                rules={RulesColorPicker({ filedName: `${t("Background Color")}`, colorValue: colorValues?.backgroundColor })} className="color-picker-required">
                                <ColorPickerInput colorValue={colorValues?.backgroundColor} onChangeComplete={async (color: any, event: any) => {
                                    try {
                                        setColorsValues({ ...colorValues, backgroundColor: color?.hex })
                                        await form.validateFields();
                                    }
                                    catch (err) {
                                        console.log("Background color", err);
                                    }
                                }} />
                            </Form.Item>

                            <Form.Item label={`${t("Text Color")}`} name='forgroundColor' style={{ width: "10%", marginLeft: "3%", display: "inline-block", textAlign: "center" }}
                                rules={RulesColorPicker({ filedName: `${t("Text Color")}`, colorValue: colorValues?.forgroundColor })}
                                className="color-picker-required"
                            >
                                <ColorPickerInput colorValue={colorValues?.forgroundColor} onChangeComplete={async (color: any, event: any) => {
                                      try {
                                        setColorsValues({ ...colorValues, forgroundColor: color?.hex })
                                        await form.validateFields();
                                    }
                                    catch (err) {
                                        console.log("Text color", err);
                                    }
                                  
                                }} />

                            </Form.Item>
                            <Form.Item label={`${t("English Description")}`} name='descriptionEn'
                                rules={RulesDescription({ name: `${t("English Description")}`,countChar:1500 })} hasFeedback>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item label={`${t("German Description")}`} name='descriptionDe'
                                rules={RulesDescription({ name: `${t("German Description")}`,countChar:1500})}
                                hasFeedback>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name='isOriginal' valuePropName="checked">
                                <Checkbox >{`${t("Is Original")}`}</Checkbox>
                            </Form.Item>
                        </Form>
                    </ModalAdmin>
                </Space>
            </div >
        </Spin >
    )
};
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.disciplines.isDisciplinesLoading,
        disciplines: state.disciplines.disciplinesResult,
        isModalLoading: state.disciplines.isModalLoading,
        modalResponse: state.disciplines.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDiscipline: (model: IDisciplineItem) => dispatch({ type: ActionTypes.UPDATE_DISCIPLINE, model }),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DisciplineEditModal);