import { UploadFile } from "antd";
import { t } from "i18next";

export const RulesName = (props: { name: string, countChar: number }) => {
    const rules: any[] = [{ required: true, message: `${props.name}  ${t("is required*")}` },
    { whitespace: true, message: `${props.name} ${t("must not be empty")} ` }, {
        max: props.countChar, message: `${props.name} ${t("must be less than")} ${props.countChar} ${t("characters.")}`
    }]
    return rules;
}
export const RulesDescription = (props: { name: string, countChar: number }) => {
    const rules: any[] = [
        // { required: true, message: `${props.name}  ${t("is required*")}` },
        { whitespace: true, message: `${props.name} ${t("must not be empty")} ` },
        {
            max: props.countChar, message: `${props.name} ${t("must be less than")} ${props.countChar} ${t("characters.")}`
        }]
    return rules;
}
export const RulesPhone = () => {
    const rules: any[] = [{
        pattern: (/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/),
        message: `${t("Please enter valid Phone Number")}`,
    }]
    return rules
}
export const RulesEmail = () => {
    const rules: any[] = [
        {
            required: true,
            message: `E-Mail ${t("is required*")}`
        },
        {
            pattern: (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i),
            message: `${t("Please enter valid E-Mail")}`,
        },
    ]
    return rules
}
export const RulesRequire = (props: { filedName: string }) => {
    const rules: any[] = [{ required: true, message: `${props.filedName} ${t("is required*")}` }]
    return rules
}
export const RulesColorPicker = (props: { filedName: string, colorValue: string }) => {
    const rules: any[] = [
        () => ({
            validator(_, value) {
                if (!props.colorValue) {
                    return Promise.reject(`${props.filedName} ${t("is required*")}`);
                }
                return Promise.resolve();
            }
        }),
    ]
    return rules;
}
export const RulesDurations = (props: { filedName: string;}) => {
    const rules: any[] = [
        () => ({
            validator(_, value) {
                if (typeof (value) !== 'number' || value < 10 || value > 10080) {
                    return Promise.reject(`${props.filedName} ${t("value must be between 10 and 10080")}`);
                }
                return Promise.resolve();
            }
        })
    ]
    return rules;
}
export const RulesPercentage = (props: { filedName: string; valuePercentage?: number, remainingPercentage?: number }) => {
    const rules: any[] = [
        props.valuePercentage ? (() => ({
            validator(_, value) {
                if (props.remainingPercentage === 0) {
                    return Promise.reject(`${t("Values ​​have been completed")}`);

                } else {
                    if (typeof (props?.valuePercentage) !== 'number' || props.valuePercentage < 0 || props.valuePercentage > (props.remainingPercentage ?? 100)) {
                        return Promise.reject(`${props.filedName} ${t("value must be between 0 and ")} ${props.remainingPercentage ? props.remainingPercentage : "100"}`);
                    }
                }

                return Promise.resolve();
            }
        })
        ) : (
            () => ({
                validator(_, value) {
                    if (typeof (value) !== 'number' || value < 0 || value > (props.remainingPercentage ?? 100)) {
                        return Promise.reject(`${props.filedName} ${t("value must be between 0 and ")}  ${props.remainingPercentage ? props.remainingPercentage : "100"}`);
                    }
                    return Promise.resolve();
                }
            })
        )
    ]
    return rules;
}
export const RulesPassword = (props: { filedName: string }) => {
    const rules =
        [
            {
                required: true,
                message: `${props.filedName}  ${t("is required*")} `
            }, {
                pattern: (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/g),
                message: `${t("Use at least 6 characters including (0,9), (a-z) and (A-Z)")} `
            }

        ]

    return rules;
}

export const validateFileType = (
    { type, name }: UploadFile,
    allowedTypes?: string
) => {
    if (!allowedTypes) {
        return true;
    }

    if (type) {
        return allowedTypes.includes(type);
    }
};