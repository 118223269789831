import { Select, Spin } from "antd";
import { profile } from "console";
import { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import i18n from "../../../../../i18n";
import { ProfileResponse } from "../../../../../Models/Responses/ProfileResponse";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
import { IEbitToolState } from "../../../../../Redux/reducers/IGeneralState";
import RTDCurve from "../RTDCurveTab/components/RTDCurve";
import { RTDCurveData } from "../RTDCurveTab/Models/RTDCurveData";
import RDCostsVSDisciplineBarChart from "./components/RDCostsVSDisciplineBarChart";
import './costTypesVsProjectTab.scss';
import DisciplinesVSBestPractice from './components/DisciplinesVsBestPractices';
import { useTranslation } from "react-i18next";
interface IProps {
    active?: boolean;
    getEbitBenchmark: (projectID: number) => void;
    profileResponse: ProfileResponse;
    ebitResult: IEbitToolState;
    gettingRTDCurveData: boolean;
    RTDCurveData: RTDCurveData;
    getRTDCurveData: (flProjectId: number, isCurrentFlProject: boolean) => void;
    currentFrontLoadingProjectId: number;
}

function CostTypesVsProjectTab(props: IProps) {
    const { t } = useTranslation();
    const [allDisciplinesNames, setAllDisciplinesNames] = useState<string[]>([]);
    const [disciplineName, setDisciplineName] = useState<string>(t("Wählen Sie eine Disziplin aus"));
    useEffect(() => {
        if (props.profileResponse?.currentProject) {
            props.getEbitBenchmark(props.profileResponse.currentProject.id);
            props.getRTDCurveData(props.currentFrontLoadingProjectId, true);
        }
    }, [props.profileResponse?.currentProject?.id]);

    useEffect(() => {

        if (props.ebitResult?.ebitBenchmarkingInfo) {
            setAllDisciplinesNames(
                props.ebitResult
                    .ebitBenchmarkingInfo
                    .disciplinesBestPractices?.map(d => i18n.language === 'de' ? d.name : d.englishName)
            );
        }

    }, [props.ebitResult]);

    const GetChartDataByDisciplineName = (disciplineName: string) => {
        return props.ebitResult.ebitBenchmarkingInfo?.projectsBenchmarks?.map((d) => {
            let item = d.projectDisciplinesBenchmark.find(x => i18n.language === 'de' ? x.name == disciplineName : x.englishName == disciplineName);
            return {
                name: d.projectName,
                value: item?.rate,
                isTarget: d.isTarget,
                isComplete: d.isComplete
            }
        }
        );
    }
    const GetChartBestPracticeByDisciplineName = (disciplineName: string) => {
        let item = props.ebitResult.ebitBenchmarkingInfo?.disciplinesBestPractices.find(x => x.name == disciplineName || x.englishName == disciplineName);
        return {
            value: item?.bestPractice?.value ?? 0,
            max: item?.bestPractice?.max ?? 0,
            min: item?.bestPractice?.min ?? 0,
        }
    };

    const disciplineChanged = useCallback((disciplineName: string) => {
        console.log("disciplineName: ", disciplineName);
        setDisciplineName(disciplineName);
    }, []);

    return (
        props.active ? <div className="CostTypesVsProjectTab_main_tab">
            <div className="CostTypesVsProjectTab_container">
                <div className="disciplines-departments-tables-div">
                    <DisciplinesVSBestPractice
                        projectName={props.profileResponse?.currentProject?.shortName}
                        ebitBenchmarkingInfo={props.ebitResult?.ebitBenchmarkingInfo}
                        setDisciplineName={disciplineChanged}
                    />
                </div>
                <div className="charts-div">
                    <div className="r&dcosts-chart-div">
                        <Spin spinning={props.ebitResult?.isEbitLoading}>
                            <div className="discipline-rndcosts-chart-div">
                                <RDCostsVSDisciplineBarChart
                                    disciplineName={disciplineName}
                                    key={disciplineName}
                                    currentDiscipline={GetChartDataByDisciplineName(disciplineName)}
                                    disciplineBestPractice={GetChartBestPracticeByDisciplineName(disciplineName)} />
                            </div>
                        </Spin>
                    </div>
                    <Spin spinning={props.gettingRTDCurveData}>
                        <div className="RTDCurve-chart">
                            <RTDCurve  showBestPracticeCurve={true} key={`costtypes_vs_projecttabs_${props.RTDCurveData?.flProjectId}`} RTDCurveData={props.RTDCurveData} />
                        </div>
                    </Spin>
                </div>
            </div>
        </div> : null
    );
}

const mapStateToProps = (state) => {
    return {
        profileResponse: state.generalInfo.profileResponse,
        ebitResult: state.ebit,
        gettingRTDCurveData: state.frontLoadingProjects.gettingRTDCurveData,
        currentFrontLoadingProjectId: state.generalInfo.profileResponse?.currentFrontLoadingProjectId,
        RTDCurveData: state.frontLoadingProjects.RTDCurveData,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getEbitBenchmark: (projectID: number) =>
            dispatch({ type: ActionTypes.GetEbitBenchmark, projectID: projectID }),
        getRTDCurveData: (flProjectId: number, isCurrentFlProject: boolean) =>
            dispatch({ type: ActionTypes.GetRTDCurveData, flProjectId: flProjectId, isCurrentFlProject: isCurrentFlProject }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CostTypesVsProjectTab);