import { 
  Form,
  InputNumber,
  
  Table,  
} from "antd";
 
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState"; 
import { inputFormatter, inputParser, formatValue } from "../../../../Utils/helperFunctions";
import i18n from "../../../../i18n";
import { ILeverQuestion } from "../../../../Models/Levers/Items"; 
import { IDProject } from "../../../../Models/IDProject";
import { ILeverSubquestion } from "../../../../Models/Requests/SaveLevers";

interface DProjctInLeverRowDeviationRateValue {
  key: string;
  project: number;//d-project id
  projectyear: number;//year of complation
  plannedRate: number;
  runtime: number;
  delays: number;
  NewProduct?: string;
}
interface IProps {
  question: ILeverQuestion;
  subquestions: ILeverSubquestion;
  dprojects: IDProject[];
}

function SubQuestionTableCostOfDelaysReadonly(props: IProps) {
  const newRowCOD: DProjctInLeverRowDeviationRateValue = {
    key: "newDProjectInleverRow",
    project: null,
    projectyear: 0,
    delays: 0,
    plannedRate: 0,
    runtime: 0,
    NewProduct: "",
  };
  const { t } = useTranslation();
   const [form] = Form.useForm();  

  const dataColCODRate: ColumnsType = [
    {
      title: (
        <p>
          {`Products`}
        </p>
      ),
      width: '30%',
      dataIndex: "product",
      fixed: "left", // fix element
      ellipsis: true,
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        return (
          props.dprojects.find(
            (c) => c.id == record?.project
          )?.name ?? ""
        );
      }, // keep this if you need to render link with data cell.
    },
    {
      title: (
        <p>
          {`Year`}
        </p>
      ),
      width: '10%',
      dataIndex: "projectyear",
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        if (record?.key == "sum") return <></>;
        return (
          props.dprojects.find(
            (c) => c.id == record?.project
          )?.finishingYear ?? ""
        );
      },
    },
    {
      title: (
        <p>
          {props.question.subquestions.headers?.[1]?.displayname}
        </p>
      ),
      width: '20%',
      dataIndex: props.question.subquestions.headers?.[1]?.dataIndex,
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => { 
        return `${formatValue(record?.runtime)}${props.question.subquestions.headers?.[1]?.unit}`;
      },
    },
    {
      title: (
        <p>
          {props.question.subquestions.headers?.[2]?.displayname}
        </p>
      ),
      width: '20%',
      dataIndex: props.question.subquestions.headers?.[2]?.dataIndex,
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        
        return `${formatValue(record?.delays)}${props.question.subquestions.headers?.[2]?.unit}`;
      },
    },
    {
      title: (
        <p>
          {props.question.subquestions.headers?.[3]?.displayname}
        </p>
      ),
      width: '15%',
      dataIndex: "plannedRate",
      render: (text: any, record: DProjctInLeverRowDeviationRateValue, index) => {
        if (record?.key == "sum") return `${formatValue(record.plannedRate)}${props.question.subquestions.headers?.[3]?.unit}`;
         
        return `${formatValue((record?.delays / (record?.runtime - record?.delays)) * 100)}${props.question.subquestions.headers?.[3]?.unit}`;
      },
    }   
  ];
  console.log("props.subquestions?.deviationRates", props.subquestions?.deviationRates);
  const dataRowCODRate = props.subquestions?.deviationRates?.map((row) => {
    return {
      key: `qk_row_${row.dprojectId}`,
      plannedRate: 0,//row.rate,
      project: row.dprojectId,
      projectyear: row.dprojectCompletionYear,
      delays: row.delayMonths,
      runtime: row.runtimeMonths,
      NewProduct: ""
    } as DProjctInLeverRowDeviationRateValue;
  }) ?? [];

  dataRowCODRate.push({
    key: `sum`,
    plannedRate: dataRowCODRate.reduce((a, v) => a = a + ((v.delays / (v.runtime - v.delays)) * 100), 0) / dataRowCODRate.length,
    project: 0,
    projectyear: 0,
    delays: dataRowCODRate.reduce((a, v) => a = a + v.delays, 0),
    runtime: dataRowCODRate.reduce((a, v) => a = a + v.runtime, 0),
    NewProduct: "",
  } as DProjctInLeverRowDeviationRateValue);
 
  return (
    <div className="editable-table-dproject-lever" id="editable-table-dproject-lever">
      <Form form={form} component={false} key="cod_form">
        <Table
          key={"cod_table"}
          bordered={false}
          dataSource={[...dataRowCODRate]}
          columns={dataColCODRate}
          scroll={{ y: 500 }}
          rowClassName={() => "editable-row"}
          pagination={false}
          className={"subquestion-table"}
        />
      </Form>
    </div>
  );
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    dprojects: state.dprojects.dprojects,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubQuestionTableCostOfDelaysReadonly);
