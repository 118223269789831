import { Space, Tooltip, Form, Input, Typography, InputNumber } from 'antd';
import { IActivityProfileTemplate } from '../../../Models/IActivityManagement';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { connect } from 'react-redux';
import { SisternodeOutlined } from "@ant-design/icons";
import { useRef, useState } from 'react';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import { ActionTypes } from '../../../Redux/actionsTypes';
import ActivityProfileTasksModal from './ActivityTasks/ActivityProfileTasksModal';
import ActivityProfileGroupsModal from './ActivityGroups/ActivityGroupsModal';
import AdminOperation from '../../../Components/AdminOperation/AdminOperation';
import { RulesName, RulesRequire } from '../Rules/RulesValidation';
import { Link } from 'react-router-dom';
import i18n from "../../../i18n";
import { t } from "i18next";
import AdminTable from '../../../Components/AdminTable/AdminTable';
import PopconfirmAsync from '../../../Components/PopconfirmAsync'; 

interface IProps {
    allActivityProfileData: IActivityProfileTemplate[];
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    functionTypes: any[];
    updateActivityProfile: (model: IActivityProfileTemplate) => any;
    changeStatusActivityProfile: (profileId: number, isActive: boolean) => any;
}
const ActivityMngTable = (props: IProps) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };

    const columns: any[] = [
        {
            key: "nameEn_1",
            title: `${t("English Name")}`,
            dataIndex: 'nameEn',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='nameEn'
                            rules={RulesName({ name: `${t("English Name")}`,countChar:128})}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <Typography.Text>{text}</Typography.Text>
                }
            },
            ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),
            sorter: (a, b) => a?.nameEn?.localeCompare(b?.nameEn) ?? 0,
            defaultSortOrder: 'ascend'
        },
        {
            key: "nameDe_2",
            title: `${t("German Name")}`,
            dataIndex: 'nameDe',
            ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='nameDe'
                            rules={RulesName({ name: `${t("German Name")}`,countChar:128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <Typography.Text>{text}</Typography.Text>
                }
            },
            sorter: (a, b) => a?.nameDe?.localeCompare(b?.nameDe) ?? 0
        },
        {
            title: `${t("Actions")}`,
            dataIndex: 'Actions',
            width: "10%",
            render: (_: any, record: IActivityProfileTemplate) => {
                const editable = isEditing(record);
                return <AdminOperation
                    record={record}
                    editable={editable}
                    onCancel={cancel}
                    onEdit={edit}
                />
            },
        },
        {
            key: "status_1",
            title: `${t("Status")}`,
            dataIndex: 'isActive',
            width: "10%",
            render: (_: any, record: any) => {
                const activeStatus = record.isActive;
                return activeStatus ? (
                    <PopconfirmAsync
                        title={`${t("Sure to deactivate?")}`}
                        handleOk={() => changeStatusActivityProfile(record.id, !record.isActive)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        deactivateUser={true}
                        iconPower
                    />
                ) : (
                    <PopconfirmAsync
                        title={`${t("Sure To Activate?")}`}
                        handleOk={() => changeStatusActivityProfile(record.id, !record.isActive)}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        activeUser={true}
                        iconPower
                    />
                )
            }
        },
        {
            title: `${t("Activity Groups")}`,
            dataIndex: "",
            width: "10%",
            render: (_any, record: IActivityProfileTemplate) => {
                return <ActivityProfileGroupsModal activityProfileId={record?.id}
                    ActivityProfileName={i18n.language === 'de' ? record?.nameDe : record?.nameEn} groups={record?.profileGroups} />
            }
        },
        {
            title: `${t("Tasks")}`,
            dataIndex: "",
            width: "7%",
            render: (_any, record: IActivityProfileTemplate) => {
                return <ActivityProfileTasksModal activityProfileId={record?.id}
                    ActivityProfileName={i18n.language === 'de' ? record?.nameDe : record?.nameEn} tasks={record?.profileTasks} />
            }
        },
        {
            title: `${t("Versions")}`,
            dataIndex: '',
            width: "7%",
            fixed: "right",
            render: (_: any, record: any) => {
                return <Space>
                    <Tooltip title={`${t("Go to")} ${t("Versions")}`}>
                        <Link to={`/admin/activity/versions?profileId=${record?.id}`}
                        >
                            <SisternodeOutlined style={{ fontSize: "15px" }} />
                        </Link>
                    </Tooltip>
                </Space>

            }
        },

    ];
    const isEditing = (record: IActivityProfileTemplate) => record.key === editingKey;
    const edit = (record: Partial<IActivityProfileTemplate>) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: IActivityProfileTemplate) => {
        try {
            await form.validateFields()
            const newData = [...props.allActivityProfileData];
            const index = newData.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = newData[index];
                const activityProfileUpdate: IActivityProfileTemplate = {
                    ...values,
                    id: item.id,
                    bestPracticeValue: 100,//values.bestPracticeValue ?? item.bestPracticeValue,
                    isActive: values.isActive ?? item.isActive,
                    nameDe: values.nameDe ?? item.nameDe,
                    nameEn: values.nameEn ?? item.nameEn,
                }
                props.updateActivityProfile(activityProfileUpdate);
                setEditingKey('');
            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }
    const changeStatusActivityProfile = async (profileId: number, isActive: boolean) => {
        try {
            await props.changeStatusActivityProfile(profileId, isActive);
        } catch (errInfo) {
            console.log('change status Failed:', errInfo);
        }
    }

    return (
        <Form form={form} key="activity_profile_form" onFinish={onFinish}>
            <AdminTable
                keyTable='activityProfileMng_table'
                className='activityProfileMng_table'
                dataSource={props.allActivityProfileData}
                columns={columns}
            />
        </Form>
    );
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateActivityProfile: (model: IActivityProfileTemplate) => dispatch({ type: ActionTypes.UPDATE_ACTIVITY_PROFILE_FOR_ADMIN, model }),
        changeStatusActivityProfile: (profileId: number, isActive: boolean) => dispatch({ type: ActionTypes.CHANGE_STATUS_ACTIVITY_PROFILE, profileId, isActive })
    };
};

export default connect(null, mapDispatchToProps)(ActivityMngTable);