import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { ToastMessages } from "../Enums/Messages";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";
import { IDepartmentItem } from "../Models/IDepartment";
import saveAs from "file-saver";
import { InterfaceAnalysisExportExcel } from "../Models/InterfaceAnalysis/IAItems";


export default class DepartmentByBmProjectService {

  static async GetDepartmentsByBmProject(bmProjectId: number): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.getDepartmentByBmProject, {
        params: {
          bmProjectId,
        },
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        console.log("result test", result);
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async CreateDepartmentByBmProject(model: IDepartmentItem): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.createCustomerDepartment, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async UpdateDepartmentByBmProject(model: IDepartmentItem): Promise<ICommonResponse> {
    console.log("UpdateCustomerDepartment", model);
    return await asupapi
      .post(endPoints.updateCustomerDepartment, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async UpdateOrderDepartmentByBmProject(list: IDepartmentItem[]): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.updateOrdersCustomerDepartment, list)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async DeleteDepartmentByBmProject(
    departmentId: number
  ): Promise<ICommonResponse> {
    return await asupapi
      .get(endPoints.deleteCustomerDepartment, {
        params: {
          departmentId
        }
      })
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error1 ", error.response);
          if (error.response.data) {
            console.log("error0 ", error.response.data);
            let result: ICommonResponse = error.response.data;
            return result;
          }
        } else if (error.request) {
          console.log("error2 ", error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError,
            },
          } as ICommonResponse;
        } else if (error.message) {
          console.log("error3 ", error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError,
          },
        } as ICommonResponse;
      });
  }

  static async ExportToExcel(model: InterfaceAnalysisExportExcel) {
    await asupapi
      .post(endPoints.exporInterfacesToExcel, model, {
        responseType: 'blob'
      })
      .then(function (response) {
        var contentDisposition = response.headers["content-disposition"];
        var match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
        var filename = match[1];
        console.log("filename:", filename);
        var blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, filename);

      }).catch(function (error) {
        console.log('error1 ', error);
      });
  }
  static async ImportDepartmentTable(model: FormData): Promise<ICommonResponse> {
    return await asupapi
      .post(endPoints.ImportDepartmentTable, model)
      .then(function (response) {
        let result: ICommonResponse = response.data;
        return result;
      })
      .catch(function (error) {
        if (error.response) {
          console.log('error1 ', error.response);
          if (error.response.data) {
            console.log('error0 ', error?.response?.data);
            let result: ICommonResponse = error?.response?.data ?? ToastMessages.UnExpectedError;
            return result;
          } else {
            return {
              isError: false,
              statusCode: -400,
              error: {
                exceptionMessage: ToastMessages.UnExpectedError
              }
            } as ICommonResponse
          }
        } else if (error.request) {
          console.log('error2 ', error.request);
          return {
            isError: false,
            statusCode: -400,
            error: {
              exceptionMessage: ToastMessages.NetworkError
            }
          } as ICommonResponse
        } else if (error.message) {
          console.log('error3 ', error.message);
          //do something other than the other two
        }
        return {
          isError: false,
          statusCode: -400,
          error: {
            exceptionMessage: ToastMessages.UnExpectedError
          }
        } as ICommonResponse
      });
  }
}
