import React, { useRef, useState } from 'react'
import { IHelpPage } from '../../../Models/IHelpPage'
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { Button, Drawer, Empty, Form, Table, Tooltip, Typography } from 'antd';
import TextEditorForRichText from '../../../Components/TextEditorForRichText';
import AdminOperation from '../../../Components/AdminOperation/AdminOperation';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import parse from 'html-react-parser';
import { RulesRequire } from '../Rules/RulesValidation';
import { t } from 'i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
interface IProps {
  helpPagesRow: IHelpPage[];
  updateHelpPage: (model: IHelpPage) => any;
}
const HelpPageTable = (props: IProps) => {
  const [editingKey, setEditingKey] = useState('');
  const [formValues, setFormValues] = useState<IHelpPage>();
  const [form] = Form.useForm();
  //search code start
  const searchInput = useRef<any>(null);
  const handleSearch = (
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };
  const [visible, setVisible] = useState(false);
  const [visibleContext, setVisibleContext] = useState(null);
  const showDrawer = (context) => {
    setVisible(true);
    //console.log("showDrawer ", location.pathname);
    setVisibleContext(context);
    //props.getCurrentHelpPage(props.helpPageState?.currentPageCode);
  };
  const onClose = () => {
    setVisible(false);
  };
  //search code end
  const columns: any[] = [
    {
      key: "help_name_key",
      title: `${t("Name")}`,
      dataIndex: "code",
      width: "10%",
      render: (text: any, _: any) => {
        return <Typography.Text>{text}</Typography.Text>
      },
      ...getColumnSearchProps(`${t("Name")}`, 'code', searchInput, handleSearch),
      sorter: (a, b) => a?.code?.localeCompare(b?.code) ?? 0,
      defaultSortOrder: 'ascend',
    },
    {
      key: "help_des_en_key",
      title: `${t("English Description")}`,
      dataIndex: "descriptionEn",
      width: "40%",
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='descriptionEn'
              rules={RulesRequire({ filedName: `${t("English Description")}` })} >
              <TextEditorForRichText
                onChange={(value) => setFormValues({ ...formValues, descriptionEn: value })}
                value={formValues?.descriptionEn}
                placeholder="placeholder"
              />
            </Form.Item >
          )
        } else {
          if (text) {
            let stringText = text.toString();
            return <Tooltip placement="bottom" title={`${t("Click to view")}`}>
              <Button icon={<QuestionCircleOutlined />} onClick={() => { showDrawer(stringText) }} type="ghost"></Button>
            </Tooltip>
            //return parse(stringText);
          } else {
            return null;
          }
        }
      },

    },
    {
      key: "help_des_de_key",
      title: `${t("German Description")}`,
      dataIndex: "descriptionDe",
      width: "40%",
      render: (text: any, record: any) => {
        if (editingKey === record.key) {
          return (
            <Form.Item name='descriptionDe' rules={RulesRequire({ filedName: `${t("German Description")}` })} >
              <TextEditorForRichText
                onChange={(value) => setFormValues({ ...formValues, descriptionDe: value })}
                value={formValues?.descriptionDe}
                placeholder="placeholder"
              />
            </Form.Item>
          )
        } else {
          if (text) {
            let stringText = text.toString();
            return <Tooltip placement="bottom" title={`${t("Click to view")}`}>
              <Button icon={<QuestionCircleOutlined />} onClick={() => { showDrawer(stringText) }} type="ghost"></Button>
            </Tooltip>
            // return parse(stringText);
          } else {
            return null;
          }
        }
      }
    },
    {
      title: `${t("Actions")}`,
      dataIndex: '',
      width: '10%',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return <Link to={`/admin/help-page/edit?helpPageId=${record?.id}`}>
          <Button type='primary'>{`${t("Edit")}`}</Button>
        </Link>
        /* return <AdminOperation
          record={record}
          editable={editable}
          onCancel={cancel}
          onEdit={edit}
          onSave={save}
        /> */

      },
    }
  ]
  const isEditing = (record: IHelpPage) => record.key === editingKey;
  const edit = (record: Partial<IHelpPage> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async () => {
    try {
      await form.validateFields()
      const index = props.helpPagesRow.findIndex(item => item.key === editingKey);
      if (index > -1) {
        const item = props.helpPagesRow[index];
        const helpPageUpdate: IHelpPage = {
          id: item.id,
          descriptionEn: formValues?.descriptionEn ?? item.descriptionEn,
          descriptionDe: formValues?.descriptionDe ?? item.descriptionDe,
        }
        //console.log("HelpPageUpdate : ", helpPageUpdate);
        await props.updateHelpPage(helpPageUpdate);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }

  }

  return (
    <Form form={form} key="help_form" >
      <Drawer getContainer={() => document.getElementById('app-layout')} key={`drawer_header`} title="Quick Help" placement="right" onClose={onClose} open={visible}>
<div className='parent-image ql-editor'>
        {(visibleContext) ?
          parse(visibleContext) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Content"} />}
</div>
      </Drawer>
      <Table
        key='help_table'
        getPopupContainer={() => document.getElementById("app-layout")}
        bordered
        dataSource={props.helpPagesRow}
        columns={columns}
        rowClassName="editable-row"
        scroll={{ y: "calc(100vh - 300px)" }}
        pagination={{
          defaultPageSize: 50,
          pageSizeOptions: [50, 100, 150],
          total: props.helpPagesRow?.length,
          showTotal: (total) => `Total ${total} items`,
          showSizeChanger: true,
          locale: { items_per_page: `/ ${t("Page")}` },
        }}
        locale={{
          triggerDesc: t('Click to sort descending'),
          triggerAsc: t('Click to sort ascending'),
          cancelSort: t('Click to cancel sorting')
        }}

      />
    </Form>
  )
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateHelpPage: (model: IHelpPage) => dispatch({ type: ActionTypes.UPDATE_HELP_PAGE, model })
  }
}
export default connect(null, mapDispatchToProps)(HelpPageTable);
