import { History, Transition } from 'history';
import { useCallback, useContext, useEffect } from "react";
//import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

//type ExtendNavigator = Navigator & Pick<History, "block">;

export function useBlocker(confirmExit: () => boolean, when = true) {
    const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
    // const { navigator } = useContext(NavigationContext);

    // useEffect(() => {
    //     try{
    //         if (!when) return;

    //     const unblock = (navigator as ExtendNavigator).block((tx) => { 
    //         const autoUnblockingTx = {
    //             ...tx,
    //             retry() {
    //                 unblock();
    //                 tx.retry();
    //             },
    //         };

    //         blocker(autoUnblockingTx);
    //     });

    //     return unblock;
    //     }catch(error){
    //         console.log("error", error);
    //     }
    // }, [navigator, blocker, when]);
}

// export default function usePrompt(message: string, when = true) {
//     const blocker = useCallback((tx: Transition) => {
//         if (window.confirm(message)) tx.retry();
//     }, [message]);

//     useBlocker(blocker, when);
// }

export function usePrompt(message: string, when = true) {
    useEffect(() => {
      if (when) {
        window.onbeforeunload = function () {
          return message;
        };
      }
  
      return () => {
        window.onbeforeunload = null;
      };
    }, [message, when]);
  
    const confirmExit = useCallback(() => {
      const confirm = window.confirm(message);
      return confirm;
    }, [message]);
    useBlocker(confirmExit, when);
  }