import { Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ToastMessages } from "../../../../Enums/Messages";
import { ICommonResponse } from "../../../../Models/Responses/ICommonResponse";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import AuthService from "../../../../Services/AuthService";
import { openNotificationWithIcon } from "../../../../Utils/helperFunctions";
import { t } from "i18next";


interface IProps {
    isPasswordRequired: boolean;
    isModalLoading: boolean;
    confirmPassword: (pass: string) => any;
    modalResponse: ICommonResponse;
    closePasswordModal: () => any;
}

function PasswordRequiredView(props: IProps) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    // const [toChangeProject, setToChangeProject] = useState(false);
    // const [toEditLevers, setToEditLevers] = useState(false);
    // const [toEditEbit, setToEditEbit] = useState(false);
    useEffect(() => {
        setIsModalVisible(props.isPasswordRequired);
    }, [props.isPasswordRequired]);
    useEffect(() => {
        if (props.isPasswordRequired) {
            if (props.modalResponse?.isError) {
                if( typeof props.modalResponse?.error?.exceptionMessage == 'string'){
                    if (props.modalResponse?.error?.exceptionMessage ) {
                        openNotificationWithIcon("error", "Error", props.modalResponse.error?.exceptionMessage as string);
                    } else {
                        openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
                    }
                }else{
                    openNotificationWithIcon("error", "Error", ToastMessages.UnExpectedError);
                }   
            } else {
                if (props.modalResponse?.message) {
                    openNotificationWithIcon("success", "Success", props.modalResponse?.message);
                    props.closePasswordModal();
                    form.resetFields();
                }
            }
        }
    }, [props.modalResponse]);

    //const [confirmingPassword, setConfirmingPassword] = useState(false);
    const handleCancel = () => {
        //setIsModalVisible(false);
        // setToChangeProject(false);
        // setToEditLevers(false);
        // setToEditEbit(false);
        props.closePasswordModal();
        form.resetFields();
    };
    const validateForm = async () => {
        try {
            form.submit();
        } catch (e) {
            console.log("FormError: ", e);
        }
    }

    const onFinish = async (values) => {
        console.log('onFinish-values: ', values);
        //setConfirmingPassword(true);
        try {
            props.confirmPassword(values.password);
            // let response: AxiosResponse = await asupapi.post(
            //     endPoints.ConfirmPassword,
            //     {
            //         password: values.password
            //     });
            // if (response.status === 200) {
            //     if (toChangeProject) navigate('/changeProject');
            //     if (toEditLevers) navigate('/editLevers');
            //     if (toEditEbit) navigate('/editEbit');
            // }

        } catch (error) {
            console.log("confirm password error: ", error);
            //setConfirmingPassword(false);
            // notification['error']({
            //     message: <div className='toast_error_title'> {error.response.data.error.exceptionMessage} </div>,
            // });
        }

    };

    const onFinishFailed = () => {
        console.error('Error FinishFailed, confirm password form did not finish correctly.');
    }


    return (<Modal
        getContainer={() => document.getElementById('app-layout')}
        title={`${t("Confirm Password")}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
            <Button key="back" onClick={handleCancel} >
                 {`${t("Cancel")}`}
            </Button>,
            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={validateForm}>
                {`${t("Confirm")}`}
            </Button>

        ]}
    >
        <div className="main_modal_div">
            <Form
                form={form}
                onFinish={onFinish.bind(this)}
                onFinishFailed={onFinishFailed.bind(this)}
                autoComplete="off"
            >
                <Form.Item
                    label={`${t("Password")}`}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t("Your password is required*"),
                        },
                    ]}
                >
                    <Input.Password placeholder={`${t("Your Password")}`} />
                </Form.Item>
            </Form>
        </div>
    </Modal>
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        isPasswordRequired: state.generalInfo.isPasswordRequired,
        isModalLoading: state.leverTool.isModalLoading,
        modalResponse: state.leverTool.modalResponse
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        closePasswordModal: () => dispatch({ type: ActionTypes.CloseAccessModal }),
        confirmPassword: (pass: string) => dispatch({ type: ActionTypes.ConfirmPassword, password: pass })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRequiredView);