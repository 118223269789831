import axios from "axios";
import { put } from "redux-saga/effects";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import { ActionTypes } from "../Redux/actionsTypes";
import { endPoints } from "./endPoints";
import { IAsupAppConfig } from "../Models/Requests/IAsupAppConfig";
const asupAppConfig = (window as any).globalTS as IAsupAppConfig;
const protocol = window.location.protocol;
const domain = window.location.hostname;
const port = window.location.port;

const full =  asupAppConfig.isTest? asupAppConfig.serverURL : `${protocol}//${domain}:${port ? port : ""}/`
console.log("serverURL full", full);
const asupapi = axios.create({
    baseURL: full
});

asupapi.interceptors.request.use(
    async (req) => {
        let token = (localStorage.getItem("rememberme") != 'true') ? sessionStorage.getItem("token") : localStorage.getItem("token");
        // console.log('req.url ', req.url);
        // if (token && req.url != '/api/AuthManagement/Login' && req.url != '/api/AuthManagement/TwoStepVerification') { 
        //     console.log('token ', token);
        //     if (isJwtExpired(token)) {

        //         console.log('isJwtExpired(token): ', isJwtExpired(token));

        //         token = await refreshToken();
        //     }
        //     req.headers["Authorization"] = `Bearer ${token}`;
        // }
        req.headers["Authorization"] = `Bearer ${token}`;
        req.headers["file-lang"] = localStorage.getItem('exported_files_lang') ?? "de";
        req.headers["culture"] = localStorage.getItem('localLang') ?? "de";
        req.headers["Accept-Language"] = (localStorage.getItem('localLang') ?? "de") == "de" ? "de-DE" : "en-US";
        req.headers["bm-project"] = sessionStorage.getItem("bm_project");
        // req.headers["Access-Control-Allow-Origin"] = 'localhost:5002';
        // req.headers["Access-Control-Allow-Methods"] = 'GET, POST, OPTIONS';
        return req;
    },
    (error) => {
        console.log('asupapi apply config error, ', error);
        return Promise.reject(error);
    }
);


asupapi.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        console.log('originalConfig', err);
        console.log('originalConfig', err?.config);
        const originalRequest = err.config;
        if (err.response.status === 1403) {
            return Promise.reject(err);
        }
        if (err.response.status === 1401) {
            console.log("Go to login 1401");

            await forceLogout();

            return Promise.reject(err);
        }
        if ((err.response.status === 403 || err.response.status === 401) && !originalRequest._retry) {
            originalRequest._retry = true;

            const token = await refreshToken();
            console.log("refreshToken ", token);
            if (token) {
                // asupapi.defaults.headers.common[
                //     "Authorization"
                // ] = `Bearer ${token}`;

                return asupapi(originalRequest);
            } else {
                return Promise.reject(err);
            }
        }
        return Promise.reject(err);
    }
);

async function refreshToken() {
    let token = (localStorage.getItem("rememberme") != 'true') ? sessionStorage.getItem("token") : localStorage.getItem("token");
    let refreshToken = (localStorage.getItem("rememberme") != 'true') ? sessionStorage.getItem("refreshToken") : localStorage.getItem("refreshToken");
    return await axios.post(full + endPoints.RefreshToken, {
        token: token,
        refreshToken: refreshToken
    }).then(async function (response) {
        let _result: ICommonResponse = response.data;
        console.log("RefreshToken ", _result);
        if (_result.isError) {
            // //logout 
            console.log("Error01 refreshToken  ", _result);
            await forceLogout();

            return null;
        } else {
            addTokenToLocalStorage(_result.result.token, _result.result.refreshToken);
            return _result.result.token;
        }
    })
        .catch(async function (error) {
            console.log("Error02 refreshToken  ", error);
            await forceLogout();
            return null;
        });
}

async function forceLogout() {
    sessionStorage.clear();
    localStorage.clear();
    await put({ type: ActionTypes.SetForceToPath, result: "/login" });
}
function addTokenToLocalStorage(token: string, refreshToken: string) {
    if (localStorage.getItem("rememberme") != 'true') {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("refreshToken", refreshToken);
    } else {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
    }
}
export default asupapi;
