import { ActionTypes } from "../actionsTypes";
import { ISelfAssessmentState } from "./IGeneralState";

const initialState: ISelfAssessmentState = {
  isSelfAssessmentLoading: false,
  selfAssessmentResult: null,
  isModalLoading: false,
  modalResponse: null,
};

const SelfAssessmentBpTempReducer = (state = initialState, action: any): ISelfAssessmentState => {
  switch (action.type) {
    case ActionTypes.CHANGE_SELF_ASSESSMENT_BP_TEMP_LOADER:
      return {
        ...state,
        isSelfAssessmentLoading: action.isLoading,
      };
    case ActionTypes.SAVE_SELF_ASSESSMENT_BP_TEMP:
      return {
        ...state,
        selfAssessmentResult: action.result,
      };
    case ActionTypes.SetModalLoader:
      return {
        ...state,
        isModalLoading: action.result
      }
    case ActionTypes.SetModalResponse:
      return {
        ...state,
        modalResponse: action.result
      }
    default:
      break;
  }
  return state;
};
export default SelfAssessmentBpTempReducer;
