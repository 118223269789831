import { Divider, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { IIndustryDisciplineBestPracticeItem, IIndustryDisciplineBestPracticeItemWithKey } from '../../../Models/IIndustryDisciplineBestPractice';
import './index.scss';
import IndustryDisciplineTable from './IndustryDisciplineTable';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import { t } from 'i18next';
interface IProps {
    allIndustryDiscipline: IIndustryDisciplineBestPracticeItem[];
    isLoading: boolean;
    industryId: number;
    getALLIndustryDisciplineBestPractice: (id: number) => any;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
};
const IndustryDisciplineBestPractice = (props: IProps) => {
    useEffect(() => { props.getALLIndustryDisciplineBestPractice(props.industryId); }, [])
    const industryDisciplineData: IIndustryDisciplineBestPracticeItemWithKey[] =
        props.allIndustryDiscipline?.map((p) => {
            return {
                key: `industryDis_${p.disciplineId}`, ...p
            }
        }) ?? [];
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="indDis_best_practice admin-component">
                <Divider><Typography.Title level={4}>{`${t("Discipline")}`} Best Practices</Typography.Title></Divider>
                {(industryDisciplineData && industryDisciplineData.length > 0) ? <IndustryDisciplineTable
                    industryId={props.industryId}
                    industryDisciplineData={[...industryDisciplineData]}
                /> : null
                }

            </div>
        </Spin >
    );
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        allIndustryDiscipline: state.industryDisciplineBestPractice.industryDisciplineBestPracticeResult,
        isLoading: state.industryDisciplineBestPractice.isIndustryDisciplineBestPracticeLoading,
        isModalLoading: state.industryDisciplineBestPractice.isModalLoading,
        modalResponse: state.industryDisciplineBestPractice.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getALLIndustryDisciplineBestPractice: (id: number) => dispatch({ type: ActionTypes.GET_INDUSTRY_DISCIPLINE_BEST_PRACTICES, industryId: id }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(IndustryDisciplineBestPractice);
