import { FunctionTypes } from "../../../Enums/FunctionTypes";
import {
  IKpiDegree,
  ILever,
  ILeverCell,
  ILeverQuestion,
  ISubLever,
  ISucessFactor,
} from "../../../Models/Levers/Items";

var FormulaParser = require("hot-formula-parser").Parser;

//Calculate KPI Erfüllungsgrad
//As Example: =IF(X7="linear",MAX(0,((N7-Y7)/(AA7-Y7))),IF(X7="linear bis",MAX(0,((MIN(N7,Z7)-Y7)/(Z7-Y7))),MAX(0,1-ABS(Z7-N7)/(AA7-Z7))))
export function CalculateKpiDegreeForOneOfFulfillment(question: ILeverQuestion) {
  var qKpiValue = parseFloat(question.kpiValue.value);
  var piFloor = question.piFloor;
  var bestPracticeValue = question.bestPracticeValue;
  var piUpperLimit = question.piUpperLimit;
  if (question.unit == "%") {
    qKpiValue = qKpiValue / 100;
    piFloor = question.piFloor / 100;
    bestPracticeValue = question.bestPracticeValue / 100;
    piUpperLimit = question.piUpperLimit / 100;
  }
  if (question.functionType.value == FunctionTypes.dach) {
    if (qKpiValue <= bestPracticeValue) {
      let temp = (bestPracticeValue == piFloor) ? 0 : ((Math.abs(bestPracticeValue - qKpiValue)) / (bestPracticeValue - piFloor));
      return Math.max(0, 1 - temp);
    } else {
      let temp = (piUpperLimit == bestPracticeValue) ? 0 : ((Math.abs(bestPracticeValue - qKpiValue)) / (piUpperLimit - bestPracticeValue));
      return Math.max(0, 1 - temp);
    }
  }
  if (question.functionType.value == FunctionTypes.linear) {
    if (piFloor == piUpperLimit) return 0;
    return Math.max(
      0,
      (qKpiValue -
        piFloor) /
      (piUpperLimit -
        piFloor)
    );
  }
  if (question.functionType.value == FunctionTypes.linear_bis) {
    if (bestPracticeValue == piFloor) return 0; 
    return Math.max(
      0,
      (Math.min(
        qKpiValue,
        bestPracticeValue
      ) - piFloor) / (bestPracticeValue - piFloor)
    );
  }
  if (question.functionType.value == FunctionTypes.linear_bis_2) {
    if (qKpiValue < bestPracticeValue) return 1;
    else { 
      let temp = (piUpperLimit == bestPracticeValue) ? 0 : ((Math.abs(bestPracticeValue - qKpiValue)) / (piUpperLimit - bestPracticeValue));
      return Math.max(0, 1 - temp);
    }
  }

}
export function CalculateKPIDegreeOfFulfillment(kpiLevel: IKpiDegree) {
  var result = 0;
  if (kpiLevel.questions.length > 1) {
    result = kpiLevel.questions.reduce((total, next) => total + next.kpiDegreeOfFulfillment / 100, 0) / kpiLevel.questions.length;
  } else {
    kpiLevel.questions.map((question) => {
      result += question.kpiDegreeOfFulfillment / 100;
    });
  }
  return result;
}
//Calculate EF Erfüllungsgrad
//As Example: =SUM(W6:W11*AB6:AB11)
export function CalculateEfDegreeOfFulfillment(successFactor: ISucessFactor) {
  var result = 0;
  result = successFactor.kpiLevels.reduce((total, next) => total + next.kpiValue / 100, 0) / successFactor.kpiLevels.length;
  return result;
}

//Calculate TSH Erfüllungsgrad
//As Example: =SUM(W5:W48*AC5:AC48)
export function CalculateTshDegreeOfFulfillment(sublever: ISubLever) {
  var result = 0;
  result = sublever.successFactors.reduce((total, next) => total + next.kpiValue / 100, 0) / sublever.successFactors.length;
  return result;
}

export function CalculateLeverKPIValue(lever: ILever) {
  var result = 0;
  result = lever.subLevers.reduce((total, next) => total + next.kpiValue / 100, 0) / lever.subLevers.length;
  return result;
}