import { Button, Modal, Space, Spin, Tooltip } from 'antd';
import { SettingOutlined } from "@ant-design/icons";
import React, { useState } from 'react'
import CustomerUserAssignBmProjectModal from './CustomerUserAssignBmProjectModal';
import { ICustomerNewBmProjectForUser } from '../../../../Models/ICustomerUsers';
import CustomerUserAssignBmProjectTable from './CustomerUserAssignBmProjectTable';
import { connect } from 'react-redux';
import { t } from 'i18next';

interface IProps {
    userId: number;
    customerId: number;
    bmProjectsData: ICustomerNewBmProjectForUser[];
    isLoading: boolean;
    isModalLoading: boolean;
}
const AssignBmProjectForCustomerUserModal = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="link" onClick={showModal} >
                <Tooltip title={`${t("Assign Bm Project")}`}>
                    <SettingOutlined style={{ color: "	darkolivegreen", fontSize: "20px" }} />
                </Tooltip>
            </Button>
            <Modal title={`${t("Assign Bm Project")}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className='admin-model' getContainer={() => document.getElementById("app-layout")} footer={[]}>
                <Spin spinning={props.isLoading || props.isModalLoading}>
                    <div className="admin-component">
                        <Space direction="vertical" size="middle">
                            <CustomerUserAssignBmProjectModal userId={props.userId} customerId={props.customerId} bmProjectsData={props.bmProjectsData} />
                            <CustomerUserAssignBmProjectTable userId={props.userId} customerId={props.customerId} bmProjectsData={props.bmProjectsData} />
                        </Space>
                    </div>
                </Spin>
            </Modal>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.customerUser.isCustomerUserLoading,
        isModalLoading: state.customerUser?.isModalLoading,
    };
};
export default connect(mapStateToProps, null)(AssignBmProjectForCustomerUserModal);