import React, { useEffect, useState } from 'react'
import { ActionTypes } from '../../../../Redux/actionsTypes'
import { ICurvesAll, ICurvesItem } from '../../../../Models/ICurves'
import { connect } from 'react-redux'
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { Button, Form, Input, Space, Spin } from 'antd';
import CurvesTable from './CurvesTable';
import { RulesDescription, RulesName } from '../../Rules/RulesValidation';
import { t } from 'i18next';

interface IProps {
  AllCurves: ICurvesAll[];
  isLoading: boolean;
  isModalLoading: boolean;
  modalResponse: ICommonResponse;
  getAllCurves: () => any;
  createCurve: (model: ICurvesItem) => any;
}
const Curves = (props: IProps) => {
  const [formValues, setFormValues] = useState<ICurvesItem>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => { props.getAllCurves() }, []);
  const curvesData: ICurvesItem[] = props.AllCurves?.map(c => {
    return {
      ...c.curve,
      key: `curve_${c.curve.id}`
    }
  }) ?? [];
  //console.log(curvesData);

  const formValuesHandler = async () => {
    try {
      await form.validateFields();
      const newCurve = { ...formValues };
      await props.createCurve(newCurve);
    } catch (error) {
      console.log(error);
    }
  }
  const closeAndReset = () => {
    setIsModalVisible(false);
    form.resetFields();
  }
  return (
    <Spin spinning={props.isLoading || props.isModalLoading}>
      <div className="admin-component">
        <Space direction="vertical" size="small">
          <ModalAdmin title={`${t("Add")} ${t("New")} ${t("FrontLoading")} ${t("Curve")}`} btnText={`${t("Add")}`}
            width="520px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
              <Button key="back" onClick={closeAndReset}>
                {`${t("Cancel")}`}
              </Button>,
              <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                 {`${t("Save")}`} & {`${t("Send")}`}
              </Button>
            ]}>
            <Form
              layout="vertical"
              form={form}

            >
              <Form.Item label={`${t("English Name")}`} name='nameEn'
                rules={RulesName({ name: `${t("English Name")}`,countChar:128 })}
                hasFeedback>
                <Input onChange={(e) => setFormValues({ ...formValues, nameEn: (e.target.value).trimStart() })} />
              </Form.Item>
              <Form.Item label={`${t("German Name")}`} name='nameDe'
                rules={RulesName({ name: `${t("German Name")}`,countChar:128 })}
                hasFeedback>
                <Input onChange={(e) => setFormValues({ ...formValues, nameDe: (e.target.value).trimStart() })} />
              </Form.Item>
              <Form.Item label={`${t("English Description")}`} name='descriptionEn'
               rules={RulesDescription({name:`${t("English Description")}`,countChar:1500})} 
                hasFeedback>
                <Input.TextArea onChange={(e) => setFormValues({ ...formValues, descriptionEn: (e.target.value).trimStart() })} />
              </Form.Item>
              <Form.Item label={`${t("German Description")}`}  name='descriptionDe'
                rules={RulesDescription({name:`${t("German Description")}`,countChar:1500})} 
                hasFeedback>
                <Input.TextArea onChange={(e) => setFormValues({ ...formValues, descriptionDe: (e.target.value).trimStart() })} />
              </Form.Item>
            </Form>
          </ModalAdmin>
          <CurvesTable curvesData={curvesData} />
        </Space>
      </div>
    </Spin>
  )
}
const mapStateToProps = (state: any) => {
  return {
    AllCurves: state.curves?.curvesResult,
    isLoading: state.curves?.isCurvesLoading,
    isModalLoading: state.curves?.isModalLoading,
    modalResponse: state.curves?.modalResponse
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllCurves: () => dispatch({ type: ActionTypes.GET_ALL_CURVES }),
    createCurve: (model: ICurvesItem) => dispatch({ type: ActionTypes.CREATE_CURVE, model })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Curves);