import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import IndustrySegmentService from "../../Services/IndustrySegmentService";
import {  ISegmentAll } from "../../Models/IIndustrySegment";

function* OnGetIndustrySegments(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER, isLoading: true });
        let _result: ICommonResponse = yield IndustrySegmentService.getIndustrySegments(action.industryId );
        if (!_result.isError) {
            let result = _result.result as ISegmentAll;
            yield put({ type: ActionTypes.SAVE_INDUSTRYSEGMENTS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGetIndustrySegments: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}

function* onCreateIndustrySegment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield IndustrySegmentService.CreateIndustrySegment(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield IndustrySegmentService.getIndustrySegments(action.industryId );
            let result = _result.result as ISegmentAll;
            yield put({ type: ActionTypes.SAVE_INDUSTRYSEGMENTS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result:resultCreate  });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateIndustrySegment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER, isLoading: true });
        let resultUpdate: ICommonResponse = yield IndustrySegmentService.UpdateIndustrySegment(
            action.model
        );
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield IndustrySegmentService.getIndustrySegments(action.industryId );
            let result = _result.result as ISegmentAll;
            yield put({ type: ActionTypes.SAVE_INDUSTRYSEGMENTS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteIndustrySegment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER, isLoading: true });
        let resultDelete: ICommonResponse = yield IndustrySegmentService.DeleteIndustrySegment(
            action.id
        );
        if (!resultDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete});
            let _result: ICommonResponse = yield IndustrySegmentService.getIndustrySegments(action.industryId );
            let result = _result.result as ISegmentAll;
            yield put({ type: ActionTypes.SAVE_INDUSTRYSEGMENTS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_INDUSTRYSEGMENT_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* watchGetIndustrySegments() {
    yield takeEvery(ActionTypes.GET_INDUSTRYSEGMENTS, OnGetIndustrySegments)
}
function* watchCreateIndustrySegment() {
    yield takeEvery(ActionTypes.CREATE_INDUSRTYSEGMENT, onCreateIndustrySegment)
}
function* watchUpdateIndustrySegment() {
    yield takeEvery(ActionTypes.UPDATE_INDUSRTYSEGMENT, onUpdateIndustrySegment)
}
function* watchDeleteIndustrySegment() {
    yield takeEvery(ActionTypes.DELETE_INDUSRTYSEGMENT, onDeleteIndustrySegment)
}
export default function* IndustrySegmentSagas() {
    yield all([
        watchGetIndustrySegments(),
        watchCreateIndustrySegment(),
        watchUpdateIndustrySegment(),
        watchDeleteIndustrySegment()

    ])
}