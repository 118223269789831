import React, { useEffect, useState } from "react";
import { ItemCustomerManagement, ItemCustomerManagementDT } from "../../../Models/ItemAdminTable/Items";
import { connect } from "react-redux";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { Button, Form, Input, Select, Space, Spin, Upload, UploadFile, UploadProps } from "antd";
import CustomerMngTable from "./CustomerMngTable";
import ModalAdmin from "../../../Components/ModalAdmin/ModalAdmins";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import "./index.scss";
import { UploadOutlined } from "@ant-design/icons";
import { RulesName, RulesRequire } from "../Rules/RulesValidation";
import { IListItem } from "../../../Models/Responses/ProfileResponse";
import { t } from "i18next";
interface IProps {
    getAllCustomer: () => any;
    languagesList: IListItem[],
    curruncyList: IListItem[],
    isLoading: boolean;
    customersData: ItemCustomerManagement[];
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    onCreateCustomer: (model: ItemCustomerManagementDT) => any;
}

const CustomerMng = (props: IProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formValues, setFormValues] = useState<ItemCustomerManagementDT>();
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    useEffect(() => { props.getAllCustomer(); }, []);
    const customerDataRow: ItemCustomerManagementDT[] =
        props.customersData?.map((row: any) => {
            return {
                ...row,
                key: `cus_${row.id}`,
                id: row.id,
                defaultLanguageId: row.defaultLanguage.id,
                currencyId: row.currency.id,
            }
        }) ?? [];
    const uploadProps: UploadProps = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            await props.onCreateCustomer({ ...formValues });
            console.log("formValues :", formValues);


        } catch (e) {
            console.log(e);
        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setFileList([]);
        setIsModalVisible(false);
    };
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Customer")}`} btnText={`${t("Add")}`}
                        width="520px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save & Send")}`}
                            </Button>
                        ]}>
                        <Form layout="vertical" form={form}>
                            <Form.Item label="Name" name="name"
                                rules={RulesName({ name: `${t("Customer")} Name`, countChar: 128 })}
                            >
                                <Input onChange={(e) => setFormValues({ ...formValues, name: (e.target.value)?.trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("Location")}`} name="location"
                                rules={RulesName({ name: `${t("Location")}`, countChar: 128 })}
                            >
                                <Input onChange={(e) => setFormValues({ ...formValues, location: (e.target.value)?.trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("Logo")}`} name="logo">
                                <Upload accept="image/*" maxCount={1}  {...uploadProps} onChange={(values) => setFormValues({ ...formValues, logo: values.file })}>
                                    <Button icon={<UploadOutlined />}>{`${t("Click to upload")}`}</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item label={`${t("Language")}`} name="defaultLanguageId" rules={RulesRequire({ filedName: `${t("Language")}` })} style={{ width: '48%', display: "inline-block", marginRight: "2%" }}>
                                <Select showSearch placeholder={`${t("Select Language")}`} onChange={(value: number) => {
                                    setFormValues({ ...formValues, defaultLanguageId: value })
                                }}
                                    getPopupContainer={() => document.getElementById("app-layout")}
                                >
                                    {props.languagesList?.map(l => <Select.Option value={l?.value} key={`lang_${l?.label}`}>{l?.label}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label={`${t("Currency")}`} name="currencyId" rules={RulesRequire({ filedName: `${t("Currency")}` })} style={{ width: '48%', display: "inline-block", marginLeft: "2%" }}>
                                <Select showSearch placeholder={`${t("Select Currency")}`} onChange={(value: number) => {
                                    setFormValues({ ...formValues, currencyId: value })
                                }}
                                    getPopupContainer={() => document.getElementById("app-layout")}
                                >
                                    {props.curruncyList?.map(c => <Select.Option value={c?.value} key={`curr_${c?.label}`}>{c?.label}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Form>

                    </ModalAdmin>
                    <CustomerMngTable customerData={customerDataRow} />
                </Space>
            </div>
        </Spin>
    );
};

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.customerReducer?.isCustomerLoading,
        customersData: state.customerReducer?.responseResult,
        isModalLoading: state.customerReducer?.isModalLoading,
        modalResponse: state.customerReducer?.modalResponse,
        languagesList: state.generalInfo.profileResponse?.generalSettings?.languageList,
        curruncyList: state.generalInfo.profileResponse?.generalSettings?.curruncyList,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllCustomer: () => dispatch({ type: ActionTypes.GET_CUSTOMERS }),
        onCreateCustomer: (model: ItemCustomerManagementDT) =>
            dispatch({
                type: ActionTypes.CREATE_CUSTOMER,
                model: model,
            })
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerMng);
