export interface EditEbitView {
    id: number;
    key: number;
    projectName: string;
    sector: string;
    numEmployees: number;
    sales: number;
    segment: string,
    year: number;
    fERate: string;
    qKAnteil: string;
    innoRate: string;
    hKRate: string;
    coDRate: string;
    ebitRate: string;
    ebitSales: string
}

export const EditEbitFilter = {
    projectName: {
        visible: true
    },
    sector: {
        visible: true
    },
    numEmployees: {
        visible: true
    },
    sales: {
        visible: true
    },
    segment: {
        visible: true
    },
    year: {
        visible: true
    },
    fERate: {
        visible: true
    },
    qKAnteil: {
        visible: true
    },
    innoRate: {
        visible: true
    },
    hKRate: {
        visible: true
    },
    coDRate: {
        visible: true
    },
    ebitRate: {
        visible: true
    },
    ebitSales: {
        visible: true
    },
}