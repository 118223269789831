import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Col, Empty, Row, Select, Space, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IFrontLoadingToolProject } from "../../../../../Models/IFrontLoadingTool";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
import FlTableColumn from "../InputTab/Models/FlTableColumns";
import RTDCurve from "../RTDCurveTab/components/RTDCurve";
import { RTDCurveData } from "../RTDCurveTab/Models/RTDCurveData";

import './projectcomparisontab.scss';

interface IProps {
    active: boolean;
    flProjects: IFrontLoadingToolProject[];
    getRTDCurveData: (flProjectId: number, isCurrentFlProject: boolean) => void;
    currentFrontLoadingProjectId: number;
    RTDCurveData: RTDCurveData;
    RTDCurveDataForComarativeProject: RTDCurveData;
    gettingRTDCurveData: boolean;
    resetRTDCurveDataForComarativeProject: () => void;
};

function ProjectComparisonTab(props: IProps) {

    const [peerFlProjectId, setPeerFlProjectId] = useState<number>(null);
    const [peerFlProjectName, setPeerFlProjectName] = useState<string>(null);
    const [currentFlProjectCols, setCurrentFlProjectCols] = useState<FlTableColumn[]>(null);
    const [peerFlProjectCols, setPeerFlProjectCols] = useState<FlTableColumn[]>(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (props.currentFrontLoadingProjectId)
            props.getRTDCurveData(props.currentFrontLoadingProjectId, true);
        return () => {
            props.resetRTDCurveDataForComarativeProject();
            setPeerFlProjectId(null);
            setPeerFlProjectName(null);
            setCurrentFlProjectCols(null);
            setPeerFlProjectCols(null);
        };
    }, []);

    useEffect(() => {
        if (peerFlProjectId)
            props.getRTDCurveData(peerFlProjectId, false);
    }, [peerFlProjectId]);

    useEffect(() => {
        if (props.RTDCurveData) {
            setCurrentFlProjectCols(
                [new FlTableColumn(
                    <div className="current_flProject_table_title">
                        {props.flProjects.find(p => p.id === props.currentFrontLoadingProjectId)?.name}
                    </div>,
                    "current_flProject_table",
                    {
                        align: "left",
                        DataIndex: "name",
                        render: (text, record, index) => {
                            return (<div key={"current_FlProject_table" + index.toString()} style={{
                                backgroundColor: record["backGroundColor"],
                                color: record["foreGroundColor"],
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <div className="DepartmentSpansDiv">
                                    <span className="DepartmentOrderSpan">
                                        {record['order']}
                                    </span>
                                    <span className="DepartmentNameSpan">
                                        {text}
                                    </span>
                                </div>
                            </div>);
                        },
                    }
                )]
            );
        }
    }, [props.RTDCurveData]);

    useEffect(() => {
        console.log("props.RTDCurveDataForComarativeProject: ", props.RTDCurveDataForComarativeProject);

        if (props.RTDCurveDataForComarativeProject) {
            setPeerFlProjectCols(
                [new FlTableColumn(
                    <div className="current_flProject_table_title">
                        {peerFlProjectName}
                    </div>,
                    "peer_flProject_table",
                    {
                        align: "left",
                        DataIndex: "name",
                        render: (text, record, index) => {
                            return (<div key={"peer_FlProject_table" + index.toString()} style={{
                                backgroundColor: record["backGroundColor"],
                                color: record["foreGroundColor"],
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <div className="DepartmentSpansDiv">
                                    <span className="DepartmentOrderSpan">
                                        {record['order']}
                                    </span>
                                    <span className="DepartmentNameSpan">
                                        {text}
                                    </span>
                                </div>
                            </div>);
                        },
                    }
                )]
            );
        }
    }, [props.RTDCurveDataForComarativeProject]);

    useEffect(() => {
        if (props.RTDCurveDataForComarativeProject) {
            setCurrentFlProjectCols(
                [new FlTableColumn(
                    <div className="current_flProject_table_title">
                        {props.flProjects.find(p => p.id === props.currentFrontLoadingProjectId)?.name}
                    </div>,
                    "peer_flProject_table",
                    {
                        align: "left",
                        DataIndex: "name",
                        render: (text, record, index) => {
                            return (<div style={{
                                backgroundColor: record["backGroundColor"],
                                color: record["foreGroundColor"],
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <div className="DepartmentSpansDiv">
                                    <span className="DepartmentOrderSpan">
                                        {record['order']}
                                    </span>
                                    <span className="DepartmentNameSpan">
                                        {text}
                                    </span>
                                </div>
                            </div>);
                        },
                    }
                )]
            );
        }
    }, [props.RTDCurveDataForComarativeProject]);

    props.flProjects.sort((a, b) => (a.id > b.id) ? 1 : (a.id < b.id) ? -1 : 0);
    let minFlProjectId = Math.min(...props.flProjects?.map(p => p.id));

    const comparativeProjectChanged = (id: number) => {
        setPeerFlProjectName(props.flProjects.find(p => p.id === id)?.name);
        setPeerFlProjectId(id);
    };

    return (
        (props.currentFrontLoadingProjectId) ?
            (props.RTDCurveData == null) ? <Empty /> :
                <div className="comparison_tab_main_div">
                    <Row gutter={24}>
                        <Col className="gutter-row" span={4}>
                            <div className="current_flproject_div" style={{ width: 180 }}>
                                <Spin spinning={props.gettingRTDCurveData && (peerFlProjectId === null)}>
                                    <Table
                                        columns={currentFlProjectCols}
                                        dataSource={props.RTDCurveData?.flDepartments}
                                        pagination={false} />
                                </Spin>
                            </div>

                        </Col>
                        <Col className="gutter-row" span={20} >
                            <div className="figures_div">
                                <div className="dropdown_div">
                                    <Select
                                        disabled={props.gettingRTDCurveData}
                                        size="small"
                                        placeholder="Select peer project"
                                        onChange={comparativeProjectChanged}>
                                        {props.flProjects?.filter(p => p.id !== props.currentFrontLoadingProjectId).map((project, idx) => {
                                            return (
                                                <Select.Option
                                                    key={"project" + project.id.toString()}
                                                    value={project.id}>
                                                    {project.name}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <Spin spinning={props.gettingRTDCurveData && (peerFlProjectId === null)}>
                                    <div className="current_flproject_figure_div">
                                        <RTDCurve showBestPracticeCurve={true} key={`current_flproject_figure_div_${props.RTDCurveData?.flProjectId}`} RTDCurveData={props.RTDCurveData} />
                                    </div>
                                </Spin>
                                <div className="comparative_flproject_figure_div">
                                    {
                                        peerFlProjectId ? <Spin spinning={props.gettingRTDCurveData}>
                                            <div className="peer_flProject_figure_div">
                                                <RTDCurve showBestPracticeCurve={true} key={`peer_flProject_figure_div_${props.RTDCurveDataForComarativeProject?.flProjectId}`} RTDCurveData={props.RTDCurveDataForComarativeProject} />
                                            </div>
                                        </Spin> : null
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div> : <Space direction="vertical" style={{ width: '100%', marginTop: '10vh' }}>
                <Alert
                    message={
                        <div className="alert_body">
                            <div className="warning_title">
                                <WarningOutlined />
                            </div>
                            <div className="warning_text">
                                <span className="warning_text_value">{t("Please, select a front loading project from Overview tab.")}</span>
                            </div>
                        </div>
                    }
                    type="warning" />
            </Space>
    );
};

const mapStateToProps = (state) => {
    return {
        currentFrontLoadingProjectId: state.generalInfo.profileResponse?.currentFrontLoadingProjectId,
        gettingRTDCurveData: state.frontLoadingProjects.gettingRTDCurveData,
        RTDCurveData: state.frontLoadingProjects.RTDCurveData,
        RTDCurveDataForComarativeProject: state.frontLoadingProjects.RTDCurveDataForComarativeProject
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getRTDCurveData: (flProjectId: number, isCurrentFlProject: boolean) =>
            dispatch({ type: ActionTypes.GetRTDCurveData, flProjectId: flProjectId, isCurrentFlProject: isCurrentFlProject }),
        resetRTDCurveDataForComarativeProject: () =>
            dispatch({ type: ActionTypes.SaveRTDCurveData, RTDCurveData: null, isCurrentFlProject: false })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectComparisonTab);