import React, { useState } from 'react'
import { DynamicField, ICompleteBmProject } from '../../../Models/IBMProjectItem';
import ModalAdmin from '../../../Components/ModalAdmin/ModalAdmins';
import { Button, Form, Input, InputNumber, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { t } from 'i18next';
import { RulesDescription } from '../Rules/RulesValidation';
interface IProps {
  isLoading: boolean;
  isModalLoading: boolean;
  modalResponse: ICommonResponse;
  completionBmProject: (model: ICompleteBmProject, customerId: number) => any;
  bmProjectId: number;
  customerId?: number;

}
const CompletionBmProjectModal = (props: IProps) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formValues, setFormValues] = useState<ICompleteBmProject>();
  const closeAndReset = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormValues({} as ICompleteBmProject);
  }
  const formValuesHandler = async () => {
    try {
      await form.validateFields();
      let dynamicFields: DynamicField[] = form.getFieldsValue(['dynamicFields'])?.dynamicFields;
      const completion: ICompleteBmProject = { ...formValues, dynamicFields: dynamicFields, bmProjectId: props.bmProjectId };
      await props.completionBmProject(completion, props?.customerId ?? null);
    } catch (error) {
      console.log(error);
    }

  }

  return (
    <ModalAdmin title={`${t("Completion BmProject")}`} btnText={`${t("Make Complete")}`}
      width="520px"
      modalResponse={props.modalResponse}
      closeAndReset={closeAndReset}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      footerchildren={[
        <Button key="back" onClick={closeAndReset}>
          {`${t("Cancel")}`}
        </Button>,
        <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
          {`${t("Save")}`} & {`${t("Send")}`}
        </Button>
      ]}>
      <Form
        layout="vertical"
        form={form}
        name="dynamic_form_complex"
      >
        <Form.Item label={`${t("Project Result")}`} name='projectResult' rules={RulesDescription({ name: `${t("Project Result")}`, countChar: 256 })}>
          <Input onChange={(e) => setFormValues({ ...formValues, projectResult: (e.target.value)?.trimStart() })} />
        </Form.Item>
        <Form.Item label={`${t("Project Implementation")}`} name='projectImplementation' rules={RulesDescription({ name: `${t("Project Implementation")}`, countChar: 256 })}>
          <Input onChange={(e) => setFormValues({ ...formValues, projectImplementation: (e.target.value)?.trimStart() })} />
        </Form.Item>
        <Form.Item label={`${t("Tool Support")}`} name='toolSupport' rules={RulesDescription({ name: `${t("Tool Support")}`, countChar: 256 })}>
          <Input onChange={(e) => setFormValues({ ...formValues, toolSupport: (e.target.value)?.trimStart() })} />
        </Form.Item>
        <Form.Item label={`${t("Customer Cooperation")}`} name='customerCooperation' rules={RulesDescription({ name: `${t("Customer Cooperation")}`, countChar: 256 })}>
          <Input onChange={(e) => setFormValues({ ...formValues, customerCooperation: (e.target.value)?.trimStart() })} />
        </Form.Item>
        <Form.Item label={`${t("Lessons Learned")}`} name='lessonsLearned' rules={RulesDescription({ name: `${t("Lessons Learned")}`, countChar: 256 })}>
          <Input onChange={(e) => setFormValues({ ...formValues, lessonsLearned: (e.target.value)?.trimStart() })} />
        </Form.Item>
        <Form.Item label={`${t("Evaluation Rate")}`} name="evaluationRate" rules={[
          { required: true, message: '' },
          () => ({
            validator(_, value) {
              if (!(typeof (formValues?.evaluationRate) === 'number') || formValues?.evaluationRate <= 0 || formValues?.evaluationRate > 10) {
                return Promise.reject(`${t("Evaluation Rate value must be between 1 and 10")}`);
              }
              return Promise.resolve();
            }
          }),
        ]}>
          <InputNumber onChange={async (value: number) => {
            setFormValues({ ...formValues, evaluationRate: value })
            form.validateFields();
          }}
          />
        </Form.Item>
        <Form.List name="dynamicFields">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'fieldLabel']}
                    rules={[{ required: true, message: `${t("Missing Field Label")}` },
                    { max: 50, message: `${t("Field Label")} ${t("must be less than 50 characters.")}` }

                    ]}
                  >
                    <Input placeholder={`${t("Field Label")}`} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'fieldValue']}
                    rules={[{ required: true, message: `${t("Missing Field Value")}` },
                    { max: 50, message: `${t("Field Value")} ${t("must be less than 50 characters.")}` }
                    ]}
                  >
                    <Input placeholder={`${t("Field Value")}`} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {`${t("Add field")}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </ModalAdmin >
  )
}
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.bmProjects.isBmProjectLoading,
    isModalLoading: state.bmProjects.isModalLoading,
    modalResponse: state.bmProjects.modalResponse,
  };
}; const mapDispatchToProps = (dispatch: any) => {
  return {
    completionBmProject: (model: ICompleteBmProject, customerId: number) => dispatch({ type: ActionTypes.COMPLETE_BM_PROJECT, model: model, customerId }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletionBmProjectModal);