import { Dropdown, Menu, Popover, Space } from 'antd';
import { SettingOutlined } from "@ant-design/icons";
import './coloptionsdrop.scss';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { tablesFilters } from '../../../../Services/tablesFilters';
import { EditEbitFilter } from '../DataTypes/DataTypes';
import { t } from 'i18next';



interface IProps {
    tableColsProps: typeof EditEbitFilter[],
    updateFilter: (colName: string) => void
};


function ColOptionsDrop(props: IProps) {

    const hideOrShowCol = (e: CheckboxChangeEvent, colName: string) => {
        //console.log('colName: ', colName);

        props.updateFilter(colName);
    };

    const menu = (
        <Menu className='menu_class'
            items={Object.keys(props.tableColsProps).map((col, idx) => {
                return (
                    {
                        label: <Checkbox checked={props.tableColsProps[col].visible} onChange={(e: CheckboxChangeEvent) => hideOrShowCol(e, col)}>{col}</Checkbox>,
                        key: idx
                    }
                );
            })}
        />
    );

    const App = <div className="col_options_wrapper">
        <Popover getPopupContainer={() => document.getElementById('app-layout')} className='columns-popover' content={menu} trigger="click" placement="bottom">
            <a href="#">
                <Space>
                    <div className='BuildRoundedIcon_div'><SettingOutlined /></div>
                    <span className='col_options_span'>
                        {`${t("Column options")}`}
                    </span>
                </Space>
            </a>
        </Popover>
    </div>;
    {/* <Dropdown overlay={menu} trigger={['click']} overlayClassName='drop_down_class'>
        <a onClick={e => e.preventDefault()}>
            <Space>
                <div className='BuildRoundedIcon_div'><SettingOutlined /></div>
                <span className='col_options_span'>
                    {`${t("Column options")}`}
                </span>
            </Space>
        </a>
    </Dropdown>; */}

    return App;

}


const mapStateToProps = (state: any) => {
    return {
        tableColsProps: state.tablesColsProps.editEbitTableColsProps
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateFilter: (colName: string) => {
            //console.log('updateFilter: ', { type: ActionTypes.UPDATEFILTER, filter: tablesFilters.editLeversTableFilter, colName: colName });
            dispatch({ type: ActionTypes.UPDATEFILTER, filter: tablesFilters.editEbitTableFilter, colName: colName })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ColOptionsDrop);