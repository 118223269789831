import React, { useRef, useState } from 'react';
import { IFrontLoadingTemp, IFrontLoadingTempAllWithKey } from '../../../../Models/IFrontLoading';
import {  Form, Input, Table  } from 'antd';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import FrontLoadingTempPhaseModal from '../FromtLoadingTempPhaseModal';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../../Components/SearchButton';
import { RulesName } from '../../Rules/RulesValidation';
import AdminOperation from '../../../../Components/AdminOperation/AdminOperation';
import { t } from 'i18next';



interface IProps {
    frontLoadingTempData: IFrontLoadingTempAllWithKey[],
    updateFrontLoadingTemp: (model: IFrontLoadingTemp) => any;
    deleteFrontLoadingTemp: (id: number) => any;
    //curvesData: any[];
}
const FrontLoadingTable = (props: IProps) => {
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "nameEn_1",
            title:`${t("English Name")}`,
            dataIndex: 'nameEn',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='nameEn'
                            rules={RulesName({ name: `${t("English Name")}`,countChar:128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("English Name")}`,'nameEn', searchInput, handleSearch),
            sorter: (a, b) => a?.nameEn?.localeCompare(b?.nameEn) ?? 0,
            defaultSortOrder: 'ascend',
        },
        {
            key: "nameDe_2",
            title: `${t("German Name")}`,
            dataIndex: 'nameDe',
            render: (text: any, record: any) => {
                if (editingKey === record.key) {
                    return (
                        <Form.Item name='nameDe'
                            rules={RulesName({ name: `${t("German Name")}`,countChar:128 })}
                        >
                            <Input />
                        </Form.Item>
                    )
                } else {
                    return <p>{text}</p>
                }
            },
            ...getColumnSearchProps(`${t("German Name")}`,'nameDe', searchInput, handleSearch),
            sorter: (a, b) => a?.nameDe?.localeCompare(b?.nameEn) ?? 0,
            // defaultSortOrder: 'ascend',
        }, 
        {
            title:`${t("Actions")}`,
            dataIndex: '',
            width: '100px',
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                return <AdminOperation
                deleteId={record.id}
                record={record}
                editable={editable}
                onCancel={cancel}
                onEdit={edit}
                onDelete={deleteTemp}
            />

            },
        },
        {
            title:`${t("Phases")}`,
            dataIndex: "",
            render: (_any, record: any) => {
                return <FrontLoadingTempPhaseModal phasesData={record.phases} templateId={record.id} />
            }

        }

    ];
    const isEditing = (record: IFrontLoadingTemp) => record.key === editingKey;
    const edit = (record: Partial<IFrontLoadingTemp> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            const index = props?.frontLoadingTempData.findIndex(item => item.key === editingKey);
            if (index > -1) {
                const item = props?.frontLoadingTempData[index];
                const frontLoadingTempUpdate: IFrontLoadingTemp = { id: item.id, ...values };
                await props.updateFrontLoadingTemp(frontLoadingTempUpdate);
                console.log(frontLoadingTempUpdate);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }

    }
    const deleteTemp = async (id: any) => {
        try {
            await props.deleteFrontLoadingTemp(id);
        } catch (error) {
            console.log('delete Failed:', error);
        }

    }


    return (
        <Form form={form} key="ind_form" onFinish={onFinish}>
            <Table
                key='ind_table'
                className='table_seg'
                bordered
                getPopupContainer={() => document.getElementById("app-layout")}
                dataSource={props?.frontLoadingTempData}
                columns={columns}
                rowClassName="editable-row"
                scroll={{ y: "calc(100vh - 300px)" }}
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 150],
                    total: props?.frontLoadingTempData.length,
                    showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                    showSizeChanger: true,
                    locale:{items_per_page:`/ ${t("Page")}`},
                }}
                locale={{
                    triggerDesc: t('Click to sort descending'),
                    triggerAsc: t('Click to sort ascending'),
                    cancelSort: t('Click to cancel sorting')
                }}
            />
        </Form>
    );
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateFrontLoadingTemp: (model: IFrontLoadingTemp) => dispatch({ type: ActionTypes.UPDATE_FRONT_LOADING_TEMP, model: model }),
        deleteFrontLoadingTemp: (id: number) => dispatch({ type: ActionTypes.DELETE_FRONT_LOADING_TEMP, id })
    };
};

export default connect(null, mapDispatchToProps)(FrontLoadingTable);
