import { Button, Checkbox, Form, Input, Space, Spin, Table, Tabs } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import AppAvatar from "../../../Components/AppAvatar";
import EditableDataTable from "../../../Components/EditableDataTable";
import ModalComponent from "../../../Components/ModalComponent";
import { ISavePermissionsDto } from "../../../Models/Permissions/PermissionsDto";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IGeneralState, IPermissionsState } from "../../../Redux/reducers/IGeneralState";
import "./index.scss";
import PermissionsTable from "./widgets/PermissionsTable";
import SuperadminTable from "./widgets/SuperadminTable";
import { t } from "i18next";
 
 
interface IProps {
    viewState: IPermissionsState;
    getDetails: () => any;
    saveAnswers: (model: ISavePermissionsDto) => any;
}
function Permissions(props: IProps) {
    useEffect(() => {
        props.getDetails();
    }, []);

    const onSaveAll = () => {
        let model: ISavePermissionsDto = {
            adminPermissions: props.viewState?.viewResult?.adminPermissions,
            permissionPerProjects: props.viewState?.viewResult?.permissionPerProjects,
            superAdmins: props.viewState?.viewResult?.allEmployees.filter(c=>c.isSuperAdmin).map(c=>{ return c.userId; })
        }
        props.saveAnswers(model);
    }
    const operations = <Space size={16} align="center">
        <Button type="primary" onClick={onSaveAll} >{`${t("Save")}`}</Button>
    </Space>;
    const items = [
        { label: t('Consultant Tools'), key: 'consultant-tab', children: <PermissionsTable isForAdmin={false} key={'consultant'} /> },
        { label: t('Administrator Pages'), key: 'admins-tab', children: <PermissionsTable isForAdmin={true} key={'admin'} /> },
        { label: t('Superusers'), key: 'superadmin-tab', children: <SuperadminTable key={'superadmin'} /> }
    ];
    return <Spin spinning={props.viewState.isLoading}>
        <Tabs tabBarExtraContent={operations} items={items} />
    </Spin>;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        viewState: state.permissions,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getDetails: () => dispatch({ type: ActionTypes.GetPermissions }),
        saveAnswers: (model: ISavePermissionsDto) => dispatch({ type: ActionTypes.SavePermissions, model: model }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);