import {
  RollbackOutlined,
  DownloadOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { Button, Popover, Switch } from "antd";
import React, { useEffect, useState } from "react";
import HeatMap from "react-heatmap-grid";
import {
  IKpiDegree,
  ILeverQuestion,
  ISucessFactor,
} from "../../../Models/Levers/Items";
import { BmProject } from "../../../Models/Responses/ProfileResponse";
import AsupBarChart_Ant from "../AsupBarChart/AsupBarChart_Ant";
import "./heatmap.scss";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import exportIcon from "../../../assets/Images/export-xlsx-icon.svg";
import { connect } from "react-redux";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { formatValue } from "../../../Utils/helperFunctions";
import { t } from "i18next";
import { HeatMapQEFColorsLimits } from "../../../Enums/HeatMapQEFColorsLimits";
import { HeatMapColors } from "../../../Enums/HeatMapColors";
import { DownloadChartAsImage as _DownloadChartAsImage } from "../../../Utils/helperFunctions";

interface IRechartData {
  name: string;
  value: number;
  isTarget: boolean;
  isComplete: boolean;
}

interface ChartDetails {
  leverLevel: number;
  leverId: number;
  leverCode: string;

  frageKPIId?: string;
  frageEFValue?: number;

  chartTitle: string;
  chartType: string;
  chartDescription: string;
  chartUnit: string;
  isPercentChart?: boolean;
  bestPracticeMaxValue?: number;
  bestPracticeMinValue?: number;
}
interface ChartDetailsAndValues {
  chartDetails: ChartDetails;
  chartValues: IRechartData[];
}

interface Iprops {
  //data: IBMProjectData;
  withCharts: boolean;
  project: BmProject;
  width?: number;
  height?: number;
  isHeatMapIcon?: boolean;
  isHeatMapLarge?: boolean;
  exportHeatMap: (fileName: string) => void;
}

interface FrageParentKPIAncesstorEF {
  frage: ILeverQuestion | undefined;
  kpi: IKpiDegree | undefined;
  ef: ISucessFactor | undefined;
}

const getFrageParentKPIAncesstorEF = (
  project: BmProject
): FrageParentKPIAncesstorEF[][] => {
  if (!project || project.leversData?.length === 0) return [];
  let FrageParentKPIAncesstorEFArr: FrageParentKPIAncesstorEF[][] = [];
  project?.leversData?.forEach((lever, idx) => {
    let leverFrageParentKPIAncesstorEF: FrageParentKPIAncesstorEF[] = [];
    lever.subLevers.forEach((subLever, idx) => {
      subLever.successFactors.forEach((successFactor, idx) => {
        successFactor.kpiLevels.forEach((kpi, idx) => {
          kpi.questions.forEach((question, idx) => {
            leverFrageParentKPIAncesstorEF.push({
              frage: question,
              kpi: kpi,
              ef: successFactor,
            });
          });
        });
      });
    });
    FrageParentKPIAncesstorEFArr.push(leverFrageParentKPIAncesstorEF);
  });
  return FrageParentKPIAncesstorEFArr;
};

type Borders = {
  borderTop: string;
  borderRight: string;
  borderBottom: string;
  borderLeft: string;
};

function HeatMapChart(props: Iprops) {
  const [withCharts, setWithCharts] = useState(false);

  let FrageParentKPIAncesstorEF: FrageParentKPIAncesstorEF[][] =
    getFrageParentKPIAncesstorEF(props.project);

  //console.log('HeatMapChart-project: ', props.project);

  //console.log('HeatMapChart-FrageParentKPIAncesstorEF: ', FrageParentKPIAncesstorEF);

  // useEffect(() => {
  //   //console.log('chartsDetailsAndValues useEffect: ', chartsDetailsAndValues);

  // }, []);

  let firstleverFrage: FrageParentKPIAncesstorEF[] = [];
  let secondLeverFrage: FrageParentKPIAncesstorEF[] = [];
  let thirdLeverFrage: FrageParentKPIAncesstorEF[] = [];
  let fourthLeverFrage: FrageParentKPIAncesstorEF[] = [];
  let fifthLeverFrage: FrageParentKPIAncesstorEF[] = [];
  if (FrageParentKPIAncesstorEF) {
    firstleverFrage = FrageParentKPIAncesstorEF[0]
      ? [
          ...FrageParentKPIAncesstorEF[0].slice(0, 7),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[0].slice(7, 16),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[0].slice(16, 25),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[0].slice(25),
        ]
      : [];
    secondLeverFrage = FrageParentKPIAncesstorEF[1]
      ? [
          ...FrageParentKPIAncesstorEF[1].slice(0, 3),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[1].slice(3),
        ]
      : [];
    thirdLeverFrage = FrageParentKPIAncesstorEF[2]
      ? [
          ...FrageParentKPIAncesstorEF[2].slice(0, 17),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[2].slice(17),
        ]
      : [];
    fourthLeverFrage = FrageParentKPIAncesstorEF[3]
      ? [
          ...FrageParentKPIAncesstorEF[3].slice(0, 7),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[3].slice(7, 25),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[3].slice(25),
        ]
      : [];
    fifthLeverFrage = FrageParentKPIAncesstorEF[4]
      ? [
          ...FrageParentKPIAncesstorEF[4].slice(0, 4),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[4].slice(4, 10),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[4].slice(10, 29),
          {
            ef: undefined,
            frage: undefined,
            kpi: undefined,
          },
          ...FrageParentKPIAncesstorEF[4].slice(29),
        ]
      : [];
  }

  let leversFragesLengths = [
    Math.floor(firstleverFrage.length / 3),
    Math.floor(secondLeverFrage.length / 2),
    Math.floor(thirdLeverFrage.length / 3),
    Math.floor(fourthLeverFrage.length / 3),
    Math.floor(fifthLeverFrage.length / 3),
  ];
  let longestLeversFrages = Math.max(...leversFragesLengths);
  //
  const yLabels = new Array(longestLeversFrages)
    .fill(0)
    .map((_, i) => `${i}`)
    .sort((a, b) => {
      return parseInt(a) - parseInt(b);
    });
  const xLabels = new Array(14).fill(0).map((_, i) => `${i}`);

  let heatmapData = [];
  yLabels.forEach((y, idx) => {
    let heatmapDataRow = [];
    xLabels.forEach((x, idx) => {
      switch (x) {
        // firstleverFrage
        case "0":
          if (3 * parseInt(y) < firstleverFrage.length) {
            let frageParentKpiAncestorEf = firstleverFrage[3 * parseInt(y)];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[0] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[0] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "1":
          if (3 * parseInt(y) + 1 < firstleverFrage.length) {
            let frageParentKpiAncestorEf = firstleverFrage[3 * parseInt(y) + 1];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[1] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[1] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "2":
          if (3 * parseInt(y) + 2 < firstleverFrage.length) {
            let frageParentKpiAncestorEf = firstleverFrage[3 * parseInt(y) + 2];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[2] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[2] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        // secondLeverFrage
        case "3":
          if (2 * parseInt(y) < secondLeverFrage.length) {
            let frageParentKpiAncestorEf = secondLeverFrage[2 * parseInt(y)];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[3] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[3] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "4":
          if (2 * parseInt(y) + 1 < secondLeverFrage.length) {
            let frageParentKpiAncestorEf =
              secondLeverFrage[2 * parseInt(y) + 1];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[4] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[4] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        // thirdLeverFrage
        case "5":
          if (3 * parseInt(y) < thirdLeverFrage.length) {
            let frageParentKpiAncestorEf = thirdLeverFrage[3 * parseInt(y)];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[5] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[5] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "6":
          if (3 * parseInt(y) + 1 < thirdLeverFrage.length) {
            let frageParentKpiAncestorEf = thirdLeverFrage[3 * parseInt(y) + 1];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[6] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[6] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "7":
          if (3 * parseInt(y) + 2 < thirdLeverFrage.length) {
            let frageParentKpiAncestorEf = thirdLeverFrage[3 * parseInt(y) + 2];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[7] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[7] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        // fourthLeverFrage
        case "8":
          if (3 * parseInt(y) < fourthLeverFrage.length) {
            let frageParentKpiAncestorEf = fourthLeverFrage[3 * parseInt(y)];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[8] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[8] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "9":
          if (3 * parseInt(y) + 1 < fourthLeverFrage.length) {
            let frageParentKpiAncestorEf =
              fourthLeverFrage[3 * parseInt(y) + 1];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[9] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[9] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "10":
          if (3 * parseInt(y) + 2 < fourthLeverFrage.length) {
            let frageParentKpiAncestorEf =
              fourthLeverFrage[3 * parseInt(y) + 2];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[10] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[10] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        // fifthLeverFrage
        case "11":
          if (3 * parseInt(y) < fifthLeverFrage.length) {
            let frageParentKpiAncestorEf = fifthLeverFrage[3 * parseInt(y)];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[11] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[11] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "12":
          if (3 * parseInt(y) + 1 < fifthLeverFrage.length) {
            let frageParentKpiAncestorEf = fifthLeverFrage[3 * parseInt(y) + 1];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[12] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[12] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        case "13":
          if (3 * parseInt(y) + 2 < fifthLeverFrage.length) {
            let frageParentKpiAncestorEf = fifthLeverFrage[3 * parseInt(y) + 2];
            let frageKPI_id = frageParentKpiAncestorEf.kpi?.number;
            let frageEF_value = frageParentKpiAncestorEf.ef?.kpiValue;
            heatmapDataRow[13] = {
              frageId: frageParentKpiAncestorEf.frage?.code,
              frageKPI_id: frageKPI_id,
              frageEF_value: frageEF_value,
            };
          } else {
            heatmapDataRow[13] = { frageKPI_id: "-666", frageEF_value: -666 };
          }
          break;
        default:
          break;
      }
    });
    heatmapData.push(heatmapDataRow);
  });

  //console.log('heatmapData: ', heatmapData);

  const [chartsDetailsAndValues, setChartsDetailsAndValues] = useState(
    heatmapData.map((row) => new Array(11).fill(null))
  );
  //let chartsDetailsAndValues = heatmapData.map(row => new Array(11).fill(null));

  //console.log('chartsDetailsAndValues array: ', chartsDetailsAndValues);

  // Display only even labels
  const xLabelsVisibility = new Array(24)
    .fill(0)
    .map((_, i) => (i % 2 === 0 ? true : false));

  //const yLabels = ["Sun", "Mon", "Tue"];
  // const data = new Array(yLabels.length)
  //     .fill(0)
  //     .map(() =>
  //         new Array(xLabels.length).fill(0).map((val, idx) => {
  //             return Math.floor(Math.random() * 100);
  //         })
  //     );

  const clickHandle = () => {
    //alert("clicked");
  };

  const switchChart = () => {
    setWithCharts(!withCharts);
  };

  const DownloadChartAsImage = (subTitle) => {
    const canvasSave = document.getElementById(subTitle);
    // html2canvas(canvasSave)
    //   .then((canvas) => {
    //     canvas.toBlob(function (blob) {
    //       saveAs(blob, `${props.project.project_name}_HeatMap_${Date.now().toString()}.png`)
    //     }, 'png', 1)
    //   })
    _DownloadChartAsImage(
      `${props.project.project_name}_HeatMap_${Date.now().toString()}.png`,
      canvasSave
    );
  };

  const recieveChartDetailsAndValuesUp = (
    chartDetailsAndValues: ChartDetailsAndValues
  ) => {
    let found = false;
    for (let i = 0; i < chartsDetailsAndValues.length; i++) {
      for (let j = 0; j < chartsDetailsAndValues[0].length; j++) {
        if (
          heatmapData[i][j].frageId ===
          chartDetailsAndValues.chartDetails.leverCode
        ) {
          chartDetailsAndValues.chartDetails.frageKPIId =
            heatmapData[i][j].frageKPI_id;
          chartDetailsAndValues.chartDetails.frageEFValue =
            heatmapData[i][j].frageEF_value;

          //chartsDetailsAndValues[i][j] = chartDetailsAndValues;
          setChartsDetailsAndValues((chartsDetailsAndValues) => {
            return chartsDetailsAndValues.map((row, rowIdx) =>
              row.map((c, columnIdx) => {
                if (rowIdx === i && columnIdx === j)
                  return chartDetailsAndValues;
                return c;
              })
            );
          });
          found = true;
          return;
        }
      }
      if (found) return;
    }
  };

  const exportHeatMapToExcel = () => {
    //props.exportHeatMap(chartsDetailsAndValues, `${props.project.project_name}_HeatMap_${Date.now().toString()}`);
    props.exportHeatMap(
      `${props.project.project_name}_HeatMap_${Date.now().toString()}`
    );
  };

  const border = (x: number, y: number): Borders => {
    let borders: Borders = {
      borderTop: "",
      borderRight: "",
      borderBottom: "",
      borderLeft: "",
    };
    if (x === 2 || x === 4 || x === 7 || x === 10 || x === 13)
      borders = { ...borders, borderRight: "2px solid black" };
    if (x === 0) borders = { ...borders, borderLeft: "2px solid black" };
    if (y === 0) borders = { ...borders, borderTop: "2px solid black" };
    if (y === longestLeversFrages - 1)
      borders = { ...borders, borderBottom: "2px solid black" };
    return borders;
  };

  return (
    <div className={`${props.isHeatMapIcon ? "" : "fullheatmap-container"}`}>
      {!props.isHeatMapIcon ? (
        <div style={{ marginBottom: "5px" }} className="chartControlsDiv">
          <div className="downloadall-div">
            <Button
              className="chart-download-Btn"
              icon={<FileExcelOutlined style={{ margin: "auto" }} />}
              onClick={exportHeatMapToExcel}
              type="default"
              title={`${t("Export HeatMap to excel.")}`}
              size="small"
            />
          </div>

          <div className="downloadall-div">
            <Button
              className="chart-download-Btn"
              icon={<DownloadOutlined style={{ margin: "auto" }} />}
              onClick={() => DownloadChartAsImage("heatmap_container")}
              type="default"
              title={`${t("Download chart")}`}
              size="small"
            />
          </div>
          <div className="downloadall-div">
            <Switch
              checkedChildren={`${t("To charts")}`} //{<div className='To-charts'>To charts</div>}
              unCheckedChildren={`${t("To serials")}`} //{<div className='To-serials'>To serials</div>}
              // style={{ 'zIndex': 100000 }}
              checked={withCharts}
              onChange={switchChart}
              size="small"
              title={
                withCharts
                  ? `${t("Switch to charts view")}`
                  : `${t("Switch to serials view")}`
              }
            />
          </div>
        </div>
      ) : null}

      <div id="heatmap_container" className="heatmap_container">
        {props.isHeatMapIcon ? null : (
          <div className="levers_container">
            <div
              className="strategie"
              style={{
                width: (3 * Math.ceil(props.width / 11) - 1).toString() + "px",
              }}
            >{`1. ${t("Strategy").toUpperCase()}`}</div>

            <div
              className="process"
              style={{
                width: (2 * Math.ceil(props.width / 11) - 1).toString() + "px",
              }}
            >{`2. ${t("Process").toUpperCase()}`}</div>

            <div
              className="project_management"
              style={{
                width: (3 * Math.ceil(props.width / 11)).toString() + "px",
              }}
            >{`3. ${t("Project Management").toUpperCase()}`}</div>

            <div
              className="structure"
              style={{
                width: (3 * Math.ceil(props.width / 11)).toString() + "px",
              }}
            >{`4. ${t("Structure").toUpperCase()}`}</div>

            <div
              className="people"
              style={{
                width: (3 * Math.ceil(props.width / 11) - 2).toString() + "px",
              }}
            >{`5. ${t("People").toUpperCase()}`}</div>
          </div>
        )}
        <div id="heatmap_div" className="heatmap_div">
          <HeatMap
            xLabels={xLabels}
            yLabels={yLabels}
            displayYLabels={false}
            xLabelsLocation={""}
            //xLabelsVisibility={[false, false, false, false, false, false, false, false, false, false, false]}
            xLabelWidth={0}
            yLabelWidth={0}
            data={heatmapData}
            squares
            //onClick={(x, y) => alert(`Clicked ${x}, ${y}`)}
            cellStyle={(
              background,
              value: {
                frageId: string;
                frageKPI_id: string;
                frageEF_value: number;
              },
              min,
              max,
              data,
              x,
              y
            ) => {
              //min: min value in flat data array, max: max value in flat data array, value: current data value
              let color: string = "";
              if (
                HeatMapQEFColorsLimits.EF_Red_L <= value.frageEF_value &&
                value.frageEF_value < HeatMapQEFColorsLimits.EF_Red_H
              ) {
                color = HeatMapColors.red; //red
              } else if (
                HeatMapQEFColorsLimits.EF_Orange_L <= value.frageEF_value &&
                value.frageEF_value < HeatMapQEFColorsLimits.EF_Orange_H
              ) {
                color = HeatMapColors.orange; //orange
              } else if (
                HeatMapQEFColorsLimits.EF_Yellow_L <= value.frageEF_value &&
                value.frageEF_value < HeatMapQEFColorsLimits.EF_Yellow_H
              ) {
                color = HeatMapColors.yellow; //yellow
              } else if (
                HeatMapQEFColorsLimits.EF_Chartreuse_L <= value.frageEF_value &&
                value.frageEF_value < HeatMapQEFColorsLimits.EF_Chartreuse_H
              ) {
                color = HeatMapColors.Chartreuse; //Chartreuse
              } else if (
                HeatMapQEFColorsLimits.EF_Green_L <= value.frageEF_value
              ) {
                color = HeatMapColors.green; //green
              } else if (value.frageEF_value === undefined) {
                color = HeatMapColors.gray;
              }

              return {
                background: color,
                //fontSize: "11px",
                title: "",
                width: Math.ceil(props.width / 11),
                height: Math.ceil(props.height / longestLeversFrages),
                textAlign: "center",
                justifyContent: "center",
                zIndex: "0",
                ...border(x, y),
              };
            }}
            cellRender={(value: {
              frageId: string;
              frageKPI_id: string;
              frageEF_value: number;
            }) => {
              let color: string = "";
              if (
                HeatMapQEFColorsLimits.EF_Red_L <= value.frageEF_value &&
                value.frageEF_value < HeatMapQEFColorsLimits.EF_Red_H
              ) {
                color = HeatMapColors.red; //red
              } else if (
                HeatMapQEFColorsLimits.EF_Orange_L <= value.frageEF_value &&
                value.frageEF_value < HeatMapQEFColorsLimits.EF_Orange_H
              ) {
                color = HeatMapColors.orange; //orange
              } else if (
                HeatMapQEFColorsLimits.EF_Yellow_L <= value.frageEF_value &&
                value.frageEF_value < HeatMapQEFColorsLimits.EF_Yellow_H
              ) {
                color = HeatMapColors.yellow; //yellow
              } else if (
                HeatMapQEFColorsLimits.EF_Chartreuse_L <= value.frageEF_value &&
                value.frageEF_value < HeatMapQEFColorsLimits.EF_Chartreuse_H
              ) {
                color = HeatMapColors.Chartreuse; //Chartreuse
              } else if (
                HeatMapQEFColorsLimits.EF_Green_L <= value.frageEF_value
              ) {
                color = HeatMapColors.green; //green
              }

              let cell =
                value.frageKPI_id !== "-666" &&
                value.frageKPI_id !== undefined ? (
                  props.isHeatMapIcon ? null : withCharts ? (
                    <div>
                      <div
                        className="div1"
                        style={{
                          margin: "0px",
                          padding: "0px",
                          lineHeight: "10px",
                          textAlign: "center",
                        }}
                      >
                        {/* //lineHeight controls hieght of heatmap */}
                        <p
                          style={{
                            margin: "5px",
                            padding: "0px",
                            fontSize: "12px",
                          }}
                        >
                          {value.frageKPI_id}
                        </p>

                        <p
                          style={{
                            margin: "5px",
                            padding: "0px",
                            fontSize: "12px",
                          }}
                        >
                          {formatValue(value.frageEF_value)}%
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="HeatMap-cell">
                      <Popover
                        getPopupContainer={() =>
                          document.getElementById("app-layout")
                        }
                        placement="rightBottom"
                        content={
                          <div
                            style={{ backgroundColor: color }}
                            className="outHeatMapCellBarChart"
                          >
                            <AsupBarChart_Ant
                              leverNumber={value.frageId}
                              width={600}
                              height={400}
                              showChartDetails={true}
                              showChartControls={false}
                              backgroundColor={color}
                            />
                          </div>
                        }
                        trigger="hover"
                      >
                        <div
                          className="inHeatMapCellBarChart"
                          style={{ zIndex: 10000 }}
                        >
                          <AsupBarChart_Ant
                            leverNumber={value.frageId}
                            inHeatMapCell={true}
                            width={Math.ceil(props.width / 11)}
                            height={Math.ceil(
                              props.height / longestLeversFrages
                            )}
                            showChartDetails={false}
                            showChartControls={false}
                            informChartDetailsAndValuesUp={
                              recieveChartDetailsAndValuesUp
                            }
                          />
                        </div>
                      </Popover>
                    </div>
                  )
                ) : null;
              return cell;
            }}
            title={(value, unit) => ""}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    exportHeatMap: (fileName: string) =>
      dispatch({ type: ActionTypes.ExportHeatMap, fileName: fileName }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeatMapChart);
