import { Alert, Button, Checkbox, Form, Input, Select, Space, Tag } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { ICustomerPersonToUser } from '../../../Models/ICustomerUsers';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import ModalAdmin from '../../../Components/ModalAdmin/ModalAdmins';
import { IBmProjectList } from '../../../Models/IBmProjectList';
import { UserAddOutlined } from "@ant-design/icons";
import { RulesRequire } from '../Rules/RulesValidation';
import { t } from 'i18next';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

interface IProps {
    personId: number;
    customerId: number;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    bmProjectList: IBmProjectList[];
    collectionRoles: any[];
    email: string;
    makeCustomerPersonToUser: (model: ICustomerPersonToUser, id: number) => any;
}
const CustomerPersonMakeUser = (props: IProps) => {
    const [formValues, setFormValues] = useState<ICustomerPersonToUser>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const makeCustomerUser: ICustomerPersonToUser = {
                personId: props.personId,
                ...formValues
            }
            props.makeCustomerPersonToUser(makeCustomerUser, props.customerId);

        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <ModalAdmin title={`${t("Make Customer Person To User")}`} btnText={<UserAddOutlined style={{ color: "teal", fontSize: "20px" }} />}
            titleTooltip={`${t("Make Person to User")}`}
            width="520px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save")}`} & {`${t("Send")}`}
                </Button>
            ]}>
            <Space direction="vertical" className='per_make_user'>
                <Alert message={<>{`${t("Activation link will be sent to this E-Mail")}`}<span style={{ color: "#84a5f4", fontWeight: "600" }}>{` ${props.email} `}</span>
                    <br />{`${t("Please select bm project and roles on it.")}`}</>} type="info" />
                <Form layout="vertical" form={form}>
                    <Form.Item label={`${t("Bm Project")}`} name='bmProjectId' rules={RulesRequire({ filedName: 'BmProject' })}  >
                        <Select showSearch placeholder={`${t("Select BmProject")}`} optionFilterProp="children"
                            onChange={(value: number) => setFormValues({ ...formValues, bmProjectId: value })} getPopupContainer={() => document.getElementById("app-layout")}>
                            {props.bmProjectList?.map((b: any) => <Select.Option value={b.id} key={b.id}>{b.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label={`${t("Data Collection Roles")}`} name='dataCollectionRoles' rules={RulesRequire({ filedName: `${t("Data Collection Roles")}` })} >
                        <Checkbox.Group options={props.collectionRoles && props.collectionRoles} onChange={(list: any[]) => setFormValues({ ...formValues, dataCollectionRoles: list })} />
                    </Form.Item>
                </Form>
            </Space>
        </ModalAdmin>
    )
}
const mapStateToProps = (state: any) => {
    return {
        isModalLoading: state.customerUser?.isModalLoading,
        modalResponse: state.customerUser?.modalResponse,
        collectionRoles: state.generalInfo.profileResponse?.generalSettings?.collectionRoles,
        bmProjectList: state.bmProjectList.bmProjectListResult,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getBmProjectList: (id: number) => dispatch({ type: ActionTypes.GET_BMPROJECT_LIST_BY_ID, customerId: id }),
        makeCustomerPersonToUser: (model: ICustomerPersonToUser, id: number) => dispatch({ type: ActionTypes.MAKE_CUSTOMER_PERSON_TO_USER, model, customerId: id }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPersonMakeUser);
