import { ActionTypes } from "../actionsTypes";
import { ICurvesState } from "./IGeneralState";

const initialState: ICurvesState = {
    isCurvesLoading: false,
    curvesResult: null,
    isModalLoading: false,
    modalResponse: null,
}
const FrontLoadingCurvesReducer = (state = initialState, action: any): ICurvesState => {
    switch (action.type) {
        case ActionTypes.CHANGE_CURVES_LOADER:
            return {
                ...state,
                isCurvesLoading: action.isLoading,
            };
        case ActionTypes.SAVE_CURVES:
            return {
                ...state,
                curvesResult: action.result
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            }
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            }
        default:
            break;
    }
    return state;
}
export default FrontLoadingCurvesReducer;
