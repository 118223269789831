import { Button, Form, Tag } from 'antd'
import React, { useState } from 'react'
import TextEditorForRichText from '../../../Components/TextEditorForRichText';
import { t } from 'i18next';
import { IHelpPage } from '../../../Models/IHelpPage';
import './index.scss'
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
interface IProps {
    record: IHelpPage;
    updateHelpPage: (model: IHelpPage) => any;
}
const HelpPageEditForm: React.FC<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<IHelpPage>({
        id: props.record?.id,
        descriptionEn: props.record?.descriptionEn,
        descriptionDe: props.record?.descriptionDe,
    });
    const handleFormValues = async (e: any) => {
        e.preventDefault();
        
        const helpPageUpdate: IHelpPage = {
            id: props.record.id,
            descriptionEn: formValues?.descriptionEn ?? props.record?.descriptionEn,
            descriptionDe: formValues?.descriptionDe ?? props.record?.descriptionDe,
        }
        console.log("HelpPageUpdate : ", helpPageUpdate);
        await props.updateHelpPage(helpPageUpdate)

    }
    return (
        <Form form={form} key="help_form" layout="vertical" className='help_form'>
            <h3>{props.record?.code}</h3>
            <Form.Item
                label={<Tag color="#2db7f5">{`${t("English Description")}`}</Tag>}
            >
                <TextEditorForRichText
                    onChange={(value) => setFormValues({ ...formValues, descriptionEn: value })}
                    value={formValues?.descriptionEn ?? props.record?.descriptionEn}
                    placeholder="placeholder"
                />
            </Form.Item >
            <Form.Item 
              label={<Tag color="#2db7f5">{`${t("German Description")}`}</Tag>}
            >
                <TextEditorForRichText
                    onChange={(value) => setFormValues({ ...formValues, descriptionDe: value })}
                    value={formValues?.descriptionDe ?? props.record?.descriptionDe}
                    placeholder="placeholder"
                />
            </Form.Item>
            <div className='help_btn_save_box'>
                <Button type='primary' onClick={(e) => handleFormValues(e)}>{`${t("Save")}`}</Button>
            </div>
        </Form>
    )
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        //isfullScreen: state.generalInfo.isFullScreen,
        //projects: state.projects.projects,
        //currentProject: state.generalInfo.profileResponse?.currentProject,
        //profileResponse: state.generalInfo.profileResponse,
        helpPageState: state.helpPagesForAdmin
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateHelpPage: (model: IHelpPage) => dispatch({ type: ActionTypes.UPDATE_HELP_PAGE, model })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HelpPageEditForm);