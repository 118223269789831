import AsupColumnChart from '../../../../EBITTool-V2/BasicCharts/ASupColumnchart';
import i18n from "../../../../../../i18n";
import { useTranslation } from 'react-i18next';
interface IProps {
    disciplineName: string;
    currentDiscipline: {
        name: string;
        value: number;
        isTarget: boolean;
        isComplete: boolean;
    }[],
    disciplineBestPractice: {
        value: number;
        max: number;
        min: number;
    },
}

function RDCostsVSDisciplineBarChart(props: IProps) {

    const { t } = useTranslation();

    const SummaryDisciplinesNames = [t("Gesamte F&E-Kosten"), t("Interdisziplinäre F&E Kosten"), t("Originäre F&E Kosten")];

    return (
        <AsupColumnChart
            isTopLevelChart={true}
            key="AsupColumnChart"
            subTitle={props.disciplineName === t("Wählen Sie eine Disziplin aus")? props.disciplineName:!SummaryDisciplinesNames.includes(props.disciplineName) ? `${props.disciplineName} / ${t("Gesamte F&E-Kosten")}` : `${props.disciplineName} / ${t("Umsatz")}`}
            ebitBMProjectsForChart={props.currentDiscipline}
            bestPractice={props.disciplineBestPractice}
        />
    );
}

export default RDCostsVSDisciplineBarChart;