
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import CustomerUsersService from "../../Services/CustomerUsersService";
import { ActionTypes } from "../actionsTypes";
import { ICustomerUserItem } from "../../Models/ICustomerUsers";
import UserManagementService from "../../Services/UserManagementService";

function* OnGetCustomerUsers(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
    if (!_result.isError) {
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
  } catch (error) {
    console.error("ERR in onGetCustomerUsers: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* OnCreateCustomerUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultCreate: ICommonResponse = yield CustomerUsersService.CreateCustomerUser(
      action.model
    );
    if (!resultCreate.isError) {
      // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
      let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onCreateQk: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onUpdateCustomerUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultUpdate: ICommonResponse = yield CustomerUsersService.UpdateCustomerUser(action.model);

    if (!resultUpdate.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
      let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: _result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onUpdateCustomerUser: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
// make customer person to user
function* onMakeCustomerPersonToUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultMake: ICommonResponse = yield CustomerUsersService.MakeCustomerPersonToUser(action.model);
    if (!resultMake.isError) {
      // yield put({ type: ActionTypes.SetCommonResponse, result: resultMake });
      let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: resultMake });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultMake });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onUpdateCustomerUser: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onDeletePerson(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultDelete: ICommonResponse = yield CustomerUsersService.deletePerson(action.personId);
    if (!resultDelete.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
      let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: resultDelete });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onDeletePerson: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

//BmProjectForUser Setting
function* onAssignNewBmProjectForUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultAssign: ICommonResponse = yield CustomerUsersService.assignNewBmProjectForUser(action.model);
    if (!resultAssign.isError) {
      let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: resultAssign });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultAssign });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onUpdateCustomerUser: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onDeleteBmProjectForUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse = yield CustomerUsersService.deleteBmProjectForUser(action.customerUserId);
    if (!_result.isError) {
      let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: _result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onDeactivate: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onActiveCustomerUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultActive: ICommonResponse = yield UserManagementService.ActiveUser(action.userId);
    if (!resultActive.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultActive });
      let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: _result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultActive });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onActive: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onDeactivateCustomerUser(action: any) {
  try {
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let resultDeactivate: ICommonResponse = yield UserManagementService.DeactivateUser(action.userId);
    if (!resultDeactivate.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDeactivate });
      let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
      let result = _result.result as ICustomerUserItem[];
      yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });
      yield put({ type: ActionTypes.SetModalResponse, result: _result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: resultDeactivate });
    }
    yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onDeactivate: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}



function* refreshTheData(action: any) {

  let _result: ICommonResponse = yield CustomerUsersService.GetCustomerUsers(action.customerId);
  if (!_result.isError) {
    let result = _result.result as ICustomerUserItem[];
    yield put({ type: ActionTypes.SAVE_CUSTOMERUSERS, result: result });

  } else {
    yield put({ type: ActionTypes.SetCommonResponse, result: _result });
  }
}
function* watchGetCustomerUsers() {
  yield takeEvery(ActionTypes.GET_CUSTOMERUSERS, OnGetCustomerUsers);
}
function* watchCreateCustomerUser() {
  yield takeEvery(ActionTypes.CREATE_CUSTOMERUSER, OnCreateCustomerUser);
}
function* watchUpdateCustomerUser() {
  yield takeEvery(ActionTypes.UPDATE_CUSTOMERUSER, onUpdateCustomerUser);
}
function* watchMakeCustomerPersonToUser() {
  yield takeEvery(ActionTypes.MAKE_CUSTOMER_PERSON_TO_USER, onMakeCustomerPersonToUser);
}
function* watchDeletePerson() {
  yield takeEvery(ActionTypes.DELETE_PERSON, onDeletePerson);
}
function* watchAssignNewBmProjectForUser() {
  yield takeEvery(ActionTypes.ASSIGN_NEW_BM_PROJECT_FOR_USER, onAssignNewBmProjectForUser);
}
function* watchDeleteBmProjectForUser() {
  yield takeEvery(ActionTypes.DELETE_BM_PROJECT_FOR_USER, onDeleteBmProjectForUser);
}
function* watchActiveCustomerUser() {
  yield takeEvery(ActionTypes.ACTIVE_CUSTOMERUSER, onActiveCustomerUser)
}
function* watchDeactivateCustomerUser() {
  yield takeEvery(ActionTypes.DEACTIVATE_CUSTOMERUSER, onDeactivateCustomerUser)
}


export default function* CustomerUsersSagas() {
  yield all([
    watchGetCustomerUsers(),
    watchCreateCustomerUser(),
    watchUpdateCustomerUser(),
    watchMakeCustomerPersonToUser(),
    watchAssignNewBmProjectForUser(),
    watchDeleteBmProjectForUser(),
    watchDeletePerson(),
    watchActiveCustomerUser(),
    watchDeactivateCustomerUser(),
   
  ]);
}
