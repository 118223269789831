import { notification } from "antd";
import {
  dprojectEbitCostsSecondLevelType,
  dprojectEbitCostsType,
  dprojectGeneralDataSecondLevelType,
  dprojectGeneralDataType,
  dProjectQuestionnairesType,
  QuestionnaireLeverLevelType,
  QuestionnairesecondLevelType,
  QuestionnaireSubLeverLevelType,
  _dprojectEbitCostsSecondLevelType,
  _dprojectEbitCostsType,
  _dprojectGeneralDataSecondLevelType,
  _dprojectGeneralDataType,
  _dProjectQuestionnairesType,
  _QuestionnaireLeverLevelType,
  _QuestionnairesecondLevelType,
  _QuestionnaireSubLeverLevelType,
} from "../Scenes/Tools/DProjects/DataType/types";
import i18n from "../i18n";
import { BmProject } from "../Models/Responses/ProfileResponse";
import {
  IKpiDegree,
  ILever,
  ILeverQuestion,
  ISubLever,
  ISucessFactor,
} from "../Models/Levers/Items";
//import { ActivitySurveyData } from "../Scenes/Tools/ActivityTool/Survey/Models/ActivitySurveyData";
//import { ActivityTask } from "../Scenes/Tools/ActivityTool/Survey/Models/ActivityTask";
//import { ActivityGroup } from "../Scenes/Tools/ActivityTool/Survey/Models/ActivityGroup";
import { t } from "i18next";
import { TableLocale } from "antd/lib/table/interface";
import html2canvas from "html2canvas";
import saveAs from "file-saver";

export const DownloadChartAsImage = (
  titel: string,
  canvasSave: HTMLElement
) => {
  try {
    html2canvas(canvasSave, {
      scale: 15,
    }).then((canvas) => {
      var dataURL = canvas.toDataURL();
      //console.log("dataURL", dataURL);
      saveAs(dataURL, `${titel}_${Date.now().toString()}.png`);
      // canvas.toBlob(function (blob) {
      //     saveAs(blob, `${titel}_${Date.now().toString()}.png`)
      // })
    });
  } catch (err) {
    console.log("DownloadChartAsImage error:", err);
  }
};

export const transformId = (id: number): string => {
  let id_str = id.toString();
  let levels = id_str.split("");
  let separatedId: string = "";
  levels.forEach((level) => {
    separatedId += level + ".";
  });
  return separatedId;
};

export const upperCaseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const lowerCaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export type NotificationType = "success" | "info" | "warning" | "error";
export const openNotificationWithIcon = (
  type: NotificationType,
  title: string,
  message: string
) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export function formatValueWithNoDefaultFractions(result: any) {
  return new Intl.NumberFormat(i18n.language, {
    maximumFractionDigits: 1,
  }).format(result);
  //return new Intl.NumberFormat(i18n.language).format(result);
}
export function formatValue(result: any) {
  if (
    result == null ||
    result === null ||
    typeof result === "undefined" ||
    result.toString() === "" ||
    result.isNaN
  ) {
    result = "NaN";
    return result;
  }

  return new Intl.NumberFormat(i18n.language, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  }).format(result);
  //return new Intl.NumberFormat(i18n.language).format(result);
}

export const dprojectInputFormatter = (value: any) => {
  if (value.toString() === "" || value < 0) {
    return "";
  } else {
    if((value as string).endsWith(',') || (value as string).endsWith('.')){
      console.log("end with . or ,");
      return value;
    }

    return new Intl.NumberFormat(i18n.language, {      
      maximumFractionDigits: 1,
    }).format(value);
  }
};

export const dprojectParser = (val, locale) => {
  let lng = locale;
  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = "";
    }
    if((val as string).endsWith(',') || (val as string).endsWith('.')){
      console.log("end with . or ,");
      return val;
    }
    // detecting and parsing between comma and dot
    var group = new Intl.NumberFormat(lng).format(1111).replace(/1/g, "");
    var decimal = new Intl.NumberFormat(lng).format(1.1).replace(/1/g, "");
    var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.\-+]/g, "");
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? "" : reversedVal;
  } catch (error) {
    console.error("inputParser", error);
  }
};

export const inputFormatter = (value, locale) => {
  try{
    if (!value || value.toString() === "" || value.isNaN) {
      return "";
    }
    if (value === "") return "";
    
    if((value as string)?.endsWith(".") || (value as string)?.endsWith(","))
      return value;
    if (value) {
      // if((value as string).endsWith(',') || (value as string).endsWith('.')){
      //   console.log("end with . or ,");
      //   return value;
      // } 

      return new Intl.NumberFormat(locale, { maximumFractionDigits: 1 }).format(
        value
      );
    } else {
      return value;
    }
  }catch(e){
    console.log("inputFormatter", value, locale);
    return value;
  }

};

export const inputParser = (val, locale) => {
  let lng = locale;
  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = "";
    }

    if((val as string).endsWith(',') || (val as string).endsWith('.')){
      console.log("end with . or ,");
      return val;
    }
 

    // detecting and parsing between comma and dot
    var group = new Intl.NumberFormat(lng).format(1111).replace(/1/g, "");
    var decimal = new Intl.NumberFormat(lng).format(1.1).replace(/1/g, "");
    var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.\-+]/g, "");
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? "" : reversedVal;
  } catch (error) {
    console.error("inputParser", val, error);
    return val;
  }
};

export const splitGeneralInfo = (
  dprojectGeneralData: dprojectGeneralDataType
): [_dprojectGeneralDataType, dprojectGeneralDataType] => {
  let shownChildren: _dprojectGeneralDataSecondLevelType[] =
    dprojectGeneralData.children
      .filter((child) => child.visible === true)
      .map((child) => {
        let { visible, order, ...toAddChild } = child;
        return toAddChild;
      });
  let hiddenCgildren: dprojectGeneralDataSecondLevelType[] =
    dprojectGeneralData.children.filter((child) => child.visible === false);
  let shown: _dprojectGeneralDataType = null;
  if (shownChildren.length > 0) {
    shown = { ...dprojectGeneralData, children: shownChildren };
  }
  let hidden: dprojectGeneralDataType =
    hiddenCgildren.length > 0
      ? { ...dprojectGeneralData, children: hiddenCgildren }
      : null;
  return [shown, hidden];
};

export const splitEbitCosts = (
  dprojectEbitCosts: dprojectEbitCostsType
): [_dprojectEbitCostsType, dprojectEbitCostsType] => {
  let secondLevelItems = dprojectEbitCosts.children;

  let secondLevelHiddenItems: dprojectEbitCostsSecondLevelType[] = [];
  let secondLevelShownItems: _dprojectEbitCostsSecondLevelType[] = [];

  secondLevelItems.forEach((secondLevelItem) => {
    let thirdLevelItems = secondLevelItem.children;
    let thirdLevelShownItems = thirdLevelItems
      .filter((thirdLevelItem) => thirdLevelItem.visible === true)
      .map((thirdLevelShownItem) => {
        let { visible, order, ...toRetThirdLevelShownItem } =
          thirdLevelShownItem;
        return toRetThirdLevelShownItem;
      });
    let thirdLevelHiddenItems = thirdLevelItems.filter(
      (thirdLevelItem) => thirdLevelItem.visible === false
    );

    if (thirdLevelHiddenItems.length > 0) {
      let secondLevelHiddenItem = {
        ...secondLevelItem,
        children: thirdLevelHiddenItems,
      };
      secondLevelHiddenItems.push(secondLevelHiddenItem);
    }

    if (thirdLevelShownItems.length > 0) {
      let { visible, order, ...secondLevelShownItem } = {
        ...secondLevelItem,
        children: thirdLevelShownItems,
      };
      secondLevelShownItems.push(secondLevelShownItem);
    }
  });
  let hiddenDprojectEbitCosts: dprojectEbitCostsType = null;
  if (secondLevelHiddenItems.length > 0) {
    hiddenDprojectEbitCosts = {
      ...dprojectEbitCosts,
      children: secondLevelHiddenItems,
    };
  }
  let shownDprojectEbitCosts: _dprojectEbitCostsType = null;
  if (secondLevelShownItems.length > 0) {
    shownDprojectEbitCosts = {
      ...dprojectEbitCosts,
      children: secondLevelShownItems,
    };
  }

  return [shownDprojectEbitCosts, hiddenDprojectEbitCosts];
};

export const splitQuestionnaire = (
  dProjectQuestionnaires: dProjectQuestionnairesType
): [_dProjectQuestionnairesType, dProjectQuestionnairesType] => {
  let levers = dProjectQuestionnaires.children;
  let leversShownItems: _QuestionnaireLeverLevelType[] = [];
  let leversHiddenItems: QuestionnaireLeverLevelType[] = [];

  levers.forEach((lever) => {
    let subLevers: QuestionnaireSubLeverLevelType[] = lever.children;
    let subleversShownItems: _QuestionnaireSubLeverLevelType[] = [];
    let subleversHiddenItems: QuestionnaireSubLeverLevelType[] = [];
    subLevers.forEach((subLever) => {
      let secondLevelItems = subLever.children;
      let secondLevelShownItems: _QuestionnairesecondLevelType[] = [];
      let secondLevelHiddenItems: QuestionnairesecondLevelType[] = [];

      secondLevelItems.forEach((secondLevelItem) => {
        let thirdLevelItems = secondLevelItem.children;
        let thirdLevelShownItems = thirdLevelItems
          .filter((thirdLevelItem) => thirdLevelItem.visible === true)
          .map((thirdLevelShownItem) => {
            let { visible, order, ...toRetThirdLevelShownItem } =
              thirdLevelShownItem;
            return toRetThirdLevelShownItem;
          });
        let thirdLevelHiddenItems = thirdLevelItems.filter(
          (thirdLevelItem) => thirdLevelItem.visible === false
        );

        if (thirdLevelHiddenItems.length > 0) {
          let secondLevelHiddenItem = {
            ...secondLevelItem,
            children: thirdLevelHiddenItems,
          };
          secondLevelHiddenItems.push(secondLevelHiddenItem);
        }

        if (thirdLevelShownItems.length > 0) {
          let { visible, order, ...secondLevelShownItem } = {
            ...secondLevelItem,
            children: thirdLevelShownItems,
          };
          secondLevelShownItems.push(secondLevelShownItem);
        }
      });

      if (secondLevelHiddenItems.length > 0) {
        let hiddenSubLever = { ...subLever, children: secondLevelHiddenItems };
        subleversHiddenItems.push(hiddenSubLever);
      }
      if (secondLevelShownItems.length > 0) {
        let { visible, order, ...shownSubLever } = {
          ...subLever,
          children: secondLevelShownItems,
        };
        subleversShownItems.push(shownSubLever);
      }
    });

    if (subleversHiddenItems.length > 0) {
      let hiddenLever = { ...lever, children: subleversHiddenItems };
      leversHiddenItems.push(hiddenLever);
    }
    if (subleversShownItems.length > 0) {
      let { visible, order, ...shownLever } = {
        ...lever,
        children: subleversShownItems,
      };
      leversShownItems.push(shownLever);
    }
  });

  let shownDProjectQuestionnaires: _dProjectQuestionnairesType = null;
  if (leversShownItems.length > 0) {
    shownDProjectQuestionnaires = {
      ...dProjectQuestionnaires,
      children: leversShownItems,
    };
  }
  let hiddenDProjectQuestionnaires: dProjectQuestionnairesType = null;
  if (leversHiddenItems.length > 0) {
    hiddenDProjectQuestionnaires = {
      ...dProjectQuestionnaires,
      children: leversHiddenItems,
    };
  }

  return [shownDProjectQuestionnaires, hiddenDProjectQuestionnaires];
};

export const orderAndCopyGeneralInfo = (
  dprojectsTable_GeneralInfo: dprojectGeneralDataType
): dprojectGeneralDataType => {
  let orderedChildren = dprojectsTable_GeneralInfo.children
    .sort((a, b) => a.order - b.order)
    .filter((child) => true);
  return {
    ...dprojectsTable_GeneralInfo,
    children: orderedChildren,
  };
};

export const orderAndCopyEbitCosts = (
  dprojectTable_EbitCosts: dprojectEbitCostsType
): dprojectEbitCostsType => {
  let secondLevelItems = dprojectTable_EbitCosts.children;
  secondLevelItems.forEach((secondLevelItem) => {
    //console.log('secondLevelItem: ', secondLevelItem);

    let orderedThirdLevelItems = secondLevelItem.children
      .sort((a, b) => a.order - b.order)
      .filter((child) => true);
    secondLevelItem.children = orderedThirdLevelItems;
  });
  let orderedSecondLevelItems = secondLevelItems
    .sort((a, b) => a.order - b.order)
    .filter((child) => true);

  return {
    ...dprojectTable_EbitCosts,
    children: orderedSecondLevelItems,
  };
};

export const orderAndCopyQuestionnaires = (
  dprojectTable_Questionnare: dProjectQuestionnairesType
): dProjectQuestionnairesType => {
  let levers: QuestionnaireLeverLevelType[] =
    dprojectTable_Questionnare.children;
  levers.forEach((lever: QuestionnaireLeverLevelType) => {
    let sublevers: QuestionnaireSubLeverLevelType[] = lever.children;
    sublevers.forEach((sublever) => {
      let secondLevelItems = sublever.children;
      secondLevelItems.forEach((secondLevelItem) => {
        let orderedThirdLevelItems = secondLevelItem.children
          .sort((a, b) => a.order - b.order)
          .filter((child) => true);
        secondLevelItem.children = orderedThirdLevelItems;
      });
      let orderedSecondLevelItems = secondLevelItems
        .sort((a, b) => a.order - b.order)
        .filter((child) => true);
      sublever.children = orderedSecondLevelItems;
    });
    let orderedSubLevers = sublevers.sort((a, b) => a.order - b.order);
    lever.children = orderedSubLevers;
  });
  let orderedLevers = levers.sort((a, b) => a.order - b.order);

  return {
    ...dprojectTable_Questionnare,
    children: orderedLevers,
  };
};

export const getColumns = (
  dprojectsTable_GeneralInfo: _dprojectGeneralDataType,
  dprojectTable_EbitCosts: _dprojectEbitCostsType,
  dprojectTable_Questionnare: _dProjectQuestionnairesType
) => {
  //console.log('getColumns dprojectsTable_GeneralInfo: ', dprojectsTable_GeneralInfo);
  //console.log('getColumns dprojectTable_EbitCosts: ', dprojectTable_EbitCosts);
  //console.log('getColumns dprojectTable_Questionnare: ', dprojectTable_Questionnare);

  let GeneralInfoCols: _dprojectGeneralDataType = dprojectsTable_GeneralInfo;
  let EbitCostsCols: _dprojectEbitCostsSecondLevelType[] =
    dprojectTable_EbitCosts?.children;
  let QuestionnaireCols: _QuestionnairesecondLevelType[] = [];
  dprojectTable_Questionnare?.children.forEach((lever) => {
    lever.children.forEach((subLever) => {
      QuestionnaireCols.push(...subLever.children);
    });
  });
  //console.log('QuestionnaireCols: ', QuestionnaireCols);

  let dprojectsTableCols = [];

  if (dprojectsTable_GeneralInfo?.order === 1) {
    dprojectsTableCols.push(GeneralInfoCols);
  } else if (dprojectTable_EbitCosts?.order === 1) {
    dprojectsTableCols.push(...EbitCostsCols);
  } else {
    dprojectsTableCols.push(...QuestionnaireCols);
  }

  if (dprojectsTable_GeneralInfo?.order === 2) {
    dprojectsTableCols.push(GeneralInfoCols);
  } else if (dprojectTable_EbitCosts?.order === 2) {
    dprojectsTableCols.push(...EbitCostsCols);
  } else {
    dprojectsTableCols.push(...QuestionnaireCols);
  }

  if (dprojectsTable_GeneralInfo?.order === 3) {
    dprojectsTableCols.push(GeneralInfoCols);
  } else if (dprojectTable_EbitCosts?.order === 3) {
    dprojectsTableCols.push(...EbitCostsCols);
  } else {
    dprojectsTableCols.push(...QuestionnaireCols);
  }

  return dprojectsTableCols;
};

export const groupBy = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
};

export const smashedXs = (projects: any[]) => {
  if (!projects) return [];
  if (!(projects.length > 0)) return [];
  // examine if every project contains branch property
  let projectsContainsBranchProperty = true;
  projects.forEach((p) => {
    if (p) {
      if (!objectHasProperty(p, "branch"))
        projectsContainsBranchProperty = false;
    } else {
      projectsContainsBranchProperty = false;
    }
  });
  if (!projectsContainsBranchProperty) {
    console.error("Err in smashedXs, not all projects has branch property.");
    return [];
  }
  let groupedByBranch = groupBy(projects, "branch");
  let branchs = Object.keys(groupedByBranch);
  let smashedProjects = [];
  branchs.forEach((branch) => {
    groupedByBranch[branch].forEach((p, idx) => {
      smashedProjects.push({
        ...p,
        smashedName:
          (branch.length > 0 ? branch.charAt(0).toUpperCase() : "X") +
          (idx + 1).toString(),
      });
    });
  });

  return smashedProjects;
};

export const objectHasProperty = (object: any, property: string): boolean => {
  if (object === null) return false;
  if (typeof object === "undefined") return false;
  let objectKeys = Object.keys(object);
  if (!objectKeys) return false;
  if (objectKeys.length === 0) return false;
  if (!objectKeys.includes(property)) return false;
  return true;
};

export const getLevels = (leverNumber: string): string[] => {
  if (leverNumber === "0.") return [];
  if (leverNumber?.includes("_"))
    return leverNumber.split("_").filter((element) => element !== "q");
  return leverNumber.split(".").filter((element) => element !== "");
};

export const getCurrentNode = (
  project: BmProject,
  levels: string[]
): ILever | ISubLever | ISucessFactor | IKpiDegree | ILeverQuestion => {
  let number: string = "";
  let currentNode:
    | ILever
    | ISubLever
    | ISucessFactor
    | IKpiDegree
    | ILeverQuestion;
  levels.forEach((level, idx) => {
    number = number + level + ".";
    switch (idx) {
      case 0:
        currentNode = project.leversData?.find((l) => l.number === number);
        break;
      case 1:
        currentNode = (currentNode as ILever)?.subLevers?.find(
          (s) => s.number === number
        );
        break;
      case 2:
        currentNode = (currentNode as ISubLever)?.successFactors?.find(
          (sf) => sf.number === number
        );
        break;
      case 3:
        currentNode = (currentNode as ISucessFactor)?.kpiLevels?.find(
          (kl) => kl.number === number
        );
        break;
      case 4:
        // construct the right code for question
        let questionCode = `q_${number.replaceAll(".", "_").slice(0, -1)}`;
        currentNode = (currentNode as IKpiDegree)?.questions?.find(
          (q) => q.code === questionCode
        );
        break;

      default:
        break;
    }
  });

  return currentNode;
};
export const deleteFirstLetter = (word: string) => {
  return word.substring(1);
};

// export const transformActivitySurveyData = (result: ActivitySurveyData): ActivitySurveyData => {
//   try{
//     console.log("transformActivitySurveyData")
//     let groups = result.groups?.map((group) => {
//         let tasks = group.children?.map((task) => {
//             return new ActivityTask(task.key, task.taskId, task.taskGroupId, task.taskName, task.children);
//         });
//         return new ActivityGroup(group.key, group.groupId, group.groupSerialNumber, group.groupName, tasks);
//     });
//     console.log("transformActivitySurveyData", {
//         ...result,
//         groups: groups
//     })
//     return {
//         ...result,
//         groups: groups
//     };
//   }
//   catch(er){
//     console.log("transformActivitySurveyData", er)
//   }
// };

export const tableLocalTranslate = (): TableLocale => {
  return {
    filterReset: `${t("Reset")}`,
    triggerDesc: t("Click to sort descending"),
    triggerAsc: t("Click to sort ascending"),
    cancelSort: t("Click to cancel sorting"),
    emptyText: `${t("No data")}`,
  };
};
