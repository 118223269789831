import React from 'react';
import { Column, G2 } from '@ant-design/plots';
import { DisciplineBenchmarkRow } from '../Models/EBITBenchmarkData';
import { Button, Col, Row, Space, Tooltip, Checkbox } from "antd";
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { connect } from "react-redux";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { formatValue } from "../../../../Utils/helperFunctions";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DownloadOutlined } from "@ant-design/icons";

interface Iprops {
    chartTitle: string,
    data: DisciplineBenchmarkRow[],
    currentProject: BmProject;
    bestPracticeRates: DisciplineBenchmarkRow[],
    isTopLevelChart: boolean,
    selectChart?: (chartDivId: string, add: boolean) => void;
    selectedCharts?: string[];
    topLevelTitle?: string;
    setDisciplinName?: (string) => void;
    isForFrontLoading?: boolean;
    isPercent?: boolean;
}

const StackedColumnChart = (props: Iprops) => {
    const { t } = useTranslation();
    let activeDiscipline = '';
    const projectDisciplines = props.data?.sort((a, b) => a.viewOrder > b.viewOrder ? 1 : -1) ?? [];
    const bestPracticeValues = props.bestPracticeRates?.map(x => { return { ...x, rate: 100 * x.rate } }).sort((a, b) => a.viewOrder > b.viewOrder ? 1 : -1) ?? [];
    const colors = projectDisciplines?.map(x => { return { name: i18n.language === 'de' ? x.name : x.englishName, color: x.color } }) ?? [];


    const DownloadChartAsImage = () => {
        const canvasSave = document.getElementById(props.chartTitle);
        // let originalHeight = canvasSave.offsetHeight;
        html2canvas(canvasSave, {
            scale: 25
        })
            .then((canvas) => {
                canvas.toBlob(function (blob) {
                    saveAs(blob, `${props.chartTitle}.png`)
                })
            })
    }

    let chartData = projectDisciplines?.concat(bestPracticeValues);
    //console.log('chartData: ', chartData);
    
    G2.registerInteraction('element-link', {
        start: [
            { trigger: 'plot:click', action: 'element-link-by-color:link' }
        ],
        end: [
            { trigger: 'plot:dblclick', action: 'element-link-by-color:unlink' },
            { trigger: 'legend-item:click ', action: 'element-link-by-color:clear' }
        ]
    });
    const selectChart = async (e: CheckboxChangeEvent) => {
        if (!props.selectChart) return;
        if (e.target.checked) {
            props.selectChart(props.chartTitle, true);
        }
        else {
            props.selectChart(props.chartTitle, false);
        }
    };
    const config: any = {
        data: chartData,
        isStack: true,
        xField: 'projectName',
        yField: 'rate',
        //pixelRatio:20,
        isPercent: props.isPercent ?? false,
        seriesField: i18n.language === 'de' ? 'name' : 'englishName',
        tooltip: {
            customItems: (originalItems: any[]) => {
                return i18n.language === 'de' ? originalItems.filter(element => element.name == activeDiscipline)
                    : originalItems.filter(element => element.englishName == activeDiscipline);
            },
            formatter: (datum) => {
                if (i18n.language == 'de')
                    if (datum.name == activeDiscipline) {
                        if (datum.projectName === 'Best Practices')
                            return { ...datum, value: props.isPercent ? formatValue(datum.rate * 100) + " %" : formatValue(datum.rate) + " %" };
                        else
                            return { ...datum, value: props.isPercent ? formatValue(datum.rate * 100) + " %" : formatValue(datum.rate) + " %" };
                    }
                    else return null;
                else
                    if (datum.englishName == activeDiscipline) {
                        if (datum.projectName === 'Best Practices')
                            return { ...datum, name: datum.englishName, value: props.isPercent ? formatValue(datum.rate * 100) + " %" : formatValue(datum.rate) + " %" };
                        else
                            return { ...datum, name: datum.englishName, value: props.isPercent ? formatValue(datum.rate * 100) + " %" : formatValue(datum.rate) + " %" };
                    }
                    else return null;
            },
            showNil: false,
        },
        autoFit: true,
        // width: props.isTopLevelChart ? 200 : 490,
        appendPadding: [10, 40, props.isForFrontLoading ? 20 : 10, 10],
        legend: props.isTopLevelChart ? false : {
            offsetX: -40,
            offsetY: props.isForFrontLoading ? 0 : 15,
            flipPage: true,
            maxItemWidth: 140,
            slidable: true
        },
        color: i18n.language === 'de' ? ({ name }) => {
            return colors?.find(x => x.name == name)?.color ?? 'white'
        } :
            ({ englishName }) => {
                return colors?.find(x => x.name == englishName)?.color ?? 'white'
            },
        interactions: [
            { type: 'element-link' },
            { type: 'legend-filter', enable: false }
        ],
        yAxis: {
            tickInterval: 0,
            // max:100,
            label: {
                formatter: (text) => { return props.isPercent ? formatValue(text * 100) + '%' : formatValue(text) + '%' }
            },
        },
        connectedArea: {

            style: (oldStyle, element) => {
                activeDiscipline = i18n.language == 'de' ? element.data.name : element.data.englishName;
                if (props.setDisciplinName) props.setDisciplinName(activeDiscipline);
                return {
                    fill: 'rgba(0,0,0,0.25)',
                    stroke: oldStyle.fill,
                    lineWidth: 0.5,
                };

            },
        },
        // tooltip: {
        //     formatter: (datum) => {
        //         return { name: datum.name, value: inputFormatter(datum.rate * 100, i18n.language) + '%' };
        //     }
        // },
    };
    return (
        <div style={{ border: !props.isTopLevelChart ? '1px solid #ccc' : null, height: props.isForFrontLoading ? '71vh' : null }} className='stacked_column_Chart'>
            {!props.isTopLevelChart && !props.isForFrontLoading && <Row>
                {/* <Col span={1}></Col> */}
                <Col span={1} style={{ textAlign: 'right' }}><Checkbox checked={props.selectedCharts?.includes(props.chartTitle)} onChange={(e) => selectChart(e)} style={{ 'zIndex': '100000' }} />
                </Col>
                <Col span={21}>
                </Col>
                <Col span={1} style={{ paddingTop: 10 }}><Tooltip placement="bottom" title="Download Chart">
                    <Button icon={<DownloadOutlined />} onClick={() => DownloadChartAsImage()} type="default"></Button>
                </Tooltip></Col>

                <Col span={1}></Col>
            </Row>
            }

            <div id={props.chartTitle} style={{ height: props.isTopLevelChart ? '22vh' : props.isForFrontLoading ? '67vh' : null }}>
                <>
                    <Row >
                        <Col span={24} style={{ textAlign: 'center', paddingBottom: props.isTopLevelChart ? 0 : 20 }}>
                            <>
                            {props.isTopLevelChart ? <><text style={{ fontSize: "13px", fontWeight: "normal" }}>{props.topLevelTitle}</text>
                                <br /><text style={{ fontSize: "13px", fontWeight: "normal" }}>{t("F&E-Kosten")}</text></>
                                : <text style={{ fontSize: "14px", fontWeight: props.isForFrontLoading ? "normal" : "bold" }}>{props.chartTitle}</text>
                            }
                            {props.isPercent && <><br /> <text style={{ fontSize: "14px", fontWeight: props.isForFrontLoading ? "normal" : "bold" }}>[%]</text></>}
                            </>
                        </Col>
                    </Row>
                    <Column {...config} />
                </>
            </div>
        </div>);
};
const mapStateToProps = (state: IGeneralState) => {
    return {
        ebitState: state.ebit,
        generalInfo: state.generalInfo,
        currentProject: state.generalInfo.profileResponse?.currentProject,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StackedColumnChart);
