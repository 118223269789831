import { Button, Card, Checkbox, Form, Input, InputNumber, Space, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { connect } from "react-redux";
import ColorPickerInput from '../../../../../Components/ColorPickerInput';
import { IGeneralState } from "../../../../../Redux/reducers/IGeneralState";
import { IIaDiscipline } from '../../../../../Models/InterfaceAnalysis/IAItems';
import { ActionTypes } from '../../../../../Redux/actionsTypes';
import { t } from 'i18next';
import ModalAdmin from '../../../../../Components/ModalAdmin/ModalAdmins';
import { RulesColorPicker, RulesName, RulesRequire } from '../../../../Admin/Rules/RulesValidation';
import { IDepartmentItem } from '../../../../../Models/IDepartment';
import { ICommonResponse } from '../../../../../Models/Responses/ICommonResponse';
import { deleteFirstLetter } from '../../../../../Utils/helperFunctions';
import DepartmentsTable from './DepartmentsTable';
import UploadFile from '../../../EBITTool-V2/CostType/UploadFilesComponent';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
interface IProps {
    enableEdit: boolean;
    createDepartment: (model: IDepartmentItem, id: number) => any;
    isModalLoading: boolean;
    isLoading: boolean;
    modalResponse: ICommonResponse;
    bmProjectId: number;
    departments: IIaDiscipline[];
    onImportTable: (frmData: FormData) => any;
}

interface DataType {
    key: string;
    name: string;
    id: number;
    foreColor: string;
    backColor: string;
    isInclude: boolean;
    index: number;
    viewOrder: number;
    isOriginal: boolean;
}
const Departments = (props: IProps) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<IDepartmentItem>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const data: DataType[] = props.departments?.map(disc => {
        return {
            backColor: disc.backColor,
            foreColor: disc.frontendColor,
            id: disc.id,
            index: disc.order,
            isInclude: disc.inAnalysis,
            key: `disc_config_${disc.id}`,
            name: disc.name,
            viewOrder: disc.order,
            isOriginal: disc.isOriginal
        }
    });

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const departmentNew: IDepartmentItem = {
                ...formValues,
                bmProjectId: props.bmProjectId,
                foreColor: deleteFirstLetter(formValues.foreColor),
                backgroundColor: deleteFirstLetter(formValues.backgroundColor),
            }
            await props.createDepartment(departmentNew, props.bmProjectId);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setFormValues({} as IDepartmentItem);
        setIsModalVisible(false);
    }

    return (
        <Card title={`${t("Departments")}`} className="current_project_card"
            extra={(props.enableEdit) ? <Space className="admin-component department-component">
                {/* <Tooltip placement="top" title={`${t("Daten importieren")}`}>
                    <UploadFile currentProjectId={props.bmProjectId} onImportCostTable={props.onImportTable} />
                </Tooltip> */}
                <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Department")}`}
                    width="500px"
                    modalResponse={props.modalResponse}
                    closeAndReset={closeAndReset}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    footerchildren={[
                        <Button key="back" onClick={closeAndReset}>
                            {`${t("Cancel")}`}
                        </Button>,
                        <Button key="submit" type="primary" loading={props.isModalLoading || props.isLoading} onClick={() => formValuesHandler()}>
                            {`${t("Save")}`}
                        </Button>
                    ]}>
                    <Form layout="vertical" form={form}>
                        <Form.Item label={`${t("View Order")}`} name='viewOrder'
                            rules={RulesRequire({ filedName: `${t("View Order")}` })}
                            validateTrigger='onBlur' hasFeedback >
                            <InputNumber
                                min={0}
                                onChange={(value: number) =>
                                    setFormValues({ ...formValues, viewOrder: value })
                                }

                            />
                        </Form.Item>
                        <Form.Item
                            label={`${t("Department")} Name`}
                            name="name"
                            rules={RulesName({ name: `${t("Department")} Name`, countChar: 128 })}
                            hasFeedback
                        >
                            <Input
                                onChange={(e) =>
                                    setFormValues({ ...formValues, name: (e.target.value).trimStart() })
                                }
                            />
                        </Form.Item>
                        <Form.Item label={`${t("Text Color")}`} name="foreColor"
                            rules={RulesColorPicker({ filedName: `${t("Text Color")}`, colorValue: formValues?.foreColor })}
                            hasFeedback className="color-picker-required">
                            <ColorPickerInput colorValue={formValues?.foreColor}
                                onChangeComplete={async (color: any, event: any) => {
                                    try {
                                        setFormValues({ ...formValues, foreColor: color?.hex })
                                        await form.validateFields();
                                    }
                                    catch (err) {
                                        console.log("Text Color", err);
                                    }

                                }}
                            />
                        </Form.Item>
                        <Form.Item label={`${t("Background Color")}`} name="backgroundColor"
                            rules={RulesColorPicker({ filedName: `${t("Background Color")}`, colorValue: formValues?.backgroundColor })}
                            hasFeedback className="color-picker-required">
                            <ColorPickerInput colorValue={formValues?.backgroundColor}
                                onChangeComplete={async (color: any, event: any) => {
                                    try {
                                        setFormValues({ ...formValues, backgroundColor: color?.hex })
                                        await form.validateFields()
                                    }
                                    catch (err) {
                                        console.log("Text Color", err);
                                    }

                                }} />
                        </Form.Item>
                        <Form.Item label={`${t("Is Original")}`} name='isOriginal' >
                            <Checkbox onChange={(e: CheckboxChangeEvent) => setFormValues({ ...formValues, isOriginal: e.target.checked })} />
                        </Form.Item>
                    </Form>
                </ModalAdmin>

            </Space> : null}>
            <DepartmentsTable data={data} enableEdit={props.enableEdit} />
        </Card>

    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        isModalLoading: state.interfaceAnalysis.isModalLoading,
        modalResponse: state.interfaceAnalysis.modalResponse,
        isLoading: state.interfaceAnalysis.isLoading,
        bmProjectId: state.generalInfo.profileResponse?.currentProject?.id
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createDepartment: (model: IDepartmentItem, id: number) => dispatch({ type: ActionTypes.CREATE_DEPARTMENT_BY_BM_PROJECT, model: model, bmProjectId: id }),
        onImportTable: (frmData: FormData) => dispatch({ type: ActionTypes.ImportDepartment, frmData })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Departments);