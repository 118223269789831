import React, { useState } from 'react';
import {  Button, Modal} from 'antd';
import { IFrontLoadingTempPhase } from '../../../Models/IFrontLoading';
import FrontLoadingTempPhase from './FrontLoadingTempPhase/FrontLoadingTempPhase';
import { t } from 'i18next';

interface IProps {
    phasesData: IFrontLoadingTempPhase[];
    templateId: number;
}
const FrontLoadingTempPhaseModal = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showModal} shape="default">
                 {`${t("Phases")}`}
            </Button>
            <Modal title= {`${t("Template Phase")}`}  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className='admin-model' 
            getContainer={() => document.getElementById("app-layout")}  footer={[]} width={1000} >
                <FrontLoadingTempPhase phasesData={props.phasesData} templateId={props.templateId}  />
            </Modal>
        </>
    );
};

export default FrontLoadingTempPhaseModal;