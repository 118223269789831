import React, { useState } from 'react'
import { IProfileUserUpdate } from '../../../Models/IProfileUser'
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { RulesName, RulesPhone, RulesRequire, validateFileType } from '../Rules/RulesValidation';
import { Button, Form, Input, Select, Row, Col, Typography, Upload, UploadFile, UploadProps, Avatar, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { IGeneralSettings, ProfileResponse } from '../../../Models/Responses/ProfileResponse';
import userImage from "../../../assets/Images/user_image.png";
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import ChangePasswordModal from './ChangePasswordForm';
import { t } from 'i18next';




interface IProps {
  profileData: ProfileResponse;
  generalSettings: IGeneralSettings;
  updateProfile: (model: IProfileUserUpdate) => any;
  isLoading: boolean;
  isModalLoading: boolean;
  removeProfileImage: () => any;
}
const ProfileMngForm = (props: IProps) => {
  const [imageFile, setImageFile] = useState<any>();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onFinish = async (values: any) => {
    try {
      await form.validateFields();
      const profileUpdate = {
        id: props.profileData.id,
        ...values,
        image: imageFile,
        phoneNumber: values.phoneNumber ? values.phoneNumber : ""
      }
      await props.updateProfile(profileUpdate);
      setFileList([]);
      setImageFile(null);

    } catch (e) {
      console.log("onEditRow ", e);
    }
  }

  const removeImage = async () => {
    try {
      await props.removeProfileImage();
      setFileList([]);
      setImageFile(null);
    } catch (error) {
      console.log(error);

    }
  }
  const propsImage: UploadProps = {
    // onChange(info) {
    //   setFileList(info.fileList);
    //   setImageFile(info.file)
    // },
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      const isAllowedType =
       validateFileType(file, "image/png")||
       validateFileType(file, "image/jpeg")||
       validateFileType(file, "image/jpg")||
       validateFileType(file, "image/gif")||
       validateFileType(file, "image/webp")||
       validateFileType(file, "image/svg+xml");
      if (!isAllowedType) {
        setFileList((state) => [...state]);
        message.error(`${file.name} is not Image file`);
        return false;
      }
      setFileList([ file]);
      setImageFile(file)
      return false;
    },
    fileList,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16, offset: 1 },
  };
  return (
    props.profileData &&
    <Form form={form} onFinish={onFinish} initialValues={props.profileData} {...formItemLayout}>
      <Row style={{ margin: "1.5rem 0" }} >
        <Col span={12}> {props.profileData.image ?
          (<Button loading={props.isLoading || props.isModalLoading} onClick={() => removeImage()}>
            <DeleteOutlined style={{ fontSize: "1rem", color: "red" }} />
            <Typography.Text strong style={{ marginBottom: "0px", color: "red" }}>
              {`${t("Remove Image")}`}</Typography.Text>
          </Button>) : (
            <Form.Item>
              <Upload listType="picture" maxCount={1}
                accept="image/*"  {...propsImage} >
                <Button >{`${t("Upload New Image")}`}</Button>
              </Upload>
            </Form.Item>
          )}
        </Col>
        <Col span={12}>
          <Avatar size={150} src={props.profileData?.image ? props.profileData?.image : userImage} shape="square" className='ant-card-bordered' />
        </Col>
      </Row>
      <Row style={{ margin: "1.5rem 0", }}>
        <Typography.Text strong style={{ color: "#84a5f4" }}>{`${t("General Setting")}`}</Typography.Text>
      </Row>
      <Form.Item label={`${t("First Name")}`} name='firstName'
        rules={RulesName({ name: `${t("First Name")}`, countChar: 128 })}
        hasFeedback labelAlign='left'>
        <Input placeholder='No First Name' />
      </Form.Item>
      <Form.Item label={`${t("Last Name")}`} name='lastName'
        rules={RulesName({ name: `${t("Last Name")}`, countChar: 128 })}
        hasFeedback labelAlign='left'>
        <Input placeholder='No Last Name' />
      </Form.Item>
      <Form.Item label="E-Mail" name="email"
        labelAlign='left' >
        <Input readOnly />
      </Form.Item>
      <Form.Item label={`${t("Title")}`} name='titleId' rules={RulesRequire({ filedName: `${t("Title")}` })} labelAlign='left'>
        <Select showSearch placeholder={`${t("Select Title")}`} optionFilterProp="children"
          getPopupContainer={() => document.getElementById("app-layout")}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={props.generalSettings?.titles}

        />

      </Form.Item>
      <Form.Item label={`${t("Language")}`} name='languageId' rules={RulesRequire({ filedName: `${t("Language")}` })} labelAlign='left' >
        <Select
          showSearch
          placeholder={`${t("Select Language")}`}
          optionFilterProp="children"
          getPopupContainer={() => document.getElementById("app-layout")}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={props.generalSettings?.languageList}
        />
      </Form.Item>
      <Form.Item label={`${t("Phone")}`} name="phoneNumber"
        rules={RulesPhone()}
        labelAlign='left'
      >
        <Input />
      </Form.Item>

      <Row justify="center" style={{ marginTop: "1rem" }}>
        <Button htmlType="submit" style={{ display: "flex", padding: "10px 40px", justifyContent: "center", alignItems: "center", marginRight: "10px" }} loading={props.isLoading || props.isModalLoading} type='primary'>
          <Typography.Title level={5} style={{ marginBottom: "0px", color: "#fff" }}>
            {`${t("Save")}`}</Typography.Title>
        </Button>
      </Row>
    </Form>
  )
}
const mapStateToProps = (state: IGeneralState) => {
  return {
    profileData: state.generalInfo?.profileResponse,
    generalSettings: state.generalInfo?.profileResponse?.generalSettings,
    isLoading: state.profileData?.isProfileLoading,
    isModalLoading: state.profileData?.isModalLoading,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateProfile: (model: IProfileUserUpdate) => dispatch({ type: ActionTypes.UPDATE_PROFILE, model }),
    removeProfileImage: () => dispatch({ type: ActionTypes.REMOVE_PROFILE_IMAGE }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileMngForm);