import { connect } from "react-redux";
import { Table, Card, Button, Row, Col, Form, InputNumber, Input, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './currentprojectcard.css'
import { useNavigate } from "react-router-dom";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { useEffect } from "react";
import { HelpPageCodes } from "../../../../Redux/HelpPageCodes";
import { useTranslation } from "react-i18next";
import { formatValue, tableLocalTranslate } from "../../../../Utils/helperFunctions";
import { RulesDescription } from "../../../Admin/Rules/RulesValidation";
import { IUpdateBmProjectExtraInfoDto } from "../../../../Models/IBMProjectItem";

const { Meta } = Card;

interface IProps {
    currentProject: BmProject;
    isLoading: boolean;
    changeCurrentHelpPageCode: (pageName: string) => any;
    updateBmProjectExtraInfo: (model: IUpdateBmProjectExtraInfoDto) => any;
};

function CurrentProjectCard(props: IProps) {
    const [form] = Form.useForm();

    useEffect(() => {
        props.changeCurrentHelpPageCode(HelpPageCodes.CurrentProject);
    }, []);
    useEffect(() => {

    }, [props.currentProject]);

    let navigate = useNavigate();

    const { t } = useTranslation();
    interface DataType {
        key: string;
        levers: string;
        project: string;
        ebit: string;
    }
    const columns: ColumnsType<DataType> = [
        {
            title: <div className="current_project_table_title_div">{t("Levers B-M Project")}</div>,
            dataIndex: 'levers',
            key: 'name',
            align: 'center',
            className: 'current_project_table_col'
        },
        {
            title: <div className="current_project_table_title_div">{t("Project")}</div>,
            dataIndex: 'project',
            key: 'project',
            align: 'center',
            className: 'current_project_table_col'
        },
        {
            title: <div className="current_project_table_title_div">{t("EBIT B-M Project")}</div>,
            dataIndex: 'ebit',
            key: 'ebit',
            align: 'center',
            className: 'current_project_table_col'
        }
    ];
    console.log("props.currentProject?.OtherBmProjects", props.currentProject?.otherBmProjects);
    let data: DataType[] = props.currentProject?.otherBmProjects?.map(bm => {
        return {
            key: bm.id.toString(),
            levers: (bm.isTargetLever != null) ? (bm.isTargetLever == true) ? 'k' : 'x' : '',
            project: `${bm.name}`,
            ebit: (bm.isTargetEbit != null) ? (bm.isTargetEbit == true) ? 'k' : 'x' : '',
        };
    });
    let currentProjectName = props.currentProject?.project_name ?? t('No current project');

    const changeProject = () => {
        navigate("/changeProject");
    };
    const editLevers = () => {
        navigate("/editLevers");
    };
    const editEbit = () => {
        navigate("/editEbit");
    };
    const gridStyle: React.CSSProperties = {
        width: '25%',
        textAlign: 'center',
    };
    const onfinish = (values) => {

        props.updateBmProjectExtraInfo({
            consultant: values.consultant,
            projectManager: values.projectManager,
            contactCompany: values.contactCompany
        });
    }
    return (
        <>
            {(props.isLoading || props.currentProject == null) ? <></> :
                <div>
                    <Row gutter={16} className="current_project_card_wrapper current_project-responsive-part1 ">
                        <Col  xl={16} span={24}>
                            <Card className="current_project_card" style={{ padding: "10px", marginBottom: "10px", height: "310px" }}>
                                <Row gutter={16}>
                                    <Col className="gutter-row header-current-proejct" span={2}>
                                    </Col>
                                    <Col className="gutter-row header-current-proejct" span={6}>
                                        <div>{t("Sales")}</div>
                                    </Col>
                                    <Col className="gutter-row header-current-proejct" span={6}>
                                        <div>{t("EBIT absolute")}</div>
                                    </Col>
                                    <Col className="gutter-row header-current-proejct" span={6}>
                                        <div className="">{t("EBIT ratio")}</div>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row side-current-proejct" span={2}>
                                        <div >{props.currentProject?.yearUnderReview}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.sales)}`}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.ebitTodayCost)}`}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.ebitTodayPercentage)}%`}</div>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row side-current-proejct" span={2}>
                                        <div >{props.currentProject?.yearUnderReview - 1}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.sales_1)}`}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.ebitCost_1)}`}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.ebitPercentage_1)}%`}</div>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row side-current-proejct" span={2}>
                                        <div >{props.currentProject?.yearUnderReview - 2}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.sales_2)}`}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.ebitCost_2)}`}</div>
                                    </Col>
                                    <Col className="gutter-row current-project-value" span={6}>
                                        <div >{`${formatValue(props.currentProject?.ebitPercentage_2)}%`}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col  xl={8} span={24}>
                            <Card className="current_project_card" style={{ padding: "10px", marginBottom: "10px", height: "310px" }} >
                                <Form layout="vertical" form={form} onFinish={onfinish}
                                    initialValues={{
                                        projectManager: props.currentProject?.projectManager,
                                        consultant: props.currentProject?.consultant,
                                        contactCompany: props.currentProject?.contactCompany
                                    }}
                                >
                                    <Form.Item label={`${t("Project Manager")}`} name='projectManager'
                                        rules={RulesDescription({ name: `${t("Project Manager")}`, countChar: 128 })}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label={`${t("Consultant")}`} name='consultant'
                                        rules={RulesDescription({ name: `${t("Consultant")}`, countChar: 128 })}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label={`${t("Contact Company")}`} name='contactCompany'
                                        rules={RulesDescription({ name: `${t("Contact Company")}`, countChar: 128 })}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="current-project-submit-btn">
                                        <Button type="primary" htmlType="submit">
                                            {`${t("Submit")}`}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
            <Row gutter={16} className="current_project_card_wrapper current_project-responsive-part2">
                <Col span={24}>
                    <Card title={currentProjectName} extra={<div className="current_project_card_title_Btn">
                        <div className="Current_project_footer_Btns">
                            <Button disabled={props.currentProject === null} onClick={editLevers} className="Edit_Levers_Btn">{t("Edit Levers B-M")}</Button>
                            <Button disabled={props.currentProject === null} onClick={editEbit} className="Edit_EBIT_Btn">{t("Edit EBIT B-M")}</Button>
                            <Button className="change_project_Btn" onClick={changeProject}>{t("Change Project")}</Button>
                        </div>
                    </div>}
                        className="current_project_card" >
                        <Table
                            className="current_project_table"
                            bordered
                            pagination={false}
                            columns={columns}
                            dataSource={data}
                            scroll={{ y: 'calc(100vh - 500px)' }}
                            locale={tableLocalTranslate()}
                        />
                    </Card>
                </Col>
            </Row></>
    );
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        //projects: state.generalInfo.allProjects,
        //currentProject: state.projects.currentProject
        currentProject: state.generalInfo.profileResponse?.currentProject,
        isLoading: state.generalInfo.isLoading
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        changeCurrentHelpPageCode: (pageName: string) => dispatch({ type: ActionTypes.ChangeHelpPageCode, result: pageName }),
        updateBmProjectExtraInfo: (model: IUpdateBmProjectExtraInfoDto) => dispatch({ type: ActionTypes.UpdateBmProjectExtraInfo, model: model })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentProjectCard);