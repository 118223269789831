import { IDProject } from "./IDProject";

export interface IRequestDProject {
    generalDprojectInfo: IRequestGeneralDprojectInfo,
    innovation?: IRequestInnovation,
    costOfDelay?: IRequestCostOfDelay,
    manufacturingCost?: IRequestManufacturingCost,
    qualityCost?: IRequestQualityCost,
    questionaire?: IRequestQuestionaire
};

export interface IRequestGeneralDprojectInfo {
    projectId: number;
    id: number;
    key: number;
    name: string;
    finishingYear: number;//string;
    runTime: number;
    //projectType: string; //TODO delete this
    actualProjectCosts: number;
    comments: { id: number, userId: number, name: string, text: string }[];
};

export interface IRequestInnovation {
    dprojectId: number,
    revenue: number;
    productMargin: number;//string;
    projectType: number;//string; //TODO uncomment this
}

export interface IRequestCostOfDelay {
    dprojectId: number,
    delay: number;
    missedExpectedSales: number;
    projectCostsDelayedMonths: number;
    otherAdditionalCosts: number;
}

export interface IRequestManufacturingCost {
    dprojectId: number,
    MCPlanned: number;
    MCActual: number;
    quantity: number;
    otherCosts: number;
    MCShareOfRD: number;//string;
}

export interface IRequestQualityCost {
    dprojectId: number,
    qualityCosts: number;
    qualityShareOfRD: number;//string;
}

export interface IRequestQuestionaire {
    dprojectId: number,
    q1_Total_Sales_in_N: number;
    q1_Revenue_share: number;//string;

    q2_Total_Sales_in_N: number;
    q2_Revenue_share: number;//string;

    q3_Total_Sales_in_N: number;
    q3_Revenue_share: number;//string;

    q4_Target_price: number;
    q4_Actual_price: number;
    q4_Deviation: number;//string;

    q5_Planned_quantities: number;
    q5_Actual_quantities: number;
    q5_Deviation: number;//string;

    q6_Planned_project_costs: number;
    q6_Deviation: number;//string;

    q7_customer_experience: number;

    q8_technical_safeguarding: number;

    q9_tool_dropped_parts: number;

    q10_series_production: number;

    q11_costs_last_3months: number;

    q12_costs_last_3months: number;

    q13_Average_delay: number;

    q14_One_time_yield_rate: number;

    q15_Claim_Tickets: number;

    q16_Complaint_rate: number;

    q17_Planned_revenue: number;
    q17_Actual_revenue: number;
    q17_Deviation: number;//string;

    q18_Support_requests: number;

    q19_Planned_project_runtime: number;
    q19_Deviation: number;//string;

    q20_features_relaized: number;

    q21_Budget_Deviation: number;//string;
    q22_MC_Deviation: number;//string;

    q4522_Subcontractor_in_the_project: number;
}

const transformEmptyStringsToNulls = (dproject: IDProject): IDProject => {
    let outDproject: IDProject = dproject;
    let dprojectsKeys = Object.keys(dproject);
    dprojectsKeys.forEach(key => {
        outDproject[key] = (dproject[key] === '') ? undefined : dproject[key];
    });
    return outDproject;
};

export const IDprojectToIRequestDproject = (dproject_: IDProject): IRequestDProject => {
    // transform empty strings to nulls.
    let dproject = transformEmptyStringsToNulls(dproject_);
    //console.log('dproject after transform empty strings to nulls: ', dproject);

    let generalDprojectInfo: IRequestGeneralDprojectInfo = {
        projectId: dproject.projectId,
        id: dproject.id,
        key: dproject.key,
        name: dproject.name,
        finishingYear: dproject.finishingYear,
        //projectType: dproject.projectType, //TODO delete this. it is not the correct place
        runTime: dproject.runTime,
        actualProjectCosts: dproject.actualProjectCosts,
        comments: [...dproject.comments]
    }
    let innovation: IRequestInnovation = ((typeof dproject.revenue === 'undefined') ||
        (typeof dproject.productMargin === 'undefined') ||
        (typeof dproject.projectType === 'undefined')) ?
        undefined : {
            dprojectId: dproject.id,
            revenue: dproject.revenue,
            productMargin: dproject.productMargin,
            projectType: dproject.projectType //TODO here is the correct place for projectType
        }
    let iCostOfDelay: IRequestCostOfDelay = ((typeof dproject.delay === 'undefined') ||
        (typeof dproject.missedExpectedSales === 'undefined') ||
        (typeof dproject.projectCostsDelayedMonths === 'undefined') ||
        (typeof dproject.otherAdditionalCosts === 'undefined')) ? undefined : {
        dprojectId: dproject.id,
        delay: dproject.delay,
        missedExpectedSales: dproject.missedExpectedSales,
        projectCostsDelayedMonths: dproject.projectCostsDelayedMonths,
        otherAdditionalCosts: dproject.otherAdditionalCosts
    }
    let iManufacturingCost: IRequestManufacturingCost = ((typeof dproject.mcPlanned === 'undefined') ||
        (typeof dproject.mcActual === 'undefined') ||
        (typeof dproject.quantity === 'undefined') ||
        (typeof dproject.otherCosts === 'undefined') ||
        (typeof dproject.mcShareOfRD === 'undefined')) ? undefined : {
        dprojectId: dproject.id,
        MCPlanned: dproject.mcPlanned,
        MCActual: dproject.mcActual,
        quantity: dproject.quantity,
        otherCosts: dproject.otherCosts,
        MCShareOfRD: dproject.mcShareOfRD,
    }
    let iQualityCost: IRequestQualityCost = ((typeof dproject.qualityCosts === 'undefined') ||
        (typeof dproject.qualityShareOfRD === 'undefined')) ? undefined : {
        dprojectId: dproject.id,
        qualityCosts: dproject.qualityCosts,
        qualityShareOfRD: dproject.qualityShareOfRD
    }

    let iQuestionaire: IRequestQuestionaire = {
        dprojectId: dproject.id,
        q1_Total_Sales_in_N: dproject.q1_Total_Sales_in_N,
        q1_Revenue_share: dproject.q1_Revenue_share,

        q2_Total_Sales_in_N: dproject.q2_Total_Sales_in_N,
        q2_Revenue_share: dproject.q2_Revenue_share,

        q3_Total_Sales_in_N: dproject.q3_Total_Sales_in_N,
        q3_Revenue_share: dproject.q3_Revenue_share,

        q4_Target_price: dproject.q4_Target_price,
        q4_Actual_price: dproject.q4_Actual_price,
        q4_Deviation: dproject.q4_Deviation,

        q5_Planned_quantities: dproject.q5_Planned_quantities,
        q5_Actual_quantities: dproject.q5_Actual_quantities,
        q5_Deviation: dproject.q5_Deviation,

        q6_Planned_project_costs: dproject.q6_Planned_project_costs,
        q6_Deviation: dproject.q6_Deviation,

        q7_customer_experience: dproject.q7_customer_experience,

        q8_technical_safeguarding: dproject.q8_technical_safeguarding,

        q9_tool_dropped_parts: dproject.q9_tool_dropped_parts,

        q10_series_production: dproject.q10_series_production,

        q11_costs_last_3months: dproject.q11_costs_last_3months,

        q12_costs_last_3months: dproject.q12_costs_last_3months,

        q13_Average_delay: dproject.q13_Average_delay,

        q14_One_time_yield_rate: dproject.q14_One_time_yield_rate,

        q15_Claim_Tickets: dproject.q15_Claim_Tickets,

        q16_Complaint_rate: dproject.q16_Complaint_rate,

        q17_Planned_revenue: dproject.q17_Planned_revenue,
        q17_Actual_revenue: dproject.q17_Actual_revenue,
        q17_Deviation: dproject.q17_Deviation,

        q18_Support_requests: dproject.q18_Support_requests,

        q19_Planned_project_runtime: dproject.q19_Planned_project_runtime,
        q19_Deviation: dproject.q19_Deviation,

        q20_features_relaized: dproject.q20_features_relaized,

        q21_Budget_Deviation: dproject.q21_Budget_Deviation,
        q22_MC_Deviation: dproject.q22_MC_Deviation,

        q4522_Subcontractor_in_the_project: dproject.q4522_Subcontractor_in_the_project
    }

    let iRequestDproject: IRequestDProject = {
        generalDprojectInfo: { ...generalDprojectInfo },
        //innovation: { ...innovation },
        //costOfDelay: { ...iCostOfDelay },
        //manufacturingCost: { ...iManufacturingCost },
        //qualityCost: { ...iQualityCost },
        //questionaire: { ...iQuestionaire },
    }
    if (typeof innovation !== 'undefined') {
        iRequestDproject.innovation = { ...innovation };
    }
    if (typeof iCostOfDelay !== 'undefined') {
        iRequestDproject.costOfDelay = { ...iCostOfDelay };
    }
    if (typeof iManufacturingCost !== 'undefined') {
        iRequestDproject.manufacturingCost = { ...iManufacturingCost };
    }
    if (typeof iQualityCost !== 'undefined') {
        iRequestDproject.qualityCost = { ...iQualityCost };
    }
    if (typeof iQuestionaire !== 'undefined') {
        iRequestDproject.questionaire = { ...iQuestionaire }
    }
    return iRequestDproject;
}