
import { useState, useEffect } from 'react';
//import { Line } from '@ant-design/plots';
import { FunctionTypes } from '../../../../Enums/FunctionTypes';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { formatValueWithNoDefaultFractions } from '../../../../Utils/helperFunctions';

interface InfoGraphicsProps {
    piUpper: number;
    piLower: number;
    bestPractice: number;
    functionTypeId: number;
    lowRange: number;
    hightRange: number;
    xAxisUnit: string;
}

const LeverInfoGraphicEvaluationChart = (props: InfoGraphicsProps) => {
    const dataLinerChart = [
        {
            x_value: props.piLower,//BP-
            y_value: 0,
        },

        {
            x_value: props.piUpper,//pi+ == BP
            y_value: 100,
        }
    ];
    const dataLinerBisChart = [
        {
            x_value: formatValueWithNoDefaultFractions(props.piLower),
            y_value: 0,
        },

        {
            x_value: formatValueWithNoDefaultFractions(props.bestPractice),
            y_value: 100,
        },

        {
            x_value: formatValueWithNoDefaultFractions(props.hightRange),
            y_value: 100,
        },
    ];

    const dataLinerBis2Chart = [
        {
            x_value: formatValueWithNoDefaultFractions(props.piLower),
            y_value: 100,
        },

        {
            x_value: formatValueWithNoDefaultFractions(props.bestPractice),
            y_value: 100,
        },

        {
            x_value: formatValueWithNoDefaultFractions(props.piUpper),
            y_value: 0,
        },
    ];

    const dataDachChart = [
        {
            x_value: formatValueWithNoDefaultFractions(props.piLower),
            y_value: 0,
        },
        {
            x_value: formatValueWithNoDefaultFractions(props.bestPractice),
            y_value: 100,
        },
        {
            x_value: formatValueWithNoDefaultFractions(props.piUpper),
            y_value: 0,
        },
    ];

    const data = (props.functionTypeId == FunctionTypes.linear) ? dataLinerChart :
        (props.functionTypeId == FunctionTypes.linear_bis) ? dataLinerBisChart :
            (props.functionTypeId == FunctionTypes.linear_bis_2) ? dataLinerBis2Chart : dataDachChart;


    return (<LineChart width={300} height={250}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>

        <CartesianGrid strokeDasharray="5 5" />

        <XAxis dataKey="x_value" domain={[props.lowRange, props.hightRange]} >
            <Label value={props.xAxisUnit} offset={0} position="insideBottom" />
        </XAxis>
        <YAxis dataKey="y_value" domain={[0, 100]}>
            <Label value={'%'} offset={0} position="insideLeft" />
        </YAxis>

        <Line data={data} dataKey="y_value" stroke="#8884d8" />
    </LineChart>); 
 
};

export default LeverInfoGraphicEvaluationChart;
