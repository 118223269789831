
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import FrontLoadingService from "../../Services/ForntLoadingService";
import { IFrontLoadingTempAll } from "../../Models/IFrontLoading";

function* OnGetAllFrontLoadingTemp(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: true });
        let _result: ICommonResponse = yield FrontLoadingService.GetAllFrontLoadingTemp();
        if (!_result.isError) {
            let result = _result.result as IFrontLoadingTempAll[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGet: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnCreateFrontLoadingTemp(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield FrontLoadingService.CreateFrontLoadingTemp(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield FrontLoadingService.GetAllFrontLoadingTemp();
            let result = _result.result as IFrontLoadingTempAll[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result:false });
    } catch (error) {
        console.error("ERR in onCreate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateFrontLoadingTemp(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield FrontLoadingService.UpdateFrontLoadingTemp(action.model)
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate })
            let _result: ICommonResponse = yield FrontLoadingService.GetAllFrontLoadingTemp();
            let result = _result.result as IFrontLoadingTempAll[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteFrontLoadingTemp(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultDelete: ICommonResponse = yield FrontLoadingService.deleteFrontLoadingTemp(action.id);
        if (!resultDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
            let _result: ICommonResponse = yield FrontLoadingService.GetAllFrontLoadingTemp();
            let result = _result.result as IFrontLoadingTempAll[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDelete: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
//template Phase

function* OnCreateFrontLoadingTempPhase(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield FrontLoadingService.CreateFrontLoadingTempPhase(
            action.model
        );
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield FrontLoadingService.GetAllFrontLoadingTemp();
            let result = _result.result as IFrontLoadingTempAll[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateFrontLoadingTempPhase(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield FrontLoadingService.UpdateFrontLoadingTempPhase(action.model)
        if (!resultUpdate.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
            let _result: ICommonResponse = yield FrontLoadingService.GetAllFrontLoadingTemp();
            let result = _result.result as IFrontLoadingTempAll[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result:resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdate: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteFrontLoadingTempPhase(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resulDelete: ICommonResponse = yield FrontLoadingService.deleteFrontLoadingTempPhase(action.id);
        if (!resulDelete.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resulDelete });
            let _result: ICommonResponse = yield FrontLoadingService.GetAllFrontLoadingTemp();
            let result = _result.result as IFrontLoadingTempAll[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resulDelete });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onDelete: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}


function* watchGetAllFrontLoadingTemp() {
    yield takeEvery(ActionTypes.GET_ALL_FRONT_LOADING_TEMP, OnGetAllFrontLoadingTemp);
}
function* watchCreateFrontLoadingTemp() {
    yield takeEvery(ActionTypes.CREATE_FRONT_LOADING_TEMP, OnCreateFrontLoadingTemp);
}
function* watchUpdateFrontLoadingTemp() {
    yield takeEvery(ActionTypes.UPDATE_FRONT_LOADING_TEMP, onUpdateFrontLoadingTemp);
}
function* watchDeleteFrontLoadingTemp() {
    yield takeEvery(ActionTypes.DELETE_FRONT_LOADING_TEMP, onDeleteFrontLoadingTemp);
}
//template Phase
function* watchCreateFrontLoadingTempPhase() {
    yield takeEvery(ActionTypes.CREATE_FRONT_LOADING_TEMP_PHASE, OnCreateFrontLoadingTempPhase);
}
function* watchUpdateFrontLoadingTempPhase() {
    yield takeEvery(ActionTypes.UPDATE_FRONT_LOADING_TEMP_PHASE, onUpdateFrontLoadingTempPhase);
}
function* watchDeleteFrontLoadingTempPhaser() {
    yield takeEvery(ActionTypes.DELETE_FRONT_LOADING_TEMP_PHASE, onDeleteFrontLoadingTempPhase);
}



export default function* FrontloadingTemplateSagas() {
    yield all([
        watchGetAllFrontLoadingTemp(),
        watchCreateFrontLoadingTemp(),
        watchUpdateFrontLoadingTemp(),
        watchDeleteFrontLoadingTemp(),
        watchCreateFrontLoadingTempPhase(),
        watchUpdateFrontLoadingTempPhase(),
        watchDeleteFrontLoadingTempPhaser()
    ]);
}
