import { ILever, ISubLeverOnEbit } from "../../Models/Levers/Items";
import { takeEvery, all, put } from "redux-saga/effects";
// here we import all async services to be called as sagas (generator functions)
//import BenchmarkingService from '../../Services/BenchMarking/BenchmarckingService';
//import { BenchmarckingData } from '../../Models/BenchmarkingData';
import { ActionTypes } from "../actionsTypes";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import LeverService from "../../Services/LeverService";
import { ToastMessages } from "../../Enums/Messages";
import { UsersInDataCollectionRoleDto } from "../../Models/Requests/CustomerUsers";
import { EasyAccessDto } from "../../Models/EasyAccessLever/IEasyAccessLever";
import CustomerUsersService from "../../Services/CustomerUsersService";
import { BmProject } from "../../Models/Responses/ProfileResponse";
import BmProjectService from "../../Services/BmProjectsService";

function* onImportCustomerUser(action: any) {
  try {
    //yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: true });
    yield put({
      type: ActionTypes.getDataCollectionRolesLoader,
      isLoading: true,
    });
    let _result: ICommonResponse = yield CustomerUsersService.ImportUserTable(
      action.frmData
    );
    if (!_result.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
      yield put({ type: ActionTypes.GetDataCollectionRoleWithUsers });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
  } catch (error) {
    console.error("ERR in onImportDepartment: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
  yield put({
    type: ActionTypes.getDataCollectionRolesLoader,
    isLoading: false,
  });
  //yield put({ type: ActionTypes.CHANGE_CUSTOMERUSER_LOADER, isLoading: false });
}

function* onGetEasyAccessLevers(action: any) {
  try {
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
    let _result: ICommonResponse = yield LeverService.GetEasyAccessLevers(
      action.filter
    );
    if (!_result.isError) {
      let result = _result.result as EasyAccessDto;
      yield put({
        type: ActionTypes.SaveGetEasyAccessLeversResults,
        result: result,
      });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
  } catch (error) {
    console.error("ERR in onGetLevers: ", error);
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* GetBmProjectWithAllData(action: any) {
    try {
        console.log("MMousa GetBmProjectWithAllData", action.projectID);
        // yield put({ type: ActionTypes.BenchmarkProjectsLoader, result: true });
        let _result2: ICommonResponse = yield BmProjectService.GetBmProjectWithAllData(action.projectID);
        if (!_result2.isError) {
            let result2 = _result2.result as BmProject;
            yield put({ type: ActionTypes.SaveBmProject, result: result2 });
            // yield put({ type: ActionTypes.BenchmarkProjectsLoader, result: false });
        }
    }
    catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }
}

function* onMarkKpiInEasyAccessLevers(action: any) {
  try {
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
    let _result: ICommonResponse = yield LeverService.MarkKpiInEasyAccessLevers(
      action.model
    );
    //if (!_result.isError) {
    //let result = (_result.result as EasyAccessDto);
    //yield put({ type: ActionTypes.SaveGetEasyAccessLeversResults, result: result });
    //} else {
    //yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    //}
    yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
  } catch (error) {
    console.error("ERR in onGetLevers: ", error);
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* refreshLevers(action: any) {
  try {
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
    let _result: ICommonResponse = yield LeverService.GetLevers();

    if (!_result.isError) {
      let result = (_result.result as ILever[]).sort((a, b) =>
        a.id < b.id ? -1 : 1
      );
      yield put({ type: ActionTypes.SaveGetLeversResults, result: result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
  } catch (error) {
    console.error("ERR in onGetLevers: ", error);
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onGetLevers(action: any) {
  yield refreshLevers(action);
}

function* onSaveLeversByConsultant(action: any) {
  try {
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
    let _result: ICommonResponse = yield LeverService.SaveLeversByConsultant(
      action.model
    );

    if (!_result.isError) {
      yield refreshLevers(action);
      // let result = (_result.result as ILever[]).sort((a, b)=> a.id < b.id ? -1 : 1);
      // yield put({ type: ActionTypes.SaveGetLeversResults, result: result });
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
      //yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bmProjectId });
      //yield put({ type: ActionTypes.GetHomePage, result: _result, applyRedirect: false });
      //yield put({ type: ActionTypes.GetAllBenchmarkProjects });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
  } catch (error) {
    console.error("ERR in onSaveLeversByConsultant: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onSaveEasyAccessLeversByConsultant(action: any) {
  try {
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
    let _result: ICommonResponse =
      yield LeverService.SaveEasyAccessLeversByConsultant(action.model);
    if (!_result.isError) {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
  } catch (error) {
    console.error("ERR in onSaveEasyAccessLeversByConsultant: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
  yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
}

function* onSaveLeversByCustomer(action: any) {
  try {
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
    let _result: ICommonResponse = yield LeverService.SaveLeversByCustomer(
      action.model
    );

    if (!_result.isError) {
      // let result = (_result.result as ILever[]).sort((a, b)=> a.id < b.id ? -1 : 1);
      // yield put({ type: ActionTypes.SaveGetLeversResults, result: result });
      yield put({
        type: ActionTypes.GETDPROJECTS,
        bmProjectId: action.bmProjectId,
      });
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
  } catch (error) {
    console.error("ERR in onSaveLeversByCustomer: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onSaveCustomerUsersInQuestion(action: any) {
  try {
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse =
      yield LeverService.SaveCustomerUsersInQuestion(action.model);

    if (!_result.isError) {
      let _result2: ICommonResponse = yield LeverService.GetLevers();
      if (!_result2.isError) {
        let result2 = (_result2.result as ILever[]).sort((a, b) =>
          a.id < b.id ? -1 : 1
        );
        yield put({ type: ActionTypes.SaveGetLeversResults, result: result2 });
        yield put({ type: ActionTypes.SetModalResponse, result: _result });
      } else {
        yield put({ type: ActionTypes.SetModalResponse, result: _result });
      }
    } else {
      yield put({ type: ActionTypes.SetModalResponse, result: _result });
    }
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onSaveCustomerUsersInQuestion: ", error);
    yield put({
      type: ActionTypes.SetModalResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onRemoveCustomerUserFromQuestion(action: any) {
  try {
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse =
      yield LeverService.RemoveCustomerUserFromQuestion(action.model);

    if (!_result.isError) {
      let _result2: ICommonResponse = yield LeverService.GetLevers();
      if (!_result2.isError) {
        let result2 = (_result2.result as ILever[]).sort((a, b) =>
          a.id < b.id ? -1 : 1
        );
        yield put({ type: ActionTypes.SaveGetLeversResults, result: result2 });
        yield put({ type: ActionTypes.SetModalResponse, result: _result });
      } else {
        yield put({ type: ActionTypes.SetModalResponse, result: _result });
      }
    } else {
      yield put({ type: ActionTypes.SetModalResponse, result: _result });
    }
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onSaveCustomerUsersInQuestion: ", error);
    yield put({
      type: ActionTypes.SetModalResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onRemindCustomerUserForSelectedQuestion(action: any) {
  try {
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse =
      yield LeverService.RemindCustomerUserForSelectedQuestion(action.model);
    yield put({ type: ActionTypes.SetModalResponse, result: _result });
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onSaveCustomerUsersInQuestion: ", error);
    yield put({
      type: ActionTypes.SetModalResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onReviewQuestion(action: any) {
  try {
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse =
      yield LeverService.ReviewQuestionAnswerByConsultant(action.model);

    if (!_result.isError) {
      yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
      let _result2: ICommonResponse = yield LeverService.GetLevers();
      if (!_result2.isError) {
        let result2 = (_result2.result as ILever[]).sort((a, b) =>
          a.id < b.id ? -1 : 1
        );
        yield put({ type: ActionTypes.SaveGetLeversResults, result: result2 });
        yield put({ type: ActionTypes.SetCommonResponse, result: _result });
      } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _result2 });
      }
      yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onReviewQuestion: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onAddcommentToQuestion(action: any) {
  try {
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse =
      yield LeverService.AddcommentToQuestionByConsultant(action.model);

    if (!_result.isError) {
      yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
      let _result2: ICommonResponse = yield LeverService.GetLevers();
      if (!_result2.isError) {
        let result2 = (_result2.result as ILever[]).sort((a, b) =>
          a.id < b.id ? -1 : 1
        );
        yield put({ type: ActionTypes.SaveGetLeversResults, result: result2 });
        yield put({ type: ActionTypes.SetCommonResponse, result: _result });
      } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _result2 });
      }
      yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onReviewQuestion: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onDeletecommentToQuestion(action: any) {
  try {
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse = yield LeverService.DeleteQuestionComment(
      action.id
    );

    if (!_result.isError) {
      yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
      let _result2: ICommonResponse = yield LeverService.GetLevers();
      if (!_result2.isError) {
        let result2 = (_result2.result as ILever[]).sort((a, b) =>
          a.id < b.id ? -1 : 1
        );
        yield put({ type: ActionTypes.SaveGetLeversResults, result: result2 });
        yield put({ type: ActionTypes.SetCommonResponse, result: _result });
      } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _result2 });
      }
      yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onReviewQuestion: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onEditcommentToQuestion(action: any) {
  try {
    yield put({ type: ActionTypes.SetModalLoader, result: true });
    let _result: ICommonResponse = yield LeverService.EditcommentToQuestion(
      action.model
    );

    if (!_result.isError) {
      yield put({ type: ActionTypes.changeLeverLoader, isLoading: true });
      let _result2: ICommonResponse = yield LeverService.GetLevers();
      if (!_result2.isError) {
        let result2 = (_result2.result as ILever[]).sort((a, b) =>
          a.id < b.id ? -1 : 1
        );
        yield put({ type: ActionTypes.SaveGetLeversResults, result: result2 });
        yield put({ type: ActionTypes.SetCommonResponse, result: _result });
      } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _result2 });
      }
      yield put({ type: ActionTypes.changeLeverLoader, isLoading: false });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({ type: ActionTypes.SetModalLoader, result: false });
  } catch (error) {
    console.error("ERR in onReviewQuestion: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}
function* onExportSelectedChartsToExcel(action: any) {
  console.log("onExportSelectedChartsToExcel-action: ", action);

  yield put({ type: ActionTypes.ExportingSelectedChartsToExcel, value: true });
  let result;
  try {
    result = yield LeverService.ExportSelectedChartsToExcel(
      action.exportChartsToExcelModel,
      action.fileName
    );
    console.log("ExportSelectedChartsToExcel-result: ", result);
    yield put({
      type: ActionTypes.ExportingSelectedChartsToExcel,
      value: false,
    });
  } catch (error) {
    console.log("ExportSelectedChartsToExcel-error: ", error);
    yield put({
      type: ActionTypes.ExportingSelectedChartsToExcel,
      value: false,
    });
  }
}

function* onExportAllChartsToExcel(action: any) {
  console.log("onExportAllChartsToExcel-action: ", action);
  yield put({ type: ActionTypes.ExportingAllChartsToExcel, value: true });
  let result;
  try {
    result = yield LeverService.ExportAllChartsToExcel(
      action.fileName
    );
    console.log("ExportAllChartsToExcel-result: ", result);
    yield put({
      type: ActionTypes.ExportingAllChartsToExcel,
      value: false,
    });
  } catch (error) {

  }
}

function* onGetDataCollectionRoleWithUsers(action: any) {
  try {
    yield put({
      type: ActionTypes.getDataCollectionRolesLoader,
      isLoading: true,
    });
    let _result: ICommonResponse =
      yield LeverService.GetDataCollectionRoleWithUsers();

    if (!_result.isError) {
      let result = _result.result as UsersInDataCollectionRoleDto;
      yield put({
        type: ActionTypes.SaveDataCollectionRoleWithUsersResults,
        result: result,
      });
    } else {
      yield put({ type: ActionTypes.SetCommonResponse, result: _result });
    }
    yield put({
      type: ActionTypes.getDataCollectionRolesLoader,
      isLoading: false,
    });
  } catch (error) {
    console.error("ERR in onGetDataCollectionRoleWithUsers: ", error);
    yield put({
      type: ActionTypes.SetCommonResponse,
      result: {
        isError: true,
        statusCode: 600,
        error: { exceptionMessage: ToastMessages.UnExpectedError },
      } as ICommonResponse,
    });
  }
}

function* onExportHeatMapToExcel(action: any) {
  yield put({ type: ActionTypes.ExportingHeatMap, value: true });
  let result;
  try {
    result = yield LeverService.ExportHeatMapToExcel(action.fileName);
    console.log("ExportSelectedChartsToExcel-result: ", result);
    yield put({ type: ActionTypes.ExportingHeatMap, value: false });
  } catch (error) {
    console.log("ExportSelectedChartsToExcel-error: ", error);
    yield put({ type: ActionTypes.ExportingHeatMap, value: false });
  }
}

function* onGetLeversProgress(action: any) {
  try {
    yield put({ type: ActionTypes.LeversWithEbitChartLoader, isLoading: true });
    let _result: ICommonResponse = yield LeverService.GetLeversProgress();
    if (!_result.isError) {
      let result = _result.result as number;
      yield put({ type: ActionTypes.SetLeversProgressValue, result: result });
    }
    yield put({
      type: ActionTypes.LeversWithEbitChartLoader,
      isLoading: false,
    });
  } catch (error) {
    console.error("onGetLeversProgress", error);
  }
}
function* onGetLeversWithEbitChart(action: any) {
  try {
    let _result: ICommonResponse = yield LeverService.GetLeversWithEbitChart();
    if (!_result.isError) {
      let result = _result.result as ISubLeverOnEbit[];
      yield put({ type: ActionTypes.SetSubleversWithEbit, result: result });
    }
  } catch (error) {
    console.error("onGetLeversProgress", error);
  }
}

function* onGetLeversKpiWithEbitChart(action: any) {
  try {
    let _result: ICommonResponse =
      yield LeverService.GetLeversKpiWithEbitChart();
    if (!_result.isError) {
      let result = _result.result as ISubLeverOnEbit[];
      yield put({ type: ActionTypes.SetKpileversWithEbit, result: result });
    }
  } catch (error) {
    console.error("onGetLeversProgress", error);
  }
}

function* onChangeCollectionRole(action: any) {
  yield put({ type: ActionTypes.SetCollectionRole, result: action.model });
}
function* onChangeQuestionStatuses(action: any) {
  yield put({ type: ActionTypes.SetQuestionStatuses, result: action.model });
}

function* onChangeLevers(action: any) {
  yield put({ type: ActionTypes.SetGetLeversResults, result: action.model });
}

function* onChangeEasyAccessLevers(action: any) {
  yield put({
    type: ActionTypes.SaveGetEasyAccessLeversResults,
    result: action.model,
  });
}

function* onChangeLever(action: any) {
  yield put({ type: ActionTypes.SetGetLeverResult, result: action.model });
  //yield put({ type: ActionTypes.ChageLeverInCurrentProject, result: action.model });
}

function* onSetSelectedQuestions(action: any) {
  yield put({
    type: ActionTypes.SetSelectedQuestions,
    questionId: action.questionId,
  });
}
function* onSetAllSelectedQuestions(action: any) {
  yield put({
    type: ActionTypes.SetAllSelectedQuestions,
    selectedAll: action.selectedAll,
  });
}

function* onAddCommentToLeverQuestion(action: any) {
  yield put({ type: ActionTypes.SetGetLeversResults, result: action.model });
}

function* watchSaveLeversByCustomer() {
  yield takeEvery(ActionTypes.SaveAllLevers, onSaveLeversByCustomer);
}

function* watchSaveLeversByConsultant() {
  yield takeEvery(
    ActionTypes.SaveAllLeversByConsultant,
    onSaveLeversByConsultant
  );
}

function* watchSaveCustomerUsersInQuestion() {
  yield takeEvery(
    ActionTypes.SaveCustomerUsersInQuestion,
    onSaveCustomerUsersInQuestion
  );
}

function* watchGetLevers() {
  yield takeEvery(ActionTypes.GetLevers, onGetLevers);
}

function* watchGetDataCollectionRoleWithUsers() {
  yield takeEvery(
    ActionTypes.GetDataCollectionRoleWithUsers,
    onGetDataCollectionRoleWithUsers
  );
}

function* watchChangeLever() {
  yield takeEvery(ActionTypes.ChangeLever, onChangeLever);
}
function* watchChangeLevers() {
  yield takeEvery(ActionTypes.ChangeLevers, onChangeLevers);
}
function* watchChangeEasyAccessLevers() {
  yield takeEvery(ActionTypes.ChangeEasyAccessLevers, onChangeEasyAccessLevers);
}

function* watchChangeCollectionRoles() {
  yield takeEvery(ActionTypes.ChangeCollectionRole, onChangeCollectionRole);
}

function* watchChangeQuestionStatuses() {
  yield takeEvery(ActionTypes.ChangeQuestionStatuses, onChangeQuestionStatuses);
}

function* watchAddCommentToLeverQuestion() {
  yield takeEvery(
    ActionTypes.AddCommentToQuestion,
    onAddCommentToLeverQuestion
  );
}
function* watchReviewQuestion() {
  yield takeEvery(ActionTypes.ReviewQuestionByConsultant, onReviewQuestion);
}

function* watchAddcommentToQuestion() {
  yield takeEvery(
    ActionTypes.AddcommentToQuestionByConsultant,
    onAddcommentToQuestion
  );
}

function* watchEditcommentToQuestion() {
  yield takeEvery(
    ActionTypes.EditcommentToQuestionByConsultant,
    onEditcommentToQuestion
  );
}
function* watchDeletecommentToQuestion() {
  yield takeEvery(
    ActionTypes.DeletecommentToQuestionByConsultant,
    onDeletecommentToQuestion
  );
}

function* watchSetSelectedQuestions() {
  yield takeEvery(ActionTypes.ChangeSelectedQuestions, onSetSelectedQuestions);
}
function* watchSetAllSelectedQuestions() {
  yield takeEvery(
    ActionTypes.ChangeAllSelectedQuestions,
    onSetAllSelectedQuestions
  );
}
function* watchExportSelectedChartsToExcel() {
  yield takeEvery(
    ActionTypes.ExportSelectedChartsToExcel,
    onExportSelectedChartsToExcel
  );
}
function* watchExportAllChartsToExcel() {
  yield takeEvery(ActionTypes.ExportAllChartsToExcel, onExportAllChartsToExcel);
}
function* watchExportHeatMapToExcel() {
  yield takeEvery(ActionTypes.ExportHeatMap, onExportHeatMapToExcel);
}

function* watchRemindCustomerUserForSelectedQuestion() {
  yield takeEvery(
    ActionTypes.RemindCustomerUserForSelectedQuestion,
    onRemindCustomerUserForSelectedQuestion
  );
}

function* watchRemoveCustomerUserFromQuestion() {
  yield takeEvery(
    ActionTypes.RemoveCustomerUserFromQuestion,
    onRemoveCustomerUserFromQuestion
  );
}

function* watchGetLeversProgress() {
  yield takeEvery(ActionTypes.GetLeversProgressValue, onGetLeversProgress);
}

function* watchGetLeversWithEbitChart() {
  yield takeEvery(ActionTypes.GetLeversWithEbitChart, onGetLeversWithEbitChart);
}

function* watchGetLeversKpiWithEbitChart() {
  yield takeEvery(
    ActionTypes.GetLeversKpiWithEbitChart,
    onGetLeversKpiWithEbitChart
  );
}

function* watchGetEasyAccessLevers() {
  yield takeEvery(ActionTypes.GetEasyAccessLevers, onGetEasyAccessLevers);
}

function* watchMarkKpiInEasyAccessLevers() {
  yield takeEvery(
    ActionTypes.MarkKpiInEasyAccessLevers,
    onMarkKpiInEasyAccessLevers
  );
}

function* watchSaveEasyAccessLeversByConsultant() {
  yield takeEvery(
    ActionTypes.SaveEasyAccessLeversByConsultant,
    onSaveEasyAccessLeversByConsultant
  );
}

function* watchImportCustomerUser() {
  yield takeEvery(ActionTypes.ImportCustomerUser, onImportCustomerUser);
}

export default function* leversSagas() {
  yield all([
    watchGetLevers(),
    watchChangeLever(),
    watchAddCommentToLeverQuestion(),
    watchSaveLeversByCustomer(),
    watchSaveLeversByConsultant(),
    watchReviewQuestion(),
    watchSetSelectedQuestions(),
    watchSetAllSelectedQuestions(),
    watchChangeLevers(),
    watchChangeCollectionRoles(),
    watchExportSelectedChartsToExcel(),
    watchExportAllChartsToExcel(),
    watchExportHeatMapToExcel(),
    watchSaveCustomerUsersInQuestion(),
    watchGetDataCollectionRoleWithUsers(),
    watchRemindCustomerUserForSelectedQuestion(),
    watchRemoveCustomerUserFromQuestion(),
    watchGetLeversProgress(),
    watchGetLeversWithEbitChart(),
    watchChangeQuestionStatuses(),
    watchGetLeversKpiWithEbitChart(),
    watchGetEasyAccessLevers(),
    watchChangeEasyAccessLevers(),
    watchSaveEasyAccessLeversByConsultant(),
    watchAddcommentToQuestion(),
    watchEditcommentToQuestion(),
    watchDeletecommentToQuestion(),
    watchMarkKpiInEasyAccessLevers(),
    watchImportCustomerUser(),
  ]);
}
