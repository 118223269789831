import { Form, Input, InputNumber, Popconfirm, Table, Typography, Space } from 'antd';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';

interface Item {
  key: string;
  name: string;
  age: number;
  address: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

interface EditableDataTableProps {
  tableCol: any,
  tableRow: any,
  showAction: boolean,
  fixHeader: boolean,
  showPagination: boolean,
  selectRows: boolean
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
// const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
let executed = false;
const EditableDataTable: React.FC<EditableDataTableProps> = ({
  tableCol,
  tableRow,
  showAction,
  fixHeader,
  showPagination,
  selectRows }) => {
  const [data, setData] = useState<Item[]>([]);
  const [height, setHeight] = useState(10000);

  useEffect(() => {
    setData(tableRow);
    if (fixHeader) {
      setHeight(500)
    }
  }, [tableRow, fixHeader]);
  const handleDelete = (key: String) => {
    const newData = data.filter(item => item.key !== key);
    setData(newData);
  };
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: Item) => record.key === editingKey;
  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ name: '', age: '', address: '', ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      //console.log('Validate Failed:', errInfo);
    }
  };
  if (showAction) {
    const columns = {
      title: 'Actions',
      dataIndex: 'Actions',
      render: (_: any, record: Item) => {

        const editable = isEditing(record);
        return (<Space size={20}>
          {editable ?
            <span>
              <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
            :
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link> 
          }
          {data.length >= 1 ?
            <Popconfirm title={`${t("Sure to delete?")}`} onConfirm={() => handleDelete(record.key)}>
              <a>Delete</a>
            </Popconfirm>
            : ""}
        </Space>);
      },
    };
    if (!executed) {
      executed = true;
      tableCol.push(columns);
    } else {
      tableCol.pop(columns);
      tableCol.push(columns);
    }
  }
  const mergedColumns = tableCol.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Form form={form} component={false} key="test">
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        {...(
          selectRows ?
            { rowSelection } :
            {}
        )}
        bordered
        dataSource={data}
        columns={mergedColumns}
        scroll={{ y: height }}
        rowClassName={() => 'editable-row'}
        pagination={(
          showPagination ?
            { onChange: cancel } :
            false
        )}

      />
    </Form>

  );
};

export default EditableDataTable;