import React from 'react';
import { Tabs } from 'antd';
import AdminUsers from './AdminUsers/AdminUsers';
import ConsultantUsers from './ConsultanUsers/ConsultanUsers';
import "./index.scss";

const Users = () => {
    const items = [
        { label: 'ADMIN', key: 'admin_tab_key', children: <AdminUsers btnTitle="Add Admin" /> }, // remember to pass the key prop
        { label: 'CONSULTANT', key: 'consultant_tab_key', children: <ConsultantUsers btnTitle="Add Consultant" /> },  // remember to pass the key prop
    ];
    return (
        <>
     <Tabs items={items} />
        </>
    )
}

export default Users;