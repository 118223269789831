import { Badge, Rate } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

interface rateValue {
  value: number;
  name: string;
  color: string;
}

export const desc: rateValue[] = [
  // {
  //     value: 0,
  //     name: `${t("Very low")}`,
  //     color: "red"
  // },
  {
    value: 1,
    name: `${t("Very low")}`,
    color: "#FC3E40",
  },
  {
    value: 2,
    name: `${t("Very low")}`,
    color: "#FC3E40",
  },
  {
    value: 3,
    name: `${t("Very low")}`,
    color: "#FC3E40",
  },
  {
    value: 4,
    name: `${t("Sufficient")}`,
    color: "#F7DA46",
  },
  {
    value: 5,
    name: `${t("Sufficient")}`,
    color: "#F7DA46",
  },
  {
    value: 6,
    name: `${t("Sufficient")}`,
    color: "#F7DA46",
  },
  {
    value: 7,
    name: `${t("High")}`,
    color: "#00C04D",
  },
  {
    value: 8,
    name: `${t("High")}`,
    color: "#00C04D",
  },
  {
    value: 9,
    name: `${t("Very high")}`,
    color: "#008631",
  },
  {
    value: 10,
    name: `${t("Very high")}`,
    color: "#008631",
  },
];

interface IProps {
  data: any[];
  dicId: number;
  qValue?: boolean;
  kValue?: boolean;
  rValue?: boolean;
  //setStarsValue: (data: DataType[]) => void;
}
const Starts = (props: IProps) => {
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (props.data) {
      if (props.qValue) {
        setValue(props.data?.find((d) => d.id === props.dicId)?.qValue);
      }
      if (props.kValue) {
        setValue(props.data?.find((d) => d.id === props.dicId)?.kValue);
      }
      if (props.rValue) {
        setValue(props.data?.find((d) => d.id === props.dicId)?.rValue);
      }
    } else {
      return;
    }
    console.log("Starts", value);
  }, [props.data, props.qValue, props.kValue, props.rValue]);
  return (
    <Badge
      count={value ? desc.filter((c) => c.value === value)?.[0].name : ""}
      color={value ? desc.filter((c) => c.value === value)?.[0].color : ""}
      style={{ top: "-4px", right: "-20px" }}
    >
      <Rate
        count={10}
        tooltips={desc.map((c) => c.name)}
        onChange={(value) => {
          let elementIndex = props.data?.findIndex((d) => d.id === props.dicId);
          if (props.data[elementIndex].noInteraction) return;
          let element: any = {
            id: props.dicId,
            qValue: props.qValue ? value : props.data[elementIndex]?.qValue,
            kValue: props.kValue ? value : props.data[elementIndex]?.kValue,
            rValue: props.rValue ? value : props.data[elementIndex]?.rValue,
            comment: props.data[elementIndex]?.comment,
            name: props.data[elementIndex]?.name,
            noInteraction: props.data[elementIndex].noInteraction,
          };
          props.data[elementIndex] = element;
          setValue(value);
          //console.log("onChange", value);
        }}
        disabled={!value === null}
        value={value}
      />
    </Badge>
  );
};

export default Starts;
