
import { t } from "i18next";


export const validationMessage: IValidationMessage = {
    innoMinMessage: t("The innovation Best Practice min should less than or equal to innovation Best Practice value"),
    innoMaxMessage: t("The innovation Best Practice max should greater or equal to innovation Best Practice value"),
    pfeMinMessage: t("The Portfolio Extensions BestPractice min should less than or equal to portfolio Extensions BestPractice value"),
    pefMaxMessage: t("The portfolio Extensions BestPractice max should greater or equal to portfolio Extensions BestPractice value"),
    subsMinMessage: t("The substitutions BestPractice min should less than or equal to substitutions BestPractice value"),
    subsMaxMessage: t("The substitutions BestPractice max should greater or equal to substitutions BestPractice value"),
    mfcMaxMessage: t("The manufacturing Cost BestPractice min should less than or equal to manufacturing Cost BestPractice value"),
    mfcMinMessage: t("The manufacturing Cost BestPractice max should greater or equal to manufacturing Cost BestPractice value"),
    qctMinMessage: t("The quality cost BestPractice min should less than or equal to quality costBestPractice value"),
    qctMaxMessage: t("The quality cost BestPractice max should greater or equal to qualityCostBestPractice value"),
    codMinMessage: t("The cost of delay BestPractice min should less than or equal to cost Of delay BestPractice value"),
    codMaxMessage: t("The cost of delay BestPractice max should greater or equal to cost Of delay BestPractice value"),
    rndCMinMessage: t("The rnd Cost BestPractice min should less than or equal to rndCostBestPractice value"),
    rndCMaxMessage: t("The rnd Cost BestPractice max should greater or equal to rndCostBestPractice value"),
    interdisMinMessage: t("The inter discipline BestPractice min should less than or equal to inter discipline BestPractice value"),
    interdisMaxMessage: t("The inter discipline BestPractice max should greater or equal to inter discipline BestPractice value"),
    originalMinMessage: t("The original BestPractice min should less than or equal to original BestPractice value"),
    originalMaxMessage: t("The original BestPractice max should greater or equal to original BestPractice value")
}
export interface IValidationMessage {
    innoMinMessage: string;
    innoMaxMessage: string;
    pfeMinMessage: string;
    pefMaxMessage: string;
    subsMinMessage: string;
    subsMaxMessage: string;
    mfcMaxMessage: string;
    mfcMinMessage: string;
    qctMinMessage: string;
    qctMaxMessage: string;
    codMinMessage: string;
    codMaxMessage: string;
    rndCMinMessage: string;
    rndCMaxMessage: string;
    interdisMinMessage: string;
    interdisMaxMessage: string;
    originalMinMessage: string;
    originalMaxMessage: string;
}


