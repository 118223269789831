import {
  Collapse,
  Form,
  InputNumber,
  Typography,
  Row,
  Col,
  Input,
  Select,
  Popconfirm,
  Space,
  Tooltip,
  Table,
  Segmented,
  Checkbox,
} from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  StopOutlined,
  DownloadOutlined,
  UploadOutlined,
  CheckCircleOutlined,
  StopTwoTone,
} from "@ant-design/icons";
import { Button, message, Upload } from 'antd';
import TableTwoColumns from "../../../../../Components/TableTwoColumns";
import { CostOfDelayRowValue } from "../../../../../Models/EBIT/CostTypes";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IEbitToolState,
  IGeneralState,
} from "../../../../../Redux/reducers/IGeneralState";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
import {
  CostOfDelayDto,
  CostOfDelayWithDprojectDto,
  QualityCostDto,
  QualityCostWithDprojectDto,
} from "../../Models/CostTypesResponse";
import { BmProject } from "../../../../../Models/Responses/ProfileResponse";
import exportIcon from "../../../../../assets/Images/export-xlsx-icon.svg";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { inputFormatter, inputParser, formatValue } from "../../../../../Utils/helperFunctions";
import i18n from "../../../../../i18n";
import TableForPanel from "./TableForPanel";
import UploadFile from '../UploadFilesComponent';

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

const { Option } = Select;
interface CODProps {
  ebitInfo: IEbitToolState;
  selectedProject?: BmProject,
  currentProject: BmProject;
  onUpdateCODWithNewDproject: (model: CostOfDelayWithDprojectDto) => any;
  onCreateCOD: (model: CostOfDelayDto, projectID: number) => any;
  onUpdateCOD: (model: CostOfDelayDto, projectID: number) => any;
  onDeleteCOD: (modelId: number, projectID: number, forEbit: boolean) => any;
  onDownloadCODTable: (projectName: string, projectID: number) => any;
  onImportCODTable: (frmData: FormData, forEbit:boolean) => any;
}

function CODPanel(props: CODProps) {

  const newRowCOD: CostOfDelayRowValue = {
    key: "newCOD",
    project: null,
    projectyear: 0,
    delayInMonths: 0,
    expectedMonthlySales: 0,
    monthlyProjectCosts: 0,
    otherCosts: 0,
    NewProduct: "",
  };

  const { t } = useTranslation();
  const [editRecord, setEditRecord] = useState(
    newRowCOD as CostOfDelayRowValue
  );

  const [form] = Form.useForm();
  const [addFromList, setAddFromList] = useState(true);
  const isForEbit = props.selectedProject ? false : true;
  const projectId = Form.useWatch(`project_${editRecord?.project}`, form);

  useEffect(() => {
    if (props.ebitInfo?.dProjectForEbit) {
      form.setFieldsValue(fillInForm(newRowCOD));
      setEditRecord(newRowCOD);
    }
  }, [props.ebitInfo]);

  useEffect(() => {
    if (props.selectedProject && props.ebitInfo?.dProjectForSelectedProject) {
      form.setFieldsValue(fillInForm(newRowCOD));
      setEditRecord(newRowCOD);
    }
  }, [props.selectedProject]);
  let DProjectsList = props.selectedProject ? props.ebitInfo?.dProjectForSelectedProject : props.ebitInfo?.dProjectForEbit;
  const options = DProjectsList?.filter(
    (c) =>
      !c.costOfDelay && c.dproject.completionYear == props.currentProject?.yearUnderReview
  )
    .map((dp) => {
      return {
        value: dp.dproject.id,
        label: dp.dproject.name,
      };
    });
  const columnsOne = [
    {
      title: "",
      dataIndex: "left",
      key: "1",
      width: "75%",
    },
    {
      title: "",
      dataIndex: "right",
      key: "2",
      width: "25%",
    },
  ];

  function calculateOtherCosts() {
    let sum = DProjectsList
      ?.filter(
        (c) =>
          c.costOfDelay &&
          c.dproject.completionYear == props.currentProject?.yearUnderReview
      )
      .reduce((total, next) => {
        return total + next.costOfDelay.otherCosts;
      }, 0);
    return sum;
  }

  function calculateTotalLostRevenue() {
    let sum = 0;
    DProjectsList
      ?.filter(
        (c) =>
          c.costOfDelay &&
          c.dproject.completionYear == props.currentProject?.yearUnderReview
      )
      .map((record) => {
        sum +=
          record.costOfDelay.delayInMonths *
          record.costOfDelay.expectedMonthlySales;
      });
    return sum;
  }

  function calculateLostEbitDueToSales() {
    let rv =
      calculateTotalLostRevenue() *
      (props.currentProject?.ebitTodayPercentage / 100);
    return rv;
  }

  function calculateAdditionalDevelopmentCosts() {
    let sum = 0;
    DProjectsList
      ?.filter(
        (c) =>
          c.costOfDelay &&
          c.dproject.completionYear == props.currentProject?.yearUnderReview
      )
      .map((record) => {
        sum +=
          record.costOfDelay.delayInMonths *
          record.costOfDelay.monthlyProjectCosts;
      });
    return sum;
  }

  function calculateTotalLostEbit() {
    //console.log("props.currentProject.bpCodShare * props.currentProject.sales", props.currentProject.bpCodShare,  props.currentProject.sales);
    let rv = calculateAdditionalDevelopmentCosts() + calculateLostEbitDueToSales()
      - (props.currentProject?.bpCodShare * props.currentProject?.sales)
      + calculateOtherCosts();
    return rv >= 0 ? rv : 0;
  }

  function calculateEbitPotentialCodRate() {
    let rv = props.currentProject?.sales !== 0 ? calculateTotalLostEbit() / props.currentProject?.sales : 0;
    return rv * 100;
  }
  const heute = (
    <>
      {t("Entgangener EBIT")}
      <sub>€ </sub> /
      {t("Umsatz")}
      <sub>𝑛</sub>
    </>
  );
  const heute1 = (
    <>
      {t("Entgangener Umsatz")}
      <sub>𝑛</sub>/
      EBIT
      <sub>𝑛</sub>
    </>
  );
  const heute2 = (
    <>
      {t("Summe entgangener Umsätze")}
      <sub>𝑛</sub>
    </>
  )
  const rowsOne = [
    {
      key: 1,
      left: (
        <Tooltip placement="bottomRight" title={heute}>
          {t("EBIT-Potenzial")}
          <sub>{`COD `}</sub>
          {`[%]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateEbitPotentialCodRate())} %`,
    },
    {
      key: 2,
      left: (
        <Tooltip placement="bottomRight" title={t("Entgangener EBIT durch Umsatz + Entwicklungskoten + sonstige")}>
          {t("Gesamter entgangener EBIT")} {`[€]`}

        </Tooltip>
      ),
      right: `${formatValue(calculateTotalLostEbit())} €`,
    },
  ];

  const rowsTwo = [
    {
      key: 3,
      left: (
        <Tooltip placement="bottomRight" title={t("Monatliche Kosten * Verzögerung")}>
          {t("Zusätzliche Entwicklungskosten")} {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateAdditionalDevelopmentCosts())} €`,
    },
    {
      key: 4,
      left: (
        <Tooltip placement="bottomRight" title={heute1}>
          {t("Entgangenes EBIT durch Umsatz")} {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateLostEbitDueToSales())} €`,
    },
  ];

  const rowsThree = [
    {
      key: 5,
      left: (
        <Tooltip placement="bottomRight" title={heute2}>
          {t("Gesamter entgangener Umsatz")} {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateTotalLostRevenue())} €`,
    },
    {
      key: 6,
      left: (
        <Tooltip placement="bottomRight" title={t("Summe aller sonstiger Kosten")}>
          {t("Sonstige Kosten")} {`[€]`}
        </Tooltip>
      ),
      right: `${formatValue(calculateOtherCosts())} €`,
    },
  ];

  const dataColCOD: ColumnsType = [
    {
      title: (
        <p>
          {t("Produkte")}
          <br />
          <small>{t("Markteintritt")} in n</small>
        </p>

        // <>
        //   <label>{`Products`}</label>
        //   <br></br>
        //   <sub>{`ME < 1 year`}</sub>
        // </>
      ),
      width: '17%',
      dataIndex: "product",
      fixed: "left", // fix element
      render: (text: any, record: CostOfDelayRowValue, index) => {
        //console.log("render", text, record, index);
        if (index == 0) {
          if (editRecord?.key != "newCOD") {
            return (
              <Select
                disabled
                showSearch
                placeholder="Select..."
                options={options}
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  fontSize: "12px"
                }}
                dropdownStyle={{ zIndex: 2000 }}
              />
            );
          }
          // return (
          //   <Space size={2}>
          return <Row>
            <Col span={2}>              <Checkbox
              checked={addFromList}
              onChange={(e) => {
                form.setFieldsValue(fillInForm(newRowCOD));
                setEditRecord(newRowCOD);
                setAddFromList(e.target.checked);
                console.log("form fields", form.getFieldsValue());
              }}
            /></Col>
            <Col span={1}></Col>
            <Col span={21}>
              <Form.Item
                hidden={!addFromList}
                name={`project_${record?.project}`}
                style={{ margin: 0 }}
                rules={
                  !addFromList
                    ? []
                    : [
                      {
                        required: true,
                        message: `${t("required*")}`,
                      },
                    ]
                }
              >
                <Select
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={() =>
                    isForEbit ? document.getElementById("app-layout")
                      : document.getElementsByClassName("ant-modal-root").item(0) as HTMLElement
                  }
                  optionFilterProp="label"
                  showSearch
                  placeholder={t("Auswahl")}
                  options={options}
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "12px",
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Form.Item
                hidden={addFromList}
                name={`projectname_${record?.project}`}
                style={{ margin: 0 }}
                rules={
                  addFromList
                    ? []
                    : [
                      {
                        required: true,
                        message: `${t("required*")}`,
                      },
                    ]
                }
              >
                <Input placeholder={t("Neues Entwciklungsprojekt")} />
              </Form.Item>
            </Col>
            {/* </Space> */}
          </Row>
          {/* ); */ }
        } else {
          return (
            DProjectsList?.find(
              (c) => c.dproject.id == record?.project
            )?.dproject.name ?? ""
          );
        }
      }, // keep this if you need to render link with data cell.
    },
    {
      title: (
        <p>
          {t("Projekt-Abschlussjahr")}
          <br />
          <small>{`n`}</small>
        </p>
        // <>
        //   <label>{`Project completion year`}</label>
        //   <br></br>
        //   <sub>{`n-1`}</sub>
        // </>
      ),
      width: '10%',
      dataIndex: "projectyear",
      defaultSortOrder: "descend",
      sorter: (a: CostOfDelayRowValue, b: CostOfDelayRowValue) => {
        if (
          a.projectyear == -1 ||
          b.projectyear == -1 ||
          a.projectyear == 0 ||
          b.projectyear == 0
        )
          return 0;
        else return a.projectyear - b.projectyear;
      },
      render: (text: any, record: CostOfDelayRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newCOD") {
            return <InputNumber
              maxLength={4}
              style={{ width: "10ch" }}
              disabled />;
          }
          if (addFromList) {
            return DProjectsList?.find(
              (c) => c.dproject.id == projectId
            )?.dproject.completionYear;
          } else {
            return (
              <Form.Item
                name={`ProjektAbschlussjahr_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  maxLength={4}
                  style={{ width: "10ch" }}
                  min={1970} />
              </Form.Item>
            );
          }
        } else {
          return record?.projectyear;
        }
      },
    },
    {
      title: (
        <p>
          {t("Verzögerung")}
          <br />
          <small>{t("Monat(e)")}</small>
        </p>
        // <>
        //   <label>{`Delay`}</label>
        //   <br></br>
        //   <sub>{`month(s)`}</sub>
        // </>
      ),
      width: '8%',
      dataIndex: "delayInMonths",
      render: (text: any, record: CostOfDelayRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newCOD") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`delayInMonths_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                // maxLength={2}
                style={{ width: "8ch" }}
                min={0}
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`delayInMonths_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  // maxLength={2}
                  style={{ width: "8ch" }}
                  min={0}
                  formatter={(value) => inputFormatter(value, i18n.language)}
                  parser={(x) => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.delayInMonths);
          }
        }
      },
    },
    {
      title: (
        <p>
          {t("Erwarteter Monatsumsatz nach Ramp-Up")}
          <br />
          <small>{`in € / ${t("Monat")}`}</small>
        </p>
        // <>
        //   <p>{`Expected monthly turnover`}</p>
        //   <br></br>
        //   <sub>{`in € / month`}</sub>
        // </>
      ),
      width: '17%',
      dataIndex: "expectedMonthlySales",
      render: (text: any, record: CostOfDelayRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newCOD") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`expectedMonthlySales_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={'€'}
                // maxLength={12}
                min={0}
                // max={9999999}
                style={{ width: "20ch" }}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`expectedMonthlySales_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  addonAfter={'€'}
                  // maxLength={12}
                  min={0}
                  // max={9999999}
                  style={{ width: "20ch" }}
                  formatter={value => inputFormatter(value, i18n.language)}
                  parser={x => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.expectedMonthlySales) + "€";
          }
        }
      },
    },
    {
      title: (
        <p>
          {t("Monatliche Projektkosten")}
          <br />
          <small>{`in €`}</small>
        </p>
        // <>
        //   <label>{`Monthly project costs`}</label>
        //   <br></br>
        //   <sub>{`in €`}</sub>
        // </>
      ),
      width: '17%',
      dataIndex: "monthlyProjectCosts",
      render: (text: any, record: CostOfDelayRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newCOD") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`monthlyProjectCosts_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={'€'}
                upHandler={null}
                downHandler={null}
                // maxLength={12}
                min={0}
                // max={9999999}
                style={{ width: "20ch" }}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`monthlyProjectCosts_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  addonAfter={'€'}
                  // maxLength={12}
                  min={0}
                  // max={9999999}
                  style={{ width: "20ch" }}
                  formatter={value => inputFormatter(value, i18n.language)}
                  parser={x => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.monthlyProjectCosts) + "€";
          }
        }
      },
    },
    {
      title: (
        <p>
          {t("Sonstige entstandene Kosten")}
          <br />
          <small>{`in €`}</small>
        </p>
        // <>
        //   <label>{`Other costs incurred`}</label>
        //   <br></br>
        //   <sub>{`in €`}</sub>
        // </>
      ),
      width: '17%',
      dataIndex: "otherCosts",
      render: (text: any, record: CostOfDelayRowValue, index) => {
        if (index == 0) {
          if (editRecord?.key != "newCOD") {
            return <InputNumber disabled />;
          }
          return (
            <Form.Item
              name={`otherCosts_${record?.project}`}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `${t("required*")}`,
                },
              ]}
            >
              <InputNumber
                addonAfter={'€'}
                // maxLength={12}
                min={0}
                // max={9999999}
                style={{ width: "20ch" }}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
              />
            </Form.Item>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Form.Item
                name={`otherCosts_${record?.project}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `${t("required*")}`,
                  },
                ]}
              >
                <InputNumber
                  addonAfter={'€'}
                  // maxLength={12}
                  min={0}
                  // max={9999999}
                  style={{ width: "20ch" }}
                  formatter={value => inputFormatter(value, i18n.language)}
                  parser={x => inputParser(x, i18n.language)}
                />
              </Form.Item>
            );
          } else {
            return formatValue(record?.otherCosts) + "€";
          }
        }
      },
    },
    {
      title: (
        <Space wrap={true} align="center" direction="horizontal">
          <Tooltip placement="top" title={t("Daten importieren")}>
            <UploadFile currentProjectId={props.currentProject?.id} isForEbit={isForEbit} onImportCostTable={props.onImportCODTable} />
          </Tooltip>
          <Tooltip placement="top" title={t("Daten exportieren")}>
            <img
              src={exportIcon}
              alt="export icon"
              onClick={() => {
                onExport();

              }}
            />
          </Tooltip>
        </Space>
      ),
      width: '8%',
      dataIndex: "actions",
      render: (text, record: CostOfDelayRowValue, index) => {
        //console.log(text, record, index);
        if (index == 0) {
          return (
            <Space size={20}>
              {" "}
              <span>
                <Typography.Link
                  disabled={editRecord?.key != record?.key}
                  onClick={() => onAddRow(record)} //handleAdd(record?.key)}
                  style={{ marginRight: 8 }}
                >
                  <Tooltip placement="bottom" title={`${t("Add data")}`}>
                    <PlusOutlined style={{ color: "green" }} />
                  </Tooltip>
                </Typography.Link>
              </span>
            </Space>
          );
        } else {
          if (editRecord?.key == record?.key) {
            return (
              <Space size={10}>
                <Popconfirm
                  title="Sure to save?"
                  onConfirm={() => onEditRow(record)}
                >
                  <a>
                    <Tooltip placement="bottom" title="Save data">
                      <CheckCircleOutlined />
                    </Tooltip>
                  </a>
                </Popconfirm>

                <Typography.Link
                  disabled={
                    editRecord?.key != "newCOD" &&
                    editRecord?.key != record?.key
                  }
                  onClick={() => {
                    form.setFieldsValue(fillInForm(newRowCOD));
                    setEditRecord(newRowCOD);
                  }}
                >
                  <Tooltip placement="bottom" title="Cancel Changes">
                    <StopTwoTone twoToneColor="red" />
                  </Tooltip>
                </Typography.Link>
              </Space>
            );
          } else {
            return (
              <Space size={10}>
                <Typography.Link
                  disabled={
                    editRecord?.key != "newCOD" &&
                    editRecord?.key != record?.key
                  }
                  onClick={() => {
                    form.setFieldsValue(fillInForm({ ...record }));
                    setEditRecord(record);
                  }}
                >
                  <Tooltip placement="bottom" title={`${t("Edit")}`}>
                    <EditTwoTone twoToneColor="#1890ff" />
                  </Tooltip>
                </Typography.Link>

                <Popconfirm
                  title={`${t("Sure to delete?")}`}
                  onConfirm={() => onDeleteRow(record)}
                >
                  <a>
                    <Tooltip placement="bottom" title={`${t("Delete")}`}>
                      <DeleteTwoTone twoToneColor="red" />
                    </Tooltip>
                  </a>
                </Popconfirm>
              </Space>
            );
          }
        }
      },
    },
  ];

  const dataRowCOD =
    DProjectsList
      ?.filter(
        (c) =>
          c.costOfDelay &&
          c.dproject.completionYear == props.currentProject?.yearUnderReview
      )
      .map((row) => {
        if (row.costOfDelay) {
          return {
            key: `qk_row_${row.dproject.id}`,
            expectedMonthlySales: row.costOfDelay.expectedMonthlySales,
            delayInMonths: row.costOfDelay.delayInMonths,
            monthlyProjectCosts: row.costOfDelay.monthlyProjectCosts,
            otherCosts: row.costOfDelay.otherCosts,
            project: row.dproject.id,
            projectyear: row.dproject.completionYear,
            NewProduct: "",
          } as CostOfDelayRowValue;
        }
      }) ?? [];

  function fillInForm(row: CostOfDelayRowValue) {
    let rowNames: any = {};
    rowNames[`project_${row.project}`] = row.project;
    rowNames[`delayInMonths_${row.project}`] = row.delayInMonths;
    rowNames[`expectedMonthlySales_${row.project}`] = row.expectedMonthlySales;
    rowNames[`monthlyProjectCosts_${row.project}`] = row.monthlyProjectCosts;
    rowNames[`otherCosts_${row.project}`] = row.otherCosts;
    rowNames[`projectname_${row.project}`] = row.NewProduct ?? "";
    rowNames[`ProjektAbschlussjahr_${row.project}`] = row.projectyear;
    return rowNames;
  }
  function getRowFromForm(obj: any) {
    let row: CostOfDelayRowValue = {
      key: "",
      project: 0,
      projectyear: 0,
      delayInMonths: 0,
      expectedMonthlySales: 0,
      monthlyProjectCosts: 0,
      otherCosts: 0,
      NewProduct: "",
    };

    Object.keys(obj).forEach((key) => {
      console.log(key, obj[key]);
      if (key.includes("projectname_")) row.NewProduct = obj[key] ?? "";
      if (key.includes("ProjektAbschlussjahr_")) row.projectyear = obj[key] ?? "";
      if (key.includes("project_")) row.project = obj[key];
      if (key.includes("delayInMonths_")) row.delayInMonths = obj[key];
      if (key.includes("expectedMonthlySales_"))
        row.expectedMonthlySales = obj[key];
      if (key.includes("monthlyProjectCosts_"))
        row.monthlyProjectCosts = obj[key];
      if (key.includes("otherCosts_")) row.otherCosts = obj[key];
    });
    return row;
  }
  async function onAddRow(row: CostOfDelayRowValue) {
    try {
      console.log("obj", form.getFieldsValue());
      const row2 = await form.validateFields();
      let newData = getRowFromForm(row2);
      // console.log(newData);
      if (newData.NewProduct == "") {
        props.onCreateCOD(
          {
            id: newData.project,
            delayInMonths: newData.delayInMonths,
            expectedMonthlySales: newData.expectedMonthlySales,
            monthlyProjectCosts: newData.monthlyProjectCosts,
            otherCosts: newData.otherCosts,
            forEbit: isForEbit,
          } as CostOfDelayDto,
          props.currentProject?.id
        );
      } else {
        console.log("newData.NewProduct:", newData);
        var newdata2: CostOfDelayWithDprojectDto = {
          bmProjectId: props.currentProject?.id,
          delayInMonths: newData.delayInMonths,
          expectedMonthlySales: newData.expectedMonthlySales,
          monthlyProjectCosts: newData.monthlyProjectCosts,
          otherCosts: newData.otherCosts,
          dprojectCompletionYear: newData.projectyear,
          dprojectName: newData.NewProduct,
          forEbit: isForEbit,
        };
        props.onUpdateCODWithNewDproject(newdata2);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function onEditRow(row: CostOfDelayRowValue) {
    try {
      const row2 = await form.validateFields();
      let newData = getRowFromForm(row2);
      console.log(newData);
      props.onUpdateCOD(
        {
          id: editRecord.project, //newData.Produkte,
          delayInMonths: newData.delayInMonths,
          expectedMonthlySales: newData.expectedMonthlySales,
          monthlyProjectCosts: newData.monthlyProjectCosts,
          otherCosts: newData.otherCosts,
          forEbit: isForEbit,
        } as CostOfDelayDto,
        props.currentProject?.id
      );
    } catch (e) {
      console.log("onEditRow ", e);
    }
  }
  function onDeleteRow(row: CostOfDelayRowValue) {
    // console.log(row);
    props.onDeleteCOD(row.project, props.currentProject?.id, isForEbit);
  }
  function onExport() {
    props.onDownloadCODTable(props.currentProject?.project_name, props.selectedProject ? props.currentProject?.id : null)
  }
  return (
    <Panel
      key={"cod_1"}
      {...props}
      header={
        <Row>
          <Col span={6} className="title-tab-hover">
            <Title level={5} >{`${t("Cost Of Delay")}`}</Title>
          </Col>
          <Col span={6}>
            <input disabled value={`${t("EBIT-Potenzial")}:`} />
          </Col>
          <Col span={6}>
            <input
              style={{ width: '100%' }}
              disabled
              value={`${t("Absolut")}  = ${formatValue(calculateTotalLostEbit())} €`}
            />
          </Col>
          <Col span={6}>
            <input
              disabled
              value={`${t("Prozentual")} = ${formatValue(
                calculateEbitPotentialCodRate())} %`}
            />
          </Col>
          <Col span={24} className="paragraph-tab-hover">
            <Paragraph
            >{t("Umsatzpotenziale durch frühere Markteintritte und die daraus resultierenden EBIT-Potenziale.")}</Paragraph>
          </Col>
        </Row>
      }
      className="cost-type-custom-panel celery"
    >
      <section id="table-two-columns" className="cod-table-tow-columns">
        <Row>
          <Col span={8}>
            <TableForPanel columns={columnsOne} rows={rowsOne} />
          </Col>
          <Col span={8}>
            <TableForPanel columns={columnsOne} rows={rowsTwo} />
          </Col>
          <Col span={8}>
            <TableForPanel columns={columnsOne} rows={rowsThree} />
          </Col>
        </Row>
      </section>
      <div className="cost-type-editable" id="cost-type-editable">
        <Form form={form} component={false} key="cod_form">
          <Table
            key={"cod_table"}
            bordered={false}
            dataSource={[newRowCOD, ...dataRowCOD]}
            columns={dataColCOD}
            scroll={{ y: 500 }}
            rowClassName={() => "editable-row"}
            pagination={false}
          />
        </Form>
      </div>
    </Panel>
  );
}
const mapStateToProps = (state: IGeneralState, ownProps) => {
  return {
    ebitInfo: state.ebit,
    currentProject: ownProps.selectedProject ? ownProps.selectedProject : state.generalInfo.profileResponse?.currentProject,
    selectedProject: ownProps.selectedProject,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    onUpdateCODWithNewDproject: (model: CostOfDelayWithDprojectDto) => dispatch({ type: ActionTypes.CreateCODNewDproject, model: model }),
    onCreateCOD: (model: CostOfDelayDto, projectID: number) => dispatch({ type: ActionTypes.CreateCOD, model: model, projectID: projectID }),
    onUpdateCOD: (model: CostOfDelayDto, projectID: number) => dispatch({ type: ActionTypes.UpdateCOD, model: model, projectID: projectID }),
    onDeleteCOD: (modelId: number, projectID: number, forEbit: boolean) => dispatch({ type: ActionTypes.DeleteCOD, id: modelId, projectID: projectID, forEbit }),
    onDownloadCODTable: (projectName: string, projectID: number) => dispatch({ type: ActionTypes.DownloadCODTable, projectID, projectName }),
    onImportCODTable: (frmData: FormData, forEbit:boolean) => dispatch({ type: ActionTypes.ImportCODTable, frmData, forEbit }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CODPanel);
