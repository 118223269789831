import { Space, Typography, Tabs, Button, Form, Input } from "antd";
import AppAvatar from "../AppAvatar";
import ProgressBarShape from "../ProgressBarShape";
//import CustomRadio from "../CustomRadio";
import EditableDataTable from "../EditableDataTable";
import ExpandTable from "../ExpandTable";
import FormComponent from "../FormComponent";
import ModalComponent from "../ModalComponent";
import TabsAccordion from "../TabsAccordion";

import "./index.scss";
//import SpreadsheetComponent from "../Spreadsheet";
import FormulaParserExample from "../FormulaParser/FormulaParserExample";

// const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

const dataCol = [
  {
    title: 'name',
    dataIndex: 'name',
    width: '25%',
    editable: true, // effect if showAction props is true.
    fixed: 'left', // fix element
    render: (text: string) => <a href="#">{text}</a>, // keep this if you need to render link with data cell.
  },
  {
    title: 'age',
    dataIndex: 'age',
    // width: '15%',
    editable: true,
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: 'address',
    dataIndex: 'address',
    // width: '40%',
    editable: true,
  },
];
const dataCol2 = [
  {
    title: 'name',
    dataIndex: 'name',
    fixed: 'left', // fix element
    render: (text: string) => <a href="#">{text}</a>, // keep this if you need to render link with data cell.
  },
  {
    title: 'age',
    dataIndex: 'age',
    editable: true,
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: 'address',
    dataIndex: 'address',
  },
  {
    title: 'additional',
    dataIndex: 'additional',
  },
];
const dataRow = [{
  key: '1',
  name: 'Mike',
  age: 32,
  address: '10 Downing Street',
},
{
  key: '2',
  name: 'John',
  age: 42,
  address: '10 Downing Street',
},];
for (let i = 3; i < 25; i++) {
  dataRow.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: i++,
    address: `London Park no. ${i}`,
  });
}

const dataRow2 = [{
  key: '1',
  name: 'Mike',
  age: 32,
  address: '10 Downing Street',
  additional: 'additional address'
},
{
  key: '2',
  name: 'John',
  age: 42,
  address: '10 Downing Street',
  additional: 'additional address'
},];
for (let i = 3; i < 25; i++) {
  dataRow2.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: i++,
    address: `London Park no. ${i}`,
    additional: `additional address no. ${i}`,
  });
}

const ComponentExample: React.FC = () => (
  <Space size={30} direction="vertical" className="component-exap">
    <Title level={2}> Avarars</Title>
    <Space size={40}>
      {/* Avatar Component Examples*/}
      <AppAvatar showPosition={true} showEmpName={true} />
      <AppAvatar avatarImage="https://bobbyhadz.com/images/blog/javascript-does-not-contain-default-export/banner.webp" showPosition={true} showEmpName={true} />
      <AppAvatar showPosition={true} showEmpName={true} empName="Sara Sara" jobPosition="Tester" />
      <AppAvatar avatarImage="https://bobbyhadz.com/images/blog/javascript-does-not-contain-default-export/banner.webp" />
    </Space>

    <hr />
    <Title level={2}> Editable Table</Title>
    {/* 
  * Editable DataTable compoent to view data in table
  * Change dataCol props will change on data columns
  * Change dataRow props will chnage table data
  * Change showAction props to true will show table action (Edit, Delete)
  * Change fixHeader to true will fix table header
  */}
    <EditableDataTable
      key={1}
      tableCol={dataCol}
      tableRow={dataRow}
      showAction={true}
      fixHeader={false}
      showPagination={true}
      selectRows={true}
    />

    <EditableDataTable
      key={2}
      tableCol={dataCol2}
      tableRow={dataRow2}
      showAction={false}
      fixHeader={true}
      showPagination={false}
      selectRows={false}
    />

    <hr />
    <Title level={2}> Expand Table </Title>
    <ExpandTable />

    <hr />
    <Title level={2}> Levers Table </Title>
    <div style={{ width: '100%' }}>
      {/* <LeversTable /> */}
    </div>


    <hr />
    <Title level={2}> Custom Radio Button </Title>
    {/* Custom Radio Butons */}
   {/*  <CustomRadio /> */}

    <Title level={2}> Tabs with Accordion </Title>
    {/* Tabs with Accordion */}
    <TabsAccordion />


    <hr />
    <Title level={2}> Modals </Title>
    <Paragraph> You can use modal to show data in popus usinf <code> ModalComponent </code> with props, component childer element will be the content of the modal.
      <br /> <code> title </code> props is the title of the modal,
      <code> btnText </code> Modal Button text (which we click to open modal), <code> footerchildren </code> props will be the modal Actions, if the modal dosn't have buttons set this pros empty {"{[]}"}</Paragraph>
    {/* Modal Example One  */}
    <ModalComponent title="Add whatever you want" btnText="Add User"
     width="520px"
      isClosed={false}
      footerchildren={[
        <Button key="back">
          Return
        </Button>,
        <Button key="submit" type="primary">
          Submit
        </Button>,
        <Button
          key="link"
          href="https://google.com"
          type="primary"
        >
          Search on Google
        </Button>,
      ]}>
      <Form
        layout="vertical"
      >
        <Form.Item label="Input">
          <Input />
        </Form.Item>
        <Form.Item label="Input">
          <Input />
        </Form.Item>
      </Form>
    </ModalComponent>

    {/* Modal Example Two  */}
    <ModalComponent title="Show Comments" btnText="Show Data"
       width="520px"
      isClosed={false}
      footerchildren={[]}>
      <AppAvatar avatarImage={""} showEmpName={false} showPosition={false}></AppAvatar>
      <p>Hello I'm Nasca Developer, Have a nice day!</p>
    </ModalComponent>

    <hr />
    <Title level={2}> Form Inputs </Title>
    <Paragraph>Form component is an example for inputs, please use inputs from the component</Paragraph>
    {/* Form Example  */}
    <FormComponent />
    <hr />
    <FormulaParserExample />

    <hr />
    {/* <SpreadsheetComponent /> */}
    {/* <FormulaParserExample /> */}
  </Space>
);


export default ComponentExample;