export enum ErrorActions {
    INVALIDEBMPROJECTID = 'INVALIDEBMPROJECTID',
    NOTFOUNDBMPROJECT = 'NOTFOUNDBMPROJECT',
    SETSHOWERRORTOFALSE = 'SETSHOWERRORTOFALSE',
    SHOWERRMESSAGEDIRECTLY = 'SHOWERRMESSAGEDIRECTLY',
    NOINTERNETCONNECTION = 'NOINTERNETCONNECTION',
    UNKOWNERR = 'UNKOWNERR',
    INVALIDEDPPAYLOAD = 'INVALIDEDPPAYLOAD',
    UpDproject_SHOWERRMSGDIRECTLY = 'UpDproject_SHOWERRMSGDIRECTLY',
    DPVALIDERR = 'DPVALIDERR',
    ErrorSaveInnovation = 'ErrorSaveInnovation',
    ErrorSaveCostOfDelay = 'ErrorSaveCostOfDelay',
    ErrorSaveMC = 'ErrorSaveMC',
    ErrorSaveQC = 'ErrorSaveQC',
    ErrInsertDproject = 'ErrInsertDproject',
    UPDP_NOINTERNET = 'UPDP_NOINTERNET',
    UPDP_UNKOWNERR = 'UPDP_UNKOWNERR',
    EDDP_NOINTERNET = 'EDDP_NOINTERNET',
    EDDP_UNKOWNERR = 'EDDP_UNKOWNERR',
    EDDproject_SHOWERRMSGDIRECTLY = 'EDDproject_SHOWERRMSGDIRECTLY',
    NOtFOUNDDPROJECT = 'NOtFOUNDDPROJECT',
    ERRORUPDATEDPROJECT = 'ERRORUPDATEDPROJECT',
    DDP_NOINTERNET = 'DDP_NOINTERNET',
    DDP_UNKOWNERR = 'DDP_UNKOWNERR',
    DDproject_SHOWERRMSGDIRECTLY = "DDproject_SHOWERRMSGDIRECTLY",
    ERRORDLTDP = "ERRORDLTDP",
    GETDPCOMMENTS_NOINTERNET = 'GETDPCOMMENTS_NOINTERNET',
    GETDPCOMMENTS_UNKOWNERR = 'GETDPCOMMENTS_UNKOWNERR',
    GETDPCOMMENTS_NFDPROJECT = 'GETDPCOMMENTS_NFDPROJECT',
    GETDPCOMMENTS_SHOWERRMSGDIRECTLY = 'GETDPCOMMENTS_SHOWERRMSGDIRECTLY',
    UPDPCOMMENT_NOINTERNET = 'UPDPCOMMENT_NOINTERNET',
    UPDPCOMMENT_UNKOWNERR = 'UPDPCOMMENT_UNKOWNERR',
    INVALIDDPCOMMENT = 'INVALIDDPCOMMENT',
    DPCOMMENTVALIDERR = 'DPCOMMENTVALIDERR',
    EDDPCOMMENT_NOINTERNET = 'EDDPCOMMENT_NOINTERNET',
    EDDPCOMMENT_UNKOWNERR = 'EDDPCOMMENT_UNKOWNERR',
    UpDpCOMMENT_SHOWERRMSGDIRECTLY = 'UpDpCOMMENT_SHOWERRMSGDIRECTLY',
    EDDpCOMMENT_SHOWERRMSGDIRECTLY = 'EDDpCOMMENT_SHOWERRMSGDIRECTLY',
    NotFoundComment = "NotFoundComment",
    DELDPCOMMENT_NOINTERNET = "DELDPCOMMENT_NOINTERNET",
    DELDPCOMMENT_UNKOWNERR = "DELDPCOMMENT_UNKOWNERR",
    DELDpCOMMENT_SHOWERRMSGDIRECTLY = "DELDpCOMMENT_SHOWERRMSGDIRECTLY"
}