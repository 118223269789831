import { connect } from "react-redux";
import { Card, Col, Row, Space, Spin, Tag, Typography } from "antd";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import ProfileMngTable from "./ProfileMngForm";
import './index.scss';
import { ProfileResponse } from "../../../Models/Responses/ProfileResponse";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import ChangePasswordForm from "./ChangePasswordForm";
interface IProps {
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    profileData: ProfileResponse;
}

const ProfileMng = (props: IProps) => {
    if (!props?.profileData) {
        return;
    }
    
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component profile-admin">
                <Row style={{ columnGap: "1.5rem", height: "auto" }}>
                    <Col span={11}  ><Card style={{ padding: "1.5rem", minHeight: "100%" }}><ProfileMngTable /></Card></Col>
                    <Col span={11} >
                        <Space direction="vertical" size="middle" style={{ padding: "1.5rem", display: 'flex', minHeight: "100%", justifyContent: "center" }} className="ant-card ant-card-bordered">
                            <ChangePasswordForm />
                        </Space>
                    </Col>
                </Row>
            </div >
        </Spin >
    );
};

const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.profileData?.isProfileLoading,
        profileData: state.generalInfo?.profileResponse,
        isModalLoading: state.profileData?.isModalLoading,
        modalResponse: state.profileData?.modalResponse,
    };
};


export default connect(mapStateToProps, null)(ProfileMng);