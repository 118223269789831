
import { Checkbox, Form, Table, Tag, Typography } from 'antd';
import { ILeverManagementItemWithKey } from '../../../Models/ILeversManagement';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { formatValue } from '../../../Utils/helperFunctions';
import KipsEditModal from './LeversMngModal/KipsEditModal';
import LeverEditModal from './LeversMngModal/LeverEditModal';
import QuestionsEditModal from './LeversMngModal/QuestionsEditModal';
import SubLeverEditModal from './LeversMngModal/SubLeverEditModal';
import SuccessFactorsEditModal from './LeversMngModal/SuccessFactorsEditModal';
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import { useRef } from 'react';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import parse from 'html-react-parser';
import { t } from 'i18next';

interface IProps {
    allLeversData: ILeverManagementItemWithKey[],
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    functionTypes: any[];
    units: any[];
    collectionRoles: any[];
}
const LeversMngTable = (props: IProps) => {
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "number_1",
            title: `${t("Number")}`,
            dataIndex: 'number',
            width: "10%",
        },
        {
            key: "nameEn_1",
            title: `${t("English Name")}`,
            dataIndex: 'nameEn',
            ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),

        },
        {
            key: "nameDe_2",
            title: `${t("German Name")}`,
            dataIndex: 'nameDe',
            ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),

        },
        {
            title: `${t("Actions")}`,
            dataIndex: 'Actions',
            render: (_: any, record: ILeverManagementItemWithKey) => {
                return < LeverEditModal
                    isModalLoading={props.isModalLoading}
                    modalResponse={props.modalResponse}
                    levers={props.allLeversData}
                    leverId={record.id}
                />
            },
        },

    ];
    // NESTED TABLE SubLevers
    const expandedRowRenderSubLevers = (subLevers: any) => {
        //search code start
        const handleSearchSubLevers = (
            confirm: (param?: FilterConfirmProps) => void,
        ) => {
            confirm();
        };
        //search code end
        const subLeversWithKey = subLevers?.map(s => { return { key: `subLevers_${s.id}`, ...s } })
        const columns: any[] = [
            {
                key: "number_1",
                title: `${t("Number")}`,
                dataIndex: 'number',
                width: "10%",
            },
            {
                key: "sub_nameEn_1",
                title: `${t("English Name")}`,
                dataIndex: 'nameEn',
                width: "15%",
                ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearchSubLevers),

            },
            {
                key: "sub_nameDe_2",
                title: `${t("German Name")}`,
                dataIndex: 'nameDe',
                width: "15%",
                ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearchSubLevers),

            },
            {
                key: "sub_ebitImpctRnd_2",
                title: 'Rnd',
                dataIndex: 'ebitImpctRnd',
                render: (text: any, _: any) => {
                    return <Tag color='cyan'>{`${formatValue(text)}%`}</Tag>
                }
            },
            {
                key: "sub_ebitImpctInno_2",
                title: 'Inno',
                dataIndex: 'ebitImpctInno',
                render: (text: any, _: any) => {
                    return <Tag color='cyan'>{`${formatValue(text)}%`}</Tag>
                }
            },
            {
                key: "sub_ebitImpctCod_2",
                title: 'Cod',
                dataIndex: 'ebitImpctCod',
                render: (text: any, _: any) => {
                    return <Tag color='cyan'>{`${formatValue(text)}%`}</Tag>
                }
            },
            {
                key: "sub_ebitImpctMc_2",
                title: 'Mc',
                dataIndex: 'ebitImpctMc',
                render: (text: any, _: any) => {
                    return <Tag color='cyan'>{`${formatValue(text)}%`}</Tag>
                }
            },
            {
                key: "sub_ebitImpctQc_2",
                title: 'Qc',
                dataIndex: 'ebitImpctQc',
                render: (text: any, _: any) => {
                    return <Tag color='cyan'>{`${formatValue(text)}%`}</Tag>
                }
            },
            {
                title: `${t("Actions")}`,
                dataIndex: '',
                render: (_: any, record: any) => {
                    return <SubLeverEditModal
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        subLevers={subLeversWithKey}
                        subLeverId={record.id}
                    />
                },
            },

        ]

        return <Table columns={columns} dataSource={subLeversWithKey} pagination={false}
            bordered getPopupContainer={() => document.getElementById("app-layout")}
            //title={() => <Typography.Title level={3}>{`${t("Sub Levers")}`}</Typography.Title>}
            className='table_subLever'
            //showHeader={false}
            size='small'
            expandable={{
                defaultExpandAllRows: false,
                rowExpandable: (record) => true,
                expandedRowRender: (subLevers) => {
                    return expandedRowRenderSuccessFactors(subLevers.successFactors);
                },
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                    ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                    )
            }}

        />;
    }
    // NESTED TABLE Success Factors
    const expandedRowRenderSuccessFactors = (successFactors: any) => {
        //search code start
        const handleSearchSuccessFactors = (
            confirm: (param?: FilterConfirmProps) => void,
        ) => {
            confirm();
        };
        //search code end
        const successFactorsWithKey = successFactors?.map(s => { return { key: `successFactor_${s.id}`, ...s } })
        const columns: any[] = [
            {
                key: "number_1",
                title: `${t("Number")}`,
                dataIndex: 'number',
                width: "10%",
            },
            {
                key: "factor_nameEn_1",
                title: `${t("English Name")}`,
                dataIndex: 'nameEn',
                ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearchSuccessFactors),

            },
            {
                key: "factor_nameDe_2",
                title: `${t("German Name")}`,
                dataIndex: 'nameDe',
                ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearchSuccessFactors),

            },
            {
                title: `${t("Actions")}`,
                dataIndex: 'Actions',
                render: (_: any, record: any) => {
                    return <SuccessFactorsEditModal
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        successFactors={successFactorsWithKey}
                        successFactorId={record.id}
                    />
                },
            },

        ]

        return <Table columns={columns} dataSource={successFactorsWithKey} pagination={false}
            // scroll={{ y: 240 }}
            bordered getPopupContainer={() => document.getElementById("app-layout")}
            //title={() => <Typography.Title level={3}>{`${t("Success Factors")}`}</Typography.Title>}
            //showHeader={false}
            size='small'
            className='table_factory'
            expandable={{
                defaultExpandAllRows: false,
                rowExpandable: (record) => true,
                expandedRowRender: (successFactors) => {
                    return expandedRowRenderKpis(successFactors?.kpis);
                },
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                    ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                    )
            }}

        />;
    }
    // NESTED TABLE Kipss
    const expandedRowRenderKpis = (kpis: any) => {
        //search code start
        const handleSearchKips = (
            confirm: (param?: FilterConfirmProps) => void,
        ) => {
            confirm();
        };
        //search code end
        const kpisWithKey = kpis?.map(s => { return { key: `kpis_${s.id}`, ...s } })
        const columns: any[] = [
            {
                key: "number_1",
                title: `${t("Number")}`,
                dataIndex: 'number',
                width:"10%",
            },
            {
                key: "kpis_nameEn_1",
                title: `${t("English Name")}`,
                dataIndex: 'nameEn',
                ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearchKips),

            },
            {
                key: "kpis_nameDe_2",
                title: `${t("German Name")}`,
                dataIndex: 'nameDe',
                ...getColumnSearchProps(`${t("German Name")}`, 'nameEn', searchInput, handleSearchKips),

            },
            {
                key: "dataCollectionRoles_key",
                title: `${t("Data Collection Roles")}`,
                dataIndex: 'dataCollectionRoles',
                render: (text: any, record: any) => {
                    return <Checkbox.Group options={props.collectionRoles && props.collectionRoles} value={record.dataCollectionRoles} disabled />

                }
            },
            {
                title: `${t("Actions")}`,
                dataIndex: 'Actions',
                render: (_: any, record: any) => {
                    return <KipsEditModal
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        kips={kpisWithKey}
                        kipsId={record.id}
                    />
                },
            },

        ]

        return <Table columns={columns} dataSource={kpisWithKey} pagination={false}
            // scroll={{ y: 240 }}
            bordered getPopupContainer={() => document.getElementById("app-layout")}
            //title={() => <Typography.Title level={3}>Kpis</Typography.Title>}
            //showHeader={false}
            size='small'
            className='table_kips'
            expandable={{
                defaultExpandAllRows: false,
                rowExpandable: (record) => true,
                expandedRowRender: (kpis) => {
                    return expandedRowRenderQuestions(kpis?.questions);
                },
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                    ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                    )
            }}

        />;
    }
    // NESTED TABLE Questions
    const expandedRowRenderQuestions = (questions: any) => {
        //search code start
        const handleSearchQuestions = (
            confirm: (param?: FilterConfirmProps) => void,
        ) => {
            confirm();
        };
        //search code end
        const questionsWithKey = questions?.map(s => { return { key: `question_${s.id}`, ...s } })
        const columns: any[] = [
            {
                key: "number_1",
                title: `${t("Number")}`,
                dataIndex: 'number',
                width:"10%",
            },
            {
                key: "ques_nameEn_1",
                title: `${t("English Name")}`,
                dataIndex: 'nameEn',
                // fixed: 'left',
                width: "15%",
                ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearchQuestions),

            },
            {
                key: "ques_nameDe_2",
                title: `${t("German Name")}`,
                dataIndex: 'nameDe',
                // fixed: 'left',
                width: "15%",
                ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearchQuestions),

            },
            {
                key: "ques_goalEn_2",
                title: `${t("English Target")}`,
                dataIndex: 'goalEn',
                width: "15%",
                render: (text: any, _: any) => {
                    if (text) {
                        let stringText = text.toString();
                        return parse(stringText);
                    } else {
                        return null;
                    }
                }
            },
            {
                key: "ques_goalDe_2",
                title: `${t("German Target")}`,
                dataIndex: 'goalDe',
                width: "15%",
                render: (text: any, _: any) => {
                    if (text) {
                        let stringText = text.toString();
                        return parse(stringText);
                    } else {
                        return null;
                    }
                }
            },
            {
                key: "ques_explanationEn_2",
                title: `${t("English Explanation")}`,
                dataIndex: 'explanationEn',
                width: "15%",
                render: (text: any, _: any) => {
                    if (text) {
                        let stringText = text.toString();
                        return parse(stringText);
                    } else {
                        return null;
                    }
                }
            },
            {
                key: "ques_explanationDe_2",
                title: `${t("German Explanation")}`,
                dataIndex: 'explanationDe',
                width: "15%",
                render: (text: any, _: any) => {
                    if (text) {
                        let stringText = text.toString();
                        return parse(stringText);
                    } else {
                        return null;
                    }
                }
            },
            {
                key: "ques_inputMin_2",
                title: `${t("input Min")}`,
                dataIndex: 'inputMin',
                width: "10%",
                render: (text: any, _: any) => {
                    return <Tag color='geekblue'>{text}</Tag>
                }
            },
            {
                key: "ques_inputMax_2",
                title: `${t("input Max")}`,
                dataIndex: 'inputMax',
                width: "10%",
                render: (text: any, _: any) => {
                    return <Tag color='geekblue'>{text}</Tag>
                }
            },
            {
                key: "ques_unitId_2",
                title: `${t("unit")}`,
                dataIndex: 'unitId',
                width: "10%",
                render: (text: any, _: any) => {
                    return <Tag color='geekblue'> {props.units?.filter(u => u.id === text).map[0]?.label}</Tag>

                }
            },
            {
                key: "ques_functionTypeId_2",
                title: `${t("function Type")}`,
                dataIndex: 'functionTypeId',
                width: "10%",
                render: (text: any, _: any) => {
                    return <Tag color='geekblue'> {props.functionTypes?.filter(u => u.value === text)[0]?.label}</Tag>
                }
            },

            {
                key: "ques_minBorder_2",
                title: `${t("min Border")}`,
                dataIndex: 'minBorder',
                width: "10%",
                render: (text: any, _: any) => {
                    return <Tag color='geekblue'>{`${formatValue(text)}%`}</Tag>
                }
            },

            {
                key: "ques_maxBorder_2",
                title: `${t("max Border")}`,
                dataIndex: 'maxBorder',
                width: "10%",
                render: (text: any, _: any) => {
                    return <Tag color='geekblue'>{`${formatValue(text)}%`}</Tag>
                }
            },

            {
                key: "ques_bestPracticeValue_2",
                title: `${t("BestPractice value")}`,
                dataIndex: 'bestPracticeValue',
                width: "10%",
                render: (text: any, _: any) => {
                    return <Tag color='geekblue'>{`${formatValue(text)}%`}</Tag>
                }
            },
            {
                title: `${t("Actions")}`,
                dataIndex: 'Actions',
                // fixed: 'right',           
                width: "10%",
                render: (_: any, record: any) => {
                    return <QuestionsEditModal
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        questions={questionsWithKey}
                        questionId={record.id}
                        functionTypes={props.functionTypes}
                        units={props.units}
                    />
                },
            },
        ]

        return <Table columns={columns} dataSource={questionsWithKey} pagination={false}
            // scroll={{ x: true, y: 1500 }}
            bordered getPopupContainer={() => document.getElementById("app-layout")}
            //title={() => <Typography.Title level={3}>{`${t("Questions")}`}</Typography.Title>}
            //showHeader={false}
            size='small'
            className='table_questions'

        />;
    }

    return (
        <Table
            className='leverMng_table'
            getPopupContainer={() => document.getElementById("app-layout")}
            key='leverMng_table'
            bordered
            size='small'            
            title={() => <Typography.Title level={4}>{`${t("Levers Management")}`}</Typography.Title>}
            dataSource={props.allLeversData}
            columns={columns}
            rowClassName="editable-row"
            scroll={{ y: "calc(100vh - 300px)" }}
            pagination={{
                defaultPageSize: 50,
                pageSizeOptions: [50, 100, 150],
                total: props.allLeversData.length,
                showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                showSizeChanger: true,
                locale: { items_per_page: `/ ${t("Page")}` },

            }}
            locale={{
                triggerDesc: t('Click to sort descending'),
                triggerAsc: t('Click to sort ascending'),
                cancelSort: t('Click to cancel sorting')
            }}
            expandable={{
                defaultExpandAllRows: false,
                rowExpandable: (record) => true,
                expandedRowRender: (record) => {
                    return expandedRowRenderSubLevers(record?.subLevers);
                },
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                    ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                    )
            }}

        />
    );
};

export default LeversMngTable;