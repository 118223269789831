import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import FrontLoadingDepartmentService from "../../Services/FrontLoadingDepartmentService";
import { IFrontLoadingDepartment } from "../../Models/IFrontLoading";

function* OnGetAllFrontLoadingDepartment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER, isLoading: true });
        let _result: ICommonResponse = yield FrontLoadingDepartmentService.GetAllFrontLoadingDepartment()
        if (!_result.isError) {
            let result = _result.result as IFrontLoadingDepartment[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING_DEPARTMENTS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in OnGetAllFrontLoadingDepartment: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnCreateFrontLoadingDepartment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultCreate: ICommonResponse = yield FrontLoadingDepartmentService.CreateFrontLoadingDepartment(action.model);
        if (!resultCreate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
            let _result: ICommonResponse = yield FrontLoadingDepartmentService.GetAllFrontLoadingDepartment()
            let result = _result.result as IFrontLoadingDepartment[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING_DEPARTMENTS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultCreate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultCreate });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER, isLoading: false, });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in OnCreateFrontLoadingDepartment: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateFrontLoadingDepartment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultUpdate: ICommonResponse = yield FrontLoadingDepartmentService.UpdateFrontLoadingDepartment(action.model);
        if (!resultUpdate.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result:  resultUpdate });
            let _result: ICommonResponse = yield FrontLoadingDepartmentService.GetAllFrontLoadingDepartment();
            let result = _result.result as IFrontLoadingDepartment[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING_DEPARTMENTS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultUpdate });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultUpdate });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onUpdateFrontLoadingDepartment: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onDeleteFrontLoadingDepartment(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER, isLoading: true });
        let resultDelete: ICommonResponse = yield FrontLoadingDepartmentService.DeleteFrontLoadingDepartment(action.id);

        if (!resultDelete.isError) {
            let _result: ICommonResponse = yield FrontLoadingDepartmentService.GetAllFrontLoadingDepartment();
            let result = _result.result as IFrontLoadingDepartment[];
            yield put({ type: ActionTypes.SAVE_FRONT_LOADING_DEPARTMENTS, result: result });
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultDelete });
        }
        yield put({ type: ActionTypes.CHANGE_FRONT_LOADING_DEPARTMENT_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onDeleteFrontLoadingDepartment: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* watchGetAllFrontLoadingDepartment() {
    yield takeEvery(ActionTypes.GET_FRONT_LOADING_DEPARTMENTS, OnGetAllFrontLoadingDepartment);
}
function* watchCreateFrontLoadingDepartment() {
    yield takeEvery(ActionTypes.CREATE_FRONT_LOADING_DEPARTMENT, OnCreateFrontLoadingDepartment);
}
function* watchUpdateFrontLoadingDepartment() {
    yield takeEvery(ActionTypes.UPDATE_FRONT_LOADING_DEPARTMENT, onUpdateFrontLoadingDepartment);
}
function* watchDeleteFrontLoadingDepartment() {
    yield takeEvery(ActionTypes.DELETE_FRONT_LOADING_DEPARTMENT, onDeleteFrontLoadingDepartment);
}

export default function* FrontLoadingDepartmentSagas() {
    yield all([
        watchGetAllFrontLoadingDepartment(),
        watchCreateFrontLoadingDepartment(),
        watchUpdateFrontLoadingDepartment(),
        watchDeleteFrontLoadingDepartment(),
    ]);
}
