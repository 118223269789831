import React, { FunctionComponent, Component } from 'react';
import ReactDOM from 'react-dom';
import { BestPractice } from '../Models/EvaluationData';
import {
    BarChart,
    Bar,
    Cell,
    CartesianGrid,
    XAxis,
    YAxis,
    Brush,
    Rectangle,
    ReferenceArea,
    ReferenceLine,
    ComposedChart,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import { Button, Col, Row, Tooltip } from "antd";
import { formatValue } from "../../../../Utils/helperFunctions";
import i18n from "../../../../i18n";
interface ProjectData {
    name: string,
    // annoName: string,
    value: number,
    isTarget: boolean,
    isComplete: boolean
}
interface Iprops {
    colors: Icolors[],
    data: Idata[];
    bestPractice: BestPractice,
    // ebitBMProjectsForChart: ProjectData[],
    subTitle: string,
    isTopLevelChart: boolean,
}
interface Idata {
    name: string,
    // annoName: string,
    value: number,
    isTarget: boolean,
    color: string,
    hasdashborder: boolean,
}
interface Icolors {
    color: string,
}
function CustomizedTick(props) {
    const { x, y, stroke, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>

            <text
                x={0}
                y={0}
                dy={8}
                textAnchor="middle"
                fontSize={10}
                fill="black"
            // transform="rotate(35)"
            >
                {payload.value}
            </text>
        </g>
    );
}

function RotatedTick(props) {
    const { x, y, stroke, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>

            <text
                x={0}
                y={0}
                dy={4}
                textAnchor="start"
                fontSize={10}
                fill="black"
                transform="rotate(35)"
            >
                {payload.value}
            </text>
        </g>
    );
}
function BrushXaxisTick(props) {
    const { x, y, stroke, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>

            <text
                x={0}
                y={0}
                dy={4}
                textAnchor="middle"
                fontSize={6}
                fill="black"
            // transform="rotate(45)"

            >
                {/* {payload.value} */}
            </text>
        </g>
    );
}
const CustomizedLabel: FunctionComponent<any> = (props: any) => {
    const { x, y, stroke, fill, value } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={-4} fill={fill} fontSize={12} textAnchor="start">
                {formatValue(value)}
            </text>
        </g>
    );
};
const getInitialState = {
    startIndex: 0,
    endIndex: 19,
    timerId: 0,
    left: 0,
    right: 0,
}
export default class CustomShapeBarChart extends Component<Iprops> {
    constructor(Iprops) {
        super(Iprops)
        this.state = getInitialState;


    }
    state = {
        startIndex: 0,
        endIndex: 0,
        timerId: 0,
        left: 0,
        right: 0,
    };

    render() {
        // const emptyProject: ProjectData = { annoName: "", isComplete: false, isTarget: false, name: "", value: 0 };
        const emptyProject: ProjectData = { isComplete: false, isTarget: false, name: "", value: 0 };
        const { left, right } = this.state;
        let xsProjects = this.props.data?.filter(x => x.isTarget === false);
        let avarage: number = xsProjects?.map(item => item?.value).reduce((a, b) => a + b, 0) / xsProjects?.length;
        let bestPracticeArea;
        let max = 0;
        if (this.props.bestPractice) {
            max = Number((this.props.bestPractice.max * 100).toFixed(1)) || 0;
            let min = Number((this.props.bestPractice.min * 100).toFixed(1));
            var firstProject = this.props.data ? this.props.data[this.state.startIndex] : emptyProject;
            let lastIndex = Math.min(this.props.data?.length - 1, this.state.endIndex);
            var lastProject = this.props.data ? this.props.data[lastIndex] : emptyProject;
            const reference = {
                // x1: (this.props.data?.length > 0) ? firstProject.isTarget ? firstProject.name : firstProject.annoName : "",
                // x2: (this.props.data?.length > 0) ? lastProject?.isTarget ? lastProject?.name : lastProject?.annoName : "",
                // x1: (this.props.data?.length > 0) ? firstProject.name : "",
                // x2: (this.props.data?.length > 0) ? lastProject?.name : "",
                y1: max,
                y2: min
            }
            bestPracticeArea = <ReferenceArea ifOverflow="extendDomain" alwaysShow y1={reference.y1} strokeDasharray="10 5" y2={reference.y2} stroke="orange" strokeOpacity={1} fill={"#FDF4C9"} fillOpacity={0.5} />
        }

        let dataMax = this.props.data ? Math.max(...this.props.data?.map(x => x.value)) : 100;
        let maxValue: number = Math.max(max, Math.ceil(dataMax));
        return (
            <ResponsiveContainer id={this.props.subTitle} width="100%" height={this.props.isTopLevelChart ? "100%" : "85%"} >
                <ComposedChart
                    id={this.props.subTitle + "_chart"}
                    style={{ marginTop: this.props.isTopLevelChart ? 0 : "6%" }}
                    width={500}
                    height={400}
                    data={this.props.data}
                    margin={{
                        top: 15,
                        right: 20,
                        bottom: 30,
                        left: 0,
                    }}>
                    <CartesianGrid vertical={false} strokeDasharray="3" />
                    <XAxis
                        dataKey="name"
                        interval={0}
                        tick={this.props.data?.length > 20 ? <RotatedTick /> : <CustomizedTick />}
                        width={20}


                    />
                    <YAxis
                        axisLine={false}
                        domain={[0, (maxValue + maxValue / 4) < 100 ? Math.ceil(maxValue + maxValue / 4) : 100]}
                        tickFormatter={value => `${value}%`}
                    />
                    {bestPracticeArea}
                    <Bar dataKey="value" isAnimationActive={false} fill="#8884d8" shape={<Rectangle />} maxBarSize={20}>
                        {this.props.data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={this.props.colors[(index + this.state.startIndex) % this.props.colors.length].color} stroke='black' strokeDasharray={(entry.hasdashborder) ? 3 : 0} strokeWidth={0.5} strokeOpacity={0.5} />
                        ))}
                        <LabelList content={<CustomizedLabel />} />
                    </Bar>
                    <ReferenceLine y={avarage} stroke="#F9DC4C" strokeWidth={2} />
                    {/* {((this.props.data?.length >= 20 && !this.props.isTopLevelChart) || (this.props.data?.length >= 20 && this.props.isTopLevelChart)) &&
                        <Brush dataKey="name" gap={1} id={this.props.subTitle + "_Brush "} height={30} stroke="#8884d8"
                            onClick={(e) => { e.preventDefault(); }}
                            onChange={indices =>
                                this.setState({
                                    startIndex: indices.startIndex,
                                    endIndex: indices.endIndex,

                                })
                            }
                            startIndex={0} endIndex={(this.props.data?.length < 20) ? this.props.data?.length - 1 : 19}

                            padding={{ left: left, right: 10 }}
                        >
                            <BarChart data={this.props.data} >
                                <XAxis
                                    dataKey="name"
                                    interval={0}
                                    tick={<BrushXaxisTick />}
                                    tickLine={true}
                                    axisLine={true}
                                    width={500}
                                    padding={{ left: 20 }}
                                    height={10}
                                />
                                <Bar dataKey="value" isAnimationActive={false} fill="#8884d8" shape={<Rectangle />} maxBarSize={10}>
                                    {this.props.data?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={this.props.colors[index].color} stroke='black' strokeDasharray={(entry.hasdashborder) ? 3 : 0} strokeWidth={0.5} strokeOpacity={0.5} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </Brush>} */}
                    <Tooltip />
                </ComposedChart>
            </ResponsiveContainer >
        );
    }
}