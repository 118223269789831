import { Card, Collapse, Input, InputNumber, Select, Space, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ISABestPractice, ISelfAssessmentView } from "../../../../Models/SelfAssessment/ISelfAssessmentView";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState, ISelfAssessmentToolState } from "../../../../Redux/reducers/IGeneralState";
import { t } from "i18next";
import { formatValue, inputFormatter, inputParser, tableLocalTranslate } from "../../../../Utils/helperFunctions";
import i18n from "../../../../i18n";
const { Option } = Select;
interface IProps {
    viewState: ISelfAssessmentToolState;
    dataSource: ISABestPractice[];
    defaultTopRated: number;
    changeAnswers: (model: ISelfAssessmentView) => any;
    changeTopRated: (topn: number) => any;
}
function SATopRated(props: IProps) {
    const onChange = (value: number, bpId: number) => {
        let viewStateTemp = props.viewState;
        viewStateTemp.viewResult.levers.map(lev => {
            lev.sublevers.map(sub => {
                sub.bestPractices.map(bb => {
                    if (bb.id == bpId) {
                        bb.urgency = value;
                    }
                });
            });
        });
        props.changeAnswers(viewStateTemp.viewResult);
    }
    const columns = [
        {
            title: t('Number'),
            dataIndex: 'index',
            key: 'index_top_rated',
            render: (text: any, record: ISABestPractice, index: number) => {
                return index + 1;
            }
        },
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name_top_rated',
        },
        {
            title: t('Product'),
            dataIndex: 'product',
            key: 'product_top_rated',
            render: (text: any, record: ISABestPractice, index: number) => {
                return formatValue((record.urgency ?? 0) * (record.importance ?? 0));
            }
        },
        {
            title: t('Importance'),
            dataIndex: 'importance',
            key: 'important_top_rated',
            render: (text: any, record: ISABestPractice, index: number) => {
                return formatValue(text);
            }
        },
        {
            title: t('Urgency'),
            dataIndex: 'urgency',
            key: 'urgency_top_rated',
            render: (text: any, record: ISABestPractice, index: number) => {
                return <InputNumber
                    //formatter={(value) => inputFormatter(value, i18n.language)}
                    //parser={(x) => inputParser(x, i18n.language)}
                    decimalSeparator={i18n.language == 'en' ? "." : ","}
                    precision={1}
                    value={record.urgency} min={1} max={5} onChange={(v) => { onChange(v, record.id) }} />;
            }
        },
        {
            title: t('Last Comment'),
            dataIndex: 'comments',
            key: 'comments_top_rated',
            ellipsis: {
                showTitle: false,
            },
            render: (text: any, record: ISABestPractice, index: number) => (
                <Tooltip placement="topLeft" title={`${record.comment ?? ''}`}>
                    {`${record.comment ?? ''}`}
                </Tooltip>
            ),
        },
    ];


    const changeTopRated = (value) => {
        localStorage.setItem("sa_top_rated", value);
        props.changeTopRated(value)
    }

    return <Card title={`${t("Top Rated")}`} key={`toprated_key_n`}
        extra={
            <Select onChange={changeTopRated} value={props.defaultTopRated}>
                <Option value="20">20</Option>
                <Option value="15">15</Option>
                <Option value="10">10</Option>
            </Select>
        }
    >
        <Table dataSource={props.dataSource} columns={columns} pagination={false} scroll={{ y: "calc(100vh - 450px)" }} locale={tableLocalTranslate()} />
    </Card >;
}

const mapStateToProps = (state: IGeneralState) => {
    return {
        viewState: state.selfAssessementTool,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        changeAnswers: (model: ISelfAssessmentView) => dispatch({ type: ActionTypes.ChangeAllSelfAssessmentView, model: model }),
        changeTopRated: (topn: number) => dispatch({ type: ActionTypes.ChangeSelfAssessmentTopRated, model: topn }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SATopRated);