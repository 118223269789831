import { Button, Form, Input, Row, Typography } from 'antd';
import { IProfilePasswordChange } from '../../../Models/IProfileUser';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { RulesPassword } from '../Rules/RulesValidation';
import { t } from 'i18next';
import { useEffect } from 'react';

interface IProps {
    changePassword: (model: IProfilePasswordChange) => any;
    resetFormClear: () => any;
    isLoading: boolean;
    clearForm: number;
}

const ChangePasswordForm = (props: IProps) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (props.clearForm == 1) {
            form.resetFields();
        }
        props.resetFormClear();
    }, [props.clearForm]);

    const onFinish = async (values) => {
        try {
            await form.validateFields();
            await props.changePassword({
                ...values
            });
        } catch (error) {
            console.log(error);
        }
    }
    const formItemLayout = {
        labelCol: { span: 9 },
        wrapperCol: { span: 16, offset: 1 },
    };
    return (
        <Form
            form={form}
            onFinish={onFinish}
            {...formItemLayout}
        >
            <Row style={{ margin: "1.5rem 0", }}>
                <Typography.Text strong style={{ color: "#84a5f4" }}> {`${t("Change Password")}`}</Typography.Text>
            </Row>
            <Form.Item
                label={`${t("Old Password")}`}
                name="oldPassword"
                labelAlign="left"
                rules={RulesPassword({ filedName: `${t("Old Password")}` })}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label={`${t("New Password")}`}
                labelAlign="left"
                name="newPassword"
                rules={RulesPassword({ filedName: `${t("New Password")}` })}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label={`${t("Confirm Password")}`}
                name="confirm"
                labelAlign="left"
                rules={[
                    {
                        required: true,
                        message: `${t("Confirm Password is Required*")}`,
                    },
                    () => ({
                        validator(_, value) {
                            if (value && value !== form.getFieldValue('newPassword')) {
                                return Promise.reject(`${t("New Password and Confirm Password do not match")}`);
                            } else {
                                return Promise.resolve();
                            }
                        }
                    })
                ]}
            >
                <Input.Password />

            </Form.Item>
            <Row justify="center" style={{ marginTop: "2rem" }}>
                <Button htmlType="submit" style={{ display: "flex", padding: "10px 40px", justifyContent: "center", alignItems: "center", marginRight: "10px" }} loading={props.isLoading} type='primary'>
                    <Typography.Title level={5} style={{ marginBottom: "0px", color: "#fff" }}>
                     {`${t("Save")}`} </Typography.Title>
                </Button>
            </Row>
        </Form>
    );
};

const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.profileData?.isProfileLoading,
        clearForm: state.generalInfo?.clearForm
    };
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        changePassword: (model: IProfilePasswordChange) => dispatch({ type: ActionTypes.CHANGE_PASSWORD, model }),
        resetFormClear: () => dispatch({ type: ActionTypes.ToResetClearForm })
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);