import { ActionTypes } from "../actionsTypes";
import { IHistoryState } from "./IGeneralState";

const initialState: IHistoryState = {
  isLoading: false,
  historyResult: null,
};

const SelfAssessmentHistoryReducer = (state = initialState, action: any): IHistoryState => {
  switch (action.type) {
    case ActionTypes.CHANGE_HISTORY_IMPORTANCE_LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.SAVE_HISTORY_IMPORTANCE:
      return {
        ...state,
        historyResult: action.result,
      };    
    default:
      break;
  }
  return state;
};
export default SelfAssessmentHistoryReducer;

