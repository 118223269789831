import React, { useState } from 'react'
import { IActivityTask } from '../../../../Models/IActivityManagement';
import { Button, Modal, Tooltip } from 'antd';
import { DatabaseOutlined, SnippetsOutlined } from '@ant-design/icons';
import TasksTable from './ActivityProfileTasksTable';
import { t } from 'i18next';
interface IProps {
    tasks: IActivityTask[];
    activityProfileId: number;
    ActivityProfileName: string;
}
const ActivityProfileTasksModal = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const activityTask: IActivityTask[] = props.tasks?.map((t, index) => {
        return {
            key: `task_${index}`,
            ...t
        }
    }) ?? [];

    const showModal = async () => {
        try {
            setIsModalOpen(true);
            // await props.getDetails(props.activityProfileId)
        } catch (error) {
            console.log(error);
        }

    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <> <Tooltip title={`${t("show Activity Profile Template Tasks")}`}>
            {/* <Button  > */}
                {/* <DatabaseOutlined />  */}
                <SnippetsOutlined onClick={showModal} style={{fontSize:"15px"}}/>
                {/* {`${t("Tasks")}`} */}
            {/* </Button> */}
        </Tooltip>
            <Modal title={`( ${props.ActivityProfileName} ) ${t("Profile Tasks")}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1200} getContainer={() => document.getElementById("app-layout")}  footer={[]}>
                <TasksTable activityProfileId={props.activityProfileId}  tasks={activityTask} />
            </Modal>
        </>
    );
};
export default ActivityProfileTasksModal;