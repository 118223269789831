var FormulaParser = require('hot-formula-parser').Parser;

function FormulaParserExample() {

    var parser = new FormulaParser();
    var result = parser.parse('SUM(1, 6, 7)') as { error, result };
    parser.setVariable('MY_VARIABLE', 5);
    parser.setVariable('fooBar_01dsds', 10);

    var result2 = parser.parse('(1 + MY_VARIABLE + (5 * fooBar_01dsds)) / fooBar_01dsds') as { error, result }; // returns `5.6`
    var result3 = parser.parse('fooBar_01dsds') as { error, result }; // returns `10`
    parser.setVariable('result_01', 10);
    parser.setVariable('result_02', 30);
    var result4 = parser.parse('AVERAGE(result_01,result_02)') as { error, result }; // returns `15`
    //=IF(J20="","",J9*J20)
    parser.setVariable('J_20',0);
    parser.setVariable('J_9', 5);
    
    var result5 = parser.parse('IF(J_20<>0,"J_9/J_20", "33")') as { error, result }; 
    var data = [
        [1, 2, 3, 4, 5],
        [6, 7, 8, 9, 10],
        [11, 12, 13, 14, 15],
        [16, 17, 18, 19, 20],
    ];

    parser.setVariable('J_J', 10);
    parser.setVariable('K_K', 10);
    parser.setVariable('L_L', 10);
    console.log('IF(J_J=\u0022\u0022,\u0022\u0022,K_K/J_J-1)', 
    parser.parse('IF(J_J=\u0022\u0022,\u0022\u0022,K_K/J_J-1)') as { error, result }
        );
        console.log('L_L/K_K-1', 
        parser.parse('L_L/K_K-1') as { error, result }
            );

    parser.parse('L_L') as { error, result };
    console.log('L_L', 
    parser.parse('L_L') as { error, result });
    
    return (
        <p>
            <label>
                Test Formula Parser
            </label>
            <br />
            <label>
                {`var result = parser.parse('SUM(1, 6, 7)') as {error, result};`}
            </label>
            <br />
            {result.result}/{result.error}
            <br />
            <label>
                {`var result2 =  parser.parse('(1 + MY_VARIABLE + (5 * fooBar)) / fooBar') as {error, result};`}
            </label>
            <br />
            {result2.result}/{result2.error}
            <br />
            <label>
                {`var result3 =  parser.parse('fooBar_01dsds') as {error, result};`}
            </label>
            <br />
            {result3.result}/{result3.error}
            <br/>
            <label>
                {`var result4 = AVERAGE(result_01,result_02) as { error, result };`}
            </label>
            <br/>
            {result4.result}/{result4.error}
            <br/>   
            <label>
                {`var result5 = parser.parse('IF(J_20="","",J_9*J_20)') as { error, result }; `}
            </label>
            {result5.result}/{result5.error}
 
        </p>
    );
}

export default FormulaParserExample;