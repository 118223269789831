import { Button, Form, Input, InputNumber, Modal, Space, Row, Col } from "antd";
import { RnDUnit } from "../../../Scenes/Tools/EBITTool-V2/Models/RnDUnit";
import { inputFormatter, inputParser } from "../../../Utils/helperFunctions";
import i18n from "../../../i18n";
import { BmProject } from "../../../Models/Responses/ProfileResponse";
import { useTranslation } from "react-i18next";
interface IProps {
    currentProject: BmProject;
    createNewUnit: any;
    isModalVisible: boolean;
    finishAdding: () => void;
}
function AddModal(props: IProps) {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const validateForm = async () => {
        try {
            form.submit();
            // const row2 = (await form.validateFields());
            // console.log(row2);
            // let newUnit: RnDUnit = props.unit;

            //props.createNewUnit(newUnit);
        } catch (e) {
            console.log("FormError: ", e);
        }
    }

    // adding new R&D unit, when add new unit (Anlegen) button clicked
    const addNewUnit = async () => {
        try {
            const row2 = (await form.validateFields());
            //console.log(row2);
            let newUnit: RnDUnit = {
                id: 0,
                projectId: props.currentProject.id,
                name: row2["new_unit_name"],
                totalFullFimeEquivalent: row2["new_unit_gesamt"],
                averageAnnualCost: row2["new_unit_avg"],
                rDCosts: [],
            };
            props.createNewUnit(newUnit);
            props.finishAdding();
        } catch (e) {
            console.log("FormError: ", e);
        }
    };
    return (
        <Modal
            getContainer={() => document.getElementById('app-layout')}
            className="EditModal"
            title={t("Add new organizational unit")}
            open={props.isModalVisible}
            onOk={validateForm}
            onCancel={props.finishAdding}
            footer={[
                <Button key="back" onClick={props.finishAdding} >
                    {t("Cancel")}
                </Button>,
                <Button key="submit" type="primary" onClick={validateForm} >
                    OK
                </Button>
            ]}
        >

            <Form form={form} onFinish={addNewUnit} layout="vertical" >
                <Form.Item
                    name={`new_unit_name`}
                    label={`Name`}
                    rules={[
                        {
                            required: true,
                            message: `${t("required*")}`,
                        },
                    ]}
                >
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    name={`new_unit_gesamt`}
                    label={t("Anzahl Mitarbeiter")}
                    rules={[
                        {
                            required: true,
                            message: `${t("required*")}`,
                        },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.reject();
                                }
                                if (isNaN(value)) {
                                    return Promise.reject("MA gesamt must be a number");
                                }
                                return Promise.resolve();
                            }
                        })
                    ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        formatter={value => inputFormatter(value, i18n.language)}
                        parser={x => inputParser(x, i18n.language)}
                    />
                </Form.Item>
                <Form.Item
                    name={`new_unit_avg`}
                    label={t("Durchschnittlicher Jahressatz")}
                    rules={[
                        {
                            required: true,
                            message: `${t("required*")}`,
                        },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.reject();
                                }
                                if (isNaN(value)) {
                                    return Promise.reject('Durchschn. Jahressatz must be a money');
                                }
                                return Promise.resolve();
                            }
                        })
                    ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        formatter={value => inputFormatter(value, i18n.language)}
                        parser={x => inputParser(x, i18n.language)}
                    />
                </Form.Item>
                {/* <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            {`Anlegen`}
                        </Button>
                    )}
                </Form.Item> */}
                <hr />
            </Form>
        </Modal>
    );
}
export default AddModal;
