import { useState, useEffect } from "react";
import { Button, Form, Input, Row, Space, Spin, Typography } from "antd";
import { connect } from "react-redux";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { IIndustrySegmentItem, ISegmentAll } from "../../../Models/IIndustrySegment";
import SegmentsTable from "./SegmentsTable";
import { IIndustryAll } from "../../../Models/IIndustry";
import ModalAdmin from "../../../Components/ModalAdmin/ModalAdmins";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import "./index.scss";
import { useSearchParams } from "react-router-dom";
import { RulesName } from "../Rules/RulesValidation";
import { t } from "i18next";
interface IProps {
    industrySegmentsData: ISegmentAll,
    isLoading: boolean,
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getIndustries: () => any;
    industries: IIndustryAll[];
    getIndustrySegments: (id: number) => any;
    createIndustrySegments: (model: IIndustrySegmentItem, id: number) => any;
}
const IndustrySegments = (props: IProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const industryId = Number(searchParams.get('industryId'));
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<IIndustrySegmentItem>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => {
        props.getIndustrySegments(industryId);
        props.getIndustries();
    }, [industryId]);
    const segmentsData: IIndustrySegmentItem[] = props.industrySegmentsData?.list?.map(s => {
        return {
            key: `seg_${s.id}`,
            ...s
        }
    }) ?? [];
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const newSegment = { ...formValues, industryId: industryId };
            await props.createIndustrySegments(newSegment, industryId);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setIsModalVisible(false);
    }
    return (

        <Spin spinning={props.isLoading || props.isModalLoading}>

            <Typography.Title level={2} style={{ marginLeft: "2rem" }}>{props.industrySegmentsData?.industryName ?? ''}</Typography.Title>

            <div className="admin-component">
                <Space direction="vertical" size="small">
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Segments")}`} btnText={`${t("Add")}`}
                        width="520px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>
                        <Form
                            layout="vertical"
                            form={form}
                        >
                            <Form.Item label={`${t("English Name")}`} name='codeEn'
                                rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}
                                hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, codeEn: (e.target.value).trimStart() })} />
                            </Form.Item>
                            <Form.Item label={`${t("German Name")}`} name='codeDe'
                                rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}
                                hasFeedback>
                                <Input onChange={(e) => setFormValues({ ...formValues, codeDe: (e.target.value).trimStart() })} />
                            </Form.Item>
                        </Form>
                    </ModalAdmin>
                    <SegmentsTable dataRowSegments={segmentsData} industries={props.industries} industryId={industryId} />
                </Space>
            </div>
        </Spin>
    );
}
const mapStateToProps = (state: any) => {
    return {
        industrySegmentsData: state.industrySegments.industrySegmentsResult,
        isLoading: state.industrySegments.isIndustrySegmentLoading,
        industries: state.industries.industriesResult,
        isModalLoading: state.industrySegments.isModalLoading,
        modalResponse: state.industrySegments.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getIndustrySegments: (id: number) => dispatch({ type: ActionTypes.GET_INDUSTRYSEGMENTS, industryId: id }),
        getIndustries: () => dispatch({ type: ActionTypes.GET_INDUSTRIES }),
        createIndustrySegments: (model: IIndustrySegmentItem, id: number) => dispatch({ type: ActionTypes.CREATE_INDUSRTYSEGMENT, model, industryId: id }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustrySegments);
