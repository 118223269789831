import AsupColumnChart from "../BasicCharts/ASupColumnchart"
import { Col, Row } from "antd";
import { EBITProjectBenchmarkData, CostTypesBestPractices } from '../Models/EBITBenchmarkData';


interface Iprops {
    projectsBenchmarks: EBITProjectBenchmarkData[];
    CostTypesBestPractices: CostTypesBestPractices;
    selectChart?: (chartDivId: string, add: boolean) => void;
    selectedCharts?: string[],
}
const BenchmarkCostTypes = (props: Iprops) => {
    const GetChartDataByCostType = (costType: string) => {
        // let projectsCountPerBranch = {};
        let ebitBMProjectsForChart = props.projectsBenchmarks?.map((x) => {
            // if (!x.isTarget)
            //     projectsCountPerBranch[x.annoName] = (projectsCountPerBranch[x.annoName] || 0) + 1;
            return {
                name: x.projectName,
                // annoName: x.annoName.substring(0, 1).toUpperCase() + projectsCountPerBranch[x.annoName],
                value: x.costTypes ? x.costTypes[costType] : 0,
                isTarget: x.isTarget,
                isComplete: x.isComplete,
            }
        });
        return ebitBMProjectsForChart;
    }
    return (
        <>
            <div className="chart_container">
                <Row>
                    <Col span={12}>
                        <AsupColumnChart
                            selectedCharts={props.selectedCharts}
                            selectChart={props.selectChart}
                            isTopLevelChart={false}
                            key={`cod`}
                            subTitle="Cost of Delay EBIT-Ratio Potential [%]"
                            ebitBMProjectsForChart={GetChartDataByCostType('codRate')}
                            bestPractice={props.CostTypesBestPractices?.codbp}
                        />
                    </Col>
                    <Col span={12}>
                        <AsupColumnChart
                            selectedCharts={props.selectedCharts}
                            selectChart={props.selectChart}
                            isTopLevelChart={false}
                            key={`qk`}
                            subTitle="Quality Cost EBIT-Ratio Potential [%]"
                            ebitBMProjectsForChart={GetChartDataByCostType('qcRate')}
                            bestPractice={props.CostTypesBestPractices?.qcbp}
                        />
                    </Col>
                </Row>
            </div>
            <div className="chart_container">
                <Row>
                    <Col span={12}>
                        <AsupColumnChart
                            selectedCharts={props.selectedCharts}
                            selectChart={props.selectChart}
                            isTopLevelChart={false}
                            key={`hk`}
                            subTitle="Manufacturing Costs EBIT-Ratio Potential [%]"
                            ebitBMProjectsForChart={GetChartDataByCostType('hkRate')}
                            bestPractice={props.CostTypesBestPractices?.hkbp}
                        />
                    </Col>
                    <Col span={12}>
                        <AsupColumnChart
                            selectedCharts={props.selectedCharts}
                            selectChart={props.selectChart}
                            isTopLevelChart={false}
                            key={`inno 3`}
                            subTitle="Total Innovation / sales [%]"
                            ebitBMProjectsForChart={GetChartDataByCostType('innoRate')}
                            bestPractice={props.CostTypesBestPractices?.innobp}
                        />
                    </Col>

                </Row>
            </div>
            <div className="chart_container">
                <Row>
                    <Col span={12}>
                        <AsupColumnChart
                            selectedCharts={props.selectedCharts}
                            selectChart={props.selectChart}
                            isTopLevelChart={false}
                            key={`inno 1`}
                            subTitle="Innovation Portfolio / sales [%]"
                            ebitBMProjectsForChart={GetChartDataByCostType('innoPortfolioRate')}
                            bestPractice={props.CostTypesBestPractices?.innoPortfolioBP}
                        />
                    </Col>
                    <Col span={12}>
                        <AsupColumnChart
                            selectedCharts={props.selectedCharts}
                            selectChart={props.selectChart}
                            isTopLevelChart={false}
                            key={`inno 2`}
                            subTitle="Innovation substitutions / sales [%]"
                            ebitBMProjectsForChart={GetChartDataByCostType('innoSubstitutionsRate')}
                            bestPractice={props.CostTypesBestPractices?.innoSubstitutionsBP}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default BenchmarkCostTypes;