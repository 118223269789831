import { takeEvery, all, put } from 'redux-saga/effects';
import { ActionTypes } from '../actionsTypes';
import { IResponseDProject } from '../../Models/IResponseDProject';
import { IDProject } from '../../Models/IDProject';
import { ICommonResponse } from '../../Models/Responses/ICommonResponse';
import DProjectsServices from '../../Services/DProjectsService';
import { ToastMessages } from '../../Enums/Messages';
import DprojectType from '../../Scenes/Tools/DProjects/DataType/DprojectType';

function* onGetDProjects(action: any) {
    try {
        yield put({ type: ActionTypes.FetchingDprojects, value: true });
        let _result: ICommonResponse = yield DProjectsServices.GetDProjects(action.bmProjectId);
        if (!_result.isError) {
            let result = _result.result as IResponseDProject[];

            console.log("IResponseDProject[] sagas: ", result);

            let dprojects = result.map(newDproject => {
                let dproject: IDProject = {
                    ...newDproject.generalDprojectInfo,
                    ...newDproject.innovation,
                    ...newDproject.costOfDelay,
                    ...newDproject.manufacturingCost,
                    ...newDproject.qualityCost,
                    ...newDproject.questionaire
                };
                return dproject;
            });
            console.log("dprojects sagas: ", dprojects);

            yield put({ type: ActionTypes.SAVEDPROJECTS, dprojects: dprojects });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.FetchingDprojects, value: false });

    } catch (err) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.FetchingDprojects, value: false });
    }
}

function* onUploadDproject(action: any) {
    try {
        yield put({ type: ActionTypes.UPLOADINGDPROJECT, value: true });
        let _result: ICommonResponse = yield DProjectsServices.UploadDproject(action.dproject);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.UPLOADINGDPROJECT, value: false });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.dproject.generalDprojectInfo.projectId });
            yield put({ type: ActionTypes.GetDProjectsProgressValue });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.UPLOADINGDPROJECT, value: false });
        }

    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.UPLOADINGDPROJECT, value: false });
    }
}

function* onFetchDprojectComments(action: any) {
    try {
        yield put({ type: ActionTypes.FetchingDprojectComments, value: true });
        let _result: ICommonResponse = yield DProjectsServices.FetchDprojectComments(action.dprojectId);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SAVEDPROJECTCOMMENTS, dprojectComments: _result.result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.FetchingDprojectComments, value: false });
    } catch (err) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.FetchingDprojectComments, value: false });
    }
}

function* onUploadDprojectComments(action: any) {
    try {
        yield put({ type: ActionTypes.UPLOADINGDPROJECTCOMMENT, value: true });
        let _result: ICommonResponse = yield DProjectsServices.UploadDprojectComments(action.comment);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.FETCHDPROJECTCOMMENTS, dprojectId: action.comment.dprojectId });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.UPLOADINGDPROJECTCOMMENT, value: false });
    } catch (err) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.UPLOADINGDPROJECTCOMMENT, value: false });
    }
}

function* onUpdateComment(action: any) {
    try {
        yield put({ type: ActionTypes.UPDATINGCOMMENT, value: true });
        let _result: ICommonResponse = yield DProjectsServices.UpdateComment(action.comment);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.FETCHDPROJECTCOMMENTS, dprojectId: action.dProjectId });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.UPDATINGCOMMENT, value: false });
    } catch (err) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.UPDATINGCOMMENT, value: false });
    }
}

function* onDeleteComment(action: any) {
    try {
        yield put({ type: ActionTypes.DELETEINGCOMMENT, value: true });
        let _result: ICommonResponse = yield DProjectsServices.DeleteComment(action.commentId);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.FETCHDPROJECTCOMMENTS, dprojectId: action.dProjectId });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.DELETEINGCOMMENT, value: false });
    } catch (err) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.DELETEINGCOMMENT, value: false });
    }
}

function* onUpdateDproject(action: any) {
    try {
        yield put({ type: ActionTypes.UPDATINGDPROJECT, value: true });
        let _result: ICommonResponse = yield DProjectsServices.UpdateDproject(action.dproject);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.UPDATINGDPROJECT, value: false });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.dproject.generalDprojectInfo.projectId });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.UPDATINGDPROJECT, value: false });
        }
    } catch (err) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.UPDATINGDPROJECT, value: false });
    }
}

function* onDeleteDproject(action: any) {
    try {
        yield put({ type: ActionTypes.DLTINGDPROJECT, value: true });
        let _result: ICommonResponse = yield DProjectsServices.DeleteDproject(action.dprojectId);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.DLTINGDPROJECT, value: false });
            yield put({ type: ActionTypes.GETDPROJECTS, bmProjectId: action.bm_projectId });
            yield put({ type: ActionTypes.GetDProjectsProgressValue });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.DLTINGDPROJECT, value: false });
        }
    } catch (err) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.DLTINGDPROJECT, value: false });
    }
}

function* onGetDprojectsTypes(action: any) {
    try {
        yield put({ type: ActionTypes.GETINFTYPES, value: true });
        let _result: ICommonResponse = yield DProjectsServices.GetDprojectsTypes();
        if (!_result.isError) {
            let result = _result.result as DprojectType[];
            yield put({ type: ActionTypes.DPROJECTTYPESFSUCCESS, dprojectsTypes: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GETINFTYPES, value: false });
    } catch (err) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GETINFTYPES, value: false });
    }
}

function* onGetDprojectProgress(action: any) {
    try {
        let _result: ICommonResponse = yield DProjectsServices.GetDprojectProgress();
        if (!_result.isError) {
            let result = _result.result as number;
            yield put({ type: ActionTypes.SaveDprojectsProgress, dprojectProgress: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onGetIncludeInLevers(action: any) {
    try {
        yield put({ type: ActionTypes.GettingIncludeInLevers, value: true });
        let _result: ICommonResponse = yield DProjectsServices.GetIncludeInLevers();
        if (!_result.isError) {
            let result = _result.result as number[];
            yield put({ type: ActionTypes.SaveDprojectsIdsIncludedInLevers, dprojectIds: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.GettingIncludeInLevers, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.GettingIncludeInLevers, value: false });
    }
}

function* onUploadIncludeInLevers(action: any) {
    try {
        yield put({ type: ActionTypes.UploadingIncludeInLevers, value: true });
        let _result: ICommonResponse = yield DProjectsServices.UploadIncludeInLevers(action.includeInLeversArr);
        if (!_result.isError) {
            let result = _result.result as number[];
            yield put({ type: ActionTypes.SaveDprojectsIdsIncludedInLevers, dprojectIds: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.UploadingIncludeInLevers, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.UploadingIncludeInLevers, value: false });
    }
}

function* onMarkDProjectAnalysisAsComplete(action: any) {
    try {
        yield put({ type: ActionTypes.MarkingDProjectAnalysisAsComplete, value: true });
        let _result: ICommonResponse = yield DProjectsServices.MarkDProjectAnalysisAsComplete();
        if (!_result.isError) {
            yield put({ type: ActionTypes.GetDProjectsProgressValue });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.MarkingDProjectAnalysisAsComplete, value: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
        yield put({ type: ActionTypes.MarkingDProjectAnalysisAsComplete, value: false });
    }
}

function* watchGetDProjects() {
    yield takeEvery(ActionTypes.GETDPROJECTS, onGetDProjects);
}

function* watchUploadDproject() {
    yield takeEvery(ActionTypes.UPLOADDPROJECT, onUploadDproject);
}

function* watchFetchDprojectComments() {
    yield takeEvery(ActionTypes.FETCHDPROJECTCOMMENTS, onFetchDprojectComments);
}

function* watchUploadDprojectComments() {
    yield takeEvery(ActionTypes.UPOLADDPROJECTCOMMENT, onUploadDprojectComments);
}

function* watchUpdateComment() {
    yield takeEvery(ActionTypes.UPDATEDPROJECTCOMMENT, onUpdateComment);
}


function* watchDeleteComment() {
    yield takeEvery(ActionTypes.DELETECOMMENT, onDeleteComment);
}

function* watchUpdateDproject() {
    yield takeEvery(ActionTypes.UPDATEDPROJECT, onUpdateDproject);
}

function* watchDeleteDproject() {
    yield takeEvery(ActionTypes.DELETEDPROJECT, onDeleteDproject);
}

function* watchGetDprojectsTypes() {
    yield takeEvery(ActionTypes.GETDPROJECTSTYPES, onGetDprojectsTypes);
}
function* watchGetDprojectProgress() {
    yield takeEvery(ActionTypes.GetDProjectsProgressValue, onGetDprojectProgress);
}

function* watchGetIncludeInLevers() {
    yield takeEvery(ActionTypes.GetIncludeInLevers, onGetIncludeInLevers);
}

function* watchUploadIncludeInLevers() {
    yield takeEvery(ActionTypes.UploadIncludeInLevers, onUploadIncludeInLevers);
}

function* watchMarkDProjectAnalysisAsComplete() {
    yield takeEvery(ActionTypes.MarkDProjectAnalysisAsComplete, onMarkDProjectAnalysisAsComplete);
}

export default function* DProjectsSagas() {
    yield all([
        watchMarkDProjectAnalysisAsComplete(),
        watchUploadIncludeInLevers(),
        watchGetIncludeInLevers(),
        watchGetDProjects(),
        watchUploadDproject(),
        watchFetchDprojectComments(),
        watchUploadDprojectComments(),
        watchUpdateComment(),
        watchDeleteComment(),
        watchUpdateDproject(),
        watchDeleteDproject(),
        watchGetDprojectsTypes(),
        watchGetDprojectProgress()
    ]);
}