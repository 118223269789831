import {
  CollapsePanelProps,
  Collapse,
  Form,
  Row,
  Col,
  InputNumber,
  Tooltip,
} from "antd";
import { General } from "../Models/Estimate";
import { useTranslation } from "react-i18next";
import { inputFormatter, inputParser } from "../../../../Utils/helperFunctions";
import i18n from "../../../../i18n";
import { ExclamationCircleOutlined } from "@ant-design/icons";

type IGeneral = {
  generalInfo: General;
  yearUnderReview: string;
  handle_change: (value, name) => void;
} & CollapsePanelProps;

function GeneralPanel(props: IGeneral) {
  const { t } = useTranslation();

  return (
    <Collapse.Panel {...props} className="estimate-custom-panel">
      <Form>
        <Row>
          <Col span={16}>
            <Form.Item
            // label={`${t("Wie hoch war der Umsatz (im Geschäftsjahr")} ${props.yearUnderReview})?`}
            // style={{ width: "59%", paddingRight: "2%" }}
            >
              <text style={{ paddingRight: 7 }}>{`${t("Wie hoch war der Umsatz (im Geschäftsjahr")} ${props.yearUnderReview})?`}</text>
              <InputNumber
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
                //formatter={value => `${value} €`}
                onChange={(value) => {
                  if (typeof value === "number") {
                    props.handle_change(value, "salesLastYear")
                  }
                }}

                value={props.generalInfo?.salesLastYear}
                //parser={value => Number(value!.replace(' €', ''))}
                addonAfter="Million €"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
            // label={t("Wie hoch war die EBIT-Rate?")}
            // style={{ width: "36%" }}
            >
              <text style={{ paddingRight: 7 }}>{t("Wie hoch war die EBIT-Rate?")}</text>
              <InputNumber
                // defaultValue={0}
                min={0}
                max={100}
                // name="EBITRate"
                style={{ width: "20ch" }}
                onChange={(value) => props.handle_change(value, "ebitRate")}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
                value={props.generalInfo?.ebitRate}
                addonAfter="%"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form.Item style={{ marginBottom: "1%" }}>
        <label style={{ fontWeight: "bold" }}> {t("Benchmark-Annahme")} {props.yearUnderReview}</label>
      </Form.Item>
      <Form>
        <Row>
          <Col span={4}>
            <Form.Item label={<>
              <Tooltip placement="top" title={t("NPS 3 (Umsatz mit den Produkten der 3 letzten Jahre, die innovative Elemente enthalten)")}>
                <ExclamationCircleOutlined
                  style={{ marginLeft: "10px", color: "black" }}
                />  
              </Tooltip>
              &nbsp;Inno
            </>}>
              <InputNumber
                defaultValue={0}
                min={0}
                max={100}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
                name="estimateBenchmarkInno"
                onChange={(value) => {
                  if (typeof value === "number") {
                    props.handle_change(value, "estimateBenchmarkInno")
                  }
                }
                }
                value={props.generalInfo?.estimateBenchmarkInno}

                //parser={value => Number(value!.replace(' %', ''))}
                addonAfter="%"
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={4}>
            <Form.Item label={t("QK")}>
              <InputNumber
                defaultValue={0}
                min={0}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
                max={100}
                //formatter={value => `${value} %`}
                name="estimateBenchmarkQC"
                onChange={(value) =>
                  props.handle_change(value, "estimateBenchmarkQC")
                }
                value={props.generalInfo?.estimateBenchmarkQC}
                //parser={value => Number(value!.replace(' %', ''))}
                addonAfter="%"
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={4}>
            <Form.Item label={t("F&E")}>
              <InputNumber
                defaultValue={0}
                min={0}
                max={100}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
                //formatter={value => `${value} %`}
                name="estimateBenchmarkRD"
                onChange={(value) =>
                  props.handle_change(value, "estimateBenchmarkRD")
                }
                value={props.generalInfo?.estimateBenchmarkRD}
                //parser={value => Number(value!.replace(' %', ''))}
                addonAfter="%"
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={4}>
            <Form.Item label="COD">
              <InputNumber
                defaultValue={0}
                min={0}
                max={100}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
                //formatter={value => `${value} %`}
                name="estimateBenchmarkCOD"
                onChange={(value) =>
                  props.handle_change(value, "estimateBenchmarkCOD")
                }
                value={props.generalInfo?.estimateBenchmarkCOD}
                //parser={value => Number(value!.replace(' %', ''))}
                addonAfter="%"
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={4}>
            <Form.Item label={t("HK")}>
              <InputNumber
                defaultValue={0}
                min={0}
                max={100}
                formatter={value => inputFormatter(value, i18n.language)}
                parser={x => inputParser(x, i18n.language)}
                //formatter={value => `${value} %`}
                name="estimateBenchmarkMC"
                onChange={(value) =>
                  props.handle_change(value, "estimateBenchmarkMC")
                }
                value={props.generalInfo?.estimateBenchmarkMC}
                //parser={value => Number(value!.replace(' %', ''))}
                addonAfter="%"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Collapse.Panel>
  );
}
export default GeneralPanel;
