import { t } from "i18next";


export enum GeneralQuestions {
    SalesLastYearQuest = 'Wie hoch war der Umsatz (im letzten Geschäftsjahr)?',
    EBITRateQuest = 'Wie hoch war die EBIT-Rate?',
    EstimateBenchmarkInnoQuest = 'Inno',
    EstimateBenchmarkQCQuest = 'QK',
    EstimateBenchmarkRDQuest = 'F&E',
    EstimateBenchmarkCODQuest = 'COD',
    EstimateBenchmarkMCQuest = 'HK',
}
 
export const CODQuestions = {
    ProjectCountLastYear: `${t("Anzahl von Projekten, die in")} Jahr -1 ${t("abgeschlossen wurden:")}`,
    AvgDelayLastYear: `${t("Wie hoch schätzen Sie die durchschnittliche Verzögerung dieser Projekte ein (in Monaten)?")}`,
    AvgProjectCostLastYear: `${t("Wie hoch schätzen Sie die durchschnittlichen Projektkosten pro Monat ein?")}`,
    AvgExpectedSales: `${t("Wie hoch ist der durchschnittlich erwartete, monatliche Umsatz mit den entsprechenden Produkten?")}`,
    AdditionalCostCOD: `${t("Welche zusätzlichen Kosten sind bei diesen Projekten durch Verzögerungen entstanden (z.B. durch F&E-Nachentwicklungskosten nach SOP)?")}`,
}

export const InnoQuestions = {
    ProductsSalesLastYear: `${t("Wie hoch schätzen Sie den Umsatz dieser Produkte im Geschäftsjahr Jahren ein?")}`,
    ProductsAvgEBITRateLastYear: `${t("Wie hoch schätzen Sie die durchschnittliche EBIT-Rate dieser Produkte ein?")}`, 
    InnoSalesShare: `${t("Innovationsumsatzanteil: ")}`
}
 
export const RDQuestions = {
    personnelCostDevelopmentArea: `${t("Wie hoch sind die originären Personalkosten (ohne Material) des Entwicklungsbereiches?")}`,
    personnelCostinterdisciplinary: `${t("Wie hoch sind die Personalkosten interdisziplinärer Funktionen (z.B. Einkauf, Produktion, QS, ...) für die Beteiligung am Produktentstehungsprozess Jahr?")}`,
}
 
export const QCQuestions = {
    QualityCost: `${t("Wie hoch sind die Qualitätskosten in Jahr Gewährleistung, Garantie, Preisnachlässe, Ausschuss, Nacharbeiten")}`,
    QualityCostByRD: `${t("Wie hoch ist der Anteil der oben genannten Qualitätskosten, der durch die F&E (nicht durch Produktion oder Lieferanten) verursacht wurde?")}`,
}
 
export const MCQuestions = {
    CompletedProjectLastYear: `${t("Anzahl von Projekten, die in")} Jahr -1 ${t("abgeschlossen wurden:")}`,
    ManufactoringCostDeviation: `${t("Wie hoch war die durchschnittliche Abweichung von den geplanten HK dieser Produkte?")}`,
    Tooltip: `${t("Produktionskosten = Materialkosten + Fertigungskosten + Fertigungsgemeinkosten")}`,
    AvgQuantity: `${t("Wie hoch ist die durchschnittliche geplante Jahresstückzahl nach Ramp-Up für oben genannte Produkte (Business Case)?")}`,
    AvgPlannedManufactoringCost: `${t("Wie hoch sind die durchschnittlichen, geplanten Produktionskosten für oben genannte Produkte (pro Stück)?")}`,
}




