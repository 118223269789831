import { ActionTypes } from "../actionsTypes";
import { IDepartmentByCustomerState } from "./IGeneralState";


const initialState: IDepartmentByCustomerState = {
    isDepartmentByCustomerLoading: false,
    departmentsByCustomerResult: null,
    isModalLoading: false,
    modalResponse: null,
};
const DepartmentsByCustomerReducer = (state = initialState, action: any): IDepartmentByCustomerState => {
    switch (action.type) {
        case ActionTypes.CHANGE_DEPARTMENTS_BY_CUSTOMER_LOADER:
            return {
                ...state,
                isDepartmentByCustomerLoading: action.isLoading,
            };
        case ActionTypes.SAVE_DEPARTMENTS_BY_CUSTOMER:
            return {
                ...state,
                departmentsByCustomerResult: action.result,
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            }
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            }
        default:
            break;
    }
    return state;
};
export default DepartmentsByCustomerReducer;
