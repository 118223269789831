import { ActionTypes } from "../actionsTypes";
import { IEventLogsFiltersState, IEventLogsState } from "./IGeneralState";


const initialStateLogs: IEventLogsState = {
    isEventLogsLoading: false,
    eventLogsResult: null,
}

const initialStateFilters: IEventLogsFiltersState = {
    isFilterMenusLoading: false,
    filterMenus: null,
}

export const EventLogsForAdminReducer = (state = initialStateLogs, action: any): IEventLogsState => {
    switch (action.type) {
        case ActionTypes.CHANGE_EVENT_LOGS_LOADER:
            return {
                ...state,
                isEventLogsLoading: action.isLoading,
            };
        case ActionTypes.SAVE_EVENT_LOGS:
            return {
                ...state,
                eventLogsResult: action.result
            };

        default:
            break;
    }
    return state;
}

export const EventLogsFiltersForAdminReducer = (state = initialStateFilters, action: any): IEventLogsFiltersState => {
    switch (action.type) {
        case ActionTypes.CHANGE_EVENT_LOGS_FILTERS_LOADER:
            return {
                ...state,
                isFilterMenusLoading: action.isLoading,
            };

        case ActionTypes.SAVE_EVENT_LOGS_FILTERS:
            return {
                ...state,
                filterMenus: action.result
            };
        default:
            break;
    }
    return state;
}
