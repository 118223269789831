import { Button, Form, Input, InputNumber } from 'antd'
import { t } from 'i18next';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { IActivityProfile } from '../../../../Models/IActivityManagement';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { RulesName, RulesRequire } from '../../Rules/RulesValidation';
import { inputFormatter, inputParser } from '../../../../Utils/helperFunctions';
import i18n from '../../../../i18n';

interface IProps {
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    createActivityProfile: (model: IActivityProfile) => any;

}
const ActivityProfileEditModal = (props: IProps) => {
    const [formValues, setFormValues] = useState<IActivityProfile>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            await props.createActivityProfile(formValues)


        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <ModalAdmin title={`${t("Add Activity Profile")}`} btnText={`${t("Add")}`}
            width="520px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form}>
                <Form.Item label={`${t("English Name")}`} name='nameEn'
                    rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}>
                    <Input
                        onChange={(e) =>
                            setFormValues({ ...formValues, nameEn: e.target.value })
                        }
                    />
                </Form.Item>
                <Form.Item label={`${t("German Name")}`} name='nameDe'
                    rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}>
                    <Input
                        onChange={(e) =>
                            setFormValues({ ...formValues, nameDe: e.target.value })
                        }
                    />
                </Form.Item> 
            </Form>
        </ModalAdmin>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        createActivityProfile: (model: IActivityProfile) => dispatch({ type: ActionTypes.CREATE_ACTIVITY_PROFILE_FOR_ADMIN, model }),
    };
};

export default connect(null, mapDispatchToProps)(ActivityProfileEditModal);
