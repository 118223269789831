import FlDepartment from "../Models/FlDiscipline";
import { Fte, MileStone, Phase } from "../Models/Phase";

export function phasesToTableRow(phases: Phase[]): any {
    let row = {};
    let allMilestonesIds: string[] = [];
    phases.forEach(phase => {
        phase.mileStones.forEach(milestone => {
            allMilestonesIds.push(milestone.id.toString());
        });
    });
    allMilestonesIds.forEach((id, idx) => {
        row[id] = idx;
    });
    return row;
}

export function getTableData(phases: Phase[], departments: FlDepartment[]): any[] {
    departments.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
    return departments.map((department, idx) => {
        let row = phasesToTableRow(phases);

        row['key'] = department.id;
        row['department'] = department.order.toString() + "." + department.name;
        row['departmentId'] = department.id;
        row['foreGroundColor'] = department.foreGroundColor;
        row['backGroundColor'] = department.backGroundColor;

        console.log("row_after: ", row);
        return row;
    });
}

export function getMilestonesArr(phases: Phase[]): MileStone[] { // phases must be ordered before using this function. And Milestones must be ordered in each phase.
    let allMilestones: MileStone[] = [];
    phases.forEach(phase => {
        allMilestones.push(...phase.mileStones);
    });
    return allMilestones;
}

export function getTableRowData(allMilestones: MileStone[], department: FlDepartment) {
    let ftesValuesObj = {};
    allMilestones.forEach(milestone => {
        let departmentFteForMilestone: Fte = milestone.ftes.find(fte => fte.departmentId === department.id);
        ftesValuesObj[milestone.id.toString()] = departmentFteForMilestone?.value;
        ftesValuesObj[milestone.id.toString() + '_fteId'] = departmentFteForMilestone?.id;
    });

    ftesValuesObj['key'] = department.id;
    ftesValuesObj['department'] = department.order.toString() + "." + department.name;
    ftesValuesObj['departmentId'] = department.id;
    ftesValuesObj['foreGroundColor'] = department.foreGroundColor;
    ftesValuesObj['backGroundColor'] = department.backGroundColor;
    return ftesValuesObj;
}

export function getTableRows(phases: Phase[], departments: FlDepartment[]) {
    let allMilestones = getMilestonesArr(phases);
    let maxDepartmentOrder = getMaxDepartmentOrder(departments);
    let total = {};
    phases.forEach((phase, idx) => {

        total[phase.id.toString() + "_phase"] = calculateSumFtesForPhase(phase);
        total['key'] = 'total';
        total['department'] = (10000).toString() + "." + "Total";
        total['departmentId'] = 10000;
        total['foreGroundColor'] = "#FFFFFF";
        total['backGroundColor'] = "#000000";
    });
    let rows = departments.map(department => getTableRowData(allMilestones, department));
    rows.push(total);
    return rows;
}

const calculateSumFtesForPhase = (phase: Phase): number => {
    let phaseTotal = 0;
    phase.mileStones.forEach(milestone => {
        const sumMilestoneFtes = milestone.ftes.reduce((partialSum, a) => partialSum + ((typeof a.value === 'number') ? (a.value as number) : 0), 0);
        phaseTotal = phaseTotal + sumMilestoneFtes
    });
    return phaseTotal;
}
const getMaxDepartmentOrder = (departments: FlDepartment[]) => {
    let departmentsOrders = departments.map(d => d.order);
    return Math.max(...departmentsOrders);
};