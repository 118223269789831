export const DProjectValidationRules = {

    name: true,
    finishingYear: true,
    runTime: false,
    comments: false,

    revenue: false,
    productMargin: false,
    projectType: false,

    delay: false,
    missedExpectedSales: false,
    projectCostsDelayedMonths: false,
    otherAdditionalCosts: false,

    mcPlanned: false,
    mcActual: false,
    quantity: false,
    otherCosts: false,
    mcShareOfRD: false,

    qualityCosts: false,
    qualityShareOfRD: false,

    q1_Total_Sales_in_N: false,
    q1_Revenue_share: false,

    q2_Total_Sales_in_N: false,
    q2_Revenue_share: false,

    q3_Total_Sales_in_N: false,
    q3_Revenue_share: false,

    q4_Target_price: false,
    q4_Actual_price: false,
    q4_Deviation: false,

    q5_Planned_quantities: false,
    q5_Actual_quantities: false,
    q5_Deviation: false,

    q6_Planned_project_costs: false,
    q6_Deviation: false,

    q7_customer_experience: false,

    q8_technical_safeguarding: false,

    q9_tool_dropped_parts: false,

    q10_series_production: false,

    q11_costs_last_3months: false,

    q12_costs_last_3months: false,

    q13_Average_delay: false,

    q14_One_time_yield_rate: false,

    q15_Claim_Tickets: false,

    q16_Complaint_rate: false,

    q17_Planned_revenue: false,
    q17_Actual_revenue: false,
    q17_Deviation: false,

    q18_Support_requests: false,

    q19_Planned_project_runtime: false,
    q19_Deviation: false,

    q20_features_relaized: false,

    q21_Budget_Deviation: false,

    q22_MC_Deviation: false,
}; 