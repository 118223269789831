import React, { Component } from 'react';
import './App.css';
import Router from './Router';

export interface IAppProps {
  //sessionStore?: SessionStore; 
}

class App extends Component<IAppProps>{


  async componentDidMount() {
    // await this.props.sessionStore!.getCurrentLoginInformations();

    // if (!!this.props.sessionStore!.currentLogin.user) {
    //   //this.props.sessionStore!.currentLogin.application.features['SignalR']
    // }
    window.addEventListener("error", (e) => {
      console.error("Err from App component: ", e);
      return true;
    });
  }

  public render() {
    return <Router />;
  }
}
export default App;
