import { GeneralQuestions, CODQuestions, InnoQuestions, RDQuestions, QCQuestions, MCQuestions } from "../EstimateQuestions/EstimateQuestions";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


export default class Estimate {
    public BmProjectId: number = 0;
    public generalinfo: General = new General();
    public cod: CostOfDelay;// = new CostOfDelay(this.year); 
    public inno: InnoCost;// = new InnoCost(); 
    public rd: RDCost;//= new RDCost();
    public qc: QCCost;//= new QCCost();
    public mc: MCCost;//= new MCCost();
    constructor(year: number) {
        this.cod = new CostOfDelay(year);
        this.inno = new InnoCost(year);
        this.rd = new RDCost(year);
        this.mc = new MCCost(year);
        this.qc = new QCCost(year);
    }
}

export class General {

    public salesLastYear: number = 0;
    public ebitRate: number = 0;
    public estimateBenchmarkInno: number = 0;
    public estimateBenchmarkQC: number = 0;
    public estimateBenchmarkRD: number = 0;
    public estimateBenchmarkCOD: number = 0;
    public estimateBenchmarkMC: number = 0;
}
// const AvgDelayLastYear = `${t("Wie hoch schätzen Sie die durchschnittliche Verzögerung der Projekte ein (in Monaten)?")}`;
// const CODQuestions = {
//     ProjectCountLastYear : `${t("WAnzahl von Projekten, die im")} Jahr -1 ${t("abgeschlossen wurden:")}`,
//     AvgDelayLastYear  : `${t("Wie hoch schätzen Sie die durchschnittliche Verzögerung der Projekte ein (in Monaten)?")}`,
//     AvgProjectCostLastYear : `${t("Wie hoch schätzen Sie die durchschnittlichen Projektkosten pro Monat ein?")}`,
//     AvgExpectedSales : `${t("Wie hoch ist der durchschnittlich erwartete, monatliche Umsatz mit den entsprechenden Produkten?")}`,
//     AdditionalCostCOD : `${t("Welche zusätzlichen Kosten sind bei diesen Projekten durch Verzögerungen entstanden (z.B. durch F&E-Nachentwicklungskosten nach SOP)?")}`,
// }


export class CostOfDelay {

    public subHeader: string = `${t("Bezogen auf Projekte, die in")} Jahr -1 ${t("abgeschlossen wurden:")}`;
    public projectCountLastYear: estimatefield = { Question: CODQuestions.ProjectCountLastYear, Answer: 0,TooltipAnswer:"", AnswerType: 0, Tooltip: t("Wenn in diesem Referenzjahr kein SOP stattgefunden hat, muss vereinbart werden, ob man dafür ein Vorgängerjahr verwendet oder den Durschnitt von 2 bis 3 Vorgängerjahren")};
    public avgDelayLastYear: estimatefield = { Question: CODQuestions.AvgDelayLastYear, Answer: 0,TooltipAnswer:"", AnswerType: 0, Tooltip: '' };
    public avgProjectCostLastYear: estimatefield = { Question: CODQuestions.AvgProjectCostLastYear, Answer: 0,TooltipAnswer:"", AnswerType: 1, Tooltip: '' };
    public avgExpectedSales: estimatefield = { Question: CODQuestions.AvgExpectedSales, Answer: 0,TooltipAnswer:"", AnswerType: 1, Tooltip: t("Ausgehend vom zuletzt bei Investitionsfreigabe geplanten (gate!) monatlicher Umsatz nach Ramp-Up.Bei Software, Produktänderungen oder Plattformkomponenten ist oft der entsprechende Umsatz unklar – hier hilft die Frage: „was hätte man nicht, wenn es diese Produkt/Feature nicht gegeben hätte?“ oder „um wieviel verkaufe ich XYZ mehr?") };
    public additionalCostCOD: estimatefield = { Question: CODQuestions.AdditionalCostCOD, Answer: 0,TooltipAnswer:"", AnswerType: 1, Tooltip: t("Auch externe Zusatzkosten, Vertragsstrafen, zusätzliche Test- und Zertifizierungsaufwände usw. ABER: Nachentwicklungskosten gehören zu QK!")};
    constructor(year: number) {
        if (year !== 0) {
            this.subHeader = this.subHeader.replace("Jahr -1", (year).toString());
            this.projectCountLastYear.Question = this.projectCountLastYear.Question.replace("Jahr -1", (year).toString());
        }
    }


}

export class InnoCost {
    public subHeader: IHeaderWithTooltip ={ title:`${t("Bezogen auf Produkte, die in den Jahren")} Jahren -3 - -1 ${t("auf den Markt gekommen sind:")}`, headerTooltip:t("„Es geht hier um Produkte mit „echter Innovation“: Produkte ohne Vorgänger oder mit Features, die es bisher im Portfolio nicht gab. Damit erfolgt die Abgrenzung zur kontinuierlichen, inkrementellen Weiterentwicklung.“ (Inno-Leistung auch bekannt als NPS3 = New Product Sales last 3 years = Innovation Vitaliy Index)")};
    public productsSalesLastYear: estimatefield = { Question: InnoQuestions.ProductsSalesLastYear, Answer: 0,TooltipAnswer:"", AnswerType: 1, Tooltip: '' };
    public productsAvgEBITRateLastYear: estimatefield = { Question: InnoQuestions.ProductsAvgEBITRateLastYear, Answer: 0,TooltipAnswer:t("Das durchschnittliche EBIT dieser neuartigen Produkte mit „echter Innovation“ – sollte idR höher als der EBIT-Durchschnitt sein, da diese Produkte ja einen höheren Geschäftswert bzw. Kundennutzen besitzen!"), AnswerType: 2, Tooltip: '' };
    public InnoSalesShare : estimatefield = { Question: InnoQuestions.InnoSalesShare, Answer: 0, Tooltip:"", AnswerType: 2, TooltipAnswer:''}
    //public ebitProductExtensions: estimatefield = { Question: InnoQuestions.EBITProductExtensions, Answer: 0, AnswerType: 2, Tooltip: '' };
    constructor(year: number) {
        if (year !== 0) {
            let start = year - 2;
            let end = year;
            this.subHeader.title = this.subHeader.title.replace("Jahren -3 - -1", (start + " - " + end));
            this.productsSalesLastYear.Question = this.productsSalesLastYear.Question.replace("Jahren", year.toString())
        }
    }

}

export class RDCost {
    public subHeader: string = `${t("Bezogen auf das Geschäftsjahr")} Jahr -1:`;
    public personnelCostDevelopmentArea: estimatefield = { Question: RDQuestions.personnelCostDevelopmentArea, Answer: 0,TooltipAnswer:"", AnswerType: 1, Tooltip: t("Personalkosten inklusive externer Personalkosten für: Vorentwicklung, Systems Engineering, Simulation, Mechanical Engineering, Hardware Engineering, Software Engineering, Prototyping, Test, R&D Support, Patent Management") };
    public personnelCostinterdisciplinary: estimatefield = { Question: RDQuestions.personnelCostinterdisciplinary, Answer: 0, TooltipAnswer:"", AnswerType: 1, Tooltip: t("Personalkosten inklusive externer Personalkosten für: Projektmanagement, Sales, Produktmanagement, Einkauf, Controlling, Qualität, IE und Service. Achtung nur die Aufwände, die im PEP investiert werden.")};
    constructor(year: number) {
        if (year !== 0) {
            this.subHeader = this.subHeader.replace("Jahr -1", (year).toString());
            this.personnelCostinterdisciplinary.Question = this.personnelCostinterdisciplinary.Question.replace("Jahr", (year).toString());
        }

    }

}

export class QCCost {
    public subHeader: string = `${t("Bezogen auf das komplette Produktportfolio im Geschäftsjahr")} Jahr?`;
    public qualityCost: estimatefield = { Question: QCQuestions.QualityCost, Answer: 0, TooltipAnswer:"",AnswerType: 1, Tooltip:t("Summe sämtlicher Non Conformance Costs des Gesamtjahres (Jahresscheibe) Kosten aus den verschiedenen Bereichen aufsummiert (Qualität, Supply Chain, Verpackung, Produktion, Vertrieb, Audits usw.) NICHT aus laufenden Produktentwicklungs-Projekten") };
    public qualityCostByRD: estimatefield = { Question: QCQuestions.QualityCostByRD, Answer: 0, TooltipAnswer:"",AnswerType: 2, Tooltip: t("Anteil dessen, was nur durch F&E verursacht wurde, also durch schlechtes Design, fehlerhaftes Design, verursachte Doppel- und Nacharbeiten, verursachte Verzögerungen bei Dritten, die in Rechnung gestellt wurden.") };
    constructor(year: number) {
        if (year !== 0) {
            this.subHeader = this.subHeader.replace("Jahr", (year).toString());
            this.qualityCost.Question = this.qualityCost.Question.replace("Jahr", (year).toString());
        }

    }

}

export class MCCost {
    public subHeader: string = `${t("Bezogen auf Produkte, deren SOP in")} Jahr -1 ${t("war")}:`;
    public completedProjectLastYear: estimatefield = { Question: MCQuestions.CompletedProjectLastYear, Answer: 0, TooltipAnswer:"",AnswerType: 0, Tooltip:  t("Wenn in diesem Referenzjahr kein SOP stattgefunden hat, muss vereinbart werden, ob man dafür ein Vorgängerjahr verwendet oder den Durschnitt von 2 bis 3 Vorgängerjahren") };
    public manufactoringCostDeviation: estimatefield = {
        Question: MCQuestions.ManufactoringCostDeviation,
        Answer: 0,TooltipAnswer:"", AnswerType: 2, Tooltip: t("Hier betrachten wir vor allem die wenigen Projekte mit großer Auswirkung Geplante HK: Differenz der geplanten HK bei Projektfreigabe (Business Case) vs den tatsächlichen HK im eingeschwungenen Zustand (nach Ramp Up).")
    };
    // public ManufactoringCostByRDDeviation  : estimatefield = {Question:MCQuestions.ManufactoringCostByRDDeviation, Answer: 0, AnswerType:2};
    public avgQuantity: estimatefield = { Question: MCQuestions.AvgQuantity, Answer: 0,TooltipAnswer:"", AnswerType: 0, Tooltip:t("Zum Zeitpunkt Projektfreigabe (Buisness Case). Nach Ramp-Up = im eingeschwungenen Zustand.")};
    public avgPlannedManufactoringCost: estimatefield = {
        Question: MCQuestions.AvgPlannedManufactoringCost,
        TooltipAnswer:"",
        Answer: 0, AnswerType: 1,
        Tooltip: MCQuestions.Tooltip,
    };
    constructor(year: number) {
        if (year !== 0) {
            this.subHeader = this.subHeader.replace("Jahr -1", (year).toString());
            this.completedProjectLastYear.Question = this.completedProjectLastYear.Question.replace("Jahr -1", (year).toString());
        }
    }

}
export class estimatefield {
    public Question: string;
    public Tooltip: string;
    public Answer: number;
    public TooltipAnswer:string;
    public AnswerType: AnswertTYpes = 0;
    constructor(year: number) {
        if (year !== 0) {
            this.Question = this.Question.replace("Jahr -1", (year - 1).toString());
        }
        this.Tooltip = "";
        this.TooltipAnswer="";
    }
}
enum AnswertTYpes {
    count = 0,
    Money = 1,
    Rate = 2

}
export interface IHeaderWithTooltip{
    title:string;
    headerTooltip:string;
} 