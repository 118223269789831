import { Space, Spin, Typography } from 'antd';
import { connect } from 'react-redux';
import { ICommonResponse } from '../../../Models/Responses/ICommonResponse';
import { IIndustryBestPracticeItem } from '../../../Models/IIndustryBestPractice';
import IndustryBestPracticeForm from './IndustryBestPracticeForm';
import IndustryDisciplineBestPractice from '../IndustryDisciplineBestPractice/IndustryDisciplineBestPractice';
import { useSearchParams } from 'react-router-dom';
import './index.scss';
interface IProps {
  allIndustryBestPractice: IIndustryBestPracticeItem;
  isLoading: boolean,

  isModalLoading: boolean;
  modalResponse: ICommonResponse;
};
const IndustryBestPractice = (props: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const industryId = Number(searchParams.get('industryId'));

  return (
    <Spin spinning={props.isLoading}>
      <Typography.Title level={2}>{props?.allIndustryBestPractice?.industry ?? ''}</Typography.Title>
      <div className="ind-Bp admin-component">
        <IndustryBestPracticeForm industryId={industryId} />
        <IndustryDisciplineBestPractice industryId={industryId} />
      </div>
    </Spin>
  );
}
const mapStateToProps = (state: any) => {
  return {
    allIndustryBestPractice: state.industryBestPractice.industryBestPracticeResult,
    isLoading: state.industryBestPractice.isIndustryBestPracticeLoading,
    isModalLoading: state.industryBestPractice.isModalLoading,
    modalResponse: state.industryBestPractice.modalResponse,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IndustryBestPractice);
