import React, { useState } from 'react'
import { Button, Col, List, Modal, Tooltip, Typography } from 'antd';
import { t } from 'i18next';

interface IActivities {
    activityEn: string;
    activityDe: string;
}
interface IProps {
    activities: IActivities[];
}
const TaskActivitiesModal: React.FC<IProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = async () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <> <Tooltip title={`${t("show Activities")}`}>
            <Button onClick={showModal} type='primary'>
                {`${t("Activities")}`}
            </Button>
        </Tooltip>
            <Modal title={`${t("Activities Details")}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={800} getContainer={() => document.getElementById("app-layout")} footer={[]}>
                <List bordered>
                    <List.Item style={{ padding: "8px 0" }}><Col span={12} style={{ textAlign: "center" }}><Typography.Text strong>{`${t("English activity")}`}</Typography.Text></Col>
                        <Col span={12} style={{ textAlign: "center" }}><Typography.Text strong>{`${t("Germany activity")}`}</Typography.Text></Col></List.Item>
                    {props?.activities?.map((d, index) => <List.Item key={`activity_${index}`} style={{ padding: "8px 0" }}>
                        <Col span={12} style={{ borderRight: "1px solid #565656", textAlign: "center" }}><span>{d?.activityEn}</span></Col>
                        <Col span={12} style={{ textAlign: "center" }}><span >{d?.activityDe}</span></Col></List.Item>)}
                </List>
            </Modal>
        </>
    );
};

export default TaskActivitiesModal;