
import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import InterfaceToolService from "../../Services/InterfaceToolService";
import { InterfaceToolMetaData, InterfaceToolRoundView, InterfaceToolViewAnonymousDto, InterfaceToolViewDto, InterfaceToolViewRoundsDto, ReviewIAAnswer } from "../../Models/InterfaceAnalysis/IAItems";
import DepartmentByBmProjectService from "../../Services/DepartmentByBmProjectService";

function* refreshTheData(action: any) {
    let _resultGet: ICommonResponse = yield InterfaceToolService.getAll();
    if (!_resultGet.isError) {
        let resultGet = _resultGet.result as InterfaceToolViewDto;
        yield put({ type: ActionTypes.SaveIAViewResult, result: resultGet });
    } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _resultGet });
    }
}

function* refreshTheRoundsData(action: any) {
    let _resultGet: ICommonResponse = yield InterfaceToolService.getIARounds();
    if (!_resultGet.isError) {
        let resultGet = _resultGet.result as InterfaceToolViewRoundsDto;
        yield put({ type: ActionTypes.SaveIARoundsViewResult, result: resultGet });
    } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _resultGet });
    }
}

function* refreshTheMetaData(action: any) {
    let _resultGet: ICommonResponse = yield InterfaceToolService.getIAMetaData();
    if (!_resultGet.isError) {
        let resultGet = _resultGet.result as InterfaceToolMetaData;
        yield put({ type: ActionTypes.SaveIAMetaData, result: resultGet });
    } else {
        yield put({ type: ActionTypes.SetCommonResponse, result: _resultGet });
    }
}
function* onGetIAMetaData(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        yield refreshTheMetaData(action);
    } catch (error) {
        console.error("ERR in onGetIARoundsView: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
    yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
}

function* onGetIARoundsView(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoaderRounds, isLoading: true });
        yield refreshTheRoundsData(action);
    } catch (error) {
        console.error("ERR in onGetIARoundsView: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }

    yield put({ type: ActionTypes.ChangeIALoaderRounds, isLoading: false });
}

function* onGetInterfaceProgress(action: any) {
    try {
        let _result: ICommonResponse = yield InterfaceToolService.getInterfaceProgress();
        if (!_result.isError) {
            let result = _result.result as number;
            yield put({ type: ActionTypes.SetInterfaceProgressValue, result: result });
        }
    } catch (error) {
        console.error('onGetIntergaceProgress', error);
    }
}

function* onCreateInterfaceAnalysis(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.createInterface();
        if (!_result.isError) {
            yield refreshTheMetaData(action);
            // yield refreshTheData(action);
            // yield refreshTheRoundsData(action);            
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onGetIAView(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoaderMatrix, isLoading: true });
        yield refreshTheData(action);

    } catch (error) {
        console.error("ERR in onGetIAView: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
    yield put({ type: ActionTypes.ChangeIALoaderMatrix, isLoading: false });
}

function* onGetIAViewAnonymous(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.getAllAnonymous(action.token);
        if (!_result.isError) {
            let result = _result.result as InterfaceToolViewAnonymousDto;
            yield put({ type: ActionTypes.SaveIAViewAnonymousResult, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetIAView: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
// function* onInviteCandidateForIA(action: any) {
//     try {
//         yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
//         let _result: ICommonResponse = yield InterfaceToolService.invite(action.id);
//         if (!_result.isError) {
//             yield refreshTheData(action);
//         } else {
//             yield put({ type: ActionTypes.SetCommonResponse, result: _result });
//         }
//         yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
//     } catch (error) {
//         console.error("ERR in onInviteCandidateForIA: ", error);
//         yield put({
//             type: ActionTypes.SetCommonResponse,
//             result: {
//                 isError: true,
//                 statusCode: 600,
//                 error: { exceptionMessage: ToastMessages.UnExpectedError },
//             } as ICommonResponse,
//         });
//     }
// }
function* onExcludeFromIA(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.exclude(action.id);
        if (!_result.isError) {
            //yield refreshTheData(action);
            yield refreshTheRoundsData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onInviteCandidateForIA: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onChangeDepartmentStatusInInterface(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.changeDepartmentStatusInInterface(action.model);
        if (!_result.isError) {
            yield refreshTheMetaData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onInviteCandidateForIA: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onStartIARound(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.startAnalysisInterface(action.duration);
        if (!_result.isError) {
            yield refreshTheMetaData(action);
            yield refreshTheRoundsData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onInviteCandidateForIA: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onEndIARound(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.endAnalysisInterface();
        if (!_result.isError) {
            yield refreshTheMetaData(action);
            yield refreshTheRoundsData(action);
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onInviteCandidateForIA: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

// function* onRestartIARound(action: any) {
//     try {
//         yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
//         let _result: ICommonResponse = yield InterfaceToolService.restartAnalysisInterface(action.duration, action.excludePreviosRoundsInvitation);
//         if (!_result.isError) {
//             yield refreshTheData(action);
//         } else {
//             yield put({ type: ActionTypes.SetCommonResponse, result: _result });
//         }
//         yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
//     } catch (error) {
//         console.error("ERR in onInviteCandidateForIA: ", error);
//         yield put({
//             type: ActionTypes.SetCommonResponse,
//             result: {
//                 isError: true,
//                 statusCode: 600,
//                 error: { exceptionMessage: ToastMessages.UnExpectedError },
//             } as ICommonResponse,
//         });
//     }
// }
function* onCompleteIARound(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.completeAnalysisInterface(action.model);
        if (!_result.isError) {
            yield refreshTheMetaData(action);
            yield refreshTheRoundsData(action);
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onInviteCandidateForIA: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onCancelIARound(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.cancelAnalysisInterface(action.model);
        if (!_result.isError) {
            yield refreshTheMetaData(action);
            yield refreshTheRoundsData(action);
            yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onInviteCandidateForIA: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onSaveResponseForAnonymousAnalysisInterface(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.saveResponseForAnonymousAnalysisInterface(action.model);
        if (!_result.isError) {
            yield put({ type: ActionTypes.isSuccessAnonymousVoting, isSuccessAnonymousVoting: true });
            //yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onSaveResponseForAnonymousAnalysisInterface: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onReviewAnswer(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield InterfaceToolService.reviewAnswerAnalysisInterface(action.model);
        if (!_result.isError) {
            var roundResult = _result.result as ReviewIAAnswer;
            if (roundResult != null) {
                if (roundResult.round != null) {
                    yield put({ type: ActionTypes.RefreshIARound, result: roundResult.round });
                }
                if (roundResult.vote != null) {
                    yield put({ type: ActionTypes.RefreshIARoundVote, result: roundResult.vote });
                }
            }
            //yield refreshTheRoundsData(action);
            //yield refreshTheData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error("ERR in onReviewAnswer: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }

    yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
}

function* onCreateDepartment(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let _result: ICommonResponse = yield DepartmentByBmProjectService.CreateDepartmentByBmProject(
            action.model
        );
        if (!_result.isError) {
            yield refreshTheMetaData(action);
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCreateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onUpdateDepartment(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield DepartmentByBmProjectService.UpdateDepartmentByBmProject(
            action.model
        );
        if (!_result.isError) {
            yield refreshTheMetaData(action);
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onUpdateOrderDepartments(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield DepartmentByBmProjectService.UpdateOrderDepartmentByBmProject(
            action.model
        );
        if (!_result.isError) {
            yield refreshTheMetaData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* onDeleteDepartment(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield DepartmentByBmProjectService.DeleteDepartmentByBmProject(
            action.departmentId
        );
        if (!_result.isError) {
            yield refreshTheMetaData(action);
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
    } catch (error) {
        console.error("ERR in onUpdateQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* OnExportToExcel(action: any) {
    try {
        yield DepartmentByBmProjectService.ExportToExcel(action.model);
    } catch (error) {
        console.error("ERR in onGet: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onImportDepartment(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeIALoader, isLoading: true });
        let _result: ICommonResponse = yield DepartmentByBmProjectService.ImportDepartmentTable(action.frmData);
        if (!_result.isError) {
            yield refreshTheMetaData(action);
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
    } catch (error) {
        console.error('ERR in onImportDepartment: ', error);
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
    }

    yield put({ type: ActionTypes.ChangeIALoader, isLoading: false });
}
function* onGoToSetDisipline(action: any) {
    yield put({ type: ActionTypes.SetDisipline, result: action.model });
}
function* watchCreateInterfaceAnalysis() {
    yield takeEvery(ActionTypes.CreateInterfaceAnalysis, onCreateInterfaceAnalysis);
}
function* watchGetIAView() {
    yield takeEvery(ActionTypes.GetIAView, onGetIAView);
}
function* watchGetIAMetaData() {
    yield takeEvery(ActionTypes.GetIAMetaData, onGetIAMetaData);
}
function* watchGetIAViewAnonymous() {
    yield takeEvery(ActionTypes.GetIAViewAnonymous, onGetIAViewAnonymous);
}
function* watchGoToSetDisipline() {
    yield takeEvery(ActionTypes.GoToSetDisipline, onGoToSetDisipline);
}

// function* watchInviteCandidateForIA() {
//     yield takeEvery(ActionTypes.InviteCandidateForIA, onInviteCandidateForIA);
// }
function* watchExcludeFromIA() {
    yield takeEvery(ActionTypes.ExcludeFromIA, onExcludeFromIA);
}
function* watchStartIARound() {
    yield takeEvery(ActionTypes.StartIARound, onStartIARound);
}
function* watchChangeDepartmentStatusInInterface() {
    yield takeEvery(ActionTypes.ChangeDepartmentStatusInInterface, onChangeDepartmentStatusInInterface);
}
function* watchEndIARound() {
    yield takeEvery(ActionTypes.EndIARound, onEndIARound);
}

// function* watchRestartIARound() {
//     yield takeEvery(ActionTypes.RestartIARound, onRestartIARound);
// }

function* watchCompleteIARound() {
    yield takeEvery(ActionTypes.CompleteIARound, onCompleteIARound);
}

function* watchCancelIARound() {
    yield takeEvery(ActionTypes.CancelIARound, onCancelIARound);
}

function* watchSaveResponseForAnonymousAnalysisInterface() {
    yield takeEvery(ActionTypes.SaveResponseForAnonymousAnalysisInterface, onSaveResponseForAnonymousAnalysisInterface);
}

function* watchReviewAnswer() {
    yield takeEvery(ActionTypes.ReviewAnswerIA, onReviewAnswer);
}

function* watchGetInterfaceProgress() {
    yield takeEvery(ActionTypes.GetInterfaceProgressValue, onGetInterfaceProgress);
}

function* watchCreateDepartment() {
    yield takeEvery(ActionTypes.CREATE_DEPARTMENT_BY_BM_PROJECT, onCreateDepartment);
}
function* watchUpdateOrderDepartments() {
    yield takeEvery(ActionTypes.UPDATE_Order_DEPARTMENT_BY_BM_PROJECT, onUpdateOrderDepartments);
}
function* watchUpdateDepartment() {
    yield takeEvery(ActionTypes.UPDATE_DEPARTMENT_BY_BM_PROJECT, onUpdateDepartment);
}
function* watchDeleteDepartment() {
    yield takeEvery(ActionTypes.DELETE_DEPARTMENT_BY_BM_PROJECT, onDeleteDepartment);
}
function* watchIAExportToExcel() {
    yield takeEvery(ActionTypes.ExportIAToExcel, OnExportToExcel);
}

function* watchImportDepartment() {
    yield takeEvery(ActionTypes.ImportDepartment, onImportDepartment);
}



function* watchGetIARoundsView() {
    yield takeEvery(ActionTypes.GetIARoundsView, onGetIARoundsView);
}
export default function* InterfaceAnalysisSaga() {
    yield all([
        watchGetIAView(),
        watchGoToSetDisipline(),
        watchGetIAViewAnonymous(),
        watchCreateInterfaceAnalysis(),
        //watchInviteCandidateForIA(),
        watchExcludeFromIA(),
        watchStartIARound(),
        watchChangeDepartmentStatusInInterface(),
        watchEndIARound(),
        //watchRestartIARound(),
        watchCompleteIARound(),
        watchCancelIARound(),
        watchSaveResponseForAnonymousAnalysisInterface(),
        watchReviewAnswer(),
        watchGetInterfaceProgress(),
        watchCreateDepartment(),
        watchUpdateDepartment(),
        watchDeleteDepartment(),
        watchUpdateOrderDepartments(),
        watchIAExportToExcel(),
        watchImportDepartment(),
        watchGetIARoundsView(),
        watchGetIAMetaData()
    ]);
}