import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { IDepartmentByCustomerItem } from '../../../Models/IDepartment';
import { connect } from 'react-redux';
import { Collapse, Empty, Typography } from 'antd';
import DepartmentBmProject from './DeparmentBmProject/DepartmentBmProject';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import './index.scss';
import { t } from 'i18next';

interface IProps {
    allDepartments: IDepartmentByCustomerItem[];
    isLoading: boolean;
    getAllDepartments: (id: number) => any;
    isFromBmProject: boolean;
}
const DepartmentByCustomer = (props: IProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const customerId = Number(searchParams.get('customerId'));
    const customerName = searchParams.get('customerName');
    useEffect(() => { props.getAllDepartments(customerId); }, [customerId]);
    const { Panel } = Collapse;
    return <>
        <Typography.Title level={4} style={{ color: "#1644b0" }}>{customerName}</Typography.Title>
        {(props.allDepartments != null && props.allDepartments.length > 0) ? (
            <Collapse defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                isActive ? (
                    <MinusCircleTwoTone />
                ) : (
                    <PlusCircleTwoTone />
                )} className='department-collapse'>
                {
                    props.allDepartments?.map((d, index) => {
                        return (
                            <Panel header={d.bmPrject.name} key={`${index + 1}`}>
                                <DepartmentBmProject isFromBmProject={props.isFromBmProject} departments={d.departements} bmProjectId={d.bmPrject?.id} customerId={customerId} />
                            </Panel>)
                    })
                }
            </Collapse>) : <Empty description={`${t("Add new project to continue")}`} />}
    </>

}
const mapStateToProps = (state: any) => {
    return {
        allDepartments: state.departmentsByCustomer?.departmentsByCustomerResult,
        isLoading: state.departmentsByCustomers?.isDepartmentByCustomerLoading,

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllDepartments: (id: number) =>
            dispatch({ type: ActionTypes.GET_DEPARTMENTS_BY_CUSTOMER, customerId: id }),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentByCustomer);