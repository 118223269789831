import React, { useState } from 'react';
import { Alert, Button, Modal } from 'antd';
import { ICurvePhaseItem } from '../../../../Models/ICurves';
import CurvesPhase from './CurvesPhase/CurvesPhase';
import { t } from 'i18next';

interface IProps {
  phasesData: ICurvePhaseItem[];
  curveId: number;
}
const CurvesPhaseModal = (props: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  console.log(props.phasesData);
  return (
    <>
      <Button type="primary" onClick={showModal} shape="default">
      {`${t("Phases")}`}
      </Button>
      <Modal title={`${t("Curve")} Phase`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className='admin-model' getContainer={() => document.getElementById("app-layout")} width={1000}  footer={[]}>
        <Alert message={`${t('All phases percentages should add up to 100.')}`} type="info" showIcon style={{ marginBottom: ".5rem" }} />
        <CurvesPhase phasesData={props.phasesData} curveId={props.curveId} />
      </Modal>
    </>
  );
};
export default CurvesPhaseModal