import { ToastMessages } from "../../Enums/Messages";
import { takeEvery, put, all } from "redux-saga/effects";
import { ActionTypes } from "../actionsTypes";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { IBMProjectALL, ICompleteBmProjectDetails } from "../../Models/IBMProjectItem";
import BmProjectService from "../../Services/BmProjectsService";

function* onGetCompletionDetailsBmProject(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_COMPLETION_DETAILS_LOADER, isLoading: true });
        let resultGet: ICommonResponse = yield BmProjectService.GetCompletionDetailsForBmProject(action.bmProjectId)
        if (!resultGet.isError) {
            let result = resultGet.result as ICompleteBmProjectDetails[];
            yield put({ type: ActionTypes.SAVE_COMPLETION_DETAILS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultGet });
        }
        yield put({ type: ActionTypes.CHANGE_COMPLETION_DETAILS_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onCompletionBmProject(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultComplete: ICommonResponse = yield BmProjectService.CompleteBmProject(action.model)
        if (!resultComplete.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultComplete });
            let _result: ICommonResponse = yield BmProjectService.GetBmProjects(action.customerId??null);
            let result = _result.result as IBMProjectALL[];
            yield put({ type: ActionTypes.SAVE_BM_PROJECTS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: resultComplete });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultComplete });
        }
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onCompleteQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}
function* onReopenBmProject(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: true });
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let resultReopen: ICommonResponse = yield BmProjectService.ReopenBmProject(action.bmProjectId)
        if (!resultReopen.isError) {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultReopen });
            let _result: ICommonResponse = yield BmProjectService.GetBmProjects(action.customerId??null);
            let result = _result.result as IBMProjectALL[];
            yield put({ type: ActionTypes.SAVE_BM_PROJECTS, result: result });
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultReopen });
        }
        yield put({ type: ActionTypes.CHANGE_BM_PROJECT_LOADER, isLoading: false });
        yield put({ type: ActionTypes.SetModalLoader, result: false });
    } catch (error) {
        console.error("ERR in onGetQk: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}



function* watchGetCompletionDetailsBmProject() {
    yield takeEvery(ActionTypes.GET_COMPLETION_DETAILS_BM_PROJECT, onGetCompletionDetailsBmProject)
}
function* watchCompletionBmProject() {
    yield takeEvery(ActionTypes.COMPLETE_BM_PROJECT, onCompletionBmProject)
}
function* watchReopenBmProject() {
    yield takeEvery(ActionTypes.REOPEN_BM_PROJECT, onReopenBmProject)
}
export default function* BmProjectCompletionSagas() {
    yield all([
        watchGetCompletionDetailsBmProject(),
        watchCompletionBmProject(),
        watchReopenBmProject()
    ]);
}
