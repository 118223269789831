import React, { ReactNode } from "react";
import { List } from "antd";
import StackedColumnChart from "../Charts/StackedColumnChart";
import { IChartData } from "../../../../Models/Activity/ActivityModels";
import { connect } from "react-redux";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { ActivityProfileAnalysisView } from "../Survey/Models/ActivitySurveyData";
import { ActivityGroup } from "../Survey/Models/ActivityGroup";

interface IProps {
  profile: ActivityProfileAnalysisView;
}
const ActivityProfileResult = (props: IProps) => {
  const getActivityProfileBestPracticeData = () => {
    let activityProfileBestPractces: IChartData[] = props.profile?.profileData
      ?.sort((a, b) => b.groupSerialNumber - a.groupSerialNumber)
      .map((gg) => {
        return {
          id: gg?.groupId,
          code: `${gg.name}-bp`,
          value: gg.bestPractice,
          name: "Best Practice",
        };
      });
    return activityProfileBestPractces; //.sort((a, b)=> a.value - b.value);;
  };

  const getActivityGroupsByProfileData = () => {
    let activityGroupsData: IChartData[] = props.profile?.profileData
      ?.sort((a, b) => b.groupSerialNumber - a.groupSerialNumber)
      .map((activityGroup) => {
        let activityGroupTemp = props.profile.finalResult?.groupsResult?.filter(
          (c) => c.groupId === activityGroup.groupId
        )?.[0];

        return {
          id: activityGroup.groupId,
          code: `${activityGroup.name}`,
          value: activityGroupTemp?.percentage,
          name: props.profile?.activityProfile.name,
        };
      });
    return activityGroupsData; //.sort((a, b)=> a.value - b.value);
  };

  const getTasksByActivityGroupData = (activityGroup: ActivityGroup) => {
    let tasksData: IChartData[] =
      activityGroup?.children        
        .map((task) => {
          let taskTemp = props.profile.finalResult.tasksResult.filter(
            (c) => c.taskId === task.taskId
          )?.[0];

          return {
            id: task.taskId,
            code: task.name,
            value: taskTemp?.percentage,
            name: activityGroup.name,
          };
        }) ?? [];
    return tasksData.sort((a, b)=> a.value - b.value);;
  };

  const getActivityGroupBestPracticeData = (activityGroup: ActivityGroup) => {
    let activityProfileBestPractces: IChartData = {
      id: activityGroup?.groupId,
      code: `${activityGroup?.name}-bp`,
      value: activityGroup?.bestPractice,
      name: "Best Practice",
    };
    return activityProfileBestPractces;
  };

  const activityProfileChartHeight = 30;
  //  const activityGroupChartHeight = 46;
  // const cols = [];

  // let MaxSubColCount = 0;
  const displayGrouspChart = () => {
    let charts: ReactNode[] = [];

    var listProfile = getActivityGroupsByProfileData();
    var sumOfValuesInList2 = listProfile.reduce((c, v) => c + v.value, 0);
    var maxOfBp2 = Math.max(100, sumOfValuesInList2);

    charts.push(
      <List.Item>
        <div className="div-over-stackedcolumnchart">
          <StackedColumnChart
            isTasks={false}
            maxLimit={maxOfBp2}
            height={activityProfileChartHeight}
            bestPracticesData={getActivityProfileBestPracticeData()}
            chartTitle={props.profile?.activityProfile?.name}
            data={listProfile}
            key={`profile_chart_${props.profile?.activityProfile?.id}`}
            baseColor="#154874"
          />
        </div>
      </List.Item>
    );
    props.profile?.profileData?.sort((a, b)=> a.groupSerialNumber - b.groupSerialNumber).map((item) => {
      var groupBp = getActivityGroupBestPracticeData(item);
      var list = getTasksByActivityGroupData(item);
      var sumOfValuesInList = list.reduce((c, v) => c + v.value, 0);
      var maxOfBp = Math.max(sumOfValuesInList, groupBp.value);
      charts.push(
        <List.Item>
          <div className="div-over-stackedcolumnchart">
            <StackedColumnChart
              isTasks={true}
              maxLimit={maxOfBp}
              height={activityProfileChartHeight}
              bestPracticesData={[groupBp]}
              chartTitle={item.name}
              data={list}
              key={`group_chart_${item.groupId}`}
              baseColor="#154874"
            />
          </div>
        </List.Item>
      );
    });

    return charts;
  };

  return (
    <>
      <List
        grid={{ gutter: 16, column: 2 }}
        dataSource={displayGrouspChart()}
        renderItem={(item) => item}
      ></List>
    </>
  );
};

const mapStateToProps = (state: IGeneralState) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityProfileResult);
