import { Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { IIndustrySegmentBestPracticeItem} from '../../../Models/IIndustrySegmentBestPractice';
import { ActionTypes } from '../../../Redux/actionsTypes';
import SegmentDisciplineBP from '../SegmentDisciplineBP/SegmentDisciplineBP';
import IndustrySegmentBestPracticeForm from './IndustrySegmentBestPracticeForm';
import './index.scss';
import { IGeneralState } from '../../../Redux/reducers/IGeneralState';
interface IProps {
    allIndustrySegmentBestPractice: IIndustrySegmentBestPracticeItem,
    isLoading: boolean,
    getALLIndustrySegmentBestPractice: (id: number) => any;
}
const IndustrySegmentBestPractice = (props: IProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const industrySegmentId = Number(searchParams.get('segmentId'));

    useEffect(() => { props.getALLIndustrySegmentBestPractice(industrySegmentId); }, [])

    return (
        <Spin spinning={props.isLoading}>
            <Typography.Title level={2}>{ props.allIndustrySegmentBestPractice?.industrySegment?? ''}</Typography.Title>
            <div className="indSeg-Bp admin-component">
                <IndustrySegmentBestPracticeForm industrySegmentId={industrySegmentId} />
                <SegmentDisciplineBP industrySegmentId={industrySegmentId} />
            </div>
        </Spin>
    );
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        allIndustrySegmentBestPractice: state.industrySegmentBestPractice?.industrySegmentBestPracticeResult,
        isLoading: state.industrySegmentBestPractice.isIndustrySegmentBestPracticeLoading,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getALLIndustrySegmentBestPractice: (id: number) => dispatch({ type: ActionTypes.GET_INDUSTRY_SEGMENT_BEST_PRACTICES, industrySegmentId: id }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(IndustrySegmentBestPractice);
