import { Layout, notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import { createBrowserHistory } from "history";
import { Outlet, useNavigate } from "react-router-dom";

export default function LoginLayout() {
  const history = createBrowserHistory();
    return (
    <Layout className="login-layout" id="login-layout">
      <Layout>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>);
}