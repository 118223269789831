import { SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, InputNumber, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { IIndustryBestPracticeItem, IIndustryBestPracticeUpdate } from '../../../Models/IIndustryBestPractice';
import { inputFormatter, inputParser, openNotificationWithIcon } from "../../../Utils/helperFunctions";
import i18n from "../../../i18n";
import { IGeneralState } from "../../../Redux/reducers/IGeneralState";
import { PopconfirmClearAll } from "../../../Components/PopconfirmAsync";
import { t } from "i18next";
import { IValidationMessage, validationMessage } from "../IndustrySegmentBestPractice/ValidationBestPractice";

interface IProps {
    industryId: number;
    industryBestPracticeData: IIndustryBestPracticeItem;
    updateIndustryBestPractice: (model: IIndustryBestPracticeUpdate, id: number) => any;
    clearIndustryBestPractice: (industryId: number) => any;
    getALLIndustryBestPractice: (id: number) => any;
}
const IndustryBestPracticeForm = (props: IProps) => {
    const [errorsMessages, setErrorsMessages] = useState<IValidationMessage>();
    const [disabledBtnSubmit, setDisabledBtnSubmit] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => { props.getALLIndustryBestPractice(props.industryId); }, []);
    const [fieldsValue, setFieldsValue] = useState(null);
    useEffect(() => {
        console.log("props.industryBestPracticeData", props.industryBestPracticeData);
        setFieldsValue(props.industryBestPracticeData);
        form.setFieldsValue({ ...props.industryBestPracticeData })
    }, [props.industryId, props.industryBestPracticeData])

    // 
    // let innoBpMin = Form.useWatch('innovationBestPracticeMin', form);
    // let innoBp = Form.useWatch('innovationBestPractice', form);
    // let innoBpMax = Form.useWatch('innovationBestPracticeMax', form);
    // let pfeBpMin = Form.useWatch('portfolioExtensionsBestPracticeMin', form);
    // let pfeBp = Form.useWatch('portfolioExtensionsBestPractice', form);
    // let pfeBpMax = Form.useWatch('portfolioExtensionsBestPracticeMax', form);
    // let subsBpMin = Form.useWatch('substitutionsBestPracticeMin', form);
    // let subsBp = Form.useWatch('substitutionsBestPractice', form);
    // let subsBpMax = Form.useWatch('substitutionsBestPracticeMax', form);
    // let mfcBpMin = Form.useWatch('manufacturingCostBestPracticeMin', form);
    // let mfcBp = Form.useWatch('manufacturingCostBestPractice', form);
    // let mfcBpMax = Form.useWatch('manufacturingCostBestPracticeMax', form);
    // let qcBpMin = Form.useWatch('qualityCostBestPracticeMin', form);
    // let qcBp = Form.useWatch('qualityCostBestPractice', form);
    // let qcBpMax = Form.useWatch('qualityCostBestPracticeMax', form);
    // let codBpMin = Form.useWatch('costOfDelayBestPracticeMin', form);
    // let codBp = Form.useWatch('costOfDelayBestPractice', form);
    // let codBpMax = Form.useWatch('costOfDelayBestPracticeMax', form);
    // let rndBpMin = Form.useWatch('rndCostBestPracticeMin', form);
    // let rndBp = Form.useWatch('rndCostBestPractice', form);
    // let rndBpMax = Form.useWatch('rndCostBestPracticeMax', form);
    // let indBpMin = Form.useWatch('interdisciplineBestPracticeMin', form);
    // let indBp = Form.useWatch('interdisciplineBestPractice', form);
    // let indBpMax = Form.useWatch('interdisciplineBestPracticeMax', form);
    // let originalBpMin = Form.useWatch('originalBestPracticeMin', form);
    // let originalBp = Form.useWatch('originalBestPractice', form);
    // let originalBpMax = Form.useWatch('originalBestPracticeMax', form);
    //
    const alertErrorMessage = (message: string) => <Alert message={message} type="error" banner key={`err_${message}`} />;
    const FormItemBp = (props: { label: string, name: string, key: string, /* onChange: (value: number) => any;  */ }) => {
        return <Form.Item label={props.label} name={props.name} key={props.key} rules={[{ required: true, message: `` }]}  >
            <InputNumber
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
                addonAfter="%"
                min={0} max={100}
            //onChange={(value: number) => props.onChange(value)}
            />
        </Form.Item>
    }
    const onFinish = async (values: any) => {
        try {
            await form.validateFields()
            let isError = false;
            let innoBpMin = form.getFieldValue('innovationBestPracticeMin');
            let innoBp = form.getFieldValue('innovationBestPractice');
            let innoBpMax = form.getFieldValue('innovationBestPracticeMax');
            if (innoBpMin > innoBp && innoBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.innoMinMessage);
                return;
            }
            if (innoBpMax < innoBp && innoBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.innoMaxMessage);
                return;
            }
            let pfeBpMin = form.getFieldValue('portfolioExtensionsBestPracticeMin');
            let pfeBp = form.getFieldValue('portfolioExtensionsBestPractice');
            let pfeBpMax = form.getFieldValue('portfolioExtensionsBestPracticeMax');
            if (pfeBpMin > pfeBp && pfeBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.pfeMinMessage);
                return;
            }
            if (pfeBpMax < pfeBp && pfeBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.pefMaxMessage);
                return;
            }
            let subsBpMin = form.getFieldValue('substitutionsBestPracticeMin');
            let subsBp = form.getFieldValue('substitutionsBestPractice');
            let subsBpMax = form.getFieldValue('substitutionsBestPracticeMax');
            if (subsBpMin > subsBp && subsBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.subsMinMessage);
                return;
            }
            if (subsBpMax < subsBp && subsBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.subsMaxMessage);
                return;
            }
            let mfcBpMin = form.getFieldValue('manufacturingCostBestPracticeMin');
            let mfcBp = form.getFieldValue('manufacturingCostBestPractice');
            let mfcBpMax = form.getFieldValue('manufacturingCostBestPracticeMax');
            if (mfcBpMin > mfcBp && mfcBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.mfcMinMessage);
                return;
            }
            if (mfcBpMax < mfcBp && mfcBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.mfcMaxMessage);
                return;
            }
            let qcBpMin = form.getFieldValue('qualityCostBestPracticeMin');
            let qcBp = form.getFieldValue('qualityCostBestPractice');
            let qcBpMax = form.getFieldValue('qualityCostBestPracticeMax');
            if (qcBpMin > qcBp && qcBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.qctMinMessage);
                return;
            }
            if (qcBpMax < qcBp && qcBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.qctMaxMessage);
                return;
            }
            let codBpMin = form.getFieldValue('costOfDelayBestPracticeMin');
            let codBp = form.getFieldValue('costOfDelayBestPractice');
            let codBpMax = form.getFieldValue('costOfDelayBestPracticeMax');
            if (codBpMin > codBp && codBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.codMinMessage);
                return;
            }
            if (codBpMax < codBp && codBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.codMaxMessage);
                return;
            }
            let rndBpMin = form.getFieldValue('rndCostBestPracticeMin');
            let rndBp = form.getFieldValue('rndCostBestPractice');
            let rndBpMax = form.getFieldValue('rndCostBestPracticeMax');
            if (rndBpMin > rndBp && rndBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.rndCMinMessage);
                return;
            }
            if (rndBpMax < rndBp && rndBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.rndCMaxMessage);
                return;
            }
            let indBpMin = form.getFieldValue('interdisciplineBestPracticeMin');
            let indBp = form.getFieldValue('interdisciplineBestPractice');
            let indBpMax = form.getFieldValue('interdisciplineBestPracticeMax');
            if (indBpMin > indBp && indBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.interdisMinMessage);
                return;
            }
            if (indBpMax < indBp && indBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.interdisMaxMessage);
                return;
            }
      
            let originalBpMin = form.getFieldValue('originalBestPracticeMin');
            let originalBp = form.getFieldValue('originalBestPractice');
            let originalBpMax = form.getFieldValue('originalBestPracticeMax');
            if (originalBpMin > originalBp && originalBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.originalMinMessage);
                return;
            }
            if (originalBpMax < originalBp && originalBp !== null) {
                openNotificationWithIcon("warning", t("Warning"), validationMessage.originalMaxMessage);
                return;
            }
            const industryBestPracticeUpdate: IIndustryBestPracticeUpdate = {
                id: props.industryBestPracticeData?.id,
                industryId: props.industryId,
                ...values
            }
            await props.updateIndustryBestPractice(industryBestPracticeUpdate, props.industryId);


        } catch (error) {
            console.log(error);

        }

    };
    const onClearAll = async (industryId: number) => {
        try {
            await props.clearIndustryBestPractice(industryId);
            form.setFieldsValue({});
            form.resetFields()
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Form form={form} key={`ind_best_practice_form_${props.industryId}`} onFinish={onFinish} layout="vertical" className='ind_bp-form'>
            <Row gutter={32} style={{ marginBottom: "5px" }}>
                {/* inno */}
                <Col span={8} style={{ borderRight: ".5px solid #ccc" }} >
                    <Row gutter={4} justify='center'>
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>Innovation</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'inno_bp-min'}
                                label={'MIN'}
                                name={'innovationBestPracticeMin'}
                            /*  onChange={(value: number) => {
                                 if (value > innoBp && innoBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, innoMinMessage: validationMessage.innoMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, innoMinMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'inno_bp'}
                                label={'BP'}
                                name={'innovationBestPractice'}
                            /*  onChange={(value: number) => {
                                 if (value < innoBpMin && innoBpMin !== null) {
                                     setErrorsMessages({ ...errorsMessages, innoMinMessage: validationMessage.innoMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else if (value > innoBpMax && innoBpMax !== null) {
                                     setErrorsMessages({ ...errorsMessages, innoMaxMessage: validationMessage.innoMaxMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, innoMaxMessage: '', innoMinMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                        <Col span={8} >
                            <FormItemBp
                                key={'inno_bp-max'}
                                label={'MAX'}
                                name={'innovationBestPracticeMax'}
                            /* onChange={(value: number) => {
                                if (value < innoBp && innoBp !== null) {
                                    setErrorsMessages({ ...errorsMessages, innoMaxMessage: validationMessage.innoMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, innoMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }} */
                            />
                        </Col>
                        {errorsMessages?.innoMinMessage ? alertErrorMessage(errorsMessages?.innoMinMessage) : (null)}
                        {errorsMessages?.innoMaxMessage ? alertErrorMessage(errorsMessages?.innoMaxMessage) : (null)}
                    </Row>
                </Col>
                {/* PortfolioExtensions */}
                <Col span={8} style={{ borderRight: "1px solid #ccc" }}>
                    <Row gutter={4} justify='center' >
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>PortfolioExtensions</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'pfe_bp-min'}
                                label={'MIN'}
                                name={'portfolioExtensionsBestPracticeMin'}
                            /* onChange={(value: number) => {
                                if (value > pfeBp && pfeBp !== null) {
                                    setErrorsMessages({ ...errorsMessages, pfeMinMessage: validationMessage.pfeMinMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, pfeMinMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }} */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'pfe_bp'}
                                label={'BP'}
                                name={'portfolioExtensionsBestPractice'}
                            /* onChange={(value: number) => {
                                if (value < pfeBpMin && pfeBpMin !== null) {
                                    setErrorsMessages({ ...errorsMessages, pfeMinMessage: validationMessage.pfeMinMessage })
                                    setDisabledBtnSubmit(true)
                                } else if (value > pfeBpMax && pfeBpMax !== null) {
                                    setErrorsMessages({ ...errorsMessages, pefMaxMessage: validationMessage.pefMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, pfeMinMessage: '', pefMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }
                            } */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'pfe_bp_max'}
                                label={'MAX'}
                                name={'portfolioExtensionsBestPracticeMax'}
                            /*  onChange={(value: number) => {
                                 if (value < pfeBp && pfeBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, pefMaxMessage: validationMessage.pefMaxMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, pefMaxMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                    </Row>
                    {errorsMessages?.pfeMinMessage ? alertErrorMessage(errorsMessages?.pfeMinMessage) : (null)}
                    {errorsMessages?.pefMaxMessage ? alertErrorMessage(errorsMessages?.pefMaxMessage) : (null)}
                </Col>
                {/* Substitutions */}
                <Col span={8}>
                    <Row gutter={4} justify='center' >
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>Substitutions</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'subs_bp-min'}
                                label={'MIN'}
                                name={'substitutionsBestPracticeMin'}
                            /*   onChange={(value: number) => {
                                  if (value > subsBp && subsBp !== null) {
                                      setErrorsMessages({ ...errorsMessages, subsMinMessage: validationMessage.subsMinMessage })
                                      setDisabledBtnSubmit(true)
                                  } else {
                                      setErrorsMessages({ ...errorsMessages, subsMinMessage: '' })
                                      setDisabledBtnSubmit(false)
                                  }
                              }} */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'subs_bp'}
                                label={'BP'}
                                name={'substitutionsBestPractice'}
                            /* onChange={(value: number) => {
                                if (value < subsBpMin && subsBpMin !== null) {
                                    setErrorsMessages({ ...errorsMessages, subsMinMessage: validationMessage.subsMinMessage })
                                    setDisabledBtnSubmit(true)
                                } else if (value > subsBpMax && subsBpMax !== null) {
                                    setErrorsMessages({ ...errorsMessages, subsMaxMessage: validationMessage.subsMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, subsMinMessage: '', subsMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }
                            } */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'subs_bp_max'}
                                label={'MAX'}
                                name={'substitutionsBestPracticeMax'}
                            /*  onChange={(value: number) => {
                                 if (value < subsBp && subsBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, subsMaxMessage: validationMessage.subsMaxMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, subsMaxMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                    </Row>
                    {errorsMessages?.subsMinMessage ? alertErrorMessage(errorsMessages?.subsMinMessage) : (null)}
                    {errorsMessages?.subsMaxMessage ? alertErrorMessage(errorsMessages?.subsMaxMessage) : (null)}
                </Col>
            </Row>
            <Row gutter={32} style={{ marginBottom: "5px" }}>
                {/* manufacturingCost */}
                <Col span={8} style={{ borderRight: "1px solid #ccc" }}>
                    <Row gutter={4} justify='center' >
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>ManufacturingCost</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'mfc_bp-min'}
                                label={'MIN'}
                                name={'manufacturingCostBestPracticeMin'}
                            /*  onChange={(value: number) => {
                                 if (value > mfcBp && mfcBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, mfcMinMessage: validationMessage.mfcMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, mfcMinMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'mfc_bp'}
                                label={'BP'}
                                name={'manufacturingCostBestPractice'}
                            /*  onChange={(value: number) => {
                                 if (value < mfcBpMin && mfcBpMin !== null) {
                                     setErrorsMessages({ ...errorsMessages, mfcMinMessage: validationMessage.mfcMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else if (value > mfcBpMax && mfcBpMax !== null) {
                                     setErrorsMessages({ ...errorsMessages, mfcMaxMessage: validationMessage.mfcMaxMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, mfcMinMessage: '', mfcMaxMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }
                             } */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'mfc_bp-max'}
                                label={'MAX'}
                                name={'manufacturingCostBestPracticeMax'}
                            /* onChange={(value: number) => {
                                if (value < mfcBp && mfcBp !== null) {
                                    setErrorsMessages({ ...errorsMessages, mfcMaxMessage: validationMessage.mfcMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, mfcMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }} */
                            />
                        </Col>
                    </Row>
                    {errorsMessages?.mfcMinMessage ? alertErrorMessage(errorsMessages?.mfcMinMessage) : (null)}
                    {errorsMessages?.mfcMaxMessage ? alertErrorMessage(errorsMessages?.mfcMaxMessage) : (null)}
                </Col>
                {/* qualityCost */}
                <Col span={8} style={{ borderRight: "1px solid #ccc" }}>
                    <Row gutter={4} justify='center' >
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>QualityCost</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'qc_bp-min'}
                                label={'MIN'}
                                name={'qualityCostBestPracticeMin'}
                            /* onChange={(value: number) => {
                                if (value > qcBp && qcBp !== null) {
                                    setErrorsMessages({ ...errorsMessages, qctMinMessage: validationMessage.qctMinMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, qctMinMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }} */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'qc_bp'}
                                label={'BP'}
                                name={'qualityCostBestPractice'}
                            /* onChange={(value: number) => {
                                if (value < qcBpMin && qcBpMin !== null) {
                                    setErrorsMessages({ ...errorsMessages, qctMinMessage: validationMessage.qctMinMessage })
                                    setDisabledBtnSubmit(true)
                                } else if (value > qcBpMax && qcBpMax !== null) {
                                    setErrorsMessages({ ...errorsMessages, qctMaxMessage: validationMessage.qctMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, qctMinMessage: '', qctMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }
                            } */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'qc_bp-max'}
                                label={'MAX'}
                                name={'qualityCostBestPracticeMax'}
                            /*  onChange={(value: number) => {
                                 if (value < qcBp && qcBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, qctMaxMessage: validationMessage.qctMaxMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, qctMaxMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                    </Row>
                    {errorsMessages?.qctMinMessage ? alertErrorMessage(errorsMessages?.qctMinMessage) : (null)}
                    {errorsMessages?.qctMaxMessage ? alertErrorMessage(errorsMessages?.qctMaxMessage) : (null)}
                </Col>
                {/* costOfDelay*/}
                <Col span={8}>
                    <Row gutter={4} justify='center' >
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>CostOfDelay</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'cod_bp-min'}
                                label={'MIN'}
                                name={'costOfDelayBestPracticeMin'}
                            /*  onChange={(value: number) => {
                                 if (value > codBp && codBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, codMinMessage: validationMessage.codMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, codMinMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'cod_bp'}
                                label={'BP'}
                                name={'costOfDelayBestPractice'}
                            /*  onChange={(value: number) => {
                                 if (value < codBpMin && codBpMin !== null) {
                                     setErrorsMessages({ ...errorsMessages, codMinMessage: validationMessage.codMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else if (value > codBpMax && codBpMax !== null) {
                                     setErrorsMessages({ ...errorsMessages, codMaxMessage: validationMessage.codMaxMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, codMinMessage: '', codMaxMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }
                             } */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'cod_bp-max'}
                                label={'MAX'}
                                name={'costOfDelayBestPracticeMax'}
                            /* onChange={(value: number) => {
                                if (value < codBp && codBp !== null) {
                                    setErrorsMessages({ ...errorsMessages, codMaxMessage: validationMessage.codMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, codMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }} */
                            />

                        </Col>
                    </Row>
                    {errorsMessages?.codMinMessage ? alertErrorMessage(errorsMessages?.codMinMessage) : (null)}
                    {errorsMessages?.codMaxMessage ? alertErrorMessage(errorsMessages?.codMaxMessage) : (null)}
                </Col>
            </Row>
            <Row gutter={32} style={{ marginBottom: "5px" }}>
                {/* RndCost */}
                <Col span={8} style={{ borderRight: "1px solid #ccc" }}>
                    <Row gutter={4} justify='center' >
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>RndCost</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'rnd_bp-min'}
                                label={'MIN'}
                                name={'rndCostBestPracticeMin'}
                            /*  onChange={(value: number) => {
                                 if (value > rndBp && rndBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, rndCMinMessage: validationMessage.rndCMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, rndCMinMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />

                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'rnd_bp'}
                                label={'BP'}
                                name={'rndCostBestPractice'}
                            /* onChange={(value: number) => {
                                if (value < rndBpMin && rndBpMin !== null) {
                                    setErrorsMessages({ ...errorsMessages, rndCMinMessage: validationMessage.rndCMinMessage })
                                    setDisabledBtnSubmit(true)
                                } else if (value > rndBpMax && rndBpMax !== null) {
                                    setErrorsMessages({ ...errorsMessages, rndCMaxMessage: validationMessage.rndCMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, rndCMinMessage: '', rndCMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }
                            } */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'rnd_bp-max'}
                                label={'MAX'}
                                name={'rndCostBestPracticeMax'}
                            /*  onChange={(value: number) => {
                                 if (value < rndBp && rndBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, rndCMaxMessage: validationMessage.rndCMaxMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, rndCMaxMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                    </Row>
                    {errorsMessages?.rndCMinMessage ? alertErrorMessage(errorsMessages?.rndCMinMessage) : (null)}
                    {errorsMessages?.rndCMaxMessage ? alertErrorMessage(errorsMessages?.rndCMaxMessage) : (null)}
                </Col>
                {/* interdiscipline */}
                <Col span={8} style={{ borderRight: "1px solid #ccc" }}>
                    <Row gutter={4} justify='center' >
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>Interdiscipline</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'idiscipline_bp-min'}
                                label={'MIN'}
                                name={'interdisciplineBestPracticeMin'}
                            /*  onChange={(value: number) => {
                                 if (value > indBp && indBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, interdisMinMessage: validationMessage.interdisMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, interdisMinMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'idiscipline_bp'}
                                label={'BP'}
                                name={'interdisciplineBestPractice'}
                            /*   onChange={(value: number) => {
                                  if (value < indBpMin && indBpMin !== null) {
                                      setErrorsMessages({ ...errorsMessages, interdisMinMessage: validationMessage.interdisMinMessage })
                                      setDisabledBtnSubmit(true)
                                  } else if (value > indBpMax && indBpMax !== null) {
                                      setErrorsMessages({ ...errorsMessages, interdisMaxMessage: validationMessage.interdisMaxMessage })
                                      setDisabledBtnSubmit(true)
                                  } else {
                                      setErrorsMessages({ ...errorsMessages, interdisMinMessage: '', interdisMaxMessage: '' })
                                      setDisabledBtnSubmit(false)
                                  }
                              }
                              } */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'idiscipline_bp-max'}
                                label={'MAX'}
                                name={'interdisciplineBestPracticeMax'}
                            /* onChange={(value: number) => {
                                if (value < indBp && indBp !== null) {
                                    setErrorsMessages({ ...errorsMessages, interdisMaxMessage: validationMessage.interdisMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, interdisMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }} */
                            />
                        </Col>
                    </Row>
                    {errorsMessages?.interdisMinMessage ? alertErrorMessage(errorsMessages?.interdisMinMessage) : (null)}
                    {errorsMessages?.interdisMaxMessage ? alertErrorMessage(errorsMessages?.interdisMaxMessage) : (null)}
                </Col>
                {/* Original*/}
                <Col span={8}>
                    <Row gutter={4} justify='center' >
                        <Col span={24}><Typography.Title level={5} type="secondary" underline>Original</Typography.Title></Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'original_bp-min'}
                                label={'MIN'}
                                name={'originalBestPracticeMin'}
                            /*  onChange={(value: number) => {
                                 if (value > originalBp && originalBp !== null) {
                                     setErrorsMessages({ ...errorsMessages, originalMinMessage: validationMessage.originalMinMessage })
                                     setDisabledBtnSubmit(true)
                                 } else {
                                     setErrorsMessages({ ...errorsMessages, originalMinMessage: '' })
                                     setDisabledBtnSubmit(false)
                                 }
                             }} */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'original_bp'}
                                label={'BP'}
                                name={'originalBestPractice'}
                            /*   onChange={(value: number) => {
                                  if (value < originalBpMin && originalBpMin !== null) {
                                      setErrorsMessages({ ...errorsMessages, originalMinMessage: validationMessage.originalMinMessage })
                                      setDisabledBtnSubmit(true)
                                  } else if (value > originalBpMax && originalBpMax !== null) {
                                      setErrorsMessages({ ...errorsMessages, originalMaxMessage: validationMessage.originalMaxMessage })
                                      setDisabledBtnSubmit(true)
                                  } else {
                                      setErrorsMessages({ ...errorsMessages, originalMinMessage: '', originalMaxMessage: '' })
                                      setDisabledBtnSubmit(false)
                                  }
                              }
                              } */
                            />
                        </Col>
                        <Col span={8}>
                            <FormItemBp
                                key={'original_bp-max'}
                                label={'MAX'}
                                name={'originalBestPracticeMax'}
                            /* onChange={(value: number) => {
                                if (value < originalBp && originalBp !== null) {
                                    setErrorsMessages({ ...errorsMessages, originalMaxMessage: validationMessage.originalMaxMessage })
                                    setDisabledBtnSubmit(true)
                                } else {
                                    setErrorsMessages({ ...errorsMessages, originalMaxMessage: '' })
                                    setDisabledBtnSubmit(false)
                                }
                            }} */
                            />
                        </Col>
                    </Row>
                    {errorsMessages?.originalMinMessage ? alertErrorMessage(errorsMessages?.originalMinMessage) : (null)}
                    {errorsMessages?.originalMaxMessage ? alertErrorMessage(errorsMessages?.originalMaxMessage) : (null)}
                </Col>
            </Row>
            <Row justify="center" style={{ marginBottom: "10px", marginTop: "10px" }}>
                <Button htmlType="submit"
                    style={{ display: "flex", padding: "10px 50px", justifyContent: "center", alignItems: "center", marginRight: "10px" }}
                    disabled={disabledBtnSubmit}>
                    <SaveOutlined /> <Typography.Title level={5} style={{ color: "currentcolor" }}>
                        {`${t("Save")}`}</Typography.Title>
                </Button>
                <PopconfirmClearAll onConfirm={async () => await onClearAll(props.industryId)} bpId={props.industryBestPracticeData?.id} />

            </Row>
        </Form >
    );
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        industryBestPracticeData: state.industryBestPractice.industryBestPracticeResult,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getALLIndustryBestPractice: (id: number) => dispatch({ type: ActionTypes.GET_INDUSTRY_BEST_PRACTICES, industryId: id }),
        updateIndustryBestPractice: (model: IIndustryBestPracticeUpdate, id: number) => dispatch({ type: ActionTypes.UPDATE_INDUSTRY_BEST_PRACTICE, model, industryId: id }),
        clearIndustryBestPractice: (industryId: number) => dispatch({ type: ActionTypes.CLEAR_INDUSTRY_BEST_PRACTICE, industryId }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(IndustryBestPracticeForm); 