import { Card, Empty, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IDProject } from "../../../../../Models/IDProject";
import { IFlToolToBeEdittedProject, IFrontLoadingToolProject, IFrontLoadingToolTemplate } from "../../../../../Models/IFrontLoadingTool";
import { ICommonResponse } from "../../../../../Models/Responses/ICommonResponse";
import { BmProject } from "../../../../../Models/Responses/ProfileResponse";
import { ActionTypes } from "../../../../../Redux/actionsTypes";
import { openNotificationWithIcon } from "../../../../../Utils/helperFunctions";
import AddEditFlProjectForm from "./Components/AddEditFlProjectForm/AddEditFlProjectForm";
import AddFrontLoadingProject from "./Components/AddFrontLoadingProject/AddFrontLoadingProject";
import FrontLoadingProjectSelection from "./Components/FrontLoadingProjectSelection/FrontLoadingProjectSelection";
import BestpracticeCurveData from "./Models/BestpracticeCurveData";
import './overviewprojectstab.scss';

interface IProps {
    flProjects: IFrontLoadingToolProject[];
    getDprojects: (bmProjectId: number) => void;
    getingFlTemplates: boolean;
    getFlTemplates: () => void;
    dprojectsFetchedSuccess: boolean;
    currentProject: BmProject;
    active: boolean;
    getBestPracticesCurves: () => void;
    gettingBestPracticesCurves: boolean,
    //bestpracticeCurveData: BestpracticeCurveData[],
    flToolToBeEdittedProject: IFlToolToBeEdittedProject;
    gettingToBeEdittedFlProject: boolean;
    resetFlToolToBeEdittedProject: () => void;
};

function OverViewProjectsTab(props: IProps) {

    const { t } = useTranslation();

    useEffect(() => {
        props.getFlTemplates();
        if (props.currentProject?.id)
            props.getDprojects(props.currentProject?.id);
        props.getBestPracticesCurves();
    }, []);

    useEffect(() => {
        if (props.currentProject?.id)
            props.getDprojects(props.currentProject?.id);
    }, [props.currentProject]);

    const saveFlProject = () => {

    };

    const cancelSaveFlProject = () => {
        props.resetFlToolToBeEdittedProject();

    };

    let view = (props.active ? <Spin
        spinning={props.getingFlTemplates ||
            !props.dprojectsFetchedSuccess ||
            props.gettingBestPracticesCurves}>
        <div className="input_tab_main_div">
            <div className="flprojectselection_card_div">
                <Card
                    title={<div className="flprojectselection_card_title">{t("Select Frontloading Project")}</div>}
                    bordered={false}>
                    {(props.flProjects.length === 0)?
                    <Empty className="empty-flprojects"/>
                    :<FrontLoadingProjectSelection
                        flProjects={props.flProjects} />}
                </Card>
            </div>
            <div className="flprojectadd_card_div">
                <Spin spinning={props.gettingToBeEdittedFlProject}>
                    <Card
                        title={<div className="flprojectadd_card_title">
                            {props.flToolToBeEdittedProject ? t("Edit Frontloading Project") : t("Create New Analysis")}
                        </div>}
                        bordered={false}>
                        <AddEditFlProjectForm
                            cancelSaveFlProject={cancelSaveFlProject} />
                    </Card>
                </Spin>
            </div>
        </div>
    </Spin> : null);


    return view;
};

const mapStateToProps = (state) => {
    return {
        currentProject: state.generalInfo.profileResponse?.currentProject,
        dprojectsFetchedSuccess: state.dprojects.dprojectsFetchedSuccess,
        getingFlTemplates: state.frontLoadingProjects.getingFlTemplates,
        gettingBestPracticesCurves: state.frontLoadingProjects.gettingBestPracticesCurves,

        gettingToBeEdittedFlProject: state.frontLoadingProjects.gettingToBeEdittedFlProject,

        edittingFlProject: state.frontLoadingProjects.edittingFlProject,
        flToolToBeEdittedProject: state.frontLoadingProjects.flToolToBeEdittedProject,

    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getFlTemplates: () =>
            dispatch({ type: ActionTypes.GetFlTemplates }),

        getDprojects: (bmProjectId: number) =>
            dispatch({ type: ActionTypes.GETDPROJECTS, bmProjectId: bmProjectId }),

        getBestPracticesCurves: () =>
            dispatch({ type: ActionTypes.GetBestPracticesCurves }),

        resetFlToolToBeEdittedProject: () =>
            dispatch({ type: ActionTypes.SaveFlToolToBeEdittedProject, flToolToBeEdittedProject: null }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OverViewProjectsTab);