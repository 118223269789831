import { useState, useEffect } from "react";
import { Button, Form, Input, Select, Space, Spin, Checkbox, Typography, Row, Col, Tooltip } from "antd";
import { connect } from "react-redux";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { ICustomerUserItem, ICustomerUserItemNew, ICustomerUserItemWithKey } from "../../../Models/ICustomerUsers";
import CustomerUsersTable from "./CustomerUsersTable";
import { IBmProjectList } from "../../../Models/IBmProjectList";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useSearchParams } from "react-router-dom";
import ModalAdmin from "../../../Components/ModalAdmin/ModalAdmins";
import { ICommonResponse } from "../../../Models/Responses/ICommonResponse";
import "./index.scss";
import { RulesEmail, RulesName, RulesPhone, RulesRequire } from "../Rules/RulesValidation";
import { IListItem } from "../../../Models/Responses/ProfileResponse";
import { t } from "i18next";


interface IProps {
    customerUsers: ICustomerUserItem[];
    titles: IListItem[];
    languagesList: IListItem[];
    collectionRoles: any[];
    bmProjectList: IBmProjectList[];
    isLoading: boolean;
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getCustomerUsers: (id: number) => any;
    getBmProjectList: (id: number) => any;
    createCustomerUser: (model: ICustomerUserItemNew, id: number) => any;
    onImportTable: (frmData: FormData, customerId: number) => any;
}
const CustomerUsers = (props: IProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const customerId = Number(searchParams.get('customerId'));
    const customerName = searchParams.get('customerName');
    const [formValues, setFormValues] = useState<ICustomerUserItemNew>();
    const [form] = Form.useForm();
    useEffect(() => {
        props.getCustomerUsers(customerId);
        props.getBmProjectList(customerId)
    }, [customerId]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const customerUsersData: ICustomerUserItemWithKey[] = props.customerUsers?.map((c) => {
        return {
            key: `cus_user_${c.personId}`,
            id: c.personId,
            titleName: c.title.label,
            titleId: c.title.value,
            languageName: c.defaultLanguage.label,
            defaultLanguageId: c.defaultLanguage.value,
            ...c
        }
    }) ?? [];

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const newCustomerUsers = { ...formValues, customerId: customerId };
            await props.createCustomerUser(newCustomerUsers, customerId);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setIsModalVisible(false);
    }
    const onImport = (formData: FormData)=>{
        props.onImportTable(formData, customerId);
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <Typography.Title level={4} style={{ color: "#1644b0" }}>{customerName}</Typography.Title>
                <Space direction="vertical" size="small">
                    {/* <Tooltip placement="top" title={`${t("Daten importieren")}`}>
                        <UploadFile currentProjectId={props.bmProjectId} onImportCostTable={onImport} />
                    </Tooltip> */}
                    <ModalAdmin title={`${t("Add")} ${t("New")} ${t("Customer")} ${t("User")}`} btnText={`${t("Add")}`}
                        width="1000px"
                        modalResponse={props.modalResponse}
                        closeAndReset={closeAndReset}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        footerchildren={[
                            <Button key="back" onClick={closeAndReset}>
                                {`${t("Cancel")}`}
                            </Button>,
                            <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                                {`${t("Save")}`} & {`${t("Send")}`}
                            </Button>
                        ]}>
                        <Form
                            layout="vertical"
                            form={form}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item label={`${t("First Name")}`} name='firstName'
                                        rules={RulesName({ name: `${t("First Name")}`, countChar: 128 })}
                                        hasFeedback>
                                        <Input onChange={(e) => setFormValues({ ...formValues, firstName: (e.target.value)?.trimStart() })} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={`${t("Last Name")}`} name='lastName'
                                        rules={RulesName({ name: `${t("Last Name")}`, countChar: 128 })}
                                        hasFeedback>
                                        <Input onChange={(e) => setFormValues({ ...formValues, lastName: (e.target.value)?.trimStart() })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item label={`${t("E-Mail")}`} name='email'
                                        rules={RulesEmail()} hasFeedback>
                                        <Input onChange={(e) => setFormValues({ ...formValues, email: (e.target.value)?.trimStart() })} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={`${t("Phone")}`} name='phoneNumber' rules={RulesPhone()}>
                                        <Input
                                            onChange={(e) =>
                                                setFormValues({ ...formValues, phoneNumber: (e.target.value)?.trimStart() })
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item label={`${t("Title")}`} name='titleId' rules={RulesRequire({ filedName: `${t("Title")}` })}  >
                                        <Select showSearch placeholder={`${t("Select Title")}`} optionFilterProp="children"
                                            onChange={(value: number) => {
                                                let _title = props.titles.find(t => t.value === value);
                                                setFormValues({ ...formValues, titleId: value, title: _title?.label })
                                            }}
                                            getPopupContainer={() => document.getElementById("app-layout")}
                                        >
                                            {props.titles?.map((t, index) => <Select.Option value={t?.value} key={index}>{t?.label}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={`${t("Language")}`} name='defaultLanguageId' rules={RulesRequire({ filedName: `${t("Language")}` })}  >
                                        <Select showSearch placeholder={`${t("Select Language")}`} optionFilterProp="children"
                                            onChange={(value: number) => {
                                                let _defaultLanguage = props.languagesList?.find(l => l?.value === value);
                                                setFormValues({ ...formValues, defaultLanguageId: value, defaultLanguage: _defaultLanguage?.label })
                                            }
                                            }
                                            getPopupContainer={() => document.getElementById("app-layout")}
                                        >
                                            {props.languagesList?.map((d: any) => <Select.Option value={d?.value} key={d?.value}>{d?.label}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label={`${t("Bm Project")}`} name='bmProjectId' rules={RulesRequire({ filedName: `${t("Bm Project")}` })} >
                                <Select showSearch placeholder={`${t("Select BmProject")}`} optionFilterProp="children"
                                    onChange={(value: number) => setFormValues({ ...formValues, bmProjectId: value })}
                                    getPopupContainer={() => document.getElementById("app-layout")}
                                >
                                    {props.bmProjectList?.map((b: any) => <Select.Option value={b.id} key={b.id}>{b.name}</Select.Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item label={`${t("Data Collection Roles")}`} name='dataCollectionRoles' rules={RulesRequire({ filedName: `${t("Data Collection Roles")}` })}>
                                <Checkbox.Group options={props.collectionRoles && props.collectionRoles} onChange={(list: any[]) => setFormValues({ ...formValues, dataCollectionRoles: list })} />
                            </Form.Item>




                        </Form>
                    </ModalAdmin>
                    <CustomerUsersTable
                        customerUsersData={customerUsersData}
                        titlesData={props.titles}
                        languageListData={props.languagesList}
                        isModalLoading={props.isModalLoading}
                        modalResponse={props.modalResponse}
                        collectionRoles={props.collectionRoles}
                        customerId={customerId}
                    />
                </Space>
            </div>
        </Spin>
    );
}
const mapStateToProps = (state: any) => {
    return {

        customerUsers: state.customerUser?.customerUsersResult,
        isLoading: state.customerUser.isCustomerUserLoading,
        titles: state.generalInfo.profileResponse?.generalSettings?.titles,
        languagesList: state.generalInfo.profileResponse?.generalSettings?.languageList,
        collectionRoles: state.generalInfo.profileResponse?.generalSettings?.collectionRoles,
        contacts: state.generalInfo.profileResponse?.generalSettings?.contactChannels,
        bmProjectList: state.bmProjectList.bmProjectListResult,
        isModalLoading: state.customerUser.isModalLoading,
        modalResponse: state.customerUser.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getCustomerUsers: (id: number) => dispatch({ type: ActionTypes.GET_CUSTOMERUSERS, customerId: id }),
        getBmProjectList: (id: number) => dispatch({ type: ActionTypes.GET_BMPROJECT_LIST_BY_ID, customerId: id }),
        createCustomerUser: (model: ICustomerUserItemNew, id: number) => dispatch({ type: ActionTypes.CREATE_CUSTOMERUSER, model: model, customerId: id }),
        onImportTable: (frmData: FormData, customerId: number) => dispatch({ type: ActionTypes.ImportCustomerUser, frmData: frmData, customerId: customerId })

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsers);
