import { EditFlProjectToUploadModel } from "../../Models/FrontLoadingTool/FrontLoadingTool";
import { CurrentFlProject, IFlToolToBeEdittedProject, IFrontLoadingToolTemplate } from "../../Models/IFrontLoadingTool";
import FlProjectDetails from "../../Scenes/Tools/FrontLoading/FrontLoadingTabs/InputTab/Models/FlProjectDetails";
import BestpracticeCurveData from "../../Scenes/Tools/FrontLoading/FrontLoadingTabs/OverViewProjectsTab/Models/BestpracticeCurveData";
import { RTDCurveData } from "../../Scenes/Tools/FrontLoading/FrontLoadingTabs/RTDCurveTab/Models/RTDCurveData";
import { ActionTypes } from "../actionsTypes";
import { IFrontloadingnitialState } from "./IGeneralState";


const initialState: IFrontloadingnitialState = {
  flProjects: [],
  flTemplates: [],
  savingFlProject: false,
  getingFlTemplates: true,
  gettingFlProjects: true,
  showAddFlProjectForm: false,
  gettingToBeEdittedFlProject: false,
  flToolToBeEdittedProject: null,
  edittingFlProject: false,
  deletingFlProject: false,
  selectingFlProjectForConsultant: false,
  gettingFlProjectDetails: false,
  flProjectDetails: null,
  uploadingPhase: false,
  uploadingMilestone: false,
  gettingPhaseValidDates: false,
  phaseValidDates: {
    phaseLimitDates: {
      actualEndMin: '',
      actualEndMax: '',
      //plannedEndMin: '',
      //plannedEndMax: ''
    },
    milestoneLimiDates: {
      actualMin: '',
      actualMax: '',
    //plannedMin: '',
     // plannedMax: ''
    }
  },
  gettingRTDCurveData: false,
  RTDCurveData: null,
  gettingBestPracticesCurves: false,
  bestpracticeCurveData: [],
  RTDCurveDataForComarativeProject: null,
  showConfirmationMsg: false,
  toBeEdittedFlProject: null,
  confirmingEditFlProject: false,
  gettingAboutCurrentFlProject: false,
  currentFlProject: null,
  frontLoadingProgress: 0,
  markingFlProjectAsComplete: false,
  deletingMilestone: false
};

const FrontLoadingToolReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.DeletingMilestone:
      return {
        ...state,
        deletingMilestone: action.value
      }
    case ActionTypes.MarkingFlProjectAsComplete:
      return {
        ...state,
        markingFlProjectAsComplete: action.value
      };
    case ActionTypes.SaveFrontloadingPrgress:
      return {
        ...state,
        frontLoadingProgress: action.progressValue
      }
    case ActionTypes.SaveCurrentFlProject:
      return {
        ...state,
        currentFlProject: action.currentFlProject
      }
    case ActionTypes.GettingAboutCurrentFlProject:
      return {
        ...state,
        gettingAboutCurrentFlProject: action.value
      }
    case ActionTypes.ResetEditConfirmationAttributes:
      console.log("ActionTypes.ResetEditConfirmationAttributes");
      return {
        ...state,
        showConfirmationMsg: false,
        toBeEdittedFlProject: null,
        //confirmingEditFlProject: false
      };
    case ActionTypes.ConfirmingEditFlProject:
      return {
        ...state,
        confirmingEditFlProject: action.value
      }
    case ActionTypes.ShowConfirmationMsg:
      return {
        ...state,
        showConfirmationMsg: action.value
      };
    case ActionTypes.SaveToBeEdittedFlProject:
      return {
        ...state,
        toBeEdittedFlProject: action.flProject
      };
    case ActionTypes.SaveingFlProject:
      return {
        ...state,
        savingFlProject: action.value
      };
    case ActionTypes.GetingFlTemplates:
      return {
        ...state,
        getingFlTemplates: action.value
      };
    case ActionTypes.SaveFlTemplates:
      return {
        ...state,
        flTemplates: action.templates
      };
    case ActionTypes.GettingFlProjects:
      return {
        ...state,
        gettingFlProjects: action.value
      };
    case ActionTypes.SaveFlProjects:
      return {
        ...state,
        flProjects: action.projects
      };
    case ActionTypes.ShowAddFlProjectForm:
      return {
        ...state,
        showAddFlProjectForm: action.value
      };
    case ActionTypes.GettingToBeEdittedFlProject:
      return {
        ...state,
        gettingToBeEdittedFlProject: action.value
      };
    case ActionTypes.SaveFlToolToBeEdittedProject:
      return {
        ...state,
        flToolToBeEdittedProject: action.flToolToBeEdittedProject
      };
    case ActionTypes.EdittingFlProject:
      return {
        ...state,
        edittingFlProject: action.value
      };
    case ActionTypes.DeletingFlProject:
      return {
        ...state,
        deletingFlProject: action.value
      };
    case ActionTypes.SelectingFlProjectForConsultant:
      return {
        ...state,
        selectingFlProjectForConsultant: action.value
      };
    case ActionTypes.GettingFlProjectDetails:
      return {
        ...state,
        gettingFlProjectDetails: action.value
      };
    case ActionTypes.SaveFlProjectDetails:
      return {
        ...state,
        flProjectDetails: action.details
      };
    case ActionTypes.UploadingPhase:
      return {
        ...state,
        uploadingPhase: action.value
      };
    case ActionTypes.UploadingMilestone:
      return {
        ...state,
        uploadingMilestone: action.value
      };
    case ActionTypes.GettingPhaseValidDates:
      return {
        ...state,
        gettingPhaseValidDates: action.value
      };
    case ActionTypes.SavePhaseValidDates:
      return {
        ...state,
        phaseValidDates: { ...action.phaseValidDates }
      };
    case ActionTypes.ChangeMilestoneActualDateMax:
      return {
        ...state,
        phaseValidDates: {
          ...state.phaseValidDates,
          milestoneLimiDates: {
            ...state.phaseValidDates.milestoneLimiDates,
            actualMax: action.actualDateMax
          }
        }
      };
    case ActionTypes.ChangeMilestonePlannedDateMax:
      return {
        ...state,
        phaseValidDates: {
          ...state.phaseValidDates,
          milestoneLimiDates: {
            ...state.phaseValidDates.milestoneLimiDates,
            plannedMax: action.plannedDateMax
          }
        }
      };
    case ActionTypes.GettingRTDCurveData:
      return {
        ...state,
        gettingRTDCurveData: action.value
      };
    case ActionTypes.SaveRTDCurveData:
      if (action.RTDCurveData === null) {
        return {
          ...state,
          RTDCurveData: null,
          RTDCurveDataForComarativeProject: null
        }
      }
      if (action.isCurrentFlProject) {
        return {
          ...state,
          RTDCurveData: action.RTDCurveData
        };
      } else {
        return {
          ...state,
          RTDCurveDataForComarativeProject: action.RTDCurveData
        };
      }
    case ActionTypes.GettingBestPracticesCurves:
      return {
        ...state,
        gettingBestPracticesCurves: action.value
      };
    case ActionTypes.SaveBestpracticeCurveData:
      return {
        ...state,
        bestpracticeCurveData: action.BestpracticeCurveData
      };
    default:

      break;
  }
  return state;
};

export default FrontLoadingToolReducer;