import React, { useState } from 'react';
import { Button, DatePicker, Form, Row, Typography } from 'antd';
import { IEventLogFilter, IEventLogFilterMenus, ILogs } from '../../../Models/IEventLogForAdmin'
import CheckBoxFilters from '../../../Components/CheckBoxFilters/CheckBoxFilters'
import i18n from '../../../i18n';
import de from 'antd/es/date-picker/locale/de_DE';
import en from 'antd/es/date-picker/locale/en_US';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { SettingOutlined } from '@ant-design/icons';

interface IProps {
    filtersMenus: IEventLogFilterMenus;
    isLoading: boolean;
    getAllEventLogs: (model: IEventLogFilter) => any;
    setFilters: (model: IEventLogFilter) => any;
    filters: IEventLogFilter;
}
const EventLogsFiltersForm = (props: IProps) => {
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    let eventTypesCheckItem = Form.useWatch('eventTypes', form);
    let eventLevelCheckItem = Form.useWatch('eventLevels', form);
    let entityTypesCheckItem = Form.useWatch('entityTypes', form);
    let auditTypeIdsCheckItem = Form.useWatch('auditTypes', form);

    const onFinish = async (fieldsValue) => {
        try {
            const rangeValue = fieldsValue['range-picker'] ?? [];
            const newFilter: IEventLogFilter = {
                pageIndex: props.filters?.pageIndex,
                pageLength: props.filters?.pageLength,
                eventTypeIds: fieldsValue['eventTypes'] ?? [],
                eventLevelIds: fieldsValue['eventLevels'] ?? [],
                entityTypeIds: fieldsValue['entityTypes'] ?? [],
                auditTypeIds: fieldsValue['auditTypes'] ?? [],
                startDate: rangeValue[0]?.format('L') ?? null,
                endDate: rangeValue[1]?.format('L') ?? null,
            }
            await props.setFilters(newFilter)

        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Form form={form} onFinish={onFinish}>
            <Row style={{ columnGap: '.5rem' }}>
                <Form.Item name="eventTypes">
                    <CheckBoxFilters filterData={props.filtersMenus?.eventTypes}
                        title={`${t("Event Types")}`}
                        itemSelected={eventTypesCheckItem?.length} />
                </Form.Item>
                <Form.Item name="auditTypes">
                    <CheckBoxFilters filterData={props.filtersMenus?.auditTypes}
                        title={`${t("Audit Types")}`}
                        itemSelected={auditTypeIdsCheckItem?.length} />
                </Form.Item>
                <Form.Item name="entityTypes">
                    <CheckBoxFilters filterData={props.filtersMenus?.entityTypes}
                        title={`${t("Entity Types")}`}
                        itemSelected={entityTypesCheckItem?.length} />
                </Form.Item>
                <Form.Item name="eventLevels">
                    <CheckBoxFilters filterData={props.filtersMenus?.eventLevels}
                        title={`${t("Event Levels")}`}
                        itemSelected={eventLevelCheckItem?.length} />
                </Form.Item>
                <Form.Item name="range-picker" >
                    <RangePicker locale={i18n.language === 'de' ? de : en} format={"L"} />
                </Form.Item>
                <Button htmlType="submit" >
                    <SettingOutlined />
                    <Typography.Text>
                        {`${t("Apply Filter")}`}</Typography.Text>
                </Button>
            </Row>
        </Form>
    )
}
const mapStateToProps = (state: any) => {
    return {

        isLoading: state.eventLogs.eventLogsResult,
    }
}
const mapDispatchForProps = (dispatch: any) => {
    return {
        getAllEventLogs: (model: IEventLogFilter) => dispatch({ type: ActionTypes.GET_ALL_EVENT_LOGS, model }),
    }
}
export default connect(mapStateToProps, mapDispatchForProps)(EventLogsFiltersForm);