import { all, put, takeEvery } from "redux-saga/effects";
import { ToastMessages } from "../../Enums/Messages";
import { ILoginResponse } from "../../Models/Responses/AuthResponse";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import AuthService from "../../Services/AuthService";
import { ActionTypes } from "../actionsTypes";

function* onLoggedIn(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result: ICommonResponse = yield AuthService.StartLogin(action.model);
        if (!_result.isError) {
            let result = _result.result as ILoginResponse;
           
            if (result.randomCodes && result.randomCodes.length > 0) {
                //console.log("onLoggedIn ", "01")
                yield put({ type: ActionTypes.SaveLoginResult, result: result });
                yield put({ type: ActionTypes.ForceToPath, model: "/verify" });
            }
            else {
                //console.log("onLoggedIn ", "02")
                if (localStorage.getItem("rememberme") == 'false') {
                    sessionStorage.setItem("token", result.token);
                    sessionStorage.setItem("refreshToken", result.refreshToken);
                } else {
                    localStorage.setItem("token", result.token);
                    localStorage.setItem("refreshToken", result.refreshToken);
                }
                yield put({ type: ActionTypes.GetHomePage, result: _result, applyRedirect: true });
            }
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
            yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
        }
    } catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    }
}

function* onVerifyLogin(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result: ICommonResponse = yield AuthService.StartVerify(action.model);

        if (!_result.isError) {
            let result = _result.result as ILoginResponse;
            yield put({ type: ActionTypes.SaveVerifyLoginResult, result: result });        
            yield put({ type: ActionTypes.GetHomePage, result: _result, applyRedirect: true });
            
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result });
        }
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    } catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    }

}

function* onActivateUser(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result: ICommonResponse = yield AuthService.ActivateUser(action.model);

        yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    } catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    }
}

function* onResetPasswordRequest(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result: ICommonResponse = yield AuthService.ResetPasswordRequest(action.email);

        yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    } catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    }
}

function* onResetPassword(action: any) {
    try {
        yield put({ type: ActionTypes.ChangeHomeLoader, result: true });
        let _result: ICommonResponse = yield AuthService.ConfirmResetPassword(action.model);

        yield put({ type: ActionTypes.SetCommonResponse, result: _result });

        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    } catch (error) {
        yield put({ type: ActionTypes.SetCommonResponse, result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse });
        yield put({ type: ActionTypes.ChangeHomeLoader, result: false });
    }
}

function* onConfirmPassword(action: any) {
    try {
        yield put({ type: ActionTypes.SetModalLoader, result: true });
        let _result: ICommonResponse = yield AuthService.ConfirmPassword(action.password);
        if (!_result.isError) {
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
            yield put({ type: ActionTypes.SetPasswordRequiredModal, isPasswordRequired: false, hasAccessTokenToProjects: true });
        } else {
            yield put({ type: ActionTypes.SetModalResponse, result: _result });
            yield put({ type: ActionTypes.SetPasswordRequiredModal, isPasswordRequired: true, hasAccessTokenToProjects: false });
        }
        yield put({ type: ActionTypes.SetModalLoader, result: false });

    } catch (error) {
        yield put({ type: ActionTypes.SetModalLoader, result: false });
        yield put({ type: ActionTypes.SetPasswordRequiredModal, isPasswordRequired: true, hasAccessTokenToProjects: false });
    }
}


function* watchLogin() {
    yield takeEvery(ActionTypes.Login, onLoggedIn);
}
function* watchVerifyLogin() {
    yield takeEvery(ActionTypes.VerifyLogin, onVerifyLogin);
}

function* watchActivateUser() {
    yield takeEvery(ActionTypes.ActivateUser, onActivateUser);
}
function* watchConfirmPassword() {
    yield takeEvery(ActionTypes.ConfirmPassword, onConfirmPassword);
}
function* watchResetPasswordRequest() {
    yield takeEvery(ActionTypes.ResetPasswordRequest, onResetPasswordRequest);
}
function* watchResetPassword() {
    yield takeEvery(ActionTypes.ResetPassword, onResetPassword);
}
export default function* AuthenticationSagas() {
    yield all([
        watchLogin(),
        watchVerifyLogin(),
        watchActivateUser(),
        watchConfirmPassword(),
        watchResetPasswordRequest(),
        watchResetPassword()
    ]);
}