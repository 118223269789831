import i18n from 'i18next';
import {  initReactI18next, Trans } from "react-i18next";
import { format as formatDate, isDate } from "date-fns";
import { de, fr, enUS } from "date-fns/locale"; // import all locales we need
import { isRealNumber } from '@antv/g2plot/lib/utils';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
const locales = { en: enUS, de }; // used to look up the required locale

const translationsEn = require("./assets/localization/en.json");
const translationsDe = require("./assets/localization/de.json")
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: ''
    },
    resources: {
      en: { translation: translationsEn },
      de: { translation: translationsDe },
    },    
    lng: localStorage.getItem('localLang') ?? 'de',
    fallbackLng: "de",
    debug: true,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        const locale = locales[lng];
        //console.log("locale: ", locale);
        if (isDate(value)) {
          //console.log('format: ', value, format, lng);
          try {
            return formatDate(value, format, { locale });
          } catch (ex) {

          }
        } else if (isRealNumber(value)) {
          return new Intl.NumberFormat(lng == "de" ? 'de-DE' : 'en-US', { maximumFractionDigits: 1 }).format(value);
        }
        return value;
      }
    },
  });

i18n.on('languageChanged', function (lng) {

  //i18n.reloadResources();
});
i18n.on('missingKey', function (lngs, namespace, key, res) {
  console.log('missingKey', lngs, namespace, key);
})
export default i18n;