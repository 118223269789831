import { ActionTypes } from "../actionsTypes";
import { ISegmentDisciplineBestPracticeState } from "./IGeneralState";

const initialState: ISegmentDisciplineBestPracticeState = {
    isSegmentDisciplineBestPracticeLoading: false,
    segmentDisciplineBestPracticeResult: null,
    isModalLoading: false,
    modalResponse: null,
};
const SegmentDisciplineBestPracticeReducer = (state = initialState, action: any): ISegmentDisciplineBestPracticeState => {
    switch (action.type) {
        case ActionTypes.CHANGE_SEGMENT_DISCIPLINE_BEST_PRACTICE_LOADER:
            return {
                ...state,
                isSegmentDisciplineBestPracticeLoading: action.isLoading,
            };
        case ActionTypes.SAVE_SEGMENT_DISCIPLINE_BEST_PRACTICES:
            return {
                ...state,
                segmentDisciplineBestPracticeResult: [...action.result],
            };
        case ActionTypes.SetModalLoader:
            return {
                ...state,
                isModalLoading: action.result
            };
        case ActionTypes.SetModalResponse:
            return {
                ...state,
                modalResponse: action.result
            };
        default:
            break;
    }
    return state;
};
export default SegmentDisciplineBestPracticeReducer;
