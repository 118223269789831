import { ToastMessages } from "../Enums/Messages";
import { ICurvePhaseItem, ICurvesItem } from "../Models/ICurves";
import { ICommonResponse } from "../Models/Responses/ICommonResponse";
import asupapi from "./axios-asup";
import { endPoints } from "./endPoints";

export default class FrontLoadingCurvesService {
    static async GetAllCurves(): Promise<ICommonResponse> {
        return await asupapi.get(
            endPoints.getAllCurves
        ).then(function (response) {
            let result: ICommonResponse = response.data;
            console.log("result test :", result);
            return result;
        }).catch(function (error) {
            if (error.response) {
                console.log('error1', error.response);
                if (error.response.data) {
                    console.log("error0 ", error.resonse.data);
                    let result: ICommonResponse = error.response.data;
                    return result;
                }
            } else if (error.request) {
                console.log("error2 ", error.request)
            } return {
                isError: false,
                statusCode: -400,
                error: {
                    exceptionMessage: ToastMessages.UnExpectedError,
                }
            } as ICommonResponse;
        })
    }
    static async CreateCurve(model: ICurvesItem): Promise<ICommonResponse> {
        console.log("create curve :", model);
        return await asupapi.post(endPoints.createCurve, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async UpdateCurve(model: ICurvesItem): Promise<ICommonResponse> {
        console.log("Update curve :", model);
        return await asupapi.post(endPoints.updateCurve, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async deleteCurve(id: number): Promise<ICommonResponse> {
        console.log("delete Curve");
        return await asupapi
            .get(endPoints.deleteCurve, {
                params: {
                    id
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    //CurvePhase
    static async CreateCurvePhase(model:ICurvePhaseItem): Promise<ICommonResponse> {
        console.log("create curve phase :", model);
        return await asupapi.post(endPoints.createCurvePhase, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async UpdateCurvePhase(model:ICurvePhaseItem): Promise<ICommonResponse> {
        console.log("Update curve Phase :", model);
        return await asupapi.post(endPoints.updateCurvePhase, model)
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    static async deleteCurvePhase(id: number): Promise<ICommonResponse> {
        console.log("delete Curve phase");
        return await asupapi
            .get(endPoints.deleteCurvePhase, {
                params: {
                    id
                }
            })
            .then(function (response) {
                let result: ICommonResponse = response.data;
                return result;
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("error1 ", error.response);
                    if (error.response.data) {
                        console.log("error0 ", error.response.data);
                        let result: ICommonResponse = error.response.data;
                        return result;
                    }
                } else if (error.request) {
                    console.log("error2 ", error.request);
                    return {
                        isError: false,
                        statusCode: -400,
                        error: {
                            exceptionMessage: ToastMessages.NetworkError,
                        },
                    } as ICommonResponse;
                } else if (error.message) {
                    console.log("error3 ", error.message);
                    //do something other than the other two
                }
                return {
                    isError: false,
                    statusCode: -400,
                    error: {
                        exceptionMessage: ToastMessages.UnExpectedError,
                    },
                } as ICommonResponse;
            });
    }
    // //CurvePhasePoint
    // static async CreateCurvePhasePoint(model:ICurvePhasePointItem): Promise<ICommonResponse> {
    //     console.log("create curve phase point:", model);
    //     return await asupapi.post(endPoints.createCurvePhasePoint, model)
    //         .then(function (response) {
    //             let result: ICommonResponse = response.data;
    //             return result;
    //         })
    //         .catch(function (error) {
    //             if (error.response) {
    //                 console.log("error1 ", error.response);
    //                 if (error.response.data) {
    //                     console.log("error0 ", error.response.data);
    //                     let result: ICommonResponse = error.response.data;
    //                     return result;
    //                 }
    //             } else if (error.request) {
    //                 console.log("error2 ", error.request);
    //                 return {
    //                     isError: false,
    //                     statusCode: -400,
    //                     error: {
    //                         exceptionMessage: ToastMessages.NetworkError,
    //                     },
    //                 } as ICommonResponse;
    //             } else if (error.message) {
    //                 console.log("error3 ", error.message);
    //                 //do something other than the other two
    //             }
    //             return {
    //                 isError: false,
    //                 statusCode: -400,
    //                 error: {
    //                     exceptionMessage: ToastMessages.UnExpectedError,
    //                 },
    //             } as ICommonResponse;
    //         });
    // }
    // static async UpdateCurvePhasePoint(model:ICurvePhasePointItem): Promise<ICommonResponse> {
    //     console.log("Update curve Phase point :", model);
    //     return await asupapi.post(endPoints.updateCurvePhasePoint, model)
    //         .then(function (response) {
    //             let result: ICommonResponse = response.data;
    //             return result;
    //         })
    //         .catch(function (error) {
    //             if (error.response) {
    //                 console.log("error1 ", error.response);
    //                 if (error.response.data) {
    //                     console.log("error0 ", error.response.data);
    //                     let result: ICommonResponse = error.response.data;
    //                     return result;
    //                 }
    //             } else if (error.request) {
    //                 console.log("error2 ", error.request);
    //                 return {
    //                     isError: false,
    //                     statusCode: -400,
    //                     error: {
    //                         exceptionMessage: ToastMessages.NetworkError,
    //                     },
    //                 } as ICommonResponse;
    //             } else if (error.message) {
    //                 console.log("error3 ", error.message);
    //                 //do something other than the other two
    //             }
    //             return {
    //                 isError: false,
    //                 statusCode: -400,
    //                 error: {
    //                     exceptionMessage: ToastMessages.UnExpectedError,
    //                 },
    //             } as ICommonResponse;
    //         });
    // }
    // static async deleteCurvePhasePoint(id: number): Promise<ICommonResponse> {
    //     console.log("delete Curve phase point");
    //     return await asupapi
    //         .get(endPoints.deleteCurvePhasePoint, {
    //             params: {
    //                 id
    //             }
    //         })
    //         .then(function (response) {
    //             let result: ICommonResponse = response.data;
    //             return result;
    //         })
    //         .catch(function (error) {
    //             if (error.response) {
    //                 console.log("error1 ", error.response);
    //                 if (error.response.data) {
    //                     console.log("error0 ", error.response.data);
    //                     let result: ICommonResponse = error.response.data;
    //                     return result;
    //                 }
    //             } else if (error.request) {
    //                 console.log("error2 ", error.request);
    //                 return {
    //                     isError: false,
    //                     statusCode: -400,
    //                     error: {
    //                         exceptionMessage: ToastMessages.NetworkError,
    //                     },
    //                 } as ICommonResponse;
    //             } else if (error.message) {
    //                 console.log("error3 ", error.message);
    //                 //do something other than the other two
    //             }
    //             return {
    //                 isError: false,
    //                 statusCode: -400,
    //                 error: {
    //                     exceptionMessage: ToastMessages.UnExpectedError,
    //                 },
    //             } as ICommonResponse;
    //         });
    // }
}