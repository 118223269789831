import { Button, Checkbox, Col, Input, Popover, Row } from 'antd'
import { IListItem } from '../../Models/Responses/ProfileResponse'
import { FilterOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { t } from 'i18next';

interface IProps {
    filterData: IListItem[];
    title: string;
    onChange?: (list: any[]) => any;
    itemSelected?: number;
}
const CheckBoxFilters = (props: IProps) => {
    const { Search } = Input;
    const [dataSearch, setDataSearch] = useState<IListItem[]>();
    useEffect(() => {
        if (props.filterData) {
            setDataSearch(props.filterData)
        }
    }, [])
    const onSearch = (value: string) => {
        setDataSearch(props.filterData?.filter(l => (l?.label ?? '').toLowerCase().includes(value.toLowerCase())));

    }
    if (props.filterData?.length === 0) {
        return;
    }
    return (
        <Popover
            getPopupContainer={() => document.getElementById('app-layout')}
            content={<Checkbox.Group onChange={props.onChange}>
                <Row style={{ width: '250px', overflowY: 'auto', maxHeight: '90vh', margin: "0 -10px" }}>
                    <Search placeholder={`${props.title}`} onSearch={onSearch} style={{ width: 250, marginBottom: ".5rem" }} />
                    {dataSearch?.map(filter => {
                        return (
                            <Col
                                key={"checkbox-group-" + filter.value}
                                span={24}
                                style={{ display: "block", marginBottom: ".2rem" }}
                            >

                                <Checkbox
                                    key={filter.value}
                                    value={filter.value}

                                >
                                    {filter.label}
                                </Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            </Checkbox.Group >}
            trigger="click"
            placement="bottomLeft"
        >
            {
                props.filterData?.length === props.itemSelected ?
                    (<Button >
                        {`${t("All")} ${props.title} ${t("selected")}`} <FilterOutlined />
                    </Button>)
                    : (<Button >
                        {props.itemSelected} {props.title} <FilterOutlined />
                    </Button>)
            }
        </Popover >
    )
}

export default CheckBoxFilters