import { ActionTypes } from "../actionsTypes";

const initialState = {
    isLoading: false,
    stellhebelRadar: null,
    teilstellhebelRadars: null,
    stellhebelChartTable: null
};

const benchMarkingReducer = (state = initialState, action: any) => {
    switch (action.type) {

        // case ActionTypes.SETLOADINGTOTRUE:
        //     return {
        //         ...state,
        //         isLoading: true
        //     }

        // case ActionTypes.SAVESTARTBENCHMARKINGRESULT:
        //     return {
        //         ...state,
        //         isLoading: false,
        //         stellhebelRadar: { ...action.result.stellhebelRadar },
        //         teilstellhebelRadars: [...action.result.teilstellhebelRadars],
        //         stellhebelChartTable: { ...action.result.stellhebelChartTable }
        //     };

        // case ActionTypes.BENCHMARKINGVIEWWILLUNMOUNT: // just for cleanning up
        //     return {
        //         ...state,
        //         isLoading: false
        //     }

        default:
            break;
    }
    return state;
};

export default benchMarkingReducer;