import React, { useState } from 'react'
import { Button, Modal, Tooltip } from 'antd';
import { IErrorLogs } from '../../../Models/IErrorPage';
import ErrorLogTable from './ErrorLogsTable';
import { t } from 'i18next';
interface IProps {
    logs: IErrorLogs[];
}
const ErrorLogModal = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const logsData: IErrorLogs[] = props.logs?.map((d, index) => {
        return {
            key: `detail_${index + 1}`,
            ...d
        }
    }) ?? [];
    const showModal = async () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <> <Tooltip title={`${t("show Errors Logs")}`}>
            <Button onClick={showModal} type='primary'>
               {`${t("Logs")}`}
            </Button>
        </Tooltip>
            <Modal title={`${t("Errors Logs Details")}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1200}  getContainer={() => document.getElementById("app-layout")} footer={[]} >
                <ErrorLogTable logs={logsData} />
            </Modal>
        </>
    );
};

export default ErrorLogModal;