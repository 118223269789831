import { Button, Checkbox, Form, Input } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux';
import ModalAdmin from '../../../../Components/ModalAdmin/ModalAdmins';
import { IKpiItem } from '../../../../Models/ILeversManagement';
import { ICommonResponse } from '../../../../Models/Responses/ICommonResponse';
import { ActionTypes } from '../../../../Redux/actionsTypes';
import { RulesName, RulesRequire } from '../../Rules/RulesValidation';
import { t } from 'i18next';

interface IProps {
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    kips: any[];
    kipsId: number;
    collectionRoles: any[];
    updateKpis: (model: IKpiItem) => any;
}
const KipsEditModal = (props: IProps) => {
    const kipsItem: IKpiItem[] = props.kips?.filter(l => l.id === props.kipsId).map(m => {
        return {
            id: m.id,
            nameEn: m.nameEn,
            nameDe: m.nameDe,
            dataCollectionRoles: m.dataCollectionRoles,
            questions: [],
        }
    }) ?? [];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            let values = await form.getFieldsValue();
            await props.updateKpis({
                id: kipsItem[0].id,
                nameEn: values?.nameEn,
                nameDe: values?.nameDe,
                dataCollectionRoles: values?.dataCollectionRoles,
                questions: [],
            });

        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        setIsModalVisible(false);
        form.resetFields();
    }
    return (
        <ModalAdmin title={`${t("Edit")} Kips`} btnText={`${t("Edit")}`}
            width="520px"
            modalResponse={props.modalResponse}
            closeAndReset={closeAndReset}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            footerchildren={[
                <Button key="back" onClick={closeAndReset}>
                    {`${t("Cancel")}`}
                </Button>,
                <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                    {`${t("Save & Send")}`}
                </Button>
            ]}>
            <Form layout="vertical" form={form} initialValues={{ ...kipsItem[0] }}>
                <Form.Item label={`${t("English Name")}`} name='nameEn'
                    rules={RulesName({ name: `${t("English Name")}`, countChar: 128 })}>
                    <Input />
                </Form.Item>
                <Form.Item label={`${t("German Name")}`} name='nameDe'
                    rules={RulesName({ name: `${t("German Name")}`, countChar: 128 })}>
                    <Input />
                </Form.Item>
                <Form.Item label={`${t("Data Collection Roles")}`} name='dataCollectionRoles'
                    rules={RulesRequire({ filedName: 'Data Collection Roles' })}>
                    <Checkbox.Group options={props.collectionRoles && props.collectionRoles}
                    />
                </Form.Item>
            </Form>
        </ModalAdmin>
    )
}
const mapStateToProps = (state: any) => {
    return {

        collectionRoles: state.generalInfo.profileResponse?.generalSettings?.collectionRoles,

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateKpis: (model: IKpiItem) => dispatch({ type: ActionTypes.UPDATE_KPIS_FOR_ADMIN, model }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KipsEditModal);