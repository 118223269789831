import { Button, Checkbox, Spin, Tooltip } from "antd";
import './allchartstopbar.scss';
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { RollbackOutlined, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

interface IProps {
    checkAll: boolean;
    selectedCharts: string[];
    finishedDownload: () => void;
    onCheckAllChange: (checked: boolean) => void;

    backBtnTitle: string;
    isBackBtnVisible: boolean;
    goBack: () => void;
    exportSelectedChartsToExcel: () => void,
    exportAllChartsToExcel: () => void,
    exportingSelectedChartsToExcel: boolean,
    exportingAllChartsToExcel: boolean,
    DownloadAllChartsAsImage: () => void,
};



const AllChartsTopBar = (props: IProps) => {

    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [downloadedChartsCount, setDownloadedChartsCount] = useState(0);

    useEffect(() => {
        if (downloadedChartsCount === props.selectedCharts.length) {
            setDownloadInProgress(false);
            setDownloadedChartsCount(0);
        }
    }, [downloadedChartsCount]);


    const exportAllChartsToExcel = () => {
        props.exportAllChartsToExcel();
    };

    const exportSelectedChartsToExcel = () => {
        props.exportSelectedChartsToExcel();
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        props.onCheckAllChange(e.target.checked);
    };

    return (
        <div className='AllChartsTopBar-container'>
            <div className="return-back-div">
                {props.isBackBtnVisible ? <Button
                    size='small'
                    style={{ 'alignSelf': 'center', 'backgroundColor': 'inherit' }}
                    type="primary"
                    shape="default"
                    icon={<RollbackOutlined style={{ 'color': 'black' }} />}
                    title={props.backBtnTitle}
                    onClick={props.goBack} /> : null}
            </div>
            <div className="download-checkall_div">
                <div className="downloadall-div">
                    {props.exportingSelectedChartsToExcel ?
                        <Spin size="small" /> :
                        <Button className='chart-download-Btn'
                            icon={<FileExcelOutlined style={{ 'margin': 'auto' }} />}
                            onClick={exportSelectedChartsToExcel}
                            type="default"
                            title="Export selected charts to excel."
                            disabled={(props.selectedCharts.length === 0)}
                            size='small'
                        />
                    }
                </div>
                <div className="downloadall-div">
                    {props.exportingAllChartsToExcel ?
                        <Spin size="small" /> :
                        <Button className='chart-download-Btn'
                            icon={<FileExcelOutlined style={{ 'margin': 'auto' }} />}
                            onClick={exportAllChartsToExcel}
                            type="default"
                            title="Export All charts to excel."
                            size='small'
                        />
                    }
                </div>
                <div className="downloadall-div">
                    {downloadInProgress ? <Spin size="small" /> :
                        <Button className='chart-download-Btn'
                            icon={<DownloadOutlined style={{ 'margin': 'auto' }} />}
                            onClick={props.DownloadAllChartsAsImage}
                            type="default"
                            title="Download selected charts"
                            disabled={(props.selectedCharts.length === 0)}
                            size='small'
                        />
                    }
                </div>
                <div className="checkall-div" title={props.checkAll ? "Unselect all" : "Select all"}>
                    <Checkbox
                        style={{ 'margin': 'auto' }}
                        onChange={onCheckAllChange}
                        checked={props.checkAll} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        exportingSelectedChartsToExcel: state.leverTool.exportingSelectedChartsToExcel,
        exportingAllChartsToExcel: state.leverTool.exportingAllChartsToExcel
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllChartsTopBar);