import { takeEvery, put, all } from "redux-saga/effects";
import { ICommonResponse } from "../../Models/Responses/ICommonResponse";
import { ToastMessages } from "../../Enums/Messages";
import { ActionTypes } from "../actionsTypes";
import EventLogPageForAdminService from "../../Services/EventLogForAdminService";
import { IAllEventLogs, IEventLogFilterMenus } from "../../Models/IEventLogForAdmin";
import moment from "moment";
import i18n from "../../i18n";

function* OnGetAllEventLog(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_EVENT_LOGS_LOADER, isLoading: true });
        let _result: ICommonResponse = yield EventLogPageForAdminService.GetAllEventLog(
            {
                pageIndex: action.model?.pageIndex ?? 0,
                pageLength: action.model?.pageLength ?? 50,
                eventTypeIds: action.model?.eventTypeIds ?? [],
                eventLevelIds: action.model?.eventLevelIds ?? [],
                entityTypeIds: action.model?.entityTypeIds ?? [],
                auditTypeIds: action.model?.auditTypeIds ?? [],
                startDate: action.model?.startDate ??  moment().locale(i18n.language == 'de' ? 'de' : 'en').startOf('week').format('L'),
                endDate: action.model?.endDate ??  moment().locale(i18n.language == 'de' ? 'de' : 'en').endOf('week').format('L'),
            }
        )
        if (!_result.isError) {
            let result = _result.result as IAllEventLogs;
            yield put({ type: ActionTypes.SAVE_EVENT_LOGS, result: result });
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: _result })
        }
        yield put({ type: ActionTypes.CHANGE_EVENT_LOGS_LOADER, isLoading: false });
    } catch (error) {
        console.error('ERR in onGet EventLogs: ', error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: { isError: true, statusCode: 600, error: { exceptionMessage: ToastMessages.UnExpectedError } } as ICommonResponse
        });
    }
}

function* onGetFilter(action: any) {
    try {
        yield put({ type: ActionTypes.CHANGE_EVENT_LOGS_FILTERS_LOADER, isLoading: true });
        let resultFilter: ICommonResponse = yield EventLogPageForAdminService.getEventLogAllFilterMenus();
        if (!resultFilter.isError) {
            // yield put({ type: ActionTypes.SetCommonResponse, result: resultFilter });
            let result = resultFilter.result as IEventLogFilterMenus;
            yield put({ type: ActionTypes.SAVE_EVENT_LOGS_FILTERS, result: result })
        } else {
            yield put({ type: ActionTypes.SetCommonResponse, result: resultFilter });
        }
        yield put({ type: ActionTypes.CHANGE_EVENT_LOGS_FILTERS_LOADER, isLoading: false });
    } catch (error) {
        console.error("ERR in onGetFilters: ", error);
        yield put({
            type: ActionTypes.SetCommonResponse,
            result: {
                isError: true,
                statusCode: 600,
                error: { exceptionMessage: ToastMessages.UnExpectedError },
            } as ICommonResponse,
        });
    }
}

function* watchGetAllEventLog() {
    yield takeEvery(ActionTypes.GET_ALL_EVENT_LOGS, OnGetAllEventLog)
}
function* watchGetFilter() {
    yield takeEvery(ActionTypes.GET_FILTER_EVENT_LOGS_MENUS, onGetFilter)
}

export default function* EventLogsForAdminSagas() {
    yield all([
        watchGetAllEventLog(),
        watchGetFilter()
    ])
}