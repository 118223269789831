import { Button, Popconfirm, Space, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import React, { useRef, useState } from 'react';
import { IDisciplineItemWithKey } from '../../../Models/IDiscipline'
import { connect } from 'react-redux';
import { ActionTypes } from '../../../Redux/actionsTypes';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getColumnSearchProps } from '../../../Components/SearchButton';
import DisciplineEditModal from './DisciplineEditModal';
import { t } from 'i18next';

interface IProps {
    dataDisciplines: IDisciplineItemWithKey[],
    deleteDiscipline: (id: number) => any;
}

const DisciplineTable = (props: IProps) => {
    //search code start
    const searchInput = useRef<any>(null);
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };
    //search code end
    const columns: any[] = [
        {
            key: "discipline_key_4",
            title: `${t("View Order")}`,
            dataIndex: 'viewOrder',
            width: "7%",
            render: (text: any, record: any) => <Typography>{text}</Typography>,
            sorter: (a, b) => a?.viewOrder > b?.viewOrder,
            defaultSortOrder: 'ascend',
        },
        {
            key: "discipline_key_1",
            title: `${t("English Name")}`,
            dataIndex: 'nameEn',
            width: "10%",
            render: (text: any, record: any) => <Typography>{text}</Typography>,
            ...getColumnSearchProps(`${t("English Name")}`, 'nameEn', searchInput, handleSearch),
            sorter: (a, b) => a?.nameEn?.localeCompare(b?.nameEn) ?? 0,
            //defaultSortOrder: 'ascend',
        },
        {
            key: "discipline_key_2",
            title: `${t("German Name")}`,
            dataIndex: 'nameDe',
            width: "10%",
            render: (text: any, record: any) => <Typography>{text}</Typography>,
            ...getColumnSearchProps(`${t("German Name")}`, 'nameDe', searchInput, handleSearch),
            sorter: (a, b) => a?.nameDe?.localeCompare(b?.nameDe) ?? 0,
            // defaultSortOrder: 'ascend',
        },
        {
            key: "discipline_key_3",
            title: `${t("Parent")}`,
            dataIndex: 'Parent',
            width: "10%",
            render: (text: any, record: any) => {
                return <Typography>{props.dataDisciplines?.find(d => d?.id === record?.parent)?.nameDe}</Typography>
            },
        },

        {
            key: "discipline_key_5",
            title: `${t("Background Color")}`,
            dataIndex: 'backgroundColor',
            width: "7%",
            render: (text: any, record: any) => {
                return <div style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '5px',
                    background: text,
                    marginLeft: "auto",
                    marginRight: "auto",
                    border: "0.5px solid #ccc"
                }}>
                </div >
            },

        },
        {
            key: "discipline_key_6",
            title: `${t("Text Color")}`,
            dataIndex: 'forgroundColor',
            width: "7%",
            render: (text: any, record: any) => {
                return <div style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '5px',
                    background: text,
                    marginLeft: "auto",
                    marginRight: "auto",
                    border: "0.5px solid #ccc"
                }}>
                </div>
            },
        },
        {
            key: "discipline_key_7",
            title: `${t("is Original")}`,
            dataIndex: 'isOriginal',
            width: "7%",
            render: (text: any, record: any) => <Typography>{text ? `${t("Yes")}` : `${t("No")}`}</Typography>
        },
        {
            key: "discipline_key_8",
            title: `${t("English Description")}`,
            dataIndex: 'descriptionEn',
            render: (text: any, record: any) => <Typography>{text}</Typography>,
        },
        {
            key: "discipline_key_9",
            title: `${t("German Description")}`,
            dataIndex: 'descriptionDe',
            render: (text: any, record: any) => <Typography>{text}</Typography>,
        },
        {
            title: `${t("Actions")}`,
            dataIndex: '',
            width: "12%",
            render: (_: any, record: any) => {

                return <Space direction="vertical" >
                    <DisciplineEditModal dataDiscipline={record} />
                    <Popconfirm title={`${t("Sure to Delete")}`}
                        onConfirm={() => deleteDiscipline(record.id)}
                        getPopupContainer={() => document.getElementById("app-layout")}
                        cancelText={`${t("Cancel")}`}
                    >
                        <Button type="link">
                            <DeleteOutlined style={{ fontSize: " 20px", cursor: "pointer", color: "red" }} />
                            {`${t("Delete")}`}
                        </Button>
                    </Popconfirm>
                </Space>
            },
        }
    ];

    const deleteDiscipline = async (id: any) => {
        try {
            await props.deleteDiscipline(id);
        } catch (error) {
            console.log('delete Failed:', error);
        }
    }
    const [totalDataSourceLength, setTotalDataSourceLength] = useState(props.dataDisciplines?.length)
    return (
        <Table
            key='disciplines_table'
            getPopupContainer={() => document.getElementById("app-layout")}
            bordered
            dataSource={props.dataDisciplines}
            columns={columns}
            rowClassName="editable-row"
            scroll={{ y: "calc(100vh - 300px)" }}
            pagination={{
                defaultPageSize: 50,
                pageSizeOptions: [50, 100, 150],
                total: totalDataSourceLength,
                showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
                showSizeChanger: true,
                locale: { items_per_page: `/ ${t("Page")}` },
            }}
            locale={{
                triggerDesc: t('Click to sort descending'),
                triggerAsc: t('Click to sort ascending'),
                cancelSort: t('Click to cancel sorting')
            }}
            onChange={function (pagination, filters, sorter, extra: { currentDataSource: [], action: any }) {
                return setTotalDataSourceLength(extra.currentDataSource.length);
            }}
        />);
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteDiscipline: (id: number) => dispatch({ type: ActionTypes.DELETE_DISCIPLINE, id }),
    };
};

export default connect(null, mapDispatchToProps)(DisciplineTable);