import { Button, Form, Input, Select, Spin } from "antd";
import { t } from "i18next";
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import ModalAdmin from "../../../../Components/ModalAdmin/ModalAdmins";
import { IConsultantUser, IConsultantUserNew, IConsultantUserWithKey } from "../../../../Models/IConsultantUsers";
import { ICommonResponse } from "../../../../Models/Responses/ICommonResponse";
import { ActionTypes } from "../../../../Redux/actionsTypes";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import ConsultantUsersTable from "./ConsultantUsersTable";

interface IProps {
    consultantUsers: IConsultantUser[];
    isLoading: boolean;
    btnTitle: string;
    titles: any[];
    languagesList: any[];
    isModalLoading: boolean;
    modalResponse: ICommonResponse;
    getConsultantUsers: () => any;
    createConsultantUser: (model: IConsultantUserNew) => any;
    getResendActivationLink: (userId: number) => any;
}
const ConsultantUsers = (props: IProps) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<IConsultantUserNew>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => { props.getConsultantUsers() }, []);
    const consultantUserData: IConsultantUserWithKey[] = props.consultantUsers?.map(co => {
        return { ...co, key: `consultant_${co.id}`, defaultLanguageId: co.languageId }
    }) ?? [];
    const formValuesHandler = async () => {
        try {
            await form.validateFields();
            const newConsultant = { ...formValues };
            await props.createConsultantUser(newConsultant);
        } catch (error) {
            console.log(error);
        }
    }
    const closeAndReset = () => {
        form.resetFields();
        setIsModalVisible(false);
    }
    return (
        <Spin spinning={props.isLoading || props.isModalLoading}>
            <div className="admin-component">
                <ModalAdmin title="Add Consultant info" btnText="Add Consultant"
                    width="520px"
                    modalResponse={props.modalResponse}
                    closeAndReset={closeAndReset}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    footerchildren={[
                        <Button key="back" onClick={closeAndReset}>
                            {`${t("Close")}`}
                        </Button>,
                        <Button key="submit" type="primary" loading={props.isModalLoading} onClick={() => formValuesHandler()}>
                            {`${t("Save & Send")}`}
                        </Button>
                    ]}>
                    <Form
                        layout="vertical"
                        form={form}

                    >
                        <Form.Item label="FirstName" name='firstName' rules={[
                            {
                                required: true,
                                message: "Please enter your first name"
                            }
                        ]} hasFeedback>
                            <Input onChange={(e) => setFormValues({ ...formValues, firstName: e.target.value })} />
                        </Form.Item>
                        <Form.Item label="LastName" name='lastName' rules={[
                            {
                                required: true,
                                message: "Please enter your last name"
                            }
                        ]} hasFeedback>
                            <Input onChange={(e) => setFormValues({ ...formValues, lastName: e.target.value })} />
                        </Form.Item>
                        <Form.Item name='email' label="E-mail" rules={[
                            {
                                required: true,
                                message: "Please enter your mail"
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                                        return Promise.reject('Please enter valid Email');
                                    }
                                    return Promise.resolve();
                                }
                            })
                        ]} hasFeedback>
                            <Input onChange={(e) => setFormValues({ ...formValues, email: e.target.value })} />
                        </Form.Item>
                        <Form.Item name='titleId' rules={[{ required: true, message: "Please enter Title" }]} style={{ width: '50%', display: "inline-block" }} >
                            <Select showSearch placeholder="Select Title" optionFilterProp="children"
                                onChange={(value: number) => { setFormValues({ ...formValues, titleId: value }) }} >
                                {props.titles?.map((t, index) => <Select.Option value={t.value} key={index}>{t.label}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item name='defaultLanguageId' rules={[{ required: true, message: "Please enter language" }]} style={{ width: '50%', display: "inline-block" }} >
                            <Select showSearch placeholder="Select language" optionFilterProp="children"
                                onChange={(value: number) =>
                                    setFormValues({ ...formValues, defaultLanguageId: value })

                                }
                            >
                                {props.languagesList?.map((d: any) => <Select.Option value={d.value} key={d.value}>{d.label}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Phone" name="phoneNumber" rules={[
                            {
                                required: true,
                                message: "Please enter Phone Number"
                            }
                        ]} hasFeedback>
                            <Input onChange={(e) => setFormValues({ ...formValues, phoneNumber: e.target.value })} />
                        </Form.Item>
                    </Form>
                </ModalAdmin>
                <ConsultantUsersTable
                    consultantUsers={consultantUserData}
                    titlesData={props.titles}
                    languageListData={props.languagesList}
                    isModalLoading={props.isModalLoading}
                    modalResponse={props.modalResponse}
                />
            </div >
        </Spin>
    )
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        isLoading: state.consultantUsers.isConsultantLoading,
        consultantUsers: state.consultantUsers.consultantUsersResult,
        titles: state.generalInfo.profileResponse?.generalSettings?.titles,
        languagesList: state.generalInfo.profileResponse?.generalSettings?.languageList,
        isModalLoading: state.consultantUsers.isModalLoading,
        modalResponse: state.consultantUsers.modalResponse,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getConsultantUsers: () => dispatch({ type: ActionTypes.GET_CONSULTANT_USERS }),
        createConsultantUser: (model: IConsultantUserNew) => dispatch({ type: ActionTypes.CREATE_CONSULTANT_USER, model }),
        getResendActivationLink: (userId: number) => dispatch({ type: ActionTypes.GET_Resend_Activation_Link, userId }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantUsers);
