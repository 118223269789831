import './chartdetails.scss';


interface IProps {
    Title: string;
    Description: string;
    Unit: string;
    textColor: string;
};


const ChartDetails = (props: IProps) => {


    return (
      <div className="chart-details" title={props.Title}>
        {/* style={{ color: `${props.textColor ? props.textColor : "black"}` }} */}
        <div className="chart-title">{props.Title}</div>
        <div
          className="chart-description"
          style={{ color: `${props.textColor}` }}
          title={props.Description}
        >
          {props.Description}
        </div>
        <div className="chart-unit" style={{ color: `${props.textColor}` }}>
          {props.Unit}
        </div>
      </div>
    );

};


export default ChartDetails;