import {
  Button,
  Form,
  Input,
  Modal,
  Table,
  Popconfirm,
  Tooltip,
  Spin,
  Space,
  InputNumber,
} from "antd";
import {
  QuestionCircleOutlined,
  CaretDownOutlined,
  CaretRightOutlined
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import EditModal from "./Modals/EditModal";
import { Children, useCallback, useEffect, useState, useRef } from "react";
import "./index.scss";
import TableTwoColumns from "../TableTwoColumns";
import { Col, Row } from "antd";
import { BmProject } from "../../Models/Responses/ProfileResponse";
import RnDUnitValueInRow, {
  RndInfoResponse,
  RowType,
  RowTypeValue,
} from "../../Scenes/Tools/EBITTool-V2/Models/RndInfoResponse";
import { connect } from "react-redux";
import { ActionTypes } from "../../Redux/actionsTypes";
import { IGeneralState } from "../../Redux/reducers/IGeneralState";
import {
  getBasicColoredTableHeader,
  getBasicColoredTableHeaderChangable,
} from "./EbitHeaders";
import {
  rndCostType,
  RnDUnit,
  rnDUnitDto,

} from "../../Scenes/Tools/EBITTool-V2/Models/RnDUnit";
import RnDUnitCost from "../../Scenes/Tools/EBITTool-V2/Models/RnDUnitCost";
import Discipline from "../../Scenes/Tools/EBITTool-V2/Models/Discipline";
import { useTranslation, Trans } from "react-i18next";
import { inputFormatter, inputParser, formatValue } from "../../Utils/helperFunctions";
import i18n from "../../i18n";
import AddModal from "./Modals/AddModal";
import { ProfileResponse } from "../../Models/Responses/ProfileResponse";
import { usePrompt } from "../../Scenes/Tools/EBITTool-V2/BasicCharts/PromptAndBlocker";
// import _ from 'lodash';
// // import { isEqual } from "react-lodash";
import isEqual from 'lodash.isequal'
import { debounce } from 'lodash';

interface IProps {
  data: RndInfoResponse;
  recievedRndUints: RnDUnit[];
  currentProject: BmProject;
  projects: BmProject[];
  localRows: RowTypeValue[];
  isEbitLoading: boolean;
  setEbitUnitsRows: any;
  createNewUnit: any;
  UpdateRnDUnit: any;
  deleteUnit: any;
  SaveUnitCostsChanges: any;
  benchmarkProjectsLoader: boolean;
  profileResponse: ProfileResponse;
  downloadRDTableExcel: (projectID: number, projectName: string) => any;
}


interface LocalInputMonitor {
  inputValue: number;
  isChanged: boolean;
}

const columnsOne = [
  {
    title: "",
    dataIndex: "first",
    // render: (value) => renderRow(value.toString()),
    // onCell: (value) => renderRow(value.toString()),
    // width: "25%",
  },
  {
    title: "",
    dataIndex: "second",
    // width: "10%",
  },
  {
    title: "",
    dataIndex: "third",
    // width: "10%",
  },
  {
    title: "",
    dataIndex: "fourth",
    // width: "10%",
  },
  {
    title: "",
    dataIndex: "fifth",
    // width: "10%",
  },
  {
    title: "",
    dataIndex: "sixth",
    // width: "10%",
  },
  {
    title: "",
    dataIndex: "seventh",
    // width: "10%",
  },
  {
    title: "",
    dataIndex: "eighth",
    // width: "10%",
  },
  {
    title: "",
    dataIndex: "ninth",
    // width: "10%",
  },
  {
    title: "",
    dataIndex: "tenth",
    // width: "10%",
  },
];



function ColoredTable(props: IProps) {
  // Statfull variables for R&D summery table
  // const [totalCostsAllDisciplines, SetTotalCostsAllDisciplines] = useState(0);
  // const [origrnalDisciplinesCosts, SetOrigrnalDisciplinesCosts] = useState(0);
  // const [interdiscDisciplinesCosts, SetInterdiscDisciplinesCosts] = useState(0);
  // const [origrnalDisciplinesRate, SetOrigrnalDisciplinesRate] = useState(0);
  // const [interdiscDisciplinesRate, SetInterdiscDisciplinesRate] = useState(0);
  // const [proportionOfExternalCosts, SetProportionOfExternalCosts] = useState(0);
  const [BMPrpjectsAvgRndRate, setBMPrpjectsAvgRndRate] = useState(0);
  // const [form] = Form.useForm();
  //const [currentInputChanged, setCurrentInputChanged] = useState<LocalInputMonitor>({ inputValue: 0, isChanged: false })
  const [localRndUnits, setLocalRndUnits] = useState([] as RnDUnit[]);
  const { t } = useTranslation();
  // a state where to observe the R&D (inputs) state
  const [formState, setFormState] = useState<"unchanged" | "modified" | "saving">("unchanged");

  // Show an exit prompt if the formState is NOT
  // unchanged (modified or being saved) to prevent losing enterd values.
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };
    if (formState !== "unchanged") {
      window.addEventListener("beforeunload", handler);
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    return () => { };
  }, [formState]);

  useEffect(() => {
    if (props.data) {
      let columns = getBasicColoredTableHeaderChangable(
        props.data.rnDUnits,
        toggleUpdateModal,
        deleteUnit,
        saveChanges,
        addNewUnit,
        onExport
      );
      setColumns(columns);
      let rowsValuesTemp: RowTypeValue[] = fillRowsInValue(
        props.data.disciplines,
        props.data.rnDUnits
      );
      setLocalRndUnits(props.data.rnDUnits);

      let newXScroll = xScroll;
      newXScroll.x = 50 + 300 * props.data.rnDUnits.length;
      setXScroll(newXScroll);
      props.setEbitUnitsRows(rowsValuesTemp);
      // form.setFieldsValue({ "new_unit_name": "", "new_unit_gesamt": null, "new_unit_avg": null });
    }
  }, [props.data, i18n.language]);

  const onExport = () => {
    props.downloadRDTableExcel(
      props.profileResponse.currentProject.id,
      props.profileResponse.currentProject.project_name
    );
  };
  const addNewUnit = () => {
    setIsAddModalVisible(true);
  };
  function _toString(value: number, currency: string = "") {
    try {
      if (Number.isNaN(value)) {
        value = 0;
      }
      let valueNumber = new Number(value);
      let tempVal = "";
      if (valueNumber && valueNumber != 0) {
        tempVal = valueNumber.toFixed(2);
      } else {
        tempVal = valueNumber.toFixed(3);
      }
      return `${t("localizedvalue", { count: parseFloat(tempVal), })}${currency}`;
    } catch (e) {
      //console.log("_toString", value, e);
      return value;
    }
  }


  const initialColumns = getBasicColoredTableHeader();

  function calculatePercentCell(
    currentDescipline: Discipline,
    disciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    if (currentDescipline.parentDisciplineId) {
      //has parent
      let parentDiscipline = disciplines.find(
        (c) => c.id == currentDescipline.parentDisciplineId
      );
      temp =
        calculateGesamtCell(currentDescipline, units) /
        calculateGesamtCell(parentDiscipline, units);
    } else {
      temp =
        calculateGesamtCell(currentDescipline, units) /
        calculateTotalAllDisciplinesCosts(disciplines, units);
    }
    return (temp ?? 0) * 100;
  }

  function calculateFteInternCell(descipline: Discipline, units: RnDUnit[]) {
    let temp = 0;
    let tempSum = 0;
    if (
      descipline.childrenDisciplines &&
      descipline.childrenDisciplines.length > 0
    ) {
      descipline.childrenDisciplines.map((disc) => {
        units.map((unit) => {
          tempSum += unit.rDCosts.find(
            (c) => c.disciplineId == disc.id
          )?.fullTimeEquivalent ?? 0;
        });
      });
    } else {
      units.map((unit) => {
        tempSum += unit.rDCosts.find(
          (c) => c.disciplineId == descipline.id
        )?.fullTimeEquivalent ?? 0;
      });
    }
    temp = tempSum;
    return temp;
  }

  function calculateGesamtCell(descipline: Discipline, units: RnDUnit[]) {
    let temp = 0;
    let tempSum = 0;
    if (
      descipline.childrenDisciplines &&
      descipline.childrenDisciplines.length > 0
    ) {
      descipline.childrenDisciplines.map((disc) => {
        units.map((unit) => {
          tempSum +=
            (unit.rDCosts.find((c) => c.disciplineId == disc.id)
              ?.fullTimeEquivalent ?? 0) *
            unit.averageAnnualCost +
            (unit.rDCosts.find((c) => c.disciplineId == disc.id)
              ?.externalAnnualCost ?? 0);
        });
      });
    } else {
      units.map((unit) => {
        tempSum +=
          (unit.rDCosts.find((c) => c.disciplineId == descipline.id)
            ?.fullTimeEquivalent ?? 0) *
          unit.averageAnnualCost +
          (unit.rDCosts.find((c) => c.disciplineId == descipline.id)
            ?.externalAnnualCost ?? 0);
      });
    }
    temp = tempSum;
    return temp;
  }
  function calculatePkInternCell(descipline: Discipline, units: RnDUnit[]) {
    let temp = 0;
    let tempSum = 0;
    if (
      descipline.childrenDisciplines &&
      descipline.childrenDisciplines.length > 0
    ) {
      descipline.childrenDisciplines.map((disc) => {
        units.map((unit) => {
          tempSum +=
            (unit.rDCosts.find((c) => c.disciplineId == disc.id)
              ?.fullTimeEquivalent ?? 0) * (unit?.averageAnnualCost ?? 0);
        });
      });
    } else {
      units.map((unit) => {
        tempSum +=
          (unit.rDCosts.find((c) => c.disciplineId == descipline.id)
            ?.fullTimeEquivalent ?? 0) * (unit?.averageAnnualCost ?? 0);
      });
    }
    temp = tempSum;
    return temp;
  }
  function calculatePkExternCell(descipline: Discipline, units: RnDUnit[]) {
    let temp = 0;
    let tempSum = 0;
    if (
      descipline.childrenDisciplines &&
      descipline.childrenDisciplines.length > 0
    ) {
      descipline.childrenDisciplines.map((disc) => {
        units.map((unit) => {
          tempSum +=
            unit.rDCosts.find((c) => c.disciplineId == disc.id)
              ?.externalAnnualCost ?? 0;
        });
      });
    } else {
      units.map((unit) => {
        tempSum +=
          unit.rDCosts.find((c) => c.disciplineId == descipline.id)
            ?.externalAnnualCost ?? 0;
      });
    }
    temp = tempSum;
    return temp;
  }
  function calculateTotalAllDisciplinesExtCosts(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    let tempSum = 0;
    desciplines.map((descipline) => {
      tempSum += calculatePkExternCell(descipline, units);
    });
    temp = tempSum;
    return temp;
  }

  function calculateTotalAllDisciplinesInternCosts(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    let tempSum = 0;
    desciplines.map((descipline) => {
      tempSum += calculatePkInternCell(descipline, units);
    });
    temp = tempSum;
    return temp;
  }

  function calculateTotalAllDisciplinesCosts(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    let tempSum = 0;
    desciplines.map((descipline) => {
      tempSum += calculateGesamtCell(descipline, units);
    });
    temp = tempSum;
    return temp;
  }
  function calculateTotalAllOriginalDisciplinesCosts(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    let tempSum = 0;
    desciplines.map((descipline) => {
      if (descipline.costTypeIsOriginalNotInterdisciplinary) {
        tempSum += calculateGesamtCell(descipline, units);
      }
    });
    temp = tempSum;
    return temp;
  }

  function calculateTotalAllInterdiscDisciplinesCosts(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    let tempSum = 0;
    desciplines.map((descipline) => {
      if (!descipline.costTypeIsOriginalNotInterdisciplinary) {
        tempSum += calculateGesamtCell(descipline, units);
      }
    });
    temp = tempSum;
    return temp;
  }

  function calculateProportionOfExternalRate(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    let currentTotalAllDisciplinesExtCosts =
      calculateTotalAllDisciplinesExtCosts(desciplines, units);
    let currentTotalAllDisciplinesInternCosts =
      calculateTotalAllDisciplinesInternCosts(desciplines, units);
    temp =
      currentTotalAllDisciplinesExtCosts /
      currentTotalAllDisciplinesInternCosts;
    return temp * 100;
  }

  function calculateInterdiscDisciplinesRate(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    let currentInterdiscDisciplinesCosts =
      calculateTotalAllInterdiscDisciplinesCosts(desciplines, units);
    let currentTotalAllDisciplinesCosts = calculateTotalAllDisciplinesCosts(
      desciplines,
      units
    );
    temp = currentInterdiscDisciplinesCosts / currentTotalAllDisciplinesCosts;
    return temp * 100;
  }

  function calculateOrginalDisciplinesRate(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let temp = 0;
    let currentInterdiscDisciplinesCosts =
      calculateTotalAllOriginalDisciplinesCosts(desciplines, units);
    let currentTotalAllDisciplinesCosts = calculateTotalAllDisciplinesCosts(
      desciplines,
      units
    );
    temp = currentInterdiscDisciplinesCosts / currentTotalAllDisciplinesCosts;
    return temp * 100;
  }

  function calculateRDpotentialRate(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let rv =
      calculateTotalAllDisciplinesCosts(desciplines, units) /
      props.currentProject.sales -
      props.currentProject.bpRdShare;
    return rv >= 0 ? rv * 100 : 0;
  }

  function calculateRDPotentialCost(
    desciplines: Discipline[],
    units: RnDUnit[]
  ) {
    let rv =
      calculateRDpotentialRate(desciplines, units) * props.currentProject.sales / 100;
    return rv;
  }

  function fillRowsInValue(disciplines: Discipline[], rnDUnits: RnDUnit[]) {
    let rowsValuesTemp: RowTypeValue[] = disciplines
      .sort((a, b) => (a.viewOrder > b.viewOrder ? 1 : -1))
      .map((d, i) => {
        let subDiscs = d.childrenDisciplines
          .sort((a, b) => (a.viewOrder > b.viewOrder ? 1 : -1))
          .map((child, j) => {
            return {
              title: i18n.language === 'de' ? child.code : child.englishCode,
              tooltip: i18n.language === 'de' ? d.tooltip : d.englishTooltip,
              foreColor: d.foreColor,
              backgroundColor: d.backgroundColor,
              isSubRow: true,
              percent: calculatePercentCell(child, disciplines, rnDUnits),
              fte_intern: calculateFteInternCell(child, rnDUnits),
              gesamt: calculateGesamtCell(child, rnDUnits),
              pk_intern: calculatePkInternCell(child, rnDUnits),
              pk_extern: calculatePkExternCell(child, rnDUnits),
              key: `child_row_${child.id}`,
              id: child.id,
              viewOrder: child.viewOrder,
              //color: d.backgroundColor,
              costTypeIsOriginalNotInterdisciplinary:
                child.costTypeIsOriginalNotInterdisciplinary,
              unitInRow: rnDUnits.map((tt) => {
                let tempV: RnDUnitValueInRow = {
                  unit: tt,
                  disciplineId: child.id,
                  fullTimeEquivalent:
                    tt.rDCosts.find((c) => c.disciplineId == child.id)
                      ?.fullTimeEquivalent ?? 0,
                  externalAnnualCost:
                    tt.rDCosts.find((c) => c.disciplineId == child.id)
                      ?.externalAnnualCost ?? 0,
                };
                return tempV;
              }),
              children: null,
            };
          });
        return {
          title: i18n.language === 'de' ? d.code : d.englishCode,
          tooltip: i18n.language === 'de' ? d.tooltip : d.englishTooltip,
          foreColor: d.foreColor,
          backgroundColor: d.backgroundColor,
          isSubRow: false,
          percent: calculatePercentCell(d, disciplines, rnDUnits),
          fte_intern: calculateFteInternCell(d, rnDUnits),
          gesamt: calculateGesamtCell(d, rnDUnits),
          pk_intern: calculatePkInternCell(d, rnDUnits),
          pk_extern: calculatePkExternCell(d, rnDUnits),
          key: `main_row_${d}`,
          id: d.id,
          viewOrder: d.viewOrder,
          costTypeIsOriginalNotInterdisciplinary:
            d.costTypeIsOriginalNotInterdisciplinary,
          unitInRow: rnDUnits.map((tt) => {
            let tempV: RnDUnitValueInRow = {
              unit: tt,
              disciplineId: d.id,
              fullTimeEquivalent:
                tt.rDCosts.find((c) => c.disciplineId == d.id)
                  ?.fullTimeEquivalent ?? 0,
              externalAnnualCost:
                tt.rDCosts.find((c) => c.disciplineId == d.id)
                  ?.externalAnnualCost ?? 0,
            };
            return tempV;
          }),
          children: subDiscs && subDiscs.length !== 0 ? subDiscs : null,
        };
      });
    return rowsValuesTemp;
  }
  const heute = (
    <>
      {t("F&E")}
      <sub> {t("Originär")} </sub>
      / {t("F&E-Kosten Gesamt")}
    </>
  );
  const heute1 = (
    <>
      {t("F&E")}
      <sub> {t("Interdisziplinär")} </sub>
      / {t("F&E-Kosten Gesamt")}
    </>
  )

  // const subText = ()=> {<sub>heute</sub>};
  const rowsOne = [
    {
      first: (
        <Tooltip placement="bottomRight" title={t("Absolutes EBIT-Potenzial")}>
          {t("EBIT-Potenzial")}
          <sub>{t("F&E")}</sub>
          {`[€]`}
          {/* <Trans components={{ sub: <sub /> }} title={heute}>
          EBIT-Potenzial
                  </Trans> */}
        </Tooltip>
      ),
      second: (
        <b>
          {formatValue(calculateRDPotentialCost(props.data.disciplines, localRndUnits))}€
        </b>
      ),
      third: (
        <Tooltip placement="bottomRight" title={t("Summe Disziplinen")}>
          {t("F&E-Kosten Gesamt")}
        </Tooltip>
      ),
      fourth: formatValue(
        calculateTotalAllDisciplinesCosts(
          props.data.disciplines,
          localRndUnits
        )) + "€",
      //fourth: _toString(totalCostsAllDisciplines),
      fifth: (
        <Tooltip
          placement="bottomRight"
          title={`Best Practice ${t("F&E-Rate")}`}
        >
          {t("F&E-Rate")}
          <sub>BP</sub>
        </Tooltip>
      ),
      /* sixth: <Spin spinning={props.benchmarkProjectsLoader}>{formatValue(Number(BMPrpjectsAvgRndRate)) + "%"}</Spin> , */
      sixth: formatValue(Number(props.currentProject.bpRdShare * 100)) + "%",
      seventh: (
        <Tooltip placement="bottomRight" title={t("Summe Originäre")}>
          {t("F&E")}
          <sub>{t("Originär")}</sub>
        </Tooltip>
      ),
      eighth: formatValue(
        calculateTotalAllOriginalDisciplinesCosts(
          props.data.disciplines,
          localRndUnits
        )) + "€",
      ninth: (
        <Tooltip placement="bottomRight" title={heute}>
          {t("Anteil origin. Kosten")}
        </Tooltip>

      ),
      tenth: formatValue(
        calculateOrginalDisciplinesRate(props.data.disciplines, localRndUnits)) + "%",

      key: "rnd_row_1",
    },
    {
      first: (
        <Tooltip
          placement="bottomRight"
          title={t("Differenz zur Best Practice F&E-Rate")}
        >
          {t("EBIT-Potenzial")}
          <sub>{t("F&E")}</sub>
          {`[%]`}
          {/*           
          {`EBIT-Potenzial`}
          <sub>{`F&E`}</sub>
          {`[%]`} */}
        </Tooltip>
      ),
      second: (
        <b>
          {formatValue(
            calculateRDpotentialRate(props.data.disciplines, localRndUnits))} %
        </b>
      ),
      third: (
        <Tooltip placement="bottomRight" title={t("F&E-Kosten / Umsatzn")}>
          {t("Anteil F&E am Umsatz")}
        </Tooltip>
      ),
      fourth: props.currentProject
        ? formatValue(
          (calculateTotalAllDisciplinesCosts(
            props.data.disciplines,
            localRndUnits
          ) /
            props.currentProject.sales) *
          100
        ) + "%"
        : "0%",
      fifth: (
        <Tooltip
          placement="bottomRight"
          title={`${t("BM Durchschnitt")} ${t("F&E-Rate")}`}
        >
          {t("BM Durchschnitt")}
          <sub> {t("F&E-Anteil")}</sub>
        </Tooltip>
      ),
      sixth: <Spin spinning={props.benchmarkProjectsLoader}>{formatValue(Number(BMPrpjectsAvgRndRate)) + "%"}</Spin>,

      // fifth: (
      //   <Tooltip placement="bottomRight" title={t("Ext. Kosten / Int. Kosten")}>
      //     {t("Ext. vs Int. Kosten")}
      //   </Tooltip>
      // ),
      // sixth: formatValue(calculateTotalAllDisciplinesExtCosts(props.data.disciplines, localRndUnits)) + "€",
      seventh: (
        <Tooltip placement="bottomRight" title={t("Summe Interdisz.")}>
          {t("F&E")}
          <sub>{t("Interdisziplinär")}</sub>
        </Tooltip>
      ),
      eighth: formatValue(
        calculateTotalAllInterdiscDisciplinesCosts(
          props.data.disciplines,
          localRndUnits
        )) + "€",
      ninth: (
        <Tooltip
          placement="bottomRight"
          title={heute1}
        >
          {t("Anteil interdisz. Kosten")}
        </Tooltip>
      ),
      tenth:
        formatValue(
          calculateInterdiscDisciplinesRate(
            props.data.disciplines,
            localRndUnits
          )
        ) + "%",
      key: "rnd_row_2",
    },
  ];
  // rows of colored table that contains the displines and BM_project's R&D
  // units costs for each discipline
  //const [rows, setRows] = useState([] as RowType[]);

  // variable represents the new R&D unit to be added to BM_project's R&D units (colored table)
  //const [formValues, setFormValues] = useState({} as rnDUnitDto);

  // methods for handling change in the edit unit Modal fields
  // for editting an existing R&D unit
  // show/hide Modal (for editing unit)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  // confirm change (Modal)
  const handleOk = (editedUnit: RnDUnit) => {
    let currentRnDUnits = localRndUnits;
    var unit = currentRnDUnits.find((x) => x.id == editedUnit.id);
    unit.name = editedUnit.name;
    unit.averageAnnualCost = editedUnit.averageAnnualCost;
    unit.totalFullFimeEquivalent = editedUnit.totalFullFimeEquivalent;
    // setUpdateUnit(null);
    // let updatedRows = fillRowsInValue(props.data.disciplines, currentRnDUnits);
    // let columns = getBasicColoredTableHeaderChangable(t, currentRnDUnits, toggleUpdateModal, deleteUnit, saveChanges);
    // setColumns(columns);
    // setLocalRndUnits(currentRnDUnits);
    // props.setEbitUnitsRows(updatedRows);
    setIsModalVisible(false);
    props.UpdateRnDUnit(unit);
  };

  // Cancel Change (Modal)
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFinishAdding = () => {
    setIsAddModalVisible(false);
  };

  // variable represent the unit that is currently edited (updated)
  const [UpdateUnit, setUpdateUnit] = useState<RnDUnit>({
    id: 0,
    averageAnnualCost: 0,
    totalFullFimeEquivalent: 0,
    name: "",
    projectId: 0,
    rDCosts: [],
  });

  //updating the average of the ebit of Benchmarking projects list when current project or projects changed
  useEffect(() => {
    setdata();
  }, [props.currentProject]);
  useEffect(() => {
    setdata();
  }, [props.projects]);

  const setdata = async () => {
    let bmAvgRate = 0;
    if (
      props.currentProject?.otherBmProjects?.filter(c => c.isTargetEbit == true || c.isTargetEbit == false)?.map(c => { return c.id }) &&
      props.currentProject?.otherBmProjects?.filter(c => c.isTargetEbit == true || c.isTargetEbit == false).map(c => { return c.id }).length >= 0
    ) {
      bmAvgRate =
        (props.currentProject && props.projects)
          ? props.projects
            // get the x,k projects from projects list
            .filter(
              (x) =>
                props.currentProject?.otherBmProjects?.filter(c => c.isTargetEbit == true || c.isTargetEbit == false)?.map(c => { return c.id }).includes(x.id)

            )
            // calcultate the average ebit rate for k,x projects
            .reduce((total, next) => total + next.feRate, 0) /
          props.currentProject?.otherBmProjects?.filter(c => c.isTargetEbit == true || c.isTargetEbit == false).map(c => { return c.id }).length
          : 0;
    }
    bmAvgRate = isNaN(bmAvgRate) ? 0 : bmAvgRate;
    console.log("props.currentProject?.otherBmProjects", bmAvgRate);
    setBMPrpjectsAvgRndRate(bmAvgRate);
  };

  // initial columns for colored table (Kostenstruktur) that hold the sum
  // of disciplines for the BM_project
  const [columns, setColumns] = useState<ColumnsType>(initialColumns);

  // to set the colored table width based on the count of the
  // BM_Project's R&D units
  const [xScroll, setXScroll] = useState({ x: 300, y: 400 });

  // Handling the inline change of unit
  // costs ( i.e. fte in each discipline, external cost for each discipline)
  const changeValue = async (
    e,
    unitId: number,
    disciplineId: number,
    property: string
  ) => {
    let val: string = e; //.target.value;
    let convertToNumber = true;

    // if (val.includes(',') || val.includes('.')) {
    //   convertToNumber = false;
    // }
    // let convertToNumber = false;
    // try {
    //   let tempV = Number(e.target.value);
    //   tempV.toPrecision(4);
    //   convertToNumber = true;
    // } catch {
    //   convertToNumber = false;
    // }
    let rnDUnits: RnDUnit[] = [...localRndUnits];

    // Checking if RDCost for this discilpine exists in the unit
    let rDDisciplineCost = rnDUnits
      .find((c) => c.id == unitId)
      .rDCosts.find(
        (c) => c.disciplineId == disciplineId
      );
    // if existing one then edit it
    if (rDDisciplineCost) {
      if (property == "externalAnnualCost") {
        rnDUnits
          .find((c) => c.id == unitId)
          .rDCosts.find(
            (c) => c.disciplineId == disciplineId
          ).externalAnnualCost = convertToNumber ? Number(e) : e; //Number(e.target.value) : e.target.value;
      } else {
        rnDUnits
          .find((c) => c.id == unitId)
          .rDCosts.find(
            (c) => c.disciplineId == disciplineId
          ).fullTimeEquivalent = convertToNumber ? Number(e) : e;
      }
    }
    //else add a new cost for this discipline in the unit
    else {
      let newRDCost: RnDUnitCost = {
        disciplineId: disciplineId,
        fullTimeEquivalent: 0,
        externalAnnualCost: 0,
      }
      if (property == "externalAnnualCost") {
        newRDCost.externalAnnualCost = convertToNumber ? Number(e) : e;
      }
      else {
        newRDCost.fullTimeEquivalent = convertToNumber ? Number(e) : e;
      }

      rnDUnits
        .find((c) => c.id == unitId)
        .rDCosts.push(newRDCost);
    }
    let notChanged: boolean = isEqual(rnDUnits, props.recievedRndUints);
    let updatedRows = fillRowsInValue(props.data.disciplines, rnDUnits);
    let columns = getBasicColoredTableHeaderChangable(
      rnDUnits,
      toggleUpdateModal,
      deleteUnit,
      saveChanges,
      addNewUnit,
      onExport
    );
    setColumns(columns);
    setLocalRndUnits(rnDUnits);
    props.setEbitUnitsRows(updatedRows);

    if (!notChanged) {
      setFormState("modified");
    }
    else {
      setFormState("unchanged");
    }
  };

  // delete R&D unit
  const deleteUnit = async (id) => {
    props.deleteUnit(id);
  };

  const saveChanges = async () => {
    //console.log("saveChanges");
    await props.SaveUnitCostsChanges(localRndUnits);
    setFormState("unchanged");
  };
  const toggleUpdateModal = (unit: RnDUnit) => {
    // if (!unit) {
    //   unit = {};
    // }
    setIsModalVisible(true);
    setUpdateUnit(unit);
  };

  const highlightRow = (e) => {
    // // if (e.target.value == 0)
    // e.target.select();
    // var target = e.target as Element;
    // const el = target.closest("table tr") as HTMLElement;
    // const el2 = el.querySelector('.ant-table-cell-fix-left-last') as HTMLElement;
    // el.style.background = "hsla(40, 96%, 53%)";
    // el2.style.background = "hsla(40, 96%, 53%)";
  };
  // useEffect(()=>{
  //   const el = target.closest("table tr") as HTMLElement;
  //   const el2 = el.querySelector('.ant-table-cell-fix-left-last') as HTMLElement;
  //   el.style.background = (localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "#262626" : "white";
  //   el2.style.background = null;
  // },[localStorage.getItem('selectedTheme')]);

  const removeHighlightRow = (e) => {
    // var target = e.target as Element;
    // const el = target.closest("table tr") as HTMLElement;
    // const el2 = el.querySelector('.ant-table-cell-fix-left-last') as HTMLElement;
    // el.style.background = (localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "#262626" : "white";
    // el2.style.background = null;
  };
  // add the BM_project R&D units to the colored table
  // this function will be called whenever the rnDUnits
  // changes (adding new unit, edit a unit, delete a unit)
  // in order to recalculate all
  // function toLocalizeNumber(value: number) {
  //   return `${t("localizedvalue", { count: value })}`
  // }
  const tableData =
    props.localRows?.map((d) => {
      let tempchildRow = {};
      let unitRow: any = {};

      let headerName = "";

      let subDiscs = d.children
        ?.sort((a, b) => (a.viewOrder > b.viewOrder ? -1 : 1))
        .map((child, i) => {
          tempchildRow = {
            title: <>{child.title}</>,
            isSubRow: true,
            percent: formatValue(child.percent ?? 0) + "%",
            fte_intern: formatValue(child.fte_intern ?? 0),
            gesamt: formatValue(child.gesamt ?? 0),
            pk_intern: formatValue(child.pk_intern ?? 0),
            pk_extern: formatValue(child.pk_extern ?? 0),
            id: child.id,
            key: `childdisc_${child.id}`,
            backgroundColor: d.backgroundColor,
            foreColor: d.foreColor,
            viewOrder: child.viewOrder,
            costTypeIsOriginalNotInterdisciplinary:
              child.costTypeIsOriginalNotInterdisciplinary,
            children: null,
          };

          child.unitInRow?.map((unitInRow) => {
            let internalCosts =
              unitInRow.fullTimeEquivalent * unitInRow.unit.averageAnnualCost;
            let externalCosts = unitInRow.externalAnnualCost;
            let totalCosts = internalCosts + externalCosts;
            let parentFullTimeEquivalent = 0;
            let parentInternalCosts = 0;
            let parentExternalCosts = 0;
            let parentTotalCosts = 0;
            parentFullTimeEquivalent += unitInRow.fullTimeEquivalent;
            parentInternalCosts += internalCosts;
            parentExternalCosts += externalCosts;
            parentTotalCosts += totalCosts;
            headerName = unitInRow.unit.id.toString();
            tempchildRow[`fte_mit_pe_beteiligung_${headerName}`] = (
              <InputNumber
                min={0}
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
                value={unitInRow.fullTimeEquivalent}
                onChange={debounce((e) => {
                  changeValue(
                    e,
                    unitInRow.unit.id,
                    unitInRow.disciplineId,
                    "fullTimeEquivalent"
                  );
                }, 250)}
                onFocus={(e) => {
                  highlightRow(e);
                }}
                onBlur={(e) => {
                  removeHighlightRow(e);
                }} 
              />
            );
            tempchildRow[`interne_kosten_${headerName}`] = formatValue(
              internalCosts ?? 0
            );
            tempchildRow[`externe_kosten_${headerName}`] = (
              <InputNumber
                formatter={(value) => inputFormatter(value, i18n.language)}
                parser={(x) => inputParser(x, i18n.language)}
                value={externalCosts}
                onChange={debounce((e) =>
                  changeValue(
                    e,
                    unitInRow.unit.id,
                    unitInRow.disciplineId,
                    "externalAnnualCost"
                  ), 250
                )
                }
                onFocus={(e) => {
                  highlightRow(e);
                }}
                onBlur={(e) => {
                  removeHighlightRow(e);
                }}
                minLength={7}
              />
            );
            tempchildRow[`kosten_gesamt_${headerName}`] = formatValue(totalCosts);
          });
          return tempchildRow;
        });

      let row = {
        title: (
          <div style={{ width: "91%", float: "right" }}>
            <Row>
              <Col span={22}>
                <abbr>{d.title}</abbr>
              </Col>
              <Col span={2}>
                <Tooltip
                  title={d.tooltip}
                  overlayStyle={{ whiteSpace: "break-spaces" }}
                  overlayInnerStyle={{
                    width: "350px",
                    color: d.foreColor,
                    fontSize: "1.5vh",
                  }}
                  color={d.backgroundColor}
                >
                  <QuestionCircleOutlined
                    style={{
                      // marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
        isSubRow: false,
        percent: formatValue(d.percent ?? 0) + "%",
        fte_intern: formatValue(d.fte_intern ?? 0),
        gesamt: formatValue(d.gesamt ?? 0),
        pk_intern: formatValue(d.pk_intern ?? 0),
        pk_extern: formatValue(d.pk_extern ?? 0),
        key: `maindisc_${d.id}`,
        id: d.id,
        backgroundColor: d.backgroundColor,
        foreColor: d.foreColor,
        viewOrder: d.viewOrder,
        costTypeIsOriginalNotInterdisciplinary:
          d.costTypeIsOriginalNotInterdisciplinary,
        children: subDiscs && subDiscs.length !== 0 ? subDiscs : null,
      };
      d.unitInRow?.map((unitInRow) => {
        let internalCosts =
          unitInRow.fullTimeEquivalent * unitInRow.unit.averageAnnualCost;
        let externalCosts = unitInRow.externalAnnualCost;
        let totalCosts = internalCosts + externalCosts;
        headerName = unitInRow.unit.id.toString();
        let parentFullTimeEquivalent = 0;
        let parentInternalCosts = 0;
        let parentExternalCosts = 0;
        let parentTotalCosts = 0;
        if (d.children && d.children.length > 0) {
          d.children.map((child, i) => {
            child.unitInRow
              .filter((c) => c.unit.id == unitInRow.unit.id)
              ?.map((unitInRow2) => {
                let internalCosts =
                  unitInRow2.fullTimeEquivalent *
                  unitInRow2.unit.averageAnnualCost;
                let externalCosts = unitInRow2?.externalAnnualCost ?? 0;
                let totalCosts = internalCosts + externalCosts;
                parentFullTimeEquivalent += unitInRow2?.fullTimeEquivalent ?? 0;
                parentInternalCosts += internalCosts;
                parentExternalCosts += externalCosts;
                parentTotalCosts += totalCosts;
              });
          });
          row[`fte_mit_pe_beteiligung_${headerName}`] = (
            <InputNumber
              min={0}
              formatter={(value) => inputFormatter(value, i18n.language)}
              parser={(x) => inputParser(x, i18n.language)}
              disabled={true}
              value={parentFullTimeEquivalent}
             
            />
          );
          row[`interne_kosten_${headerName}`] = formatValue(parentInternalCosts);
          row[`externe_kosten_${headerName}`] = (
            <InputNumber
              min={0}
              formatter={(value) => inputFormatter(value, i18n.language)}
              parser={(x) => inputParser(x, i18n.language)}
              disabled={true}
              value={parentExternalCosts} 
            />
          );
          row[`kosten_gesamt_${headerName}`] = formatValue(parentTotalCosts);
        } else {
          row[`fte_mit_pe_beteiligung_${headerName}`] = (
            <InputNumber
              min={0}
              formatter={(value) => inputFormatter(value, i18n.language)}
              parser={(x) => inputParser(x, i18n.language)}
              value={unitInRow.fullTimeEquivalent}
              onChange={debounce((e) =>
                changeValue(
                  e,
                  unitInRow.unit.id,
                  unitInRow.disciplineId,
                  "fullTimeEquivalent"
                ), 250)
              }
              onFocus={(e) => {
                highlightRow(e);
              }}
              onBlur={(e) => {
                removeHighlightRow(e);
              }} 
            />
          );
          row[`interne_kosten_${headerName}`] = formatValue(internalCosts);
          row[`externe_kosten_${headerName}`] = (
            <InputNumber
            min={0}
              formatter={(value) => inputFormatter(value, i18n.language)}
              parser={(x) => inputParser(x, i18n.language)}
              value={externalCosts}
              onChange={debounce((e) =>
                changeValue(
                  e,
                  unitInRow.unit.id,
                  unitInRow.disciplineId,
                  "externalAnnualCost"
                ), 250)
              }
              onFocus={(e) => {
                highlightRow(e);
              }}
              onBlur={(e) => {
                removeHighlightRow(e);
              }} 
            />
          );
          row[`kosten_gesamt_${headerName}`] = formatValue(totalCosts);
        }
      });
      return row;
    }) ?? [];

  tableData.push({
    title: <></>,
    isSubRow: false,
    percent: `${formatValue(
      props.localRows.reduce((accumulator, obj) => {
        return accumulator + obj.percent;
      }, 0)
    )}%`,
    fte_intern: `${formatValue(
      props.localRows.reduce((accumulator, obj) => {
        return accumulator + obj.fte_intern;
      }, 0)
    )}`,
    gesamt: `${formatValue(
      props.localRows.reduce((accumulator, obj) => {
        return accumulator + obj.gesamt;
      }, 0))} €`,
    pk_intern: `${formatValue(
      props.localRows.reduce((accumulator, obj) => {
        return accumulator + obj.pk_intern;
      }, 0))} €`,
    pk_extern: `${formatValue(
      props.localRows.reduce((accumulator, obj) => {
        return accumulator + obj.pk_extern;
      }, 0))} €`,
    key: "",
    id: 0,
    backgroundColor: (localStorage.getItem('selectedTheme') ?? "light") == "dark" ? "#262626" : "white",
    foreColor: (localStorage.getItem('selectedTheme') ?? "light")  == "dark" ? "white" : "#262626",
    viewOrder: 0,
    costTypeIsOriginalNotInterdisciplinary: false,
    children: null,
  });
  usePrompt(t("Sie haben nicht gespeicherte Änderungen, möchten Sie wirklich gehen?"), formState !== "unchanged");
  return (
    <Spin spinning={props.isEbitLoading}>
      <Row>
        <Col span={24}>
          <TableTwoColumns columns={columnsOne} rows={rowsOne} />
        </Col>
      </Row>

      <section id="colored-table"> 
        <Row id="input-form">
          <Col span={24}>
            <hr />
            <h3>
              {t("Produktentwicklungskosten sind Personalkosten für die originäre und interdisziplinäre Produktentwicklung.")}
            </h3>
            <hr />
          </Col>
        </Row>
        <Table
          defaultExpandAllRows
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered={false}
          //scroll={xScroll}
          scroll={{ x: xScroll.x, y: "calc(100vh - 100px)" }}
          key="rd_ebit_table"
          expandIcon={({ expanded, onExpand, record }) => {
            if (!record["children"]) return <span></span>;
            return expanded ? (
              <CaretDownOutlined
                key={`expand_down_${record["id"]}`}
                style={{ color: "white" }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <CaretRightOutlined
                key={`expand_right_${record["id"]}`}
                style={{ color: "white" }}
                onClick={(e) => onExpand(record, e)}
              />
            );
          }}
        />
      </section>
      {UpdateUnit ? (
        <EditModal
          key={`update_${UpdateUnit.id}`}
          unit={UpdateUnit}
          handleCancel={handleCancel}
          // handleChangeAvg={handleEditUnitAvg}
          // handleChangeFte={handleEditUnitFte}
          // handleChangeName={handleEditUnitName}
          handleOk={handleOk}
          isModalVisible={isModalVisible}
        />
      ) : (
        <></>
      )}
      {isAddModalVisible ? (
        <AddModal
          finishAdding={handleFinishAdding}
          createNewUnit={props.createNewUnit}
          currentProject={props.currentProject}
          isModalVisible={isAddModalVisible}
        />
      ) : (
        <></>
      )}
    </Spin>
  );
}

const mapStateToProps = (state: IGeneralState) => {
  return {
    localRows: state.ebit.unitsRows,
    isEbitLoading: state.ebit.isEbitLoading,
    projects: state.generalInfo?.allBenchmarkingProjects,
    benchmarkProjectsLoader: state.generalInfo?.benchmarkProjectsLoader,
    // commonResult: state.generalInfo.commonResponse,
    profileResponse: state.generalInfo.profileResponse,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setEbitUnitsRows: (list: RowTypeValue[]) =>
      dispatch({ type: ActionTypes.UpdateRdUnitsRow, result: list }),
    createNewUnit: (unit: RnDUnit) =>
      dispatch({ type: ActionTypes.CreateNewUnit, rndUnit: unit }),
    UpdateRnDUnit: (unit: RnDUnit) =>
      dispatch({ type: ActionTypes.UpdateUnit, rndUnit: unit }),
    SaveUnitCostsChanges: (units: RnDUnit[]) =>
      dispatch({ type: ActionTypes.SaveUnitCostsChanges, rndUnits: units }),
    deleteUnit: (rndUnitId: number) =>
      dispatch({ type: ActionTypes.DeleteUnit, rndUnitId: rndUnitId }),
    chnageLoaderStatus: (isLoading: boolean) =>
      dispatch({ type: ActionTypes.changeEbitLoader, isLoading: isLoading }),

    downloadRDTableExcel: (projectID: number, projectName: string) =>
      dispatch({
        type: ActionTypes.DownloadRDTableExcel,
        projectID,
        projectName,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColoredTable);
