import React from 'react';
import { Column } from '@ant-design/plots';
import { Col, Row } from "antd";
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { BmProject } from "../../../../Models/Responses/ProfileResponse";
import { IGeneralState } from "../../../../Redux/reducers/IGeneralState";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatValue } from "../../../../Utils/helperFunctions";
import { IChartData } from '../../../../Models/Activity/ActivityModels';

import tinycolor from "tinycolor2";
interface Iprops {
    chartTitle: string,
    data: IChartData[],
    bestPracticesData: IChartData[],
    currentProject: BmProject;
    height: number;
    className?: string;
    baseColor?: string;
    maxLimit: number;
    isTasks: boolean;
}

const StackedColumnChart = (props: Iprops) => {
    const { t } = useTranslation();

    var bpcolor = tinycolor("#6ca72d");
    var bpcolors = [];
    for (var i = 0; i < props.bestPracticesData.length; i++) {
        bpcolors.push(tinycolor.mix(bpcolor, "white", i * 8).toHexString());
    }

    var color = tinycolor(props.baseColor ?? "#1CADE4");
    var colors = [];
    for (var i = 0; i < props.data.length; i++) {
        colors.push(tinycolor.mix(color, "white", i * 8).toHexString());
    }
    let activeDiscipline = '';
    const DownloadChartAsImage = () => {
        const canvasSave = document.getElementById(props.chartTitle);
        html2canvas(canvasSave, {
            scale: 15
        })
            .then((canvas) => {
                canvas.toBlob(function (blob) {
                    saveAs(blob, `${props.chartTitle}.png`)
                })
            })

    }
    const ResizeCanvas = (canvas, newWidth, newHeight) => {
        var tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        var ctx = tmpCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

        return tmpCanvas;
    }
    let chartData = props.bestPracticesData?.concat(props.data);

    // G2.registerInteraction('element-link', {
    //     start: [
    //         { trigger: 'plot:click', action: 'element-link-by-color:link' }
    //     ],
    //     end: [
    //         { trigger: 'plot:dblclick', action: 'element-link-by-color:unlink' },
    //         { trigger: 'legend-item:click ', action: 'element-link-by-color:clear' }
    //     ]
    // });
    const config = {
        data: chartData,
        isStack: true,
        xField: 'name',
        yField: 'value',
        isPercent: false,
        seriesField: 'code',
        tooltip: null,
        autoFit: true,
        appendPadding: [10, 40, 10, 10],
        legend: {
            offsetX: -15,
            offsetY: 0,
            flipPage: true,
            maxItemWidth: 140,
            slidable: true
        },
        interactions: [
            //{ type: 'element-link' },
            { type: 'legend-filter', enable: false }
        ],
        color: bpcolors.concat(colors),// props.isTasks ? bpcolors.concat(colors) : colors,
        // interactions: [
        //     { type: 'element-link' },
        //     { type: 'legend-filter', enable: false }
        // ],
        yAxis: {
            maxLimit: props.maxLimit,
            tickInterval: 0,
            label: {
                //position: 'middle',
                formatter: (text) => { return formatValue(text) + '%' },
            },
        },
        label: {
            formatter: (item: any, state, index) => {
                return formatValue(item.value) + '%';
                // console.log("Formatter.........", index, item);
                // if (index % 2 == 0) return formatValue(item.value) + '%'
                // else return "";
            },
            position: (item: any, state, index) => {
                if (props.isTasks && props.data?.length < 4) return "middle";
                if (index % 2 == 0) return "left";
                else return "middle"
            },
            offsetX: props.isTasks ? 0 : 15,
            // 可手动配置 label 数据标签位置                        
            // 'top', 'bottom', 'middle'
            // 可配置附加的布局方法
            layout: [

                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                }, // 数据标签文颜色自动调整
                // {
                //     type: 'adjust-color',
                // },
            ],
        }
    };
    return (

        <div style={{ height: (props.height - 1) + 'vh', background: '#eeeeee' }} >
            <>
                <Row style={{ height: '1vh', paddingBottom: '5vh' }}>
                    <Col span={24} style={{ fontSize: "18px", textOverflow: 'ellipsis', fontWeight: "bold", textAlign: 'center' }}>
                        <span >{props.chartTitle}</span>
                    </Col>
                </Row>
                <Column style={{ height: Number(props.height) - 6 + 'vh' }} {...config} />
            </>
        </div>);
};
const mapStateToProps = (state: IGeneralState) => {
    return {
        ebitState: state.ebit,
        generalInfo: state.generalInfo,
        currentProject: state.generalInfo.profileResponse?.currentProject,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StackedColumnChart);
