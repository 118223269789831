import React from 'react';
import html2canvas from 'html2canvas';
// import { jsPDF } from "jspdf";
import { saveAs } from 'file-saver';
import BasicBarChart from './BasicColumnChart';
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tooltip, Checkbox } from "antd";
import { BestPractice } from '../Models/EvaluationData';
import { CheckboxChangeEvent } from "antd/lib/checkbox";
interface Iprops {
    ebitBMProjectsForChart: ProjectData[],
    isMinimized?: boolean;
    width?: number,
    height?: number,
    bestPractice: BestPractice,
    subTitle: string,
    isTopLevelChart: boolean,
    selectChart?: (chartDivId: string, add: boolean) => void;
    selectedCharts?: string[];
}
interface ProjectData {
    name: string,
    // annoName: string,
    value: number,
    isTarget: boolean,
    isComplete: boolean,
}
export default class ASupColumnchart extends React.Component<Iprops> {

    // printDocument() {
    //     const input = document.getElementById('divToPrint');
    //     html2canvas(input)
    //         .then((canvas) => {
    //             const imgData = canvas.toDataURL('image/png');
    //             const pdf = new jsPDF();
    //             pdf.addImage(imgData, 'JPEG', 0, 0, 100, 100);
    //             // pdf.output('dataurlnewwindow');
    //             pdf.save("download.pdf");
    //         })
    //         ;
    // }
    DownloadChartAsImage(subTitle) {
        const canvasSave = document.getElementById(subTitle);
        const subcanvasSave = document.getElementById(subTitle + "_chart");
        const lastChild = subcanvasSave.lastElementChild;
        // if (this.props.ebitBMProjectsForChart?.length >= 20) {

        //     if (subcanvasSave.hasChildNodes()) {
        //         subcanvasSave.removeChild(lastChild);
        //     }
        // }
        canvasSave.insertAdjacentHTML('afterbegin', `<div id="appendid" style="text-align: center; font-weight:bold;" }><text>${this.props.subTitle}</text></div>`);
        let heightToBeAdded = document.getElementById("appendid").offsetHeight * 2;
        let originalHeight = canvasSave.offsetHeight;
        let newHeight = heightToBeAdded + originalHeight;
        html2canvas(canvasSave, { height: newHeight, scale: 20 })
            .then((canvas) => {
                canvas.toBlob(function (blob) {
                    saveAs(blob, `${subTitle}.png`)
                })
            })
        // if (this.props.ebitBMProjectsForChart?.length >= 20) {
        //     subcanvasSave.appendChild(lastChild);
        // }
        canvasSave.removeChild(canvasSave.firstChild);

    }
    selectChart(e: CheckboxChangeEvent) {
        if (!this.props.selectChart) return;
        if (e.target.checked) {
            this.props.selectChart(this.props.subTitle, true);
        }
        else {
            this.props.selectChart(this.props.subTitle, false);
        }
    };
    // checkBoxClicked(e) {
    //     //e.preventDefault();
    //     e.stopPropagation();
    // };
    ResizeCanvas(canvas, newWidth, newHeight) {
        var tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        var ctx = tmpCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

        return tmpCanvas;
    }
    render() {

        if (this.props.ebitBMProjectsForChart && this.props.ebitBMProjectsForChart.length == 0) return;
        let data = this.props.ebitBMProjectsForChart?.map(c => {
            return {
                // name: c.isTarget ? c.name : c.annoName,
                name: c.name,
                // annoName: c.annoName,
                isTarget: c.isTarget,
                value: c.value ? Number(c.value?.toFixed(2)) : 0,
                color: c.isTarget ? '#779BDB' : '#7A7A7A',
                hasdashborder: c.isComplete
            }
        });
        data = data?.sort((a, b) => (a.value < b.value ? 1 : -1));
        // let ebitBMProjectsForChart = this.props.ebitBMProjectsForChart?.sort((a, b) => (a.value < b.value ? 1 : -1));
        let colors = data?.
            //sort((a, b) => (a.value < b.value ? 1 : -1))?.
            map(c => { return { color: c.isTarget ? '#779BDB' : '#7A7A7A' } });
        return (
            <div
                className={this.props.isMinimized ? "" : this.props.isTopLevelChart ? "" : "barChart_container unselectable"}
                style={{
                    margin: this.props.isMinimized ? "0px" : this.props.isTopLevelChart ? "0px" : "5px 10px 5px 10px", height: this.props.isTopLevelChart ? '27vh' : null,
                    cursor: this.props.isTopLevelChart ? 'pointer' : 'default', border: !this.props.isTopLevelChart ? '1px solid #ccc' : null
                }}
            >
                <div
                    className={this.props.isMinimized ? "" : "chart_container"}
                    style={this.props.isMinimized
                        ? { height: this.props.height, }
                        : this.props.isTopLevelChart ? { height: '27vh' } : { height: 350 }}>
                    {!this.props.isTopLevelChart && <>
                        <Row style={{ position: "relative" }}>
                            {/* <Col span={1}></Col> */}
                            <Col span={2} style={{ textAlign: 'center' }}>
                                <Checkbox checked={this.props.selectedCharts?.includes(this.props.subTitle)} onChange={(e) => this.selectChart(e)} style={{ 'zIndex': '100000' }} />
                            </Col>
                            <Col span={20} style={{ textAlign: 'center' }}>
                                {/* <text x="250" y="0" dominantBaseline="hanging" textAnchor='middle' fontSize="13" fontWeight="bold">{this.props.subTitle}</text> */}
                            </Col>
                            <Col span={2}>

                                <Tooltip placement="bottom" title="Download Chart">
                                    <Button style={{ position: "absolute", top: '5px' }}
                                        icon={<DownloadOutlined />} onClick={() => this.DownloadChartAsImage(this.props.subTitle)} type="default"></Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        <div style={{ fontWeight: "bold", textAlign: "center" }}>
                            <text dominantBaseline="hanging" textAnchor='middle' fontSize="13" fontWeight="bold">{this.props.subTitle}</text>
                        </div>
                    </>
                    }
                    {this.props.isTopLevelChart && <div style={{ paddingBottom: 0, fontWeight: "normal", textAlign: "center" }}>
                        <text dominantBaseline="hanging" textAnchor='middle' fontSize="8" fontWeight="normal">{this.props.subTitle}</text>
                    </div>
                    }
                    <BasicBarChart isTopLevelChart={this.props.isTopLevelChart} subTitle={this.props.subTitle} data={data} colors={colors} bestPractice={this.props.bestPractice} />
                </div>
            </div >
        );
    }
}
